import React from 'react'
import PropTypes from "prop-types"
const UpdateIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M19.692 1a.778.778 0 0 0-.769.786v2.87a9.834 9.834 0 0 0-14.113.233A10.31 10.31 0 0 0 2 12c0 .434.344.786.77.786.424 0 .768-.352.768-.786.001-4.773 3.79-8.642 8.464-8.641a8.31 8.31 0 0 1 3.409.733 8.468 8.468 0 0 1 2.83 2.074l-3.407 1.16a.79.79 0 0 0-.488.995c.134.412.57.635.974.498l4.616-1.571a.77.77 0 0 0 .381-.287.797.797 0 0 0 .145-.461V1.786a.778.778 0 0 0-.77-.786Zm1.539 10.214a.778.778 0 0 0-.77.786c0 4.773-3.79 8.642-8.463 8.641a8.31 8.31 0 0 1-3.409-.733 8.47 8.47 0 0 1-2.83-2.074l3.407-1.16a.79.79 0 0 0 .488-.995.767.767 0 0 0-.974-.498l-4.615 1.571a.77.77 0 0 0-.382.287.797.797 0 0 0-.145.461v4.714c0 .434.345.786.77.786.424 0 .769-.352.769-.786v-2.87a9.834 9.834 0 0 0 14.113-.233A10.311 10.311 0 0 0 22 12a.777.777 0 0 0-.77-.786Z"
                />
            </svg>
        </>
    )
}
UpdateIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default UpdateIcon
