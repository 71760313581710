import React, { useState, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ClosePopup from "../../Images/close-popup.svg";
import Loader from "../../Images/loader.gif";


export default function ImageCropperReact({
  file, // common
  setModalOpen = () => { },
  setFile = () => { },
  fileName = "",
  fileDimension = "",
  requiredImageSize = {},
  setRequiredImageSizeError = () => { },
  requiredImageAspectRatio = 16 / 9,

  setcropedFile = () => { },
  setcropedFileData = () => { },

  title = "",
  isEdit = false,

  CropDone = () => { }, // call API for some component

  selectedImagePerson = "", // User profile CRUD
  setCroppedUserFile = () => { },
  setCroppedPartnerFile = () => { },

  selectedBannerType = "", // banner CRUD
  setBannerForMobileCroppedImage = () => { },
  setBannerForWebCroppedImage = () => { },
  setMobileCroppedFileData = () => { },
  setWebCroppedFileData = () => { },

  actionType = "", // news CRUD
  handleIsEdit = () => { },
  handleIsCreate = () => { },
  setCreateImageFile = () => { },
  setEditImageFile = () => { },
  setCreateImageFileData = () => { },
  setEditImageFileData = () => { },

  notificationDetail = {},
  setNotificationDetail = () => { },

  selectImageType = "", // partner CRUD
  setLogo = () => { },
  setDarkCompanyLogo = () => { },
  setThumbnail = () => { },
  setWebBanner = () => { },
  setMobileBanner = () => { },
  parntnerImageFileData = {},
  setParntnerImageFileData = () => { },

  // setVideoThumbnail = () => { }, // video CRUD
  setPartnerImgData = () => { },
  setVideoThumbnailData = () => { },
  setRelevantPartnerImg = () => { },
  setVideoThumbnailImg = () => { },

  setAddIconFile = () => { },
  setEditIconFile = () => { },
  setcreateHelpFullLinkCroppedfileData = () => { },
  setEditHelpFullLinkCroppedfileData = () => { },

  setAddLocationImgFile = () => { }, // location CRUD
  setEditLocationImgFile = () => { },
  setAddLocationImgFileData = () => { },
  setEditLocationImgFileData = () => { },
  isLoading,
}) {
  const [crop, setCrop] = useState({
    // aspect: 1/2,
    // unit: "%",
    // x: 25,
    // y: 25,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageActualSize, setImageActualSize] = useState({
    height: "",
    width: "",
  });
  //when ESC key press close popup
  useEffect(() => {
    // Add event listener for keyboard keys
    document.addEventListener("keydown", handleKeyPress);

    // Clean up function to remove event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  // Event listener for keyboard keys
  const handleKeyPress = (event) => {
    if (event.keyCode === 27) {
      setModalOpen(false);
    }
  };

  //calling when page load cropping area
  useEffect(() => {
    // debugger
    if (isLoading) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;
          setImageActualSize({ width, height });
          const aspectRatio = requiredImageAspectRatio;
          setCrop(centerAspectCrop(width, height, aspectRatio));
        };
      };
      if (fileDimension)
        reader.readAsDataURL(fileDimension);
    }
  }, [fileDimension, requiredImageAspectRatio]);

  //set cropping area in center
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 50,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  //handle chaneg in crop
  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  // when crop submit
  const handleCropSubmit = async () => {
    // image size validation for other modules

    // const croppedHeight = ((crop.height * +imageActualSize.height) / 100)
    // const croppedWidth = ((crop.width * +imageActualSize.width) / 100)
    // if (croppedWidth < requiredImageSize?.width || croppedHeight < requiredImageSize?.height) {
    //   setRequiredImageSizeError()
    //   setModalOpen(false);
    //   return;
    // }

    // image size validation for partner modules

    // const requiredImageSizes = {
    //   logoForLightTheme: { width: requiredImageSize.logoForLightThemeWidth, height: requiredImageSize.logoForLightThemeHeight },
    //   logoForDarkTheme: { width: requiredImageSize.logoForDarkThemeWidth, height: requiredImageSize.logoForDarkThemeHeight },
    //   partnerThumbnailImage: { width: requiredImageSize.thumbnailImageWidth, height: requiredImageSize.thumbnailImageHeight },
    //   partnerWebBanner: { width: requiredImageSize.webBannerWidth, height: requiredImageSize.webBannerHeight },
    //   default: { width: requiredImageSize.mobileBannerWidth, height: requiredImageSize.mobileBannerHeight }
    // };
    // const selectedSize = requiredImageSizes[selectImageType] || requiredImageSizes.default;

    // if (croppedWidth < selectedSize.width || croppedHeight < selectedSize.height) {
    //   setRequiredImageSizeError();
    //   setModalOpen(false);
    //   return;
    // }
    const originalImage = document.querySelector(".crop-media-image");
    originalImage.crossOrigin = "anonymous";

    // Wait for the image to load
    await new Promise((resolve) => {
      originalImage.onload = resolve;
    });

    let croppedImageBlob;
    if (completedCrop) {
      croppedImageBlob = await getCroppedBase64(
        originalImage,
        completedCrop,
        "crop-image" +
        new Date().getTime() +
        `.${fileDimension?.type.split("/")[1]
          ? fileDimension?.type.split("/")[1]
          : "jpg"
        }`
      );
    } else {
      croppedImageBlob = await getCroppedBase64(
        originalImage,
        crop,
        "crop-image" +
        new Date().getTime() +
        `.${fileDimension?.type.split("/")[1]
          ? fileDimension?.type.split("/")[1]
          : "jpg"
        }`
      );
    }

    const croppedImageUrl = croppedImageBlob; //for show local image
    const cropImageData = dataURLtoFile(croppedImageBlob); //for send to formData

    // *********  Display the cropped image  *********

    CropDone(cropImageData);
    //showing image locally
    setcropedFile(croppedImageUrl);
    selectedImagePerson && selectedImagePerson === "user"
      ? setCroppedUserFile(croppedImageUrl)
      : setCroppedPartnerFile(croppedImageUrl);

    selectedBannerType && selectedBannerType === "webBanner"
      ? setBannerForWebCroppedImage(croppedImageUrl)
      : setBannerForMobileCroppedImage(croppedImageUrl);

    if (actionType) {
      if (actionType === "add") {
        handleIsCreate(croppedImageUrl);
        setCreateImageFile(croppedImageUrl);
        setAddIconFile(croppedImageUrl);
        setAddLocationImgFile(croppedImageUrl);
      } else {
        handleIsEdit(croppedImageUrl);
        setEditImageFile(croppedImageUrl);
        setEditIconFile(croppedImageUrl);
        setEditLocationImgFile(croppedImageUrl);
      }
    }

    handleIsEdit(croppedImageUrl);
    setNotificationDetail({
      ...notificationDetail,
      image: croppedImageUrl,
    });

    //showing image locally(Partner)
    if (selectImageType) {
      switch (selectImageType) {
        case "logoForLightTheme":
          setLogo(croppedImageUrl);
          break;
        case "logoForDarkTheme":
          setDarkCompanyLogo(croppedImageUrl);
          break;
        case "partnerThumbnailImage":
          setThumbnail(croppedImageUrl);
          break;
        case "partnerWebBanner":
          setWebBanner(croppedImageUrl);
          break;
        case "relevantPartners":
          setRelevantPartnerImg(croppedImageUrl);
          break;
        case "thumbnail":
          setVideoThumbnailImg(croppedImageUrl);
          break;
        default:
          setMobileBanner(croppedImageUrl);
          break;
      }
    }

    // *********  appending image into FormData  *********

    setcropedFileData(cropImageData);
    selectedBannerType && selectedBannerType === "webBanner"
      ? setWebCroppedFileData(cropImageData)
      : setMobileCroppedFileData(cropImageData);

    if (actionType) {
      if (actionType === "add") {
        setCreateImageFileData(cropImageData);
        setcreateHelpFullLinkCroppedfileData(cropImageData);
        setAddLocationImgFileData(cropImageData);
      } else {
        setEditImageFileData(cropImageData);
        setEditHelpFullLinkCroppedfileData(cropImageData);
        setEditLocationImgFileData(cropImageData);
      }
    }

    //appending image into FormData(Partner)
    if (selectImageType) {
      switch (selectImageType) {
        case "logoForLightTheme":
          setParntnerImageFileData({
            ...parntnerImageFileData,
            logoFileData: cropImageData,
          });
          break;
        case "logoForDarkTheme":
          setParntnerImageFileData({
            ...parntnerImageFileData,
            darkCompanyLogoFileData: cropImageData,
          });
          break;
        case "partnerThumbnailImage":
          setParntnerImageFileData({
            ...parntnerImageFileData,
            thumbnailFileData: cropImageData,
          });
          break;
        case "partnerWebBanner":
          setParntnerImageFileData({
            ...parntnerImageFileData,
            webBannerFileData: cropImageData,
          });
          break;
        case "relevantPartners":
          setPartnerImgData(cropImageData);
          break;
        case "thumbnail":
          setVideoThumbnailData(cropImageData);
          break;
        default:
          setParntnerImageFileData({
            ...parntnerImageFileData,
            mobileBannerFileData: cropImageData,
          });
          break;
      }
    }

    setModalOpen(false);
    setFile("");
    CropDone(cropImageData);
  };

  // get url cropping image
  async function getCroppedBase64(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const cropInPercentage = {
      aspect: crop.aspect,
      unit: "%",
      width: crop.width,
      height: crop.height,
      x: crop.x,
      y: crop.y,
    };

    // Get the actual dimensions of the image
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;

    // Convert percentage values to pixels
    const cropInPixels = {
      aspect: cropInPercentage.aspect,
      unit: "px", // Change to 'px' to use pixel values
      width: (cropInPercentage.width / 100) * imageWidth,
      height: (cropInPercentage.height / 100) * imageHeight,
      x: (cropInPercentage.x / 100) * imageWidth,
      y: (cropInPercentage.y / 100) * imageHeight,
    };

    canvas.width = cropInPixels.width;
    canvas.height = cropInPixels.height;
    // Set the background to white
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      cropInPixels.x,
      cropInPixels.y,
      cropInPixels.width,
      cropInPixels.height,
      0,
      0,
      cropInPixels.width,
      cropInPixels.height
    );

    // Convert the canvas content to a base64-encoded string
    const croppedImageUrl = canvas.toDataURL(
      `image/${fileDimension?.type.split("/")[1]}`
    );
    return croppedImageUrl;
  }

  //covert function dataURLtoFile
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], fileName, { type: mime });
  };

  //When Handle Cropping completed
  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setCompletedCrop(croppedAreaPixels);
    setCrop(croppedAreaPixels);
  };

  return (
    <div className="desk-globle-model-box globle-model-box checksboxes-visible cstm-rpp-main cropper-bd">
      <div className="desk-globle-model-box-inner edit-profile-cstm">
        <div className={`modal-content cropper-content`}>
          <div className="media-attachment-details-header">
            <h4 className="madh-h4">
              {isEdit ? `Replace ${title}` : "Select view area"}
            </h4>
            <button
              type="button"
              className="madh-close"
              onClick={(e) => {
                setModalOpen(false);
              }}
            >
              <img src={ClosePopup} />
            </button>
          </div>
          {isLoading ?
            <div className="img-cropper-container">
              <img src={Loader} />
            </div>
            : <><div className="crop-media-body">
              {file && (
                <ReactCrop
                  crop={crop}
                  keepSelection={true}
                  aspect={requiredImageAspectRatio}
                  onChange={handleCropChange}
                  onComplete={handleCropComplete}
                  // minHeight={requiredImageSize?.height}
                  // minWidth={requiredImageSize?.width}
                  minHeight={50}
                  minWidth={50}
                >
                  <img src={file} className="crop-media-image" />
                </ReactCrop>
              )}

            </div><div className="crop-media-footer">
                <button className="upload-media-btn" onClick={handleCropSubmit}>
                  Done
                </button>
              </div></>
          }
        </div>
      </div>
    </div>
  );
}
