import React, { useEffect, useState } from 'react'
import Logo from "../../../Images/logo_mds_small.svg";
import Successful from "../../../Images/mds.svg";
import { Link, useLocation } from 'react-router-dom';
import loading from "../../../Images/loader.gif";
import authServiceCollaborator from '../../../Services/auth.service.collaborator';
import noAccess from '../../../Images/nonaccessuser.svg'
const CollaboratorMainPage = () => {
    const location = useLocation().search
    const id = new URLSearchParams(location).get('id')
    const [loader, setLoader] = useState(true)
    const [isExist, setIsExist] = useState(false)
    const [collaboratoName, setCollaboratorName] = useState('')
    useEffect(() => {
        authServiceCollaborator.getCollaboratorDetail(id)
            .then((res) => {
                console.log(res, "res");
                if (res.data.status) {
                    setCollaboratorName(res.data.data.sharedUserDetails.firstName + ' ' + res.data.data.sharedUserDetails.lastName)
                    setLoader(false)
                } else {
                    setIsExist(true)
                    setLoader(false)
                }
            })
            .catch((err) => {
                console.log(err, "err");
                setLoader(false)
            })
    }, [])
    return (
        <>
            {loader ?
                <div className="loadind-post-colla">
                    <img src={loading} />
                </div>
                :
                <>
                    {isExist ?
                        <>
                            <div className="collaborator-main">
                                <div className="collaborator-emailicon"><img src={noAccess} /></div>

                                <div className="collaborator-succesfully">
                                    <div className="collaborator-info">
                                        <h2 className='collaborator-h2'>Oops. Link expired!</h2>
                                        <h6 className='collaborator-h6'>It appears you clicked on an expired join link or the URL is incorrect.</h6>
                                    </div>
                                    {/* <div className='btn-log-in-to-mds'><a className='btn-log' href="/">Request a new link</a></div> */}
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="header-collaborator"><a href='#'><img src={Logo} /></a></div>

                            <div className="collaborator-main">
                                <div className="collaborator-emailicon"><img src={Successful} /></div>

                                <div className="collaborator-succesfully">
                                    <div className="collaborator-info">
                                        <h4 className='collaborator-h4'>{collaboratoName} has invited to </h4>
                                        <h2 className='collaborator-h2'>Join MDS as a collaborator</h2>
                                        <h6 className='collaborator-h6'>We will send you a verification code in email, please verify your email address.</h6>
                                    </div>
                                    <div className='btn-log-in-to-mds'><Link className='btn-log' to={'/collaboratorverify?id=' + id}>Verify email</Link></div>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

export default CollaboratorMainPage