const custommodalpopup = false;

const ReduCustomModalPopupClose = (state = custommodalpopup, action) => {
  switch (action.type) {
    case "SETCUSTOMMODALPOPUPCLOSE":
      return true;
    case "SETSTAYONPAGE":
        return action.payload;
    case "REMOVECUSTOMMODALPOPUPCLOSE":
        return false;
    default:
      return state;
  }
};

export default ReduCustomModalPopupClose;