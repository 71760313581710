const chattingUser = [];

const ReduCurrentlyChatting = (state = chattingUser, action) => {
  switch (action.type) {
    case "SETCHATTINGUSERS":
      return action.payload;
    default:
      return state;
  }
};

export default ReduCurrentlyChatting;