import React from 'react'
import { useState, useEffect, useRef } from "react";
import Services from "../../../Services/auth.service";
import loading from "../../../Images/loader.gif";
import { invalid } from 'moment';
import { fetchmyaccount } from "../../../Action";

const AccountProfileSettings = () => {
   const [openDeactivateRequestDialog,setopenDeactivateRequestDialog] = useState(false)
  const [confirmAccountDeletion,setconfirmAccountDeletion] = useState(false)
  const [accountDeletion,setaccountDeletion] = useState(false)
  const [popuploader,setpopuploader] = useState(false)
  const [userdetail,setuserdetail] = useState({})
  const [loader,setloader] = useState(false)
  
  useEffect(()=>{
    if (localStorage.getItem("userid") !== undefined && localStorage.getItem("userid") !== null) 
    {
      const userid = localStorage.getItem("userid")
      Services.getuserbyid(userid).then(function (response) {
        if (response.data.status === true) {
          console.log(response.data.data.profile)
            setuserdetail(response.data.data.profile[0])
        };
      })
      .catch(function (err) {
        console.log(err);
      });
    }
    
  },[])
  const fnAccountDeletion = () =>{
    setloader(true)
    
    setTimeout(() => {
      setconfirmAccountDeletion(true);
      setopenDeactivateRequestDialog(false)  
      setloader(false)
    }, 1000);
  }

  

  useEffect(() => {
    if (confirmAccountDeletion)
    { 
      setpopuploader(true);
      Services.senddeactivaterequest()
      .then(function (response) {
        if (response.data.status === true) {
          setopenDeactivateRequestDialog(false)
          setaccountDeletion(true)
          setpopuploader(false)
        };
      })
      .catch(function (err) {
        console.log(err);
        setopenDeactivateRequestDialog(false)
        setaccountDeletion(false)
        setpopuploader(false)
      });
    }
  }, [confirmAccountDeletion]);


  return (
    <>
       <div className="all-content-main-edit-profile-main">
          <div className="cstm-per-inf-title">Account settings</div>

            <div class="cstm-to-delete-request">
            <div className='cstm-sub-title'>Deactivate account</div>  
            {" "}
            To request the deletion of all data associated with your account,{" "}
            <b className='cstm-click-here-link'>please<a onClick={()=>setopenDeactivateRequestDialog(true)}> click here.</a></b>
            {openDeactivateRequestDialog && (
          <div className="desk-globle-model-box globle-model-box ">
            <div className="desk-globle-model-box-inner  cstm-deactivate-account-only">
              {/* <button className='sctm-da-close' onClick={()=>setopenDeactivateRequestDialog(false)}>✖</button> */}

                {popuploader?(
              <div className="deactivate-popup-loading-image">
                <span className="cstm-pli-bg">
                  <img src={loading} />
                </span>
              </div>
            ):<></>}
                <div className="cstm-deactivate-account-main">
                  <div class="cstm-deactivate-account-main-title">
                  Are you sure you want to continue?
                  </div>

                  <div className="cstm-deactivate-account-main-content">
                  If you delete your account, you will lose all your data. This action can’t be undone.
                  </div>
                  <div className="confirm-account-deletion-btn">
                    <button className='deactivate-loader-container' onClick={(e)=>fnAccountDeletion(e)}> {loader ? (
                <div className="deactivate-loading-image">
              
                    <img src={loading} />
              
                </div>):<></>} Confirm account deletion</button>
                  </div>
                  <div className="ays-cancel-btn">
                    <button onClick={(e)=> setopenDeactivateRequestDialog(false)}>Cancel</button>
                  </div>
                </div>
              
            </div>{" "}
          </div>
        )}

        {accountDeletion && (
          <div className="desk-globle-model-box globle-model-box ">
            <div className="desk-globle-model-box-inner cstm-deactivate-account-only">
      
            <div className="cstm-deactivate-account-main">
                  <div class="cstm-deactivate-account-main-title">
                  Account deletion request
                  </div>
                  <div className="cstm-deactivate-account-main-content">
                  We will reach out to you shortly via your email address to complete the account deletion.
                    {/* We will reach out to you shortly via email address on your account <b>{userdetail.email}</b>  to complete deletion */}
                  </div>

                  <div className="ays-cancel-btn">
                    <button onClick={(e)=>{
                        e.preventDefault(); 
                        setaccountDeletion(false); 
                        setconfirmAccountDeletion(false)
                        }}>Cancel</button>
                  </div>
               
                </div>
           
            </div>{" "}
          </div>
        )}

          </div>
          </div>
    </>
  )
}

export default AccountProfileSettings
