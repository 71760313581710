import React, { useState } from "react";
import VideoSmallCard from "../../../CardsVideo/VideoSmallCard";
import videodummy from "../../../../Images/nothumbnail.png";
import { InView } from "react-intersection-observer";
import millify from "millify";
import moment from "moment";
import SaveIcon from "../../../Icons/GeneralIcons/SaveIcon";
import {
  useRemoveVideoHistoryMutation,
  useSaveVideoMutation,
} from "../../../../Services/videos";
import DeleteIcon from "../../../Icons/GeneralIcons/DeleteIcon";
import { useSelector } from "react-redux";
import { fetchsavedvideos } from "../../../../Action";
import { useDispatch } from "react-redux";
import VideoNoHistory from "../../../GlobalComponents/Icons/IconEmptyState/VideoNoHistory";
import IconSearchNoResults from "../../../GlobalComponents/Icons/IconEmptyState/IconSearchNoResults";
import ToastNotification from "../../../ToastNotification/ToastNotification";
import EmptyState from "../../../EmptyState/EmptyState";
import { useLocation, useNavigate } from "react-router-dom";

const HistoryListing = ({
  displayVideos = [],
  pageLoader,
  totalVideos,
  setPage,
  page,
  setDisplayHistoryVideos,
  setTotalVideos,
  noData,
  setListingCount,
  setNoData,
  handleClearFilter,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchedVideo = new URLSearchParams(search).get("search");
  const categoryId = new URLSearchParams(search).get("catid");
  const speakerId = new URLSearchParams(search).get("speaker");
  const dateFilter = new URLSearchParams(search).get("datefilter");
  const [saveVideo] = useSaveVideoMutation();
  const [removeFromHistory] = useRemoveVideoHistoryMutation();
  const [activeId, setActiveId] = useState(null);
  const [selectedHistoryId, setSelectedHistoryId] = useState(null);
  const [alreadySaved, setAlreadySaved] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [isRemoved, setRemoved] = useState(false);

  const savedvideos = useSelector((state) => {
    return state.ReduSavedVideos;
  });

  const actions = (videoId) => [
    {
      id: "save",
      name: `${
        savedvideos.map((video) => video._id).includes(videoId)
          ? "Remove from saved"
          : "Save"
      }`,
      icon: (
        <SaveIcon
          isFilled={savedvideos.map((video) => video._id).includes(videoId)}
        />
      ),
      callbackFunction: async () => {
        setActiveId(null);
        setSelectedHistoryId(null);
        const saveVideoRes = await saveVideo({ video_id: activeId });
        if (saveVideoRes.data) {
          setSaved(true);
          if (savedvideos.map((video) => video._id).includes(videoId)) {
            setAlreadySaved(true);
            setListingCount((prev) => ({ ...prev, saved: prev.saved - 1 }));
          } else {
            setListingCount((prev) => ({ ...prev, saved: prev.saved + 1 }));
          }
          dispatch(fetchsavedvideos());
          setTimeout(() => {
            setSaved(false);
            setAlreadySaved(false);
          }, 2000);
        }
      },
    },
    {
      id: "history",
      name: "Remove from History",
      icon: <DeleteIcon />,
      callbackFunction: async () => {
        setActiveId(null);
        setSelectedHistoryId(null);
        const removeHistoryRes = await removeFromHistory({
          params: { _id: selectedHistoryId },
        });
        if (removeHistoryRes.data && removeHistoryRes.data.status) {
          setRemoved(true);
          setDisplayHistoryVideos((prev) => {
            return prev.filter(
              (video) => video.video_history_id !== selectedHistoryId
            );
          });
          setTotalVideos((prev) => prev - 1);
          setListingCount((prev) => ({ ...prev, history: prev.history - 1 }));
          if (totalVideos === 1) {
            setNoData(true);
          }
          setTimeout(() => {
            setRemoved(false);
          }, 2000);
        }
      },
    },
  ];
  const handleActionClick = (id, historyId) => {
    setActiveId(activeId === id ? null : id);
    setSelectedHistoryId(selectedHistoryId === historyId ? null : historyId);
  };

  function handleSelect(selectedItem) {
    selectedItem.callbackFunction();
  }

  const handleMouseLeave = () => {
    setActiveId(null);
    setSelectedHistoryId(null);
  };

  return (
    <div className="videos-history-saved-grid">
      {pageLoader && page === 1
        ? [...Array(20)].map((ele) => (
            <div className={`video-small-wrapper position-relative`}>
              <div className={`video-small-card`}>
                <div className="video-small-thumb-img video-img-type-small position-relative skeleton-box rounded-2"></div>
                <div
                  className="video-small-details-wrapper"
                  style={{ width: "100%" }}
                >
                  <h6
                    className="video-small-card-title skeleton-box"
                    style={{ height: "24px", width: "100%" }}
                  ></h6>
                  <p
                    className="video-type-text skeleton-box"
                    style={{ height: "20px", width: "100%" }}
                  ></p>
                  <div className="video-details-view-date">
                    <div className="video-details-view-date">
                      <span
                        className="video-view-text skeleton-box"
                        style={{ height: "20px", width: "55px" }}
                      ></span>
                      <span
                        className="video-uploded-date"
                        style={{
                          height: "20px",
                          width: "16px",
                          flexShrink: "0",
                        }}
                      ></span>
                      <p
                        className="skeleton-box"
                        style={{ height: "20px", width: "72px" }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : displayVideos.length > 0 &&
          displayVideos.map((convideo, index) => {
            if (
              index === displayVideos.length - 1 &&
              index !== totalVideos - 1
            ) {
              return (
                <InView
                  onChange={(inView) => {
                    if (inView) setPage((prev) => prev + 1);
                  }}
                >
                  <>
                    <VideoSmallCard
                      handleActionClick={(id) =>
                        handleActionClick(id, convideo.video_history_id)
                      }
                      id={convideo._id}
                      index={index}
                      actions={actions(convideo._id)}
                      linkURL={`/videos/${convideo._id}`}
                      thumbnail={
                        convideo.thumbnail !== undefined &&
                        convideo.thumbnail !== null &&
                        convideo.thumbnail.length > 0
                          ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                            convideo.thumbnail
                          : videodummy
                      }
                      duration={
                        !convideo.duration || convideo.duration.includes("NaN")
                          ? "0:00"
                          : convideo.duration
                      }
                      name={convideo.title}
                      category={convideo.categories[0].name}
                      views={
                        convideo.viewsCount !== undefined
                          ? convideo.viewsCount > 1
                            ? `${millify(convideo.viewsCount)} Views`
                            : convideo.viewsCount === 1
                              ? "1 View"
                              : "0 Views"
                          : "0 Views"
                      }                      
                      uploaded={moment(convideo.createdAt).fromNow()}
                      handleSelect={handleSelect}
                      activeId={activeId}
                      handleMouseLeave={handleMouseLeave}
                    />
                  </>
                </InView>
              );
            } else {
              return (
                <>
                  <VideoSmallCard
                    handleActionClick={(id) =>
                      handleActionClick(id, convideo.video_history_id)
                    }
                    id={convideo._id}
                    index={index}
                    actions={actions(convideo._id)}
                    linkURL={`/videos/${convideo._id}`}
                    thumbnail={
                      convideo.thumbnail !== undefined &&
                      convideo.thumbnail !== null &&
                      convideo.thumbnail.length > 0
                        ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                          convideo.thumbnail
                        : videodummy
                    }
                    duration={
                      !convideo.duration || convideo.duration.includes("NaN")
                        ? "0:00"
                        : convideo.duration
                    }
                    name={convideo.title}
                    category={convideo.categories[0].name}
                    views={
                      convideo.viewsCount !== undefined
                        ? `${millify(convideo.viewsCount)} ${convideo.viewsCount === 1 ? "View" : "Views"}`
                        : "0 Views"
                    }                    
                    uploaded={moment(convideo.createdAt).fromNow()}
                    handleSelect={handleSelect}
                    activeId={activeId}
                    handleMouseLeave={handleMouseLeave}
                  />
                </>
              );
            }
          })}
      {pageLoader &&
        page > 1 &&
        [...Array(20)].map((ele) => (
          <div className={`video-small-wrapper position-relative`}>
            <div className={`video-small-card`}>
              <div className="video-small-thumb-img video-img-type-small position-relative skeleton-box rounded-2"></div>
              <div
                className="video-small-details-wrapper"
                style={{ width: "100%" }}
              >
                <h6
                  className="video-small-card-title skeleton-box"
                  style={{ height: "24px", width: "100%" }}
                ></h6>
                <p
                  className="video-type-text skeleton-box"
                  style={{ height: "20px", width: "100%" }}
                ></p>
                <div className="video-details-view-date">
                  <div className="video-details-view-date">
                    <span
                      className="video-view-text skeleton-box"
                      style={{ height: "20px", width: "55px" }}
                    ></span>
                    <span
                      className="video-uploded-date"
                      style={{
                        height: "20px",
                        width: "16px",
                        flexShrink: "0",
                      }}
                    ></span>
                    <p
                      className="skeleton-box"
                      style={{ height: "20px", width: "72px" }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {!pageLoader && displayVideos.length === 0 && noData && (
        <EmptyState
          extraClass="grid-full-row"
          label={
            searchedVideo || categoryId || speakerId || dateFilter
              ? "Clear all filters"
              : "Explore videos"
          }
          icon={
            searchedVideo || categoryId || speakerId || dateFilter ? (
              <IconSearchNoResults />
            ) : (
              <VideoNoHistory />
            )
          }
          hasTitle={searchedVideo || categoryId || speakerId || dateFilter}
          title={"Oops! No results this time!"}
          hasSubTitle
          subTitle={
            searchedVideo || categoryId || speakerId || dateFilter
              ? "Please adjust your searching filters and give it another go!"
              : "No watched videos yet, but they're just a click away. Start watching now!"
          }
          isClear={true}
          onClick={() => {
            handleClearFilter();
            navigate(
              `/videos?type=${
                searchedVideo || categoryId || speakerId || dateFilter
                  ? "history"
                  : "all"
              }&filter=recent`
            );
          }}
        />
      )}
      {isSaved && (
        <div className="toast-wrapper bottom left" style={{ width: "400px" }}>
          <ToastNotification
            variant="neutral"
            size="medium"
            hasIcon={false}
            text={alreadySaved ? "Removed from Saved" : "Video saved"}
            hasAction={!alreadySaved}
            hasCross={false}
            actionText="View list"
            actionUrl="/videos?type=saved&filter=recent"
          />
        </div>
      )}
      {isRemoved && (
        <div className="toast-wrapper bottom left" style={{ width: "400px" }}>
          <ToastNotification
            variant="neutral"
            size="medium"
            hasIcon={false}
            text={"Video history removed"}
            hasAction={false}
            hasCross={false}
          />
        </div>
      )}
    </div>
  );
};

export default HistoryListing;
