import React, { useEffect, useState } from "react";
import Loader from "../../../Images/loader.gif";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import TableBodyComponentModalPagination from "../../AdminCommonComponent/TableComponent/TableBodyComponentModalPagination";
import { useAddonsByEventIdQuery } from "../../../Services/addons";
import { useLocation } from "react-router-dom";
import DeleteAddons from "./DeleteAddons";

const columns = [
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
  // { id: "price", label: "Price" },
  { id: "status", label: "Visibility status" },
  { id: "visibilityDate", label: "Visibility start and end date" },
  { id: "saleEndDate", label: "Sales start and end date" },
  { id: "totalAddons", label: "Quantity per order" },
  { id: "gross", label: "Gross" },
  { id: "createdAt", label: "Created on " },
  { id: "updatedAt", label: "Updated on" },
  { id: "action", label: "Actions" },
];

function createData(
  name,
  description,
  price,
  status,
  visibilityDate,
  saleEndDate,
  totalAddons,
  gross,
  createdAt,
  updatedAt,
  action
) {
  return {
    name,
    description,
    price,
    status,
    visibilityDate,
    saleEndDate,
    totalAddons,
    gross,
    createdAt,
    updatedAt,
    action,
  };
}

const ListAddons = ({
  guestTicketRedirect,
  handleEditId,
  finalSearchedText,
  selectedType,
  visibilityType,
  filterType,
  fromDateIsoString,
  toDateIsoString,
}) => {
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [allEvents, setAllEvents] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [addonRedirectId, setaAddonRedirectId] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("id");

  //get all the guest ticket api hook
  const { data: rowsList, isFetching: AddonsLoader } = useAddonsByEventIdQuery({
    event_id: eventId,
    params: {
      page: page + 1,
      limit: rowsPerPage,
      search: finalSearchedText && finalSearchedText,
      type: selectedType && selectedType.toUpperCase(),
      sortField: sortField && sortField,
      sortType: sortType && sortType,
      visibility: visibilityType && visibilityType.toUpperCase(),
      filterType: filterType && filterType,
      reqFromDate:
        fromDateIsoString && filterType === "custom" && fromDateIsoString,
      reqToDate: toDateIsoString && filterType === "custom" && toDateIsoString,
    },
  });

  useEffect(() => {
    getAddonsList();
  }, [rowsList]);

  //get all the addons
  const getAddonsList = () => {
    if (rowsList?.status) {
      let data = rowsList?.data;
      let table = [];
      for (let i = 0; i < data.length; i++) {
        const currentUpdatedAt = new Date(data[i].updatedAt);
        // Format the date using Intl.DateTimeFormat
        const updatedAtformattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(currentUpdatedAt);

        const currentCreatedAt = new Date(data[i].createdAt);
        // Format the date using Intl.DateTimeFormat
        const createdAtformattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(currentCreatedAt);

        //convert to formatted date
        const dateConvertor = (date) => {
          let inputDate = new Date(date);
          let hours = inputDate.getHours();
          let minutes = inputDate.getMinutes();
          let timeFormat = hours >= 12 ? "P.M." : "A.M.";
          hours = hours % 12 || 12;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          let formattedTime = hours + ":" + minutes + " " + timeFormat;
          let options = { year: "numeric", month: "long", day: "numeric" };
          let formattedDate = inputDate.toLocaleDateString("en-US", options);
          return { formattedDate, formattedTime };
        };

        //converting first letter of priceType to capital
        const priceType =
          data[i].type?.toLowerCase().charAt(0).toUpperCase() +
          data[i].type?.slice(1).toLowerCase();

        const status =
          data[i].visibility.toLowerCase().charAt(0).toUpperCase() +
          data[i].visibility.slice(1).toLowerCase();

        table[i] = createData(
          data[i].name,
          data[i].details,
          data[i].price,
          status,
          status === "Custom" ? (
            <span>
              Start date -
              {dateConvertor(data[i].visibilityStartDate).formattedDate}{" "}
              {dateConvertor(data[i].visibilityStartDate).formattedTime} <br />
              End date -{
                dateConvertor(data[i].visibilityEndDate).formattedDate
              }{" "}
              {dateConvertor(data[i].visibilityEndDate).formattedTime}
            </span>
          ) : (
            "-"
          ),
          priceType === "Paid" ? (
            <span>
              Start date - {dateConvertor(data[i].saleStart).formattedDate}{" "}
              {dateConvertor(data[i].saleStart).formattedTime} <br />
              End date - {dateConvertor(data[i].saleEnd).formattedDate}{" "}
              {dateConvertor(data[i].saleEnd).formattedTime}
            </span>
          ) : (
            "-"
          ),
          data[i].quantity,
          data[i].gross,
          createdAtformattedDate,
          updatedAtformattedDate,
          data[i]._id
        );
      }
      setAllEvents(table);
      setTotalCount(rowsList?.totalAddons);
      setRow(table);
    }
  };

  //handle sorting
  const sortingHandler = (col, direc) => {
    setSortType(direc === "Dec" ? "Dsc" : "Asc");
    setSortField(
      col === "description"
        ? "details"
        : col === "priceType"
        ? "type"
        : col === "status"
        ? "visibility"
        : col
    );
  };

  //handling pagination when we move to next page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //handling number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      {showAlert && <div className="alert alert-info">{alertMessage}</div>}
      <Paper className="uic-tb-mn">
        <TableContainer className="uic-table-main">
          <Table className="uic-table">
            <TableHeader
              columns={columns}
              allEventList={allEvents}
              columnsWithoutSoritng={[
                "saleEndDate",
                "visibilityDate",
                "totalAddons",
                "action",
                "gross",
                "updatedAt",
              ]}
              sortingHandler={sortingHandler}
              setLoader={setLoader}
              searchText={""}
            />
            {AddonsLoader ? (
              <div className="cstm-data-load">
                <img src={Loader} />{" "}
              </div>
            ) : rows.length > 0 ? (
              <TableBodyComponentModalPagination
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={page}
                columns={columns}
                editCallBack={(value) => {
                  handleEditId(value);
                }}
                setDeleteId={(id) => {
                  setDeleteId(id);
                  setaAddonRedirectId(id);
                  setShowDeleteModal(true);
                }}
                editModalId="editModalAddons"
                deleteModalId=""
              />
            ) : (
              <span className="no-data-found">No data found</span>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          className="uic-pagination"
          rowsPerPageOptions={[20, 50, 100, 200]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          count={totalCount}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Modal Delete */}
      {showDeleteModal && (
        <DeleteAddons
          eventId={eventId}
          addonRedirectId={addonRedirectId}
          deleteId={deleteId}
          rows={rows}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setDeleteId={setDeleteId}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          guestTicketRedirect={guestTicketRedirect}
        />
      )}
    </>
  );
};

export default ListAddons;
