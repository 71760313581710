import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { EditorState, convertToRaw, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import Services from "../../../Services/auth.service";
import authServiceEvent from "../../../Services/auth.service.event";
import authHeaderChannel from "../../../Services/auth.header.channel";
import defaultTimezones from "../timeZone.json";
import Frame from "../../../Images/Frame.svg";
import undo from "../../../Images/undo.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-places-autocomplete";
import AlertComponent from "../../../Hooks/AlertComponent";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import { useDropzone } from "react-dropzone";
import CreateCategory from "../Modals/CreateCategory";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";
import AddRules from "../../CommonRules/AddRules";
// import ImageCropper from "../../ImageCropper/ImageCropper";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";
import Select from "react-select";
import { useUserCountMutation } from "../../../Services/ruleUserCount";


const CreateEvent = () => {
  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const descriptionRef = useRef(null);
  const longDescriptionRef = useRef(null);
  const latestRequest = useRef(0);

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });

  const [saveInProcess, setSaveInProcess] = useState(false);
  const [locationImageUrls, setLocationImageUrls] = useState([]);
  const [locationImageLoader, setLocationImageLoader] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [createInprocess, setcreateInprocess] = useState(false);
  const [showalertAdd, setshowalertAdd] = useState(false);
  const [alertmsgAdd, setalertmsgAdd] = useState("");
  const [addtag, setaddtag] = useState("");
  const [adderr, setadderr] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [ruleError, setRuleError] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUserList, setTotalUserList] = useState([]);
  const [resetRulesFields, setResetRulesFields] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [badgeList, setBadgeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [eventDetail, setEventDetail] = useState({
    tag: [],
    title: "",
    shortDescription: "",
    longDescription: "",
    url: "",
    type: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    eventOccurs: "",
    weekDayOccurOption: "",
    endAts: "",
    noOfeventOccurs: "",
    restrictedAccessGroups: [],
    restrictedAccessMemberships: [],
    preRegisterBtnLink: "",
    preRegisterBtnTitle: "",
    preRegisterDescription: "",
    preRegisterEndDate: "",
    preRegisterStartDate: "",
    preRegisterTitle: "",
    preRegisterStartTime: "",
    preRegisterEndTime: "",
    preRegisterCompulsory: false,
    isLocation: true,
    address: "",
    city: "",
    country: "",
    latitude: "",
    longitude: "",
    postalcode: "",
    airTableEventName: "",
    partnerType: "",
    status: "draft",
    category: [],
    subCategory: [],
    locationType: "inPerson",
    onlineUrl: "",
    toBeAnnounceedDescription: "",
    autoTimezone: "",
  });
  const [eventDetailErr, setEventDetailErr] = useState({
    title: "",
    url: "",
    timeZone: "",
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    eventOccurs: "",
    weekDayOccurOption: "",
    endAts: "",
    noOfeventOccurs: "",
    thumbnail: "",
    shortDescription: "",
    longDescription: "",
    type: "",
    preRegisterBtnLink: "",
    preRegisterBtnTitle: "",
    preRegisterDescription: "",
    preRegisterEndDate: "",
    preRegisterStartDate: "",
    preRegisterTitle: "",
    preRegisterStartTime: "",
    preRegisterEndTime: "",
    preRegisterCompulsory: "",
    isLocation: "",
    address: "",
    city: "",
    country: "",
    latitude: "",
    longitude: "",
    postalcode: "",
    locationImages: "",
    airTableEventName: "",
    status: "",
    locationType: "",
    onlineUrl: "",
    toBeAnnounceedDescription: "",
    category: "",
  });
  const [inProcess, setInProcess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [allGroupList, setAllGroupList] = useState([]);
  const [allMemberShipList, setAllMemberShipList] = useState([]);
  const [symbolCompulsory, setSymbolCompulsory] = useState("");
  const [preRegisterCompulsory, setPreRegisterCompulsory] = useState(false);
  const [address, setAddress] = useState("");
  const [showSuggestion, setshowSuggestion] = useState(false);
  const [placeId, setPlaceId] = useState("");
  const [symbolCompulsoryLocation, setSymbolCompulsoryLocation] = useState("*");
  const [isLocationCompulsory, setLocationCompulsory] = useState(true);
  const [isTimezoneManually, setIsTimezoneManually] = useState(false);
  const regex = /^[^0-9]+$/;
  const [imgFileList, setImgFileList] = useState([]);
  // Event Recurring State
  const [isEventRecurring, setIsEventRecurring] = useState(false);
  const [eventRecurringOption, setEventRecurringOption] = useState("");
  const [eventRecurringEndAt, setEventRecurringEndAt] = useState("");

  //  image cropping state
  const [modalOpen, setModalOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState([]);
  const [fileDimension, setFileDimension] = useState();
  const [fileName, setFileName] = useState("");
  const [cropedFile, setcropedFile] = useState();
  const [cropedFileData, setcropedFileData] = useState();
  const [requiredImageSize, setRequiredImageSize] = useState({
    width: 1500,
    height: 846,
  });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(
    16 / 9
  );
  const [isCheckInAllowed, setIsCheckInAllowed] = useState(false);


  const [users, setUsers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [nonUsers, setNonUsers] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState([]);
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

  const dayList = [
    { label: "Sun", value: "SUN" },
    { label: "Mon", value: "MON" },
    { label: "Tue", value: "TUE" },
    { label: "Wed", value: "WED" },
    { label: "Thu", value: "THU" },
    { label: "Fri", value: "FRI" },
    { label: "Sat", value: "SAT" },
  ];

  //User count mutation
  const [userCountApi] = useUserCountMutation();

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };


  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ? "public" : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  useEffect(() => {
    handleClearAllStates();

    Services.getallgroup()
      .then((res) => {
        if (res.data.status) {
          setAllGroupList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getPlanService()
      .then((res) => {
        if (res.data.status) {
          setAllMemberShipList(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    getTagList();
    getCategoriesListApi();
    getChannelList();
  }, []);

  async function getChannelList() {
    try {
      const channelListRes = await authHeaderChannel.getAllChannelList("");
      if (channelListRes.data.status) {
        const channelList = channelListRes.data.data;
        let arr = [
          {
            value: "",
            label: "Select channel",
          },
        ];
        if (channelList !== undefined && channelList.length > 0) {
          channelList.map((channel) => {
            arr.push({
              value: channel._id,
              label: channel.channelName,
            });
          });
          setBadgeList(arr);
        } else {
          setBadgeList([]);
        }
      }
    } catch (error) { }
  }

  async function getTagList() {
    try {
      const tagListRes = await Services.getalltags_byadmin("");
      if (tagListRes.data.status) {
        setTagList(tagListRes.data.data);
      }
    } catch (error) {
      return false;
    }
  }

  const onhandleBadgeChange = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setEventDetail({ ...eventDetail, partnerType: selectedOption });
  };

  const getCategoriesListApi = async () => {
    let query = "?";
    query += `page=${1}`;
    query += `&limit=${1000}`;

    try {
      const getCategoriesRes = await authServiceEvent.getCategoriesList(query);

      if (getCategoriesRes.data.status) {
        var data = getCategoriesRes.data.data.event;
        setCategoryData(data);
      } else {
      }
    } catch (error) { }
  };

  // handle event detail changes
  const handleEventDetailChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });
    if (
      e.target.name === "title" ||
      e.target.name === "status" ||
      e.target.name === "shortDescription" ||
      e.target.name === "timeZone" ||
      e.target.name === "startDate" ||
      e.target.name === "startTime" ||
      e.target.name === "endDate" ||
      e.target.name === "endTime"
    ) {
      if (e.target.value.length === 0)
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]:
            e.target.name === "title"
              ? "Enter title"
              : e.target.name === "status"
                ? "Enter status"
                : e.target.name === "shortDescription"
                  ? "Enter short description"
                  : e.target.name === "timeZone"
                    ? "Select time zone"
                    : e.target.name === "startDate"
                      ? "Select start date"
                      : e.target.name === "startTime"
                        ? "Select start time"
                        : e.target.name === "endDate"
                          ? "Select end date"
                          : e.target.name === "endTime"
                            ? "Select end time"
                            : "Select end time",
        });
      else {
        if (e.target.name === "title") {
          if (e.target.value.indexOf(",") > -1) {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: 'Special character comma "," is not allowed!',
            });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "",
            });
          }
          // setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });
        } else if (e.target.name === "endDate" && eventDetail.startDate) {
          if (Date.parse(e.target.value) < Date.parse(eventDetail.startDate))
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Must be greater then start date",
            });
          else setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        } else {
          setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        }
      }
    }

    if (e.target.name === "toBeAnnounceedDescription") {
      if (e.target.value.trim().length === 0) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter description",
        });
      } else {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "",
        });
      }
    }
    if (isEventRecurring) {
      if (
        e.target.name === "weekDayOccurOption" &&
        eventRecurringOption === "WEEKLY" &&
        e.target.value.length === 0
      ) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Select On these days",
        });
      } else if (
        e.target.name === "noOfeventOccurs" &&
        e.target.value.length === 0
      ) {
        const inputValue = e.target.value;
        if (isNaN(inputValue) || inputValue === "") {
          // Check if input is not a number or empty
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Select days of event occurs",
          });
        } else {
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "", // Clear error message if input is valid
          });
        }
      } else {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "", // Clear error message if conditions are not met
        });
      }
    }

    if (eventDetail.isLocation) {
      if (eventDetail.locationType === "inPerson") {
        if (e.target.name === "city") {
          if (e.target.value.trim().length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Enter city",
            });
          }
        } else if (e.target.name === "country") {
          if (e.target.value.trim().length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Enter country",
            });
          }
        } else if (e.target.name === "latitude") {
          if (e.target.value.length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Enter latitude",
            });
          }
        } else if (e.target.name === "longitude") {
          if (e.target.value.length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
          } else {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "Enter longitude",
            });
          }
        }
        // else if (e.target.name === "url") {
        //   if (e.target.value.length > 0) {
        //     setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        //   } else {
        //     setEventDetailErr({
        //       ...eventDetailErr,
        //       [e.target.name]: "Enter URL",
        //     });
        //   }
        // }
        if (e.target.name === "url") {
          let url;
          try {
            url = new URL(e.target.value);
          } catch (error) {
            if (e.target.value.length > 0) {
              setEventDetailErr({
                ...eventDetailErr,
                [e.target.name]: "Invalid url",
              });
            } else {
              setEventDetailErr({
                ...eventDetailErr,
                [e.target.name]: "",
              });
            }
          }
          if (url && (url.protocol === "http:" || url.protocol === "https:")) {
            setEventDetailErr({
              ...eventDetailErr,
              [e.target.name]: "",
              status: "",
            });
          }
        }
      }

      if (eventDetail.locationType === "virtual") {
        if (e.target.name === "virtuaUrl") {
          if (e.target.value.trim().length > 0) {
            setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
            let url;
            try {
              url = new URL(e.target.value);
            } catch (error) {
              setEventDetailErr({
                ...eventDetailErr,
                [e.target.name]: "Invalid url",
              });
              // setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });
            }
            if (
              url &&
              (url.protocol === "http:" || url.protocol === "https:")
            ) {
              setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
            }
          }
        }
      }
      // if (eventDetail.locationType === "to_be_announce") {
      //   if (e.target.name === "toBeAnnounceedDescription") {
      //     if (e.target.value.trim().length > 0) {
      //       setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
      //     } else {
      //       setEventDetailErr({
      //         ...eventDetailErr,
      //         [e.target.name]: "Enter description",
      //       });
      //     }
      //   }
      // }
    }

    if (eventDetail.preRegisterCompulsory) {
      if (e.target.name === "preRegisterTitle" && e.target.value.length === 0) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter title",
        });
      }

      if (
        e.target.name === "preRegisterDescription" &&
        e.target.value.length === 0
      ) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter description",
        });
      }

      if (
        e.target.name === "preRegisterBtnTitle" &&
        e.target.value.length === 0
      ) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter button title",
        });
      }

      if (
        e.target.name === "preRegisterBtnLink" &&
        e.target.value.length === 0
      ) {
        setEventDetailErr({
          ...eventDetailErr,
          [e.target.name]: "Enter button link",
        });
      } else if (
        e.target.name === "preRegisterBtnLink" &&
        e.target.value.length === 0
      ) {
        let url;
        try {
          url = new URL(e.target.value);
        } catch (error) {
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Invalid url",
          });
          // setEventDetail({ ...eventDetail, [e.target.name]: e.target.value });
        }
        if (url && (url.protocol === "http:" || url.protocol === "https:")) {
          setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        }
      }

      if (
        e.target.name === "preRegisterEndDate" &&
        eventDetail.preRegisterStartDate
      ) {
        if (
          moment(e.target.value).isBefore(
            moment(eventDetail.preRegisterStartDate)
          )
        )
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Must be greater then start date",
          });
        // else if (moment(e.target.value).isAfter(moment(eventDetail.startDate)))
        //   setEventDetailErr({
        //     ...eventDetailErr,
        //     [e.target.name]: "Must be less then event start date",
        //   });
        else setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
      } else {
        setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
      }

      if (
        e.target.name === "preRegisterStartDate" &&
        e.target.value.length > 0
      ) {
        // if (moment(e.target.value, 'YYYY-MM-DD').isAfter(moment(eventDetail.startDate, 'YYYY-MM-DD'))) {
        //   setEventDetailErr({
        //     ...eventDetailErr,
        //     [e.target.name]: "Must be less then event start date!",
        //   });
        // } else
        // if (moment(e.target.value, 'YYYY-MM-DD').isBefore(moment(moment(new Date().toDateString()), 'YYYY-MM-DD'))) {
        //   setEventDetailErr({
        //     ...eventDetailErr,
        //     [e.target.name]: "Must be greater then today's date!",
        //   });
        // } else {
        //   setEventDetailErr({
        //     ...eventDetailErr,
        //     [e.target.name]: "",
        //   });
        // }
      }

      if (
        e.target.name === "preRegisterStartTime" &&
        eventDetail.preRegisterStartDate.length > 0
      ) {
        // if ((moment(eventDetail.preRegisterStartDate, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD')))
        //   && (moment(e.target.value, 'hh:mm').isBefore(moment((new Date()), 'hh:mm')))) {
        //   setEventDetailErr({
        //     ...eventDetailErr,
        //     [e.target.name]: "Must be greater or equal to current time",
        //   });
        // }
        // else if ((moment(eventDetail.preRegisterStartDate, 'YYYY-MM-DD').isSame(moment(eventDetail.startDate, 'YYYY-MM-DD')))
        //   && (moment(e.target.value, "HH:mm").isAfter((moment(eventDetail.startTime, 'HH:mm'))))
        // ) {
        //   setEventDetailErr({
        //     ...eventDetailErr,
        //     [e.target.name]: "Must be less then event start time",
        //   });
        // }
        // else {
        //   setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        // }
      }

      if (
        e.target.name === "preRegisterEndTime" &&
        eventDetail.preRegisterStartDate.length > 0 &&
        eventDetail.preRegisterEndDate.length > 0 &&
        eventDetail.preRegisterStartTime.length > 0
      ) {
        if (
          Date.parse(eventDetail.preRegisterEndDate) ===
          Date.parse(eventDetail.preRegisterStartDate) &&
          (moment(eventDetail.preRegisterStartTime, "hh:mm").isAfter(
            moment(e.target.value, "hh:mm")
          ) ||
            moment(eventDetail.preRegisterStartTime, "hh:mm").isSame(
              moment(e.target.value, "hh:mm")
            ))
        ) {
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Must be greater then start time",
          });
        } else if (
          e.target.name === "preRegisterEndTime" &&
          moment(eventDetail.preRegisterStartTime, "hh:mm").isSame(
            moment(e.target.value, "hh:mm")
          )
        ) {
          setEventDetailErr({
            ...eventDetailErr,
            [e.target.name]: "Must be less then end time",
          });
        } else {
          setEventDetailErr({ ...eventDetailErr, [e.target.name]: "" });
        }
      }
    }
  };
  // base64 to image file conversation
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], Math.random().toString() + ".png", { type: mime });
  };
  // handling editor state to html state and uploading images to server
  const handleDesciption = async (state) => {
    return new Promise((resolve, reject) => {
      const contentState = state.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      if (
        contentRaw?.entityMap &&
        Object.keys(contentRaw.entityMap).length > 0
      ) {
        var entityMap = contentRaw.entityMap;
        var formdata = new FormData();
        var uploadStatus = false;
        var keyList = [];

        for (var key in entityMap) {
          var data = entityMap[key];
          if (
            data.type === "IMAGE" &&
            data.data.src.startsWith("data:image/")
          ) {
            uploadStatus = true;
            var fileData = dataURLtoFile(data.data.src);
            formdata.append(`image`, fileData);
            keyList = [...keyList, key];
          }
        }
        if (uploadStatus) {
          authServiceEvent
            .eventUploadFiles(formdata)
            .then((res) => {
              if (res.data.status) {
                var mediaData = res.data.media;
                mediaData.map((m, index) => {
                  contentRaw.entityMap[keyList[index]].data.src = m.key;
                });
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              } else {
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              }
            })
            .catch((e) => {
              console.log(e);
              const htmlContent = draftToHtml(contentRaw);
              resolve(htmlContent);
            });
        } else {
          const htmlContent = draftToHtml(contentRaw);
          resolve(htmlContent);
        }
      } else {
        const htmlContent = draftToHtml(contentRaw);
        resolve(htmlContent);
      }
    });
  };
  // handle upload image in editor
  const uploadImageCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  //Create session validation and api call
  // const checkDesciptionValue = (state) => {
  //   var text = descriptionRef?.current.getContent();
  //   if (text.length > 0 && text !== "") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  //Create session validation and api call
  const checkLongDesciptionValue = (state) => {
    if (longDescriptionRef.current) {
      var text = longDescriptionRef.current.getContent();
    }
    if (text?.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  //close create session module
  const handleCloseAddModel = () => {
    setResetRulesFields(true);
    setTimeout(() => {
      setResetRulesFields(false);
    }, 2000);
  };
  // create event api function
  const createEventApi = async () => {
    let imageCompress;
    let longDescriptionFinalData;
    if (longDescriptionRef.current) {
      longDescriptionFinalData = longDescriptionRef.current.getContent();
    }
    const formDataCreateEvent = new FormData();

    if (eventDetail.tag.length > 0) {
      eventDetail.tag.map((tag) => formDataCreateEvent.append("tag", tag._id));
    }
    if (eventDetail.title)
      formDataCreateEvent.append("title", eventDetail.title);
    if (eventDetail.status)
      formDataCreateEvent.append("status", eventDetail.status);
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formDataCreateEvent.append("thumbnail", imageCompress);
    if (eventDetail.shortDescription)
      formDataCreateEvent.append(
        "shortDescription",
        eventDetail.shortDescription
      );
    if (longDescriptionRef.current !== null && longDescriptionFinalData)
      formDataCreateEvent.append("longDescription", longDescriptionFinalData);
    // if (eventDetail.url)
    eventDetail.url !== undefined &&
      eventDetail.url !== null &&
      formDataCreateEvent.append("eventUrl", eventDetail.url);

    if (isTimezoneManually) {
      eventDetail.timeZone &&
        eventDetail.timeZone.length > 0 &&
        formDataCreateEvent.append("timeZone", eventDetail.timeZone);
    } else {
      eventDetail.autoTimezone &&
        eventDetail.autoTimezone.length > 0 &&
        formDataCreateEvent.append("timeZone", eventDetail.autoTimezone);
    }
    if (eventDetail.startDate)
      formDataCreateEvent.append(
        "startDate",
        moment(eventDetail.startDate).format("MM-DD-YYYY")
      );
    if (eventDetail.startTime)
      formDataCreateEvent.append(
        "startTime",
        moment(eventDetail.startTime, ["HH:mm"]).format("hh:mm a")
      );
    if (eventDetail.endDate)
      formDataCreateEvent.append(
        "endDate",
        moment(eventDetail.endDate).format("MM-DD-YYYY")
      );
    if (eventDetail.endTime)
      formDataCreateEvent.append(
        "endTime",
        moment(eventDetail.endTime, ["HH:mm"]).format("hh:mm a")
      );

    isEventRecurring !== undefined &&
      isEventRecurring !== null &&
      formDataCreateEvent.append("isRecurring", isEventRecurring);

    if (isEventRecurring) {
      /// event recurring
      const recurrenceDetails = {
        eventStartDate: moment(eventDetail.startDate).format("YYYY-MM-DD"),
        eventStartTime: moment(eventDetail.startTime, ["HH:mm"]).format(
          "hh:mm a"
        ),
        eventEndTime: moment(eventDetail.endTime, ["HH:mm"]).format("hh:mm a"),
        pattern: eventDetail.eventOccurs,
        weekDayOccurOption: eventDetail.weekDayOccurOption,
        isNoOfRecurrence: eventRecurringEndAt === "noOfEvents" ? true : false,
        noOfRecurrence: eventDetail.noOfeventOccurs,
        endDateOfRecurrence: moment(eventDetail.endDate).format("YYYY-MM-DD"),
      };
      formDataCreateEvent.append(
        "recurrenceDetails",
        JSON.stringify(recurrenceDetails)
      );
    }

    if (eventDetail.category && eventDetail.category.length > 0) {
      eventDetail.category.map((cat) =>
        formDataCreateEvent.append("category", cat._id)
      );
    }
    if (subcategories && subcategories.length > 0) {
      subcategories.map((item) =>
        formDataCreateEvent.append("subcategory", item)
      );
    }
    if (eventDetail.partnerType.value) {
      formDataCreateEvent.append("chatChannel", eventDetail.partnerType.value);
    }
    eventDetail.isLocation !== undefined &&
      eventDetail.isLocation !== null &&
      formDataCreateEvent.append("isLocation", eventDetail.isLocation);
    isTimezoneManually !== undefined &&
      isTimezoneManually !== null &&
      formDataCreateEvent.append("isTimeZoneAutomatic", !isTimezoneManually);
    if (eventDetail.isLocation) {
      if (eventDetail.locationType) {
        formDataCreateEvent.append(
          `locationType`,
          eventDetail.locationType === "virtual"
            ? "virtual"
            : eventDetail.locationType === "inPerson"
              ? "inPerson"
              : eventDetail.locationType
        );
      }
      if (eventDetail.locationType && eventDetail.locationType === "inPerson") {
        if (eventDetail.address && eventDetail.address.length > 0) {
          const locationData = {
            address: eventDetail.address,
            latitude: eventDetail.latitude,
            longitude: eventDetail.longitude,
            postalCode: eventDetail.postalcode,
            city: eventDetail.city,
            country: eventDetail.country,
            placeId: placeId,
            locationImages: locationImageUrls,
          };
          formDataCreateEvent.append("location", JSON.stringify(locationData));
        }
      }
      if (eventDetail.locationType && eventDetail.locationType === "virtual") {
        if (eventDetail.onlineUrl) {
          formDataCreateEvent.append("onlineEventUrl", eventDetail.onlineUrl);
        }
      }
      if (
        eventDetail.locationType &&
        eventDetail.locationType === "to_be_announce"
      ) {
        if (eventDetail.toBeAnnounceedDescription) {
          formDataCreateEvent.append(
            "toBeAnnouceDetail",
            eventDetail.toBeAnnounceedDescription
          );
        }
      }
    } else {
    }

    if (eventDetail.preRegisterCompulsory) {
      if (eventDetail.preRegisterTitle)
        formDataCreateEvent.append(
          "preRegisterTitle",
          eventDetail.preRegisterTitle
        );

      if (eventDetail.preRegisterDescription)
        formDataCreateEvent.append(
          "preRegisterDescription",
          eventDetail.preRegisterDescription
        );

      if (eventDetail.preRegisterBtnTitle)
        formDataCreateEvent.append(
          "preRegisterBtnTitle",
          eventDetail.preRegisterBtnTitle
        );

      if (eventDetail.preRegisterBtnLink)
        formDataCreateEvent.append(
          "preRegisterBtnLink",
          eventDetail.preRegisterBtnLink
        );

      if (eventDetail.preRegisterCompulsory)
        formDataCreateEvent.append(
          "isPreRegister",
          eventDetail.preRegisterCompulsory
        );

      if (eventDetail.preRegisterStartDate && eventDetail.preRegisterStartTime)
        formDataCreateEvent.append(
          "preRegisterStartDate",
          moment(
            eventDetail.preRegisterStartDate +
            " " +
            eventDetail.preRegisterStartTime
          ).format()
        );

      if (eventDetail.preRegisterEndDate && eventDetail.preRegisterEndTime)
        formDataCreateEvent.append(
          "preRegisterEndDate",
          moment(
            eventDetail.preRegisterEndDate +
            " " +
            eventDetail.preRegisterEndTime
          ).format()
        );
    } else {
    }

    eventDetail.airTableEventName &&
      formDataCreateEvent.append(
        "airTableEventName",
        eventDetail.airTableEventName
      );

    //Data for rules
    dataFromRules?.userAccess.length !== 0 &&
      formDataCreateEvent.append(
        `restrictionAccess`,
        dataFromRules?.userAccess === "all" ? "public" : "restricted"
      );
    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formDataCreateEvent.append(`restrictedAccessGroupId[]`, id);
        });
    }
    if (dataFromRules?.tags_id) {
      dataFromRules?.tags_id?.length > 0 &&
        dataFromRules?.tags_id?.map((id) => {
          formDataCreateEvent.append(`restrictedAccessTagId[]`, id);
        });
    }
    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formDataCreateEvent.append(`restrictedAccessMembershipPlanId[]`, id);
        });
    }
    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formDataCreateEvent.append(`restrictedAccessUserId[]`, id);
      });
    if (isCheckInAllowed !== undefined) {
      formDataCreateEvent.append("isCheckInAllow", isCheckInAllowed);
    }

    //return;
    authServiceEvent
      .createEvent(formDataCreateEvent)
      .then((response) => {
        setInProcess(false);
        setSaveInProcess(false);
        setResponseMessage(response.data.message);
        setTimeout(() => {
          setResponseMessage("");
        }, 3000);
        if (response.data.status) {
          setEventDetail({
            ...eventDetail,
            shortDescription: "",
            longDescription: "",
          });
          setEventDetail({
            title: "",
            // shortDescription: "",
            // longDescription: "",
            url: "",
            type: "",
            timeZone: "",
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            restrictedAccessGroups: [],
            restrictedAccessMemberships: [],
            preRegisterBtnLink: "",
            preRegisterBtnTitle: "",
            preRegisterDescription: "",
            preRegisterEndDate: "",
            preRegisterStartDate: "",
            preRegisterTitle: "",
            preRegisterStartTime: "",
            preRegisterEndTime: "",
            preRegisterCompulsory: false,
            isLocation: false,
            address: "",
            city: "",
            country: "",
            latitude: "",
            longitude: "",
            postalcode: "",
            airTableEventName: "",
            tag: [],
            category: [],
          });
          setThumbnail([]);
          setEventDetailErr({
            title: "",
            url: "",
            timeZone: "",
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            thumbnail: "",
            shortDescription: "",
            longDescription: "",
            preRegisterBtnLink: "",
            preRegisterBtnTitle: "",
            preRegisterDescription: "",
            preRegisterEndDate: "",
            preRegisterStartDate: "",
            preRegisterTitle: "",
            preRegisterStartTime: "",
            preRegisterEndTime: "",
            preRegisterCompulsory: "",
            isLocation: "",
            address: "",
            city: "",
            country: "",
            latitude: "",
            longitude: "",
            postalcode: "",
            airTableEventName: "",
          });
          handleClearAllStates();
          handleCloseAddModel();
          navigate(`/events/detail?id=${response.data.data[0]._id}`);
        }
      })
      .catch((e) => {
        setInProcess(false);
        setSaveInProcess(false);
        setResponseMessage(e);
        setTimeout(() => {
          setResponseMessage("");
        }, 3000);
        console.log(e);
      });
  };

  // handel save draft event
  const handleSaveDraft = () => {
    setSaveInProcess(true);
    createEventApi();
  };

  // called when click on create event button
  const handleCreateEvent = async (e) => {
    e.preventDefault();
    var validatedData = true;
    var addressErr = true;
    var eventDetailErrObj = { ...eventDetailErr };
    for (let eventDetailKey in eventDetailErr) {
      if (validatedData && eventDetailErr[eventDetailKey].length > 0)
        validatedData = false;
    }
    for (let eventDetailKey in eventDetail) {
      if (
        (eventDetailKey === "title" ||
          eventDetailKey === "status" ||
          eventDetailKey === "shortDescription" ||
          eventDetailKey === "startDate" ||
          eventDetailKey === "startTime" ||
          eventDetailKey === "endDate" ||
          eventDetailKey === "category" ||
          eventDetailKey === "endTime") &&
        ((eventDetail[eventDetailKey].length === 0 &&
          eventDetailKey !== "endDate" &&
          !isEventRecurring) ||
          (eventDetail[eventDetailKey].length === 0 &&
            eventDetailKey === "endDate" &&
            (!isEventRecurring || eventRecurringEndAt === "endDateSelect")))
      ) {
        if (validatedData) validatedData = false;
        eventDetailErrObj = {
          ...eventDetailErrObj,
          [eventDetailKey]:
            eventDetailKey === "title"
              ? "Enter title"
              : eventDetailKey === "status"
                ? "select status"
                : eventDetailKey === "shortDescription"
                  ? "Enter short description"
                  : eventDetailKey === "startDate"
                    ? "Select start date"
                    : eventDetailKey === "startTime"
                      ? "Select start time"
                      : eventDetailKey === "endDate"
                        ? "Select end date"
                        : eventDetailKey === "endTime"
                          ? "Select end time"
                          : eventDetailKey === "category"
                            ? "Select category"
                            : "Select end time",
        };
      }
      if (isEventRecurring) {
        if (
          eventDetailKey === "eventOccurs" &&
          eventDetail[eventDetailKey].length === 0
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            eventOccurs: "Select event occurrence",
          };
          validatedData = false;
        } else if (
          eventDetailKey === "weekDayOccurOption" &&
          eventRecurringOption === "WEEKLY" &&
          eventDetail[eventDetailKey].length === 0
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            weekDayOccurOption: "Select On these days",
          };
          validatedData = false;
        } else if (
          eventDetailKey === "noOfeventOccurs" &&
          eventRecurringEndAt.length === 0
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            noOfeventOccurs: "Select days of event occurs",
          };
          validatedData = false;
        } else if (
          eventDetailKey === "endAts" &&
          eventRecurringEndAt.length === 0
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            endAts: "Select the end date or add number of events.",
          };
          validatedData = false;
        }
      }

      if (isEventRecurring && eventRecurringEndAt !== "endDateSelect") {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          endDate: "",
        };
        validatedData = true;
      }
    }

    if (isTimezoneManually) {
      if (eventDetail.timeZone === "") {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          timeZone: "Select time zone",
        };
        validatedData = false;
      }
    }

    if (eventDetail.title && eventDetail.title.indexOf(",") > -1) {
      eventDetailErrObj = {
        ...eventDetailErrObj,
        title: 'Special character comma "," is not allowed!',
      };
      validatedData = false;
    }
    // if (eventDetail.airTableEventName && eventDetail.airTableEventName.indexOf(",") > -1) {
    //   eventDetailErrObj = { ...eventDetailErrObj, airTableEventName: 'Special character comma "," is not allowed!' };
    //   validatedData = false;

    // }

    if (eventDetail.isLocation) {
      if (eventDetail.locationType === "inPerson") {
        // if (eventDetail.url.trim().length === 0) {
        //   eventDetailErrObj = {
        //     ...eventDetailErrObj,
        //     url: "Enter URL",
        //   };
        //   addressErr = false;
        //   validatedData = false;
        // }
        if (eventDetail.address.trim().length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            address: "Enter full address!",
          };
          addressErr = false;
          validatedData = false;
        }
        if (!placeId && addressErr) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            address: "Please select full address from dropdown list!",
          };
          validatedData = false;
        }
        if (eventDetail.latitude.length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            latitude: "Enter latitude!",
          };
          validatedData = false;
        }
        if (eventDetail.longitude.length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            longitude: "Enter longitude!",
          };
          validatedData = false;
        }
        if (eventDetail.city.trim().length === 0) {
          eventDetailErrObj = { ...eventDetailErrObj, city: "Enter city!" };
          validatedData = false;
        } else if (!regex.test(eventDetail.city)) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            city: "Numbers are not allowed!",
          };
          validatedData = false;
        }
        if (eventDetail.country.trim().length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            country: "Enter country!",
          };
          validatedData = false;
        } else if (!regex.test(eventDetail.country)) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            country: "Numbers are not allowed!",
          };
          validatedData = false;
        }
      }

      if (eventDetail.locationType === "virtual") {
        if (eventDetail.onlineUrl.trim().length === 0) {
          eventDetailErrObj = { ...eventDetailErrObj, onlineUrl: "Enter url!" };
          addressErr = false;
          validatedData = false;
        } else {
          let url;
          try {
            url = new URL(eventDetail.onlineUrl);
          } catch (error) {
            eventDetailErrObj = {
              ...eventDetailErrObj,
              onlineUrl: "Invalid url",
            };
            if (validatedData) validatedData = false;
          }
          if (url && (url.protocol === "http:" || url.protocol === "https:")) {
            eventDetailErrObj = { ...eventDetailErrObj, onlineUrl: "" };
          }
        }
      }
      if (eventDetail.locationType === "to_be_announce") {
        if (eventDetail.toBeAnnounceedDescription.trim().length === 0) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            toBeAnnounceedDescription: "Enter description!",
          };
          addressErr = false;
          validatedData = false;
        }
      }
    } else {
      eventDetailErrObj = { ...eventDetailErrObj, address: "" };
      eventDetailErrObj = { ...eventDetailErrObj, latitude: "" };
      eventDetailErrObj = { ...eventDetailErrObj, longitude: "" };
      eventDetailErrObj = { ...eventDetailErrObj, city: "" };
      eventDetailErrObj = { ...eventDetailErrObj, country: "" };
      eventDetailErrObj = { ...eventDetailErrObj, onlineUrl: "" };
      eventDetailErrObj = {
        ...eventDetailErrObj,
        toBeAnnounceedDescription: "",
      };
    }

    if (!cropedFileData || cropedFileData.length === 0) {
      eventDetailErrObj = { ...eventDetailErrObj, thumbnail: "Add thumbnail" };
      if (validatedData) validatedData = false;
    }

    if (eventDetail.shortDescription.trim().length === 0) {
      eventDetailErrObj = {
        ...eventDetailErrObj,
        shortDescription: "Enter short description",
      };
      validatedData = false;
    }
    if (eventDetail.status === "draft") {
      eventDetailErrObj = { ...eventDetailErrObj, status: "select status" };
      validatedData = false;
    }
    if (!checkLongDesciptionValue(eventDetail.longDescription)) {
      eventDetailErrObj = {
        ...eventDetailErrObj,
        longDescription: "Enter long description",
      };
      if (validatedData) validatedData = false;
    } else {
      eventDetailErrObj = { ...eventDetailErrObj, longDescription: "" };
    }

    if (eventDetail.startDate.length > 0 && eventDetail.endDate.length > 0) {
      if (Date.parse(eventDetail.endDate) < Date.parse(eventDetail.startDate)) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          endDate: "Must be greater then start date",
        };
        if (validatedData) validatedData = false;
      } else eventDetailErrObj = { ...eventDetailErrObj, endDate: "" };
    }
    if (
      Date.parse(eventDetail.endDate) === Date.parse(eventDetail.startDate) &&
      (moment(eventDetail.startTime, "hh:mm").isAfter(
        moment(eventDetail.endTime, "hh:mm")
      ) ||
        moment(eventDetail.startTime, "hh:mm").isSame(
          moment(eventDetail.endTime, "hh:mm")
        ))
    ) {
      eventDetailErrObj = {
        ...eventDetailErrObj,
        endTime: "Must be greater then start time",
      };
      if (validatedData) validatedData = false;
    } else {
      if (eventDetailErrObj.endTime.length === 0) {
        eventDetailErrObj = { ...eventDetailErrObj, endTime: "" };
      }
    }

    if (eventDetail.preRegisterCompulsory) {
      if (eventDetail.preRegisterTitle.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterTitle: "Enter title",
        };
        if (validatedData) validatedData = false;
      } else {
        eventDetailErrObj = { ...eventDetailErrObj, preRegisterTitle: "" };
      }

      if (eventDetail.preRegisterDescription.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterDescription: "Enter description",
        };
        if (validatedData) validatedData = false;
      } else {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterDescription: "",
        };
      }

      if (eventDetail.preRegisterBtnTitle.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterBtnTitle: "Enter button title",
        };
        if (validatedData) validatedData = false;
      } else {
        eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnTitle: "" };
      }

      if (eventDetail.preRegisterBtnLink.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterBtnLink: "Enter button link",
        };
        if (validatedData) validatedData = false;
      } else {
        let url;
        try {
          url = new URL(eventDetail.preRegisterBtnLink);
        } catch (error) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            preRegisterBtnLink: "Invalid url",
          };
          if (validatedData) validatedData = false;
        }
        if (url && (url.protocol === "http:" || url.protocol === "https:")) {
          eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnLink: "" };
        }
      }

      if (eventDetail.preRegisterStartDate.length > 0) {
        if (
          moment(eventDetail.preRegisterStartDate, "YYYY-MM-DD").isAfter(
            moment(eventDetail.startDate, "YYYY-MM-DD")
          )
        ) {
        } else if (
          moment(eventDetail.preRegisterStartDate, "YYYY-MM-DD").isBefore(
            moment(moment(new Date().toDateString()), "YYYY-MM-DD")
          )
        ) {
        } else {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            preRegisterStartDate: "",
          };
        }
      } else {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterStartDate: "Enter start date",
        };
        if (validatedData) validatedData = false;
      }

      if (eventDetail.preRegisterStartTime.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterStartTime: "Enter start time",
        };
        if (validatedData) validatedData = false;
      } else {
      }

      if (eventDetail.preRegisterEndDate.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterEndDate: "Enter end date!",
        };
        if (validatedData) validatedData = false;
      } else if (eventDetail.preRegisterStartDate.length > 0) {
        if (
          moment(eventDetail.preRegisterEndDate).isBefore(
            moment(eventDetail.preRegisterStartDate)
          )
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            preRegisterEndDate: "Must be greater then start date!",
          };
          if (validatedData) validatedData = false;
        } else if (
          moment(eventDetail.preRegisterEndDate).isAfter(
            moment(eventDetail.startDate)
          )
        ) {
        } else
          eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndDate: "" };
      } else {
        eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndDate: "" };
      }

      if (eventDetail.preRegisterEndTime.length === 0) {
        eventDetailErrObj = {
          ...eventDetailErrObj,
          preRegisterEndTime: "Enter end time!",
        };
        if (validatedData) validatedData = false;
      } else if (
        eventDetail.preRegisterEndDate.length > 0 &&
        eventDetail.preRegisterStartDate.length > 0 &&
        eventDetail.preRegisterEndTime.length > 0 &&
        eventDetail.preRegisterStartTime.length > 0
      ) {
        if (
          Date.parse(eventDetail.preRegisterEndDate) ===
          Date.parse(eventDetail.preRegisterStartDate) &&
          (moment(eventDetail.preRegisterStartTime, "hh:mm").isAfter(
            moment(eventDetail.preRegisterEndTime, "hh:mm")
          ) ||
            moment(eventDetail.preRegisterStartTime, "hh:mm").isSame(
              moment(eventDetail.preRegisterEndTime, "hh:mm")
            ))
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            preRegisterEndTime: "Must be greater then start time!",
          };
          if (validatedData) validatedData = false;
        } else if (
          eventDetail.preRegisterEndTime.length > 0 &&
          moment(eventDetail.preRegisterStartTime, "hh:mm").isSame(
            moment(eventDetail.preRegisterEndTime, "hh:mm")
          )
        ) {
          eventDetailErrObj = {
            ...eventDetailErrObj,
            preRegisterEndTime: "Must be less then end time!",
          };
          if (validatedData) validatedData = false;
        } else {
          eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndTime: "" };
        }
      }
    } else {
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndTime: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterTitle: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterDescription: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndDate: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterStartTime: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterStartDate: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnLink: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnTitle: "" };
    }
    setEventDetailErr({ ...eventDetailErr, ...eventDetailErrObj });

    if (validatedData && !ruleError && !noRuleFieldSelected) {
      setInProcess(true);
      createEventApi();
    } else {
      const emptyKeys = Object.keys(eventDetailErrObj).filter(
        (key) => eventDetailErrObj[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
      setInProcess(false);
      noRuleFieldSelected && setRuleError(true);
    }
  };

  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
  };

  const handleCheckPreRegisterationChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSymbolCompulsory(e.target.checked ? "*" : "");
    setPreRegisterCompulsory(true);
    setEventDetail({
      ...eventDetail,
      preRegisterCompulsory: e.target.checked ? true : false,
    });

    var eventDetailErrObj = { ...eventDetailErr };
    if (!e.target.checked) {
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndTime: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterTitle: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterDescription: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterEndDate: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterStartTime: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterStartDate: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnLink: "" };
      eventDetailErrObj = { ...eventDetailErrObj, preRegisterBtnTitle: "" };

      setEventDetailErr({ ...eventDetailErr, ...eventDetailErrObj });
    }
  };

  const handleCheckLocationChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSymbolCompulsoryLocation(e.target.checked ? "*" : "");
    setLocationCompulsory(true);
    setEventDetail({
      ...eventDetail,
      isLocation: e.target.checked ? true : false,
    });
    var eventDetailErrObj = { ...eventDetailErr };
    if (!e.target.checked) {
      eventDetailErrObj = { ...eventDetailErrObj, address: "" };
      eventDetailErrObj = { ...eventDetailErrObj, city: "" };
      eventDetailErrObj = { ...eventDetailErrObj, latitude: "" };
      eventDetailErrObj = { ...eventDetailErrObj, country: "" };
      eventDetailErrObj = { ...eventDetailErrObj, longitude: "" };
      eventDetailErrObj = { ...eventDetailErrObj, postalcode: "" };
      eventDetailErrObj = { ...eventDetailErrObj, onlineUrl: "" };
      eventDetailErrObj = {
        ...eventDetailErrObj,
        toBeAnnounceedDescription: "",
      };

      setEventDetailErr({ ...eventDetailErr, ...eventDetailErrObj });
    }
  };

  //past event for editor
  const handlePastedText = (text, editor, stateChangeFunctionCall) => {
    const contentState = editor.getCurrentContent();
    const selectionState = editor.getSelection();

    // Create a new modified content state with the pasted text
    const modifiedContentState = Modifier.replaceText(
      contentState,
      selectionState,
      text,
      editor
        .getCurrentInlineStyle()
        .filter((style) => style !== "backgroundColor")
    );

    // Create a new editor state with the modified content state
    const newEditorState = EditorState.push(
      editor,
      modifiedContentState,
      "insert-characters"
    );
    stateChangeFunctionCall(newEditorState);
  };

  //called when user selects on suggestion from dropdown
  const handleSelectAddLocation = async (
    address,
    selectedPlaceId,
    selectedPlace
  ) => {
    setshowSuggestion(false);
    setPlaceId(selectedPlaceId);
    if (!selectedPlace?.description || !selectedPlace.description) return;
    setAddress(selectedPlace.description);
    //latitude, longitude api call
    var { latitude, longitude } = await getPlaceDetails(selectedPlaceId);
    let city = "";
    let country = "";
    //city, country api call
    const results = await geocodeByPlaceId(selectedPlaceId);
    for (const result of results) {
      for (const component of result.address_components) {
        if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
        }
      }
    }
    if (selectedPlace) {
      setEventDetail({
        ...eventDetail,
        address: selectedPlace.description,
        city: city,
        country: country,
        latitude,
        longitude,
      });
      let tempErrObj = eventDetailErr;
      if (city) {
        tempErrObj = { ...tempErrObj, city: "" };
      }
      if (country) {
        tempErrObj = { ...tempErrObj, country: "" };
      }
      if (latitude && longitude) {
        tempErrObj = { ...tempErrObj, latitude: "", longitude: "" };
      }
      setEventDetailErr(tempErrObj);
    }
  };

  //google map id and setup
  const googlePlacesAutocompleteProps = {
    apiKey: process.env.REACT_APP_GOOGLE_PLACE_KEY, // Replace with your actual API key
    debounce: 300,
  };

  const getPlaceDetails = async (placeId) => {
    const geocodeResponse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_PLACE_KEY}`
    );
    const geocodeData = await geocodeResponse.json();

    if (geocodeData.status === "OK") {
      const { lat, lng } = geocodeData.results[0].geometry.location;
      return { latitude: lat, longitude: lng };
    } else {
    }
  };

  //onchange event for add location field
  const handleChangeAddLocation = (newAddress) => {
    setAddress(newAddress);
    setshowSuggestion(true);
    setEventDetail({ ...eventDetail, address: newAddress });
    setPlaceId("");
    if (newAddress) {
      setEventDetailErr({ ...eventDetailErr, address: "" });
    }
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  const assignFiles = (acceptedFile) => {
    if (imgFileList.length === 5) {
    } else {
      let tempArray = [...imgFileList, ...acceptedFile];
      if (tempArray.length > 5) tempArray.length = 5;
      setImgFileList(tempArray);
    }
  };

  //Called when someone drop a file
  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });

  const removeSelectedImage = (index) => {
    setImgFileList(imgFileList.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const formDataAddLocationImages = new FormData();

    if (
      imgFileList !== undefined &&
      imgFileList !== null &&
      imgFileList.length > 0 &&
      imgFileList.length <= 5 &&
      eventDetail.isLocation
    ) {
      imgFileList.map((img) => {
        formDataAddLocationImages.append("location_images", img);
      });
      setLocationImageLoader(true);
      authServiceEvent
        .addLocationImageForEvent(formDataAddLocationImages)
        .then((resImage) => {
          if (resImage.status) {
            if (resImage.data.data.length > 0) {
              setLocationImageUrls(resImage.data.data);
            }
          } else {
            setLocationImageUrls([]);
          }
          setLocationImageLoader(false);
        })
        .catch((e) => {
          setLocationImageUrls([]);
          setLocationImageLoader(false);
        });
    }
  }, [imgFileList]);

  // handle tag onchange
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setaddtag(e.target.value.trim());
    if (e.target.value.length > 0) {
      setadderr("");
    } else {
      setadderr("Enter tags! ");
    }
  };

  // handle create tag
  const handleCreate = () => {
    if (addtag.length > 0) {
      setcreateInprocess(true);

      Services.createtag({
        name: addtag.trim(),
      })
        .then((res) => {
          setcreateInprocess(false);
          if (res.data.status) {
            setshowalertAdd(true);
            setaddtag("");
            setalertmsgAdd(res.data.message);
            getTagList();
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          } else {
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          }
          handleClearAllStates();
        })
        .catch((e) => {
          handleClearAllStates();
          setcreateInprocess(false);
          console.log(e);
        });
      setadderr("");
    } else {
      setadderr("Enter tags!");
    }
  };

  async function fetchTimeZone(lat, lng) {
    try {
      const response = await authServiceEvent.getTimeZoneFromLocation(
        `?lat=${lat}&long=${lng}`
      );
      if (response.data.status) {
        const data = response.data.data;
        setEventDetail({ ...eventDetail, autoTimezone: data });
        return data;
      }
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    fetchTimeZone(eventDetail.latitude, eventDetail.longitude);
  }, [eventDetail.latitude, eventDetail.longitude]);

  // handle Change when event recurring (daily,weekely,monthly)
  const handleEventRecurringChange = (event) => {
    setEventRecurringOption(event.target.value);
    if (
      event.target.name === "eventOccurs" &&
      event.target.value.length === 0
    ) {
      setEventDetailErr({
        ...eventDetailErr,
        [event.target.name]: "Select event occurs",
      });
    } else {
      setEventDetailErr({
        ...eventDetailErr,
        [event.target.name]: "",
      });
    }
    setEventDetail({
      ...eventDetail,
      eventOccurs: event.target.value, // Clear the value of noOfeventOccurs field
    });
  };

  // handle Change End At in event recurring
  const handleEventRecurringEndsAtChange = (e) => {
    setEventRecurringEndAt(e.target.value);
    if (e.target.name === "endAts" && e.target.value.length === 0) {
      setEventDetailErr({
        ...eventDetailErr,
        [e.target.name]: "Select the end date or add number of events.",
      });
    } else {
      setEventDetailErr({
        ...eventDetailErr,
        [e.target.name]: "",
      });
    }
  };

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          {modalOpen && (
            <ImageCropperReact
              file={thumbnail}
              setModalOpen={setModalOpen}
              setcropedFile={setcropedFile}
              setFile={setThumbnail}
              fileName={fileName}
              setcropedFileData={setcropedFileData}
              fileDimension={fileDimension}
              requiredImageSize={requiredImageSize}
              requiredImageAspectRatio={requiredImageAspectRatio}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            // setRequiredImageSizeError={() => setEventDetailErr({
            //   ...eventDetailErr,
            //   thumbnail: `Recommended size ${requiredImageSize.width} x ${requiredImageSize.height}`
            // })
            // }
            />
          )}
          {responseMessage.length > 0 && (
            <div className="alert alert-info">{responseMessage}</div>
          )}
          <div className="um-back">
            <button
              type="button"
              className="back-btn"
              onClick={() => {
                if (modalInProcess) {
                  dispatch({ type: "SETCUSTOMPAGECHANGE" });
                  dispatch({
                    type: "NAVIGATEPAGE",
                    payload:
                      NAVIGATEPAGE !== null && NAVIGATEPAGE !== ""
                        ? NAVIGATEPAGE
                        : "/events/list?search=",
                  });
                } else {
                  navigate(
                    NAVIGATEPAGE !== null && NAVIGATEPAGE !== ""
                      ? NAVIGATEPAGE
                      : "/events/list?search="
                  );
                  //navigate("/events/list?search=")
                }
              }}
            >
              <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
              Back
            </button>
          </div>
          <div className="uic-title">
            <h1>Event Info</h1>
          </div>
          {/* <form onSubmit={handleCreateEvent}> */}
          <div className="group-info-user">
            <div className="cstm-video-upload">
              <Dropzone
                onDrop={(acceptedFiles) => {
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    setModalOpen(true)
                    setIsLoading(true)
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        setThumbnail(event.target.result);
                        thumbnailImages(file);
                        setEventDetailErr({ ...eventDetailErr, thumbnail: "" });
                        setIsLoading(false)
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                name="thumbnail"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="cstm-video-upload-img">
                      {cropedFile ? (
                        <img
                          className="channel-img square w-100 h-100"
                          src={cropedFile}
                        />
                      ) : (
                        <img className="empty-img" src={Frame} />
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="group-info-user-link">
              <div className="cover-image-upload-ttl">Thumbnail*</div>
              <div className="cover-image-upload-size">
                {`Recommended size ${requiredImageSize.width} x ${requiredImageSize.height}`}
              </div>
            </div>
          </div>
          <span className="error cstm-error44">{eventDetailErr.thumbnail}</span>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Event title*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="title"
                  value={eventDetail.title}
                  onChange={handleEventDetailChange}
                />
              </div>
              <span className="error cstm-error4">{eventDetailErr.title}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Airtable Event Name</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="airTableEventName"
                  value={eventDetail.airTableEventName}
                  onChange={handleEventDetailChange}
                />
              </div>
              <span className="error cstm-error4">
                {eventDetailErr.airTableEventName}
              </span>
            </div>
          </div>
          {eventDetail.isLocation && (
            <>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Location type*</div>
                  <div className="form-group-ct">
                    <select
                      value={eventDetail.locationType}
                      onChange={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setEventDetail({
                          ...eventDetail,
                          locationType: e.target.value,
                        });
                        if (e.target.value !== "inPerson") {
                          setIsTimezoneManually(true);
                        } else {
                          setIsTimezoneManually(false);
                        }
                      }}
                    >
                      <option value="inPerson">InPerson</option>
                      <option value="virtual">Virtual</option>
                      <option value="to_be_announce">To be announceed</option>
                    </select>
                  </div>
                  <span className="error">{eventDetailErr.locationType}</span>
                </div>
              </div>
              {eventDetail.locationType === "inPerson" && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">
                      Full address{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <PlacesAutocomplete
                        value={address}
                        onSelect={handleSelectAddLocation}
                        onChange={handleChangeAddLocation}
                        {...googlePlacesAutocompleteProps}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Enter a location",
                              })}
                              value={address} // Pass the value prop directly to the input element
                            />
                            <div>
                              <div className="location-suggestions">
                                {loading ? (
                                  <div class="location-suggestions-loading">
                                    <img src={Loader} />
                                  </div>
                                ) : null}
                                {suggestions.map((suggestion) => {
                                  const style = {
                                    backgroundColor: suggestion.active
                                      ? "#e8e8e8"
                                      : "#ffffff",
                                  };
                                  if (showSuggestion) {
                                    return (
                                      <div
                                        className="location-suggestions-in"
                                        tabIndex="-1"
                                        key={suggestion.placeId}
                                        {...getSuggestionItemProps(suggestion, {
                                          style,
                                        })}
                                      >
                                        {suggestion.description}
                                      </div>
                                    );
                                  }
                                  return "";
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <span className="error">{eventDetailErr.address}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      Latitude{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="number"
                        placeholder="Enter latitude"
                        name="latitude"
                        onChange={handleEventDetailChange}
                        value={eventDetail.latitude}
                      />
                    </div>
                    <span className="error">{eventDetailErr.latitude}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      Longitude{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="number"
                        placeholder="Enter longitude"
                        name="longitude"
                        onChange={handleEventDetailChange}
                        value={eventDetail.longitude}
                      />
                    </div>
                    <span className="error">{eventDetailErr.longitude}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Postal code</div>
                    <div className="form-group-ct">
                      <input
                        type="number"
                        placeholder="Enter postal code"
                        name="postalcode"
                        onChange={handleEventDetailChange}
                        value={eventDetail.postalcode}
                      />
                    </div>
                    <span className="error">{eventDetailErr.postalcode}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      City{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter city name"
                        name="city"
                        onChange={handleEventDetailChange}
                        value={eventDetail.city}
                      />
                    </div>
                    <span className="error">{eventDetailErr.city}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">
                      Country{symbolCompulsoryLocation}
                    </div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter country name"
                        name="country"
                        onChange={handleEventDetailChange}
                        value={eventDetail.country}
                      />
                      <span className="error">{eventDetailErr.country}</span>
                    </div>
                  </div>
                  {!isTimezoneManually && (
                    <div className="form-group">
                      <div className="form-group-label">
                        Time Zone(Fetched automatically)
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          placeholder="Timezone"
                          name="autoTimezone"
                          disabled
                          value={eventDetail.autoTimezone}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">
                        Event URL (External URL to WA registration page)
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="url"
                          value={eventDetail.url}
                          onChange={handleEventDetailChange}
                        />
                      </div>
                      <span className="error cstm-error4">
                        {eventDetailErr.url}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div
                        {...getRootProps()}
                        className="edit-group-info-mn-change-img-label"
                      >
                        <input {...getInputProps()} />

                        <div className="cstm-apl-main">
                          {" "}
                          {locationImageLoader ? (
                            <>
                              {" "}
                              <div className="event-locimage-loader">
                                <span className="loader-btn">
                                  <img src={Loader2} />
                                </span>
                              </div>
                            </>
                          ) : (
                            <>Click here to add photos</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="cstm-apl-upload-img">
                        {imgFileList !== undefined &&
                          imgFileList !== null &&
                          imgFileList.map((image, index) => {
                            return (
                              <>
                                <div className="cstm-apl-upload-img-box">
                                  <span
                                    className="cstm-apl-upload-close"
                                    onClick={() => {
                                      removeSelectedImage(index);
                                    }}
                                  >
                                    ×
                                  </span>
                                  <img
                                    alt="location image"
                                    src={
                                      typeof image === "object"
                                        ? URL.createObjectURL(image)
                                        : ""
                                    }
                                  />
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="cstm-chk-preregistration-container">
                        <input
                          type="checkbox"
                          name="chk-islocation"
                          id="chk-islocation"
                          checked={isTimezoneManually}
                          onChange={(e) =>
                            setIsTimezoneManually(e.target.checked)
                          }
                        />
                        <label for="chk-islocation">
                          Do you want to add timezone manually?
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {eventDetail.locationType === "virtual" && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">URL*</div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="onlineUrl"
                          value={eventDetail.onlineUrl}
                          onChange={handleEventDetailChange}
                          maxLength={75}
                        />
                      </div>
                      <span className="error cstm-error4">
                        {eventDetailErr.onlineUrl}
                      </span>
                    </div>
                  </div>
                </>
              )}
              {eventDetail.locationType === "to_be_announce" && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">Description*</div>
                      <div className="form-group-ct">
                        <textarea
                          name="toBeAnnounceedDescription"
                          value={eventDetail.toBeAnnounceedDescription}
                          onChange={handleEventDetailChange}
                        />
                      </div>
                      <span className="error cstm-error4">
                        {eventDetailErr.toBeAnnounceedDescription}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {isTimezoneManually && (
            <div className="row">
              <div className="col-sm-12 form-group">
                <div className="form-group-label">Time Zone*</div>
                <div className="form-group-ct">
                  <select
                    name="timeZone"
                    value={eventDetail.timeZone}
                    onChange={handleEventDetailChange}
                  >
                    <option value="">Select time zone</option>
                    {Object.keys(defaultTimezones).map((timeZoneKey) => {
                      return <option value={timeZoneKey}>{timeZoneKey}</option>;
                    })}
                  </select>
                </div>
                <span className="error">{eventDetailErr.timeZone}</span>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-sm-3 form-group">
              <div className="form-group-label">Start date*</div>
              <div className="form-group-ct">
                <input
                  type="date"
                  placeholder="Select start date"
                  name="startDate"
                  onChange={handleEventDetailChange}
                  value={eventDetail.startDate}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>
              <span className="error">{eventDetailErr.startDate}</span>
            </div>
            <div className="col-sm-3 form-group">
              <div className="form-group-label">Start time*</div>
              <div className="form-group-ct">
                <input
                  type="time"
                  placeholder="Select start time"
                  name="startTime"
                  onChange={handleEventDetailChange}
                  value={eventDetail.startTime}
                  disabled={eventDetail.startDate.length === 0 ? true : false}
                />
              </div>
              <span className="error">{eventDetailErr.startTime}</span>
            </div>
            {!isEventRecurring && (
              <div className="col-sm-3 form-group">
                <div className="form-group-label">End date*</div>
                <div className="form-group-ct">
                  <input
                    type="date"
                    placeholder="Select end date"
                    name="endDate"
                    onChange={handleEventDetailChange}
                    value={eventDetail.endDate}
                    disabled={eventDetail.startDate.length === 0 ? true : false}
                    min={eventDetail.startDate}
                  />
                </div>
                <span className="error">{eventDetailErr.endDate}</span>
              </div>
            )}
            <div className="col-sm-3 form-group">
              <div className="form-group-label">End time*</div>
              <div className="form-group-ct">
                <input
                  type="time"
                  placeholder="Select end time"
                  name="endTime"
                  onChange={handleEventDetailChange}
                  value={eventDetail.endTime}
                  disabled={eventDetail.startDate.length === 0 ? true : false}
                />
              </div>
              <span className="error">{eventDetailErr.endTime}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input
                  type="checkbox"
                  name="chk-event-recurring"
                  id="chk-event-recurring"
                  checked={isEventRecurring}
                  onChange={(e) => setIsEventRecurring(e.target.checked)}
                />
                <label for="chk-event-recurring">
                  Do you want to create recurring event?
                </label>
              </div>
            </div>
          </div>
          {isEventRecurring && (
            <>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Occurrence*</div>
                  <div className="form-group-ct">
                    <select
                      name="eventOccurs"
                      value={eventRecurringOption}
                      onChange={handleEventRecurringChange}
                    >
                      <option value="">Select Occurrence</option>
                      <option value="DAILY">Daily</option>
                      <option value="WEEKLY">Weekly</option>
                      <option value="MONTHLY">Monthly</option>
                    </select>
                  </div>
                  <span className="error">{eventDetailErr.eventOccurs}</span>
                </div>
              </div>
              {eventRecurringOption === "WEEKLY" && (
                <div class="form-group">
                  <div class="cstm-channel-type-title partner-icon-rf-only">
                    On these days
                  </div>
                  <div class="cstm-pm-list">
                    {dayList.map((data, index) => {
                      return (
                        <div class="cstm-radio-btn">
                          <input
                            id={`dayId` + index}
                            type="radio"
                            value={data.value}
                            name="weekDayOccurOption"
                            onChange={handleEventDetailChange}
                          />
                          <label
                            htmlFor={`dayId` + index}
                            class="subcat-addvideo"
                          >
                            {data.label}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  <span className="error cstm-pm-list-msg">
                    {eventDetailErr.weekDayOccurOption}
                  </span>
                </div>
              )}

              <div className="row align-items-start">
                <div className="col-sm-6 col-12 form-group">
                  <div className="form-group-label">Ends at*</div>
                  <div className="form-group-ct">
                    <select
                      name="endAts"
                      value={eventRecurringEndAt}
                      onChange={handleEventRecurringEndsAtChange}
                    >
                      <option value="">Select</option>
                      <option value="endDataSelect">End date</option>
                      <option value="noOfEvents">Add no. of events</option>
                    </select>
                  </div>
                  <span className="error">{eventDetailErr.endAts}</span>
                </div>
                <div className="col-sm-6 col-12 cstm-pm-list cstm-ending-wrapper">
                  {eventRecurringEndAt === "endDataSelect" && (
                    <div className="form-group w-100">
                      <div className="form-group-label">End date*</div>
                      <div className="form-group-ct">
                        <input
                          type="date"
                          placeholder="Select end date"
                          name="endDate"
                          onChange={handleEventDetailChange}
                          value={
                            eventRecurringEndAt === "endDataSelect"
                              ? eventDetail.endDate
                              : "" // Clear the value if eventRecurringEndDataOption is not "on"
                          }
                          disabled={
                            eventDetail.startDate.length === 0 ? true : false
                          }
                          min={eventDetail.startDate}
                        />
                      </div>
                      <span className="error position-relative">
                        {eventDetailErr.endDate}
                      </span>
                    </div>
                  )}
                  {eventRecurringEndAt === "noOfEvents" && (
                    <>
                      <div className="form-group w-100">
                        <div className="form-group-label">Ends After :</div>
                        <div className="form-group-ct d-flex p-0">
                          <input
                            type="text"
                            placeholder="Enter no. of events"
                            name="noOfeventOccurs"
                            value={eventDetail.noOfeventOccurs}
                            onChange={handleEventDetailChange}
                            maxLength={7}
                            pattern="[0-9]*"
                            onKeyDown={(e) => {
                              // Allow only numeric input (0-9), Backspace, and special keys like Arrow keys, etc.
                              if (
                                !/[\d\b]/.test(e.key) &&
                                e.key !== "ArrowLeft" &&
                                e.key !== "ArrowRight" &&
                                e.key !== "Delete" &&
                                e.key !== "Backspace"
                              ) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </div>
                      <span className="error cstm-pm-list-msg">
                        {eventDetailErr.noOfeventOccurs}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input
                  type="checkbox"
                  name="chk-preregistration"
                  id="chk-preregistration"
                  checked={isCheckInAllowed}
                  onChange={(e) => {
                    setIsCheckInAllowed(e.target.checked);
                  }}
                />
                <label for="chk-preregistration">
                  Do you want to enable the check-in tab for this event?
                </label>
              </div>
            </div>
          </div>
          <AddRules
            title="Rules*"
            ruleError={ruleError}
            setRuleError={setRuleError}
            totalUserList={totalUserList}
            noRuleFieldSelected={noRuleFieldSelected}
            setNoRuleFieldSelected={setNoRuleFieldSelected}
            dataFromRules={dataFromRules}
            setDataFromRules={setDataFromRules}
            resetRulesFields={resetRulesFields}
            showEventField={true}
            showTagsField={true}
            users={users}
            setUsers={setUsers}
            emails={emails}
            setEmails={setEmails}
            invalidEmail={invalidEmail}
            setInvalidEmail={setInvalidEmail}
            emailRegex={emailRegex}
            nonUsers={nonUsers}
            setNonUsers={setNonUsers}
          />
          <div className="row">
            <div class="col-sm-4 form-group">
              <div class="form-group-label cstm-channel-type-title">
                Channel
              </div>
              <div className="form-group-ctst event-type-drop-only">
                <Select
                  isSearchable={true}
                  options={badgeList}
                  onChange={onhandleBadgeChange}
                  value={eventDetail.partnerType}
                  styles={{ zIndex: 9999 }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">
                Add Tag or{" "}
                <a
                  className="create-speaker-link"
                  data-toggle="modal"
                  data-target="#addtag"
                >
                  Create Tag
                </a>
              </div>
              <div className="form-group-ct">
                <input
                  type="text"
                  autoComplete="off"
                  list="tag"
                  name="browser"
                  onKeyUp={(e) => {
                    var selType = tagList.filter((type) => {
                      if (type.name === e.target.value) return type.name;
                    });
                    var eventType = eventDetail.tag.find(
                      (type) => type.name === e.target.value
                    );

                    if (selType.length > 0 && !eventType) {
                      setEventDetail({
                        ...eventDetail,
                        tag: [...eventDetail.tag, ...selType],
                      });
                      e.target.value = "";
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            {eventDetail.tag.map((type) => {
              return (
                <span className="topic-label-in">
                  <b>{type.name}</b>
                  <button
                    onClick={() => {
                      setEventDetail({
                        ...eventDetail,
                        tag: eventDetail.tag.filter((typeFilter) => {
                          if (typeFilter.name !== type.name) return typeFilter;
                        }),
                      });
                    }}
                  >
                    ×
                  </button>
                </span>
              );
            })}
            <datalist id="tag">
              {tagList.map((tag) => {
                if (
                  eventDetail.tag.filter((category) => {
                    if (category.name === tag.name) return category;
                  }).length === 0
                )
                  return <option>{tag.name}</option>;
              })}
            </datalist>
          </div>
          <div className="row">
            <div className="col-sm-4 form-group">
              <div className="form-group-label">
                Category* or{" "}
                <a
                  className="create-speaker-link"
                  data-toggle="modal"
                  data-target="#addCategory"
                >
                  Create Category
                </a>
              </div>
              <div className="form-group-ct">
                <input
                  list="category"
                  type="text"
                  name="browser"
                  autoComplete="off"
                  onKeyUp={(e) => {
                    dispatch({ type: "SETMODALINPROCESS" });
                    var selType = categoryData.filter((type) => {
                      if (type.name === e.target.value) return type.name;
                    });
                    var eventType = eventDetail.category.find(
                      (type) => type.name === e.target.value
                    );

                    if (selType.length > 0 && !eventType) {
                      setEventDetail({
                        ...eventDetail,
                        category: [...eventDetail.category, ...selType],
                        subCategory: [
                          ...eventDetail.subCategory,
                          ...selType[0].subcategory.map((cat) => {
                            return cat._id;
                          }),
                        ],
                      });
                      e.target.value = "";
                      setEventDetailErr({ ...eventDetailErr, category: "" });
                    }
                  }}
                />
              </div>
              <span className="error cstm-error4">
                {eventDetailErr.category}
              </span>
            </div>
          </div>
          <div>
            {eventDetail.category.map((type) => {
              return (
                <span className="topic-label-in">
                  <b>{type.name}</b>
                  <button
                    onClick={() => {
                      setEventDetail({
                        ...eventDetail,
                        category: eventDetail.category.filter((typeFilter) => {
                          if (typeFilter.name !== type.name) return typeFilter;
                        }),
                      });
                    }}
                  >
                    ×
                  </button>
                </span>
              );
            })}
            <datalist id="category">
              {categoryData.map((item) => {
                if (
                  eventDetail.category.filter((category) => {
                    if (category.name === item.name) return category;
                  }).length === 0
                )
                  return <option>{item.name}</option>;
              })}
            </datalist>
            {eventDetail.category.length > 0 &&
              eventDetail.category.filter((cat) => {
                if (cat.subcategory) return cat;
              }).length > 0 ? (
              <div className="row">
                <div className="col-sm-12 form-group mb-0">
                  <div className="cstm-add-video-title-sub">
                    Select Subcategory
                  </div>
                  <div className="form-group-ctst">
                    {eventDetail.category.map((cat) => {
                      if (cat.subcategory)
                        return cat.subcategory.map((sCat) => {
                          return (
                            <label className="subcat-addvideo">
                              <input
                                type="checkbox"
                                name="subcategory"
                                value={sCat.name}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSubcategories([
                                      ...subcategories,
                                      sCat._id,
                                    ]);
                                  } else {
                                    setSubcategories([
                                      ...subcategories.filter((cat) => {
                                        if (cat !== sCat._id) return cat;
                                      }),
                                    ]);
                                  }
                                }}
                                defaultChecked={
                                  subcategories.filter((cat) => {
                                    if (cat === sCat._id) return cat;
                                  }).length > 0
                                }
                              />
                              {sCat.name}
                            </label>
                          );
                        });
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div class="form-group">
            <div class="cstm-channel-type-title partner-icon-rf-only">
              Event Status*
              <a
                className="status-undo"
                onClick={() =>
                  setEventDetail({ ...eventDetail, status: "draft" })
                }
              >
                <img src={undo} />
              </a>
            </div>
            <div class="cstm-pm-list">
              <label class="subcat-addvideo">
                <input
                  type="radio"
                  value="paused"
                  name="status"
                  checked={eventDetail.status === "paused"}
                  onChange={handleEventDetailChange}
                />
                Pause
              </label>
              <label class="subcat-addvideo">
                <input
                  type="radio"
                  value="published"
                  name="status"
                  checked={eventDetail.status === "published"}
                  onChange={handleEventDetailChange}
                />
                Live
              </label>
            </div>
            <span className="error cstm-pm-list-msg">
              {eventDetailErr.status}
            </span>
          </div>
          {/* <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input type="checkbox" name="chk-islocation" id="chk-islocation" onChange={handleCheckLocationChange} />
                <label for="chk-islocation">Do you want to add location?</label>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Short Description*</div>
              <div className="cstm-editor-tool-ds">
                <textarea
                  value={eventDetail.shortDescription}
                  name="shortDescription"
                  onChange={handleEventDetailChange}
                  maxLength={500}
                />
              </div>
              <span className="error">{eventDetailErr.shortDescription}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Long Description*</div>
              <div className="cstm-editor-tool-ds">
                <TinyEditor
                  value={eventDetail.longDescription}
                  valueRef={longDescriptionRef}
                  handleEditorChange={() => {
                    setEventDetailErr({
                      ...eventDetailErr,
                      longDescription: "",
                    });
                  }}
                  handleKeyPress={() => {
                    dispatch({ type: "SETMODALINPROCESS" });
                  }}
                />
              </div>
              <span className="error">{eventDetailErr.longDescription}</span>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input
                  type="checkbox"
                  name="chk-preregistration"
                  id="chk-preregistration"
                  onChange={handleCheckPreRegisterationChange}
                />
                <label for="chk-preregistration">
                  Do you want to proceed with Pre Registration form?
                </label>
              </div>
            </div>
          </div>
          {eventDetail.preRegisterCompulsory && (
            <>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Title{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      name="preRegisterTitle"
                      placeholder="Enter title"
                      value={eventDetail.preRegisterTitle}
                      onChange={handleEventDetailChange}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {eventDetailErr.preRegisterTitle}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Description{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <textarea
                      name="preRegisterDescription"
                      placeholder="Enter description"
                      value={eventDetail.preRegisterDescription}
                      row="5"
                      col="5"
                      onChange={handleEventDetailChange}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {eventDetailErr.preRegisterDescription}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Button Title{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter button title"
                      name="preRegisterBtnTitle"
                      value={eventDetail.preRegisterBtnTitle}
                      onChange={handleEventDetailChange}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {eventDetailErr.preRegisterBtnTitle}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Button Redirection Link{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      name="preRegisterBtnLink"
                      placeholder="Enter button redirection link"
                      value={eventDetail.preRegisterBtnLink}
                      onChange={handleEventDetailChange}
                    />
                  </div>
                  <span className="error cstm-error4">
                    {eventDetailErr.preRegisterBtnLink}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 form-group">
                  <div className="form-group-label">
                    Start Date{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      name="preRegisterStartDate"
                      onChange={handleEventDetailChange}
                      value={eventDetail.preRegisterStartDate}
                    // max={moment(eventDetail.startDate).subtract(2).format("YYYY-MM-DD")}
                    // min={moment().format("YYYY-MM-DD")}
                    />
                  </div>
                  <span className="error">
                    {eventDetailErr.preRegisterStartDate}
                  </span>
                </div>
                <div className="col-sm-3 form-group">
                  <div className="form-group-label">
                    Start Time{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="time"
                      name="preRegisterStartTime"
                      onChange={handleEventDetailChange}
                      value={eventDetail.preRegisterStartTime}
                      disabled={
                        eventDetail.preRegisterStartDate.length === 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <span className="error">
                    {eventDetailErr.preRegisterStartTime}
                  </span>
                </div>
                <div className="col-sm-3 form-group">
                  <div className="form-group-label">
                    End Date{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      name="preRegisterEndDate"
                      onChange={handleEventDetailChange}
                      value={eventDetail.preRegisterEndDate}
                      disabled={
                        eventDetail.preRegisterStartDate.length === 0
                          ? true
                          : false
                      }
                      min={moment(eventDetail.preRegisterStartDate).format(
                        "YYYY-MM-DD"
                      )}
                    // max={moment(eventDetail.startDate).subtract(2).format("YYYY-MM-DD")}
                    />
                  </div>
                  <span className="error">
                    {eventDetailErr.preRegisterEndDate}
                  </span>
                </div>
                <div className="col-sm-3 form-group">
                  <div className="form-group-label">
                    End Time{symbolCompulsory}
                  </div>
                  <div className="form-group-ct">
                    <input
                      type="time"
                      placeholder="Select end time"
                      name="preRegisterEndTime"
                      onChange={handleEventDetailChange}
                      value={eventDetail.preRegisterEndTime}
                      disabled={
                        eventDetail.preRegisterEndDate.length === 0
                          ? true
                          : false
                      }
                    />
                  </div>
                  <span className="error">
                    {eventDetailErr.preRegisterEndTime}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="cyp-btm-btn">
            {inProcess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button className="btn" type="submit" onClick={handleCreateEvent}>
                Create Event
              </button>
            )}
            {eventDetail.status === "draft" && (
              <>
                {saveInProcess ? (
                  <div className="loader-button1 save-draft-loader">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button className="btn ms-4" onClick={handleSaveDraft}>
                    Save as draft
                  </button>
                )}
              </>
            )}
            <button
              className="btn2"
              onClick={() => {
                handleClearAllStates();
                navigate("/events/list?search=");
              }}
            >
              Discard
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
      <>
        <AlertComponent />
      </>
      <div
        className="modal fade edit-user-details-popup"
        id="addtag"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create tag</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addtag").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showalertAdd && (
                <div className="alert alert-info">{alertmsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Tag name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter tags name"
                    name="tag"
                    onChange={handleAddChange}
                    value={addtag}
                  />
                </div>
                <span className="error">{adderr}</span>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <CreateCategory
        handleClearAllStates={handleClearAllStates}
        listingApi={getCategoriesListApi}
      />
    </>
  );
};
export default CreateEvent;
