import React, { useState } from "react";
import VideoSmallCard from "../../../CardsVideo/VideoSmallCard";
import videodummy from "../../../../Images/nothumbnail.png";
import { InView } from "react-intersection-observer";
import millify from "millify";
import moment from "moment";
import SaveIcon from "../../../Icons/GeneralIcons/SaveIcon";
import { useSaveVideoMutation } from "../../../../Services/videos";
import { fetchsavedvideos } from "../../../../Action";
import { useDispatch } from "react-redux";
import ToastNotification from "../../../ToastNotification/ToastNotification";
import IconSave from "../../../GlobalComponents/Icons/IconEmptyState/IconSave";
import IconSearchNoResults from "../../../GlobalComponents/Icons/IconEmptyState/IconSearchNoResults";

const SavedListing = ({
  displayVideos = [],
  pageLoader,
  totalVideos,
  setPage,
  page,
  setDisplaySavedVideos,
  setTotalVideos,
  noData,
  renderEmptyState,
  setListingCount,
  setNoData,
}) => {
  const dispatch = useDispatch();
  const [removeSaveVideo] = useSaveVideoMutation();
  const [activeId, setActiveId] = useState(null);
  const [isSaved, setSaved] = useState(false);

  const actions = [
    {
      id: "save",
      name: "Remove from saved",
      icon: <SaveIcon isFilled />,
      callbackFunction: async () => {
        setActiveId(null);
        const saveVideoRes = await removeSaveVideo({ video_id: activeId });
        if (saveVideoRes.data) {
          if (displayVideos.length === 1) {
            setNoData(true);
          }
          setListingCount((prev) => ({ ...prev, saved: prev.saved - 1 }));

          setSaved(true);
          dispatch(fetchsavedvideos());
          setDisplaySavedVideos((prev) => {
            return prev.filter((video) => video._id !== activeId);
          });
          setTotalVideos((prev) => prev - 1);
          setTimeout(() => {
            setSaved(false);
          }, 2000);
        }
      },
    },
  ];

  const handleActionClick = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  function handleSelect(selectedItem) {
    selectedItem.callbackFunction();
  }

  const handleMouseLeave = () => {
    setActiveId(null);
  };

  return (
    <div className="videos-history-saved-grid">
      {pageLoader && page === 1
        ? [...Array(20)].map((ele) => (
            <div className={`video-small-wrapper position-relative`}>
              <div className={`video-small-card`}>
                <div className="video-small-thumb-img video-img-type-small position-relative skeleton-box rounded-2"></div>
                <div
                  className="video-small-details-wrapper"
                  style={{ width: "100%" }}
                >
                  <h6
                    className="video-small-card-title skeleton-box"
                    style={{ height: "24px", width: "100%" }}
                  ></h6>
                  <p
                    className="video-type-text skeleton-box"
                    style={{ height: "20px", width: "100%" }}
                  ></p>
                  <div className="video-details-view-date">
                    <div className="video-details-view-date">
                      <span
                        className="video-view-text skeleton-box"
                        style={{ height: "20px", width: "55px" }}
                      ></span>
                      <span
                        className="video-uploded-date"
                        style={{
                          height: "20px",
                          width: "16px",
                          flexShrink: "0",
                        }}
                      ></span>
                      <p
                        className="skeleton-box"
                        style={{ height: "20px", width: "72px" }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : displayVideos.length > 0 &&
          displayVideos.map((convideo, index) => {
            if (
              index === displayVideos.length - 1 &&
              index !== totalVideos - 1
            ) {
              return (
                <InView
                  onChange={(inView) => {
                    if (inView) setPage((prev) => prev + 1);
                  }}
                >
                  <>
                    <VideoSmallCard
                      handleActionClick={handleActionClick}
                      id={convideo._id}
                      index={index}
                      actions={actions}
                      linkURL={`/videos/${convideo._id}`}
                      thumbnail={
                        convideo.thumbnail !== undefined &&
                        convideo.thumbnail !== null &&
                        convideo.thumbnail.length > 0
                          ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                            convideo.thumbnail
                          : videodummy
                      }
                      duration={
                        !convideo.duration || convideo.duration.includes("NaN")
                          ? "0:00"
                          : convideo.duration
                      }
                      name={convideo.title}
                      category={convideo.categories[0].name}
                      views={
                        convideo.viewsCount !== undefined
                          ? `${millify(convideo.viewsCount)} ${convideo.viewsCount === 1 ? "View" : "Views"}`
                          : "0 Views"
                      }                      
                      uploaded={moment(convideo.createdAt).fromNow()}
                      handleSelect={handleSelect}
                      activeId={activeId}
                      handleMouseLeave={handleMouseLeave}
                    />
                  </>
                </InView>
              );
            } else {
              return (
                <>
                  <VideoSmallCard
                    handleActionClick={handleActionClick}
                    id={convideo._id}
                    index={index}
                    actions={actions}
                    linkURL={`/videos/${convideo._id}`}
                    thumbnail={
                      convideo.thumbnail !== undefined &&
                      convideo.thumbnail !== null &&
                      convideo.thumbnail.length > 0
                        ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                          convideo.thumbnail
                        : videodummy
                    }
                    duration={
                      !convideo.duration || convideo.duration.includes("NaN")
                        ? "0:00"
                        : convideo.duration
                    }
                    name={convideo.title}
                    category={convideo.categories[0].name}
                    views={
                      convideo.viewsCount !== undefined
                        ? `${millify(convideo.viewsCount)} ${convideo.viewsCount === 1 ? "View" : "Views"}`
                        : "0 Views"
                    }                    
                    uploaded={moment(convideo.createdAt).fromNow()}
                    handleSelect={handleSelect}
                    activeId={activeId}
                    handleMouseLeave={handleMouseLeave}
                  />
                </>
              );
            }
          })}
      {pageLoader &&
        page > 1 &&
        [...Array(20)].map((ele) => (
          <div className={`video-small-wrapper position-relative`}>
            <div className={`video-small-card`}>
              <div className="video-small-thumb-img video-img-type-small position-relative skeleton-box rounded-2"></div>
              <div
                className="video-small-details-wrapper"
                style={{ width: "100%" }}
              >
                <h6
                  className="video-small-card-title skeleton-box"
                  style={{ height: "24px", width: "100%" }}
                ></h6>
                <p
                  className="video-type-text skeleton-box"
                  style={{ height: "20px", width: "100%" }}
                ></p>
                <div className="video-details-view-date">
                  <div className="video-details-view-date">
                    <span
                      className="video-view-text skeleton-box"
                      style={{ height: "20px", width: "55px" }}
                    ></span>
                    <span
                      className="video-uploded-date"
                      style={{
                        height: "20px",
                        width: "16px",
                        flexShrink: "0",
                      }}
                    ></span>
                    <p
                      className="skeleton-box"
                      style={{ height: "20px", width: "72px" }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      {!pageLoader &&
        displayVideos.length === 0 &&
        noData &&
        renderEmptyState(
          displayVideos,
          "saved",
          <IconSave />,
          <IconSearchNoResults />,
          "Got any favorite videos?",
          "Oops! No results this time!",
          "Save them here for easy access whenever you want to watch!",
          "Please adjust your searching filters and give it another go!",
          "Clear all filters"
        )}
      {isSaved && (
        <div className="toast-wrapper bottom left" style={{ width: "400px" }}>
          <ToastNotification
            text="Removed from Saved"
            hasAction={false}
            hasCross={false}
          />
        </div>
      )}
    </div>
  );
};

export default SavedListing;
