import React from 'react'
import PropTypes from "prop-types"
const IconScamera = ({ width = 16, height = 16, isFilled = true, iconColor = "#394046" }) => {
    return (
        <>
            {isFilled ?
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 16 16"
                >
                    <path
                        fill={iconColor}
                        d="M8 7.185c.894 0 1.62.733 1.62 1.636 0 .904-.726 1.636-1.62 1.636-.895 0-1.62-.732-1.62-1.636 0-.903.725-1.636 1.62-1.636Z"
                    />
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M10.211 2.239c-.792-.319-3.63-.319-4.423 0-.374.15-.623.566-.864.97-.28.466-.55.917-.993.917-1.434 0-2.598 1.175-2.598 2.624v3.903C1.333 12.5 2.817 14 4.647 14h6.706c1.83 0 3.314-1.5 3.314-3.347V6.75c0-1.45-1.164-2.624-2.598-2.624-.444 0-.713-.45-.993-.918-.242-.403-.49-.819-.865-.97Zm1.456 3.465c.368 0 .67.302.67.674 0 .345-.258.63-.59.669-.394.023-.75-.254-.75-.67 0-.381.305-.652.67-.673ZM8 6.175c-1.447 0-2.62 1.185-2.62 2.646 0 1.462 1.173 2.646 2.62 2.646s2.62-1.184 2.62-2.646c0-1.461-1.173-2.646-2.62-2.646Z"
                        clipRule="evenodd"
                    />
                </svg> :
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    viewBox="0 0 16 16"
                >
                    <path
                        fill={iconColor}
                        d="M11.667 5.704c.368 0 .67.302.67.674 0 .345-.258.63-.59.669-.393.023-.75-.254-.75-.67 0-.382.305-.652.67-.673Z"
                    />
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M8 6.175c-1.447 0-2.62 1.184-2.62 2.646S6.554 11.467 8 11.467s2.62-1.184 2.62-2.646S9.446 6.175 8 6.175Zm0 1.01c.895 0 1.62.732 1.62 1.636 0 .904-.725 1.636-1.62 1.636-.894 0-1.62-.732-1.62-1.636 0-.904.726-1.636 1.62-1.636Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M5.788 2.239c.793-.319 3.632-.319 4.424 0 .807.324 1.032 1.887 1.857 1.887 1.434 0 2.598 1.175 2.598 2.624v3.902c0 1.849-1.484 3.348-3.313 3.348H4.647c-1.83 0-3.314-1.5-3.314-3.348V6.75c0-1.45 1.164-2.624 2.599-2.624.825 0 1.048-1.562 1.856-1.887Zm4.054.938c-.959-.322-2.738-.38-3.684 0-.528.213-.617 1-.971 1.39-.338.37-.837.569-1.255.569-.875.03-1.598.718-1.598 1.614v3.902c0 1.291 1.035 2.338 2.313 2.338h6.707c1.277 0 2.313-1.047 2.313-2.338V6.75c0-.891-.716-1.614-1.598-1.614a1.79 1.79 0 0 1-1.256-.57c-.354-.39-.443-1.176-.971-1.389Z"
                        clipRule="evenodd"
                    />
                </svg>
            }
        </>
    )
}
IconScamera.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isFilled: PropTypes.bool,
    iconColor: PropTypes.string,
    scale: PropTypes.number
}
export default IconScamera