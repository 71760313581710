import axios from "axios";
import authHeader from "../Services/auth.header";
import authAdminHeader from './auth.admin.header'
const API_URL = process.env.REACT_APP_API_URL;

//Banner crud operation
const createNewsBanner = (data) => {
  return axios.post(API_URL + "news/createBanner", data, { headers: authAdminHeader() });
};
const getAllNewsBanner = (query) => {
  return axios.get(API_URL + "news/getAllBanner"+query, { headers: authAdminHeader() })
}
const getNewsBannerById = (eventId) => {
  return axios.get(API_URL + "news/getBannerById/" + eventId, { headers: authAdminHeader() })
}
const editNewsBannerDetail = (data) => {
  return axios.patch(API_URL + "news/editBanner/" + data.id, data.data, { headers: authAdminHeader() })
}
const deleteNewsBanner = (eventId) => {
  return axios.patch(API_URL + "news/deleteBanner/" + eventId, {}, { headers: authAdminHeader() })
}
const reorderNewsBanner = (data) => {
  return axios.post(API_URL + "news/reorderBanner", data, { headers: authAdminHeader() });
};

//Banner crud operation
const createNews = (data) => {
  return axios.post(API_URL + "news/create", data, { headers: authAdminHeader() });
};
const getAllNews = (search) => {
  return axios.get(API_URL + "news/list" + search, { headers: authAdminHeader() })
}
const getNewsById = (eventId) => {
  return axios.get(API_URL + "news/detailForAdmin/" + eventId, { headers: authAdminHeader() })
}
const editNewsDetail = (data) => {
  return axios.post(API_URL + "news/edit/" + data.id, data.data, { headers: authAdminHeader() })
}
const deleteNews = (eventId) => {
  return axios.patch(API_URL + "news/delete/" + eventId, {}, { headers: authAdminHeader() })
}

//desciption image upload
const newsUploadFiles = (data) => {
  return axios.post(API_URL + "news/upload/files", data, {
    headers: authHeader(),
  });
};

//Post crud operation
const createNewsPost = (data) => {
  return axios.post(API_URL + "news/createPost", data, { headers: authAdminHeader() });
};
const getAllNewsPost = (search) => {
  return axios.get(API_URL + "news/getAllPost" + search, { headers: authAdminHeader() })
}
const getNewsPostById = (postId) => {
  return axios.get(API_URL + "news/getPostById/" + postId, { headers: authAdminHeader() })
}
const editNewsPostDetail = (data) => {
  return axios.patch(API_URL + "news/editPost/" + data.postId, data.formData, { headers: authAdminHeader() })
}
const deleteNewsPost = (postId) => {
  return axios.patch(API_URL + "news/deletePost/" + postId, {}, { headers: authAdminHeader() })
}
// Content operation
const getContentNewsList = (query) => {
  return axios.get(API_URL + "contentNews/list" + query, { headers: authAdminHeader() })
}
const makeNewsFeaturedById = (data) => {
  return axios.patch(API_URL + "news/makeNewsFeatured/" + data.id, data.data, { headers: authAdminHeader() })
}
const deleteNewsContent = (data) => {
  return axios.patch(API_URL + "news/delete/" + data, {}, { headers: authAdminHeader() });
}
const getNewsContentDetail = (data) => {
  return axios.get(API_URL + "news/detailForAdmin/" + data, { headers: authAdminHeader() });
}
const getUserNewsBanner = () => {
  return axios.get(API_URL + "news/getAllBannerUsers", { headers: authHeader() })
}
const getTrandingPost = () => {
  return axios.get(API_URL + 'news/getAllPostUsers', { headers: authHeader() })
}
const getFeaturedNews = () => {
  return axios.get(API_URL + 'news/getFeaturedNews', { headers: authHeader() })
}
const getAllNewsContent = (data) => {
  return axios.get(API_URL + 'news/getAllNewsAndContentList' + data, { headers: authHeader() })
}
const getNewsDetail = (id) => {
  return axios.get(API_URL + 'news/detailForUser/' + id, { headers: authHeader() })
}
const getLatestNews = (id) => {
  return axios.get(API_URL + 'news/getAllNewsAndContentList', { headers: authHeader() })
}




export default {
  createNewsPost,
  getNewsPostById,
  editNewsPostDetail,
  deleteNewsPost,
  getAllNewsPost,
  getContentNewsList,
  deleteNewsContent,
  getNewsContentDetail,
  makeNewsFeaturedById,
  createNews,
  getAllNews,
  getNewsById,
  editNewsDetail,
  deleteNews,
  createNewsBanner,
  getAllNewsBanner,
  getNewsBannerById,
  editNewsBannerDetail,
  deleteNewsBanner,
  reorderNewsBanner,
  newsUploadFiles,
  getUserNewsBanner,
  getTrandingPost,
  getFeaturedNews,
  getAllNewsContent,
  getNewsDetail,
  getLatestNews,
}
