import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    cloneActivity: builder.mutation({
      query: ({ body }) => {
        return {
          url: ApiRoutes.activity.cloneActivity.url,
          method: ApiRoutes.activity.cloneActivity.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useCloneActivityMutation } = extendedApi;
