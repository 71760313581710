const savedpost = [];

const ReduSavedPost = (state = savedpost, action) => {
  switch (action.type) {
    case "SAVEDPOST":
      return [...state, action.payload];
    case "FETCHSAVEDPOST":
      return action.payload;
    default:
      return state;
  }
};

export default ReduSavedPost;
