import {
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../../Images/loader.gif";
import imgdelete from "../../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import authServicePartner from "../../../../Services/auth.service.partner";
import ApproveIcon from "../../../../Images/approve-reject.svg";
import tickIcon from "../../../../Images/icn_m_tick.svg";
import crossIcon from "../../../../Images/icn_m_cross.svg";
import Loader2 from "../../../../Images/loader2.gif";
import ReportCountModal from "../PartnerModals/ReportCountModal";

const ReviewTable = ({
  dataLoader,
  rows,
  recallApi,
  handleSort,
  partnername,
  filter,
  columns,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [showAlert, setShowalert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [reviewId, setReviewId] = useState("");
  const [allReason, setAllReason] = useState([]);
  const [reason, setReason] = useState({
    selectedReason: "",
    note: "",
  });
  const [err, setErr] = useState("");
  const [inProcess, setInProcess] = useState(false);
  const [isReport, setReport] = useState(false);
  useEffect(() => {
    allReasonListApi();
  }, []);
  //handle approve review
  async function handleApproveReview() {
    setInProcess(true);
    const bodydata = {
      status: "approved",
      reasonId: null,
    };
    try {
      const approveReviewRes = await authServicePartner.approveOrRejectReview(
        reviewId,
        bodydata
      );
      if (approveReviewRes.data.status) {
        setShowalert(true);
        setAlertMsg(approveReviewRes.data.message);
        setInProcess(false);
        document.querySelector("#aprroveReview .close").click();
        recallApi(filter);
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
        authServicePartner.sendApproveOrRejectReviewEmail(reviewId);
      } else {
        setShowalert(true);
        setAlertMsg(approveReviewRes.data.message);
        setInProcess(false);
        document.querySelector("#aprroveReview .close").click();
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      }
    } catch (error) {
      // setShowalert(true)
      // setAlertMsg(error.data.message)
      setInProcess(false);
      document.querySelector("#aprroveReview .close").click();
      setTimeout(() => {
        setShowalert(false);
        setAlertMsg("");
      }, 3000);
      return false;
    }
  }

  //delete Review
  async function handleDeleteReview() {
    setInProcess(true);
    const deleteReviewRes = await authServicePartner.reviewDelete(reviewId);
    try {
      if (deleteReviewRes.data.status) {
        setShowalert(true);
        setAlertMsg(deleteReviewRes.data.message);
        recallApi(filter);
        setInProcess(false);
        document.querySelector("#deleteReview .close").click();
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      } else {
        setShowalert(true);
        setAlertMsg(deleteReviewRes.data.message);
        setInProcess(false);
        document.querySelector("#deleteReview .close").click();
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      }
    } catch (error) {
      setInProcess(false);
      setTimeout(() => {
        setShowalert(false);
        setAlertMsg("");
      }, 3000);
      document.querySelector("#deleteReview .close").click();
      return false;
    }
  }

  //all reason list api function
  async function allReasonListApi() {
    const allReasonRes = await authServicePartner.allReasonList("");
    try {
      if (allReasonRes.data.status) {
        setAllReason(allReasonRes.data.data);
      }
    } catch (error) {
      return false;
    }
  }

  //handle reject review api function
  async function handleRejectReview() {
    if (reason.selectedReason === "") {
      setErr("Select reason");
    } else {
      const bodydata = {
        status: "rejected",
        reasonId: reason.selectedReason,
        rejectNotes: reason.note,
      };
      setInProcess(true);
      try {
        const rejectReviewRes = await authServicePartner.approveOrRejectReview(
          reviewId,
          bodydata
        );
        if (rejectReviewRes.data.status) {
          setShowalert(true);
          setAlertMsg(rejectReviewRes.data.message);
          setInProcess(false);
          recallApi(filter);
          setReason({
            selectedReason: "",
            note: "",
          });
          document.querySelector("#rejectReview .close").click();
          setTimeout(() => {
            setShowalert(false);
            setAlertMsg("");
          }, 3000);
          authServicePartner.sendApproveOrRejectReviewEmail(reviewId);
        } else {
          setShowalert(true);
          document.querySelector("#rejectReview .close").click();
          setInProcess(false);
          setAlertMsg(rejectReviewRes.data.message);
          setTimeout(() => {
            setShowalert(false);
            setAlertMsg("");
          }, 3000);
        }
      } catch (error) {
        document.querySelector("#rejectReview .close").click();
        setInProcess(false);
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
        recallApi(filter);
        return false;
      }
    }
  }
  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onEscCloseModal = (e) => {
    if (isReport && e.key === "Escape") {
      setReport(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  document.addEventListener("keydown", onEscCloseModal);
  return (
    <>
      {showAlert && <div className="alert alert-info">{alertMsg}</div>}
      <Paper className="uic-tb-mn">
        <TableContainer className="uic-table-main">
          <Table className="uic-table">
            <TableHead className="uic-table-th">
              <TableRow>
                {columns.map((col) => (
                  <TableCell
                    key={col.id}
                    align={col.align}
                    className="uic-th-td "
                  >
                    {col.id !== "_id" ? (
                      <>
                        <span className="custom-sorting">
                          <i
                            className="fa fa-angle-down"
                            onClick={(e) => handleSort(col.id, "Asc")}
                          ></i>
                          <i
                            className="fa fa-angle-up"
                            onClick={(e) => handleSort(col.id, "Dec")}
                          ></i>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    {col.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="uic-table-td">
              {dataLoader ? (
                <div className="cstm-data-load">
                  <img src={Loader} />{" "}
                </div>
              ) : rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).length > 0 ? (
                rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        className={`uic-th-td ${
                          row.status === "none" ? "pending-only" : ""
                        }`}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column.id === "companyName" ? (
                            <TableCell className="uic-th-td w-14">
                              {value}
                            </TableCell>
                          ) : column.id === "star" ? (
                            <TableCell className="uic-th-td w-8">
                              {value}
                            </TableCell>
                          ) : column.id === "name" ? (
                            <TableCell className="uic-th-td w-14">
                              {value}
                            </TableCell>
                          ) : column.id === "reviewNote" ? (
                            <TableCell className="uic-th-td w-20">
                              {value}
                            </TableCell>
                          ) : column.id === "date" ? (
                            <TableCell className="uic-th-td w-8">
                              {value}
                            </TableCell>
                          ) : column.id === "status" ? (
                            <TableCell className="uic-th-td w-8">
                              {value !== "none"
                                ? value.charAt(0).toUpperCase() + value.slice(1)
                                : "Pending"}
                            </TableCell>
                          ) : column.id === "reportCount" ? (
                            <TableCell className="uic-th-td w-10">
                              <a
                                onClick={() => {
                                  value > 0 && setReport(true);
                                  setReviewId(row._id);
                                }}
                                className="cstm-view-users-count"
                              >
                                {value}
                              </a>
                            </TableCell>
                          ) : (
                            <TableCell className="uic-th-td w-8 uic-table-icon">
                              {row.status === "none" && (
                                <>
                                  <a
                                    data-toggle="modal"
                                    data-target="#aprroveReview"
                                    onClick={() => setReviewId(row._id)}
                                  >
                                    <Tooltip title="Approve" arrow>
                                      <img src={tickIcon} />
                                    </Tooltip>
                                  </a>
                                  <a
                                    data-toggle="modal"
                                    data-target="#rejectReview"
                                    onClick={() => setReviewId(row._id)}
                                  >
                                    <Tooltip title="Reject" arrow>
                                      <img src={crossIcon} />
                                    </Tooltip>
                                  </a>
                                </>
                              )}
                              <a
                                onClick={() => setReviewId(row._id)}
                                data-toggle="modal"
                                data-target="#deleteReview"
                              >
                                <Tooltip title="Delete" arrow>
                                  <img src={imgdelete} />
                                </Tooltip>
                              </a>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <span className="no-data-found">No data found</span>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="uic-pagination"
          rowsPerPageOptions={[20, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* delete modal */}
      <div
        className="modal fade msg-popup"
        id="deleteReview"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgBigDelete} />
            </div>
            <h1>
              {partnername != undefined
                ? `Are you sure you want to delete this review from ${partnername}?`
                : "Are you sure you want to delete this review?"}
            </h1>
            <div className="row">
              <div className="col-6">
                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn btn-red"
                      onClick={handleDeleteReview}
                    >
                      Delete
                    </button>
                  </span>
                )}
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn2" data-dismiss="modal">
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Approve modal */}
      <div
        className="modal fade msg-popup"
        id="aprroveReview"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={ApproveIcon} />
            </div>
            <h1>Are you sure you want to approve this review?</h1>
            <div className="row">
              <div className="col-6">
                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn"
                      onClick={handleApproveReview}
                    >
                      Approve
                    </button>
                  </span>
                )}
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn2" data-dismiss="modal">
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade msg-popup"
        id="rejectReview"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="cstm-reason-info">
              <label>Select reason*</label>
              <select
                value={reason.selectedReason}
                onChange={(e) => {
                  setReason({ ...reason, selectedReason: e.target.value });
                  setErr("");
                }}
              >
                <option value="">Select reason</option>
                {allReason.map((item) => (
                  <option value={item._id}>
                    {item.reason.slice(0, 44)}
                    {item.reason.length > 44 ? "..." : ""}
                  </option>
                ))}
              </select>
              <span className="error">{err}</span>
            </div>
            <div className="cstm-reason-info">
              <label>Additional text</label>
              <textarea
                placeholder="Enter reason note"
                value={reason.note}
                name="note"
                onChange={(e) => setReason({ ...reason, note: e.target.value })}
              />
            </div>
            <div className="row">
              <div className="col-6">
                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn"
                      onClick={handleRejectReview}
                    >
                      Reject
                    </button>
                  </span>
                )}
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn2" data-dismiss="modal">
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isReport && (
        <ReportCountModal setReport={setReport} reviewId={reviewId} />
      )}
    </>
  );
};

export default ReviewTable;
