import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logo from "../../../Images/whitelogo.svg";
import FacebookLogin from '@greatsumini/react-facebook-login';
import auth0 from "auth0-js";

import Logo from "../../../Images/logo_mds_small.svg";
import MdsIcon from "../../../Images/MdsIcon.svg";
import IconFacebookColor from "../../../Images/icon_m_facebook_color.svg";
import IconAppleFilled from "../../../Images/icon_m_apple_filled.svg";




const CollaboratorRegistration = () => {
    const [mainErr, setMainErr] = useState("");
    return (
        <>
            <div className="header-collaborator"><a><img src={Logo} /></a></div>

            <div className="collaborator-main">

                <div className="collaborator-emailicon"><img src={MdsIcon} /></div>


                <div className="collaborator-info-registration">
                    <div className="collaborator-info">
                        <h2 className='collaborator-h2'>Welcome to MDS</h2>
                        <h6 className='collaborator-h6'>Please link your social accounts to finish setting up your MDS account.</h6>
                    </div>

                    <div className="collaborator-registration-info">
                        <div className="collaborator-registration-btn">
                            <FacebookLogin
                                appId="494638692251434"
                                onSuccess={(res) => {
                                    console.log(res);
                                }}
                                onFail={(error) => {
                                    console.log(error);
                                }}
                                onProfileSuccess={(response) => {
                                    console.log("response from fb", response);
                                    // handleSelectFB(response);
                                    var options = {
                                        connection: "facebook",
                                    };
                                    var auth_soc = new auth0.WebAuth({
                                        domain: process.env.REACT_APP_AUTH0_DOMAIN,
                                        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                                        responseType: "token id_token",
                                        redirectUri:
                                            process.env.REACT_APP_REDIRECT_URL_COLLABORATOR_SOCIAL_SIGNUP,
                                        scope: "openid profile email full_access",
                                    });

                                    auth_soc.authorize(options, function (err, authResult) {
                                        if (err) {
                                            setMainErr(err);
                                            console.log(err, " error");
                                        }
                                        console.log(authResult, "*********");
                                    });
                                }}
                                render={({ onClick, logout }) => (
                                    <button className="btn-connect" onClick={onClick}><img src={IconFacebookColor} /><span className='collaborator-registration-btn-label'>Connect with Facebook</span></button>
                                )}
                            />
                        </div>
                        <div className="collaborator-registration-btn">
                            <button className="btn-connect" onClick={() => {
                                var options = { connection: "apple", };
                                var auth_soc = new auth0.WebAuth({
                                    domain: process.env.REACT_APP_AUTH0_DOMAIN,
                                    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                                    responseType: "token id_token",
                                    redirectUri:
                                        process.env.REACT_APP_REDIRECT_URL_COLLABORATOR_SOCIAL_SIGNUP,
                                    scope: "openid profile email full_access",
                                });

                                auth_soc.authorize(options, function (err, authResult) {
                                    if (err) {
                                        setMainErr(err);
                                        console.log(err, " error");
                                    }
                                    console.log(authResult, "*********");
                                });
                            }}
                            ><img src={IconAppleFilled} /><span className='collaborator-registration-btn-label'>Connect with Apple</span></button>
                        </div>
                        <div
                            className={mainErr.length > 0 ? 'collaborator-field-error' : "collaborator-field-msg"}>
                            {mainErr}
                        </div>
                        <div className="collaborator-registration-agree">By connecting, you agree to our <a target='_blank' href="https://www.milliondollarsellers.com/terms-of-service">Terms of Service</a> and <a href="https://www.milliondollarsellers.com/privacy-policy" target='_blank'>Privacy Policy</a>.</div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default CollaboratorRegistration