const HISTORYPAGE = ""
const ReduHistoryPage = (state = HISTORYPAGE, action) => {
    switch (action.type) {
        case "HISTORYPAGE":
            return action.payload
        default:
            return state
    }
}

export default ReduHistoryPage;