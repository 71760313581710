import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../../Hooks/AlertComponent";
import authServiceEvent from "../../../Services/auth.service.event";
import Loader from "../../../Images/loader.gif";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import editFill from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import CreateCategory from "../Modals/CreateCategory";
import EditCategory from "../Modals/EditCategory";
import ModalPopup from "../../ModalPopup/ModalPopup";
import {
  useReAssignMainEventCategoryQuery,
  useDeleteCategoryMutation,
} from "../../../Services/mainEvent";

//columns for table
const columns = [
  { id: "name", label: "Name", width: 60 },
  { id: "subCategoryCount", label: "No. of subcategories" },
  { id: "action", label: "Actions", width: 7 },
];

function createData(name, subCategoryCount, action, categoryCount) {
  return {
    name,
    subCategoryCount,
    action,
    categoryCount,
  };
}

const CategoriesManagement = () => {
  const [dataLoader, setDataLoader] = useState(false);
  const [allCategoryPartners, setAllCategoryPartners] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const [selCategoryId, setSelCategoryId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [createInprocess, setCreateInprocess] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [actualsearch, setActualsearch] = useState("");
  const [listIndex, setListIndex] = useState(-1);
  const [totalData, setTotalData] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [restPartner, setRestPartner] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [err, setErr] = useState("");

  //get reassign category
  const { data: reAssignedData } = useReAssignMainEventCategoryQuery({
    id: selCategoryId,
  });

  //delete category
  const [deleteCategory] = useDeleteCategoryMutation();
  console.log("re assigned data >>", reAssignedData);
  // category list api
  const getCategoriesListApi = async (
    page,
    limit,
    search,
    sortField,
    sortType
  ) => {
    let query = "?";
    query += page !== undefined && page !== null ? `page=${page}` : "";
    query += limit !== undefined && limit !== null ? `&limit=${limit}` : "";
    query += search !== undefined && search !== null ? `&search=${search}` : "";
    query +=
      sortField !== undefined && sortField !== null
        ? `&sortField=${sortField}`
        : "";
    query +=
      sortType !== undefined && sortType !== null
        ? `&sortType=${sortType}`
        : "";

    try {
      const getCategoriesRes = await authServiceEvent.getCategoriesList(query);

      if (getCategoriesRes.data.status) {
        setDataLoader(false);
        setTotalData(getCategoriesRes.data.data.totalEvents);
        setPage(getCategoriesRes.data.data.currentPage - 1);
        var data = getCategoriesRes.data.data.event;
        var tbl = [];
        for (var i = 0; i < data.length; i++) {
          tbl[i] = createData(
            data[i].name,
            data[i].subCategoryCount,
            data[i]._id,
            data[i].categoryWiseEventDataCount
          );
        }
        setRows(tbl);
        setAllCategoryPartners(tbl);
        setSuggestionList(tbl);
      } else {
        setDataLoader(false);
        setRows([]);
        setAllCategoryPartners([]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setDataLoader(true);
    getCategoriesListApi(page + 1, rowsPerPage, searchText);
  }, []);

  //called when category delete
  // const handleDelete = () => {
  //   setCreateInprocess(true);
  //   authServiceEvent
  //     .deleteCategory(selCategoryId)
  //     .then((res) => {
  //       if (res.status) {
  //         setShowDeleteModal(false);
  //         setCreateInprocess(false);
  //         getCategoriesListApi();
  //         setShowAlert(true);
  //         setAlertMsg(res.data.message);
  //         setTimeout(() => {
  //           setShowAlert(false);
  //           setAlertMsg("");
  //         }, 3000);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  //called when category delete
  const handleDeleteCategory = async () => {
    setCreateInprocess(true);
    const bodyData = {
      deleteEventCategoryId: selCategoryId,
      reassignEventCategoryId:
        selectedCategory !== "" ? selectedCategory : null,
    };
    try {
      const deleteCategoryRes = await deleteCategory({ body: bodyData });
      if (deleteCategoryRes?.data?.status) {
        getCategoriesListApi(1, rowsPerPage);
        setCreateInprocess(false);
        $("#deleteCategory").modal("hide");
        setShowAlert(true);
        setAlertMsg(deleteCategoryRes?.data?.message);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 3000);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getCategoriesListApi(newPage + 1, rowsPerPage, searchText);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    getCategoriesListApi(1, +event.target.value, searchText);
    setPage(0);
  };

  //search functionality
  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    getCategoriesListApi(1, rowsPerPage, text);
  };

  const handleSort = (field, type) => {
    getCategoriesListApi(1, rowsPerPage, searchText, field, type);
  };
  console.log("selected row >>", selectedRow);
  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>Categories</h1>
            <div className="uic-fl-btn">
              <button
                className="btn"
                data-toggle="modal"
                data-target="#addCategory"
                onClick={() => {
                  // document.getElementById("newSubCatValue").value = ""
                  handleClearAllStates();
                }}
              >
                Add category
              </button>
            </div>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                value={searchText}
                placeholder="Search"
                className="search-user-input"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.name
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />
              {suggestionBox && suggestions.length > 0 && (
                <div className="suggestion-box">
                  <ul id="suggestion-list">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onMouseDown={() => handleSearch(suggestion.name)}
                        className={index === listIndex ? "selected-active" : ""}
                      >
                        {searchText !== null &&
                        searchText.length > 0 &&
                        suggestion.name
                          .toLowerCase()
                          .startsWith(searchText.toLowerCase()) ? (
                          <>
                            <b>{suggestion.name.slice(0, searchText.length)}</b>
                            {suggestion.name &&
                              suggestion.name.slice(
                                searchText.length,
                                suggestion.name.length
                              )}{" "}
                          </>
                        ) : (
                          suggestion.name
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              />
            </div>
          </div>
          {showAlert && <div className="alert alert-info">{alertMsg}</div>}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table min-w-auto">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={`uic-th-td w-${column.width}`}
                      >
                        {column.id !== "action" && column.id !== "drag" ? (
                          <>
                            <span className="custom-sorting">
                              <i
                                className="fa fa-angle-down"
                                onClick={(e) => {
                                  handleSort(column.id, "Asc");
                                }}
                              ></i>
                              <i
                                className="fa fa-angle-up"
                                onClick={(e) => {
                                  handleSort(column.id, "Dec");
                                }}
                              ></i>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {dataLoader ? (
                  <div className="cstm-data-load">
                    <img src={Loader} />{" "}
                  </div>
                ) : rows.length > 0 ? (
                  <TableBody className="uic-table-td">
                    {rows.map((row, index) => {
                      return (
                        <TableRow className="uic-th-td w-20">
                          {columns.map((column) => {
                            const value = row[column.id];
                            return column.id === "name" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-60"
                              >
                                <a
                                  onClick={() => {
                                    setSelCategoryId(row.action);
                                  }}
                                  className="cstm-view-users-count"
                                  data-toggle="modal"
                                  data-target="#editCategory"
                                >
                                  {value}
                                </a>
                              </TableCell>
                            ) : column.id === "action" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td uic-table-icon w-7"
                              >
                                <a
                                  data-toggle="modal"
                                  data-target="#editCategory"
                                  onClick={() => {
                                    setSelCategoryId(value);
                                  }}
                                >
                                  <img src={editFill} />
                                </a>
                                <a
                                  data-toggle="modal"
                                  data-target="#deleteCategory"
                                  onClick={() => {
                                    setSelCategoryId(value);
                                    setShowDeleteModal(true);
                                    setDeleteName(row.name);
                                    setSelectedRow(row);
                                    setSelectedCategory("");
                                    setErr("");
                                  }}
                                >
                                  <img src={imgDelete} />
                                </a>
                              </TableCell>
                            ) : column.id === "subCategoryCount" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-60"
                              >
                                {value}
                              </TableCell>
                            ) : (
                              <></>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <span className="no-data-found">No data found</span>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>

      {/* delete modal */}
      {/* <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        buttonText1="Delete"
        isOpen={showDeleteModal}
        className=""
        btn2variant="danger"
        handleClick2ndButton={handleDelete}
        headingLable={"Delete category"}
      >
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{deleteName}"? This action
            cannot be undone.
          </p>
        </div>
      </ModalPopup> */}
      {/* <div
        className="modal fade msg-popup"
        id="deleteCategory"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgBigDelete} alt="" />
            </div>
            <h1>Are you sure you want to delete this category?</h1>
            <div className="row">
              <div className="col-6">
                {createInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn btn-red"
                      disabled={createInprocess}
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </span>
                )}
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn2" data-dismiss="modal">
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Modal Delete */}
      <div
        className="modal fade cstm-delete-popup"
        id="deleteCategory"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {selectedRow.categoryCount !== undefined &&
            selectedRow.categoryCount > 0 ? (
              <div className="reassigncontent">
                <div className="cstm-delete-badge">
                  <div className="cstm-delete-badge-top">
                    <h3 className="popup-title-h3">Delete event category?</h3>
                    <p className="popup-cont-p">
                      {selectedRow.categoryCount}{" "}
                      {selectedRow.categoryCount > 1
                        ? "events are"
                        : "event is"}{" "}
                      currently assigned to <b>{selectedRow.name}</b> category.
                      Pick a new category to reassign.
                    </p>
                  </div>
                  <div className="select-a-new">
                    <h5 className="select-a-new-h5">
                      Select a new category for these events*
                    </h5>
                    <select
                      className="select-popup-field"
                      name="selevent"
                      value={selectedCategory}
                      onChange={(e) => {
                        setSelectedCategory(e.target.value);
                        e.target.value !== ""
                          ? setErr("")
                          : setErr("Select category");
                      }}
                    >
                      <option value="">Select category</option>
                      {reAssignedData?.data?.length > 0 &&
                        reAssignedData?.data?.map((o) => (
                          <option value={o._id}>{o.name}</option>
                        ))}
                    </select>
                    <span className="error">{err}</span>
                  </div>
                </div>

                <div className="cstm-popup-footer">
                  <button
                    type="button"
                    className="btn-cancel-popup"
                    onClick={() => {
                      setShowDeleteModal(false);
                    }}
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {createInprocess ? (
                    <div className="loader-button1">
                      <button className="btn-blue-popup">In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      disabled={selectedCategory.trim() === ""}
                      type="button"
                      className="btn-blue-popup"
                      onClick={handleDeleteCategory}
                    >
                      Reassign and delete
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="cstm-delete-badge">
                  <div className="cstm-delete-badge-top">
                    <h3 className="popup-title-h3">Delete event category?</h3>
                    <p className="popup-cont-p">
                      Are you sure you want to delete this "{selectedRow.name}"?
                      This action cannot be undone.
                    </p>
                  </div>
                </div>
                <div className="cstm-popup-footer">
                  <button
                    type="button"
                    className="btn-cancel-popup"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {createInprocess ? (
                    <div className="loader-button1">
                      <button className="btn-blue-popup">In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn-delete-popup"
                      onClick={handleDeleteCategory}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* add category modal */}
      <CreateCategory
        handleClearAllStates={handleClearAllStates}
        listingApi={() => getCategoriesListApi(1, rowsPerPage, searchText)}
      />
      <EditCategory
        handleClearAllStates={handleClearAllStates}
        listingApi={() => getCategoriesListApi(1, rowsPerPage, searchText)}
        categoryId={selCategoryId}
      />
      <AlertComponent />
    </>
  );
};

export default CategoriesManagement;
