import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../Images/loader.gif";
import authHeaderChannel from "../../../Services/auth.header.channel";

const ChannelUserDisplay = ({
  setViewModalOpen,
  claimId,
  filterType,
  fromDate,
  toDate,
  rowId,
  clickCountFlag,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const userColumns = [
    { id: "userName", label: "Username" },
    { id: "email", label: "Email" },
  ];

  //function to create userrow for userlisting table
  function createUsers(id, userName, email) {
    return {
      id,
      userName,
      email,
    };
  }

  const [viewUsers, setViewUsers] = useState([]);
  const [userViewLoader, setUserViewLoader] = useState(false);
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0); // Selected rows
  const [selected, setSelected] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [selectError, setSelectError] = useState("");
  const [error, setError] = useState(false);
  const [showData, setshowData] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [sortColumn, setSortColumn] = useState("display_name");
  const [sortOrder, setSortOrder] = useState("Asc");
  const [currentUsersList, setCurrentUsersList] = useState([]);

  // first render when component is mount
  useEffect(() => {
    // setUserViewLoader(true);
    handleViewUsers(userPage + 1, sortColumn, sortOrder);
  }, [userPage]);

  // Get AllPartnerView Users List Api calling function
  const handleViewUsers = async (userPage, col, sort) => {
    setUserViewLoader(true);
    const queryPayload = `${rowId}?page=${userPage}&limit=${userRowsPerPage}&sortField=${col}&sortType=${sort}`;
    authHeaderChannel
      .getChannelUserListDetails(queryPayload)
      .then((res) => {
        if (res.data.status) {
          const channelUserList = res.data.data;
          setCurrentUsersList(channelUserList);
          setTotalData(res.data.channelMember);
          setUserViewLoader(false);
        }
      })
      .catch((e) => {
        setUserViewLoader(false);
        console.log(e);
      });
  };

  // Pagination onChange function
  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage);
  };

  // Table data shorting function
  const handleSort = async (col, direc) => {
    setSortColumn(col);
    setSortOrder(direc);
    handleViewUsers(1, col, direc);
  };

  return (
    <>
      <div className="desk-globle-model-box globle-model-box ">
        <div className="desk-globle-model-box-inner">
          <div className="modal-content">
            <div class="cstm-replace-pp">
              <div className="cstm-hdr">
                <span
                  class="cstm-rpp-close-btn"
                  onClick={() => {
                    setViewModalOpen(false);
                    document.body.classList.remove("modal-open");
                  }}
                >
                  ×
                </span>
                <div class="cstm-rpp-title">Channel User list</div>
              </div>
              {/* <div className="cstm-export-link">
                <a onClick={handleExport}>
                  {" "}
                  <img src={exportImg} />
                  Export
                </a>
              </div> */}
              <div className="cstm-cstm-viewlist">
                {error && <div className="alert alert-info">{selectError}</div>}
                <Paper className="uic-tb-mn">
                  <TableContainer className="uic-table-main">
                    <Table className="">
                      <TableHead className="uic-table-th">
                        <TableRow className="uic-th-td w-20">
                          {userColumns.map((column) => (
                            <TableCell className="uic-th-td" key={column.id}>
                              {column.id === "userName" && (
                                <span className="custom-sorting">
                                  <i
                                    className="fa fa-angle-down"
                                    onClick={(e) => {
                                      handleSort(
                                        column.id == "userName"
                                        && "display_name",
                                        "Asc"
                                      );
                                    }}
                                  ></i>
                                  <i
                                    className="fa fa-angle-up"
                                    onClick={(e) => {
                                      handleSort(
                                        column.id == "userName"
                                        && "display_name",
                                        "Dec"
                                      );
                                    }}
                                  ></i>
                                </span>
                              )}
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody className="uic-table-td">
                        {userViewLoader ? (
                          <div className="cstm-data-load">
                            <img src={Loader} />
                          </div>
                        ) : currentUsersList.length > 0 ? (
                          currentUsersList?.map((row, index) => {
                            return (
                              <>
                                {/* {row?.map((item) => ( */}
                                <TableRow className="uic-th-td w-20">
                                  <TableCell className="uic-th-td">
                                    {row.display_name}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {row["Preferred Email"]}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })
                        ) : (
                          <span className="no-data-found-pl">
                            No data found
                          </span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalData}
                    rowsPerPage={userRowsPerPage}
                    page={userPage}
                    onPageChange={handleChangeUserPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelUserDisplay;
