import React from "react";
import logoClick from "../../../Images/logo_clickup_vertical_dark.jpg";
import exclusiveDiamonIcon from "../../../Images/icn_s_diamond.svg";
import starIcon from "../../../Images/star_part.svg";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
const PartnerOfferCard = ({ reviewCount, item, onClick }) => {
  // if role is user for that all the navigation clicks will work
  const isUser = (localStorage.getItem("role") === "user")


  function getLuminance(color) {
    // Check if the input color is in RGBA format
    if (color !== undefined && color.startsWith !== undefined && color.startsWith("rgba")) {
      const rgbaMatch = color.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
      if (rgbaMatch) {
        const r = parseInt(rgbaMatch[1]);
        const g = parseInt(rgbaMatch[2]);
        const b = parseInt(rgbaMatch[3]);
        const a = parseFloat(rgbaMatch[4]);
        return (0.299 * r + 0.587 * g + 0.114 * b) / 255 * a;
      }
    }

    // Check if the input color is in HEX format
    if (color !== undefined && color.startsWith !== undefined && color.startsWith("#") && (color.length === 4 || color.length === 7)) {
      const hexColor = color.length === 4 ? expandShortHex(color) : color;
      const r = parseInt(hexColor.slice(1, 3), 16);
      const g = parseInt(hexColor.slice(3, 5), 16);
      const b = parseInt(hexColor.slice(5, 7), 16);
      return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    }

    return null; // Return null if the color format is not recognized
  }

  function expandShortHex(hexColor) {
    return `#${hexColor[1]}${hexColor[1]}${hexColor[2]}${hexColor[2]}${hexColor[3]}${hexColor[3]}`;
  }

  function decideTextColorBasedOnBackground(bgColor) {
    const luminance = getLuminance(bgColor);
    if (luminance !== null) {
      return luminance < 0.5 ? 'light' : 'dark';
    }
    return 'unknown'; // You can return 'unknown' or handle this case differently
  }
  const textColor = decideTextColorBasedOnBackground(item.partnerType.badgeColor);
  return (
    <>
      <div className="partners-fresh-only">
        <div className="mds-partners-cont-post partners-more-offer">
          <Link to={(reviewCount !== undefined && reviewCount >= 3 && isUser) ? `/partners/all-partners/${item._id}` : `/partners/all-partners?type=offer&&cat=all&&search=&&sortby=newest&&badge=all`} onClick={onClick !== undefined && onClick}>
            {item.partnerType !== undefined && item.partnerType !== null && window.location.hostname === "test.milliondollarsellers.com" && item.partnerType._id === "64f83db1acacc396564d4562" ? (
              // <div >
              <div className="cstm-exclusive-label" style={{ backgroundColor: item.partnerType.badgeColor }}>
                <img src={exclusiveDiamonIcon} />
                {item.partnerType.name}
              </div>
            ) : item.partnerType !== undefined && item.partnerType !== null && window.location.hostname === "dev.milliondollarsellers.com" && item.partnerType._id === "65083abc4e4b52568e71e55a" ? (
              <div className="cstm-exclusive-label" style={{ backgroundColor: item.partnerType.badgeColor }}>

                <img src={exclusiveDiamonIcon} />
                {item.partnerType.name}
              </div>
            ) : item.partnerType !== undefined && item.partnerType !== null && window.location.hostname === "app.milliondollarsellers.com" && item.partnerType._id === "6513f058feeb91913e2d303d" ? (
              <div className="cstm-exclusive-label" style={{ backgroundColor: item.partnerType.badgeColor }}>

                <img src={exclusiveDiamonIcon} />
                {item.partnerType.name}
              </div>
            ) : (item.partnerType !== undefined && item.partnerType !== null && item.partnerType.name !== "nobadge" &&
              <div className="cstm-parter-label" style={{ backgroundColor: item.partnerType.badgeColor, color: textColor === 'light' ? 'white' : 'black', }}>
                {item.partnerType.name}
              </div>
            )}
            <div className="mds-partners-cont-post-img">
              <img src={item?.thumbnail ?? logoClick} />
            </div>
            <div className="mds-partners-cont-post-title">
              <h3>{item?.offerValue}</h3>
            </div>
            <h5 className="cstm-partnesh5">by {`${item?.companyName}`}</h5>
          </Link>
          <h6
            className="cstm-partnesh6"
            dangerouslySetInnerHTML={{
              __html: item?.shortDescription ?? "",
            }}
          />
          <div className="partners-details-reviews justify-content-start">
            {item.rating !== "0.0" && item.rating !== "0" && <span className="partners-reviews-count">
              <Rating
                precision={0.5}
                readOnly
                value={
                  item.rating !== undefined &&
                  item.rating !== null &&
                  item.rating
                }
              />
            </span>}
            {item.reviews?.length !== 0 &&
              <Link to={(reviewCount !== undefined && reviewCount >= 3 && isUser) ? `/partners/all-partners/${item._id}#rating` : `/partners/all-partners?type=offer&&cat=all&&search=&&sortby=newest&&badge=all`} className="partners-reviews-link">
                {item !== undefined &&
                  item !== null &&
                  item.reviews !== undefined &&
                  item.reviews.length}{" "}
                {`${item.reviews?.length > 1
                  ? "reviews"
                  : "review"
                  }`}
              </Link>}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerOfferCard;
