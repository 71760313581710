import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedEventListApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    upcomingEventDetail: builder.query({
      query: ({ event_id }) => {
        return {
          url: ApiRoutes.eventDetail.upcomingEventDetailApi.url.replace(
            ":id",
            event_id
          ),
          method: ApiRoutes.eventDetail.upcomingEventDetailApi.method,
        };
      },
      transformResponse: (response) => response,
    }),
    pastEventDetail: builder.query({
      query: ({ event_id }) => {
        return {
          url: ApiRoutes.eventDetail.pastEventDetailApi.url.replace(
            ":eventId",
            event_id
          ),
          method: ApiRoutes.eventDetail.pastEventDetailApi.method,
        };
      },
      transformResponse: (response) => response,
    }),
    myEventDetail: builder.query({
      query: ({ event_id }) => {
        return {
          url: ApiRoutes.eventDetail.myEventDetailApi.url.replace(
            ":eventId",
            event_id
          ),
          method: ApiRoutes.eventDetail.myEventDetailApi.method,
        };
      },
      transformResponse: (response) => response,
    }),
    checkEventAccess: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.eventDetail.checkEventAccess.url,
          method: ApiRoutes.eventDetail.checkEventAccess.method,
          body
        }
      },
      transformResponse: (response) => response
    })
  }),
  overrideExisting: false
});

export const {
  useLazyUpcomingEventDetailQuery,
  useLazyMyEventDetailQuery,
  useLazyPastEventDetailQuery,
  useCheckEventAccessMutation,
} = extendedEventListApi;
