import { createContext, useState } from "react";
const MediaContext = createContext({});

export const MediaInprogressProvider = ({ children }) => {
    const [media, setMedia] = useState({})
    return (
        <MediaContext.Provider value={{ media, setMedia }}>
            {children}
        </MediaContext.Provider>
    )
}
export default MediaContext;