import React from "react";
import PropTypes from "prop-types";
import "../HelperComponents/Styles/EventTicketPurchaseHeading.css";
import { ReactComponent as BackArrow } from "../../../../Images/icn_m_arrow_left.svg";
const EventTicketPurchaseHeading = ({
  backToText = "guest’s tickets",
  title = "Title Text",
  description = "",
  hasDates = false,
  hasBack = true,
  backLink = "/",
  hasDatePeriod = false,
  hasStartYear = false,
  startDates = "Mar 2",
  startYear = "2024",
  endDates = "Jun 4",
  endYear = "2025",
  handleBackClick,
}) => {
  return (
    <>
      <div className="event-ticket-purchase-heading-wrapper">
        {hasBack && (
          <div
            // href={backLink ? backLink : "javascript:void(0)"}
            className="back-to-text-link cursor-pointer"
            onClick={handleBackClick}
          >
            <BackArrow />
            <span className="back-to-text">Back to {backToText}</span>
          </div>
        )}
        <h2 className="event-ticket-purchase-heading-text">{title}</h2>
        {hasDates && (
          <h2 className="event-medium-card-date">
            {!hasDatePeriod
              ? startDates + ", " + startYear
              : !hasStartYear
              ? startDates + " - " + endDates + ", " + endYear
              : startDates + startYear + " - " + endDates + ", " + endYear}
          </h2>
        )}
        {description && (
          <p className="event-ticket-purchase-heading-description">
            {description}
          </p>
        )}
      </div>
    </>
  );
};

EventTicketPurchaseHeading.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hasBack: PropTypes.bool,
  backLink: PropTypes.string,
  backToText: PropTypes.string,
  hasDates: PropTypes.bool,
  hasDatePeriod: PropTypes.bool,
  hasStartYear: PropTypes.bool,
  startDates: PropTypes.string,
  startYear: PropTypes.string,
  endDates: PropTypes.string,
  endYear: PropTypes.string,
  handleBackClick: PropTypes.func,
};

export default EventTicketPurchaseHeading;
