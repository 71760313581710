import React, { useEffect, useState } from "react";
import FacebookLogin from "@greatsumini/react-facebook-login";
import auth0 from "auth0-js";
import logo from "../../../Images/logo_mds_small.svg";
import MdsIcon from "../../../Images/news-logo.svg";
import IconFacebookColor from "../../../Images/icon_m_facebook_color.svg";
import IconAppleFilled from "../../../Images/icon_m_apple_filled.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import authService from "../../../Services/auth.service";
import PageNotFound from "../../PageNotFound";
import { ReactComponent as FbIcon } from "../../../Images/fb-icon.svg";
import { ReactComponent as AppleIcon } from "../../../Images/apple-icon.svg";
import noAccess from "../../../Images/nonaccessuser.svg";
import loading from "../../../Images/loader.gif";

const MigrateUserSignup = () => {
  const { authid } = useParams();
  const location = useLocation();
  const { auth0_appdata, auth0_metadata, auth_user_id, verifed } =
    location.state !== null && location.state !== undefined
      ? location.state
      : { undefined, undefined, undefined };
  const redirectURL = window.location.hostname;
  const [mainErr, setMainErr] = useState("");
  const [userId, setUserId] = useState("");
  const [error, setError] = useState("");
  const [noAuthId, setNoAuthId] = useState(false);
  const [isExist, setExist] = useState(false);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (authid.startsWith("auth0")) {
      setNoAuthId(false);
      authService
        .isAuthUserExists({ auth0Id: authid.split("|")[1] })
        .then((res) => {
          if (res.data.status) {
            authService.updateMigrationCaseStatus({
              auth0Id: authid.split("|")[1],
              field: "case1",
              fieldval: true,
              error: "",
            });
            setExist(true);
            setLoader(false);
          } else {
            authService.updateMigrationCaseStatus({
              auth0Id: authid.split("|")[1],
              field: "case2",
              fieldval: true,
              error: res?.data?.message,
            });
            setExist(false);
            setLoader(false);
          }
        })
        .catch((err) => {
          authService.updateMigrationCaseStatus({
            auth0Id: authid.split("|")[1],
            field: "case2",
            fieldval: true,
            error: err,
          });
          console.log(err);
          setLoader(false);
          return false;
        });

      authService
        .getauthuser(authid)
        .then((res) => {
          if (res.data.status) {
            setUserId(res.data.data.user_id);
            localStorage.setItem(
              "migrated_user_plan_id",
              res.data.data.user_metadata.plan_id
            );
            localStorage.setItem(
              "m_user",
              JSON.stringify(res.data.data.user_metadata)
            );
            localStorage.setItem(
              "app_metadata",
              JSON.stringify(res.data.data.app_metadata)
            );
            localStorage.setItem("AuthUserId", res.data.data.user_id);
            // setTimeRemaining(true)
            // setuserDetail(res.data.data);
            // setErr("");
            authService.updateMigrationCaseStatus({
              auth0Id: authid.split("|")[1],
              field: "case3",
              fieldval: true,
              error: "",
            });
          } else {
            authService.updateMigrationCaseStatus({
              auth0Id: authid.split("|")[1],
              field: "case4",
              fieldval: true,
              error: res.data.message,
            });
            // setErr(res.data.message);
          }
          // setInprocess(false);
        })
        .catch((e) => {
          authService.updateMigrationCaseStatus({
            auth0Id: authid.split("|")[1],
            field: "case4",
            fieldval: true,
            error: e,
          });
          // setInprocess(false);
          console.log(e);
        });
    } else {
      setNoAuthId(true);
      setLoader(false);
    }
  }, []);
  useEffect(() => {
    // if (verifed) {
    //     setUserId(auth_user_id)
    //     localStorage.setItem("migrated_user_plan_id", auth0_metadata.plan_id)
    //     localStorage.setItem("m_user", JSON.stringify(auth0_metadata))
    //     localStorage.setItem("app_metadata", JSON.stringify(auth0_appdata))
    // }
    if (
      location.state !== undefined &&
      location.state !== null &&
      location.state.userExist
    ) {
      setMainErr(
        `This ${location.state.selectedMedia} account is already connected with MDS, Please try with another account.`
      );
    } else {
      setMainErr("");
      setError("");
    }
  }, [location]);
  return (
    <>
      {loader ? (
        <div className="loadind-post-colla tttt">
          <img src={loading} />
        </div>
      ) : noAuthId ? (
        <PageNotFound />
      ) : isExist && !noAuthId ? (
        <>
          <div className="header-collaborator">
            <a href="#">
              <img src={logo} />
            </a>
          </div>
          <div className="collaborator-main">
            <div className="collaborator-emailicon">
              <img src={noAccess} />
            </div>

            <div className="collaborator-succesfully">
              <div className="collaborator-info">
                <h2 className="collaborator-h2">Oops. Link expired!</h2>
                <h6 className="collaborator-h6">
                  It appears you clicked on an expired join link or the URL is
                  incorrect.
                </h6>
              </div>
              {/* <div className='btn-log-in-to-mds'><a className='btn-log' href="/">Request a new link</a></div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid">
            <div className="new-login-box-wrapper">
              <div className="header-collaborator header-spacing">
                <Link to="/">
                  <img src={logo} />
                </Link>
              </div>

              <div className="collaborator-main new-signup-page">
                <div className="collaborator-info-registration ">
                  <div className="collaborator-emailicon">
                    <img src={MdsIcon} width={120} height={120} />
                  </div>

                  <div className="collaborator-info">
                    <h2 className="collaborator-h2">Finish signing up</h2>
                  </div>

                  <div className="collaborator-registration-info">
                    {/* <div className="collaborator-registration-btn">
                            <FacebookLogin
                                appId="494638692251434"
                                onSuccess={(res) => {
                                    console.log(res);
                                }}
                                onFail={(error) => {
                                    authService.updateMigrationCaseStatus({
                                        "auth0Id": userId.split("|")[1],
                                        "field": "case10",
                                        "fieldval": true,
                                        "error": error
                                    })
                                    console.log(error);
                                }}
                                onProfileSuccess={(response) => {
                                    authService.updateMigrationCaseStatus({
                                        "auth0Id": userId.split("|")[1],
                                        "field": "case9",
                                        "fieldval": true,
                                        "error": ""
                                    })
                                    localStorage.setItem("AuthUserId", userId);
                                    console.log("response from fb", response);
                                    // handleSelectFB(response);
                                    var options = {
                                        connection: "facebook",
                                    };
                                    var auth_soc = new auth0.WebAuth({
                                        domain: process.env.REACT_APP_AUTH0_DOMAIN,
                                        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                                        responseType: "token id_token",
                                        redirectUri: "https://" + redirectURL + "/migrateusersignup",
                                        scope: "openid profile email full_access",
                                    });

                                    auth_soc.authorize(options, function (err, authResult) {
                                        if (err) {
                                            authService.updateMigrationCaseStatus({
                                                "auth0Id": userId.split("|")[1],
                                                "field": "case11",
                                                "fieldval": true,
                                                "error": error
                                            })
                                            setMainErr(err);
                                            console.log(err, " error");
                                        }
                                        console.log(authResult, "*********");
                                    });
                                }}
                                render={({ onClick, logout }) => (
                                    <button className="btn-connect" onClick={onClick}><img src={IconFacebookColor} /><span className='collaborator-registration-btn-label'>Connect with Facebook</span></button>
                                )}
                            />
                        </div> */}
                    <div className="collaborator-registration-btn">
                      <button
                        className="btn-facebook new-login-page-btn login-btn-facebook rounded-pill"
                        onClick={() => {
                          authService.updateMigrationCaseStatus({
                            auth0Id: userId.split("|")[1],
                            field: "case9",
                            fieldval: true,
                            error: "",
                          });
                          localStorage.setItem("AuthUserId", userId);
                          var options = {
                            connection: "facebook",
                          };
                          var auth_soc = new auth0.WebAuth({
                            domain: process.env.REACT_APP_AUTH0_DOMAIN,
                            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                            responseType: "token id_token",
                            redirectUri:
                              window.location.origin + "/migrateusersignup",
                            scope: "openid profile email full_access",
                          });

                          auth_soc.authorize(
                            options,
                            function (err, authResult) {
                              if (err) {
                                authService.updateMigrationCaseStatus({
                                  auth0Id: userId.split("|")[1],
                                  field: "case5",
                                  fieldval: true,
                                  error: error,
                                });
                                setMainErr(err);
                                console.log(err, " error");
                              }
                              console.log(authResult, "*********");
                            }
                          );
                        }}
                      >
                        <FbIcon /> Connect with Facebook
                      </button>
                    </div>
                    <div className="collaborator-registration-btn">
                      <button
                        className="apple-btn new-login-page-btn rounded-pill"
                        onClick={() => {
                          authService.updateMigrationCaseStatus({
                            auth0Id: userId.split("|")[1],
                            field: "case9",
                            fieldval: true,
                            error: "",
                          });
                          localStorage.setItem("AuthUserId", userId);
                          var options = {
                            connection: "apple",
                          };
                          var auth_soc = new auth0.WebAuth({
                            domain: process.env.REACT_APP_AUTH0_DOMAIN,
                            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                            responseType: "token id_token",
                            redirectUri:
                              window.location.origin + "/migrateusersignup",
                            scope: "openid profile email full_access",
                          });

                          auth_soc.authorize(
                            options,
                            function (err, authResult) {
                              if (err) {
                                authService.updateMigrationCaseStatus({
                                  auth0Id: userId.split("|")[1],
                                  field: "case5",
                                  fieldval: true,
                                  error: error,
                                });
                                setMainErr(err);
                                console.log(err, " error");
                              }
                              console.log(authResult, "*********");
                            }
                          );
                        }}
                      >
                        <AppleIcon /> Connect with Apple
                      </button>
                    </div>
                    <div
                      className={
                        mainErr.length > 0
                          ? "collaborator-field-error"
                          : "collaborator-field-msg"
                      }
                    >
                      {mainErr}
                    </div>
                  </div>

                  <div className="collaborator-registration-agree">
                    By clicking "Connect with Facebook" or "Connect with Apple",
                    you agree to the MDS{" "}
                    <a
                      target="_blank"
                      href="https://www.milliondollarsellers.com/terms-of-service"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.milliondollarsellers.com/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                    .
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MigrateUserSignup;
