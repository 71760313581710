const initialState = {
  message: "",
  isUploading: false,
  currentChatId: null, // Store information about the current chat
};

const MediaProgressDisplayMessage = (state = initialState, action) => {
  switch (action.type) {
    case "ASSIGNPROGRESSMEDIAMESSAGE":
      return {
        ...state,
        message: action.payload.message,
        isUploading: action.payload.isUploading,
        currentChatId: action.payload.currentChatId,
      };
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export default MediaProgressDisplayMessage;
