import React, { useRef, useState } from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

const ChipInputFieldForRules = ({
  allUser,
  selectedUser,
  allParticipantsEmailArr,
  users,
  setUsers,
  emails,
  setEmails,
  invalidEmail,
  setInvalidEmail,
  emailRegex,
  nonUsers,
  setNonUsers,
  ruleError,
  setRuleError,
  setNoRuleFieldSelected
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const textFieldRef = useRef();
  const [showSuggestions, setShowSuggestions] = useState(false);

  //Debounce for input text
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleInputChange = debounce((newInputValue) => {
    const normalizedInput = newInputValue.replace(/\s+/g, "").toLowerCase();
    if (newInputValue === "") {
      setFilteredSuggestions([]); // Clear suggestions when input is empty
    } else {
      // Filter suggestions based on input value
      const filtered = allUser
        .filter((emailObj) => {
          return emailObj.email.includes(normalizedInput); // Filter by input
        })
        .filter((emailObj, index, self) => {
          // Exclude already selected emails
          if (emails.includes(emailObj.email.trim())) {
            return false;
          }
          // Ensure no duplicates by checking if this is the first occurrence
          return (
            index ===
            self.findIndex((e) => e.email.trim() === emailObj.email.trim())
          );
        });
      setFilteredSuggestions(filtered);
    }
  }, 300);

  //Handle text entered in the text field
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    debouncedHandleInputChange(newInputValue);
    setShowSuggestions(newInputValue.length > 0);
  };

  //Handle pasted texts
  const handlePaste = (event) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("text");
    const emailsToPaste = pastedData
      .split(/[\s,]+/) // Split by spaces, commas, and newlines
      .map((email) => email.trim())
      .filter((email) => email);

    // Update the emails state with valid emails
    setEmails((prevEmails) => [...new Set([...prevEmails, ...emailsToPaste])]);

    // Update the attendees and non-attendees states along with invalid email
    const newUsers = emailsToPaste.filter((email) =>
      allParticipantsEmailArr?.includes(email)
    );
    const newNonUsers = emailsToPaste.filter(
      (email) =>
        !allParticipantsEmailArr?.includes(email) && emailRegex.test(email)
    );
    const newInvalidEmails = emailsToPaste.filter(
      (email) => !emailRegex.test(email)
    );
    setUsers((prevAttendees) => [...new Set([...prevAttendees, ...newUsers])]);
    setNonUsers((prevNonAttendees) => [
      ...new Set([...prevNonAttendees, ...newNonUsers]),
    ]);
    setInvalidEmail((prevInvalidEmails) => [
      ...new Set([...prevInvalidEmails, ...newInvalidEmails]),
    ]);
  };

  //Executes while making chips
  const handleChange = (event, normalizedNewValue) => {
    // Normalize the new values to strings
    const newValue = normalizedNewValue.map((item) =>
      typeof item === "string" ? item : item.email
    );
    const allInput = newValue;
    if (newValue?.length > emails?.length) {
      const selectedEmail = newValue[newValue.length - 1].trim();

      setEmails([...new Set([...allInput])]);
      setInputValue("");

      if (allParticipantsEmailArr?.includes(selectedEmail)) {
        setUsers([...new Set([...users, selectedEmail])]);
      } else if (emailRegex.test(selectedEmail)) {
        setNonUsers([...new Set([...nonUsers, selectedEmail])]);
      } else {
        setInvalidEmail([...new Set([...invalidEmail, selectedEmail])]);
      }

      setFilteredSuggestions((prevSuggestions) => {
        prevSuggestions.filter((emailObj) => {
          emailObj.email.trim() !== selectedEmail;
        });
      });
    } else {
      setEmails(newValue);
      const removedEmail = emails
        .find((email) => !newValue.includes(email))
        .trim();
      if (removedEmail) {
        setUsers(users.filter((email) => email !== removedEmail));
        setNonUsers(nonUsers.filter((email) => email !== removedEmail));
        setInvalidEmail(invalidEmail.filter((email) => email !== removedEmail));
      }
    }
    setRuleError(false);
    setNoRuleFieldSelected(false);
    
    // focus to the next line
    const inputElement = textFieldRef?.current?.querySelector(
      ".MuiFilledInput-root"
    );
    const inputElements =
      textFieldRef?.current?.querySelectorAll(".MuiChip-root");

    if (inputElement && inputElements && inputElements.length > 0) {
      const inputRect = inputElement.getBoundingClientRect();
      let chipHeight = 0;

      inputElements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        chipHeight = rect.height;
      });

      setTimeout(() => {
        inputElement.scrollTo({
          top: inputRect.height + Number(inputElements.length) * chipHeight,
          behavior: "smooth",
        });
      }, 100);

      inputElement.focus();
    }
    setFilteredSuggestions([]);
  };

  //Scrolling automatically when we press arrow key in the suggestions list
  const scrollToHighlightedItem = (index) => {
    const listBox = document.querySelector('[role="listbox"]');
    if (listBox) {
      const items = listBox.querySelectorAll("li");
      const item = items[index];
      if (item) {
        const listBoxRect = listBox.getBoundingClientRect();
        const itemRect = item.getBoundingClientRect();

        // Check if the item is not fully visible
        if (
          itemRect.top < listBoxRect.top ||
          itemRect.bottom > listBoxRect.bottom
        ) {
          item.scrollIntoView({
            block: "nearest", // Scroll to make the item visible
            behavior: "smooth",
          });
        }
      }
    }
  };

  //Handling keyboard keys enter,comma,space,top and bottom arrow
  const handleKeyDown = (event) => {
    event.stopPropagation();
    const totalSuggestions = filteredSuggestions.length;
    if (
      event.keyCode === 13 &&
      highlightedIndex >= 0 &&
      filteredSuggestions.length > 0
    ) {
      // Enter key
      const selectedEmail = filteredSuggestions[highlightedIndex].email.trim();
      handleChange(event, [...emails, selectedEmail]);
      setInputValue("");
      setHighlightedIndex(-1);
      event.preventDefault();
    } else if (event.keyCode === 38) {
      // Arrow up
      setHighlightedIndex((prevIndex) => {
        const newIndex = prevIndex <= 0 ? totalSuggestions - 1 : prevIndex - 1;
        scrollToHighlightedItem(newIndex);
        return newIndex;
      });
      event.preventDefault();
    } else if (event.keyCode === 40) {
      // Arrow down
      setHighlightedIndex((prevIndex) => {
        const newIndex = prevIndex >= totalSuggestions ? 0 : prevIndex + 1;
        scrollToHighlightedItem(newIndex);
        return newIndex;
      });
      event.preventDefault();
    } else if (
      event.keyCode === 32 ||
      event.keyCode === 188 ||
      event.keyCode === 13
    ) {
      // Space or comma
      if (inputValue.trim() !== "") {
        const selectedEmail = inputValue.trim();
        if (allParticipantsEmailArr?.includes(selectedEmail)) {
          setUsers([...new Set([...users, selectedEmail])]);
        } else if (emailRegex.test(selectedEmail)) {
          setNonUsers([...new Set([...nonUsers, selectedEmail])]);
        } else {
          setInvalidEmail([...new Set([...invalidEmail, selectedEmail])]);
        }
        handleChange(event, [...emails, selectedEmail]);
      }
      event.preventDefault();
    } else if (event.keyCode === 8 && inputValue === "") {
      // Backspace key and input is empty
      if (emails.length > 0) {
        handleChange(event, emails.slice(0, -1));
      }
    }
  };

  return (
    <Stack className="select-input-box">
      <Autocomplete
        freeSolo
        multiple
        id="tags-filled"
        options={allUser}
        getOptionLabel={(option) => option.label}
        value={emails}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        disableClearable={true}
        open={showSuggestions && inputValue.length > 0}
        sx={{
          "& input.MuiInputBase-input": {
            border: "0",
            height: "auto",
          },
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            // Destructure key from getTagProps
            const { key, ...tagProps } = getTagProps({ index });
            // Ensure option and option.value are defined before accessing .trim()
            const optionValue =
              typeof option === "string" ? option : option?.value?.trim() || "";
            return (
              <Chip
                style={{
                  maxWidth: "340px",
                  backgroundColor: users.includes(optionValue)
                    ? "#CFF7DA"
                    : nonUsers.includes(optionValue)
                    ? "#DFEDFF"
                    : invalidEmail.includes(optionValue)
                    ? "#FCDDDF"
                    : "inherit",
                  color:
                    users.includes(optionValue) ||
                    nonUsers.includes(optionValue) ||
                    invalidEmail.includes(optionValue)
                      ? "#0D0D0D"
                      : "inherit",
                }}
                variant="standard"
                size="small"
                label={
                  typeof option === "string" ? option : option?.label || ""
                } // Ensure option.label is defined
                key={key}
                {...tagProps}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            ref={textFieldRef}
            variant="filled"
            placeholder={
              !emails?.length &&
              `Add multiple users by separating their email addresses with a comma, space, or newline.`
            }
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
          />
        )}
        renderOption={(props, option, { index }) => {
          return (
            <li
              {...props}
              style={{
                backgroundColor:
                  highlightedIndex === index ? "lightgrey" : "inherit",
                ...props.style,
              }}
            >
              {option.label}
            </li>
          );
        }}
        filterOptions={(options, { inputValue }) => {
          const normalizedInput = inputValue.toLowerCase();
          return options.filter((option) =>
            option.email.toLowerCase().includes(normalizedInput)
          );
        }}
      />
    </Stack>
  );
};

export default ChipInputFieldForRules;
