import React from 'react'

const NoResultDocIcon = () => {
    return (
        <>
            <div className="mds-icons-empty">
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={120}
                    height={120}
                    fill="none"
                >
                    <circle cx={60} cy={60} r={60} fill="#6FCADF" fillOpacity={0.2} />
                    <path
                        fill="#060D14"
                        d="M57.802 51.04h-7.28c-1.094 0-1.981-.9-1.981-2.008 0-1.11.887-2.01 1.981-2.01h7.28c1.094 0 1.982.9 1.982 2.01 0 1.109-.888 2.009-1.982 2.009ZM50.523 62.23h19.079c1.093 0 1.981-.9 1.981-2.01 0-1.109-.888-2.009-1.981-2.009H50.523c-1.094 0-1.982.9-1.982 2.01 0 1.108.888 2.008 1.982 2.008ZM50.523 73.444h19.079c1.093 0 1.981-.9 1.981-2.01 0-1.108-.888-2.008-1.981-2.008H50.523c-1.094 0-1.982.9-1.982 2.009s.888 2.009 1.982 2.009Z"
                    />
                    <path
                        fill="#060D14"
                        fillRule="evenodd"
                        d="M36 72.317c0 8.582 5.584 14.35 13.891 14.35l20.329-.008C78.59 86.605 84 80.974 84 72.317V47.683c0-8.582-5.58-14.35-13.889-14.35l-20.33.011C41.408 33.395 36 39.023 36 47.684v24.633Zm13.794-34.955 20.317-.01c6.215 0 9.925 3.862 9.925 10.331v24.634c0 6.423-3.678 10.286-9.83 10.324l-20.317.008c-6.213 0-9.925-3.863-9.925-10.332V47.683c0-6.426 3.678-10.283 9.83-10.32Z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
        </>
    )
}

export default NoResultDocIcon