import React, { useEffect } from 'react'
import loading from "../../../Images/loader.gif";
import { useNavigate } from 'react-router-dom';
import Services from "../../../Services/auth.service";
import auth from "../../../Services/auth0services";
import authServiceCollaborator from '../../../Services/auth.service.collaborator';

const CollaboratorSignup = () => {

    const history = useNavigate();

    const processHash = (hash) => {
        auth.parseHash({ hash }, function (err, result) {
            if (err) {
                console.log("there is an error");
                console.log(err);
            } else {
                console.log(result);
                const userid = result.idTokenPayload.sub.split("|")[1];
                const media = result.idTokenPayload.sub.split("|")[0];
                var auth0_data = {
                    collaboratorId: localStorage.getItem("collaboratorId"),
                    auth0Id: userid,
                    facebookLinkedinId: userid,
                    firstName: result.idTokenPayload.name.split(" ")[0] !== undefined && result.idTokenPayload.name.split(" ")[0] !== null ? result.idTokenPayload.name.split(" ")[0] : result.idTokenPayload.given_name !== undefined && result.idTokenPayload.given_name !== null ? result.idTokenPayload.given_name : "",
                    lastName: result.idTokenPayload.name.split(" ")[1] !== undefined && result.idTokenPayload.name.split(" ")[1] !== null ? result.idTokenPayload.name.split(" ")[1] : result.idTokenPayload.family_name !== undefined && result.idTokenPayload.family_name !== null ? result.idTokenPayload.family_name : "",
                    provider: media,
                }
                authServiceCollaborator.collaboratorRegister(auth0_data)
                    .then((res) => {
                        console.log(res, "res");
                        if (res.data.status) {
                            localStorage.clear()
                            history('/collaboratorsuccess')
                        }
                    })
            }
        })
    }
    useEffect(() => {
        if (location.hash) {
            processHash(location.hash);
        }
    }, [location]);
    return (
        <>
            <div className="loadind-post-colla">
                <img src={loading} />
            </div>
        </>
    )
}

export default CollaboratorSignup