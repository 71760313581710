import React from 'react'
import './Badges.css'
import "../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"
const StatusBadge = ({ status = "offline", size = "medium" }) => {
    return (
        <div className={`online-status ${status} ${size}`} />
    )
}
StatusBadge.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
    status: PropTypes.oneOf(["online", "offline"]).isRequired
}


export default StatusBadge