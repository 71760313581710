import React, { useEffect, useState } from 'react'
import authServiceNews from '../../../Services/auth.service.news'
import TrendingPostsIcon from "../../../Images/trending-posts-icon.svg";
import TrendingPostsLink from "../../../Images/TrendingPostsLink.svg";
import { Link } from 'react-router-dom';
import moment from 'moment';

const TrendingPost = ({ loader, setLoader }) => {
    const [postData, setPostData] = useState([])
    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')

    //call on first render
    useEffect(() => {
        getTrendingPostApi()
    }, [])

    //trendingpost api call
    async function getTrendingPostApi() {
        try {
            const trendingPostRes = await authServiceNews.getTrandingPost()
            if (trendingPostRes.status) {
                setPostData(trendingPostRes.data.data)
                let arr = trendingPostRes.data.data
                const dates = arr.map(post => new Date(post.date));
                const minDate = new Date(Math.min(...dates));
                setMinDate(minDate)
                const maxDate = new Date(Math.max(...dates));
                setMaxDate(maxDate)
            }
        } catch (error) {
            setLoader(false)
            return false
        }
    }
    return (
        <>

            {loader ?
                <>
                    <div className="trending-posts-main-box-loading">
                        <div className='trending-posts-sub-title-loading skeleton-box'></div>
                        <div className='trending-posts-sub-label-loading skeleton-box'></div>
                    </div>

                    <div className="trending-posts-main-box-loading">
                        <div className='trending-posts-sub-title-loading skeleton-box'></div>
                        <div className='trending-posts-sub-label-loading skeleton-box'></div>
                    </div>

                    <div className="trending-posts-main-box-loading">
                        <div className='trending-posts-sub-title-loading skeleton-box'></div>
                        <div className='trending-posts-sub-label-loading skeleton-box'></div>
                    </div>

                    <div className="trending-posts-main-box-loading">
                        <div className='trending-posts-sub-title-loading skeleton-box'></div>
                        <div className='trending-posts-sub-label-loading skeleton-box'></div>
                    </div>

                </> :
                <>
                    <h2 className='trending-posts-title'>Trending posts</h2>
                    <h5 className='trending-posts-date'>{moment(minDate).format('MMMM DD')} — {moment(maxDate).format('MMMM DD')}</h5>
                    <div className="trending-posts-main">
                        {postData.map((item, i) => (
                            <>
                                <div className="trending-posts-main-box">
                                    <img className='trending-posts-icon' src={TrendingPostsIcon} />
                                    <h3 className='trending-posts-sub-title'>{item.title}</h3>
                                    <h6 className='trending-posts-sub-label'>{item.name}</h6>
                                    <Link to={item.url} target='_blank'>
                                        <img className='trending-posts-link' src={TrendingPostsLink} />
                                    </Link>
                                </div>
                            </>
                        )
                        )}
                    </div>
                </>
            }
        </>
    )
}

export default TrendingPost