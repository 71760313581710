import React from "react";
import filterIcon from "../../Images/filter-icon.svg";
import PropTypes from "prop-types";
import "./InputField.css";
import "../../Styles/Theme/Theme.css";
import calendarIcon from "../../Images/text_field_search.svg";
import Cancel from "../../Images/Cancel.svg";

function SearchField({
  ref,
  label = "Label",
  className = "text-field-search",
  handleChange,
  name,
  value = "",
  handleKeyUp,
  handleKeyDown,
  handleFocus,
  helperText = "",
  isError = false,
  isRounded = false,
  placeholder = "Search",
  error = "",
  image = calendarIcon,
  readOnly = false,
  handleClear,
  onBlur,
  isToanl = false
}) {
  return (
    <>
      <div className={`text-field-search-main ${isToanl ? 'tonal-search-field' : ''}`}>
        <img className="text-field-search-icon" src={image} />
        <input
          autoComplete="off"
          readOnly={readOnly}
          ref={ref}
          className={isRounded ? `${className} radius` : className}
          placeholder={placeholder}
          type="text"
          value={value}
          name={name}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={onBlur}
        />
        {value?.length > 0 && (
          <img
            onClick={handleClear}
            className="text-field-cancel-icon"
            src={Cancel}
            onBlur={onBlur}
          />
        )}
      </div>
    </>
  );
}

// define types to all props
SearchField.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.string,
  isError: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isRounded: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleChange: PropTypes.func,
  handleKeyUp: PropTypes.func,
  handleKeyDown: PropTypes.func,
  handleFocus: PropTypes.func,
  handleClear: PropTypes.func,
};

export default SearchField;
