import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Services from "../../../Services/auth.service.event";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import editIcon from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import DeleteModalWithLoader from "../../AdminCommonComponent/DeleteModalWithLoader";
import MessageModal from "../../AdminCommonComponent/MessageModal";
import AlertComponent from "../../../Hooks/AlertComponent";
import ModalPopup from '../../ModalPopup/ModalPopup'

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SearchAndSorting from "../../AdminCommonComponent/TableComponent/SearchAndSorting";
const columns = [
  { id: "drag", label: "" },
  { id: "name", label: "Name" },
  { id: "location", label: "Location" },
  { id: "action", label: "Actions" },
];

//create a object of using given key
function createData(drag, name, location, action) {
  return {
    drag,
    name,
    location,
    action,
  };
}
//Event room module
const Rooms = ({ tabInformation }) => {
  const emptyObject = { name: "", location: ""};
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allEvents, setAllevents] = useState([]);
  const [addEvent, setAddEvent] = useState("");
  const [addErr, setAddErr] = useState(emptyObject);
  const [editEvent, setEditEvent] = useState(emptyObject);
  const [editerr, setediterr] = useState(emptyObject);
  const [selectedId, setselectedId] = useState("");
  const [createInprocess, setcreateInprocess] = useState(false);
  const [editInprocess, seteditInprocess] = useState(false);
  const [locationList, setlocationList] = useState([]);
  const [selectedLocationId, setselectedLocationId] = useState("");
  const [editModalLoader, seteditModalLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletionMessage, setDeletionMessage] = useState("");
  const [deleteModalLoader, setDeleteModalLoader] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [messageModalDivsArray, setMessageModalDivsArray] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const [sortingOption, setSortingOption] = useState('');
  const [roomeName, setRoomName] = useState("")

  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [clearSearch, setClearSearch] = useState(false);


  //fetch all existing room list
  const getAllLocationApi = () => {
    Services.getAllEventLocationsByEventId(queryVariable, "")
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data;
          setlocationList(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getAllRoomApi = (isClearSearch, col, sort) => {
    let query = ''
    query += col !== undefined && col !== null && col !== "" ? `&sortField=${col}` : ""
    query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
    Services.getAllRoomsByEventId(queryVariable, query)
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          var table = [];

          for (var i = 0; i < data.length; i++) {
            table[i] = createData(
              "",
              data[i].name,
              data[i].locations.name,
              data[i]._id
            );
          }
          setAllevents(table);

          if (isClearSearch) {
            setSearchText("")
            setSortingOption("-1")
            setClearSearch(true);
          }

          if (!isClearSearch && searchText && searchText.length > 0) {
            table = table.filter((room) => {
              if (
                searchText.length > 0 &&
                room.name.toLowerCase().includes(searchText.toLowerCase())
              )
                return room;
            });
          }


          setRow(table);

        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (tabInformation === "Room") {
      setLoader(true);
      setSearchText("")
      getAllRoomApi(true);
      getAllLocationApi();
      setClearSearch(true);
      // dispatch({type:"REMOVEMODALINPROCESS"})
    }
  }, [tabInformation]);

  async function getRoomsSuggestionApi() {
    try {
      const getSuggestionRes =
        await Services.getRoomsSuggestionApi(queryVariable);
      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.name }));
        setSuggestionList(arr);
      }
    } catch (err) { }
  }

  useEffect(()=>{
    getRoomsSuggestionApi();
  },[])

  //close create room module
  const handleCloseAddModel = (e) => {
    setAddEvent("");
    setAddErr(emptyObject);
    setediterr(emptyObject);
    setselectedLocationId("");
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    dispatch({ type: "REMOVEMODALINPROCESS" })
  };

  //called when edit button is clicked
  const handleEdit = (value) => {
    setselectedId(value);
    seteditModalLoader(true);
    Services.getEventRoomById(value)
      .then((res) => {
        seteditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setselectedLocationId(data.location._id);
          setEditEvent({
            name: data.name,
          });
        }
      })
      .catch((e) => {
        seteditModalLoader(false);
        console.log(e);
      });
  };

  //search using text
  const handleSearch = (text) => {
    setSearchText(text);
    if (allEvents !== undefined && allEvents.length > 0) {
      var data = allEvents.filter((event) => {
        return event.name.toLowerCase().includes(text.trim().toLowerCase());
      });
      var table = [];
      for (var i = 0; i < data.length; i++) {
        table[i] = createData("", data[i].name, data[i].location, data[i].action);
      }
      setRow(table);
      setPage(0);
    }
  };
  //deletion room api call
  const handleDelete = () => {
    setDeleteModalLoader(true)
    Services.deleteEventRoom(deleteId)
      .then((res) => {
        setDeleteModalLoader(false);
        setDeleteId("");
        setShowDeleteModal(false);
        let actualData = res.data;
        if (res.data.status) {
          setIsFailure(false);
          getAllRoomApi(true)
          setShowAlert(true)
          setalertmsg(res.data.message)
          setTimeout(() => {
            setShowAlert(false)
            setalertmsg("")
          }, 2000);
          // var table = [];
          // var n = 0;
          // for (var i = 0; i < allEvents.length; i++) {
          //   if (allEvents[i].action !== deleteId) {
          //     table[n] = createData(
          //       "",
          //       allEvents[i].name,
          //       allEvents[i].location,
          //       allEvents[i].action
          //     );
          //     n++;
          //   }
          // }

          // setAllevents(table);
          // setRow(table);
          // setSearchText("")
        }
        else {
          setIsFailure(true);
          let messageStringArray = [];
          if (actualData.data?.sessionList.length) {
            let actList = "Session List : " + actualData.data.sessionList.toString().replace(",", ", ");
            messageStringArray.push(actList)
          }
          setMessageModalDivsArray(messageStringArray)
        }
        setDeletionMessage(actualData.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //sorting function
  const handleSort = (col, direc) => {
    setLoader(true);
    getAllRoomApi("",col, direc)
  };
  //handle onchange for create room
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setAddEvent(e.target.value);
    if (e.target.value.trim().length > 0) {
      setAddErr({ ...addErr, [e.target.name]: "" });
    }
  };
  //Create room validation and api call
  const handleCreate = () => {
    var status = true;
    var temp = {};
    if (addEvent.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (!selectedLocationId) {
      temp = { ...temp, location: "Select location!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setcreateInprocess(true);
      Services.createEventRoom({
        name: addEvent,
        location: selectedLocationId,
        event: queryVariable,
      })
        .then((res) => {
          setcreateInprocess(false);
          if (res.data.status) {
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.message);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
            }, 3000);
            getAllRoomApi();
            $("#Addroom").modal('hide')
          } else {
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.message);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
            }, 3000);
          }
          dispatch({ type: "REMOVEMODALINPROCESS" })
          dispatch({ type: "SETSTAYONPAGE", payload: null })
          setAddEvent("");
        })
        .catch((e) => {
          setcreateInprocess(false);
          console.log(e);
        });
      setAddErr(emptyObject);
      setselectedLocationId("");
    } else {
      setAddErr(temp);
    }
  };
  //
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    if (e.target.value.trim().length > 0) {
      setediterr({ ...editerr, [e.target.name]: "" });
    }
  };
  //Called when user click on update changes
  const handleEditSubmit = () => {
    var status = true;
    var tempObj = {};
    if (editEvent.name.trim().length === 0) {
      tempObj = { ...tempObj, name: "Enter name!" };
      status = false;
    }
    if (!selectedLocationId) {
      tempObj = { ...tempObj, location: "Select location!" };
      status = false;
    }
    if (Object.keys(tempObj).length > 0) {
      const emptyKeys = Object.keys(tempObj).filter((key) => tempObj[key] !== "");
      const inputElement = document.querySelectorAll(`[name="${emptyKeys[0]}"]`);
      if (inputElement) {
        inputElement[1].focus(); // Focus the input element
      }
    }
    if (status) {
      seteditInprocess(true);
      Services.editEventRoom({
        id: selectedId,
        data: {
          name: editEvent.name,
          location: selectedLocationId,
        },
      })
        .then((res) => {
          if (res.data.status) {
            setShowAlertEdit(true);
            setAlertMsgEdit(res.data.message);
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
            }, 3000);
            getAllRoomApi(false);
            seteditInprocess(false);
            $("#editRoom").modal('hide')
          } else {
            setShowAlertEdit(true);
            setAlertMsgEdit(res.data.message);
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
            }, 3000);
          }
          dispatch({ type: "REMOVEMODALINPROCESS" })
          dispatch({ type: "SETSTAYONPAGE", payload: null })
          seteditInprocess(false);
        })
        .catch((e) => {
          console.log(e);
          seteditInprocess(false);
        });
      setediterr(emptyObject);
    } else {
      setediterr(tempObj);
    }
  };
  const handleSelectAddress = (event) => {
    const selectedAddressId = event.target.value;
    setselectedLocationId(selectedAddressId);
    if (selectedAddressId) {
      setAddErr({ ...addErr, location: "" });
      setediterr({ ...editerr, location: "" });
    }
  };
  //close delete modal event
  const handleDeleteModalCloseEvent = () => {
    setShowDeleteModal(false);
    setDeleteId("");
  }

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($('#Addroom').hasClass('show')) {
        $("#Addroom").modal('show')
      }

      if ($('#editRoom').hasClass('show')) {
        $("#editRoom").modal('show')
      }

    }
    else if (stayOnPage !== null && !stayOnPage) {
      if ($('#Addroom').hasClass('show')) { $("#Addroom").modal('hide') }

      if ($('#editRoom').hasClass('show')) { $("#editRoom").modal('hide') }
    }

  }, [stayOnPage])

  //handle drag and reorder rows of rooms
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const updatedRows = Array.from(allEvents);
    const [movedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, movedRow);
    setAllevents(updatedRows);
    setRow(updatedRows)
    const data = { ids: updatedRows.map(r => r.action) }
    Services.reorderRooms(data, queryVariable)
  }

   //object from array
   const createObjectFromArray = (arr, updateAllEventArray = true) => {
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].name,
        arr[i].address,
        arr[i].city,
        arr[i].country,
        arr[i].action
      );
    }
    setPage(0);
    setRow(tbl);
    if (updateAllEventArray) setAllevents(tbl);
  };

  return (
    <>
<SearchAndSorting
        currentArray={allEvents}
        createObjectFromArray={(arr) => {
          setRow(arr);
          setPage(0);
        }}
        sortingOn={"name"}
        suggestionOn={"name"}
        searchOn={["name", "address"]}
        placeholderText={"Search location"}
        clearSearch={clearSearch}
        setClearSearch={setClearSearch}
        searchedText={searchText}
        setSearchedText={setSearchText}
        suggestionList={suggestionList}
        setSuggestionList={setSuggestionList}
        suggestions={suggestions}
        setSuggestions={setSuggestions}
        listIndex={listIndex}
        setListIndex={setListIndex}
        suggestionBox={suggestionBox}
        setSuggestionBox={setSuggestionBox}
      >
        <div className="uic-title-rt-only mt-0 position-relative end-0 ms-auto">
          <div className="uic-fl-btn">
          <button className="btn" data-toggle="modal" data-target="#Addroom" onClick={(e) => {
            handleCloseAddModel(e);
            setSortingOption("-1")
          }}>
            Create Room
          </button>
          </div>
        </div>
      </SearchAndSorting>

      {showAlert && <div className="alert alert-info">{alertmsg}</div>}
      <Paper className="uic-tb-mn mt-lg-4 mt-3">
        <DragDropContext onDragEnd={onDragEnd}>
          <TableContainer className="uic-table-main">
            <Table className="uic-table">
              <TableHead className="uic-table-th">
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      className="uic-th-td w-10"
                    >
                      {(column.id !== "action" && column.id !== "drag") ? (
                        <>
                          <span className="custom-sorting">
                            <i
                              className="fa fa-angle-down"
                              onClick={(e) => {
                                handleSort(column.id, "Dec");
                              }}
                            ></i>
                            <i
                              className="fa fa-angle-up"
                              onClick={(e) => {
                                handleSort(column.id, "Asc");
                              }}
                            ></i>
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loader ?
                <div className="cstm-data-load"><img src={Loader} /> </div> :
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length > 0 ?
                  <Droppable droppableId="table">
                    {(provided) => (
                      <TableBody ref={provided.innerRef} {...provided.droppableProps} className="uic-table-td">
                        {rows
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            return (
                              <Draggable key={row.action} draggableId={row.action} index={index}>
                                {(provided) => (
                                  <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={row.code}
                                    className="uic-th-td w-20"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {columns.map((column) => {
                                      const value = row[column.id];

                                      return column.id === "name" ? (
                                        <TableCell key={column.id} className="uic-th-td w-17">
                                          <a
                                            data-toggle="modal"
                                            data-target="#editRoom"
                                            onClick={() => {
                                              handleEdit(row['action']);

                                            }}
                                            className="cstm-view-users-count"
                                          >
                                            {value}
                                          </a>
                                        </TableCell>
                                      ) : column.id === "location" ? (
                                        <TableCell key={column.id} className="uic-th-td w-17">
                                          {value}
                                        </TableCell>
                                      ) : column.id === "action" ? (
                                        <TableCell
                                          key={column.id}
                                          className="uic-th-td uic-table-icon w-17"
                                        >
                                          <a
                                            data-toggle="modal"
                                            data-target="#editRoom"
                                            onClick={() => {
                                              handleEdit(value);
                                            }}
                                          >
                                            <img src={editIcon} />
                                          </a>
                                          {row["name"] !== "others" && (
                                            <a
                                              onClick={() => {
                                                setDeleteId(value);
                                                setShowDeleteModal(true);
                                                setRoomName(row.name)
                                              }}
                                            >
                                              <img src={imgDelete} />
                                            </a>
                                          )}
                                        </TableCell>) : (<TableCell className="uic-th-td w-4">
                                          <i class="fas fa-arrows-alt banner-drag-drop"></i>
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                )}
                              </Draggable>

                            );
                          })}
                      </TableBody>)}</Droppable> : <span className="no-data-found">No data found</span>}
            </Table>
          </TableContainer>
        </DragDropContext>
      </Paper>
      <div
        className="modal fade edit-user-details-popup"
        id="Addroom"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Room</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                  } else {
                    $("#Addroom").modal('hide')
                    handleCloseAddModel(e)
                  }

                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleAddChange}
                    value={addEvent}
                  />
                  <span className="error">{addErr.name}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Location*</div>
                <div className="form-group-ct">
                  <select
                    onChange={handleSelectAddress}
                    value={selectedLocationId}
                    className="shortenedSelect"
                  >
                    <option value="">Select an address</option>
                    {locationList.map((location) => (
                      <option
                        key={location._id}
                        value={location._id}
                        data-address={location.name}
                      >
                        {location.name.slice(0, 70)}
                        {location.name.length > 70 ? "..." : ""}
                      </option>
                    ))}
                  </select>
                  <span className="error">{addErr.location}</span>
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editRoom"
        tabIndex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Room</h2>
                <button
                  type="button"
                  className="close"
                  //data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {

                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })



                    } else {
                      $("#editRoom").modal('hide')
                      handleCloseAddModel(e)
                    }

                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      onChange={handleEditChange}
                      value={editEvent.name}
                    />
                    <span className="error">{editerr.name}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Location*</div>
                  <div className="form-group-ct">
                    <select
                      onChange={handleSelectAddress}
                      value={selectedLocationId}
                      className="shortenedSelect"
                    >
                      <option value="">Select an address</option>
                      {locationList.map((location) => (
                        <option
                          key={location._id}
                          value={location._id}
                          data-address={location.name}
                        >
                          {location.name.slice(0, 70)}
                          {location.name.length > 70 ? "..." : ""}
                        </option>
                      ))}
                    </select>
                    <span className="error">{editerr.location}</span>
                  </div>
                </div>
              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteModalLoader ? "danger" : 'secondary'}
        buttonText1={!deleteModalLoader ? "Delete" : <img src={Loader2} width={50} height={15} className="btn-loader-img-cover" />}
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete room"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>Are you sure you want to delete this "{roomeName}"? This action cannot be undone.</p>
        </div>


      </ModalPopup>
      {/* {showDeleteModal && (
        <DeleteModalWithLoader
          message={"Are you sure you want to delete this room?"}
          handleDelete={handleDelete}
          onClose={handleDeleteModalCloseEvent}
          loader={deleteModalLoader}
        />
      )} */}
      <MessageModal deletionMessage={deletionMessage} setDeletionMessage={setDeletionMessage} failure={isFailure} additionalDivArray={messageModalDivsArray} />
      <AlertComponent />
    </>
  );
};
export default Rooms;
