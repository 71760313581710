import React from 'react';
import imgBigDelete from "../../Images/BackendImages/delete-icon.svg";
import loading from "../../Images/loader.gif";

const DeleteModalWithLoader = ({ message, handleDelete, onClose, loader = false, modalId = "deleteModal"}) => {

    return (
        <>

        <div className="modal fade msg-popup delete-modal-with-loader show" id={modalId} tabIndex="-1" role="dialog" aria-labelledby="example" aria-hidden="true" style={{display:"block"}}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="close" onClick={()=>{if(!loader)onClose()}}>
                        &times;
                    </button>
                    <div className="msg-icon">
                        <img src={imgBigDelete} alt="" />
                    </div>
                    <h1>{message}</h1>
                    <div className="row">
                        <div className="col-6">
                            <span className="msg-btn">
                                <button type="button" className="btn btn-red" onClick={handleDelete}>
                                    Delete
                                    {loader&&<span className='create-group-loader'><img src={loading} alt=''/></span>}
                                </button>
                            </span>
                        </div>
                        <div className="col-6">
                            <span className="msg-btn">
                                <button type="button" className="btn2" onClick={()=>{if(!loader)onClose()}}>
                                    Discard
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        </>
    );
};

export default DeleteModalWithLoader;