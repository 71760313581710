import React from 'react'
import './FilterIcon.css'
import "../../Styles/Theme/Theme.css"
// import Button from '../Button/Button'
import Badges from '../Badge/Badges'
import PropTypes from "prop-types"
import FilterIcons from '../Icons/GeneralIcons/FilterIcon';
import Chips from '../Chips/Chips'


const FilterIcon = ({ btnCls, bdCls, filterText = 'Filter Text', size = "large", hasIcon = true, hasText = false, handleClick, label, icon = <FilterIcons /> }) => {
    return (
        <>
            <div className='filter-button'>
                <Chips
                    className={size}
                    handleClick={handleClick}
                    hasIconRight={hasIcon}
                    label={hasText && filterText}
                    icon={icon}
                />
                {label > 0 && <Badges label={label} />}
            </div>
        </>
    )
}

// define types to all props
FilterIcon.propTypes = {
    btnCls: PropTypes.string,
    filterText: PropTypes.string,
    hasText: PropTypes.bool,
    hasIcon: PropTypes.bool,
    bdCls: PropTypes.string,
    handleClick: PropTypes.func
}
export default FilterIcon