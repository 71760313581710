const allMedia = [];

const AllMediaPreview = (state = allMedia, action) => {
  switch (action.type) {
    case "FETCHIMAGEANDVIDEO":
      return action.payload;
    case "APPENDMEDIA":
      return [...state, ...action.payload];
    case "DELETEMEDIA":
      return state.map((data) => {
        let imageArray = data.images.filter((image) => {
          return image.url !== action.payload;
        });

        let videoArray = data.videos.filter((video) => {
          return video.url !== action.payload;
        });
        return { ...data, images: imageArray, videos: videoArray };
      });
    case "ADDMEDIA":
      let data = {
        _id: action.payload._id,
        sender: action.payload.sender.id,
        userTimeStamp: action.payload.userTimeStamp,
        images: action.payload.images,
        videos: action.payload.videos,
      };
      return [data, ...state];
    case "DELETEMEDIARECORD":
      return state.filter((data) => data._id !== action.payload);
    case 'LOGOUT':
      return allMedia;
    default:
      return state;
  }
};

export default AllMediaPreview;
