import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import arrowimg from "../../../Images/arow-left-right.png";
import { useGuestTicketSuggestionListQuery } from "../../../Services/guestTicket";
import { useAddonsByEventIdQuery } from "../../../Services/addons";

import ListGuestTicket from "./ListGuestTicket";
import CreateGuestTicket from "./CreateGuestTicket";
import EditGuestTicket from "./EditGuestTicket";

//Event Guest Tickets module
const GuestTicketManagement = () => {
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionList, setSuggestionList] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "days").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [filterAddon, setFilterAddon] = useState("");
  const [filterType, setFilterType] = useState("");
  const [finalSearchedText, setFinalSearchedText] = useState("");
  const [fromDateIsoString, setFromDateIsoString] = useState();
  const [toDateIsoString, setToDateIsoString] = useState();
  const [isCloseAddModal, setCloseAddModal] = useState(false);
  const [editId, setEditId] = useState("");
  const [forceRender, setForceRender] = useState(false);
  const [isChangeDate, setIsChangeDate] = useState(true);

  const fromDateRef = useRef(fromDate);
  const toDateRef = useRef(toDate);


  //event id
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("id");
  const addonId = new URLSearchParams(location.search).get("addonId");

  //get all the guest ticket suggestions
  const { data: suggestionData } = useGuestTicketSuggestionListQuery({
    id: eventId,
  });

  //get addons list
  const { data: addonList } = useAddonsByEventIdQuery({
    event_id: eventId,
    params: {
      page: 1,
      limit: 100,
    },
  });

  useEffect(() => {
    if (suggestionData) showMainTicketSuggestionList();
  }, [suggestionData]);

  useEffect(() => {
    setFilterAddon(addonId);
  }, [addonId]);

  //search using text
  const handleSearch = (text) => {
    setSearchedText(text);
    setSuggestionBox(false);
    setFinalSearchedText(text);
  };

  //show guest ticket suggestion list
  const showMainTicketSuggestionList = () => {
    if (suggestionData?.status) {
      let data = suggestionData.data;
      const arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.name }));
        setSuggestionList(arr);
      }
    }
  };

  // on arrow key up and down when list index change that time this useefect call
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      const currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        const text = currentList.innerText;
        setSearchedText(text);
      }
    }
  }, [listIndex]);

  //pricetype filtering
  const handleFilterPriceType = (e) => {
    setSelectedType(e.target.value);
  };

  //addons filter
  const handleFilterAddons = (e) => {
    setFilterAddon(e.target.value);
  };

  //filter date
  const handleOnChangeFilterType = (e) => {
    setFilterType(e.target.value);
    let fromDateNew = new Date(fromDate);
    let fromDateString = fromDateNew.toISOString();

    let todateNew = new Date(toDate);
    let toDateString = todateNew.toISOString();
    if (e.target.value === "custom") {
      setFromDateIsoString(fromDateString);
      setToDateIsoString(toDateString);
    } else {
      setFromDateIsoString("");
      setToDateIsoString("");
    }
  };

  //check date changed or not
  const handleDateChange = (date, setDateFunction, dateRef) => {
    setIsChangeDate(false)
    if (date !== dateRef.current) {
      setDateFunction(date);
      setIsChangeDate(true)
    }
  };
  //called when calender close
  const handleCalenderClose = (e) => {
    if (filterType === "custom") {
      let fromDateNew = new Date(fromDate);
      let fromDateString = fromDateNew.toISOString();

      let todateNew = new Date(toDate);
      let toDateString = todateNew.toISOString();
      if (fromDateString !== "" && toDateString !== "" && isChangeDate) {
        setFromDateIsoString(fromDateString);
        setToDateIsoString(toDateString);
      }
    }
  };

  //get the edit id
  const handleEditId = (id) => {
    setEditId(id);
    setForceRender((prev) => !prev);
  };

  return (
    <div>

      <div className="d-flex flex-wrap align-items-center gap-lg-4 gap-2 mb-lg-4 mb-sm-3 mb-2">
        <div className="search-input position-relative mb-0 me-0 flex-grow-1">
          <input
            type="search"
            placeholder="Search"
            className="w-100"
            value={searchedText}
            onChange={(e) => {
              setSearchedText(e.target.value);
              let currentValue;
              if (e.target.value) {
                currentValue = e.target.value;
              } else {
                currentValue = e.target.value;
                setSearchedText(currentValue);
              }
              setActualsearch(currentValue);
              if (suggestionList !== undefined && suggestionList.length > 0) {
                setListIndex(-1);
                setSuggestionBox(true);
                let searchResult = suggestionList.filter((user) => {
                  return user.name
                    .toLowerCase()
                    .includes(e.target.value.trim().toLowerCase());
                });
                setSuggestions(searchResult);
              }
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                handleSearch(e.target.value);
              } else if (e.key === "ArrowDown") {
                const listLength =
                  suggestions.length > 15 ? 15 : suggestions.length;
                if (listIndex < listLength - 1) {
                  setListIndex(listIndex + 1);
                } else if (listIndex === listLength - 1) {
                  setListIndex(-1);
                  setSearchedText(actualsearch);
                }
              } else if (e.key === "ArrowUp") {
                e.preventDefault();
                const listLength =
                  suggestions.length > 15 ? 15 : suggestions.length;
                if (listIndex === 0) {
                  setListIndex(-1);
                  setSearchedText(actualsearch);
                } else if (listIndex === -1) {
                  setListIndex(listLength - 1);
                } else {
                  setListIndex(listIndex - 1);
                }
              }
            }}
            onBlur={() => setSuggestionBox(false)}
          />

          {suggestionBox && suggestions.length > 0 && searchedText.length > 0 && (
            <div className="suggestion-box">
              <ul id="suggestion-list">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onMouseDown={() => handleSearch(suggestion.name)}
                    className={index === listIndex ? "selected-active" : ""}
                  >
                    {searchedText !== null &&
                      searchedText.length > 0 &&
                      suggestion.name
                        .toLowerCase()
                        .startsWith(searchedText.toLowerCase()) ? (
                      <>
                        <b>{suggestion.name.slice(0, searchedText.length)}</b>
                        {suggestion.name &&
                          suggestion.name.slice(
                            searchedText.length,
                            suggestion.name.length
                          )}{" "}
                      </>
                    ) : (
                      suggestion.name
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <i
            className="fa fa-search"
            onClick={() => handleSearch(searchedText)}
          ></i>
        </div>

        <div className="uic-fl-btn d-flex align-items-center flex-grow-1">
          <div className="sort-by-mn-title flex-shrink-0">Filter by : </div>
          <div className="w-100">
            <select value={selectedType} onChange={handleFilterPriceType}>
              <option value="">Select Type</option>
              <option value="free">Free</option>
              <option value="base">Base</option>
              <option value="individual">Individual</option>
            </select>
          </div>
        </div>

        <div className="uic-fl-btn lifetime-only flex-grow-1 ms-0">
          <div className="w-100">
            <select value={filterAddon} onChange={handleFilterAddons}>
              <option value="">Select addons</option>
              {addonList?.data?.map((addon) => (
                <option value={addon._id}>{addon.name}</option>
              ))}
            </select>
          </div>
        </div>

        <div className="uic-fl-btn lifetime-only flex-grow-1 ms-0">
          <div className="w-100">
            <select value={filterType} onChange={handleOnChangeFilterType}>
              <option value="">Lifetime</option>
              <option value="todays">Last 24 h</option>
              <option value="past7days">Last 7 Days</option>
              <option value="past30days">Last 30 Days</option>
              <option value="past90days">Last 90 Days</option>
              <option value="past365days">Last 365 days</option>
              <option value="custom">Custom</option>
            </select>
          </div>
        </div>

        {filterType === "custom" ? (
          <div className="cstm-select-ap-drop documenlist-datepicker d-flex align-items-center gap-sm-0 gap-2 flex-wrap cstm-datepicker-field ms-0">
            <DatePicker
              showIcon
              selected={fromDate}
              onChange={(date) => handleDateChange(date, setFromDate, fromDateRef)}
              onCalendarClose={handleCalenderClose}
            />
            <img src={arrowimg} className="cstm-arrow-left-right" />
            <DatePicker
              showIcon
              selected={toDate}
              onChange={(date) => handleDateChange(date, setToDate, toDateRef)}
              onCalendarClose={handleCalenderClose}
            />
          </div>
        ) : (
          <></>
        )}

        <div className="uic-fl-btn ms-auto">
          <button
            className="btn"
            data-toggle="modal"
            data-target="#CreateGuestTickets"
            onClick={(e) => {
              setCloseAddModal(true);
              setTimeout(() => {
                setCloseAddModal(false);
              }, 1500);
            }}
          >
            Create Guest Ticket
          </button>
        </div>
      </div>

      <ListGuestTicket
        handleEditId={handleEditId}
        finalSearchedText={finalSearchedText}
        selectedType={selectedType}
        filterType={filterType}
        fromDateIsoString={fromDateIsoString}
        toDateIsoString={toDateIsoString}
        filterAddon={filterAddon}
      />
      {/* add modal */}
      <CreateGuestTicket
        isCloseAddModal={isCloseAddModal}
        addonList={addonList?.data}
        forceRender={forceRender}
      />
      {/* //edit modal */}
      <EditGuestTicket
        editId={editId}
        forceRender={forceRender}
        isCloseAddModal={isCloseAddModal}
      />
    </div>
  );
};

export default GuestTicketManagement;
