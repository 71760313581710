import React from 'react'
import PropTypes from "prop-types"
const ExclamationIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                d="M11.994 13.373a.75.75 0 0 1-.75-.75V8.204a.75.75 0 0 1 1.5 0v4.419a.75.75 0 0 1-.75.75ZM10.999 15.796a1.003 1.003 0 0 0 2.005 0 1 1 0 0 0-1-1h-.01a.995.995 0 0 0-.995 1Z"
            />
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M12 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10Zm0-18.5c-4.687 0-8.5 3.813-8.5 8.5 0 4.687 3.813 8.5 8.5 8.5 4.687 0 8.5-3.813 8.5-8.5 0-4.687-3.813-8.5-8.5-8.5Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
ExclamationIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default ExclamationIcon