import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
//import api file
import Services from "../../Services/auth.service";
// Import images
import unblock from "../../Images/BackendImages/unlock.svg";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import AdminSidebar from "../AdminSidebar";
import UserInfoSideBar from './UserInfoSideBar';
import authService from "../../Services/auth.service";
import ModalPopup from '../../components/ModalPopup/ModalPopup'
import Loader2 from "../../Images/loader2.gif";
const columns = [
  { id: "firstName", label: "First name" },
  { id: "lastName", label: "Last name" },
  { id: "email", label: "Email" },
  { id: "createdAt", label: "Joined" },
  { id: "last_activity_log", label: "Last activity log" },
  { id: "last_login", label: "Last Sign On" },
  { id: "action", label: "Actions" },
];

function createData(
  firstName,
  lastName,
  email,
  createdAt,
  last_activity_log,
  last_login,
  action
) {
  return {
    firstName,
    lastName,
    email,
    createdAt,
    last_activity_log,
    last_login,
    action,
  };
}
const Blockeduserslist = () => {
  const [users, setUsers] = useState([]);
  const [rows, setrow] = useState([]);
  const [page, setPage] = useState(0);
  const [tblalluser, settblalluser] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedUid, setselectedUid] = useState("");
  const [searchText, setSearchText] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteModalLoader, setDeleteModalLoader] = useState(false);
  let history = useNavigate();
  const [isMenu, setMenu] = useState(false)

  //call when page loads
  useEffect(() => {
    getBlockedUserList()
  }, []);

  //function to get block users list
  function getBlockedUserList(col, sort) {
    let query = ""
    query += col !== undefined && col !== null && col !== "" ? `&sortField=${col}` : ""
    query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
    Services.getblockeduser(query)
      .then((res) => {
        console.log(res.data,'data')
        if (res.data.length > 0) {
          var arr = res.data.filter((u) => {
            return u;
          });
          setUsers(arr);
          var newarr = [];
          for (var i = 0; i < arr.length; i++) {
            var date =
              arr[i].createdAt !== undefined
                ? moment(arr[i].createdAt).format("MMM DD, YYYY [at] hh:mm a")
                : "-";
            newarr[i] = createData(
              arr[i].first_name ? arr[i].first_name : "",
              arr[i].last_name ? arr[i].last_name : "",
              arr[i].email,
              date,
              arr[i].last_activity_log !== undefined &&
                arr[i].last_activity_log !== null
                ? moment(arr[i].last_activity_log).format(
                  "MMM DD, YYYY [at] hh:mm a"
                )
                : "-",
              arr[i].last_login !== undefined && arr[i].last_login !== null
                ? moment(arr[i].last_login).format("MMM DD, YYYY [at] hh:mm a")
                : "-",
              arr[i]._id
            );
          }
          settblalluser(newarr);
          setrow(newarr);
          getallusersSuggestionListApi()
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  //call when page changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //call when rows changes
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //call when unblock user
  const handleUnblock = () => {
    Services.unblockuser({ id: selectedUid })
      .then((res) => {
        if (res) {
          setshowalert(true);
          setalertmsg("Successfully unblocked!");
          setTimeout(() => {
            setshowalert(false);
          }, 2000);
          Services.getblockeduser()
            .then((res) => {
              if (res.data.length > 0) {
                var arr = res.data.filter((u) => {
                  return u;
                });
                var newarr = [];
                for (var i = 0; i < arr.length; i++) {
                  var date =
                    arr[i].createdAt !== undefined
                      ? moment(arr[i].createdAt).format("MMM DD, YYYY [at] hh:mm a")
                      : "-";
                  newarr[i] = createData(
                    arr[i].first_name !== undefined && arr[i].first_name !== null ? arr[i].first_name : "",
                    arr[i].last_name  !== undefined && arr[i].last_name  !== null ? arr[i].last_name : "",
                    arr[i].email,
                    date,
                    arr[i].last_activity_log !== undefined &&
                      arr[i].last_activity_log !== null
                      ? moment(arr[i].last_activity_log).format(
                        "MMM DD, YYYY [at] hh:mm a"
                      )
                      : "-",
                    arr[i].last_login !== undefined && arr[i].last_login !== null
                      ? moment(arr[i].last_login).format("MMM DD, YYYY [at] hh:mm a")
                      : "-",
                    arr[i]._id
                  );
                }
                setrow(newarr);
              }
              else {
                setrow([])
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //call when user search
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getBlockedUserSuggestionList()
      console.log(getSuggestionRes, "getSuggestionRes");
      let data = getSuggestionRes.data
      var arr = []
      if (data && data.length !== undefined && data.length !== null && data.length > 0) {
        data.map((item) => arr.push({ name: `${item.first_name} ${item.last_name}` }))
        console.log(arr);
        setSuggestionList(arr);
      }
    } catch (err) {

    }
  }
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex]
      if (currentList) {
        var text = currentList.innerText
        setSearchText(text);
      }
    }
  }, [listIndex]);

  //call when delete block user
  const handleDelete = () => {
    setDeleteModalLoader(true)
    Services.deleteuser({ id: selectedUid })
      .then((res) => {
        setDeleteModalLoader(false)
        Services.getblockeduser()
          .then((res) => {
            if (res.data.length > 0) {
              setshowalert(true);
              setalertmsg("Successfully deleted user!");
              setDeleteModal(false)
              setTimeout(() => {
                setshowalert(false);
              }, 2000);
              var arr = res.data.filter((u) => {
                return u;
              });
              var newarr = [];
              for (var i = 0; i < arr.length; i++) {
                var date =
                  arr[i].createdAt !== undefined
                    ? moment(arr[i].createdAt).format("MMM DD, YYYY [at] hh:mm a")
                    : "-";
                newarr[i] = createData(
                  arr[i].first_name !== undefined && arr[i].first_name !== null  ? arr[i].first_name : "",
                  arr[i].last_name !== undefined && arr[i].last_name !== null  ?arr[i].last_name : "",
                  arr[i].email,
                  date,
                  arr[i].last_activity_log !== undefined &&
                    arr[i].last_activity_log !== null
                    ? moment(arr[i].last_activity_log).format(
                      "MMM DD, YYYY [at] hh:mm a"
                    )
                    : "-",
                  arr[i].last_login !== undefined && arr[i].last_login !== null
                    ? moment(arr[i].last_login).format("MMM DD, YYYY [at] hh:mm a")
                    : "-",
                  arr[i]._id
                );
              }
              setrow(newarr);
            }
            else {
              setrow([])
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSearch = () => {

    if (users.length > 0) {
      var arr = users.filter((u) => {
        if (
          (u.first_name.includes(searchText)) ||
          u.email.includes(searchText)
        )
          return u;

      });
      var newarr = [];
      for (var i = 0; i < arr.length; i++) {
        var date =
          arr[i].createdAt !== undefined
            ? moment(arr[i].createdAt).format("MMM DD, YYYY [at] hh:mm a")
            : "-";
        newarr[i] = createData(
          arr[i].first_name !== undefined && arr[i].first_name !== null ? arr[i].first_name : "",
          arr[i].last_name !== undefined && arr[i].last_name !== null ?arr[i].last_name : "",
          arr[i].email,
          date,
          arr[i].last_activity_log !== undefined &&
            arr[i].last_activity_log !== null
            ? moment(arr[i].last_activity_log).format(
              "MMM DD, YYYY [at] hh:mm a"
            )
            : "-",
          arr[i].last_login !== undefined && arr[i].last_login !== null
            ? moment(arr[i].last_login).format("MMM DD, YYYY [at] hh:mm a")
            : "-",
          arr[i]._id
        );
      }
      setrow(newarr);
    }
    setSuggestionBox(true);
  };
  const handleSort = (col, direc) => {
    getBlockedUserList(col, direc)
  };

  const deleteUserName = (deleteId) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].action === deleteId) {
        return rows[i].firstName + " " + rows[i].lastName;
      }
    }
  };
  return (
    <>
      <div className="users-info">
        <UserInfoSideBar />
        <div className="users-info-content">
          <div className="users-info-content-main">
            {showalert && (
              <div className="alert alert-info">{alertmsg}</div>
            )}
            <div className="uic-title">
              <h1>Blocked users</h1>
            </div>
            <div className="uic-fl">
              <div className="uic-fl-search">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-user-input"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    var currentValue;
                    if (e.target.value) {
                      currentValue = e.target.value;
                    } else {
                      currentValue = e.target.value;
                      setSearchText(currentValue);
                    }
                    setActualsearch(currentValue);
                    if (suggestionList !== undefined && suggestionList.length > 0) {
                      setListIndex(-1);
                      setSuggestionBox(true)
                      var searchResult = suggestionList.filter((user) => {
                        return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                      });
                      setSuggestions(searchResult);
                    }
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch(e.target.value)
                    } else if (e.key === "ArrowDown") {
                      const listLength =
                        suggestions.length > 15
                          ? 15
                          : suggestions.length;
                      if (listIndex < listLength - 1) {
                        setListIndex(listIndex + 1);
                      } else if (listIndex === listLength - 1) {
                        setListIndex(-1);
                        setSearchText(actualsearch);
                      }
                    } else if (e.key === "ArrowUp") {
                      e.preventDefault();
                      const listLength =
                        suggestions.length > 15
                          ? 15
                          : suggestions.length;
                      if (listIndex === 0) {
                        setListIndex(-1);
                        setSearchText(actualsearch);
                      } else if (listIndex === -1) {
                        setListIndex(listLength - 1);
                      } else {
                        setListIndex(listIndex - 1);
                      }
                    }
                  }}
                  onBlur={() => setSuggestionBox(false)}
                />

                {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.name)}
                          className={index === listIndex ? 'selected-active' : ''}
                        >
                          {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length - 1)} </> : suggestion.name}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

                </i>
              </div>
            </div>

            <Paper className="uic-tb-mn">
              <TableContainer className="uic-table-main">
                <Table className="uic-table min-w-auto">
                  <TableHead className="uic-table-th">
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            column.id === "action"
                              ? "uic-th-td w-15"
                              : "uic-th-td w-10"
                          }
                        >
                          {" "}
                          {column.id !== "action" ? (
                            <>
                              <span className="custom-sorting">
                                <i
                                  className="fa fa-angle-down"
                                  onClick={(e) => {
                                    handleSort(column.id, "Dec");
                                  }}
                                ></i>
                                <i
                                  className="fa fa-angle-up"
                                  onClick={(e) => {
                                    handleSort(column.id, "Asc");
                                  }}
                                ></i>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                            className="uic-th-td w-20"
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return column.id !== "action" ? (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td w-10"
                                >
                                  {value}
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td uic-table-icon w-15"
                                >
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setselectedUid(value);
                                      setDeleteModal(true)
                                    }}
                                  >
                                    <img src={imgdelete} />
                                  </a>
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#block"
                                    onClick={() => {
                                      setselectedUid(value);
                                    }}
                                  >
                                    <img src={unblock} />
                                  </a>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                {rows.length === 0 ? (
                  <div className="no-data-found">
                    <p>No Data Found </p>
                  </div>
                ) : (
                  <></>
                )}{" "}
              </TableContainer>
              <TablePagination
                className="uic-pagination"
                rowsPerPageOptions={[20, 50, 100, 200]}
                component="div"
                count={Math.ceil(rows.length / rowsPerPage)}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteModalLoader ? "danger" : 'secondary'}
        buttonText1={!deleteModalLoader ? "Delete" : <img src={Loader2} width={50} height={15} className="btn-loader-img-cover" />}
        isOpen={deleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete blocked user"}
      >
        <div className="ctsm-modal-body">
          <p>Are you sure you want to permanently delete this {deleteUserName(selectedUid)}? This action cannot be undone.</p>
        </div>


      </ModalPopup>
      {/* Modal Block */}
      <div
        className="modal fade msg-popup"
        id="block"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={unblock} />
            </div>
            <h1>Are you sure you want to unblock this user?</h1>
            <div className="row">
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={handleUnblock}
                    data-dismiss="modal"
                  >
                    Unblock
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn" data-dismiss="modal">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default Blockeduserslist;



