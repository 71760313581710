import React, { useEffect } from "react";
import IcnMCrossWhite from "../../../Images/icn_m_cross_white.svg";
import Button from "../../../components/Button/Button";
const NoDownload = ({ isDoc, data, setRestrict, setSelectedRow }) => {
  useEffect(() => {
    if (!isDoc) {
      setRestrict(false);
      setSelectedRow({});
    }
  }, [isDoc]);
  return (
    <div className={`${isDoc ? "modal-fadein" : "modal-fadeout"}`}>
      <div className="desk-globle-model-box globle-model-box doc-file-modal-only">
        <div className="desk-globle-model-box-inner doc-modal-only sidebar">
          <div className="doc-modal-only-left">
            <div className="doc-modal-only-left-top">
              <div className="doc-modal-only-left-top-left">
                <button
                  className="doc-modal-icon"
                  onClick={() => {
                    setRestrict(false);
                    setSelectedRow({});
                  }}
                >
                  <img src={IcnMCrossWhite} />
                </button>
                <h5 className="doc-modal-filename">
                  {data.fileName !== undefined && data.fileName
                    ? data.fileName
                    : ""}
                </h5>
              </div>
              <div className="doc-modal-only-left-top-right">
                <button
                  className="doc-modal-btn"
                  disabled={data?.userDownloadBlockStatus}
                >
                  Download
                </button>
              </div>
            </div>
            <div className="preview-not-available-main">
              <div className="preview-not-available">
                <div className="preview-not-available-body">
                  <h4>Download Limit Reached</h4>
                  <p className="you-can-downlod-text">
                  Oops! It seems you've hit the documents download limit. 
                  </p>
                </div>
                <div className="preview-not-available-footer">
                  
                <Button
              label="Cancel"
              variant="secondary"
              size="medium"
              handleClick={()=>
              {
                setRestrict(false);
                setSelectedRow({});
              }}
              
              
            >
              {" "}
            </Button>
            <Button
             label="Contact Us"
             variant="secondary"
             size="medium"
             handleClick={()=>
             {
              if (window.Intercom) {
                window.Intercom('show');
            }
             }}
             
             
           >
             {" "}
           </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDownload;
