import { useEffect, useState } from "react";
//import image
import deletefill from "../../Images/BackendImages/delete-fill.svg";
import { Link,Navigate, useNavigate , useSearchParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AlertComponent from "../../Hooks/AlertComponent";

const EditField = ({
  fields,
  setFields,
  fieldindex,
  selectedField,
  styleDiv,
  setstyleDiv,
}) => {
  const [showOption, setshowOption] = useState(false);
  const [showaddoptionfield, setshowaddoptionfield] = useState(false);
  const [showInfoDiv, setshowInfoDiv] = useState(
    fields[fieldindex] && fields[fieldindex]["fields"][selectedField]["info"].length > 0
      ? true
      : false
  );
  const [showvalidDiv, setshowvalidDiv] = useState(
    fields[fieldindex]  ? fields[fieldindex]["fields"][selectedField]["required"].length > 0 ||
      fields[fieldindex]["fields"][selectedField]["min"] > 0 ||
      fields[fieldindex]["fields"][selectedField]["max"].length > 0
      ? true
      : false : false
  );
  const [showminmax, setshowminmax] = useState(
    fields[fieldindex] ? fields[fieldindex]["fields"][selectedField]["min"] > 0 ||
      fields[fieldindex]["fields"][selectedField]["max"].length > 0
      ? true
      : false : false
  );
  const [showPlaceholder, setshowPlaceholder] = useState(
    fields[fieldindex] ? fields[fieldindex]["fields"][selectedField]["placeholder"].length > 0
      ? true
      : false : false
  );
  const [formFields, setFormFields] = useState(
  fields[fieldindex]["fields"][selectedField] 
  );
  const [err, setErr] = useState("");
  const [error, setError] = useState({ label: "", placeholder: "", type: "" });
  const [fieldoptions, setfieldoptions] = useState(
    fields[fieldindex]["fields"][selectedField]["options"]
  );
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  
  
  // useEffect called whenever any state changed
  useEffect(() => {
    console.log(fields[0].grid);
  }, [
    showOption,
    setFields,
    setFormFields,
    setshowOption,
    showaddoptionfield,
    setshowaddoptionfield,
    showInfoDiv,
    setshowInfoDiv,
    showvalidDiv,
    setshowvalidDiv,
    showminmax,
    setshowminmax,
    showPlaceholder,
    setshowPlaceholder,
  ]);
  // useEffect called on
  useEffect(() => {
    setFormFields({ ...formFields, options: fieldoptions });
  }, [setfieldoptions, fieldoptions]);
  // useEffect called on first render
  useEffect(() => {
    dispatch({type:"REMOVEMODALINPROCESS"})
    setfieldoptions(fields[fieldindex]["fields"][selectedField]["options"]);
    fields[fieldindex]["fields"][selectedField]["options"].length > 0
      ? setshowOption(true)
      : setshowOption(false);
    fields[fieldindex]["fields"][selectedField]["info"].length > 0
      ? setshowInfoDiv(true)
      : setshowInfoDiv(false);
    fields[fieldindex]["fields"][selectedField]["required"].length > 0 ||
    fields[fieldindex]["fields"][selectedField]["min"] > 0 ||
    fields[fieldindex]["fields"][selectedField]["max"].length > 0
      ? setshowvalidDiv(true)
      : setshowvalidDiv(false);
    fields[fieldindex]["fields"][selectedField]["min"] > 0 ||
    fields[fieldindex]["fields"][selectedField]["max"].length > 0
      ? setshowminmax(true)
      : setshowminmax(false);
    fields[fieldindex]["fields"][selectedField]["placeholder"].length > 0
      ? setshowPlaceholder(true)
      : setshowPlaceholder(false);
  }, []);
  // Called on click on update button
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formFields.label.length > 0 && formFields.type.length > 0) {
      if (
        ((formFields.type === "select" ||
          formFields.type === "checkbox" ||
          formFields.type === "radio") &&
          formFields.options.length > 1) ||
        formFields.placeholder.length > 0 ||
        formFields.type === "date" ||
        formFields.type === "time" ||
        formFields.type === "file"
      ) {
        if (
          formFields.min > 0 &&
          formFields.max > 0 &&
          formFields.max < formFields.min
        ) {
          setErr("Minimum value should be less than maximum value ");
        } else {
          var arr = fields;
          arr[fieldindex]["fields"][selectedField] = formFields;
          setFields(arr);
          setError({ label: "", type: "", placeholder: "" });
          document.getElementById("editFieldBtn").click();
        }
      } else {
        formFields.placeholder.length > 0 && formFields.options.length < 2
          ? setErr("Please Add Minimum 2 Options")
          : setError({ ...error, placeholder: "Enter placeholder name!" });
      }
    } else {
      formFields.label.length < 1 && formFields.type.length < 1
        ? setError({
            ...error,
            label: "Enter label name!",
            type: "Select type!",
          })
        : formFields.label.length < 1
        ? setError({ ...error, label: "Enter label name!" })
        : setError({ ...error, label: "Select type!" });
    }
  };
  // Called on type field change
  const handleType = (e) => {
    switch (e.target.value) {
      case "select":
      case "checkbox":
      case "radio":
        if (e.target.value === "select" || e.target.value === "radio") {
          setshowminmax(false);
          setFormFields({ ...formFields, min: 0 });
          setFormFields({ ...formFields, max: 0 });
        } else {
          setshowminmax(true);
        }
        setFormFields({ ...formFields, placeholder: "" });
        setshowPlaceholder(false);
        setshowOption(true);
        break;
      case "date":
      case "time":
      case "file":
        setFormFields({ ...formFields, min: 0 });
        setFormFields({ ...formFields, max: 0 });
        setFormFields({ ...formFields, placeholder: "" });
        setfieldoptions([]);
        setshowminmax(false);
        setshowOption(false);
        setshowPlaceholder(false);
        setFormFields({ ...formFields, options: [] });
        break;
      default:
        if (e.target.value === "email" || e.target.value === "file") {
          setshowminmax(false);
          setFormFields({ ...formFields, min: 0 });
          setFormFields({ ...formFields, max: 0 });
        } else setshowminmax(true);
        setfieldoptions([]);
        setshowOption(false);
        setshowPlaceholder(true);
        setFormFields({ ...formFields, options: [] });
        break;
    }
    e.target.value.length > 0
      ? setError({ ...error, type: "" })
      : setError({ ...error, type: "Select type!" });
    setFormFields({ ...formFields, type: e.target.value });
  };
  // Called on input field change
  const handleOnChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    if (e.target.name === "infocheckbox") {
      if (showInfoDiv) {
        setshowInfoDiv(false);
        setFormFields({ ...formFields, info: "" });
      } else setshowInfoDiv(true);
    } else if (e.target.name === "required") {
      setFormFields({ ...formFields, [e.target.name]: e.target.checked });
    } else if (e.target.name === "validatcheckbox") {
      if (showvalidDiv) {
        setFormFields({ ...formFields, required: false, min: 0, max: 0 });
        setshowvalidDiv(false);
      } else setshowvalidDiv(true);
    } else {
      setFormFields({ ...formFields, [e.target.name]: e.target.value });
    }
  };
  // Called on click on delete button
  const handleDeleteOption = (option) => {
    var arr = fieldoptions;
    arr.splice(arr.indexOf(option), 1);
    setfieldoptions(arr);
  };
  // Called on click on add button
  const addoption = (e) => {
    console.log(document.getElementById("newoptionvalue").value);
    if (document.getElementById("newoptionvalue").value.length > 0) {
      var newarr = fieldoptions;
      newarr.push(document.getElementById("newoptionvalue").value);
      setfieldoptions(newarr);
      formFields["options"] = newarr;
      setshowaddoptionfield(false);
      document.getElementById("newoptionvalue").value = "";
      setFormFields({ ...formFields, options: fieldoptions });
    }
  };
  // Called on click on add option button
  const handleaddoptionbtn = (e) => {
    setshowaddoptionfield(true);
  };
  // Called on click on cancel button
  const handleCancel = () => {
    setshowOption(false);
    setfieldoptions([]);
    setshowminmax(false);
    setshowPlaceholder(false);
    setshowvalidDiv(false);
    setshowInfoDiv(false);
    setstyleDiv({ display: "none" });
    setErr("");
    setError({ label: "", type: "", placeholder: "" });
    dispatch({type: "REMOVECUSTOMMODALPOPUPCLOSE"})
    dispatch({type:"REMOVEMODALINPROCESS"})
  };

      //stayonpage  popup for do you want to leave page
      useEffect(()=>{
        if (stayOnPage !== null && stayOnPage)
        { 
          if ($('#Editfield').hasClass('show'))
            {
              $("#Editfield").modal('show')
            }
             
          
            
        }
        else if (stayOnPage !== null && !stayOnPage){
          if ($('#Editfield').hasClass('show'))
          { $("#Editfield").modal('hide')}
           
          
        }
          
      },[stayOnPage])

  return (
    <>
      {/* Edit field details form */}
      <div>
        <form onSubmit={handleSubmit} id="form">
          <div
            className="modal fade edit-user-details-popup"
            id="Editfield"
            tabIndex="-1"
            role="dialog"
            data-backdrop="static" 
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-keyboard="false"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="edit-user-details-header">
                  <button
                    type="button"
                    className="close"
                    //data-dismiss="modal"
                    aria-label="Close"
                    id="editFieldBtn"
                    onClick={(e)=>{
                      if (modalInProcess)
                      { dispatch({type:"SETCUSTOMMODALPOPUPCLOSE"})
                       }else
                      {
                        $("#Editfield").modal('hide')
                        handleCancel(e)
                      }
                      
                  
                    }}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <span className="error">{err}</span>
                  <div className="form-group">
                    <div className="form-group-label">Label name*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="enter label here"
                        name="label"
                        onChange={handleOnChange}
                        value={formFields["label"]}
                      />
                    </div>
                    <span classNameName="error">{error.label}</span>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">Type*</div>
                    <div className="form-group-ct">
                      <select
                        name="type"
                        placeholder="Type"
                        onChange={handleType}
                        value={formFields["type"]}
                      >
                        <option value="">--- Select Type ---</option>
                        <option value="text">Text</option>
                        <option value="select">Select</option>
                        {fields[0].grid === 1 ? (
                          <>
                            <option value="checkbox">Checkbox</option>
                            <option value="radio">Radio button</option>
                          </>
                        ) : (
                          <></>
                        )}
                        <option value="number">Number</option>
                        <option value="textarea">Textarea</option>
                        <option value="email">Email</option>
                        <option value="time">Time</option>
                        <option value="date">Date</option>
                        <option value="file">Media</option>
                      </select>
                    </div>
                    <span classNameName="error">{error.type}</span>
                  </div>

                  <div
                    className="form-group"
                    style={
                      showPlaceholder
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="form-group-label">Enter placeholder*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="enter placeholder here"
                        name="placeholder"
                        onChange={handleOnChange}
                        value={formFields["placeholder"]}
                      />
                    </div>
                    <span className="error">{error.placeholder}</span>
                  </div>

                  <div
                    className="form-group"
                    id="optiontr"
                    style={
                      showOption ? { display: "block" } : { display: "none" }
                    }
                  >
                    <div className="form-group-label">Options</div>
                    <div className="form-group-ct">
                      {fieldoptions.length > 0
                        ? fieldoptions.map((option) => {
                            return (
                              <>
                                <div className="form-group-ct cstm-edit-delete">
                                  <input
                                    type="text"
                                    name={option}
                                    value={option}
                                    disabled
                                  />
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      href="#"
                                      onClick={() => {
                                        handleDeleteOption(option);
                                      }}
                                    >
                                      <img src={deletefill} />
                                    </a>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ""}
                    </div>

                    <div
                      className="form-group"
                      id="newoption"
                      style={
                        showaddoptionfield
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <input type="text" id="newoptionvalue" />
                      <button type="button" onClick={addoption} className="btn">
                        Add
                      </button>
                    </div>
                    {showaddoptionfield ? (
                      <></>
                    ) : (
                      <div>
                        <button
                          type="button"
                          onClick={handleaddoptionbtn}
                          className="btn"
                        >
                          Add Option
                        </button>
                      </div>)}
                  </div>

                  <div className="form-group clt-checkbox">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={handleOnChange}
                        name="validatcheckbox"
                        checked={showvalidDiv ? "checked" : ""}
                      />
                      Show validation
                    </label>
                  </div>

                  <div
                    style={
                      showvalidDiv ? { display: "block" } : { display: "none" }
                    }
                  >
                    <div className="required-bx">
                      <div className="form-group clt-checkbox">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="required"
                            onChange={handleOnChange}
                            checked={formFields["required"]}
                          />
                          Required
                        </label>
                      </div>
                      <div
                        style={
                          showminmax || showvalidDiv
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div className="form-group">
                          <div className="form-group-label">Max</div>
                          <div className="form-group-ct">
                            <input
                              type="number"
                              name="max"
                              onChange={handleOnChange}
                              value={formFields["max"]}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">Minimum</div>
                          <div className="form-group-ct">
                            <input
                              type="number"
                              name="min"
                              onChange={handleOnChange}
                              value={formFields["min"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group clt-checkbox">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="infocheckbox"
                        onChange={handleOnChange}
                        checked={
                          showInfoDiv || formFields["info"].length > 0
                            ? "checked"
                            : ""
                        }
                      />
                      Info
                    </label>
                  </div>

                  <div
                    className="form-group"
                    style={
                      showInfoDiv ? { display: "block" } : { display: "none" }
                    }
                  >
                    <div className="form-group-label">Info</div>
                    <div className="form-group-ct">
                      <textarea
                        name="info"
                        onChange={handleOnChange}
                        value={formFields["info"]}
                      >
                        write info
                      </textarea>
                    </div>
                  </div>
                </div>
                <div className="edit-user-details-footer">
                  <button type="submit" className="btn">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <AlertComponent setcurrentDiv={""} />
    </>
  );
};

export default EditField;
