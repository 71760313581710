const alltopics = [];

const ReduAllTopics = (state = alltopics, action) => {
  switch (action.type) {
    case "FETCHALLTOPICS":
      return action.payload;
    default:
      return state;
  }
};

export default ReduAllTopics;
