import React, { useEffect, useState } from "react";
import authServiceNews from "../../../Services/auth.service.news";
import moment from "moment";
import noThumbnail from "../../../Images/no-image-available.jpg";
import CalendarDade from "../../../Images/calendar-dade.svg";
import NewsLogo from "../../../Images/news-logo.svg";
import { Link } from "react-router-dom";
import ShareFunctionality from "../../ShareFunctionality";

const NewsDetail = ({ action }) => {
  const [detailData, setDetailData] = useState({});
  const [latestNews, setLatestNews] = useState([]);
  const [loader, setLoader] = useState(false);
  console.log(action, "action");
  //call on first render
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    newsDetalApi();
    latestNewsApi();
    setLoader(true);
  }, [action]);

  //news detail api call
  async function newsDetalApi() {
    try {
      const newsDetailRes = await authServiceNews.getNewsDetail(action);
      if (newsDetailRes.status) {
        setDetailData(newsDetailRes.data.newsDetail);
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  }

  //latest news list api call
  async function latestNewsApi() {
    try {
      const latestNewsRes = await authServiceNews.getLatestNews();
      if (latestNewsRes.status) {
        var data = latestNewsRes.data.newsList.list
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3);
        setLatestNews(data.reverse());
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  }
  return (
    <>
      <div className="news-details-main">
        {loader ? (
          <>
            <div className="cstm-news-details-date-loading skeleton-box"></div>
            <div className="cstm-news-details-title-loading skeleton-box"></div>
            <div className="cstm-news-details-image-loading skeleton-box"></div>
            <div className="cstm-news-details-content-loading skeleton-box"></div>
            <div className="cstm-news-details-content-loading skeleton-box"></div>
            <div className="cstm-news-details-content-loading skeleton-box"></div>
            <div className="cstm-news-details-content-loading skeleton-box"></div>
            <div className="cstm-news-details-content-loading skeleton-box"></div>

            <hr className="news-details-pg-line" />
            <div className="news-details-main-list-loading">
              <div className="news-details-main-list-left-loading skeleton-box"></div>
              <div className="news-details-main-list-right-loading">
                <div className="news-details-main-list-right-title-loading skeleton-box"></div>
                <div className="news-details-main-list-right-date-loading skeleton-box"></div>
              </div>
            </div>
            <div className="news-details-main-list-loading">
              <div className="news-details-main-list-left-loading skeleton-box"></div>
              <div className="news-details-main-list-right-loading">
                <div className="news-details-main-list-right-title-loading skeleton-box"></div>
                <div className="news-details-main-list-right-date-loading skeleton-box"></div>
              </div>
            </div>
            <div className="news-details-main-list-loading">
              <div className="news-details-main-list-left-loading skeleton-box"></div>
              <div className="news-details-main-list-right-loading">
                <div className="news-details-main-list-right-title-loading skeleton-box"></div>
                <div className="news-details-main-list-right-date-loading skeleton-box"></div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="">
              <p className="news-details-pg-date">
                {detailData !== undefined &&
                detailData.date !== undefined &&
                detailData.date !== null &&
                detailData.date !== ""
                  ? moment(detailData.date).format("MMM DD, YYYY")
                  : ""}
              </p>
              {detailData !== undefined &&
                detailData.title !== undefined &&
                detailData.title !== null &&
                detailData.title !== "" &&
                (process.env.REACT_APP_FRONTEND_ROOTURL ===
                  "https://dev.milliondollarsellers.com/" ||
                  process.env.REACT_APP_FRONTEND_ROOTURL ===
                    "https://test.milliondollarsellers.com/") && (
                  <div className="mark-btn-watched-news">
                    <ShareFunctionality
                      url={"redirectto=" + "news" + "&news_id=" + action}
                    />
                  </div>
                )}
            </div>
            <h2 className="news-details-pg-title">
              {detailData !== undefined &&
              detailData.title !== undefined &&
              detailData.title !== null &&
              detailData.title !== ""
                ? detailData.title
                : ""}
            </h2>
            <div
              className="news-details-test-edit"
              dangerouslySetInnerHTML={{
                __html:
                  detailData !== undefined &&
                  detailData.description !== undefined &&
                  detailData.description !== null &&
                  detailData.description !== ""
                    ? detailData.description
                    : "",
              }}
            ></div>
            <hr className="news-details-pg-line" />
            <h3 className="news-details-pg-h3">Latest news</h3>
            <div className="news-details-main-list">
              <ul>
                {latestNews.length > 0 &&
                  latestNews.map((item) => (
                    <>
                      <Link
                        onClick={() => {
                          setLoader(true);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                          newsDetalApi();
                          latestNewsApi();
                        }}
                        to={
                          item.newsType === "news"
                            ? "/frontnews/" + item._id
                            : `/videos/${item.videoData._id}`
                        }
                      >
                        <li>
                          <div className="news-details-main-list-img">
                            {item.thumbnailUrl !== undefined &&
                            item.thumbnailUrl !== "" &&
                            item.thumbnailUrl !== null ? (
                              <img
                                src={
                                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                                  item.thumbnailUrl
                                }
                              />
                            ) : (
                              <img src={noThumbnail} />
                            )}
                          </div>
                          <div className="news-details-main-list-info">
                            <h4>
                              {item.titleMain !== undefined &&
                              item.titleMain !== null &&
                              item.titleMain !== ""
                                ? item.titleMain
                                : ""}
                            </h4>
                            <span className="news-details-pg-date">
                              <img src={CalendarDade} />{" "}
                              {item.date !== undefined &&
                              item.date !== null &&
                              item.date !== ""
                                ? moment(item.date).format("MMM DD, YYYY")
                                : ""}
                            </span>
                          </div>
                        </li>
                      </Link>
                    </>
                  ))}
              </ul>
            </div>
            <div className="news-details-main-newsroom">
              <p className="newsroom-icon">
                <img src={NewsLogo} />
              </p>
              <h3 className="newsroom-title">Newsroom</h3>
              <p className="newsroom-text">TrendingPost</p>
              <p className="newsroom-read-more">
                <Link to="/homepage">Read More</Link>
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default NewsDetail;
