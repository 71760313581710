import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Services from "../../Services/auth.service1";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import editfill from "../../Images/BackendImages/edit.svg";
import Loader2 from "../../Images/loader2.gif";
import Loader from "../../Images/loader.gif";
import AlertComponent from "../../Hooks/AlertComponent";
import authService from "../../Services/auth.service";
const columns = [
    { id: "name", label: "Name" },
    { id: "counts", label: "No. of videos" },
    { id: "action", label: "Actions" },
];

function createData(name, counts, action) {
    return {
        name,
        counts,
        action,
    };
}
const VideoEvent = () => {
    const history = useNavigate();
    const [rows, setrow] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [deleteId, setdeleteId] = useState("");
    const [showalert, setshowalert] = useState(false);
    const [alertmsg, setalertmsg] = useState("");
    const [showalertAdd, setshowalertAdd] = useState(false);
    const [alertmsgAdd, setalertmsgAdd] = useState("");
    const [showalertEdit, setshowalertEdit] = useState(false);
    const [alertmsgEdit, setalertmsgEdit] = useState("");
    const [searchText, setSearchText] = useState("");
    const [allevents, setallevents] = useState([]);
    const [addevent, setaddevent] = useState("");
    const [adderr, setadderr] = useState("");
    const [editevent, seteditevent] = useState("");
    const [editerr, setediterr] = useState("");
    const [selectedId, setselectedId] = useState("");
    const [createInprocess, setcreateInprocess] = useState(false);
    const [editInprocess, seteditInprocess] = useState(false);
    const [reassignId, setReassignId] = useState("");
    const [confirmdeletemodal, setconfirmdeletemodal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [restevents, setrestevents] = useState([]);
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [listIndex, setListIndex] = useState(-1);
    const [actualsearch, setActualsearch] = useState("");
    const dispatch = useDispatch()
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    useEffect(() => {
        setLoader(true)
        handleClearAllStates()
        getallusersSuggestionListApi()
        Services.getallevent_byadmin()
        getAllVideoEventApi()
    }, []);

    async function getAllVideoEventApi(col, sort) {
        let query = ""
        query += col !== undefined && col !== null && col !== "" ? `?sortField=${col}` : ""
        query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
        Services.getallevent_byadmin(query)
            .then((res) => {
                if (res.data.status) {
                    setLoader(false)
                    var data = res.data.data;
                    var tbl = [];
                    for (var i = 0; i < data.length; i++) {
                        tbl[i] = createData(data[i].name, data[i].counts, data[i]._id);
                    }
                    setrow(tbl);
                    setallevents(tbl);
                }
            })
            .catch((e) => {
                setLoader(false)
                console.log(e);
            });
    }
    async function getallusersSuggestionListApi() {
        try {
            const getSuggestionRes = await authService.eventSuggestionList()

            console.log(getSuggestionRes, "getSuggestionRes");
            let data = getSuggestionRes.data.data
            var arr = []
            if (data && data.length !== undefined && data.length !== null && data.length > 0) {
                data.map((item) => arr.push({ name: item.name }))
                console.log(arr);
                setSuggestionList(arr);
            }
        } catch (err) {

        }
    }
    useEffect(() => {
        if (suggestionBox && listIndex > -1) {
            var currentList =
                document.getElementById("suggestion-list").children[listIndex]
            // .firstElementChild.firstElementChild;
            if (currentList) {
                var text = currentList.innerText
                setSearchText(text);
            }
        }
    }, [listIndex]);

    const handleCloseAddModel = (e) => {
        handleClearAllStates()
        setaddevent("");
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleEdit = (value) => {
        setselectedId(value);
        Services.geteventbyid(value)
            .then((res) => {
                if (res.data.status) {
                    seteditevent(res.data.data.name);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleSearch = (text) => {
        console.log(text)
        setSearchText(text)
        setSuggestionBox(false)
        if (allevents !== undefined && allevents.length > 0) {
            var data = allevents.filter((event) => {
                return event.name.includes(searchText)
            });
            var tbl = [];
            for (var i = 0; i < data.length; i++) {
                tbl[i] = createData(data[i].name, data[i].counts, data[i].action);
            }
            setrow(tbl);
        }
    };
    const handleDelete = () => {
        const data = {
            deleted_event_id: deleteId,
            reassign_event_id: reassignId
        }
        Services.deleteEvent(data)
            .then((res) => {
                if (res.data.status) {
                    var tbl = [];
                    var n = 0;
                    for (var i = 0; i < allevents.length; i++) {
                        if (allevents[i].action !== deleteId) {
                            tbl[n] = createData(
                                allevents[i].name,
                                allevents[i].counts,
                                allevents[i].action
                            );
                            n++;
                        }
                    }
                    setallevents(tbl);
                    setrow(tbl);
                    setrestevents([])
                    setconfirmdeletemodal(false);
                    getAllVideoEventApi()
                }
                setshowalert(true);
                setalertmsg(res.data.message);
                setTimeout(() => {
                    setshowalert(false);
                    setalertmsg("");
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
                setrestevents([])
                setconfirmdeletemodal(false);
            });
    };

    const handleSorting = (e) => {
        var normal = allevents;
        var arr = [...allevents];

        if (e.target.value === "a-z") {
            arr.sort(function (a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
        } else if (e.target.value === "z-a") {
            arr.sort(function (a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return textA > textB ? -1 : textA < textB ? 1 : 0;
            });
        } else {
            arr = normal;
        }
        var tbl = [];
        for (var i = 0; i < arr.length; i++) {
            tbl[i] = createData(arr[i].name, arr[i].counts, arr[i].action);
        }
        setrow(tbl);
    };

    const handleSort = (col, direc) => {
        setLoader(true)
        getAllVideoEventApi(col, direc)
    };
    const handleAddChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        setaddevent(e.target.value);
        if (e.target.value.length > 0) {
            setadderr("")
        } else {
            setadderr("Enter event! ")
        }
    };
    const handleCreate = () => {
        if (addevent.length > 0) {
            setcreateInprocess(true);

            Services.createevent({
                name: addevent.trim(),
            })
                .then((res) => {
                    setcreateInprocess(false);
                    if (res.data.status) {
                        setshowalertAdd(true);
                        setalertmsgAdd(res.data.message);
                        setTimeout(() => {
                            setshowalertAdd(false);
                            setalertmsgAdd("");
                        }, 3000);
                        getAllVideoEventApi()
                    } else {
                        setshowalertAdd(true);
                        setalertmsgAdd(res.data.message);
                        setTimeout(() => {
                            setshowalertAdd(false);
                            setalertmsgAdd("");
                        }, 3000);
                    }
                    handleClearAllStates()
                    setaddevent("");
                })
                .catch((e) => {
                    handleClearAllStates()
                    setcreateInprocess(false);
                    console.log(e);
                });
            setadderr("");
        } else {
            setadderr("Enter event!");
        }
    };
    const handleEditChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        seteditevent(e.target.value);
        if (e.target.value.length > 0) {
            setediterr("")
        } else {
            setediterr("Enter event! ")
        }
    };
    const handleEditSubmit = () => {
        if (editevent.length > 0) {
            seteditInprocess(true);
            Services.editEvent({
                id: selectedId,
                data: { name: editevent },
            })
                .then((res) => {
                    if (res.data.status) {
                        setshowalertEdit(true);
                        setalertmsgEdit(res.data.message);
                        setTimeout(() => {
                            setshowalertEdit(false);
                            setalertmsgEdit("");
                        }, 3000);
                        getAllVideoEventApi()
                        seteditInprocess(false);
                    } else {
                        setshowalertEdit(true);
                        setalertmsgEdit(res.data.message);
                        setTimeout(() => {
                            setshowalertEdit(false);
                            setalertmsgEdit("");
                        }, 3000);
                    }
                    handleClearAllStates()
                    seteditInprocess(false);
                })
                .catch((e) => {
                    console.log(e);
                    seteditInprocess(false);
                    handleClearAllStates()
                });
            setediterr("");
        } else {
            setediterr("Enter event!");
        }
    };



    useEffect(() => {
        Services.getrestevents_byadmin(deleteId)
            .then((res) => {
                if (res.data.status) {

                    setrestevents(res.data.data)


                }
            })
            .catch((e) => {
                setconfirmdeletemodal(false)
                console.log(e);
            });
    }, [deleteId])

    const handleConfirmDelete = (e) => {
        setconfirmdeletemodal(true)

        // Services.getrestevents_byadmin(deleteId)
        // .then((res) => {
        //     if (res.data.status) {

        //         setrestevents(res.data.data)


        //     }
        // })
        // .catch((e) => {
        //     setconfirmdeletemodal(false)
        //     console.log(e);
        // });
    }
    // console.log(confirmdeletemodal,"Sdfsdf")    
    const handleDeleteChange = (e) => {
        setReassignId(e.target.value)
    }
    //pop up state for do you want to leave....
    useEffect(() => {

        if (stayOnPage !== null && stayOnPage) {
            if ($('#Addevent').hasClass('show')) {
                $("#Addevent").modal('show')
            }
            if ($('#editevent').hasClass('show')) {
                $("#editevent").modal('show')
            }

        }
        else if (stayOnPage !== null && !stayOnPage) {
            if ($('#Addevent').hasClass('show')) {
                $("#Addevent").modal('hide')
                handleCloseAddModel()
            }
            if ($('#editevent').hasClass('show')) {
                $("#editevent").modal('hide')
                handleCloseAddModel()
            }

        }

    }, [stayOnPage])

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }

    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title">
                        <h1>All video events</h1>
                        <div className="uic-fl-btn">
                            <button
                                className="btn"
                                data-toggle="modal"
                                data-target="#Addevent"
                            >
                                Add event
                            </button>
                        </div>
                    </div>

                    <div className="uic-fl">
                        <div className="uic-fl-search">
                            <input
                                type="text"
                                placeholder="Search"
                                className="search-user-input"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    var currentValue;
                                    if (e.target.value) {
                                        currentValue = e.target.value;
                                    } else {
                                        currentValue = e.target.value;
                                        setSearchText(currentValue);
                                    }
                                    setActualsearch(currentValue);
                                    if (suggestionList !== undefined && suggestionList.length > 0) {
                                        setListIndex(-1);
                                        setSuggestionBox(true)
                                        var searchResult = suggestionList.filter((user) => {
                                            return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                                        });
                                        setSuggestions(searchResult);
                                    }
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch(e.target.value)
                                    } else if (e.key === "ArrowDown") {
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex < listLength - 1) {
                                            setListIndex(listIndex + 1);
                                        } else if (listIndex === listLength - 1) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        }
                                    } else if (e.key === "ArrowUp") {
                                        e.preventDefault();
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex === 0) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        } else if (listIndex === -1) {
                                            setListIndex(listLength - 1);
                                        } else {
                                            setListIndex(listIndex - 1);
                                        }
                                    }
                                }}
                                onBlur={() => setSuggestionBox(false)}
                            />
                            <i
                                className="fa fa-search"
                                onClick={() => handleSearch(searchText)}
                            ></i>
                            {suggestionBox && searchText.length > 0 && (
                                <div className="suggestion-box">
                                    <ul id="suggestion-list">
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onMouseDown={() => handleSearch(suggestion.name)}
                                                className={index === listIndex ? 'selected-active' : ''}
                                            >
                                                {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length -1)} </> : suggestion.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>

                        <div className="uic-fl-btn sort-by-mn">
                            <div className="sort-by-mn-title">Sort by : </div>
                            <div className="sort-by-mn-select">
                                <select onChange={handleSorting}>
                                    <option>All</option>
                                    <option value="a-z">A-Z</option>
                                    <option value="z-a">Z-A</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {showalert && <div className="alert alert-info">{alertmsg}</div>}
                    <Paper className="uic-tb-mn">
                        <TableContainer className="uic-table-main">
                            <Table className="uic-table min-w-auto">
                                <TableHead className="uic-table-th">
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className="uic-th-td w-10"
                                            >
                                                {column.id !== "action" ? (
                                                    <>
                                                        <span className="custom-sorting">
                                                            <i
                                                                className="fa fa-angle-down"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "Dec");
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="fa fa-angle-up"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "Asc");
                                                                }}
                                                            ></i>
                                                        </span>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="uic-table-td">
                                    {loader ? (
                                        <div className="cstm-data-load">
                                            <img src={Loader} />
                                        </div>
                                    ) : rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.code}
                                                    className="uic-th-td w-20"
                                                >
                                                    {columns.map((column) => {
                                                        const value = row[column.id];

                                                        return column.id === "name" ? (
                                                            <TableCell
                                                                key={column.id}
                                                                className="uic-th-td w-17"
                                                            >
                                                                {value}
                                                            </TableCell>
                                                        ) : column.id === "counts" ? (
                                                            <TableCell
                                                                key={column.id}
                                                                className="uic-th-td w-17"
                                                            >
                                                                {value}
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell
                                                                key={column.id}
                                                                className="uic-th-td uic-table-icon w-17"
                                                            >
                                                                <a
                                                                    data-toggle="modal"
                                                                    data-target="#editevent"
                                                                    onClick={() => {
                                                                        handleEdit(value);
                                                                    }}
                                                                >
                                                                    <img src={editfill} />
                                                                </a>
                                                                {row['name'] !== "others" &&
                                                                    <a
                                                                        data-toggle="modal"
                                                                        data-target="#DeleteFromEvent"
                                                                        onClick={() => {

                                                                            setdeleteId(value);


                                                                        }}
                                                                    >
                                                                        <img src={imgdelete} />
                                                                    </a>
                                                                }

                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="uic-pagination"
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>
            <div
                className="modal fade edit-user-details-popup"
                id="Addevent"
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Create Event</h2>
                            <button
                                type="button"
                                className="close"
                                // data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {
                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                    } else {
                                        $("#Addevent").modal('hide')
                                        handleCloseAddModel(e)
                                    }

                                }}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {showalertAdd && <div className="alert alert-info">{alertmsgAdd}</div>}
                            <div className="form-group">
                                <div className="form-group-label">Event name*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="Enter name"
                                        name="event"
                                        onChange={handleAddChange}
                                        value={addevent}
                                    />
                                </div>
                                <span className="error">{adderr}</span>
                            </div>
                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {createInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleCreate}
                                    disabled={createInprocess}
                                >
                                    Create
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade edit-user-details-popup"
                id="editevent"
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Edit Event</h2>
                            <button
                                type="button"
                                className="close"
                                //data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {
                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                    } else {
                                        $("#editevent").modal('hide')
                                        handleCloseAddModel(e)
                                    }

                                }}

                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {showalertEdit && <div className="alert alert-info">{alertmsgEdit}</div>}
                            <div className="form-group">
                                <div className="form-group-label">Event name*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="Enter name"
                                        name="event"
                                        onChange={handleEditChange}
                                        value={editevent}
                                    />
                                </div>
                                <span className="error">{editerr}</span>
                            </div>

                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {editInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleEditSubmit}
                                >
                                    Update Changes
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Delete */}
            <div
                className="modal fade msg-popup"
                id="DeleteFromEvent"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-backdrop="static"

            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => { setconfirmdeletemodal(false); }}
                        >
                            &times;
                        </button>
                        {confirmdeletemodal &&
                            <div className="reassigncontent">
                                <h1>Assign all videos to another event</h1>
                                <div className="form-group">
                                    <div className="form-group-label">Select Event name*</div>
                                    <div className="form-group-ct">
                                        <select
                                            type="text"
                                            placeholder="Se"
                                            name="selevent"
                                            onChange={handleDeleteChange}

                                        >

                                            {restevents.length > 0 && restevents.map(o => <option value={o._id}>{o.name}</option>)}


                                        </select>
                                    </div>
                                    <span className="error">{editerr}</span>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <span className="msg-btn">
                                            <button
                                                type="button"
                                                className="btn btn-red"
                                                onClick={handleDelete}
                                                data-dismiss="modal"
                                            >
                                                Submit
                                            </button>
                                        </span>
                                    </div>
                                    <div className="col-6">
                                        <span className="msg-btn">
                                            <button type="button" className="btn2" onClick={() => { setconfirmdeletemodal(false); }} data-dismiss="modal">
                                                Discard
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        {!confirmdeletemodal &&
                            <div className="confirmdeletecontent">
                                <div className="msg-icon">
                                    <img src={imgbigdelete} />
                                </div>
                                <h1>Are you sure you want to delete this event?</h1>
                                <div className="row">
                                    <div className="col-6">
                                        <span className="msg-btn">
                                            <button
                                                type="button"
                                                className="btn btn-red"
                                                onClick={handleConfirmDelete}


                                            >
                                                Delete
                                            </button>
                                        </span>
                                    </div>
                                    <div className="col-6">
                                        <span className="msg-btn">
                                            <button type="button" className="btn2" data-dismiss="modal">
                                                Discard
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <AlertComponent />

        </>
    );
};
export default VideoEvent;
