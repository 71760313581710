import React, { useEffect, useRef, useState } from "react";
import CensusDataModal from "../../User/PartnerManagement/CensusDataModal";
import DropDown from "../../DropDown/DropDown";
import DropDownItem from "../../DropDownItem/DropDownItem";
import FilterIcon from "../../FilterIcon/FilterIcon";
import { useLocation, useNavigate } from "react-router-dom";
import Chips from "../../Chips/Chips";
import VideoFilterSidebar from "./components/VideoFilterSidebar";
import {
  useGetSpeakersByVideoQuery,
  useGetVideoByFilterSortCountQuery,
  useGetVideosCategoriesQuery,
  useGetVideosQuery,
  useLazyGetVideosQuery,
} from "../../../Services/videos";
import FilterIconAlt from "../../Icons/GeneralIcons/FilterIconAlt";
import moment from "moment";
import LibraryListing from "./components/LibraryListing";
import SavedListing from "./components/SavedListing";
import { ReactComponent as IcnMClear } from "../../../Images/icn_m_clear.svg";
import Avtar from "../../Avtar/Avtar";
import CalendarIcon from "../../Icons/GeneralIcons/CalendarIcon";
import ChipFilter from "../../Chips/ChipFilter";
import ArrowChevronDown from "../../Icons/ArrowsIcon/ArrowChevronDown";
import ArrowChevronUp from "../../Icons/ArrowsIcon/ArrowChevronUp";
import EmptyState from "../../EmptyState/EmptyState";
import { fetchsavedvideos } from "../../../Action";
import { useDispatch } from "react-redux";
import HistoryListing from "./components/HistoryListing";
import Slider from "react-slick";
import ButtonCarousel from "../../Button/ButtonCarousel";

// date  list array
const dateList = [
  { id: "", label: "Anytime" },
  { id: "first24hours", label: "Today" },
  { id: "past7days", label: "Last 7 days" },
  { id: "past30days", label: "Last 30 days" },
  { id: "thisYear", label: `This year (${new Date().getFullYear()})` },
  { id: "lastYear", label: `Last  year (${new Date().getFullYear() - 1})` },
  { id: "custom", label: "Custom date range" },
];

const sortByList = [
  { _id: "recent", name: "Newest" },
  { _id: "comment", name: "Comments" },
  { _id: "popular", name: "Views" },
];

const ContentListing = () => {
  const filterRef = useRef();
  const sortRef = useRef();
  const categoryRef = useRef();
  const navigate = useNavigate();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const listType = new URLSearchParams(search).get("type");
  const searchedVideo = new URLSearchParams(search).get("search");
  const filtertype = new URLSearchParams(search).get("filter");
  const subcategoryId = new URLSearchParams(search).get("subcategoryId");
  const categoryId = new URLSearchParams(search).get("catid");
  const speakerId = new URLSearchParams(search).get("speaker");
  const dateFilter = new URLSearchParams(search).get("datefilter");
  const fromDateFilter = new URLSearchParams(search).get("fromdate");
  const toDateFilter = new URLSearchParams(search).get("todate");
  const [access, setAccess] = useState(false);
  const [access1, setAccess1] = useState(false);
  const [access2, setAccess2] = useState(false);
  const [displayVideos, setDisplayVideos] = useState([]);
  const [displaySavedVideos, setDisplaySavedVideos] = useState([]);
  const [displayHistoryVideos, setDisplayHistoryVideos] = useState([]);
  const [allSpeakersList, setAllSpeakersList] = useState([]);
  const [allCategoriesList, setAllCategoriesList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalVideos, setTotalVideos] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [listingCount, setListingCount] = useState({
    all: 0,
    saved: 0,
    history: 0,
  });
  const [isSorting, setSorting] = useState(false);
  const [currentTab, setCurrentTab] = useState(listType ? listType : "all");
  const [sortBy, setSortBy] = useState({ _id: "recent", name: "Newest" });
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState({});
  const [selectedPeople, setSelectedPeople] = useState({});
  const [selectedDateFilter, setSelectedDateFilter] = useState({});
  const [isFilter, setFilter] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [isCategory, setCategory] = useState(false);
  const [noData, setNoData] = useState(false);

  const queryData = {
    status: listType ? listType : currentTab,
    categorie_id: categoryId ? categoryId : "",
    subcategorie_id: subcategoryId ? subcategoryId : "",
    speaker_id: speakerId ? speakerId : "",
    filter: filtertype ? filtertype : "",
    page: page,
    limit: 20,
    search: searchedVideo ? searchedVideo : "",
    filterType: dateFilter ? dateFilter : "",
    reqFromDate: fromDateFilter ? fromDateFilter : "",
    reqToDate: toDateFilter ? toDateFilter : "",
  };

  const {
    data: getVideosList,
    isFetching: pageLoader,
    refetch: listingRefetch,
  } = useGetVideosQuery(queryData);

  const [getContentVideos] = useLazyGetVideosQuery();

  const { data: filteredDataCount } = useGetVideoByFilterSortCountQuery({
    status: currentTab,
    categorie_id: selectedCategory?._id ? selectedCategory?._id : "",
    subcategorie_id: selectedSubCategory?._id ? selectedSubCategory?._id : "",
    speaker_id: selectedPeople?._id ? selectedPeople?._id : "",
    filter: sortBy._id ? sortBy._id : "",
    search: searchedVideo ? searchedVideo : "",
    filterType: selectedDateFilter.id ? selectedDateFilter.id : "",
    reqFromDate: fromDate,
    reqToDate: toDate,
  });

  const { data: allCategories } = useGetVideosCategoriesQuery();

  const { data: allSpeakers } = useGetSpeakersByVideoQuery();

  useEffect(() => {
    listingRefetch();
    if (localStorage.getItem("count") > 364) {
      setAccess2(true);
    }
  }, []);

  useEffect(() => {
    if ((getVideosList, !pageLoader)) {
      if (getVideosList?.data) {
        setPage(parseInt(getVideosList?.data?.currentPage));
        setTotalVideos(getVideosList?.data?.totalVideos);
        setListingCount({
          all: getVideosList?.data?.all,
          saved: getVideosList?.data?.saveVideo,
          history: getVideosList?.data?.historyVideo,
        });
        if (parseInt(getVideosList?.data?.currentPage) === 1) {
          if (currentTab === "all") {
            setDisplayVideos(getVideosList?.data?.videos);
          }
          if (currentTab === "saved") {
            setDisplaySavedVideos(getVideosList?.data?.videos);
          }
          if (currentTab === "history") {
            setDisplayHistoryVideos(getVideosList?.data?.videos);
          }
          if (getVideosList?.data?.videos.length > 0) {
            setNoData(false);
          } else {
            setNoData(true);
          }
        } else {
          if (currentTab === "all") {
            setNoData(false);
            setDisplayVideos([
              ...displayVideos,
              ...getVideosList?.data?.videos,
            ]);
          }
          if (currentTab === "saved") {
            setNoData(false);
            setDisplaySavedVideos([
              ...displaySavedVideos,
              ...getVideosList?.data?.videos,
            ]);
          }
          if (currentTab === "history") {
            setNoData(false);
            setDisplayHistoryVideos([
              ...displayHistoryVideos,
              ...getVideosList?.data?.videos,
            ]);
          }
        }
      } else {
        setNoData(true);
      }
    }
  }, [getVideosList, pageLoader, currentTab]);

  useEffect(() => {
    dispatch(fetchsavedvideos());
    if (searchedVideo) {
      setPage(1);
    }
    if (filtertype) {
      const sortFilter = sortByList.filter(
        (filter) => filter._id === filtertype
      );
      setSortBy(sortFilter[0]);
    }
    if (categoryId && allCategories) {
      const categoryFilter = allCategories?.data.filter(
        (category) => category._id === categoryId
      );
      setSelectedCategory(categoryFilter[0]);
      if (subcategoryId) {
        const subCategoryFilter = categoryFilter[0].subcategory.filter(
          (subCat) => subCat._id === subcategoryId
        );
        setSelectedSubCategory(subCategoryFilter[0]);
      }
    }
    if (speakerId && allSpeakers) {
      const speakerFilter = allSpeakers?.data.filter(
        (spekaer) => spekaer._id === speakerId
      );
      setSelectedPeople(speakerFilter[0]);
    }
    if (dateFilter) {
      const filteredDate = dateList.filter((date) => date.id === dateFilter);
      setSelectedDateFilter(filteredDate[0]);
    }
    if (allSpeakers) {
      setAllSpeakersList(allSpeakers?.data);
    }
    if (allCategories) {
      setAllCategoriesList(allCategories?.data);
    }
    if (listType) {
      setCurrentTab(listType);
    }
  }, [
    allCategories,
    allSpeakers,
    searchedVideo,
    listType,
    filtertype,
    dateFilter,
    categoryId,
    speakerId,
  ]);

  const handleSortBy = (data) => {
    navigate(
      `/videos?type=${currentTab}&filter=${data._id}${
        searchedVideo ? `&search=${searchedVideo}` : ""
      }${categoryId ? `&catid=${categoryId}` : ""}${
        subcategoryId ? `&subcategoryId=${subcategoryId}` : ""
      }${speakerId ? `&speaker=${speakerId}` : ""}${
        dateFilter ? `&datefilter=${dateFilter}` : ""
      }`
    );
    setPage(1);
    setSortBy(data);
    setSorting(false);
  };

  const handleCancelFilter = () => {
    setFilter(false);
    setFromDate(new Date());
    setToDate(new Date());
  };

  // handle Category select
  const handleCategoryFilter = (data) => {
    setSelectedCategory(data);
    setSelectedSubCategory({});
  };

  // handle Category select
  const handleSubCategoryFilter = (data) => {
    setSelectedSubCategory(data);
  };

  // handle people select
  const handlePeopleFilter = (data) => {
    setSelectedPeople(data);
  };

  // handle date select
  const handleDateFilter = (data) => {
    setSelectedDateFilter(data);
  };

  // handle date select filter
  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(date);
  };

  // handle todate select change
  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const updateAppliedFilterCount = () => {
    // Calculate the count based on your state variables
    const count =
      (Object.keys(selectedCategory).length > 0 ? 1 : 0) +
      (Object.keys(selectedPeople).length > 0 ? 1 : 0) +
      (Object.keys(selectedDateFilter).length > 0 ? 1 : 0);
    setFilterCount(count);
  };

  useEffect(() => {
    updateAppliedFilterCount();
  }, [selectedCategory, selectedPeople, selectedDateFilter]);

  const handleClearFilter = () => {
    setSelectedCategory({});
    setSelectedPeople({});
    setSelectedSubCategory({});
    setSelectedDateFilter({});
    setFilterCount(0);
    setFromDate(new Date());
    setToDate(new Date());
    navigate(
      `/videos?type=${currentTab}&filter=recent${
        categoryId ? `&catid=${categoryId}` : ""
      }${subcategoryId ? `&subcategoryId=${subcategoryId}` : ""}${
        speakerId ? `&speaker=${speakerId}` : ""
      }${dateFilter ? `&datefilter=${dateFilter}` : ""}`
    );
  };

  // handle apply filter show result
  const handleShowResult = () => {
    setPage(1);
    navigate(
      `/videos?type=${currentTab}&filter=${sortBy._id}${
        Object.keys(selectedCategory).length > 0
          ? `&catid=${selectedCategory._id}`
          : ""
      }${
        Object.keys(selectedSubCategory).length > 0
          ? `&subcategoryId=${selectedSubCategory._id}`
          : ""
      }${
        Object.keys(selectedPeople).length > 0
          ? `&speaker=${selectedPeople._id}`
          : ""
      }${
        Object.keys(selectedDateFilter).length > 0
          ? `&datefilter=${selectedDateFilter.id}`
          : ""
      }${
        Object.keys(selectedDateFilter).length > 0 &&
        selectedDateFilter.id === "custom"
          ? `&fromdate=${fromDate}&todate=${toDate}`
          : ""
      }${searchedVideo ? `&search=${searchedVideo}` : ""}`
    );
    setFilter(false);
  };

  // in filtersidebar close icon click
  const handleCloseIconClick = (type) => {
    if (type === "category") {
      setSelectedCategory({});
      setSelectedSubCategory({});
    }
    if (type === "people") {
      setSelectedPeople({});
    }
    if (type === "date") {
      setSelectedDateFilter({});
    }
  };

  // On tab chips click
  const onTabClick = (tab) => {
    getContentVideos({ ...queryData, status: tab });
    navigate(
      `/videos?type=${tab}&filter=${sortBy._id}${
        Object.keys(selectedCategory).length > 0
          ? `&catid=${selectedCategory._id}`
          : ""
      }${
        Object.keys(selectedSubCategory).length > 0
          ? `&subcategoryId=${selectedSubCategory._id}`
          : ""
      }${
        Object.keys(selectedPeople).length > 0
          ? `&speaker=${selectedPeople._id}`
          : ""
      }${
        Object.keys(selectedDateFilter).length > 0
          ? `&datefilter=${selectedDateFilter.id}`
          : ""
      }${searchedVideo ? `&search=${searchedVideo}` : ""}`
    );
    setCurrentTab(tab);
    setPage(1);
  };

  // on selected filter chip click
  const onSelectedChipClick = (type) => {
    if (type === "category") {
      setSelectedCategory({});
      setSelectedSubCategory({});
      navigate(
        `/videos?type=${currentTab}&filter=${sortBy._id}${
          Object.keys(selectedPeople).length > 0
            ? `&speaker=${selectedPeople._id}`
            : ""
        }${
          Object.keys(selectedDateFilter).length > 0
            ? `&datefilter=${selectedDateFilter.id}`
            : ""
        }${searchedVideo ? `&search=${searchedVideo}` : ""}`
      );
    }
    if (type === "people") {
      setSelectedPeople({});
      navigate(
        `/videos?type=${currentTab}&filter=${sortBy._id}${
          Object.keys(selectedCategory).length > 0
            ? `&catid=${selectedCategory._id}`
            : ""
        }${
          Object.keys(selectedSubCategory).length > 0
            ? `&subcategoryId=${selectedSubCategory._id}`
            : ""
        }${
          Object.keys(selectedDateFilter).length > 0
            ? `&datefilter=${selectedDateFilter.id}`
            : ""
        }${searchedVideo ? `&search=${searchedVideo}` : ""}`
      );
    }
    if (type === "date") {
      setSelectedDateFilter({});
      navigate(
        `/videos?type=${currentTab}&filter=${sortBy._id}${
          Object.keys(selectedCategory).length > 0
            ? `&catid=${selectedCategory._id}`
            : ""
        }${
          Object.keys(selectedSubCategory).length > 0
            ? `&subcategoryId=${selectedSubCategory._id}`
            : ""
        }${
          Object.keys(selectedPeople).length > 0
            ? `&speaker=${selectedPeople._id}`
            : ""
        }${searchedVideo ? `&search=${searchedVideo}` : ""}`
      );
    }
  };

  const handleSelectCategory = (data) => {
    setSelectedCategory(data);
    setPage(1);
    navigate(
      `/videos?type=${currentTab}&filter=${sortBy._id}${
        Object.keys(data).length > 0 ? `&catid=${data._id}` : ""
      }${
        Object.keys(selectedSubCategory).length > 0
          ? `&subcategoryId=${selectedSubCategory._id}`
          : ""
      }${
        Object.keys(selectedPeople).length > 0
          ? `&speaker=${selectedPeople._id}`
          : ""
      }${
        Object.keys(selectedDateFilter).length > 0
          ? `&datefilter=${selectedDateFilter.id}`
          : ""
      }${searchedVideo ? `&search=${searchedVideo}` : ""}`
    );
    setCategory(false);
  };

  const outSideClick = (e) => {
    if (sortRef.current && isSorting && !sortRef.current.contains(e.target)) {
      setSorting(false);
    }
    if (
      categoryRef.current &&
      isCategory &&
      !categoryRef.current.contains(e.target)
    ) {
      setCategory(false);
    }
  };
  document.addEventListener("mousedown", outSideClick);
  document.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      handleCancelFilter();
    }
  });

  // this function will render empty state on the base of tab
  const renderEmptyState = (
    displayCondition,
    currentTab,
    iconDefault,
    iconFiltered,
    titleDefault,
    titleFiltered,
    subTitleDefault,
    subTitleFiltered,
    buttonLabel
  ) => {
    const hasFilters =
      Object.keys(selectedCategory).length +
        Object.keys(selectedPeople).length +
        Object.keys(selectedDateFilter).length !==
        0 ||
      searchedVideo ||
      (displayCondition.length === 0 && currentTab === "history");
    if (!pageLoader && displayCondition.length === 0) {
      return (
        !pageLoader &&
        displayCondition.length === 0 &&
        currentTab === currentTab && (
          <EmptyState
            extraClass="grid-full-row"
            label={buttonLabel}
            icon={hasFilters ? iconFiltered : iconDefault}
            hasTitle
            title={hasFilters ? titleFiltered : titleDefault}
            hasSubTitle
            subTitle={hasFilters ? subTitleFiltered : subTitleDefault}
            isClear={hasFilters}
            onClick={() => {
              handleClearFilter();
              navigate(`/videos?type=${currentTab}&filter=recent`);
            }}
          />
        )
      );
    }
  };

  const [hasCarouselButton, sethasCarouselButton] = useState(false);
  const [totalButtonWidth, settotalButtonWidth] = useState(0);
  const [totalButton, settotalButton] = useState(0);
  const [hasCarouselButtonSide, sethasCarouselButtonSide] = useState("right");
  const filterButtonWrapperRef = useRef(null);

  const handleCarouselButton = () => {
    if (filterButtonWrapperRef.current) {
      const filterButtonWrapper = filterButtonWrapperRef.current;
      const scrollPosition = filterButtonWrapper.scrollLeft;
      if (scrollPosition <= 0) {
        filterButtonWrapper.scrollLeft += Number(
          totalButtonWidth - filterButtonWrapper.offsetWidth + totalButton * 8
        );
      } else {
        filterButtonWrapper.scrollLeft += Number(
          filterButtonWrapper.offsetWidth -
            (totalButton - 1) * 8 -
            totalButtonWidth
        );
      }
    }
  };

  const refScrollFun = () => {
    const filterButtonWrapper = filterButtonWrapperRef.current;
    const scrollPosition = filterButtonWrapper.scrollLeft;
    if (scrollPosition <= 0) {
      sethasCarouselButtonSide("right");
    } else {
      sethasCarouselButtonSide("left");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const filterButtonBox = filterButtonWrapperRef.current;
      if (!filterButtonBox) return;

      const rectFilterButtonBox = filterButtonBox.getBoundingClientRect();
      const filterButtons = filterButtonBox.querySelectorAll("button");

      let totalWidth = 0;
      filterButtons.forEach((element) => {
        const rectFilterButtons = element.getBoundingClientRect();
        totalWidth += rectFilterButtons.width;
      });

      settotalButtonWidth(totalWidth);
      settotalButton(filterButtons.length);

      if (
        rectFilterButtonBox.width <
        totalWidth + (filterButtons.length - 1) * 8
      ) {
        sethasCarouselButton(true);
      } else {
        sethasCarouselButton(false);
      }
    };

    // Attach resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleCarouselButton, filterButtonWrapperRef]);

  return (
    <>
      {access2 ||
      (localStorage.getItem("remindmetomorrowcensus") &&
        moment(
          localStorage.getItem("remindmetomorrowcensus"),
          "YYYY-MM-DD"
        ).diff(moment().format("YYYY-MM-DD"), "days") === 0 &&
        localStorage.getItem("dontshowcensuspopup") === "false" &&
        (access || access1)) ||
      ((localStorage.getItem("remindmetomorrowcensus") === null ||
        localStorage.getItem("remindmetomorrowcensus") === undefined) &&
        (localStorage.getItem("dontshowcensuspopup") === "false" ||
          localStorage.getItem("dontshowcensuspopup") === null ||
          localStorage.getItem("dontshowcensuspopup") === undefined) &&
        (access || access1)) ? (
        <CensusDataModal
          access={access}
          setAccess={setAccess}
          access1={access1}
          setAccess1={setAccess1}
          access2={access2}
          setAccess2={setAccess2}
        />
      ) : (
        <div className="cstm-content-archive-main cstm-conten-listing-pg-only">
          {pageLoader && page === 1 ? (
            <div className="cstm-cl-only-sk">
              <div className="cstm-cl-top">
                <h2
                  class="cstm-cl-title skeleton-box"
                  style={{ height: "40px", width: "234px" }}
                ></h2>
                <div
                  className="dropdown-wrapper skeleton-box"
                  style={{ height: "24px", width: "81px" }}
                ></div>
              </div>
              <div className="video-filter-container">
                <div className="video-buttons-tabs-wrapper">
                  <div className="video-browse-tabs">
                    <div
                      className="skeleton-box rounded-full"
                      style={{ width: "87px", height: "42px" }}
                    ></div>
                    <div
                      className="skeleton-box rounded-full"
                      style={{ width: "82px", height: "42px" }}
                    ></div>
                    <div
                      className="skeleton-box rounded-full"
                      style={{ width: "79px", height: "42px" }}
                    ></div>
                  </div>
                  <div className="vertical-seprator"></div>
                  <div className="filter-button-wrapper">
                    <div
                      className="skeleton-box rounded-full"
                      style={{ width: "111px", height: "42px" }}
                    ></div>
                  </div>
                </div>
                <div
                  className="skeleton-box rounded-full"
                  style={{ width: "94px", height: "42px" }}
                ></div>
              </div>
            </div>
          ) : (
            <div className="cstm-cl-only-sk">
              <div className="cstm-cl-top">
                <div class="cstm-cl-title">Browse videos</div>
                <div className="dropdown-wrapper" ref={sortRef}>
                  <DropDown
                    label=""
                    selected={
                      sortByList.find((obj) => obj._id === sortBy._id).name
                    }
                    onOpen={() => setSorting(!isSorting)}
                    isOpen={isSorting}
                  />
                  {isSorting && (
                    <DropDownItem
                      selectedItem={sortBy}
                      options={sortByList}
                      onSelect={(data) => handleSortBy(data)}
                    />
                  )}
                </div>
              </div>
              <div className="video-filter-container">
                <div className="video-buttons-tabs-wrapper">
                  <div className="video-browse-tabs">
                    <Chips
                      className="large"
                      label="Library"
                      // label={`Library ${
                      //   listingCount.all > 0 ? `(${listingCount.all})` : ""
                      // }`}
                      isActive={currentTab === "all"}
                      handleClick={() => {
                        onTabClick("all");
                      }}
                    />
                    <Chips
                      className="large"
                      label="Saved"
                      // label={`Saved ${
                      //   listingCount.saved > 0 ? `(${listingCount.saved})` : ""
                      // }`}
                      isActive={currentTab === "saved"}
                      handleClick={() => {
                        onTabClick("saved");
                      }}
                    />
                    <Chips
                      className="large"
                      label="History"
                      // label={`History ${
                      //   listingCount.history > 0
                      //     ? `(${listingCount.history})`
                      //     : ""
                      // }`}
                      isActive={currentTab === "history"}
                      handleClick={() => {
                        onTabClick("history");
                      }}
                    />
                  </div>
                  <div className="vertical-seprator"></div>
                  {Object.keys(selectedCategory).length +
                    Object.keys(selectedPeople).length +
                    Object.keys(selectedDateFilter).length ===
                    0 && (
                    <div
                      className="chips-filter-dropdown-wrapper position-relative"
                      ref={categoryRef}
                    >
                      <ChipFilter
                        extraClass="flex-shrink-0"
                        handleClick={() => setCategory(!isCategory)}
                        label="Category"
                        dropIcon={
                          isCategory ? <ArrowChevronUp /> : <ArrowChevronDown />
                        }
                      />
                      {isCategory && (
                        <DropDownItem
                          size="large"
                          selectedItem={sortBy}
                          options={allCategories?.data}
                          onSelect={(data) => handleSelectCategory(data)}
                        />
                      )}
                    </div>
                  )}
                  <div
                    className={`scrollbar-wrapper ${
                      hasCarouselButton ? "grediant-shadow" : ""
                    }`}
                  >
                    <div
                      className="filter-button-wrapper hidden-scrollbar "
                      onScroll={refScrollFun}
                      ref={filterButtonWrapperRef}
                    >
                      {Object.keys(selectedCategory).length > 0 && (
                        <ChipFilter
                          extraClass="flex-shrink-0"
                          isActive
                          label={
                            Object.keys(selectedSubCategory).length > 0
                              ? selectedSubCategory.name
                              : selectedCategory.name
                          }
                          closeIcon={<IcnMClear />}
                          handleClick={() => onSelectedChipClick("category")}
                        />
                      )}
                      {Object.keys(selectedPeople).length > 0 && (
                        <ChipFilter
                          extraClass="flex-shrink-0"
                          isActive
                          handleClick={() => onSelectedChipClick("people")}
                          label={`${selectedPeople.first_name} ${selectedPeople.last_name}`}
                          hasAvatarIcon
                          closeIcon={<IcnMClear />}
                          avatarIcon={
                            selectedPeople?.speakerIcon ? (
                              <Avtar
                                src={selectedPeople?.speakerIcon}
                                size="24"
                              />
                            ) : (
                              <Avtar
                                type="letter"
                                size="24"
                                letter={selectedPeople?.first_name}
                              />
                            )
                          }
                        />
                      )}
                      {Object.keys(selectedDateFilter).length > 0 && (
                        <ChipFilter
                          extraClass="flex-shrink-0"
                          isActive
                          handleClick={() => onSelectedChipClick("date")}
                          hasIcon
                          eleIcon={<CalendarIcon />}
                          label={
                            selectedDateFilter.id === "custom"
                              ? `${moment(fromDate).format(
                                  "MMM DD, YYYY"
                                )} - ${moment(toDate).format("MMM DD, YYYY")}`
                              : selectedDateFilter.label
                          }
                          closeIcon={<IcnMClear />}
                        />
                      )}
                    </div>
                  </div>
                  {hasCarouselButton ? (
                    <ButtonCarousel
                      hadleClick={handleCarouselButton}
                      type={hasCarouselButtonSide}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <FilterIcon
                  isActive
                  icon={<FilterIconAlt />}
                  hasIcon
                  hasText
                  filterText="Filters"
                  handleClick={() => {
                    setFilter(!isFilter);
                  }}
                  label={filterCount}
                />
              </div>
            </div>
          )}

          {currentTab === "all" ? (
            <LibraryListing
              displayVideos={displayVideos}
              pageLoader={pageLoader}
              totalVideos={totalVideos}
              setPage={setPage}
              page={page}
              noData={noData}
              renderEmptyState={renderEmptyState}
              setListingCount={setListingCount}
              selectedCategory={selectedCategory}
            />
          ) : currentTab === "saved" ? (
            <SavedListing
              page={page}
              displayVideos={displaySavedVideos}
              pageLoader={pageLoader}
              totalVideos={totalVideos}
              setPage={setPage}
              setDisplaySavedVideos={setDisplaySavedVideos}
              setTotalVideos={setTotalVideos}
              noData={noData}
              renderEmptyState={renderEmptyState}
              setListingCount={setListingCount}
              setNoData={setNoData}
            />
          ) : (
            currentTab === "history" && (
              <HistoryListing
                page={page}
                displayVideos={displayHistoryVideos}
                pageLoader={pageLoader}
                totalVideos={totalVideos}
                setPage={setPage}
                setDisplayHistoryVideos={setDisplayHistoryVideos}
                setTotalVideos={setTotalVideos}
                noData={noData}
                renderEmptyState={renderEmptyState}
                setListingCount={setListingCount}
                setNoData={setNoData}
                handleClearFilter={handleClearFilter}
              />
            )
          )}
          <div className={`${isFilter ? "modal-fadein" : "modal-fadeout"}`}>
            <div
              className="desk-globle-model-box globle-model-box justify-content-end"
              ref={filterRef}
            >
              <VideoFilterSidebar
                allCategories={allCategories?.data}
                resultCount={filteredDataCount?.total}
                dateList={dateList}
                filterRef={filterRef}
                onCancel={handleCancelFilter}
                onClear={handleClearFilter}
                handleSubCategoryFilter={handleSubCategoryFilter}
                handleCategoryFilter={handleCategoryFilter}
                allSpeakers={allSpeakers?.data}
                selectedCategory={selectedCategory}
                selectedSubCategory={selectedSubCategory}
                handlePeopleFilter={handlePeopleFilter}
                selectedPeople={selectedPeople}
                handleDateFilter={handleDateFilter}
                selectedDateFilter={selectedDateFilter}
                handleShowResult={handleShowResult}
                handleToDateChange={handleToDateChange}
                handleFromDateChange={handleFromDateChange}
                toDate={toDate}
                fromDate={fromDate}
                allCategoriesList={allCategoriesList}
                allSpeakersList={allSpeakersList}
                setAllSpeakersList={setAllSpeakersList}
                setAllCategoriesList={setAllCategoriesList}
                handleCloseIconClick={handleCloseIconClick}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ContentListing;
