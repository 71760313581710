import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import Services from "../../../Services/auth.service";
import Select from "react-select";
import AlertComponent from '../../../Hooks/AlertComponent';
import authServiceCollaborator from '../../../Services/auth.service.collaborator';
import Loader2 from "../../../Images/loader2.gif";

const Invite = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [inProcess, setInProcess] = useState(false);
    const [inviteDetail, setInviteDetail] = useState({
        email: "",
        user: {}
    })
    const [detailErr, setDetailErr] = useState({
        email: "",
        user: ''
    })
    const [allUser, setAllUser] = useState([])
    const [responseMessage, setResponseMessage] = useState("");
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    //email regex
    const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    // this will call on first render of component and call apis 
    useEffect(() => {
        Services.getallusers("")
            .then((res) => {
                if (res.status) {
                    let userList = res.data
                    let arr = []
                    if (userList !== undefined && userList.length > 0) {
                        userList.map(user => {
                            user.first_name !== undefined && user.last_name !== undefined
                                && (user.first_name !== "" || user.last_name !== "") &&
                                arr.push({
                                    value: user._id,
                                    label: `${user.first_name} ${user.last_name}`.trim()
                                })
                        })
                        setAllUser(arr)
                    }
                } else {
                    setAllUser([])
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, [])

    // invite send api function
    async function sendInviteApi() {
        const bodyData = {
            userId: inviteDetail.user.value,
            emailAddress: inviteDetail.email
        }
        try {
            const sendInviteRes = await authServiceCollaborator.sendInvite(bodyData)
            if (sendInviteRes.data.status) {
                setInProcess(false)
                setResponseMessage(sendInviteRes.data.message)
                setInviteDetail({
                    email: "",
                    user: ''
                })
                setTimeout(() => {
                    setResponseMessage('')
                    navigate('/membership/invitelist')
                }, 3000);
            } else {
                setInProcess(false)
                setResponseMessage(sendInviteRes.data.message)
                setTimeout(() => {
                    setResponseMessage('')
                }, 3000);
            }
        } catch (error) {
            setInProcess(false)
            return false
        }
    }

    // validate function for required field
    const validate = () => {
        const errors = {};
        switch (true) {
            case inviteDetail.email.trim() === '':
                errors.email = 'Enter email address';
            case inviteDetail.user === '':
                errors.user = 'Select membership user';
            default:
        }
        inviteDetail.email.trim() === '' ? errors.email = 'Enter email address' : !emailRegex.test(inviteDetail.email) ? errors.email = 'Enter valid email address' : errors
        if (Object.keys(errors).length > 0) {
            const emptyKeys = Object.keys(errors).filter((key) => errors[key] !== "");
            const inputElement = document.getElementsByName(emptyKeys[0])[0];
            if (inputElement) {
                inputElement.focus(); // Focus the input element
            }
        }
        setDetailErr(errors)
        return Object.keys(errors).length === 0;
    }

    // handle invite
    const handleSendInvite = (e) => {
        e.preventDefault()
        if (validate()) {
            setInProcess(true)
            sendInviteApi()
        }
    }

    // handle input change
    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch({ type: "SETMODALINPROCESS" })
        setInviteDetail({ ...inviteDetail, [name]: value })
        switch (name) {
            case 'email':
                value.trim() === '' ? setDetailErr({ ...detailErr, email: 'Enter email address' }) : !emailRegex.test(value) ? setDetailErr({ ...detailErr, email: 'Enter valid email address' }) : setDetailErr({ ...detailErr, email: '' })
                break
            default:
                break
        }
    }

    // handle user change for dropdown
    const handleUserChange = (selectedOption) => {
        dispatch({ type: "SETMODALINPROCESS" })
        setDetailErr({ ...detailErr, user: "" })
        setInviteDetail({ ...inviteDetail, user: selectedOption })
    }

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }
    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    {responseMessage.length > 0 && (
                        <div className="alert alert-info">{responseMessage}</div>
                    )}
                    <div className="um-back">
                        <button
                            type="button"
                            className="back-btn"
                            onClick={() => {
                                if (modalInProcess) {
                                    dispatch({ type: "SETCUSTOMPAGECHANGE" })
                                    dispatch({ type: "NAVIGATEPAGE", payload: "/membership/invitelist" })
                                } else {
                                    navigate("/membership/invitelist");
                                }
                            }}
                        >
                            <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
                            Back
                        </button>
                    </div>
                    <div className="uic-title uic-title-crt-plan">
                        <h1>Send Membership Invite</h1>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 form-group">
                            <div className="form-group-label">Invitee Email*</div>
                            <div className="form-group-ct">
                                <input
                                    type="text"
                                    name="email"
                                    value={inviteDetail.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <span className="error">{detailErr.email}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 form-group">
                            <div className="form-group-label">Select Member*</div>
                            <div className="form-group-ctst event-type-drop-only">
                                <Select
                                    isSearchable={true}
                                    options={allUser}
                                    value={inviteDetail.user}
                                    styles={{ zIndex: 9999 }}
                                    onChange={handleUserChange}
                                />
                            </div>
                            <span className="error">{detailErr.user}</span>
                        </div>
                    </div>
                    <div className="cyp-btm-btn">
                        {inProcess ? (<div className="loader-button1">
                            <button>In Process...</button>
                            <span className="loader-btn">
                                <img src={Loader2} />
                            </span>
                        </div>) : <button
                            className="btn"
                            disabled={inProcess}
                            onClick={handleSendInvite}
                        >
                            Send Invite
                        </button>}
                        <button
                            className="btn2" onClick={() => {
                                handleClearAllStates()
                                navigate("/membership/invitelist")
                            }}
                        >
                            Discard
                        </button>
                    </div>
                </div>
            </div>
            <AlertComponent />
        </>
    )
}

export default Invite
