import React from 'react'
import succesIcon from "../../../../Images/notifications.svg";
const SuccessModal = ({ isSuccsess, setSuccess }) => {
    return (
        <>
            <div className={`${isSuccsess ? "modal-fadein" : "modal-fadeout"}`}>
                <div className="desk-globle-model-box globle-model-box ">
                    <div className="desk-globle-model-box-inner partners-details-popup-main">
                        <div className="partners-details-popup-your-review">
                            <img src={succesIcon} />
                            <div className='unlock-partners'>
                            <h3 className="your-review-title">Congrats, you're in!</h3>
                            <p className="sometihng-p">Thanks for sharing your thoughts.
                                <br />
                                Enjoy the all the partners' offers now!</p>
                            </div>
                            <span className="partners-details-btn-main" onClick={()=>{
                                setSuccess(false)
                                document.body.classList.remove("modal-open");
                            }}>
                                Explore partners
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessModal