import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
// Import api integration file
import Services from "../../../Services/auth.service";
import auth from "../../../Services/auth0services";
import { setregidetail } from "../../../Action";
import loading from "../../../Images/loader.gif";
const SocialSignup = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const processHash = (hash) => {
        auth.parseHash({ hash }, function (err, result) {
          if (err) {
            console.log("there is an error");
            console.log(err);
          } else {
            console.log(result);
            const email = result.idTokenPayload.email;
            const userid = result.idTokenPayload.sub.split("|")[1];
            const media = result.idTokenPayload.sub.split("|")[0];
            var auth0_data = {
                email: email,
                provider: media,
                auth0Id: userid,
                isSocial: true,
                webOrApp: "web"
              }
              Services.socialuserexist({ facebookLinkedinId: userid}).then((response)=>{
                if (!response.data.status) {
                  Services.saveauth0detail(auth0_data).then((res) => {
                    if(res.data.status)
                    {
                      dispatch(setregidetail({ userid: res.data.data._id,
                        provider: media,
                        register_status: true,
                        personalDetail_status: false,
                        payment_status: false,
                        QA_status: false,
                        emailid: email,
                        backpage: "preemail"}));
                      history("/firststepregister/3");
                    } else if(res.data.data) {
                      const userinfo = res.data.data
                      if (
                        userinfo.register_status &&
                        userinfo.personalDetail_status &&
                        userinfo.payment_status &&
                        userinfo.QA_status 
                      ) {
                        history("/firststepregister/2", {state: {UserExist: true}})
                      } else {
                        if (!userinfo.personalDetail_status) {
                          setTimeout(() => {
                            dispatch(
                              setregidetail({
                                userid: userinfo.userid,
                                provider: userinfo.provider,
                                register_status: userinfo.register_status,
                                personalDetail_status: userinfo.personalDetail_status,
                                payment_status: userinfo.payment_status,
                                QA_status: userinfo.QA_status,
                                emailid: userinfo.emailid,
                                backpage: "login",
                              })
                            );
                            localStorage.removeItem("userinfo", "");
                            history("/firststepregister/3");
                          }, 2000);
                        } else if (!userinfo.payment_status) {
                          setTimeout(() => {
                            dispatch(
                              setregidetail({
                                userid: userinfo.userid,
                                provider: userinfo.provider,
                                register_status: userinfo.register_status,
                                personalDetail_status: userinfo.personalDetail_status,
                                payment_status: userinfo.payment_status,
                                QA_status: userinfo.QA_status,
                                emailid: userinfo.email,
                                backpage: "login",
                              })
                            );
                            localStorage.removeItem("userinfo", "");
                            history("/firststepregister/4");
                          }, 2000);
                        } else if (!userinfo.QA_status) {
                          setTimeout(() => {
                            dispatch(
                              setregidetail({
                                userid: userinfo.userid,
                                provider: userinfo.provider,
                                register_status: userinfo.register_status,
                                personalDetail_status: userinfo.personalDetail_status,
                                payment_status: userinfo.payment_status,
                                QA_status: userinfo.QA_status,
                                emailid: userinfo.email,
                                backpage: "login",
                              })
                            );
                            localStorage.removeItem("userinfo");
                            history("/firststepregister/6");
                          }, 2000);
                        } 
                      }
                    } else {
                      alert(res.data.message);
                    }
                  }).catch((e) => {
                    console.log(e)
                  })
                } else {
                  history("/firststepregister/2", {state: {UserExist: true}})
                }
              }).catch((e)=>{
                console.log(e)
              })
            
            localStorage.setItem("regiuserid", "");
            console.log("successfull");
          }
        });
      };
    
      useEffect(() => {
        if (location.hash) {
          processHash(location.hash);
        }
      }, [location]);
    return (
        <>
         <div className="loadind-post">
            <img src={loading} />
          </div>
        </>
    )
}
export default SocialSignup;