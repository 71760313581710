import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/Button/Button";

// Icons
import CrossIcon from "../../../../components/Icons/GeneralIcons/CrossIcon";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageBarReply.css";

const MessageBarReply = ({
  handleClickOnRepliedMessage = () => {},
  type = "MessageReply",
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  avtarName = "Anton Reponen",
  replyText = "Medium short text msg",
  alt = "images",
  hasRemoveButton = true,
  handleClick,
}) => {
  let imageUrl;

  if (typeof src === "string") {
    imageUrl = src;
  } else if (typeof src === "object") {
    imageUrl = src.type === "videos" ? src.thumbnail : src.img;
  } else {
    console.error("Invalid src format");
  }

  return (
    <div
      className="MessageBarReply-Sec flex items-center"
      // onClick={handleClickOnRepliedMessage}
    >
      <div className="reply-line"></div>
      <div
        className={`MessageBarReply-container flex items-center ${
          type === "ImageReply"
            ? "ImageReply"
            : type === "VideoReply"
            ? "VideoReply"
            : type === "VoiceReply"
            ? "Voice"
            : ""
        }`}
      >
        {type === "MessageReply" ||
        type === "FileReply" ||
        type === "VoiceReply" ? (
          <div className="MessageBar-content">
            <p className="avtarName">{avtarName}</p>
            <p className="replyText">
              {replyText && replyText.length
                ? replyText
                : type === "FileReply"
                ? "File"
                : type === "VoiceReply"
                ? "Voice"
                : "Voice"}
            </p>
          </div>
        ) : (
          <>
            <img src={imageUrl} alt={alt} />
            <div className="MessageBar-content">
              <p className="avtarName">{avtarName}</p>
              <p className="replyText">
                {replyText && replyText.length
                  ? replyText
                  : type === "ImageReply"
                  ? "Image"
                  : type === "VideoReply"
                  ? "Video"
                  : type === "VoiceReply"
                  ? "Voice"
                  : "Voice"}
              </p>
            </div>
          </>
        )}
      </div>
      {hasRemoveButton && (
        <Button
          elementIcon={<CrossIcon />}
          handleClick={handleClick}
          onContextMenu={() => {}}
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          variant="ghost"
          size="extrasmall"
          hasText={false}
          toolTipText="Close"
        />
      )}
    </div>
  );
};

MessageBarReply.propTypes = {
  type: PropTypes.oneOf([
    "MessageReply",
    "ImageReply",
    "VideoReply",
    "FileReply",
    "VoiceReply",
  ]),
  src: PropTypes.string,
  avtarName: PropTypes.string,
  replyText: PropTypes.string,
  alt: PropTypes.string,
  handleClick: PropTypes.func,
  hasRemoveButton: PropTypes.bool,
};

export default MessageBarReply;
