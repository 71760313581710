import React from 'react'
import PropTypes from "prop-types"
const MentionIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M2 12C2 6.49 6.49 2 12 2s10 4.49 10 10v3.518a3.483 3.483 0 0 1-3.467 3.467c-1.785 0-3.214-1.392-3.434-3.126-.888.702-1.962 1.11-3.1 1.116A4.99 4.99 0 0 1 7.026 12 4.99 4.99 0 0 1 12 7.025 4.99 4.99 0 0 1 16.975 12v3.518c0 .872.686 1.558 1.558 1.558s1.558-.686 1.558-1.558V12c0-4.48-3.61-8.09-8.091-8.09-4.48 0-8.09 3.61-8.09 8.09s3.61 8.09 8.09 8.09A.955.955 0 1 1 12 22C6.49 22 2 17.51 2 12Zm10-3.065A3.05 3.05 0 0 0 8.935 12 3.05 3.05 0 0 0 12 15.065 3.05 3.05 0 0 0 15.065 12 3.05 3.05 0 0 0 12 8.935Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
MentionIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default MentionIcon
