import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from "../../Images/logo_mds_small.svg";
import Successful from "../../Images/Successful.svg";
import "./DeleteAccountPage.css"
const DeleteAccountPage = () => {

    //email regex
    const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    const [email, setEmail] = useState("")
    const [err, setErr] = useState("")
    const [msg, setMsg] = useState("")
    // handle email change
    const handleChange = (e) => {
        setEmail(e.target.value)
        e.target.value.trim() === "" ? setErr("Please enter email address.") : !emailRegex.test(e.target.value) ? setErr("Please enter a valid email address.") : setErr("")
    }

    const handleClick = () => {
        setEmail("")
        setMsg("An account deletion request has been submitted to admin, We will contact you shortly for update.")
    }
    return (
        <>
            <div className="header-collaborator"><Link to="/"><img src={Logo} /></Link></div>

            {msg ?
                <div className="collaborator-main">
                    <div className="collaborator-emailicon"><img src={Successful} /></div>

                    <div className="collaborator-succesfully">
                        <div className="collaborator-info">
                            <h4 className='collaborator-h4'>{msg}</h4>
                        </div>
                    </div>
                </div> :
                <div className="delete-account-main">
                    <div className="cstm-user-header-main">
                        <h2 className="cstm-user-title">Delete Account</h2>
                    </div>
                    <div className="cstm-user-email-address">
                        <div className="cstm-user-email-address-box">
                            <h5>Email address</h5>
                            <input type='email' placeholder='Enter email address' className={err ? 'cstm-input-field error-ft-border' : 'cstm-input-field'} onChange={handleChange} />
                            {err && <span className='error-ft'>{err}</span>}
                        </div>
                        <div className="cstm-user-email-address-btn">
                            <button className='btn-review disabled-btn-color' disabled={email.trim() === "" || !emailRegex.test(email)} onClick={handleClick} >
                                Delete account</button>
                        </div>
                    </div>
                </div>}

        </>
    )
}

export default DeleteAccountPage