import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Button.css";
import TagIcon from "../Icons/GeneralIcons/TagIcon";
import Spinners from "../Spinners/Spinners";
import "../../Styles/Theme/Theme.css";
import ToolTip from "../ToolTip/ToolTip";

const Button = ({
  variant = "primary",
  size = "medium",
  disabled = false,
  label = "",
  buttonId = "buttonId",
  // Bollean
  hasIconLeft = false,
  hasIconRight = false,
  hasLoading = false,
  hasText = true,

  //   Loader Icon
  spinnersType = "white",

  //   Icon
  iconColor = "var(--color-icon-neutral-strong)",
  isIconFilled = true,
  elementIcon = (
    <TagIcon
      width={24}
      height={24}
      iconColor={iconColor}
      isIconFilled={isIconFilled}
    />
  ),
  extraClass = "",
  // toolTip
  hasToolTip = false,
  toolTipText = "toolTip",
  toolTipId = "tooltip",
  //   Click Events
  handleClick,
  onContextMenu,
  onMouseEnter,
  onMouseLeave,
  refClass,
  children,
}) => {
  const [toolTipClass, setToolTipClass] = useState("tool-tip-botom");
  const handleMouseEnter = (element) => {
    let heights = window.innerHeight - element.pageY;
    if (heights < 200) {
      setToolTipClass("tool-tip-top");
    }
  };
  return (
    <>
      <button
        data-size={size}
        className={`button relative ${size} ${extraClass} ${variant} ${!hasText ? "icon-only" : ""
          } ${variant === "ghost" ? toolTipClass : ""}`}
        onClick={handleClick}
        disabled={disabled}
        onContextMenu={onContextMenu}
        onMouseLeave={() => { }}
        onMouseEnter={handleMouseEnter}
        id={buttonId}
        ref={refClass}
      >
        {/* {label} */}
        {hasText ? (
          <>
            {/* Left Icon */}
            {hasIconLeft && !hasLoading && elementIcon}
            {/* text */}
            <div className="button-text">{label}</div>

            {/* RightICon */}
            {hasIconRight && !hasLoading && elementIcon}
          </>
        ) : (
          //  Icon Button
          <>{!hasLoading && elementIcon}</>
        )}
        {hasLoading && <Spinners size="24" type={spinnersType} />}

        {children}
      </button>
    </>
  );
};

// define types to all props
Button.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "ghost",
    "tonal",
    "static-white",
    "ghost-white",
    "danger",
  ]),
  size: PropTypes.oneOf(["extrasmall", "small", "medium", "large"]),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  buttonId: PropTypes.string,
  // Bollean
  hasIconLeft: PropTypes.bool,
  hasIconRight: PropTypes.bool,
  hasLoading: PropTypes.bool,
  hasText: PropTypes.bool,
  // toolTip
  hasToolTip: PropTypes.bool,
  toolTipText: PropTypes.string,
  toolTipId: PropTypes.string,
  //   Loader Icon
  spinnersType: PropTypes.oneOf(["white", "brand"]),
  //   Icon
  iconColor: PropTypes.string,
  isIconFilled: PropTypes.bool,
  elementIcon: PropTypes.element,
  //   Click Events
  handleClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default Button;
