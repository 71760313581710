const stayOnPage = null;
const ReduStayOnPage = (state = stayOnPage, action) => {
  switch (action.type) {
    case "SETSTAYONPAGE":
        return action.payload;
    default:
      return state;
  }
};

export default ReduStayOnPage;