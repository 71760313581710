import React from 'react'
import './Separators.css'
import "../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"

const Separators = (
) => {
    return (
        <>
            <hr />
        </>
    )
}

export default Separators