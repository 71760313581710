import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
    Chart as ChartJS,
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-moment';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Filler,
    Legend
);
export default function Graph({ statisticsOptions }) {

    const [chartOptions, setChartOptions] = useState({})
    const [chartData, setChartData] = useState({})

    useEffect(() => {

        const { filterType, field, startingViewCount, fromDateTime, toDateTime, labels, chartDataArr } = statisticsOptions

        let fromDate = moment(fromDateTime);
        let toDate = moment(toDateTime);
        let daydiff = toDate.diff(fromDate, 'days')

        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        title: function (tooltipItem) {
                            var lbl = filterType === "first24hrs" ? moment(tooltipItem[0].parsed.x).format('h:mm:ss a') :
                                filterType === "past365days" ? moment(tooltipItem[0].parsed.x).format('D MMM, YYYY') :
                                    (filterType === "custom" && daydiff >= 364) ? moment(tooltipItem[0].parsed.x).format('D MMM, YYYY')
                                        : moment(tooltipItem[0].parsed.x).format('MMM, D')


                            return lbl
                        }
                    }
                },

            },

            scales: {
                x: {
                    type: 'time',
                    display: true,
                    distribution: 'series',
                    time: {
                        unit: filterType === "first24hrs" ? 'second' : (filterType === "past365days" || (filterType === "custom" && daydiff >= 364)) ? 'month' : 'day',
                    },
                    title: {
                        display: true,
                        text: filterType === "first24hrs" ? 'Hours' : (filterType === "past365days" || (filterType === "custom" && daydiff >= 364)) ? 'month' : 'Days',
                        padding: { top: 20, left: 0, right: 0, bottom: 0 }
                    },
                    min: (new Date(fromDateTime)).getTime(),
                    max: (new Date(toDateTime)).getTime(),
                    ticks: {
                        // forces step size to be 50 units
                        stepSize: filterType === "first24hrs" ? 120 : filterType === "past28days" ? 3 : filterType === "past90days" ? 15 : 1,
                    },
                    beginAtZero: true
                },
                y: {
                    display: true,
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: field === "views" ? `Views (starting view: ${startingViewCount != undefined && startingViewCount} )` : field === "likes" ? 'Likes' : field === "pageView" ? `Page views` : field === "claim" ? 'Page claims' : field === "rating" ? 'Ratings' : 'Comments',

                    },
                    min: 0,
                    ticks: {
                        precision: 0
                    }
                }
            }

        };
        setChartOptions(chartOptions)

        const chartData = {
            labels,
            datasets: [
                {
                    fill: true,
                    label: '',
                    data: chartDataArr,
                    borderColor: field == "views" || field === "pageView" ? 'rgba(53, 27, 206, 1)' : field == "comments" || field == "claim" ? 'rgba(240, 82, 82, 1)' : 'rgba(49, 196, 141, 1)',
                    backgroundColor: field == "views" || field === "pageView" ? 'rgba(235, 233, 251, 1)' : field == "comments" || field == "claim" ? 'rgba(255,238,238,255)' : 'rgba(231,249,244,255)',
                },
            ],
        };
        setChartData(chartData)

    }, [statisticsOptions])

    return (
        <>
            {chartOptions !== undefined && chartData !== undefined
                && chartOptions !== null && chartData !== null && statisticsOptions.filterType !== "lifetime"
                && chartOptions !== {} && chartData !== {} && chartData.datasets ?
                <Line options={chartOptions} data={chartData} /> : <></>}
        </>
    );
};

