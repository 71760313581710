import axios from "axios";
import authAdminHeader from "./auth.admin.header";
import authHeader from "./auth.header";

const API_URL = process.env.REACT_APP_API_URL;

const sendInvite = (data) => {
    return axios.post(API_URL + 'collaborator/sendInvitation', data, { headers: authAdminHeader() })
}

const getPendingCollaboratorsList = (query) => {
    return axios.get(API_URL + 'getPendingCollaborators' + query, { headers: authAdminHeader() })
}

const getRevokeCollaboratorsList = (query) => {
    return axios.get(API_URL + 'getRevokedCollaborators' + query, { headers: authAdminHeader() })
}

const getAcceptedCollaboratorsList = (query) => {
    return axios.get(API_URL + 'getAcceptedCollaborators' + query, { headers: authAdminHeader() })
}

const resendInvitation = (data) => {
    return axios.post(API_URL + 'collaborator/resendAndRevoke', data, { headers: authAdminHeader() })
}

const sendOtpToCollaborator = (id) => {
    return axios.get(API_URL + 'collaborator?id=' + id, { headers: authAdminHeader() })
}

const verifyOtp = (data) => {
    return axios.post(API_URL + 'collaborator/acceptInvitation', data)
}

const resendOtp = (id) => {
    return axios.post(API_URL + 'collaborator/resend?id=' + id)
}

const collaboratorRegister = (data) => {
    return axios.post(API_URL + 'collaborator/register', data)
}

const getCollaboratorResources = () => {
    return axios.get(API_URL + 'user/getUserCollaboratorResources', { headers: authHeader() })
}

const getCollaboratorDetail = (id) => {
    return axios.get(API_URL + "collaboratorDetail?id=" + id)
}
const getPlanWiseMemberDetail = (id) => {
    return axios.get(API_URL + 'membership/getPlanWiseMemberDetail/' + id, { headers: authAdminHeader() })
}

// collaborator for user
const getCollaboratorsForUser = () => {
    return axios.get(API_URL + 'getCollaboratorsByStatusForUser?status=all', { headers: authHeader() })
}
const sendInviteByUser = (data) => {
    return axios.post(API_URL + 'collaborator/sendInvitationByUser', data, { headers: authHeader() })
}
const revokeOrResendInviteByUser = (data) => {
    return axios.post(API_URL + 'collaborator/resendAndRevokeByUser', data, { headers: authHeader() })
}
const getFirebaseDeviceToken = (data) => {
    return axios.post(API_URL + 'user/storeWebDeviceToken', data, { headers: authHeader() })
}

const getAllCollaboratorUsers = (query) => {
    return axios.get(API_URL + `collaborator/getAllCollaboratorUsers${query}`, { headers: authAdminHeader() })
}
export default {
    getPlanWiseMemberDetail,
    getCollaboratorDetail,
    sendInvite,
    getCollaboratorResources,
    getPendingCollaboratorsList,
    getRevokeCollaboratorsList,
    getAcceptedCollaboratorsList,
    resendInvitation,
    sendOtpToCollaborator,
    verifyOtp,
    resendOtp,
    collaboratorRegister,
    getCollaboratorsForUser,
    sendInviteByUser,
    revokeOrResendInviteByUser, getFirebaseDeviceToken,
    getAllCollaboratorUsers
}