import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AdminSidebar from "../AdminSidebar";
import PageNotFound from "../PageNotFound";
import Banners from "./BannerManagement/Banners";
import News from "./NewsManagement/News";
import Posts from "./PostManagement/Posts";
import ContentListing from "./ContentManagement/ContentListing";

const NewsManagement = () => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const { action } = useParams();
    const [selectedDiv, setselectedDiv] = useState("");
    const videoInprocess = useSelector((state) => {
        return state.ReduVideoInProcess;
    })
    const [changePage, setchangePage] = useState("");
    const [showPopup, setshowPopup] = useState(false);
    const [tempVideoId, setTempVideoId] = useState("");
    const [pageNotFound, setPageNotFound] = useState(false);
    const [sidebarmenu, setSidebarmenu] = useState(false);

    useEffect(() => {
        dispatch({ type: "HISTORYPAGE", payload: "/news/banners" })
        if (action !== undefined && action.length > 0) setselectedDiv(action);
        if (action !== undefined && action !== null) {
            if (action !== "posts" && action !== "banners" && action !== "news" && action !== "content")
                setPageNotFound(true)
        } else {
            setselectedDiv("banners");
        }
    }, [action]);

    const updateWindowSize = () => {
        if (window.innerWidth > 992) {
            setSidebarmenu(false)
            document.body.classList.remove('overflow-hidden')
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowSize);
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    const sidebarHandleClick = (ele) => {
        setSidebarmenu(ele);
        if (window.innerWidth < 992) {
            if (sidebarmenu) {
                document.body.classList.remove('overflow-hidden')
            } else {
                document.body.classList.add('overflow-hidden')
            }
        }
    }


    return (
        <>
            {!pageNotFound && <div className="cstm-all-admin">
                {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
                <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
                <div className="content-main">
                    <div className="users-info">
                        <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            onClick={() => { sidebarHandleClick(false) }}
                                            className={`btn-link collapsed ${selectedDiv === "banners" ? "sd-active" : ""} `}
                                            to={"/news/banners"}
                                        >
                                            Banners
                                        </Link>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            onClick={() => { sidebarHandleClick(false) }}
                                            className={`btn-link collapsed ${selectedDiv === "posts" ? "sd-active" : ""} `}
                                            to={"/news/posts"}
                                        >
                                            Posts
                                        </Link>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            onClick={() => { sidebarHandleClick(false) }}
                                            className={`btn-link collapsed ${selectedDiv === "news" ? "sd-active" : ""} `}
                                            to={"/news/news"}
                                        >
                                            News
                                        </Link>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            onClick={() => { sidebarHandleClick(false) }}
                                            className={`btn-link collapsed ${selectedDiv === "content" ? "sd-active" : ""} `}
                                            to={"/news/content"}
                                        >
                                            Content
                                        </Link>
                                    </div>
                                </div>


                            </div>
                        </div>
                        {selectedDiv === "banners" ? (
                            <Banners />
                        ) : selectedDiv === "news" ? (
                            <News />
                        ) : selectedDiv === "posts" ? (
                            <Posts />
                        ) : selectedDiv === "content" ? (
                            <ContentListing />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>}
            {pageNotFound && <PageNotFound />}
        </>
    );
};
export default NewsManagement;
