import { useEffect, useRef, useState } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  Link,
  NavLink,
} from "react-router-dom";
import homeclh from "../../Images/HomeCLH.svg";
import homecl from "../../Images/HomeCL.svg";
import myvideoscl from "../../Images/MyVideosCL.svg";
import myvideosclh from "../../Images/MyVideosCLH.svg";
import historycl from "../../Images/HistoryCL.svg";
import historyclh from "../../Images/HistoryCLH.svg";
import timecirclecl from "../../Images/TimeCircleCL.svg";
import timecircleclh from "../../Images/TimeCircleCLH.svg";
import ViewNav from "../../Images/ViewNav.svg";
import Logo from "../../Images/logo_mds_small.svg";
import newsRoom from "../../Images/newsroom.svg";
import partnersIcon from "../../Images/icn_m_nav_partners_filled.svg";
import LeftSideBarMenu from "../GlobalComponents/LeftSideBarMenu";
import VideosMenu from "../GlobalComponents/VideosMenu";
import FacebookGroupMenu from "../GlobalComponents/FacebookGroupMenu";
import CommunityMenu from "../GlobalComponents/CommunityMenu";
import Services from "../../Services/auth.service";
import crossIcon from "../../Images/CancelCategoriesPopup.svg";

const GlobalMenuPopup = ({ setglobalmenu, globalmenu }) => {
  const [activemenu, setactivemenu] = useState("contentlibrary");
  const [showCategoryMenu, setShowCategoryMenu] = useState(false);
  const [allcategories, setallcategories] = useState([]);
  let history = useNavigate();
  const search = useLocation().search;
  const listingtype = new URLSearchParams(search).get("type");
  const categoryId = new URLSearchParams(search).get("catid");
  let ref = useRef();
  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (ref.current && globalmenu && !ref.current.contains(e.target)) {
        setglobalmenu(false);
      }
    });
    document.onkeydown = function (evt) {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        setglobalmenu(false);
      }
    };
    Services.getallcontentcategories()
      .then((res) => {
        if (res.data.status) {
          setallcategories(res.data.data);
        } else if (res.data.invalidToken) {
          localStorage.clear();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  const resourceList = localStorage.getItem("accessResources");
  const isCollaborator = JSON.parse(localStorage.getItem("isCollaborator"));
  const videoResource =
    resourceList !== null &&
    JSON.parse(resourceList).find((resource) => resource.name === "Videos");
  return (
    <>
      <div
        className={`desk-globle-model-box sidebar-menu-popup-main ${
          globalmenu ? "show-menu" : "hide-menu"
        }`}
      >
        <div
          className="custom-overlay"
          onClick={() => {
            setglobalmenu(false);
          }}
        ></div>
        <div className="sidebar-menu-popup" id="global-sidebar-menu" ref={ref}>
          {showCategoryMenu && (
            <div
              id="contentlib"
              className="collapse show category-slider-menu"
              aria-labelledby="headingfour"
            >
              <div className="category-slider-menu-in">
                <div className="category-slider-close">
                  <img
                    src={crossIcon}
                    onClick={() => {
                      setShowCategoryMenu(false);
                    }}
                  />
                </div>
                <div className="category-slider-menu-all">
                  <ul className="sidebar-main-ul">
                    {allcategories.length > 0 &&
                      allcategories.map((cate) => {
                        return (
                          <li>
                            <Link
                              to={`/videos?type=category&filter=recent&catid=${cate._id}`}
                              onClick={() => {
                                setShowCategoryMenu(false);
                                setglobalmenu(false);
                              }}
                              className={
                                listingtype === "category" &&
                                categoryId === cate._id
                                  ? "active-cs"
                                  : "sidebar-link"
                              }
                            >
                              {cate.name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          )}
          <div className="cstm-menu-pp">
            <div className="cstm-menu-pp-top">
              <div
                className="stm-menu-pp-top-view"
                onClick={() => {
                  setglobalmenu(false);
                }}
              >
                <img className="header-ft-vn" src={ViewNav} />
              </div>
              <div className="stm-menu-pp-top-logo">
                <a
                  onClick={() => {
                    history("/homepage");
                  }}
                >
                  <img src={Logo} />
                </a>
              </div>
            </div>

            <div className="cstm-menu-pp-menu sidebar-main">
              <LeftSideBarMenu />
              <div className="sidebar-footer">
                <hr className="sidebar-line" />
                <ul className="sidebar-footer-link">
                  <li>
                    <Link
                      to="https://apps.apple.com/app/id1636838955"
                      target="_blank"
                    >
                      Download iOS app
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.app.mdscommunity"
                      target="_blank"
                    >
                      Download Android app
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GlobalMenuPopup;
