import React, { useState } from "react";
import Avtar from "../../Avtar/Avtar";
import Button from "../../Button/Button";
import DotsIcon from "../../Icons/GeneralIcons/DotsIcon";
import ReplyCommentBox from "./ReplyCommentBox";
import commentUser from "../../../Images/image1.png";
import { ReactComponent as ReplyIcon } from "../../../Images/reply.svg";
import { ReactComponent as IconSuccessful } from "../../../Images/Successful.svg";
import ComplainIcon from "../../Icons/GeneralIcons/ComplainIcon";
import EditIcon from "../../Icons/GeneralIcons/EditIcon";
import DeleteIcon from "../../Icons/GeneralIcons/DeleteIcon";
import ModalPopup from "../../ModalPopup/ModalPopup";
import EmptyState from "../../EmptyState/EmptyState";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import authService from "../../../Services/auth.service";
import CrossIcon from "../../Icons/GeneralIcons/CrossIcon";

const ReplyCommentList = ({
  commentId,
  selectedVideoId,
  suggestionList,
  userImageSrc = commentUser,
  userName = "Cameron Williamson",
  commentDate = "Jun 13, 2023",
  comment = "Medium to long text v1 template is used if the wording content’s text-width is wider. Medium to long text v1 template is used if the woMedium to long text v1 template is used if the wording content’s. Medium to long text v1 template is used if the wording content’s text-width is wider. Medium to long text v1 template is used if the woMedium to long text v1 template is used if the wording content’s. Medium to long text v1 template is used if the wording",
  setAllComments,
  userId,
  mainCommentId,
  setTotalCommts,
  setDeleteComment,
  totalCommts
}) => {
  const [commentBox, setCommentBox] = useState({
    box: false,
    id: "",
    message: "",
  });
  const [reportModal, setReportModal] = useState(false);
  const [dropPos, setDropPos] = useState(false);
  const [commentDropDown, setCommentCommentDropDown] = useState(false);
  const [commentDelete, setCommentDelete] = useState({
    isOpen: false,
    commentId: "",
  });
  const [deletedCommentLoading, setDeleteCommentLoading] = useState(false);

  const handleCommentBox = (id, message) => {
    setCommentBox((prev) => ({ box: !prev.box, id: id, message: message }));
  };

  const handleCommentDrop = (event) => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const buttonRect = event.target.getBoundingClientRect();
    const buttonBottomRelativeToViewport = buttonRect.bottom + scrollTop;

    const windowHeight = window.innerHeight;
    const isButtonAtBottom =
      buttonBottomRelativeToViewport >= scrollTop + windowHeight - 200; // Adjust 10 as needed for tolerance
    if (isButtonAtBottom) {
      setDropPos(true);
    } else {
      setDropPos(false);
    }

    setCommentCommentDropDown((ele) => !ele);
  };

  const handleReport = () => {
    setReportModal(true);
  };

  const deletecmt = (videoid, comtid) => {
    setDeleteCommentLoading(true);
    var data = {
      videoId: videoid,
      commId: comtid,
    };
    authService
      .deletecomment_video(data)
      .then((res) => {
        if (res.data.status) {
          setDeleteCommentLoading(false);
          setDeleteComment(true);
          setCommentDelete({ isOpen: false, commentId: "" });
          setAllComments((prev) => {
            return prev.map((comment) => {
              if (comment._id === mainCommentId) {
                return {
                  ...comment,
                  comments: comment.comments.filter(
                    (repCom) => repCom._id !== commentId
                  ),
                };
              } else {
                return comment;
              }
            });
          });
          setTotalCommts(totalCommts-1)
          setTimeout(() => {
            setDeleteComment(false);
          }, 2000);
        } else {
          setDeleteCommentLoading(false);
        }
      })
      .catch((e) => {
        setDeleteCommentLoading(false);
        console.log(e);
      });
  };
  return (
    <>
      <div className="reply-comment-list-item comment-list-item">
        <div className="comment-wrapper">
          <Avtar size="40" src={userImageSrc} />
          <div className="comment-details">
            <div
              className="comment-text"
              onMouseLeave={() => setCommentCommentDropDown(false)}
            >
              <div className="comment-heading">
                <h5>{userName}</h5>
                <span className="comment-date">{commentDate}</span>
              </div>
              {commentBox.box ? (
                <ReplyCommentBox
                  isReply={true}
                  mainCommentId={mainCommentId}
                  message={commentBox.message}
                  commentId={commentBox.id}
                  selectedVideoId={selectedVideoId}
                  commentBox={commentBox.box}
                  setAllComments={setAllComments}
                  userImageSrc={localStorage.getItem("userprofile")}
                  cancelClick={handleCommentBox}
                  setTotalCommts={setTotalCommts}
                />
              ) : (
                <ShowMoreText
                  lines={4}
                  more="Read more"
                  less="Read less"
                  className="content-css"
                  anchorclassName="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  <p>{comment}</p>
                </ShowMoreText>
              )}
              {localStorage.getItem("userid") === userId && (
                <Button
                  variant="ghost"
                  extraClass="comment-dot-btn"
                  size={"small"}
                  handleClick={handleCommentDrop}
                  hasText={false}
                  elementIcon={<DotsIcon />}
                >
                  {commentDropDown && (
                    <div className={`comment-option ${dropPos && "top"}`}>
                      {/* <button type="button" onClick={() => {}}>
                      <ReplyIcon />
                      Reply
                    </button>
                    <button type="button" onClick={handleReport}>
                      <ComplainIcon />
                      Report
                    </button> */}
                      <button
                        type="button"
                        onMouseDown={() => {
                          handleCommentBox(commentId, comment);
                        }}
                      >
                        <EditIcon />
                        Edit
                      </button>
                      <button
                        type="button"
                        className="danger-text"
                        onClick={() => {
                          setCommentDelete((prev) => ({
                            isOpen: !prev.isOpen,
                            commentId: commentId,
                          }));
                        }}
                      >
                        <DeleteIcon />
                        Delete
                      </button>
                    </div>
                  )}
                </Button>
              )}
            </div>
            {/* <div className="reply-wrapper">
              <div className="reply-btns">
                <Button
                  variant="ghost"
                  hasText={true}
                  size="small"
                  handleClick={handleCommentBox}
                  label="Reply"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* delete comment */}
      <ModalPopup
        hasLoading={deletedCommentLoading}
        disabled={deletedCommentLoading}
        isOpen={commentDelete.isOpen}
        isFooter={true}
        isHeader={false}
        btn2variant="danger"
        buttonText1="Delete"
        buttonText="Cancel"
        className="delete-video-modal"
        onClose={() => setCommentDelete({ isOpen: false, commentId: "" })}
        handleClick2ndButton={() =>
          deletecmt(selectedVideoId, commentDelete.commentId)
        }
      >
        <div className="ctsm-modal-body">
          <Button
            size="small"
            variant="ghost"
            elementIcon={<CrossIcon />}
            handleClick={() =>
              setCommentDelete({ isOpen: false, commentId: "" })
            }
            hasText={false}
            toolTipText="Close"
          />
          <h3 className="mb-2">Delete comment</h3>
          <p>Delete your comment permanently?</p>
        </div>
      </ModalPopup>
      <ModalPopup
        isOpen={reportModal}
        isFooter={false}
        isHeader={false}
        className="report-modal"
        modelSize="648"
      >
        <div className="ctsm-modal-body">
          <EmptyState
            title="Thank you for your report!"
            hasIcon={true}
            icon={<IconSuccessful />}
            onClick={() => {
              setReportModal(false);
            }}
            label="Got it, thanks"
            subTitle={
              <>
                We're here to keep our community safe. If this content goes
                against our{" "}
                <Link to="/" target="_blank">
                  Community Guidelines
                </Link>{" "}
                , we'll remove it.
              </>
            }
          />
        </div>
      </ModalPopup>
    </>
  );
};

export default ReplyCommentList;
