import React from "react";
import Header from "../../../../components/UserComponents/Dashboard/Header";
import EventDetailsPage from "./EventDetailsPage";
import AccessDeny from "../../../../components/AccessDeny";

const index = () => {
  const resourceList = localStorage.getItem("accessResources");
  const isCollaborator = JSON.parse(localStorage.getItem("isCollaborator"));
  const partnerResource =
    resourceList !== null &&
    JSON.parse(resourceList).find((resource) => resource.name === "Events");
  return (
    <>
      {isCollaborator && partnerResource !== undefined && partnerResource ? (
        <>
          <Header />
          <EventDetailsPage />
        </>
      ) : isCollaborator !== null && !isCollaborator ? (
        <>
          <Header />
          <EventDetailsPage />
        </>
      ) : isCollaborator && partnerResource === undefined ? (
        <AccessDeny />
      ) : (
        <></>
      )}
    </>
  );
};
export default index;
