const allmessages = {};
const ReduChat = (state = allmessages, action) => {
  switch (action.type) {
    case "FETCHALLMESSAGES":
      return { ...state, ...action.payload };
    case "MDSADDMESSAGE":
      //Called when loading more data

      if (
        state[action.payload.id] !== undefined &&
        action.payload.data.length > 0
      )
        return {
          ...state,
          [action.payload.id]: [
            ...state[action.payload.id],
            ...action.payload.data,
          ],
        };
      else return { ...state, [action.payload.id]: [action.payload.data] };

    case "MDSRECIVEMESSAGE":
      //Called when receive new message
      let receiveMessage = action.payload.message;
      let currentlyOnPage = action.payload.currentlyOnPage;
      if (
        receiveMessage &&
        receiveMessage[0].sender &&
        receiveMessage[0].sender.id &&
        receiveMessage[0].sender.id === localStorage.getItem("userid") &&
        state[receiveMessage[0].recipient.id] &&
        receiveMessage[0]._id
      ) {
        var arr = [];
        let recordExist = false;
        for (var i = 0; i < state[receiveMessage[0].recipient.id].length; i++) {
          if (
            receiveMessage[0].frontendUniqueId &&
            state[receiveMessage[0].recipient.id][i].frontendUniqueId &&
            state[receiveMessage[0].recipient.id][i].frontendUniqueId ===
            receiveMessage[0].frontendUniqueId
          ) {
            arr[i] = receiveMessage[0];
            recordExist = true;
          } else {
            arr[i] = state[receiveMessage[0].recipient.id][i];
          }
        }

        if (recordExist) {
          return { ...state, [receiveMessage[0].recipient.id]: arr };
        } else {
          return {
            ...state,
            [receiveMessage[0].recipient.id]: [receiveMessage[0], ...arr],
          };
        }
      } else {
        if (
          receiveMessage &&
          receiveMessage[0] &&
          receiveMessage[0].type !== "group" &&
          receiveMessage[0].type.toLowerCase() !== "userchatgroup" &&
          receiveMessage[0].type.toLowerCase() !== "chatchannel"
        ) {
          var result = { ...state };
          for (var l = 0; l < receiveMessage.length; l++) {
            if (
              receiveMessage[l].sender &&
              receiveMessage[l].sender.id &&
              receiveMessage[l].sender.id !== localStorage.getItem("userid")
            ) {
              if (result[receiveMessage[l].sender.id] !== undefined) {
                var sortMessage = [
                  receiveMessage[l],
                  ...result[receiveMessage[l].sender.id],
                ].sort((a, b) => {
                  if (b.userTimeStamp && a.userTimeStamp) {
                    return b.userTimeStamp.localeCompare(a.userTimeStamp);
                  } else return a;
                });
                result = {
                  ...result,
                  [receiveMessage[l].sender.id]: sortMessage,
                };
              } else {
                if (currentlyOnPage) {
                  result = {
                    ...result,
                    [receiveMessage[l].sender.id]: [receiveMessage[l]],
                  };
                } else {
                  result = result;
                }
              }
            } else if (
              receiveMessage[l].sender &&
              receiveMessage[l].sender.id &&
              receiveMessage[l].sender.id === localStorage.getItem("userid")
            ) {
              if (result[receiveMessage[l].recipient.id] !== undefined) {
                var sortMessage = [
                  receiveMessage[l],
                  ...result[receiveMessage[l].recipient.id],
                ].sort((a, b) => {
                  if (b.userTimeStamp && a.userTimeStamp) {
                    return b.userTimeStamp.localeCompare(a.userTimeStamp);
                  } else return a;
                });
                result = {
                  ...result,
                  [receiveMessage[l].recipient.id]: sortMessage,
                };
              } else {
                if (currentlyOnPage) {
                  result = {
                    ...result,
                    [receiveMessage[l].recipient.id]: [receiveMessage[l]],
                  };
                } else {
                  result = result;
                }
              }
            }
          }
          return result;
        } else {
          var result = { ...state };
          if (receiveMessage) {
            for (var l = 0; l < receiveMessage.length; l++) {
              if (result[receiveMessage[l].recipient.id] !== undefined) {
                var sortMessage = [
                  receiveMessage[l],
                  ...result[receiveMessage[l].recipient.id],
                ].sort((a, b) => {
                  if (b.userTimeStamp && a.userTimeStamp) {
                    return b.userTimeStamp.localeCompare(a.userTimeStamp);
                  } else return a;
                });
                result = {
                  ...result,
                  [receiveMessage[l].recipient.id]: sortMessage,
                };
              } else {
                if (currentlyOnPage) {
                  result = {
                    ...result,
                    [receiveMessage[l].recipient.id]: [receiveMessage[l]],
                  };
                } else {
                  result = result;
                }
              }
            }
          }
          return result;
        }
      }
      break;
    case "SETREADMESSAGE":
      //Setting read message when currently chatting with the person/group
      if (
        state[action.payload.recipient] !== undefined &&
        state[action.payload.recipient].length > 0
      ) {
        var arr = [];

        for (var i = 0; i < state[action.payload.recipient].length; i++) {
          arr[i] = {
            ...state[action.payload.recipient][i],
            group_member:
              action.payload.type === "user"
                ? state[action.payload.recipient][i].group_member.map((mem) => {
                  return { ...mem, readmsg: true };
                })
                : state[action.payload.recipient][i].group_member.map((mem) => {
                  if (mem.id === action.payload.sender) {
                    return { ...mem, readmsg: true };
                  } else {
                    return mem;
                  }
                }),
          };
        }
        return { ...state, [action.payload.recipient]: arr };
      } else {
        return state;
      }
      break;

    case "ADDMESSAGEREACTION":
      // Safely access nested properties using optional chaining
      let key =
        action?.payload?.message?.receiverId !== localStorage.getItem("userid")
          ? action.payload.message.receiverId
          : action.payload.message.senderId;

      let emogiID = action?.payload?.message?.emojiId;
      let senderID = action?.payload?.message?.senderId;
      let messageID = action?.payload?.message?.messageId;
      let storeData = [];

      if (state[key] && Array.isArray(state[key])) {
        for (let i = 0; i < state[key].length; i++) {
          if (state[key][i]?._id === messageID) {
            let message = state[key][i];
            if ("messageReactions" in message) {
              // 'messageReactions' exists in the object
              if (message?.messageReactions?.length === 0) {
                storeData?.push({
                  ...state[key][i],
                  messageReactions: [
                    {
                      emojiId: emogiID,
                      userIds: [senderID],
                    },
                  ],
                });
              } else {
                let emojiExists = message?.messageReactions?.filter(
                  (val) => val?.emojiId === emogiID
                );
                if (emojiExists?.length) {
                  let msgReactArr = [];
                  let reacted = false;
                  for (let j = 0; j < message?.messageReactions?.length; j++) {
                    if (message?.messageReactions[j]?.emojiId === emogiID) {
                      if (
                        !message?.messageReactions[j]?.userIds?.includes(
                          senderID
                        )
                      ) {
                        let updatedReaction = {
                          ...message?.messageReactions[j],
                          userIds: [
                            ...message?.messageReactions[j]?.userIds,
                            senderID,
                          ],
                        };
                        msgReactArr?.push(updatedReaction);
                      } else {
                        msgReactArr?.push(message?.messageReactions[j]);
                        reacted = true;
                      }
                    } else {
                      msgReactArr?.push(message?.messageReactions[j]);
                    }
                  }
                  // if (!reacted) {
                  //   msgReactArr?.push({
                  //     emojiId: emogiID,
                  //     userIds: [senderID],
                  //   });
                  // }
                  storeData.push({
                    ...state[key][i],
                    messageReactions: msgReactArr,
                  });
                } else {
                  storeData?.push({
                    ...state[key][i],
                    messageReactions: [
                      {
                        emojiId: emogiID,
                        userIds: [senderID],
                      },
                      ...message?.messageReactions,
                    ],
                  });
                }
              }
            } else {
              // 'messageReactions' does not exist in the object
              storeData?.push({
                ...state[key][i],
                messageReactions: [
                  {
                    emojiId: emogiID,
                    userIds: [senderID],
                  },
                ],
              });
            }

            // check if message of message.messageReactions is not exist then first create message array of object
          } else {
            storeData?.push(state[key][i]);
          }
        }
      }

      return { ...state, [key]: storeData };

    // Remove the emoji containing the username.

    case "REMOVEMESSAGEREACTION":
      const { payload } = action;
      const { message } = payload;

      const targetUserId =
        message.type !== "userChatGroup" && message.type !== "chatChannel"
          ? message.receiverId !== localStorage.getItem("userid")
            ? message.receiverId
            : message.senderId
          : message.receiverId;

      const messageId = message.messageId;
      const emojiIdToRemove = message.emojiId;
      const removeUserId = message.senderId;
      // Find the target message in the state
      const messages = state[targetUserId] || [];
      const updatedMessages = messages.map((msg) => {
        if (
          msg?._id === messageId &&
          msg?.messageReactions &&
          msg?.messageReactions?.length &&
          msg?.messageReactions?.filter(
            (msgReact) =>
              msgReact.emojiId === emojiIdToRemove &&
              msgReact?.userIds?.includes(removeUserId)
          ).length
        ) {
          // If the message ID matches, remove the emoji with emojiIdToRemove
          const updatedEmojis = msg?.messageReactions?.map((msgReact) => {
            if (
              msgReact?.emojiId === emojiIdToRemove &&
              msgReact?.userIds?.includes(removeUserId)
            ) {
              let usersList = msgReact?.userIds?.filter(
                (user) => user !== removeUserId
              );
              if (usersList.length) {
                return {
                  ...msgReact,
                  userIds: usersList,
                };
              }
            } else {
              return msgReact;
            }
          });
          return {
            ...msg,
            messageReactions: updatedEmojis.filter(
              (msg) => msg?.userIds?.length
            ),
          };
        } else {
          return msg;
        }
      });

      // Update the state with the modified messages
      return {
        ...state,
        [targetUserId]: updatedMessages,
      };

    case "EDITMESSAGE":
      //Called When edit event triggered
      if (
        state[action.payload.id] !== undefined &&
        state[action.payload.id].length > 0
      ) {
        var arr = [];
        for (var i = 0; i < state[action.payload.id].length; i++) {
          if (state[action.payload.id][i]._id === action.payload.data._id) {
            arr[i] = {
              ...state[action.payload.id][i],
              message: action.payload.data.message,
              taggedUserId: action.payload.data.taggedUserId,
              images: action.payload.data.images,
              videos: action.payload.data.videos,
              documents: action.payload.data.documents,
              edited: true,
            };
          } else if (
            state[action.payload.id][i].quote_message_id !== undefined &&
            state[action.payload.id][i].quote_message_id !== null &&
            state[action.payload.id][i].quote_message_id._id ===
            action.payload.data._id
          ) {
            arr[i] = {
              ...state[action.payload.id][i],
              quote_message_id: {
                ...state[action.payload.id][i].quote_message_id,
                message: action.payload.data.message,
                taggedUserId: action.payload.data.taggedUserId,
                images: action.payload.data.images,
                videos: action.payload.data.videos,
                documents: action.payload.data.documents,
                edited: true,
              },
            };
          } else {
            arr[i] = { ...state[action.payload.id][i] };
          }
        }
        return { ...state, [action.payload.id]: arr };
      } else {
        return state;
      }
    case "DELETEMESSAGE":
      //Called when delete any message
      var alldata = {};
      for (const key in state) {
        var arr = [];
        var n = 0;
        for (var i = 0; i < state[key].length; i++) {
          if (
            state[key][i].quote_message_id &&
            state[key][i].quote_message_id._id === action.payload
          ) {
            arr.push({ ...state[key][i], quote_message_id: null });
          } else if (state[key][i]._id !== action.payload) {
            arr.push(state[key][i]);
          }
        }
        alldata = { ...alldata, [key]: [...arr] };
      }
      return alldata;
    case "CLEARCHAT":
      //Called when clear chat event called
      var alldata = {};
      for (const key in state) {
        if (key === action.payload) {
          alldata = { ...alldata, [key]: [] };
        } else {
          alldata = { ...alldata, [key]: state[key] };
        }
      }
      return alldata;
    case "CHANGEPROGRESSSTATUS":
      //Called When Image Progress to compress event triggered
      if (
        state[action.payload.id] !== undefined &&
        state[action.payload.id].length > 0
      ) {
        var arr = [];
        for (var i = 0; i < state[action.payload.id].length; i++) {
          if (
            state[action.payload.id][i].frontendUniqueId ===
            action.payload.frontendUniqueId
          ) {
            arr[i] = {
              ...state[action.payload.id][i],
              progressStatus: action.payload.progressStatus,
            };
          } else {
            arr[i] = { ...state[action.payload.id][i] };
          }
        }
        return { ...state, [action.payload.id]: arr };
      } else {
        return state;
      }
    case 'LOGOUT':
      return allmessages;
    default:
      return state;
  }
};

export default ReduChat;
