const allUrls = [];

const AllUrlList = (state = allUrls, action) => {
  switch (action.type) {
    case "FETCHALLURLLIST":
      return action.payload;
    case "APPENDLINKS":
      return [...state, ...action.payload];
    case "ADDURLINLIST":
      let data = {
        _id: action.payload._id,
        message: action.payload.message,
        userTimeStamp: action.payload.userTimeStamp
      }
      return [data, ...state];
    case "DELETEURLRECORD":
      return state.filter((data) => data._id !== action.payload);
    case 'LOGOUT':
      return allUrls;
    default:
      return state;
  }
};

export default AllUrlList;
