import React, { useEffect, useRef, useState } from "react";
import Button from "../../Button/Button";
import Avtar from "../../Avtar/Avtar";
import authService from "../../../Services/auth.service";
import EmojiIcon from "../../../components/Icons/GeneralIcons/EmojiIcon";
import EmojiPicker from "emoji-picker-react";

const ReplyCommentBox = ({
  userImageSrc = localStorage.getItem("userprofile"),
  cancelClick = () => {},
  selectedVideoId,
  commentId,
  setAllComments,
  message = "",
  commentBox,
  isReply = false,
  mainCommentId,
  setRepliedComment,
  setTotalCommts,
  totalCommts
}) => {
  const inputRef = useRef(null);
  const emojiRef = useRef(null);
  const [commentField, setCommentField] = useState(message);
  const [isEmoji, setEmoji] = useState(false);
  const [isComment, setComment] = useState(false);
  const [isCommentLoading, setCommentLoading] = useState(false);

  useEffect(() => {
    if (message !== "") {
      if (inputRef.current) {
        inputRef.current.innerText = message;
      }
    }
    const el = inputRef.current;
    if (el) {
      // Set focus to the element
      el.focus();
      el.scrollTop = el.scrollHeight;
      // Move the caret to the end of the content
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(el);
      range.collapse(false);

      selection.removeAllRanges();
      selection.addRange(range);
    }
  }, [message, inputRef]);

  const replyAddComment = async () => {
    if (commentField.trim().length > 0) {
      setCommentLoading(true);
      if (message !== "") {
        // for edit comment
        const body = {
          comment_id: commentId,
          content: commentField,
        };
        const editRes = await authService.editComment(selectedVideoId, body);
        if (editRes.data.status) {
          setCommentLoading(false);
          if (isReply) {
            setAllComments((prev) => {
              return prev.map((comment) => {
                if (comment._id === mainCommentId) {
                  return {
                    ...comment,
                    comments: comment.comments.map((repCom) => {
                      if (repCom._id === commentId) {
                        return {
                          ...repCom,
                          content: editRes.data.data.content,
                        };
                      } else {
                        return repCom;
                      }
                    }),
                  };
                } else {
                  return comment;
                }
              });
            });
            setTotalCommts(totalCommts + 1)
          } else {
            setAllComments((prev) => {
              return prev.map((comment) => {
                if (comment._id === commentId) {
                  return {
                    ...comment,
                    content: editRes.data.data.content,
                  };
                } else {
                  return comment;
                }
              });
            });
          }
          setCommentField("");
          cancelClick();
        } else {
          setCommentLoading(false);
        }
      } else {
        // for reply comment
        var data = {
          videoId: selectedVideoId,
          commId: commentId,
          user_type: "airtable-syncs",
          content: commentField,
          createdAt: new Date(),
        };
        const addRes = await authService.createreply_video(data);
        if (addRes.data.status) {
          setCommentLoading(false);
          cancelClick();
          setCommentField("");
          setRepliedComment({ isReply: true, comment: addRes.data.data });
          setAllComments((prev) => {
            return prev.map((comment) => {
              if (comment._id === commentId) {
                return {
                  ...comment,
                  comments: [...comment.comments, addRes.data.data],
                };
              } else {
                return comment;
              }
            });
          });
          setTotalCommts(totalCommts + 1)
        } else {
          setCommentLoading(false);
        }
      }
    }
  };

  function onChange(event) {
    setCommentField(event.target.innerText);
  }

  const handlePaste = (event) => {
    event.preventDefault();
    setCommentField(event.target.innerText);
    const text = (event.clipboardData || window.clipboardData).getData("text");
    document.execCommand("insertText", false, text);
    const el = inputRef.current;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  };

  function focusInput() {
    const el = inputRef.current;
    if (el) {
      // Set focus to the element
      el.focus();
      el.scrollTop = el.scrollHeight;
      // Move the caret to the end of the content
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(el);
      range.collapse(false);

      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  document.addEventListener("mousedown", (e) => {
    if (isEmoji && emojiRef.current && !emojiRef.current.contains(e.target)) {
      setEmoji(false);
    }
    // if (
    //   commentBox &&
    //   inputRef.current &&
    //   !inputRef.current.contains(e.target)
    // ) {
    //   cancelClick();
    // }
  });

  document.addEventListener("keydown", (e) => {
    if (e.code === "Escape" && inputRef.current && isComment) {
      setComment(false);
      cancelClick();
      e.target.innerText = "";
    }
  });
  return (
    <>
      <div
        className="reply-comment-box d-flex flex-column"
        // onBlur={cancelClick}
      >
        <div className="comment-field d-flex align-items-start">
          {userImageSrc ? (
            <Avtar size="40" src={userImageSrc} />
          ) : (
            <Avtar
              type="letter"
              size="40"
              letter={localStorage.getItem("username")}
            />
          )}
          <p
            ref={inputRef}
            className="comment-input align-self-center"
            onKeyUp={(event) => onChange(event)}
            onPaste={handlePaste}
            contentEditable={true}
            aria-label="Add a comment"
            onFocus={() => setComment(true)}
            autoFocus
          />
        </div>

        {isComment && (
          <div className="comment-btn d-flex align-items-center justify-content-end">
            <Button
              extraClass="w-fit comment-emoji-btn"
              size="small"
              elementIcon={<EmojiIcon width={24} height={24} />}
              hasText={false}
              variant="ghost"
              handleClick={() => setEmoji(!isEmoji)}
            />
            {isEmoji && (
              <div ref={emojiRef}>
                <EmojiPicker
                  className="emoji-modal"
                  onEmojiClick={(e) => {
                    if (inputRef.current) {
                      inputRef.current.innerText += e.emoji;
                    }
                    setCommentField((prev) => prev + e.emoji);
                    focusInput();
                  }}
                />
              </div>
            )}
            <Button
              variant="ghost"
              hasText={true}
              label="Cancel"
              handleClick={() => {
                cancelClick();
                if (inputRef.current) {
                  inputRef.current.innerText = "";
                }
              }}
            />
            <Button
              hasLoading={isCommentLoading}
              variant="primary"
              hasText={true}
              disabled={commentField.length === 0 || isCommentLoading}
              label="Comment"
              handleClick={replyAddComment}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ReplyCommentBox;
