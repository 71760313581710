import { useEffect, useState } from "react";
import { Link,Navigate, useNavigate , useSearchParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import AlertComponent from "../../Hooks/AlertComponent";
// Import component
import EditSelectedField from "./EditSelectedField.js";
import editfill from "../../Images/BackendImages/edit-fill.svg";


const AddField = ({
  setFields,
  fields,
  grid,
  showaddfield,
  setShowaddfield,
  fieldarr,
  setGrid,
  setfieldarr,
}) => {
  const [newField, setNewField] = useState({ grid: grid, fields: fieldarr });
  const [styleDiv, setstyleDiv] = useState({ display: "none" });
  const [selectedGrid, setSelectedGrid] = useState(1);
  const [err, setErr] = useState("");
  const [showmodal, setshowmodal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  

  // useEffect called on fieldarr change
  useEffect(()=>{
    dispatch({type:"REMOVEMODALINPROCESS"})
  },[])
  useEffect(() => {
    setNewField({ grid: grid, fields: fieldarr });
    setshowmodal(true);
  }, [fieldarr]);
  // useEffect called on showaddfield , fieldarr change
  useEffect(() => {}, [showaddfield]);
  // Called on edit field
  const handleEdit = (g) => {
    // dispatch({ type: "SETMODALINPROCESS" })
    // dispatch({ type: "SETSTAYONPAGE", payload: null })
    setSelectedGrid(g);
    setstyleDiv({ display: "inline", float: "right", padding: "0px 20px" });
  };
  // Called on update field
  const updateField = (e) => {
    var fieldempty = false;
    switch (grid) {
      case 1:
        fieldempty =
          newField.fields[0]["label"].length < 1 ||
          newField.fields[0]["type"].length < 1
            ? true
            : false;
        break;
      case 2:
        for (var i = 0; i < 2; i++) {
          fieldempty =
            newField.fields[i].label.length < 1 ||
            newField.fields[i].type.length < 1
              ? true
              : false;
          if (fieldempty) break;
        }
        break;
      case 3:
        for (var i = 0; i < 3; i++) {
          fieldempty =
            newField.fields[i].label.length < 1 ||
            newField.fields[i].type.length < 1
              ? true
              : false;
          if (fieldempty) break;
        }
        break;
      default:
        break;
    }
    if (fieldempty) {
      setErr("Enter all details!");
    } else {
      var newarr = fields;
      newarr.length > 0
        ? (newarr[newarr.length] = newField)
        : (newarr[0] = newField);
      setFields(newarr);
      // setGrid(1);
      setErr("");
      setfieldarr([]);
    }
  };
  // Called on cancel button click event
  const handleCancel = (e) => {
    dispatch({type: "REMOVECUSTOMMODALPOPUPCLOSE"})
    dispatch({type:"REMOVEMODALINPROCESS"})
    setShowaddfield(false);
    setfieldarr([]);
    setGrid(1);
  };

      //stayonpage  popup for do you want to leave page
      useEffect(()=>{
        if (stayOnPage !== null && stayOnPage)
        { 
          if ($('#Addfield').hasClass('show'))
            {
              $("#Addfield").modal('show')
            }
             
          
            
        }
        else if (stayOnPage !== null && !stayOnPage){
          if ($('#Addfield').hasClass('show'))
          { $("#Addfield").modal('hide')}
           
          
        }
          
      },[stayOnPage])

console.log(grid,"grid");
  // Add field form
  switch (grid) {
    case 1:
      return (
        <>
        {console.log("wrirrrrrrrrer",newField.fields)}
          {newField.fields.length > 0 ? (
            <>
              <div
                className="modal fade edit-user-details-popup add-cstm-field-main-sec"
                id="Addfield"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                data-backdrop="static" 
                aria-hidden="true"
              >
                <div
                  className="modal-dialog cstm-modal-dialog "
                  role="document"
                >
                  <div className="modal-content  cstm-model-content">
                    <div className="edit-user-details-header">
                      <h2>Edit Layout</h2>
                    </div>
                    <div className="cstm-add-field">
                      <div className="admin-error-msg">
                        <p className="error-msg">{err}</p>
                      </div>
                      <div className="cstm-edit-delete-field">
                        <div className="">
                          <div className="col-sm-12 form-group form-group-rel">
                            <div className="form-group-ct cstm-edit-delete">
                              <div className="form-group-label cstm-label-here">
                                {/* <input
                                  type="text"
                                  value={
                                    newField.fields[0].label.length > 0
                                      ? newField.fields[0].label
                                      : "Label here"
                                  }
                                  disabled
                                /> */}
                                {newField.fields[0].label.length > 0
                                  ? newField.fields[0].label
                                  : "Label here"}
                              </div>

                              <div className="form-group-ct cstm-add-bdr-box">
                                <div className="cstm-add-bdr"></div>
                                <div className="cstm-edit-delete-main cstm-edit-delete-label">
                                  <a
                                    href="#"
                                    data-target="#editselectfield"
                                    data-toggle="modal"
                                    onClick={(e) => {
                                      handleEdit(1);
                                    }}
                                  >
                                    <img src={editfill} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="form-group-ct cstm-edit-delete-added-info">
                              {newField.fields[0]["options"].length > 0 ? (
                                <div>
                                  <input
                                    type="text"
                                    value={newField.fields[0]["options"][0]}
                                    disabled
                                  />

                                  {/* {newField.fields[0]["options"].map((opt) => {
                                    return (
                                      <input type="text" value={opt} disabled />
                                    );
                                  })} */}
                                </div>
                              ) : newField.fields[0].placeholder.length > 0 ? (
                                <input
                                  type="text"
                                  value={newField.fields[0].placeholder}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <EditSelectedField
                          styleDiv={styleDiv}
                          newField={newField}
                          setNewField={setNewField}
                          selectedGrid={selectedGrid}
                          setstyleDiv={setstyleDiv}
                          grid={grid}
                        />
                      </div>
                      <div className="cstm-update-cancle-btn">
                        <button
                          type="button"
                          className="btn"
                          onClick={(e) => {
                            updateField();
                          }}
                          data-dismiss="modal"
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          className="btn cncle-btn"
                          //data-dismiss="modal"
                          onClick={(e)=>{
                            if (modalInProcess)
                            { dispatch({type:"SETCUSTOMMODALPOPUPCLOSE"})
                             }else
                            {
                              $("#Addfield").modal('hide')
                              handleCancel(e)
                            }
                            
                        
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      );
    case 2:
      return (
        <>
          <div
            className="modal fade edit-user-details-popup add-cstm-field-main-sec"
            id="Addfield"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog cstm-modal-dialog" role="document">
              <div className="modal-content cstm-model-content">
                <div className="edit-user-details-header">
                  <h2>Edit Layout</h2>
                </div>
                <div className="cstm-add-field">
                  {newField.fields.length > 0 ? (
                    <>
                      <div className="cstm-edit-delete-field">
                        <div className="row cstm-row-pdg">
                          <div className="col-sm-6 form-group form-group-rel">
                            <div className="form-group-ct cstm-edit-delete">
                              <div className="form-group-label cstm-label-here">
                                {newField.fields[0].label.length > 0
                                  ? newField.fields[0].label
                                  : "Label here"}
                              </div>

                              <div className="form-group-ct cstm-add-bdr-box">
                                <div className="cstm-add-bdr"></div>
                                <div className="cstm-edit-delete-main cstm-edit-delete-label">
                                  <a
                                    href="#"
                                    data-target="#editselectfield"
                                    data-toggle="modal"
                                    onClick={(e) => {
                                      handleEdit(1);
                                    }}
                                  >
                                    <img src={editfill} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="form-group-ct cstm-edit-delete-added-info">
                              {newField.fields[0]["options"].length > 0 ? (
                                <div>
                                  <input
                                    type="text"
                                    value={newField.fields[0]["options"][0]}
                                    disabled
                                  />

                                  {/* {newField.fields[0]["options"].map((opt) => {
                                    return (
                                      <input type="text" value={opt} disabled />
                                    );
                                  })} */}
                                </div>
                              ) : newField.fields[0].placeholder.length > 0 ? (
                                <input
                                  type="text"
                                  value={newField.fields[0].placeholder}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-6 form-group form-group-rel">
                            <div className="form-group-ct cstm-edit-delete">
                              <div className="form-group-label cstm-label-here">
                                {newField.fields[1].label.length > 0
                                  ? newField.fields[1].label
                                  : "Label here"}
                              </div>
                              <div className="form-group-ct cstm-add-bdr-box">
                                <div className="cstm-add-bdr"></div>
                                <div className="cstm-edit-delete-main cstm-edit-delete-label">
                                  <a
                                    href="#"
                                    data-target="#editselectfield"
                                    data-toggle="modal"
                                    onClick={(e) => {
                                      handleEdit(2);
                                    }}
                                  >
                                    <img src={editfill} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="form-group-ct cstm-edit-delete-added-info">
                              {newField.fields[1]["options"].length > 0 ? (
                                <div>
                                  <input
                                    type="text"
                                    value={newField.fields[1]["options"][0]}
                                    disabled
                                  />
                                  {/* {newField.fields[1]["options"].map((opt) => {
                                    return (
                                      <input type="text" value={opt} disabled />
                                    );
                                  })} */}
                                </div>
                              ) : newField.fields[1].placeholder.length > 0 ? (
                                <input
                                  type="text"
                                  value={newField.fields[1].placeholder}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <EditSelectedField
                          styleDiv={styleDiv}
                          newField={newField}
                          setNewField={setNewField}
                          selectedGrid={selectedGrid}
                          setstyleDiv={setstyleDiv}
                          grid={grid}
                        />
                      </div>
                      <div className="cstm-update-cancle-btn">
                        <button
                          className="btn"
                          type="button"
                          onClick={(e) => {
                            updateField();
                          }}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          data-dismiss="modal"
                          className="btn cncle-btn"
                          onClick={(e) => {
                            handleCancel();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    case 3:
      return (
        <>
          <div
            className="modal fade edit-user-details-popup add-cstm-field-main-sec"
            id="Addfield"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog cstm-modal-dialog" role="document">
              <div className="modal-content  cstm-model-content">
                <div className="edit-user-details-header">
                  <h2>Edit Layout</h2>
                </div>
                <div className="cstm-add-field">
                  {newField.fields.length > 0 ? (
                    <>
                      <div className="cstm-edit-delete-field">
                        <div className="row cstm-row-pdg">
                          <div className="col-sm-4 form-group form-group-rel">
                            <div className="form-group-ct cstm-edit-delete">
                              <div className="form-group-label cstm-label-here">
                                {newField.fields[0].label.length > 0
                                  ? newField.fields[0].label
                                  : "Label here"}
                              </div>

                              <div className="form-group-ct cstm-add-bdr-box">
                                <div className="cstm-add-bdr"></div>
                                <div className="cstm-edit-delete-main cstm-edit-delete-label">
                                  <a
                                    href="#"
                                    data-target="#editselectfield"
                                    data-toggle="modal"
                                    onClick={(e) => {
                                      handleEdit(1);
                                    }}
                                  >
                                    <img src={editfill} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="form-group-ct cstm-edit-delete-added-info">
                              {newField.fields[0]["options"].length > 0 ? (
                                <div>
                                  <input
                                    type="text"
                                    value={newField.fields[0]["options"][0]}
                                    disabled
                                  />

                                  {/* {newField.fields[0]["options"].map((opt) => {
                                    return (
                                      <input type="text" value={opt} disabled />
                                    );
                                  })} */}
                                </div>
                              ) : newField.fields[0].placeholder.length > 0 ? (
                                <input
                                  type="text"
                                  value={newField.fields[0].placeholder}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-4 form-group">
                            <div className="form-group-ct cstm-edit-delete">
                              <div className="form-group-label cstm-label-here">
                                {newField.fields[1].label.length > 0
                                  ? newField.fields[1].label
                                  : "Label here"}
                              </div>
                              <div className="form-group-ct cstm-add-bdr-box">
                                <div className="cstm-add-bdr"></div>
                                <div className="cstm-edit-delete-main cstm-edit-delete-label">
                                  <a
                                    href="#"
                                    data-target="#editselectfield"
                                    data-toggle="modal"
                                    onClick={(e) => {
                                      handleEdit(2);
                                    }}
                                  >
                                    <img src={editfill} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="form-group-ct">
                              {newField.fields[1]["options"].length > 0 ? (
                                <div>
                                  <input
                                    type="text"
                                    value={newField.fields[1]["options"][0]}
                                    disabled
                                  />
                                  {/* {newField.fields[1]["options"].map((opt) => {
                                    return (
                                      <input type="text" value={opt} disabled />
                                    );
                                  })} */}
                                </div>
                              ) : newField.fields[1].placeholder.length > 0 ? (
                                <input
                                  type="text"
                                  value={newField.fields[1].placeholder}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-sm-4 form-group">
                            <div className="form-group-ct cstm-edit-delete">
                              <div className="form-group-label cstm-label-here">
                                {newField.fields[2].label.length > 0
                                  ? newField.fields[2].label
                                  : "Label here"}
                              </div>
                              <div className="form-group-ct cstm-add-bdr-box">
                                <div className="cstm-add-bdr"></div>
                                <div className="cstm-edit-delete-main cstm-edit-delete-label">
                                  <a
                                    href="#"
                                    data-target="#editselectfield"
                                    data-toggle="modal"
                                    onClick={(e) => {
                                      handleEdit(3);
                                    }}
                                  >
                                    <img src={editfill} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="form-group-ct">
                              {newField.fields[2]["options"].length > 0 ? (
                                <div>
                                  <input
                                    type="text"
                                    value={newField.fields[2]["options"][0]}
                                    disabled
                                  />

                                  {/* {newField.fields[2]["options"].map((opt) => {
                                    return (
                                      <input type="text" value={opt} disabled />
                                    );
                                  })} */}
                                </div>
                              ) : newField.fields[2].placeholder.length > 0 ? (
                                <input
                                  type="text"
                                  value={newField.fields[2].placeholder}
                                  disabled
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <EditSelectedField
                          styleDiv={styleDiv}
                          newField={newField}
                          setNewField={setNewField}
                          selectedGrid={selectedGrid}
                          setstyleDiv={setstyleDiv}
                          grid={grid}
                        />
                      </div>
                      <div className="cstm-update-cancle-btn">
                        <button
                          type="button"
                          className="btn"
                          onClick={(e) => {
                            updateField();
                          }}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          className="btn cncle-btn"
                          data-dismiss="modal"
                          onClick={(e) => {
                            handleCancel();
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <AlertComponent setcurrentDiv={""} /> */}
        </>
      );
    default:
      return <></>;
  }
};

export default AddField;


