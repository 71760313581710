import React, { useEffect, useState } from 'react'
import emailOtp from "../../../Images/email_otp.svg";
import loadingResend from "../../../Images/loader.gif";
import { useLocation, useNavigate } from 'react-router-dom';
import authServiceCollaborator from '../../../Services/auth.service.collaborator';
import Logo from "../../../Images/logo_mds_small.svg";
import EmailIcon from "../../../Images/EmailIcon.svg";
import IcnMEmail from "../../../Images/icn_m_email.svg";
import loading from "../../../Images/loader.gif";
import noAccess from '../../../Images/nonaccessuser.svg'
const CollaboratorMigration = () => {

    const navigate = useNavigate()
    const [otpErr, setOptErr] = useState("");
    const [msg, setMsg] = useState("");
    const [collaboratorEmail, setCollaboratorEmail] = useState('')
    const [resendProcess, setResendProcess] = useState(false);
    const [otpField, setOtpField] = useState({
        otp1: "",
        otp2: "",
        otp3: "",
        otp4: "",
        otp5: "",
        otp6: "",
    });
    const [loader, setLoader] = useState(true)
    const location = useLocation().search
    const id = new URLSearchParams(location).get('id')
    const [timeRemaining, setTimeRemaining] = useState(30);
    const [isTimerActive, setIsTimerActive] = useState(true);
    const [isExist, setIsExist] = useState(false)

    useEffect(() => {
        let timerId;

        if (isTimerActive && timeRemaining > 0) {
            timerId = setInterval(() => {
                setTimeRemaining(timeRemaining - 1);
            }, 1000);
        } else if (timeRemaining === 0) {
            setIsTimerActive(false);
        }

        return () => {
            clearInterval(timerId);
        };
    }, [isTimerActive, timeRemaining]);

    useEffect(() => {
        authServiceCollaborator.sendOtpToCollaborator(id)
            .then((res) => {
                if (res.data.status) {
                    localStorage.setItem("collaboratorId", id)
                    setCollaboratorEmail(res.data.data.email)
                    setLoader(false)
                } else {
                    setIsExist(true)
                    setLoader(false)
                }
            })
            .catch((err) => {
                setLoader(false)
            })
    }, [])

    // verify otp api function
    async function verifyOTP(otp) {
        console.log(otp, "otp");
        const bodyData = {
            collaboratorId: id,
            otp: otp
        }
        try {
            const verifyRes = await authServiceCollaborator.verifyOtp(bodyData)
            console.log(verifyRes, "verifyRes");
            if (verifyRes.data.status) {
                setOtpField({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" });
                navigate('/collaboratorregistration')
            } else {
                setOptErr(verifyRes.data.message)
            }
        } catch (error) {

        }
    }
    
    // handle change for otp input fields
    const handleChange = (value1, event) => {
        const re = /^[0-9\b]+$/;

        if (event.target.value === "" || re.test(event.target.value)) {
            setOtpField({ ...otpField, [value1]: event.target.value });
        }
        if (
            value1 === "otp6" &&
            event.target.value.length > 0 &&
            otpField.otp1.length > 0 &&
            otpField.otp2.length > 0 &&
            otpField.otp3.length > 0 &&
            otpField.otp4.length > 0 &&
            otpField.otp5.length > 0
        ) {
            var otp =
                otpField.otp1 +
                otpField.otp2 +
                otpField.otp3 +
                otpField.otp4 +
                otpField.otp5 +
                event.target.value
            verifyOTP(otp)
        }

    };

    // handle focus for input on change focus to next box
    const inputfocus = (elmnt, value1) => {
        const re = /^[0-9\b]+$/;
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus();
            }
        } else if (re.test(elmnt.target.value) && elmnt.target.tabIndex !== 6) {
            const next = elmnt.target.tabIndex;
            if (next < 7 && otpField[value1].length > 0) {
                elmnt.target.form.elements[next].focus();
            } else {
                elmnt.target.form.elements[next - 2].focus();
            }
        }
    };

    // handlePaste for otp field
    const handlePaste = (e) => {
        const re = /^[0-9]+$/;
        if (
            re.test(e.clipboardData.getData("text")) &&
            e.clipboardData.getData("text").length === 6
        ) {
            setOtpField({
                otp1: e.clipboardData.getData("text").charAt(0),
                otp2: e.clipboardData.getData("text").charAt(1),
                otp3: e.clipboardData.getData("text").charAt(2),
                otp4: e.clipboardData.getData("text").charAt(3),
                otp5: e.clipboardData.getData("text").charAt(4),
                otp6: e.clipboardData.getData("text").charAt(5),
            });
            verifyOTP(e.clipboardData.getData("text"))
        } else {
            setOtpField({
                otp1: "",
                otp2: "",
                otp3: "",
                otp4: "",
                otp5: "",
                otp6: "",
            });
        }
    };

    // handle resend otp
    const resendCode = () => {
        setResendProcess(true);
        setOtpField({ otp1: "", otp2: "", otp3: "", otp4: "", otp5: "", otp6: "" });
        authServiceCollaborator
            .resendOtp(id)
            .then((res) => {
                console.log(res, "res");
                if (res.data.status) {
                    setOptErr("");
                    setMsg(res.data.message);
                    setTimeout(() => {
                        setMsg('')
                    }, 3000);
                } else {
                    setMsg("");
                    setOptErr(res.data.message);
                }
                setResendProcess(false);
            })
            .catch((e) => {
                setResendProcess(false);
                console.log(e);
            });
    };
    return (
        <>
            {loader ?
                <div className="loadind-post-colla">
                    <img src={loading} />
                </div>
                :
                <>
                    <div className="header-collaborator"><a href='#'><img src={Logo} /></a></div>


                    {isExist ?
                        <div className="collaborator-main">
                            <div className="collaborator-emailicon"><img src={noAccess} /></div>

                            <div className="collaborator-succesfully">
                                <div className="collaborator-info">
                                    <h2 className='collaborator-h2'>Oops. Link expired!</h2>
                                    <h6 className='collaborator-h6'>It appears you clicked on an expired join link or the URL is incorrect.</h6>
                                </div>
                                <div className='btn-log-in-to-mds'><a className='btn-log' href="/">Request a new link</a></div>
                            </div>
                        </div>
                        :
                        <div className="collaborator-main">
                            <div className="collaborator-emailicon"><img src={EmailIcon} /></div>

                            <div className="collaborator-info">
                                <h2 className='collaborator-h2'>Check your email for a code</h2>
                                <h6 className='collaborator-h6'>We've sent a 6-character code to your email address. The code expires shortly, so please enter it soon.</h6>
                                <div className="collaborator-email">
                                    <img src={IcnMEmail} />{collaboratorEmail}
                                </div>
                            </div>


                            <form>
                                <div className="collaborator-cont">
                                    <div className="collaborator-field">
                                        <input
                                            name="otp1"
                                            type="text"
                                            autoComplete="off"
                                            placeholder="-"
                                            className={`${otpErr.length > 0
                                                ? "collaborator-error-field"
                                                : otpField.otp1.length === 1
                                                    ? "collaborator-fill-field"
                                                    : ""
                                                } "otpInput"`}
                                            value={otpField.otp1}
                                            onChange={(e) => {
                                                handleChange("otp1", e);
                                            }}
                                            onPaste={handlePaste}
                                            tabIndex="1"
                                            maxLength="1"
                                            onKeyUp={(e) => {
                                                inputfocus(e, "otp1");
                                            }}
                                        />
                                        <input
                                            name="otp2"
                                            type="text"
                                            placeholder="-"
                                            autoComplete="off"
                                            className={`${otpErr.length > 0
                                                ? "collaborator-error-field"
                                                : otpField.otp2.length === 1
                                                    ? "collaborator-fill-field"
                                                    : ""
                                                } "otpInput"`}
                                            value={otpField.otp2}
                                            onPaste={handlePaste}
                                            onChange={(e) => {
                                                handleChange("otp2", e);
                                            }}
                                            tabIndex="2"
                                            maxLength="1"
                                            onKeyUp={(e) => {
                                                inputfocus(e, "otp1");
                                            }}
                                        />
                                        <input
                                            name="otp3"
                                            type="text"
                                            placeholder="-"
                                            autoComplete="off"
                                            className={`${otpErr.length > 0
                                                ? "collaborator-error-field"
                                                : otpField.otp3.length === 1
                                                    ? "collaborator-fill-field"
                                                    : ""
                                                } "otpInput"`}
                                            value={otpField.otp3}
                                            onPaste={handlePaste}
                                            onChange={(e) => {
                                                handleChange("otp3", e);
                                            }}
                                            tabIndex="3"
                                            maxLength="1"
                                            onKeyUp={(e) => {
                                                inputfocus(e, "otp2");
                                            }}
                                        />
                                        <input
                                            name="otp4"
                                            type="text"
                                            autoComplete="off"
                                            placeholder="-"
                                            className={`${otpErr.length > 0
                                                ? "collaborator-error-field"
                                                : otpField.otp4.length === 1
                                                    ? "collaborator-fill-field"
                                                    : ""
                                                } "otpInput"`}
                                            value={otpField.otp4}
                                            onPaste={handlePaste}
                                            onChange={(e) => {
                                                handleChange("otp4", e);
                                            }}
                                            tabIndex="4"
                                            maxLength="1"
                                            onKeyUp={(e) => {
                                                inputfocus(e, "otp3");
                                            }}
                                        />
                                        <input
                                            name="otp5"
                                            type="text"
                                            placeholder="-"
                                            autoComplete="off"
                                            className={`${otpErr.length > 0
                                                ? "collaborator-error-field"
                                                : otpField.otp5.length === 1
                                                    ? "collaborator-fill-field"
                                                    : ""
                                                } "otpInput"`}
                                            value={otpField.otp5}
                                            onPaste={handlePaste}
                                            onChange={(e) => {
                                                handleChange("otp5", e);
                                            }}
                                            tabIndex="5"
                                            maxLength="1"
                                            onKeyUp={(e) => {
                                                inputfocus(e, "otp4");
                                            }}
                                        />
                                        <input
                                            name="otp6"
                                            type="text"
                                            placeholder="-"
                                            autoComplete="off"
                                            className={`${otpErr.length > 0
                                                ? "collaborator-error-field"
                                                : otpField.otp6.length === 1
                                                    ? "collaborator-fill-field"
                                                    : ""
                                                } "otpInput"`}
                                            value={otpField.otp6}
                                            onPaste={handlePaste}
                                            onChange={(e) => {
                                                handleChange("otp6", e);
                                            }}
                                            tabIndex="6"
                                            maxLength="1"
                                            onKeyUp={(e) => {
                                                inputfocus(e, "otp5");
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={otpErr.length > 0 ? 'collaborator-field-error' : msg.length > 0 ? "collaborator-field-sucessfully" : "collaborator-field-msg"}>
                                        {" "}
                                        {otpErr.length > 0
                                            ? otpErr
                                            : msg
                                                ? msg
                                                : ""}
                                    </div>
                                    <div className="collaborator-send">
                                        {`Didn't see code? ${isTimerActive ? `send a new code in 00:${timeRemaining}` : ""}`}
                                        {timeRemaining === 0 && <span className="collaborator-send-in"> {resendProcess ? <span className="collaborator-send-loader-img"><img src={loadingResend} /></span> : <></>}
                                            <span className='collaborator-link'
                                                onClick={() => {
                                                    resendCode();
                                                }}
                                            >
                                                Resend code
                                            </span>
                                        </span>}

                                    </div>
                                </div>
                            </form>
                        </div>}
                </>
            }
        </>
    )
}

export default CollaboratorMigration