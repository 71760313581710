import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import moment from "moment";
import millify from "millify";
import { checktoken_admin } from "../checktoken";
import authService from "../../Services/auth.service";
import authService1 from "../../Services/auth.service1";
import CommnetImage from "../../Images/message.svg";
import VideoViewsImage from "../../Images/view-2.png";
import VideoViewDotted from "../../Images/dotted.svg";
import VideoEditImage from "../../Images/edit.svg";
import VideoDeleteImage from "../../Images/delete-img1.png";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import videodummy from "../../Images/video-detail.png";
import videoPlayericons from "../../Images/video-player-icons.svg";
import LoaderComponent from "../UserComponents/LoaderComponent";

const VideoListing = () => {
  const ref = useRef();
  const search = useLocation().search;
  const listingtype = new URLSearchParams(search).get("category");
  const tag = new URLSearchParams(search).get("tag");
  const history = useNavigate();
  const [allvideo, setallvideos] = useState([]);
  const [allcategories, setallcategories] = useState([]);
  const [displayvideos, setdisplayvideos] = useState([]);
  const [selectedTag, setSelectedTag] = useState('')
  const [tagList, setTagList] = useState([])
  const [loader, setloader] = useState(false);
  const [deleteid, setdeleteid] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [page, setpage] = useState(1);
  const [catpage, setcatpage] = useState(1);
  const [totalvideos, settotalvideos] = useState(0);
  const [pageloader, setpageloader] = useState(false);
  const [startloader, setstartloader] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("")

  useEffect(() => {
    tag !== null && setSelectedTag(tag)
    setstartloader(true);
    setSearchText('')
    getallusersSuggestionListApi()
    if (listingtype !== undefined && listingtype !== null && listingtype.length > 0) {
      setallvideos([])
      getVideoListing(selectedCategory, selectedTag, searchText).then((res) => {
        checktoken_admin(res)
        setstartloader(false);
        if (res.data.status) {
          setallvideos(res.data.data[0].videos);
          setdisplayvideos(res.data.data[0].videos);
          settotalvideos(res.data.data[0].totalVideos);
        }
      }).catch((e) => {
        setstartloader(false);
      })
    } else {
      authService
        .getallcontentvideos(page, tag !== null ? tag : "")
        .then((res) => {
          checktoken_admin(res)
          setstartloader(false);
          if (res.data.status) {
            setallvideos(res.data.data[0].videos);
            setdisplayvideos(res.data.data[0].videos);
            settotalvideos(res.data.data[0].totalVideos);
          }
        })
        .catch((e) => {
          setstartloader(false);
          console.log(e);
        });
      setstartloader(false);
    }
    setpage(1)
    setcatpage(1)

    // get all categories
    authService
      .getallcontentcategory_byadmin("")
      .then((res) => {
        checktoken_admin(res)
        if (res.data.status) {
          setallcategories(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    window.addEventListener("scroll", () => {
      var max = Math.ceil(document.documentElement.offsetHeight + 10)
      var min = Math.floor(document.documentElement.offsetHeight - 10)
      if (
        window.innerHeight + document.documentElement.scrollTop > min && window.innerHeight + document.documentElement.scrollTop < max
      ) {
        if (!pageloader) {
          setpageloader(true)
        } else {
          setpageloader(false)
        }
      }
    });
    const content = document.getElementById("content-list-admin");
    if (content !== null) {
      content.scrollTop = content.scrollHeight;
    }
    getTagList()
  }, []);

  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getContentVideoSuggestionList()
      let data = getSuggestionRes.data.data
      var arr = []
      if (data && data.length !== undefined && data.length !== null && data.length > 0) {
        data.map((item) => arr.push({ name: item.title }))
      }
      setSuggestionList(arr)
    } catch (err) {

    }
  }

  const getVideoListing = (selectedCategory, selectedTag, searchText) => {
    let query = "";
    query += `?limit=${20}&page=${page}`;
    query +=
      selectedCategory !== undefined && selectedCategory !== null && selectedCategory !== ""
        ? `&category=${selectedCategory}`
        : "";
    query +=
      selectedTag !== undefined && selectedTag !== null && selectedTag !== "" ? `&tag=${selectedTag}` : "";
    query +=
      searchText !== undefined && searchText !== null && searchText !== ""
        ? `&search=${searchText}`
        : "";
    const data = authService.getVideoListingByAdmin(query).then((res) => {
      return res
    })
      .catch((e) => {
        setstartloader(false);
      });
    return data
  }

  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex]
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText
        setSearchText(text);
      }
    }
  }, [listIndex]);

  // tag list api function
  async function getTagList() {
    try {
      const tagListRes = await authService.getalltags_byadmin("")
      if (tagListRes.data.status) {
        setTagList(tagListRes.data.data)
      }
    } catch (error) {
      return false
    }
  }
  useEffect(() => {
    if (allvideo.length < totalvideos) {
      if (
        listingtype !== null &&
        listingtype !== undefined &&
        listingtype.length > 0
      ) {
        setcatpage(catpage + 1);
      } else {
        setpage(page + 1);
      }
    } else {
      setpageloader(false)
    }
  }, [pageloader])
  useEffect(() => {
    setSearchText("")
    setpage(1)
    setcatpage(1)

    if (listingtype !== undefined && listingtype !== null && listingtype.length > 0 && allvideo.length < totalvideos) {
      setstartloader(true);
      setallvideos([]);
      getVideoListing(selectedCategory, selectedTag, searchText)
        .then((res) => {
          checktoken_admin(res);
          if (res.data.status) {
            setstartloader(false);
            setallvideos(res.data.data[0].videos);
            setdisplayvideos(res.data.data[0].videos);
            settotalvideos(res.data.data[0].totalVideos);
            setpageloader(false)
          } else {
            setstartloader(false);
            setpageloader(false)
          }
        })
        .catch((e) => {
          setstartloader(false);
          setpageloader(false)
        });
    } else {
      if (allvideo.length < totalvideos) {
        setstartloader(true);
        setallvideos([]);
        getVideoListing(selectedCategory, selectedTag, searchText)
          .then((res) => {
            checktoken_admin(res)
            setstartloader(false);
            if (res.data.status) {
              setallvideos(res.data.data[0].videos);
              setdisplayvideos(res.data.data[0].videos);
              settotalvideos(res.data.data[0].totalVideos);
              setpageloader(false)
            } else {
              setpageloader(false)
            }
          })
          .catch((e) => {
            setstartloader(false);
            setpageloader(false)
            console.log(e);
          });
        setstartloader(false);
      }
    }
  }, [listingtype])

  useEffect(() => {
    setloader(true);
    if (true) {
      if (page === 1) setstartloader(true);
      if (listingtype !== null && listingtype !== undefined && listingtype.length > 0) {
        if (allvideo.length < totalvideos && catpage <= Math.ceil(totalvideos / 10)) {
          getVideoListing(selectedCategory, selectedTag, searchText)
            .then((res) => {

              setloader(false);
              checktoken_admin(res)
              setstartloader(false);
              if (res.data.status) {
                setallvideos([...allvideo, ...res.data.data[0].videos]);
                setdisplayvideos([...allvideo, ...res.data.data[0].videos]);
                settotalvideos(res.data.data[0].totalVideos);
                setpageloader(false)
              } else {
                setpageloader(false)
              }
            })
            .catch((e) => {
              setloader(false);
              setstartloader(false);
              setpageloader(false)
              console.log(e);
            });
        } else {
          setloader(false);
          setpageloader(false)
        }
      } else {
        if (allvideo.length < totalvideos && page <= Math.ceil(totalvideos / 10)) {
          getVideoListing(selectedCategory, selectedTag, searchText)
            .then((res) => {

              setloader(false);
              setstartloader(false);
              checktoken_admin(res)
              if (res.data.status) {
                if (selectedTag) {
                  setallvideos(res.data.data[0].videos);
                  setdisplayvideos(res.data.data[0].videos);
                  settotalvideos(res.data.data[0].totalVideos);
                  setpageloader(false)
                } else {
                  setallvideos([...allvideo, ...res.data.data[0].videos]);
                  setdisplayvideos([...allvideo, ...res.data.data[0].videos]);
                  settotalvideos(res.data.data[0].totalVideos);
                  setpageloader(false)
                }
              } else {
                setpageloader(false)
              }
            })
            .catch((e) => {
              setloader(false);
              setstartloader(false);
              setpageloader(false)
              console.log(e);
            });
        } else {
          if (totalvideos > 0)
            setloader(false);
        }
      }
    } else {
      setloader(false)
      setpageloader(false)
      setstartloader(false)
    }

  }, [catpage, page]);

  const handledelete = () => {
    authService
      .deletecontentvideobyId(deleteid)
      .then((res) => {
        checktoken_admin(res)
        setshowalert(true);
        setalertmsg(res.data.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
          if (res.data.status) {
            var arr = allvideo.filter((video) => {
              if (video._id !== deleteid) return video;
            });
            setallvideos(arr);
            var arr1 = displayvideos.filter((video) => {
              if (video._id !== deleteid) return video;
            });
            setdisplayvideos(arr1);
          }
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const searchfilter = () => {
    if (listingtype !== undefined && listingtype !== null && listingtype.length > 0 && allvideo.length < totalvideos) {

      setstartloader(true);
      setallvideos([]);
      getVideoListing(selectedCategory, selectedTag, searchText)
        .then((res) => {
          checktoken_admin(res);
          if (res.data.status) {
            setstartloader(false);
            setallvideos(res.data.data[0].videos);
            setdisplayvideos(res.data.data[0].videos);
            settotalvideos(res.data.data[0].totalVideos);
            setpageloader(false)

          } else {
            setstartloader(false);
            setpageloader(false)

          }
        })
        .catch((e) => {
          setstartloader(false);
          setpageloader(false)
        });
    } else {

      setstartloader(true);
      setallvideos([]);
      getVideoListing(selectedCategory, selectedTag, searchText)
        .then((res) => {
          checktoken_admin(res)
          setstartloader(false);
          if (res.data.status) {
            setallvideos(res.data.data[0].videos);
            setdisplayvideos(res.data.data[0].videos);
            settotalvideos(res.data.data[0].totalVideos);
            setpageloader(false)
          } else {
            setpageloader(false)
          }
        })
        .catch((e) => {
          setstartloader(false);
          setpageloader(false)
          console.log(e);
        });
      setstartloader(false);

    }

  }


  const OnSearchKeyDown = (e) => {
    // var arr = allvideo.filter((video) => {
    //   if (video.title.includes(e.target.value)) return video;
    // });
    // setdisplayvideos(arr);
    if (e.key === "Enter") {

      searchfilter()
    }



  };

  const handleSearch = (e) => {
    setpage(1)
    searchfilter()
    setSuggestionBox(false)
    setSearchText(e)
  }
  const handleSort = (e) => {
    setSelectedCategory(e.target.value)
    setSearchText('')
    setpage(1)
    if (e.target.value.length > 0) {
      setcatpage(1)
      getVideoListing(e.target.value, selectedTag, searchText)
        .then((res) => {
          checktoken_admin(res);
          if (res.data.status) {
            setstartloader(false);
            setallvideos(res.data.data[0].videos);
            setdisplayvideos(res.data.data[0].videos);
            settotalvideos(res.data.data[0].totalVideos);
          } else {
            setstartloader(false);
          }
        })
        .catch((e) => {
          setstartloader(false);
        });
    } else {
      history("/contentlibrary/listing/noid/0");
      setpage(1)
      getVideoListing(e.target.value, selectedTag, searchText)
        .then((res) => {
          checktoken_admin(res)
          setstartloader(false);
          if (res.data.status) {
            setallvideos(res.data.data[0].videos);
            setdisplayvideos(res.data.data[0].videos);
            settotalvideos(res.data.data[0].totalVideos);
          }
        })
        .catch((e) => {
          setstartloader(false);
          console.log(e);
        });
    }
  };


  // handle partner type filter change
  const handleOnChangeTag = (e) => {
    setSelectedTag(e.target.value)
    setpage(1)
    getVideoListing(selectedCategory, e.target.value, searchText)
      .then((res) => {
        checktoken_admin(res)
        setstartloader(false);
        if (res.data.status) {
          setallvideos(res.data.data[0].videos);
          setdisplayvideos(res.data.data[0].videos);
          settotalvideos(res.data.data[0].totalVideos);
        }
      })
      .catch((e) => {
        setstartloader(false);
        console.log(e);
      });
  }

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main" ref={ref} id="content-list-admin">
          <div className="uic-title">
            <h1>Videos</h1>
            <div className="uic-fl-btn">
              <button
                className="btn"
                onClick={() => {
                  history("/contentlibrary/add/noid/0");
                }}
              >
                Add video
              </button>
            </div>
          </div>
          <div className="uic-fl uic-fl-cstm">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (suggestionList !== undefined && suggestionList.length > 0) {
                    setListIndex(-1);
                    setSuggestionBox(true)
                    var searchResult = suggestionList.filter((user) => {
                      return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                    });
                    var setByOrder = searchResult?.reduce((acc, user) => {
                      if (user.name.toLowerCase().startsWith(e.target.value.trim().toLowerCase())) {
                        acc.unshift(user);
                      } else {
                        acc.push(user);
                      }
                      return acc;
                    }, []);
                    setSuggestions(setByOrder);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch(e.target.value)
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15
                        ? 15
                        : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15
                        ? 15
                        : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                <div className="suggestion-box">
                  <ul id="suggestion-list">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onMouseDown={() => handleSearch(suggestion.name)}
                        className={index === listIndex ? 'selected-active' : ''}
                      >
                        {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length)} </> : suggestion.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

              </i>
            </div>

            <div className="uic-sort-by">
              <span className="uic-sort-by-title">Sort by :</span>
              <select className="uic-select-sort-by" onChange={handleSort}>
                <option value="">All</option>
                {allcategories.map((cate) => {
                  return <option value={cate._id} selected={listingtype === cate._id}>{cate.name}</option>;
                })}
              </select>
              <div className="uic-fl-btn sort-by-mn lifetime-only">
                <div className='cstm-select-ap'>
                  <select value={selectedTag} onChange={handleOnChangeTag} >
                    <option value=''>Select Tag</option>
                    {tagList.map((tag) => (
                      <option value={tag._id}>{tag.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              Total videos: {totalvideos}
            </div>
          </div>
          {showalert && <div className="alert alert-info">{alertmsg}</div>}
          {/* Video Listing section */}
          <div className="">
            {startloader ? (
              <div className="video-col-padding">
                {[...Array(10)].map((el, index) => (
                  <div className="video-list-main">
                    <div className="video-list-thumb skeleton-box"></div>
                    <div className="video-list-heading skeleton-box"></div>
                    <div className="video-list-datetime skeleton-box"></div>
                    <div className="video-list-view-cmt">
                      <div className="video-list-view skeleton-box"></div>
                      <div className="video-list-cmt skeleton-box"></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (

              ""
            )}
            {!startloader &&
              displayvideos !== undefined &&
              displayvideos.length < 1 ? (
              <div className="newsfeed-post">
                {" "}
                <span className="emptymsg">No Data Found</span>
              </div>
            ) : (
              <></>
            )}
            <div className="video-col-padding">

              {displayvideos.map((convideo) => {
                return (
                  <>
                    <Link to={`/contentlibrary/detail/${convideo._id}/${convideo.user_video_pause !== undefined &&
                      convideo.user_video_pause[
                      localStorage.getItem("adminuserid")
                      ] !== undefined
                      ? convideo.user_video_pause[
                      localStorage.getItem("adminuserid")
                      ]
                      : 0
                      }`}>
                      <div className="gallery-main">
                        <div className="gallery-img">
                          <div
                            className="admin-content-video"
                            style={{ overflow: "hidden" }}
                          >
                            <img className="cstm-vl-img" src={convideo.thumbnail !== undefined && convideo.thumbnail !== null && convideo.thumbnail.length > 0
                              ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                              convideo.thumbnail
                              : videodummy} />
                            <img
                              className="cstm-videoPlayericons"
                              src={videoPlayericons}
                              alt="videoPlayericons"
                            />
                            <div className="cstm-rv-time">{!convideo.duration.includes("NaN") ? convideo.duration : "0:00"}</div>
                          </div>
                        </div>
                        <p
                          className="gallery-content1  cstm-ttl2"
                        >
                          {convideo.title}
                        </p>
                        <p className="gallery-content2">
                          {moment(convideo.createdAt).fromNow()}
                        </p>

                        <div className="icon-box" >
                          <a className="gallery-main-icon icon-1">
                            <img src={VideoViewsImage} />
                            <span>
                              {convideo.views !== undefined &&
                                convideo.views.length > 0
                                ? `${millify(convideo.starting_view_cnt ? convideo.views.length + convideo.starting_view_cnt : convideo.views.length)} Views`
                                : `${millify(convideo.starting_view_cnt ? convideo.starting_view_cnt : 0) + " View"}`}{" "}
                            </span>
                          </a>

                          <a className="gallery-main-icon icon-1" >
                            {/* <img src={CommnetImage} /> */}
                            <span>
                              {convideo.comments !== undefined &&
                                convideo.comments.length > 0
                                ? `${millify(convideo.comments.length)} Comments`
                                : "0 Comments"}{" "}
                            </span>
                          </a>
                        </div>
                      </div> </Link>
                  </>
                );
              })}
            </div>
            <div className="loadind-post cstm-cn-pg-loader">
              {pageloader ?
                <LoaderComponent /> : <></>
              }
            </div>
          </div>
        </div>
      </div>
      {/* Modal Delete */}
      <div
        className="modal fade msg-popup"
        id="DeleteVideo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgbigdelete} />
            </div>
            <h1>Are you sure you want to delete this video?</h1>
            <div className="row">
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={handledelete}
                    data-dismiss="modal"
                  >
                    Delete
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn" data-dismiss="modal">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default VideoListing;



