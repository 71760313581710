import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "./UserComponents/Dashboard/Header";
import Services from "../Services/auth.service";
import logo from "../Images/logo_mds_small.svg";
import user from "../Images/BackendImages/user.png";
import Logouticon from "../Images/logouticon.svg";
import ErrorFour from "../../src/Images/accessdeny.svg";
import LeftSidebar from "./UserComponents/Dashboard/LeftSidebar";
const AccessDeny = () => {
  const location = useLocation();
  let history = useNavigate();
  useEffect(() => {
    if (
      location.pathname !== undefined &&
      location.pathname.length > 0 &&
      location.pathname.split("/").length > 0 &&
      location.pathname.split("/").length == 2 &&
      location.pathname.split("/")[1] == "dashboard"
    ) {
      history("/");
    }
  }, []);

  const handleLogout = () => {
    Services.setlastlogin();
    localStorage.clear();
    history("/adminlogin");
  };
  return (
    <>
      {localStorage.getItem("idtoken") !== null &&
        localStorage.getItem("idtoken").length !== 0 &&
        localStorage.getItem("role") !== null &&
        localStorage.getItem("role") !== "user" &&
        localStorage.getItem("userid") !== null &&
        localStorage.getItem("userid").length !== 0 && (
          <div>
            <div className="header-bk">
              <div className="header-bk-left">
                <a>
                  <img src={logo} />
                </a>
              </div>
              <div className="header-bk-right">
                <div className="header-bk-right-user  dropdown">
                  <a
                    className="dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src={user} className="user-icon" />
                    {localStorage.getItem("username")}
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <a onClick={handleLogout}>
                      Logout <img src={Logouticon} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      {localStorage.getItem("idtoken") !== null &&
        localStorage.getItem("idtoken").length !== 0 &&
        localStorage.getItem("role") !== null &&
        localStorage.getItem("role") === "user" &&
        localStorage.getItem("userid") !== null &&
        localStorage.getItem("userid").length !== 0 && <Header />}

      {/* <Header /> */}
      <div className="all-content-main cstm-all-content-main cstm-all-library-content-main cstm-all-content-library-main-only">
        <LeftSidebar />
      </div>
      <div className="not-found-page">
        <span className="not-found-image2">
          <img src={ErrorFour} alt="ErrorFour" />
        </span>
        <h1>Access Denied</h1>
        <p class="sorry-but-cont">
          You currently do not have access to this page.
        </p>
      </div>
    </>
  );
};
export default AccessDeny;
