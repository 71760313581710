import React, { useEffect, useState } from 'react'
import InviteListTable from './InviteListTable';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authServiceCollaborator from '../../../Services/auth.service.collaborator';
import authService from '../../../Services/auth.service';

const AcceptedInviteList = ({ tabInformation }) => {
    const columns = [
        { id: "email", label: "Invitee email" },
        { id: "firstName", label: "Member name" },
        { id: "_id", label: "Actions" },
    ];
    const [loader, setLoader] = useState(false)
    const [rows, setRows] = useState([])
    const [allList, setAllList] = useState([])
    const [searchText, setSearchText] = useState("");
    const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions,setSuggestions]=useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");

    const dispatch = useDispatch()
    
    

    // create function for create object
    function creatCollaborator(firstName, email, status, _id, inviteeName, userId) {
        return { firstName, email, status, _id, inviteeName, userId }
    }

    useEffect(() => {
        setLoader(true)
        getAcceptedCollaboratorsListApi()
        getallusersSuggestionListApi()
    }, [tabInformation])
    async function getallusersSuggestionListApi() {
        try {
          const getSuggestionRes = await authService.getAcceptedCollaboratorsSuggestionList()
    
           console.log(getSuggestionRes , "getSuggestionRes");
          let data = getSuggestionRes.data.data
          var arr = []
         if (data &&data.length !==undefined && data.length !== null && data.length>0 ){
          data.map((item)=>arr.push({ name:item.email}))
          console.log(arr);
          setSuggestionList(arr);
         }
        } catch (err) {
         
        }
      }
      useEffect(() => {
        if (suggestionBox && listIndex > -1) {
            var currentList =
                document.getElementById("suggestion-list").children[listIndex]
            // .firstElementChild.firstElementChild;
            if (currentList) {
                var text = currentList.innerText
                setSearchText(text);
            }
        }
    }, [listIndex]);

    // pending collaborator list api
    async function getAcceptedCollaboratorsListApi(col, sort) {
        let query = ""
        query += col !== undefined && col !== null && col !== "" ? `?sortField=${col}` : ""
        query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
        try {
            const collaboratorAcceptedListRes = await authServiceCollaborator.getAcceptedCollaboratorsList(query)
            if (collaboratorAcceptedListRes.data.status) {
                let data = collaboratorAcceptedListRes.data.data
                const payload = {
                    acceptedCount: data.length,
                }
                dispatch({
                    type: "SETINVITEECOUNT",
                    payload: payload,
                });
                if (data.length > 0) {
                    var newArr = []
                    for (let i = 0; i < data.length; i++) {
                        newArr[i] = creatCollaborator(
                            data[i].sharedUserDetails.firstName + ' ' + data[i].sharedUserDetails.lastName,
                            data[i].email,
                            data[i].teamMateInvitationStatus,
                            data[i]._id,
                            data[i].name,
                            data[i].sharedUserDetails.userId
                        )
                    }
                    setAllList(newArr)
                    setRows(newArr)
                }
                setLoader(false)
            } else {
                setAllList([])
                setRows([])
                const payload = {
                    acceptedCount: 0,
                }
                dispatch({
                    type: "SETINVITEECOUNT",
                    payload: payload,
                });
                setLoader(false)
            }
        } catch (error) {
            setAllList([])
            setRows([])
            const payload = {
                acceptedCount: 0,
            }
            dispatch({
                type: "SETINVITEECOUNT",
                payload: payload,
            });
            setLoader(false)
            return false
        }
    }

    //search using text
    const handleSearch = (text) => {
        setSearchText(text);
        setSuggestionBox(false)
        if (allList !== undefined && allList.length > 0) {
            var searchResult = allList.filter((invites) => {
                const searchTextLower = text.trim().toLowerCase();
                const inviteeNameLower = invites.inviteeName.toLowerCase();
                const memberNameLower = invites.firstName.toLowerCase();
                const emailLower = invites.email.toLowerCase();

                return (
                    inviteeNameLower.includes(searchTextLower) ||
                    memberNameLower.includes(searchTextLower) ||
                    emailLower.includes(searchTextLower)
                );
            });
            setRows(searchResult);
        }
    };
 console.log(suggestions,"suggestion")
    return (
        <>
            <div className="uic-fl">
                <div className="uic-fl-search">
                    <input
                        type="text"
                        placeholder="Search"
                        className="search-user-input"
                        value={searchText}
                        onChange={(e) => {
                      setSearchText(e.target.value);
                      var currentValue;
                      if (e.target.value) {
                        currentValue = e.target.value;
                      } else {
                        currentValue = e.target.value;
                        setSearchText(currentValue);
                      }
                      setActualsearch(currentValue);
                      if (suggestionList !== undefined && suggestionList.length > 0) {
                        setListIndex(-1);
                        setSuggestionBox(true)
                        var searchResult = suggestionList.filter((user) => {
                          return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                        });
                        setSuggestions(searchResult);
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch(e.target.value)
                      } else if (e.key === "ArrowDown") {
                        const listLength =
                          suggestions.length > 15
                            ? 15
                            : suggestions.length;
                        if (listIndex < listLength - 1) {
                          setListIndex(listIndex + 1);
                        } else if (listIndex === listLength - 1) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        }
                      }else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        const listLength =
                        suggestions.length > 15
                            ? 15
                            : suggestions.length;
                        if (listIndex === 0) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        } else if (listIndex === -1) {
                          setListIndex(listLength - 1);
                        } else {
                          setListIndex(listIndex - 1);
                        }
                      }
                    }}
                    onBlur={() => setSuggestionBox(false)}
                  />

                  {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                    <div className="suggestion-box">
                      <ul  id="suggestion-list">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onMouseDown={() => handleSearch(suggestion.name)}
                            className={index === listIndex ? 'selected-active' : ''}
                          >
                            {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length -1)} </> : suggestion.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

                  </i>
                </div>
            </div>
            <InviteListTable allList={allList} columns={columns} rows={rows} loader={loader} setRows={setRows} recallApi={getAcceptedCollaboratorsListApi} />
        </>
    )
}

export default AcceptedInviteList