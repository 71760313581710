import React, { useEffect, useState, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import arrowimg from "../../../../Images/arow-left-right.png";
import moment from "moment";
import authServicePartner from "../../../../Services/auth.service.partner";
import loading from "../../../../Images/loader.gif";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ViewsModal from "../PartnerModals/ViewsModal";
import ClaimModal from "../PartnerModals/ClaimModal";
import ReviewModal from "../PartnerModals/ReviewModal";
import "chartjs-adapter-moment";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import Graph from "../../../Statistic/Graph";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend
);
const StatisticsManagement = () => {
  const [dataLoader, setDataLoader] = useState(true);
  const [filterHeader, setFilterHeader] = useState("Lifetime");
  const [filterType, setFilterType] = useState("lifetime");
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "days").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [field, setField] = useState("pageView");
  const [allCount, setAllCount] = useState(0);
  const [statisticsOptions, setStatisticsOptions] = useState(null);
  const [rows, setRows] = useState([]);
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [claimModal, setClaimModal] = useState(false);
  const [ratingModal, setRatingmodal] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [claimId, setClaimId] = useState("");
  const [reviewName, setReviewName] = useState();
  const [count, setCount] = useState({
    viewCount: 0,
    claimCount: 0,
    ratingCount: 0,
  });
  const [alertMsg, setAlertMsg] = useState("");
  const [isChangeDate, setIsChangeDate] = useState(true);

  const fromDateRef = useRef(fromDate);
  const toDateRef = useRef(toDate);

  const columns = [
    { id: "srno", label: "Sr" },
    { id: "companyName", label: "Company name" },
    {
      id:
        field === "pageView"
          ? "countViewData"
          : field === "claim"
          ? "countOfferViewData"
          : "partnerReviewCount",
      label:
        field === "pageView"
          ? "Views"
          : field === "claim"
          ? "Claims"
          : "Rating",
    },
  ];

  useEffect(() => {
    statisticAllCountApi(field);
    statisticTabCountApi(filterType, fromDate, toDate);
    statisticListApi(field, filterType, fromDate, toDate);
  }, []);

  // statistics list api function
  async function statisticListApi(field, filterType, fromDate, toDate) {
    setDataLoader(true);
    setNoDataFound(false);
    let query = `?field=${field}&filtertype=${filterType}`;
    filterType !== "lifetime"
      ? (query += `&fromdate=${fromDate}&todate=${toDate}`)
      : query;
    try {
      const statisticListRes =
        await authServicePartner.partnerStatisticListForAdmin(query);
      if (statisticListRes.data.status) {
        setIsChangeDate(false)
        setDataLoader(false);
        let data = statisticListRes.data.data;
        if (data.length > 0 )
        {
          (field === "pageView") ? data = data.sort((a,b)=>b.countViewData-a.countViewData) :
          (field === "claim") ? data = data.sort((a,b)=>b.countOfferViewData-a.countOfferViewData) : 
          data = data.sort((a,b)=>b.partnerReviewCount-a.partnerReviewCount)
          
        }
        

        setRows(data);
        setAllData(data);
        if (data.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
      } else {
        setDataLoader(false);
      }
    } catch (error) {
      setDataLoader(false);
      return false;
    }
  }

  // statistic graph api function
  async function statisticsGraphApi(
    field,
    filterType,
    fromDateTime,
    toDateTime
  ) {
    let query = `?field=${field}&filtertype=${filterType}`;
    try {
      const statisticsGraphRes =
        await authServicePartner.partnerStatisticDateWiseFilterCountForAdmin(
          query
        );
      if (statisticsGraphRes.data.status) {
        let chartDataArray = [];
        let labelsArray = [];
        statisticsGraphRes.data.data.map((item) =>
          chartDataArray.push(item.count)
        );
        statisticsGraphRes.data.data.map((item) =>
          labelsArray.push(new Date(item._id).getTime())
        );
        const statisticsOpt = {
          field: field,
          filterType: filterType,
          fromDateTime: fromDateTime,
          toDateTime: toDateTime,
          labels: labelsArray,
          chartDataArr: chartDataArray,
        };
        setStatisticsOptions(statisticsOpt);
      }
    } catch (error) {}
  }

  // statistic all count api function
  async function statisticAllCountApi(field) {
    try {
      const allCountRes =
        await authServicePartner.partnerStatisticAllCountForPartner(field);
      if (allCountRes.data.status) {
        setAllCount(allCountRes.data.DataCount);
      }
    } catch (error) {
      return false;
    }
  }

  // statistic count by tab api function
  async function statisticTabCountApi(filterType, fromDate, toDate) {
    let query = `?filtertype=${filterType}`;
    filterType !== "lifetime"
      ? (query += `&fromdate=${fromDate}&todate=${toDate}`)
      : query;
    try {
      const tabCountRes = await authServicePartner.partnerStatisticFieldcount(
        query
      );
      if (tabCountRes.data.status) {
        setIsChangeDate(false)
        setCount({
          ...count,
          claimCount: tabCountRes.data.data.claimsDataCount,
          viewCount: tabCountRes.data.data.pageViewCount,
          ratingCount: tabCountRes.data.data.totalCountOfReview,
        });
      }
    } catch (error) {
      return false;
    }
  }

  // field and filter type change this use effect will call
  useEffect(() => {
    let fromDateTime = "";
    let toDateTime = "";
    if (filterType !== "lifetime") {
      if (filterType === "first24hrs") {
        fromDateTime = moment().subtract(24, "hours").subtract(-1);
        toDateTime = moment().add(1, "hours");
      } else if (filterType === "past7days") {
        fromDateTime = moment().subtract(6 + 1, "days");
        toDateTime = moment().add(1, "days");
      } else if (filterType === "past28days") {
        fromDateTime = moment().subtract(27 + 1, "days");
        toDateTime = moment().add(1, "days");
      } else if (filterType === "past90days") {
        fromDateTime = moment().subtract(89 + 1, "days");
        toDateTime = moment().add(1, "days");
      } else if (filterType === "past365days") {
        fromDateTime = moment().subtract(364 + 1, "days");
        toDateTime = moment().add(1, "days");
      } else if (filterType === "custom") {
        fromDateTime = moment(fromDate).subtract(1, "days");
        toDateTime = moment(toDate).add(1, "days");
      }
      statisticsGraphApi(field, filterType, fromDateTime, toDateTime);
    }
  }, [filterType, field]);

  // handle filter change function for life time or date wise
  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
    if (e.target.value === "first24hrs") {
      var fromdate = moment().subtract(24, "hours").format("DD MMM HH:mm");
      var todate = moment().format("DD MMM HH:mm:SS");
      statisticTabCountApi(
        e.target.value,
        moment().subtract(24, "hours").format("DD MMM, YYYY"),
        todate
      );
      statisticListApi(
        field,
        e.target.value,
        moment().subtract(24, "hours").format("DD MMM, YYYY"),
        todate
      );
      setFilterHeader(fromdate + "-" + todate);
    } else if (e.target.value === "past7days") {
      var fromdate = moment()
        .subtract(6 + 1, "days")
        .format("DD MMM");
      var todate = moment().format("DD MMM, YYYY");
      statisticTabCountApi(
        e.target.value,
        moment().subtract(6, "days").format("DD MMM, YYYY"),
        todate
      );
      statisticListApi(
        field,
        e.target.value,
        moment().subtract(6, "days").format("DD MMM, YYYY"),
        todate
      );
      setFilterHeader(fromdate + "-" + todate);
    } else if (e.target.value === "past28days") {
      var fromdate = moment().subtract(27, "days").format("DD MMM");
      var todate = moment().format("DD MMM, YYYY");
      statisticListApi(
        field,
        e.target.value,
        moment().subtract(27, "hours").format("DD MMM, YYYY"),
        todate
      );
      statisticTabCountApi(
        e.target.value,
        moment().subtract(27, "days").format("DD MMM, YYYY"),
        todate
      );
      setFilterHeader(fromdate + "-" + todate);
    } else if (e.target.value === "past90days") {
      var fromdate = moment().subtract(89, "days").format("DD MMM");
      var todate = moment().format("DD MMM, YYYY");
      statisticListApi(
        field,
        e.target.value,
        moment().subtract(89, "hours").format("DD MMM, YYYY"),
        todate
      );
      statisticTabCountApi(
        e.target.value,
        moment().subtract(89, "days").format("DD MMM, YYYY"),
        todate
      );
      setFilterHeader(fromdate + "-" + todate);
    } else if (e.target.value === "past365days") {
      var fromdate = moment().subtract(364, "days").format("DD MMM");
      var todate = moment().format("DD MMM, YYYY");
      statisticListApi(
        field,
        e.target.value,
        moment().subtract(364, "hours").format("DD MMM, YYYY"),
        todate
      );
      statisticTabCountApi(
        e.target.value,
        moment().subtract(364, "days").format("DD MMM, YYYY"),
        todate
      );
      setFilterHeader(fromdate + "-" + todate);
    } else if (e.target.value === "custom") {
      var fromdate = moment(fromDate).format("DD MMM, YYYY");
      var todate = moment(toDate).format("DD MMM, YYYY");
      setFilterHeader(fromdate + "-" + todate);
      statisticListApi(
        field,
        e.target.value,
        moment().subtract(364, "hours").format("DD MMM, YYYY"),
        todate
      );
      statisticTabCountApi(
        e.target.value,
        moment().subtract(364, "days").format("DD MMM, YYYY"),
        todate
      );
      setFilterHeader(fromdate + "-" + todate);
    } else {
      statisticListApi(field, e.target.value, fromDate, toDate);
      statisticTabCountApi(e.target.value, fromDate, toDate);
      setFilterHeader("Lifetime");
    }
  };

  //for pagination roe per page change function
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

    //check date changed or not
    const handleDateChange = (date, setDateFunction, dateRef) => {
      setIsChangeDate(false)
      if (date !== dateRef.current) {
        setDateFunction(date);
        setIsChangeDate(true)
      }
    };
  // when to date select calendar will close and this function will call
  const handleCalenderClose = (e) => {
    if (
      moment(new Date(fromDate)).format("YYYY-MM-DD") >=
      moment(new Date(toDate)).format("YYYY-MM-DD")
    ) {
      setAlertMsg("Fromdate should be less than Todate!");
      setTimeout(() => {
        setAlertMsg("");
      }, 3000);
    } else {
      if (fromDate !== "" && toDate !== "" && isChangeDate) {
        var fromdate = moment(fromDate).format("DD MMM, YYYY");
        var todate = moment(toDate).format("DD MMM, YYYY");
        setFilterHeader(fromdate + "-" + todate);
        statisticTabCountApi(filterType, fromdate, todate);
        statisticListApi(field, filterType, fromdate, todate);
      }
    }
  };

  //sorting function
  const handleSort = (col, direc) => {
    var sortedResult = allData;
    if (direc === "asc") {
      sortedResult.sort(function (firstItem, secondItem) {
        var itemA = firstItem[col];
        var itemB = secondItem[col];
        return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
      });
    } else if (direc === "desc") {
      sortedResult.sort(function (firstItem, secondItem) {
        var itemA = firstItem[col];
        var itemB = secondItem[col];
        return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
      });
    }
    const sortedData = sortedResult.filter((item) => item);
    setRows(sortedData);
  };

  // handle field tab change
  const handleFieldChange = (field) => {
    statisticAllCountApi(field);
    statisticListApi(field, filterType, fromDate, toDate);
    setField(field);
  };

  const onEscCloseModal = (e) => {
    if (viewModalOpen && e.key === "Escape") {
      setViewModalOpen(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
    if (claimModal && e.key === "Escape") {
      setClaimModal(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }

    if (ratingModal && e.key === "Escape") {
      setRatingmodal(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  document.addEventListener("keydown", onEscCloseModal);

  function formatWithCommas(n) { 
    return n.toString().replace(/\B(?=(\d{3})+\b)/g, ","); 
  }


  return (
    <>
      <div className="users-info-content video-statistic-info-content">
        <div className="video-statistic-info-content-main">
          {alertMsg && <div className="alert alert-info">{alertMsg}</div>}
          <div className="cstm-video-filter-container">
            <div className="cstm-video-filter-container-left">
              <h2 className="cstn-vst">
                {" "}
                {allCount} Page{" "}
                {field === "pageView"
                  ? "Views"
                  : field === "claim"
                  ? "Claims"
                  : "Rating"}
              </h2>
            </div>
            <div className="cstm-video-filter-container-right">
              <h4 className="cstm-video-select-title">{filterHeader}</h4>
              <select
                value={filterType}
                onChange={handleFilterChange}
                className="cstm-video-select-field"
              >
                <option value="lifetime">lifetime</option>
                <option value="first24hrs">Last 24 h</option>
                <option value="past7days">Last 7 Days</option>
                <option value="past28days">Last 30 Days</option>
                <option value="past90days">Last 90 Days</option>
                <option value="past365days">Last 365 Days</option>
                <option value="custom">Custom</option>
              </select>
              {filterType === "custom" ? (
                <div className="cstm-video-filter-custom">
                  <ReactDatePicker
                    showIcon
                    selected={fromDate}
                    onChange={(date) => handleDateChange(date, setFromDate, fromDateRef)}
                    onCalendarClose={handleCalenderClose}
                  />
                  <img src={arrowimg} className="cstm-arrow-left-right" />
                  <ReactDatePicker
                    showIcon
                    selected={toDate}
                    onChange={(date) => handleDateChange(date, setToDate, toDateRef)}
                    onCalendarClose={handleCalenderClose}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="cstm-tab-video-statistic-main">
            <ul
              className={`${
                filterType === "lifetime" ? "statistic-lifetime-tab" : ""
              }  cstm-tab-video-statistic`}
            >
              <li className={field === "pageView" ? "cstm-active-vs" : null}>
                <a
                  className="active cstm-videostatistic-view-link"
                  id="VideoStatisticViewBtn"
                  data-toggle="tab"
                  href="#VideoStatisticViewContent"
                  role="tab"
                  onClick={() => {
                    handleFieldChange("pageView");
                  }}
                >
                  <div className="csm-videostatistic-header">
                    <p className="csm-videostatistic-header-title">
                      Page Views
                    </p>
                    <h3 className="csm-videostatistic-header-viewscount">
                      {formatWithCommas(count.viewCount)}
                    </h3>
                    <p className="csm-videostatistic-header-subtitle">
                      {filterType === "first24hrs"
                        ? `First 24 hours`
                        : filterType === "past7days"
                        ? `In last 7 days`
                        : filterType === "past28days"
                        ? `In last 28 days`
                        : filterType === "past90days"
                        ? `In last 90 days`
                        : filterType === "past365days"
                        ? `In last 365 days`
                        : filterType === "lifetime"
                        ? `Lifetime`
                        : ""}
                    </p>
                  </div>
                </a>
              </li>
              <li className={field === "claim" ? "cstm-active-vs" : null}>
                <a
                  className="active cstm-videostatistic-view-link"
                  id="VideoStatisticViewBtn"
                  data-toggle="tab"
                  href="#VideoStatisticViewContent"
                  role="tab"
                  onClick={() => {
                    handleFieldChange("claim");
                  }}
                >
                  <div className="csm-videostatistic-header">
                    <p className="csm-videostatistic-header-title">Claims</p>
                    <h3 className="csm-videostatistic-header-viewscount">
                      {formatWithCommas(count.claimCount)}
                    </h3>
                    <p className="csm-videostatistic-header-subtitle">
                      {filterType === "first24hrs"
                        ? `First 24 hours`
                        : filterType === "past7days"
                        ? `In last 7 days`
                        : filterType === "past28days"
                        ? `In last 28 days`
                        : filterType === "past90days"
                        ? `In last 90 days`
                        : filterType === "past365days"
                        ? `In last 365 days`
                        : filterType === "lifetime"
                        ? `Lifetime`
                        : ""}
                    </p>
                  </div>
                </a>
              </li>
              <li className={field === "rating" ? "cstm-active-vs" : null}>
                <a
                  className="active cstm-videostatistic-view-link"
                  id="VideoStatisticViewBtn"
                  data-toggle="tab"
                  href="#VideoStatisticViewContent"
                  role="tab"
                  onClick={() => {
                    handleFieldChange("rating");
                  }}
                >
                  <div className="csm-videostatistic-header">
                    <p className="csm-videostatistic-header-title">Ratings</p>
                    <h3 className="csm-videostatistic-header-viewscount">
                      {formatWithCommas(count.ratingCount)}
                    </h3>
                    <p className="csm-videostatistic-header-subtitle">
                      {filterType === "first24hrs"
                        ? `First 24 hours`
                        : filterType === "past7days"
                        ? `In last 7 days`
                        : filterType === "past28days"
                        ? `In last 28 days`
                        : filterType === "past90days"
                        ? `In last 90 days`
                        : filterType === "past365days"
                        ? `In last 365 days`
                        : filterType === "lifetime"
                        ? `Lifetime`
                        : ""}
                    </p>
                  </div>
                </a>
              </li>
            </ul>
            <div
              className={`tab-content ${
                filterType !== "lifetime" ? "videostatistic-tabs" : ""
              }`}
              id="VideoStatisticViewContent"
            >
              <div
                className={`tab-pane fade show active ${
                  filterType !== "lifetime" ? `cstm-video-graph-container` : ``
                }`}
                id="videostatisticview"
                role="tabpanel"
              >
                {dataLoader && (
                  <>
                    {" "}
                    <div className="statistic-loader-img">
                      <img src={loading} />
                    </div>
                  </>
                )}
                {!dataLoader && noDataFound && (
                  <>
                    <div className="cstm-no-data-found">No Data Found</div>
                  </>
                )}
                {!dataLoader &&
                !noDataFound &&
                filterType !== "lifetime" &&
                statisticsOptions !== undefined &&
                statisticsOptions !== null &&
                Object.keys(statisticsOptions).length > 0 ? (
                  <Graph statisticsOptions={statisticsOptions} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {!dataLoader && !noDataFound && (
            <Paper className="uic-tb-mn">
              <TableContainer className="uic-table-main">
                <Table className="uic-table min-w-auto">
                  <TableHead className="uic-table-th">
                    <TableRow className="uic-th-td">
                      {columns.map((column, i) => (
                        <TableCell className="uic-th-td" key={column.id}>
                          {column.id !== "srno" &&
                          column.id !== "companyName" ? (
                            <>
                              <span className="custom-sorting">
                                <i
                                  className="fa fa-angle-down"
                                  onClick={(e) => {
                                    handleSort(column.id, "asc");
                                  }}
                                ></i>
                                <i
                                  className="fa fa-angle-up"
                                  onClick={(e) => {
                                    handleSort(column.id, "desc");
                                  }}
                                ></i>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {rows.length > 0 &&
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, i) => {
                          return (
                            <TableRow className="uic-th-td">
                              {columns.map((col) => {
                                const value = row[col.id];
                                return col.id === "srno" ? (
                                  <TableCell className="uic-th-td">
                                    {i + 1}
                                  </TableCell>
                                ) : col.id === "companyName" ? (
                                  <TableCell className="uic-th-td">
                                    {value}
                                  </TableCell>
                                ) : col.id === "countViewData" ? (
                                  <TableCell className="uic-th-td">
                                    <a
                                      onClick={(e) => {
                                        value > 0 && setViewModalOpen(true);
                                        value > 0 &&
                                          document.body.classList.add(
                                            "modal-open"
                                          );
                                        value > 0 && setClaimId(row._id);
                                      }}
                                      className="cstm-view-users-count"
                                    >
                                      {value}
                                    </a>
                                  </TableCell>
                                ) : col.id === "countOfferViewData" ? (
                                  <TableCell className="uic-th-td">
                                    <a
                                      onClick={() => {
                                        value !== "NA" && setClaimModal(true);
                                        value !== "NA" &&
                                          document.body.classList.add(
                                            "modal-open"
                                          );
                                        value !== "NA" && setClaimId(row._id);
                                      }}
                                      className="cstm-view-users-count"
                                    >
                                      {value !== "NA" ? value : 0}
                                    </a>
                                  </TableCell>
                                ) : (
                                  <TableCell className="uic-th-td">
                                    <a
                                      onClick={() => {
                                        value > 0 && setRatingmodal(true);
                                        value > 0 &&
                                          document.body.classList.add(
                                            "modal-open"
                                          );
                                        value > 0 && setClaimId(row._id);
                                        setReviewName(row.companyName);
                                      }}
                                      className="cstm-view-users-count"
                                    >
                                      {value}
                                    </a>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="uic-pagination"
                rowsPerPageOptions={[20, 50, 100, 200]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </div>
      </div>
      {viewModalOpen && (
        <ViewsModal
          filterType={filterType}
          fromDate={fromDate}
          toDate={toDate}
          setViewModalOpen={setViewModalOpen}
          claimId={claimId}
        />
      )}
      {claimModal && (
        <ClaimModal
          filterType={filterType}
          fromDate={fromDate}
          toDate={toDate}
          claimModal={claimModal}
          claimId={claimId}
          setClaimModal={setClaimModal}
        />
      )}
      {ratingModal && (
        <ReviewModal
          filterType={filterType}
          fromDate={fromDate}
          toDate={toDate}
          reviewName={reviewName}
          setRatingmodal={setRatingmodal}
          claimId={claimId}
        />
      )}
    </>
  );
};

export default StatisticsManagement;
