import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import EventThumbImage from "../../Components/HelperComponents/EventThumbImage";
import Button from "../../../../components/Button/Button";
import "../EventStyle/EventDetailsPage.css";
import { ReactComponent as AppLogo } from "../../../../Images/logo-32-32.svg";
import Separators from "../../../../components/Separators/Separators";
import mdsLogoImage from "../../../../Images/logo-image-any-only.jpg";
import { ReactComponent as CheckIcon } from "../../../../Images/icn_m_check_filled.svg";
import "../EventStyle/EventDetailsPage.css";
import NotificationBanner from "../../../Notifications/components/NotificationBanner";
import {
  useLazyMyEventDetailQuery,
  useLazyPastEventDetailQuery,
  useLazyUpcomingEventDetailQuery,
  useCheckEventAccessMutation,
} from "../../../../Services/eventDetail";
import { usePurchaseEventTicketDetailsByEventIdQuery } from "../../../../Services/event-ticket";
import AccessDeny from "../../../../components/AccessDeny";

const EventDetailsPage = () => {
  const [eventDetailData, setEventDetailData] = useState({});
  const [loader, setLoader] = useState(true);
  const { eventId, eventType } = useParams();
  const [isAccess, setAccess] = useState(false);
  const history = useNavigate();

  //fetch upcoming event detail data
  const [upcomingEventDetailHook, { isFetching: upcomigDetailLoader }] =
    useLazyUpcomingEventDetailQuery();

  //fetch past event detail data
  const [pastEventDetailHook, { isFetching: pastDetailLoader }] =
    useLazyPastEventDetailQuery();

  //fetch my event detail data
  const [myEventDetailHook, { isFetching: myEventDetailLoader }] =
    useLazyMyEventDetailQuery();

  const [checkEventAccess, { isLoading: eventAccessLoading }] =
    useCheckEventAccessMutation();

  useEffect(() => {
    (async function () {
      const checkEventAccessRes = await checkEventAccess({
        eventId: eventId,
        localDate: new Date(),
        accessFor: "event",
        role: "member",
      });
      if (checkEventAccessRes.data) {
        if (!checkEventAccessRes.data.access) {
          setAccess(true);
        } else {
          setAccess(false);
        }
      }
    })();
  }, [eventId]);

  // API Calling For get purchase event ticket list of data
  const { data: eventTicketPurchase } =
    usePurchaseEventTicketDetailsByEventIdQuery({
      eventId: eventId,
    });
  console.log("eventTicketPurchase>>", eventTicketPurchase);
  useEffect(() => {
    setLoader(true);
    if (eventType === "upcoming") {
      handleUpcomingDetail();
      setLoader(false);
    } else if (eventType === "pastEvents") {
      handlePastEventDetail();
      setLoader(false);
    } else if (eventType === "myEvents") {
      handleMyEventDetail();
      setLoader(false);
    }
  }, []);
  //handle upcoming api
  const handleUpcomingDetail = async () => {
    try {
      const upcomingRes = await upcomingEventDetailHook({
        event_id: eventId,
      });
      setEventDetailData(upcomingRes?.data);
    } catch (err) {
      console.log("Error:", err);
    }
  };

  //handle past api
  const handlePastEventDetail = async () => {
    try {
      const pastEventRes = await pastEventDetailHook({
        event_id: eventId,
      });
      setEventDetailData(pastEventRes?.data);
    } catch (err) {
      console.log("Error:", err);
    }
  };

  //handle my event api
  const handleMyEventDetail = async () => {
    try {
      const myEventRes = await myEventDetailHook({
        event_id: eventId,
      });
      setEventDetailData(myEventRes?.data);
    } catch (err) {
      console.log("Error:", err);
    }
  };

  const formattedDateConvert = (dateString) => {
    if (dateString) {
      // Parse the date string into a Date object
      const dateParts = dateString.split("-");
      const month = parseInt(dateParts[0], 10); // Month is 0-based in JavaScript Date object
      const day = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);
      const dateObject = new Date(year, month - 1, day);

      // Format the date as "MMM D, YYYY"
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(dateObject);
      return formattedDate;
    }
  };

  const formattedTimeConvert = (timeString) => {
    if (timeString) {
      const [time, period] = timeString.split(" ");
      const [hours, minutes] = time.split(":");

      // Check if minutes are '00'
      const formattedTime =
        minutes === "00"
          ? `${parseInt(hours, 10)}${period.toUpperCase()}` // Remove minutes if they are '00'
          : `${parseInt(hours, 10)}:${minutes}${period.toUpperCase()}`; // Include minutes otherwise

      return formattedTime;
    }
    return "";
  };

  //set amount
  let eventTypeSet;
  if (eventDetailData?.data?.type) {
    eventTypeSet = eventDetailData?.data?.type[0];
  }

  let amount;
  //set event type
  if (eventDetailData?.data?.price) {
    amount = eventDetailData?.data?.price.toFixed(2);
  }

  const handleTicketsClick = () => {
    if (eventDetailData?.data?.locationType === "inPerson") {
      if (eventDetailData?.data?.eventUrl !== "") {
        window.open(eventDetailData?.data?.eventUrl, "_blank");
      } else {
        history(`/event-ticketing/${eventType}/${eventId}`);
      }
    } else if (
      eventDetailData?.data?.locationType === "virtual" &&
      !eventDetailData?.data?.registationFlag
    ) {
      history(`/event-ticketing/${eventType}/${eventId}`);
    } else if (
      eventDetailData?.data?.registationFlag &&
      eventDetailData?.data?.locationType === "virtual"
    ) {
      history(`/event-ticketing/${eventType}/${eventId}`);
    }
  };
  //register and go to event click
  const handleRegisterClick = () => {
    if (eventDetailData?.data?.locationType === "inPerson") {
      if (eventDetailData?.data?.eventUrl !== "") {
        window.open(eventDetailData?.data?.eventUrl, "_blank");
      } else {
        history(`/event-ticketing/${eventType}/${eventId}`);
      }
    } else if (
      eventDetailData?.data?.locationType === "virtual" &&
      !eventDetailData?.data?.registationFlag
    ) {
      history(`/event-ticketing/${eventType}/${eventId}`);
    } else if (
      eventDetailData?.data?.registationFlag &&
      eventDetailData?.data?.locationType === "virtual"
    ) {
      window.open(
        eventDetailData?.data?.onlineLocationDetail?.onlineEventUrl,
        "_blank"
      );
    }
  };

  // function getCanonicalTimezone(timezoneString) {
  //   const allTimezones = moment2.tz.names();
  //   const normalizedInput = timezoneString.toLowerCase().replace(/ /g, "_");

  //   for (const tz of allTimezones) {
  //     const normalizedTz = tz.toLowerCase().replace(/\//g, "_");
  //     if (normalizedInput.includes(normalizedTz)) {
  //       return tz;
  //     }
  //   }
  // }

  // //timezone abbrevation
  // const timeZoneAbbrevation = (timezoneString) => {
  //   // Get the user's local timezone
  //   const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   const extractedTimezone = getCanonicalTimezone(timezoneString);
  //   console.log("extracted timezone", extractedTimezone);
  //   const abbreviation = moment2.tz(localTimezone).format("z");
  // };

  //get a day of week
  function getDayOfWeek(dateString) {
    const date = moment(dateString, "MM-DD-YYYY");
    const dayOfWeek = date.format("dddd");
    return dayOfWeek;
  }

  return (
    <>
      {upcomigDetailLoader ||
      loader ||
      myEventDetailLoader ||
      pastDetailLoader ? (
        <>
          <div className="cstm-event-page-banner-loading skeleton-box"></div>
          <div className="event-mds-logo-loader skeleton-box"></div>
          <div className="event-details-description-loader skeleton-box"></div>
          <div className="price-tag-loader skeleton-box"></div>
        </>
      ) : (
        !isAccess &&
        !eventAccessLoading && (
          <div className="event-detail-page">
            {/* Event Heading */}
            <div className="event-detail-head">
              <div className="event-container">
                <div className="event-detail-head-wrapper">
                  <EventThumbImage
                    imgType="small"
                    ThumbSrc={
                      eventDetailData?.data?.thumbnail
                        ? eventDetailData?.data?.thumbnail
                        : mdsLogoImage
                    }
                  />
                  <div className="event-detail-text-wrapper">
                    <h1 className="event-detail-heading">
                      {eventDetailData?.data?.title}
                    </h1>
                    <p>
                      {/* {formattedDateConvert(eventDetailData.startDate)},{" "}
                    {formattedTimeConvert(eventDetailData.startTime)} -{" "}
                    {formattedTimeConvert(eventDetailData.endTime)} */}
                      {getDayOfWeek(eventDetailData?.data?.startDate)},{" "}
                      {moment(
                        eventDetailData?.data?.startDate,
                        "MM-DD-YYYY"
                      ).format("MMM DD")}{" "}
                      {formattedTimeConvert(eventDetailData?.data?.startTime)}
                      {moment(
                        eventDetailData?.data?.startDate,
                        "MM-DD-YYYY"
                      ).isSame(
                        moment(eventDetailData?.data?.endDate, "MM-DD-YYYY"),
                        "day"
                      ) &&
                        ` -
                      ${formattedTimeConvert(eventDetailData?.data?.endTime)}`}
                      {moment(
                        eventDetailData?.data?.endDate,
                        "MM-DD-YYYY"
                      ).diff(
                        moment(eventDetailData?.data?.startDate, "MM-DD-YYYY"),
                        "days"
                      ) > 0 && (
                        <>
                          {" "}
                          - {getDayOfWeek(eventDetailData?.data?.endDate)},{" "}
                          {moment(
                            eventDetailData?.data?.endDate,
                            "MM-DD-YYYY"
                          ).format("MMM DD")}{" "}
                          {formattedTimeConvert(eventDetailData?.data?.endTime)}
                        </>
                      )}{" "}
                      {eventDetailData?.data?.timeZone !== ""
                        ? `
                    ${eventDetailData?.data?.timeZone}`
                        : ""}
                      {eventDetailData?.data?.location?.length > 0
                        ? `,
                    ${eventDetailData?.data?.location[0]?.city}`
                        : ""}
                      {eventDetailData?.data?.location?.length > 0
                        ? `,
                    ${eventDetailData?.data?.location[0]?.country}`
                        : ""}
                    </p>
                    {eventType !== "pastEvents" &&
                      eventDetailData?.data?.locationType !==
                        "to_be_announce" && (
                        <div className="btn-wrapper">
                          <Button
                            label={
                              eventDetailData?.data?.registationFlag
                                ? "Go to the event"
                                : eventDetailData?.data?.locationType ===
                                  "virtual"
                                ? "Reserve a spot"
                                : "Get tickets"
                            }
                            variant="primary"
                            handleClick={handleRegisterClick}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {/* Event Details */}
            <div className="event-details-wrapper">
              <div className="event-container">
                <div className="event-details-flexbox">
                  <div className="event-details">
                    <div className="event-organizer-heading">
                      <AppLogo />
                      <h3>Million Dollar Sellers</h3>
                    </div>
                    <Separators />
                    <div className="text-wrapper">
                      <div className="faq-wrapper">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: eventDetailData?.data?.longDescription,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="event-sticky-card-wrapper">
                    {eventDetailData?.data?.registationFlag &&
                      eventType !== "pastEvents" &&
                      eventDetailData?.data?.locationType !==
                        "to_be_announce" && (
                        <NotificationBanner
                          extraClass="mb-32"
                          icon={<CheckIcon />}
                          text="Registered"
                          onClickFun={handleTicketsClick}
                          actionText={
                            eventTicketPurchase?.status ? "Details" : ""
                          }
                          variant="success"
                          hasActionText={true}
                        />
                      )}
                    {eventType === "pastEvents" && (
                      <NotificationBanner
                        extraClass="mb-32"
                        variant="success"
                        icon={<CheckIcon />}
                        text="Completed"
                      />
                    )}
                    <div className="event-sticky-card">
                      <div className="event-details">
                        <h3>
                          {eventDetailData?.data?.price === 0
                            ? "Free"
                            : `from $${amount}`}
                        </h3>
                        <p className="event-type-text">{eventTypeSet}</p>
                      </div>
                      {!eventDetailData?.data?.registationFlag && (
                        <Separators />
                      )}
                      {!eventDetailData?.data?.registationFlag &&
                        eventType !== "pastEvents" &&
                        eventDetailData?.data?.locationType !==
                          "to_be_announce" && (
                          <div className="btn-wrapper">
                            <Button
                              label={
                                // eventDetailData?.data?.locationType ===
                                // "inPerson"
                                //   ? "Get tickets"
                                //   :
                                eventDetailData?.data?.locationType ===
                                "virtual"
                                  ? "Reserve a spot"
                                  : "Get tickets"
                              }
                              variant="primary w-100"
                              handleClick={handleTicketsClick}
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
      {!upcomigDetailLoader &&
        !loader &&
        !myEventDetailLoader &&
        !pastDetailLoader &&
        !eventAccessLoading &&
        isAccess && <AccessDeny />}
    </>
  );
};

export default EventDetailsPage;
