import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
// Import Api integration file
import Services from "../../Services/auth.service";

// Import component
import EditQues from "./EditQues";

// Import css files
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/registration.css";

// Import images
import uploadpic from "../../Images/upload-dp.png";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import editfill from "../../Images/BackendImages/edit-fill.svg";
import deletefill from "../../Images/BackendImages/delete-fill.svg";
import AdminSidebar from "../AdminSidebar";
import UserInfoSideBar from "../BackendUserlisting/UserInfoSideBar";

const Questions = ({ allques, setallques, setShowaddques, retriveQues }) => {
  const [Inprocess, setInprocess] = useState(false);
  const [showupdateorder, setshowupdateorder] = useState(false);
  const [showedit, setshowedit] = useState(false);
  const [selectedId, setselectedId] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [deletequesid, setdeletequesid] = useState("");
  const [isMenu,setMenu]=useState(false)
  const [dragAndDrop, setDragAndDrop] = useState({
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  });
  let history = useNavigate();
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  // Called on click on add question button
  const addques = () => {
    setShowaddques(true);
  };
  // Called on click on edit button
  const handleEdit = (quesid) => {
    setselectedId(quesid);
    setshowedit(true);
  };
  // Called on click on delete button
  const handleDelete = (quesid) => {
    Services.deletequestion(quesid)
      .then(function (response) {
        if (response.data.status) {
          setshowalert(true);
          setalertmsg("Question deleted successfully!");
          setTimeout(() => {
            setshowalert(false);
          }, 2000);
          retriveQues();
        } else {
          setshowalert(true);
          setalertmsg(response.data.message);
          setTimeout(() => {
            setshowalert(false);
          }, 2000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: allques,
    });

    // Note: this is only for Firefox.
    // Without it, the DnD won't work.
    // But we are not using it.
    event.dataTransfer.setData("text/html", "");
  };

  // onDragOver fires when an element being dragged
  // enters a droppable area.
  // In this case, any of the items on the list
  const onDragOver = (event) => {
    // in order for the onDrop
    // event to fire, we have
    // to cancel out this one
    event.preventDefault();

    let newList = dragAndDrop.originalOrder;

    // index of the item being dragged
    const draggedFrom = dragAndDrop.draggedFrom;

    // index of the droppable area being hovered
    const draggedTo = Number(event.currentTarget.dataset.position);

    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo,
      });
    }
  };

  const onDrop_list = (event) => {
    setallques(dragAndDrop.updatedOrder);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };

  const onDragLeave = () => {
    setshowupdateorder(true);
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };


  const savereorder = () => {
    setInprocess(true);
    Services.savereorderindb({ questions_bank: allques })
      .then((res) => {
        if (res.data.status) {
          setshowupdateorder(false);
          retriveQues();
        }
        setInprocess(false);
      })
      .catch((e) => {
        setInprocess(false);
        console.log(e);
      });
  };

  return (
    <>
      {/* <div>
        <AdminSidebar />

        <div className="content-main"> */}
          <div className="users-info">
       <UserInfoSideBar />
            <div className="users-info-content">
              <div className="users-info-content-main">
                {showalert && (
                  <div className="alert alert-info">{alertmsg}</div>
                )}
                <div className="uic-title">
                  <h1>Questions</h1>
                  <button
                    className="btn2"
                    data-toggle="modal"
                    data-target="#Addques"
                    onClick={addques}
                  >
                    Add Question
                  </button>
                </div>

                <div className="questions-accordian">
                  <div id="accordion">
                    {allques.length > 0
                      ? allques.map((ques, index) => {
                          var ref = `#div${index}`;
                          var divmain = `div${index}`;
                          return (
                            <div 
                              data-id={index}
                              key={index}
                              data-position={index}
                              draggable
                              onDragStart={onDragStart}
                              onDragOver={onDragOver}
                              onDrop={onDrop_list}
                              onDragLeave={onDragLeave}
                              className="card dragdrop2"
                            >
                              <div>
                                <div className="questions-accordian-header">
                                  {" "}
                                  <a 
                                    className={`card-link ${
                                      dragAndDrop &&
                                      dragAndDrop.draggedTo === Number(index)
                                        ? "dropArea"
                                        : ""
                                    }`}
                                    data-toggle="collapse"
                                    href={ref}
                                  >
                                    {" "}
                                    {ques.question}{" "}
                                  </a>{" "}
                                  <div className="questions-accordian-edt-delete">
                                    <a
                                      href="#"
                                      data-toggle="modal"
                                      data-target="#editques"
                                      onClick={() => {
                                        handleEdit(ques._id);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                    <a
                                      data-toggle="modal"
                                      data-target="#Deletequestion"
                                      onClick={() => {
                                        setdeletequesid(ques._id);
                                      }}
                                    >
                                      <img src={deletefill} />
                                    </a>
                                   
                                  </div>
                                  
                                </div>
                              <div className="movableicon"> <i className="fas fa-arrows-alt"></i></div>
                                <div
                                  id={divmain}
                                  className="collapse"
                                  data-parent="#accordion"
                                >
                                  <div className="questions-box-incont">
                                    {ques.options.length < 1 ? (
                                      ques.field_type === "file" ? (
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <img
                                            src={uploadpic}
                                            className="full"
                                          />
                                        </div>
                                      ) : ques.field_type === "textarea" ? (
                                        <textarea></textarea>
                                      ) : (
                                        <input type={ques.type} />
                                      )
                                    ) : ques.field_type === "checkbox" ? (
                                      ques.options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    ) : (
                                      ques.options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
                {showupdateorder ? (
                  <div className="cyp-btm-btn">
                    <button
                      className="btn"
                      onClick={savereorder}
                      disabled={Inprocess}
                    >
                      {Inprocess ? "In Process.." : "Update position"}
                    </button>
                    <button
                      className="btn2"
                      onClick={() => {
                        setshowupdateorder(false);
                        retriveQues();
                      }}
                    >
                      Discard
                    </button>
                  </div>
                ) : (
                  <></>
                )}

                <div className="cstm-aad-field">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#Addques"
                    onClick={addques}
                  >
                    + Add Question
                  </a>
                </div>
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
      {selectedId.length > 0 ? (
        <EditQues
          allques={allques}
          selectedId={selectedId}
          setshowedit={setshowedit}
          showedit={showedit}
          retriveQues={retriveQues}
          setallques={setallques}
        />
      ) : (
        ""
      )}
      {/* Modal Delete */}
      <div
        className="modal fade msg-popup"
        id="Deletequestion"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgbigdelete} />
            </div>
            <h1>Are you sure you want to delete this question?</h1>
            <div className="row">
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={() => {
                      handleDelete(deletequesid);
                    }}
                    data-dismiss="modal"
                  >
                    Delete
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="uic-fl-btn">
                  <button type="button" className="btn" data-dismiss="modal">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Questions;
