import React, { useState } from "react";
import EmptyState from "../../../components/EmptyState/EmptyState";
import Table from "../../../components/Table";
import TableBody from "../../../components/Table/TableBody";
import TableHead from "../../../components/Table/TableHead";
import { InView } from "react-intersection-observer";
import Button from "../../../components/Button/Button";
import SavedDocumentTableList from "./SavedDocumentTableList";
import authServiceDocument from "../../../Services/auth.service.document";
import SaveIcon from "../../../components/Icons/GeneralIcons/SaveIcon";
import ShareIcon from "../../../components/Icons/GeneralIcons/ShareIcon";
import DownloadIcon from "../../../components/Icons/GeneralIcons/DownloadIcon";
import ComplainIcon from "../../../components/Icons/GeneralIcons/ComplainIcon";
import NoResultDocIcon from "./NoResultDocIcon";
import { useLocation, useNavigate } from "react-router-dom";
import IconSearchNoResults from "../../../components/GlobalComponents/Icons/IconEmptyState/IconSearchNoResults";
import TableLoadingSkeleton from "./TableLoadingSkeleton";
const SavedDocsListView = ({
  handleFilterByOwner,
  setFileName,
  handlePreview,
  savedCount,
  setSavedCount,
  handleSelectCategory,
  columns,
  handleDownloadDoc,
  setSavedList,
  allDocList,
  totalRecords,
  currentPage,
  totalPage,
  handleMoreResult,
  moreLoader,
  loader,
  handleClearAllFilter,
  selectedFilter,
  searchText,
  handleSortByColumn,
  sortedColumn,
  setSortedColumn,
}) => {
  const navigate = useNavigate();
  const [docId, setDocId] = useState("");
  const [fileUrl, setFireUrl] = useState("");
  const [saved, setSaved] = useState(false);
  const [restricted, setRestricted] = useState(false);
  const location = useLocation().search;
  const searchFromUrl = new URLSearchParams(location).get("search");

  // handle doc save or unsave
  const handleSaveDocApi = (id) => {
    setSavedCount(savedCount - 1);
    setSaved(true);
    const updatedData = allDocList.filter((row) => row._id !== id);
    setSavedList(updatedData);
    try {
      authServiceDocument.saveDoc(id);
    } catch (error) {}
  };

  const actionOptions = [
    {
      id: "save",
      name: "Remove from saved",
      icon: <SaveIcon isFilled />,
      callbackFunction: () => handleSaveDocApi(docId),
    },
    // { id: "share", name: "Share", icon: <ShareIcon /> },
    {
      id: "download",
      name: "Download",
      icon: <DownloadIcon />,
      callbackFunction: () => handleDownloadDoc(docId, fileUrl, restricted),
    },
    // { id: "report", name: "Report", icon: <ComplainIcon /> },
  ];
  return (
    <>
      <div className="doc-table">
        {loader ? (
          <TableLoadingSkeleton/>
        ) : (
          <>
            {allDocList.length > 0 && (
              <Table>
                <TableHead columns={columns} />
                <td colspan="100%">
                  <hr />
                </td>
                <TableBody>
                  {allDocList.length > 0 &&
                    allDocList.map((docs, index) => {
                      return (
                        <>
                          {index === allDocList.length - 1 &&
                          index !== totalRecords - 1 ? (
                            <tr>
                              <td colspan="100%">
                                <InView
                                  onChange={(inView) => {
                                    if (inView) {
                                      !saved && handleMoreResult();
                                      setSaved(false);
                                    }
                                  }}
                                >
                                  <table>
                                    <TableBody>
                                      <SavedDocumentTableList
                                        setRestricted={setRestricted}
                                        handleFilterByOwner={
                                          handleFilterByOwner
                                        }
                                        setFileName={setFileName}
                                        handlePreview={handlePreview}
                                        setFireUrl={setFireUrl}
                                        handleSelectCategory={
                                          handleSelectCategory
                                        }
                                        setDocId={setDocId}
                                        handleSaveDocApi={handleSaveDocApi}
                                        columns={columns}
                                        docs={docs}
                                        actionOptions={actionOptions}
                                      />
                                    </TableBody>
                                  </table>
                                </InView>
                              </td>
                            </tr>
                          ) : (
                            <SavedDocumentTableList
                              setRestricted={setRestricted}
                              handleFilterByOwner={handleFilterByOwner}
                              setFileName={setFileName}
                              handlePreview={handlePreview}
                              setFireUrl={setFireUrl}
                              handleSelectCategory={handleSelectCategory}
                              setDocId={setDocId}
                              handleSaveDocApi={handleSaveDocApi}
                              columns={columns}
                              docs={docs}
                              actionOptions={actionOptions}
                            />
                          )}
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            )}
            {!loader &&
            allDocList.length === 0 &&
            (selectedFilter > 0 ||
              (searchFromUrl !== null && searchFromUrl.length > 0)) ? (
              <EmptyState
                icon={<IconSearchNoResults />}
                onClick={handleClearAllFilter}
                title="Oops! No results this time"
                subTitle={`Please adjust your searching filters and give it another go!`}
              />
            ) : (
              !loader &&
              allDocList.length === 0 && (
                <EmptyState
                  icon={<NoResultDocIcon />}
                  label="Browse docs"
                  onClick={() => navigate("/documents?type=documents")}
                  title="You have not saved files yet"
                  subTitle={`Create your own collection from community files`}
                />
              )
            )}
            {moreLoader && (
              <TableLoadingSkeleton/>
            )}
            {!loader &&
              totalPage > 1 &&
              currentPage !== 0 &&
              currentPage < 2 && (
                <div className="more-results">
                  <Button
                    size="large"
                    handleClick={handleMoreResult}
                    variant="secondary"
                  >
                    More results
                  </Button>
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
};

export default SavedDocsListView;
