import React from "react";

const IconNavDoc = () => {
  return (
    <div className="mds-icons">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M17.5 3.5h-11a3 3 0 0 0-3 3v11a3 3 0 0 0 3 3h11a3 3 0 0 0 3-3v-11a3 3 0 0 0-3-3ZM6.5 2A4.5 4.5 0 0 0 2 6.5v11A4.5 4.5 0 0 0 6.5 22h11a4.5 4.5 0 0 0 4.5-4.5v-11A4.5 4.5 0 0 0 17.5 2h-11Z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M7.25 12a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75ZM7.25 15a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75ZM7.25 9A.75.75 0 0 1 8 8.25h7a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 9Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavDoc;
