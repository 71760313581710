import React from "react";
import PropTypes from "prop-types"
function PauseIcon({ height = 24, width = 24, iconColor = "#000", isFilled }) {
    return (
        <>
            {isFilled ? <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M14.664 2h4.342a.49.49 0 0 1 .49.49v19.02a.49.49 0 0 1-.49.49h-4.342a.49.49 0 0 1-.49-.49V2.49a.49.49 0 0 1 .49-.49ZM5.489 2h4.343a.49.49 0 0 1 .49.49v19.02a.49.49 0 0 1-.49.49H5.489A.49.49 0 0 1 5 21.51V2.49A.49.49 0 0 1 5.49 2Z"
                />
            </svg> : <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M10.78 8.522v6.468c0 .369-.327.668-.731.668-.404 0-.732-.3-.732-.668V8.522c0-.369.328-.668.732-.668.404 0 .732.3.732.668ZM14.683 14.99V8.522c0-.369-.328-.668-.732-.668-.404 0-.732.3-.732.668v6.468c0 .369.328.668.732.668.404 0 .732-.3.732-.668Z"
                />
                <path
                    fill="#000"
                    fillRule="evenodd"
                    d="M12 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10Zm0-18.537c-4.707 0-8.537 3.83-8.537 8.537 0 4.707 3.83 8.537 8.537 8.537 4.707 0 8.537-3.83 8.537-8.537 0-4.707-3.83-8.537-8.537-8.537Z"
                    clipRule="evenodd"
                />
            </svg>}
        </>
    );
}
PauseIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool
}
export default PauseIcon;
