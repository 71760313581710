import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Store.js";

//Import css files
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/Theme/Theme.css"

// Import component
import App from "./App.js";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ThemeProvider } from "@mui/material";
import theme from "./Styles/Theme/theme.js";
if (window.location.hostname === "app.milliondollarsellers.com") {
  Sentry.init({
    dsn: "https://a4d95b1feb8c49f7ad51c7277c8edc61@o4505071778791424.ingest.sentry.io/4505163713806336",
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
  });
}
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
