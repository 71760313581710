import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedPartnersApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    importCsvToMongo: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.users.importCsvToMongo.url,
          method: ApiRoutes.users.importCsvToMongo.method,
          body,
        };
      },
      invalidatesTags: ["IMPORTEDUSERS"],
      transformResponse: (response) => response,
    }),
    getImportedMongoUsers: builder.query({
      query: (params) => {
        return {
          url: ApiRoutes.users.getImportedMongoUsers.url,
          method: ApiRoutes.users.getImportedMongoUsers.method,
          params
        };
      },
      providesTags: ["IMPORTEDUSERS"],
      transformResponse: (response) => response,
    }),
    getCancelledUsers: builder.query({
      query: (params) => {
        return {
          url: ApiRoutes.users.cancelledUserLists.url,
          method: ApiRoutes.users.cancelledUserLists.method,
          params
        };
      },
      providesTags: ["CANCELLEDUSERS"],
      transformResponse: (response) => response,
    }),
    getCancelledUsers: builder.query({
      query: (params) => {
        return {
          url: ApiRoutes.users.cancelledUserLists.url,
          method: ApiRoutes.users.cancelledUserLists.method,
          params
        };
      },
      providesTags: ["CANCELLEDUSERS"],
      transformResponse: (response) => response,
    }),
    cancelledUserSuggestionLists: builder.query({
      query: (params) => {
        return {
          url: ApiRoutes.users.cancelledUserSuggestionLists.url,
          method: ApiRoutes.users.cancelledUserSuggestionLists.method,
          params
        };
      },
      providesTags: ["CANCELLEDUSERS"],
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetImportedMongoUsersQuery,
  useLazyGetImportedMongoUsersQuery,
  useImportCsvToMongoMutation,
  useGetCancelledUsersQuery,
  useLazyGetCancelledUsersQuery,
  useCancelledUserSuggestionListsQuery,
} = extendedPartnersApi;
