import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import Services from "../../../Services/auth.service";
//import images
import crossIcon from "../../../Images/CancelCategoriesPopup.svg";
import LeftSideBarMenu from "../../GlobalComponents/LeftSideBarMenu";
import VideosMenu from "../../GlobalComponents/VideosMenu";
import FacebookGroupMenu from "../../GlobalComponents/FacebookGroupMenu";
import CommunityMenu from "../../GlobalComponents/CommunityMenu";
const LeftSidebar = ({
  setSelectedGroup,
  setselectedDiv,
  selectedDiv,
  SelectedGroup,
  leftsidebar,
}) => {
  let history = useNavigate();
  const [angle, setangle] = useState({
    starredgroup: "up",
    allgroup: "up",
    starredtopics: "down",
    alltopics: "down",
    contentlib: "up",
    category: "down",
  });
  const { action, videoid, sec } = useParams();
  let { groupid } = useParams();
  const pathname = useLocation();
  const search = useLocation().search;
  const listingtype = new URLSearchParams(search).get("type");
  const categoryId = new URLSearchParams(search).get("catid");
  const [allgroup, setallgroup] = useState([]);
  const [stargroup, setstargroup] = useState([]);
  const [startopic, setstartopic] = useState([]);
  const [alltopics, setalltopics] = useState([]);
  const [displaytopics, setdisplaytopics] = useState([]);
  const [groupname, setgroupname] = useState("");
  const [groupimage, setgroupimage] = useState("");
  const [allcategories, setallcategories] = useState([]);
  const [current_path, setcurrent_path] = useState("");
  const [inprocess_groupstar, setinprocess_groupstar] = useState({});
  const [inprocess_topicstar, setinprocess_topicstar] = useState({});
  const [sidebarloader, setsidebarloader] = useState({
    grptopic: false,
    stargrp: false,
    allgrp: false,
    startopic: false,
  });
  const [sort, setsort] = useState({
    allgroup: "asc",
    starredtopic: "asc",
    grouptopic: "asc",
  });

  const [showleftsidebarmenus, setleftshowsidebarmenus] = useState(true);
  const [activemenu, setactivemenu] = useState("contentlibrary");
  const [showCategoryMenu, setShowCategoryMenu] = useState(false);
  useEffect(() => {
    setsidebarloader({
      grptopic: false,
      stargrp: true,
      allgrp: true,
      startopic: true,
    });

    if (window.location.href.indexOf("frontcontentlibrary") > -1) {
      setleftshowsidebarmenus(true);
    } else {
      setleftshowsidebarmenus(true);
    }

    if (groupid !== undefined && groupid.length > 0) {
      Services.getgroupbyid(groupid)
        .then((res) => {
          console.log(res.data.invalidToken);
          if (res.data.status) {
            setgroupname(res.data.data.groupTitle);
            setgroupimage(res.data.data.groupImage);
          } else if (res.data.invalidToken) {
            localStorage.clear();
            history("/");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    Services.getallcontentcategories()
      .then((res) => {
        if (res.data.status) {
          setallcategories(res.data.data);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getallgrouploginuser()
      .then((res) => {
        if (res.data.status) {
          setsidebarloader({ ...sidebarloader, allgrp: false });
          setallgroup(res.data.data);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        } else {
          setsidebarloader({ ...sidebarloader, allgrp: false });
        }
      })
      .catch((err) => {
        setsidebarloader({ ...sidebarloader, allgrp: false });
        console.log(err);
      });
    Services.getstarredgroup()
      .then((res) => {
        if (res.data.status) {
          setsidebarloader({ ...sidebarloader, stargrp: false });
          setstargroup(res.data.data);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        } else {
          setsidebarloader({ ...sidebarloader, stargrp: false });
        }
      })
      .catch((err) => {
        setsidebarloader({ ...sidebarloader, stargrp: false });
        console.log(err);
      });
    Services.getstarredtopic()
      .then((res) => {
        if (res.data.status) {
          setsidebarloader({ ...sidebarloader, startopic: false });
          setstartopic(res.data.data);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        } else {
          setsidebarloader({ ...sidebarloader, startopic: false });
        }
      })
      .catch((e) => {
        setsidebarloader({ ...sidebarloader, startopic: false });
        console.log(e);
      });
  }, []);

  useEffect(() => {
    action == "videohistory"
      ? setactivemenu("videohistory")
      : action == "myvideos"
      ? setactivemenu("myvideos")
      : action == "watchlater"
      ? setactivemenu("watchlater")
      : setactivemenu("contentlibrary");
  }, [action]);

  useEffect(() => {
    setcurrent_path(pathname.pathname.split("/")[1]);
  }, [pathname]);
  useEffect(() => {
    setsidebarloader({ ...sidebarloader, grptopic: true });
    var result = allgroup.filter((gr) => {
      if (gr._id === SelectedGroup) return gr;
    })[0];
    if (result !== undefined && result.groupTitle !== undefined)
      setgroupname(
        allgroup.filter((gr) => {
          if (gr._id === SelectedGroup) return gr;
        })[0].groupTitle
      );
    Services.gettopicsbygroupid(SelectedGroup)
      .then((res) => {
        if (res.data.status) {
          setsidebarloader({ ...sidebarloader, grptopic: false });
          setalltopics(res.data.data);
          setdisplaytopics(res.data.data);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [SelectedGroup]);
  const addstargroup = (groupid) => {
    setinprocess_groupstar({ [groupid]: true });
    Services.addstargroup({ groupId: groupid })
      .then((res) => {
        if (res.data.status) {
          var arr = [];
          var n = 0;
          for (var i = 0; i < allgroup.length; i++) {
            if (allgroup[i]._id === groupid) {
              if (stargroup !== undefined && stargroup.length > 0)
                setstargroup([...stargroup, allgroup[i]]);
              else setstargroup([allgroup[i]]);
            }
          }
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
        setinprocess_groupstar({ [groupid]: false });
      })
      .catch((e) => {
        setinprocess_groupstar({ [groupid]: false });
        console.log(e);
      });
  };
  const removestargroup = (groupid) => {
    setinprocess_groupstar({ [groupid]: true });
    Services.removestargroup(groupid)
      .then((res) => {
        if (res.data.status) {
          var arr = [];
          var n = 0;
          for (var i = 0; i < stargroup.length; i++) {
            if (stargroup[i]._id !== groupid) {
              arr[n] = stargroup[i];
              n++;
            }
          }
          setstargroup(arr);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
        setinprocess_groupstar({ [groupid]: false });
      })
      .catch((e) => {
        setinprocess_groupstar({ [groupid]: false });
        console.log(e);
      });
  };
  const removestartopic = (topicid) => {
    setinprocess_topicstar({ [topicid]: true });
    Services.removestartopic(topicid)
      .then((res) => {
        if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
        var arr = [];
        var n = 0;
        for (var i = 0; i < startopic.length; i++) {
          if (startopic[i]._id !== topicid) {
            arr[n] = startopic[i];
            n++;
          }
        }
        setstartopic(arr);
        setinprocess_topicstar({ [topicid]: false });
      })
      .catch((e) => {
        setinprocess_topicstar({ [topicid]: false });
        console.log(e);
      });
  };
  const addstartopic = (topicid) => {
    setinprocess_topicstar({ [topicid]: true });
    Services.addstartopic({ topicId: topicid })
      .then((res) => {
        if (res.data.status) {
          for (var i = 0; i < alltopics.length; i++) {
            if (alltopics[i]._id === topicid) {
              if (startopic !== undefined && startopic.length > 0)
                setstartopic([...startopic, alltopics[i]]);
              else setstartopic([alltopics[i]]);
            }
          }
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
        setinprocess_topicstar({ [topicid]: false });
      })
      .catch((e) => {
        setinprocess_topicstar({ [topicid]: false });
        console.log(e);
      });
  };
  const handleselectgroup = (groupid, groupname, groupimg) => {
    Services.gettopicsbygroupid(groupid)
      .then((res) => {
        if (res.data.status) {
          setalltopics(res.data.data);
          setdisplaytopics(res.data.data);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
      })
      .catch((e) => {
        console.log(e);
      });

    setgroupname(groupname);
    setgroupimage(groupimg);
    // setSelectedGroup(groupid);
    // setselectedDiv("group");
    history(`/group/${groupid}`);
  };
  const handleSearchTopics = (text) => {
    var arr = [];
    var n = 0;
    for (var i = 0; i < alltopics.length; i++) {
      if (alltopics[i].topic.toUpperCase().includes(text.toUpperCase())) {
        arr[n] = alltopics[i];
        n++;
      }
    }
    setdisplaytopics(arr);
  };
  const handleGroupTopicSort = () => {
    var arr = [...displaytopics];
    if (sort.grouptopic === "asc") {
      arr.sort(function (a, b) {
        var textA = a.topic.toUpperCase();
        var textB = b.topic.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else {
      arr.sort(function (a, b) {
        var textA = a.topic.toUpperCase();
        var textB = b.topic.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }
    setdisplaytopics(arr);
    setsort({ ...sort, grouptopic: sort.grouptopic === "des" ? "asc" : "des" });
  };
  const handleStarGroupSort = () => {
    var arr = [...stargroup];
    arr.sort(function (a, b) {
      var textA = a.groupTitle.toUpperCase();
      var textB = b.groupTitle.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    setstargroup(arr);
  };
  const handleStarTopicSort = () => {
    var arr = [...startopic];
    if (sort.starredtopic === "asc") {
      arr.sort(function (a, b) {
        var textA = a.topic.toUpperCase();
        var textB = b.topic.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else {
      arr.sort(function (a, b) {
        var textA = a.topic.toUpperCase();
        var textB = b.topic.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }
    setstartopic(arr);
    setsort({
      ...sort,
      starredtopic: sort.starredtopic === "des" ? "asc" : "des",
    });
  };
  const handleAllGroupSort = () => {
    var arr = [...allgroup];
    if (sort.allgroup === "asc") {
      arr.sort(function (a, b) {
        var textA = a.groupTitle.toUpperCase();
        var textB = b.groupTitle.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else {
      arr.sort(function (a, b) {
        var textA = a.groupTitle.toUpperCase();
        var textB = b.groupTitle.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }
    setallgroup(arr);
    setsort({ ...sort, allgroup: sort.allgroup === "des" ? "asc" : "des" });
  };
  const resourceList = localStorage.getItem("accessResources");
  const isCollaborator = JSON.parse(localStorage.getItem("isCollaborator"));
  const videoResource =
    resourceList !== null &&
    JSON.parse(resourceList).find((resource) => resource.name === "Videos");
  return (
    <>
      {showCategoryMenu && (
        <div
          id="contentlib"
          className="collapse show category-slider-menu"
          aria-labelledby="headingfour"
        >
          <div
            className="category-slider-menu-in"
            onMouseLeave={() => {
              setShowCategoryMenu(false);
            }}
          >
            <div className="category-slider-close">
              <img
                src={crossIcon}
                onClick={() => {
                  setShowCategoryMenu(false);
                }}
              />
            </div>
            <div className="category-slider-menu-all">
              <ul className="sidebar-main-ul">
                {allcategories.length > 0 &&
                  allcategories.map((cate) => {
                    return (
                      <li>
                        <Link
                          to={`/videos?type=category&filter=recent&catid=${cate._id}`}
                          onClick={() => {
                            setShowCategoryMenu(false);
                          }}
                          className={
                            listingtype === "category" &&
                            categoryId === cate._id
                              ? "sidebar-link active"
                              : "sidebar-link"
                          }
                        >
                          {cate.name}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      )}

      <div className="sidebar-main">
        <div className="scrollbar sidebar-main-in">
          <LeftSideBarMenu setShowCategoryMenu={setShowCategoryMenu} />
          <div className="sidebar-footer">
            <hr className="sidebar-line" />
            <ul className="sidebar-footer-link">
              <li>
                <Link
                  to="https://apps.apple.com/app/id1636838955"
                  target="_blank"
                >
                  Download iOS app
                </Link>
              </li>
              <li>
                <Link
                  to="https://play.google.com/store/apps/details?id=com.app.mdscommunity"
                  target="_blank"
                >
                  Download Android app
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSidebar;
