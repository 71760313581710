import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import millify from "millify";
import authService from "../../Services/auth.service";
import CommnetImage from "../../Images/message.svg";
import VideoViewsImage from "../../Images/view-2.png";
import loading from "../../Images/loader.gif";
import restoreimg from "../../Images/restoreicon.png";
import videoPlayericons from "../../Images/video-player-icons.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
const DeletedVideo = () => { 
    const history = useNavigate()
    const [allvideo, setallvideos] = useState([]);
    const [allcategories, setallcategories] = useState([]);
    const [displayvideos, setdisplayvideos] = useState([]);
    const [loader, setloader] = useState(false);
    const [deleteid,setdeleteid] = useState('');
    const [showalert, setshowalert] = useState(false);
    const [alertmsg, setalertmsg] = useState("");
    const [restoreid, setrestoreid] = useState('');
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions,setSuggestions]=useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [listIndex, setListIndex] = useState(-1);
    const [searchText, setSearchText] = useState("");
    const [actualsearch, setActualsearch] = useState("");
    useEffect(() => {
      // setloader(true)
      getallusersSuggestionListApi()
      authService
        .getalldeletedvideos()
        .then((res) => {
          setloader(false);
          if (res.data.status) {
            setallvideos(res.data.data);
            setdisplayvideos(res.data.data);
          }
        })
        .catch((e) => {
          setloader(false)
          console.log(e);
        });
        authService.getallcontentcategory_byadmin()
        .then((res) => {
          if (res.data.status) {
            setallcategories(res.data.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, []);
    const handledelete = () => {
      authService.permenantdeletevideobyid(deleteid).then((res)=>{
        setshowalert(true)
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false)
              setalertmsg("");
              if(res.data.status)
              {
                var arr = allvideo.filter((video)=>{
                  if(video._id !== deleteid)
                    return video
                })
                setallvideos(arr);
                var arr1 = displayvideos.filter((video)=>{
                  if(video._id !== deleteid)
                    return video
                })
                setdisplayvideos(arr1);
              }
            }, 3000);
      }).catch((e)=>{console.log(e)})
    }
    async function getallusersSuggestionListApi() {
      try {
        const getSuggestionRes = await authService.getDeletedContentVideoSuggestionList()
  
         console.log(getSuggestionRes , "getSuggestionRes");
        let data = getSuggestionRes.data.data
        var arr = []
       if (data &&data.length !==undefined && data.length !== null && data.length>0 ){
        data.map((item)=>arr.push({ name:item.title}))
        console.log(arr);
        setSuggestionList(arr);
       }
      } catch (err) {
       
      }
    }
    useEffect(() => {
      if (suggestionBox && listIndex > -1) {
        var currentList =
          document.getElementById("suggestion-list").children[listIndex]
        // .firstElementChild.firstElementChild;
        if (currentList) {
          var text = currentList.innerText
          setSearchText(text);
        }
      }
    }, [listIndex]);
    const handlerestore = () => {
        authService.restorevideobyid(restoreid).then((res)=>{
            setshowalert(true)
                setalertmsg(res.data.message);
                setTimeout(() => {
                  setshowalert(false)
                  setalertmsg("");
                  if(res.data.status)
                  {
                    var arr = allvideo.filter((video)=>{
                      if(video._id !== restoreid)
                        return video
                    })
                    setallvideos(arr);
                    var arr1 = displayvideos.filter((video)=>{
                      if(video._id !== restoreid)
                        return video
                    })
                    setdisplayvideos(arr1);
                  }
                }, 3000);
          }).catch((e)=>{console.log(e)})
    }
    const handleSearch = (e) => {
      setSearchText(e)
      var arr = allvideo.filter((video)=>{ if(video.title.includes(e)) return video})
      setdisplayvideos(arr);
       setSuggestionBox(false);
    }
    const handleSort = (e) => {
      if(e.target.value.length > 0)
      {
        var arr = allvideo.filter((video)=>{
          if(video.categories.length > 0)
          {
           var categories = video.categories.filter((cate)=>{ if(cate._id === e.target.value) return cate})
           if(categories.length > 0)
             return video;
          }
         })
       setdisplayvideos(arr);
      } else {
        setdisplayvideos([...allvideo])
      }
    }
    return (
      <>
        <div className="users-info-content">
          <div className="users-info-content-main">
            <div className="uic-title">
              <h1>All deleted videos</h1>
            </div>
            <div className="uic-fl uic-fl-cstm">
              <div className="uic-fl-search">
                <input
                  type="text"
                  placeholder="Search"
                  className="search-user-input"
                  onChange={(e) => {
                      setSearchText(e.target.value);
                      var currentValue;
                      if (e.target.value) {
                        currentValue = e.target.value;
                      } else {
                        currentValue = e.target.value;
                        setSearchText(currentValue);
                      }
                      setActualsearch(currentValue);
                      if (suggestionList !== undefined && suggestionList.length > 0) {
                        setListIndex(-1);
                        setSuggestionBox(true)
                        var searchResult = suggestionList.filter((user) => {
                          return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                        });
                        setSuggestions(searchResult);
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch(e.target.value)
                      } else if (e.key === "ArrowDown") {
                        const listLength =
                          suggestions.length > 15
                            ? 15
                            : suggestions.length;
                        if (listIndex < listLength - 1) {
                          setListIndex(listIndex + 1);
                        } else if (listIndex === listLength - 1) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        }
                      }else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        const listLength =
                        suggestions.length > 15
                            ? 15
                            : suggestions.length;
                        if (listIndex === 0) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        } else if (listIndex === -1) {
                          setListIndex(listLength - 1);
                        } else {
                          setListIndex(listIndex - 1);
                        }
                      }
                    }}
                    onBlur={() => setSuggestionBox(false)}
                  />

                  {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                    <div className="suggestion-box">
                      <ul  id="suggestion-list">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onMouseDown={() => handleSearch(suggestion.name)}
                            className={index === listIndex ? 'selected-active' : ''}
                          >
                            {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length -1)} </> : suggestion.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <i className="fa fa-search" onClick={() => handleSearch(searchText)}>
                  </i>           
              </div>
              <div className="uic-sort-by">
                <span className="uic-sort-by-title">Sort by :</span>
                <select className="uic-select-sort-by" onChange={handleSort}>
                  <option value="">All</option>
                  {allcategories.map((cate) => {
                    return <option value={cate._id}>{cate.name}</option>;
                  })}
                </select>
              </div>
            </div>
            {showalert && <div className="alert alert-info">{alertmsg}</div>}
            {/* Video Listing section */}
            <div className="">
              {loader ? (
                <div className="loadind-post">
                  <img src={loading} />
                </div>
              ) : (
                ""
              )}
              {!loader &&
              displayvideos !== undefined &&
              displayvideos.length < 1 ? (
                <div className="newsfeed-post">
                  {" "}
                  <span className="emptymsg">No Data Found</span>
                </div>
              ) : (
                <></>
              )}
              <div className="video-col-padding">
                {displayvideos.map((convideo) => {
                  var videoJsOptions = {
                    startTime: 0,
                    playbackRates: [0.5, 1, 1.5, 2],
                    width: 640,
                    height: 360,
                    controls: true,
                    plugins: {
                      videoJsResolutionSwitcher: {
                        default: "high",
                        dynamicLabel: true,
                      },
                    },
                    // source: process.env.REACT_APP_AWS_IMG_VID_PATH + convideo.video,
                    sources: [
                      {
                        src:
                          process.env.REACT_APP_AWS_IMG_VID_PATH +
                          convideo.video,
                        label: "720",
                        res: 720,
                      },
                      {
                        src:
                          process.env.REACT_APP_AWS_IMG_VID_PATH +
                          convideo.video,
                        label: "480",
                        res: 480,
                      },
                      {
                        src:
                          process.env.REACT_APP_AWS_IMG_VID_PATH +
                          convideo.video,
                        label: "270",
                        res: 270,
                      },
                      {
                        src:
                          process.env.REACT_APP_AWS_IMG_VID_PATH +
                          convideo.video,
                        label: "180",
                        res: 180,
                      },
                    ],
                  };
                  return (
                    <>
                      <div className="gallery-main">
                        <div className="gallery-img">
                          <div className="admin-content-video" style={{ overflow : "hidden"}}>
                            <video
                              src={
                                process.env.REACT_APP_AWS_IMG_VID_PATH +
                                convideo.video
                              }
                              className="video-js vjs-default-skin"
                              style={{ width: "100%", height: "100%" }}
                              // controls
                            ></video>
                            <img
                              className="cstm-videoPlayericons"
                              src={videoPlayericons}
                              alt="videoPlayericons"
                            />
                          </div>
                        </div>
                        <p
                          className="gallery-content1"
                          onClick={() => {
                            history(`/contentlibrary/detail/${convideo._id}/0`);
                          }}
                        >
                          {convideo.title}
                        </p>
                        <p className="gallery-content2">
                          {moment(convideo.createdAt).format(
                            "MMM DD, YYYY [at] hh:mm a"
                          )}
                        </p>
                        <div className="icon-box">
                          <a href="" className="gallery-main-icon icon-1">
                            <img src={VideoViewsImage} />
                            <span>
                              {convideo.views !== undefined && convideo.views.length > 0
                                ? `${millify(convideo.views.length)} ${convideo.views.length === 1 ? "View" : "Views"}`
                                : "0 Views"}
                            </span>
                          </a>
                          <a href="" className="gallery-main-icon icon-1">
                            {/* <img src={CommnetImage} /> */}
                            <span>
                              {convideo.comments !== undefined &&
                              convideo.comments.length > 0
                                ? `${millify(
                                    convideo.comments.length
                                  )} Comments`
                                : "0 Comments"}{" "}
                            </span>
                          </a>
                        </div>
                        <div className="icon-box icon-box-last">
                          <a
                            data-toggle="modal"
                            data-target="#PermDeleteVideo"
                            className="deletevideolink"
                            onClick={() => {
                              setdeleteid(convideo._id);
                            }}
                          >
                            Delete permanently
                          </a>
                          <a
                            className="restorevideolink"
                            data-toggle="modal"
                            data-target="#RestoreVideo"
                            onClick={() => {
                              setrestoreid(convideo._id);
                            }}
                          >
                            Restore
                          </a>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/* Modal Delete */}
        <div
          className="modal fade msg-popup"
          id="PermDeleteVideo"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </button>
              <div className="msg-icon">
                <img src={imgbigdelete} />
              </div>
              <h1>Are you sure you want to permanetly delete this video?</h1>
              <div className="row">
                <div className="col-6">
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn btn-red"
                      onClick={handledelete}
                      data-dismiss="modal"
                    >
                      Delete
                    </button>
                  </span>
                </div>
                <div className="col-6">
                  <span className="msg-btn">
                    <button type="button" className="btn" data-dismiss="modal">
                      Discard
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade msg-popup"
          id="RestoreVideo"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                &times;
              </button>
              <div className="msg-icon">
                <img src={restoreimg} />
              </div>
              <h1>Are you sure to restore this video?</h1>
              <div className="row">
                <div className="col-6">
                  <span className="msg-btn">
                    <button
                      type="btn"
                      className="btn btn-red"
                      onClick={handlerestore}
                      data-dismiss="modal"
                    >
                      Restore
                    </button>
                  </span>
                </div>
                <div className="col-6">
                  <span className="msg-btn">
                    <button type="button" className="btn" data-dismiss="modal">
                      Discard
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );  
};
export default DeletedVideo;
