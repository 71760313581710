import React from "react";
import PropTypes from "prop-types"
function ArrowleftIcon({ height = 24, width = 24, iconColor = "#000" }) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M11.278 19a.75.75 0 0 0-.002-1.06l-5.21-5.19H20.75c.406-.028.75-.321.75-.75a.75.75 0 0 0-.75-.75H6.067l5.208-5.19.073-.083a.75.75 0 0 0-1.132-.98l-6.484 6.46A.757.757 0 0 0 3.5 12c0 .192.074.385.22.531l6.498 6.471.084.073a.75.75 0 0 0 .976-.075Z"
                    clipRule="evenodd"
                />
            </svg >
        </>
    );
}
ArrowleftIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default ArrowleftIcon;
