import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import authService from "../../Services/auth.service";
export default function VideoPlayer({ videooptions, setvideooptions }) {
  const videoRef = useRef(null);
  var completeTime = 0;
  var position = 0;
  const firstRender = useRef(true);
  const cursec = useRef(0);
  const { sec } = useParams();

  const [videoResolution, setVideoResolution] = useState(null);


  // set dynamic size of video thumbnail
  const getResolution = (url) => {
    const video = document.createElement("video");
    video.src = url;
    video.addEventListener("loadedmetadata", () => {
      setVideoResolution({
        videoWidth: video.videoWidth,
        videoheight: video.videoHeight
      });
    });
  };

  useEffect(() => {
    const { videoid, sources, startTime, videoUrl, ...options } = videooptions;
    getResolution(videoUrl);
    if (videoRef) {
      const player = videojs(videoRef.current, options, () => {
        player.updateSrc(sources);
        player.on("seeking", () => {});
        player.on("seeked", function () {
          completeTime = Math.floor(player.currentTime());
          position = Math.floor(player.currentTime());
        });
        player.on("pause", () => {
          const pausetime = player.currentTime();
          authService
            .addpausevideobyadmin({
              id: videoid,
              data: { pause_time: pausetime }
            })
            .then((res) => {
              console.log(res);
            })
            .catch((e) => {
              console.log(e);
            });
        });
        player.on("play", () => {
          if (firstRender.current) {
            player.currentTime(
              cursec.current === 0 ? videooptions.startTime : cursec.current
            );
            firstRender.current = false;
          }
        });
        player.on("ended", () => {
          authService
            .addpausevideobyadmin({ id: videoid, data: { pause_time: 0 } })
            .then((res) => {
              console.log(res);
            })
            .catch((e) => {
              console.log(e);
            });
        });
      });
      player.getChild('controlBar').removeChild('captionsButton');
    }
    return () => {
      window.player && window.player.dispose();
    };
  });
  useEffect(() => {
    firstRender.current = true;
    cursec.current = sec;
  }, [sec]);


  return (
    <div
      className=""
      // style={customeStyle}
      onContextMenu={(e) => e.preventDefault()}
    >
      <video
        ref={videoRef}
        currentTime="15"
        className="video-js vjs-default-skin no-aspect-ratio"
        style={{ width: "100%", height: "100%" }}
      >
        <track
          kind="captions"
          src={
            videooptions.subtitle !== undefined &&
            videooptions.subtitle.length > 0
              ? "https://mds-community.s3.us-east-2.amazonaws.com/" +
                videooptions.subtitle
              : ""
          }
          srclang="en"
          label="English"
          default
        />
      </video>
    </div>
  );
}
