import React from "react";
import PropTypes from "prop-types";
import "./DropDownItem.css";
import "../../Styles/Theme/Theme.css";
import UserImg from "../../../src/Images/user.png";
import Avtar from "../Avtar/Avtar";

export default function DropDownItem({
  id,
  options = [],
  onSelect,
  size = "small",
  extraClass = "",
  ref,
  isIcon = false,
  selectedItem,
}) {  
  return (
    <ul className={`dropdown-list scrollbar no-border-scrollbar ${size} ${extraClass}`} ref={ref} id={id}>
      {options !== undefined &&
        options.length > 0 &&
        options.map((item, index) => (
          <li
            id={item._id}
            className={`${item.isError ? "error-item-li" : selectedItem !== undefined && selectedItem !== null && item && (Object.keys(selectedItem).length > 0) && selectedItem._id.toString() === item._id.toString() ? "dropdown-active" : ""}`}
            key={index}
            onMouseDown={(e) => {
              onSelect(item)}}
          >
            {isIcon ? (
              <>
                {item?.icon ? (
                  <>{item?.icon}</>
                ) : (
                  <Avtar
                    src={item?.profileImg}
                    size="24"
                    hasStatus={true}
                    badgeStatus={item?.onlineStatus ? "online" : "offline"}
                    type={item?.profileImg ? "photo" : "letter"}
                    letter={!item?.profileImg ? item.fullName[0] : ""} //show first latter of name
                  />
                )}
                {item.name || (item?.fullName ? item?.fullName : "Unknown")}
              </>
            ) : (
              <>{item.name || (item?.fullName ? item?.fullName : "Unknown")}</>
            )}
          </li>
        ))}
    </ul>
  );
}

DropDownItem.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      fullName: PropTypes.string,
    })
  ).isRequired,
  onSelect: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isIcon: PropTypes.bool,
};
