import React from "react";
import PropTypes from "prop-types";
import "./InputField.css";
import "../../Styles/Theme/Theme.css";
import CalendarIcon from "../Icons/GeneralIcons/CalendarIcon";
import Typography from "../Typography/Typography";

function TextField({
  label = "Label",
  hasLabel = true,
  hasRightImg = true,
  placeholder = "placeholder",
  className = "text-field",
  handleChange,
  name,
  value = "",
  handleKeyUp,
  helperText = "",
  isRounded = false,
  error = "",
  Image = CalendarIcon,
  readOnly = false,
}) {
  return (
    <>
      <div className="text-field-main">
        {hasLabel && (
            <Typography variant="h6">{label}</Typography>
        )}
        <div className={`text-field-box`}>
          <input
            readOnly={readOnly}
            className={`${className} ${isRounded ? "radius" : ""} ${
              error.length > 0 ? "text-field-error-border" : ""
            }`}
            placeholder={placeholder}
            type="text"
            value={value}
            name={name}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
          {(hasRightImg && 
          <Image />
          )}
        </div>
        {helperText && <span className="helper-text-field">{helperText}</span>}
        {error && <span className="text-field-error">{error}</span>}
      </div>
    </>
  );
}

// define types to all props
TextField.propTypes = {
  label: PropTypes.string,
  hasLabel: PropTypes.bool,
  hasRightImg: PropTypes.bool,
  className: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isRounded: PropTypes.bool,
  readOnly: PropTypes.bool,
  handleChange: PropTypes.func,
  handleKeyUp: PropTypes.func,
  Image: PropTypes.element,
  placeholder: PropTypes.string,
};

export default TextField;
