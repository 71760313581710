import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import EventTicketPurchaseHeading from "../../Components/HelperComponents/EventTicketPurchaseHeading";
import CalendarIcon from "../../../../components/Icons/GeneralIcons/CalendarIcon";
import EventTicketList from "./EventTicketList";
import { ReactComponent as CheckIcon } from "../../../../Images/icn_m_check_filled.svg";
import { InView } from "react-intersection-observer";
import moment from "moment";
import "../EventStyle/EventSelectTicket.css";
import EventThumbImage from "../../Components/HelperComponents/EventThumbImage";
import Button from "../../../../components/Button/Button";
import Separators from "../../../../components/Separators/Separators";
import EventSummaryTicket from "../../Components/HelperComponents/EventSummaryTicket";
import {
  useGetEventTicketForFrontendQuery,
  usePurchaseEventTicketMutation,
  usePurchaseEventTicketDetailsByEventIdQuery,
  useCancelOrderTicketingMutation,
} from "../../../../Services/event-ticket";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import ModelPopup from "../../../../components/ModalPopup/ModalPopup";
import NotificationBanner from "../../../Notifications/components/NotificationBanner";
import Header from "../../../../components/UserComponents/Dashboard/Header";

export default function EventSelectTicket({}) {
  const Navigate = useNavigate();
  const dispatch = useDispatch();

  let { eventId, eventType } = useParams();
  const [Loader, setLoader] = useState(true);
  const [ticketList, setTicketList] = useState([]);
  const [showEventData, setEventData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [pagination, setPagination] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isContinueBtn, setIsContinueBtn] = useState(false);
  const [totalPrice, setTotalPrice] = useState("0.00");
  const [isErrorMessage, setErrorMessage] = useState("");
  const [errorMessage, setErrorMsg] = useState("");
  const [minimumTicketErrorMsg, setMinimumTicketErrorMsg] = useState("");
  const [isOrderCancelPopup, setOrderCancelPopup] = useState(false);
  const [isCancleOrderLoader, setCancleOrderLoader] = useState(false);

  const SelectedEventData = useSelector((state) => {
    return state?.SelectedEventData;
  });
  // set price based on quantity
  useEffect(() => {
    const total = SelectedEventData.selectedTickets.reduce(
      (totalPrice, ticket) => {
        return totalPrice + ticket.quantity * ticket.price;
      },
      0
    );
    setTotalPrice(total);
  }, [SelectedEventData]);

  // API Calling For get purchase event ticket list of data
  const { data: eventTicketPurchase } =
    usePurchaseEventTicketDetailsByEventIdQuery({
      eventId: eventId,
    });

  useEffect(() => {
    if (eventTicketPurchase?.status) {
      setIsContinueBtn(true);
      dispatch({
        type: "ADD_SELECTED_TICKET_EVENT",
        payload: {
          id: eventTicketPurchase.data[0]?._id,
          name: eventTicketPurchase.data[0]?.ticketId?.name,
          quantity: eventTicketPurchase.data[0]?.purchaseQuantity,
          price: eventTicketPurchase.data[0]?.ticketValue,
          thumbnail: eventTicketPurchase.data[0]?.ticketId?.thumbnail,
          type: eventTicketPurchase.data[0]?.ticketId?.type
        },
      });
    } else {
      setIsContinueBtn(false);
    }
  }, [eventTicketPurchase]);

  // API Calling for ticket purchase
  const [purchaseEventTicket, { isError }] = usePurchaseEventTicketMutation();

  // API Calling For get ticket list of event
  const {
    data: ticketData,
    isLoading,
    isSuccess,
  } = useGetEventTicketForFrontendQuery({
    event_id: eventId,
    params: {
      page: currentPage,
      limit: rowsPerPage,
    },
  });
  
  // Ticket Listing data set in state
  useEffect(() => {
    fetchTicketList();
  }, [ticketData]);

  // get Ticket listing
  const fetchTicketList = async () => {
    try {
      if (ticketData?.status === true) {
        dispatch({
          type: "ADD_SELECTED_TICKET_EVENT",
          payload: {
            id: ticketData?.data?.ticketData[0]?._id,
            name: ticketData?.data?.ticketData[0]?.name,
            quantity: ticketData?.data?.ticketData[0]?.minimumTicket,
            price: ticketData?.data?.ticketData[0]?.basePrice,
            thumbnail: ticketData?.data?.ticketData[0]?.thumbnail,
            type: ticketData.data?.ticketData[0]?.type
          },
        });
        if (currentPage === 1) {
          setTicketList(ticketData.data.ticketData);
          setEventData(ticketData.data.eventData.eventId);
          setTimeout(() => {
            setLoader(false);
          }, 2000);
        } else {
          setTicketList((prevTicketList) => [
            ...prevTicketList,
            ...ticketData.data.ticketData,
          ]);
          setTimeout(() => {
            setLoader(false);
          }, 2000);
        }
        // Check if there are more tickets than currently fetched
        const totalTickets = ticketData.totalTicket;
        const fetchedTickets =
          (currentPage - 1) * rowsPerPage + ticketData.data.ticketData.length;
        setPagination(fetchedTickets < totalTickets);
      } else {
        if (ticketData?.status === false) {
          setLoader(false);
        }
        setPagination(false); // No more tickets available
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching data:", error);
    }
  };

  //handle click continue button
  const handleClickContinue = () => {
    if (SelectedEventData.selectedTickets?.length === 0) {
      setErrorMsg("Select at least 1 ticket");
      setTimeout(() => {
        setErrorMsg("");
      }, 2000);
    } else if (
      SelectedEventData?.selectedTickets[0].quantity <
      ticketList[0]?.minimumTicket
    ) {
      setMinimumTicketErrorMsg(
        `Select atleast ${ticketList[0]?.minimumTicket} quantity`
      );
      setTimeout(() => {
        setMinimumTicketErrorMsg("");
      }, 2000);
    } else {
      if (SelectedEventData?.selectedTickets[0].type !== "FREE")
        {
          setIsContinueBtn(true);
        }else
        {
            handleCompleteRegistrationClick()
        }
      
    }
  };

  // handleBack button click
  const handleBackClick = () => {
    if (isContinueBtn) {
      if (eventTicketPurchase?.status) {
        Navigate(`/event-details/${eventType}/${eventId}`);
      }
      setIsContinueBtn(false);
    } else {
      // Navigate to home-event
      dispatch({ type: "RESET_STATE" });
      Navigate(`/event-details/${eventType}/${eventId}`);
    }
  };

  // handle click on complete registration button click
  const handleCompleteRegistrationClick = async () => {
    setIsLoader(true);
    const bodyPayload = {
      eventId: eventId,
      ticketId: SelectedEventData?.selectedTickets[0]?.id,
      purchaseQuantity: SelectedEventData?.selectedTickets[0]?.quantity,
    };
    const createRes = await purchaseEventTicket(bodyPayload);
    if (createRes.data?.status) {
      setIsLoader(false);
      Navigate("/confirmationPage/purchase");
    } else {
      setIsLoader(false);
      setErrorMessage(createRes.data?.message);
    }
    setTimeout(() => {
      setErrorMessage("");
    }, 2000);
  };

  // handle empty state button click
  const handleEmptyState = () => {
    Navigate(`/event-details/${eventType}/${eventId}`);
  };
  //open order cancel popup
  const handleClickOrderCancellation = () => {
    setOrderCancelPopup(true);
  };
  //handle support button
  const handleClickSupport = () => {
    if (window.Intercom) {
      window.Intercom("show");
    }
  };

  //close order cancel popup
  const handleCancelModal = () => {
    setOrderCancelPopup(false);
  };

  // API Calling for order cancle for ticketing
  const [ticketOrderCancel, { error, status }] =
    useCancelOrderTicketingMutation();

  // handle cancel order buttoon click
  const handleCancleOrderButtonClick = async () => {
    try {
      setCancleOrderLoader(true);

      const cancelResponse = await ticketOrderCancel({
        ticketId: eventTicketPurchase.data[0]._id,
      });
      if (cancelResponse.data?.status) {
        setCancleOrderLoader(false);
        Navigate("/confirmationPage/cancel");
      } else {
        setOrderCancelPopup(false);
        setCancleOrderLoader(false);
        setErrorMessage(cancelResponse.data?.message);
      }
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
    } catch (error) {
      console.error("Error cancelling order:", error);
    } finally {
      setCancleOrderLoader(false);
    }
  };

  //closing guest ticket and delete modal while pressing esc
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && isOrderCancelPopup) {
        handleCancelModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOrderCancelPopup]);

  const formattedTimeConvert = (timeString) => {
    if (timeString) {
      const [time, period] = timeString.split(" ");
      const [hours, minutes] = time.split(":");
      const formattedTime = `${parseInt(
        hours,
        10
      )}:${minutes} ${period.toUpperCase()}`;
      return formattedTime;
    }
  };

  return (
    <>
      <Header />
      {Loader && (
        <>
          <div className="select-ticket-page">
            <div className="ticket-container">
              <div className="ticket-row">
                <div className="ticket-details">
                  <div style={{ width: "100%" }}>
                    <div
                      className="skeleton-box"
                      style={{ height: "20px", mb: "16px", width: "100%" }}
                    ></div>
                    <div
                      className="skeleton-box"
                      style={{ height: "40px", width: "100%" }}
                    ></div>
                    <div
                      className="skeleton-box"
                      style={{ height: "24px", mt: "16px", width: "100%" }}
                    ></div>
                  </div>
                  <div
                    className="skeleton-box"
                    style={{ height: "74px" }}
                  ></div>
                  <div
                    className="skeleton-box"
                    style={{ height: "124px" }}
                  ></div>
                  <Separators />
                  <div
                    className="skeleton-box"
                    style={{ height: "56px", width: "124px" }}
                  ></div>
                </div>
                <div
                  className="event-order-summary-wrapper skeleton-box"
                  style={{ height: "293px", marginTop: "48px" }}
                ></div>
              </div>
            </div>
          </div>
        </>
      )}
      {!Loader && !isLoading && ticketList?.length > 0 && (
        <div className="select-ticket-page">
          <div className="ticket-container">
            <div className="ticket-row">
              <div className="ticket-details">
                <div>
                  <EventTicketPurchaseHeading
                    title={
                      !isContinueBtn ? "Select ticket" : showEventData?.title
                    }
                    backToText={
                      !isContinueBtn ? "Event Details" : "Final details"
                    }
                    hasDates={
                      !isContinueBtn && showEventData?.startDate ? false : true
                    }
                    startDates={moment(
                      showEventData?.startDate,
                      "MM-DD"
                    ).format("MMM DD")}
                    startYear={
                      moment(showEventData?.endDate, "MM-DD-YYYY").diff(
                        moment(showEventData?.startDate, "MM-DD-YYYY"),
                        "days"
                      ) > 0
                        ? ""
                        : moment(showEventData?.startDate, "MM-DD-YYYY").format(
                            "YYYY"
                          )
                    }
                    endDates={
                      moment(showEventData?.endDate, "MM-DD").format("MMM DD") // If isContinueBtn is true, you can specify a default end date
                    }
                    endYear={
                      moment(showEventData?.endDate, "MM-DD-YYYY").format(
                        "YYYY"
                      ) // If isContinueBtn is true, you can specify a default end year
                    }
                    hasDatePeriod={
                      moment(showEventData?.endDate, "MM-DD-YYYY").diff(
                        moment(showEventData?.startDate, "MM-DD-YYYY"),
                        "days"
                      ) > 0
                        ? true
                        : false
                    }
                    hasStartYear={true}
                    handleBackClick={handleBackClick}
                  />
                </div>

                {!isContinueBtn && (
                  <>
                    <div className="ticket-event-date-wrapper">
                      <CalendarIcon
                        height={24}
                        iconColor="#060D14"
                        width={24}
                      />
                      {/* <div className="time-duration-wrapper">
                        <p>
                          {moment(
                            showEventData?.startDate,
                            "MM-DD-YYYY"
                          ).format("MMM DD, YYYY")}
                        </p>
                        <p className="event-time-duration">
                          , {showEventData?.startTime}
                        </p>
                      </div> */}

                      <div className="time-duration-wrapper">
                        {/* <p>
                          {moment(
                            showEventData?.startDate,
                            "MM-DD-YYYY"
                          ).format("MMM DD")}
                        </p>
                        {moment(showEventData?.endDate, "MM-DD-YYYY").diff(
                          moment(showEventData?.startDate, "MM-DD-YYYY"),
                          "days"
                        ) > 0 ? (
                          <>
                            <p>
                              {" "}
                              -{" "}
                              {moment(
                                showEventData?.endDate,
                                "MM-DD-YYYY"
                              ).format("MMM DD, YYYY")}
                            </p>
                            {moment(
                              showEventData?.startDate,
                              "MM-DD-YYYY"
                            ).format("YYYY") !==
                              moment(
                                showEventData?.endDate,
                                "MM-DD-YYYY"
                              ).format("YYYY") && (
                              <p>
                                ,{" "}
                                {moment(
                                  showEventData?.endDate,
                                  "MM-DD-YYYY"
                                ).format("YYYY")}
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <p>
                              {moment(
                                showEventData?.startDate,
                                "MM-DD-YYYY"
                              ).format(", YYYY")}
                            </p>
                            {moment(
                              showEventData?.startDate,
                              "MM-DD-YYYY"
                            ).isSame(
                              moment(showEventData?.endDate, "MM-DD-YYYY"),
                              "day"
                            ) ? (
                              <p style={{ textTransform: "uppercase" }}>
                                , {showEventData?.startTime} -{" "}
                                {showEventData?.endTime} (EST)
                              </p>
                            ) : null}
                          </>
                        )} */}
                        <p>
                          {moment(
                            showEventData?.startDate,
                            "MM-DD-YYYY"
                          ).format("MMM DD")}
                          {moment(
                            showEventData?.startDate,
                            "MM-DD-YYYY"
                          ).isSame(
                            moment(showEventData?.endDate, "MM-DD-YYYY"),
                            "day"
                          ) &&
                            `, ${moment(
                              showEventData?.startDate,
                              "MM-DD-YYYY"
                            ).format("YYYY")} ${formattedTimeConvert(
                              showEventData.startTime
                            )} -
                            ${formattedTimeConvert(showEventData.endTime)}`}
                          {moment(showEventData?.endDate, "MM-DD-YYYY").diff(
                            moment(showEventData?.startDate, "MM-DD-YYYY"),
                            "days"
                          ) > 0 && (
                            <>
                              {" "}
                              -{" "}
                              {moment(
                                showEventData?.endDate,
                                "MM-DD-YYYY"
                              ).format("MMM DD, YYYY")}
                            </>
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="ticket-event-list-wrapper">
                      {errorMessage && (
                        <span className="error-text">
                          <i className="fa fa-exclamation-circle"></i>
                          {errorMessage}
                        </span>
                      )}
                      <div className="ticket-event-list">
                        {ticketList?.length > 0 ? (
                          ticketList?.map((data, index) => {
                            if (index === ticketList?.length - 1) {
                              return (
                                <InView
                                  onChange={(inView, entry) => {
                                    if (inView && pagination && !isLoading) {
                                      setCurrentPage(currentPage + 1);
                                    }
                                  }}
                                  className={`w-100`}
                                >
                                  <EventTicketList
                                    key={data.id}
                                    ticketList={data}
                                    index={index}
                                    minimumTicketErrorMsg={
                                      minimumTicketErrorMsg
                                    }
                                  />
                                </InView>
                              );
                            } else {
                              return (
                                <EventTicketList
                                  key={data.id}
                                  ticketList={data}
                                  index={index}
                                  minimumTicketErrorMsg={minimumTicketErrorMsg}
                                />
                              );
                            }
                          })
                        ) : (
                          <div>No tickets available</div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {isContinueBtn && <Separators />}

                {isContinueBtn &&
                  SelectedEventData?.selectedTickets?.length > 0 &&
                  SelectedEventData?.selectedTickets.map((data) => {
                    return (
                      <EventSummaryTicket
                        title={data.name}
                        amount={data?.price?.toFixed(2)}
                        ThumbSrc={
                          data?.thumbnail
                            ? data?.thumbnail
                            : showEventData?.thumbnail
                            ? showEventData?.thumbnail
                            : ""
                        }
                        hasRemove={false}
                      />
                    );
                  })}
                <Separators />
                {isContinueBtn && (
                  <span>
                    By continuing, I agree to the &nbsp;
                    <a
                      href="/"
                      target="_blank"
                      style={{
                        font: "inherit",
                        color: "inherit",
                        textDecoration: "underline",
                      }}
                    >
                      Refund Policy
                    </a>
                    .
                  </span>
                )}

                {isContinueBtn && eventTicketPurchase?.status && (
                  <>
                    <div className="support-cancel-btn-wrapper">
                      <Button
                        variant="secondary w-fit"
                        label="Support"
                        handleClick={handleClickSupport}
                      />
                      <Button
                        variant="secondary w-fit"
                        label="Order cancellation"
                        handleClick={handleClickOrderCancellation}
                      />
                    </div>
                  </>
                )}

                {!isContinueBtn && (
                  <Button
                    variant="primary w-fit"
                    label={`${SelectedEventData?.selectedTickets[0].type === "FREE" ? "Complete registration" : "Continue"}`}
                    handleClick={handleClickContinue}
                  />
                )}
              </div>
              <div className="event-order-summary-wrapper">
                <div className="event-order-summary-box">
                  <div className="event-order-summary-details">
                    <EventThumbImage
                      ThumbSrc={
                        isContinueBtn
                          ? SelectedEventData.selectedTickets.map((data) =>
                              data?.thumbnail
                                ? data?.thumbnail
                                : showEventData?.thumbnail
                            )
                          : showEventData?.thumbnail
                      }
                      imgType="small"
                    />
                    <h3>{showEventData?.title}</h3>
                    <div className="">
                      <p>
                        {moment(showEventData?.startDate, "MM-DD-YYYY").format(
                          "MMM DD"
                        )}
                        {moment(showEventData?.startDate, "MM-DD-YYYY").isSame(
                          moment(showEventData?.endDate, "MM-DD-YYYY"),
                          "day"
                        ) &&
                          `, ${moment(
                            showEventData?.startDate,
                            "MM-DD-YYYY"
                          ).format("YYYY")} ${formattedTimeConvert(
                            showEventData.startTime
                          )} -
                          ${formattedTimeConvert(showEventData.endTime)}`}
                        {moment(showEventData?.endDate, "MM-DD-YYYY").diff(
                          moment(showEventData?.startDate, "MM-DD-YYYY"),
                          "days"
                        ) > 0 && (
                          <>
                            {" "}
                            -{" "}
                            {moment(
                              showEventData?.endDate,
                              "MM-DD-YYYY"
                            ).format("MMM DD, YYYY")}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  {SelectedEventData.selectedTickets?.length > 0 && (
                    <>
                      <Separators />
                      <div className="event-order-summary-list">
                        {SelectedEventData.selectedTickets?.map(
                          (data, index) => {
                            return (
                              <div
                                className="order-summary-list-item"
                                key={index}
                              >
                                <p className="order-summary-list-item-name">
                                  {data.quantity} x {data.name}
                                </p>
                                <p className="order-summary-list-item-price">
                                  ${data?.price?.toFixed(2)}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}
                  <Separators />
                  <div className="event-order-summary-total">
                    <h4>Total (USD)</h4>
                    <h4>${totalPrice.toFixed(2)}</h4>
                  </div>
                </div>
                {isContinueBtn && eventTicketPurchase?.status && (
                  <NotificationBanner
                    icon={<CheckIcon />}
                    text="Purchased"
                    variant="success"
                    actionText="Details"
                    hasActionText={false}
                    hasIcon={true}
                  />
                  // <NotificationBanner text="Purchased" variant="success" />
                  // <p style={{ color: "black" }}>Purchased</p>
                )}
                {isContinueBtn && !eventTicketPurchase?.status && (
                  <Button
                    variant="primary"
                    label="Complete registration"
                    handleClick={handleCompleteRegistrationClick}
                    spinnersType="white"
                    hasLoading={isLoader}
                    disabled={isLoader ? true : false}
                  />
                )}
                {isErrorMessage && (
                  <p style={{ color: "red" }}>{isErrorMessage}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!Loader && !isLoading && ticketList.length === 0 && (
        <div className="center-box">
          <EmptyState
            onClick={handleEmptyState}
            subTitle="Please select any other event to purchase ticket"
            title="No any ticket for this event"
            label="Go to my events"
          />
        </div>
      )}
      <ModelPopup
        handleClick2ndButton={handleCancleOrderButtonClick}
        onClose={handleCancelModal}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        isOpen={isOrderCancelPopup}
        headingLable="Cancel order?"
        hasCross={true}
        hasContentCenter={true}
        disabled={isCancleOrderLoader ? true : false}
        buttonText="Keep order"
        buttonText1="Cancel order"
        buttonType="twobuttons"
        spinnersType="white"
        btn2variant="danger"
        hasLoading={isCancleOrderLoader}
      >
        <div class="cstm-modal-body cancel-order-body">
          <h3>What's included</h3>
          <div className="event-summary-ticket-item">
            <EventThumbImage
              ThumbSrc={
                eventTicketPurchase?.status
                  ? eventTicketPurchase?.data[0]?.ticketId.thumbnail
                    ? eventTicketPurchase?.data[0]?.ticketId.thumbnail
                    : showEventData?.thumbnail
                  : showEventData?.thumbnail
              }
              imgType="x-small"
              extraClass={"event-summary-ticket-image"}
            />
            <div className="event-summary-ticket-text-wrapper">
              <div className="event-summary-ticket-title-wrapper flex justify-content-between">
                <p className="event-summary-ticket-title text-ellipsis">
                  {eventTicketPurchase?.status &&
                    eventTicketPurchase?.data[0]?.ticketId.name}
                </p>
              </div>
              <p className="event-summary-ticket-amount">
                $
                {eventTicketPurchase?.status &&
                  eventTicketPurchase?.data[0].ticketValue.toFixed(2)}
              </p>
            </div>
          </div>
          <Separators />
          <div>
            <div className="total-refun-text d-flex align-items-baseline justify-content-between mb-1">
              <h3>Total refunds (USD)</h3>
              <h3>
                $
                {eventTicketPurchase?.status &&
                  eventTicketPurchase?.data[0].totalPrice.toFixed(2)}
              </h3>
            </div>
            <span className="refund-text gray-p-text">
              For further information, kindly refer to our&nbsp;
              <a
                href="/"
                target="_blank"
                style={{
                  font: "inherit",
                  color: "inherit",
                  textDecoration: "underline",
                }}
              >
                Refund Policy
              </a>
              .
            </span>
          </div>
          <NotificationBanner
            text={
              <>
                The funds will be returned to your wallet. Approximate return
                time is <b> 20 days</b>.
              </>
            }
            variant="warning"
            hasIcon={true}
          />
        </div>
      </ModelPopup>
    </>
  );
}
