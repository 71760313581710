
const initialState = {
  muteChatIdList: [],
};

const muteNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATEMUTESTATE':
      return action.payload
    case 'LOGOUT':
      return {};
    default:
      return state;
  }
};

export default muteNotificationReducer;