import React from "react";
import IconSearchNoResults from "../GlobalComponents/Icons/IconEmptyState/IconSearchNoResults";
import "./EmptyState.css";
import "../../Styles/Theme/Theme.css";
import Button from "../Button/Button";
import PropTypes from "prop-types";
const EmptyState = ({
  extraClass = "",
  onClick,
  title = "",
  subTitle = "",
  icon = <IconSearchNoResults />,
  label = "Clear all filters",
  isClear = true,
  hasIcon = true,
  hasTitle = true,
  hasSubTitle = true,
  hasInfo = true,
}) => {
  return (
    <>
      <div className={`empty-state ${extraClass}`}>
        {hasIcon && <div className="empty-state-icon">{icon}</div>}
        {hasInfo && (
          <div className="empty-state-info">
            {hasTitle && <h4 className="empty-state-info-title">{title}</h4>}
            {hasSubTitle && <p className="empty-state-info-p">{subTitle}</p>}
          </div>
        )}
        {isClear && (
          <Button
            label={label}
            handleClick={onClick}
            size="medium"
            variant="secondary"
          />
        )}
      </div>
    </>
  );
};
EmptyState.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  isClear: PropTypes.bool,
  hasIcon: PropTypes.bool,
  hasTitle: PropTypes.bool,
  hasSubTitle: PropTypes.bool,
  hasInfo: PropTypes.bool,
};
export default EmptyState;
