import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import RadioButton from "../../../components/RadioButton/RadioButton";
import Typography from "../../../components/Typography/Typography";
import ModelPopup from "../../../components/ModalPopup/ModalPopup";
import authService from "../../../Services/auth.service";
import { getMuteChatIdListForUser } from "../../../Action";


export default function MuteChatModal({ isModalOpen, setIsModalOpen, userid }) {
    // const { userid } = useParams();
    const dispatch = useDispatch();
    const [selectedMuteOption, setSelectedMuteOption] = useState(null);

    // Get notification list common function
    const getMuteNotificationList = async () => {
        dispatch(getMuteChatIdListForUser());
    };

    //handle mute radio button change
    const handleRadioChange = (value) => {
        setSelectedMuteOption(value);
    };

    //close mute notification modal box
    const handleonClickMuteNotificationCancel = () => {
        setIsModalOpen(false);
        setSelectedMuteOption(null);
    };

    //handle chat mute notification
    const handleMuteChat = async () => {
        // Calculate UTCDateTime based on selectedMuteOption
        let utcDateTime = null;
        if (selectedMuteOption !== "until") {
            const currentDateTime = new Date();
            const muteDuration = parseInt(selectedMuteOption, 10); // Assuming selectedMuteOption is in minutes
            currentDateTime.setUTCMinutes(
                currentDateTime.getUTCMinutes() + muteDuration
            );
            utcDateTime = currentDateTime.toISOString();
        }

        // Prepare payload
        const payload = {
            chatId: userid,
            unMute: false,
            dateTime: utcDateTime,
            noLimit: selectedMuteOption === "until" ? true : false,
        };
        // Call the API with the payload
        try {
            authService
                .muteChatForAll(payload)
                .then((response) => {
                    if (response.data.status) {
                        getMuteNotificationList();
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        } catch (error) {
            console.error("Error muting chat:", error);
        }
        // Close the modal
        setIsModalOpen(false);
    };
    return (
        <ModelPopup
            onClose={handleonClickMuteNotificationCancel}
            handleClick2ndButton={handleMuteChat}
            isFooter={true}
            isHeader={false}
            modelSize="480"
            disabled={!selectedMuteOption}
            isOpen={isModalOpen}
            buttonText="Cancel"
            buttonText1="Mute"
            buttonType="twobuttons"
        >
            <div className="mute-notification-data">
                <Typography variant="h3">Mute this chat?</Typography>
                <div className="mute-notification-data-list">
                    <RadioButton
                        checked={selectedMuteOption === "15"}
                        hasLabel={true}
                        id="15"
                        label="For 15 minutes"
                        onChange={() => handleRadioChange("15")}
                    />
                    <RadioButton
                        checked={selectedMuteOption === "60"}
                        hasLabel={true}
                        id="60"
                        label="For 1 hour"
                        onChange={() => handleRadioChange("60")}
                    />
                    <RadioButton
                        checked={selectedMuteOption === "480"}
                        hasLabel={true}
                        id="480"
                        label="For 8 hours"
                        onChange={() => handleRadioChange("480")}
                    />
                    <RadioButton
                        checked={selectedMuteOption === "1440"}
                        hasLabel={true}
                        id="1440"
                        label="For 24 hours"
                        onChange={() => handleRadioChange("1440")}
                    />
                    <RadioButton
                        checked={selectedMuteOption === "until"}
                        hasLabel={true}
                        id="until"
                        label="Until I change it"
                        onChange={() => handleRadioChange("until")}
                    />
                </div>
            </div>
        </ModelPopup>
    );
}
