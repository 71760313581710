import React from "react";

function IconNavMdsExtras() {
  return (
    <div className="mds-icons">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.25 10C11.25 9.58579 10.9142 9.25 10.5 9.25C10.0858 9.25 9.75 9.58579 9.75 10V12.75H7C6.58579 12.75 6.25 13.0858 6.25 13.5C6.25 13.9142 6.58579 14.25 7 14.25H9.75V17C9.75 17.4142 10.0858 17.75 10.5 17.75C10.9142 17.75 11.25 17.4142 11.25 17V14.25H14C14.4142 14.25 14.75 13.9142 14.75 13.5C14.75 13.0858 14.4142 12.75 14 12.75H11.25V10Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22 15.5C22 17.274 20.6802 18.7398 18.9689 18.9689C18.7397 20.6802 17.274 22 15.5 22H5.5C3.567 22 2 20.433 2 18.5V8.5C2 6.72599 3.31983 5.26025 5.03113 5.03113C5.26024 3.31983 6.72599 2 8.49999 2H18.5C20.433 2 22 3.567 22 5.5V15.5ZM8.49999 3.5H18.5C19.6046 3.5 20.5 4.39543 20.5 5.5V15.5C20.5 16.4319 19.8626 17.215 19 17.437L19 8.5C19 6.567 17.433 5 15.5 5H6.563C6.78502 4.13739 7.56807 3.5 8.49999 3.5ZM15.5 6.5H5.5C4.39543 6.5 3.5 7.39543 3.5 8.5V18.5C3.5 19.6046 4.39543 20.5 5.5 20.5H15.5C16.6046 20.5 17.5 19.6046 17.5 18.5V8.5C17.5 7.39543 16.6046 6.5 15.5 6.5Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default IconNavMdsExtras;
