import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getEventTicketForFrontend: builder.query({
      query: ({ event_id, params }) => {
        return {
          url: ApiRoutes.eventTicket.getEventTicketForFrontend.url.replace(
            ":event_id",
            event_id
          ),
          method: ApiRoutes.eventTicket.getEventTicketForFrontend.method,
          params,
        };
      },
      providesTags: ["GETEVENTICKETS"],
      transformResponse: (response) => response,
    }),
    purchaseEventTicket: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.eventTicket.purchaseEventTicket.url,
          method: ApiRoutes.eventTicket.purchaseEventTicket.method,
          body,
        };
      },
      invalidatesTags: ["GETEVENTTAG"],
      transformResponse: (response) => response,
    }),
    purchaseEventTicketDetailsByEventId: builder.query({
      query: ({ eventId, params }) => {
        return {
          url: ApiRoutes.eventTicket.purchaseEventTicketDetailsByEventId.url.replace(
            ":eventId",
            eventId
          ),
          method:
            ApiRoutes.eventTicket.purchaseEventTicketDetailsByEventId.method,
          params,
        };
      },
      providesTags: ["GETEVENTTAG"],
      transformResponse: (response) => response,
    }),
    cancelOrderTicketing: builder.mutation({
      query: ({ ticketId }) => {
        return {
          url: ApiRoutes.eventTicket.cancelOrderTicketing.url.replace(
            ":userTicketId",
            ticketId
          ),
          method: ApiRoutes.eventTicket.cancelOrderTicketing.method,
        };
      },
      invalidatesTags: ["GETEVENTTAG"],
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useCancelOrderTicketingMutation,
  usePurchaseEventTicketDetailsByEventIdQuery,
  usePurchaseEventTicketMutation,
  useGetEventTicketForFrontendQuery,
} = extendedApi;
