import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
//import api file
import Services from "../../Services/auth.service";
//import component

import edit from "../../Images/BackendImages/edit.svg";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import AdminSidebar from "../AdminSidebar";

const columns = [
  
  
  { id: "email", label: "Email" },
  { id: "subject", label: "Subject" },
  { id: "message", label: "Message" },
  
];

function createData(
  email,
  subject,
  message,
  action
) {
  return {
    email,
    subject,
    message
  
  };
}
const ContactUserlisting = () => {
  const [rows, setrow] = useState([]);
  const [page, setPage] = useState(0);
  const [tblalluser, settblalluser] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectedUid, setselectedUid] = useState("");
  const [searchText, setsearchText] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [contactusers, setcontactusers] = useState([])
  let history = useNavigate();
  useEffect(() => {

    Services.getcontactuserslist().then((res)=>{
      
      
      setcontactusers(res.data.data)
      
     
     
    }) .catch((e) => {
    
      return false;
    });
      
  
  }, []);

  useEffect(()=>{
    if (contactusers.length > 0) {
      
      var newarr = [];
      var arr = [...contactusers];
      for (var i = 0; i < arr.length; i++) {
        
        newarr[i] = createData(

          arr[i].email,
          arr[i].subject,
          arr[i].message,
        
        );
      }
      settblalluser(newarr);
      setrow(newarr);
    }
  },[contactusers])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleUpdate = (value) => {
    // setselectedUserid(value);
    // setselectedUid(value);
    // setselectedDiv("edit");
  };

  const handleDelete = () => {
    // Services.deactivateuser({ id: selectedUid })
    //   .then((res) => {
    //     Services.getallusers()
    //       .then((response) => {
            
    //         setshowalert(true);
    //         setalertmsg("Successfully deactivated user!");
    //         setTimeout(() => {
    //           setshowalert(false);
    //         }, 2000);
    //         setcontactusers(response.data);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

 

  const handleSearch = () => {
    if (tblalluser.length > 0) {
      var arr = tblalluser.filter((u) => {
        if (
          (u.email !== undefined &&
            u.email !== undefined &&
            (u.email.toLowerCase().includes(searchText.toLowerCase()) ||
              u.email.toLowerCase().includes(searchText.toLowerCase()))) ||
          u.email.toLowerCase().includes(searchText.toLowerCase())
        )
          return u;
      });
      setrow(arr);
    }
  };
  const handleSort = (col, direc) => {
    var normal_arr = tblalluser;
    var arr = [...tblalluser];
    if (direc === "asc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (direc === "desc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else {
      arr = normal_arr;
    }
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].email,
        arr[i].subject,
        arr[i].message,
      
      );
    }
    setrow(tbl);
  };
  
  return (
    <>
        <div className="cstm-all-admin">
                  <AdminSidebar />
                  <div className="cstm-contact">
      <div className="users-info-content">
        <div className="users-info-content-main">
          {showalert && <div className="alert alert-info">{alertmsg}</div>}
          <div className="uic-title">
            <h1>Contact Users</h1>
          </div>

          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search user"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setsearchText(e.target.value);
                }}
              />
              <i className="fa fa-search"></i>
            </div>
            <div className="uic-fl-btn">
              <button className="btn" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>

          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={
                            "uic-th-td w-10"
                        }
                      >
                        {column.id !== "action" ? (
                          <>
                            <span className="custom-sorting">
                              <i
                                className="fa fa-angle-down"
                                onClick={(e) => {
                                  handleSort(column.id, "desc");
                                }}
                              ></i>
                              <i
                                className="fa fa-angle-up"
                                onClick={(e) => {
                                  handleSort(column.id, "asc");
                                }}
                              ></i>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="uic-table-td">
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                          className="uic-th-td w-20"
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return column.id !== "action" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-10"
                              >
                                {value}
                              </TableCell>
                            ) : 
                             (
                             <></>
                            ) 
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {rows.length === 0 ? (
                <div className="admin-no-found">
                  <p>No Data Found </p>
                </div>
              ) : (
                <></>
              )}{" "}
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      </div>          
      </div>

      {/* Modal Delete */}
      <div
        className="modal fade msg-popup"
        id="Delete"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgbigdelete} />
            </div>
            <h1>Are you sure you want to delete this user?</h1>
            <div className="row">
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={handleDelete}
                    data-dismiss="modal"
                  >
                    Delete
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="uic-fl-btn">
                  <button type="button" className="btn" data-dismiss="modal">
                    Cancel
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

  
    </>
  );
};
export default ContactUserlisting;
