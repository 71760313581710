import React from 'react'
import Logo from "../../../Images/logo_mds_small.svg";
import Successful from "../../../Images/Successful.svg";


const CollaboratorSuccess = () => {
  return (

    <>

    <div className="header-collaborator"><a href='#'><img src={Logo} /></a></div>
    
    <div className="collaborator-main">
          <div className="collaborator-emailicon"><img src={Successful} /></div>

          <div className="collaborator-succesfully">
          <div className="collaborator-info">
                    <h2 className='collaborator-h2'>Congrats, you're in!</h2>
                    <h6 className='collaborator-h6'>You've successfully joined MDS! Enjoy the app.</h6>
                </div>
            <div className='btn-log-in-to-mds'><a className='btn-log' href="/">Log in</a></div>
           </div>
    </div>

    </>


  )
}

export default CollaboratorSuccess