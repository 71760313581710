import React from 'react'
import './Badges.css'
import "../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"
const BadgesControl = ({ size = "status-medium", color = "accent-yellow", rounded = false, label = "label", extraClass = "" }) => {
    return (
        <span className={`status-badge ${size} ${rounded ? "status-radius-full" : "status-radius-small"} ${color} ${extraClass}`}>{label}</span>
    )
}

BadgesControl.propTypes = {
    size: PropTypes.oneOf(["status-medium", "status-small"]),
    color: PropTypes.oneOf(["accent-pink", "accent-yellow", "blackout", "brand", "danger", "neutral", "success", "warning", "white"]),
    rounded: PropTypes.bool,
    label: PropTypes.string
}

export default BadgesControl