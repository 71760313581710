import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import authServiceDocument from "../../../../Services/auth.service.document";
import Loader from "../../../../Images/loader.gif";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import exportImg from "../../../../Images/exportimg.svg"
import ExportSelect from '../../PartnerManagement/PartnerModals/ExportSelect';
import moment from 'moment';

const DocumentModal = ({
  setViewModalOpen,
  userRowsData,
  setUserRowsData,
  userColumns,
  userId,
  exportFileName,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [selectError, setSelectError] = useState('');
  const [error, setError] = useState(false);
  // Pagination onChange function
  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage);
  };

  // Table data shorting function
  const handleSort = (col, direc) => {
    const sortedResult = [...userRowsData];
    if (direc === "asc") {
      sortedResult.sort((firstItem, secondItem) => {
        const itemA = firstItem[col];
        const itemB = secondItem[col];
        return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
      });
    } else if (direc === "desc") {
      sortedResult.sort((firstItem, secondItem) => {
        const itemA = firstItem[col];
        const itemB = secondItem[col];
        return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
      });
    }

    setUserRowsData(sortedResult);
  };


  // Export for download xlsx file
  const handleExport = () => {
    const workbook_ = XLSX.utils.book_new(); // create new workbook
    if (selectRow.length > 0) {
      const modifiedData = selectRow.map((item) => ({
        FileName: item.fileName,
        DownloadCount: item.downloadCount
      }));
      const convertToexcel = XLSX.utils.json_to_sheet(modifiedData); // convert modified JSON data to sheet
      XLSX.utils.book_append_sheet(workbook_, convertToexcel, "Sheet2"); // add the sheet to the workbook
      const downloadExcel = XLSX.write(workbook_, {
        bookType: "xlsx",
        type: "array",
      });
      const dataExcel = new Blob([downloadExcel], { type: fileType });

      FileSaver.saveAs(dataExcel, exportFileName);
    } else {
      setError(true);
      setSelectError("Please select document to export")
      setTimeout(() => {
        setError(false)
        setSelectError("")
      }, 2000)
    }
  }


  return (
    <>
      <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
        <div className="desk-globle-model-box-inner">
          <div className="modal-content">
            <div class="cstm-replace-pp">


              <div className="cstm-hdr">
                <span
                  class="cstm-rpp-close-btn"
                  onClick={() => {
                    setViewModalOpen(false);
                    document.body.classList.remove("modal-open");
                  }}
                >
                  ×
                </span>
                <div class="cstm-rpp-title">
                  Total number of document downloads based on users
                </div>
              </div>
              <div className="cstm-export-link">
                <a onClick={handleExport}> <img src={exportImg} />Export</a>
              </div>

              <div className="cstm-cstm-viewlist">

                {error && <div className="alert alert-info">{selectError}</div>}

                <Paper className="uic-tb-mn ">
                  <TableContainer className=" uic-table-main ">
                    <Table className="">
                      <TableHead className="uic-table-th">
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              className="cstm-ls-checkbox"
                              color="primary"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const newSelectedids = userRowsData.map((row) => row.documentId);
                                  const newSelectedRow = userRowsData.map((row) => row);
                                  setSelected(newSelectedids)
                                  setSelectRow(newSelectedRow)
                                  setSelectError("")
                                }else
                                {
                                  setSelected([])
                                  setSelectRow([])
                                  setSelectError("")
                                }
                              }}
                              inputProps={{
                                'aria-label': 'select all desserts',
                              }}
                            />
                          </TableCell>
                          {userColumns.map((column) => (
                            <TableCell className={`uic-th-td w-${column.width}`} key={column.id}>
                              {" "}
                              <span className="custom-sorting">
                                <i
                                  className="fa fa-angle-down"
                                  onClick={(e) => {
                                    handleSort(column.id, "asc");
                                  }}
                                ></i>
                                <i
                                  className="fa fa-angle-up"
                                  onClick={(e) => {
                                    handleSort(column.id, "desc");
                                  }}
                                ></i>
                              </span>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className="uic-table-td">
                        {userRowsData.length > 0 ? (
                          userRowsData
                            .slice(
                              userPage * userRowsPerPage,
                              userPage * userRowsPerPage + userRowsPerPage
                            )
                            .map((row, index) => {
                              return (
                                <TableRow key={row.documentId} role="checkbox"
                                  tabIndex={-1}>
                                  <TableCell padding="checkbox">
                                    <ExportSelect
                                      id={row.documentId}
                                      row={row}
                                      selected={selected}
                                      setSelected={setSelected}
                                      selectRow={selectRow}
                                      setSelectRow={setSelectRow}
                                      setSelectError={setSelectError}

                                    />
                                  </TableCell>

                                  {userColumns.map((column) =>
                                  (
                                    <TableCell className="uic-th-td">
                                      {row[column.id]}
                                    </TableCell>

                                  )
                                  )}
                                </TableRow>
                              );
                            })
                        ) : (
                          <span className="no-data-found-pl">
                            No data found
                          </span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={userRowsData.length}
                    rowsPerPage={userRowsPerPage}
                    page={userPage}
                    onPageChange={handleChangeUserPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentModal;
