import React from "react";

const FilterIconAlt = ({ width = 24, height = 24, iconColor = "#000" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <path
        fill={iconColor}
        fillRule="evenodd"
        d="M3.333 5.208a.625.625 0 0 0 0 1.25h13.334a.625.625 0 1 0 0-1.25H3.333ZM5.208 10c0-.345.28-.625.625-.625h8.334a.625.625 0 1 1 0 1.25H5.833A.625.625 0 0 1 5.208 10Zm1.667 4.167c0-.345.28-.625.625-.625h5a.625.625 0 1 1 0 1.25h-5a.625.625 0 0 1-.625-.625Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default FilterIconAlt;
