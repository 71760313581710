import React from "react";
import "./Styles/ChatDetailsEdit.css";
import PropTypes from "prop-types";
import "../../../Styles/Theme/Theme.css";
import "../../../Styles/frontendGlobal.css";
import "./Styles/ChatDetailsMembers.css";
import AvtarLarge from "../../../components/AvtarLarge/AvtarLarge";
import Typography from "../../../components/Typography/Typography";
import AddUserIcon from "../../../components/Icons/GeneralIcons/AddUserIcon";
import Button from "../../../components/Button/Button";
import TextField from "../../../components/InputField/TextField";
import ModalFooter from "../../../components/ModalPopup/ModalFooter/ModalFooter";
import ArrowleftIcon from "../../../components/Icons/ArrowsIcon/ArrowleftIcon";

const ChatDetailsEdit = ({
  isAdmin = false,
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  letter = "W",
  handleButtonClick,
  handleImageUpload,
  handleChangeInput,
  groupName,
  onClickBackButton,
  onClickEditSave,
  isLoader,
}) => {
  return (
    <>
      <div className="chat-details-edit-main">
        <div className="chat-details-members-heading">
          <div className="chat-details-members-heading-title">
            <Button
              iconColor="#060D14"
              label=""
              size="small"
              variant="ghost"
              elementIcon={<ArrowleftIcon height={24} width={24} />}
              hasText={false}
              toolTipText="Back"
              handleClick={onClickBackButton}
            />
            <Typography variant="h4">Edit group</Typography>
          </div>
        </div>

        <div className="chat-details-edit-info">
          <div className="chat-details-edit-info-avtar">
            <AvtarLarge
              size="48"
              src={src}
              type={src && src.length ? "photo" : "empty"}
              letter={letter}
              hasEdit={src && src.length ? true : false}
              onImageUpload={handleImageUpload}
            />
          </div>
          {isAdmin && (
            <div className="chat-details-edit-add-member">
              <Button
                hasIconLeft={true}
                label="Add Member"
                size="small"
                variant="secondary"
                elementIcon={
                  <AddUserIcon height={24} iconColor="#000" width={24} />
                }
                handleClick={handleButtonClick}
              />
            </div>
          )}
          <div className="chat-details-edit-info-field">
            <TextField
              className="text-field"
              handleChange={(e) => handleChangeInput(e)}
              isRounded={true}
              value={groupName}
              readOnly={false}
              hasLabel={false}
              hasRightImg={false}
              placeholder="Group name"
            />
          </div>
        </div>

        <ModalFooter
          type="twobuttons"
          hasText={false}
          label="Cancel"
          label2="Save"
          btn2variant="primary"
          disabled={isLoader ? true : false}
          handleClick1stButton={onClickBackButton}
          handleClick2ndButton={onClickEditSave}
          spinnersType="white"
          hasLoading={isLoader ? true : false}
        ></ModalFooter>
      </div>
    </>
  );
};

ChatDetailsEdit.propTypes = {
  type: PropTypes.oneOf(["Channel", "Group", "Message"]),
  isAdmin: PropTypes.bool,
  src: PropTypes.string,
  notificationTitle: PropTypes.string,
  notificationText: PropTypes.string,
  membersTitle: PropTypes.string,
  membersText: PropTypes.string,
  mediaTitle: PropTypes.string,
  mediaText: PropTypes.string,
  channelOrGroupNameOrName: PropTypes.string,
  membersCount: PropTypes.string,
  dateTime: PropTypes.string,
  hasStatus: PropTypes.bool,
  isOnline: PropTypes.string,
  handleClick: PropTypes.func,
  handleButtonClick: PropTypes.func,
  onClickMemberDetails: PropTypes.func,
  onClickNotification: PropTypes.func,
  handleImageUpload: PropTypes.func,
  onClickEditSave: PropTypes.func,
  handleChangeInput: PropTypes.func,
};

export default ChatDetailsEdit;
