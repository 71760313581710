import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import noMediaFound from "../../../Images/NoVideo.svg";
import ListingVideoComponent from "./ListingVideoComponent";
import VideoHistory from "./VideoHistory";

import moment from "moment";
import millify from "millify";
import authService from "../../../Services/auth.service";

import videoPlayericons from "../../../Images/video-player-icons.svg";
import videodummy from "../../../Images/app_logo.png";
import myvideoscl from "../../../Images/MyVideosCL.svg";
import myvideosclh from "../../../Images/MyVideosCLH.svg";
import historycl from "../../../Images/HistoryCL.svg";
import historyclh from "../../../Images/HistoryCLH.svg";
import timecirclecl from "../../../Images/TimeCircleCL.svg";
import timecircleclh from "../../../Images/TimeCircleCLH.svg";
import { useEffect } from "react";
import { fetchsavedvideos } from "../../../Action";

import { retrivehistoryvideos } from "../../../Action/index1";
import { getVideoProgressPercentage } from "../../checktoken";
import sepbullet from "../../../Images/separator-bullet.svg";
const MyVideos = () => {
    const { action } = useParams();
    const history = useNavigate();
    const dispatch = useDispatch();

    const savedvideos = useSelector((state) => {
        return state.ReduSavedVideos
    })
    const historyvideos = useSelector((state) => {
        return state.ReduHistoryVideos
    })

    const savedvideoloader = useSelector((state) => {
        return state.ReduSavedVideoLoader
    })

    const historyvideoloader = useSelector((state) => {
        return state.ReduHistoryVideoLoader
    })

    useEffect(() => {
        dispatch(fetchsavedvideos());
        dispatch(retrivehistoryvideos({ page: 1, limit: 20 }))
    }, [])
    return (

        <div className="cstm-content-archive-main cstm-my-videos-only">

            {historyvideos.length > 0 ? (<>
                <div className="cstm-history-top">
                    <div className="cstm-history-top-title">
                        <img src={historycl} />  History
                    </div>
                    {historyvideos.length > 10 && <div className="cstm-history-top-seeall" onClick={() => {
                        history(`/videos/history`);

                    }}>See all</div>}
                </div>
                {historyvideoloader ? (
                    <div className="cstm-cl-mv-ldr">
                        {[...Array(20)].map((el, index) => (
                            <div className="cstm-cl-mv-ldr-main">
                                <div className="cstm-cl-mv-ldr-video skeleton-box"></div>
                                <div className="cstm-cl-mv-ldr-title skeleton-box"></div>
                                <div className="cstm-cl-mv-ldr-time skeleton-box"></div>
                                <div className="cstm-cl-mv-ldr-view skeleton-box"></div>
                            </div>
                        ))}
                    </div>
                ) :
                    <div className="cstm-cv-video">


                        {historyvideos.length > 0 && historyvideos.map((convideo, index) => {
                            if (index <= 9) {

                                return (<Link to={`/videos/${convideo.video_id}}`}>
                                    <div
                                        className="cstm-cv-video-in"
                                        onClick={async () => {
                                            var res = await authService.addviewbyuser(convideo.video_id, 'web');


                                        }}
                                    >
                                        <div className="gallery-img">
                                            <div
                                                className="cstm-gallery-img cstm-gallery-img-main "
                                                style={{
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <img className="video-js vjs-default-skin" src={convideo.archive_video.thumbnail !== undefined && convideo.archive_video.thumbnail !== null && convideo.archive_video.thumbnail.length > 0
                                                    ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                                    convideo.archive_video.thumbnail
                                                    : videodummy} />
                                                <img
                                                    className="cstm-videoPlayericons"
                                                    data-src={videoPlayericons}
                                                    alt="videoPlayericons"
                                                />
                                                {convideo.archive_video.duration !== undefined && convideo.archive_video.duration !== null ? <div class="cstm-rv-time">{convideo.archive_video.duration}</div> : <></>}

                                                {

                                                    getVideoProgressPercentage(convideo.archive_video) !== 0 &&
                                                    <div className="video_progress_wrapper">

                                                        <div className="video_progress_bar" style={{ width: `${getVideoProgressPercentage(convideo.archive_video)}%` }} >

                                                        </div>
                                                    </div>
                                                }


                                            </div>

                                        </div>

                                        <div className="cstm-gle">
                                            <p className="gallery-content1 cstm-ttl2" >
                                                {convideo.archive_video.title}
                                            </p>
                                            <p className="gallery-content2">
                                                {convideo.archive_video.categories[0].name}
                                            </p>
                                            <div className="icon-box">
                                                <a
                                                    className="gallery-main-icon"
                                                ><span>{moment(convideo.history_date).fromNow()}
                                                    </span></a><span className="cstm-bullet"><img src={sepbullet} /></span><a
                                                        className="gallery-main-icon"
                                                    ><span>
                                                        {convideo.archive_video.viewsCount !== undefined &&
                                                            convideo.archive_video.viewsCount > 0
                                                            ? `${millify(convideo.archive_video.viewsCount)} ${convideo.archive_video.viewsCount > 1 ? "Views" : "View"}`
                                                            : "0 Views"}
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Link>);
                            }

                        })}


                    </div>
                }
            </>) : <></>}



            {savedvideos.length > 0 ? (<>
                {(historyvideos.length > 0) ?
                    <hr className="mv-line" /> : <></>}
                <div className="cstm-content-archive-savedvideos-main">


                    <div className="cstm-history-top">
                        <div className="cstm-history-top-title">
                            <img src={timecirclecl} />  Saved videos
                        </div>
                        {savedvideos.length > 10 && <div className="cstm-history-top-seeall" onClick={() => {
                            history(`/videos/saved`);

                        }}>See all</div>}
                    </div>
                    {savedvideoloader ? (
                        <div className="cstm-cl-mv-ldr">
                            {[...Array(20)].map((el, index) => (
                                <div className="cstm-cl-mv-ldr-main">
                                    <div className="cstm-cl-mv-ldr-video skeleton-box"></div>
                                    <div className="cstm-cl-mv-ldr-title skeleton-box"></div>
                                    <div className="cstm-cl-mv-ldr-time skeleton-box"></div>
                                    <div className="cstm-cl-mv-ldr-view skeleton-box"></div>
                                </div>
                            ))}
                        </div>
                    ) :

                        <div className="cstm-cv-video">

                            {savedvideos.map((convideo) => {
                                return (<ListingVideoComponent convideo={convideo} />);
                            })}
                        </div>
                    }
                </div>
            </>) : <></>}
            {
                (!historyvideoloader && historyvideos.length <= 0) && (!savedvideoloader && savedvideos.length <= 0) ?
                    <div class="cstm-no-history"> <div class="cstm-oops-main-img"><img src={noMediaFound} /></div> <div class="cstm-oops-main-ctn">No videos yet!<br />Time to explore!</div> </div> : <></>
            }

            {
                (historyvideoloader && historyvideos.length <= 0) && (savedvideoloader && savedvideos.length <= 0) ?
                    <div className="cstm-cl-mv-ldr">
                        {[...Array(40)].map((el, index) => (
                            <div className="cstm-cl-mv-ldr-main">
                                <div className="cstm-cl-mv-ldr-video skeleton-box"></div>
                                <div className="cstm-cl-mv-ldr-title skeleton-box"></div>
                                <div className="cstm-cl-mv-ldr-time skeleton-box"></div>
                                <div className="cstm-cl-mv-ldr-view skeleton-box"></div>
                            </div>
                        ))}
                    </div> : <></>
            }
        </div>
    )
}
export default MyVideos;

