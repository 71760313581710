import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// Import Api integration file
import Services from "../../../Services/auth.service";

// Import image
import logo from "../../../Images/logo.png";

const ResetPassword = () => {
  let history = useNavigate();
  const { userId, token } = useParams();
  const [showForm, setShowForm] = useState(false);
  const [pwddetail, setpwddetail] = useState({
    newpassword: "",
    confirmpassword: "",
  });
  const [err, setErr] = useState({ newpassword: "", confirmpassword: "" });

  // Changes updated on page while pwddetail , err changed and setpwddetail ,setErr called
  useEffect(() => {}, [pwddetail, setpwddetail, err, setErr]);
  // Called on click on reset password
  const handleresetpwd = (e) => {
    e.preventDefault();
    var obj = { ...err };
    obj =
      pwddetail.newpassword.length > 0 && pwddetail.confirmpassword.length > 0
        ? { ...err }
        : pwddetail.newpassword.length === 0 &&
          pwddetail.confirmpassword.length === 0
        ? {
            newpassword: "Enter password!",
            confirmpassword: "Please re-enter a new password!",
          }
        : pwddetail.newpassword.length === 0
        ? { ...err, newpassword: "Enter password!" }
        : { ...err, confirmpassword: "Please re-enter a new password!" };
    setErr({ ...obj });
    if (
      err.newpassword.length === 0 &&
      err.confirmpassword.length === 0 &&
      obj.confirmpassword.length === 0 &&
      obj.newpassword.length === 0
    ) {
      var data = {
        userId: userId,
        token: token,
        new_pwd: pwddetail.newpassword,
        re_new_pwd: pwddetail.confirmpassword,
      };

      Services.resetpassword(data)
        .then((res) => {
          if (res.data.status) history("/");
          else alert(res.data.message);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  // Called on input fields change event
  const handleChange = (e) => {
    setpwddetail({ ...pwddetail, [e.target.name]: e.target.value });
    setErr({
      ...err,
      [e.target.name]:
        e.target.value.length < 1
          ? "Enter password!"
          : e.target.name === "confirmpassword"
          ? e.target.value === pwddetail.newpassword
            ? ""
            : "Password Mismatch!"
          : "",
    });
  };

  return (
    <>
      {/* Reset Password Form */}
      <div className="um-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 um-left">
              <div className="um-left-in">
                <div className="um-logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>

                <div className="um-left-in-mn">
                  <div className="um-title">
                    Forgot Your
                    <br />
                    Password?
                  </div>
                  <div className="um-text">Follow these few steps</div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 um-right">
              <div className="um-right-in">
                <div>
                  <div className="um-right-in-title">Reset Password</div>
                  <div className="um-right-in-sub">
                    Your new password must be different from previous used
                    passwords.
                  </div>
                  <hr className="um-line" />
                  <form onSubmit={handleresetpwd}>
                    <div className="form-group">
                      <div className="um-right-in-label">
                        Create new password*
                      </div>
                      <div className="um-right-in-box">
                        <input
                          type="password"
                          name="newpassword"
                          placeholder="enter your new password"
                          onChange={handleChange}
                          value={pwddetail.newpassword}
                        />
                      </div>
                      <span className="error">{err.newpassword} </span>
                    </div>

                    <div className="form-group">
                      <div className="um-right-in-label">
                        Re-enter new password*
                      </div>
                      <div className="um-right-in-box">
                        <input
                          type="password"
                          name="confirmpassword"
                          placeholder="enter your password"
                          onChange={handleChange}
                          value={pwddetail.confirmpassword}
                        />
                      </div>
                      <span className="error">{err.confirmpassword} </span>
                    </div>

                    <div className="form-group">
                      <button type="submit" className="btn">
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
