import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import imgdelete from "../../../../Images/BackendImages/delete.svg";
import editfill from "../../../../Images/BackendImages/edit.svg";
import Loader from "../../../../Images/loader.gif";
import Loader2 from "../../../../Images/loader2.gif";
import moment from "moment";
import { useDispatch } from "react-redux";
import authServiceDocument from "../../../../Services/auth.service.document";
import ImageIcon from "../../../Icons/FileIcons/ImageIcon";
import VideoFileIcon from "../../../Icons/FileIcons/VideoFileIcon";
import DocIcon from "../../../Icons/FileIcons/DocIcon";
import PdfIcon from "../../../Icons/FileIcons/PdfIcon";
import NotDefinedFileIcon from "../../../Icons/FileIcons/NotDefinedFileIcon";
import ExcelIcon from "../../../Icons/FileIcons/ExcelIcon";
import ExcelFileIcon from "../../../Icons/FileIcons/ExcelFileIcon";
import PptIcon from "../../../Icons/FileIcons/PptIcon";
import AudioFileIcon from "../../../Icons/FileIcons/AudioFileIcon";
import hide from "../../../../Images/Hide.svg";
import show from "../../../../Images/Show.svg";
import arrowimg from "../../../../Images/arow-left-right.png";
import ReactDatePicker from "react-datepicker";
import authService from "../../../../Services/auth.service";
import UserViewsDocumentModal from "../DocumentModals/UserViewsDocumentModal";
import DownloadDocumentList from "../DocumentModals/DownloadDocumentList";
const columns = [
  { id: "fileName", label: "File Name", width: 18 },
  { id: "shortDescription", label: "Short Description", width: 15 },
  { id: "owner", label: "Owner", width: 10 },
  { id: "dateUploaded", label: "Date uploaded", width: 12 },
  { id: "visibility", label: "Visibility", width: 9 },
  { id: "likesCount", label: "Likes", width: 6 },
  { id: "viewsCount", label: "Views", width: 6 },
  { id: "commentsCount", label: "Comments", width: 8 },
  { id: "downloadCount", label: "Downloads", width: 8 },
  { id: "submissionStatus", label: "Status", width: 8 },
  { id: "action", label: "Actions" },
];

const DocumentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [allDocument, setAllDocument] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dataLoader, setDataLoader] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowalert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [searchText, setSearchText] = useState("");
  const [inProcess, setInProcess] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [tagList, setTagList] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedVisibility, setSelectedVisibility] = useState("");
  const [subCategoryList, setSubCategoryList] = useState([]);
  const location = useLocation().search;
  const search = new URLSearchParams(location).get("search");
  const rowsFromUrl = new URLSearchParams(location).get("perpage");
  const tagFromUrl = new URLSearchParams(location).get("tag");
  const categoryFromUrl = new URLSearchParams(location).get("category");
  const subcategoryFromUrl = new URLSearchParams(location).get("subcategory");
  const typeFromUrl = new URLSearchParams(location).get("contentType");
  const [sortList, setSortList] = useState({
    sortField: "",
    sortType: "",
  });
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [filterType, setFilterType] = useState("");
  const [showCustomFilter, setShowCustomFilter] = useState(false);
  const [filterHeader, setFilterHeader] = useState("Lifetime");
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "days").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [contentList, setContentList] = useState([]);
  const [selectedContent, setSelectedContent] = useState("");
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [isChangeDate, setIsChangeDate] = useState(true);

  const fromDateRef = useRef(fromDate);
  const toDateRef = useRef(toDate);
  //function for get and store specific data in state
  useEffect(() => {
    rowsFromUrl !== null && setRowsPerPage(rowsFromUrl);
    suggestionListApi();
    getTagList();
  }, []);

  useEffect(() => {
    const category = categoryList.filter(
      (cate) => cate._id === categoryFromUrl
    );
    category.length > 0 && setSubCategoryList(category[0].subcategory);
  }, [categoryList]);

  // tag list api function
  async function getTagList() {
    try {
      const [tagListRes, categoryRes, contentTypeRes] = await Promise.all([
        authService.getalltags_byadmin(""),
        authServiceDocument.categoryList(),
        authServiceDocument.contentTypeList(),
      ]);
      if (tagListRes.data.status) {
        setTagList(tagListRes.data.data);
      }
      if (categoryRes.data.data.status) {
        setCategoryList(categoryRes.data.data.data);
      }
      if (contentTypeRes.data.data.status) {
        setContentList(contentTypeRes.data.data.data);
      }
    } catch (error) {
      return false;
    }
  }

  //intialize document object
  function documentCreateData(
    fileName,
    shortDescription,
    owner,
    dateUploaded,
    visibility,
    likesCount,
    viewsCount,
    commentsCount,
    downloadCount,
    submissionStatus,
    action,
    fileType
  ) {
    return {
      fileName,
      shortDescription,
      owner,
      dateUploaded,
      visibility,
      likesCount,
      viewsCount,
      commentsCount,
      downloadCount,
      submissionStatus,
      action,
      fileType,
    };
  }

  // suggestionList api
  async function suggestionListApi() {
    try {
      const suggestionRes = await authServiceDocument.adminSuggestionList();
      if (suggestionRes.data.data.status) {
        let data = suggestionRes.data.data.list;
        var arr = [];
        if (
          data &&
          data.length !== undefined &&
          data.length !== null &&
          data.length > 0
        ) {
          data.map((item) => arr.push({ name: item.fileName }));
          setSuggestionList(arr);
        }
      }
    } catch (error) {}
  }

  //get all documents api call
  async function getAllDocumentApi(
    page,
    rowsPerPage,
    searchText,
    col,
    sort,
    filtertype,
    fromdate,
    todate,
    tag,
    category,
    subCategory,
    status,
    visibility,
    contentType
  ) {
    let query = "";
    query += `?limit=${rowsPerPage}&page=${page}`;
    query +=
      searchText !== undefined && searchText !== null && searchText !== ""
        ? `&search=${searchText}`
        : "";
    query +=
      col !== undefined && col !== null && col !== "" ? `&sortBy=${col}` : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    query +=
      filtertype !== undefined && filtertype !== null && filtertype !== ""
        ? `&filterType=${filtertype}`
        : "";
    query +=
      filtertype !== undefined && filtertype !== null && filtertype === "custom"
        ? `&filterTypeFromDate=${fromdate}&filterTypeToDate=${todate}`
        : "";
    query +=
      tag !== undefined && tag !== null && tag !== "" ? `&tag=${tag}` : "";
    query +=
      category !== undefined && category !== null && category !== ""
        ? `&categories=${category}`
        : "";
    query +=
      subCategory !== undefined && subCategory !== null && subCategory !== ""
        ? `&subcategories=${subCategory}`
        : "";
    query +=
      status !== undefined && status !== null && status !== ""
        ? `&status=${status}`
        : "";
    query +=
      visibility !== undefined && visibility !== null && visibility !== ""
        ? `&documentStatus=${visibility}`
        : "";
    query +=
      contentType !== undefined && contentType !== null && contentType !== ""
        ? `&contentTypeFilter=${contentType}`
        : "";
    try {
      const getDocRes = await authServiceDocument.getDocumentList(query);
      if (getDocRes.data.data.status) {
        setIsChangeDate(false);
        const docRes = getDocRes.data.data.data.docList;
        console.log("Document response >>", docRes);
        setTotalData(getDocRes.data.data.data.totalMessages);
        let newArr = [];
        if (docRes.length > 0) {
          for (let i = 0; i < docRes.length; i++) {
            newArr.push(
              documentCreateData(
                docRes[i].fileName !== undefined &&
                  docRes[i].fileName !== null &&
                  docRes[i].fileName !== ""
                  ? docRes[i].fileName
                  : "",
                docRes[i].shortDescription !== undefined &&
                  docRes[i].shortDescription !== null &&
                  docRes[i].shortDescription != ""
                  ? docRes[i].shortDescription
                  : "-",
                docRes[i].owner,
                docRes[i].dateUploaded !== undefined &&
                  docRes[i].dateUploaded !== null &&
                  docRes[i].dateUploaded !== ""
                  ? moment(docRes[i].dateUploaded).format("MMM DD, YYYY")
                  : "",
                docRes[i].documentStatus != undefined &&
                  docRes[i].documentStatus != null
                  ? docRes[i].documentStatus
                  : "",
                docRes[i].likesCount !== undefined &&
                  docRes[i].likesCount !== null &&
                  docRes[i].likesCount !== ""
                  ? docRes[i].likesCount
                  : 0,
                docRes[i].viewsCount !== undefined &&
                  docRes[i].viewsCount !== null &&
                  docRes[i].viewsCount !== ""
                  ? docRes[i].viewsCount
                  : 0,
                docRes[i].commentsCount !== undefined &&
                  docRes[i].commentsCount !== null &&
                  docRes[i].commentsCount !== ""
                  ? docRes[i].commentsCount
                  : 0,
                docRes[i].downloadCount !== undefined &&
                  docRes[i].downloadCount !== null &&
                  docRes[i].downloadCount !== ""
                  ? docRes[i].downloadCount
                  : 0,
                docRes[i].doctSubmissionsTypes !== undefined &&
                  docRes[i].doctSubmissionsTypes !== null &&
                  docRes[i].doctSubmissionsTypes.submissionStatus !==
                    undefined &&
                  docRes[i].doctSubmissionsTypes.submissionStatus !== null
                  ? docRes[i].doctSubmissionsTypes.submissionStatus
                      .charAt(0)
                      .toUpperCase() +
                      docRes[i].doctSubmissionsTypes.submissionStatus.slice(1)
                  : "",
                docRes[i]._id,
                docRes[i].documentType !== undefined &&
                  docRes[i].documentType !== null
                  ? docRes[i].documentType
                  : ""
              )
            );
          }

          setRows(newArr);
          setAllDocument(newArr);
          setDataLoader(false);
        } else {
          setRows([]);
          setAllDocument([]);
          setDataLoader(false);
        }
        setDataLoader(false);
      } else {
        setDataLoader(false);
      }
    } catch (error) {
      setDataLoader(false);
      return false;
    }
  }

  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //when query string search
  useEffect(() => {
    setDataLoader(true);
    tagFromUrl !== null && setSelectedTag(tagFromUrl);
    categoryFromUrl !== null && setSelectedCategory(categoryFromUrl);
    typeFromUrl !== null && setSelectedContent(typeFromUrl);
    subcategoryFromUrl !== null && setSelectedSubCategory(subcategoryFromUrl);
    search !== null && setSearchText(search);
    getAllDocumentApi(
      page + 1,
      rowsPerPage,
      search,
      sortList.sortField,
      sortList.sortType,
      filterType,
      fromDate,
      toDate,
      tagFromUrl !== null ? tagFromUrl : selectedTag,
      categoryFromUrl !== null ? categoryFromUrl : selectedCategory,
      subcategoryFromUrl !== null ? subcategoryFromUrl : selectedSubCategory,
      selectedStatus,
      selectedVisibility,
      typeFromUrl !== null ? typeFromUrl : selectedContent
    );
  }, [page, rowsPerPage]);

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //search using text
  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    setDataLoader(true);
    setPage(0);
    getAllDocumentApi(
      1,
      rowsPerPage,
      searchText,
      sortList.sortField,
      sortList.sortType,
      filterType,
      fromDate,
      toDate,
      selectedTag,
      selectedCategory,
      selectedSubCategory,
      selectedStatus,
      selectedVisibility,
      selectedContent
    );
    navigate(`/document/list?search=${searchText}`);
  };

  //sorting function
  const handleSort = (col, direc) => {
    setDataLoader(true);
    setSortList({ ...sortList, sortField: col, sortType: direc });
    getAllDocumentApi(
      1,
      rowsPerPage,
      searchText,
      col,
      direc,
      filterType,
      fromDate,
      toDate,
      selectedTag,
      selectedCategory,
      selectedSubCategory,
      selectedStatus,
      selectedVisibility,
      selectedContent
    );
  };

  async function deleteDocumentApi() {
    setInProcess(true);
    try {
      const deleteDocRes = await authServiceDocument.deleteDocument(deleteId);
      if (deleteDocRes.data.data.status) {
        setAlertMsg(deleteDocRes.data.data.message);
        setShowalert(true);
        setInProcess(false);
        document.querySelector("#Deletedocument .close").click();
        getAllDocumentApi(
          page + 1,
          rowsPerPage,
          searchText,
          sortList.sortField,
          sortList.sortType,
          filterType,
          fromDate,
          toDate,
          selectedTag,
          selectedCategory,
          selectedSubCategory,
          selectedStatus,
          selectedVisibility,
          selectedContent
        );
        setTimeout(() => {
          setAlertMsg("");
          setShowalert(false);
        }, 2000);
      } else {
        setAlertMsg(deleteDocRes.data.data.message);
        setShowalert(true);
        setInProcess(false);
        document.querySelector("#Deletedocument .close").click();
        setTimeout(() => {
          setAlertMsg("");
          setShowalert(false);
        }, 2000);
      }
    } catch (error) {
      setInProcess(false);
      document.querySelector("#Deletedocument .close").click();
    }
  }

  //handle status update api function
  async function updateDocStatusApi(id, status) {
    setRows((prevData) =>
      prevData.map((item) => {
        if (item.action === id) {
          if (item.visibility === "Public") {
            return { ...item, visibility: "Unlisted" };
          } else {
            return { ...item, visibility: "Public" };
          }
        }
        return item;
      })
    );
    let data = {
      docId: id,
      documentStatus: status,
    };
    try {
      const updateRes = await authServiceDocument.updateDocStatus(data);
      if (updateRes.data.status) {
      }
    } catch (error) {
      return false;
    }
  }

  // on arrow key up and down when list index change that time this useefect call
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  //called when filter change
  const handleOnChangeFilterType = (e) => {
    setFilterType(e.target.value);
    const filter = e.target.value;
    setShowCustomFilter(false);
    let fromDateTime = "";
    let toDateTime = "";

    if (filter === "first24hrs") {
      var fromdate = moment().subtract(24, "hours").format("DD MMM HH:mm");
      var todate = moment().format("DD MMM HH:mm");

      setFilterHeader(fromdate + "-" + todate);
    } else {
      var addFilterCount = 0;
      if (filter === "past7days") {
        addFilterCount = 6;
      } else if (filter === "past28days") {
        addFilterCount = 27;
      } else if (filter === "past90days") {
        addFilterCount = 89;
      } else if (filter === "past365days") {
        addFilterCount = 364;
      }

      if (filter === "") {
        setFilterHeader("Lifetime");
      } else if (filter === "past365days") {
        var fromdate = moment()
          .subtract(addFilterCount, "days")
          .format("DD MMM, YYYY");
        var todate = moment().format("DD MMM, YYYY");
        setFilterHeader(fromdate + "-" + todate);
      } else if (filter === "custom") {
        setDataLoader(true);
        fromDateTime = moment(fromDate).format("YYYY-MM-DD");
        toDateTime = moment(toDate).format("YYYY-MM-DD");
        var fromdate = moment(fromDate).format("DD MMM, YYYY");
        var todate = moment(toDate).format("DD MMM, YYYY");
        getAllDocumentApi(
          1,
          rowsPerPage,
          searchText,
          sortList.sortField,
          sortList.sortType,
          filter,
          fromdate,
          todate,
          selectedTag,
          selectedCategory,
          selectedSubCategory,
          selectedStatus,
          selectedVisibility,
          selectedContent
        );
        setFilterHeader(fromdate + "-" + todate);
        setShowCustomFilter(true);
      } else {
        var fromdate = moment()
          .subtract(addFilterCount, "days")
          .format("DD MMM");
        var todate = moment().format("DD MMM");
        setFilterHeader(fromdate + "-" + todate);
      }
    }
    if (filter !== "custom") {
      setDataLoader(true);
      getAllDocumentApi(
        1,
        rowsPerPage,
        searchText,
        sortList.sortField,
        sortList.sortType,
        filter,
        "",
        "",
        selectedTag,
        selectedCategory,
        selectedSubCategory,
        selectedStatus,
        selectedVisibility,
        selectedContent
      );
    }
  };

  //check date changed or not
  const handleDateChange = (date, setDateFunction, dateRef) => {
    setIsChangeDate(false);
    if (date !== dateRef.current) {
      setDateFunction(date);
      setIsChangeDate(true);
    }
  };

  //called when calender close
  const handleCalenderClose = () => {
    if (filterType === "custom") {
      var fromdate = moment(fromDate).format("DD MMM, YYYY");
      var todate = moment(toDate).format("DD MMM, YYYY");
      setFilterHeader(fromdate + "-" + todate);
    }
    if (filterType === "custom") {
      if (
        moment(new Date(fromDate)).format("YYYY-MM-DD") >=
        moment(new Date(toDate)).format("YYYY-MM-DD")
      ) {
        setShowalert(true);
        setAlertMsg("From date should be less than To date!");
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      } else {
        if (fromDate !== "" && toDate !== "" && isChangeDate) {
          setDataLoader(true);
          getAllDocumentApi(
            1,
            rowsPerPage,
            searchText,
            sortList.sortField,
            sortList.sortType,
            filterType,
            fromDate,
            toDate,
            selectedTag,
            selectedCategory,
            selectedSubCategory,
            selectedStatus,
            selectedVisibility,
            selectedContent
          );
        }
      }
    }
  };

  // handle document tag filter change
  const handleOnChangeTag = (e) => {
    setSelectedTag(e.target.value);
    setDataLoader(true);
    getAllDocumentApi(
      1,
      rowsPerPage,
      searchText,
      sortList.sortField,
      sortList.sortType,
      filterType,
      fromDate,
      toDate,
      e.target.value,
      selectedCategory,
      selectedSubCategory,
      selectedStatus,
      selectedVisibility,
      selectedContent
    );
    setPage(0);
  };

  // handle document tag filter change
  const handleOnChangeContent = (e) => {
    setSelectedContent(e.target.value);
    setDataLoader(true);
    getAllDocumentApi(
      1,
      rowsPerPage,
      searchText,
      sortList.sortField,
      sortList.sortType,
      filterType,
      fromDate,
      toDate,
      selectedTag,
      selectedCategory,
      selectedSubCategory,
      selectedStatus,
      selectedVisibility,
      e.target.value
    );
    setPage(0);
  };

  // handle document category filter change
  const handleOnChangeCategory = (e) => {
    setSelectedCategory(e.target.value);
    setDataLoader(true);
    getAllDocumentApi(
      1,
      rowsPerPage,
      searchText,
      sortList.sortField,
      sortList.sortType,
      filterType,
      fromDate,
      toDate,
      selectedTag,
      e.target.value,
      selectedSubCategory,
      selectedStatus,
      selectedVisibility,
      selectedContent
    );
    setPage(0);
    const category = categoryList.filter((cate) => cate._id === e.target.value);
    setSubCategoryList(category[0].subcategory);
  };

  // handle document category filter change
  const handleOnChangeSubCategory = (e) => {
    setSelectedSubCategory(e.target.value);
    setDataLoader(true);
    getAllDocumentApi(
      1,
      rowsPerPage,
      searchText,
      sortList.sortField,
      sortList.sortType,
      filterType,
      fromDate,
      toDate,
      selectedTag,
      selectedCategory,
      e.target.value,
      selectedStatus,
      selectedVisibility,
      selectedContent
    );
    setPage(0);
  };

  // handle document status filter change
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setDataLoader(true);
    getAllDocumentApi(
      1,
      rowsPerPage,
      searchText,
      sortList.sortField,
      sortList.sortType,
      filterType,
      fromDate,
      toDate,
      selectedTag,
      selectedCategory,
      selectedSubCategory,
      e.target.value,
      selectedVisibility,
      selectedContent
    );
    setPage(0);
  };

  // handle document vidibility filter change
  const handleVisibilityChange = (e) => {
    setSelectedVisibility(e.target.value);
    setDataLoader(true);
    getAllDocumentApi(
      1,
      rowsPerPage,
      searchText,
      sortList.sortField,
      sortList.sortType,
      filterType,
      fromDate,
      toDate,
      selectedTag,
      selectedCategory,
      selectedSubCategory,
      selectedStatus,
      e.target.value,
      selectedContent
    );
    setPage(0);
  };

  const onEscCloseModal = (e) => {
    if (viewModalOpen && e.key === "Escape") {
      setViewModalOpen(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
    if (downloadModalOpen && e.key === "Escape") {
      setDownloadModalOpen(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  document.addEventListener("keydown", onEscCloseModal);

  const onSyncData = async () => {
    setInProcess(true);
    try {
      const syncDataRes = await authService.syncUserDataWithDocumet();
      if (syncDataRes.data.status) {
        setShowalert(true);
        setInProcess(false);
        setAlertMsg(syncDataRes.data.message);
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      }
    } catch (error) {}
  };
  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>All Documents</h1>
            <div className="uic-fl-mn-right">
              <div className="uic-fl-btn">
                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button className="btn" onClick={onSyncData}>
                    Sync data
                  </button>
                )}
                <div className="uic-fl-import-btn">
                  <button
                    className="btn"
                    onClick={() => {
                      dispatch({ type: "PAGECHANGE", payload: false });
                      dispatch({
                        type: "NAVIGATEPAGE",
                        payload: `${window.location.pathname}?search=`,
                      });
                      navigate("/document/createdocument");
                    }}
                  >
                    Add document
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.name
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox &&
                suggestions.length > 0 &&
                searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.name)}
                          className={
                            index === listIndex ? "selected-active" : ""
                          }
                        >
                          {searchText !== null &&
                          searchText.length > 0 &&
                          suggestion.name
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase()) ? (
                            <>
                              <b>
                                {suggestion.name.slice(0, searchText.length)}
                              </b>
                              {suggestion.name &&
                                suggestion.name.slice(
                                  searchText.length,
                                  suggestion.name.length
                                )}{" "}
                            </>
                          ) : (
                            suggestion.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
            </div>
            <div className="uic-fl-btn sort-by-mn">
              <div className="uic-fl-btn sort-by-mn">
                <div className="sort-by-mn-title">Filter by : </div>
                <div className="cstm-select-ap">
                  <select value={selectedTag} onChange={handleOnChangeTag}>
                    <option value="">Select Tag</option>
                    {tagList.map((tag) => (
                      <option value={tag._id}>{tag.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="uic-fl-btn sort-by-mn lifetime-only">
                <div className="cstm-select-ap">
                  <select
                    value={selectedContent}
                    onChange={handleOnChangeContent}
                  >
                    <option value="">Select Content Type</option>
                    {contentList.map((content) => (
                      <option value={content.name}>{content.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="uic-fl-btn sort-by-mn lifetime-only">
                <div className="cstm-select-ap">
                  <select
                    value={selectedCategory}
                    onChange={handleOnChangeCategory}
                  >
                    <option value="">Select Category</option>
                    {categoryList.map((cate) => (
                      <option value={cate._id}>{cate.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              {subCategoryList.length > 0 && (
                <div className="uic-fl-btn sort-by-mn lifetime-only">
                  <div className="cstm-select-ap">
                    <select
                      value={selectedSubCategory}
                      onChange={handleOnChangeSubCategory}
                    >
                      <option value="">Select SubCategory</option>
                      {subCategoryList.map((cate) => (
                        <option value={cate._id}>{cate.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="uic-fl-btn sort-by-mn lifetime-only">
                <div className="cstm-select-ap">
                  <select value={selectedStatus} onChange={handleStatusChange}>
                    <option value="">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                  </select>
                </div>
              </div>
              <div className="uic-fl-btn sort-by-mn lifetime-only">
                <div className="cstm-select-ap">
                  <select
                    value={selectedVisibility}
                    onChange={handleVisibilityChange}
                  >
                    <option value="">Select Visibility</option>
                    <option value="Public">Public</option>
                    <option value="Unlisted">Unslisted</option>
                    <option value="Draft">Draft</option>
                  </select>
                </div>
              </div>
              <div className="uic-fl-btn sort-by-mn lifetime-only">
                <div className="cstm-select-ap">
                  <select
                    value={filterType}
                    onChange={handleOnChangeFilterType}
                  >
                    <option value="">Lifetime</option>
                    <option value="first24hrs">Last 24 h</option>
                    <option value="past7days">Last 7 Days</option>
                    <option value="past28days">Last 30 Days</option>
                    <option value="past90days">Last 90 Days</option>
                    <option value="past365days">Last 365 days</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>
                {filterType === "custom" && showCustomFilter ? (
                  <div className="cstm-select-ap-drop documenlist-datepicker cstm-datepicker-field">
                    <ReactDatePicker
                      showIcon
                      selected={fromDate}
                      onChange={(date) =>
                        handleDateChange(date, setFromDate, fromDateRef)
                      }
                      onCalendarClose={handleCalenderClose}
                    />
                    <img src={arrowimg} className="cstm-arrow-left-right" />
                    <ReactDatePicker
                      showIcon
                      selected={toDate}
                      onChange={(date) =>
                        handleDateChange(date, setToDate, toDateRef)
                      }
                      onCalendarClose={handleCalenderClose}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="uic-fl-btn sort-by-mn lifetime-only">
              <div className="cstm-select-ap"></div>
            </div>
          </div>
          {showAlert && <div className="alert alert-info">{alertMsg}</div>}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map(
                      (col) =>
                        col.id !== "drag" && (
                          <TableCell
                            key={col.id}
                            align={col.align}
                            className="uic-th-td"
                          >
                            {col.id !== "action" && col.id !== "drag" ? (
                              <>
                                <span className="custom-sorting">
                                  <i
                                    className="fa fa-angle-down"
                                    onClick={(e) => {
                                      handleSort(col.id, "Asc");
                                    }}
                                  ></i>
                                  <i
                                    className="fa fa-angle-up"
                                    onClick={(e) => {
                                      handleSort(col.id, "Dec");
                                    }}
                                  ></i>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                            {col.label}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody className="uic-table-td">
                  {dataLoader ? (
                    <div className="cstm-data-load">
                      <img src={Loader} />{" "}
                    </div>
                  ) : rows.length > 0 ? (
                    rows.map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          className={`uic-th-td`}
                        >
                          {columns.map((col) => {
                            const value = row[col.id];
                            return col.id === "action" ? (
                              <TableCell className="uic-th-td w-9 uic-table-icon ">
                                <Link
                                  to={`/document/detail?id=${value}`}
                                  onClick={() => {
                                    dispatch({
                                      type: "PAGECHANGE",
                                      payload: false,
                                    });
                                    dispatch({
                                      type: "NAVIGATEPAGE",
                                      payload: `/document/list?search=${search}&&perpage=${rowsPerPage}`,
                                    });
                                  }}
                                >
                                  <Tooltip title="Edit" arrow>
                                    <img src={editfill} />
                                  </Tooltip>
                                </Link>
                                <a
                                  data-toggle="modal"
                                  data-target="#Deletedocument"
                                  onClick={() => setDeleteId(value)}
                                >
                                  <Tooltip title="Delete" arrow>
                                    <img src={imgdelete} />
                                  </Tooltip>
                                </a>
                              </TableCell>
                            ) : col.id === "fileName" ? (
                              <TableCell className={`uic-th-td w-${col.width}`}>
                                <div className="admin-doc-icon-list">
                                  <span className="doc-icon-list">
                                    {row.fileType === "photos" ? (
                                      <ImageIcon scale={1.34} />
                                    ) : row.fileType === "videos" ? (
                                      <VideoFileIcon scale={1.34} />
                                    ) : row.fileType === "documents" ? (
                                      <DocIcon scale={1.34} />
                                    ) : row.fileType === "pdfs" ? (
                                      <PdfIcon scale={1.34} />
                                    ) : row.fileType === "tables" ? (
                                      <ExcelIcon scale={1.34} />
                                    ) : row.fileType === "audio" ? (
                                      <AudioFileIcon scale={1.34} />
                                    ) : row.fileType === "presentations" ? (
                                      <PptIcon scale={1.34} />
                                    ) : (
                                      <NotDefinedFileIcon scale={1.34} />
                                    )}
                                  </span>
                                  <Link
                                    to={`/document/detail?id=${row.action}`}
                                    onClick={() => {
                                      dispatch({
                                        type: "PAGECHANGE",
                                        payload: false,
                                      });
                                      dispatch({
                                        type: "NAVIGATEPAGE",
                                        payload: `/document/list?search=${search}&&perpage=${rowsPerPage}`,
                                      });
                                    }}
                                    className="cstm-view-doc-title"
                                  >
                                    {value}
                                  </Link>
                                </div>
                              </TableCell>
                            ) : col.id === "visibility" ? (
                              <TableCell className={`uic-th-td w-${col.width}`}>
                                {value !== "Draft" ? (
                                  <div className="document-status-select">
                                    <a>
                                      {value !== "Public" ? (
                                        <img src={hide} />
                                      ) : (
                                        <img src={show} />
                                      )}
                                    </a>
                                    <select
                                      className="status-select-only"
                                      disabled={value === "Draft"}
                                      value={value}
                                      onChange={(e) =>
                                        updateDocStatusApi(
                                          row.action,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Public">Public</option>
                                      <option value="Unlisted">Unlisted</option>
                                    </select>
                                  </div>
                                ) : (
                                  <Link
                                    className="cstm-view-users-count"
                                    to={`/document/detail?id=${row.action}`}
                                    onClick={() => {
                                      dispatch({
                                        type: "PAGECHANGE",
                                        payload: false,
                                      });
                                      dispatch({
                                        type: "NAVIGATEPAGE",
                                        payload: `/document/list?search=${search}&&perpage=${rowsPerPage}`,
                                      });
                                    }}
                                  >
                                    Edit Draft
                                  </Link>
                                )}
                              </TableCell>
                            ) : col.id === "shortDescription" ? (
                              <TableCell className={`uic-th-td w-${col.width}`}>
                                <span className="admin-table-short-desc">
                                  {value}
                                </span>
                              </TableCell>
                            ) : col.id === "viewsCount" ? (
                              <TableCell className={`uic-th-td w-${col.width}`}>
                                <a
                                  className="cstm-view-users-count"
                                  onClick={(e) => {
                                    value > 0 && setViewModalOpen(true);
                                    setDeleteId(row.action);
                                    document.body.classList.add("modal-open");
                                  }}
                                >
                                  {value}
                                </a>
                              </TableCell>
                            ) : col.id === "downloadCount" ? (
                              <TableCell className={`uic-th-td w-${col.width}`}>
                                {" "}
                                <a
                                  onClick={(e) => {
                                    setDownloadModalOpen(true);
                                    setDeleteId(row.action);
                                    document.body.classList.add("modal-open");
                                  }}
                                  className="cstm-view-users-count"
                                >
                                  {value}
                                </a>{" "}
                              </TableCell>
                            ) : (
                              <TableCell className={`uic-th-td w-${col.width}`}>
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  ) : (
                    <span className="no-data-found">No data found</span>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>

      <div
        className="modal fade msg-popup"
        id="Deletedocument"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgBigDelete} />
            </div>
            <h1>Are you sure you want to delete this document?</h1>
            <div className="row">
              <div className="col-6">
                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn btn-red"
                      onClick={deleteDocumentApi}
                    >
                      Delete
                    </button>
                  </span>
                )}
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn2" data-dismiss="modal">
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewModalOpen && (
        <UserViewsDocumentModal
          documentId={deleteId}
          filterType={filterType}
          reqFromDate={fromDate}
          reqToDate={toDate}
          setViewModalOpen={setViewModalOpen}
        />
      )}
      {downloadModalOpen && (
        <DownloadDocumentList
          documentId={deleteId}
          filterType={filterType}
          reqFromDate={fromDate}
          reqToDate={toDate}
          setDownloadModalOpen={setDownloadModalOpen}
        />
      )}
    </>
  );
};

export default DocumentList;
