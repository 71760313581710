import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchtopics, fetchpostbygroupid_admin } from "../../Action";
import Services from "../../Services/auth.service";
import emoji from "../../Images/optimistic.png";
//import components
import AdminSidebar from "../AdminSidebar";
import CreateGroup from "./CreateGroup";
import Dashboard from "./Dashborad";
import GroupInfo from "./GroupInfo";
import GroupList from "./GroupList";

const GroupManagement = () => {
  let history = useNavigate();
  const [currentDiv, setcurrentDiv] = useState("");
  const [selectedGid, setselectedGid] = useState("");
  const [grpdetail, setgrpdetail] = useState({
    groupImages: "",
    groupCoverImages: "",
    groupTitle: "",
    groupInfo: "",
    groupType: "",
    groupVisibility: "",
    createGroupChat: "",
    topics: [],
  });
  const [allgrp, setallgrp] = useState([]);
  const dispatch = useDispatch();
  const [infotab, setinfotab] = useState(false);
  const [sidebarmenu, setSidebarmenu] = useState(false);
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })

  useEffect(() => {
    handleClearAllStates();
    Services.getallgroup()
      .then((res) => {
        if (res.data.status) {
          if (res.data.data.length > 0) {
            setallgrp(res.data.data);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
    dispatch(fetchtopics());
  }, []);
  useEffect(() => {
    if (!modalInProcess) {
      handleClearAllStates();
    }

    Services.getallgroup()
      .then((res) => {
        if (res.data.status) {
          if (res.data.data.length > 0) {
            setallgrp(res.data.data);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
    if (selectedGid.length > 0)
      dispatch(fetchpostbygroupid_admin({ groupid: selectedGid, page: 1 }));
  }, [currentDiv]);

  const updateWindowSize = () => {
    if (window.innerWidth > 992) {
      setSidebarmenu(false)
      document.body.classList.remove('overflow-hidden')
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  const sidebarHandleClick = (ele) => {
    setSidebarmenu(ele);
    if (window.innerWidth < 992) {
      if (sidebarmenu) {
        document.body.classList.remove('overflow-hidden')
      } else {
        document.body.classList.add('overflow-hidden')
      }
    }
  }

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  switch (currentDiv) {
    case "create":
      return (
        <div className="cstm-all-admin">
          {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
          <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
          <div className="content-main">
            <div className="create-yuor-topic-main-box">
              <div className="create-yuor-topic cyfg-mn">
                <h1>Create your First Group</h1>
                <span className="cyt-cont">
                  Let’s cover some basic inforamtion about your first on Million
                  Dollar Sellers
                </span>
                <span className="cyt-lets-get-started-link">
                  <a href="#">Let’s Get Started</a>
                  <img src={emoji} />{" "}
                </span>
              </div>

              <CreateGroup setcurrentDiv={setcurrentDiv} />
            </div>
          </div>
        </div>
      );
    case "listing":
      return (
        <div className="cstm-all-admin">
          {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
          <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
          <div className="content-main">
            <div className="users-info">
              <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <button
                        type="button"
                        className={
                          currentDiv === "create"
                            ? "btn-link collapsed sd-active2"
                            : "btn-link collapsed"
                        }
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        onClick={() => {
                          {
                            setcurrentDiv("create");
                            sidebarHandleClick(false);
                          }
                        }}
                      >
                        Add new group
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <Link
                        className={
                          currentDiv === "listing" || currentDiv === ""
                            ? "btn-link collapsed sd-active2"
                            : "btn-link collapsed"
                        }
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        onClick={() => {
                          setcurrentDiv("listing");
                          sidebarHandleClick(false);
                        }}
                      >
                        All groups
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <GroupList
                setcurrentDiv={setcurrentDiv}
                setselectedGid={setselectedGid}
                setgrpdetail={setgrpdetail}
                allgrp={allgrp}
                setallgrp={setallgrp}
                setinfotab={setinfotab}
              />
            </div>
          </div>
        </div>
      );
    case "dashboard":
      return (
        <div className="cstm-all-admin">
          {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
          <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
          <div className="content-main">
            <div className="users-info">
              <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <button
                        type="button"
                        className={
                          currentDiv === "create"
                            ? "btn-link collapsed sd-active2"
                            : "btn-link collapsed"
                        }
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        onClick={() => {
                          sidebarHandleClick(false);
                          setcurrentDiv("create");
                        }}
                      >
                        Add new group
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <Link
                        className={
                          currentDiv === "listing" || currentDiv === ""
                            ? "btn-link collapsed sd-active2"
                            : "btn-link collapsed"
                        }
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        onClick={() => {
                          sidebarHandleClick(false);
                          setcurrentDiv("listing");
                        }}
                      >
                        All groups
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <Dashboard
                setcurrentDiv={setcurrentDiv}
                selectedGid={selectedGid}
                grpdetail={grpdetail}
                setgrpdetail={setgrpdetail}
                infotab={infotab}
                setinfotab={setinfotab}
              />
            </div>
          </div>
        </div>
      );
    case "grpinfo":
      return (
        <div className="cstm-all-admin">
          {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
          <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
          <GroupInfo
            setcurrentDiv={setcurrentDiv}
            selectedGid={selectedGid}
            grpdetail={grpdetail}
            setgrpdetail={setgrpdetail}
          />
        </div>
      );
    default:
      return (
        <div className="cstm-all-admin">
          {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
          <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
          <div className="content-main">
            <div className="users-info">
              <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <button
                        type="button"
                        className={
                          currentDiv === "create"
                            ? "btn-link collapsed sd-active2"
                            : "btn-link collapsed"
                        }
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        onClick={() => {
                          sidebarHandleClick(false);
                          setcurrentDiv("create");
                        }}
                      >
                        Add new group
                      </button>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <Link
                        className={
                          currentDiv === "listing" || currentDiv === ""
                            ? "btn-link collapsed sd-active2"
                            : "btn-link collapsed"
                        }
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        onClick={() => {
                          sidebarHandleClick(false);
                          setcurrentDiv("listing");
                        }}
                      >
                        All groups
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <GroupList
                setcurrentDiv={setcurrentDiv}
                setselectedGid={setselectedGid}
                setgrpdetail={setgrpdetail}
                allgrp={allgrp}
                setallgrp={setallgrp}
                setinfotab={setinfotab}
              />
            </div>
          </div>
        </div>
      );
  }
};

export default GroupManagement;
