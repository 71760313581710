import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedMainTicketApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getTicketByeventid: builder.query({
      query: ({ event_id, params }) => {
        return {
          url: ApiRoutes.mainTicket.getTicketByeventid.url.replace(
            ":event_id",
            event_id
          ),
          method: ApiRoutes.mainTicket.getTicketByeventid.method,
          params,
        };
      },
      providesTags: ["GETMAINTICKETS"],
      transformResponse: (response) => response,
    }),
    createTicket: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.mainTicket.addEventTicket.url,
          method: ApiRoutes.mainTicket.addEventTicket.method,
          body,
        };
      },
      invalidatesTags: ["GETMAINTICKETS", "GETMAINTICKETSUGGESTION"],
      transformResponse: (response) => response,
    }),
    deleteMainTicket: builder.mutation({
      query: ({ id }) => {
        return {
          url: ApiRoutes.mainTicket.deleteMainTicket.url.replace(":id", id),
          method: ApiRoutes.mainTicket.deleteMainTicket.method,
        };
      },
      invalidatesTags: ["GETMAINTICKETS", "GETMAINTICKETSUGGESTION"],
      transformResponse: (response) => response,
    }),
    getTicketByTicketId: builder.query({
      query: ({ ticketId }) => {
        return {
          url: ApiRoutes.mainTicket.getMainTicketById.url.replace(
            ":ticketId",
            ticketId
          ),
          method: ApiRoutes.mainTicket.getMainTicketById.method,
        };
      },
      providesTags: ["GETMAINTICKET"],
      transformResponse: (response) => response,
    }),
    editMainTicket: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: ApiRoutes.mainTicket.editMainTicket.url.replace(":id", id),
          method: ApiRoutes.mainTicket.editMainTicket.method,
          body,
        };
      },
      invalidatesTags: [
        "GETMAINTICKETS",
        "GETMAINTICKET",
        "GETMAINTICKETSUGGESTION",
      ],
      transformResponse: (response) => response,
    }),
    mainTicketSuggestionList: builder.query({
      query: ({ id }) => {
        return {
          url: ApiRoutes.mainTicket.mainTicketSuggestionList.url.replace(
            ":id",
            id
          ),
          method: ApiRoutes.mainTicket.mainTicketSuggestionList.method,
        };
      },
      providesTags: ["GETMAINTICKETSUGGESTION"],
      transformResponse: (response) => response,
    }),
    reorderMainTicket: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: ApiRoutes.mainTicket.reorderMainTicket.url.replace(":id", id),
          method: ApiRoutes.mainTicket.reorderMainTicket.method,
          body,
        };
      },
      invalidatesTags: ["GETMAINTICKETS"],
      transformResponse: (response) => response,
    }),
    getSoldTicektPerUser: builder.query({
      query: ({ ticketId }) => {
        return {
          url: ApiRoutes.mainTicket.getSoldTicektPerUser.url.replace(
            ":ticketId",
            ticketId
          ),
          method: ApiRoutes.mainTicket.getSoldTicektPerUser.method,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTicketByeventidQuery,
  useCreateTicketMutation,
  useDeleteMainTicketMutation,
  useLazyGetTicketByTicketIdQuery,
  useEditMainTicketMutation,
  useMainTicketSuggestionListQuery,
  useReorderMainTicketMutation,
  useGetSoldTicektPerUserQuery,
} = extendedMainTicketApi;
