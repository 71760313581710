import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
//import api file
import Services from "../../Services/auth.service";
import Services1 from "../../Services/auth.service1";
import loading from "../../Images/loader.gif";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import exportImg from "../../Images/exportimg.svg"
import { Checkbox } from "@mui/material";
import ExportSelect from "../Admin/PartnerManagement/PartnerModals/ExportSelect";

//columns array- user listing table
const userColumns = [
  { id: "srno", label: "Sr. No" },
  { id: "username", label: "Username" },
  { id: "noofvideos", label: "No of videos watched" },
  { id: "total_watchtime", label: "Total watch time" },
];
//function to create userrow for userlisting table
function createUsers(
  srno,
  id,
  username,
  noofvideos,
  total_watchtime
) {
  return {
    srno,
    id,
    username,
    noofvideos,
    total_watchtime
  };
}
//columns array- video listing table
const videoColumns = [
  { id: "srno", label: "No." },
  { id: "title", label: "Video Title" },
  { id: "watchtime", label: "Watch Time" },
];
//function to create row for videolisting table
function createVideos(
  srno,
  id,
  title,
  watchtime
) {
  return {
    srno,
    id,
    title,
    watchtime
  };
}
const UserStatistic = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const [searchText, setSearchText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [userRows, setUserRow] = useState([]);
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);
  const [videoUsers, setVideoUsers] = useState([])
  const [tblAllVideoUsers, setTblAllVideoUsers] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [videos, setVideos] = useState([])
  const [tblAllVideos, setTblAllVideos] = useState([]);
  const [videoPage, setVideoPage] = useState(0);
  const [videoRowsPerPage, setVideoRowsPerPage] = useState(10);
  const [selUserId, setSelUserId] = useState('');
  const [videoRows, setVideoRow] = useState([]);
  const [dataLoader, setDataLoader] = useState(false)
  const [selected, setSelected] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [selectError, setSelectError] = useState('');
  const [error, setError] = useState(false);
  let history = useNavigate();
  useEffect(() => {
    setUserPage(0)
    setDataLoader(true)
    Services1.getAllVideoWatchedUser().then((res) => {
      if (res.data.status) {
        setVideoUsers(res.data.data)
        setDataLoader(false)
      }
    }).catch((e) => {
      setDataLoader(false)
      return false;
    });

  }, [])
  useEffect(() => {
    if (videoUsers.length > 0) {
      var newarr = [];
      var arr = [...videoUsers];
      for (var i = 0; i < arr.length; i++) {
          newarr[i] = createUsers(
            i + 1,
            arr[i].id,
            arr[i].first_name + ' ' + arr[i].last_name,
            sumTimeWatched(arr[i].watched_time) === "-" ? 0 : arr[i].noofvideos,
            sumTimeWatched(arr[i].watched_time)
          );
        

      }
      setTblAllVideoUsers(newarr);
      setUserRow(newarr);
    } else {
      setTblAllVideoUsers([]);
      setUserRow([]);
    }
  }, [videoUsers]);
  useEffect(() => {
    if (videos.length > 0) {
      var newarr = [];
      var arr = [...videos];
      for (var i = 0; i < arr.length; i++) {
        newarr[i] = createVideos(
          i + 1,
          arr[i].id,
          arr[i].title,
          (arr[i].watched_realtime === undefined || arr[i].watched_realtime.length <= 0) ? '-' : arr[i].watched_realtime.filter(item => item.userid.toString() == selUserId).length > 0 ? showWatchTime(arr[i].watched_realtime.filter(item => item.userid.toString() == selUserId)[0].watch_realduration) : '-',
        );
      }
      setTblAllVideos(newarr);
      setVideoRow(newarr);
    } else {
      setTblAllVideos([]);
      setVideoRow([]);
    }
  }, [videos]);
  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage);
  };
  const handleChangeUserRowsPerPage = (event) => {
    setUserRowsPerPage(+event.target.value);
    setUserPage(0);
  };
  const handleChangeVideoPage = (event, newPage) => {
    setVideoPage(newPage);
  };
  const handleChangeVideoRowsPerPage = (event) => {
    setVideoRowsPerPage(+event.target.value);
    setVideoPage(0);
  };
  const handleSearch = (e) => {
    if (tblAllVideoUsers.length > 0) {
      var arr = tblAllVideoUsers.filter((u) => {
        if (u.username !== undefined && u.username.toLowerCase().includes(e.target.value.toLowerCase()))
          return u;
      });
      setUserRow(arr);
    }
  };
  const handleVideosLists = (e, id) => {
    setSelected([])
    setSelectRow([])
    setVideoPage(0)
    setSelUserId(id)
    Services1.getAllVideoListByUserId(id).then((res) => {
      if (res.data.status) {
        setVideos(res.data.data)
        setViewModalOpen(true)
        document.body.classList.add("modal-open");
      }
    }).catch((e) => {
      return false;
    });
  }
  //sorting rows in usertable
  const handleSort = (col, direc) => {
    setUserPage(0)
    var normal_arr = tblAllVideoUsers;
    var arr = [...tblAllVideoUsers];
    if (direc === "asc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (direc === "desc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else {
      arr = normal_arr;
    }
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createUsers(
        i+1,
        arr[i].id,
        arr[i].username,
        arr[i].noofvideos,
        arr[i].total_watchtime

      );
    }
    setUserRow(tbl);
  };
  //function to get total watchtime 
  const sumTimeWatched = (timeArray) => {
    
    if (timeArray.length > 0) {
      let secondSum = 0;
      let minuteSum = 0;
      let hourSum = 0;
      for (var i = 0; i < timeArray.length; i++) {
        if (timeArray[i] !== null) {
          var time = timeArray[i].split(":")
          secondSum += Number(time[2] !== undefined && time[2] !== null ? time[2] : 0)
          minuteSum += Number(time[1] !== undefined && time[1] !== null ? time[1] : 0)
          hourSum += Number(time[0] !== undefined && time[0] !== null ? time[0] : 0)
        }

      }
      

      let delta = secondSum + (minuteSum * 60) + (hourSum * 60 * 60)

      let hours = Math.floor(Math.abs(delta) / 3600);
      delta = Math.abs(delta) - (hours > 0 ? hours * 3600 : 0);
      let minutes = Math.floor(Math.abs(delta) / 60);
      delta = Math.abs(delta) - (minutes > 0 ? (minutes * 60) : 0);
      let seconds = Math.floor(delta);

      const tmpTime = hours + ':' + minutes + ':' + seconds;
      return showWatchTime(tmpTime)
    } else {
      return `-`;
    }
  }
  //function to show watchtime in proper format
  const showWatchTime = (time1) => {
    let secondSum = 0;
    let minuteSum = 0;
    let hourSum = 0;

    var time = time1.split(":")
    secondSum += Number(time[2] !== undefined && time[2] !== null ? time[2] : 0)
    minuteSum += Number(time[1] !== undefined && time[1] !== null ? time[1] : 0)
    hourSum += Number(time[0] !== undefined && time[0] !== null ? time[0] : 0)
    if (hourSum !== 0 && minuteSum != 0 && secondSum != 0) {
      if (hourSum > 1) {
        return `${hourSum} hrs ${minuteSum} min ${secondSum} sec`;
      } else {
        return `${hourSum} hr ${minuteSum} min ${secondSum} sec`;
      }
    }
    if (hourSum === 0 && minuteSum != 0 && secondSum != 0) {
      return `${minuteSum} min ${secondSum} sec`;
    }
    if (hourSum === 0 && minuteSum === 0 && secondSum != 0) {
      return `${secondSum} sec`;
    }
    if (hourSum === 0 && minuteSum != 0 && secondSum === 0) {
      return `${minuteSum} min`;
    }
    if (hourSum !== 0 && minuteSum === 0 && secondSum === 0) {
      if (hourSum > 1) {
        return `${hourSum} hrs`;
      } else {
        return `${hourSum} hr`;
      }
    }
    if (hourSum !== 0 && minuteSum === 0 && secondSum !== 0) {
      if (hourSum > 1) {
        return `${hourSum} hrs ${secondSum} sec`;
      } else {
        return `${hourSum} hr ${secondSum} sec`;
      }
    }
    if (hourSum === 0 && minuteSum === 0 && secondSum === 0) {
      return `-`;
    }
  }

  const onEscCloseModal = (e) => {
    if (viewModalOpen && e.key === "Escape") {
      setViewModalOpen(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  document.addEventListener("keydown", onEscCloseModal);

  // Export for download xlsx file
  const handleExport = () => {
    const workbook_ = XLSX.utils.book_new(); // create new workbook
    if (selectRow.length > 0) {
      const modifiedData = selectRow.map((item) => ({
        "Name": item.title,
        "Watched Time": item.watchtime
      }));
      const convertToexcel = XLSX.utils.json_to_sheet(modifiedData); // convert modified JSON data to sheet
      XLSX.utils.book_append_sheet(workbook_, convertToexcel, "Sheet2"); // add the sheet to the workbook
      const downloadExcel = XLSX.write(workbook_, {
        bookType: "xlsx",
        type: "array",
      });
      const dataExcel = new Blob([downloadExcel], { type: fileType });

      FileSaver.saveAs(dataExcel, `Watched_Video_List.xlsx`);
    } else {
      setError(true);
      setSelectError("Please select document to export")
      setTimeout(() => {
        setError(false)
        setSelectError("")
      }, 2000)
    }
  }

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          {showAlert && <div className="alert alert-info">{alertMsg}</div>}
          <div className="uic-title">
            <h1>User Statistics</h1>
          </div>
          {dataLoader && <> <div className="userstatistic-loading-image">
            <img src={loading} />
          </div></>}

          {!dataLoader && <>
            <div className="uic-fl-mn">
              <div className="uic-fl-mn-left">
                <div className="uic-fl-search">
                  <input
                    type="text"
                    placeholder="Search user"
                    className="search-user-input"
                    onChange={handleSearch}
                  />
                  <i className="fa fa-search"></i>
                </div>
              </div>
            </div>
            <Paper className="uic-tb-mn">
              <TableContainer className="uic-table-main">
                <Table className="uic-table min-w-auto">
                  <TableHead className="uic-table-th">
                    <TableRow>
                      {userColumns.map((column) => (
                        (column.id === "srno") ?
                          <TableCell
                            key={column.id}
                            className="uic-th-td w-5"
                          > {column.label}</TableCell> : (column.id === "username") ?
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className="uic-th-td w-50"
                            >   <>
                                <span className="custom-sorting">
                                  <i
                                    className="fa fa-angle-down"
                                    onClick={(e) => {
                                      handleSort(column.id, "desc");
                                    }}
                                  ></i>
                                  <i
                                    className="fa fa-angle-up"
                                    onClick={(e) => {
                                      handleSort(column.id, "asc");
                                    }}
                                  ></i>
                                </span>
                              </>{column.label}
                            </TableCell> : (column.id === "noofvideos") ?
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-25"
                              > <>
                                  <span className="custom-sorting">
                                    <i
                                      className="fa fa-angle-down"
                                      onClick={(e) => {
                                        handleSort(column.id, "desc");
                                      }}
                                    ></i>
                                    <i
                                      className="fa fa-angle-up"
                                      onClick={(e) => {
                                        handleSort(column.id, "asc");
                                      }}
                                    ></i>
                                  </span>
                                </>{column.label}</TableCell> : (column.id === "total_watchtime") ?
                                <TableCell key={column.id} className="uic-th-td"> {column.label}</TableCell> :
                                <></>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {userRows
                      .slice(userPage * userRowsPerPage, userPage * userRowsPerPage + userRowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={row.code}
                            className="uic-th-td"
                          >
                            {userColumns.map((column) => (
                              (column.id === "srno") ?
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td"
                                > {row[column.id]}</TableCell> : (column.id === "username") ?
                                  <TableCell
                                    className="uic-th-td"
                                  > {row[column.id]}  </TableCell> : (column.id === "noofvideos") ?
                                    <TableCell
                                      className="uic-th-td"
                                    > {row[column.id] !== 0 ? <a style={{ color: "blue" }} onClick={(e) => { handleVideosLists(e, row["id"]) }}>{row[column.id]}</a> : row[column.id]}</TableCell> : (column.id === "total_watchtime") ?
                                      <TableCell> {row[column.id]}</TableCell> :
                                      <></>

                            ))}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                {(userRows.length === 0) ? (
                  <div className="admin-no-found">
                    <p>No Users Found </p>
                  </div>
                ) : (
                  <></>
                )}{" "}
              </TableContainer>
              <TablePagination
                className="uic-pagination"
                rowsPerPageOptions={[10, 20, 50, 100, 200]}
                component="div"
                count={userRows.length}
                rowsPerPage={userRowsPerPage}
                page={userPage}
                onPageChange={handleChangeUserPage}
                onRowsPerPageChange={handleChangeUserRowsPerPage}
              />
            </Paper>
          </>}
        </div>
      </div>


      {viewModalOpen && (
        <div className="desk-globle-model-box globle-model-box  cstm-user-videolist">
          <div className="desk-globle-model-box-inner cstm-singleuser-videolist-popup">
            <div className="modal-content">
              <div className="cstm-replace-pp">
                <div class="cstm-rpp-title">
                  Watched Video List
                  <span
                    class="cstm-rpp-close-btn"
                    onClick={() => { setViewModalOpen(false); document.body.classList.remove("modal-open"); }}
                  >
                    ✖
                  </span>
                </div>
                <div className="cstm-export-link">
                  <a onClick={handleExport}> <img src={exportImg} />Export</a>
                </div>
                <div className="cstm-cstm-viewlist">
                  {error && <div className="alert alert-info">{selectError}</div>}
                  {videos !== undefined && videos !== null && videos.length > 0 && <Paper className="uic-tb-mn">
                    <TableContainer className="">
                      <Table className="" >
                        <TableHead className="">
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                className="cstm-ls-checkbox"
                                color="primary"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    const newSelectedids = videoRows.map((row) => row.id);
                                    const newSelectedRow = videoRows.map((row) => row);
                                    setSelected(newSelectedids)
                                    setSelectRow(newSelectedRow)
                                    setSelectError("")
                                  } else {
                                    setSelected([])
                                    setSelectRow([])
                                    setSelectError("")
                                  }
                                }}
                                inputProps={{
                                  'aria-label': 'select all desserts',
                                }}
                              />
                            </TableCell>
                            {videoColumns.map((column) => (
                              (column.id === "srno") ?
                                <TableCell
                                  key={column.id}

                                > {column.label}</TableCell> : (column.id === "title") ?
                                  <TableCell
                                    key={column.id}
                                  > {column.label}</TableCell> : (column.id === "watchtime") ?
                                    <TableCell
                                      key={column.id}
                                    > {column.label}</TableCell> : <></>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody className="">
                          {videoRows
                            .slice(videoPage * videoRowsPerPage, videoPage * videoRowsPerPage + videoRowsPerPage)
                            .map((row, index) => {
                              return (
                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  key={row.code}
                                  className=""
                                >
                                  <TableCell padding="checkbox">
                                    <ExportSelect
                                      id={row.id}
                                      row={row}
                                      selected={selected}
                                      setSelected={setSelected}
                                      selectRow={selectRow}
                                      setSelectRow={setSelectRow}
                                      setSelectError={setSelectError}

                                    />
                                  </TableCell>
                                  {videoColumns.map((column) => (
                                    (column.id === "srno") ?
                                      <TableCell
                                        key={column.id}

                                      > {row[column.id]}</TableCell> : (column.id === "title") ?
                                        <TableCell

                                        > {row[column.id]}</TableCell> : (column.id === "watchtime") ?
                                          <TableCell

                                          > {row[column.id]}</TableCell> : <></>

                                  ))}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                      {(videoRows.length === 0) ? (
                        <div className="admin-no-found">
                          <p>No videos Found </p>
                        </div>
                      ) : (
                        <></>
                      )}{" "}
                    </TableContainer>
                    <TablePagination
                      className="uic-pagination"
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={videos.length}
                      rowsPerPage={videoRowsPerPage}
                      page={videoPage}
                      onPageChange={handleChangeVideoPage}
                      onRowsPerPageChange={handleChangeVideoRowsPerPage}
                    />
                  </Paper>}
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      )}





    </>
  );
};
export default UserStatistic;