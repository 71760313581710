import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import NonExistingUsersPopup from "./NonExistingUsersPopup";
import { useAddImportedQuestionsMutation } from "../../../Services/event-services";
import Loader2 from "../../../Images/loader2.gif";

const ImportQuestions = ({ setAlertMsg, setshowAlert }) => {
  const [showNonExistingUsersPopup, setShowNonExistingUsersPopup] =
    useState(false);
  const [failedUsers, setFailedUsers] = useState([]);

  const location = useLocation().search;
  const eventId = new URLSearchParams(location).get("id");

  //Add questions hook
  const [addImportedQuestions, { isLoading: addQuestionsLoader }] =
    useAddImportedQuestionsMutation();

  //Handle import questions
  const handleImportQuestions = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }

    // Validate the file type
    const validTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];

    if (!validTypes.includes(file.type)) {
      setshowAlert(true);
      setAlertMsg("Please upload a valid Excel file (.xls or .xlsx).");
      setTimeout(() => {
        setshowAlert(false);
        setAlertMsg("");
      }, 3000);
      e.target.value = null; // Clear the file input
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      // Extract the header row and find the index of the email column
      const headerRow = jsonData[0].map((header) => header.trim());

      if (headerRow[0].toLowerCase() !== "email") {
        setshowAlert(true);
        setAlertMsg("The first column in the Excel sheet must be 'Email'.");
        setTimeout(() => {
          setshowAlert(false);
          setAlertMsg("");
        }, 3000);
        return;
      }
      const emailColIndex = 0;
      // Build the desired structure
      const questions = headerRow.slice(1); // Remove the email column from questions
      const result = [];
      jsonData.slice(1).forEach((row) => {
        const email = row[emailColIndex];
        if (typeof email === "string" && email.trim()) {
          const userQuestions = [];
          questions.forEach((question, index) => {
            const answer = row[index + 1]; // Offset by 1 due to email column
            if (answer) {
              userQuestions.push({
                question: headerRow[index + 1],
                answer: answer.trim(),
              });
            }
          });
          result.push({
            email: email.trim(),
            questions: userQuestions,
          });
        }
      });

      const bodyData = { questions: result };
      try {
        const addQuestionsRes = await addImportedQuestions({
          eventId,
          body: bodyData,
        });

        if (addQuestionsRes?.data?.status) {
          if (addQuestionsRes?.data?.data?.failCount > 0) {
            setShowNonExistingUsersPopup(true);
            setFailedUsers(addQuestionsRes?.data?.data?.traceData);
          } else {
            setshowAlert(true);
            setAlertMsg(addQuestionsRes?.data?.message);
            setTimeout(() => {
              setshowAlert(false);
              setAlertMsg("");
            }, 3000);
          }
        } else {
          setshowAlert(true);
          setAlertMsg(addQuestionsRes?.data?.message);
          setTimeout(() => {
            setshowAlert(false);
            setAlertMsg("");
          }, 3000);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
    e.target.value = null;
    reader.readAsBinaryString(file);
  };

  //Esc button close popup
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setShowNonExistingUsersPopup(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <div className="d-flex  align-items-end justify-content-between">
        <div className="ms-auto me-1">
          <div className="">
            <input
              id="uploadFileLink"
              type="file"
              className="upload"
              onChange={handleImportQuestions}
            />
            <label
              htmlFor="uploadFileLink"
              className="cursor-pointer mb-2 text-primary"
            >
              {addQuestionsLoader ? (
                <div className="loader-button1">
                  <button>In process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <p className="btn d-flex align-items-center">Import Question</p>
              )}
            </label>
          </div>
        </div>
      </div>
      <NonExistingUsersPopup
        showNonExistingUsersPopup={showNonExistingUsersPopup}
        setShowNonExistingUsersPopup={setShowNonExistingUsersPopup}
        failedUsers={failedUsers}
      />
    </>
  );
};

export default ImportQuestions;
