import React from "react";

const IconSave = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={120}
      height={120}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#6FCADF"
          fillOpacity={0.2}
          d="M59.4 119.944c-1.587 0-3.19.147-4.753-.034-2.456-.286-4.913-.635-7.333-1.148A58.98 58.98 0 0 1 23.19 107.52a59.343 59.343 0 0 1-6.402-5.722 62.277 62.277 0 0 1-8.399-11.046 59.11 59.11 0 0 1-4.357-8.818A57.263 57.263 0 0 1 .96 71.122a56.538 56.538 0 0 1-.863-6.085c-.193-2.951-.05-5.928 0-8.898.07-4.133.921-8.158 1.96-12.128a51.266 51.266 0 0 1 3.223-8.898 58.827 58.827 0 0 1 5.202-9.107 61.068 61.068 0 0 1 3.731-4.93 69.78 69.78 0 0 1 5.98-6.017 57.893 57.893 0 0 1 7.47-5.683A60.39 60.39 0 0 1 61.341.016 59.985 59.985 0 0 1 99.554 14.88a59.892 59.892 0 0 1 19.715 35.927c.654 4.11.87 8.28.644 12.435-.746 12.369-4.833 23.51-12.372 33.358a60.38 60.38 0 0 1-18.381 15.882 58.189 58.189 0 0 1-10.315 4.568 57.159 57.159 0 0 1-12.59 2.676 46.69 46.69 0 0 1-6.854.218Z"
        />
        <path
          fill="#060D14"
          fillRule="evenodd"
          d="M47.167 33.333c-3.145 0-5.834 2.271-5.834 5.218v45.217c0 .667.152 1.52.821 2.156.668.637 1.48.743 2.096.743a1.76 1.76 0 0 0 .966-.308L60 76.56l14.784 9.8c.285.196.62.302.966.308.617 0 1.428-.106 2.096-.743.669-.637.82-1.489.82-2.156V38.551c0-2.947-2.688-5.218-5.833-5.218H47.167Zm.673 4.675h24.32c1.49 0 2.212.844 2.212 1.648V81.25l-13.456-8.91a1.663 1.663 0 0 0-1.83 0l-13.457 8.91V39.656c0-.804.723-1.648 2.211-1.648Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSave;
