import EventsList from "./EventsList";
// icon
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
const EventListPopup = ({ setclose, openingpop }) => {
  return (
    <div className="globle-model-box for-globle-model-box">
      <div className="an-globle-model-box-inner">
        <div className="an-globle-model-heading">
          <button
            className="an-globle-back"
            onClick={() => {
              setclose({ ...openingpop, event: false });
            }}
          >
            <ArrowLeftLineIcon size="20px" />
          </button>
          <div className="an-globle-model-heading-sec">
            <h3>Events</h3>
          </div>
        </div>
        <EventsList />
      </div>
    </div>
  );
};
export default EventListPopup;