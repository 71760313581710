import React from 'react'
import './ProgressBar.css'
import "../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"

const ProgressBar = ({ progress = 0, color = "", label = "" }) => {
    return (
        <>

            <div className={`progress-bar ${color === "white" ? "white2" : ""}`}>
                <div className='progress-bar-info'>
                    <div className='progress-bar-info-filename'>{label}</div>
                    <div className='progress-bar-info-value'>{progress}%</div>
                </div>
                <div className='progress-bar-track'>
                    <span className={`progress-bar-track-color ${color}`} style={{ width: `${progress}%` }}></span>
                </div>
            </div>
        </>
    )
}
ProgressBar.propTypes = {
    progress: PropTypes.number,
    color: PropTypes.oneOf(["blue", "white", "black"]),
    label: PropTypes.string
}

export default ProgressBar  