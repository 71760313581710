import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import ChipInputField from "./ChipInputField";

const UserAccessRule = ({
  emails,
  setEmails,
  emailRegex,
  attendees,
  setAttendees,
  nonAttendees,
  setNonAttendees,
  invalidEmail,
  setInvalidEmail,
  setSelectedUsers,
  getAllEventAttendees,
}) => {
  //add user states
  const [allParticipantsEmailArr, setAllParticipantsEmailArr] = useState([]);
  const [isError, setIsError] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (getAllEventAttendees) {
      // Update allParticipantsEmailArr for file upload validation
      setAllParticipantsEmailArr(
        getAllEventAttendees.map((user) => user["Preferred Email"].trim())
      );

      // Create a Map for quick email to ID lookup
      const emailToIdMap = new Map(
        getAllEventAttendees?.map((user) => [user["Preferred Email"], user._id])
      );

      // Filter attendees and get corresponding IDs
      const selectedIds = attendees
        .map((email) => emailToIdMap.get(email))
        .filter((id) => id !== undefined); // Remove undefined values

      setSelectedUsers(selectedIds);
    }
  }, [attendees, getAllEventAttendees]);

  
  //File upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setIsLoading(true);
    if (!file) {
      return;
    }
    // Validate the file type
    const validTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    if (!validTypes.includes(file.type)) {
      setIsError(true);
      setResponseMessage("Please upload a valid Excel file (.xls or .xlsx).");
      e.target.value = null; // Clear the file input
      setIsLoading(false);
      setTimeout(() => {
        setResponseMessage("");
      }, 3000);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      // Identify the index of the "email" column
      const headerRow = jsonData[0].map((header) =>
        header?.trim().toLowerCase()
      );
      const emailColIndex = headerRow.indexOf("email");

      if (emailColIndex === -1) {
        setIsError(true);
        setResponseMessage("No 'Email' column found in the Excel sheet.");
        setIsLoading(false);
        setTimeout(() => {
          setResponseMessage("");
        }, 3000);
        return;
      }

      const newArr = [];
      jsonData.slice(1).forEach((row) => {
        const email = row[emailColIndex];
        if (typeof email === "string") {
          newArr.push(email.trim());
        }
      });
      setIsError(false);
      // setExcelFileEmails(newArr);
      setEmails((prevEmails) => [...new Set([...prevEmails, ...newArr])]);
      // Update the attendees and non-attendees states along with invalid email
      const newAttendees = newArr.filter((email) =>
        allParticipantsEmailArr?.includes(email)
      );
      const newNonAttendees = newArr.filter(
        (email) =>
          !allParticipantsEmailArr?.includes(email) && emailRegex.test(email)
      );
      const newInvalidEmails = newArr.filter(
        (email) => !emailRegex.test(email)
      );
      setAttendees((prevAttendees) => [
        ...new Set([...prevAttendees, ...newAttendees]),
      ]);
      setNonAttendees((prevNonAttendees) => [
        ...new Set([...prevNonAttendees, ...newNonAttendees]),
      ]);
      setInvalidEmail((prevInvalidEmails) => [
        ...new Set([...prevInvalidEmails, ...newInvalidEmails]),
      ]);
      setResponseMessage("Email list added successfully");
      setIsLoading(false);
      setTimeout(() => {
        setResponseMessage("");
      }, 3000);
    };
    e.target.value = null;
    reader.readAsBinaryString(file);
  };

  return (
    <>
      {responseMessage && (
        <div className={isError ? "alert alert-danger" : "alert alert-info"}>
          {responseMessage}
        </div>
      )}
      <div className="row">
        <div className="col-sm-12 form-group">
          <div className="d-flex  align-items-end justify-content-between">
            <div className="ms-auto me-1">
              <div className="">
                <input
                  id="uploadFileLink"
                  type="file"
                  className="upload"
                  onChange={handleFileUpload}
                />
                {isLoading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <label
                    htmlFor="uploadFileLink"
                    className="text-decoration-underline cursor-pointer mb-2 text-primary"
                  >
                    <p>Import via excel </p>
                  </label>
                )}
              </div>
            </div>
          </div>
          {/* Email Text Area */}
          <ChipInputField
            allParticipantsEmailArr={allParticipantsEmailArr}
            attendees={attendees}
            setAttendees={setAttendees}
            nonAttendees={nonAttendees}
            setNonAttendees={setNonAttendees}
            emails={emails}
            setEmails={setEmails}
            invalidEmail={invalidEmail}
            setInvalidEmail={setInvalidEmail}
            emailRegex={emailRegex}
            getAllEventAttendees={getAllEventAttendees}
          />
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-end gap-2">
              <div className="cstm-channel-type-title mr-1">
                Attendees : {!attendees?.length ? 0 : attendees?.length}
              </div>
              <div className="cstm-channel-type-title-non-select mr-1">
                Non attendees:{" "}
                {!nonAttendees?.length ? 0 : nonAttendees?.length}
              </div>
              <div className="cstm-channel-type-title-error mr-1">
                Error: {!invalidEmail?.length ? 0 : invalidEmail?.length}
              </div>
            </div>
          </div>
          <p className="text-secondary">
            Add multiple users by separating their email addresses with a comma,
            space, or newline.
          </p>
        </div>
      </div>
    </>
  );
};

export default UserAccessRule;
