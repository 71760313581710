import React from 'react'
import { Link } from 'react-router-dom'
import mdsLogoImg from "../../../Images/logo-image.jpg";
const PartnerPerkCard = ({ reviewCount, item, onClick }) => {
     // if role is user for that all the navigation clicks will work
    const isUser = (localStorage.getItem("role") === "user")
    return (
        <>
            <div key={item._id} className="mds-partners-cont-post partners-more-benefits">
                <Link to={reviewCount >= 3 && isUser ? `/partners/all-partners/${item._id}`: `/partners/all-partners?type=perk&&cat=all&&search=&&sortby=newest&&badge=all`} onClick={onClick}>
                    <div className="mds-partners-cont-post-img">
                        <img src={item?.thumbnail ? item.thumbnail : mdsLogoImg ?? mdsLogoImg} />
                    </div>
                    <div className="mds-partners-cont-post-title">
                        <h3>{item?.offerValue ? item.offerValue : "-" ?? "-"}</h3>
                    </div>
                    <h5 className="cstm-partnesh5">
                        {item?.companyName ? item.companyName : "-" ?? "-"}
                    </h5>
                </Link>
                <h6
                    className="cstm-partnesh6"
                    dangerouslySetInnerHTML={{
                        __html: item?.shortDescription ?? "",
                    }}
                />
            </div>
        </>
    )
}

export default PartnerPerkCard