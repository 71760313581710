import React, { useEffect, useState } from "react";
import VideoXSmallCard from "./VideoXSmallCard";
import PdfIcon from "../../Icons/FileIcons/PdfIcon";
import NotDefinedFileIcon from "../../Icons/FileIcons/NotDefinedFileIcon";
import DocIcon from "../../Icons/FileIcons/DocIcon";
import PptIcon from "../../Icons/FileIcons/PptIcon";
import ImageIcon from "../../Icons/FileIcons/ImageIcon";
import VideoFileIcon from "../../Icons/FileIcons/VideoFileIcon";
import AudioFileIcon from "../../Icons/FileIcons/AudioFileIcon";
import HashTag from "../../Hashtag/HashTag";
import Chips from "../../Chips/Chips";
import Avtar from "../../Avtar/Avtar";
import MediaPreview from "../../../Pages/ChatModule/MainComponent/MediaPreview";
import moment from "moment";
import mdsLogo from "../../../Images/news-logo.svg";
import videodummy from "../../../Images/logo-image-any-only.jpg";
import { Link } from "react-router-dom";
import EventSmallCard from "../../../Pages/EventModule/Components/HelperComponents/EventSmallCard";
import DocSmallCard from "../../DocumentCards/DocSmallCell";
import SponsorSmallCell from "../../SponsorSmallCell/SponsorSmallCell";
const DetailsTab = ({ videodetail }) => {
  const [previewData, setPreviewData] = useState(null);
  const [showPreview, setPreview] = useState(false);
  const [filePreview, setFilePreview] = useState();
  const [showFileData, setFileData] = useState([]);

  // Function to handle image preview
  const handleImagePreview = (e, data) => {
    e.preventDefault();
    const formattedData = Array.isArray(data) ? data : [data];
    setPreviewData(formattedData);
    setFileData(data);
    setPreview(true);
  };

  // Function to handle closing the preview
  const handleClosePreview = () => {
    setPreviewData(null);
    setPreview(false);
  };

  const mediaType = () => {
    if (!previewData || previewData.length === 0) {
      return null;
    }

    const allowedExtensions = [
      "jpeg",
      "jpg",
      "png",
      "pdf",
      "doc",
      "ppt",
      "xls",
      "mp4",
    ];

    for (let i = 0; i < previewData.length; i++) {
      const url = previewData[i].url;
      if (!url) continue;

      const extension = url.substring(url.lastIndexOf(".") + 1).toLowerCase();
      if (["jpeg", "jpg", "png"].includes(extension)) {
        previewData.forEach((item) => {
          item.type = "image";
          item.url = item.url.startsWith("https")
            ? item.url
            : process.env.REACT_APP_AWS_IMG_VID_PATH + item.url;
        });

        setPreviewData(previewData);
      } else if (["mp4"].includes(extension)) {
        previewData.forEach((item) => {
          item.type = "video";
          item.url = item.url.startsWith("https")
            ? item.url
            : process.env.REACT_APP_AWS_IMG_VID_PATH + item.url;
        });

        setPreviewData(previewData);
      } else if (["pdf"].includes(extension)) {
        previewData.forEach((item) => {
          item.type = "pdf";
        });
      }
      if (allowedExtensions.includes(extension)) {
        if (["jpeg", "jpg", "png", "mp4"].includes(extension)) {
          return "media";
        } else {
          return "file";
        }
      }
    }
  };

  useEffect(() => {
    const type = mediaType();
    if (type === "media" || type === "file") {
      setFilePreview(type);
    }
  }, [previewData]);

  const formattedStartDateConvert = (dateString) => {
    if (dateString) {
      // Parse the date string into a Date object
      const dateParts = dateString.split("-");
      const month = parseInt(dateParts[0], 10); // Month is 0-based in JavaScript Date object
      const day = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);
      const dateObject = new Date(year, month - 1, day);

      // Format the date as "MMM D, YYYY"
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
      }).format(dateObject);
      return formattedDate;
    }
  };

  const formattedEndDateConvert = (dateString) => {
    if (dateString) {
      // Parse the date string into a Date object
      const dateParts = dateString.split("-");
      const month = parseInt(dateParts[0], 10); // Month is 0-based in JavaScript Date object
      const day = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);
      const dateObject = new Date(year, month - 1, day);

      // Format the date as "MMM D, YYYY"
      const formattedDate = new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(dateObject);
      return formattedDate;
    }
  };
  return (
    <>
      <div className="tab-conten">
        <div className="text-wrapper">
          {videodetail !== undefined &&
            videodetail.description !== undefined && (
              <>
                {/* <ShowMoreText
                                    lines={3}
                                    more="Show more"
                                    less="Show less"
                                    className="content-css"
                                    anchorclassName="my-anchor-css-class"
                                    expanded={false}
                                    truncatedEndingComponent={"... "}
                                  > */}
                <div
                  className="cstm-videodes"
                  dangerouslySetInnerHTML={{
                    __html: videodetail.description,
                  }}
                ></div>
                {/* </ShowMoreText> */}
              </>
            )}
        </div>
        {videodetail?.tag && videodetail?.tag.length > 0 && (
          <div className="text-wrapper">
            <div className="hashtag-list">
              {videodetail.tag.map((tag) => (
                <Link to={`/videos?type=all&filter=recent&search=${tag.name}`}>
                  <HashTag tag={`#${tag.name}`} />
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>

      {videodetail?.categories && videodetail?.categories.length > 0 && (
        <div className="tab-conten">
          <div className="text-wrapper">
            <h3>Categories</h3>
            <div className="chips-wrapper">
              {videodetail.categories.map((category) => (
                <React.Fragment key={category._id}>
                  <Link
                    className="rounded-full"
                    to={`/videos?type=all&filter=recent&catid=${category._id}`}
                    onClick={() => {
                      window.scrollTo({
                        top: -50,
                        left: -50,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Chips label={category.name} />
                  </Link>
                  {category.subcategory &&
                    category.subcategory.length > 0 &&
                    category.subcategory.map((subCat) => (
                      <Link
                        className="rounded-full"
                        key={subCat._id}
                        to={`/videos?type=all&filter=recent&catid=${category._id}&subcategorie_id=${subCat._id}`}
                        onClick={() => {
                          window.scrollTo({
                            top: -50,
                            left: -50,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <Chips label={`${category.name} - ${subCat.name}`} />
                      </Link>
                    ))}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
      {videodetail?.speaker && videodetail?.speaker.length > 0 && (
        <div className="tab-conten">
          <div className="text-wrapper">
            <h3>Speakers</h3>
            <div className="speakers-wrapper">
              {videodetail.speaker.map((speaker) => (
                <Link
                  to={`/videos?type=all&filter=recent&speaker=${speaker._id}`}
                >
                  <div className="speakers-item">
                    {speaker?.profileImg ? (
                      <Avtar src={speaker?.profileImg} size="48" />
                    ) : (
                      <Avtar
                        type="letter"
                        size="48"
                        letter={speaker.display_name}
                      />
                    )}
                    <div className="type-text-only">
                      <h5 className="table-h5">{`${speaker.display_name}`}</h5>
                      <h6 className="table-h6">
                        {speaker?.attendeeDetail?.profession}
                      </h6>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
      {videodetail.eventIds && videodetail.eventIds.length > 0 && (
        <div className="tab-conten">
          <div className="text-wrapper">
            <h3>Related event</h3>
            <div className="related-event-video-card-list">
              {videodetail.eventIds &&
                videodetail.eventIds.length > 0 &&
                videodetail.eventIds.map((event) => (
                  <EventSmallCard
                    url={`/event-details/pastEvents/${event._id}`}
                    hasDate={true}
                    hasEventType={true}
                    thumbType={"img"}
                    thumbUrl={event?.thumbnail ? event?.thumbnail : videodummy}
                    title={event?.title}
                    uplodedDate={`${formattedStartDateConvert(
                      event?.startDate
                    )} - ${formattedEndDateConvert(event?.endDate)}`}
                    eventType={
                      event?.locationType
                        ?.replace(/_/g, " ")
                        ?.charAt(0)
                        ?.toUpperCase() +
                      event?.locationType
                        ?.replace(/_/g, " ")
                        ?.slice(1)
                        ?.toLowerCase()
                    }
                    hasDotMenu={false}
                    hasView={false}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
      {videodetail.files && videodetail.files.length > 0 && (
        <div className="tab-conten">
          <div className="text-wrapper">
            <h3>Files</h3>
            <div className="related-event-video-card-list">
              {videodetail.files &&
                videodetail.files.length > 0 &&
                videodetail.files.map((file) => {
                  const extension = file.url.split(".").pop().toLowerCase();
                  const fileType = () => {
                    switch (extension) {
                      case "png":
                      case "jpg":
                      case "jpeg":
                      case "gif":
                      case "webp":
                      case "svg":
                      case "heic":
                        return (
                          <ImageIcon scale={1.67} height={40} width={40} />
                        );
                      case "pdf":
                        return <PdfIcon scale={1.67} height={40} width={40} />;
                      case "doc":
                        return <DocIcon scale={1.67} height={40} width={40} />;
                      case "ppt":
                        return <PptIcon scale={1.67} height={40} width={40} />;
                      case "mp4":
                      case "mov":
                      case "wmv":
                      case "avi":
                      case "mkv":
                      case "webm":
                      case "flv":
                        return (
                          <VideoFileIcon scale={1.67} height={40} width={40} />
                        );
                      case "mp3":
                      case "wav":
                      case "aac":
                        return (
                          <AudioFileIcon scale={1.67} height={40} width={40} />
                        );
                      default:
                        return (
                          <NotDefinedFileIcon
                            scale={1.67}
                            height={40}
                            width={40}
                          />
                        );
                    }
                  };
                  return (
                    <>
                      <DocSmallCard
                        alignCenter={true}
                        hasDate={false}
                        thumbType={"file"}
                        onClick={(e) => handleImagePreview(e, file)}
                        thumbUrl={fileType()}
                        title={file.name}
                        hasDotMenu={false}
                        hasView={false}
                      />
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      {videodetail.relevant_partners &&
        videodetail.relevant_partners.length > 0 && (
          <div className="tab-conten">
            <div className="text-wrapper">
              <h3>Partners</h3>
              <div className="related-event-video-card-list">
                {videodetail.relevant_partners &&
                  videodetail.relevant_partners.length > 0 &&
                  videodetail.relevant_partners.map((partner) => {
                    return (
                      <>
                        <SponsorSmallCell
                          alignCenter={true}
                          url={partner.url}
                          target="_blank"
                          thumbUrl={
                            partner.logo
                              ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                              partner.logo
                              : mdsLogo
                          }
                          hasSponsorType={false}
                          title={partner.name}
                          videoType="Platinum Sponsor"
                          thumbType={"sponsor"}
                          hasDotMenu={false}
                        />
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      {previewData && (
        <MediaPreview
          mediaPreviewData={previewData}
          showPreview={showPreview}
          handleClosePreview={handleClosePreview}
          mediaPreviewType={filePreview}
          selectedImageVideo={previewData}
          showFileData={showFileData}
          previewAttachmentDisplay={true}
          previewFrom="contentLibrary"
        />
      )}
    </>
  );
};

export default DetailsTab;
