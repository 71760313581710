import React from "react";

function IconNavGroup() {
  return (
    <div className="mds-icons">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M8.819 8.569A3.072 3.072 0 0 1 11.887 5.5a3.073 3.073 0 0 1 3.069 3.069c0 1.693-1.39 3.069-3.082 3.069-1.672-.006-3.062-1.395-3.055-3.069Zm3.04 4.569h.028c2.52 0 4.569-2.05 4.569-4.569A4.574 4.574 0 0 0 11.886 4 4.573 4.573 0 0 0 7.32 8.566c-.01 2.486 2.056 4.564 4.54 4.572Zm5.286-1.637a.75.75 0 0 0 .846.639 3.642 3.642 0 0 0 3.12-3.584 3.611 3.611 0 0 0-3.035-3.576.75.75 0 0 0-.244 1.48 2.115 2.115 0 0 1 1.779 2.094 2.133 2.133 0 0 1-1.827 2.1.75.75 0 0 0-.64.847Zm3.716 7.03a.75.75 0 0 1-.267-1.45c.678-.259.678-.544.678-.68 0-.439-.557-.745-1.655-.909a.752.752 0 0 1-.639-.741v-.002a.75.75 0 0 1 .862-.74c2.39.357 2.918 1.469 2.932 2.354v.073c-.01.55-.244 1.513-1.644 2.046a.759.759 0 0 1-.267.05Zm-8.974-2.617c-2.376 0-5.21.292-5.21 1.682 0 1.4 2.834 1.695 5.21 1.695s5.208-.291 5.208-1.678c0-1.404-2.832-1.7-5.208-1.7Zm0 4.877c-1.66 0-6.71 0-6.71-3.195 0-3.182 5.05-3.182 6.71-3.182 1.659 0 6.708 0 6.708 3.199 0 3.178-4.873 3.178-6.708 3.178Zm-6.001-8.645a.737.737 0 0 1-.104-.007 3.641 3.641 0 0 1-3.12-3.582A3.612 3.612 0 0 1 5.699 4.98a.75.75 0 0 1 .244 1.48 2.116 2.116 0 0 0-1.78 2.096 2.132 2.132 0 0 0 1.827 2.098.75.75 0 0 1-.103 1.493Zm-3.241 6.336a.75.75 0 0 0 .534-1.402c-.679-.259-.679-.543-.679-.68 0-.438.557-.744 1.655-.908a.752.752 0 0 0 .631-.854.755.755 0 0 0-.853-.63C1.509 14.371 1 15.51 1 16.4c0 .543.214 1.536 1.645 2.082Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
}

export default IconNavGroup;
