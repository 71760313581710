const popup_trigger = false;

const ReduMigrateUserPopup = (state = popup_trigger, action) => {
  switch (action.type) {
    case "OpenPopup":
      return true;
    case "ClosePopup":
      return false;
    default:
      return state;
  }
};

export default ReduMigrateUserPopup;
