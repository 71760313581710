import React, { useState, useCallback, useEffect } from 'react';
import ClosePopup from "../Images/close-popup.svg";
import CropImage from "../Images/cropimage.png";
// import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, {
  centerCrop,
  makeAspectCrop
} from 'react-image-crop'

export default function MediaCropPopup({ filterData, resetFilterData, cropType, setCroppedImageUrl }) {
  console.log(cropType, "cropType")
  const [crop, setCrop] = useState({
    aspect: cropType === "Square" ? 1 / 1 : 16 / 9,
    unit: '%',
    x: 25,
    y: 25,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [image, setImage] = useState('');

  useEffect(() => {
    if (filterData.length > 0) {
      const imageUrl = filterData[0].urls.length > 1 ? filterData[0].urls[4]?.url : filterData[0].urls[0]?.url;
      const width = filterData[0].urls.length > 1 ? filterData[0].urls[4]?.width : filterData[0].urls[0]?.width;

      const height = filterData[0].urls.length > 1 ? filterData[0].urls[4]?.height : filterData[0].urls[0]?.height;

      const aspectRatio = cropType === "Square" ? 1 / 1 : 16 / 9
      setImage(imageUrl);
      setCrop(centerAspectCrop(width, height, aspectRatio))
    }
  }, [filterData]);


  function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 50,
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

  const handleCropChange = (newCrop) => {
    const minWidth = 100; // Set your minimum width
    const minHeight = 100; // Set your minimum height

    // Ensure the width and height are not below the minimum values
    if (newCrop.width < minWidth || newCrop.height < minHeight) {
      // Adjust the crop area to meet the minimum dimensions
      setCrop((prevCrop) => ({
        ...prevCrop,
        width: Math.max(newCrop.width, minWidth),
        height: Math.max(newCrop.height, minHeight),
      }));
    } else {
      // Update the crop area
      setCrop(newCrop);
    }
  };

  const handleCropSubmit = async () => {
    const originalImage = document.querySelector('.crop-media-image');
    originalImage.crossOrigin = 'anonymous';

    // Wait for the image to load
    await new Promise((resolve) => {
      originalImage.onload = resolve;
    });

    let croppedImageBlob;
    if (completedCrop) {
      console.log('Cropped Image:', completedCrop);
      croppedImageBlob = await getCroppedBase64(
        originalImage,
        completedCrop,
        "crop-image" + new Date().getTime() + ".jpg"
      );
    } else {
      console.log('Cropped Image:', crop);
      croppedImageBlob = await getCroppedBase64(
        originalImage,
        crop,
        "crop-image" + new Date().getTime() + ".jpg"
      );
    }
    $("#mediaCropModel").modal("hide");
    // Display the cropped image
    const croppedImageUrl = croppedImageBlob;
    setCroppedImageUrl(croppedImageUrl);
    // Call the resetFilterData function from the parent component
    resetFilterData();
  };


  async function getCroppedBase64(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const cropInPercentage = {
      aspect: crop.aspect,
      unit: '%',
      width: crop.width,
      height: crop.height,
      x: crop.x,
      y: crop.y,
    };

    // Get the actual dimensions of the image
    const imageWidth = image.naturalWidth;
    const imageHeight = image.naturalHeight;

    // Convert percentage values to pixels
    const cropInPixels = {
      aspect: cropInPercentage.aspect,
      unit: 'px', // Change to 'px' to use pixel values
      width: (cropInPercentage.width / 100) * imageWidth,
      height: (cropInPercentage.height / 100) * imageHeight,
      x: (cropInPercentage.x / 100) * imageWidth,
      y: (cropInPercentage.y / 100) * imageHeight,
    };

    canvas.width = cropInPixels.width;
    canvas.height = cropInPixels.height;

    ctx.drawImage(
      image,
      cropInPixels.x,
      cropInPixels.y,
      cropInPixels.width,
      cropInPixels.height,
      0,
      0,
      cropInPixels.width,
      cropInPixels.height
    );

    // Convert the canvas content to a base64-encoded string
    const croppedImageUrl = canvas.toDataURL('image/jpeg');
    return croppedImageUrl;
  }

  function onLoad(img) {
    setImage(img);
    setCrop({
      aspect: cropType === "Square" ? 1 / 1 : 16 / 9,
      unit: '%',
      width: cropType === "Square" ? 30 : 50,
      height: cropType === "Square" ? 30 : 50,
      x: 25,
      y: 25,
    });
  }


  const onCropComplete = useCallback((crop, percentCrop) => {
    setCompletedCrop(percentCrop);
    setCrop(percentCrop);
  }, []);

  const onClickChangeImage = () => {
    setCrop({
      aspect: cropType === "Square" ? 1 / 1 : 16 / 9,
      unit: '%',
      width: cropType === "Square" ? 30 : 50,
      height: cropType === "Square" ? 30 : 50,
      x: 25,
      y: 25,
    });
    $("#mediaCropModel").modal("hide");
  }

  return (
    <div
      className="modal fade media-attachment-details"
      id="mediaCropModel"
      data-backdrop="static"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="media-attachment-details-header">
            <h4 className="madh-h4">Select view area</h4>
            <button type="button" className="madh-close" onClick={(e) => {
              $("#mediaCropModel").modal("hide");
              resetFilterData();
            }}>
              <img src={ClosePopup} />
            </button>
          </div>

          <div className="crop-media-body">
            {image && (
              <ReactCrop crop={crop} keepSelection={true} aspect={cropType === "Square" ? 1 / 1 : 16 / 9} onChange={handleCropChange}
                onImageLoad={onLoad}
                onComplete={onCropComplete}>
                <img src={image} className="crop-media-image" />
              </ReactCrop>
            )}
            {/* <img src={image} className="crop-media-image" /> */}
          </div>
          <div className="crop-media-footer">
            <button className="change-image-btn" data-toggle="modal"
              data-target="#chooseMediaModel" onClick={onClickChangeImage}>Change image</button>
            <button className="upload-media-btn" onClick={handleCropSubmit}>Done</button>
          </div>
        </div>
      </div>
    </div>
  );
}
