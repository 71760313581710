import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Loader from "../../../Images/loader.gif";
import { TablePagination } from "@mui/material";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import authHeaderChannel from "../../../Services/auth.header.channel";
import { useLocation, useNavigate } from "react-router-dom";
import SearchAndSorting from "../../AdminCommonComponent/TableComponent/SearchAndSorting";
import TableBodyComponent from "../../AdminCommonComponent/TableComponent/TableBodyComponent";
import authService from "../../../Services/auth.service";
import { useDispatch } from "react-redux";
import ModalPopup from "../../ModalPopup/ModalPopup";

const columns = [
  { id: "name", label: "Name" },
  { id: "eventName", label: "Event name" },
  { id: "userCount", label: "User count" },
  { id: "action", label: "Actions", width: 3 },
];

function createData(srNo, name, eventName, userCount, action) {
  return {
    srNo,
    name,
    userCount,
    eventName,
    action,
  };
}

//Channel Listing component
const Channel = () => {
  const [rows, setRow] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [allEvents, setallEvents] = useState([]);
  const [tableAlertMsg, setTableAlertMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const dispatch = useDispatch();
  const location = useLocation().search;
  const searchText = "";
  const [showIndividualData, setshowIndividualData] = useState("");
  const [searchby, setsearchby] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const search = new URLSearchParams(location).get("search");

  //get all news list
  const getAllChannelApi = (searchText, col, sort) => {
    const sortFieldName = col === "name" ? "channelName" : col;
    let query = "";
    query +=
      searchText !== undefined && searchText !== null && searchText !== "null"
        ? `?search=${searchText.trim()}`
        : "";
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${sortFieldName}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    authHeaderChannel
      .getAllChannelList(query)
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setshowIndividualData(data);
          var tbl = [];
          for (var i = 0; i < data.length; i++) {
            tbl[i] = createData(
              i + 1,
              data[i].channelName,
              data[i].eventsName !== undefined &&
                data[i].eventsName !== null &&
                data[i].eventsName !== ""
                ? data[i].eventsName
                : "-",
              data[i].userCount,
              data[i]._id
            );
          }

          setRow(tbl);
          setallEvents(tbl);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setLoader(true);
    getAllChannelApi(search);
    getallusersSuggestionListApi();
  }, []);

  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getChannelSuggestionList();
      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.channelName }));
        setSuggestionList(arr);
      }
    } catch (err) { }
  }
  useEffect(() => {
    setTimeout(() => {
      setTableAlertMsg("");
    }, 3000);
  }, [tableAlertMsg]);

  useEffect(() => {
    setLoader(true);
    getAllChannelApi(search);
    getallusersSuggestionListApi();
  }, []);

  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getChannelSuggestionList();
      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.channelName }));
        setSuggestionList(arr);
      }
    } catch (err) { }
  }

  //handle delete Channel
  const handleDelete = () => {
    authHeaderChannel
      .deleteChannel(deleteId)
      .then((res) => {
        if (res.data.status) {
          var tbl = [];
          var n = 0;
          for (var i = 0; i < allEvents.length; i++) {
            if (allEvents[i].action !== deleteId) {
              tbl[n] = createData(
                i + 1,
                allEvents[i].name,
                allEvents[i].eventName,
                allEvents[i].userCount,
                allEvents[i].action
              );
              n++;
            }
          }
          setallEvents(tbl);
          setRow(tbl);
          authHeaderChannel.deleteChannelSendMessage({
            memberIds: res.data.chatData.memberIds,
            channelId: res.data.chatData.channelId,
          });
          setShowDeleteModal(false);
        }

        setTableAlertMsg(res.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
      setInProcess(false)
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setTimeout(() => {
      setTableAlertMsg("");
    }, 3000);
  }, [tableAlertMsg]);

  //create object from array
  const createObjectFromArray = (arr, updateAllEventArray = true) => {
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].srNo,
        arr[i].name,
        arr[i].eventName,
        arr[i].userCount,
        arr[i].action
      );
    }
    setPage(0);
    setRow(tbl);
    if (updateAllEventArray) setallEvents(tbl);
  };

  const deleteChannelrName = (deleteId) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].action === deleteId) {
        return rows[i].name;
      }
    }
  };
  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>All Channels</h1>
            <div className="uic-title-rt-only">
              <button
                className="btn"
                onClick={() => {
                  navigate("/channel/createChannel");
                }}
              >
                Add channel
              </button>
            </div>
          </div>
          <SearchAndSorting
            currentArray={allEvents}
            createObjectFromArray={createObjectFromArray}
            sortingOn={"name"}
            suggestionOn={"name"}
            searchOn={["name"]}
            placeholderText={"Search"}
            suggestionList={suggestionList}
            setSuggestions={setSuggestions}
            setListIndex={setListIndex}
            listIndex={listIndex}
            setSuggestionBox={setSuggestionBox}
            suggestions={suggestions}
            recallApi={getAllChannelApi}
            suggestionBox={suggestionBox}
          />

          {tableAlertMsg && (
            <div className="alert alert-info">{tableAlertMsg}</div>
          )}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table min-w-auto">
                <TableHeader
                  columns={columns}
                  createObjectFromArray={createObjectFromArray}
                  allEventList={allEvents}
                  columnsWithoutSoritng={["image", "action", "drag"]}
                  listApi={getAllChannelApi}
                  setLoader={setLoader}
                  searchText={""}
                />

                {loader ? (
                  <div className="cstm-data-load">
                    <img src={Loader} alt="" />{" "}
                  </div>
                ) : rows.length > 0 &&
                  rows?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).length > 0 ? (
                  <TableBodyComponent
                    rows={rows}
                    rowsPerPage={rowsPerPage ? rowsPerPage : 0}
                    page={page ? page : 0}
                    columns={columns}
                    editCallBack={(value) => {
                      navigate(`/channel/detail?id=${value}`);
                      dispatch({
                        type: "NAVIGATEPAGE",
                        payload: `/channel/channelListing?search=${search}`,
                      });
                    }}
                    setDeleteId={(id) => {
                      setDeleteId(id);
                      setShowDeleteModal(true);
                      setInProcess(false)
                    }}
                  />
                ) : (
                  <span className="no-data-found">No data found</span>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(e, newPage) => setPage(newPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        hasLoading={inProcess}
        disabled={inProcess}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        buttonText1="Delete"
        isOpen={showDeleteModal}
        btn2variant="danger"
        className=""
        handleClick2ndButton={()=>{handleDelete();setInProcess(true)}}
        headingLable={"Delete channel"}
      >
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{deleteChannelrName(deleteId)}
            "? This action cannot be undone.
          </p>
        </div>
      </ModalPopup>
      {/* <DeleteModal message={"Are you sure you want to delete this channel?"} handleDelete={handleDelete} /> */}
    </>
  );
};
export default Channel;
