export const emptyObject = {
  name: "",
  description: "",
  quantityPerOrder: "",
  priceType: "",
  maxQuantity: "",
  minQuantity: "",
  variationType: "",
  free: "",
  paid: "",
  salePrice: "",
  salesValue: "",
  visibility: "",
  saleStart: "",
  saleEnd: "",
  visibilityStartDate: "",
  visibilityEndDate: "",
  image: "",
  mainAmount: "",
  visibilityStartTime: "",
  visibilityEndTime: "",
  salesStartTime: "",
  salesEndTime: "",
};
