import React, { useEffect, useState } from 'react'
import "../../Styles/Theme/Theme.css"
import ModelPopup from '../ModalPopup/ModalPopup';
import CrossIcon from '../Icons/GeneralIcons/CrossIcon';
import LocalTextField from '../InputField/TextField';
import Button from '../Button/Button';
import { Link } from 'react-router-dom'
import { setModalIsClose } from '../../Action';
import IconSuccessGreen from "../../components/GlobalComponents/Icons/IconEmptyState/IconSuccessGreen";
import { useDispatch } from "react-redux";

const ConfirmReferralModal = ({ setIsConfirmReferralModalOpen,setShowbtnLoader }) => {
    const dispatch = useDispatch();

    // close modal when hit Esc button
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                dispatch(setModalIsClose());
            }
        };
        document.addEventListener("keydown", handleEsc);
        return () => {
            document.removeEventListener("keydown", handleEsc);
        };
    }, [dispatch]);

    // call when invitemore button clicked
    const inviteMoreHandler = async () => {
        setShowbtnLoader(false)
        setIsConfirmReferralModalOpen(false)
    };

    return (
        <>
            <ModelPopup
                handleClick2ndButton={""}
                onClose={""}
                isFooter={false}
                isHeader={false}
                modelSize="480"
                isOpen={true}
                headingLable=""
                hasCross={true}
                hasContentCenter={true}
                disabled={false}
                buttonText="Keep order"
                buttonText1="Cancel order"
                spinnersType="white"
                btn2variant="danger"
                hasLoading={true}
                hasTextString={"Read more about the"}
                buttonType={"fluidbutton"}
            >
                <div className="cstm-modal-referal-body scrollbar" id="refer-box-sec">
                    <div className='modal-close-btn'>
                        <Button
                            size='small'
                            variant='ghost'
                            elementIcon={<CrossIcon />}
                            handleClick={() => {
                                setIsConfirmReferralModalOpen(false);
                                dispatch(setModalIsClose())
                            }}
                            hasText={false}
                        />
                    </div>
                    <div className='modal-success'>
                        <div className='modal-success-icon'>
                            <IconSuccessGreen />
                        </div>
                        <div className='modal-success-text'>
                            <h2 className="modal-success-title">Invites sent</h2>
                            <p className='modal-success-disc'>You'll receive a $1,000 account credit for each member you refer who stays with us for at least three months.</p>
                        </div>
                        <div className='modal-success-button w-100'>
                            <Button
                                size='medium'
                                extraClass='w-100 '
                                variant='secondary'
                                hasIconLeft={false}
                                handleClick={inviteMoreHandler}
                                hasText={true}
                                label='Invite more'
                            />
                        </div>
                    </div>
                </div>
            </ModelPopup>
        </>
    )
}

export default ConfirmReferralModal