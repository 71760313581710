import React from 'react'
import { Link } from 'react-router-dom'
import VideoThumbnailSmallImage from './VideoThumbnailSmallImage'
import Button from '../../Button/Button'
import DotsIcon from '../../Icons/GeneralIcons/DotsIcon'

const VideoXSmallCard = ({
    url = "/",
    hasSpacings = false,
    thumbUrl,
    cardSize = "small",
    title = 'Video Title Text',
    views = '0',
    uplodedDate = "2 Months ago",
    videoType = "Summit",
    thumbType,
    hasDate = false,
    hasVideoType = false,
    hasDuration,
    duration,
    onClick,
    hasDotMenu = false,
    hasView = false,
    alignCenter = false,
    target = ""
}) => {
    return (
        <>
            <div className={`position-relative ${hasDotMenu ? 'has-dotmenu' : ''}`}>
                <Link to={url} target={target} className={`video-card ${cardSize} ${alignCenter ? 'align-items-center' : ''} ${hasSpacings ? 'card-spacing' : ''}`} onClick={onClick}>
                    <VideoThumbnailSmallImage thumbUrl={thumbUrl} hasDuration={hasDuration} duration={duration} thumbType={thumbType} />
                    <div className='video-card-text'>
                        <h6 className='video-card-title'>{title}</h6>
                        {(hasDate || hasView) && <div class="video-details-view-date">
                            {hasView && <span class="video-view-text">{views}</span>}
                            {hasDate && <span class="video-uploded-date">{uplodedDate}</span>}
                        </div>}
                        {hasVideoType && <p className='video-type-text'>{videoType}</p>}
                    </div>
                </Link>
                {hasDotMenu && <Button
                    variant="ghost"
                    extraClass="video-dot-btn ml-auto align-self-start"
                    size={"small"}
                    handleClick={() => { }}
                    hasText={false}
                    elementIcon={<DotsIcon />}
                />}
            </div>
        </>
    )
}

export default VideoXSmallCard
