import axios from "axios";
import authHeader from "../Services/auth.header";
const API_URL = process.env.REACT_APP_API_URL;

const sendReferalEmails = (data) => {
  return axios.post(API_URL + 'email/sendEmailToUser', data, { headers: authHeader() });
}

export default {
  sendReferalEmails
}