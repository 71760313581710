import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../Images/loader.gif";
import moment from "moment";

const PageViewEventModal = ({ loader, pageViewList, setShowPageViewModal }) => {
  const ticketColumns = [
    { id: "number", label: "No." },
    { id: "userName", label: "User Name" },
    { id: "views", label: "Views" },
    { id: "lastViewDate", label: "Last View Date" },
  ];

  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(20);

  // Pagination onChange function
  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage);
  };

  return (
    <>
      <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
        <div className="desk-globle-model-box-inner">
          <div className="modal-content">
            <div class="cstm-replace-pp">
              <span
                class="cstm-rpp-close-btn"
                onClick={() => {
                  setShowPageViewModal(false);
                  document.body.classList.remove("modal-open");
                }}
              >
                ×
              </span>
              <div class="cstm-rpp-title">
                Total number of Event Views based on Users
              </div>
              <div className="cstm-cstm-viewlist">
                <Paper className="uic-tb-mn ">
                  <TableContainer className=" uic-table-main ">
                    <Table className="">
                      <TableHead className="uic-table-th">
                        <TableRow>
                          {ticketColumns.map((column) => (
                            <TableCell className="uic-th-td" key={column.id}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className="uic-table-td">
                        {loader ? (
                          <div className="cstm-data-load">
                            <img src={Loader} />
                          </div>
                        ) : pageViewList?.length > 0 &&
                          pageViewList !== undefined ? (
                          pageViewList
                            .slice(
                              userPage * userRowsPerPage,
                              userPage * userRowsPerPage + userRowsPerPage
                            )
                            .map((pageView, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={pageView.id}
                                >
                                  <TableCell className="uic-th-td">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {pageView.user}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {pageView.viewCount}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {moment(pageView.lastViewAt).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <span className="no-data-found-pl">
                            No data found
                          </span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={pageViewList !== undefined ? pageViewList.length : 0}
                    rowsPerPage={userRowsPerPage}
                    page={userPage}
                    onPageChange={handleChangeUserPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageViewEventModal;
