import axios from "axios";
import authAdminHeader from "./auth.admin.header";
const API_URL = process.env.REACT_APP_API_URL;

const addPartner = (data) => {
    return axios.post(API_URL + 'partner/create', data, { headers: authAdminHeader() });
}

const getAllPartner = (page) => {
    return axios.get(API_URL + 'partner/list' + page, { headers: authAdminHeader() })
}

const getPartnerList = (query) => {
    return axios.get(API_URL + 'allPartner/list' + query, { headers: authAdminHeader() })
}

const deletePartner = (id) => {
    return axios.patch(API_URL + 'partner/delete/' + id, {}, { headers: authAdminHeader() })
}

const getPartnerDetail = (id) => {
    return axios.get(API_URL + 'partner/detail/' + id, { headers: authAdminHeader() })
}

const editPartner = (id, data) => {
    return axios.post(API_URL + 'partner/edit/' + id, data, { headers: authAdminHeader() })
}

const addHelpfulLink = (data) => {
    return axios.post(API_URL + 'partner/helpfullinks/create', data, { headers: authAdminHeader() })
}

const getAllLink = (id, query) => {
    return axios.get(API_URL + 'partner/helpfullinks/list/' + id + query, { headers: authAdminHeader() })
}

const getLinkDetail = (id) => {
    return axios.get(API_URL + 'partner/helpfullinks/detail/' + id, { headers: authAdminHeader() })
}

const editLink = (id, data) => {
    return axios.post(API_URL + 'partner/helpfullinks/edit/' + id, data, { headers: authAdminHeader() })
}

const editReason = (data) => {
    return axios.post(API_URL + 'partner/editReason/' + data.id, data.data, { headers: authAdminHeader() })
}

const deleteLink = (id) => {
    return axios.patch(API_URL + 'partner/helpfullinks/delete/' + id, {}, { headers: authAdminHeader() })
}

const getReasonListByPartnerId = (id) => {
    return axios.get(API_URL + 'partner/gerReasonListByPartnerId/' + id, { headers: authAdminHeader() })
}
const allReasonList = (query) => {
    return axios.get(API_URL + 'partner/getReasonList' + query, { headers: authAdminHeader() })
}
const createReason = (data) => {
    return axios.post(API_URL + 'partner/createReason', data, { headers: authAdminHeader() })
}

const getReasonDetailById = (id) => {
    return axios.get(API_URL + 'partner/getReasonDetail/' + id, { headers: authAdminHeader() })
}
const addPartnerPost = (data) => {
    console.log(data, 'dsdsf')
    return axios.post(API_URL + 'partner/post/create', data, { headers: authAdminHeader() });
}

const getAllPartnerPostByPartnerId = (partnerId, query) => {
    return axios.get(API_URL + 'partner/post/list/' + partnerId + query, { headers: authAdminHeader() })
}

const getPartnerPostDetail = (id) => {
    return axios.get(API_URL + 'partner/post/detail/' + id, { headers: authAdminHeader() })
}


const deletePartnerPost = (id) => {
    return axios.patch(API_URL + 'partner/post/delete/' + id, {}, { headers: authAdminHeader() })
}

const applyUrlToAllPartnerPosts = (data) => {
    return axios.post(API_URL + 'partner/post/applyurltoposts', data, { headers: authAdminHeader() })
}

const editPartnerPost = (data) => {

    return axios.post(API_URL + 'partner/post/edit/' + data.id, data, { headers: authAdminHeader() })
}

const getMemberList = () => {
    return axios.get(API_URL + 'user/getallusers', { headers: authAdminHeader() })
}

const applyUrlToAllPostsBtn = (data) => {
    return axios.post(API_URL + 'partner/post/applyurltoallpostsbtn', data, { headers: authAdminHeader() })
}

const getUnFeaturedPartnerList = () => {
    return axios.get(API_URL + 'partner/getPublishedPartnersList', { headers: authAdminHeader() })
}

const addFeaturedPartner = (data) => {
    return axios.post(API_URL + 'partner/addFeaturedOrFreshDealPartners', data, { headers: authAdminHeader() })
}

const getFeaturedPartnerList = (type) => {
    return axios.get(API_URL + 'partner/getFeaturedOrFreshdealPartnersList' + type, { headers: authAdminHeader() })
}

const removeFeaturedPartner = (id) => {
    return axios.patch(API_URL + 'partner/removeFeaturedOrFreshDealPartners/' + id, {}, { headers: authAdminHeader() })
}

const reorderFeaturedPartner = (id) => {
    return axios.post(API_URL + 'partner/reorderFeaturedPartner', id, { headers: authAdminHeader() })
}

const partnersListForFreshDeal = () => {
    return axios.get(API_URL + 'partner/get/partnersListForFreshDeal', { headers: authAdminHeader() })
}

const addFreshDealPartner = (ids) => {
    return axios.post(API_URL + 'partner/addFreshDealPartner', ids, { headers: authAdminHeader() })
}

const freshDealPartnersList = () => {
    return axios.get(API_URL + 'partner/get/freshDealPartnersList', { headers: authAdminHeader() })
}

const removeFreshDealPartner = (id) => {
    return axios.patch(API_URL + 'partner/removeFeaturedOrFreshDealPartners/' + id, {}, { headers: authAdminHeader() })
}

const reorderFreshDealPartner = (ids) => {
    return axios.post(API_URL + 'partner/reorderFeaturedOrFreshdealPartner', ids, { headers: authAdminHeader() })
}

const updatePartnerStatus = (id) => {
    return axios.patch(API_URL + 'updateStatusPartner/' + id, {}, { headers: authAdminHeader() })
}

const allReviewList = (query) => {
    return axios.get(API_URL + 'partnerReview/list' + query, { headers: authAdminHeader() })
}

const reviewListByPartnerId = (id, query) => {
    return axios.get(API_URL + 'reviewsByPartner/' + id + query, { headers: authAdminHeader() })
}

const reviewDelete = (id) => {
    return axios.patch(API_URL + 'deleteReview/' + id, {}, { headers: authAdminHeader() })
}

const approveOrRejectReview = (id, data) => {
    return axios.post(API_URL + 'approveOrRejectReview/' + id, data, { headers: authAdminHeader() })
}

const getNotSelectedCategoryForPartner = () => {
    return axios.get(API_URL + 'partner/getNotSelectedCategoryListForPartner', { headers: authAdminHeader() })
}

const getSelectedCategoryForPartner = () => {
    return axios.get(API_URL + 'partner/getSelectedCategoryListForPartner', { headers: authAdminHeader() })
}

const reorderCategoryForPartner = (ids) => {
    return axios.post(API_URL + 'partner/redorderCategoryForPartnerWithOrder', ids, { headers: authAdminHeader() })
}


const addCategoryForPartnerWithOrder = (ids) => {
    return axios.post(API_URL + 'partner/addCategoryForPartnerWithOrder', ids, { headers: authAdminHeader() })
}

const removeSingleCategoryForPartner = (id) => {
    return axios.patch(API_URL + 'partner/removeSingleCategoryForPartner/' + id, {}, { headers: authAdminHeader() })
}
const getAllVideoList = () => {
    return axios.get(API_URL + 'getContentVideolistForPartner', { headers: authAdminHeader() })
}

const addRelatedvideo = (body) => {
    return axios.post(API_URL + 'addVidosInPartners', body, { headers: authAdminHeader() })
}

const updateRelatedVideoStatus = (id) => {
    return axios.post(API_URL + 'partner/updateRelatedVideoStatus/' + id, {}, { headers: authAdminHeader() })
}

const getVideoByPartner = (id) => {
    return axios.get(API_URL + 'getVideoListByPartner/' + id, { headers: authAdminHeader() })
}

const deleteVideo = (id) => {
    return axios.patch(API_URL + 'removeVidosFromPartner/' + id, {}, { headers: authAdminHeader() })
}
const deleteReason = (id) => {
    return axios.patch(API_URL + 'partner/deleteReason/' + id, {}, { headers: authAdminHeader() })
}
const badgeList = () => {
    return axios.get(API_URL + 'partner/Badge/list', { headers: authAdminHeader() })
}
const createBadge = (data) => {
    return axios.post(API_URL + "partner/Badge/create", data, { headers: authAdminHeader() })
}
const getBadgeById = (id) => {
    return axios.get(API_URL + 'partner/Badge/detail/' + id, { headers: authAdminHeader() })
}
const editBadge = (id, data) => {
    return axios.post(API_URL + 'partner/Badge/edit/' + id, data, { headers: authAdminHeader() })
}
const deleteBadge = (id) => {
    return axios.patch(API_URL + 'partner/Badge/delete' + "", id, { headers: authAdminHeader() })
}
const clonePartner = (data) => {
    return axios.post(API_URL + 'partner/clonePartner/', data, { headers: authAdminHeader() })
}
const allBadgeList = () => {
    return axios.get(API_URL + 'partner/Badge/partnerBadgelist', { headers: authAdminHeader() })
}

const getAllPartnerViewUsersList = (id) => {
    return axios.get(API_URL + 'whoClickPartnerData/' + id, { headers: authAdminHeader() })
}

const whoClickGetDetails = (id) => {
    return axios.get(API_URL + 'whoClickGetDetails/' + id, { headers: authAdminHeader() })
}
const badgeReorder = (id) => {
    return axios.post(API_URL + 'partner/badgesReorder', id, { headers: authAdminHeader() })
}

const getPartnerDetailForPreview = (id) => {
    return axios.get(API_URL + "getPartnerDetailsForAdmin/" + id, { headers: authAdminHeader() })
}
const getPartnerDetailReviewForPreview = (id) => {
    return axios.get(API_URL + 'getPartnerReviewDetailsForAdmin/' + id, { headers: authAdminHeader() })
}

const getPartnerDetailPostsForPreview = (id) => {
    return axios.get(API_URL + 'getPartnerDetailPostsForAdmin/' + id, { headers: authAdminHeader() })
}
const getPartnerDetailVideosForPreview = (id) => {
    return axios.get(API_URL + 'getPartnerDetailVideosForAdmin/' + id, { headers: authAdminHeader() })
}
const getPartnersInOtherCategoriesForPreview = (id) => {
    return axios.get(API_URL + 'getPartnersInOtherCategoriesForAdmin/' + id, { headers: authAdminHeader() })
}

const getOfferDetailsCountForPreview = (id) => {
    return axios.get(API_URL + 'getClaimOfferDetailsForAdmin/' + id, { headers: authAdminHeader() })
}

const badgeWiseReorderPartner = (ids) => {
    return axios.post(API_URL + '/partner/badgeFilterWiseReOrderPartner', ids, { headers: authAdminHeader() })
}
const partnerSetting = (id, data) => {
    return axios.patch(API_URL + 'updatePartnerBanner/' + id, data, { headers: authAdminHeader() })
}
const getPartnerSetting = () => {
    return axios.get(API_URL + 'partnerBanner/list', { headers: authAdminHeader() })
}


const reorderVideosInPartners = (ids) => {
    return axios.post(API_URL + 'partner/reorderVideosInPartners/' + ids.partnerId, { "videoIds": ids.videoIds, "videoSortingOption": ids.videoSortingOption }, { headers: authAdminHeader() })
}

const categoryList = () => {
    return axios.get(API_URL + 'partner/categories', { headers: authAdminHeader() })
}
const createCategory = (data) => {
    return axios.post(API_URL + "partner/category/create", data, { headers: authAdminHeader() })
}
const getCategoryById = (id) => {
    return axios.get(API_URL + 'partner/category/' + id, { headers: authAdminHeader() })
}
const editCategory = (id, data) => {
    return axios.put(API_URL + 'partner/category/edit/' + id, data, { headers: authAdminHeader() })
}
const partnerCheckAlredayExistSubCategory = (data) => {
    return axios.post(API_URL + "partner/checkAlredayExistSubCategory", data, {
      headers: authAdminHeader(),
    });
  };
const deleteCategory = (id) => {
    return axios.delete(API_URL + 'partner/category/delete/' + id, { headers: authAdminHeader() })
}
const sendApproveOrRejectReviewEmail = (id) => {
    return axios.post(API_URL + 'sendApproveOrRejectReviewEmail/' + id, {}, { headers: authAdminHeader() })
}
const partnerStatisticAllCountForPartner = (field) => {
    return axios.get(API_URL + 'partnerStatisticAllCountForAdmin?field=' + field, { headers: authAdminHeader() })
}
const partnerStatisticFieldcount = (filter) => {
    return axios.get(API_URL + 'partnerStatisticFieldcountDatewiseForAdmin' + filter, { headers: authAdminHeader() })
}
const partnerStatisticListForAdmin = (filter) => {
    return axios.get(API_URL + 'partnerStatisticListForAdmin' + filter, { headers: authAdminHeader() })
}
const partnerStatisticDateWiseFilterCountForAdmin = (filter) => {
    return axios.get(API_URL + 'partnerStatisticDateWiseFilterCountForAdmin' + filter, { headers: authAdminHeader() })
}
const reportReviewCount = (id) => {
    return axios.get(API_URL + 'getPartnerReviewReportUserList/' + id, { headers: authAdminHeader() })
}
const addVideoByTag = (data) => {
    return axios.post(API_URL + 'partner/addVideosByTagInPartners', data, { headers: authAdminHeader() })
}
const restBadge = (id) => {
    return axios.get(API_URL + `partner/Badge/restPartnerBadges/${id}`, { headers: authAdminHeader() })
}
const editSubCategory = (id, data) => {
    return axios.put(API_URL + "partner/subCategory/edit/" + id, data, {
      headers: authAdminHeader(),
    });
  };
export default {
    reportReviewCount,
    addVideoByTag,
    partnerStatisticDateWiseFilterCountForAdmin,
    partnerStatisticListForAdmin,
    partnerStatisticAllCountForPartner,
    partnerStatisticFieldcount,
    updateRelatedVideoStatus,
    sendApproveOrRejectReviewEmail,
    addPartner,
    badgeReorder,
    getAllPartner,
    deletePartner,
    getPartnerDetail,
    getPartnerList,
    editPartner,
    addHelpfulLink,
    getAllLink,
    getLinkDetail,
    editLink,
    deleteLink,
    getReasonListByPartnerId,
    editReason,
    createReason,
    getReasonDetailById,
    addPartnerPost,
    getAllPartnerPostByPartnerId,
    deletePartnerPost,
    getPartnerPostDetail,
    applyUrlToAllPartnerPosts,
    editPartnerPost,
    getMemberList,
    applyUrlToAllPostsBtn,
    addFeaturedPartner,
    getUnFeaturedPartnerList,
    getFeaturedPartnerList,
    removeFeaturedPartner,
    reorderFeaturedPartner,
    partnersListForFreshDeal,
    addFreshDealPartner,
    freshDealPartnersList,
    removeFreshDealPartner,
    reorderFreshDealPartner,
    updatePartnerStatus,
    allReviewList,
    reviewListByPartnerId,
    reviewDelete,
    approveOrRejectReview,
    getNotSelectedCategoryForPartner,
    getSelectedCategoryForPartner,
    reorderCategoryForPartner,
    addCategoryForPartnerWithOrder,
    removeSingleCategoryForPartner,
    allReasonList,
    getAllVideoList,
    addRelatedvideo,
    getVideoByPartner,
    deleteVideo,
    deleteReason,
    badgeList,
    createBadge,
    getBadgeById,
    editBadge,
    deleteBadge,
    clonePartner,
    allBadgeList,
    getAllPartnerViewUsersList,
    whoClickGetDetails,
    getPartnerDetailForPreview,
    getPartnerDetailReviewForPreview,
    getPartnerDetailPostsForPreview,
    getPartnerDetailVideosForPreview,
    getPartnersInOtherCategoriesForPreview,
    getOfferDetailsCountForPreview,
    badgeWiseReorderPartner,
    partnerSetting,
    getPartnerSetting,
    reorderVideosInPartners,
    categoryList,
    createCategory,
    editCategory,
    partnerCheckAlredayExistSubCategory,
    deleteCategory,
    getCategoryById,
    restBadge,
    editSubCategory
}