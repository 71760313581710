export const getFormatedListForUserAndAttendees = (unFormattedList) => {
  if (!unFormattedList || !Array.isArray(unFormattedList)) {
    console.error("Invalid input data:", unFormattedList);
    return [];
  }
  const newFormatedData = unFormattedList.map((u) => {
    const fullName = u?.display_name ? `${u?.display_name}` : "";
    return {
      fullName,
      id: u._id,
      profileImg: u.profileImg ?? "",
      onlineStatus: u.onlineStatus ?? "",
    };
  });

  return newFormatedData;
};

export const getFormatedListForUser = (unFormattedList) => {
  if (!unFormattedList || !Array.isArray(unFormattedList)) {
    console.error("Invalid input data:", unFormattedList);
    return [];
  }

  const newFormatedData = unFormattedList.map((u) => {
    const fullName =
      u?.display_name ? `${u?.display_name}` : "";
    return {
      fullName,
      id: u._id,
      profileImg: u.profileImg ?? "",
      onlineStatus: u.onlineStatus ?? "",
    };
  });

  return newFormatedData;
};
