import React, { useEffect, useRef, useState } from 'react'
import "../../Styles/Theme/Theme.css"
import ModelPopup from '../ModalPopup/ModalPopup';
import CrossIcon from '../Icons/GeneralIcons/CrossIcon';
import LocalTextField from '../InputField/TextField';
import Button from '../Button/Button';
import { Link } from 'react-router-dom'
import { setModalIsClose } from '../../Action';
import { useDispatch } from "react-redux";
import referEmail from '../../Services/sendReferalEmails';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import ConfirmReferralModal from '../ConfirmReferralModal/ConfirmReferralModal';

const ReferralModal = () => {
    const dispatch = useDispatch();
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const textFieldRef = useRef();

    const [referalEmailMessage, setReferalEmailMessage] = useState("")
    const [copyButtonTitle, setCopyButtonTitle] = useState("Copy")
    const [showbtnLoader, setShowbtnLoader] = useState(false)
    const [emails, setEmails] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isConfirmReferralModalOpen,setIsConfirmReferralModalOpen] = useState(false)

    const names = (localStorage.getItem("username") || "").split(" ").map(ele => ele?.trim())
    const loggedUserEmail = (localStorage.getItem("useremailid") || "")
    const fname = names[0] || "";
    const lname = names.slice(1).join(" ") || "";

    // close modal when hit Esc button
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                dispatch(setModalIsClose());
            }
        };
        document.addEventListener("keydown", handleEsc);
        return () => {
            document.removeEventListener("keydown", handleEsc);
        };
    }, [dispatch]);

    const copyHandler = () => {
        navigator.clipboard.writeText(`https://www.milliondollarsellers.com/membership?invite=${fname}_${lname}`)
        setCopyButtonTitle("Copied")
        const timer = setTimeout(() => {
            setCopyButtonTitle("Copy")
        }, 1500);
        return () => clearTimeout(timer);
    }

    // email validator
    const separateEmails = (arr) => {
        let validEmails = [];
        let invalidEmails = [];
        arr.forEach(str => {
            const emailsArr = str.split(/[ ,]+/);
            emailsArr.forEach(email => {
                if (emailRegex.test(email)) {
                    validEmails.push(email);
                } else {
                    invalidEmails.push(email);
                }
            });
        });

        return { validEmails, invalidEmails };
    }

    // call when invite button clicked
    const inviteHandler = async () => {
        setShowbtnLoader(true)
        setReferalEmailMessage("")

        //  text area Logic code
        // if (!referalEmail) {
        //     setShowbtnLoader(false)
        //     setReferalEmailMessage("Enter email");
        //     return;
        // }

        // const emailsArray = referalEmail.split(/[, \n]+/);
        // const validEmails = emailsArray.filter((email) => emailRegex.test(email));
        // const invalidEmails = emailsArray.filter((email) => !emailRegex.test(email));

        // if (invalidEmails?.length > 0) {
        //     setReferalEmailMessage("Invalid email address");
        //     setShowbtnLoader(false)
        // } else {
        //     setReferalEmailMessage("");
        //     const fullName = `${fname}_${lname}`;
        //     const data = {
        //         email: validEmails,
        //         link: `https://www.milliondollarsellers.com/membership?invite=${fullName}`,
        //     };
        //     try {
        //         const res = await referEmail.sendReferalEmails(data);
        //         if (res?.data.status) {
        //             setShowbtnLoader(false)
        //             setReferalEmailMessage(res?.data?.message)
        //             setTimeout(() => {
        //                 setReferalEmailMessage("")
        //                 res?.data?.status && dispatch(setModalIsClose())
        //             }, 1500);
        //         }
        //     } catch (error) {
        //         console.error("Error sending emails:", error);
        //     }
        // }
        // setShowbtnLoader(false)

        // multi select Logic code
        const inputElement = textFieldRef.current.querySelector('input');
        const { validEmails, invalidEmails } = separateEmails(inputElement.value?.split(' ,'));

        if (invalidEmails.filter(email => email !== "")?.length) {
            setInputValue(invalidEmails?.join(','))
            setReferalEmailMessage("Invalid email address");
            setEmails([...new Set([...validEmails, ...emails])])
            return setShowbtnLoader(false)
        } else if (validEmails?.length || emails?.length) {
            const emailsDataArray = [...new Set([...validEmails, ...emails])]
            const data = {
                email: emailsDataArray,
                memberName: `${fname} ${lname}`,
                memberEmail: loggedUserEmail,
                link: `https://www.milliondollarsellers.com/membership?invite=${fname}_${lname}`,
            };
            try {
                const res = await referEmail?.sendReferalEmails(data);
                if (res?.data.status) {
                    setTimeout(() => {
                        setIsConfirmReferralModalOpen(true)
                        setEmails([])
                        setInputValue("")
                    }, 1500);
                }
            } catch (error) {
                console.error("Error sending emails:", error);
            }
        } else {
            setReferalEmailMessage("Enter email");
            setShowbtnLoader(false)
        }
        // setShowbtnLoader(false)
    };

    //  text area(insted of Autocomplete) Logic code (go to next line and dynamic hights of text area)
    // const maxRows = 5;
    // const lineHeight = 13;
    // const maxHeight = lineHeight * maxRows;
    // function adjustHeight() {
    //     if (textbox.current) {
    //         textbox.current.style.height = "inherit";
    //         if (textbox.current.scrollHeight > maxHeight) {
    //             textbox.current.style.height = `${maxHeight}px`;
    //             textbox.current.style.overflowY = "auto";
    //         } else {
    //             textbox.current.style.height = `${textbox.current.scrollHeight}px`;
    //             textbox.current.style.overflowY = "hidden";
    //         }
    //     }
    // }

    const handleInputChange = (event, newInputValue) => {
        setReferalEmailMessage("");
        setInputValue(newInputValue);
    };

    // call when email add or remove in input field
    const handleChange = (event, newValue) => {
        const { validEmails, invalidEmails } = separateEmails(newValue);
        if (newValue.length > emails.length) {
            if (newValue.length) {
                setEmails([...new Set([...validEmails])]);
                setInputValue("")
            };
            if (invalidEmails.length) {
                setInputValue(invalidEmails?.join(','));
                return setReferalEmailMessage("Invalid email address");
            }
        } else {
            setEmails(newValue);
        }

        // focus to the next line
        const inputElement = textFieldRef?.current?.querySelector('.MuiFilledInput-root');
        const inputElements = textFieldRef?.current?.querySelectorAll('.MuiChip-root');

        if (inputElement && inputElements && inputElements.length > 0) {
            const inputRect = inputElement.getBoundingClientRect();
            let chipHeight = 0;

            inputElements.forEach((element) => {
                const rect = element.getBoundingClientRect();
                chipHeight = rect.height;
            });

            setTimeout(() => {
                inputElement.scrollTo({
                    top: inputRect.height + (Number(inputElements.length) * chipHeight),
                    behavior: "smooth"
                });
            }, 100);

            inputElement.focus();
        }
    };

    const handleKeyDown = (event) => {
        event.stopPropagation();
        if (event.keyCode === 13 || event.keyCode === 32 || event.keyCode === 188) {
            if (inputValue.trim() !== '') {
                handleChange(event, [...emails, inputValue.trim()])
            }
            event.preventDefault();
        }
    };

    return (
        <>
            {isConfirmReferralModalOpen ?
                <>
                    <ConfirmReferralModal
                        setIsConfirmReferralModalOpen={setIsConfirmReferralModalOpen}
                        setShowbtnLoader={setShowbtnLoader}
                    />
                </> :
                <ModelPopup
                    handleClick2ndButton={""}
                    onClose={""}
                    isFooter={false}
                    isHeader={false}
                    modelSize="480"
                    isOpen={true}
                    headingLable=""
                    hasCross={true}
                    hasContentCenter={true}
                    disabled={false}
                    buttonText="Keep order"
                    buttonText1="Cancel order"
                    spinnersType="white"
                    btn2variant="danger"
                    hasLoading={true}
                    hasTextString={"Read more about the"}
                    buttonType={"fluidbutton"}
                >
                    <div className="cstm-modal-referal-body scrollbar" id="refer-box-sec">
                        <div className='modal-close-btn'>
                            <Button
                                size='small'
                                variant='ghost'
                                elementIcon={<CrossIcon />}
                                handleClick={() => dispatch(setModalIsClose())}
                                hasText={false}
                            />
                        </div>
                        <div className='inner-child'>
                            <div className='d-flex flex-column align-items-center'>

                                <h2 className="referral-heading">Refer & Earn $1000</h2>
                                <p className='referal-description-heading'>Earn a $1,000 account credit for every member you refer who stays with us for at least three months.</p>
                            </div>
                            <div className='input-group refer-link-group'>
                                <LocalTextField
                                    className="text-field-search copy-filed w-100"
                                    hasLabel={false}
                                    placeholder="Link"
                                    type="text"
                                    hasRightImg={false}
                                    value={`https://www.milliondollarsellers.com/membership?invite=${fname}_${lname}`}
                                    name={""}
                                />
                                <button className="copy-button" onClick={copyHandler}><p className='copy-btn'>{copyButtonTitle}</p></button>
                            </div>
                            <div className='referal-email refer-link-group'>
                                <label htmlFor='emailField' className='invite-label'>Invite via email</label>
                                <Stack className='select-input-box'>
                                    <Autocomplete
                                        freeSolo
                                        multiple
                                        id="tags-filled"
                                        options={[]}
                                        value={emails}
                                        inputValue={inputValue}
                                        onInputChange={handleInputChange}
                                        onChange={handleChange}
                                        disableClearable={true}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                const { key, ...tagProps } = getTagProps({ index });
                                                return (
                                                    <Chip style={{ maxWidth: "340px" }} variant="standard" size="small" label={option} key={key} {...tagProps} />
                                                );
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                ref={textFieldRef}
                                                variant="filled"
                                                placeholder={!emails?.length && `Your friends emails`}
                                                onKeyDown={handleKeyDown}
                                            />
                                        )}
                                    />
                                </Stack>
                                {referalEmailMessage ? <p className="text-danger">{referalEmailMessage}</p> : ""}
                                <p className='referal-description-info'>Invite multiple friends by separating their email addresses with a comma, space, or newline.</p>
                            </div>
                            <div>
                                <Button
                                    extraClass='w-100 mt-2'
                                    variant='primary'
                                    hasIconLeft={false}
                                    handleClick={inviteHandler}
                                    hasText={true}
                                    hasLoading={showbtnLoader}
                                    label='Send invites'
                                    disabled={showbtnLoader}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="referral-link w-100">
                        <p>Read more about the <Link to="/referral" target='_blank' className='Referral-link' onClick={() => dispatch(setModalIsClose())}>MDS Referral Program</Link></p>
                    </div>
                </ModelPopup>
            }
        </>
    )
}

export default ReferralModal