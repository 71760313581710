import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"
import Services from "../../../Services/auth.service.event";
import Loader2 from "../../../Images/loader2.gif";
import AlertComponent from "../../../Hooks/AlertComponent";

//Support detail component
const Support = () => {
  const emptyObject = {
    email: "",
    phone: "",
    localPhone: "",

  };
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [editEvent, setEditEvent] = useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [editInprocess, setEditInprocess] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })

  //get all contact support listing
  const getAllContactSupportApi = () => {
    Services.getEventById(queryVariable)
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data;

          setEditEvent({ ...data, email: data.contactSupport.email, phone: data.contactSupport.phone, localPhone: data.contactSupport.localPhone })

        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getAllContactSupportApi();
    // handleClearAllStates()
  }, []);

  //email validation
  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }


  //onChange event for edit contact support
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    if (e.target.value && e.target.value.length > 0) {
      setEditErr({ ...editErr, [e.target.name]: "" });
    }
  };

  //submit edited form
  const handleEditSubmit = () => {
    var status = true;
    var temp = {};
    if (editEvent.email.trim().length === 0) {
      temp = { ...temp, email: "Enter email!" };
      status = false;
    } else if (!isValidEmail(editEvent.email)) {
      temp = { ...temp, email: "Enter a validate email id!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setEditInprocess(true);
      var data = {
        email: editEvent.email,
        phone: editEvent.phone,
        localPhone: editEvent.localPhone,
        event: queryVariable,
      };

      Services.addEditEventContactSupport(data)
        .then((res) => {
          if (res.data.status) {
            setShowAlert(true);
            setAlertMsg(res.data.message);
            setTimeout(() => {
              setShowAlert(false);
              setAlertMsg("");
            }, 3000);
            getAllContactSupportApi();
            setEditInprocess(false);
          } else {
            setShowAlert(true);
            setAlertMsg(res.data.message);
            setTimeout(() => {
              setShowAlert(false);
              setAlertMsg("");
            }, 3000);
          }
          handleClearAllStates()
          setEditInprocess(false);
        })
        .catch((e) => {
          handleClearAllStates()
          console.log(e);
          setEditInprocess(false);
        });

    } else {
      setEditErr(temp);
    }
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }
  return (
    <>
      {showAlert && <div className="alert alert-info">{alertMsg}</div>}

      <div className="form-group">
        <div className="form-group-label">Email*</div>
        <div className="form-group-ct">
          <input
            type="text"
            placeholder="Enter email"
            name="email"
            onChange={handleEditChange}
            value={editEvent.email}
          />
        </div>
        <span className="error">{editErr.email}</span>
      </div>
      <div className="form-group">
        <div className="form-group-label">Phone No.(Usa)</div>
        <div className="form-group-ct">
          <PhoneInput
            country="us"
            value={editEvent.phone}
            disableDropdown={true}
            inputClass="tel-class"
            onChange={(phoneNumber, selectedCountry) => {
              if (phoneNumber.length > 0)
                setEditErr({ ...editErr, phone: "" })
              setEditEvent({ ...editEvent, phone: phoneNumber })
            }}
            type="text"
            placeholder="Enter phone no.(Usa)"
          />
        </div>
        <span className="error">{editErr.phone}</span>
      </div>
      <div className="form-group">
        <div className="form-group-label">Phone No.(Local)</div>
        <div className="form-group-ct">
          <PhoneInput
            country="us"
            preferredCountries={["us", "au", "gb", "ae", "de"]}
            value={editEvent.localPhone}
            inputClass="tel-class"
            onChange={(phoneNumber, selectedCountry) => {
              if (phoneNumber.length > 0)
                setEditErr({ ...editErr, localPhone: "" })
              setEditEvent({ ...editEvent, localPhone: phoneNumber })
            }}
            type="text"
            placeholder="Enter phone no.(local)"
          />
        </div>
        <span className="error">{editErr.localPhone}</span>
      </div>
      {editInprocess ? (
        <div className="loader-button1">
          <button>In Process...</button>
          <span className="loader-btn">
            <img src={Loader2} />
          </span>
        </div>
      ) : (
        <button type="button" className="btn" onClick={handleEditSubmit}>
          Save
        </button>
      )}

    </>
  );
};
export default Support;
