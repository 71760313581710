import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Frame from "../../../src/Images/Frame.svg";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ClosePopup from "../../Images/close-popup.svg";
import ImageCropperReact from "../ImageCropper/ImageCropperReact";
import { useDispatch } from "react-redux";

//this component is used for single image selection and it also contains view modal so user can see the photo but clicking on it
const ProfileIcon = ({ image, setImage, title, requiredImageAspectRatio }) => {
  const dispatch = useDispatch();

  const [viewModalImage, setViewModalImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [crop, setCrop] = useState({
    aspect: 1 / 1,
    // unit: "%",
    // x: 25,
    // y: 25,
  });
  const [fileDimension, setFileDimension] = useState("");
  const [thumbnail, setThumbnail] = useState([]);

  //on dropping image
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
    setModalOpen(true);
    setIsLoading(true)
      thumbnailImages(file);
      const reader = new FileReader();
      reader.onload = () => {setThumbnail(reader.result),setIsLoading(false)};
      reader.readAsDataURL(file);
    }
  };
  //library calling
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });

  //view profile image
  function viewProfileImage(e) {
    if (image) setViewModalImage(e.currentTarget.src);
  }

  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
  };

  //calling when page load cropping area
  useEffect(() => {
    if (fileDimension.length > 0 && fileDimension[0] instanceof Blob) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          const width = image.width;
          const height = image.height;

          const aspectRatio = 1 / 1;
          setCrop(centerAspectCrop(width, height, aspectRatio));
        };
      };
      reader.readAsDataURL(fileDimension[0]);
    }
  }, [fileDimension]);

  //set cropping area in center
  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 50,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  return (
    <>
      {viewModalImage && (
        <div className="desk-globle-model-box globle-model-box  cstm-rpp-view-profile">
          <div className="desk-globle-model-box-inner cstm-vpp-popup">
            <div className="modal-content">
              <div className="cstm-replace-pp">
                <div class="cstm-rpp-title">
                  View {title}
                  <span
                    class="cstm-rpp-close-btn"
                    onClick={() => setViewModalImage("")}
                  >
                    ✖
                  </span>
                </div>
                <div className="cstm-cstm-vpp-photo">
                  {viewModalImage && <img src={viewModalImage} />}
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      )}
      {modalOpen && (
        <ImageCropperReact
          file={thumbnail}
          setModalOpen={setModalOpen}
          setFile={setThumbnail}
          fileName={fileName}
          fileDimension={fileDimension}
          CropDone={setImage}
          title={title}
          requiredImageAspectRatio={requiredImageAspectRatio}
          isLoading={isLoading}
        />
      )}
      <div className="form-group cstm-edit-profile-img-userlis">
        <div className="form-group-ct">
          <div className="cstm-profile-upload-img">
            <img
              className="cstm-ld-user-img"
              src={
                image
                  ? typeof image === "object"
                    ? URL.createObjectURL(image)
                    : image
                  : Frame
              }
              alt=""
              onClick={viewProfileImage}
            />
          </div>
        </div>
        <div className="cstm-profile-upload-btn-sec">
          <div className="cstm-profile-upload-btn ggg">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <span className="edit-pro-text">Upload {title}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileIcon;
