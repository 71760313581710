import React from 'react'
import PropTypes from "prop-types"
const ShareIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M11.293 2.146a1 1 0 0 1 1.414 0L18.03 7.47a.75.75 0 0 1-1.06 1.06l-4.22-4.22V15a.75.75 0 0 1-1.5 0V4.31L7.03 8.53a.75.75 0 0 1-1.06-1.06l5.323-5.324ZM4.5 14.5a3 3 0 0 1 3-3h.75a.75.75 0 0 0 0-1.5H7.5A4.5 4.5 0 0 0 3 14.5v3A4.5 4.5 0 0 0 7.5 22h9a4.5 4.5 0 0 0 4.5-4.5v-3a4.5 4.5 0 0 0-4.5-4.5h-.75a.75.75 0 0 0 0 1.5h.75a3 3 0 0 1 3 3v3a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-3Z"
                    clipRule="evenodd"
                />
            </svg>
        </>
    )
}
ShareIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default ShareIcon
