import React, { useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import authAdminNotification from "../../Services/auth.admin.notification";
import Frame from "../../Images/Frame.svg";
import { useDispatch } from "react-redux";
import Loader2 from "../../Images/loader2.gif";
import AlertComponent from "../../Hooks/AlertComponent";
import moment from "moment";
import AddRules from "../CommonRules/AddRules";
import ModalPopup from "../ModalPopup/ModalPopup";;
import { CompressImageFunction } from "./CommonFunction";
import { useUserCountMutation } from "../../Services/ruleUserCount";
import ImageCropperReact from "../ImageCropper/ImageCropperReact";

const EditNotification = () => {
  function createData(
    plan_name,
    plan_price,
    automatic_renewal,
    plan_status,
    recurring_timeframe,
    total_member_who_purchased_plan,
    _id
  ) {
    return {
      plan_name,
      plan_price,
      automatic_renewal,
      plan_status,
      recurring_timeframe,
      total_member_who_purchased_plan,
      _id,
    };
  }

  const emptyObjectModal = {
    title: "",
    subtitle: "",
    deepLinkUrl: "",
    notificationType: "immediate",
    image: "",
    event: { value: -1, label: "Select event" },
    eventid: "",
    group: "",
    membership: "",
    triggerAt: "",
    noEventUsers: "",
    channelType: false,
    accessPermission: "",
    Member: false,
    Guest: false,
    Speaker: false,
    Partner: false,
    Users: false,
    status: "published",
    deepLinkUrlForMobile: "",
    userAccess: "",
    pushNotificationStatus: false,
  };

  const emptyObjectModalerr = {
    title: "",
    subtitle: "",
    deepLinkUrl: "",
    notificationType: "",
    image: "",
    event: "",
    group: "",
    membership: "",
    triggerAt: "",
    noEventUsers: "",
    channelType: false,
    accessPermission: "",
    status: "",
    deepLinkUrlForMobile: "",
    userAccess: "",
    userAccessIds: "",
    eventid: "",
  };
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().search;

  // react state
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const id = new URLSearchParams(location).get("id");
  const [notificationDetail, setNotificationDetail] =
    useState(emptyObjectModal);
  const [notificationDetailErr, setNotificationDetailErr] =
    useState(emptyObjectModalerr);

  const [inProcess, setInProcess] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [saveInProcess, setSaveInProcess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [rulesDataForEdit, setRulesDataForEdit] = useState({});
  const [ruleError, setRuleError] = useState(false);
  const [totalUserList, setTotalUserList] = useState([]);
  const latestRequest = useRef(0);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  //  image cropping state
  const [thumbnail, setThumbnail] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileDimension, setFileDimension] = useState("");
  const [cropedFile, setcropedFile] = useState();
  const [cropedFileData, setcropedFileData] = useState();

  const [users, setUsers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [nonUsers, setNonUsers] = useState([]);
  const [invalidEmail, setInvalidEmail] = useState([]);
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;

  //User count mutation
  const [userCountApi] = useUserCountMutation();

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        // Only update state if this is the latest request
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictedAccessEventId: dataFromRules.event_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ? "public" : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  //email validation function
  function isValidURL(url) {
    var urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlPattern.test(url);
  }

  //get notification detail function
  async function getDetailApi() {
    try {
      const detailRes = await authAdminNotification.getNotification(id);
      if (detailRes.data.status) {
        let imgUrl =
          detailRes.data.data.uploadImg !== undefined &&
            detailRes.data.data.uploadImg !== ""
            ? process.env.REACT_APP_AWS_IMG_VID_PATH +
            detailRes.data.data.uploadImg
            : "";
        //set the rules selected
        setRulesDataForEdit({
          selectedUser: detailRes.data.data?.restrictedAccessUserId,
          selectedGroups: detailRes.data.data?.restrictedAccessGroupId,
          selectedEvents: detailRes.data.data?.restrictedAccessEventId,
          selectedMembership:
            detailRes.data.data?.restrictedAccessMembershipPlanId,
          selectedUserAccess:
            detailRes.data.data?.restrictionAccess === "public"
              ? "all"
              : detailRes.data.data?.restrictionAccess,
          selectedTags: detailRes.data.data?.restrictedAccessTagId,
        });

        setEmails(detailRes.data.data?.restrictedAccessUserId.map((user)=>user["Preferred Email"]))
        setUsers(detailRes.data.data?.restrictedAccessUserId.map((user)=>user["Preferred Email"]))
        setNonUsers([]);
        setInvalidEmail([]);

        setNotificationDetail({
          ...detailRes.data.data,
          title: detailRes.data.data.title,
          subtitle: detailRes.data.data.subtitle,
          deepLinkUrl: detailRes.data.data.deepLinkUrl,
          deepLinkUrlForMobile: detailRes.data.data.deepLinkUrlForMobile,
          notificationType: detailRes.data.data.sendType,
          triggerAt:
            detailRes.data.data.sendType === "schedule"
              ? detailRes.data.data.triggerAt
              : "",
          image: imgUrl,
          event:
            detailRes.data.data.event_id !== undefined &&
              detailRes.data.data.event_id.length > 0
              ? {
                value: detailRes.data.data.event_id[0]._id,
                label: detailRes.data.data.event_id[0].title,
              }
              : {},
          eventid:
            detailRes.data.data.event_id !== undefined &&
              detailRes.data.data.event_id.length > 0
              ? detailRes.data.data.event_id[0]._id
              : "",
          userAccess:
            detailRes.data.data.userAccess !== undefined
              ? detailRes.data.data.userAccess
              : "",
          pushNotificationStatus: detailRes.data.data.pushNotificationStatus
            ? detailRes.data.data.pushNotificationStatus
            : false,
        });
      }
    } catch (error) { }
  }

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //change date format function
  const showDateHandler = (date) => {
    var testDateUtc = moment(date);
    var localDate = testDateUtc.format("YYYY-MM-DD HH:mm");
    return localDate;
  };

  //called when page loads
  useEffect(() => {
    handleClearAllStates();
    getDetailApi();
  }, []);

  // handle send notification changes
  const handleNotificationChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (e.target.name == "pushNotificationStatus") {
      setNotificationDetail({
        ...notificationDetail,
        [e.target.name]: e.target.checked,
      });
    } else {
      setNotificationDetail({
        ...notificationDetail,
        [e.target.name]: e.target.value,
      });
    }

    if (
      e.target.name === "title" ||
      e.target.name === "subtitle" ||
      e.target.name === "deepLinkUrl" ||
      e.target.name === "deepLinkUrlForMobile" ||
      e.target.name === "status" ||
      e.target.name === "notificationType" ||
      e.target.name === "triggerAt" ||
      e.target.name === "userAccess"
    ) {
      if (e.target.value.length === 0)
        setNotificationDetailErr({
          ...notificationDetailErr,
          [e.target.name]:
            e.target.name === "title"
              ? "Enter title"
              : // : e.target.name === "subtitle"
              //   ? "Enter sub title"
              e.target.name === "deepLinkUrl"
                ? "Enter deep link url for web"
                : e.target.name === "deepLinkUrlForMobile"
                  ? "Enter deep link url for mobile"
                  : "",
        });
      else {
        if (e.target.name === "title") {
          if (e.target.value.length > 75) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              [e.target.name]: "Title exceeds maximum 75 character limit!",
            });
          } else {
            setNotificationDetailErr({
              ...notificationDetailErr,
              [e.target.name]: "",
            });
          }
        } else if (e.target.name === "subtitle") {
          if (e.target.value.length > 225) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              [e.target.name]: "Subtitle exceeds maximum 225 character limit!",
            });
          } else {
            setNotificationDetailErr({
              ...notificationDetailErr,
              [e.target.name]: "",
            });
          }
        } else if (e.target.name === "deepLinkUrl") {
          if (!isValidURL(e.target.value)) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              deepLinkUrl: "Enter valid deep link url for web!",
            });
          } else {
            setNotificationDetailErr({
              ...notificationDetailErr,
              deepLinkUrl: "",
            });
          }
        } else if (e.target.name === "deepLinkUrlForMobile") {
          if (!isValidURL(e.target.value)) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              deepLinkUrlForMobile: "Enter valid deep link url for mobile!",
            });
          } else {
            setNotificationDetailErr({
              ...notificationDetailErr,
              deepLinkUrlForMobile: "",
            });
          }
        } else if (e.target.name === "status") {
          if (notificationDetail.notificationType === "immediate") {
            if (e.target.value === "draft")
              setNotificationDetailErr({
                ...notificationDetailErr,
                status: "Immediate notification status can not be draft!",
              });
            else
              setNotificationDetailErr({
                ...notificationDetailErr,
                status: "",
              });
          }
        } else if (e.target.name === "notificationType") {
          if (e.target.value === "schedule") {
            setNotificationDetail({
              ...notificationDetail,
              [e.target.name]: e.target.value,
              status: "draft",
            });
            setNotificationDetailErr({ ...notificationDetailErr, status: "" });
          } else {
            setNotificationDetail({
              ...notificationDetail,
              [e.target.name]: e.target.value,
              status: "published",
            });
          }
        } else if (e.target.name === "triggerAt") {
          if (
            e.target.value.length > 0 &&
            notificationDetail.notificationType == "schedule"
          ) {
            const isValid = moment(
              e.target.value,
              moment.HTML5_FMT.DATETIME_LOCAL,
              true
            ).isValid();

            if (!isValid) {
              setNotificationDetailErr({
                ...notificationDetailErr,
                triggerAt: "Please select valid date & time!",
              });
            } else {
              if (moment(e.target.value) <= moment()) {
                setNotificationDetailErr({
                  ...notificationDetailErr,
                  triggerAt: "Please select valid future date & time!",
                });
              }
            }
          }
          if (
            e.target.value.length > 0 &&
            notificationDetail.notificationType == "schedule" &&
            moment(e.target.value) > moment()
          ) {
            setNotificationDetailErr({
              ...notificationDetailErr,
              triggerAt: "",
            });
          }
        } else {
          setNotificationDetailErr({
            ...notificationDetailErr,
            [e.target.name]: "",
          });
          setNotificationDetail({
            ...notificationDetail,
            [e.target.name]: e.target.value,
          });
        }
      }
    }
  };

  // submit form update notification data through api
  const updateNotificationAPI = async () => {
    const formdata = new FormData();
    let imageCompress;
    formdata.append("title", notificationDetail.title);
    formdata.append("subtitle", notificationDetail.subtitle);
    formdata.append("deepLinkUrl", notificationDetail.deepLinkUrl);
    formdata.append(
      "deepLinkUrlForMobile",
      notificationDetail.deepLinkUrlForMobile
    );
    formdata.append(
      "status",
      notificationDetail.status ? notificationDetail.status : "draft"
    );

    if (notificationDetail.notificationType === "schedule") {
      formdata.append("sendType", "schedule");
      if (
        notificationDetail.triggerAt &&
        notificationDetail.triggerAt.length > 0
      ) {
        formdata.append(
          "triggerAt",
          moment(notificationDetail.triggerAt).utc()
        );
      }
    } else {
      formdata.append("sendType", "immediate");
    }
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formdata.append("uploadImg", imageCompress);

    //adding a rules for document
    dataFromRules?.userAccess.length !== 0 &&
      formdata.append(
        `restrictionAccess`,
        dataFromRules?.userAccess === "all" ? "public" : "restricted"
      );
    if (dataFromRules?.tags_id) {
      dataFromRules?.tags_id?.length > 0 &&
        dataFromRules?.tags_id?.map((id) => {
          formdata.append(`restrictedAccessTagId[]`, id);
        });
    }
    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formdata.append(`restrictedAccessGroupId[]`, id);
        });
    }
    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formdata.append(`restrictedAccessMembershipPlanId[]`, id);
        });
    }
    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formdata.append(`restrictedAccessUserId[]`, id);
      });

    if (dataFromRules?.event_id) {
      dataFromRules.userAccess === "restricted" &&
        dataFromRules?.event_id.length > 0 &&
        formdata.append(`restrictedAccessEventId[]`, dataFromRules.event_id[0]);
    }

    formdata.append(
      `pushNotificationStatus`,
      notificationDetail.pushNotificationStatus
    );

    authAdminNotification
      .updateNotification(id, formdata)
      .then((res) => {
        if (res.data.status) {
          setSaveInProcess(false);
          setInProcess(false);
          setResponseMessage(res.data.message);
          setTimeout(() => {
            setResponseMessage("");
            navigate(
              NAVIGATEPAGE !== null && NAVIGATEPAGE !== ""
                ? NAVIGATEPAGE
                : "/notification/list?search="
            );
          }, 3000);
        }
        setSaveInProcess(false);
        setInProcess(false);
        handleClearAllStates();
        setNotificationDetail(emptyObjectModal);
        setcropedFile();
      })
      .catch((e) => {
        setInProcess(false);
        setSaveInProcess(false);
        handleClearAllStates();
        console.log(e);
        setResponseMessage(e.message);
        setTimeout(() => {
          setResponseMessage("");
        }, 3000);
      });
  };

  //handle when confirmation ok
  const handleConfirmOk = async () => {
    setInProcess(true);
    updateNotificationAPI();
    setShowConfirmation(false);
  };

  // called when click on update notification button
  const handleEditNotification = async () => {
    var status = true;
    var temp = {};

    if (notificationDetail.title.trim().length === 0) {
      temp = { ...temp, title: "Enter title!" };
      status = false;
    } else if (notificationDetail.title.length > 75) {
      temp = { ...temp, title: "Title exceeds maximum 75 character limit!" };
      status = false;
    }
    if (notificationDetail.status.trim().length === 0) {
      temp = { ...temp, status: "Select status!" };
      status = false;
    }

    if (notificationDetail.subtitle.trim().length === 0) {
    } else if (notificationDetail.subtitle.length > 225) {
      temp = {
        ...temp,
        subtitle: "Subtitle exceeds maximum 225 character limit!",
      };
      status = false;
    }

    if (notificationDetail.deepLinkUrl.trim().length === 0) {
      temp = { ...temp, deepLinkUrl: "Enter deep link url for web!" };
      status = false;
    } else if (!isValidURL(notificationDetail.deepLinkUrl)) {
      temp = { ...temp, deepLinkUrl: "Enter valid deep link url for web!" };
      status = false;
    }

    if (notificationDetail.deepLinkUrlForMobile.trim().length === 0) {
      temp = {
        ...temp,
        deepLinkUrlForMobile: "Enter deep link url for mobile!",
      };
      status = false;
    } else if (!isValidURL(notificationDetail.deepLinkUrlForMobile)) {
      temp = {
        ...temp,
        deepLinkUrlForMobile: "Enter valid deep link url for mobile!",
      };
      status = false;
    }

    if (
      notificationDetail.notificationType === "immediate" &&
      notificationDetail.status === "draft"
    ) {
      temp = {
        ...temp,
        status: "Immediate notification status can not be draft!",
      };
      status = false;
    }

    if (notificationDetail.notificationType === "schedule") {
      if (notificationDetail.triggerAt) {
        if (notificationDetail.triggerAt.length === 0) {
          temp = { ...temp, triggerAt: " Please select date & time!" };
          status = false;
        }
      } else {
        temp = { ...temp, triggerAt: " Please select date & time!" };
        status = false;
      }
    }

    if (
      notificationDetail.triggerAt &&
      notificationDetail.triggerAt.length > 0 &&
      notificationDetail.notificationType == "schedule"
    ) {
      const isValid = moment(
        notificationDetail.triggerAt,
        moment.ISO_8601,
        true
      ).isValid();

      if (!isValid) {
        temp = { ...temp, triggerAt: "Please select valid date & time!" };
        status = false;
      } else {
        if (moment(notificationDetail.triggerAt) <= moment()) {
          temp = {
            ...temp,
            triggerAt: "Please select valid future date & time!",
          };
          status = false;
        }
      }
    }

    if (status && !ruleError && !noRuleFieldSelected) {
      if (notificationDetail.status !== "draft") {
        setShowConfirmModal(true);
      } else {
        setInProcess(true);
        updateNotificationAPI();
        setShowConfirmModal(false);
      }
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      setNotificationDetailErr(temp);
      noRuleFieldSelected && setRuleError(true);
    }
  };

  //save draft
  const handleSaveDraft = () => {
    if (notificationDetail.title.trim() === "") {
      setNotificationDetailErr({
        ...notificationDetailErr,
        title: "Enter title!",
      });
    } else {
      setSaveInProcess(true);
      updateNotificationAPI();
    }
  };

  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
  };

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="um-back">
            <button
              type="button"
              className="back-btn"
              onClick={() => {
                if (modalInProcess) {
                  dispatch({ type: "SETCUSTOMPAGECHANGE" });
                  dispatch({
                    type: "NAVIGATEPAGE",
                    payload: "/notification/list",
                  });
                } else {
                  navigate(
                    NAVIGATEPAGE !== null && NAVIGATEPAGE !== ""
                      ? NAVIGATEPAGE
                      : "/notification/list?search="
                  );
                }
              }}
            >
              <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
              Back
            </button>
          </div>
          <div className="uic-title">
            <h1>Edit Notification</h1>
          </div>

          {responseMessage && (
            <div className="alert alert-info">{responseMessage}</div>
          )}
          {modalOpen && (
            <ImageCropperReact
              file={thumbnail}
              setModalOpen={setModalOpen}
              setcropedFile={setcropedFile}
              setNotificationDetail={setNotificationDetail}
              notificationDetail={notificationDetail}
              fileName={fileName}
              setcropedFileData={setcropedFileData}
              fileDimension={fileDimension}
              requiredImageAspectRatio={16 / 9}
              isLoading={isLoading}
            />
          )}
          <div className="group-info-user">
            <div className="cstm-video-upload">
              <Dropzone
                onDrop={(acceptedFiles) => {
                  // Check if any of the dropped files are not allowed
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    setModalOpen(true)
                    setIsLoading(true)
                    thumbnailImages(file);
                    const reader = new FileReader();
                    reader.onload = () => {setThumbnail(reader.result),setIsLoading(false)};
                    reader.readAsDataURL(file);
                  }
                }}
                name="uploadImg"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="cstm-notification-upload-img cstm-video-upload-img ">
                      <img
                        className={`${notificationDetail.image !== undefined &&
                          notificationDetail.image !== ""
                          ? 'w-100 h-auto aspect-ratio-unset'
                          : cropedFile ? "w-100 h-auto aspect-ratio-unset" : "w-auto"}`}
                        src={
                          notificationDetail.image !== undefined &&
                            notificationDetail.image !== ""
                            ? notificationDetail.image
                            : cropedFile
                              ? cropedFile
                              : Frame
                        }
                      />
                    </div>
                  </div>
                )}
              </Dropzone>
              <span className="error">{notificationDetailErr.image}</span>
            </div>
            <div className="group-info-user-link">
              <div className="cover-image-upload-ttl">Upload Image</div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Title*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="title"
                  value={notificationDetail.title}
                  onChange={handleNotificationChange}
                  maxLength={75}
                />
              </div>
              <span className="error cstm-error4">
                {notificationDetailErr.title}
              </span>
              <span className="cstm-title-note-msg">
                The maximum character limit is 75.
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Subtitle</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="subtitle"
                  value={notificationDetail.subtitle}
                  onChange={handleNotificationChange}
                  maxLength={225}
                />
              </div>
              <span className="error cstm-error4">
                {notificationDetailErr.subtitle}
              </span>
              <span className="cstm-title-note-msg">
                The maximum character limit is 225.
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Deep link url for web*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="deepLinkUrl"
                  value={notificationDetail.deepLinkUrl}
                  onChange={handleNotificationChange}
                />
              </div>
              <span className="error cstm-error4">
                {notificationDetailErr.deepLinkUrl}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Deep link url for mobile*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="deepLinkUrlForMobile"
                  value={notificationDetail.deepLinkUrlForMobile}
                  onChange={handleNotificationChange}
                />
              </div>
              <span className="error cstm-error4">
                {notificationDetailErr.deepLinkUrlForMobile}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="form-group-label">Frequency</div>
              <div className="form-group-ct">
                <select
                  name="notificationType"
                  value={notificationDetail.notificationType}
                  onChange={handleNotificationChange}
                >
                  <option value="immediate">Send Immediately</option>
                  <option value="schedule">Set Custom Date</option>
                </select>
              </div>
            </div>
          </div>
          {notificationDetail.notificationType === "schedule" && (
            <div className="row">
              <div className="col-sm-4 form-group">
                <div className="form-group-label">Select date & time*</div>
                <div className="form-group-ct">
                  <input
                    type="datetime-local"
                    name="triggerAt"
                    value={showDateHandler(notificationDetail.triggerAt)}
                    onChange={handleNotificationChange}
                  />
                </div>
                <span className="error cstm-error4">
                  {notificationDetailErr.triggerAt}
                </span>
              </div>
            </div>
          )}
          <div class="form-group">
            <div class="cstm-channel-type-title partner-icon-rf-only">
              Notification status*
            </div>
            <div class="cstm-pm-list">
              <label class="subcat-addvideo">
                <input
                  type="radio"
                  value="draft"
                  name="status"
                  onChange={handleNotificationChange}
                  checked={notificationDetail.status === "draft"}
                />
                Draft
              </label>
              <label class="subcat-addvideo">
                {" "}
                <input
                  type="radio"
                  value="published"
                  name="status"
                  onChange={handleNotificationChange}
                  checked={notificationDetail.status === "published"}
                />
                Published
              </label>
            </div>
            <span className="error cstm-error4">
              {notificationDetailErr.status}
            </span>
          </div>
          {/* groups */}
          <AddRules
            title="Rules*"
            ruleError={ruleError}
            totalUserList={totalUserList}
            setRuleError={setRuleError}
            noRuleFieldSelected={noRuleFieldSelected}
            setNoRuleFieldSelected={setNoRuleFieldSelected}
            dataFromRules={dataFromRules}
            setDataFromRules={setDataFromRules}
            addedRules={rulesDataForEdit}
            showTagsField={true}
            users={users}
            setUsers={setUsers}
            emails={emails}
            setEmails={setEmails}
            invalidEmail={invalidEmail}
            setInvalidEmail={setInvalidEmail}
            emailRegex={emailRegex}
            nonUsers={nonUsers}
            setNonUsers={setNonUsers}
          />

          <div className="row">
            <div className="col-sm-12 form-group">
              <div className="cstm-chk-preregistration-container">
                <input
                  type="checkbox"
                  name="pushNotificationStatus"
                  checked={notificationDetail.pushNotificationStatus}
                  onChange={handleNotificationChange}
                />
                <label for="pushNotificationStatus">
                  Should we send this as a mobile push notification to the
                  selected users?
                </label>
              </div>
            </div>
          </div>

          <div className="notification-btm-btn">
            <button
              className="btn2"
              onClick={() => {
                handleClearAllStates();
                navigate("/notification/list");
              }}
            >
              Cancel
            </button>

            {notificationDetail.notificationType !== "immediate" &&
              (notificationDetail.status === "draft" ||
                notificationDetail.status === "") && (
                <>
                  {saveInProcess ? (
                    <div className="loader-button1 save-draft-loader">
                      <button>In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button className="btn2" onClick={handleSaveDraft}>
                      Save as draft
                    </button>
                  )}
                </>
              )}
            {inProcess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button
                className="btn"
                onClick={() => {
                  handleEditNotification();
                }}
              >
                {notificationDetail.notificationType === "immediate"
                  ? `Set Live`
                  : `Update`}
              </button>
            )}
          </div>
        </div>
      </div>
      <AlertComponent />

      <ModalPopup
        onClose={() => setShowConfirmModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        buttonText1="Set Live"
        isOpen={showConfirmModal}
        className=""
        handleClick2ndButton={handleConfirmOk}
        headingLable={"Ready to set your notification live?"}
      >
        <div className="ctsm-modal-body">
          <p>Your notification will reach {totalUserList?.length} people.</p>
        </div>
      </ModalPopup>
    </>
  );
};

export default EditNotification;
