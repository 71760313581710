import React from "react";
import { PropTypes } from "prop-types";
// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/TypingIndicator.css";

const TypingIndicator = ({
  type = "name",
  name = "jack",
  membersName = "Peter",
  count = 3,
}) => {
  return (
    <>
      {type === "name" && (
        <div className="Typingindicatior-container">
          <span> {name} </span> is typing...
        </div>
      )}
      {type === "membersName" && (
        <div className="Typingindicatior-container">
          <span>
            {name},&nbsp;{membersName}
          </span>
          &nbsp;are typing...
        </div>
      )}
      {type === "count" && (
        <div className="Typingindicatior-container">
          <span>{count}&nbsp;</span>
          members are typing...
        </div>
      )}
    </>
  );
};

TypingIndicator.propTypes = {
  type: PropTypes.oneOf(["name", "membersName", "count"]),
  name: PropTypes.string,
  membersName: PropTypes.string,
  count: PropTypes.number,
};

export default TypingIndicator;
