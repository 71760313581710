import React from "react";
import NewChatIcon from "../../../../components/Icons/GeneralIcons/NewChatIcon";
// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/ChatFloatingButtonPrimary.css";

const ChatFloatingButtonPrimary = ({ openChatListModel }) => {
  return (
    <button
      className="chat-floating-button-primary"
      onClick={openChatListModel}
    >
      <NewChatIcon height={24} iconColor="#FFF" width={24} />
    </button>
  );
};

export default ChatFloatingButtonPrimary;
