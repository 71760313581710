import React from "react";

const IconReminder = () => {
  return (
    <div className="mds-icons-empty">
    <svg
    xmlns="https://www.w3.org/2000/svg"
    width={120}
    height={120}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="#6FCADF"
        fillOpacity={0.2}
        d="M59.392 119.945c-1.588 0-3.19.147-4.754-.034-2.457-.285-4.913-.635-7.333-1.147a58.985 58.985 0 0 1-24.126-11.242 59.24 59.24 0 0 1-6.402-5.722 62.25 62.25 0 0 1-8.4-11.044 59.099 59.099 0 0 1-4.357-8.818A57.253 57.253 0 0 1 .949 71.126a56.528 56.528 0 0 1-.863-6.084c-.193-2.951-.05-5.927 0-8.897.07-4.133.921-8.158 1.96-12.128A51.258 51.258 0 0 1 5.27 35.12a58.819 58.819 0 0 1 5.202-9.106 61.04 61.04 0 0 1 3.731-4.93 69.75 69.75 0 0 1 5.98-6.017 57.9 57.9 0 0 1 7.47-5.682 60.399 60.399 0 0 1 33.68-9.36 59.992 59.992 0 0 1 38.215 14.863 59.884 59.884 0 0 1 19.715 35.925c.654 4.11.871 8.278.645 12.434-.746 12.368-4.834 23.508-12.373 33.356a60.37 60.37 0 0 1-18.383 15.88 58.271 58.271 0 0 1-10.315 4.569 57.22 57.22 0 0 1-12.59 2.675 46.557 46.557 0 0 1-6.855.218Z"
      />
      <path
        fill="#060D14"
        fillRule="evenodd"
        d="M66.373 37.496c1.765.518 3.648 1.32 5.412 2.515 3.97 2.69 7.21 7.298 7.21 14.656v16c0 2.468 3.528 5.985 6.172 6l.002 4H37.004l-.002-4c2.683-.015 6.173-3.537 6.173-6v-16c0-7.358 3.241-11.967 7.21-14.656 1.825-1.236 3.776-2.051 5.591-2.567a5.336 5.336 0 0 1 10.397.052Zm-5.288 3.171c-1.946.018-5.466.63-8.456 2.655-2.918 1.978-5.454 5.37-5.454 11.345v16c0 2.403-1.215 4.455-2.656 6h33.132c-1.44-1.545-2.655-3.597-2.655-6v-16c0-5.976-2.536-9.367-5.454-11.345-2.99-2.025-6.51-2.637-8.456-2.655Z"
        clipRule="evenodd"
      />
      <path
        fill="#060D14"
        d="M55.836 86.667A2.667 2.667 0 0 1 58.502 84h5.334a2.667 2.667 0 0 1 0 5.333h-5.334a2.667 2.667 0 0 1-2.666-2.666ZM43.47 36.918a1.92 1.92 0 0 1 .041 2.715c-2.4 2.475-7.218 10.123-6.197 19.921a1.92 1.92 0 1 1-3.82.398c-1.165-11.18 4.264-19.903 7.261-22.993a1.92 1.92 0 0 1 2.715-.04ZM78.765 39.632a1.92 1.92 0 0 1 2.757-2.673c2.996 3.09 8.423 11.815 7.254 22.995a1.92 1.92 0 0 1-3.819-.4c1.024-9.797-3.792-17.447-6.192-19.922Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h120v120H0z" />
      </clipPath>
    </defs>
  </svg>
    </div>
  );
};

export default IconReminder;
