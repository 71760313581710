import React from "react";
import noAccessImage from "../Images/noaccess.png";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "./UserComponents/Dashboard/Header";
import { useEffect } from "react";

export default function NotAccessScreen() {
  const location = useLocation();
  let history = useNavigate();
  const pathname = window.location.href;
  console.log(pathname, "pathname");
  const queryParams = pathname.slice(pathname.indexOf("?") + 1);
  useEffect(() => {
    if (
      location.pathname !== undefined &&
      location.pathname.length > 0 &&
      location.pathname.split("/").length > 0 &&
      location.pathname.split("/").length == 2 &&
      location.pathname.split("/")[1] == "dashboard"
    ) {
      history("/");
    }
  }, []);

  const handleScreen = (data) => {
    console.log(data, "data");
    if (data === "video") {
      history(`/videos`);
    } else {
      history("/frontendchat/me/me");
    }
  };

  return (
    <>
      {localStorage.getItem("idtoken") !== null &&
        localStorage.getItem("idtoken").length !== 0 &&
        localStorage.getItem("role") !== null &&
        localStorage.getItem("role") === "user" &&
        localStorage.getItem("userid") !== null &&
        localStorage.getItem("userid").length !== 0 && <Header />}
      <div className="not-found-page">
        <span className="not-access-image">
          <img src={noAccessImage} alt="noAccessImage" />
        </span>
        {queryParams === "type=videopage" ? (
          <>
            <h1>No access to this video!</h1>
            <p class="sorry-but-cont">
              Sorry, looks like you don't have access to this video. But there's
              more to discover.
            </p>
            <button className="btn2" onClick={() => handleScreen("video")}>
              Explore video library
            </button>
          </>
        ) : (
          <>
            <h1>
              No access to this
              {queryParams === "type=userChatGroup" ? " chat" : " channel"}!
            </h1>
            <p class="sorry-but-cont">
              Sorry, looks like you don't have access to this
              {queryParams === "type=userChatGroup" ? " chat " : " channel "}
              right now.
            </p>
            <button className="btn2" onClick={() => handleScreen("chat")}>
              Got it, thanks
            </button>
          </>
        )}
      </div>
    </>
  );
}
