import React from "react";
import "./RadioButton.css";
import "../../Styles/Theme/Theme.css";
import PropTypes from "prop-types";

const RadioButton = ({
  value,
  name,
  onChange,
  checked,
  defaultChecked,
  label = "",
  hasLabel = false,
  disabled,
  readOnly,
  required,
  id = "id",
  error,
}) => {
  return (
    <>
      <div className="radio-button relative">
        <input
          type="radio"
          value={value}
          name={name}
          onChange={onChange}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          required={required}
          className={`${checked ? "Checked" : ""}`}
        />
        <label htmlFor={id}>
          <span className=""> </span>
          {hasLabel && <p className="radio-label">{label}</p>}
        </label>
      </div>
    </>
  );
};

RadioButton.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  label: PropTypes.string,
  hasLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
};

export default RadioButton;
