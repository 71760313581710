import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Styles/videoMediumCard.css";
import Button from "../Button/Button";
import DotsIcon from "../Icons/GeneralIcons/DotsIcon";
import DropDownItem from "../DropDownItem/DropDownItem";
import { getVideoProgressPercentage } from "../checktoken";
import PropTypes from "prop-types";
import BadgesControl from "../Badge/BadgesControl";

const VideoMediumCard = ({
  extraClass = "",
  pageUrl = "javascript:void(0)",
  hasTimeline = true,
  hasBadge = false,
  name = "Omnichannel Marketing: Leveling Up Beyond Amazon － Corin Prystal & Jay Hunter － MDS Inspire 2024",
  category = "Category",
  views = "10 views",
  uploaded = "1 year ago",
  duration = "10:00",
  hasDotMenu = true,
  thumbnail = "",
  handleActionClick,
  id,
  dropPos = false,
  actions,
  activeId,
  handleSelect,
  handleMouseLeave,
  convideo,
  badgeText = "NEW",
}) => {
  const [videopausepercentage, setvideopausepercentage] = useState(0);

  useEffect(() => {
    const video_percentage = getVideoProgressPercentage(convideo);
    setvideopausepercentage(video_percentage);
  }, []);

  return (
    <>
      <div
        className={`video-medium-card-wrapper position-relative ${hasDotMenu ? "has-dotmenu" : ""
          }`}
      >
        <div
          className={`video-medium-card ${extraClass}`}
          onMouseLeave={handleMouseLeave}
        >
          <Link
            to={pageUrl}
            className="video-medium-thumb-img position-relative video-img-type-medium"
          >
            {hasBadge && (
              <BadgesControl
                extraClass="video-badges"
                color="white"
                label={badgeText}
                rounded
                size="status-medium"
              />
            )}
            <img src={thumbnail} className={`thumb-image`} alt="thumb-img" />
            {hasTimeline && <span className="video-duration">{duration}</span>}
            {videopausepercentage !== 0 && (
              <div className="video_progress_wrapper">
                <div
                  className="video_progress_bar"
                  style={{
                    width: `${videopausepercentage}%`,
                  }}
                ></div>
              </div>
            )}
          </Link>
          <div className="video-medium-details-box">
            <div className="video-medium-details-wrapper">
              <div className="">
                <h6 className="video-medium-card-title">{name}</h6>
                <p className="video-medium-type-text">{category}</p>
                <div class="video-details-view-date">
                  <span class="video-view-text">{views}</span>
                  <span class="video-uploded-date">{uploaded}</span>
                </div>
              </div>
            </div>
            {hasDotMenu && (
              <Button
                variant="ghost"
                extraClass="video-dot-btn ml-auto align-self-start"
                size={"extrasmall"}
                handleClick={(e) => {
                  handleActionClick(e, id);
                }}
                hasText={false}
                elementIcon={<DotsIcon />}
              />
            )}
            {activeId === id && (
              <DropDownItem
                isIcon
                extraClass={`video-dropdown-wrapper ${dropPos ? "top" : ""}`}
                id="actions"
                size="small"
                options={actions}
                onSelect={handleSelect}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
VideoMediumCard.propTypes = {
  extraClass: PropTypes.string,
  pageUrl: PropTypes.string,
  hasTimeline: PropTypes.bool,
  hasBadge: PropTypes.bool,
  name: PropTypes.string,
  category: PropTypes.string,
  views: PropTypes.string,
  uploaded: PropTypes.string,
  duration: PropTypes.string,
  hasDotMenu: PropTypes.bool,
  thumbnail: PropTypes.string,
  handleActionClick: PropTypes.func,
  id: PropTypes.string,
  dropPos: PropTypes.bool,
  actions: PropTypes.array,
  activeId: PropTypes.string,
  handleSelect: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  convideo: PropTypes.any,
};

export default VideoMediumCard;
