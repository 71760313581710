import React from "react";
import "../Styles/ChatAccordion.css";
import PropTypes from "prop-types";
import "../../../../Styles/Theme/Theme.css";
import ArrowChevronUp from "../../../../components/Icons/ArrowsIcon/ArrowChevronUp";
import Typography from "../../../../components/Typography/Typography";
import ArrowChevronDown from "../../../../components/Icons/ArrowsIcon/ArrowChevronDown";

const ChatAccordion = ({ isCollapsed, name = "Accordion", handleToggle }) => {
  return (
    <>
      <div className="chat-accordion" onClick={handleToggle}>
        <div className="chat-accordion-in">
          <Typography variant="h5">{name}</Typography>
          <span className="chat-accordion-arrow">
            {isCollapsed ? (
              <ArrowChevronUp
                width={24}
                height={24}
                iconColor="var(--color-icon-neutral-strong)"
              />
            ) : (
              <ArrowChevronDown
                width={24}
                height={24}
                iconColor="var(--color-icon-neutral-strong)"
              />
            )}
          </span>
        </div>
      </div>
    </>
  );
};

ChatAccordion.propTypes = {
  isCollapsed: PropTypes.bool,
  name: PropTypes.string,
};

export default ChatAccordion;
