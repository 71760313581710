import React from "react";

const IconSidebarChat = () => {
    return (
        <>
            <svg
                width="120"
                height="121"
                viewBox="0 0 120 121"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="60" cy="60.5" r="60" fill="#6FCADF" fill-opacity="0.2" />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M48 37.8335H72C77.891 37.8335 82.6667 42.6091 82.6667 48.5002V61.8335C82.6667 67.7245 77.891 72.5002 72 72.5002H65.5543C64.3519 72.5002 63.1989 72.9778 62.3487 73.8279L56 80.1766V77.0335C56 74.5298 53.9704 72.5002 51.4667 72.5002H48C42.109 72.5002 37.3333 67.7245 37.3333 61.8335V48.5002C37.3333 42.6091 42.109 37.8335 48 37.8335ZM33.3333 48.5002C33.3333 40.4 39.8998 33.8335 48 33.8335H72C80.1002 33.8335 86.6667 40.4 86.6667 48.5002V61.8335C86.6667 69.9337 80.1002 76.5002 72 76.5002H65.5543C65.4128 76.5002 65.2772 76.5564 65.1771 76.6564L56.5523 85.2812C54.8724 86.9611 52 85.7713 52 83.3956V77.0335C52 76.7389 51.7612 76.5002 51.4667 76.5002H48C39.8998 76.5002 33.3333 69.9337 33.3333 61.8335V48.5002ZM68 57.0835C69.1046 57.0835 70 56.1881 70 55.0835C70 53.9789 69.1046 53.0835 68 53.0835C66.8954 53.0835 66 53.9789 66 55.0835C66 56.1881 66.8954 57.0835 68 57.0835ZM54 55.0835C54 56.1881 53.1046 57.0835 52 57.0835C50.8954 57.0835 50 56.1881 50 55.0835C50 53.9789 50.8954 53.0835 52 53.0835C53.1046 53.0835 54 53.9789 54 55.0835ZM60 57.0835C61.1046 57.0835 62 56.1881 62 55.0835C62 53.9789 61.1046 53.0835 60 53.0835C58.8954 53.0835 58 53.9789 58 55.0835C58 56.1881 58.8954 57.0835 60 57.0835Z"
                    fill="#060D14"
                />
            </svg>
        </>
    );
};

export default IconSidebarChat;
