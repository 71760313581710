const intialState = {
    pendingCount: 0,
    revokedCount: 0,
    acceptedCount: 0,
};

const ReduInviteeCount = (state = intialState, action) => {
  switch (action.type) {
    case "SETINVITEECOUNT":
      return {...state, ...action.payload};
    default:
      return state;
  }
};

export default ReduInviteeCount;