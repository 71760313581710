import React from 'react'
import PropTypes from "prop-types"
const SmallClockIcon = ({ width = 16, height = 16, iconColor = "#000" }) => {
    return (
        <><svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M13.667 8A5.667 5.667 0 1 1 2.334 8a5.667 5.667 0 0 1 11.333 0Zm1 0A6.667 6.667 0 1 1 1.334 8a6.667 6.667 0 0 1 13.333 0Zm-4.635 2.392a.504.504 0 0 0 .686-.174.501.501 0 0 0-.174-.686l-2.27-1.354V5.23a.5.5 0 1 0-1 0v3.232c0 .176.094.34.244.43l2.514 1.5Z"
                clipRule="evenodd"
            />
        </svg>
        </>
    )
}
SmallClockIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default SmallClockIcon