import React from 'react'
import PropTypes from "prop-types"
const FilterIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M6 9.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm3.93-1.75A4.001 4.001 0 0 1 2 7a4 4 0 0 1 7.93-.75H21a.75.75 0 0 1 0 1.5H9.93ZM15.5 17a2.5 2.5 0 1 0 5 0 2.5 2.5 0 0 0-5 0Zm-1.43.75A4.001 4.001 0 0 0 22 17a4 4 0 0 0-7.93-.75H3a.75.75 0 0 0 0 1.5h11.07Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
FilterIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default FilterIcon