import React from 'react'
import PropTypes from "prop-types"
const ComplainIcon = ({width = 24, height = 24, iconColor = "#000"}) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M4 5.25A3.25 3.25 0 0 1 7.25 2h12.82l-2.272 6.5 2.273 6.5H5.5v6.25a.75.75 0 0 1-1.5 0v-16Zm1.5 8.25h12.43l-1.728-5 1.727-5H7.25A1.75 1.75 0 0 0 5.5 5.25v8.25Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
ComplainIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default ComplainIcon