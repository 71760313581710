import React from 'react'
import PropTypes from "prop-types"
const BlockIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M12 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10Zm0-18.5c-4.687 0-8.5 3.813-8.5 8.5 0 2.074.747 3.977 1.985 5.454l11.97-11.969A8.462 8.462 0 0 0 12 3.5Zm6.515 3.046L6.546 18.515A8.462 8.462 0 0 0 12 20.5c4.687 0 8.5-3.813 8.5-8.5a8.462 8.462 0 0 0-1.985-5.454Z"
                clipRule="evenodd"
            />
        </svg>

    )
}
BlockIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default BlockIcon