import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Import components
import EditField from "./EditField";
import EditDBField from "./EditDBField";

// Import Api integration file
import Services from "../../Services/auth.service";

// Import images

import editfill from "../../Images/BackendImages/edit-fill.svg";
import deletefill from "../../Images/BackendImages/delete-fill.svg";
import AdminSidebar from "../AdminSidebar";
import DeleteModal from "../AdminCommonComponent/deleteModal";
import UserInfoSideBar from "../BackendUserlisting/UserInfoSideBar";
const RegistrationForm = ({
  fields,
  setShowgrid,
  setFields,
  dbformfield,
  setdbformfield,
}) => {
  let history = useNavigate();
  const [showEditDBField, setshowEditDBField] = useState(false);
  const [dbdeletefieldid, setdbdeletefieldid] = useState("");
  const [styleDiv, setstyleDiv] = useState({ display: "none" });
  const [fieldindex, setfieldindex] = useState(0);
  const [selectedField, setselectedField] = useState(0);
  const [showDBeditformdiv, setshowDBeditformdiv] = useState(false);
  const [selectedFieldId, setselectedFieldId] = useState("");
  const [dbselectedfields, setdbselectedfields] = useState({});
  const NameGrid = "625cf485e07bf211178126cc";
  const PasswordGrid = "625cf4ede07bf211178126f3";
  const EmailGrid = "625d413c64d19ba664eeca42";
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [isMenu,setMenu]=useState(false)
  // useEffect called on dbformfield , fields change or setFields call
  useEffect(() => { }, [dbformfield, fields, setFields]);
  // Called on click on delete button
  const handleGridDelete = (index) => {
    var arr = [...fields];
    arr.splice(index, 1);
    // console.log(arr)
    setFields([...arr]);
  };
  // Called on click on database field delete button
  const handledbfieldGridDelete = () => {
    Services.deletefieldregistrationform({ id: dbdeletefieldid })
      .then(function (response) {
        if (response.data.success) {
          var arr = dbformfield.filter((fld) => {
            if (fld._id !== dbdeletefieldid) {
              return fld
            }
          })
          setdbformfield(arr)
          setshowalert(true);
          setalertmsg("Successfully deleted field!");
          setTimeout(() => {
            setshowalert(false);
          }, 2000);
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // Called on click on field edit button
  const handleGridEdit = (index, selectedgrid) => {
    setstyleDiv({ display: "inline", float: "right" });
    setfieldindex(index);
    setselectedField(selectedgrid);
  };
  // useEffect called when fields, fieldindex, selectedField, styleDiv change
  useEffect(() => {
    <EditField
      fields={fields}
      setFields={setFields}
      fieldindex={fieldindex}
      selectedField={selectedField}
      styleDiv={styleDiv}
    />;
  }, [fields, fieldindex, selectedField, styleDiv]);
  // Called on click on update form button
  const addtodatabase = () => {
    Services.addfieldsregistrationform(fields)
      .then(function (response) {
        if (response.data.success) {
          setshowalert(true);
          setalertmsg("Successfully updated form!");
          setTimeout(() => {
            setshowalert(false);
            Services.retrivefieldsregistrationform()
              .then(function (response) {
                setdbformfield(response.data.data);
              })
              .catch(function (error) {
                console.log(error);
              });
          }, 2000);
          setFields([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <>
      {/* <div className="cstm-all-admin"> */}
      {/* <AdminSidebar /> */}

      {/* <div className="content-main"> */}
        <div className="users-info">
          <UserInfoSideBar />
          <div className="users-info-content">
            <div className="users-info-content-main">
              {showalert && <div className="alert alert-info">{alertmsg}</div>}
              <div className="uic-title">
                <h1>Registration form</h1>
                <button
                  className="btn2"
                  data-toggle="modal"
                  data-target="#Addgrid"
                  onClick={(e) => {
                    setShowgrid(true);
                  }}
                >
                  Add Field
                </button>
              </div>
              {/* Display fields from database */}
              {dbformfield.length > 0 ? (
                <>
                  {dbformfield.map((f, index) => {
                    switch (f.grid) {
                      case 1:
                        return (
                          <>
                            <div className="row">
                              <div className="col-sm-12 form-group">
                                <div className="form-group-label">
                                  {f.fields[0].label}
                                  {f.fields[0].required.length > 0
                                    ? " *"
                                    : ""}{" "}
                                </div>
                                <div className={`form-group-ct cstm-edit-delete ${f.fields[0].type === "checkbox" ? "cstm-bdr-cb" : ""}`}>
                                  {f.fields[0]["options"].length < 1 ? (
                                    f.fields[0].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[0].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input type={f.fields[0].type} disabled />
                                    )
                                  ) : (
                                    f.fields[0].type === "checkbox" ? <div className="hobbies-lbl">{(
                                      f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[0].type === "radio" ?
                                      <div className="hobbies-lbl"> {f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })} </div> :
                                      <select>{
                                        f.fields[0].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#editdbfield"
                                      href="#"
                                      onClick={() => {
                                        setshowEditDBField(true);
                                        setfieldindex(0);
                                        setshowDBeditformdiv(true);
                                        setselectedFieldId(f._id);
                                        setdbselectedfields(f.fields);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                    <a
                                      data-toggle="modal"
                                      data-target="#deleteConfirmBox"
                                      style={
                                        f._id === NameGrid ||
                                          f._id === PasswordGrid ||
                                          f._id === EmailGrid
                                          ? { display: "none" }
                                          : {}
                                      }
                                      onClick={() => {
                                        setdbdeletefieldid(f._id);
                                        // handledbfieldGridDelete(f._id);
                                      }}
                                    >
                                      <img src={deletefill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );

                      case 2:
                        return (
                          <>
                            <div className="row">
                              <div className="col-sm-6 form-group">
                                <div className="form-group-label">
                                  {f.fields[0].label}
                                  {f.fields[0].required.length > 0 ? " *" : ""}
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[0]["options"].length < 1 ? (
                                    f.fields[0].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[0].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input type={f.fields[0].type} disabled />
                                    )
                                  ) :
                                    (
                                      f.fields[0].type === "checkbox" ? <div className="hobbies-lbl"> {(
                                        f.fields[0].options.map((opt) => {
                                          return (
                                            <label className="form-check-label">
                                              <input type="checkbox" />
                                              <span className="qsn-lbl">{opt}</span>
                                            </label>
                                          );
                                        })
                                      )} </div> : (f.fields[0].type === "radio" ? <div className="hobbies-lbl">{
                                        f.fields[0].options.map((opt) => {
                                          return (
                                            <label className="form-check-label">
                                              <input type="radio" />
                                              <span className="qsn-lbl">{opt}</span>
                                            </label>
                                          );
                                        })}</div> :
                                        <select>{
                                          f.fields[0].options.map((opt) => {
                                            return (
                                              <option>{opt}</option>

                                            );
                                          })} </select>
                                      )
                                    )
                                  }
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#editdbfield"
                                      href="#"
                                      onClick={() => {
                                        setshowEditDBField(true);
                                        setfieldindex(0);
                                        setshowDBeditformdiv(true);
                                        setselectedFieldId(f._id);
                                        setdbselectedfields(f.fields);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 form-group">
                                <div className="form-group-label">
                                  {f.fields[1].label}
                                  {f.fields[1].required.length > 0 ? " *" : ""}
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[1]["options"].length < 1 ? (
                                    f.fields[1].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[1].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input type={f.fields[1].type} disabled />
                                    )
                                  ) : (
                                    f.fields[1].type === "checkbox" ? <div className="hobbies-lbl">{(
                                      f.fields[1].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[1].type === "radio" ? <div className="hobbies-lbl">{
                                      f.fields[1].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[1].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#editdbfield"
                                      href="#"
                                      onClick={() => {
                                        setshowEditDBField(true);
                                        setfieldindex(1);
                                        setshowDBeditformdiv(true);
                                        setselectedFieldId(f._id);
                                        setdbselectedfields(f.fields);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <a
                                data-toggle="modal"
                                data-target="#deleteConfirmBox"
                                style={
                                  f._id === NameGrid ||
                                    f._id === PasswordGrid ||
                                    f._id === EmailGrid
                                    ? { display: "none" }
                                    : {}
                                }
                                onClick={() => {
                                  setdbdeletefieldid(f._id);
                                }}
                              >
                                <img src={deletefill} />
                              </a>
                            </div>
                          </>
                        );
                      case 3:
                        return (
                          <>
                            <div className="row">
                              <div className="col-sm-4 form-group">
                                <div className="form-group-label">
                                  {f.fields[0].label}
                                  {f.fields[0].required.length > 0
                                    ? " *"
                                    : ""}{" "}
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[0]["options"].length < 1 ? (
                                    f.fields[0].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[0].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input type={f.fields[0].type} disabled />
                                    )
                                  ) : (
                                    f.fields[0].type === "checkbox" ? <div className="hobbies-lbl"> {(
                                      f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[0].type === "radio" ? <div className="hobbies-lbl">{
                                      f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[0].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#editdbfield"
                                      href="#"
                                      onClick={() => {
                                        setshowEditDBField(true);
                                        setfieldindex(0);
                                        setshowDBeditformdiv(true);
                                        setselectedFieldId(f._id);
                                        setdbselectedfields(f.fields);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4 form-group">
                                <div className="form-group-label">
                                  {f.fields[1].label}
                                  {f.fields[1].required.length > 0
                                    ? " *"
                                    : ""}{" "}
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[1]["options"].length < 1 ? (
                                    f.fields[1].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[1].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input type={f.fields[1].type} disabled />
                                    )
                                  ) : (
                                    f.fields[1].type === "checkbox" ? <div className="hobbies-lbl"> {(
                                      f.fields[1].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[1].type === "radio" ? <div className="hobbies-lbl"> {
                                      f.fields[1].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[1].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#editdbfield"
                                      href="#"
                                      onClick={() => {
                                        setshowEditDBField(true);
                                        setfieldindex(1);
                                        setshowDBeditformdiv(true);
                                        setselectedFieldId(f._id);
                                        setdbselectedfields(f.fields);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4 form-group">
                                <div className="form-group-label">
                                  {f.fields[2].label}
                                  {f.fields[2].required.length > 0
                                    ? " *"
                                    : ""}{" "}
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[2]["options"].length < 1 ? (
                                    f.fields[2].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[2].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input type={f.fields[2].type} disabled />
                                    )
                                  ) : (
                                    f.fields[2].type === "checkbox" ? <div className="hobbies-lbl">{(
                                      f.fields[2].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[2].type === "radio" ? <div className="hobbies-lbl"> {
                                      f.fields[2].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[2].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#editdbfield"
                                      href="#"
                                      onClick={() => {
                                        setshowEditDBField(true);
                                        setfieldindex(2);
                                        setshowDBeditformdiv(true);
                                        setselectedFieldId(f._id);
                                        setdbselectedfields(f.fields);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <a
                                data-toggle="modal"
                                data-target="#deleteConfirmBox" style={
                                  f._id === NameGrid ||
                                    f._id === PasswordGrid ||
                                    f._id === EmailGrid
                                    ? { display: "none" }
                                    : {}
                                }
                                onClick={() => {
                                  setdbdeletefieldid(f._id);
                                }}
                              >
                                <img src={deletefill} />
                              </a>
                            </div>
                          </>
                        );
                      default:
                        break;
                    }
                  })}
                  {showEditDBField ? (
                    <EditDBField
                      selectedFieldId={selectedFieldId}
                      fields={dbselectedfields}
                      fieldindex={fieldindex}
                      showDBeditformdiv={showDBeditformdiv}
                      setshowDBeditformdiv={setshowDBeditformdiv}
                    />
                  ) : (
                    ""
                  )}

                  {/* Display currently adding fields */}
                  {fields.length > 0
                    ? fields.map((f, index) => {
                      switch (f.grid) {
                        case 1:
                          return (
                            <div className="row">
                              <div className="col-sm-12 form-group">
                                <div className="form-group-label">
                                  {f.fields[0].label}
                                  {f.fields[0].required.length > 0
                                    ? " *"
                                    : ""}{" "}
                                  <span className="um-fm-tl">
                                    <i className="fa fa-exclamation-circle"></i>
                                  </span>
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[0]["options"].length < 1 ? (
                                    f.fields[0].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[0].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input
                                        type={f.fields[0].type}
                                        disabled
                                      />
                                    )
                                  ) : (
                                    f.fields[0].type === "checkbox" ? <div className="hobbies-lbl">{(
                                      f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[0].type === "radio" ? <div className="hobbies-lbl">{
                                      f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[0].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#Editfield"
                                      onClick={() => {
                                        handleGridEdit(index, 0);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                    <a
                                      onClick={() => {
                                        handleGridDelete(index);
                                      }}
                                    >
                                      <img src={deletefill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        case 2:
                          return (
                            <div className="row">
                              <div className="col-sm-6 form-group">
                                <div className="form-group-label">
                                  {f.fields[0].label}
                                  {f.fields[0].required.length > 0
                                    ? " *"
                                    : ""}
                                  <span className="um-fm-tl">
                                    <i className="fa fa-exclamation-circle"></i>
                                  </span>
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[0]["options"].length < 1 ? (
                                    f.fields[0].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[0].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input
                                        type={f.fields[0].type}
                                        disabled
                                      />
                                    )
                                  ) : (
                                    f.fields[0].type === "checkbox" ? <div className="hobbies-lbl">{(
                                      f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[0].type === "radio" ? <div className="hobbies-lbl">{
                                      f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[0].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#Editfield"
                                      onClick={() => {
                                        handleGridEdit(index, 0);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-6 form-group">
                                <div className="form-group-label">
                                  {f.fields[1].label}
                                  {f.fields[1].required.length > 0
                                    ? " *"
                                    : ""}
                                  <span className="um-fm-tl">
                                    <i className="fa fa-exclamation-circle"></i>
                                  </span>
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[1]["options"].length < 1 ? (
                                    f.fields[1].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[1].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input
                                        type={f.fields[1].type}
                                        disabled
                                      />
                                    )
                                  ) : (
                                    f.fields[1].type === "checkbox" ? <div className="hobbies-lbl"> {(
                                      f.fields[1].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[1].type === "radio" ? <div className="hobbies-lbl"> {
                                      f.fields[1].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[1].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#Editfield"
                                      onClick={() => {
                                        handleGridEdit(index, 1);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <a
                                href="#"
                                onClick={() => {
                                  handleGridDelete(index);
                                }}
                              >
                                <img src={deletefill} />
                              </a>
                            </div>
                          );
                        case 3:
                          return (
                            <div className="row">
                              <div className="col-sm-4 form-group">
                                <div className="form-group-label">
                                  {f.fields[0].label}
                                  {f.fields[0].required.length > 0
                                    ? " *"
                                    : ""}{" "}
                                  <span className="um-fm-tl">
                                    <i className="fa fa-exclamation-circle"></i>
                                  </span>
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[0]["options"].length < 1 ? (
                                    f.fields[0].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[0].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input
                                        type={f.fields[0].type}
                                        disabled
                                      />
                                    )
                                  ) : (
                                    f.fields[0].type === "checkbox" ? <div className="hobbies-lbl">{(
                                      f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[0].type === "radio" ? <div className="hobbies-lbl">{
                                      f.fields[0].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[0].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#Editfield"
                                      onClick={() => {
                                        handleGridEdit(index, 0);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4 form-group">
                                <div className="form-group-label">
                                  {f.fields[1].label}
                                  {f.fields[1].required.length > 0
                                    ? " *"
                                    : ""}{" "}
                                  <span className="um-fm-tl">
                                    <i className="fa fa-exclamation-circle"></i>
                                  </span>
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[1]["options"].length < 1 ? (
                                    f.fields[1].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[1].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input
                                        type={f.fields[1].type}
                                        disabled
                                      />
                                    )
                                  ) : (
                                    f.fields[1].type === "checkbox" ? <div className="hobbies-lbl">{(
                                      f.fields[1].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[1].type === "radio" ? <div className="hobbies-lbl"> {
                                      f.fields[1].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[1].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#Editfield"
                                      onClick={() => {
                                        handleGridEdit(index, 1);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4 form-group">
                                <div className="form-group-label">
                                  {f.fields[2].label}
                                  {f.fields[2].required.length > 0
                                    ? " *"
                                    : ""}{" "}
                                  <span className="um-fm-tl">
                                    <i className="fa fa-exclamation-circle"></i>
                                  </span>
                                </div>
                                <div className="form-group-ct cstm-edit-delete">
                                  {f.fields[2]["options"].length < 1 ? (
                                    f.fields[2].placeholder.length > 0 ? (
                                      <input
                                        type="text"
                                        value={f.fields[2].placeholder}
                                        disabled
                                      />
                                    ) : (
                                      <input
                                        type={f.fields[2].type}
                                        disabled
                                      />
                                    )
                                  ) : (
                                    f.fields[2].type === "checkbox" ? <div className="hobbies-lbl">{(
                                      f.fields[2].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="checkbox" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })
                                    )}</div> : (f.fields[2].type === "radio" ? <div className="hobbies-lbl">{
                                      f.fields[2].options.map((opt) => {
                                        return (
                                          <label className="form-check-label">
                                            <input type="radio" />
                                            <span className="qsn-lbl">{opt}</span>
                                          </label>
                                        );
                                      })}</div> :
                                      <select>{
                                        f.fields[2].options.map((opt) => {
                                          return (
                                            <option>{opt}</option>

                                          );
                                        })} </select>
                                    )
                                  )}
                                  <div className="cstm-edit-delete-main">
                                    <a
                                      data-toggle="modal"
                                      data-target="#Editfield"
                                      onClick={() => {
                                        handleGridEdit(index, 2);
                                      }}
                                    >
                                      <img src={editfill} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <a
                                href="#"
                                onClick={() => {
                                  handleGridDelete(index);
                                }}
                              >
                                <img src={deletefill} />
                              </a>
                            </div>
                          );
                        default:
                          break;
                      }
                    })
                    : ""}
                </>
              ) : (
                ""
              )}

              {styleDiv.display === "inline" ? (
                <EditField
                  fields={fields}
                  setFields={setFields}
                  fieldindex={fieldindex}
                  selectedField={selectedField}
                  styleDiv={styleDiv}
                  setstyleDiv={setstyleDiv}
                />
              ) : (
                ""
              )}
              <div className="cstm-aad-field">
                <a
                  data-toggle="modal"
                  data-target="#Addgrid"
                  onClick={(e) => {
                    setShowgrid(true);
                  }}
                >
                  + Add Field
                </a>
              </div>

              <div className="cstm-update-form-btn">
                <button
                  type="button"
                  className="btn"
                  onClick={(e) => {
                    addtodatabase();
                  }}
                >
                  Update Form
                </button>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
      {/* Modal Delete */}
      <DeleteModal message={"Are you sure you want to delete this fields?"} handleDelete={handledbfieldGridDelete} modalId="deleteConfirmBox" />
      {/* </div> */}
    </>
  );
};
export default RegistrationForm;
