import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import searchIcon from "../../../Images/icn_m_search.svg";
import addPlusIcon from "../../../Images/icn_m_plus.svg";
import addIcon from "../../../Images/add.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import closePopup from "../../../Images/close-popup.svg";
import IcnMClock from "../../../Images/icn_m_clock.svg";
import CloseGmv from "../../../Images/CloseGmv.svg";
import shortDownIcon from "../../../Images/table-heading sort-down.svg";
import shortUpIcon from "../../../Images/table-heading sort-up.svg";
import collaboratorInfoIcon from "../../../Images/info-collaborator-Icon.png";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import authServiceCollaborator from "../../../Services/auth.service.collaborator";
import IconSearchNoResults from "../../GlobalComponents/Icons/IconEmptyState/IconSearchNoResults";
import { fetchmyaccount } from "../../../Action";
import IconTimeLapse from "../../GlobalComponents/Icons/IconEmptyState/IconTimeLapse";

import { useNavigate, useParams } from "react-router-dom";

const UserInvite = ({ profileDetail }) => {
  const column = [
    { id: "email", label: "Email", width: 35 },
    { id: "status", label: "Status", width: 22 },
    { id: "action", label: "", width: 43 },
  ];
  const [isUser, setUser] = useState(false);
  const [rows, setRows] = useState([]);
  const [allData, setAllData] = useState([]);
  const [email, setEmail] = useState("");
  const [searchText, setSearchText] = useState("");
  const [error, setError] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [isRevoke, setRevoke] = useState(false);
  const [revokeInvite, setRevokeInvite] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [userId, setUserId] = useState("");
  const [isAsc, setAsc] = useState(false);
  const [seatTotal, setSeatTotal] = useState(0);
  const [seatPercent, setSeatPercent] = useState(0);
  const [seatPending, setSeatPending] = useState(0);
  const [seatAvailable, setSeatAvailable] = useState(0);
  const [showCollaboratorMsg, setShowCollaboratorMsg] = useState(false);
  const [disabledInviteButton, setDisabledInviteButton] = useState(false);
  const [isExistsErr, setExistsErr] = useState(false);
  const dispatch = useDispatch();
  const updatedProfileDetail = useSelector((state) => {
    return state.ReduProfile;
  });
  const navigate = useNavigate();
  const { action } = useParams();

  useEffect(() => {
    setSeatAvailable(profileDetail.totalNoOfAvailableInvite);
    setSeatTotal(profileDetail.totalNoOfTeamMate);
    setSeatPercent(
      Math.round(
        (profileDetail.totalNoOfAvailableInvite /
          Math.round(profileDetail.totalNoOfTeamMate)) *
          100
      )
    );
    if (
      Math.round(
        (profileDetail.totalNoOfAvailableInvite /
          Math.round(profileDetail.totalNoOfTeamMate)) *
          100
      ) <= 25
    ) {
      setShowCollaboratorMsg(true);
    } else {
      setShowCollaboratorMsg(false);
    }
    if (Math.round(profileDetail.totalNoOfAvailableInvite) <= 0) {
      setDisabledInviteButton(true);
    } else {
      setDisabledInviteButton(false);
    }
  }, [profileDetail]);

  useEffect(() => {
    setSeatAvailable(updatedProfileDetail.totalNoOfAvailableInvite);
    setSeatTotal(updatedProfileDetail.totalNoOfTeamMate);
    setSeatPercent(
      Math.round(
        (updatedProfileDetail.totalNoOfAvailableInvite /
          Math.round(updatedProfileDetail.totalNoOfTeamMate)) *
          100
      )
    );
    if (
      Math.round(
        (updatedProfileDetail.totalNoOfAvailableInvite /
          Math.round(updatedProfileDetail.totalNoOfTeamMate)) *
          100
      ) <= 25
    ) {
      setShowCollaboratorMsg(true);
    } else {
      setShowCollaboratorMsg(false);
    }
    if (Math.round(updatedProfileDetail.totalNoOfAvailableInvite) <= 0) {
      setDisabledInviteButton(true);
    } else {
      setDisabledInviteButton(false);
    }
  }, [updatedProfileDetail]);

  useEffect(() => {
    setLoader(true);

    getCollaboratorsForUserApi();
    if (action === "userinvite") {
      setUser(true);
    } else {
      setUser(false);
    }
  }, [isUser, action]);

  // getCollaboratorsForUser Api function
  async function getCollaboratorsForUserApi() {
    try {
      const listRes = await authServiceCollaborator.getCollaboratorsForUser();
      if (listRes.data.status) {
        const data = listRes.data.data.filter(
          (item) => item.teamMateInvitationStatus !== "revoked"
        );
        setRows(data);
        setAllData(data);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  }

  //email regex
  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  // send invite
  const handleInvite = () => {
    navigate("/settings/userinvite");
    if (seatPercent <= 25) {
      setShowCollaboratorMsg(true);
    } else {
      setShowCollaboratorMsg(false);
    }

    if (seatAvailable > 0) {
      setUser(true);
      setEmail("");
      setError("");
    }
  };

  // handle email change
  const handleChange = (e) => {
    setEmail(e.target.value);
    e.target.value.trim() === ""
      ? setError("Please enter email address.")
      : !emailRegex.test(e.target.value)
      ? setError("Please enter a valid email address.")
      : setError("");
  };

  // handle send invite to user
  const handleSendInvitation = async () => {
    if (email.trim() === "") {
      setError("Please enter email address.");
    } else if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
    } else {
      setInProcess(true);
      try {
        const sendInviteRes = await authServiceCollaborator.sendInviteByUser({
          emailAddress: email,
        });
        if (sendInviteRes.data.status) {
          // dispatch(fetchmyaccount());
          setSuccess(true);
          setInProcess(false);
          setMessage(sendInviteRes.data.message);
          setEmail("");
          setUser(false);
          setTimeout(() => {
            setSuccess(false);
            setMessage("");
          }, 10000);
          dispatch(fetchmyaccount());
          navigate("/settings/users");
        } else {
          setInProcess(false);
          if (sendInviteRes.data.isExistsErr) {
            setExistsErr(true);
          } else {
            setIsError(true);
            setMessage(sendInviteRes.data.message);
            setTimeout(() => {
              setIsError(false);
              setMessage("");
            }, 10000);
          }
        }
      } catch (error) {
        setInProcess(false);
        return false;
      }
    }
  };

  // revoke invitation function
  const handleRevokeInvite = async () => {
    setInProcess(true);
    const data = {
      collaboratorId: userId,
      type: "revoke",
    };
    try {
      const revokeRes =
        await authServiceCollaborator.revokeOrResendInviteByUser(data);
      if (revokeRes.data.status) {
        getCollaboratorsForUserApi();
        setUserId("");
        setInProcess(false);
        setRevokeInvite(false);
        setRevoke(false);
        setSuccess(true);
        setMessage(revokeRes.data.message);
        setTimeout(() => {
          setSuccess(false);
          setMessage("");
        }, 10000);
        dispatch(fetchmyaccount());
      } else {
        setInProcess(false);
        setMessage(revokeRes.data.message);
        setTimeout(() => {
          setIsError(false);
          setMessage("");
        }, 10000);
      }
    } catch (error) {
      setInProcess(false);
      return false;
    }
  };

  // resend invitation function
  const handleResendInvite = async (userId) => {
    const data = {
      collaboratorId: userId,
      type: "resend",
    };
    try {
      const revokeRes =
        await authServiceCollaborator.revokeOrResendInviteByUser(data);
      if (revokeRes.data.status) {
        getCollaboratorsForUserApi();
        setUserId("");
        setSuccess(true);
        setMessage(revokeRes.data.message);
        setTimeout(() => {
          setSuccess(false);
          setMessage("");
        }, 10000);
        dispatch(fetchmyaccount);
      } else {
        if (sendInviteRes.data.isExistsErr) {
          setExistsErr(true);
        } else {
          setIsError(true);
          setMessage(revokeRes.data.message);
          setTimeout(() => {
            setIsError(false);
            setMessage("");
          }, 10000);
        }
      }
    } catch (error) {
      return false;
    }
  };

  // handleSearch
  const handleSearch = (text) => {
    setSearchText(text);
    if (allData !== undefined && allData.length > 0) {
      var searchResult = allData.filter((user) => {
        return user.email.toLowerCase().includes(text.trim().toLowerCase());
      });
      setRows(searchResult);
    }
  };

  // Sorting function
  // const handleSort = (col, direc) => {
  //     var sortedResult = [...allData]; // Create a copy to avoid mutating the original state
  //     if (direc.toLowerCase() === "asc") {
  //         setAsc(true)
  //         sortedResult.sort(function (firstItem, secondItem) {
  //             var itemA = firstItem[col];
  //             var itemB = secondItem[col];
  //             return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
  //         });
  //     } else if (direc.toLowerCase() === "desc") {
  //         setAsc(false)
  //         sortedResult.sort(function (firstItem, secondItem) {
  //             var itemA = firstItem[col];
  //             var itemB = secondItem[col];
  //             return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
  //         });
  //     }
  //     const table = sortedResult.map((item) => item);
  //     setRows(table);
  // };

  const handleCloseErrorModalPopup = () => {
    setExistsErr(false);
  };
  return (
    <>
      <div className="cstm-content-user-main">
        {isUser ? (
          <>
            <div className="cstm-user-header-main">
              <h2 className="cstm-user-title">Invite Team-users</h2>
            </div>
            {showCollaboratorMsg && (
              <>
                <div className="cstm-collaborator-request-msg-container">
                  <span className="cstm-collaborator-request-msg-icon">
                    <img src={collaboratorInfoIcon} />
                  </span>
                  <span className="cstm-collaborator-request-msg-text">
                    <h4>
                      {seatAvailable} {seatAvailable > 0 && `of ${seatTotal}`}{" "}
                      seats available
                    </h4>
                    <h5>Request new seats to invite more users.</h5>
                  </span>
                  <span className="cstm-collaborator-request-msg-btn">
                    <a
                      onClick={() => {
                        if (window.Intercom) {
                          window.Intercom("show");
                        }
                      }}
                    >
                      Request an extra seats
                    </a>
                  </span>
                </div>
              </>
            )}
            <div className="cstm-user-email-address">
              <div className="cstm-user-email-address-box">
                <h5>Email address</h5>
                <input
                  type="email"
                  placeholder="Enter email address"
                  className={
                    error
                      ? "cstm-input-field error-ft-border"
                      : "cstm-input-field"
                  }
                  onChange={handleChange}
                />
                {error && <span className="error-ft">{error}</span>}
              </div>
              <div className="cstm-user-email-address-btn">
                <button
                  className="btn-cancel"
                  onClick={() => {
                    setUser(false);
                    navigate("/settings/users");
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn-review disabled-btn-color"
                  disabled={email.trim() === "" || !emailRegex.test(email)}
                  onClick={handleSendInvitation}
                >
                  {inProcess && (
                    <span className="cstm-invite-loader">
                      <img src={Loader2} />
                    </span>
                  )}
                  Send invite
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="cstm-user-header-main">
              {/* <h2 className="cstm-user-title">Users</h2> */}
              {showCollaboratorMsg && (
                <>
                  <div className="cstm-collaborator-request-msg-container">
                    <span className="cstm-collaborator-request-msg-icon">
                      <img src={collaboratorInfoIcon} />
                    </span>
                    <span className="cstm-collaborator-request-msg-text">
                      <h4>
                        {seatAvailable} of {seatTotal} seats available
                      </h4>
                      <h5>Request new seats to invite more users.</h5>
                    </span>
                    <span className="cstm-collaborator-request-msg-btn">
                      <a
                        onClick={() => {
                          if (window.Intercom) {
                            window.Intercom("show");
                          }
                        }}
                      >
                        Request an extra seats
                      </a>
                    </span>
                  </div>
                </>
              )}
              <div className="co-users-main-row">
                <div className="co-users-main-row-left">
                  <h2 className="cstm-user-title">Team-users</h2>
                </div>
                <div className="co-users-main-row-right">
                  {!showCollaboratorMsg && (
                    <button
                      className="request-new-seat-btn"
                      onClick={() => {
                        if (window.Intercom) {
                          window.Intercom("show");
                        }
                      }}
                    >
                      Request an extra seats
                    </button>
                  )}
                  <button
                    className="invite-btn"
                    // disabled={disabledInviteButton}
                    onClick={handleInvite}
                  >
                    <img src={addPlusIcon} />
                    Invite
                  </button>
                </div>
              </div>
              {rows.length > 0 && (
                <div className="cstm-user-data">
                  <div className="cstm-user-data-left">
                    <div className="cstm-user-data-left-search">
                      <img src={searchIcon} className="cstm-user-search-icon" />
                      <input
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchText}
                        type="text"
                        placeholder="Search"
                        className="cstm-user-search-input"
                      />
                    </div>
                  </div>

                  <div className="cstm-user-data-right"></div>
                </div>
              )}
            </div>

            <div className="table-fn-only cstm-user-table">
              <TableContainer>
                <Table>
                  {rows.length > 0 && (
                    <TableHead>
                      <TableRow>
                        {column.map((col) => (
                          <TableCell className={`wf-${col.width}`}>
                            {col.label}
                            {
                              col.id === "email" && (
                                <>
                                  {/* <img src={downArrow} alt={downArrow} /> */}
                                </>
                              )
                              // &&
                              // <>
                              //     {/* {isAsc ? <img className='cstm-tbl-arrow2' src={shortUpIcon} onClick={(e) => {
                              //         handleSort(col.id, "desc");
                              //     }} /> :
                              //         <img className='cstm-tbl-arrow' src={shortDownIcon} onClick={(e) => {
                              //             handleSort(col.id, "asc");
                              //         }} />} */}

                              // </>
                            }
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  )}
                  {loader ? (
                    <div className="cstm-table-fn-Loader">
                      <img src={Loader} />{" "}
                    </div>
                  ) : rows.length > 0 ? (
                    <TableBody>
                      {rows.map((row, i) => (
                        <TableRow>
                          <TableCell>
                            <span className="cstm-suer-email">
                              {row.email}{" "}
                            </span>
                          </TableCell>
                          <TableCell>
                            <span
                              className={
                                row.teamMateInvitationStatus === "accepted"
                                  ? "cstm-user-accepted"
                                  : row.teamMateInvitationStatus === "pending"
                                  ? "cstm-user-pending"
                                  : "cstm-user-revoke"
                              }
                            >
                              {row.teamMateInvitationStatus == "pending"
                                ? "Invite Sent"
                                : row.teamMateInvitationStatus}
                            </span>
                          </TableCell>
                          <TableCell className="cstm-btn-user-label">
                            {row.teamMateInvitationStatus === "accepted" ? (
                              <button
                                className="cstm-user-label-btn"
                                onClick={() => {
                                  setRevoke(true);
                                  setUserId(row._id);
                                }}
                              >
                                Revoke
                              </button>
                            ) : row.teamMateInvitationStatus === "pending" ? (
                              <>
                                <button
                                  className="cstm-user-label-btn"
                                  onClick={() => handleResendInvite(row._id)}
                                >
                                  Resend
                                </button>
                                <button
                                  className="cstm-user-label-btn"
                                  onClick={() => {
                                    setRevokeInvite(true);
                                    setUserId(row._id);
                                  }}
                                >
                                  Revoke invite
                                </button>
                              </>
                            ) : (
                              <button
                                className="cstm-user-label-btn"
                                onClick={() => handleResendInvite(row._id)}
                              >
                                Resend again
                              </button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <>
                      <div className="table-fn-invite-a-user-main">
                        <div className="table-fn-invite-a-user">
                          <div className="table-fn-invite-a-user-icon">
                            {searchText !== "" ? (
                              <IconSearchNoResults />
                            ) : (
                              <img src={addIcon} />
                            )}
                          </div>
                          <div className="table-fn-invite-a-user-title">
                            {searchText !== "" ? (
                              <>
                                <h4>Oops! No results this time</h4>
                                <h5>
                                  Please adjust your searching filters and give
                                  it another go!
                                </h5>
                              </>
                            ) : (
                              <>
                                <h4>Add some team-users</h4>
                                <h5>
                                  You can invite up to{" "}
                                  {profileDetail.inviteCollaboratorDetail !==
                                    undefined &&
                                    profileDetail.inviteCollaboratorDetail
                                      .available}{" "}
                                  people.
                                </h5>
                              </>
                            )}
                          </div>
                          {searchText === "" && (
                            <div className="table-fn-invite-a-user-btn">
                              {/* <button className="btn-cancel" onClick={handleInvite}>Invite a user</button> */}
                              <button
                                className="btn-cancel"
                                onClick={handleInvite}
                              >
                                Invite user
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </div>

      {isSuccess && (
        <div className="invitation-successfully-msg">
          <img src={IcnMClock} />
          <h4>{message}</h4>
          <img
            src={CloseGmv}
            className="invitation-successfully-msg-close"
            onClick={() => {
              setSuccess(false);
            }}
          />
        </div>
      )}
      {isError && (
        <div className="invitation-successfully-msg red-only">
          <img src={IcnMClock} />
          <h4>{message}</h4>
          <img
            className="invitation-successfully-msg-close"
            src={CloseGmv}
            onClick={() => {
              setIsError(false);
            }}
          />
        </div>
      )}

      <div className={`${isRevoke ? "modal-fadein" : "modal-fadeout"}`}>
        <div className="desk-globle-model-box globle-model-box ">
          <div className="desk-globle-model-box-inner collaborator-revoke-popup-main">
            <div className="partners-details-popup-main-head">
              <h3 className="partners-details-popup-title">Revoke invite?</h3>
              <span
                className="partners-details-popup-close"
                onClick={() => setRevoke(false)}
              >
                <img src={closePopup} />
              </span>
            </div>
            <div className="partners-details-popup-leave-a-review">
              <p className="cstm-user-pgf-text">
                You are about to revoke this user from your account. All access
                for this user will be revoked.
              </p>
            </div>
            <div className="partners-details-popup-main-footer">
              <button className="btn-cancel" onClick={() => setRevoke(false)}>
                Cancel
              </button>
              <button className="btn-review" onClick={handleRevokeInvite}>
                {inProcess && (
                  <span className="cstm-invite-loader">
                    <img src={Loader2} />
                  </span>
                )}
                Revoke user
              </button>
            </div>
          </div>
        </div>
      </div>
      {isExistsErr && (
        <div className="desk-globle-model-box globle-model-box ">
          <div className="desk-globle-model-box-inner collaborator-revoke-popup-main">
            <div className="partners-details-popup-main-head">
              <h3 className="partners-details-popup-title">No Invite Needed</h3>
            </div>
            <div className="partners-details-popup-leave-a-review">
              <p className="cstm-user-pgf-text">
                Oops! This person is already in. Pick someone else to invite
              </p>
            </div>
            <div className="partners-details-popup-main-footer">
              <button onClick={handleCloseErrorModalPopup} class="btn-review">
                Okay, got it!
              </button>
            </div>
          </div>
        </div>
      )}

      <div className={`${revokeInvite ? "modal-fadein" : "modal-fadeout"}`}>
        <div className="desk-globle-model-box globle-model-box ">
          <div className="desk-globle-model-box-inner collaborator-revoke-popup-main">
            <div className="partners-details-popup-main-head">
              <h3 className="partners-details-popup-title">Revoke invite?</h3>
              <span
                className="partners-details-popup-close"
                onClick={() => setRevokeInvite(false)}
              >
                <img src={closePopup} />
              </span>
            </div>
            <div className="partners-details-popup-leave-a-review">
              <p className="cstm-user-pgf-text">
                You’re about to revoke invitation for this user.
              </p>
            </div>
            <div className="partners-details-popup-main-footer">
              <button
                className="btn-cancel"
                onClick={() => setRevokeInvite(false)}
              >
                Cancel
              </button>
              <button className="btn-review" onClick={handleRevokeInvite}>
                {inProcess && (
                  <span className="cstm-invite-loader">
                    <img src={Loader2} />
                  </span>
                )}
                Revoke invite{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInvite;