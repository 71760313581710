import React from 'react'
import PropTypes from "prop-types"
const MinusIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                d="M3.515 11.25a.75.75 0 0 0 0 1.5h16.97a.75.75 0 0 0 0-1.5H3.515Z"
            />

        </svg>
    )
}
MinusIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default MinusIcon
