const LoaderComponent = () =>
{ 
    return (
      <>
        <div className="text-center">
          <div className="loadermain">
            <span></span> <span></span> <span></span> <span></span>
            <span></span>
          </div>
        </div>
      </>
    );

}
export default LoaderComponent;