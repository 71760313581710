// Formate Images and video data formate for preview
export const formatMediaPreviewMessages = (messages) => {
  const media = [];
  messages.map((message) => {
    // Add images to the media array
    [...message.images].forEach((image) => {
      media.push({
        id: image.id,
        type: "image",
        url: image.url,
        aspectRatio: image.aspectRatio,
        sender: message.sender,
      });
    });
    // Add videos to the media array
    [...message.videos].forEach((video) => {
      media.push({
        id: video.id,
        type: "video",
        url: video.url,
        aspectRatio: video.aspectRatio,
        sender: message.sender,
        thumbnail: video.thumbnail,
      });
    });
  });
  return media;
};
// Formate Images and video data formate for display all list
export const formatMediaPreviewMessagesAllMedia = (messages) => {
  const media = [];
  messages.map((message) => {
    // Add videos to the media array
    [...message.videos].reverse().forEach((video) => {
      media.push({
        id: video.id,
        type: "video",
        url: video.url,
        aspectRatio: video.aspectRatio,
        sender: message.sender,
        thumbnail: video.thumbnail,
      });
    });
    // Add images to the media array
    [...message.images].reverse().forEach((image) => {
      media.push({
        id: image.id,
        type: "image",
        url: image.url,
        aspectRatio: image.aspectRatio,
        sender: message.sender,
      });
    });
  });
  return media;
};

export const formatAttachementMediaPreviewMessages = (messages) => {
  const media = [];
  // messages.map((message) => {
  // Add images to the media array
  messages?.images?.forEach((image) => {
    media.push({
      type: "image",
      url: image,
    });
  });

  // Add videos to the media array
  messages?.videos?.forEach((video) => {
    media.push({
      type: "video",
      url: video,
    });
  });
  return media;
};
