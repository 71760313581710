import axios from "axios";
import authServiceDocument from "../../../Services/auth.service.document";
// Create a cancel token source
let cancelTokenSource;

const extractFilenameFromUrl = (url) => {
    const urlObject = new URL(url);
    var str = urlObject.pathname.split('/')[3];
    str = str.substring((str.lastIndexOf("-") + 1), str.length).split('.').pop();
    return str;
};

// handle download document
export const handleDownloadDoc = async (fileUrl, setDownloading, setProgress, filename, docId) => {

    // Create a new cancel token source for each download
    cancelTokenSource = axios.CancelToken.source();

    const fileNameExtension = extractFilenameFromUrl(fileUrl);
    const config = {
        onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setDownloading(true);
            setProgress(percentCompleted);
            percentCompleted === 100 && setDownloading(false);
        },
        responseType: 'arraybuffer',
        cancelToken: cancelTokenSource.token
    };
    try {
        
        const response = await axios.get(fileUrl, config);
        const blob = new Blob([response.data]);
        //update download count
        authServiceDocument.updateDocumentDownloadCount(docId);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename + '.' + fileNameExtension; // Specify the desired file name
        a.click();
        window.URL.revokeObjectURL(url);
       
    } catch (error) {
        setDownloading(false)
        console.error('Error downloading file:', error);
    }
};

// Add a function to cancel the download
export const cancelDownload = (setDownloading, setProgress) => {
    setDownloading(false);
    setProgress(0);
    // Check if the cancel token source is available before canceling
    if (cancelTokenSource) {
        cancelTokenSource.cancel('Download canceled by user');
    }
};
