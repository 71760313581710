import React from "react";
import PropTypes from "prop-types"
function NavChatIcon({ height = 24, width = 24, iconColor = "#000", isFilled }) {
    return (
        <>
            {isFilled ?
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M2 7.5A5.5 5.5 0 0 1 7.5 2h9A5.5 5.5 0 0 1 22 7.5v5a5.5 5.5 0 0 1-5.5 5.5h-2.417a.2.2 0 0 0-.142.059l-3.234 3.234c-.63.63-1.707.184-1.707-.707V18.2a.2.2 0 0 0-.2-.2H7.5A5.5 5.5 0 0 1 2 12.5v-5Zm8 2.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                        clipRule="evenodd"
                    />
                </svg > : <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M7.5 3.5h9a4 4 0 0 1 4 4v5a4 4 0 0 1-4 4h-2.417a1.7 1.7 0 0 0-1.202.498l-2.381 2.38V18.2a1.7 1.7 0 0 0-1.7-1.7H7.5a4 4 0 0 1-4-4v-5a4 4 0 0 1 4-4ZM2 7.5A5.5 5.5 0 0 1 7.5 2h9A5.5 5.5 0 0 1 22 7.5v5a5.5 5.5 0 0 1-5.5 5.5h-2.417a.2.2 0 0 0-.142.059l-3.234 3.234c-.63.63-1.707.184-1.707-.707V18.2a.2.2 0 0 0-.2-.2H7.5A5.5 5.5 0 0 1 2 12.5v-5Zm13 3.219a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm-5.25-.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm2.25.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                        clipRule="evenodd"
                    />
                </svg>
            }
        </>
    );
}
NavChatIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool
}
export default NavChatIcon;
