import React, { useEffect, useState } from "react";
import authServiceNews from "../../../Services/auth.service.news";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";

const NewsBanner = ({ loader, setLoader }) => {
  const [bannerData, setBannerData] = useState([]);

  //call on first render
  useEffect(() => {
    getNewsBannerApi();
    setLoader(true);
  }, []);

  //newsbanner api call
  async function getNewsBannerApi() {
    try {
      const newsBannerRes = await authServiceNews.getUserNewsBanner();
      if (newsBannerRes.status) {
        setBannerData(newsBannerRes.data.data);
      }
    } catch (error) {
      setLoader(false);
      return false;
    }
  }
  const currentDateTime = moment();
  const settings = {
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
  };

  return (
    <>
      {loader ? (
        <>
          <div className="cstm-news-banner-loading skeleton-box"></div>
        </>
      ) : (
        <div className="cstm-news-banner">
          <Slider {...settings} ref={(slider) => (slider = slider)}>
            {bannerData.map((item, i) => {
              const publicationStartDate = moment(
                `${item.publicationStartDate} ${item.publicationStartTime}`,
                "MM-DD-YYYY hh:mm A"
              );
              const publicationEndDate = moment(
                `${item.publicationEndDate} ${item.publicationEndTime}`,
                "MM-DD-YYYY hh:mm A"
              );

              const shouldShowImage =
                currentDateTime.isSameOrAfter(publicationStartDate) &&
                currentDateTime.isSameOrBefore(publicationEndDate);
              return shouldShowImage &&
                item.webBannerImage !== undefined &&
                item.webBannerImage !== null &&
                item.webBannerImage != "" ? (
                <>
                  {/* For redirect the bannerURL */}
                  <a
                    key={i}
                    href={item?.bannerUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={
                        process.env.REACT_APP_AWS_IMG_VID_PATH +
                        item?.webBannerImage
                      }
                      alt={`Banner ${i}`}
                    />
                  </a>
                </>
              ) : null;
            })}
          </Slider>
        </div>
      )}
    </>
  );
};

export default NewsBanner;
