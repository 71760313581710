import { useState, useEffect, useRef } from "react";
// import SaveVideosList from "../Dashboard/SaveVideosList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchsavedvideos } from "../../../Action";

import moment from "moment";
import videoimg from "../../../Images/nothumbnail.png";
import DeleteDrop from "../../../Images/DeleteDrop.svg";

import VideoPlayer from "../../../Images/VideoPlayer.svg";
import MenuViewDt from "../../../Images/MenuViewDt.svg";
import ButtonWhite from "../../../Images/ButtonWhite.svg";
import loading from "../../../Images/loader.gif";
import Services from "../../../Services/auth.service";
import ShowMoreText from "react-show-more-text";
import noMediaFound from "../../../Images/NoVideo.svg";
import Exclude from "../../../Images/Exclude.svg";
import Cancel from "../../../Images/Cancel-filled.svg";
import { getVideoProgressPercentage } from "../../checktoken";
import sepbullet from "../../../Images/separator-bullet.svg";
import CensusDataModal from "../../User/PartnerManagement/CensusDataModal";
const SavedVideos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [singlevideo, setsinglevideo] = useState(null);
  const [showBtn, setshowBtn] = useState(false);
  const [deletesavedvideoBtnStatus, setDeleteSavedVideoBtnStatus] = useState(
    {}
  );

  const [loader, setloader] = useState(true);
  const [deletesavedloader, setdeletesavedloader] = useState({});

  const [deletesavedSingleloader, setdeletesavedSingleloader] = useState(false);
  const [nodatafound, setnodatafound] = useState(false);
  const [isRemoveSavedSingleSuccessMsg, setRemoveSavedSingleSuccessMsg] =
    useState(false);
  const savedvideos = useSelector((state) => {
    return state.ReduSavedVideos;
  });

  const savedvideoloader = useSelector((state) => {
    return state.ReduSavedVideoLoader;
  });
  const [removesavedmodalOpen, setremovesavedmodalOpen] = useState({});
  const [removesavedsinglemodal, setremovesavedsinglemodal] = useState(false);

  const [errMsgText, setErrMsgText] = useState("");
  const [errMsgFlag, seterrMsgFlag] = useState(false);

  const [access, setAccess] = useState(false);
  const [access1, setAccess1] = useState(false);
  const [access2, setAccess2] = useState(false);
  const [accessLoader, setAccessLoader] = useState(false);

  // on first render of page this useEffect call
  useEffect(() => {
    if (localStorage.getItem("count") > 364) {
      setAccess2(true)
      setAccessLoader(false)
    } else {
      setAccessLoader(true)
      setAccess2(false)
    }
  }, [])

  useEffect(() => {
    dispatch(fetchsavedvideos());

    window.scrollTo(0, 0);

    document.addEventListener("mousedown", (e) => {
      if (
        e.target.className !== "cstm-hv-op-drop" &&
        e.target.className !== "cstm-removeOpen-saved-btns" &&
        e.target.tagName !== "A" &&
        e.target.className !== "cstm-ssvpl-dp"
      ) {
        setshowBtn(false);
        var key_values = Object.values(deletesavedvideoBtnStatus);
        var formatted = {};
        if (key_values !== undefined) {
          key_values.forEach((key_value) =>
            Object.entries(key_value).forEach(
              ([key, value]) => (formatted = { ...formatted, [key]: value })
            )
          );
          setDeleteSavedVideoBtnStatus(formatted);
        }
      }
    });
  }, []);

  useEffect(() => {
    if ((singlevideo === null) & (savedvideos.length > 0)) {
      setsinglevideo(savedvideos[0]);
    }
    if (savedvideos.length <= 0) {
      setnodatafound(true);
    } else {
      setnodatafound(false);
    }
  }, [savedvideos]);

  function toggleSavedRemoveButton(e, video_id) {
    setDeleteSavedVideoBtnStatus({
      [video_id]: !deletesavedvideoBtnStatus[video_id],
    });
    //setDeleteVideoBtnStatus({...deletevideoBtnStatus,[video_id]: !deletevideoBtnStatus[video_id]}); // new object triggers re-render
  }

  function removeSavedVideo(e, video_id) {
    setdeletesavedloader({ [video_id]: true });
    setRemoveSavedSingleSuccessMsg(false);
    seterrMsgFlag(false);

    Services.savevideo(video_id)
      .then((res_) => {
        if (res_.data.status) {
          setsinglevideo(null);
          dispatch(fetchsavedvideos());
          setdeletesavedloader({ [video_id]: false });
          setRemoveSavedSingleSuccessMsg(true);
          setremovesavedmodalOpen({ [video_id]: false });
          setshowBtn(false);
          setTimeout(() => {
            setRemoveSavedSingleSuccessMsg(false);
          }, 3000);
        } else {
          setdeletesavedloader({ [video_id]: false });
          setErrMsgText("Unable to remove video from saved list. Try again.");
          seterrMsgFlag(true);
          setTimeout(() => {
            seterrMsgFlag(false);
          }, 3000);
          setshowBtn(false);
          setremovesavedmodalOpen({ [video_id]: false });
        }
      })
      .catch((e) => {
        setnodatafound(true);
        setdeletesavedloader({ [video_id]: false });
        setshowBtn(false);
        setRemoveSavedSingleSuccessMsg(false);
        setdeletesavedloader({ [video_id]: false });
        setErrMsgText("Unable to remove video from saved list. Try again.");
        seterrMsgFlag(true);
        setTimeout(() => {
          seterrMsgFlag(false);
        }, 3000);
        return false;
      });
  }

  function removeSingleSavedVideo(e, video_id) {
    setdeletesavedSingleloader(true);
    setRemoveSavedSingleSuccessMsg(false);
    seterrMsgFlag(false);
    Services.savevideo(video_id)
      .then((res_) => {
        if (res_.data.status) {
          setsinglevideo(null);
          dispatch(fetchsavedvideos());
          setdeletesavedSingleloader(false);
          setRemoveSavedSingleSuccessMsg(true);
          setremovesavedsinglemodal(false);
          setshowBtn(false);
          setTimeout(() => {
            setRemoveSavedSingleSuccessMsg(false);
          }, 3000);
        } else {
          setdeletesavedSingleloader(false);
          setshowBtn(false);
          setremovesavedsinglemodal(false);
          setErrMsgText("Unable to remove video from saved list. Try again.");
          seterrMsgFlag(true);
          setTimeout(() => {
            seterrMsgFlag(false);
          }, 3000);
        }
      })
      .catch((e) => {
        setnodatafound(true);
        setdeletesavedSingleloader(false);
        setshowBtn(false);
        setRemoveSavedSingleSuccessMsg(false);
        setdeletesavedSingleloader(false);
        setErrMsgText("Unable to remove video from saved list. Try again.");
        seterrMsgFlag(true);
        setTimeout(() => {
          seterrMsgFlag(false);
        }, 3000);
        return false;
      });
  }

  return (
    <>
      {
        access2 || (( localStorage.getItem("remindmetomorrowcensus") && moment(localStorage.getItem("remindmetomorrowcensus"),"YYYY-MM-DD").diff(moment().format("YYYY-MM-DD"), 'days') === 0 && localStorage.getItem("dontshowcensuspopup") === "false" && (access || access1) ) || ((localStorage.getItem("remindmetomorrowcensus") === null || localStorage.getItem("remindmetomorrowcensus") === undefined) && (localStorage.getItem("dontshowcensuspopup") === "false" || localStorage.getItem("dontshowcensuspopup") === null || localStorage.getItem("dontshowcensuspopup") === undefined) && (access || access1))) ?
          <CensusDataModal access={access} setAccess={setAccess} access1={access1} setAccess1={setAccess1} access2={access2} setAccess2={setAccess2} />
          :
          accessLoader ?
          <div
            id="saved"
            className={`cstm-videos-history-only ${nodatafound ? "cstm-no-history-video-container" : ""
              }`}
          >
            <div className="cstm-video-history-col-1">
              {!nodatafound && <div className="cstm-cl-title">Saved videos</div>}
              {nodatafound && savedvideoloader && (
                <div className="cstm-cl-title">Saved videos</div>
              )}

              {savedvideoloader ? (
                [...Array(savedvideos.length <= 0 ? 10 : savedvideos.length)].map(
                  (el, index) => (
                    <div className="cstm-vh-sh">
                      <div className="cstm-vh-sh-left skeleton-box"></div>
                      <div className="cstm-vh-sh-right">
                        <div className="cstm-vh-sh-right-top skeleton-box"></div>
                        <div className="cstm-vh-sh-right-bottom skeleton-box"></div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <></>
              )}

              {savedvideos.length > 0 ? (
                <div className="cstm-my-history-videos-list">
                  {!savedvideoloader &&
                    savedvideos.map((video, index) => {
                      return (
                        <div
                          className={`cstm-my-videos-history-list-main ${singlevideo !== null && singlevideo._id === video._id
                              ? "cstm-selected-video"
                              : ""
                            }`}
                        >
                          <a
                            onClick={(e) => {
                              setsinglevideo(video);
                            }}
                          >
                            <div className={`cstm-my-videos-history-list-main-box`}>
                              <div className="cstm-my-videos-history-list-main-box-img">
                                <div className="cstm-vh-vi">
                                  <img
                                    src={
                                      video.thumbnail
                                        ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                        video.thumbnail
                                        : videoimg
                                    }
                                  />
                                  <span className="cstm-vl-time">
                                    {video.duration}
                                  </span>
                                </div>
                                {getVideoProgressPercentage(video) !== 0 && (
                                  <div className="video_progress_wrapper">
                                    <div
                                      className="video_progress_bar"
                                      style={{
                                        width: `${getVideoProgressPercentage(
                                          video
                                        )}%`,
                                      }}
                                    ></div>
                                  </div>
                                )}
                              </div>
                              <div className="cstm-my-videos-history-list-main-box-info">
                                <div class="cstm-vh-title">{video.title}</div>

                                <div className="cstm-vh-info">
                                  <span className="cstm-vh-info-title">
                                    {video.categories[0].name}
                                  </span>
                                  <span className="cstm-vh-info-dt"><img src={sepbullet} /></span>
                                  <span className="cstm-vh-info-date">
                                    {moment(video.createdAt).fromNow()}
                                  </span>
                                  <span className="cstm-vh-info-dt"><img src={sepbullet} /></span>
                                  <span className="cstm-vh-info-views">
                                    {video.viewsCount} Views
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>

                          <div
                            className={`cstm-hv-op-drop  ${deletesavedvideoBtnStatus[video._id]
                                ? "cstm-open-dp"
                                : ""
                              }`}
                          >
                            <div
                              className="cstm-hv-op-drop-dt"
                              onClick={(e) => toggleSavedRemoveButton(e, video._id)}
                            >
                              {" "}
                              <img src={MenuViewDt} />
                            </div>
                            <div
                              className="cstm-removeOpen-saved-btns  cstm-dropin"
                              style={{
                                display: deletesavedvideoBtnStatus[video._id]
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <ul>
                                <li>
                                  <a
                                    onClick={() => {
                                      setremovesavedmodalOpen({ [video._id]: true });
                                    }}
                                  >
                                    <img src={DeleteDrop} />
                                    Remove from saved video
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {removesavedmodalOpen[video._id] && (
                            <div
                              className="desk-globle-model-box globle-model-box"
                              backdrop="static"
                            >
                              <div className="desk-globle-model-box-inner cstm-clear-watch-history">
                                <div class="cstm-clear-watch-history-mn">
                                  <div class="cstm-clear-watch-history-title">
                                    Remove from saved?
                                  </div>
                                  <div className="cstm-clear-watch-history-content">
                                    Do you want to delete this video from your Saved
                                    list?
                                  </div>
                                  <div className="cstm-clear-watch-history-link">
                                    <a
                                      className="cstm-cwh-cancel-btn"
                                      onClick={() => {
                                        setremovesavedmodalOpen({
                                          [video._id]: false,
                                        });
                                        setDeleteSavedVideoBtnStatus({
                                          [video._id]: false,
                                        });
                                      }}
                                    >
                                      Cancel
                                    </a>
                                    <a
                                      className="cstm-cwh-clear-btn historyvideo-remove-saved-video-loader"
                                      onClick={(e) => removeSavedVideo(e, video._id)}
                                    >
                                      {deletesavedloader[video._id] ? (
                                        <div className="historyvideo-loading-image">
                                          <img src={loading} />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      Remove
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        // </a>
                      );
                    })}
                </div>
              ) : (
                <></>
              )}

              {nodatafound && !savedvideoloader && savedvideos.length <= 0 && (
                <div class="cstm-no-history">
                  {" "}
                  <div class="cstm-oops-main-img">
                    <img src={noMediaFound} />
                  </div>{" "}
                  <div class="cstm-oops-main-ctn">
                    No videos saved yet!
                    <br />
                    Time to explore!
                  </div>{" "}
                </div>
              )}

              {isRemoveSavedSingleSuccessMsg && (
                <div className="cstm-success-msg-container">
                  <div className="cstm-success-msg-container-title">
                    <img src={Exclude} /> Removed from Saved videos!
                  </div>
                </div>
              )}

              {errMsgFlag && (
                <div className="cstm-success-msg-container cstm-an-red">
                  <div className="cstm-success-msg-container-title">
                    <img src={Cancel} /> Something went wrong.
                  </div>
                  <div className="cstm-success-msg-container-cont">{errMsgText}</div>
                </div>
              )}
            </div>
            <div className="cstm-video-history-col-2">
              {!savedvideoloader &&
                singlevideo !== null &&
                singlevideo !== undefined ? (
                <>
                  <div className="cstm-saved-single-video-in">
                    <div
                      className="cstm-saved-single-video-gallery-img"
                      onClick={() => {

                        navigate(
                          `/videos/${singlevideo._id}`
                        );
                      }}
                    >
                      <div className="cstm-saved-single-video-gallery-img-main ">
                        <img
                          className="cstm-saved-single-video-js"
                          src={
                            singlevideo.thumbnail
                              ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                              singlevideo.thumbnail
                              : videoimg
                          }
                        />
                      </div>
                      <div className="cstm-saved-single-video-gallery-img-over">
                        <img src={VideoPlayer} />
                      </div>
                    </div>

                    <div class="cstm-saved-single-video-title">
                      {singlevideo.title}
                    </div>

                    <div class="cstm-saved-single-video-lbl">
                      <span class="cstm-ssv-title">
                        {singlevideo.categories[0].name}
                      </span>
                      <span class="cstm-ssv-dt"><img src={sepbullet} /></span>
                      <span class="cstm-ssv-title">
                        {singlevideo.viewsCount} Views
                      </span>
                      <span class="cstm-ssv-dt"><img src={sepbullet} /></span>
                      <span class="cstm-ssv-title">
                        {singlevideo.commentsCount} Comments
                      </span>
                    </div>

                    <hr className="cstm-ssv-line" />

                    <div className="cstm-ssv-description">Description</div>

                    <div className="cstm-saved-single-video-desc">
                      {singlevideo.description !== undefined ? (
                        <ShowMoreText
                          lines={3}
                          more="Show more"
                          less="Show less"
                          className="content-css"
                          anchorclassName="my-anchor-css-class"
                          expanded={false}
                          truncatedEndingComponent={"... "}
                        >
                          <div
                            className="cstm-videodes"
                            dangerouslySetInnerHTML={{
                              __html: singlevideo.description,
                            }}
                          ></div>
                        </ShowMoreText>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="cstm-ssvpl">
                      <div className="cstm-ssvpl-btn">
                        <button
                          onClick={() => {
                            navigate(
                              `/videos/${singlevideo._id}`
                            );
                          }}
                          className="cstm-play-video-ssv"
                        >
                          Play video
                        </button>
                      </div>
                      <div className="cstm-ssvpl-dp">
                        <div
                          className="cstm-ssvpl-pv-dtd"
                          onClick={() => {
                            setshowBtn(!showBtn);
                          }}
                        >
                          {" "}
                          <img src={ButtonWhite} />
                        </div>

                        <div
                          className="cstm-dropin cstm-ssvpl-pv-drop "
                          style={{ display: showBtn ? "block" : "none" }}
                        >
                          <ul>
                            <li>
                              <a
                                onClick={(e) => {
                                  setremovesavedsinglemodal(true);
                                }}
                              >
                                <img src={DeleteDrop} />
                                Remove from saved video
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>

            {removesavedsinglemodal && (
              <div className="desk-globle-model-box globle-model-box">
                <div className="desk-globle-model-box-inner cstm-clear-watch-history">
                  <div class="cstm-clear-watch-history-mn">
                    <div class="cstm-clear-watch-history-title">
                      Remove from saved?
                    </div>
                    <div className="cstm-clear-watch-history-content">
                      Do you want to delete this video from your Saved list?
                    </div>
                    <div className="cstm-clear-watch-history-link">
                      <a
                        className="cstm-cwh-cancel-btn"
                        onClick={() => {
                          setremovesavedsinglemodal(false);
                          setshowBtn(false);
                        }}
                      >
                        Cancel
                      </a>
                      <a
                        className="cstm-cwh-clear-btn historyvideo-remove-saved-video-loader"
                        onClick={(e) => removeSingleSavedVideo(e, singlevideo._id)}
                      >
                        {deletesavedSingleloader ? (
                          <div className="historyvideo-loading-image">
                            <img src={loading} />
                          </div>
                        ) : (
                          <></>
                        )}
                        Remove
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          :
          <></>
      }
    </>
  );
};
export default SavedVideos;
