import React from "react";
// Icons
import SmallCrossIcon from "../../../../components/Icons/GeneralIcons/SmallCrossIcon";
// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/CloseAttachment.css";

const CloseAttachment = ({onClick}) => {
  return (
    <button className="crossAttachment-container flex items-center justify-center" onClick={onClick}>
      <SmallCrossIcon iconColor="var(--color-icon-static-white)" />
    </button>
  );
};

export default CloseAttachment;
