import { useNavigate } from "react-router-dom";
import logo from "../../Images/app_logo.png";
import Backarrow from "../../Images/back-arrow.svg";
const LikesListPopup = ({data}) => {
    const history = useNavigate()
    return (
        <>
        <div className="modal-content cstm-topic-model-box">
        <div className="create-ps-title">
          <div className="create-ps-title-title">
            <button className="back-btn-mn" data-dismiss="modal">
              <img src={Backarrow} />
            </button>
            Likes
          </div>
          <div className="create-ps-title-close">
            <button className="close" data-dismiss="modal">
              ✕
            </button>
          </div>
        </div>
        <div className="csstm-add-topic-listing tag-csstm-add-topic-listing">
          <div className="topics-search-ovr tag-search-ovr-main">
            <div className="cstm-tag-friend-list">
              {data.length > 0 ? (
                data.map((user) => {
                    return (
                      <div className="cstm-likes-only">
                          <div className="cstm-likes-only-user"><img src={user.profile_pic && user.profile_pic.length > 0 ? process.env.REACT_APP_AWS_IMG_VID_PATH + user.profile_pic : logo}/></div>
                          <div className="cstm-likes-only-name">{user.name}</div>
                      </div>
                    );
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
        </>
    )
}
export default LikesListPopup;