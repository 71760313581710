import { useEffect, useState } from "react";
import Service from "../../Services/auth.service";
const Setting = ({setcurrentDiv , selectedGid , grpdetail}) => {
    const [Inprocess, setInprocess] = useState(false)
    const [detail,setdetail] = useState({})
    const [showalert,setshowalert] = useState(false)
    const [alertmsg,setalertmsg] = useState("")
    useEffect(()=>{
        setdetail({groupPostedBy: grpdetail.groupPostedBy,maximumGrpMember: grpdetail.maximumGrpMember, createGroupChat: grpdetail.createGroupChat, messageSendBy: grpdetail.messageSendBy, groupType: grpdetail.groupType, groupVisibility: grpdetail.groupVisibility})
    },[grpdetail])

    const handleChange = (e) => {
        setdetail({...detail,[e.target.name]:e.target.value})
    }

    const handleSave = () => {
        var empty = false
        
        Object.entries(detail).forEach(([key, value]) => {
            if(detail[key].length > 0)
                empty = false
            else
                empty = true
        });
        if(!empty)
        {
            setInprocess(true)
            var data = {
                groupid: selectedGid,
                data: detail
            }
            Service.groupsetting(data).then((res)=>{
                if(res.data.status)
                {
                    setInprocess(false)
                    setshowalert(true)
                    setalertmsg(res.data.message)
                    setInterval(() => {
                        setshowalert(false)
                        setalertmsg("")
                    }, 3000);
                    setcurrentDiv("listing")
                }
                else{
                    setInprocess(false)
                    setshowalert(true)
                    setalertmsg(res.data.message)
                    setInterval(() => {
                        setshowalert(false)
                        setalertmsg("")
                    }, 3000);
                }
            })
        }
    }
    return (
        <>
            { showalert && <div className="alert alert-info">{alertmsg}</div>  }
          
            <div className="row ">
                <div className="col-6 form-group">
                <div className="form-group-label">Group Post By</div>
                <div className="form-group-ct">
                    <select onChange={handleChange} value={detail.groupPostedBy} name="groupPostedBy">
                        <option>All</option>
                        <option>Admin only</option>
                    </select>
                </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-6 form-group">
                <div className="form-group-label">Maximum Members</div>
                    <div className="form-group-ct"><input type="number" onChange={handleChange} value={detail.maximumGrpMember} name="maximumGrpMember"/></div>
                </div>
            </div>
            <div className="row ">
                <div className="col-6 form-group">
                    <div className="form-group-label">Create Group Chat</div>
                    <div className="form-group-ct"><select onChange={handleChange} value={detail.createGroupChat} name="createGroupChat">
                        <option>Yes</option>
                        <option>Delete</option>
                        <option>Hide</option>
                    </select>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-6 form-group">
                    <div className="form-group-label">Message Send By</div>
                    <div className="form-group-ct"><select onChange={handleChange} value={detail.messageSendBy} name="messageSendBy">
                        <option>All</option>
                        <option>Admin only</option>
                    </select>
                    </div>
                </div>
            </div>
            <div className="row ">
                <div className="col-6 form-group">
                <div className="form-group-label">Group type</div>
                    <div className="form-group-ct"><select onChange={handleChange} value={detail.groupType} name="groupType">
                        <option>Public</option>
                        <option>Private</option>
                    </select>
                    </div>
                </div>
            </div>

            <div className="row ">
                <div className="col-6 form-group">
                <div className="form-group-label">Group Visibility</div>
                <div className="form-group-ct "> <select onChange={handleChange} value={detail.groupVisibility} name="groupVisibility">
                        <option>Anyone</option>
                        <option>Admin only</option>
                    </select>
                    </div>
                </div>
            </div>
            <div className="gi-btn-save">
             <button type="button" onClick={handleSave} disabled={Inprocess} className="btn">{Inprocess ? "In Process..." : "Save"}</button>
            </div>
            
        </>
    )
}
export default Setting;