import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import authServicePartner from '../../Services/auth.service.partner';
import moment from 'moment';
import imgBigDelete from "../../Images/BackendImages/delete-icon.svg";
import imgdelete from "../../Images/BackendImages/delete.svg";
import editfill from "../../Images/BackendImages/edit.svg";
import Loader from "../../Images/loader.gif";
import { useDispatch } from 'react-redux';
import copyimg from "../../Images/file-copy-line 4.svg";
import ClaimModal from '../Admin/PartnerManagement/PartnerModals/ClaimModal';
import ViewsModal from '../Admin/PartnerManagement/PartnerModals/ViewsModal';
import ReviewModal from '../Admin/PartnerManagement/PartnerModals/ReviewModal';
import hide from "../../Images/Hide.svg";
import show from "../../Images/Show.svg";
import CloneModal from '../Admin/PartnerManagement/PartnerModals/CloneModal';
import UserInfoSideBar from './UserInfoSideBar';
import authService from '../../Services/auth.service';
import ModalPopup from "../ModalPopup/ModalPopup";
import Loader2 from "../../Images/loader2.gif";
const columns = [
    { id: "companyName", label: "Company Name" },
    { id: "isMDSPartner", label: "MDS Partner" },
    { id: "partnerType", label: "Partner Badge" },
    { id: "status", label: "Status" },
    { id: "createdAt", label: "Date" },
    { id: "pageView", label: "Page Views" },
    { id: "claims", label: "Claims" },
    { id: "rating", label: "Rating" },
    { id: "action", label: "Action" },
];

const PartnersList = () => {

    const dispatch = useDispatch()
    let history = useNavigate();
    const [rows, setRows] = useState([])
    const [allPartner, setAllPartner] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [dataLoader, setDataLoader] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [showAlert, setShowalert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [searchText, setSearchText] = useState("");
    const [claimModal, setClaimModal] = useState(false)
    const [claimId, setClaimId] = useState('')
    const [viewModalOpen, setViewModalOpen] = useState(false)
    const [isMenu, setMenu] = useState(false)
    const [ratingModal, setRatingmodal] = useState(false)
    const [isClone, setClone] = useState(false)
    const [cloning, setCloning] = useState(false)
    const [cloneSuccess, setCloneSuccess] = useState(false);
    const [cloneMsg, setCloneMsg] = useState('');
    const [selectedType, setSelectedType] = useState('all')
    const location = useLocation().search
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [listIndex, setListIndex] = useState(-1);
    const [actualsearch, setActualsearch] = useState("");
    const search = new URLSearchParams(location).get("search");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalLoader, setDeleteModalLoader] = useState(false);
    useEffect(() => {
        setSearchText(search !== null ? search : "")
        setDataLoader(true)
        getAllPartnerApi(search !== null ? search : searchText, selectedType)
        getallusersSuggestionListApi()
    }, [])
    useEffect(() => {
        if (suggestionBox && listIndex > -1) {
            var currentList =
                document.getElementById("suggestion-list").children[listIndex]
            // .firstElementChild.firstElementChild;
            if (currentList) {
                var text = currentList.innerText
                setSearchText(text);
            }
        }
    }, [listIndex]);

    //function for get and store specific data in state 
    function partnerCreateData(companyName, isMDSPartner, partnerType, status, createdAt, pageView, claims, rating, action) {
        return { companyName, isMDSPartner, partnerType, status, createdAt, pageView, claims, rating, action }
    }

    //get all partner api call
    async function getAllPartnerApi(searchText, selectedType, col, sort) {
        let query = ""
        query += `?search=${searchText}`
        query += selectedType !== undefined && selectedType !== null && selectedType !== '' ? `&mdstype=${selectedType}` : ''
        query += col !== undefined && col !== null && col !== "" ? `&sortField=${col}` : ""
        query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
        try {
            const getPartnerRes = await authServicePartner.getPartnerList(query)
            if (getPartnerRes.status) {
                var arr = getPartnerRes.data.data
                if (getPartnerRes.data.data.length > 0) {
                    var newArr = []
                    for (var i = 0; i < arr.length; i++) {
                        newArr[i] = partnerCreateData(
                            arr[i].companyName !== undefined && arr[i].companyName !== null && arr[i].companyName !== "" ? arr[i].companyName : "-",
                            arr[i].isMDSPartner !== undefined && arr[i].isMDSPartner !== null && arr[i].isMDSPartner !== "" ? arr[i].isMDSPartner : "-",
                            arr[i].partnerType !== undefined && arr[i].partnerType !== null && arr[i].partnerType !== "" && arr[i].partnerType.name !== "nobadge" ? arr[i].partnerType : "-",
                            arr[i].status !== undefined && arr[i].status !== null && arr[i].status !== "" ? arr[i].status : "-",
                            moment(arr[i].createdAt).format('MMM DD, YYYY'),
                            arr[i].pageView !== undefined && arr[i].pageView !== null && arr[i].pageView !== "" ? arr[i].pageView : 0,
                            arr[i].claims !== undefined && arr[i].claims !== null && arr[i].claims !== "" ? arr[i].claims : 0,
                            arr[i].rating !== undefined && arr[i].rating !== null && arr[i].rating !== "" ? arr[i].rating : 0,
                            arr[i]._id
                        )
                    }
                    setRows(newArr)
                    setAllPartner(newArr)
                } else {
                    setRows([])
                    setAllPartner([])
                }
                setDataLoader(false)
            }
        } catch (error) {
            setDataLoader(false)
            return false
        }
    }
    async function getallusersSuggestionListApi() {
        try {
            const getSuggestionRes = await authService.getAllPartnerSuggestionList()
            let data = getSuggestionRes.data.data
            var arr = []
            if (data && data.length !== undefined && data.length !== null && data.length > 0) {
                data.map((item) => arr.push({ name: item.companyName }))
                setSuggestionList(arr);
            }
        } catch (err) {

        }
    }

    //partner delete function and api call
    async function partnerDeleteApi() {
        setDeleteModalLoader(true)
        try {
            const partnerDeleteRes = await authServicePartner.deletePartner(deleteId)
            if (partnerDeleteRes.status) {
                setDeleteModalLoader(false)
                getAllPartnerApi(searchText, selectedType)
                setShowalert(true)
                setShowDeleteModal(false)
                setAlertMsg(partnerDeleteRes.data.message)
                setTimeout(() => {
                    setShowalert(false)
                    setAlertMsg('')
                }, 3000)
            }
            setDeleteModalLoader(false)
        } catch (error) {
            setDeleteModalLoader(false)
            return false
        }
    }

    //for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //for pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //search using text
    const handleSearch = (text) => {
        setSearchText(text);
        setDataLoader(true)
        setSuggestionBox(false);
        getAllPartnerApi(searchText, selectedType)
        history(`/user/partnerlist?search=${text}`)
    };

    //sorting function
    const handleSort = (col, direc) => {
        setDataLoader(true)
        getAllPartnerApi(searchText, selectedType, col, direc)
    };

    //handle status update api function
    async function updatePartnerStatusApi(id, status) {
        setRows((prevData) =>
            prevData.map((item) => {
                if (item.action === id) {
                    return { ...item, status: status };
                }
                return item;
            })
        );
        let query = `${id}?status=${status}`
        try {
            const updateRes = await authServicePartner.updatePartnerStatus(query)
            if (updateRes.data.status) {
                // getAllPartnerApi(page + 1, rowsPerPage)
            }
        } catch (error) {
            return false
        }
    }


    // function for partner clone api 
    const handleClonePartner = async () => {
        setClone(false)
        setCloning(true)
        authServicePartner
            .clonePartner({ partnerId: claimId })
            .then((resPartner) => {
                if (resPartner.status) {
                    setCloneMsg(resPartner.data.message);
                    setCloning(false)
                    setCloneSuccess(true)
                    getAllPartnerApi(searchText, selectedType)
                    setTimeout(() => {
                        setCloneSuccess(false)
                        setCloneMsg("");
                    }, 3000);

                }
            })
    }
    // handle partner type filter change
    const handleOnChangePartnerType = (e) => {
        setSelectedType(e.target.value)
        setDataLoader(true)
        getAllPartnerApi(searchText, e.target.value)
        setPage(0)
    }

    const deletePartnerName = (deleteId) => {
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].action === deleteId) {
                return rows[i].companyName;
            }
        }
    };
    return (
        <>
            {/* <div className="cstm-all-admin">
                <AdminSidebar />
                <div className="content-main"> */}
            <div className="users-info">
                <UserInfoSideBar />
                <div className="users-info-content">
                    <div className="users-info-content-main">
                        <div className="uic-title">
                            <h1>Partners</h1>
                            <div className="uic-fl-btn">
                                <button
                                    className="btn"
                                    onClick={() => {
                                        history("/partner/createpartner");
                                        dispatch({ type: "NAVIGATEPAGE", payload: '/user/partnerlist?search=' })
                                    }}
                                >
                                    Create Partner
                                </button>
                            </div>
                        </div>
                        <div className="uic-fl">
                            <div className="uic-fl-search">
                                <input
                                    type="text"
                                    placeholder="Search partners"
                                    className="search-user-input"
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                        var currentValue;
                                        if (e.target.value) {
                                            currentValue = e.target.value;
                                        } else {
                                            currentValue = e.target.value;
                                            setSearchText(currentValue);
                                        }
                                        setActualsearch(currentValue);
                                        if (suggestionList !== undefined && suggestionList.length > 0) {
                                            setListIndex(-1);
                                            setSuggestionBox(true)
                                            var searchResult = suggestionList.filter((user) => {
                                                return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                                            });
                                            setSuggestions(searchResult);
                                        }
                                    }}
                                    onKeyUp={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearch(e.target.value)
                                        } else if (e.key === "ArrowDown") {
                                            const listLength =
                                                suggestions.length > 15
                                                    ? 15
                                                    : suggestions.length;
                                            if (listIndex < listLength - 1) {
                                                setListIndex(listIndex + 1);
                                            } else if (listIndex === listLength - 1) {
                                                setListIndex(-1);
                                                setSearchText(actualsearch);
                                            }
                                        } else if (e.key === "ArrowUp") {
                                            e.preventDefault();
                                            const listLength =
                                                suggestions.length > 15
                                                    ? 15
                                                    : suggestions.length;
                                            if (listIndex === 0) {
                                                setListIndex(-1);
                                                setSearchText(actualsearch);
                                            } else if (listIndex === -1) {
                                                setListIndex(listLength - 1);
                                            } else {
                                                setListIndex(listIndex - 1);
                                            }
                                        }
                                    }}
                                    onBlur={() => setSuggestionBox(false)}
                                />

                                {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                                    <div className="suggestion-box">
                                        <ul id="suggestion-list">
                                            {suggestions.map((suggestion, index) => (
                                                <li
                                                    key={index}
                                                    onMouseDown={() => handleSearch(suggestion.name)}
                                                    className={index === listIndex ? 'selected-active' : ''}
                                                >
                                                    {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length)} </> : suggestion.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

                                </i>
                            </div>
                            <div className="uic-fl-btn sort-by-mn">
                                <div className="sort-by-mn-title">Sort by : </div>
                                <div className="sort-by-mn-select">
                                    <select value={selectedType} onChange={handleOnChangePartnerType} >
                                        <option value='all'>Select Type</option>
                                        <option value='offer'>Offer</option>
                                        <option value='perk'>Perk</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        {showAlert && <div className="alert alert-info">{alertMsg}</div>}
                        <Paper className="uic-tb-mn">
                            <TableContainer className="uic-table-main">
                                <Table className="uic-table">
                                    <TableHead className="uic-table-th">
                                        <TableRow>
                                            {columns.map((col) => (
                                                <TableCell
                                                    key={col.id}
                                                    align={col.align}
                                                    className="uic-th-td"
                                                >
                                                    {col.id !== "action" ? (
                                                        <>
                                                            <span className="custom-sorting">
                                                                <i
                                                                    className="fa fa-angle-down"
                                                                    onClick={(e) => {
                                                                        handleSort(col.id, "Dec");
                                                                    }}
                                                                ></i>
                                                                <i
                                                                    className="fa fa-angle-up"
                                                                    onClick={(e) => {
                                                                        handleSort(col.id, "Asc");
                                                                    }}
                                                                ></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                    {col.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className="uic-table-td">
                                        {dataLoader ?
                                            <div className="cstm-data-load"><img src={Loader} /> </div>
                                            :
                                            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length > 0 ?
                                                rows
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                className="uic-th-td "
                                                            >
                                                                {columns.map((column) => {
                                                                    const value = row[column.id]
                                                                    return column.id === "companyName" ? <TableCell className="uic-th-td w-14"><Link to={`/partner/detail?id=${row.action}`} onClick={() => {
                                                                        dispatch({ type: "PAGECHANGE", payload: false })
                                                                        // dispatch({ type: "NAVIGATEPAGE", payload: `/user/list?search=${searchText}` })
                                                                        dispatch({ type: "isMDSPartner", payload: row.isMDSPartner })
                                                                        dispatch({ type: "NAVIGATEPAGE", payload: `/user/partnerlist?search=${searchText}` })
                                                                    }} className="cstm-view-users-count">{value}</Link></TableCell> :
                                                                        column.id === "isMDSPartner" ? <TableCell className="uic-th-td w-10">{value === true ? "Yes" : "No"}</TableCell> :
                                                                            column.id === 'partnerType' ? <TableCell className="uic-th-td w-11">{value === 'nobadge' ? "-" : value.charAt(0).toUpperCase() + value.slice(1)}</TableCell> :
                                                                                // column.id === 'status' ? <TableCell className="uic-th-td w-8">{value.charAt(0).toUpperCase() + value.slice(1)}</TableCell> :
                                                                                column.id === 'status' ? <TableCell className="uic-th-td w-9">{value === "published" ?
                                                                                    <>
                                                                                        <a><img src={show} /></a>
                                                                                        <select
                                                                                            className="status-select-only"
                                                                                            disabled={row.status === 'draft'}
                                                                                            value={row.status}
                                                                                            onChange={() => updatePartnerStatusApi(row.action, row.status === 'published' ? 'paused' : 'published')}
                                                                                        >
                                                                                            <option value="published">Live</option>
                                                                                            <option value="paused">Pause</option>
                                                                                        </select>
                                                                                    </>
                                                                                    : value === "paused" ? <>
                                                                                        <a><img src={hide} /></a>
                                                                                        <select className="status-select-only"
                                                                                            disabled={row.status === 'draft'}
                                                                                            value={row.status}
                                                                                            onChange={() => updatePartnerStatusApi(row.action, row.status === 'published' ? 'paused' : 'published')}
                                                                                        >
                                                                                            <option value="published">Live</option>
                                                                                            <option value="paused">Pause</option>
                                                                                        </select>
                                                                                    </> : <a className='cstm-view-users-count' data-toggle="modal"
                                                                                        data-target="#editEvent"
                                                                                        onClick={() => {
                                                                                            history(`/partner/detail?id=${row['action']}`)
                                                                                            dispatch({ type: "isMDSPartner", payload: row.isMDSPartner })
                                                                                            dispatch({ type: "NAVIGATEPAGE", payload: `/user/partnerlist?search=${searchText}` })
                                                                                        }
                                                                                        }>Edit Draft</a>}
                                                                                </TableCell> :
                                                                                    column.id === 'createdAt' ? <TableCell className="uic-th-td w-8">{value}</TableCell> :
                                                                                        column.id === 'pageView' ? <TableCell className="uic-th-td w-10"><a className="cstm-view-users-count" onClick={(e) => {
                                                                                            setViewModalOpen(true)
                                                                                            document.body.classList.add("modal-open");
                                                                                            setClaimId(row.action)
                                                                                        }}>{value}</a></TableCell> :
                                                                                            column.id === 'claims' ? <TableCell className="uic-th-td w-7"><a className="cstm-view-users-count" onClick={() => {
                                                                                                setClaimModal(true)
                                                                                                document.body.classList.add("modal-open");
                                                                                                setClaimId(row.action)
                                                                                            }}>{value}</a></TableCell> :
                                                                                                column.id === 'rating' ? <TableCell className="uic-th-td w-7"><a className="cstm-view-users-count" onClick={() => {
                                                                                                    setRatingmodal(true)
                                                                                                    document.body.classList.add("modal-open");
                                                                                                    setClaimId(row.action)
                                                                                                }}>{value}</a></TableCell> :
                                                                                                    <TableCell className="uic-th-td w-14 uic-table-icon ">
                                                                                                        <Tooltip title="Clone" arrow><a data-toggle="modal"
                                                                                                            data-target="#clonePartner" onClick={() => {
                                                                                                                setClone(true)
                                                                                                                setClaimId(value)
                                                                                                            }} >
                                                                                                            <img src={copyimg} />
                                                                                                        </a>
                                                                                                        </Tooltip>
                                                                                                        <Link to={`/partner/detail?id=${value}`}
                                                                                                            onClick={() => {
                                                                                                                dispatch({ type: "isMDSPartner", payload: row.isMDSPartner })
                                                                                                                dispatch({ type: "NAVIGATEPAGE", payload: `/user/partnerlist?search=${searchText}` })
                                                                                                            }
                                                                                                            }

                                                                                                        >
                                                                                                            <Tooltip title='Edit' arrow><img src={editfill} /></Tooltip>
                                                                                                        </Link>
                                                                                                        <a
                                                                                                            // data-toggle="modal"
                                                                                                            // data-target="#DeleteFromPartner"
                                                                                                            onClick={() => {
                                                                                                                setDeleteId(value);
                                                                                                                setShowDeleteModal(true)
                                                                                                            }}
                                                                                                        >
                                                                                                            <Tooltip title='Delete' arrow><img src={imgdelete} /></Tooltip>
                                                                                                        </a></TableCell>
                                                                })}
                                                            </TableRow>
                                                        )
                                                    }) : <span className="no-data-found">No data found</span>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className="uic-pagination"
                                rowsPerPageOptions={[20, 50, 100, 200]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </div>
            </div>
            {/* </div> */}
            <ModalPopup
                onClose={() => setShowDeleteModal(false)}
                isFooter={true}
                isHeader={true}
                modelSize="480"
                buttonText="Cancel"
                btn2variant={!deleteModalLoader ? "danger" : 'secondary'}
                buttonText1={!deleteModalLoader ? "Delete" : <img src={Loader2} width={50} height={15} className="btn-loader-img-cover" />}
                isOpen={showDeleteModal}
                className=""
                handleClick2ndButton={partnerDeleteApi}
                headingLable={"Delete partner"}
            >
                <div className="ctsm-modal-body">
                    <p>Are you sure you want to delete this "{deletePartnerName(
                        deleteId
                    )}"? This action cannot be undone.</p>
                </div>
            </ModalPopup>

            <CloneModal id='clonePartner' setClone={setClone} handleClonePartner={handleClonePartner} setCloning={setCloning} />
            {cloning && (
                <div className="cstm-clone-msg-container">
                    <h3>Cloning Record...</h3>
                    <p>Please wait while the record is being cloned.</p>
                </div>
            )}
            {cloneSuccess && (
                <div className="cstm-success-msg-container">
                    <div className="cstm-success-msg-container-title">
                        {cloneMsg}
                    </div>
                </div>
            )}
            {viewModalOpen && (<ViewsModal setViewModalOpen={setViewModalOpen} claimId={claimId} />)}
            {claimModal && <ClaimModal claimModal={claimModal} claimId={claimId} setClaimModal={setClaimModal} />}
            {ratingModal && <ReviewModal setRatingmodal={setRatingmodal} claimId={claimId} />}

            {/* </div> */}
        </>
    )
}

export default PartnersList