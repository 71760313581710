import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { ICalCalendar } from 'ical-generator';
import { jwtDecode } from 'jwt-decode';

export default function EventCalendarReminder() {
    const location = useLocation().search
    const token = new URLSearchParams(location).get("token");
    const [err, setErr] = useState("")
    const navigate = useNavigate();
    useEffect(() => {
        try {
            async function fetchEventDetails() {
                const eventDetails = jwtDecode(token);
                if (typeof eventDetails === "object") {
                    // Create a new calendar
                    const cal = new ICalCalendar({ domain: 'example.com', name: 'My Calendar' });

                    // Add an event to the calendar
                    cal.createEvent({
                        start: eventDetails.start,
                        end: eventDetails.end,
                        summary: eventDetails.summary,
                        description: eventDetails.description,
                        location: eventDetails.location,
                        timezone: eventDetails.timezone
                    });

                    // Generate the .ics file content
                    const calendarContent = cal.toString();
                    const blob = new Blob([calendarContent], { type: 'text/calendar' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'calendar.ics'; // Replace 'filename.extension' with your desired filename and extension
                    a.click();
                    URL.revokeObjectURL(url);

                }

            }
            fetchEventDetails()

        }
        catch (error) {
            setErr(error)
        }

    }, [token])

    useEffect(() => {
        if (err.length > 0) {
            navigate("/")
        }
    }, [err])

    return (
        <div>
            {
                //err
            }
        </div>
    );
}
