import moment from "moment";
import React, { useState } from "react";
import TableCell from "../../../components/Table/TableCell";
import ToolTip from "../../../components/ToolTip/ToolTip";

let hoverTimeout;

const SavedDocumentTableList = ({
  setRestricted,
  handleFilterByOwner,
  setFileName,
  handlePreview,
  setFireUrl,
  handleSelectCategory,
  columns,
  docs,
  actionOptions,
  handleSaveDocApi,
  setDocId,
}) => {
  const [hoveredDocId, setHoveredDocId] = useState(null);
  const [hoveredOwnerID, setHoveredOwnerID] = useState(null);

  const handleMouseEnterFile = (docId) => {
    hoverTimeout = setTimeout(() => {
      setHoveredDocId(docId);
    }, 500);
  };

  const handleMouseLeaveFile = () => {
    clearTimeout(hoverTimeout);
    setHoveredDocId(null);
  };

  const handleMouseEnterPeople = (docId) => {
    hoverTimeout = setTimeout(() => {
      setHoveredOwnerID(docId);
    }, 500);
  };

  const handleMouseLeavePeople = () => {
    clearTimeout(hoverTimeout);
    setHoveredOwnerID(null);
  };
  const extractFilenameFromUrl = (url) => {
    const urlObject = new URL(url);
    var str = urlObject.pathname.split("/")[3];
    str = str
      .substring(str.lastIndexOf("-") + 1, str.length)
      .split(".")
      .pop();
    return str;
  };
  return (
    <>
      <tr>
        {columns.map((col) => {
          const ownerName = docs.owner === "admin" ? "MDS" : docs.owner;
          return col.id === "name" ? (
            <div>
              <TableCell
                onClick={() => handlePreview(docs)}
                width={col.width}
                variant="file"
                fileType={docs.documentType}
                hasText
                title={docs.fileName}
                description={docs.shortDescription}
                onMouseEnter={() => handleMouseEnterFile(docs._id)}
                onMouseLeave={handleMouseLeaveFile}
              />
              {hoveredDocId === docs._id && (
                <ToolTip title={docs.fileName} id={`tooltip_${docs._id}`} />
              )}
            </div>
          ) : col.id === "people" ? (
            <>
              <TableCell
                onClick={() => handleFilterByOwner(docs.ownerId)}
                width={col.width}
                variant="avatar"
                src={docs.ownerProfileImg}
                hasText
                title={docs.display_name}
                onMouseEnter={() => handleMouseEnterPeople(docs.ownerId)}
                onMouseLeave={handleMouseLeavePeople}
              />
              {hoveredOwnerID === docs.ownerId && (
                <ToolTip title={docs.owner} id={`tooltip_${docs.ownerId}`} />
              )}
            </>
          ) : col.id === "dateUploaded" ? (
            <TableCell width={col.width}>
              <div className="last-modified-text">
                {docs.dateUploaded
                  ? moment(docs.dateUploaded).local().format("MMM DD, YYYY")
                  : "-"}
              </div>
            </TableCell>
          ) : col.id === "category" ? (
            <TableCell
              handleSelectCategory={handleSelectCategory}
              width={col.width}
              variant="categories"
              category={docs.doccategoriesData}
              hasText={false}
            />
          ) : (
            <TableCell
              onClick={() => {
                setDocId(docs._id);
                setFireUrl(docs.fileUrl);
                setFileName(docs.fileName);
                setRestricted(docs?.userDownloadBlockStatus);
              }}
              saved
              width={col.width}
              actionOptions={actionOptions}
              handleSave={() => handleSaveDocApi(docs._id)}
              variant="action"
            />
          );
        })}
      </tr>
      <td colspan="100%">
        <hr />
      </td>
    </>
  );
};

export default SavedDocumentTableList;
