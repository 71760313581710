import React from "react";
import PropTypes from "prop-types";

const MdsLogoSmall = ({ height = 60, width = 60 }) => {
  return (
    <>
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 48 48"
      >
        <g clipPath="url(#a)">
          <path
            fill="var(--color-bg-neutral-strong)"
            d="M24 0C10.747 0 0 10.747 0 24s10.747 24 24 24 24-10.747 24-24S37.256 0 24 0Z"
          />
          <path
            fill="var(--color-icon-inverse-screen)"
            d="M22.997 29.484c0 .167-.18.262-.31.167l-5.425-3.194c.09 1.43.537 2.826 1.337 4.182.282.477.647.99 1.096 1.542.036.043.055.1.055.158V39c-3.09-2.795-5.298-5.429-6.627-7.902C11.71 28.462 11 25.377 11 21.85v-7.787c0-.81.836-1.321 1.52-.928l10.145 5.825a.676.676 0 0 1 .335.592v9.938l-.003-.006ZM37 21.787c0 3.543-.709 6.636-2.124 9.283-1.329 2.482-3.538 5.125-6.628 7.93v-6.685a.25.25 0 0 1 .055-.159c.449-.55.814-1.066 1.097-1.548.8-1.363 1.246-2.761 1.337-4.197l-5.427 3.206c-.13.098-.31 0-.31-.168v-9.974c0-.248.127-.473.335-.594l9.94-5.728c.775-.447 1.725.138 1.725 1.058V21.787Z"
          />
        </g>
        <defs>
          <clipPath id="a">
            <rect
              width={48}
              height={48}
              fill="var(--color-icon-inverse-screen)"
              rx={8}
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

MdsLogoSmall.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default MdsLogoSmall;
