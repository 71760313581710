import { useEffect, useState } from "react";
//import api file
import Services from "../../../Services/auth.service";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import components from mui/material
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
//import images
import Loader2 from "../../../Images/loader2.gif";
import edit from "../../../Images/BackendImages/edit.svg";
import imgdelete from "../../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../../Images/BackendImages/delete-icon.svg";
import infopic from "../../../Images/BackendImages/errorwarningfill.svg";
import Loader from "../../../Images/loader.gif";
import authServiceCollaborator from "../../../Services/auth.service.collaborator";
import authService from "../../../Services/auth.service";
const columns = [
    { id: "plan_name", label: "Name" },
    { id: "plan_price", label: "Price" },
    { id: "automatic_renewal", label: "Automatic renewal" },
    { id: "plan_status", label: "Status" },
    { id: "recurring_timeframe", label: "Recurring timeframe" },
    { id: "total_member_who_purchased_plan", label: "Member count" },
    { id: "action", label: "Actions" },
];

function createData(
    plan_name,
    plan_price,
    automatic_renewal,
    plan_status,
    recurring_timeframe,
    total_member_who_purchased_plan,
    action
) {
    return {
        plan_name,
        plan_price,
        automatic_renewal,
        plan_status,
        recurring_timeframe,
        total_member_who_purchased_plan,
        action,
    };
}


const userColumns = [
    { id: "srno", label: "Sr no" },
    { id: "name", label: "User Name" },
    { id: "email", label: "Email" }
];

const PlanListing = () => {

    const [inProcess, setInProcess] = useState(false)
    const [allPlan, setAllPlan] = useState([]);
    const [rows, setrow] = useState([]);
    const [page, setPage] = useState(0);
    const [totalData, setTotalData] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [deleteId, setdeleteId] = useState("");
    const [showalert, setshowalert] = useState(false);
    const [alertmsg, setalertmsg] = useState("");
    const [searchText, setSearchText] = useState("");
    const [editdetail, seteditdetail] = useState({});
    const [selectedid, setSelectedId] = useState("");
    const [isMemberList, setMemberList] = useState(false)
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const dispatch = useDispatch()
    const [userRows, setUserRows] = useState([])
    const [loader, setLoader] = useState(false)
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [listIndex, setListIndex] = useState(-1);
    const [userViewLoader, setUserViewLoader] = useState(false);
    const [userRowsPerPage, setUserRowsPerPage] = useState(20);
    const [userPage, setUserPage] = useState(0);
    const navigate = useNavigate()
    const [actualsearch, setActualsearch] = useState("");

    useEffect(() => {
        setLoader(true)
        getAllPlanApi(page + 1, rowsPerPage, searchText)
        getallusersSuggestionListApi()
    }, [page, rowsPerPage]);
    useEffect(() => {
        if (suggestionBox && listIndex > -1) {
            var currentList =
                document.getElementById("suggestion-list").children[listIndex]
            // .firstElementChild.firstElementChild;
            if (currentList) {
                var text = currentList.innerText
                setSearchText(text);
            }
        }
    }, [listIndex]);
    async function getallusersSuggestionListApi() {
        try {
            const getSuggestionRes = await authService.getAllMembershipPlanSuggestionList()

            console.log(getSuggestionRes, "getSuggestionRes");
            let data = getSuggestionRes.data.data
            var arr = []
            if (data && data.length !== undefined && data.length !== null && data.length > 0) {
                data.map((item) => arr.push({ name: item.plan_name }))
                console.log(arr);
                setSuggestionList(arr);
            }
        } catch (err) {

        }
    }

    // getall plan list api function
    async function getAllPlanApi(page, rowsPerPage, searchText, col, sort) {
        let query = `?page=${page}&limit=${rowsPerPage}&search=${searchText}`
        query += col !== undefined && col !== null && col !== "" ? `&sortField=${col}` : ""
        query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
        try {
            const getPlanRes = await Services.getAllPlan(query)
            if (getPlanRes.data.status) {
                setTotalData(getPlanRes.data.data.totalPartners)
                const data = getPlanRes.data.data.plans;
                var arr = [];
                for (var i = 0; i < data.length; i++) {
                    arr[i] = createData(
                        data[i].plan_name,
                        "$ " + data[i].plan_price.toLocaleString(),
                        data[i].automatic_renewal,
                        data[i].plan_status,
                        data[i].recurring_timeframe,
                        data[i].total_member_who_purchased_plan.length,
                        data[i]._id
                    );
                }
                setrow(arr);
                setAllPlan(arr);
                setLoader(false)
            }
        } catch (error) {

        }
    }

    // handle row per page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);

    };

    // handle page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // handle search
    const handleSearch = () => {
        setSuggestionBox(false)
        setLoader(true)
        getAllPlanApi(1, 20, searchText)
    };

    // handle delete plan
    const handleDelete = () => {
        setInProcess(true)
        Services.deleteplan(deleteId)
            .then((res) => {
                setInProcess(false)
                document.querySelector('#DeleteFromList .close').click()
                getAllPlanApi(1, 20, searchText)
                setshowalert(true);
                setalertmsg(res.data.message);
                setTimeout(() => {
                    setshowalert(false);
                    setalertmsg("");
                }, 3000);

            })
            .catch((e) => {
                console.log(e);
            });
    };

    // handle sort for column
    const handleSort = (col, direc) => {
        setLoader(true)
        getAllPlanApi(1, 20, searchText, col, direc)
    };

    // handle partner info
    const handleInfo = (value) => {
        setSelectedId(value);
        Services.getplanbyid(value)
            .then((res) => {
                if (res.data.status) {
                    var obj = {
                        plan_name: res.data.data.plan_name,
                        plan_price: res.data.data.plan_price,
                        plan_description: res.data.data.plan_description,
                        recurring_timeframe: res.data.data.recurring_timeframe,
                        plan_status: res.data.data.plan_status,
                        auth0_plan_id: res.data.data.auth0_plan_id || '',
                        automatic_renewal: res.data.data.automatic_renewal,
                        event_on_offline: res.data.data.plan_resource.event_offline
                            ? "event_offline"
                            : res.data.data.plan_resource.event_online
                                ? "event_online"
                                : "",
                        notification:
                            res.data.data.plan_resource.notification !== undefined
                                ? res.data.data.plan_resource.notification
                                : "",
                        forMigratedUser: res.data.data.forMigratedUser,
                        // plan_id_by_admin: res.data.data.plan_id_by_admin,
                    };
                    seteditdetail({ ...obj });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    // handle member detail list
    const handleMemberList = async (id) => {
        setMemberList(true)
        setUserViewLoader(true)
        const memberListRes = await authServiceCollaborator.getPlanWiseMemberDetail(id)
        console.log(memberListRes.data.data[0].MemberData, "memberListRes");
        if (memberListRes.data.status) {
            setUserRows(memberListRes.data.data[0].MemberData)
            setUserViewLoader(false)
        }
    }

    // Pagination onChange function
    const handleChangeUserPage = (event, newPage) => {
        setUserPage(newPage);
    };
    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title">
                        <h1>Membership</h1>
                        <div className="uic-fl-btn">
                            <button
                                className="btn"
                                onClick={() => {
                                    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
                                    dispatch({ type: "REMOVEMODALINPROCESS" })
                                    dispatch({ type: "SETSTAYONPAGE", payload: null })
                                    navigate('/membership/create')
                                }}
                            >
                                Add plan
                            </button>
                        </div>
                    </div>

                    <div className="uic-fl">
                        <div className="uic-fl-search">
                            <input
                                type="text"
                                placeholder="Search"
                                className="search-user-input"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    var currentValue;
                                    if (e.target.value) {
                                        currentValue = e.target.value;
                                    } else {
                                        currentValue = e.target.value;
                                        setSearchText(currentValue);
                                    }
                                    setActualsearch(currentValue);
                                    if (suggestionList !== undefined && suggestionList.length > 0) {
                                        setListIndex(-1);
                                        setSuggestionBox(true)
                                        var searchResult = suggestionList.filter((user) => {
                                            return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                                        });
                                        setSuggestions(searchResult);
                                    }
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch(e.target.value)
                                    } else if (e.key === "ArrowDown") {
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex < listLength - 1) {
                                            setListIndex(listIndex + 1);
                                        } else if (listIndex === listLength - 1) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        }
                                    } else if (e.key === "ArrowUp") {
                                        e.preventDefault();
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex === 0) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        } else if (listIndex === -1) {
                                            setListIndex(listLength - 1);
                                        } else {
                                            setListIndex(listIndex - 1);
                                        }
                                    }
                                }}
                                onBlur={() => setSuggestionBox(false)}
                            />

                            {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                                <div className="suggestion-box">
                                    <ul id="suggestion-list">
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onMouseDown={() => handleSearch(suggestion.name)}
                                                className={index === listIndex ? 'selected-active' : ''}
                                            >
                                                {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length - 1)} </> : suggestion.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

                            </i>

                        </div>
                    </div>

                    {showalert && <div className="alert alert-info">{alertmsg}</div>}

                    <Paper className="uic-tb-mn">
                        <TableContainer className="uic-table-main">
                            <Table className="uic-table">
                                <TableHead className="uic-table-th">
                                    <TableRow>
                                        {columns.map((column, i) => (
                                            <TableCell
                                                key={i}
                                                align={column.align}
                                                className={
                                                    column.id === "action"
                                                        ? "uic-th-td w-20"
                                                        : "uic-th-td w-15"
                                                }
                                            >
                                                {column.id !== "action" ? (
                                                    <>
                                                        <span className="custom-sorting">
                                                            <i
                                                                className="fa fa-angle-down"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "Dec");
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="fa fa-angle-up"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "Asc");
                                                                }}
                                                            ></i>
                                                        </span>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="uic-table-td">
                                    {loader ? <div className="cstm-data-load"><img src={Loader} /> </div> : rows.length > 0 ? rows
                                        .map((row, j) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={j}
                                                    className="uic-th-td"
                                                >
                                                    {columns.map((column, k) => {
                                                        const value = row[column.id];
                                                        return column.id !== "action" && column.id !== "total_member_who_purchased_plan" ? (
                                                            <TableCell
                                                                key={k}
                                                                className="uic-th-td w-15"
                                                            >
                                                                {column.id === "automatic_renewal"
                                                                    ? value
                                                                        ? "Yes"
                                                                        : "No"
                                                                    : column.id === "plan_name" ? <Link className="cstm-view-users-count" to={`/membership/detail?id=${row.action}`}>{value}</Link> : value}
                                                            </TableCell>
                                                        ) : column.id === "total_member_who_purchased_plan" ? (
                                                            <TableCell
                                                                key={k}
                                                                className="uic-th-td w-15"
                                                            >
                                                                <a className='cstm-view-users-count' onClick={() => {
                                                                    row.total_member_who_purchased_plan > 0 && handleMemberList(row.action)
                                                                    row.total_member_who_purchased_plan > 0 && document.body.classList.add("modal-open");
                                                                }} >{value}</a>
                                                            </TableCell>
                                                        ) :
                                                            (
                                                                <TableCell
                                                                    key={column.id}
                                                                    className="uic-th-td uic-table-icon w-20"
                                                                >
                                                                    <a
                                                                        onClick={() => {
                                                                            handleInfo(value);
                                                                        }}
                                                                    >
                                                                        <img src={infopic} />
                                                                    </a>
                                                                    <Link to={`/membership/detail?id=${value}`}
                                                                        onClick={() => {
                                                                            dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
                                                                            dispatch({ type: "REMOVEMODALINPROCESS" })
                                                                            dispatch({ type: "SETSTAYONPAGE", payload: null })
                                                                        }}
                                                                    >
                                                                        <img src={edit} />
                                                                    </Link>
                                                                    <a
                                                                        data-toggle="modal"
                                                                        data-target="#DeleteFromList"
                                                                        onClick={() => {
                                                                            setdeleteId(value);
                                                                        }}
                                                                    >
                                                                        <img src={imgdelete} />
                                                                    </a>
                                                                </TableCell>
                                                            );
                                                    })}
                                                </TableRow>
                                            );
                                        }) : <div className="admin-no-found">
                                        <p>No Data Found </p>
                                    </div>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="uic-pagination"
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={totalData}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                    {/* Modal Delete */}
                    <div
                        className="modal fade msg-popup"
                        id="DeleteFromList"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                        >
                            <div className="modal-content">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    &times;
                                </button>
                                <div className="msg-icon">
                                    <img src={imgbigdelete} />
                                </div>
                                <h1>Are you sure you want to delete this plan?</h1>
                                <div className="row">
                                    <div className="col-6">
                                        {inProcess ? (
                                            <div className="loader-button1">
                                                <button>In Process...</button>
                                                <span className="loader-btn">
                                                    <img src={Loader2} />
                                                </span>
                                            </div>
                                        ) :
                                            (<span className="msg-btn">
                                                <button
                                                    type="button"
                                                    className="btn btn-red"
                                                    onClick={handleDelete}
                                                >
                                                    Delete
                                                </button>
                                            </span>)}
                                    </div>
                                    <div className="col-6">
                                        <span className="msg-btn">
                                            <button
                                                type="button"
                                                className="btn"
                                                data-dismiss="modal"
                                            >
                                                Cancel
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isMemberList && <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
                <div className="desk-globle-model-box-inner">
                    <div className="modal-content">
                        <div className="cstm-replace-pp cstm-plan-member-main">
                            <span className="cstm-rpp-close-btn" onClick={() => { setMemberList(false); document.body.classList.remove("modal-open"); }}>×</span>
                            <div className="cstm-rpp-title">Total number of Member Views based on Users</div>
                            <div className="cstm-cstm-viewlist">
                                <Paper className="uic-tb-mn ">
                                    <TableContainer className=" uic-table-main ">
                                        <Table className="" >
                                            <TableHead className="uic-table-th">
                                                <TableRow>
                                                    {userColumns.map((column) => (
                                                        <TableCell
                                                            className="uic-th-td"
                                                            key={column.id}
                                                        >
                                                            <span className="custom-sorting">
                                                                <i
                                                                    className="fa fa-angle-down"
                                                                    onClick={(e) => {
                                                                        handleSort(column.id, "asc");
                                                                    }}
                                                                ></i>
                                                                <i
                                                                    className="fa fa-angle-up"
                                                                    onClick={(e) => {
                                                                        handleSort(column.id, "desc");
                                                                    }}
                                                                ></i>
                                                            </span>
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="uic-table-td">
                                                {userViewLoader ? (
                                                    <div className="cstm-data-load">
                                                        <img src={Loader} />
                                                    </div>
                                                ) : userRows.length > 0 ? (
                                                    userRows.slice(userPage * userRowsPerPage, userPage * userRowsPerPage + userRowsPerPage)
                                                        .map((row, index) => {
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={row.id}
                                                                >
                                                                    <TableCell className='uic-th-td w-7'>{index + 1}</TableCell>
                                                                    <TableCell className='uic-th-td w-15'>  <Link to={`/user/edituser?id=${row._id}`} className='cstm-view-users-count'>{row.name}</Link></TableCell>
                                                                    <TableCell className='cstm-view-users-count w-10'>{row.email}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                ) : (
                                                    <span className="no-data-found-pl">No data found</span>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        className="uic-pagination"
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={userRows.length}
                                        rowsPerPage={userRowsPerPage}
                                        page={userPage}
                                        onPageChange={handleChangeUserPage}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};
export default PlanListing;