import React from 'react'
import PropTypes from "prop-types"
const MuteIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M16.246 3.693a6.433 6.433 0 0 0-.265-.189 7.28 7.28 0 0 0-2.03-.943 2 2 0 0 0-3.898-.02c-.681.194-1.413.5-2.097.963C6.468 4.513 5.252 6.241 5.252 9v5.687l1.5-1.5V9c0-2.24.951-3.513 2.046-4.254 1.121-.76 2.44-.99 3.17-.996.73.007 2.05.236 3.172.996l.032.022 1.074-1.075Zm1.836 2.286-1.16 1.16c.166.532.263 1.147.263 1.861v6c0 .901.456 1.67.996 2.25H6.81l-1.5 1.5H21v-1.5c-.992-.005-2.315-1.324-2.315-2.25V9c0-1.19-.226-2.19-.603-3.021ZM11 20a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm9.53-16.47a.75.75 0 0 0-1.06-1.06l-16 16a.75.75 0 1 0 1.06 1.06l16-16Z"
                    clipRule="evenodd"
                />

            </svg>

        </>
    )
}
MuteIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default MuteIcon
