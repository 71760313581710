import React from "react";
import "./ModalFooter.css";
import "../../../Styles/Theme/Theme.css";
import PropTypes from "prop-types";
import Button from "../../Button/Button";

export default function ModalFooter({
  type = "twobuttons",
  hasText = false,
  label = "label",
  label2 = "label",
  btn2variant = "primary",
  hasTextString = "Never used any of our partner's services? ",
  link = "Send us a message.",
  disabled = false,
  handleClick1stButton,
  handleClick2ndButton,
  spinnersType,
  hasLoading,
  disabled1stbtn,
}) {
  return (
    <div className={`${type}-btn-footer`}>
      {type === "twobuttons" && (
        <Button
          label={label}
          handleClick={handleClick1stButton}
          size="medium"
          variant="secondary"
          disabled={disabled1stbtn}
        />
      )}
      <Button
        label={label2}
        handleClick={handleClick2ndButton}
        size="medium"
        variant={btn2variant}
        disabled={disabled}
        spinnersType={spinnersType}
        hasLoading={hasLoading}
      />
      {type === "fluidbutton" && hasText && (
        <p className="fluidbutton-p">
          {hasTextString}
          <a>{link}</a>
        </p>
      )}
    </div>
  );
}

ModalFooter.propTypes = {
  type: PropTypes.oneOf(["twobuttons", "onebutton", "fluidbutton"]),
  hasText: PropTypes.bool,
  hasTextString: PropTypes.string,
  label: PropTypes.string,
  label2: PropTypes.string,
  disabled: PropTypes.bool,
  spinnersType: PropTypes.string,
  hasLoading: PropTypes.bool,
  disabled1stbtn: PropTypes.bool,
  handleClick1stButton: PropTypes.func,
  handleClick2ndButton: PropTypes.func,
};
