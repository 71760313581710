import React from 'react'
import PropTypes from "prop-types"
const MicrophoneIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M15.5 6v4a3.5 3.5 0 1 1-7 0V6a3.5 3.5 0 1 1 7 0ZM7 6a5 5 0 0 1 10 0v4a5 5 0 0 1-10 0V6Zm5 10.5A6.5 6.5 0 0 1 5.5 10v-.25a.75.75 0 0 0-1.5 0V10a8 8 0 0 0 7.25 7.965v2.785H9a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5h-2.25v-2.785A8 8 0 0 0 20 10v-.25a.75.75 0 0 0-1.5 0V10a6.5 6.5 0 0 1-6.5 6.5Z"
                clipRule="evenodd"
              />
          
        </svg>
    )
}
MicrophoneIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default MicrophoneIcon
