import React, { useRef, useState } from 'react'
import FilterHeader from '../../../../Pages/DocumentManagement/Components/FilterHeader'
import FilterNestedNavigation from '../../../../Pages/DocumentManagement/Components/FilterNestedNavigation'
import Separators from '../../../Separators/Separators'
import FilterItems from '../../../../Pages/DocumentManagement/Components/FilterItems'
import CategoryList from './CategoryList'
import FilterFooter from '../../../../Pages/DocumentManagement/Components/FilterFooter'
import NavVideoCategoryIcon from '../../../Icons/NavIcons/NavVideoCategoryIcon'
import DiamondIcon from '../../../Icons/GeneralIcons/DiamondIcon'
import CalendarIcon from '../../../Icons/GeneralIcons/CalendarIcon'
import ReactDatePicker from 'react-datepicker'
import FilterListItem from '../../../../Pages/DocumentManagement/Components/FilterListItem'

const FilterSidebarPartner = ({ handleClearFilter, toDate, handleFromDateChange, handleToDateChange, fromDate, selectedSubCategory, handleSubCategoryFilter, totalCount, handleShowResult, selectedByDate, handleDateFilter, dateList, onCancel, selectedSortBy, selectedType, allCategoriesList, isFilter, partnerBadges, sortByList, handleSortByFilter, selectedCategory, handleTypeFilter, handleCategoryFilter }) => {

    const [isSortBy, setIsSortBy] = useState(false)
    const [isDate, setDate] = useState(false)
    const [isCategory, setCategory] = useState(false)
    const [isBadge, setBadge] = useState(false)
    const filterRef = useRef()

    // handle back from filter
    const handleBack = () => {
        setIsSortBy(false)
        setCategory(false)
        setBadge(false)
        setDate(false)
    }

    const handleCloseFilter = (e) => {
        if (
            filterRef.current &&
            isFilter &&
            !filterRef.current.contains(e.target)
        ) {
            onCancel()
        }
    }
    document.addEventListener("mousedown", handleCloseFilter)
    const headerLabel = isSortBy ? "Sort by" : isCategory ? "Category" : isBadge ? "Partner type" : "Filters"
    const handleDate = (data) => {
        handleDateFilter(data); data.id !== "custom" && handleBack()
    }
    return (
        <>
            <div className='filter-sidebar-partners' ref={filterRef}>
                <FilterHeader onClick={handleBack} onCancel={onCancel} isNavigated={isCategory || isBadge || isSortBy || isDate} title={headerLabel} />
                <div className='filter-sidebar-body scrollbar'>
                    {isCategory ? allCategoriesList.map((category) => (
                        <CategoryList handleBack={handleBack} selectedSubCategory={selectedSubCategory} handleSubCategoryFilter={handleSubCategoryFilter} category={category} selectedCategory={selectedCategory} handleCategoryFilter={handleCategoryFilter} />
                    )) : isBadge ? partnerBadges.map((badge) => (
                        <FilterListItem label={badge.name} selected={badge._id === selectedType._id} onClick={() => { handleTypeFilter(badge); handleBack() }} />
                    )) : isSortBy ? sortByList.map((sort) => (
                        <FilterListItem selected={sort.id === selectedSortBy.id} label={sort.label} onClick={() => { handleSortByFilter(sort); handleBack() }} />
                    )) : isDate ? dateList.map((sort) => (
                        <FilterListItem selected={sort.id === selectedByDate.id} label={sort.label} onClick={() => { handleDate(sort) }} />
                    )) : <>
                        <FilterNestedNavigation label={`Sort by: ${Object.keys(selectedSortBy).length > 0 ? selectedSortBy.label : "Relevant"}`} onClick={() => setIsSortBy(true)} />
                        <Separators />
                        <FilterNestedNavigation icon={Object.keys(selectedCategory).length > 0 ? <NavVideoCategoryIcon /> : ""} label={Object.keys(selectedCategory).length > 0 ? selectedCategory.name : 'Category'} onClick={() => setCategory(true)} />
                        <FilterNestedNavigation icon={Object.keys(selectedType).length > 0 ? <DiamondIcon /> : ""} label={Object.keys(selectedType).length > 0 ? selectedType.name : 'Partner type'} onClick={() => setBadge(true)} />
                        <FilterNestedNavigation icon={Object.keys(selectedByDate).length > 0 ? <CalendarIcon /> : ""} label={Object.keys(selectedByDate).length > 0 ? selectedByDate.label : 'Date created'} onClick={() => setDate(true)} />
                    </>}
                    {selectedByDate.id === "custom" && isDate &&
                        <>
                            <div className='custom-date-range'>
                                <div className='text-field-main'>
                                    <h6>From</h6>
                                    <div className={`text-field-box`}><ReactDatePicker onChange={handleFromDateChange} selected={fromDate} /></div>
                                </div>
                                <div className='text-field-main'>
                                    <h6>To</h6>
                                    <div className={`text-field-box`}><ReactDatePicker onChange={(date) => { handleToDateChange(date); handleBack() }} selected={toDate} /></div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                {!isCategory && !isBadge && !isSortBy && !isDate && <FilterFooter onCancel={handleClearFilter} onShow={handleShowResult} resultCount={totalCount} />}
            </div>
        </>
    )
}

export default FilterSidebarPartner