import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ModalPopup from "../../ModalPopup/ModalPopup";

const NonExistingUsersPopup = ({
  showNonExistingUsersPopup,
  setShowNonExistingUsersPopup,
  failedUsers,
}) => {
  return (
    <ModalPopup
      onClose={() => {
        setShowNonExistingUsersPopup(false);
      }}
      isHeader={true}
      isFooter={false}
      modelSize="648"
      isOpen={showNonExistingUsersPopup}
      className="z-full"
      headingLable={"Non-user emails detected"}
    >
      <div className="ctsm-modal-body scrollbar no-border-scrollbar">
        <div className="checkin-modal-body-wrapper">
          <Paper className="uic-tb-mn ">
            <TableContainer className=" uic-table-main ">
              <Table className="">
                <TableHead className="uic-table-th">
                  <TableRow>
                    <TableCell className="uic-th-td">No.</TableCell>
                    <TableCell className="uic-th-td">Email</TableCell>
                    <TableCell className="uic-th-td">Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="uic-table-td">
                  {failedUsers?.length > 0 &&
                    failedUsers !== undefined &&
                    failedUsers.map((user, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell className="uic-th-td">
                            {index + 1}
                          </TableCell>
                          <TableCell className="uic-th-td">
                            {user.email}
                          </TableCell>
                          <TableCell className="uic-th-td">
                            {user.message}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </ModalPopup>
  );
};

export default NonExistingUsersPopup;
