const allfeelact = [];

const ReduActivityFeeling = (state = allfeelact, action) => {
  switch (action.type) {
    case "ADDACTFEEL":
      return [...state, action.payload];
    case "FETCHACTIFEEL":
      return action.payload;
    default:
      return state;
  }
};

export default ReduActivityFeeling;
