import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";

//import api file
import Services from "../../Services/auth.service";

import emoji from "../../Images/optimistic.png";
import arrowleft from "../../Images/arrow-left.png";
import dummy from "../../Images/Frame.svg";
const EditTopic = ({ setcurrentDiv, topicdtl, selectedTid }) => {
  var formdata= new FormData()
  const [dbimg, setdbimg] = useState(""); 
  const [img, setimg] = useState([]);
  const [tpcs, settpcs] = useState("");
  const [grps, setgrps] = useState([]);
  const [allgrp, setallgrp] = useState([]);
  const [grptext, setgrptext] = useState({
    groupTitle: "",
    _id: "",
  });
  const [txt, settxt] = useState("");
  const [err, seterr] = useState({
    name: "",
    group: "",
  });
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  useEffect(() => {
    Services.getallgroup()
      .then((res) => {
        if (res.data.status) {
          if (res.data.data.length > 0) {
            setallgrp(res.data.data);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (topicdtl !== undefined) {
      settpcs(topicdtl.topic.substring(1));
      var arr = topicdtl.numberOfGroup.map((grp) => {
        return grp;
      });
      setgrps(arr);
      topicdtl.topicImage !== undefined && topicdtl.topicImage !== null && topicdtl.topicImage.length > 0 ? setdbimg(process.env.REACT_APP_AWS_IMG_VID_PATH + topicdtl.topicImage) : setdbimg("")
    }
  }, [topicdtl]);
  
  const handleChange = (e) => {
    /^[a-zA-Z0-9\_]*$/.test(e.target.value)
      ? seterr({ ...err, name: "" })
      : seterr({
          ...err,
          name: "Enter only alphabets, numbers or underscore!",
        });
    settpcs(e.target.value);
  };

  const handleEdit = () => {
    console.log(tpcs, grps);
    if (
      err.name.length === 0 &&
      err.group.length === 0 &&
      tpcs.length > 0 &&
      grps.length > 0
    ) {
      var arr = grps.map((g) => {
        return g._id;
      });
      console.log(img,tpcs,grps)
      if(img.length > 0)
        formdata.append("topicImage",img[0])
      formdata.append("topic",  "#" + tpcs)
      for(var i = 0; i < arr.length; i++)
      {
        formdata.append("numberOfGroup[]", arr[i])
      }

      var data = {
        topicid: selectedTid,
        data: formdata,
      };
      Services.edittopic(data)
        .then((res) => {
          if (res.data.status) {
            setshowalert(true);
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false);
              setalertmsg("");
            }, 3000);
            setcurrentDiv("lisiting");
          } else {
            setshowalert(true);
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false);
              setalertmsg("");
            }, 3000);
            setcurrentDiv("lisiting");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (tpcs.length === 0 || grps.length === 0) {
      tpcs.length === 0 && grps.length === 0
        ? seterr({ name: "Enter topics!", group: "Enter group!" })
        : tpcs.length === 0
        ? seterr({ name: "Enter topics!", group: "" })
        : seterr({ name: "", group: "Enter group!" });
    }
  };

  const handlegroup = (e) => {
    var exist = false;
    for(var i = 0; i < grps.length ; i++ )
    {
      if(grps._id === e.target.value.split(",")[0])
      {
        exist = true
      }
    }
    if(!exist)
    {
      setgrps([
        ...grps,
        { _id: e.target.value.split(",")[0], groupTitle: e.target.value.split(",")[1] },
      ])
    }
    e.target.value=""
    seterr({ ...err, group: "" });
  }

  return (
    <>
  <div className="content-main">
        <div className="create-yuor-topic-main-box">
          <div className="create-yuor-topic">
            <h1>Create Your Topic</h1>
            <span className="cyt-cont">
              Let's cover some basic inforamtion about yuor first on Million
              Dollar Sellers
            </span>
            <span className="cyt-lets-get-started-link">
              <a href="#">Let’s Get Started</a>
              <img src={emoji} />{" "}
            </span>
          </div>
          <div className="create-topic-info">
            <div className="um-back">
              <button type="button" className="back-btn" onClick={()=>{setcurrentDiv("listing")}}>
                <img src={arrowleft} /> Back
              </button>
            </div>
            <h1>Topic Info</h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard.
            </p>
            {showalert && <div className="alert alert-info">{alertmsg}</div>}

            <div className="topic-file-image">
              <div className="topic-file-image-left">
                <img src={img.length > 0 ? URL.createObjectURL(img[0]) : dbimg.length > 0 ? dbimg : dummy} />{" "}
              </div>
              <div className="topic-file-image-right">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    setimg(acceptedFiles.map((file) => file));
                  }}
                  multiple={false}
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  maxSizeBytes="10485760"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <span className="topics-img-title">Topics image</span>
                      <div className="topics-img-size">
                      JPEG or PNG, No logger that 10MB
                    </div>
                    </div>
                  )}
                </Dropzone>
                
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 form-group">
                <div className="form-group-label">Topic Title</div>
                <div className="form-group-ct ct-fl-main">
                  <input
                    type="text"
                    placeholder="enter topic"
                    onChange={handleChange}
                    value={tpcs}
                  />
                </div>
                <span className="error">{err.name}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 form-group">
                <div className="form-group-label"> Add groups for topics</div>
                <div className="form-group-ct ct-down-box">
                    <select onChange={handlegroup}>
                      <option value=""> Select Groups </option>
                    {allgrp.length > 0 ? 
                      allgrp.map((opt)=>{
                        return <option value={opt._id+","+opt.groupTitle}>{opt.groupTitle}</option>
                      })
                    : ""}
                    </select>
                </div>
                <span className="error">{err.group}</span>
              </div>
            </div>

            <div className="row ct-lbl-main">
              <div className="col-sm-12 form-group">
                {grps.length > 0
                  ? grps.map((g) => {
                      return (
                        <span className="topic-label-in">
                          <b>{g.groupTitle}</b>
                          <button
                            onClick={() => {
                              setgrps(grps.filter((group) => group._id !== g._id))
                            }}
                          >
                            ×
                          </button>
                        </span>
                      );
                    })
                  : ""}
              </div>
            </div>

            <div className="cyp-btm-btn">
              <button className="btn"  onClick={handleEdit}>
                Update Topic
              </button>
              <button className="btn2" onClick={()=>{setcurrentDiv("listing")}}>Discard</button>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default EditTopic;
