import React from 'react'
import Header from './UserComponents/Dashboard/Header'
import LeftSidebar from './UserComponents/Dashboard/LeftSidebar'
import axios from 'axios';
import { useEffect, useState } from 'react';

const WordpressIframe = () => {
  const [elementorContent, setElementorContent] = useState('');
  const [cssLinks, setCssLinks] = useState([]);
  const [cssContent, setCssContent] = React.useState(null);
  const [url, setUrl] = useState("https://pages.milliondollarsellers.com/resource-directories/")
  useEffect(() => {
      
  }, []);

  return (
    <>
      <Header />
      <div className="all-content-main cstm-all-content-main cstm-all-library-content-main cstm-all-content-library-main-only">
        <LeftSidebar />
        <div className="all-content-main-edit-profile all-content-main-edit-profile" >
        <iframe className="ifrane-wordpress"  src={url}> 

 
        </iframe>          
        </div>

      </div >
    </>
  )
}

export default WordpressIframe