const registrationdetail = {};

const ReduRegistrationDetail = (state = registrationdetail, action) => {
  switch (action.type) {
    case "SETDETAIL":
      return action.payload;
    case "ADDDETAIL":
      return {...state, ...action.payload};
    default:
      return state;
  }
};

export default ReduRegistrationDetail;
