import React from 'react'

const MessageModal = ({ deletionMessage, setDeletionMessage, failure = false, additionalDivArray = [] }) => {
    return (
        <>
            {deletionMessage && failure && <div
                className="modal fade msg-popup delete-modal-with-loader show"
                id={"deletionMessage"}
                tabIndex="-1"
                role="dialog"
                aria-labelledby="example"
                aria-hidden="true"
                style={{ display: "block", color: "#ff0c0c" }}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="close" onClick={() => setDeletionMessage("")}>
                            &times;
                        </button>

                        <span className="response-text" style={{ color: failure ? "#ff0c0c" : "" }}>{deletionMessage}</span>
                        {additionalDivArray.map((text) => {
                            return <span className='message-modal-addtional-div'>{text}</span>
                        })}
                    </div>
                </div>
            </div>}
        </>
    )
}

export default MessageModal
