import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedMainEventApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    deleteCategory: builder.mutation({
      query: ({ body }) => {
        return {
          url: ApiRoutes.mainEvent.deleteCategory.url,
          method: ApiRoutes.mainEvent.deleteCategory.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    reAssignMainEventCategory: builder.query({
      query: ({ id }) => {
        return {
          url: ApiRoutes.mainEvent.reAssignCategory.url.replace(":id", id),
          method: ApiRoutes.mainEvent.reAssignCategory.method,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useDeleteCategoryMutation, useReAssignMainEventCategoryQuery } =
  extendedMainEventApi;
