import React, { useRef } from "react";
import SearchSidebar from "../../../Images/SearchSidebar.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import Services from "../../../Services/auth.service";
import { InView } from "react-intersection-observer";

import "../../../Styles/partners.css";
import partnerSearchIcon from "../../../Images/partnerSearch.svg";
import partnerSearchHistoryIcon from "../../../Images/searchandhistory.svg";
import searchIcon from "../../../Images/icn_m_search.svg";
import authService1 from "../../../Services/auth.service1";
import authService from "../../../Services/auth.service";
import PartnerOfferCard from "./PartnerOfferCard";
import PartnerPerkCard from "./PartnerPerkCard";
import CensusDataModal from "./CensusDataModal";

// icons
import IconSearchNoResults from "../../GlobalComponents/Icons/IconEmptyState/IconSearchNoResults";
import Chips from "../../Chips/Chips";
import FilterSidebarPartner from "./Components/FilterSidebarPartner";
import DropDown from "../../DropDown/DropDown";
import DropDownItem from "../../DropDownItem/DropDownItem";
import FilterIcon from "../../FilterIcon/FilterIcon";
import SearchField from "../../InputField/SearchField";
import FilterIconAlt from "../../Icons/GeneralIcons/FilterIconAlt";

const Partners = ({ reviewCount, setReview, isReview }) => {
  const sortByList = [
    { id: "relevant", label: "Relevant" },
    { id: "newest", label: "Newest first" },
    { id: "oldest", label: "Oldest first" },
    { id: "partnerAsc", label: "Partner name A-Z" },
    { id: "partnerDesc", label: "Partner name Z-A" },
    { id: "rating", label: "Reviews count" },
  ];

  // date  list array
  const dateList = [
    { id: "lifetime", label: "Anytime" },
    { id: "first24hours", label: "Today" },
    { id: "past7days", label: "Last 7 days" },
    { id: "past30days", label: "Last 30 days" },
    { id: "thisYear", label: `This year (${new Date().getFullYear()})` },
    { id: "lastYear", label: `Last  year (${new Date().getFullYear() - 1})` },
    { id: "custom", label: "Custom date range" },
  ];

  const history = useNavigate();
  const search = useLocation().search;
  //URL
  const listingtype = new URLSearchParams(search).get("type");
  const categoryFromUrl = new URLSearchParams(search).get("cat");
  const subCategoryFromUrl = new URLSearchParams(search).get("subcat");
  const dateFilterFromUrl = new URLSearchParams(search).get("datefilter");
  const sortByFromUrl = new URLSearchParams(search).get("sortby");
  const searchTextFromUrl = new URLSearchParams(search).get("search");
  const badgeFromUrl = new URLSearchParams(search).get("badge");
  const tagFromUrl = new URLSearchParams(search).get("tag");
  //page level
  const [pageType, setPageType] = useState("offer");
  const [category, setCategory] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const [allCategoriesList, setAllCategoriesList] = useState([]);
  //pagination management
  const [partnerList, setPartnerList] = useState([]);
  const [perklist, setPerkList] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [fetchMoreLoader, setFetchMoreLoader] = useState(false);
  const [showCategoryList, setShowCategoryList] = useState(false);
  const [isSubCat, setSubCat] = useState(false);
  const [showCategorySortingList, setShowCategorySortingList] = useState(false);
  const [noData, setNoData] = useState(false);
  const [noPerkData, setNoPerkData] = useState(false);
  const [totalRecordsPerk, setTotalRecordsPerk] = useState();
  const [totalPagePerk, setTotalPagePerk] = useState(0);
  const [currentPagePerk, setCurrentPagePerk] = useState(0);
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [searchHistoryList, setSearchHistoryList] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [allPartnerList, setAllPartnerList] = useState([]);
  const [partnerBadges, setPartnerBadges] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedSubCategory, setSelectedSubCategory] = useState({});
  const [selectedType, setSelectedType] = useState({});
  const [selectedSortBy, setSelectedSortBy] = useState({});
  const [selectedByDate, setSelectedByDate] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [partnerType, setPartnerType] = useState("");
  const searchInput = useRef(null);
  const filterRef = useRef(null);
  const dropdownRef = useRef(null);
  const subCatRef = useRef(null);
  const sortingKeyValueList = {
    newest: "Newest",
    value: "Value",
    rating: "Rating",
    exclusive: "MDS Exclusive",
    preferred: "Preferred partner",
    premiere: "premiere",
  };
  const [access, setAccess] = useState(false);
  const [access1, setAccess1] = useState(false);
  const [access2, setAccess2] = useState(false);
  const [isFilter, setFilter] = useState(false);
  const [allTagList, setAllTagList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(0);
  //query parameters to pass in api
  let data = {
    page: 1,
    filter: listingtype,
    searchText: searchTextFromUrl !== null ? searchTextFromUrl : "",
    newest: sortByFromUrl === "newest" ? sortByFromUrl : "",
    relevant: sortByFromUrl === "relevant" ? sortByFromUrl : "",
    oldest: sortByFromUrl === "oldest" ? sortByFromUrl : "",
    partnerAsc: sortByFromUrl === "partnerAsc" ? sortByFromUrl : "",
    partnerDesc: sortByFromUrl === "partnerDesc" ? sortByFromUrl : "",
    rating: sortByFromUrl === "rating" ? sortByFromUrl : "",
    value: sortByFromUrl === "value" ? sortByFromUrl : "",
    categoryId:
      categoryFromUrl !== null && categoryFromUrl !== "all"
        ? categoryFromUrl
        : "",
    partnerType:
      badgeFromUrl !== undefined &&
        badgeFromUrl !== null &&
        badgeFromUrl !== "all"
        ? badgeFromUrl
        : "",
    subcategoryId:
      subCategoryFromUrl !== undefined && subCategoryFromUrl !== null
        ? subCategoryFromUrl
        : "",
    dateFilterType:
      dateFilterFromUrl !== undefined && dateFilterFromUrl !== null
        ? dateFilterFromUrl
        : "",
    fromDate:
      selectedByDate.id !== undefined && selectedByDate.id === "custom"
        ? fromDate
        : "",
    toDate:
      selectedByDate.id !== undefined && selectedByDate.id === "custom"
        ? toDate
        : "",
    tag: tagFromUrl !== undefined && tagFromUrl !== null ? tagFromUrl : "",
  };

  // on first render of page this useEffect call
  useEffect(() => {
    if (localStorage.getItem("count") > 364) {
      setAccess2(true);
    }
    if (
      listingtype === null &&
      categoryFromUrl === null &&
      sortByFromUrl === null &&
      searchTextFromUrl === null &&
      badgeFromUrl === null
    )
      history(
        "/partners/all-partners?type=offer&&cat=all&&search=&&sortby=relevant&&badge=all"
      );
  }, []);

  //partner list api call
  const getPartnerListApiCall = async (data) => {
    setLoader(true);
    try {
      const res = await authService1.getPartnerByfilterAndSorting(data);
      if (res.data.status) {
        if (res.data.data.filterType === "perk") {
          setLoader(false);
          setNoPerkData(false);
          setPerkList(res.data.data.partners);
          setTotalRecordsPerk(res.data.data.totalPartners);
          setTotalPagePerk(res.data.data.totalPages);
          setCurrentPagePerk(res.data.data.currentPage);
          if (res.data.data.partners.length === 0) {
            setNoPerkData(true);
          }
        } else if (res.data.data.filterType === "offer") {
          setLoader(false);
          setNoData(false);
          setPartnerList(res.data.data.partners);
          setTotalRecords(res.data.data.totalPartners);
          setTotalPage(res.data.data.totalPages);
          setCurrentPage(res.data.data.currentPage);
          if (res.data.data.partners.length === 0) {
            setNoData(true);
          }
        } else {
          setLoader(false);
          if (res.data.data.partners.length === 0) {
            setNoData(true);
          }
        }
        setLoader(false);
      }
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log("Error:", e);
    } finally {
      setLoader(false);
      setFetchMoreLoader(false);
    }
  };

  // get filter result count
  async function getResultCount(
    pageType,
    category,
    subCategory,
    partnerType,
    sortBy,
    dateFilterType,
    fromDate,
    toDate
  ) {
    let query = "?";
    pageType !== undefined && pageType !== null
      ? (query += `filter=${pageType}`)
      : "";
    category !== undefined && category !== null
      ? (query += `&categoryId=${category}`)
      : "";
    subCategory !== undefined && subCategory !== null
      ? (query += `&subcategoryId=${subCategory}`)
      : "";
    partnerType !== undefined && partnerType !== null
      ? (query += `&partnerType=${partnerType}`)
      : "";
    sortBy !== undefined && sortBy !== null && sortBy === "relevant"
      ? (query += `&relevant=${sortBy}`)
      : "";
    sortBy !== undefined && sortBy !== null && sortBy === "newest"
      ? (query += `&newest=${sortBy}`)
      : "";
    sortBy !== undefined && sortBy !== null && sortBy === "oldest"
      ? (query += `&oldest=${sortBy}`)
      : "";
    sortBy !== undefined && sortBy !== null && sortBy === "partnerAsc"
      ? (query += `&partnerAsc=${sortBy}`)
      : "";
    sortBy !== undefined && sortBy !== null && sortBy === "partnerDesc"
      ? (query += `&partnerDesc=${sortBy}`)
      : "";
    sortBy !== undefined && sortBy !== null && sortBy === "rating"
      ? (query += `&rating=${sortBy}`)
      : "";
    dateFilterType !== undefined && dateFilterType !== null
      ? (query += `&dateFilterType=${dateFilterType}`)
      : "";
    dateFilterType !== undefined &&
      dateFilterType !== null &&
      fromDate !== undefined &&
      toDate !== undefined &&
      dateFilterType === "custom"
      ? (query += `&fromDate=${fromDate}&toDate=${toDate}`)
      : "";
    try {
      const resultCountRes = await authService1.getPartnerResultCount(query);
      if (resultCountRes.data.status) {
        setTotalCount(resultCountRes.data.total);
      }
    } catch (error) {
      return false;
    }
  }
  //onfirst render of page this useEffect call
  useEffect(() => {
    setLoader(true);
    setPageType(listingtype);
    setCategory(categoryFromUrl);
    setSearchedKeyword(searchTextFromUrl);
    setSearchText(searchTextFromUrl);
    setSortBy(sortByFromUrl);
    authService.getPartnerBadges().then((badgeRes) => {
      if (badgeRes.data.status) {
        let badges = badgeRes.data.data.filter(
          (badge) => badge.name !== "nobadge"
        );
        setPartnerBadges(badges);
      }
    });
    authService.getUserTagList().then((tagRes) => {
      if (tagRes.data.status) {
        const data = tagRes.data.data;
        var arr = [];
        if (
          data &&
          data.length !== undefined &&
          data.length !== null &&
          data.length > 0
        ) {
          data.map((item) =>
            arr.push({ _id: item._id, companyName: item.name })
          );
          setAllTagList(arr);
        }
        const tag = data.filter((tgs) => tgs._id === tagFromUrl);
        tag.length > 0 && setSearchText(tag[0].name);
        tag.length > 0 &&
          authService.addPartnerSearchHistory({
            search: tag[0].name.toLowerCase(),
            type: listingtype,
          });
      }
    });
    authService
      .getPartnerSearchHistory(`?filter=${listingtype}`)
      .then((historyRes) => {
        if (historyRes.data.status) {
          const data = historyRes.data.data;
          setSearchHistoryList(data);
          setSearchHistory(data);
        } else {
          setSearchHistoryList([]);
          setSearchHistory([]);
        }
      });
    authService1
      .getAllCategoryListForUser()
      .then((res) => {
        if (res.data.status) {
          let arr = [{ _id: "", name: "All categories" }];
          setAllCategoriesList([...arr, ...res.data.data]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    authService.allPartnerList(`?filter=${listingtype}`).then((allListRes) => {
      if (allListRes.data.status) {
        setAllPartnerList(allListRes.data.data);
      }
    });
    document.addEventListener(
      "click",
      (e) => {
        if (e.target.class !== "li-item-dropdown") {
          setShowCategoryList(false);
          setShowCategorySortingList(false);
        }
      },
      false
    );
  }, []);
  useEffect(() => {
    const partnetType = partnerBadges.filter(
      (type) => type._id === badgeFromUrl
    );
    setPartnerType(partnetType.length > 0 && partnetType[0].name);
    setSelectedType(partnetType.length > 0 ? partnetType[0] : {});
    const category = allCategoriesList.filter(
      (cate) => cate._id === categoryFromUrl
    );
    setSelectedCategory(category.length > 0 ? category[0] : {});
    const subCategory =
      category.length > 0 &&
      category[0].subcategory !== undefined &&
      category[0].subcategory.length > 0 &&
      category[0].subcategory.filter(
        (subcate) => subcate._id === subCategoryFromUrl
      );
    setSelectedSubCategory(subCategory.length > 0 ? subCategory[0] : {});
    const sortBy = sortByList.filter((sort) => sort.id === sortByFromUrl);
    setSelectedSortBy(sortBy.length > 0 ? sortBy[0] : {});
  }, [partnerBadges, allCategoriesList]);
  //when pagetype or any filter apply this useEffect call
  useEffect(() => {
    setPageType(listingtype);
    setCategory(categoryFromUrl !== "all" ? categoryFromUrl : "");
    setSearchedKeyword(searchTextFromUrl);
    setSearchText(searchTextFromUrl);
    setSortBy(sortByFromUrl);
    const partnetType = partnerBadges.filter(
      (type) => type._id === badgeFromUrl
    );
    setPartnerType(partnetType.length > 0 && partnetType[0].name);
    authService
      .getPartnerSearchHistory(`?filter=${listingtype}`)
      .then((historyRes) => {
        if (historyRes.data.status) {
          const data = historyRes.data.data;
          setSearchHistoryList(data);
          setSearchHistory(data);
        } else {
          setSearchHistoryList([]);
          setSearchHistory([]);
        }
      });
    if (listingtype === "perk") {
      if (perklist.length === 0) {
        getPartnerListApiCall(data);
      }
    } else if (listingtype === "offer") {
      if (partnerList.length === 0) {
        getPartnerListApiCall(data);
      }
    }
    getResultCount(
      listingtype,
      categoryFromUrl != "all" ? categoryFromUrl : "",
      subCategoryFromUrl !== null ? subCategoryFromUrl : "",
      badgeFromUrl !== "all" ? badgeFromUrl : "",
      sortByFromUrl !== "all" ? sortByFromUrl : "",
      dateFilterFromUrl !== null ? dateFilterFromUrl : "",
      fromDate,
      toDate
    );
  }, [
    listingtype,
    categoryFromUrl,
    searchTextFromUrl,
    sortByFromUrl,
    badgeFromUrl,
    subCategoryFromUrl,
    tagFromUrl,
  ]);

  //onscroll fetch more data
  const fetchMoreData = () => {
    if (currentPage < totalPage || currentPagePerk < totalPagePerk) {
      setFetchMoreLoader(true);
      let data = {
        page: currentPage + 1,
        filter: listingtype,
        searchText: searchTextFromUrl,
        relevant: sortByFromUrl === "relevant" ? sortByFromUrl : "",
        newest: sortByFromUrl === "newest" ? sortByFromUrl : "",
        oldest: sortByFromUrl === "oldest" ? sortByFromUrl : "",
        partnerAsc: sortByFromUrl === "partnerAsc" ? sortByFromUrl : "",
        partnerDesc: sortByFromUrl === "partnerDesc" ? sortByFromUrl : "",
        rating: sortByFromUrl === "rating" ? sortByFromUrl : "",
        value: sortByFromUrl === "value" ? sortByFromUrl : "",
        categoryId: categoryFromUrl !== "all" ? categoryFromUrl : "",
        partnerType:
          badgeFromUrl !== undefined &&
            badgeFromUrl !== null &&
            badgeFromUrl !== "all"
            ? badgeFromUrl
            : "",
        subcategoryId:
          subCategoryFromUrl !== undefined && subCategoryFromUrl !== null
            ? subCategoryFromUrl
            : "",
        dateFilterType:
          dateFilterFromUrl !== undefined && dateFilterFromUrl !== null
            ? dateFilterFromUrl
            : "",
        fromDate:
          selectedByDate.id !== undefined && selectedByDate.id === "custom"
            ? fromDate
            : "",
        toDate:
          selectedByDate.id !== undefined && selectedByDate.id === "custom"
            ? toDate
            : "",
        tag: tagFromUrl !== undefined && tagFromUrl !== null ? tagFromUrl : "",
      };
      console.log("data 2 >>", data);
      authService1
        .getPartnerByfilterAndSorting(data)
        .then((res) => {
          if (res.data.status) {
            if (res.data.data.filterType === "offer") {
              setPartnerList([...partnerList, ...res.data.data.partners]);
              setTotalPage(res.data.data.totalPages);
              setCurrentPage(res.data.data.currentPage);
            } else {
              setPerkList([...perklist, ...res.data.data.partners]);
              setTotalPagePerk(res.data.data.totalPages);
              setCurrentPagePerk(res.data.data.currentPage);
            }
          }
          setLoader(false);
          setFetchMoreLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    }
  };

  //handle sorting selection
  const onSortingSelection = (e) => {
    e.stopPropagation();
    setPartnerList([]);
    setPerkList([]);
    history(
      `/partners/all-partners?type=${pageType}&&cat=${category !== null && category !== "" ? category : "all"
      }&&search=${searchedKeyword}&&sortby=${e.target.id}&&badge=all`
    );
    setShowCategorySortingList(false);
  };
  const onBadgeSelection = (e) => {
    e.stopPropagation();
    setPartnerList([]);
    setPerkList([]);
    history(
      `/partners/all-partners?type=${pageType}&&cat=${category !== null && category !== "" ? category : "all"
      }&&search=${searchedKeyword}&&sortby=all&&badge=${e.target.id}`
    );
    setShowCategorySortingList(false);
  };
  //handle offer and perk switch click
  const onClickOffer = (type) => {
    setPageType(type);
    if (
      categoryFromUrl !== "all" ||
      badgeFromUrl !== "all" ||
      sortByFromUrl !== "all" ||
      tagFromUrl !== null
    ) {
      setPerkList([]);
      setPartnerList([]);
    }
    history(
      `/partners/all-partners?type=${type}&&cat=all&&search=&&sortby=all&&badge=all`
    );
  };
  //when search update the suggestion and history list
  const updateSuggestionAndHistoryList = (searchText) => {
    let updatedsearchHistory = searchHistoryList.filter((data) => {
      return data.name.toLowerCase().startsWith(searchText.toLowerCase());
    });
    setSearchHistory(updatedsearchHistory);
    let updatedSuggestionList = allPartnerList.filter((title) => {
      return (
        title.companyName.toLowerCase().startsWith(searchText.toLowerCase()) &&
        !updatedsearchHistory.filter((histortName) => {
          return (
            histortName.name.toLowerCase() === title.companyName.toLowerCase()
          );
        }).length
      );
    });

    let updateTagSuggestion = allTagList
      .filter((tag) =>
        tag.companyName.toLowerCase().startsWith(searchText.toLowerCase())
      )
      .filter(
        (suggestion) =>
          !updatedsearchHistory.some(
            (tag) => suggestion.name === tag.companyName
          )
      );
    setSuggestionList([...updatedSuggestionList, ...updateTagSuggestion]);
  };

  //get all history list
  const getAllHistoryList = () => {
    var newHistoryList = searchHistoryList.map((value) => {
      return value.name;
    });
    return newHistoryList;
  };

  //add search history function
  const addHistory = (historyText) => {
    const currentHistoryList = getAllHistoryList();
    if (historyText) {
      authService
        .addPartnerSearchHistory({
          search: historyText.toLowerCase(),
          type: listingtype,
        })
        .then((res) => {
          if (res.data.status) {
            var data = res.data.data;
            var newarr = searchHistoryList;
            if (currentHistoryList.includes(historyText.toLowerCase())) {
              newarr = searchHistoryList.filter((data) => {
                return data.name.toLowerCase() !== historyText.toLowerCase();
              });
            }
            newarr.unshift(data);
            setSearchHistoryList(newarr);
          }
        })
        .catch((e) => {
          return false;
        });
    }
  };

  //remove search history function
  const removeHistory = (id) => {
    const listElement = document.getElementById(id);
    listElement.classList.remove("hover");
    listElement.firstElementChild.remove();
    listElement.addEventListener("mousedown", (e) => {
      e.preventDefault();
    });
    var newDiv = document.createElement("div");
    newDiv.textContent = "Suggestion removed";
    listElement.appendChild(newDiv);
    setSearchHistoryList(() => {
      return searchHistoryList.filter((value, index) => {
        return id !== value._id;
      });
    });
    authService
      .removetPartnerSearchHistory(id)
      .then((res) => {
        if (res.data.status) {
          //
        }
      })
      .catch((e) => {
        return false;
      });
  };

  //partner selection function
  const onPartnerSelection = (title) => {
    {
      (title !== "" || searchTextFromUrl !== "") && setPartnerList([]);
      setPerkList([]);
    }
    setListIndex(-1);
    setActualsearch(title);
    setSuggestionBox(false);
    setSuggestionBox(false);
    setSearchText(title);
    updateSuggestionAndHistoryList(title);
    addHistory(title);
  };

  //handle search for search partner
  const handleSearch = (e, searchText = "") => {
    setListIndex(-1);
    setSuggestionBox(true);
    var currentValue;
    if (searchText) {
      currentValue = searchText;
    } else {
      currentValue = e.target.value;
      setSearchText(currentValue);
    }
    setActualsearch(currentValue);

    if (currentValue) {
      updateSuggestionAndHistoryList(currentValue);
    } else {
      setSearchHistory(searchHistoryList);
      setSuggestionList([]);
    }
  };

  const scrollIntoView = (listIndex) => {
    if (dropdownRef.current) {
      dropdownRef.current.children[listIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  //when list index update this useeffect will call
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        scrollIntoView(listIndex);
        var text = currentList.querySelector(
          ".cstm-cl-search-sug-history-title-common"
        ).innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  // handle restriction popup
  const handleRestriction = () => {
    if (reviewCount < 3) {
      setReview(true);
    }
  };

  // handle Category select
  const handleCategoryFilter = (data) => {
    getResultCount(
      pageType,
      data._id,
      selectedSubCategory._id,
      selectedType._id,
      selectedSortBy.id,
      selectedByDate.id,
      fromDate,
      toDate
    );
    setSelectedCategory(data);
    setSelectedSubCategory({});
  };

  // handle Category select
  const handleSubCategoryFilter = (data) => {
    getResultCount(
      pageType,
      selectedCategory._id,
      data._id,
      selectedType._id,
      selectedSortBy.id,
      selectedByDate.id,
      fromDate,
      toDate
    );
    setSelectedSubCategory(data);
  };

  // handle type select
  const handleTypeFilter = (data) => {
    getResultCount(
      pageType,
      selectedCategory._id,
      selectedSubCategory._id,
      data._id,
      selectedSortBy.id,
      selectedByDate.id,
      fromDate,
      toDate
    );
    setSelectedType(data);
  };

  // handle sort select
  const handleSortByFilter = (data) => {
    getResultCount(
      pageType,
      selectedCategory._id,
      selectedSubCategory._id,
      selectedType._id,
      data.id,
      selectedByDate.id,
      fromDate,
      toDate
    );
    setSelectedSortBy(data);
  };

  // handle fromdate select change
  const handleDateFilter = (data) => {
    getResultCount(
      pageType,
      selectedCategory._id,
      selectedSubCategory._id,
      selectedType._id,
      selectedSortBy.id,
      data.id,
      fromDate,
      toDate
    );
    setSelectedByDate(data);
  };

  // handle todate select change
  const handleToDateChange = (date) => {
    setToDate(date);
    getResultCount(
      pageType,
      selectedCategory._id,
      selectedSubCategory._id,
      selectedType._id,
      selectedSortBy.id,
      selectedByDate.id,
      fromDate,
      date
    );
  };

  // handle date select filter
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  // handle apply filter show result
  const handleShowResult = () => {
    setFilter(false);
    let data = {
      page: 1,
      filter: pageType,
      searchText: searchTextFromUrl,
      relevant: selectedSortBy.id === "relevant" ? selectedSortBy.id : "",
      newest: selectedSortBy.id === "newest" ? selectedSortBy.id : "",
      oldest: selectedSortBy.id === "oldest" ? selectedSortBy.id : "",
      partnerAsc: selectedSortBy.id === "partnerAsc" ? selectedSortBy.id : "",
      partnerDesc: selectedSortBy.id === "partnerDesc" ? selectedSortBy.id : "",
      rating: selectedSortBy.id === "rating" ? selectedSortBy.id : "",
      value: sortByFromUrl === "value" ? sortByFromUrl : "",
      categoryId:
        selectedCategory._id !== undefined && selectedCategory._id !== null
          ? selectedCategory._id
          : "",
      partnerType:
        selectedType._id !== undefined && selectedType._id !== null
          ? selectedType._id
          : "",
      subcategoryId:
        selectedSubCategory._id !== undefined &&
          selectedSubCategory._id !== null
          ? selectedSubCategory._id
          : "",
      dateFilterType:
        selectedByDate.id !== undefined && selectedByDate.id !== null
          ? selectedByDate.id
          : "",
      fromDate:
        selectedByDate.id !== undefined && selectedByDate.id === "custom"
          ? fromDate
          : "",
      toDate:
        selectedByDate.id !== undefined && selectedByDate.id === "custom"
          ? toDate
          : "",
      tag: tagFromUrl !== undefined && tagFromUrl !== null ? tagFromUrl : "",
    };
    getPartnerListApiCall(data);
    history(
      `/partners/all-partners?type=${pageType}${Object.keys(selectedCategory).length > 0
        ? `&&cat=${selectedCategory._id}`
        : "&&cat=all"
      }&&search=${searchedKeyword !== null ? searchedKeyword : ""}&&sortby=${selectedSortBy.id !== undefined ? selectedSortBy.id : "all"
      }${Object.keys(selectedType).length > 0
        ? `&&badge=${selectedType._id}`
        : ""
      }${Object.keys(selectedSubCategory).length > 0
        ? `&&subcat=${selectedSubCategory._id}`
        : ""
      }${Object.keys(selectedByDate).length > 0
        ? `&&datefilter=${selectedByDate.id}`
        : ""
      }`
    );
  };

  // handle clear filters
  const handleClearFilter = () => {
    setSelectedByDate({});
    setSelectedCategory({});
    setSelectedSortBy({});
    setSelectedSubCategory({});
    setSelectedType({});
    getResultCount(pageType, "", "", "", "", "", "", "");
  };

  const updateAppliedFilterCount = () => {
    // Calculate the count based on your state variables
    const count =
      (Object.keys(selectedSortBy).length > 0 ? 1 : 0) +
      (Object.keys(selectedType).length > 0 ? 1 : 0) +
      (Object.keys(selectedCategory).length > 0 ? 1 : 0) +
      (Object.keys(selectedSubCategory).length > 0 ? 1 : 0) +
      (Object.keys(selectedByDate).length > 0 ? 1 : 0);
    setSelectedFilter(count);
  };

  useEffect(() => {
    updateAppliedFilterCount();
  }, [
    selectedSortBy,
    selectedType,
    selectedCategory,
    selectedSubCategory,
    selectedByDate,
  ]);

  // on esc key press  this event will call
  const closeModalOnEsc = (e) => {
    if (
      e.code === "Escape" &&
      filterRef.current &&
      isFilter &&
      !filterRef.current.contains(e.target)
    ) {
      setFilter(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", closeModalOnEsc);
    }
  };
  document.addEventListener("keydown", closeModalOnEsc);

  // on outside click of dropdown this event will call
  useEffect(() => {
    const closeDropDownOnOutSideClick = (e) => {
      if (
        subCatRef.current &&
        isSubCat &&
        !subCatRef.current.contains(e.target)
      ) {
        setSubCat(false);
      }
    };

    document.addEventListener("mousedown", closeDropDownOnOutSideClick);
    return () => {
      document.removeEventListener("mousedown", closeDropDownOnOutSideClick);
    };
  }, [isSubCat]);

  return (
    <>
      {access2 ||
        (localStorage.getItem("remindmetomorrowcensus") &&
          moment(
            localStorage.getItem("remindmetomorrowcensus"),
            "YYYY-MM-DD"
          ).diff(moment().format("YYYY-MM-DD"), "days") === 0 &&
          localStorage.getItem("dontshowcensuspopup") === "false" &&
          (access || access1)) ||
        ((localStorage.getItem("remindmetomorrowcensus") === null ||
          localStorage.getItem("remindmetomorrowcensus") === undefined) &&
          (localStorage.getItem("dontshowcensuspopup") === "false" ||
            localStorage.getItem("dontshowcensuspopup") === null ||
            localStorage.getItem("dontshowcensuspopup") === undefined) &&
          (access || access1)) ? (
        <CensusDataModal
          access={access}
          setAccess={setAccess}
          access1={access1}
          setAccess1={setAccess1}
          access2={access2}
          setAccess2={setAccess2}
        />
      ) : (
        <div className="parters-content-pg-main">
          <div className="parters-header-main">
            <div className="parters-header-main-top">
              <h2>MDS Partners</h2>
              <div
                className="partner-list-search-suggestion"
                onBlur={() => setSuggestionBox(false)}
              >
                <SearchField
                  value={searchText}
                  isRounded
                  placeholder="Search"
                  ref={searchInput}
                  handleChange={handleSearch}
                  onBlur={() => {
                    setSuggestionBox(false);
                  }}
                  handleKeyDown={(e) => {
                    if (e.target.value === "" || e.target.value.match(/^\S/)) {
                      if (e.key === "Enter") {
                        if (e.target.value === "") {
                          setPartnerList([]);
                          setPerkList([]);
                        } else {
                          onPartnerSelection(searchText);
                        }
                        setSearchedKeyword(e.target.value);
                        history(
                          `/partners/all-partners?type=${pageType}&&cat=${category !== "" ? category : "all"
                          }&&search=${e.target.value}&&sortby=${sortBy !== "all" ? sortBy : "all"
                          }&&badge=${badgeFromUrl !== undefined &&
                            badgeFromUrl !== null &&
                            badgeFromUrl !== "all"
                            ? badgeFromUrl
                            : "all"
                          }`
                        );
                      } else if (e.key === "ArrowDown") {
                        const listLength =
                          suggestionList.length > 15
                            ? 15
                            : suggestionList.length + searchHistory.length;
                        if (listIndex < listLength - 1) {
                          setListIndex(listIndex + 1);
                        } else if (listIndex === listLength - 1) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        }
                      } else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        const listLength =
                          suggestionList.length > 15
                            ? 15
                            : suggestionList.length + searchHistory.length;
                        if (listIndex === 0) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        } else if (listIndex === -1) {
                          setListIndex(listLength - 1);
                        } else {
                          setListIndex(listIndex - 1);
                        }
                      }
                    }
                  }}
                  handleFocus={(e) => {
                    authService
                      .getPartnerSearchHistory(`?filter=${listingtype}`)
                      .then((historyRes) => {
                        if (historyRes.data.status) {
                          const data = historyRes.data.data;
                          setSearchHistoryList(data);
                          setSearchHistory(data);
                        } else {
                          setSearchHistoryList([]);
                          setSearchHistory([]);
                        }
                      });
                    setSuggestionBox(true);
                    setListIndex(-1);
                    handleSearch(e, searchText);
                  }}
                  handleClear={() => {
                    setSearchText("");
                    history(
                      `/partners/all-partners?type=${pageType}&&cat=${category !== "" ? category : "all"
                      }&&search=&&sortby=${sortBy !== "all" ? sortBy : "all"
                      }&&badge=${badgeFromUrl !== undefined &&
                        badgeFromUrl !== null &&
                        badgeFromUrl !== "all"
                        ? badgeFromUrl
                        : "all"
                      }`
                    );
                    tagFromUrl !== null && setPartnerList([]);
                    setPerkList([]);
                    onPartnerSelection("");
                  }}
                />
                {suggestionList.length + searchHistory.length > 0 ? (
                  <div
                    className="cstm-cl-search-sug-common"
                    style={
                      suggestionBox ? { display: "block" } : { display: "none" }
                    }
                  >
                    <ul id="suggestion-list" ref={dropdownRef}>
                      {searchHistory.map((data, index) => {
                        return (
                          <li
                            key={index}
                            id={data._id}
                            className={
                              listIndex === index ? " active-list" : ""
                            }
                          >
                            <div className="cstm-cl-search-sug-history-common">
                              <img
                                src={partnerSearchHistoryIcon}
                                className="historty-ptn-icon"
                              />
                              <div
                                className="cstm-cl-search-sug-history-title-common"
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                  history(
                                    `/partners/all-partners?type=${pageType}&&cat=${category !== "" ? category : "all"
                                    }&&search=${data.name}&&sortby=${sortBy !== "all" ? sortBy : "all"
                                    }&&badge=${badgeFromUrl !== undefined &&
                                      badgeFromUrl !== null &&
                                      badgeFromUrl !== "all"
                                      ? badgeFromUrl
                                      : "all"
                                    }`
                                  );
                                  setSearchText(data.name);
                                  onPartnerSelection(data.name);
                                }}
                              >
                                {searchText !== null &&
                                  searchText.length > 0 &&
                                  data.name
                                    .toLowerCase()
                                    .startsWith(searchText.toLowerCase()) ? (
                                  <>
                                    {data.name.slice(0, searchText.length)}
                                    <b>
                                      {data.name.slice(
                                        searchText.length,
                                        data.name.length
                                      )}
                                    </b>{" "}
                                  </>
                                ) : (
                                  data.name
                                )}
                              </div>
                              <button
                                className="cstm-cl-search-sug-history-remove-common"
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                  removeHistory(data._id);
                                }}
                              >
                                Delete
                              </button>
                            </div>
                          </li>
                        );
                      })}
                      {suggestionList
                        .slice(0, 15 - searchHistory.length)
                        .map((title, index) => {
                          return (
                            <li
                              key={index + searchHistory.length}
                              className={
                                listIndex === index + searchHistory.length
                                  ? " active-list"
                                  : ""
                              }
                            >
                              <div className="cstm-cl-search-sug-history-common">
                                <img
                                  src={partnerSearchIcon}
                                  className="search-ptn-icon"
                                />
                                <div
                                  className="cstm-cl-search-sug-history-title-common"
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                    history(
                                      `/partners/all-partners?type=${pageType}&&cat=${category !== "" ? category : "all"
                                      }&&search=${title.companyName}&&sortby=${sortBy !== "all" ? sortBy : "all"
                                      }&&badge=${badgeFromUrl !== undefined &&
                                        badgeFromUrl !== null &&
                                        badgeFromUrl !== "all"
                                        ? badgeFromUrl
                                        : "all"
                                      }`
                                    );
                                    setSearchText(title.companyName);
                                    onPartnerSelection(title.companyName);
                                  }}
                                >
                                  {title.companyName}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="parters-header-main-bottom">
              <div className="parters-header-main-bottom-left">
                <FilterIcon
                  handleClick={() => setFilter(true)}
                  label={selectedFilter}
                  hasText
                  filterText="Filters"
                  icon={<FilterIconAlt />}
                  hasIcon
                />

                <div className="doc-management-chips">
                  <Chips
                    className="large"
                    label="Offers"
                    handleClick={() => {
                      onClickOffer("offer");
                      handleClearFilter();
                    }}
                    isActive={pageType === "offer"}
                  />
                  <Chips
                    className="large"
                    label="Perks"
                    handleClick={() => {
                      onClickOffer("perk");
                      handleClearFilter();
                    }}
                    isActive={pageType === "perk"}
                  />
                </div>

                <div className="cstm-all-categories-list">
                  <div className="cstm-drop-part">
                    <span
                      className={`cstm-drop-label ${showCategoryList ? "cstm-drop-up-arrow" : ""
                        }`}
                      onClick={(e) => {
                        setShowCategoryList(!showCategoryList);
                        e.stopPropagation();
                      }}
                    >
                      {category
                        ? allCategoriesList.filter((v) => v._id === category)[0]
                          ?.name ?? ""
                        : "All categories"}
                    </span>
                    {showCategoryList && (
                      <>
                        <ul className="cstm-drop-list">
                          {allCategoriesList.map((categoryData) => {
                            return (
                              <li
                                value={categoryData._id}
                                id={categoryData._id}
                                className={`li-item-dropdown ${category === categoryData._id &&
                                  "dropdown-active"
                                  }`}
                                onClick={(e) => {
                                  if (
                                    selectedCategory &&
                                    (Object.keys(selectedCategory).length ===
                                      0 ||
                                      (Object.keys(selectedCategory).length >
                                        0 &&
                                        categoryData._id.toString() !==
                                        selectedCategory._id.toString()))
                                  ) {
                                    e.stopPropagation();
                                    handleCategoryFilter(categoryData);
                                    setPartnerList([]);
                                    setPerkList([]);
                                    setCategory(e.target.id);
                                    history(
                                      `/partners/all-partners?type=${pageType}&&cat=${e.target.id
                                      }&&search=${searchedKeyword}&&sortby=${sortBy !== "" ? sortBy : "all"
                                      }&&badge=${badgeFromUrl !== undefined &&
                                        badgeFromUrl !== null &&
                                        badgeFromUrl !== "all"
                                        ? badgeFromUrl
                                        : "all"
                                      }`
                                    );
                                    setShowCategoryList(false);
                                  }
                                }}
                              >
                                {categoryData.name}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
                {selectedCategory.subcategory !== undefined &&
                  selectedCategory.subcategory.length > 0 && (
                    <div
                      className="cstm-all-categories-list"
                      onBlur={() => setSubCat(false)}
                      ref={subCatRef}
                    >
                      <DropDown
                        label=""
                        selected={selectedSubCategory.name}
                        onOpen={() => setSubCat(true)}
                      />
                      {isSubCat && (
                        <DropDownItem
                          options={selectedCategory.subcategory}
                          onSelect={(data) => {
                            if (
                              Object.keys(selectedSubCategory).length === 0 ||
                              (Object.keys(selectedSubCategory).length > 0 &&
                                data._id.toString() !==
                                selectedSubCategory._id.toString())
                            ) {
                              setSubCat(false);
                              handleSubCategoryFilter(data);
                              setPartnerList([]);
                              setPerkList([]);
                              history(
                                `/partners/all-partners?type=${pageType}&&cat=${selectedCategory._id !== undefined &&
                                  selectedCategory._id !== null
                                  ? selectedCategory._id
                                  : "all"
                                }&&search=${searchedKeyword}&&sortby=${selectedSortBy && selectedSortBy.id
                                  ? selectedSortBy.id
                                  : "relevant"
                                }&&badge=${selectedType._id !== undefined &&
                                  selectedType._id !== null
                                  ? selectedType._id
                                  : "all"
                                }${Object.keys(data).length > 0
                                  ? `&&subcat=${data._id}`
                                  : ""
                                }${Object.keys(selectedByDate).length > 0
                                  ? `&&datefilter=${selectedByDate.id}`
                                  : ""
                                }`
                              );
                            }
                          }}
                          selectedItem={selectedSubCategory}
                        />
                      )}
                    </div>
                  )}
              </div>
              <div className="parters-header-main-bottom-right">
                {/* <div className="mds-partners-head-right-sort">
                  <h6>Sort by</h6>
                  <div className="cstm-drop-part">
                    <span
                      className={`cstm-drop-label ${showCategorySortingList ? "cstm-drop-up-arrow" : ""
                        }`}
                      onClick={(e) => {
                        setShowCategorySortingList(!showCategorySortingList);
                        e.stopPropagation();
                      }}
                    >
                      {sortingKeyValueList[sortBy] != undefined
                        ? sortingKeyValueList[sortBy]
                        : partnerType !== undefined &&
                          partnerType !== null &&
                          partnerType !== "" &&
                          partnerType
                          ? partnerType
                          : "Default"}
                    </span>
                    {showCategorySortingList && (
                      <>
                        <ul className="cstm-drop-list">
                          <li
                            id="select"
                            onClick={(e) => {
                              history(
                                `/partners/all-partners?type=${pageType}&&cat=${category}&&search=${searchedKeyword}&&sortby=all&&badge=all`
                              );
                              setPartnerList([]);
                              setPerkList([]);
                            }}
                          >
                            Default
                          </li>
                          <li id="newest" onClick={onSortingSelection}>
                            Newest
                          </li>
                          <li id="value" onClick={onSortingSelection}>
                          Value
                        </li>
                          <li id="rating" onClick={onSortingSelection}>
                            Rating
                          </li>
                          {listingtype !== "perk" &&
                            partnerBadges.length > 0 &&
                            partnerBadges.map((badge) => (
                              <li id={badge._id} onClick={onBadgeSelection}>
                                {badge.name}
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {loader ? (
            <div class="mds-partners-cont-loading">
              {[...Array(10)].map((s) => {
                return (
                  <div className="mds-partners-cont-post-loading">
                    <div
                      className={`${pageType === "offer"
                          ? "mds-partners-cont-post-img-loading skeleton-box"
                          : "mds-partners-cont-post-img2-loading skeleton-box"
                        }`}
                    ></div>
                    <div className="mds-partners-cont-post-title-loading skeleton-box"></div>
                    <div className="cstm-partnesh5-loading skeleton-box"></div>
                    <div className="cstm-partnesh6-loading-loading skeleton-box"></div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={`mds-partners-cont `}>
              {!loader &&
                (partnerList.length || perklist.length ? (
                  (listingtype !== "perk" ? partnerList : perklist).map(
                    (pat, index, array) => {
                      if (
                        (index === partnerList.length - 1 &&
                          index !== totalRecords - 1) ||
                        (index === perklist.length - 1 &&
                          index !== totalRecordsPerk - 1)
                      ) {
                        return (
                          <InView
                            onChange={(inView, entry) => {
                              if (inView) fetchMoreData();
                            }}
                          >
                            <>
                              {listingtype !== "perk" ? (
                                <PartnerOfferCard
                                  onClick={handleRestriction}
                                  reviewCount={reviewCount}
                                  item={pat}
                                />
                              ) : (
                                <PartnerPerkCard
                                  onClick={handleRestriction}
                                  reviewCount={reviewCount}
                                  item={pat}
                                />
                              )}
                            </>
                          </InView>
                        );
                      } else {
                        return listingtype !== "perk" ? (
                          <PartnerOfferCard
                            onClick={handleRestriction}
                            reviewCount={reviewCount}
                            item={pat}
                          />
                        ) : (
                          <PartnerPerkCard
                            onClick={handleRestriction}
                            reviewCount={reviewCount}
                            item={pat}
                          />
                        );
                      }
                    }
                  )
                ) : (
                  <></>
                ))}
              {listingtype === "offer" && noData && (
                <div className="access-expired-page no-data-fp">
                  <div className="access-expires-page-icon">
                    <IconSearchNoResults />
                  </div>
                  <div class="access-expires-page-cont">
                    <h4 className="access-expires-page-title">
                      {searchText !== ""
                        ? "Oops! No results this time"
                        : "No offers yet, but we're on it!"}
                    </h4>
                    <p className="access-expires-page-p">
                      {searchText !== ""
                        ? "Please adjust your searching filters and give it another go!"
                        : "We're preparing some exciting offers for you. Check back shortly!"}
                    </p>
                  </div>
                  {searchText !== "" && (
                    <a
                      onClick={() => {
                        handleClearFilter();
                        history(
                          `/partners/all-partners?type=${listingtype}&&cat=all&&search=&&sortby=all&&badge=all`
                        );
                        setNoData(false);
                        setPartnerList([]);
                        setPerkList([]);
                        setSearchText("");
                        setCategory("");
                      }}
                      class="clear-all-filters-btn"
                    >
                      Clear all filters
                    </a>
                  )}
                </div>
              )}
              {listingtype === "perk" && noPerkData && (
                <>
                  <div className="access-expired-page no-data-fp">
                    <div className="access-expires-page-icon">
                      <IconSearchNoResults />
                    </div>
                    <div class="access-expires-page-cont">
                      <h4 className="access-expires-page-title">
                        {searchText !== ""
                          ? "Oops! No results this time"
                          : "No perks yet, but we're on it!"}
                      </h4>
                      <p className="access-expires-page-p">
                        {searchText !== ""
                          ? "Please adjust your searching filters and give it another go!"
                          : "We're preparing some exciting perks for you. Check back shortly!"}
                      </p>
                    </div>
                    {searchText !== "" && (
                      <a
                        onClick={() => {
                          handleClearFilter();
                          history(
                            `/partners/all-partners?type=${pageType}&&cat=all&&search=&&sortby=all&&badge=all`
                          );
                          setNoData(false);
                          setPartnerList([]);
                          setPerkList([]);
                          setSearchText("");
                          setCategory("");
                        }}
                        class="clear-all-filters-btn"
                      >
                        Clear all filters
                      </a>
                    )}
                  </div>
                </>
              )}
              {fetchMoreLoader &&
                [...Array(10)].map((s) => {
                  return (
                    <div className="mds-partners-cont-post-loading">
                      <div
                        className={`${pageType === "offer"
                            ? "mds-partners-cont-post-img-loading skeleton-box"
                            : "mds-partners-cont-post-img2-loading skeleton-box"
                          }`}
                      ></div>
                      <div className="mds-partners-cont-post-title-loading skeleton-box"></div>
                      <div className="cstm-partnesh5-loading skeleton-box"></div>
                      <div className="cstm-partnesh6-loading-loading skeleton-box"></div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
      <div
        className={`${isFilter ? "modal-fadein" : "modal-fadeout"}`}
        ref={filterRef}
      >
        <div className="desk-globle-model-box globle-model-box ">
          <FilterSidebarPartner
            handleClearFilter={handleClearFilter}
            handleToDateChange={handleToDateChange}
            handleFromDateChange={handleFromDateChange}
            fromDate={fromDate}
            toDate={toDate}
            handleSubCategoryFilter={handleSubCategoryFilter}
            selectedSubCategory={selectedSubCategory}
            handleShowResult={handleShowResult}
            totalCount={totalCount}
            dateList={dateList}
            selectedByDate={selectedByDate}
            handleDateFilter={handleDateFilter}
            handleSortByFilter={handleSortByFilter}
            handleTypeFilter={handleTypeFilter}
            selectedSortBy={selectedSortBy}
            selectedType={selectedType}
            handleCategoryFilter={handleCategoryFilter}
            isFilter={isFilter}
            selectedCategory={selectedCategory}
            sortByList={sortByList}
            allCategoriesList={allCategoriesList}
            partnerBadges={partnerBadges}
            onCancel={() => setFilter(false)}
          />
        </div>
      </div>
    </>
  );
};

export default Partners;
