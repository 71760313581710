import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Tooltip } from '@mui/material';
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";
import { InView } from "react-intersection-observer";
//Components
import MediaCropPopup from "./MediaCropPopup";
import authService from "../Services/auth.service";
import authAdminHeader from "../Services/auth.admin.header";

// Images
import ClosePopup from "../Images/close-popup.svg";
import IcnMCopy from "../Images/icn_m_copy.svg";
import CloseGmv from "../Images/CloseGmv.svg";
import WFImagePlaceholder from "../Images/WF-Image-Placeholder.svg";
import SearchNoResults from "../Images/search_no_results.svg";
import Loader from "../Images/loader.gif";
import VideoIconWhite from "../Images/video-icon-white.svg";
import uploadIcon from "../Images/icn_m_upload.svg";

export default function mediaUploadCommon({ type, buttonType, cropType, setCroppedImageUrl }) {
  const API_URL = process.env.REACT_APP_API_URL;
  // const API_URL = "http://localhost:3002/api/";
  const [pagination, setPagination] = useState(true);
  const [showFilterData, setFilterData] = useState([]);
  const [showAllMedia, setAllMedia] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [pageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [selectedMediaCategory, setSelectedMediaCategory] = useState([]);
  const [showCategoryOption, setCategoryOption] = useState(false);
  const [showCategorySelected, setCategorySelected] = useState([]);
  const [showCategory, setCategory] = useState(false);
  const [showCategoryOptionDisplay, setCategoryOptionDisplay] =
    useState("All categories");
  const [selectedItems, setSelectedItems] = useState([]);
  const [loader, setLoader] = useState(false);
  const [copyUrlLoader, setcopyUrlLoader] = useState();
  const [formData, setFormData] = useState({
    altText: "",
    title: "",
    categories: [],
    description: "",
  });
  const [showDeleteMessage, setDeleteMessage] = useState("")
  const [showSaveLoader, setSaveLoader] = useState(false);
  const [shwoDeleteLoader, setDeleteLoader] = useState(false)
  const [showSaveMessage, setSaveMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [showMediaDisplaySection, setMediaDisplaySection] = useState(false);
  const [showUploadMessage, setUploadMessage] = useState("")
  const [showPartialUploadImageArray, setPartialUploadImageArray] =
    useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadProgressNew, setUploadProgressNew] = useState({});


  useEffect(() => {
    setLoader(true);
    getMediaDataAPI(1);
  }, [searchInput, selectedCategory]);

  useEffect(() => {
    console.log(type, "typeData UseEffect")
    setLoader(true);
    getMediaDataAPI(1);
    getCategoryAPI();
    setFilterData([]);
    setSelectedItems([])
  }, [type]);

  async function getCategoryAPI() {
    try {
      const response = await authService.getCategoryMediaDetails();
      if (response.data.status) {
        setSelectedMediaCategory(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching media data:", error);
    }
  }

  async function getMediaDataAPI(pageNumber) {
    try {
      const queryParams = `filter=${type}&category=${selectedCategory}&sortBy=Newest&page=${pageNumber}&pageSize=${pageSize}`;
      const response = await authService.getMediaDetails(queryParams);
      let ReturnData
      if (response.data.status) {
        console.log(uploadedImages.length, "uploadedImages.length")
        ReturnData = response.data.data.items;
        // if (uploadedImages.length === 0) {
        //   ReturnData = response.data.data.items.filter((data) => {
        //     return !data.isProgress && !data.isError
        //   })
        // } else {
        //   ReturnData = response.data.data.items;
        // }
        if (pageNumber === 1) {
          console.log(ReturnData, "ReturnData")
          filterMediaData(ReturnData);
        } else {
          filterMediaData([...showAllMedia, ...ReturnData]);
        }
        setPagination(true);
        setLoading(false);
        setTimeout(() => {
          setLoader(false);
        }, 200);
      } else if (response.data.data.length === 0) {
        setPagination(false);
        setLoading(false);
        setTimeout(() => {
          setLoader(false);
        }, 200);
      }
    } catch (error) {
      console.error("Error fetching media data:", error);
    }
  }

  async function getMediaProgressFun(idsData) {
    try {
      const queryParams = {
        ids: idsData.map((data) => {
          return data._id
        })
      };
      console.log(queryParams, "queryParams");
      const response = await authService.getMediaProgress(queryParams);
      console.log(response, "response  Data");
      const inProgressData = response.data.data.filter((data) => {
        return data.isProgress;
      })

      const inProgressCompletedData = response.data.data.filter((data) => {
        return !data.isProgress;
      })
      if (inProgressCompletedData.length > 0) {
        getMediaDataAPI(1)
      }
      if (inProgressData.length > 0) {
        console.log(response.data.data, "getMediaProgressFun");
        getMediaProgressFun(inProgressData)
      } else if (response.data.data.length === 0) {
        setUploadMessage("");
      }
    } catch (error) {
      console.error("Error fetching media data:", error);
    }
  }

  const filterMediaData = (data) => {
    let filteredData = data;

    if (searchInput.trim() !== "") {
      const searchTerm = searchInput.toLowerCase();
      filteredData = filteredData.filter((item) =>
        item.title.toLowerCase().includes(searchTerm)
      );
    }
    setAllMedia(filteredData);
  };

  const onClickCategoryRemove = (categoryId) => {
    console.log("remove calling")
    console.log("categoryId to remove:", categoryId);
    setCategorySelected((prev) =>
      prev.filter((category) => {
        console.log("category.id:", category.id);
        return category.id !== categoryId;
      })
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      categories: prevFormData.categories.filter((category) => category.id !== categoryId),
    }));
  };


  const handleImageClick = (id, e,) => {
    setMediaDisplaySection(false)
    const isChecked = e.target.checked;
    let filterData = showAllMedia.filter((data) => {
      return data._id === id;
    });
    let alreadyIdExist = showFilterData.filter((data) => {
      return data._id === id;
    });

    if (buttonType === "single") {
      // Image type-specific logic
      if (isChecked) {
        // Single select logic
        setSelectedItems([id]);
        setFilterData(filterData);
        setFormData({
          altText: filterData[0].altText || "",
          title: filterData[0].title || "",
          categories: filterData[0].category || [],
          description: filterData[0].description || "",
        });
      } else {
        // Deselect logic
        setSelectedItems([]);
        setFilterData([]);
        setFormData({});
      }
    } else if (buttonType === "multiple") {
      // Video type-specific logic
      if (isChecked) {
        // Multi-select logic
        setSelectedItems((prev) => [...prev, id]);
        setFilterData([...showFilterData, ...filterData]);
        setFormData({
          altText: filterData[0].altText || "",
          title: filterData[0].title || "",
          categories: filterData[0].category || [],
          description: filterData[0].description || "",
        });
      } else {
        // Deselect logic
        setSelectedItems((prev) => prev.filter((item) => item !== id));
        let removeId = showFilterData.filter((data) => {
          return data._id !== id;
        });
        if (removeId && removeId.length) {
          setFormData({
            altText: removeId[0].altText || "",
            title: removeId[0].title || "",
            categories: removeId[0].category || [],
            description: removeId[0].description || "",
          });
          setFilterData([...removeId]);
        } else {
          setFormData({});
          setFilterData([]);
        }
      }
    }
  };


  const onClickCategory = (category, categoryName) => {
    setCategory(!showCategory);
    if (category !== "All categories") {
      setSelectedCategory(category);
      setCategoryOptionDisplay(categoryName);
      setCurrentPage(1);
      setAllMedia([]);
    } else {
      setCategoryOptionDisplay(category);
      setSelectedCategory([]);
      setCurrentPage(1);
      setAllMedia([]);
    }
  };

  const handleCategoryClick = () => {
    setCategoryOption(!showCategoryOption);
  };

  const handleCategoryTopClick = () => {
    setCategory(!showCategory);
  };

  const onClickChooseImage = () => {
    $("#chooseMediaModel").modal("hide");
    setFilterData([]);
    setSelectedItems([]);
    console.log("Selected Data: ", showFilterData)
  };

  const onClickChooseImageMulti = () => {
    $("#chooseMediaModel").modal("hide");
    // setFilterData([]);
    // setSelectedItems([]);
    console.log("Selected Data: ", showFilterData)
  };


  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const onCopyUrl = (url, data) => {
    setcopyUrlLoader(data)
    console.log(url, "url");
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setcopyUrlLoader();
    }, 2000);
  };


  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const sizeInFormattedUnits = (bytes / Math.pow(k, i)).toFixed(
      i === 0 ? 0 : 1
    );

    return `${sizeInFormattedUnits} ${sizes[i]}`;
  };
  const fetchMoreData = (pageNumber) => {
    setLoading(true);
    getMediaDataAPI(pageNumber);
  }

  setTimeout(() => {
    setDeleteMessage("");
    setSaveMessage("");
  }, 2000);


  const onSaveFormData = async (id, type) => {
    setSaveLoader(true)
    const bodyData = {
      id: id,
      title: formData.title,
      category: formData.categories.map((cat) => cat.id),
      description: formData.description,
      altText: formData.altText,
    };
    // Perform the save operation using bodyData
    console.log(bodyData, "bodyData");

    const response = await authService.editMediaDetails(bodyData);
    console.log(response, "response")
    if (response.data.status) {
      setSaveMessage(`${type} data saved successfully`)
      setSaveLoader(false)
      getMediaDataAPI(1)
    } else {
      setSaveMessage(`${type} data not save.please try again`)
      setSaveLoader(false)
    }
  };

  const onAltTextChange = (value) => {
    setFormData((prev) => ({ ...prev, altText: value }));
  };

  const onTitleChange = (value) => {
    setFormData((prev) => ({ ...prev, title: value }));
  };

  const onClickCategoryAdd = (categoryId, categoryName) => {
    if (!showCategorySelected.some((category) => category.id === categoryId)) {
      setCategorySelected((prev) => [
        ...prev,
        { id: categoryId, name: categoryName },
      ]);

      setFormData((prev) => ({
        ...prev,
        categories: [...prev.categories, { id: categoryId, name: categoryName }],
      }));
    }
    setCategoryOption(!showCategoryOption);
  };

  const onDescriptionChange = (value) => {
    setFormData((prev) => ({ ...prev, description: value }));
  };


  const onClickDeleteImage = async (id, type) => {
    setDeleteLoader(true)
    console.log(typeof id, "od")
    const body = {
      ids: typeof id === "string" ? [id] : id
    }
    const response = await authService.deleteMedia(body);
    console.log(response, "response")
    if (response.data.status) {
      getMediaDataAPI(1)
      setFilterData([])
      setSelectedItems([])
      setDeleteLoader(false)
      setDeleteMessage(`${type} deleted successfully`)
    }
  }

  const resetFilterData = () => {
    setFilterData([])
    setSelectedItems([])
  }

  const onDragEnter = () => {
    setIsDragOver(true);
  };

  const onDragLeave = () => {
    setIsDragOver(false);
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {

      const MAX_ALLOWED_SIZE = 2 * 1024 * 1024 * 1024;

      const isSizeExceeded = acceptedFiles.some(
        (file) => file.size > MAX_ALLOWED_SIZE
      );
  
      if (isSizeExceeded) {
        setUploadMedia(false);
        setPartialUploadImageArray(true);
        setMediaDisplaySection(true);
        setResponseMessage("One or more files exceed the maximum allowed size (2GB).");
        setTimeout(() => {
          setResponseMessage("");
        }, 2000);
        return;
      }
      
      if (acceptedFiles.length > 20) {
        setMediaDisplaySection(false);
        setUploadMessage("Please upload only 20 images or video");
        return;
      }

      // Create FormData for sending files
      const formData = new FormData();
      const uploadedImagesTempArray = [];

      acceptedFiles.forEach((file) => {
        formData.append("files", file);

        if (file.type.startsWith("image/")) {

          const reader = new FileReader();
          reader.onload = async () => {
            const preview = reader.result;
            uploadedImagesTempArray.push({
              name: file.name.replace(/\s/g, "_"),
              preview: preview,
              file: file,
            });
          };

          reader.readAsDataURL(file);
        } else {
          uploadedImagesTempArray.push({
            name: file.name.replace(/\s/g, "_"),
            file: file,
          });
        }
      })

      setPartialUploadImageArray(true);

      setUploadedImages(uploadedImagesTempArray);
      setMediaDisplaySection(false);
      setIsDragOver(false);

      try {
        // Send images to the backend using your API endpoint
        const response = await axios.post(
          API_URL + `media/upload`,
          formData,
          {
            headers: {
              ...authAdminHeader(),
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              // Calculate progress percentage
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              console.log('Progress Event:', progressEvent);
              console.log('Percent Completed:', percentCompleted);

              // Update the progress for each file
              acceptedFiles.forEach((file) => {
                console.log('Processing File:', file.name);
                setUploadProgressNew((prevProgress) => {
                  console.log('Previous Progress:', prevProgress);
                  return {
                    ...prevProgress,
                    [file.name.replace(/\s/g, "_")]: percentCompleted,
                  };
                });
              });
            },
          }
        );

        // Handle successful upload
        console.log(response.data, "RESPONSE PESEE");
        getMediaDataAPI(1);
        getMediaProgressFun(response.data.data);
        setPartialUploadImageArray(false);
        setUploadMessage("Media uploaded successfully");
        setTimeout(() => {
          setUploadMessage("");
        }, 2000);

      } catch (error) {
        // Handle error
        console.error("Error uploading images:", error);
        setUploadMessage(error.message);
        setPartialUploadImageArray(false);
        setMediaDisplaySection(false);
        setTimeout(() => {
          setUploadMessage("");
        }, 2000);
      }
    },
    [getMediaDataAPI]
  );

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDragEnter: () => onDragEnter(),
    onDragLeave: () => onDragLeave(),
    onDrop: (acceptedFiles) => onDrop(acceptedFiles),
  });


  const handleImageRetry = async (retryImageData) => {
    console.log("retyrnImage callimng")
    try {
      if (retryImageData !== undefined) {
        setPartialUploadImageArray(false);

        console.log(retryImageData, "retryImageData")
        // Implement logic to retry uploading the image
        const formData = new FormData();
        formData.append("files", retryImageData.file);

        const response = await axios.post(API_URL + `media/upload`, formData, {
          headers: authAdminHeader(),
          onUploadProgress: (progressEvent) => {
            // Calculate progress percentage
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            // Update the progress for the retry image
            setUploadProgressNew((prevProgress) => {
              const newProgress = { ...prevProgress };
              delete newProgress[retryImageData.name];
              return { ...newProgress, [retryImageData.name]: Math.round((progressEvent.loaded * 100) / progressEvent.total) };
            });
          },
        });
        getMediaDataAPI(1);
        getMediaProgressFun(response.data.data);
        setUploadMessage("Media uploaded successfully");
      }
    } catch (error) {
      // Handle error
      console.error("Error retrying image upload:", error);
      setUploadMessage("");
    }
  };

  const onClickUploadMedia = () => {
    setMediaDisplaySection(!showMediaDisplaySection)
    setCategory(false);
    setSelectedCategory("");
    setCategoryOptionDisplay("All categories");
    setFilterData([]);
    setSelectedItems([]);
  }

  return (
    <>
      <div
        className="modal fade media-attachment-details"
        id="chooseMediaModel"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="media-attachment-details-header">
              <h4 className="madh-h4">Attachment details</h4>
              <button
                type="button"
                className="madh-close"
                aria-label="Close"
                onClick={(e) => {
                  setCategory(false);
                  setSelectedCategory("");
                  setCategoryOptionDisplay("All categories");
                  setFilterData([]);
                  setSelectedItems([]);
                  $("#chooseMediaModel").modal("hide");
                }}
              >
                <img src={ClosePopup} />
              </button>
            </div>



            <div className="media-attachment-details-body">
              {showDeleteMessage && (
                <div className="media-delete-message-alert">{showDeleteMessage}</div>
              )}

              {showSaveMessage && (
                <div className="media-delete-message-alert">{showSaveMessage}</div>
              )}

              {showUploadMessage && (
                <div className="media-delete-message-alert">{showUploadMessage}</div>
              )}
              {loader ? (
                <div className="attachement-media-data-loading">
                  <img src={Loader} alt="Loading..." />
                </div>
              ) : (
                <div className="choose-media-attachment-details-left">
                  <div className="madl-header">
                    <div className="madl-header-left">
                      <button className="upload-media-btn" onClick={() => onClickUploadMedia()}>Upload media</button>
                    </div>
                    <div className="madl-header-right">
                      <div className="upload-media-filler-search">
                        <input
                          className="upload-media-filler-search-input"
                          type="text"
                          placeholder="Search..."
                          value={searchInput}
                          onChange={handleSearchChange}
                        />
                        {/* <img
                          src={IcnMCalendar}
                          className="upload-media-filler-search-icon"
                        /> */}
                      </div>

                      <div className="madl-header-right-all-categories">
                        <div className="upload-media-dropdown">
                          <button
                            className="upload-media-label"
                            onClick={handleCategoryTopClick}
                          >
                            {showCategoryOptionDisplay}
                          </button>
                          {showCategory && (
                            <ul className="upload-media-dropdown-list">
                              <li
                                onClick={() =>
                                  onClickCategory("All categories")
                                }
                              >
                                All
                              </li>
                              {selectedMediaCategory.length > 0 &&
                                selectedMediaCategory.map((data) => (
                                  <li
                                    key={data._id}
                                    onClick={() =>
                                      onClickCategory(data._id, data.name)
                                    }
                                  >
                                    {data.name}
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {showAllMedia.length > 0 && (
                    <div className="madl-body scrollbar">
                      {showMediaDisplaySection &&
                        <div className="upload-media-drop">
                          <div
                            className={`file-uploader-medium ${isDragOver ? "drag-over" : ""
                              }`}
                            {...getRootProps()}
                          >
                            <div className={`file-uploader-medium-body`}>
                              <input {...getInputProps()} />
                              <div className="file-uploader-medium-body-icon">
                                <img src={uploadIcon} />
                              </div>
                              <div className="file-uploader-medium-body-title-text">
                                <h5>Drag and drop your files here</h5>
                                <h6>Acceptable file format is JPG, JPEG, PNG, WEBP, MP4.</h6>
                              </div>
                            </div>
                            <div className="file-uploader-medium-button">
                              <button>Upload</button>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="madl-body-main">
                        {showPartialUploadImageArray && uploadedImages.length > 0 &&
                          uploadedImages.map((image) => (
                            <div
                              className="madl-body-images-videos"
                              key={image.name}
                            >
                              <img
                                src={image.preview}
                                alt="Preview"
                                className="choose-upload-images"
                              />
                              <div className="media-upload-images-loader">
                                <div className="media-upload-images-loader-in">
                                  <span
                                    className="media-upload-images-loader-fill"
                                    style={{
                                      width: uploadProgressNew[image.name] + "%",
                                    }}
                                  >
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        {showAllMedia.length > 0 &&
                          showAllMedia.map((data, index) => {
                            if (index === showAllMedia.length - 1) {
                              return (
                                <InView
                                  onChange={(inView, entry) => {
                                    if (inView && pagination && !loading) {
                                      fetchMoreData(currentPage + 1);
                                      setCurrentPage(currentPage + 1);
                                    };
                                  }}
                                  className={`madl-body-images-videos ${selectedItems.includes(data._id) ? "media-active-select" : ""}`}
                                >
                                  <label>
                                    <input
                                      className="input-checkbox-popup-media"
                                      type="checkbox"
                                      checked={selectedItems.includes(data._id)}
                                      onChange={(e) =>
                                        handleImageClick(data._id, e)
                                      }
                                    />
                                    {data.type === "image" ? (
                                      data.isProgress ? (
                                        data.isError ? (
                                          <>
                                            <span className="compression-loader-img" onClick={() => handleImageRetry(uploadedImages.find(
                                              (uploadedImage) =>
                                                uploadedImage.name === data.title
                                            ))}>
                                              <i className="fa fa-repeat media-reupload-img" aria-hidden="true"></i>
                                            </span>
                                            <img
                                              src={
                                                uploadedImages.find(
                                                  (uploadedImage) =>
                                                    uploadedImage.name === data.title
                                                )?.preview
                                              }
                                              alt={`Preview ${data.title || `Media ${index}`
                                                }`}
                                              className="media-upload-images"
                                            />
                                          </>) :
                                          (<>
                                            <span className="compression-loader-img">
                                              <img src={Loader} alt="Loading..." />
                                            </span>
                                            <img
                                              src={
                                                uploadedImages.find(
                                                  (uploadedImage) =>
                                                    uploadedImage.name === data.title
                                                )?.preview
                                              }
                                              alt={`Preview ${data.title || `Media ${index}`
                                                }`}
                                              className="media-upload-images"
                                            // data-toggle="modal"
                                            // data-target="#mediaModel"
                                            // onClick={(e) => handleImageClick(data._id, e)}
                                            /></>
                                          )
                                      ) :
                                        <img
                                          className="choose-upload-images"
                                          src={data.urls[0]?.url}
                                          alt={data.title || `data ${index}`}
                                        />
                                    ) : data.type === "video" ? (
                                      data.isProgress ? (
                                        data.isError ? (
                                          <span className="compression-loader-img" onClick={() => handleImageRetry(uploadedImages.find(
                                            (uploadedImage) =>
                                              uploadedImage.name === data.title
                                          ))}>
                                            <i class="fa fa-repeat media-reupload-img" aria-hidden="true"></i>
                                          </span>
                                        ) : (
                                          <>
                                            <span className="compression-loader-img" >
                                              <img src={Loader} alt="Loading..." />
                                            </span>
                                            <img
                                              src={data.videoThumbnailUrl}
                                              alt={`Preview ${data.title || `Media ${index}`
                                                }`}
                                              className="media-upload-images"
                                            />
                                            <img
                                              src={data.videoThumbnailUrl}
                                              alt={`Preview ${data.title || `Media ${index}`
                                                }`}
                                              className="media-upload-images"
                                            />
                                            <div className="media-upload-videos-loader">
                                              <img
                                                src={VideoIconWhite}
                                                alt="Video Icon"
                                              />
                                            </div>
                                          </>
                                        )
                                      ) :
                                        <>
                                          <img
                                            src={data.videoThumbnailUrl}
                                            alt={`Preview ${data.title || `Media ${index}`
                                              }`}
                                            className="media-upload-images"
                                          />
                                          <div className="media-upload-videos-loader">
                                            <img
                                              src={VideoIconWhite}
                                              alt="Video Icon"
                                            />
                                          </div>
                                        </>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                </InView>
                              );
                            } else
                              return (
                                // <div className={`madl-body-images-videos ${showFilterData.length > 0 && data._id === showFilterData[0]._id ? "media-active-select" : ""}`} >
                                <div className={`madl-body-images-videos ${selectedItems.includes(data._id) ? "media-active-select" : ""}`}>
                                  <label>
                                    <input
                                      className="input-checkbox-popup-media"
                                      type="checkbox"
                                      checked={selectedItems.includes(data._id)}
                                      onChange={(e) =>
                                        handleImageClick(data._id, e)
                                      }
                                    />
                                    {data.type === "image" ? (
                                      data.isProgress ? (
                                        data.isError ? (
                                          <>
                                            <span className="compression-loader-img" onClick={() => handleImageRetry(uploadedImages.find(
                                              (uploadedImage) =>
                                                uploadedImage.name === data.title
                                            ))}>
                                              <i className="fa fa-repeat media-reupload-img" aria-hidden="true"></i>
                                            </span>
                                            <img
                                              src={
                                                uploadedImages.find(
                                                  (uploadedImage) =>
                                                    uploadedImage.name === data.title
                                                )?.preview
                                              }
                                              alt={`Preview ${data.title || `Media ${index}`
                                                }`}
                                              className="media-upload-images"
                                            />
                                          </>) :
                                          (<>
                                            <span className="compression-loader-img">
                                              <img src={Loader} alt="Loading..." />
                                            </span>
                                            <img
                                              src={
                                                uploadedImages.find(
                                                  (uploadedImage) =>
                                                    uploadedImage.name === data.title
                                                )?.preview
                                              }
                                              alt={`Preview ${data.title || `Media ${index}`
                                                }`}
                                              className="media-upload-images"
                                            // data-toggle="modal"
                                            // data-target="#mediaModel"
                                            // onClick={(e) => handleImageClick(data._id, e)}
                                            /></>
                                          )
                                      ) :
                                        <img
                                          className="choose-upload-images"
                                          src={data.urls[0]?.url}
                                          alt={data.title || `data ${index}`}
                                        />
                                    ) : data.type === "video" ? (
                                      data.isProgress ? (
                                        data.isError ? (
                                          <span className="compression-loader-img" onClick={() => handleImageRetry(uploadedImages.find(
                                            (uploadedImage) =>
                                              uploadedImage.name === data.title
                                          ))}>
                                            <i class="fa fa-repeat media-reupload-img" aria-hidden="true"></i>
                                          </span>
                                        ) : (
                                          <>
                                            <span className="compression-loader-img" >
                                              <img src={Loader} alt="Loading..." />
                                            </span>
                                            <img
                                              src={data.videoThumbnailUrl}
                                              alt={`Preview ${data.title || `Media ${index}`
                                                }`}
                                              className="media-upload-images"
                                            />
                                            <img
                                              src={data.videoThumbnailUrl}
                                              alt={`Preview ${data.title || `Media ${index}`
                                                }`}
                                              className="media-upload-images"
                                            />
                                            <div className="media-upload-videos-loader">
                                              <img
                                                src={VideoIconWhite}
                                                alt="Video Icon"
                                              />
                                            </div>
                                          </>
                                        )
                                      ) :
                                        <>
                                          <img
                                            src={data.videoThumbnailUrl}
                                            alt={`Preview ${data.title || `Media ${index}`
                                              }`}
                                            className="media-upload-images"
                                          />
                                          <div className="media-upload-videos-loader">
                                            <img
                                              src={VideoIconWhite}
                                              alt="Video Icon"
                                            />
                                          </div>
                                        </>
                                    ) : (
                                      ""
                                    )}
                                  </label>
                                </div>
                              );
                          })}

                        {loading &&
                          <>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                            <div className="madl-body-images-videos"><div className="madl-body-images-videos-loading skeleton-box"></div></div>
                          </>
                        }
                      </div>
                    </div>
                  )}
                  {showAllMedia.length === 0 && (
                    <div className="empty-attament-media">
                      <div className="empty-state">
                        <div className="empty-state-icon">
                          <img src={SearchNoResults} />
                        </div>
                        <div className="empty-state-info">
                          <h4 className="empty-state-info-title">
                            Oops! No results this time
                          </h4>
                          <p className="empty-state-info-p">
                            Please adjust your searching Media and give it
                            another go!
                          </p>
                        </div>
                        {/* <button className="empty-state-buttun">
                      Clear all Media
                    </button> */}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {showFilterData.length === 1 && (
                <>
                  <div className="choose-media-attachment-details-right scrollbar">
                    <div className="mad-image-box">
                      <div className="mad-image-box-left">
                        {showFilterData[0].type === "image" ? (
                          <img
                            src={showFilterData[0].urls[0]?.url}
                            alt={showFilterData[0].title}
                            className="media-details-video"
                          />
                        ) : showFilterData[0].type === "video" ? (
                          // <video controls>
                          //   <source
                          //     src={showFilterData[0].urls[0]?.url}
                          //     type="video/mp4"
                          //   />
                          //   Your browser does not support the video tag.
                          // </video>
                          <>
                            <img
                              src={showFilterData[0].videoThumbnailUrl}
                              alt={`Preview ${showFilterData[0].title || `Media`
                                }`}
                              className="media-details-video"
                            />
                            <div className="media-popup-video" >
                              <img src={VideoIconWhite} alt="Video Icon" />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="mad-image-box-right">
                        <h5>{showFilterData[0].title}</h5>
                        <h6>
                          {moment(showFilterData[0].createdAt).format(
                            "DD MMM, YYYY"
                          )}
                        </h6>
                        {/* {showFilterData[0].urls[0]?.size !== undefined && (
                          <h6>
                            {formatBytes(showFilterData[0].urls[0]?.size)}
                          </h6>
                        )} */}

                        {/* {showFilterData[0].type !== "video" ?
                          showFilterData[0].urls[0]?.width !== undefined &&
                          <h6>{showFilterData[0].urls[0]?.width}x{showFilterData[0].urls[0]?.height}px</h6> : showFilterData[0].videoWidth !== undefined &&
                          <h6>{showFilterData[0].videoWidth}x{showFilterData[0].videoHeight}px</h6>
                        } */}

                        {showFilterData[0].type !== "image" && showFilterData[0].videoDuration !== undefined &&
                          <h6> {moment.utc(showFilterData[0].videoDuration * 1000).format('HH:mm:ss')}</h6>}
                      </div>
                    </div>
                    <hr className="mad-hr" />

                    <div className="mad-field-box">
                      <div className="mad-field-box-main">
                        <h6>Alt text</h6>
                        <input
                          className="mad-text-field"
                          type="text"
                          value={formData.altText}
                          onChange={(e) => onAltTextChange(e.target.value)}
                        />
                      </div>

                      <div className="mad-field-box-main">
                        <h6>Title</h6>
                        <input
                          className="mad-text-field"
                          type="text"
                          value={formData.title}
                          onChange={(e) => onTitleChange(e.target.value)}
                        />
                      </div>
                      <div className="mad-field-box-main">
                        <h6>Categories</h6>

                        <div className="categories-select-box">
                          <span
                            onClick={handleCategoryClick}
                            className={`categories-select-label ${showCategoryOption && "categories-up-arrow"}`}
                          >
                            Select
                          </span>
                          {/* Add this className click --> categories-up-arrow */}
                          {showCategoryOption && (
                            <ul className="categories-select-list scrollbar">
                              {selectedMediaCategory.length > 0 &&
                                selectedMediaCategory.map((catData) => (
                                  <li
                                    className=""
                                    key={catData._id}
                                    onClick={() =>
                                      onClickCategoryAdd(
                                        catData._id,
                                        catData.name
                                      )
                                    }
                                  >
                                    {catData.name}
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>
                        {formData.categories.length !== 0 &&
                          <div className="mad-categories">
                            {formData.categories.map((selectData, index) => (
                              <div key={index}>
                                <span className="mad-categories-badge">{selectData.name}
                                  <img
                                    src={CloseGmv}
                                    onClick={() => onClickCategoryRemove(selectData.id)}
                                  />
                                </span>
                              </div>
                            ))}
                          </div>
                        }
                      </div>

                      <div className="mad-field-box-main">
                        <h6>Description</h6>
                        <textarea
                          className="mad-text-textarea"
                          value={formData.description}
                          onChange={(e) =>
                            onDescriptionChange(e.target.value)
                          }
                        />
                      </div>

                      <div className="mad-save-formdata-btn">
                        <button
                          className="mad-save-btn"
                          onClick={() => onSaveFormData(showFilterData[0]._id, showFilterData[0].type)}
                          disabled={showSaveLoader ? true : false}
                        >
                          {showSaveLoader &&
                            <span className="savedata-loader-img" >
                              <img src={Loader} alt="Loading..." />
                            </span>
                          }Save
                        </button>
                      </div>
                    </div>

                    <hr className="mad-hr" />

                    {showFilterData[0].type !== "video" ?
                      <>
                        <div className="mad-copy-url">
                          <h5>Copy URL</h5>
                          <ul className="mad-copy-url-list">
                          <Tooltip title={
                                        <div>
                                          <div>{formatBytes(showFilterData[0].urls[4]?.size)}</div>
                                          <div>{showFilterData[0].urls[4]?.width}x{showFilterData[0].urls[4]?.height} px</div>
                                        </div>} arrow>
                            <li onClick={() => onCopyUrl(showFilterData[0].urls[4]?.url, 4)}><span className="mad-copy-url-list-text">100%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>

                            <Tooltip title={
                                        <div>
                                          <div>{formatBytes(showFilterData[0].urls[3]?.size)}</div>
                                          <div>{showFilterData[0].urls[3]?.width}x{showFilterData[0].urls[3]?.height} px</div>
                                        </div>} arrow>
                            <li onClick={() => onCopyUrl(showFilterData[0].urls[3]?.url, 3)}><span className="mad-copy-url-list-text">80%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>

                            <Tooltip title={
                                        <div>
                                          <div>{formatBytes(showFilterData[0].urls[2]?.size)}</div>
                                          <div>{showFilterData[0].urls[2]?.width}x{showFilterData[0].urls[2]?.height} px</div>
                                        </div>} arrow>
                            <li onClick={() => onCopyUrl(showFilterData[0].urls[2]?.url, 2)}><span className="mad-copy-url-list-text">60%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>

                            <Tooltip title={
                                        <div>
                                          <div>{formatBytes(showFilterData[0].urls[1]?.size)}</div>
                                          <div>{showFilterData[0].urls[1]?.width}x{showFilterData[0].urls[1]?.height} px</div>
                                        </div>} arrow>
                            <li onClick={() => onCopyUrl(showFilterData[0].urls[1]?.url, 1)}><span className="mad-copy-url-list-text">40%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>

                            <Tooltip title={
                                        <div>
                                          <div>{formatBytes(showFilterData[0].urls[0]?.size)}</div>
                                          <div>{showFilterData[0].urls[0]?.width}x{showFilterData[0].urls[0]?.height} px</div>
                                        </div>} arrow>
                            <li onClick={() => onCopyUrl(showFilterData[0].urls[0]?.url, 0)}><span className="mad-copy-url-list-text">20%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>
                            
                          </ul>
                        </div>
                        {/* <div className="mad-copy-url">
                          <div className="mad-field-box-left">
                            <h5>Copy URL</h5>
                          </div>

                          {showFilterData[0].urls.length !== 1 ?
                            copyUrlLoader === 4 ?
                              <button className="share-btn-details" disabled>Copied!</button> :
                              <div className="mad-field-box-right">
                                <button
                                  className="copy-url-icon-btn"
                                  onClick={() => onCopyUrl(showFilterData[0].urls[4]?.url, 4)}
                                >
                                  100%
                                  <img src={IcnMCopy} />
                                </button>
                              </div> : ""
                          }
                          {showFilterData[0].urls.length !== 1 ? copyUrlLoader === 3 ?
                            <button className="share-btn-details" disabled>Copied!</button> :
                            <div className="mad-field-box-right">
                              <button
                                className="copy-url-icon-btn"
                                onClick={() => onCopyUrl(showFilterData[0].urls[3]?.url, 3)}
                              >
                                80%
                                <img src={IcnMCopy} />
                              </button>
                            </div> : ""
                          }


                          {showFilterData[0].urls.length !== 1 ? copyUrlLoader === 2 ?
                            <button className="share-btn-details" disabled>Copied!</button> :
                            <div className="mad-field-box-right">
                              <button
                                className="copy-url-icon-btn"
                                onClick={() => onCopyUrl(showFilterData[0].urls[2]?.url, 2)}
                              >
                                60%
                                <img src={IcnMCopy} />
                              </button>
                            </div>
                            : ""
                          }
                          {showFilterData[0].urls.length !== 1 ? copyUrlLoader === 1 ?
                            <button className="share-btn-details" disabled>Copied!</button> :
                            <div className="mad-field-box-right">
                              <button
                                className="copy-url-icon-btn"
                                onClick={() => onCopyUrl(showFilterData[0].urls[1]?.url, 1)}
                              >
                                40%
                                <img src={IcnMCopy} />
                              </button>
                            </div>
                            : ""
                          }
                          {
                            copyUrlLoader === 0 ?
                              <button className="share-btn-details" disabled>Copied!</button> :
                              <div className="mad-field-box-right">
                                <button
                                  className="copy-url-icon-btn"
                                  onClick={() => onCopyUrl(showFilterData[0].urls[0]?.url, 0)}
                                >
                                  20%
                                  <img src={IcnMCopy} />
                                </button>
                              </div>
                          }
                        </div> */}
                      </>
                      :
                      <>
                        <div className="mad-copy-url">
                          <h5>Copy URL</h5>
                          <ul className="mad-copy-url-list">


                          {showFilterData[0].urls[2] !== undefined ?
                               <Tooltip title={
                                <div>
                                  <div>{formatBytes(showFilterData[0].urls[2]?.size)}</div> 
                                </div>} arrow> 
                                <li className={`${showFilterData[0].urls[2]?.isProgress ? "li-disabled" : "li-enabled"}`} onClick={() => onCopyUrl(showFilterData[0].urls[2]?.url, 2)}><span className="mad-copy-url-list-text">1080p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>
                              : 
                              <li className="li-disabled"><span className="mad-copy-url-list-text">1080p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li> 
                              }

                          {showFilterData[0].urls[1] !== undefined ?
                               <Tooltip title={
                                <div>
                                  <div>{formatBytes(showFilterData[0].urls[1]?.size)}</div> 
                                </div>} arrow>
                                <li className={`${showFilterData[0].urls[1]?.isProgress ? "li-disabled" : "li-enabled"}`} onClick={() => onCopyUrl(showFilterData[0].urls[1]?.url, 1)}><span className="mad-copy-url-list-text">720p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>
                              : 
                              <li className="li-disabled"><span className="mad-copy-url-list-text">720p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li> 
                              } 

                              <Tooltip title={
                              <div>
                                <div>{formatBytes(showFilterData[0].urls[0]?.size)}</div> 
                              </div>} arrow>
                              <li onClick={() => onCopyUrl(showFilterData[0].urls[0]?.url, 0)}><span className="mad-copy-url-list-text">480p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>



                            {/* <li className={`${showFilterData[0].urls[0]?.isProgress ? "li-disabled" : "li-enabled"}`} onClick={() => onCopyUrl(showFilterData[0].urls[0]?.url, 0)}><span className="mad-copy-url-list-text">1080p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li>


                            <li className={`${showFilterData[0].urls[1]?.isProgress ? "li-disabled" : "li-enabled"}`} onClick={() => onCopyUrl(showFilterData[0].urls[1]?.url, 1)}><span className="mad-copy-url-list-text">720p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li>


                            <li onClick={() => onCopyUrl(showFilterData[0].urls[2]?.url, 2)}><span className="mad-copy-url-list-text">480p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li> */}

                            <li onClick={() => onCopyUrl(showFilterData[0].audioFileUrl, 3)}><span className="mad-copy-url-list-text">Audio</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li>
                            <li onClick={() => onCopyUrl(showFilterData[0].vttFileUrl, 4)}><span className="mad-copy-url-list-text">.vtt</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li>
                          </ul>
                        </div>
                        {/* <div className="mad-copy-url">
                          <div className="mad-field-box-left">
                            <h5>Copy URL</h5>
                          </div>
                          {copyUrlLoader === 0 ?
                            <button className="share-btn-details" disabled>Copied!</button> :
                            <div className="mad-field-box-right">
                              <button
                                className="copy-url-icon-btn"
                                onClick={() => onCopyUrl(showFilterData[0].urls[0]?.url, 0)}
                              >
                                1080p
                                <img src={IcnMCopy} />
                              </button>
                            </div>
                          }
                          {copyUrlLoader === 1 ?
                            <button className="share-btn-details" disabled>Copied!</button> :
                            <div className="mad-field-box-right">
                              <button
                                className="copy-url-icon-btn"
                                onClick={() => onCopyUrl(showFilterData[0].urls[1]?.url, 1)}
                              >
                                720p
                                <img src={IcnMCopy} />
                              </button>
                            </div>
                          }

                          {copyUrlLoader === 2 ?
                            <button className="share-btn-details" disabled>Copied!</button> :
                            <div className="mad-field-box-right">
                              <button
                                className="copy-url-icon-btn"
                                onClick={() => onCopyUrl(showFilterData[0].urls[2]?.url, 2)}
                              >
                                360p
                                <img src={IcnMCopy} />
                              </button>
                            </div>
                          }
                          {copyUrlLoader === 3 ?
                            <button className="share-btn-details" disabled>Copied!</button> :
                            <div className="mad-field-box-right">
                              <button
                                className="copy-url-icon-btn"
                                onClick={() => onCopyUrl(showFilterData[0].audioFileUrl, 3)}
                              >
                                Audio
                                <img src={IcnMCopy} />
                              </button>
                            </div>
                          }

                          {copyUrlLoader === 4 ?
                            <button className="share-btn-details" disabled>Copied!</button> :
                            <div className="mad-field-box-right">
                              <button
                                className="copy-url-icon-btn"
                                onClick={() => onCopyUrl(showFilterData[0].vttFileUrl, 4)}
                              >
                                .vtt
                                <img src={IcnMCopy} />
                              </button>
                            </div>
                          }
                        </div> */}
                      </>
                    }
                    <div className="mad-delete-download">
                      <span className="mad-delete-link" onClick={() => onClickDeleteImage(showFilterData[0]._id, showFilterData[0].type)}>Delete {showFilterData[0].type !== "image" ? "video" : "image"}
                        {shwoDeleteLoader &&
                          <span className="mad-delete-loader-img"> <img src={Loader} alt="Loading..." /></span>
                        }
                      </span>
                      {/* <a
                          href={showFilterData[0].urls[0]?.url}
                          download
                          onClick={() => download(data.urls[0]?.url)}
                          target="_blank"
                        > */}
                      <span
                        className="mad-download-link"
                        onClick={() => {
                          saveAs(
                            showFilterData[0].urls[0]?.url,
                            showFilterData[0].title
                          );
                        }}
                      >
                        Download image
                      </span>
                      {/* </a> */}
                      {/* <span className="mad-download-link" download>
                          Download image
                        </span> */}
                    </div>
                  </div>
                </>
              )}
              {selectedItems.length > 1 && (
                <div className="choose-media-multipie">
                  <div className="choose-media-multipie-item">
                    <div className="choose-media-multipie-item-left">
                      <img src={WFImagePlaceholder} alt="Placeholder" />
                    </div>
                    <div className="choose-media-multipie-item-right">
                      <h5>{selectedItems.length} items selected</h5>
                    </div>
                  </div>
                  <div className="choose-media-multipie-link">
                    <span className="mad-delete-link" onClick={() => onClickDeleteImage(selectedItems)}>Delete Media
                      {shwoDeleteLoader &&
                        <span className="mad-delete-loader-img"> <img src={Loader} alt="Loading..." /></span>
                      }
                    </span>
                    <span
                      className="mad-download-link"
                      onClick={() => {
                        for (
                          let index = 0;
                          index < showFilterData.length;
                          index++
                        ) {
                          saveAs(
                            showFilterData[index].urls[0]?.url,
                            showFilterData[index].title
                          );
                        }
                      }}
                    >
                      Download images
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="media-attachment-details-footer">
              {type === "video" ?
                <button
                  className="upload-media-btn"
                  onClick={onClickChooseImage}
                  disabled={showFilterData.length > 0 ? false : true}
                >
                  Choose video
                </button> : ""
              }
              {type === "image" ?
                selectedItems.length !== 1 ?
                  <button
                    className="upload-media-btn"
                    onClick={onClickChooseImage}
                    disabled={showFilterData.length > 0 ? false : true}
                  >
                    Choose image
                  </button> : <button
                    className="upload-media-btn multi-select"
                    data-toggle="modal"
                    data-target="#mediaCropModel"
                    onClick={onClickChooseImageMulti}
                    disabled={showFilterData.length > 0 ? false : true}
                  >
                    Choose image
                  </button>
                : ""
              }
            </div>
          </div>
        </div>
      </div>

      {showFilterData.length > 0 && (
        <MediaCropPopup filterData={showFilterData} resetFilterData={resetFilterData} cropType={cropType} setCroppedImageUrl={setCroppedImageUrl} />
      )}
    </>
  );
}