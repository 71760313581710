const savedvideoloader = false;

const ReduSavedVideoLoader = (state = savedvideoloader, action) => {
  switch (action.type) {
    case "SETSAVEDVIDEOLOADER":
      return true;
    case "REMOVESAVEDVIDEOLOADER":
        return false;
    default:
      return state;
  }
};

export default ReduSavedVideoLoader;