const partnerinprocess = false;

const ReduPartnerInProcess = (state = partnerinprocess, action) => {
  switch (action.type) {
    case "SETPARTNERINPROCESS":
      return true;
    case "REMOVEPARTNERINPROCESS":
        return false;
    default:
      return state;
  }
};

export default ReduPartnerInProcess;