import React from 'react'
import '../Styles/ChatLinkThumbnail.css'
import PropTypes from "prop-types"
import "../../../../Styles/Theme/Theme.css"
import GlobeIcon from "../../../../components/Icons/GeneralIcons/GlobeIcon"

const ChatLinkThumbnail = ({ hasImage, src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png" }) => {
    return (
        hasImage ?
            <div className='chat-link-thumbnail-img'><img src={src} /></div> :
            <div className='chat-link-thumbnail-icn'>
                <GlobeIcon
                    height={24}
                    iconColor="#060D14"
                    isFilled
                    width={24}
                />
            </div>
    )
}

ChatLinkThumbnail.propTypes = {
    hasImage: PropTypes.bool,
    src: PropTypes.string,
}


export default ChatLinkThumbnail