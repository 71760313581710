import React from "react";
import PropTypes from "prop-types"
function PptIcon({ height = 24, width = 24,}) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#E03303"
                    fillRule="evenodd"
                    d="M5.864 1h8.902L21 7.498v12.638A2.861 2.861 0 0 1 18.141 23H5.864A2.862 2.862 0 0 1 3 20.136V3.864A2.862 2.862 0 0 1 5.864 1Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    d="M7 16.739v-3.997h1.699c.417 0 .753.115 1.006.352.247.23.373.539.373.929 0 .385-.126.698-.373.929-.253.23-.589.346-1.006.346h-.682v1.44H7Zm1.017-2.31h.566c.154 0 .27-.032.358-.104.082-.071.12-.176.12-.302 0-.132-.038-.231-.12-.302a.544.544 0 0 0-.358-.11h-.566v.819Zm2.49 2.31v-3.997h1.699c.418 0 .753.115 1.006.352.253.23.374.539.374.929 0 .385-.121.698-.374.929-.253.23-.588.346-1.006.346h-.676v1.44h-1.023Zm1.023-2.31h.56c.154 0 .275-.032.358-.104a.386.386 0 0 0 .126-.302.378.378 0 0 0-.126-.302.534.534 0 0 0-.357-.11h-.561v.819Zm3.524 2.31V13.61h-1.139v-.869h3.304v.869h-1.143v3.128h-1.023Z"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
            </svg>
        </>
    );
}
PptIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
export default PptIcon;
