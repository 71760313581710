const intialState = {
    featuredLabel: " Featured Module 1",
    featuredSubLabel: "Sub title for featured partner 1",
    freshDealLabel: " Featured Module 2",
    freshDealSubLabel: "Sub title for featured partner 2"
};

const ReduPartnerDynamicLabel = (state = intialState, action) => {
  switch (action.type) {
    case "SETDYNAMICLABEL":
      return {...state, ...action.payload};
    default:
      return state;
  }
};

export default ReduPartnerDynamicLabel;