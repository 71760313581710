import { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

import { fetchmyaccount } from "../../../Action";
import Services from "../../../Services/auth.service";
import Image1 from "../../../Images/app_logo.png";
import loading from "../../../Images/loader.gif";

//image cropper
import Cropper from "cropperjs";
import { ReactComponent as LinkIcon } from '../../../Images/icn_s_link.svg'
import "cropperjs/dist/cropper.min.css";
import Exclude from "../../../Images/Exclude.svg";
import Cancel from "../../../Images/Cancel-filled.svg";
import Button from "../../Button/Button";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";

const EditProfile = ({ profileDetail, setshoweditdiv }) => {
  //hooks
  let dispatch = useDispatch();
  const Crop = useRef({});

  const [inprocess, setinprocess] = useState(false);
  const [tmpimage, setImage] = useState(null);
  const [tmpviewimage, setviewImage] = useState(null);
  const [cropedFile, setcropedFile] = useState();
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewmodalOpen, setViewModalOpen] = useState(false);
  const [err, seterr] = useState({ status: false, msg: "" });
  const [outputBase64, setOutput] = useState(null);
  const [loader, setloader] = useState(false);
  const [dynamic_width, setdynamic_width] = useState(0);
  const [showbtns, setshowbtns] = useState(false);
  const [isSuccessMsg, setSuccessMsg] = useState(false);
  const [errMsgText, setErrMsgText] = useState("");
  const [errMsgFlag, seterrMsgFlag] = useState(false);

  const [cropper, setCropper] = useState(null);
  const [thumbnail, setThumbnail] = useState([]);
  const [fileDimension, setFileDimension] = useState();

  const onDrop = (acceptedFiles) => {
    // Check if any of the dropped files are not allowed
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      thumbnailImages(file);
      const reader = new FileReader();
      reader.onload = () => setThumbnail(reader.result);
      reader.readAsDataURL(file);
    }
  }

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });
  const assignFiles = (acceptedFile) => {
    setModalOpen(true);
    setFileName(Math.random().toString() + acceptedFile[0].name);
    setFile(URL.createObjectURL(acceptedFile[0]));
    console.log(acceptedFile[0], "accepted file");
  };

  useEffect(() => {
    if (outputBase64 != null) {
      const convertedUrlToFile = dataURLtoFile(outputBase64);
      setcropedFile(convertedUrlToFile);
    }
  }, [outputBase64]);

  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], fileName, { type: mime });
  };
  function viewProfileImage(e) {
    setViewModalOpen(true);
    setviewImage(e.currentTarget);
  }

  const CropDone = async (croppedImg) => {
    setloader(true);
    setModalOpen(false);
    const formdata = new FormData();
    let imageCompress;
    if (croppedImg) {
      imageCompress = await CompressImageFunction(croppedImg);
    }
    imageCompress && formdata.append("profileImg", imageCompress);
    try {
      Services.editprofile(formdata)
        .then((res) => {
          if (res.data.status) {
            setFile("");
            dispatch(fetchmyaccount());
            Services.updateUserDataInRadis(localStorage.getItem("userid"));
            setTimeout(() => {
              setloader(false);
            }, 1000);
          }

        })
        .catch((e) => {
          console.log(e);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };


  return profileDetail !== undefined ? (
    <>
      <div className="all-content-main-edit-profile-main">
        <>
          <div className="cstm-per-inf-title">Edit profile</div>
          <div className="cstm-edit-profile-only-mn">
            <div className="all-content-main-edit-profile-upload">
              <div className="cstm-profile-upload-img w-auto">
                {loader ? (
                  <div className="profile-loading-image">
                    <span className="cstm-pli-bg">
                      <img src={loading} />
                    </span>
                  </div>
                ) : profileDetail.profileImg !== undefined &&
                  profileDetail.profileImg.length > 0 ? (
                  <img
                    className="cstm-ld-user-img"
                    src={
                      profileDetail.profileImg !== undefined &&
                        profileDetail.profileImg.length > 0
                        ? profileDetail.profileImg
                        : Image1
                    }
                    onClick={viewProfileImage}
                  />
                ) : (
                  <div className="cstm-upload-avar">
                    {localStorage.getItem("username")
                      ? localStorage.getItem("username").charAt(0)
                      : ""}
                  </div>
                )}
              </div>
              <div className="cstm-profile-upload-btn-sec">
                <div className="cstm-profile-upload-btn">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span className="edit-pro-text">Change</span>
                  </div>
                </div>
              </div>
            </div>
            <a href="https://member.milliondollarsellers.com/Sys/Login?ReturnUrl=%2fSys%2fProfile" target="_blank" className="link-text d-inline-flex align-items-center">Edit profile <LinkIcon /></a>
            {isSuccessMsg && (
              <div className="cstm-success-msg-container">
                <div className="cstm-success-msg-container-title">
                  <img src={Exclude} /> Profile updated!
                </div>
              </div>
            )}

            {errMsgFlag && (
              <div className="cstm-success-msg-container cstm-an-red">
                <div className="cstm-success-msg-container-title">
                  <img src={Cancel} /> Something went wrong.
                </div>
                <div className="cstm-success-msg-container-cont">
                  {errMsgText}
                </div>
              </div>
            )}

            {viewmodalOpen && (
              <div className="desk-globle-model-box globle-model-box  cstm-rpp-view-profile">
                <div className="desk-globle-model-box-inner cstm-vpp-popup">
                  <div className="modal-content">
                    <div className="cstm-replace-pp">
                      <div class="cstm-rpp-title">
                        View Profile Picture
                        <span
                          class="cstm-rpp-close-btn"
                          onClick={() => setViewModalOpen(false)}
                        >
                          ✖
                        </span>
                      </div>
                      <div className="cstm-cstm-vpp-photo">
                        {tmpviewimage && <img src={tmpviewimage.src} />}
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            )}
            {modalOpen && (
              <ImageCropperReact
                file={thumbnail}
                setModalOpen={setModalOpen}
                setcropedFile={setcropedFile}
                setFile={setThumbnail}
                fileName={fileName}
                fileDimension={fileDimension}
                CropDone={CropDone}
                isLoading={isLoading}
              />
            )}
            {/* <div className="cstm-profile-upload-cont cstm-sm-form">
                {formFields.length > 0
                  ? formFields.map((field) => {
                      return (
                        <DisplayCustomRegistrationForm
                          field={field}
                          handleChangeDBfields={handleChangeDBfields}
                          otherDetailErr={otherDetailErr}
                          otherUserDetail={otherUserDetail}
                        />
                      );
                    })
                  : ""}

{inprocess ? (
                  <div className="cstm-pi-main-btn">
                    <button className="cstm-reset-link">Reset</button>


                <div className="cstm-ep-ldr">
                  <span className="cstm-ep-ldr-icon">
                    <img src={loading} />
                  </span>
                  <button className="btn6">Save</button>
                </div>
              </div>
            ) : showbtns ? (
              <div className="cstm-pi-main-btn">
                <button
                  className="cstm-reset-link"
                  onClick={() => {
                    resetAll();
                    setshowbtns(false);
                  }}
                >
                  Reset
                </button>

                <button
                  className="btn6"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </button>
              </div>
            ) : (
              <></>
            )}
              </div> */}
          </div>
        </>

        {/* )} */}
      </div>
    </>
  ) : (
    <></>
  );
};
export default EditProfile;
