import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Services from "../../../Services/auth.service1";
import SavedServices from "../../../Services/auth.service";

import { fetchhistoryvideos, addhistoryvideo } from "../../../Action/index1";

import moment from "moment";
import videoimg from "../../../Images/nothumbnail.png";
import MoreLine from "../../../Images/more-line.png";
import { InView } from "react-intersection-observer";
import noMediaFound from "../../../Images/NoVideo.svg";
import noSearchMediaFound from "../../../Images/No-Search-Video.svg";
import loading from "../../../Images/loader.gif";
import SearchSidebar from "../../../Images/SearchSidebar.svg";
import DeleteSearch from "../../../Images/DeleteSearch.svg";

import MenuViewDt from "../../../Images/MenuViewDt.svg";

import SaveDrop from "../../../Images/SaveDrop.svg";
import DeleteDrop from "../../../Images/DeleteDrop.svg";
import { fetchsavedvideos } from "../../../Action/index";
import millify from "millify";
import Exclude from "../../../Images/Exclude.svg";
import Cancel from "../../../Images/Cancel-filled.svg";
import { getVideoProgressPercentage } from "../../checktoken";
import sepbullet from "../../../Images/separator-bullet.svg";
import CensusDataModal from "../../User/PartnerManagement/CensusDataModal";
import VideoSmallCard from "../../CardsVideo/VideoSmallCard";
import VideoMediumCard from "../../CardsVideo/VideoMediumCard";

const VideoHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setsearch] = useState("");
  const [nosearchresult, setnosearchresult] = useState(false);
  const [deletevideoBtnStatus, setDeleteVideoBtnStatus] = useState({});
  const [deletesavedvideoBtnStatus, setDeleteSavedVideoBtnStatus] = useState(
    {}
  );
  const [historyparam, setHistoryParams] = useState({
    page: 1,
    limit: 10,
  });

  const [loader, setloader] = useState(true);
  const [deletesavedloader, setdeletesavedloader] = useState({});
  const [deleteremoveloader, setdeleteremoveloader] = useState({});
  const [deleteremovesavedloader, setdeleteremovesavedloader] = useState({});

  const [page, setpage] = useState(1);
  const [totalvideos, settotalvideos] = useState(0);
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);
  const [nodatafound, setnodatafound] = useState(false);
  const [pageloader, setpageloader] = useState(false);
  const [displayvideos, setdisplayvideos] = useState([]);
  const [viewmodalOpen, setviewmodelopen] = useState(false);
  const [savedvideohistory, setsavedvideohistory] = useState({});
  const [clearallhistoryloader, setclearallhistoryloader] = useState(false);
  const [removesignlemodalOpen, setremovesignlemodalOpen] = useState({});
  const [removesavedmodalOpen, setremovesavedmodalOpen] = useState({});
  const [isRemoveSingleSuccessMsg, setRemoveSingleSuccessMsg] = useState(false);
  const [isRemoveSavedSingleSuccessMsg, setRemoveSavedSingleSuccessMsg] =
    useState(false);
  const [isSavedSingleSuccessMsg, setSavedSingleSuccessMsg] = useState(false);
  const [isClearAllSuccessMsg, setClearAllSuccessMsg] = useState(false);

  const [errMsgText, setErrMsgText] = useState("");
  const [errMsgFlag, seterrMsgFlag] = useState(false);

  const [access, setAccess] = useState(false);
  const [access1, setAccess1] = useState(false);
  const [access2, setAccess2] = useState(false);
  const [accessLoader, setAccessLoader] = useState(false);

  const savedvideos = useSelector((state) => {
    return state.ReduSavedVideos;
  });
  const historyvideos = useSelector((state) => {
    return state.ReduHistoryVideos;
  });

  // on first render of page this useEffect call
  useEffect(() => {
    if (localStorage.getItem("count") > 364) {
      setAccess2(true)
      setAccessLoader(false)
    } else {
      setAccessLoader(true)
      setAccess2(false)
    }
  }, [])

  useEffect(() => {
    setloader(true);

    Services.gethistoryvideos(historyparam)
      .then((res) => {
        if (res.data.status) {
          setHistoryParams({ page: 1, limit: 10 });
          setpageloader(false);
          settotalvideos(res.data.data[0].totalVideos);
          dispatch(fetchhistoryvideos(res.data.data[0].video_history_data));

          dispatch(fetchsavedvideos());

          setloader(false);


          if (res.data.data[0].totalVideos === 0) setnodatafound(true);
          else setnodatafound(false);
        } else {
          setloader(false);
          setnodatafound(true);

        }

        return true;
      })
      .catch((e) => {
        setnodatafound(true);
        setloader(false);

        return false;
      });

    window.scrollTo(0, 0);

    document.addEventListener("mousedown", (e) => {

      if (
        e.target.className !== "cstm-hv-op-drop" &&
        e.target.className !== "cstm-removeOpen-btns" &&
        e.target.tagName !== "A"
      )
        if (deletevideoBtnStatus !== undefined) {
          var key_values = Object.values(deletevideoBtnStatus);
          var formatted = {};
          if (key_values !== undefined) {
            key_values.forEach((key_value) =>
              Object.entries(key_value).forEach(
                ([key, value]) => (formatted = { ...formatted, [key]: value })
              )
            );
            setDeleteVideoBtnStatus(formatted);
          }
        }
    });
  }, []);

  useEffect(() => {
    if (
      historyvideos !== undefined &&
      historyvideos !== null &&
      pageloader &&
      historyvideos.length < totalvideos
    ) {
      setHistoryParams({ page: historyparam.page + 1, limit: 10 });
      setpage(page + 1);


    }
  }, [pageloader]);

  useEffect(() => {

    if (
      historyvideos !== null &&
      historyvideos !== undefined &&
      historyvideos.length > 0
    ) {
      setdisplayvideos([...historyvideos]);
      var arr = {};
      historyvideos.map((video, index) => {
        arr = { ...arr, [video.video_id]: false };
      });

      setnodatafound(false);

    } else {
      setdisplayvideos([]);
      setnodatafound(true);
    }
  }, [historyvideos]);

  useEffect(() => {
    if (displayvideos.length <= 0 && !nosearchresult) {
      setnodatafound(true);
    } else {
      setnodatafound(false);
    }
  }, [displayvideos]);

  useEffect(() => {

    if (
      historyvideos !== null &&
      historyvideos !== undefined &&
      historyvideos.length < totalvideos &&
      page <= Math.ceil(totalvideos / 10) &&
      page > 1
    ) {
      // setloader(true)
      Services.gethistoryvideos(historyparam)
        .then((res) => {
          if (res.data.status) {
            dispatch(addhistoryvideo(res.data.data[0].video_history_data));
            settotalvideos(res.data.data[0].totalVideos);
            setpageloader(false);
            setloader(false);

            if (res.data.data[0].totalVideos === 0) {
              setnodatafound(true);
              setloader(false);
            } else {
              setnodatafound(false);
              setloader(false);
            }
          } else {
            setpageloader(false);
            setloader(false);
          }
        })
        .catch((e) => {
          setpageloader(false);
          setnodatafound(true);
          setloader(false);
          return false;
        });
    }
  }, [page]);

  function toggleRemoveButton(e, video_id) {
    setDeleteVideoBtnStatus({
      [video_id]: !deletevideoBtnStatus[video_id],
    });


  }

  function openClearHistoryDialog(e) {
    setviewmodelopen(true);
    document.body.classList.add("modal-open");
  }

  function removeHistoryVideo(e, video_id) {
    setdeleteremoveloader({ [video_id]: true });
    seterrMsgFlag(false);

    const data = {
      _id: video_id,
    };
    Services.deletehistoryvideo(data)
      .then((res_) => {

        if (res_.data.status) {
          setRemoveSingleSuccessMsg(true);
          var array = [...displayvideos]; // make a separate copy of the array
          var filteredarray = array.filter((item) => item._id !== video_id);
          setdisplayvideos([...filteredarray]);
          setdeleteremoveloader({ [video_id]: false });
          setpageloader(false);
          setTimeout(() => {
            setRemoveSingleSuccessMsg(false);
          }, [3000]);
          setDeleteVideoBtnStatus({ [video_id]: false });
        } else {
          setdeleteremoveloader({ [video_id]: false });
          setDeleteVideoBtnStatus({ [video_id]: false });
          setRemoveSingleSuccessMsg(false);
          setErrMsgText("Unable to remove from watch history. Try again.");
          seterrMsgFlag(true);
          setTimeout(() => {
            seterrMsgFlag(false);
          }, 3000);
        }
      })
      .catch((e) => {
        setpageloader(false);
        setnodatafound(true);
        setdeleteremoveloader({ [video_id]: false });
        setRemoveSingleSuccessMsg(false);
        setDeleteVideoBtnStatus({ [video_id]: false });
        setErrMsgText("Unable to remove from watch history. Try again.");
        seterrMsgFlag(true);
        setTimeout(() => {
          seterrMsgFlag(false);
        }, 3000);
        return false;
      });
  }
  useEffect(() => {
    var arr = savedvideohistory;
    savedvideos.map((savedvideo) => {
      if (historyvideos.length > 0) {
        const elm = historyvideos.filter(
          (item) => item.video_id == savedvideo._id
        );
        if (elm.length > 0) {
          arr = { ...arr, [elm[0].video_id]: true };
        }
      }
    });
    setsavedvideohistory(arr);
  }, [savedvideos]);

  function saveVideo(e, video_id) {
    setdeletesavedloader({ [video_id]: true });
    setSavedSingleSuccessMsg(false);
    seterrMsgFlag(false);
    SavedServices.savevideo(video_id)
      .then((res_) => {
        if (res_.data.status) {
          var array = [...displayvideos]; // make a separate copy of the array
          setdeletesavedloader({ [video_id]: false });

          setSavedSingleSuccessMsg(true);
          var save_ = res_.data.data.saveVideos.filter(
            (item) => item._id == video_id
          );

          var arr = savedvideohistory;
          if (save_.length <= 0) {
            arr = { ...arr, [video_id]: false };
          }
          setsavedvideohistory(arr);
          setTimeout(() => {
            setSavedSingleSuccessMsg(false);
          }, 3000);
          dispatch(fetchsavedvideos());
          setDeleteVideoBtnStatus({ [video_id]: false });
          //setsavedvideohistory({...savedvideohistory, [video_id]: true})
        } else {
          setErrMsgText("The video could not be saved. Please try again.");
          seterrMsgFlag(true);
          setTimeout(() => {
            seterrMsgFlag(false);
          }, 3000);
          setSavedSingleSuccessMsg(false);
          setdeletesavedloader({ [video_id]: false });
          setDeleteVideoBtnStatus({ [video_id]: false });
        }
      })
      .catch((e) => {
        setSavedSingleSuccessMsg(false);
        setdeletesavedloader({ [video_id]: false });
        setDeleteVideoBtnStatus({ [video_id]: false });
        setErrMsgText("The video could not be saved. Please try again.");
        seterrMsgFlag(true);

        setTimeout(() => {
          seterrMsgFlag(false);
        }, 3000);
        return false;
      });
  }

  function removesavedVideo(e, video_id) {
    setdeleteremovesavedloader({ [video_id]: true });
    setRemoveSavedSingleSuccessMsg(false);
    seterrMsgFlag(false);
    SavedServices.savevideo(video_id)
      .then((res_) => {
        if (res_.data.status) {
          var array = [...displayvideos]; // make a separate copy of the array
          setdeleteremovesavedloader({ [video_id]: false });
          setremovesavedmodalOpen({ [video_id]: false });
          setRemoveSavedSingleSuccessMsg(true);
          var save_ = res_.data.data.saveVideos.filter(
            (item) => item._id == video_id
          );

          var arr = savedvideohistory;
          if (save_.length <= 0) {
            arr = { ...arr, [video_id]: false };
          }
          setTimeout(() => {
            setRemoveSavedSingleSuccessMsg(false);
          }, 3000);
          setsavedvideohistory(arr);
          setDeleteVideoBtnStatus({ [video_id]: false });
          dispatch(fetchsavedvideos());

          //setsavedvideohistory({...savedvideohistory, [video_id]: true})
        } else {
          setErrMsgText("Unable to remove video from saved list. Try again.");
          seterrMsgFlag(true);
          setTimeout(() => {
            seterrMsgFlag(false);
          }, 3000);
          setremovesavedmodalOpen({ [video_id]: false });
          setRemoveSavedSingleSuccessMsg(false);
          setdeleteremovesavedloader({ [video_id]: false });
          setDeleteVideoBtnStatus({ [video_id]: false });
        }
      })
      .catch((e) => {
        setremovesavedmodalOpen({ [video_id]: false });
        setRemoveSavedSingleSuccessMsg(false);
        setdeleteremovesavedloader({ [video_id]: false });
        setErrMsgText("Unable to remove video from saved list. Try again.");
        seterrMsgFlag(true);
        setDeleteVideoBtnStatus({ [video_id]: false });
        setTimeout(() => {
          seterrMsgFlag(false);
        }, 3000);
        return false;
      });
  }

  const fnclearallhistory = (e) => {
    setclearallhistoryloader(true);
    setClearAllSuccessMsg(false);
    seterrMsgFlag(false);
    Services.clearallhistory()
      .then((res_) => {

        if (res_.data.status) {
          //alert("deleted");
          setloader(false);
          setclearallhistoryloader(false);
          setClearAllSuccessMsg(true);
          setTimeout(() => {
            setClearAllSuccessMsg(false);
          }, 3000);
          setpageloader(false);
          setnosearchresult(false);
          setdisplayvideos([]);
          setnodatafound(true);
          setviewmodelopen(false);
          document.body.classList.remove("modal-open");
        } else {
          setclearallhistoryloader(false);
          setClearAllSuccessMsg(false);
          setloader(false);
          setpageloader(false);
          setErrMsgText("Unable to clear watch history. Please try again.");
          seterrMsgFlag(true);
          setTimeout(() => {
            seterrMsgFlag(false);
          }, 3000);
          setviewmodelopen(false);
          document.body.classList.remove("modal-open");
        }
      })
      .catch((e) => {
        setviewmodelopen(false);
        document.body.classList.remove("modal-open");
        setnosearchresult(false);
        setnodatafound(false);
        setclearallhistoryloader(false);
        setClearAllSuccessMsg(false);
        setloader(false);
        setpageloader(false);
        setErrMsgText("Unable to clear watch history. Please try again.");
        seterrMsgFlag(true);
        setTimeout(() => {
          seterrMsgFlag(false);
        }, 3000);
        return false;
      });
  };

  const handlesearchvideohistory = (e) => {

    setnodatafound(false);
    setloader(false);
    setpageloader(false);
    setsearch(e.target.value);

    var arr = historyvideos.filter((video) => {
      if (
        video.archive_video.title
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
        return video;
    });

    if (arr.length <= 0) {
      setnosearchresult(true);
      setnodatafound(false);
    } else {
      setnosearchresult(false);
      setnodatafound(false);
    }
    setdisplayvideos(arr);
  };

  return (

    <>
      {
        access2 || ((localStorage.getItem("remindmetomorrowcensus") && moment(localStorage.getItem("remindmetomorrowcensus"), "YYYY-MM-DD").diff(moment().format("YYYY-MM-DD"), 'days') === 0 && localStorage.getItem("dontshowcensuspopup") === "false" && (access || access1)) || ((localStorage.getItem("remindmetomorrowcensus") === null || localStorage.getItem("remindmetomorrowcensus") === undefined) && (localStorage.getItem("dontshowcensuspopup") === "false" || localStorage.getItem("dontshowcensuspopup") === null || localStorage.getItem("dontshowcensuspopup") === undefined) && (access || access1))) ?
          <CensusDataModal access={access} setAccess={setAccess} access1={access1} setAccess1={setAccess1} access2={access2} setAccess2={setAccess2} />
          :
          accessLoader ?
            <div
              id="history"
              className={`cstm-videos-history-only ${nodatafound ? "cstm-no-history-video-container" : ""
                }`}
            >
              <div className="cstm-video-history-col-1">
                {!nodatafound && <div className="cstm-cl-title">Watch history</div>}
                <VideoSmallCard />
                <VideoMediumCard />
                {nodatafound && loader && (
                  <div className="cstm-cl-title">Watch history</div>
                )}
                <div className="cstm-my-history-videos-list">
                  {/* {Initially loading page-loader} */}
                  {loader && !pageloader ? (
                    [...Array(10)].map((el, index) => (
                      <div className="cstm-vh-sh">
                        <div className="cstm-vh-sh-left skeleton-box"></div>
                        <div className="cstm-vh-sh-right">
                          <div className="cstm-vh-sh-right-top skeleton-box"></div>
                          <div className="cstm-vh-sh-right-bottom skeleton-box"></div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}

                  {!loader &&
                    displayvideos !== undefined &&
                    displayvideos !== null &&
                    displayvideos.length > 0 &&
                    displayvideos.map((video, index) => {
                      if (
                        historyvideos !== null &&
                        historyvideos !== undefined &&
                        index === historyvideos.length - 1
                      ) {
                        return (
                          <InView
                            onChange={(inView, entry) => {
                              if (inView && !pageloader) {
                                setpageloader(true);

                              }
                            }}
                          >
                            <div className="cstm-my-videos-history-list-main">
                              <a
                                onClick={() => {

                                  navigate(
                                    `/videos/${video.video_id}`
                                  );
                                }}
                              >
                                <div className={`cstm-my-videos-history-list-main-box`}>
                                  <div className="cstm-my-videos-history-list-main-box-img">
                                    <div className="cstm-vh-vi">
                                      <img
                                        src={
                                          video.archive_video.thumbnail
                                            ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                            video.archive_video.thumbnail
                                            : videoimg
                                        }
                                      />
                                      <span className="cstm-rv-time">
                                        {video.archive_video.duration}
                                      </span>
                                    </div>

                                    {getVideoProgressPercentage(video.archive_video) !==
                                      0 && (
                                        <div className="video_progress_wrapper">
                                          <div
                                            className="video_progress_bar"
                                            style={{
                                              width: `${getVideoProgressPercentage(
                                                video.archive_video
                                              )}%`,
                                            }}
                                          ></div>
                                        </div>
                                      )}
                                  </div>

                                  <div className="cstm-my-videos-history-list-main-box-info">
                                    <div className="cstm-vh-title">
                                      {video.archive_video.title}
                                    </div>

                                    <div className="cstm-vh-info">
                                      <span className="cstm-vh-info-title">
                                        {video.archive_video.categories[0].name}
                                      </span><span className="cstm-vh-info-dt"><img src={sepbullet} /></span>
                                      <span className="cstm-vh-info-date">{moment(video.history_date).fromNow()}</span>
                                      <span className="cstm-vh-info-dt"><img src={sepbullet} /></span>
                                      <span className="cstm-vh-info-views">{video.archive_video.viewsCount} Views</span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <div
                                className={`cstm-hv-op-drop  ${deletevideoBtnStatus[video.video_id]
                                  ? "cstm-open-dp"
                                  : ""
                                  }`}
                              >
                                <div className="cstm-hv-op-drop-dt">
                                  <a
                                    onClick={(e) =>
                                      toggleRemoveButton(e, video.video_id)
                                    }
                                  >
                                    <img src={MoreLine} />
                                  </a>
                                </div>

                                <div
                                  className="cstm-removeOpen-btns  cstm-dropin"
                                  style={{
                                    display: deletevideoBtnStatus[video.video_id]
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <ul>
                                    <li className="historyvideo-save-single-video-loader">
                                      {deletesavedloader[video.video_id] ? (
                                        <div className="historyvideo-save-loading-image">
                                          <img src={loading} />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      <a
                                        data-videoId={video._id}
                                        onClick={(e) => {
                                          if (savedvideohistory[video.video_id]) {
                                            {
                                              setremovesavedmodalOpen({
                                                [video.video_id]: true,
                                              });
                                            }
                                          } else {
                                            saveVideo(e, video.video_id);
                                          }
                                        }}
                                      >
                                        <img src={SaveDrop} />{" "}
                                        {savedvideohistory[video.video_id]
                                          ? "Remove from Watch later"
                                          : "Save for Watch later"}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        data-videoId={video._id}
                                        onClick={(e) => {
                                          setremovesignlemodalOpen({
                                            [video._id]: true,
                                          });
                                        }}
                                      >
                                        <img src={DeleteDrop} /> Remove from History
                                      </a>
                                    </li>
                                  </ul>
                                </div>


                              </div>
                              {removesignlemodalOpen[video._id] && (
                                <div
                                  className="desk-globle-model-box globle-model-box"
                                  backdrop="static"
                                >
                                  <div className="desk-globle-model-box-inner cstm-clear-watch-history">
                                    <div class="cstm-clear-watch-history-mn">
                                      <div class="cstm-clear-watch-history-title">
                                        Remove from history?
                                      </div>
                                      <div className="cstm-clear-watch-history-content">
                                        Are you sure to remove this video from watch
                                        history?
                                      </div>
                                      <div className="cstm-clear-watch-history-link">
                                        <a
                                          className="cstm-cwh-cancel-btn"
                                          onClick={() => {
                                            setremovesignlemodalOpen({
                                              [video._id]: false,
                                            });
                                            setDeleteVideoBtnStatus({
                                              [video.video_id]: false,
                                            });
                                          }}
                                        >
                                          Cancel
                                        </a>
                                        <a
                                          className="cstm-cwh-clear-btn historyvideo-remove-all-videos-loader"
                                          onClick={(e) =>
                                            removeHistoryVideo(e, video._id)
                                          }
                                        >
                                          {deleteremoveloader[video._id] ? (
                                            <div className="historyvideo-loading-image">
                                              <img src={loading} />
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          Remove
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {isRemoveSingleSuccessMsg && (
                                <div className="cstm-success-msg-container">
                                  <div className="cstm-success-msg-container-title">
                                    <img src={Exclude} /> Removed from watch history!
                                  </div>
                                </div>
                              )}

                              {removesavedmodalOpen[video.video_id] && (
                                <div
                                  className="desk-globle-model-box globle-model-box"
                                  backdrop="static"
                                >
                                  <div className="desk-globle-model-box-inner cstm-clear-watch-history">
                                    <div class="cstm-clear-watch-history-mn">
                                      <div class="cstm-clear-watch-history-title">
                                        Remove from saved?
                                      </div>
                                      <div className="cstm-clear-watch-history-content">
                                        Do you want to delete this video from your
                                        Saved list?
                                      </div>
                                      <div className="cstm-clear-watch-history-link">
                                        <a
                                          className="cstm-cwh-cancel-btn"
                                          onClick={() => {
                                            setremovesavedmodalOpen({
                                              [video.video_id]: false,
                                            });
                                            setDeleteVideoBtnStatus({
                                              [video.video_id]: false,
                                            });
                                          }}
                                        >
                                          Cancel
                                        </a>
                                        <a
                                          className="cstm-cwh-clear-btn historyvideo-remove-saved-video-loader"
                                          onClick={(e) =>
                                            removesavedVideo(e, video.video_id)
                                          }
                                        >
                                          {deleteremovesavedloader[video.video_id] ? (
                                            <div className="historyvideo-loading-image">
                                              <img src={loading} />
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          Remove
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {isRemoveSavedSingleSuccessMsg && (
                                <div className="cstm-success-msg-container">
                                  <div className="cstm-success-msg-container-title">
                                    <img src={Exclude} /> Removed from Saved videos!
                                  </div>
                                </div>
                              )}

                              {isSavedSingleSuccessMsg && (
                                <div className="cstm-success-msg-container">
                                  <div className="cstm-success-msg-container-title">
                                    <img src={Exclude} /> Video saved!
                                  </div>
                                </div>
                              )}
                            </div>
                          </InView>
                        );
                      } else {
                        return (
                          <>
                            <div className="cstm-my-videos-history-list-main">
                              <a
                                onClick={() => {

                                  navigate(
                                    `/videos/${video.video_id}`
                                  );
                                }}
                              >
                                <div className={`cstm-my-videos-history-list-main-box `}>
                                  <div className="cstm-my-videos-history-list-main-box-img">
                                    <div className="cstm-vh-vi">
                                      <img
                                        src={
                                          video.archive_video.thumbnail
                                            ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                            video.archive_video.thumbnail
                                            : videoimg
                                        }
                                      />
                                      <span className="cstm-rv-time">
                                        {video.archive_video.duration}
                                      </span>
                                    </div>
                                    {getVideoProgressPercentage(video.archive_video) !==
                                      0 && (
                                        <div className="video_progress_wrapper">
                                          <div
                                            className="video_progress_bar"
                                            style={{
                                              width: `${getVideoProgressPercentage(
                                                video.archive_video
                                              )}%`,
                                            }}
                                          ></div>
                                        </div>
                                      )}
                                  </div>
                                  <div className="cstm-my-videos-history-list-main-box-info">
                                    <div className="cstm-vh-title">
                                      {video.archive_video.title}
                                    </div>

                                    <div className="cstm-vh-info">
                                      <span className="cstm-vh-info-title">
                                        {video.archive_video.categories[0].name}
                                      </span>
                                      <span className="cstm-vh-info-dt"><img src={sepbullet} /></span>
                                      <span className="cstm-vh-info-date">{moment(video.history_date).fromNow()}</span>
                                      <span className="cstm-vh-info-dt"><img src={sepbullet} /></span>
                                      <span className="cstm-vh-info-views">
                                        {video.archive_video.viewsCount} Views
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <div
                                className={`cstm-hv-op-drop ${deletevideoBtnStatus[video.video_id]
                                  ? "cstm-open-dp"
                                  : ""
                                  }`}
                              >
                                <div className="cstm-hv-op-drop-dt">
                                  <a
                                    onClick={(e) => toggleRemoveButton(e, video.video_id)}
                                  >
                                    <img src={MenuViewDt} />
                                  </a>
                                </div>

                                <div
                                  className="cstm-removeOpen-btns  cstm-dropin"
                                  style={{
                                    display: deletevideoBtnStatus[video.video_id]
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <ul>
                                    <li className="historyvideo-save-single-video-loader">
                                      {deletesavedloader[video.video_id] ? (
                                        <div className="historyvideo-save-loading-image">
                                          <img src={loading} />
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      <a
                                        data-videoId={video._id}
                                        onClick={(e) => {
                                          if (savedvideohistory[video.video_id]) {
                                            {
                                              setremovesavedmodalOpen({
                                                [video.video_id]: true,
                                              });
                                            }
                                          } else {
                                            saveVideo(e, video.video_id);
                                          }
                                        }}
                                      >
                                        <img src={SaveDrop} />{" "}
                                        {savedvideohistory[video.video_id]
                                          ? "Remove from Watch later"
                                          : "Save for Watch later"}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        data-videoId={video._id}
                                        onClick={(e) => {
                                          setremovesignlemodalOpen({ [video._id]: true });
                                        }}
                                      >
                                        <img src={DeleteDrop} /> Remove from History
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {removesignlemodalOpen[video._id] && (
                                <div
                                  className="desk-globle-model-box globle-model-box"
                                  backdrop="static"
                                >
                                  <div className="desk-globle-model-box-inner cstm-clear-watch-history">
                                    <div class="cstm-clear-watch-history-mn">
                                      <div class="cstm-clear-watch-history-title">
                                        Remove from history?
                                      </div>
                                      <div className="cstm-clear-watch-history-content">
                                        Are you sure to remove this video from watch
                                        history?
                                      </div>
                                      <div className="cstm-clear-watch-history-link">
                                        <a
                                          className="cstm-cwh-cancel-btn"
                                          onClick={() => {
                                            setremovesignlemodalOpen({
                                              [video._id]: false,
                                            });
                                            setDeleteVideoBtnStatus({
                                              [video.video_id]: false,
                                            });
                                          }}
                                        >
                                          Cancel
                                        </a>
                                        <a
                                          className="cstm-cwh-clear-btn historyvideo-remove-all-videos-loader"
                                          onClick={(e) =>
                                            removeHistoryVideo(e, video._id)
                                          }
                                        >
                                          {deleteremoveloader[video._id] ? (
                                            <div className="historyvideo-loading-image">
                                              <img src={loading} />
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          Remove
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            {isRemoveSingleSuccessMsg && (
                              <div className="cstm-success-msg-container">
                                <div className="cstm-success-msg-container-title">
                                  <img src={Exclude} /> Removed from watch history!
                                </div>
                              </div>
                            )}

                            {removesavedmodalOpen[video.video_id] && (
                              <div
                                className="desk-globle-model-box globle-model-box"
                                backdrop="static"
                              >
                                <div className="desk-globle-model-box-inner cstm-clear-watch-history">
                                  <div class="cstm-clear-watch-history-mn">
                                    <div class="cstm-clear-watch-history-title">
                                      Remove from saved?
                                    </div>
                                    <div className="cstm-clear-watch-history-content">
                                      Do you want to delete this video from your Saved
                                      list?
                                    </div>
                                    <div className="cstm-clear-watch-history-link">
                                      <a
                                        className="cstm-cwh-cancel-btn"
                                        onClick={() => {
                                          setremovesavedmodalOpen({
                                            [video.video_id]: false,
                                          });
                                          setDeleteVideoBtnStatus({
                                            [video.video_id]: false,
                                          });
                                        }}
                                      >
                                        Cancel
                                      </a>
                                      <a
                                        className="cstm-cwh-clear-btn historyvideo-remove-saved-video-loader"
                                        onClick={(e) =>
                                          removesavedVideo(e, video.video_id)
                                        }
                                      >
                                        {deleteremovesavedloader[video.video_id] ? (
                                          <div className="historyvideo-loading-image">
                                            <img src={loading} />
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        Remove
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                            {isRemoveSavedSingleSuccessMsg && (
                              <div className="cstm-success-msg-container">
                                <div className="cstm-success-msg-container-title">
                                  <img src={Exclude} /> Removed from Saved videos!
                                </div>
                              </div>
                            )}

                            {isSavedSingleSuccessMsg && (
                              <div className="cstm-success-msg-container">
                                <div className="cstm-success-msg-container-title">
                                  <img src={Exclude} /> Video saved!
                                </div>
                              </div>
                            )}
                          </>
                        );
                      }
                    })}

                  {pageloader &&
                    totalvideos !== 0 &&
                    displayvideos.length < totalvideos ? (
                    [...Array(totalvideos - displayvideos.length)].map((el, index) => (
                      <div className="cstm-vh-sh">
                        <>
                          <div className="cstm-vh-sh-left skeleton-box"></div>
                          <div className="cstm-vh-sh-right">
                            <div className="cstm-vh-sh-right-top skeleton-box"></div>
                            <div className="cstm-vh-sh-right-bottom skeleton-box"></div>
                          </div>
                        </>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}

                  {!loader &&
                    !pageloader &&
                    nosearchresult &&
                    !nodatafound &&
                    displayvideos.length <= 0 &&
                    historyvideos.length > 0 && (
                      <div class="cstm-oops-main">
                        {" "}
                        <div class="cstm-oops-main-img">
                          <img src={noSearchMediaFound} />
                        </div>{" "}
                        <div class="cstm-oops-main-ctn">
                          Oops, we couldn't find anything.
                          <br />
                          Please try again.
                        </div>{" "}
                      </div>
                    )}

                  {!loader &&
                    !pageloader &&
                    nodatafound &&
                    !nosearchresult &&
                    (historyvideos.length <= 0 || displayvideos.length <= 0) && (
                      <div class="cstm-no-history">
                        {" "}
                        <div class="cstm-oops-main-img">
                          <img src={noMediaFound} />
                        </div>{" "}
                        <div class="cstm-oops-main-ctn">
                          No videos viewed yet!
                          <br />
                          Time to explore!
                        </div>{" "}
                      </div>
                    )}
                </div>
              </div>

              {!nodatafound ? (
                <div className="cstm-video-history-col-2">
                  <div className="cstm-manage-history-title">Manage history</div>

                  <div className="cstm-video-history-search">
                    <input
                      type="text"
                      value={search}
                      placeholder="Search watch history"
                      className={`cstm-clear-all-watch-history ${search.length > 0 ? "filedvalue" : ""
                        }`}
                      onChange={handlesearchvideohistory}
                    />
                    <img src={SearchSidebar} />
                  </div>

                  <div class="cstm-clear-video-history-container">
                    <span className="cstm-cawh">
                      <a onClick={openClearHistoryDialog}>
                        <img src={DeleteSearch} />
                        Clear all watch history
                      </a>
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {viewmodalOpen && (
                <div
                  className="desk-globle-model-box globle-model-box"
                  backdrop="static"
                >
                  <div className="desk-globle-model-box-inner cstm-clear-watch-history">
                    <div class="cstm-clear-watch-history-mn">
                      <div class="cstm-clear-watch-history-title">
                        Clear watch history?
                      </div>
                      <div className="cstm-clear-watch-history-content">
                        Your watch history will be cleared from all MDS apps on all
                        devices.
                      </div>
                      <div className="cstm-clear-watch-history-link">
                        <a
                          className="cstm-cwh-cancel-btn"
                          onClick={() => { setviewmodelopen(false); document.body.classList.remove("modal-open"); }}
                        >
                          Cancel
                        </a>
                        <a
                          className="cstm-cwh-clear-btn historyvideo-remove-all-videos-loader"
                          onClick={(e) => fnclearallhistory(e)}
                        >
                          {clearallhistoryloader ? (
                            <div className="historyvideo-clearall-loading-image">
                              <img src={loading} />
                            </div>
                          ) : (
                            <></>
                          )}
                          Clear history
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isClearAllSuccessMsg && (
                <div className="cstm-success-msg-container">
                  <div className="cstm-success-msg-container-title">
                    <img src={Exclude} /> Watch history cleared!
                  </div>
                </div>
              )}

              {errMsgFlag && (
                <div className="cstm-success-msg-container cstm-an-red">
                  <div className="cstm-success-msg-container-title">
                    <img src={Cancel} /> Something went wrong.
                  </div>
                  <div className="cstm-success-msg-container-cont">{errMsgText}</div>
                </div>
              )}
            </div>
            :
            <></>
      }
    </>


  );
};
export default VideoHistory;
