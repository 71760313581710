import React from 'react'
import "./Document.css"
import "../../../Styles/Theme/Theme.css"
import Button from '../../../components/Button/Button'
import PropTypes from "prop-types"
import CrossIcon from '../../../components/Icons/GeneralIcons/CrossIcon'
import ArrowChevronLeft from '../../../components/Icons/ArrowsIcon/ArrowChevronLeft'

const FilterHeader = ({ title = "Filters", onCancel, onClick, isNavigated }) => {
    return (
        <>
            {isNavigated ? <div className='filter-sidebar-header-second'>
                <Button 
                handleClick={onClick}
                size="small"
                elementIcon={<ArrowChevronLeft />}
                onContextMenu={() => { }}
                variant="ghost"
                hasText={false}
                hasToolTip={false} 
                >
                   
                    
                    
                    </Button>
                <h4 className='filter-sidebar-header-title-center'>{title}</h4>
                <Button 
                handleClick={onCancel}
                size="small"
                elementIcon={<CrossIcon />}
                onContextMenu={() => { }}
                variant="ghost"
                hasText={false}
                hasToolTip={false} >
                </Button>
            </div> :
                <div className='filter-sidebar-header'>
                    <h3 className='filter-sidebar-header-title'>{title}</h3>
                    <Button
                        handleClick={onCancel}
                        size="small"
                        elementIcon={<CrossIcon />}
                        onContextMenu={() => { }}
                        variant="ghost"
                        hasText={false}
                        hasToolTip={false} >
                    </Button>
                </div>
            }
        </>
    )
}

FilterHeader.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    onCancel: PropTypes.func,
    isNavigated: PropTypes.bool
}

export default FilterHeader 