import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Services from "../../Services/auth.service";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgDelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import editfill from "../../Images/BackendImages/edit.svg";
import Loader2 from "../../Images/loader2.gif";
import Dropzone from "react-dropzone";
import Frame from "../../Images/Frame.svg";
import AlertComponent from "../../Hooks/AlertComponent";
import authService from "../../Services/auth.service";
import Loader from "../../Images/loader.gif";
import {
  useReAssignVideoCategoryQuery,
  useDeleteVideoCategoryMutation,
} from "../../Services/video";
// import authService from "../../Services/auth.service";
const columns = [
  { id: "name", label: "Categories" },
  { id: "counts", label: "No. of videos" },
  { id: "action", label: "Actions" },
];

function createData(name, counts, action) {
  return {
    name,
    counts,
    action,
  };
}
const VideoCategory = () => {
  const history = useNavigate();
  const [rows, setrow] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setdeleteId] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [showalertAdd, setshowalertAdd] = useState(false);
  const [alertmsgAdd, setalertmsgAdd] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allcategories, setallcategories] = useState([]);
  const [addcategory, setaddcategory] = useState("");
  const [allsubcategory, setallsubcategory] = useState([]);
  const [adderr, setadderr] = useState("");
  const [editcategory, seteditcategory] = useState("");
  const [editerr, setediterr] = useState("");
  const [selectedId, setselectedId] = useState("");
  const [createInprocess, setcreateInprocess] = useState(false);
  const [editInprocess, seteditInprocess] = useState(false);
  const [categoryImg, setCategoryImg] = useState(null);
  const [categoryImgErr, setCategoryImgErr] = useState("");
  const [editCategoryImg, setEditCategoryImg] = useState(null);
  const [editCategoryImgErr, setEditCategoryImgErr] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");

  const [editSubCatInputErr, setEditSubCatInputErr] = useState("");
  const [editLoader, setEditLoader] = useState(false);
  const [editSubCatErr, setEditSubCatErr] = useState("");
  const [editAllSubCategory, setEditAllSubCategory] = useState([]);
  const [editAllNewSubCategory, setEditAllNewSubCategory] = useState([]);
  const [selectedSubCatVal, setSelectedSubCatVal] = useState("");
  const [selecetdSubCatId, setSelecetdSubCatId] = useState("");
  const [addSubCatInputErr, setAddSubCatInputErr] = useState("");
  const [addSubCatErr, setAddSubCatErr] = useState("");
  const [addLoader, setAddLoader] = useState(false);
  const [deleteSubCatIds, setdeleteSubCatIds] = useState([]);
  const [file, setFile] = useState(null);
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [err, setErr] = useState("");
  // const [reassignedData, setReassignedData] = useState([]);
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const location = useLocation().search;
  const search = new URLSearchParams(location).get("search");

  //Reassigned data
  const { data: reassignedData } = useReAssignVideoCategoryQuery(deleteId);

  const [deleteVideoCategory] = useDeleteVideoCategoryMutation();
  useEffect(() => {
    search !== undefined && search !== null && setSearchText(search);
    dispatch({ type: "REMOVEMODALINPROCESS" });
    setLoader(true);
    getAllVideoCategoryApi(search);
    getallusersSuggestionListApi();
  }, []);

  async function getAllVideoCategoryApi(search, col, sort) {
    let query = "";
    query += search !== undefined && search !== null ? `?search=${search}` : "";
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    Services.getallcontentcategory_byadmin(query)
      .then((res) => {
        if (res.data.status) {
          setLoader(false);
          var data = res.data.data;
          var tbl = [];
          for (var i = 0; i < data.length; i++) {
            tbl[i] = createData(data[i].name, data[i].counts, data[i]._id);
          }
          setrow(tbl);
          setallcategories(tbl);
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  }

  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getCategoriesSuggestionList();
      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.name }));
        setSuggestionList(arr);
      }
    } catch (err) {}
  }

  const handleCloseAddModel = (e) => {
    setaddcategory("");
    setallsubcategory([]);
    setdeleteSubCatIds([]);
    setCategoryImg(null);
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
    dispatch({ type: "REMOVEMODALINPROCESS" });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (value) => {
    setselectedId(value);
    Services.getcategorybyid(value)
      .then((res) => {
        if (res.data.status) {
          seteditcategory(res.data.data.name);
          setEditAllSubCategory(res.data.data.subcategory ?? []);
          setEditCategoryImg(
            res.data.data.categoryImage !== undefined &&
              res.data.data.categoryImage !== null
              ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                  res.data.data.categoryImage
              : null
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSearch = (searchText) => {
    setSearchText(searchText);
    setSuggestionBox(false);
    getAllVideoCategoryApi(searchText);
    history(`/contentlibrary/videocategories/noid/0?search=${searchText}`);
    // if (allcategories !== undefined && allcategories.length > 0) {
    //   var data = allcategories.filter((cate) => {
    //     if (cate.name.toLowerCase().includes(searchText.trim().toLowerCase())) {
    //       return cate;
    //     }
    //   });
    //   var tbl = [];
    //   for (var i = 0; i < data.length; i++) {
    //     tbl[i] = createData(data[i].name, data[i].counts, data[i].action);
    //   }
    //   setrow(tbl);
    // }
  };

  //delete video category
  const handleDeleteCategory = async () => {
    setcreateInprocess(true);
    const bodyData = {
      deleteVideoCategoryId: deleteId,
      reassignVideoCategoryId:
        selectedCategory !== "" ? selectedCategory : null,
    };
    try {
      const deleteRes = await deleteVideoCategory({ body: bodyData });
      if (deleteRes?.data?.status) {
        getAllVideoCategoryApi();
        setcreateInprocess(false);
        $("#DeleteFromCategory").modal("hide");
        setshowalert(true);
        setalertmsg(deleteRes?.data?.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
        }, 3000);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  const handleSorting = (e) => {
    var normal = allcategories;
    var arr = [...allcategories];

    if (e.target.value === "a-z") {
      arr.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (e.target.value === "z-a") {
      arr.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else {
      arr = normal;
    }
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(arr[i].name, arr[i].counts, arr[i].action);
    }
    setrow(tbl);
  };

  const handleSort = (col, direc) => {
    setLoader(true);
    getAllVideoCategoryApi(search, col, direc);
  };

  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setaddcategory(e.target.value);
    if (e.target.value.length > 0) {
      setadderr("");
    } else {
      setadderr("Enter category! ");
    }
  };

  const handleCreate = () => {
    if (
      addcategory.length > 0 &&
      addSubCatErr.length === 0 &&
      addSubCatInputErr.length === 0
    ) {
      setcreateInprocess(true);
      const addCategoryFormData = new FormData();
      addCategoryFormData.append("name", addcategory.trim());
      addCategoryFormData.append("subcategory", allsubcategory);
      addCategoryFormData.append("categoryImage", categoryImg);
      Services.createcontentcategory(addCategoryFormData)
        .then((res) => {
          setcreateInprocess(false);
          if (res.data.status) {
            setallsubcategory([]);
            setCategoryImg(null);
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
              $("#Addcategory").modal("hide");
            }, 3000);
            getAllVideoCategoryApi(search);
          } else {
            dispatch({ type: "REMOVEMODALINPROCESS" });
            setallsubcategory([]);
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          }
          setaddcategory("");
        })
        .catch((e) => {
          setcreateInprocess(false);
          console.log(e);
        });
      setadderr("");
    } else {
      setadderr("Enter category!");
    }
  };

  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    seteditcategory(e.target.value);
    if (e.target.value.length > 0) {
      setediterr("");
    } else {
      setediterr("Enter category! ");
    }
  };
  const handleEditSubmit = () => {
    if (
      editcategory.length > 0 &&
      editSubCatErr.length === 0 &&
      editSubCatInputErr.length === 0
    ) {
      seteditInprocess(true);
      const editCategoryFormdata = new FormData();
      editCategoryFormdata.append("name", editcategory);
      (editAllNewSubCategory.length > 0) &&
        editCategoryFormdata.append("subcategory", editAllNewSubCategory);
      (deleteSubCatIds.length > 0) &&
        editCategoryFormdata.append("deleteSubCategory", deleteSubCatIds);

      if (file !== undefined && file !== null) {
        editCategoryFormdata.append("categoryImage", file[0]);
      }

      Services.editCategory(selectedId, editCategoryFormdata)
        .then((res) => {
          if (res.data.status) {
            setSearchText("");
            setShowAlertEdit(true);
            setAlertMsgEdit(res.data.message);
            setEditAllSubCategory([]);
            setallsubcategory([]);
            setEditAllNewSubCategory([]);
            setdeleteSubCatIds([]);
            seteditcategory("");
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
              $("#editcategory").modal("hide");
            }, 3000);
            seteditInprocess(false);
            getCategoryList();
            setSelectedSubCatVal("");
            setSelecetdSubCatId(-1);
          } else {
            setShowAlertEdit(true);
            setAlertMsgEdit(res.data.message);
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
            }, 3000);
          }
          dispatch({ type: "REMOVEMODALINPROCESS" });
          seteditInprocess(false);
        })
        .catch((e) => {
          console.log(e);
          dispatch({ type: "REMOVEMODALINPROCESS" });
          seteditInprocess(false);
        });
      setediterr("");
    } else {
      setediterr("Enter category!");
    }
  };

  // thumbnail image upload function
  const categoryImages = async (acceptedfile) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    var height, width;
    let result_base64 = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = function (acceptedfile) {
        var image = new Image();
        image.src = acceptedfile.target.result;
        image.onload = function (acceptedfile) {
          height = this.height;
          width = this.width;
          setCategoryImgErr("");
          resolve(reader.result);
        };
      };
      reader.readAsDataURL(acceptedfile);
    });
  };
  //pop up state for do you want to leave....
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#Addcategory").hasClass("show")) {
        $("#Addcategory").modal("show");
      }
      if ($("#editcategory").hasClass("show")) {
        $("#editcategory").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#Addcategory").hasClass("show")) {
        $("#Addcategory").modal("hide");
        handleCloseAddModel();
      }
      if ($("#editcategory").hasClass("show")) {
        $("#editcategory").modal("hide");
        handleCloseAddModel();
      }
    }
  }, [stayOnPage]);

  // Called on click on add button when editcategory popup open
  const handleEditSubCategory = async (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const subCatVal = document
      .getElementById("newEditSubCatValue")
      .value.trim();
    if (subCatVal.length > 0) {
      setEditLoader(true);
      if (!editAllNewSubCategory.includes(subCatVal.toLowerCase())) {
        const isSubCategoryExists = await Services.checkAlredayExistSubCategory(
          {
            name: subCatVal,
          }
        );
        if (isSubCategoryExists && !isSubCategoryExists.data.status) {
          setEditLoader(false);
          setEditSubCatErr(isSubCategoryExists.data.message);
        } else {
          setEditLoader(false);
          setEditAllNewSubCategory([...editAllNewSubCategory, subCatVal]);
          document.getElementById("newEditSubCatValue").value = "";
          setEditSubCatErr("");
        }
      } else {
        setEditSubCatErr(
          `Subcategory ${subCatVal} already exists, subcategory must be unique!`
        );
        setEditLoader(false);
      }
    }
  };

  //  Called when edit category popup open and edit newly added subcategory
  const handleOnEditNewSubCategoryEditBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      document.getElementById("newEditSubCatValue").value = "";
      setSelecetdSubCatId(index);
      setSelectedSubCatVal(subcat);
    }
  };

  //  Called when edit category popup open and delete new added subcategory
  const handleOnEditNewSubCategoryDeleteBtnEvent = (e, subcat, index) => {
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      dispatch({ type: "SETMODALINPROCESS" });
      setEditAllNewSubCategory([
        ...editAllNewSubCategory.filter((cat, i) => {
          if (i !== index) return cat;
        }),
      ]);
      setEditSubCatErr("");
      setEditSubCatInputErr("");
    }
  };

  //  Called when edit category popup open and change new added subcategory
  const handleOnEditNewSubCategoryInputChange = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSelectedSubCatVal(e.target.value.trim());
    let subCatsArr = [...editAllNewSubCategory];
    subCatsArr[index] = e.target.value;
    setEditAllNewSubCategory(subCatsArr);
    setEditSubCatErr("");
    setEditSubCatInputErr("");
    setAddSubCatInputErr("");
    setAddSubCatErr("");
  };

  //  Called when edit category popup open and update newly added subcategory
  const handleOnEditNewSubCategoryUpdateBtnEvent = async (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (selectedSubCatVal.trim().length > 0) {
      if (
        editAllNewSubCategory.filter(
          (item, i) => item.trim() === selectedSubCatVal.trim() && i !== index
        ).length > 0
      ) {
        setEditSubCatInputErr(
          `Subcategory "${selectedSubCatVal}" already exists. Subcategory must be unique!`
        );
      } else {
        const isSubCategoryExists = await Services.checkAlredayExistSubCategory(
          {
            name: selectedSubCatVal,
          }
        );
        if (!isSubCategoryExists.data.status) {
          setEditSubCatInputErr(isSubCategoryExists.data.message);
        } else {
          let subCatsArr = [...editAllNewSubCategory];
          subCatsArr[index] = editAllNewSubCategory[index];
          setEditAllNewSubCategory(subCatsArr);
          setEditSubCatErr("");
          setEditSubCatInputErr("");
          setSelecetdSubCatId(-1);
          setSelectedSubCatVal("");
        }
      }
    } else {
      setEditSubCatInputErr(`Enter subcategory!`);
    }
  };

  //  Called when add category popup open and edit newly added subcategory
  const handleOnAddNewSubCategoryEditBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (addSubCatInputErr.length === 0 && addSubCatErr.length === 0) {
      document.getElementById("newSubCatValue").value = "";
      setSelecetdSubCatId(index);
      setSelectedSubCatVal(subcat);
    }
  };

  // Called on click on add button when addcategory popup open
  const handleAddSubCategory = async (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const subCatVal = document.getElementById("newSubCatValue").value.trim();
    if (subCatVal.length > 0) {
      setAddLoader(true);
      if (!allsubcategory.includes(subCatVal.toLowerCase())) {
        const isSubCategoryExists = await Services.checkAlredayExistSubCategory(
          {
            name: subCatVal.trim(),
          }
        );
        if (!isSubCategoryExists.data.status) {
          setAddLoader(false);
          setAddSubCatErr(isSubCategoryExists.data.message);
        } else {
          setallsubcategory([...allsubcategory, subCatVal]);
          document.getElementById("newSubCatValue").value = "";
          setAddSubCatErr("");
          setAddLoader(false);
        }
      } else {
        setAddSubCatErr(
          `Subcategory "${subCatVal}" already exists, subcategory must be unique!`
        );
        setAddLoader(false);
      }
    }
  };

  //  Called when add category popup open and update newly added subcategory
  const handleOnAddNewSubCategoryUpdateBtnEvent = async (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (selectedSubCatVal.length > 0) {
      if (
        allsubcategory.filter(
          (item, i) => item.trim() === selectedSubCatVal && i !== index
        ).length > 0
      ) {
        //if ((allsubcategory[index] !== editedSubCategoryForAdd[index]) && editedSubCategoryForAdd.filter((item, i) => item === allsubcategory[index] && i !== index).length > 0) {
        setAddSubCatInputErr(
          `Subcategory "${selectedSubCatVal}" already exists. Subcategory must be unique!`
        );
      } else {
        const isSubCategoryExists = await Services.checkAlredayExistSubCategory(
          {
            name: selectedSubCatVal,
          }
        );
        if (!isSubCategoryExists.data.status) {
          setAddSubCatInputErr(isSubCategoryExists.data.message);
        } else {
          let subCatsArr = [...allsubcategory];
          subCatsArr[index] = selectedSubCatVal;
          setallsubcategory(subCatsArr);
          setAddSubCatInputErr("");
          setSelecetdSubCatId(-1);
          setSelectedSubCatVal("");
        }
      }
    } else {
      setAddSubCatInputErr(`Enter subcategory!`);
    }
  };

  //  Called when add category popup open and delete newly added subcategory
  const handleOnAddNewSubCategoryDeleteBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (addSubCatInputErr.length === 0 && addSubCatErr.length === 0) {
      setallsubcategory([
        ...allsubcategory.filter((cat, i) => {
          if (i !== index) return cat;
        }),
      ]);
    }
  };

  //  Called when add category popup open and change newly added subcategory
  const handleOnAddNewSubCategoryInputChange = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSelectedSubCatVal(e.target.value.trim());
    let subCatsArr = [...allsubcategory];
    subCatsArr[index] = e.target.value;
    setallsubcategory(subCatsArr);
    setAddSubCatInputErr("");
    setAddSubCatErr("");
  };

  //  Called when edit category popup open and delete already exists subcategory
  const handleOnEditAlreadyExistsDeleteBtnEvent = (e, subcat, index) => {
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      dispatch({ type: "SETMODALINPROCESS" });
      setEditAllSubCategory([
        ...editAllSubCategory.filter((cat, i) => {
          if (i !== index) return cat;
        }),
      ]);
      setdeleteSubCatIds([...deleteSubCatIds, subcat._id]);
    }
  };

  //  Called when edit category popup open and edit already exists subcategory
  const handleOnEditAlreadyExistsEditBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      document.getElementById("newEditSubCatValue").value = "";
      setSelecetdSubCatId(subcat._id.toString());
      setSelectedSubCatVal(subcat.name);
    }
  };

  //  Called when edit category popup open and change already exists subcategory
  const handleOnEditAlreadyExistsInputChange = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSelectedSubCatVal(e.target.value.trim());
    let subCatsArr = [...editAllSubCategory];
    const i = editAllSubCategory.findIndex(
      (item) => item._id.toString() === subcat._id.toString()
    );
    subCatsArr[i].name = e.target.value;
    setEditAllSubCategory(subCatsArr);
    setEditSubCatErr("");
    setEditSubCatInputErr("");
    setEditSubCatErr("");
  };

  //  Called when edit category popup open and update already exists subcategory
  const handleOnEditAlreadyExistsUpdateBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (selectedSubCatVal.length > 0) {
      if (
        editAllSubCategory.filter(
          (item) =>
            item._id.toString() !== selecetdSubCatId &&
            item.name.toLowerCase() === selectedSubCatVal.toLowerCase()
        ).length > 0 ||
        editAllNewSubCategory.includes(selectedSubCatVal.toLowerCase())
      ) {
        setEditSubCatInputErr(
          `Subcategory "${selectedSubCatVal}" already exists. Subcategory  must be unique!`
        );
      } else {
        handleAlreadyAddedSubCategory(selectedSubCatVal, subcat, index);
      }
    } else {
      setEditSubCatInputErr(`Enter subcategory!`);
    }
  };

  // Called when editcategory popup open and edit subcategory which already added
  const handleAlreadyAddedSubCategory = (editedSubCate, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const bodayData = {
      name: editedSubCate.trim(),
    };
    Services.editSubCategory(subcat._id, bodayData)
      .then((res) => {
        if (res.data.status) {
          setEditSubCatInputErr("");
          let arrEditAllExists = [...editAllSubCategory];
          const existCatIndex = editAllSubCategory.findIndex(
            (item) => item._id.toString() === subcat._id.toString()
          );
          arrEditAllExists[existCatIndex].name = editedSubCate;
          setEditAllSubCategory(arrEditAllExists);
          setSelecetdSubCatId(-1);
          setSelectedSubCatVal("");
        } else {
          setEditSubCatInputErr(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>Video categories</h1>
            <div className="uic-fl-btn">
              <button
                className="btn"
                data-toggle="modal"
                data-target="#Addcategory"
              >
                Add category
              </button>
            </div>
          </div>

          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.name
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox &&
                suggestions.length > 0 &&
                searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.name)}
                          className={
                            index === listIndex ? "selected-active" : ""
                          }
                        >
                          {searchText !== null &&
                          searchText.length > 0 &&
                          suggestion.name
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase()) ? (
                            <>
                              <b>
                                {suggestion.name.slice(0, searchText.length)}
                              </b>
                              {suggestion.name &&
                                suggestion.name.slice(searchText.length)}{" "}
                            </>
                          ) : (
                            suggestion.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
            </div>

            <div className="uic-fl-btn sort-by-mn">
              <div className="sort-by-mn-title">Sort by : </div>
              <div className="sort-by-mn-select">
                <select onChange={handleSorting}>
                  <option>All</option>
                  <option value="a-z">A-Z</option>
                  <option value="z-a">Z-A</option>
                </select>
              </div>
            </div>
          </div>

          {showalert && <div className="alert alert-info">{alertmsg}</div>}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table min-w-auto">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="uic-th-td w-10"
                      >
                        {column.id !== "action" ? (
                          <>
                            <span className="custom-sorting">
                              <i
                                className="fa fa-angle-down"
                                onClick={(e) => {
                                  handleSort(column.id, "Asc");
                                }}
                              ></i>
                              <i
                                className="fa fa-angle-up"
                                onClick={(e) => {
                                  handleSort(column.id, "Dec");
                                }}
                              ></i>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="uic-table-td">
                  {loader ? (
                    <div className="cstm-data-load">
                      <img src={Loader} />
                    </div>
                  ) : (
                    rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                            className="uic-th-td w-20"
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return column.id === "name" ? (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td w-17"
                                >
                                  <a
                                    data-toggle="modal"
                                    data-target="#editcategory"
                                    onClick={() => {
                                      handleEdit(row.action);
                                    }}
                                    className="cstm-view-users-count"
                                  >
                                    {value}
                                  </a>
                                </TableCell>
                              ) : column.id === "counts" ? (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td w-17"
                                  onClick={() => {
                                    history(
                                      "/contentlibrary/listing/noid/0?category=" +
                                        row["action"]
                                    );
                                  }}
                                >
                                  {value}
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td uic-table-icon w-17"
                                >
                                  <a
                                    data-toggle="modal"
                                    data-target="#editcategory"
                                    onClick={() => {
                                      handleEdit(value);
                                    }}
                                  >
                                    <img src={editfill} />
                                  </a>
                                  <a
                                    data-toggle="modal"
                                    data-target="#DeleteFromCategory"
                                    onClick={() => {
                                      setdeleteId(value);
                                      // handleReAssignDocument(value);
                                      setShowDeleteModal(true);
                                      setSelectedRow(row);
                                      setSelectedCategory("");
                                      setErr("");
                                    }}
                                  >
                                    <img src={imgDelete} />
                                  </a>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      {/** Create Category */}
      <div
        className="modal fade edit-user-details-popup"
        id="Addcategory"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Add category</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#Addcategory").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showalertAdd && (
                <div className="alert alert-info">{alertmsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Category title*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter category"
                    name="category"
                    onChange={handleAddChange}
                    value={addcategory}
                  />
                </div>
                <span className="error">{adderr}</span>
              </div>
              <h5 className="mb-1">Subcategory name</h5>
              <div className="form-group">
                <div className="form-group-ct ">
                  <div
                    className="form-group doc-addsubcat-container-main"
                    id="newSubCat"
                  >
                    <input
                      type="text"
                      id="newSubCatValue"
                      disabled={addSubCatInputErr.length > 0}
                      onChange={(e) => {
                        setSelectedSubCatVal("");
                        setSelecetdSubCatId(-1);
                        setAddSubCatErr("");
                      }}
                      onKeyUp={(e) => {
                        setSelectedSubCatVal("");
                        setSelecetdSubCatId(-1);
                        e.key === "Enter" && handleAddSubCategory(e);
                      }}
                    />
                    <div className="cstm-subcategory-loader">
                      {addLoader && (
                        <span className="cstm-loadind-add">
                          {" "}
                          <>
                            <img src={Loader} />{" "}
                          </>{" "}
                        </span>
                      )}
                      <button
                        type="button"
                        onClick={handleAddSubCategory}
                        className="btn"
                        disabled={addSubCatInputErr.length > 0}
                      >
                        Add
                      </button>
                    </div>
                    <span className="error-saubcategor-name">
                      {addSubCatErr}
                    </span>
                  </div>
                </div>
                <div className="form-group doc-addedsubcat-container">
                  {allsubcategory.map((subcat, index) => {
                    return (
                      <>
                        <div
                          className="form-group-ct doc-subcat-subcontainer"
                          onDoubleClick={(e) =>
                            handleOnAddNewSubCategoryEditBtnEvent(
                              e,
                              subcat,
                              index
                            )
                          }
                        >
                          <input
                            type="text"
                            name={index}
                            value={subcat}
                            disabled={selecetdSubCatId !== index}
                            onChange={(e) => {
                              handleOnAddNewSubCategoryInputChange(
                                e,
                                subcat,
                                index
                              );
                            }}
                            onBlur={(e) =>
                              handleOnAddNewSubCategoryUpdateBtnEvent(
                                e,
                                subcat,
                                index
                              )
                            }
                          />

                          {selecetdSubCatId !== index && (
                            <>
                              <div className="doc-subcat-subcontainer-icon">
                                <img
                                  src={editfill}
                                  onClick={(e) => {
                                    handleOnAddNewSubCategoryEditBtnEvent(
                                      e,
                                      subcat,
                                      index
                                    );
                                  }}
                                />
                              </div>

                              <div className="doc-subcat-subcontainer-icon">
                                <img
                                  onClick={(e) => {
                                    handleOnAddNewSubCategoryDeleteBtnEvent(
                                      e,
                                      subcat,
                                      index
                                    );
                                  }}
                                  src={imgDelete}
                                />{" "}
                              </div>
                            </>
                          )}

                          {selecetdSubCatId === index &&
                            addSubCatInputErr !== "" && (
                              <span className="error-doc-addedsucat">
                                {addSubCatInputErr}
                              </span>
                            )}
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Add image</div>
                <div className="form-group-ct">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      categoryImages(acceptedFiles[0], setCategoryImgErr);
                      if (acceptedFiles.length > 0) {
                        setCategoryImg(acceptedFiles[0]);
                        setCategoryImgErr("");
                        setFile(acceptedFiles);
                      }
                    }}
                    name="catImag"
                    multiple={false}
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    maxSizeBytes="10485760"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="group-info-user cstm-upload-box">
                          <div className="group-info-user-img">
                            <img
                              className={""}
                              alt="Video category"
                              src={
                                categoryImg !== undefined &&
                                categoryImg !== null
                                  ? URL.createObjectURL(categoryImg)
                                  : Frame
                              }
                            />
                          </div>
                          <div className="group-info-user-link">
                            <div className="cover-image-upload-ttl">
                              Add Image
                            </div>
                          </div>
                          <span className="error">{categoryImgErr}</span>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/** Edit Category */}
      <div
        className="modal fade edit-user-details-popup"
        id="editcategory"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Edit category</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#editcategory").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertEdit && (
                <div className="alert alert-info">{alertMsgEdit}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Category title*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter category name"
                    name="category"
                    onChange={handleEditChange}
                    value={editcategory}
                  />
                </div>
                <span className="error">{editerr}</span>
              </div>
              <h5 className="mb-1">Subcategory name</h5>
              <div className="form-group">
                <div className="form-group-ct">
                  <div
                    className="form-group doc-addsubcat-container-main"
                    id="newEditSubCat"
                  >
                    <input
                      type="text"
                      id="newEditSubCatValue"
                      disabled={editSubCatInputErr?.length > 0}
                      onChange={(e) => {
                        setSelectedSubCatVal("");
                        setSelecetdSubCatId(-1);
                        setEditSubCatErr("");
                      }}
                      onKeyUp={(e) => {
                        setSelectedSubCatVal("");
                        setSelecetdSubCatId(-1);
                        e.key === "Enter" && handleEditSubCategory(e);
                      }}
                    />
                    <div className="cstm-subcategory-loader">
                      {editLoader && (
                        <span className="cstm-loadind-add">
                          <>
                            <img src={Loader} />
                          </>
                        </span>
                      )}
                      <button
                        type="button"
                        onClick={(e) => handleEditSubCategory(e)}
                        className="btn"
                        disabled={editSubCatInputErr?.length > 0}
                      >
                        Add
                      </button>
                    </div>

                    <span className="error-saubcategor-name">
                      {editSubCatErr}
                    </span>
                  </div>
                  {/* <span class="text-limitaion">Press enter to add a new subcategory (The name must be unique)</span> */}

                  <div className="form-group doc-addedsubcat-container">
                    {editAllSubCategory.map((subcat, index) => {
                      return (
                        <>
                          <div
                            className="form-group-ct doc-subcat-subcontainer"
                            onDoubleClick={(e) =>
                              handleOnEditAlreadyExistsEditBtnEvent(e, subcat)
                            }
                          >
                            <input
                              type="text"
                              name={subcat._id}
                              value={subcat.name}
                              disabled={
                                subcat._id.toString() !== selecetdSubCatId
                              }
                              // disabled={!editExistsSubCatInputEnabled[`${subcat._id.toString()}`]}
                              onChange={(e) => {
                                handleOnEditAlreadyExistsInputChange(
                                  e,
                                  subcat,
                                  index
                                );
                              }}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  handleOnEditAlreadyExistsUpdateBtnEvent(
                                    e,
                                    subcat,
                                    index
                                  );
                                }
                              }}
                              onBlur={(e) =>
                                handleOnEditAlreadyExistsUpdateBtnEvent(
                                  e,
                                  subcat,
                                  index
                                )
                              }
                            />

                            {selecetdSubCatId !== subcat._id.toString() && (
                              <>
                                <div className="doc-subcat-subcontainer-icon">
                                  <img
                                    src={editfill}
                                    onClick={(e) => {
                                      handleOnEditAlreadyExistsEditBtnEvent(
                                        e,
                                        subcat,
                                        index
                                      );
                                    }}
                                  />
                                </div>

                                <div className="doc-subcat-subcontainer-icon">
                                  <img
                                    onClick={(e) => {
                                      handleOnEditAlreadyExistsDeleteBtnEvent(
                                        e,
                                        subcat,
                                        index
                                      );
                                    }}
                                    src={imgDelete}
                                  />{" "}
                                </div>
                              </>
                            )}

                            {selecetdSubCatId === subcat._id.toString() &&
                              editSubCatInputErr !== "" && (
                                <span className="error-doc-addedsucat">
                                  {editSubCatInputErr}
                                </span>
                              )}
                          </div>
                        </>
                      );
                    })}
                    {editAllNewSubCategory.map((subcat, index) => {
                      return (
                        <div
                          className="form-group-ct doc-subcat-subcontainer"
                          onDoubleClick={(e) =>
                            handleOnEditNewSubCategoryEditBtnEvent(
                              e,
                              subcat,
                              index
                            )
                          }
                        >
                          <input
                            type="text"
                            name={index}
                            value={subcat}
                            disabled={index !== selecetdSubCatId}
                            onChange={(e) => {
                              handleOnEditNewSubCategoryInputChange(
                                e,
                                subcat,
                                index
                              );
                            }}
                            onBlur={(e) => {
                              handleOnEditNewSubCategoryUpdateBtnEvent(
                                e,
                                subcat,
                                index
                              );
                            }}
                          />

                          {selecetdSubCatId !== index && (
                            <>
                              <div className="doc-subcat-subcontainer-icon">
                                <img
                                  src={editfill}
                                  onClick={(e) => {
                                    handleOnEditNewSubCategoryEditBtnEvent(
                                      e,
                                      subcat,
                                      index
                                    );
                                  }}
                                />
                              </div>

                              <div className="doc-subcat-subcontainer-icon">
                                <img
                                  onClick={(e) => {
                                    handleOnEditNewSubCategoryDeleteBtnEvent(
                                      e,
                                      subcat,
                                      index
                                    );
                                  }}
                                  src={imgDelete}
                                />{" "}
                              </div>
                            </>
                          )}

                          {selecetdSubCatId === index &&
                            editSubCatInputErr !== "" && (
                              <span className="error-doc-addedsucat">
                                {editSubCatInputErr}
                              </span>
                            )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Edit image</div>
                <div className="form-group-ct">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      categoryImages(acceptedFiles[0], setEditCategoryImgErr);
                      if (acceptedFiles.length > 0) {
                        setEditCategoryImg(
                          URL.createObjectURL(acceptedFiles[0])
                        );
                        setEditCategoryImgErr("");
                        setFile(acceptedFiles);
                      }
                    }}
                    name="editCatImag"
                    multiple={false}
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    maxSizeBytes="10485760"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="group-info-user cstm-upload-box">
                          <div className="group-info-user-img">
                            <img
                              className={""}
                              alt="Video category"
                              src={
                                editCategoryImg !== undefined &&
                                editCategoryImg !== null
                                  ? editCategoryImg
                                  : Frame
                              }
                            />
                          </div>
                          <div className="group-info-user-link">
                            <div className="cover-image-upload-ttl">
                              Add Image
                            </div>
                          </div>
                          <span className="error">{editCategoryImgErr}</span>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {editInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleEditSubmit}
                >
                  Update Changes
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal Delete */}
      <div
        className="modal fade cstm-delete-popup"
        id="DeleteFromCategory"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {selectedRow.counts !== undefined && selectedRow.counts > 0 ? (
              <div className="reassigncontent">
                <div className="cstm-delete-badge">
                  <div className="cstm-delete-badge-top">
                    <h3 className="popup-title-h3">Delete video category?</h3>
                    <p className="popup-cont-p">
                      {selectedRow.counts}{" "}
                      {selectedRow.counts > 1 ? "videos are" : "video is"}{" "}
                      currently assigned to <b>{selectedRow.name}</b> category.
                      Pick a new category to reassign.
                    </p>
                  </div>
                  <div className="select-a-new">
                    <h5 className="select-a-new-h5">
                      Select a new category for these video*
                    </h5>
                    <select
                      className="select-popup-field"
                      name="selpartner"
                      value={selectedCategory}
                      onChange={(e) => {
                        setSelectedCategory(e.target.value);
                        e.target.value !== ""
                          ? setErr("")
                          : setErr("Select category");
                      }}
                    >
                      <option value="">Select category</option>
                      {reassignedData?.data?.length > 0 &&
                        reassignedData?.data?.map((o) => (
                          <option value={o._id}>{o.name}</option>
                        ))}
                    </select>
                    <span className="error">{err}</span>
                  </div>
                </div>

                <div className="cstm-popup-footer">
                  <button
                    type="button"
                    className="btn-cancel-popup"
                    onClick={() => {
                      setShowDeleteModal(false);
                    }}
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {createInprocess ? (
                    <div className="loader-button1">
                      <button className="btn-blue-popup">In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      disabled={selectedCategory.trim() === ""}
                      type="button"
                      className="btn-blue-popup"
                      onClick={handleDeleteCategory}
                    >
                      Reassign and delete
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="cstm-delete-badge">
                  <div className="cstm-delete-badge-top">
                    <h3 className="popup-title-h3">Delete video category?</h3>
                    <p className="popup-cont-p">
                      Are you sure you want to delete this "{selectedRow.name}"?
                      This action cannot be undone.
                    </p>
                  </div>
                </div>
                <div className="cstm-popup-footer">
                  <button
                    type="button"
                    className="btn-cancel-popup"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {createInprocess ? (
                    <div className="loader-button1">
                      <button className="btn-blue-popup">In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn-delete-popup"
                      onClick={handleDeleteCategory}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <AlertComponent />
    </>
  );
};
export default VideoCategory;
