const medialist = [];

const ReduMediaList = (state = medialist, action) => {
  switch (action.type) {
    case "SETMEDIALIST":
      if (action.payload?.mediaList && action.payload?.totalPages && action.payload.totalPages) {
        return {
          ...state,
          [action.payload.chatid]: {
            "data": action.payload,
            filterType: "Recent"
          }
        };
      }
      else {
        return state;
      }
    case "SETMOREMEDIALIST":
      if (action.payload?.mediaList && action.payload?.totalPages && action.payload.totalPages) {
        return {
          ...state,
          [action.payload.chatid]: {
            "data": { ...action.payload, mediaList: [...state[action.payload.chatid].data.mediaList, ...action.payload.mediaList] },
            filterType: "Recent"
          }
        };
      }
      else {
        return state;
      }
    case "SETFILTERMEDIALIST":
      if (action.payload?.filterType && action.payload?.chatid) {
        if (action.payload.filterType === "Name") {
          return {
            ...state,
            [action.payload.chatid]: {
              "data": { ...state[action.payload.chatid].data, mediaList: state[action.payload.chatid].data.mediaList.sort((a, b) => a.file.split("-GRPCHATUNIQUE-")[1].localeCompare(b.file.split("-GRPCHATUNIQUE-")[1])) },
              filterType: "Name"
            }
          }
        }
        else if (action.payload.filterType === "Date") {
          return {
            ...state,
            [action.payload.chatid]: {
              "data": { ...state[action.payload.chatid].data, mediaList: state[action.payload.chatid].data.mediaList.sort((a, b) => a.createdAt.localeCompare(b.createdAt)).reverse() },
              filterType: "Date"
            }
          }
        }
        else return state;
      }
      else {
        return state;
      }
    case "SETNEWMSGMEDIALIST":
      if (action.payload) {
        var newData = {
          _id: action.payload._id,
          file: action.payload.file,
          file_size: action.payload.file_size,
          createdAt: action.payload.createdAt,
          video_thumbnail: action.payload.video_thumbnail
        }
        if (state[action.payload.chatid] !== undefined) {
          var newMediaList = []
          const currentFilterType = state[action.payload.chatid].filterType;
          if (currentFilterType === "Name") {
            newMediaList = [...state[action.payload.chatid].data.mediaList, newData].sort((a, b) => a.file.split("-GRPCHATUNIQUE-")[1].localeCompare(b.file.split("-GRPCHATUNIQUE-")[1]))
          }
          else {
            newMediaList = [...state[action.payload.chatid].data.mediaList, newData].sort((a, b) => a.createdAt.localeCompare(b.createdAt)).reverse();
          }

          return {
            ...state,
            [action.payload.chatid]: {
              "data": { ...state[action.payload.chatid].data, mediaList: newMediaList, totalMessages: state[action.payload.chatid].data.totalMessages + 1 },
              filterType: currentFilterType
            }
          }
        }
        else {
          return {
            ...state,
            [action.payload.chatid]: {
              "data": { mediaList: [newData], totalMessages: 1 },
              filterType: "Recent"
            }
          };
        }
      }
      return state;
    case "CLEARMEDIALIST":
      if (state[action.payload.chatid]) {
        const newState = state;
        delete newState[action.payload.chatid];
        return newState;
      }
      return state;
    case "DELETEMEDIALIST":
      if (state[action.payload.chatid]) {

        if (state[action.payload.chatid].data.mediaList.length == 1) {
          const newState = state;
          delete newState[action.payload.chatid];
          return newState;
        }
        return {
          ...state,
          [action.payload.chatid]: {
            "data": { ...state[action.payload.chatid].data, mediaList: state[action.payload.chatid].data.mediaList.filter((list) => list._id !== action.payload._id), totalMessages: state[action.payload.chatid].data.totalMessages - 1 },
            filterType: state[action.payload.chatid].filterType
          }
        }
      }
      return state;
    default:
      return state;
  }
};

export default ReduMediaList;