import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InView } from "react-intersection-observer";
import Typography from "../../../../components/Typography/Typography";
import SearchField from "../../../../components/InputField/SearchField";
import FilterIcon from "../../../../components/FilterIcon/FilterIcon";
import Chips from "../../../../components/Chips/Chips";
import DropDown from "../../../../components/DropDown/DropDown";
import DropDownItem from "../../../../components/DropDownItem/DropDownItem";
import SuggestionDropDown from "../../../../components/DropDownItem/SuggestionDropDown";
import EventMediumCard from "../../Components/HelperComponents/EventMediumCard";
import ReloadIcon from "../../../../components/Icons/GeneralIcons/ReloadIcon";
import EventFilterSidebar from "../../Components/HelperComponents/EventFilterSidebar";
import mdsLogoImage from "../../../../Images/logo-image-any-only.jpg";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import IconSearchNoResults from "../../../../components/GlobalComponents/Icons/IconEmptyState/IconSearchNoResults";
import IconNoEventFound from "../../../../components/GlobalComponents/Icons/IconEmptyState/IconNoEventFound";
import Button from "../../../../components/Button/Button";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "../../../../Styles/docManagement.css";
import "../EventStyle/eventHomePage.css";
import {
  useAddEventSearchMutation,
  useEventCategoriesListQuery,
  useEventSuggestionHistoryQuery,
  useEventSuggestionListQuery,
  useMyEventListQuery,
  usePastEventFilterListQuery,
  usePastEventListQuery,
  useRemoveSearchHistoryMutation,
  useUpComingEventListQuery,
  useResultCountQuery,
  useGetAllCitiesQuery,
} from "../../../../Services/eventListing";

const EventHomePage = () => {
  const navigate = useNavigate();

  const location = useLocation().search;
  const typeFromUrl = new URLSearchParams(location).get("type");
  const categoryFromUrl = new URLSearchParams(location).get("category");
  const sortByFromUrl = new URLSearchParams(location).get("sortField");
  const sortTypeFromUrl = new URLSearchParams(location).get("sortType");
  const eventTypeFromUrl = new URLSearchParams(location).get("eventtype");
  const yearFromUrl = new URLSearchParams(location).get("year");
  const search = new URLSearchParams(location).get("search");
  const cityFromUrl = new URLSearchParams(location).get("city");

  const [selectedTab, setSelectedTab] = useState("upcoming");
  const [upcomingData, setUpcomingData] = useState([]);
  const [allUpComingData, setAllUpComingData] = useState([]);
  const [myEventData, setMyEventData] = useState([]);
  const [allMyEventData, setAllMyEventData] = useState([]);
  const [pastEventData, setPastEventData] = useState([]);
  const [allPastEventData, setAllPastEventData] = useState([]);
  const [totalUpComingData, setTotalUpComingData] = useState(0);
  const [totalPastEventData, setTotalPastEventData] = useState(0);
  const [totalMyEventData, setTotalMyEventData] = useState(0);
  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategoriesList, setSubCategoriesList] = useState([
    { name: "All subcategories" },
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const [subCategoryOpen, setSubCategoryOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All categories");
  const [selectedSubCategory, setSelectedSubCategory] =
    useState("All subcategories");
  const [suggestionList, setSuggestionList] = useState([]);
  const [historyList, setHistoryList] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [historySuggestion, setHistorySuggestion] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [finalArr, setFinalArr] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [isFilter, setFilter] = useState(false);
  const [pastEventCurrentPage, setPastEventCurrentPage] = useState(1);
  const [myEventCurrentPage, setMyEventCurrentPage] = useState(1);
  const [upcomingEventCurrentPage, setupcomingEventCurrentPage] = useState(1);
  const [selectedSortBy, setSelectedSortBy] = useState({});
  const [sortBy, setSortBy] = useState("");
  const [sortType, setSortType] = useState("");
  const [selectedCreated, setSelectedCreated] = useState({});
  const [noDataFoundError, setNoDataFoundError] = useState(false);
  const [pastNoDataFoundError, setPastNoDataFoundError] = useState(false);
  const [myEventNoDataFoundError, setMyEventNoDataFoundError] = useState(false);
  const [upcomingNoDataFoundError, setUpcomingNoDataFoundError] =
    useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedEventType, setSelectedEventType] = useState("");
  const [fetchMoreDataLoader, setFetchMoreDataLoader] = useState(false);
  const [finalSearchText, setFinalSearchText] = useState("");
  const [selectCategoryIdFilter, setsSelectCategoryIdFilter] = useState("");
  const [selectSubCategoryIdFilter, setsSelectSubCategoryIdFilter] =
    useState("");
  const [selectedYearFilter, setSelectedYearFilter] = useState("");
  const [selectedEventTypeFilter, setSelectedEventTypeFilter] = useState("");
  const [sortByfilter, setsortByFilter] = useState("");
  const [sortTypeFilter, setSortTypeFilter] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityFilter, setSelectedCityFilter] = useState("");

  const dropdownRef = React.createRef();
  const categoryRef = useRef(null);
  const subCategoryRef = useRef(null);
  const filterRef = useRef(null);

  // sort by list array
  const sortByList = [
    { id: "newest", label: "Newest first" },
    { id: "oldest", label: "Oldest first" },
    { id: "eventa-z", label: "Event name A-Z" },
    { id: "eventz-a", label: "Event name Z-A" },
    { id: "priceLow-high", label: "Price: Low to High" },
    { id: "pricehigh-low", label: "Price: High to Low" },
  ];

  const dateList = [
    { id: "", label: "Anytime" },
    { id: "todays", label: "Today" },
    { id: "past7days", label: "Last 7 days" },
    { id: "past30days", label: "Last 30 days" },
    { id: `thisYear`, label: `This year (${moment().format("YYYY")})` },
    {
      id: `lastYear`,
      label: `Last  year (${moment().subtract(1, "year").format("YYYY")})`,
    },
    { id: "custom", label: "Custom date range" },
  ];

  //event type list
  const eventTypeList = [
    { name: "Any type" },
    { name: "InPerson" },
    { name: "Virtual" },
  ];

  //chips Label array
  const chipsLabel = [
    {
      id: "upcoming",
      label: `Upcoming ${
        totalUpComingData > 0 ? `(${totalUpComingData})` : ""
      }`,
      callBackFunction: () => {
        navigate(
          `/home-event?type=upcoming&category=${categoryFromUrl}&search=${search}&sortField=${sortByFromUrl}&sortType=${sortTypeFromUrl}&eventtype=${eventTypeFromUrl}&city=${cityFromUrl}`
        );
        setSelectedSubCategory("All subcategories");
        setupcomingEventCurrentPage(1);
      },
    },
    {
      id: "myEvents",
      label: `My events ${totalMyEventData > 0 ? `(${totalMyEventData})` : ""}`,
      callBackFunction: () => {
        navigate(
          `/home-event?type=myEvents&category=${categoryFromUrl}&search=${search}&sortField=${sortByFromUrl}&sortType=${sortTypeFromUrl}&city=${cityFromUrl}`
        );
        setSelectedSubCategory("All subcategories");
        setMyEventCurrentPage(1);
      },
    },
    {
      id: "pastEvents",
      label: `Past events ${
        totalPastEventData > 0 ? `(${totalPastEventData})` : ""
      }`,
      callBackFunction: () => {
        navigate(
          `/home-event?type=pastEvents&category=${categoryFromUrl}&search=${search}&sortField=${sortByFromUrl}&sortType=${sortTypeFromUrl}&year=${yearFromUrl}&city=${cityFromUrl}`
        );
        setSelectedSubCategory("All subcategories");
        setPastEventCurrentPage(1);
      },
    },
  ];

  const getCurrentDate = () => {
    const localDateTime = moment(new Date()).utc().toISOString();
    return localDateTime;
  };
  const currentDate = useMemo(() => getCurrentDate(), []);
  //upcoming event list api hook
  const { data: upComingEventRes, isFetching: upcommingLoader } =
    useUpComingEventListQuery({
      params: {
        localDate: currentDate,
        page: upcomingEventCurrentPage,
        limit: 20,
        categoryId: selectedCategoryId,
        subCategoryId: selectedSubCategoryId,
        sortField: sortBy,
        sortType: sortType,
        type:
          selectedEventType === "Event type" || selectedEventType === "Any type"
            ? ""
            : selectedEventType,
        search: finalSearchText,
        city: selectedCity === "Any cities" ? "" : selectedCity,
      },
    });

  //my event list api hook
  const { data: myEventRes, isFetching: myEventLoader } = useMyEventListQuery({
    params: {
      role: "member",
      localDate: currentDate,
      page: myEventCurrentPage,
      limit: 20,
      categoryId: selectedCategoryId,
      subCategoryId: selectedSubCategoryId,
      sortField: sortBy,
      sortType: sortType,
      search: finalSearchText,
      city: selectedCity === "Any cities" ? "" : selectedCity,
    },
  });

  //past event list api hook
  const { data: pastEventRes, isFetching: pastEventLoader } =
    usePastEventListQuery({
      params: {
        localDate: currentDate,
        page: pastEventCurrentPage,
        limit: 20,
        categoryId: selectedCategoryId,
        subCategoryId: selectedSubCategoryId,
        sortField: sortBy,
        sortType: sortType,
        year:
          selectedYear === "Event type" || selectedYear === "Any type"
            ? ""
            : selectedYear,
        search: finalSearchText,
        city: selectedCity === "Any cities" ? "" : selectedCity,
      },
    });

  //past event filter list api hook
  const { data: pastEventFilterList } = usePastEventFilterListQuery({
    params: {
      localDate: currentDate,
    },
  });

  //event category listing
  const { data: categoriesResponse } = useEventCategoriesListQuery({
    params: {
      localDate: currentDate,
    },
  });

  //event suggestion list
  const { data: suggestionRes } = useEventSuggestionListQuery();

  //event suggestion history list
  const { data: historyRes } = useEventSuggestionHistoryQuery();

  //add event search
  const [addEventSearch] = useAddEventSearchMutation();

  //delete search history
  const [removeSearchHistory] = useRemoveSearchHistoryMutation();

  //count query
  const { data: resultCount } = useResultCountQuery({
    params: {
      localDate: currentDate,
      categoryId: selectCategoryIdFilter,
      subCategoryId: selectSubCategoryIdFilter,
      eventType:
        selectedTab === "pastEvents"
          ? "past"
          : selectedTab === "myEvents"
          ? "myevent"
          : selectedTab,
      type:
        selectedEventTypeFilter === "Event type" ||
        selectedEventTypeFilter === "Any type" ||
        selectedTab === "pastEvents" ||
        selectedTab === "myevent"
          ? ""
          : selectedEventTypeFilter,
      year:
        selectedYearFilter === "Event type" ||
        selectedYearFilter === "Any type" ||
        selectedTab === "upcoming" ||
        selectedTab === "myevent"
          ? ""
          : selectedYearFilter,
      city: selectedCityFilter === "Any cities" ? "" : selectedCityFilter,
    },
  });

  //get all cities query
  const { data: getAllCities } = useGetAllCitiesQuery();

  useEffect(() => {
    if (upComingEventRes) {
      upComingEventList();
    }
    if (pastEventRes) {
      pastEventList();
    }
    if (myEventRes) {
      myEventList();
    }
  }, [upComingEventRes, pastEventRes, myEventRes]);

  useEffect(() => {
    if (historyRes?.status) {
      historyListApi();
    }
    if (categoriesResponse?.status) {
      allCategoriesList();
    }
    if (suggestionRes?.status) suggestionListApi();
  }, [historyRes, categoriesResponse, suggestionRes]);

  // past event list and filter list api
  const pastEventList = () => {
    if (pastEventRes?.status) {
      const data = pastEventRes?.data?.pastEvents;
      if (pastEventRes?.data?.currentPage === 1) {
        setPastEventData(data);
      } else {
        setPastEventData([...pastEventData, ...data]);
      }
      setAllPastEventData(data);
      setTotalPastEventData(pastEventRes?.data?.totalEvents);
      setPastEventCurrentPage(pastEventRes?.data?.currentPage);
      if (pastEventRes?.data?.totalEvents <= 0) {
        setPastNoDataFoundError(true);
      } else {
        setPastNoDataFoundError(false);
      }
      setFetchMoreDataLoader(false);
    } else {
      setFetchMoreDataLoader(false);
      setPastNoDataFoundError(true);
      setPastEventData([]);
      setTotalPastEventData(0);
    }
  };

  //upcoming event filter list api
  const upComingEventList = () => {
    if (upComingEventRes?.status) {
      const data = upComingEventRes?.data?.upCommingEvents;
      if (upComingEventRes?.data?.currentPage === 1) {
        setUpcomingData(data);
      } else {
        setUpcomingData([...upcomingData, ...data]);
      }
      setAllUpComingData(data);
      setTotalUpComingData(upComingEventRes?.data?.totalEvents);
      setupcomingEventCurrentPage(upComingEventRes?.data?.currentPage);
      if (upComingEventRes?.data?.totalEvents <= 0) {
        setUpcomingNoDataFoundError(true);
      } else {
        setUpcomingNoDataFoundError(false);
      }
      setFetchMoreDataLoader(false);
    } else {
      setFetchMoreDataLoader(false);
      setUpcomingNoDataFoundError(true);
      setUpcomingData([]);
      setTotalUpComingData(0);
    }
  };

  //my event list api
  const myEventList = () => {
    if (myEventRes?.status) {
      const data = myEventRes?.data;
      if (myEventRes?.currentPage === 1) {
        setMyEventData(data);
      } else {
        setMyEventData([...myEventData, ...data]);
      }
      setAllMyEventData(myEventRes?.data);
      setTotalMyEventData(myEventRes?.data?.length);
      setFetchMoreDataLoader(false);

      if (myEventRes?.data?.length <= 0) {
        setMyEventNoDataFoundError(true);
      } else {
        setMyEventNoDataFoundError(false);
      }
    } else {
      setFetchMoreDataLoader(false);
      setMyEventNoDataFoundError(true);
      setMyEventData([]);
      setTotalMyEventData(0);
    }
  };

  //all category list
  const allCategoriesList = () => {
    if (categoriesResponse?.status) {
      const filteredCategory = categoriesResponse?.data?.map((category) => {
        return {
          id: category._id,
          name: category.categoryName,
          subCategory: category.subcategoryData,
        };
      });
      let arr = [{ name: "All categories" }];
      setCategoriesList([...arr, ...filteredCategory]);
    }
  };

  //suggestion res
  const suggestionListApi = () => {
    if (suggestionRes?.status) {
      let data = suggestionRes.data;
      let arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) =>
          arr.push({
            _id: item._id,
            name: item.title,
            tag: item.tag,
          })
        );
        setSuggestionList(arr);
      }
    }
  };

  //history list
  const historyListApi = () => {
    if (historyRes?.status) {
      let data = historyRes.data;
      let arr = [];
      if (data && data.length !== undefined && data.length !== null) {
        // Use a Set to store unique names
        const uniqueNamesSet = new Set();
        data.map((item) => {
          if (!uniqueNamesSet.has(item.name)) {
            uniqueNamesSet.add(item.name); // Add the name to the set and push the item to the array
            arr.push({
              _id: item._id,
              isHistory: true,
              name: item.name,
              icon: <ReloadIcon />,
            });
          }
        });
        setHistoryList(arr);
        setHistorySuggestion(arr);
      }
    }
  };

  //remove search history
  const removeHistoryApi = async (searchId) => {
    try {
      const removeRes = await removeSearchHistory({ id: searchId });
    } catch (err) {
      console.log("Error:", err);
    }
  };

  useEffect(() => {
    setupcomingEventCurrentPage(1);
    setMyEventCurrentPage(1);
    setPastEventCurrentPage(1);
    setSelectedCategoryId(
      categoryFromUrl &&
        categoryFromUrl !== "null" &&
        categoryFromUrl !== "undefined"
        ? categoryFromUrl
        : ""
    );
    setsSelectCategoryIdFilter(
      categoryFromUrl &&
        categoryFromUrl !== "null" &&
        categoryFromUrl !== "undefined"
        ? categoryFromUrl
        : ""
    );
    setSelectedYear(
      yearFromUrl !== "undefined" &&
        yearFromUrl !== "null" &&
        yearFromUrl !== null
        ? yearFromUrl
        : ""
    );
    setSelectedEventType(
      eventTypeFromUrl !== "undefined" &&
        eventTypeFromUrl !== null &&
        eventTypeFromUrl !== "null"
        ? eventTypeFromUrl
        : ""
    );
    setSortBy(
      sortByFromUrl && sortByFromUrl.trim() !== "null" && sortByFromUrl !== null
        ? sortByFromUrl.trim()
        : ""
    );
    setSortType(
      sortTypeFromUrl && sortTypeFromUrl !== "null" && sortTypeFromUrl !== null
        ? sortTypeFromUrl
        : ""
    );
    setSearchText(
      search && search !== "null" && search !== "undefined" ? search : ""
    );
    setFinalSearchText(
      search && search !== "null" && search !== "undefined" ? search : ""
    );
    setSelectedCity(
      cityFromUrl !== "undefined" &&
        cityFromUrl !== "null" &&
        cityFromUrl !== null
        ? cityFromUrl
        : ""
    );
    setSelectedCityFilter(
      cityFromUrl !== "undefined" &&
        cityFromUrl !== "null" &&
        cityFromUrl !== null
        ? cityFromUrl
        : ""
    );
    filterFromUrl();
  }, []);

  // render when component mount
  useEffect(() => {
    typeFromUrl !== null && setSelectedTab(typeFromUrl.replace(/\/$/, ""));
    setNoDataFoundError(false);
  }, [typeFromUrl]);

  // set the category when we refresh the page
  useEffect(() => {
    const categoryName = categoriesList.filter((category) => {
      if (category.id === categoryFromUrl) {
        return category.name;
      }
    });
    if (categoryName[0]?.name) {
      setSelectedCategory(categoryName[0].name);
    }
  }, [categoriesList]);

  //set subcategory based on category
  useEffect(() => {
    if (selectedCategory !== "All categories") {
      const filteredSubCategoryList = categoriesList.filter((category) => {
        return category.id === categoryFromUrl;
      });
      let arr = [{ name: "All subcategories" }];
      if (filteredSubCategoryList.length > 0) {
        setSubCategoriesList([
          ...arr,
          ...filteredSubCategoryList[0]?.subCategory,
        ]);
      }
    }
  }, [selectedCategory]);

  const updateAppliedFilterCount = () => {
    // Calculate the count based on your state variables
    const count =
      (Object.keys(selectedSortBy).length > 0 ? 1 : 0) +
      (selectedCategory !== "All categories" ? 1 : 0) +
      ((selectedYear !== "" && selectedYear !== "Event type") ||
      (selectedEventType !== "" && selectedEventType !== "Event type")
        ? 1
        : 0) +
      (selectedCity !== "All cities" && selectedCity !== "" ? 1 : 0);
    setSelectedFilter(count);
  };

  useEffect(() => {
    updateAppliedFilterCount();
  }, [
    selectedSortBy,
    selectedCategory,
    selectedYear,
    selectedEventType,
    selectedCity,
  ]);

  // on outside click close category
  const closeMenu = (e) => {
    if (
      categoryRef.current &&
      isOpen &&
      !categoryRef.current.contains(e.target)
    ) {
      setIsOpen(false);
      document.removeEventListener("mousedown", closeMenu);
    }
    if (
      subCategoryRef.current &&
      subCategoryOpen &&
      !subCategoryRef.current.contains(e.target)
    ) {
      setSubCategoryOpen(false);
      document.removeEventListener("mousedown", closeMenu);
    }
  };
  document.addEventListener("mousedown", closeMenu);

  // get sortbyfilter from url
  const filterFromUrl = () => {
    if (
      sortByFromUrl !== null &&
      sortByFromUrl === "createdAt" &&
      sortTypeFromUrl === "Asc"
    ) {
      setSelectedSortBy({ id: "newest", label: "Newest first" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "createdAt" &&
      sortTypeFromUrl === "Dec"
    ) {
      setSelectedSortBy({ id: "oldest", label: "Oldest first" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "eventName" &&
      sortTypeFromUrl === "Dec"
    ) {
      setSelectedSortBy({ id: "eventz-a", label: "Event name Z-A" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "eventName" &&
      sortTypeFromUrl === "Asc"
    ) {
      setSelectedSortBy({ id: "eventa-z", label: "Event name A-Z" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "price" &&
      sortTypeFromUrl === "Dec"
    ) {
      setSelectedSortBy({ id: "pricehigh-low", label: "Price: High to Low" });
    } else if (
      sortByFromUrl !== null &&
      sortByFromUrl === "price" &&
      sortTypeFromUrl === "Asc"
    ) {
      setSelectedSortBy({ id: "priceLow-high", label: "Price: Low to High" });
    }
  };

  // handle sortby
  const handleSortBy = (data, id) => {
    setSelectedSortBy(data);
    if (id === "newest") {
      setsortByFilter("startDate");
      setSortTypeFilter("Dec");
    } else if (id === "oldest") {
      setsortByFilter("startDate");
      setSortTypeFilter("Asc");
    } else if (id === "eventa-z") {
      setsortByFilter("title");
      setSortTypeFilter("Asc");
    } else if (id === "eventz-a") {
      setsortByFilter("title");
      setSortTypeFilter("Dec");
    } else if (id === "priceLow-high") {
      setsortByFilter("price");
      setSortTypeFilter("Asc");
    } else if (id === "pricehigh-low") {
      setsortByFilter("price");
      setSortTypeFilter("Dec");
    } else if (id === "filter") {
      setsortByFilter("");
      setSortTypeFilter("");
    }
  };

  // handle date created function
  const handleDateCreated = (data) => {
    setSelectedCreated(data);
  };

  // handle sub Category select
  const handleSubCategoryFilter = (data) => {
    setSelectedSubCategory(data.name);
    setsSelectSubCategoryIdFilter(data._id);
  };

  // handle Category select
  const handleCategoryFilter = (data) => {
    setSelectedSubCategoryId("");
    setSelectedCategory(data.name);
    setsSelectCategoryIdFilter(data.id);
    navigate(
      `/home-event?type=${typeFromUrl ? typeFromUrl : "upcoming"}&category=${
        data.id
      }&search=${searchText}&sortField=
      ${sortBy}&sortType=${sortType}&eventtype=${selectedEventType}&city=${selectedCity}`
    );
    if (data === "") {
      setSelectedCategory("All categories");
    }
  };

  // handle select event category
  const handleSelectEventCategory = (selected) => {
    navigate(
      `/home-event?type=${typeFromUrl ? typeFromUrl : "upcoming"}&category=${
        selected.id
      }&search=${searchText}&sortField=
      ${sortBy}&sortType=${sortType}&${
        typeFromUrl === "pastEvents" ? "year" : "eventtype"
      }=${
        typeFromUrl === "pastEvents"
          ? selectedYear === "Event type"
            ? ""
            : selectedYear
          : selectedEventType === "Event type"
          ? ""
          : selectedEventType
      }&city=${selectedCity}`
    );
    setSelectedCategory(selected.name);
    setSelectedCategoryId(selected.id);
    setSelectedSubCategory("All subcategories");
    if (selected.name === "All categories") {
      setTotalUpComingData(allUpComingData.length);
      setTotalPastEventData(allPastEventData.length);
      setTotalMyEventData(allMyEventData.length);
    }
    setIsOpen(false);
  };

  //handle select sub category
  const handleSelectSubCategory = (selected) => {
    setSelectedSubCategory(selected.name);
    setSelectedSubCategoryId(selected._id);
    setSubCategoryOpen(false);
  };

  //restructuring data for event medium card
  const handleEventMediumCard = (name, value) => {
    if (name === "eventPrice") {
      return value === 0
        ? "Free"
        : selectedTab === "myEvents" || selectedTab === "upcoming"
        ? "From"
        : "Amount";
    } else if (name === "startDates" || name === "endDates") {
      const uploadedDate = moment(value, "MM-DD-YYYY").local();
      const monthDayCombined = uploadedDate.format("MMM DD");
      return monthDayCombined;
    } else if (name === "startYear" || name === "endYear") {
      const uploadedYear = moment(value, "MM-DD-YYYY").local();
      const momentConvertedYear = uploadedYear.format("YYYY");
      return momentConvertedYear;
    } else if (name === "hasPriceType") {
      var current = momentTimezone(); // Current date and time

      var startDateString = value.startDate || "";
      var startTimeString = (value.startTime || "").toUpperCase();
      var endDateString = value.endDate || "";
      var endTimeString = (value.endTime || "").toUpperCase();
      var timeZone = value.timeZone;

      // Combine date and time strings
      var startDateTimeString = startDateString + " " + startTimeString;
      var endDateTimeString = endDateString + " " + endTimeString;

      // Parse date and time strings using moment.js
      var start = momentTimezone.tz(
        startDateTimeString,
        "MM-DD-YYYY hh:mm A",
        timeZone
      );
      var end = momentTimezone.tz(
        endDateTimeString,
        "MM-DD-YYYY hh:mm A",
        timeZone
      );

      var isEventLive = false;

      if (timeZone && timeZone.trim() !== "") {
        var isPlus = timeZone[4] === "+";
        var time = timeZone.substring(5, 10).split(":");
        var hour = parseInt(time[0], 10);
        var min = parseInt(time[1], 10);

        var duration = moment.duration({ hours: hour, minutes: min });

        var startDate1, endDate1;

        if (isPlus) {
          startDate1 = start.clone().subtract(duration);
          endDate1 = end.clone().subtract(duration);
        } else {
          startDate1 = start.clone().add(duration);
          endDate1 = end.clone().add(duration);
        }

        start = startDate1.local(); // Convert to local time
        end = endDate1.local(); // Convert to local time

        isEventLive = current.isAfter(start) && current.isBefore(end);
      }
      if (isEventLive) {
        return "Live";
      } else if (value.registationFlag) {
        return "Registered";
      } else {
        return "No Badge";
      }
    } else if (name === "hasDatePeriod" || name === "hasStartYear") {
      function parseISODate(dateString) {
        const [year, month, day] = dateString.split("-").map(Number);
        return new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
      }
      const startDate = parseISODate(value.startDate);
      const endDate = parseISODate(value.endDate);
      // Compare dates using UTC timestamps
      const startTime = startDate.getTime();
      const endTime = endDate.getTime();
      return startTime !== endTime;
    }
  };

  const scrollIntoView = (listIndex) => {
    if (dropdownRef.current) {
      dropdownRef.current.children[listIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  //when list index update this useeffect will call
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      let currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        scrollIntoView(listIndex);
        let text = currentList
          .querySelector(".doc-suggestion-dropdown-list h5")
          .innerText.trim();
        setSearchText(text);
      }
    }
  }, [listIndex]);

  // handle search
  const handleSearch = async (text) => {
    setFinalSearchText(text);
    navigate(
      `/home-event?type=${selectedTab}&category=${categoryFromUrl}&search=${text}&sortField=
      ${sortBy}&sortType=${sortType}&${
        typeFromUrl === "pastEvents"
          ? selectedYear === "Event type"
            ? ""
            : selectedYear
          : selectedEventType === "Event type"
          ? ""
          : selectedEventType
      }&city=${cityFromUrl}`
    );

    //api to add text in the search history
    if (text?.length > 0) {
      try {
        const addSearchText = await addEventSearch({ body: { search: text } });
      } catch (err) {
        console.log("Error in search history add", err);
      }
    }
    setSuggestionBox(false);
    setSelectedSortBy({});
    setSelectedCreated({});
  };

  // handle on change searchtext
  // const handleSearchChange = (e) => {
  //   setListIndex(-1);
  //   setSuggestionBox(true);
  //   setSearchText(e.target.value);
  //   setActualsearch(e.target.value);
  //   if (e.target.value) {
  //     updateSuggestionAndHistoryList(e.target.value);
  //   } else {
  //     setSuggestionBox(false);
  //     setSearchHistory(historyList);
  //   }
  // };

  const handleSearchChange = (e) => {
    setListIndex(-1);
    let currentValue = e.target.value;
    setSearchText(currentValue);
    setActualsearch(currentValue);

    if (currentValue === "") {
      setSuggestionBox(true);
      setSearchHistory(historyList);
    } else if (/^\s+/.test(currentValue)) {
      setSuggestionBox(false);
    } else {
      setSuggestionBox(true);
      updateSuggestionAndHistoryList(currentValue);
    }
  };

  // handle clear search filter
  const handleClearSearch = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1500);
    setUpcomingNoDataFoundError(false);
    setMyEventNoDataFoundError(false);
    setPastNoDataFoundError(false);
    navigate(
      `/home-event?type=${typeFromUrl}&category=${categoryFromUrl}&search=&sortField=${sortBy}&sortType=${sortType}&${
        typeFromUrl === "pastEvents" ? "year" : "eventtype"
      }=${
        typeFromUrl === "pastEvents"
          ? selectedYear === "Event type"
            ? ""
            : selectedYear
          : selectedEventType === "Event type"
          ? ""
          : selectedEventType
      }&city=${cityFromUrl}`
    );
    setSearchText("");
    setFinalSearchText("");
  };

  useEffect(() => {
    setFinalArr([...new Set([...searchHistory, ...historySuggestion])]);
  }, [searchHistory, historySuggestion]);

  //when search update the suggestion and history list
  const updateSuggestionAndHistoryList = (searchText) => {
    const lowercasedSearchText = searchText.toLowerCase();

    // Filter suggestions by name or tags
    let updatedSuggestionList = suggestionList.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().includes(lowercasedSearchText) ||
        (suggestion.tag &&
          suggestion.tag.find((tag) =>
            tag.name.toLowerCase().includes(lowercasedSearchText)
          ))
    );
    const startwithItem = updatedSuggestionList.filter(
      (item) =>
        item.name.toLowerCase().startsWith(lowercasedSearchText) ||
        (item.tag &&
          item.tag.find((tag) =>
            tag.name.toLowerCase().startsWith(lowercasedSearchText)
          ))
    );
    const noStartwithItem = updatedSuggestionList.filter(
      (item) =>
        !item.name.toLowerCase().startsWith(lowercasedSearchText) &&
        !(
          item.tag &&
          item.tag.find((tag) =>
            tag.name.toLowerCase().startsWith(lowercasedSearchText)
          )
        )
    );
    setSearchHistory([...startwithItem, ...noStartwithItem]);
    // Update history list
    let updatedsearchHistory = historyList.filter(
      (data) =>
        data.name.toLowerCase().includes(lowercasedSearchText) ||
        (data.tag &&
          data.tag.find((tag) =>
            tag.name.toLowerCase().includes(lowercasedSearchText)
          ))
    );
    const startwithHistory = updatedsearchHistory.filter(
      (item) =>
        item.name.toLowerCase().startsWith(lowercasedSearchText) ||
        (item.tag &&
          item.tag.find((tag) =>
            tag.name.toLowerCase().startsWith(lowercasedSearchText)
          ))
    );
    const noStartwithHistory = updatedsearchHistory.filter(
      (item) =>
        !item.name.toLowerCase().startsWith(lowercasedSearchText) &&
        !(
          item.tag &&
          item.tag.find((tag) =>
            tag.name.toLowerCase().startsWith(lowercasedSearchText)
          )
        )
    );
    setHistorySuggestion([...startwithHistory, ...noStartwithHistory]);
  };

  // call when any key event trigger and use for search
  const handleKeyDown = (e, text) => {
    if (e.key === "Enter") {
      if (e.target.value === "" || e.target.value.match(/^\S/)) {
        e.preventDefault();
        handleSearch(text);
      }
    } else if (e.key === "ArrowDown") {
      const listLength = finalArr.length;
      if (listIndex < listLength - 1) {
        setListIndex(listIndex + 1);
      } else if (listIndex === listLength - 1) {
        setListIndex(-1);
        setSearchText(actualsearch);
      }
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      const listLength = finalArr.length;
      if (listIndex === 0) {
        setListIndex(-1);
        setSearchText(actualsearch);
      } else if (listIndex === -1) {
        setListIndex(listLength - 1);
      } else {
        setListIndex(listIndex - 1);
      }
    }
  };

  // on esc key press this event will call
  const closeModalOnEsc = (e) => {
    if (
      e.code === "Escape" &&
      filterRef.current &&
      isFilter &&
      !filterRef.current.contains(e.target)
    ) {
      setFilter(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", closeModalOnEsc);
    }
    if (e.code === "Escape" && isModal) {
      handleClosePreview();
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", closeModalOnEsc);
    }
    if (e.code === "Escape" && openRestric) {
      setRestrict(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", closeModalOnEsc);
    }
  };

  // handle selecet content
  const handleSelectContent = (name, eventType) => {
    if (eventType === "pastEvent") {
      setSelectedYearFilter(name);
    } else if (eventType === "upcoming") {
      let updatedName =
        name === "Virtual" ? "virtual" : name === "InPerson" ? "inPerson" : "";
      setSelectedEventTypeFilter(updatedName);
    }
  };

  //handle select city
  const handleSelectCity = (name) => {
    setSelectedCityFilter(name);
    navigate(
      `/home-event?type=${
        typeFromUrl ? typeFromUrl : "upcoming"
      }&category=${categoryFromUrl}&search=${searchText}&sortField=${sortBy}&sortType=${sortType}&eventtype=${selectedEventType}&city=${name}`
    );
  };

  // handle final filter
  const handleFilter = () => {
    navigate(
      `/home-event?type=${selectedTab}&category=${categoryFromUrl}&search=${searchText}&sortField=${sortByfilter}&sortType=${sortTypeFilter}&${
        typeFromUrl === "pastEvents" ? "year" : "eventtype"
      }=${
        typeFromUrl === "pastEvents"
          ? selectedYear === "Event type"
            ? ""
            : selectedYearFilter
          : selectedEventType === "Event type"
          ? ""
          : selectedEventTypeFilter
      }&city=${cityFromUrl}`
    );
    setSelectedCategoryId(selectCategoryIdFilter);
    setSelectedYear(selectedYearFilter);
    setSelectedEventType(selectedEventTypeFilter);
    setSelectedSubCategoryId(selectSubCategoryIdFilter);
    setSortBy(sortByfilter);
    setSortType(sortTypeFilter);
    setSelectedCity(selectedCityFilter);
    setPastEventCurrentPage(1);
    setupcomingEventCurrentPage(1);
    setMyEventCurrentPage(1);
    setFilter(false);
  };

  // handle clear all filters
  const handleClearAllFilter = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1500);
    setUpcomingNoDataFoundError(false);
    setMyEventNoDataFoundError(false);
    setPastNoDataFoundError(false);
    navigate(`/home-event?type=${typeFromUrl ? typeFromUrl : selectedTab}`);
    setupcomingEventCurrentPage(1);
    setMyEventCurrentPage(1);
    setPastEventCurrentPage(1);
    setSelectedYear("");
    setSelectedEventType("");
    setSelectedCategoryId("");
    setSearchText("");
    setSelectedCategory("All categories");
    setSubCategoriesList([]);
    setSelectedSortBy({});
    setSelectedCreated({});
    setSelectedSubCategory("All subcategories");
    setSortBy("");
    setSortType("");
    setSelectedSubCategoryId("");
    setSelectedEventTypeFilter("");
    setSelectedYearFilter("");
    setsSelectSubCategoryIdFilter("");
    setsSelectCategoryIdFilter("");
    setsortByFilter("");
    setSortTypeFilter("");
    setFinalSearchText("");
    setSelectedCity("");
    setSelectedCityFilter("");
  };

  //redirect to upcommig event
  const handleRedirectToUpomingEvent = () => {
    navigate(
      `/home-event?type=upcoming&category=${categoryFromUrl}&search=${searchText}&sortField=
      ${sortBy}&sortType=${sortType}&${
        typeFromUrl === "pastEvents"
          ? selectedYear === "Event type"
            ? ""
            : selectedYear
          : selectedEventType === "Event type"
          ? ""
          : selectedEventType
      }&city=${cityFromUrl}`
    );
  };

  // handle more result
  const handleMoreResult = () => {
    if (selectedTab === "pastEvents") {
      setPastEventCurrentPage(pastEventCurrentPage + 1);
    } else if (selectedTab === "upcoming") {
      setupcomingEventCurrentPage(upcomingEventCurrentPage + 1);
    } else if (selectedTab === "myEvents") {
      setMyEventCurrentPage(myEventCurrentPage + 1);
    }
  };
  return (
    <>
      <div className="doc-management-header-main">
        <div className="doc-management-header-main-top">
          <Typography variant="h2">Events</Typography>
          <div
            className="document-search-suggestion"
            onBlur={() => setSuggestionBox(false)}
          >
            <SearchField
              handleFocus={() => setSuggestionBox(true)}
              value={searchText}
              handleChange={handleSearchChange}
              isRounded
              handleKeyDown={(e) => handleKeyDown(e, e.target.value)}
              handleClear={handleClearSearch}
            />
            {suggestionBox && (
              <SuggestionDropDown
                handlePreview={() => {}}
                historyOptions={historySuggestion}
                ref={dropdownRef}
                listIndex={listIndex}
                onMouseDown={(text) => {
                  handleSearch(text.name);
                  setSearchText(text.name);
                }}
                text={searchText}
                id="suggestion-list"
                size="large"
                options={finalArr}
                handleRemove={removeHistoryApi}
              />
            )}
          </div>
        </div>
        <div className="doc-management-header-main-bottom">
          {(upcommingLoader || pastEventLoader || myEventLoader || loader) &&
          !fetchMoreDataLoader ? (
            <div className="doc-management-header-main-bottom">
              <div className="doc-management-header-main-bottom-left">
                <div
                  className="skeleton-box"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    flexShrink: "0",
                  }}
                ></div>
                <div className="doc-management-chips">
                  <div
                    className="skeleton-box"
                    style={{
                      width: "133px",
                      height: "42px",
                      borderRadius: "99px",
                      flexShrink: "0",
                    }}
                  ></div>
                  <div
                    className="skeleton-box"
                    style={{
                      width: "126px",
                      height: "42px",
                      borderRadius: "99px",
                      flexShrink: "0",
                    }}
                  ></div>
                  <div
                    className="skeleton-box"
                    style={{
                      width: "150px",
                      height: "42px",
                      borderRadius: "99px",
                      flexShrink: "0",
                    }}
                  ></div>
                </div>
                <div
                  className="skeleton-box"
                  style={{
                    width: "120px",
                    height: "24px",
                    borderRadius: "99px",
                    flexShrink: "0",
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <div className="doc-management-header-main-bottom-left">
              <FilterIcon
                hasText={false}
                filterText={"Filter"}
                handleClick={() => setFilter(true)}
                label={selectedFilter}
              />
              <div className="doc-management-chips">
                {chipsLabel.map((name) => (
                  <Chips
                    handleClick={() => {
                      if (name.id !== selectedTab) {
                        name.callBackFunction(); // Execute the callback function
                      }
                    }}
                    isActive={name.id === selectedTab}
                    className="large"
                    label={name.label}
                  />
                ))}
              </div>

              <div className="event-management-dropdown" ref={categoryRef}>
                <DropDown
                  label=""
                  selected={selectedCategory}
                  isOpen={isOpen}
                  onOpen={() => setIsOpen(!isOpen)}
                />
                {isOpen && categoriesList?.length > 0 && (
                  <DropDownItem
                    options={categoriesList}
                    size="medium"
                    onSelect={handleSelectEventCategory}
                  />
                )}
              </div>
              {subCategoriesList?.length > 1 &&
                selectedCategory !== "All categories" && (
                  <div
                    className="event-management-dropdown"
                    ref={subCategoryRef}
                  >
                    <DropDown
                      label=""
                      selected={selectedSubCategory}
                      isOpen={subCategoryOpen}
                      onOpen={() => setSubCategoryOpen(!isOpen)}
                    />
                    {subCategoryOpen && subCategoriesList.length > 0 && (
                      <DropDownItem
                        options={subCategoriesList}
                        size="medium"
                        onSelect={handleSelectSubCategory}
                      />
                    )}
                  </div>
                )}
              {selectedCategory !== "All categories" ? (
                <Button
                  label="Clear filters"
                  variant="static-white"
                  handleClick={handleClearAllFilter}
                />
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>

      {(upcommingLoader || pastEventLoader || myEventLoader || loader) &&
      !fetchMoreDataLoader ? (
        <div class="mds-partners-cont-loading">
          {[...Array(20)].map((s) => {
            return (
              <div className="mds-partners-cont-post-loading">
                <div className="mds-partners-cont-post-img-loading skeleton-box"></div>
                <div className="mds-partners-cont-post-title-loading skeleton-box"></div>
                <div className="cstm-partnesh5-loading skeleton-box"></div>
                <div className="cstm-partnesh6-loading-loading skeleton-box"></div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {selectedTab === "upcoming" && (
            <div className="event-medium-cards">
              {upcomingData?.length > 0 ? (
                <>
                  {upcomingData?.map((event, index) => {
                    return (
                      <>
                        {index === upcomingData.length - 1 &&
                        index !== totalUpComingData - 1 ? (
                          <InView
                            onChange={(inView) => {
                              if (inView) {
                                setFetchMoreDataLoader(true);
                                handleMoreResult();
                              }
                            }}
                          >
                            <EventMediumCard
                              eventId={event._id}
                              selectedTab={selectedTab}
                              name={event.title}
                              eventType={`${
                                event?.locationType === "virtual"
                                  ? "Virtual"
                                  : event?.locationType === "inPerson"
                                  ? "In person"
                                  : event?.locationType === "to_be_announce"
                                  ? "To be announced"
                                  : ""
                              }${event?.city ? `,${event?.city}` : ""}${
                                event?.country ? `,${event?.country}` : ""
                              }`}
                              ThumbSrc={
                                event.thumbnail ? event.thumbnail : mdsLogoImage
                              }
                              hasBadge={false}
                              badgeText="New"
                              eventAmount={event.price.toFixed(2)}
                              eventPrice={handleEventMediumCard(
                                "eventPrice",
                                event.price
                              )}
                              hasPriceType={handleEventMediumCard(
                                "hasPriceType",
                                event
                              )}
                              hasPriceDiscount="0"
                              hasDatePeriod={handleEventMediumCard(
                                "hasDatePeriod",
                                event
                              )}
                              hasStartYear={handleEventMediumCard(
                                "hasStartYear",
                                event
                              )}
                              startDates={handleEventMediumCard(
                                "startDates",
                                event.startDate
                              )}
                              startYear={handleEventMediumCard(
                                "startYear",
                                event.startDate
                              )}
                              endDates={handleEventMediumCard(
                                "endDates",
                                event.endDate
                              )}
                              endYear={handleEventMediumCard(
                                "endYear",
                                event.endDate
                              )}
                            />
                          </InView>
                        ) : (
                          <EventMediumCard
                            eventId={event._id}
                            selectedTab={selectedTab}
                            name={event.title}
                            eventType={`${
                              event?.locationType === "virtual"
                                ? "Virtual"
                                : event?.locationType === "inPerson"
                                ? "In person"
                                : event?.locationType === "to_be_announce"
                                ? "To be announced"
                                : ""
                            }${event?.city ? `, ${event?.city}` : ""}${
                              event?.country ? `, ${event?.country}` : ""
                            }`}
                            ThumbSrc={
                              event.thumbnail ? event.thumbnail : mdsLogoImage
                            }
                            hasBadge={false}
                            badgeText="New"
                            eventAmount={event.price.toFixed(2)}
                            eventPrice={handleEventMediumCard(
                              "eventPrice",
                              event.price
                            )}
                            hasPriceType={handleEventMediumCard(
                              "hasPriceType",
                              event
                            )}
                            hasPriceDiscount="0"
                            hasDatePeriod={handleEventMediumCard(
                              "hasDatePeriod",
                              event
                            )}
                            hasStartYear={handleEventMediumCard(
                              "hasStartYear",
                              event
                            )}
                            startDates={handleEventMediumCard(
                              "startDates",
                              event.startDate
                            )}
                            startYear={handleEventMediumCard(
                              "startYear",
                              event.startDate
                            )}
                            endDates={handleEventMediumCard(
                              "endDates",
                              event.endDate
                            )}
                            endYear={handleEventMediumCard(
                              "endYear",
                              event.endDate
                            )}
                          />
                        )}
                      </>
                    );
                  })}
                  {fetchMoreDataLoader &&
                    [...Array(10)].map((s) => {
                      return (
                        <div className="mds-partners-cont-post-loading">
                          <div className="mds-partners-cont-post-img-loading skeleton-box"></div>
                          <div className="mds-partners-cont-post-title-loading skeleton-box"></div>
                          <div className="cstm-partnesh5-loading skeleton-box"></div>
                          <div className="cstm-partnesh6-loading-loading skeleton-box"></div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {upcomingNoDataFoundError &&
                  searchText === "" &&
                  selectedFilter === 0 ? (
                    <EmptyState
                      extraClass="event-empty-state"
                      icon={<IconNoEventFound />}
                      onClick={handleRedirectToUpomingEvent}
                      isClear={false}
                      title="Coming soon!"
                      subTitle="We're busy planning exciting events for you. Keep an eye out for updates!"
                    />
                  ) : (
                    upcomingData.length === 0 &&
                    (searchText !== "" || selectedFilter > 0) && (
                      <EmptyState
                        extraClass="event-empty-state"
                        icon={<IconSearchNoResults />}
                        onClick={handleClearAllFilter}
                        isClear={noDataFoundError ? false : true}
                        title="Oops! No results this time"
                        subTitle="Please adjust your searching filters and give it another go!"
                      />
                    )
                  )}
                </>
              )}
            </div>
          )}
          {selectedTab === "myEvents" && (
            <div className="event-medium-cards">
              {myEventData?.length > 0 ? (
                <>
                  {myEventData?.map((event, index) => {
                    return (
                      <>
                        {index === myEventData.length - 1 &&
                        index !== totalMyEventData - 1 ? (
                          <InView
                            onChange={(inView) => {
                              if (inView) {
                                setFetchMoreDataLoader(true);
                                handleMoreResult();
                              }
                            }}
                          >
                            <EventMediumCard
                              eventId={event._id}
                              selectedTab={selectedTab}
                              name={event.title}
                              eventType={`${
                                event?.locationType === "virtual"
                                  ? "Virtual"
                                  : event?.locationType === "inPerson"
                                  ? "In person"
                                  : event?.locationType === "to_be_announce"
                                  ? "To be announced"
                                  : ""
                              }${event?.city ? `, ${event?.city}` : ""}${
                                event?.country ? `, ${event?.country}` : ""
                              }`}
                              ThumbSrc={
                                event.thumbnail ? event.thumbnail : mdsLogoImage
                              }
                              hasBadge={false}
                              badgeText="New"
                              eventAmount={
                                event.price && event.price.toFixed(2)
                              }
                              eventPrice={handleEventMediumCard(
                                "eventPrice",
                                event.price
                              )}
                              hasPriceType={handleEventMediumCard(
                                "hasPriceType",
                                event
                              )}
                              hasPriceDiscount="0"
                              hasDatePeriod={handleEventMediumCard(
                                "hasDatePeriod",
                                event
                              )}
                              hasStartYear={handleEventMediumCard(
                                "hasStartYear",
                                event
                              )}
                              startDates={handleEventMediumCard(
                                "startDates",
                                event.startDate
                              )}
                              startYear={handleEventMediumCard(
                                "startYear",
                                event.startDate
                              )}
                              endDates={handleEventMediumCard(
                                "endDates",
                                event.endDate
                              )}
                              endYear={handleEventMediumCard(
                                "endYear",
                                event.endDate
                              )}
                            />
                          </InView>
                        ) : (
                          <EventMediumCard
                            eventId={event._id}
                            selectedTab={selectedTab}
                            name={event.title}
                            eventType={`${
                              event?.locationType === "virtual"
                                ? "Virtual"
                                : event?.locationType === "inPerson"
                                ? "In person"
                                : event?.locationType === "to_be_announce"
                                ? "To be announced"
                                : ""
                            }${event?.city ? `, ${event?.city}` : ""}${
                              event?.country ? `, ${event?.country}` : ""
                            }`}
                            ThumbSrc={
                              event.thumbnail ? event.thumbnail : mdsLogoImage
                            }
                            hasBadge={false}
                            badgeText="New"
                            eventAmount={event.price && event.price.toFixed(2)}
                            eventPrice={handleEventMediumCard(
                              "eventPrice",
                              event.price
                            )}
                            hasPriceType={handleEventMediumCard(
                              "hasPriceType",
                              event
                            )}
                            hasPriceDiscount="0"
                            hasDatePeriod={handleEventMediumCard(
                              "hasDatePeriod",
                              event
                            )}
                            hasStartYear={handleEventMediumCard(
                              "hasStartYear",
                              event
                            )}
                            startDates={handleEventMediumCard(
                              "startDates",
                              event.startDate
                            )}
                            startYear={handleEventMediumCard(
                              "startYear",
                              event.startDate
                            )}
                            endDates={handleEventMediumCard(
                              "endDates",
                              event.endDate
                            )}
                            endYear={handleEventMediumCard(
                              "endYear",
                              event.endDate
                            )}
                          />
                        )}
                      </>
                    );
                  })}
                  {fetchMoreDataLoader &&
                    [...Array(10)].map((s) => {
                      return (
                        <div className="mds-partners-cont-post-loading">
                          <div className="mds-partners-cont-post-img-loading skeleton-box"></div>
                          <div className="mds-partners-cont-post-title-loading skeleton-box"></div>
                          <div className="cstm-partnesh5-loading skeleton-box"></div>
                          <div className="cstm-partnesh6-loading-loading skeleton-box"></div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {myEventNoDataFoundError &&
                  searchText === "" &&
                  selectedFilter === 0 ? (
                    <EmptyState
                      extraClass="event-empty-state"
                      icon={<IconNoEventFound />}
                      onClick={handleRedirectToUpomingEvent}
                      isClear={true}
                      title="Room for More Memories"
                      subTitle="Participate in events, and they'll be showcased here."
                      label="See upcoming events"
                    />
                  ) : (
                    myEventData.length === 0 &&
                    (searchText !== "" || selectedFilter > 0) && (
                      <EmptyState
                        extraClass="event-empty-state"
                        icon={<IconSearchNoResults />}
                        onClick={handleClearAllFilter}
                        isClear={noDataFoundError ? false : true}
                        title="Oops! No results this time"
                        subTitle="Please adjust your searching filters and give it another go!"
                      />
                    )
                  )}
                </>
              )}
            </div>
          )}
          {selectedTab === "pastEvents" && (
            <div className="event-medium-cards">
              {pastEventData.length > 0 ? (
                <>
                  {pastEventData?.map((event, index) => {
                    return (
                      <>
                        {index === pastEventData.length - 1 &&
                        index !== totalPastEventData - 1 ? (
                          <InView
                            onChange={(inView) => {
                              if (inView) {
                                setFetchMoreDataLoader(true);
                                handleMoreResult();
                              }
                            }}
                          >
                            <EventMediumCard
                              eventId={event._id}
                              selectedTab={selectedTab}
                              name={event.title}
                              eventType={`${
                                event?.locationType === "virtual"
                                  ? "Virtual"
                                  : event?.locationType === "inPerson"
                                  ? "In person"
                                  : event?.locationType === "to_be_announce"
                                  ? "To be announced"
                                  : ""
                              }${event?.city ? `, ${event?.city}` : ""}${
                                event?.country ? `, ${event?.country}` : ""
                              }`}
                              ThumbSrc={
                                event.thumbnail ? event.thumbnail : mdsLogoImage
                              }
                              hasBadge={false}
                              badgeText="Past"
                              eventAmount={
                                event.price && event.price.toFixed(2)
                              }
                              eventPrice={handleEventMediumCard(
                                "eventPrice",
                                event.price
                              )}
                              hasPriceType="No Badge"
                              hasPriceDiscount="0"
                              hasDatePeriod={handleEventMediumCard(
                                "hasDatePeriod",
                                event
                              )}
                              hasStartYear={handleEventMediumCard(
                                "hasStartYear",
                                event
                              )}
                              startDates={handleEventMediumCard(
                                "startDates",
                                event.startDate
                              )}
                              startYear={handleEventMediumCard(
                                "startYear",
                                event.startDate
                              )}
                              endDates={handleEventMediumCard(
                                "endDates",
                                event.endDate
                              )}
                              endYear={handleEventMediumCard(
                                "endYear",
                                event.endDate
                              )}
                            />
                          </InView>
                        ) : (
                          <EventMediumCard
                            eventId={event._id}
                            selectedTab={selectedTab}
                            name={event.title}
                            eventType={`${
                              event?.locationType === "virtual"
                                ? "Virtual"
                                : event?.locationType === "inPerson"
                                ? "In person"
                                : event?.locationType === "to_be_announce"
                                ? "To be announced"
                                : ""
                            }${event?.city ? `, ${event?.city}` : ""}${
                              event?.country ? `, ${event?.country}` : ""
                            }`}
                            ThumbSrc={
                              event.thumbnail ? event.thumbnail : mdsLogoImage
                            }
                            hasBadge={false}
                            badgeText="Past"
                            eventAmount={event.price && event.price.toFixed(2)}
                            eventPrice={handleEventMediumCard(
                              "eventPrice",
                              event.price
                            )}
                            hasPriceType="No Badge"
                            hasPriceDiscount="0"
                            hasDatePeriod={handleEventMediumCard(
                              "hasDatePeriod",
                              event
                            )}
                            hasStartYear={handleEventMediumCard(
                              "hasStartYear",
                              event
                            )}
                            startDates={handleEventMediumCard(
                              "startDates",
                              event.startDate
                            )}
                            startYear={handleEventMediumCard(
                              "startYear",
                              event.startDate
                            )}
                            endDates={handleEventMediumCard(
                              "endDates",
                              event.endDate
                            )}
                            endYear={handleEventMediumCard(
                              "endYear",
                              event.endDate
                            )}
                          />
                        )}
                      </>
                    );
                  })}
                  {fetchMoreDataLoader &&
                    [...Array(10)].map((s) => {
                      return (
                        <div className="mds-partners-cont-post-loading">
                          <div className="mds-partners-cont-post-img-loading skeleton-box"></div>
                          <div className="mds-partners-cont-post-title-loading skeleton-box"></div>
                          <div className="cstm-partnesh5-loading skeleton-box"></div>
                          <div className="cstm-partnesh6-loading-loading skeleton-box"></div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  {pastNoDataFoundError &&
                  searchText === "" &&
                  selectedFilter === 0 ? (
                    <EmptyState
                      extraClass="event-empty-state"
                      icon={<IconNoEventFound />}
                      onClick={handleRedirectToUpomingEvent}
                      isClear={true}
                      title="Room for More Memories"
                      subTitle="Participate in events, and they'll be showcased here."
                      label="Discover what's next"
                    />
                  ) : (
                    pastEventData.length === 0 &&
                    (searchText !== "" || selectedFilter > 0) && (
                      <EmptyState
                        extraClass="event-empty-state"
                        icon={<IconSearchNoResults />}
                        onClick={handleClearAllFilter}
                        isClear={noDataFoundError ? false : true}
                        title="Oops! No results this time"
                        subTitle="Please adjust your searching filters and give it another go!"
                      />
                    )
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}

      <div className={`${isFilter ? "modal-fadein" : "modal-fadeout"}`}>
        <div className="desk-globle-model-box globle-model-box" ref={filterRef}>
          <EventFilterSidebar
            isFilter={isFilter}
            onCancel={() => setFilter(false)}
            upComingList={eventTypeList}
            pastEventList={pastEventFilterList?.data}
            citiesList={getAllCities?.data}
            selectedTab={selectedTab}
            handleSelectContent={handleSelectContent}
            selectedYear={selectedYearFilter}
            selectedEventType={selectedEventTypeFilter}
            handleFilter={handleFilter}
            handleClearAllFilter={handleClearAllFilter}
            sortBy={sortByList}
            selectedSortBy={selectedSortBy}
            handleSortBy={handleSortBy}
            dateList={dateList}
            handleDateCreated={handleDateCreated}
            selectedCreated={selectedCreated}
            allCategoriesList={categoriesList}
            selectedCategory={selectedCategory}
            selectedSubCategory={selectedSubCategory}
            handleSubCategoryFilter={handleSubCategoryFilter}
            resultCount={resultCount}
            handleCategoryFilter={handleCategoryFilter}
            selectedCity={selectedCityFilter}
            handleSelectCity={handleSelectCity}
          />
        </div>
      </div>
    </>
  );
};

export default EventHomePage;
