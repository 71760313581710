import React from 'react'
import Button from '../../../components/Button/Button'

import "./Document.css"
import "../../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"
const FilterFooter = ({ onCancel, onShow, resultCount }) => {
    return (
        <>
            <div className='filter-sidebar-footer'>
                <Button
                 size='medium'
                    variant='secondary'
                    handleClick={onCancel}
                    label="Clear all"

                >
                     </Button>
                <Button size='medium' variant='primary' handleClick={onShow} 
                
                label={`Show ${resultCount} results`}></Button>
            </div>
        </>
    )
}

FilterFooter.propTypes = {
    onShow: PropTypes.func,
    onCancel: PropTypes.func,
    resultCount: PropTypes.number
}

export default FilterFooter