import { useEffect, useState } from "react";

// Import Api integration file
import Services from "../../Services/auth.service";
//import image
import deletefill from "../../Images/BackendImages/delete-fill.svg";
import AddFillIcon from "remixicon-react/AddFillIcon";
import { Link,Navigate, useNavigate , useSearchParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AlertComponent from "../../Hooks/AlertComponent";
const EditDBField = ({
  selectedFieldId,
  fields,
  fieldindex,
  showDBeditformdiv,
  setshowDBeditformdiv,
}) => {
  const [Inprocess, setInprocess] = useState(false)
  const [showOption, setshowOption] = useState(false);
  const [showaddoptionfield, setshowaddoptionfield] = useState(false);
  const [showInfoDiv, setshowInfoDiv] = useState(
    fields[fieldindex]["info"].length > 0 ? true : false
  );
  const [showvalidDiv, setshowvalidDiv] = useState(
    fields[fieldindex]["min"] > 0 ||
      fields[fieldindex]["max"] > 0 ||
      fields[fieldindex]["required"]
      ? true
      : false
  );
  const [showminmax, setshowminmax] = useState(
    fields[fieldindex]["min"] > 0 || fields[fieldindex]["max"] > 0
      ? true
      : false
  );
  const [showPlaceholder, setshowPlaceholder] = useState(
    fields[fieldindex]["placeholder"].length > 0 ? true : false
  );
  const [formFields, setFormField] = useState(fields[fieldindex]);
  const [fieldoptions, setfieldoptions] = useState(
    fields[fieldindex]["options"]
  );
  const [dbgrid, setdbgrid] = useState(0);
  const [err, setErr] = useState("");
  const [error, setError] = useState({ label: "", placeholder: "", type: "" });
  const [showalert, setshowalert] = useState(false)
  const [alertmsg, setalertmsg] = useState("")
  const FirstName = process.env.REACT_APP_FIRSTNAME_DBID;
  const LastName = process.env.REACT_APP_LASTNAME_DBID;
  const Password = process.env.REACT_APP_PASSWORD_FIELD_DBID;
  const ConfirmPassword = process.env.REACT_APP_CONPASSWORD_FIELD_DBID;
  const EmailAddress = process.env.REACT_APP_EMAIL_FIELD_DBID;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  
  
  // useEffect called when fieldoptions change or setfieldoptions called
  useEffect(() => {
    setFormField({ ...formFields, options: fieldoptions });
  }, [fieldoptions, setfieldoptions]);
  // useEffect called when fields or fieldindex changed
  useEffect(() => {
    setFormField(fields[fieldindex]);
    setfieldoptions(fields[fieldindex]["options"]);
    setdbgrid(fields.length);
  }, [fields, fieldindex]);
  // useEffect called whenever any changes occur in states
  useEffect(() => {}, [
    formFields,
    setFormField,
    setshowDBeditformdiv,
    showDBeditformdiv,
    showOption,
    setshowOption,
    setshowaddoptionfield,
    showaddoptionfield,
    fieldoptions,
    setfieldoptions,
    selectedFieldId,
    showvalidDiv,
    setshowvalidDiv,
    showminmax,
    setshowminmax,
    showPlaceholder,
    setshowPlaceholder,
  ]);
  // useEffect called on first time render
  useEffect(() => {
    dispatch({type:"REMOVEMODALINPROCESS"})
    setfieldoptions(fields[fieldindex]["options"]);
    fields[fieldindex]["options"].length > 0
      ? setshowOption(true)
      : setshowOption(false);
    fields[fieldindex]["info"].length > 0
      ? setshowInfoDiv(true)
      : setshowInfoDiv(false);
    fields[fieldindex]["required"].length > 0 ||
    fields[fieldindex]["min"] > 0 ||
    fields[fieldindex]["max"] > 0
      ? setshowvalidDiv(true)
      : setshowvalidDiv(false);
    fields[fieldindex]["min"] > 0 || fields[fieldindex]["max"] > 0
      ? setshowminmax(true)
      : setshowminmax(false);
    fields[fieldindex]["placeholder"].length > 0
      ? setshowPlaceholder(true)
      : setshowPlaceholder(false);
  }, []);
  // Called when type field change
  const handleType = (e) => {
    switch (e.target.value) {
      case "select":
      case "checkbox":
      case "radio":
        if (e.target.value === "select" || e.target.value === "radio") {
          setshowminmax(false);
          setFormField({ ...formFields, min: 0, max: 0, placeholder: ""  });
        } else {
          setshowminmax(true);
          setFormField({ ...formFields, placeholder: "" });
        }
        setshowPlaceholder(false);
        setshowOption(true);
        break;
      case "date":
      case "time":
      case "file":
        setfieldoptions([]);
        setshowminmax(false);
        setshowOption(false);
        setshowPlaceholder(false);
        setFormField({ ...formFields,min: 0 , max: 0 ,  placeholder: "" , options: [], type: e.target.value });
        break;
      default:
        if (e.target.value === "email") {
          setshowminmax(false);
          setFormField({ ...formFields, min: 0, max: 0 , type: e.target.value});
        } else setshowminmax(true);
        setfieldoptions([]);
        setshowOption(false);
        setshowPlaceholder(true);
        setFormField({ ...formFields, options: [], type: e.target.value });
        break;
    }
    e.target.value.length > 0
      ? setError({ ...error, type: "" })
      : setError({ ...error, type: "Select type!" });
  };
  // Called on input fields change
  const handleOnChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    switch (e.target.name) {
      case "infocheckbox":
        if (showInfoDiv) {
          setshowInfoDiv(false);
          setFormField({ ...formFields, info: "" });
        } else setshowInfoDiv(true);
        break;
      case "required":
        setFormField({ ...formFields, [e.target.name]: e.target.checked });
        break;
      case "validatcheckbox":
        if (showvalidDiv) {
          setFormField({ ...formFields, required: false, min: 0, max: 0 });
          setshowvalidDiv(false);
        } else setshowvalidDiv(true);
        break;
      default:
        setFormField({ ...formFields, [e.target.name]: e.target.value });
        break;
    }
  };
  // Called on click on add buttonif()
  const addoption = (e) => {
    e.preventDefault();
    if (document.getElementById("newoptvalue").value.length > 0) {
      var newarr = fieldoptions;
      newarr.push(document.getElementById("newoptvalue").value);
      setfieldoptions(newarr);
      setshowaddoptionfield(false);
      document.getElementById("newoptvalue").value = "";
      setFormField({ ...formFields, options: fieldoptions });
    }
  };
  // Called on click on delete button
  const handleDeleteOption = (option) => {
    var arr = fieldoptions;
    arr.splice(arr.indexOf(option), 1);
    setfieldoptions(arr);
    setFormField({ ...formFields, options: fieldoptions });
  };
  // Called on click on add option button
  const handleaddoptionbtn = (e) => {
    e.preventDefault();
    setshowaddoptionfield(true);
  };
  // Called on click on update button
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formFields.type !== "select" &&
      (formFields.type !== "checkbox") & (formFields.type !== "radio")
    )
      setFormField({ ...formFields, options: [] });

    if (formFields.label.length > 0 && formFields.type.length > 0) {
      if (
        ((formFields.type === "select" ||
          formFields.type === "checkbox" ||
          formFields.type === "radio") &&
          formFields.options.length > 1) ||
        formFields.placeholder.length > 0 ||
        formFields.type === "date" ||
        formFields.type === "time" ||
        formFields.type === "file"
      ) {
        if (
          formFields.min > 0 &&
          formFields.max > 0 &&
          formFields.max < formFields.min
        ) {
          setErr("Minimum value should be less than maximum value ");
        } else {
          setInprocess(true)
          var arr = fields;
          arr[fieldindex] = formFields;
          var data = {
            id: selectedFieldId,
            field: arr,
          };
          Services.updatefieldregistrationform(data)
            .then(function (response) {
              setInprocess(false)
              e.target.reset();
              setErr("");
              setError({ label: "", type: "", placeholder: "" });
              setshowDBeditformdiv(false);
              setshowalert(true)
              setalertmsg("Form updated successfully!")
              setTimeout(() => {
                setshowalert(false)
                document.getElementById("editDBFieldCloseBtn").click();
              }, 2000);
              
            })
            .catch(function (err) {
             setshowalert(true)
              setalertmsg("Something went wrong!")
              setTimeout(() => {
                setshowalert(false)
              }, 2000);
            });
        }
      } else {
        formFields.placeholder.length > 0 && formFields.options.length < 2
          ? setErr("Please Add Minimum 2 Options")
          : setError({ ...error, placeholder: "Enter placeholder name!" });
      }
    } else {
      formFields.label.length < 1 && formFields.type.length < 1
        ? setError({
            ...error,
            label: "Enter label name!",
            type: "Select type!",
          })
        : formFields.label.length < 1
        ? setError({ ...error, label: "Enter label name!" })
        : setError({ ...error, label: "Select type!" });
    }
  };
  // Called on click on cancel button
  const handleCancel = (e) => {
    setshowDBeditformdiv(false);
    setshowOption(false);
    setfieldoptions([]);
    setshowInfoDiv(false);
    setErr("");
    setError({ label: "", type: "", placeholder: "" });
    document.getElementById("form").reset();
    dispatch({type: "REMOVECUSTOMMODALPOPUPCLOSE"})
    dispatch({type:"REMOVEMODALINPROCESS"})
  };

    //stayonpage  popup for do you want to leave page
    useEffect(()=>{
      if (stayOnPage !== null && stayOnPage)
      { 
        if ($('#editdbfield').hasClass('show'))
          {
            $("#editdbfield").modal('show')
          }
           
        
          
      }
      else if (stayOnPage !== null && !stayOnPage){
        if ($('#editdbfield').hasClass('show'))
        { $("#editdbfield").modal('hide')}
         
        
      }
        
    },[stayOnPage])
  return (
    <>
      {/* Form to edit database fields */}
      <div id="formdiv">
        <form onSubmit={handleSubmit} id="form">
          <div
            className="modal fade edit-user-details-popup edit-main-user-details-popup"
            id="editdbfield"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            data-backdrop="static" 
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="edit-user-details-header">
                  <button
                    type="button"
                    className="close"
                    // data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e)=>{
                      if (modalInProcess)
                      { dispatch({type:"SETCUSTOMMODALPOPUPCLOSE"})
                       }else
                      {
                        $("#editdbfield").modal('hide')
                        handleCancel(e)
                      }
                      
                  
                    }}
                    id="editDBFieldCloseBtn"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {showalert && (
                    <div className="alert alert-info">{alertmsg}</div>
                  )}
                  <span className="error">{err}</span>
                  <div className="form-group">
                    <div className="form-group-label">Label name*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="enter label here"
                        name="label"
                        onChange={handleOnChange}
                        value={formFields["label"]}
                      />
                    </div>
                    <span classNameName="error">{error.label}</span>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">Type*</div>
                    <div className="form-group-ct">
                      <select
                        name="type"
                        placeholder="Type"
                        onChange={handleType}
                        value={formFields["type"]}
                        disabled={
                          formFields["_id"] === FirstName ||
                          formFields["_id"] === LastName ||
                          formFields["_id"] === Password ||
                          formFields["_id"] === ConfirmPassword ||
                          formFields["_id"] === EmailAddress
                            ? true
                            : false
                        }
                      >
                        <option value="">--- Select Type ---</option>
                        <option value="text">Text</option>
                        <option value="select">Select</option>
                        {dbgrid === 1 ? (
                          <>
                            <option value="checkbox">Checkbox</option>
                            <option value="radio">Radio button</option>
                          </>
                        ) : (
                          <></>
                        )}
                        <option value="number">Number</option>
                        <option value="textarea">Textarea</option>
                        <option value="email">Email</option>
                        <option value="time">Time</option>
                        <option value="date">Date</option>
                        <option value="file">Media</option>
                      </select>
                    </div>
                    <span classNameName="error">{error.type}</span>
                  </div>

                  <div
                    className="form-group"
                    style={
                      showPlaceholder
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="form-group-label">Enter placeholder*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="enter placeholder here"
                        name="placeholder"
                        onChange={handleOnChange}
                        value={formFields["placeholder"]}
                      />
                    </div>
                    <span className="error">{error.placeholder}</span>
                  </div>

                  <div
                    className="form-group"
                    id="optiontr"
                    style={
                      showOption || fieldoptions.length > 0
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="admin-added-select-options-main">
                      {fieldoptions.length > 0
                        ? fieldoptions.map((option) => {
                            return (
                              <>
                                <div className="admin-added-select-options-inner">
                                  <div className="form-group-label">
                                    Options
                                  </div>
                                  <div className="form-group-ct">
                                    <div class="form-group-ct cstm-edit-delete">
                                      <input
                                        type="text"
                                        name={option}
                                        value={option}
                                        disabled
                                      />
                                      <div class="cstm-edit-delete-main">
                                        <a
                                          href="#"
                                          onClick={() => {
                                            handleDeleteOption(option);
                                          }}
                                        >
                                          <img src={deletefill} />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ""}
                    </div>

                    <div
                      className="form-group"
                      id="newoption"
                      style={
                        showaddoptionfield
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="form-group-label">Options</div>
                      <input type="text" id="newoptvalue" />
                      <button
                        type="button"
                        onClick={addoption}
                        className="admin-add-op"
                      >
                        Add
                      </button>
                    </div>
                    {showaddoptionfield ? (
                      <></>
                    ) : (
                      <div>
                        <button
                          type="button"
                          onClick={handleaddoptionbtn}
                          className="btn admin-add-option-btn"
                        >
                          <AddFillIcon
                            size="20px"
                            style={{ marginRight: "0px" }}
                          />{" "}
                          Add Option
                        </button>
                      </div>
                    )}
                  </div>

                  <div className="form-group clt-checkbox">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={handleOnChange}
                        name="validatcheckbox"
                        checked={showvalidDiv ? "checked" : ""}
                        disabled={
                          formFields["_id"] === FirstName ||
                          formFields["_id"] === LastName ||
                          formFields["_id"] === Password ||
                          formFields["_id"] === ConfirmPassword ||
                          formFields["_id"] === EmailAddress
                            ? true
                            : false
                        }
                      />
                      Show validation
                    </label>
                  </div>

                  <div
                    style={
                      showvalidDiv ? { display: "block" } : { display: "none" }
                    }
                  >
                    <div className="required-bx">
                      <div className="form-group clt-checkbox">
                        <label className="form-check-label">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="required"
                            onChange={handleOnChange}
                            checked={formFields["required"]}
                            disabled={
                              formFields["_id"] === FirstName ||
                              formFields["_id"] === LastName ||
                              formFields["_id"] === Password ||
                              formFields["_id"] === ConfirmPassword ||
                              formFields["_id"] === EmailAddress
                                ? true
                                : false
                            }
                          />
                          Required
                        </label>
                      </div>
                      <div
                        style={
                          showminmax || showvalidDiv
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <div className="form-group">
                          <div className="form-group-label">Max</div>
                          <div className="form-group-ct">
                            <input
                              type="number"
                              name="max"
                              onChange={handleOnChange}
                              value={formFields["max"]}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">Minimum</div>
                          <div className="form-group-ct">
                            <input
                              type="number"
                              name="min"
                              onChange={handleOnChange}
                              value={formFields["min"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group clt-checkbox">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="infocheckbox"
                        onChange={handleOnChange}
                        checked={
                          showInfoDiv || formFields["info"].length > 0
                            ? "checked"
                            : ""
                        }
                      />
                      Info
                    </label>
                  </div>

                  <div
                    className="form-group"
                    style={
                      showInfoDiv ? { display: "block" } : { display: "none" }
                    }
                  >
                    <div className="form-group-label">Info</div>
                    <div className="form-group-ct">
                      <textarea
                        name="info"
                        onChange={handleOnChange}
                        value={formFields["info"]}
                      >
                        write info
                      </textarea>
                    </div>
                  </div>
                </div>
                <div className="edit-user-details-footer">
                  <button type="submit" className="btn">
                    {Inprocess ? "In Process..." : "Update"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <AlertComponent setcurrentDiv={""} />
    </>
  );
};

export default EditDBField;
