import React from "react";
import ImgEvent from "./../../../../Images/imgvideo.png";
import imgIcon from "./../../../../Images/icn_m_pdf.svg";
import sponsorIcon from "./../../../../Images/heyday-img.png";

const EventThumbnailSmallImage = ({
  thumbUrl = ImgEvent,
  thumbType = "img",
}) => {
  return (
    <div className={`event-thumb-image img-${thumbType}`}>
      {thumbType === "img" && (
        <img
          src={thumbUrl}
          width={128}
          height={72}
          className="img-fluid"
          alt="event-thumb"
        />
      )}
    </div>
  );
};

export default EventThumbnailSmallImage;
