export const emptyObject = {
  name: "",
  description: "",
  priceType: "",
  maxTickets: "",
  minTickets: "",
  free: "",
  paid: "",
  salePrice: "",
  salesValue: "",
  visibility: "",
  saleStart: "",
  saleEnd: "",
  visibilityStartDate: "",
  visibilityEndDate: "",
  image: "",
  mainAmount: "",
  visibilityStartTime: "",
  visibilityEndTime: "",
  salesStartTime: "",
  salesEndTime: "",
  quantity: "",
  maxGuestAllowed: "",
  cancellationDaysBeforeEvent: "",
  addonErrorMessage: "",
  guestTicketErrorMessage: "",
};
