import React from "react";

const IconNavDocFilled = () => {
  return (
    <div className="mds-icons filled">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M2 6.5A4.5 4.5 0 0 1 6.5 2h11A4.5 4.5 0 0 1 22 6.5v11a4.5 4.5 0 0 1-4.5 4.5h-11A4.5 4.5 0 0 1 2 17.5v-11ZM7.25 9A.75.75 0 0 1 8 8.25h7a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 9ZM8 11.25a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5H8ZM7.25 15a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5H8a.75.75 0 0 1-.75-.75Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavDocFilled;
