import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
// Components
import Avtar from "../../../components/Avtar/Avtar";
import OutgoingMessageTime from "./HelperComponets/OutgoingMessageTime";
import Reaction from "./HelperComponets/Reaction";
import IncomingMessageActions from "./HelperComponets/IncomingMessageActions";
import MessageTail from "./HelperComponets/MessageTail";
import OutgoingMessageActions from "./HelperComponets/OutgoingMessageActions";
import IncomingMessageTime from "./HelperComponets/IncomingMessageTime";
import MessageBarReply from "./HelperComponets/MessageBarReply";

// Style
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatMessagesBubble.css";
import "./Styles/ChatMessageReply.css";
import DropDownItem from "../../../components/DropDownItem/DropDownItem";

const ChatMessageReply = ({
  menuSelectedDivRef,
  messageToReplyArray = [],
  messageInArray = [],
  uniqueId = uuidv4(),
  handleClickOnRepliedMessage = () => { },
  // Main Components Prompts
  type = "Channel/Group",
  isOutgoing = false,
  isHighlighted = false,
  isStacked = false,
  hasName = true,
  hasAction = false,
  name = "Jane",
  message = "Hii I am really good.",

  // Avatar
  AvatarType = "photo",
  Avtarsrc = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  AvtarLetter = "M",
  AvtarIcon = "",

  // OutgoingMessageTime
  isRead = true,
  hasBackdrop = false,
  isEdited = false,
  time = "09:12 AM",

  // Actions
  handleReplyClick,
  handleDotsClick,
  handleReactionClick,

  //  Reply Message tyoe
  receiverName = "Bill Gaston",
  srcOfReplayMessage = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  messageToReply = "Really appreciate the and @Wade variations of your recipes. So thoughtful of you! 🌱",
  altReply = "Image",
  MessageReplyType = "MessageReply",
  selectedMessageId,
  handleEmojiClick,
  actionModel,
  setActionModel,
  // reaction
  showAllEmogis,
  toggleShowEmojis,
  initialEmojis,
  reactions,
  setReactions,
  showEmojis,
  showDropdown,
  handleSelectEmoji,
  setShowDropdown,
  setShowEmojis,
  reactionMsg,
  chatId,
  dropDownOptions,
  onSelectOptions,
  chatData,
  hasCounter = true,
  hasReactions = false,
  hasReaction2 = false,
  hasReaction3 = false,
  hasReaction4 = false,
  emoji1 = "👍",
  emoji2 = "😂",
  emoji3 = "👌",
  emoji4 = "🙌",
  counter = 5,
  handleReactionUsersList,
  isInProgress,
  thumbnail,
}) => {
  const dropdownRef = useRef(null);
  const [hoverEffect, setHoverEffect] = useState(false);
  const [dropDownClass, setDropDownClass] = useState("bottom-drop-down");

  const manageMouseEnter = () => {
    setHoverEffect(true);
  };

  const manageMouseLeave = () => {
    if (
      menuSelectedDivRef === null ||
      menuSelectedDivRef.current !== uniqueId
    ) {
      setHoverEffect(false);
    } else if (showEmojis || actionModel) {
      setHoverEffect(true);
    } else {
      setHoverEffect(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      // event.preventDefault();
      let emojiDiv = document.getElementById("REACTION_EMOJIS");
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        emojiDiv &&
        !emojiDiv.contains(event.target) &&
        setActionModel
      ) {
        setHoverEffect(false);
        setActionModel(false);
        setShowEmojis(false);
        setShowDropdown(false);
        menuSelectedDivRef = null;
      }
    };
    function handleScroll(event) {
      // setHoverEffect(false);
    }
    const scrollableDiv = document.getElementById("NEW_MAIN_CHAT_CONTAINER");
    scrollableDiv.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (menuSelectedDivRef?.current === null) {
      setHoverEffect(false);
    }
  }, [menuSelectedDivRef?.current]);
  const handleOptionSelect = (selectedOption) => {
    if (onSelectOptions) {
      onSelectOptions(selectedOption, chatData); // Pass chat data as an argument
    }
  };

  return (
    <div
      id={uniqueId}
      className="chat-message-bubble-sec"
      onMouseEnter={manageMouseEnter}
      onMouseLeave={manageMouseLeave}
      ref={dropdownRef}
    >
      <div
        className={`chat-message-bubble-inner flex chat-messagereply ${type !== "Personal" ? "chat-channel-group" : ""
          } ${!isOutgoing ? "incoming" : "outgoing"} ${isStacked ? "stacked-item" : ""
          }`}
      >
        {isHighlighted && <div className="highlight"></div>}
        <div
          className={`chat-message-container ${hasReactions ? "reaction-on" : ""
            }`}
        >
          <div className="chat-avtar-message flex items-end">
            {type !== "Personal" && !isOutgoing && (
              <div className="chat-avtar flex">
                <Avtar
                  type={AvatarType}
                  src={Avtarsrc}
                  letter={AvtarLetter}
                  icon={AvtarIcon}
                  size="40"
                />
              </div>
            )}
            <div className="chat-body flex items-end relative">
              <div className="chat-tail">
                <MessageTail isOutgoing={isOutgoing} />
              </div>
              <div className={`chat-message ${isStacked ? "stacked" : ""}`}>
                <div className="chat-message-text-sec flex flex-col">
                  {type !== "Personal" && !isOutgoing && hasName && (
                    <p className="chat-avtar-name">{name}</p>
                  )}
                  {/* MessageReply Section */}
                  <div
                    className="chat-message-reply-sec cursor-pointer"
                    onClick={handleClickOnRepliedMessage}
                  >
                    <MessageBarReply
                      handleClickOnRepliedMessage={handleClickOnRepliedMessage}
                      alt={altReply}
                      avtarName={receiverName}
                      handleClick={() => { }}
                      replyText={messageToReply}
                      src={srcOfReplayMessage}
                      thumbnail={thumbnail}
                      type={MessageReplyType}
                      hasRemoveButton={false}
                    />
                  </div>
                  <p className="chat-message-text">
                    {messageInArray.map((msg) => {
                      if (msg.startsWith("@")) {
                        return <span className="mention-users">{msg} </span>;
                      } else {
                        return `${msg} `;
                      }
                    })}
                    <span className="extra-time">
                      {isOutgoing ? (
                        <OutgoingMessageTime
                          isRead={isRead}
                          hasBackdrop={hasBackdrop}
                          isEdited={isEdited}
                          time={time}
                          isInProgress={isInProgress}
                        />
                      ) : (
                        <IncomingMessageTime
                          hasBackdrop={hasBackdrop}
                          isEdited={isEdited}
                          time={time}
                          isInProgress={isInProgress}
                        />
                      )}
                    </span>
                  </p>
                </div>
                <div className="chat-message-time">
                  {isOutgoing ? (
                    <OutgoingMessageTime
                      isRead={isRead}
                      hasBackdrop={hasBackdrop}
                      isEdited={isEdited}
                      time={time}
                      isInProgress={isInProgress}
                    />
                  ) : (
                    <IncomingMessageTime
                      hasBackdrop={hasBackdrop}
                      isEdited={isEdited}
                      time={time}
                      isInProgress={isInProgress}
                    />
                  )}
                </div>
              </div>
              <div className="chat-actions">
                {!isInProgress && (hasAction || hoverEffect) && isOutgoing ? (
                  <OutgoingMessageActions
                    handleDotsClick={(e) => {
                      menuSelectedDivRef.current = uniqueId;

                      handleDotsClick(e);
                      let heights = window.innerHeight - e.pageY;
                      if (heights < 500) {
                        setDropDownClass("top-drop-down");
                      }
                    }}
                    handleReplyClick={handleReplyClick}
                    handleEmojiClick={(e) => {
                      handleEmojiClick(chatId, e);
                      menuSelectedDivRef.current = uniqueId;
                    }}
                    chatId={chatId}
                  />
                ) : !isInProgress && (hasAction || hoverEffect) ? (
                  <IncomingMessageActions
                    handleDotsClick={(e) => {
                      handleDotsClick(e);
                      let heights = window.innerHeight - e.pageY;
                      if (heights < 500) {
                        setDropDownClass("top-drop-down");
                      } else {
                        setDropDownClass("bottom-drop-down");
                      }
                    }}
                    handleReplyClick={handleReplyClick}
                    handleEmojiClick={(e) => {
                      handleEmojiClick(chatId, e);
                      menuSelectedDivRef.current = uniqueId;
                    }}
                    chatId={chatId}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {hasReactions && (
            <div className="chat-reaction">
              <Reaction
                isOutgoing={isOutgoing}
                hasCounter={hasCounter}
                counter={counter}
                handleReactionClick={handleReactionClick}
                // ChatMessagesOneLine={ChatMessagesOneLine}
                handleReactionUsersList={handleReactionUsersList}
                hasReactions={hasReactions}
                hasReaction2={hasReaction2}
                hasReaction3={hasReaction3}
                hasReaction4={hasReaction4}
                emoji1={emoji1}
                emoji2={emoji2}
                emoji3={emoji3}
                emoji4={emoji4}
                chatId={chatId}
              />
            </div>
          )}

          <div className={`chat-action-menu ${dropDownClass}`}>
            {actionModel && hoverEffect && (
              <DropDownItem
                size="small"
                options={dropDownOptions}
                isIcon={true}
                onSelect={handleOptionSelect}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ChatMessageReply.propTypes = {
  type: PropTypes.oneOf(["Channel/Group", "Personal"]),
  // Main Component prompts
  isOutgoing: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  isStacked: PropTypes.bool,
  hasTime: PropTypes.bool,
  hasName: PropTypes.bool,
  hasReactions: PropTypes.bool,
  hasAction: PropTypes.bool,
  name: PropTypes.string,
  message: PropTypes.string,

  // Avatar
  AvatarType: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds"]),
  Avtarsrc: PropTypes.string,
  AvtarLetter: PropTypes.string,
  AvtarIcon: PropTypes.element,

  // OutgoingMessageTime
  isRead: PropTypes.bool,
  hasBackdrop: PropTypes.bool,
  isEdited: PropTypes.bool,
  time: PropTypes.string,

  // Reaction
  hasCounter: PropTypes.bool,
  hasReaction2: PropTypes.bool,
  counter: PropTypes.number,

  // Actions
  handleEmojiClick: PropTypes.func,
  handleReplyClick: PropTypes.func,
  handleDotsClick: PropTypes.func,
  handleReactionClick: PropTypes.func,

  //   Reply Message
  MessageReplyType: PropTypes.oneOf([
    "MessageReply",
    "ImageReply",
    "VideoReply",
    "FileReply",
  ]),
  altReply: PropTypes.string,
  receiverName: PropTypes.string,
  srcOfReplayMessage: PropTypes.string,
  actionModel: PropTypes.any,
  setActionModel: PropTypes.func,
  showAllEmogis: PropTypes.any,
  toggleShowEmojis: PropTypes.func,
  initialEmojis: PropTypes.any,
  reactions: PropTypes.any,
  setReactions: PropTypes.func,
  showEmojis: PropTypes.bool,
  showDropdown: PropTypes.any,
  handleSelectEmoji: PropTypes.func,
  setShowDropdown: PropTypes.func,
  setShowEmojis: PropTypes.func,
  reactionMsg: PropTypes.any,
  chatId: PropTypes.string,
  dropDownOptions: PropTypes.array,
  onSelectOptions: PropTypes.func,
  chatData: PropTypes.object,
  handleReactionUsersList: PropTypes.func,
  isInProgress: PropTypes.bool,
};

export default ChatMessageReply;
