import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import "react-phone-input-2/lib/bootstrap.css"
import Dropzone from "react-dropzone";
import Frame from "../../../../Images/Frame.svg"
import authServicePartner from '../../../../Services/auth.service.partner';
import Loader from '../../../../Images/loader.gif'
import Loader2 from "../../../../Images/loader2.gif";
import AlertComponent from "../../../../Hooks/AlertComponent";
import ImageCropperReact from "../../../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../../../AdminNotification/CommonFunction";

// Partner Banner Support detail component
const MainContent = () => {
    const dispatch = useDispatch()
    // main State
    const [content, setContent] = useState({
        imageWeb: [],
        imageMobile: [],
        bannerTitle: "",
        bannerDescription: "",
        bannerButton: ""
    });
    //error state
    const [error, setError] = useState({
        imageWeb: "",
        imageMobile: "",
        bannerTitle: "",
        bannerDescription: "",
        bannerButton: ""
    });

    //image store state
    const [imageWeb, setImageWeb] = useState([]);
    const [imageMobile, setImageMobile] = useState([])
    // id set state
    const [id, setId] = useState("");
    // response message
    const [message, setMessage] = useState(false);
    // message set state
    const [responseMessage, setResponseMessage] = useState("");
    // loader state
    const [loader, setLoader] = useState(false);
    // inProcess button loader state
    const [inProcess, setInProcess] = useState(false);

    // image cropper states
    const [thumbnail, setThumbnail] = useState([]);
    const [fileDimension, setFileDimension] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [selectedBannerType, setSelectedBannerType] = useState("");
    const [bannerForWebCroppedImage, setBannerForWebCroppedImage] = useState("");
    const [bannerForMobileCroppedImage, setBannerForMobileCroppedImage] = useState("");
    const [webCroppedFileData, setWebCroppedFileData] = useState("");
    const [mobileCroppedFileData, setMobileCroppedFileData] = useState("");

    const [requiredImageSize, setRequiredImageSize] = useState({
        webBannerWidth: 2256,
        webBannerHeight: 760,
        mobileBannerWidth: 780,
        mobileBannerHeight: 500
    });
    const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(16 / 9);

    // first render api calling
    useEffect(() => {
        handleClearAllStates()
        setLoader(true)
        partnerSettingApi();
    }, [])

    // get api function
    async function partnerSettingApi() {
        try {
            const partnerDetailRes = await authServicePartner.getPartnerSetting()
            if (partnerDetailRes.status) {
                partnerDetailRes.data.data.map((item) => {
                    setLoader(false)
                    setId(item._id)
                    setContent({
                        ...item,
                        bannerTitle: item.bannerTitle,
                        bannerDescription: item.bannerDescription,
                        bannerButton: item.bannerButton,
                        imageWeb: process.env.REACT_APP_AWS_IMG_VID_PATH + item.imageWeb,
                        imageMobile: process.env.REACT_APP_AWS_IMG_VID_PATH + item.imageMobile
                    })
                    const thumbnailImg = partnerDetailRes.data.partnerDetail.thumbnail
                    var height, width;
                    const img = new Image();
                    img.src = thumbnailImg
                    img.onload = function () {
                        height = this.height
                        width = this.width
                        setThumbnalHeight(height)
                        setThumbnalWidth(width)
                    }
                })
            } else {
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    // onChange function
    const handleChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        const { name, value } = e.target;
        setContent({ ...content, [name]: value })
        switch (name) {
            case "bannerTitle":
                value.trim() === "" ? setError({ ...error, bannerTitle: "Enter title." }) : setError({ ...error, bannerTitle: "" })
                break;
            case "bannerDescription":
                value.trim() === "" ? setError({ ...error, bannerDescription: "Enter subtitle." }) : setError({ ...error, bannerDescription: "" })
                break;
            case "bannerButton":
                value.trim() === "" ? setError({ ...error, bannerButton: "Enter CTA." }) : setError({ ...error, bannerButton: "" })
                break;
            default:
                break;
        }
    }

    //update partner banner setting api call
    async function updatePartnerApi() {
        setInProcess(true)
        let imageCompressforWeb;
        let imageCompressforMobile;

        const formDataCreatePartner = new FormData();

        if (webCroppedFileData) {
            imageCompressforWeb = await CompressImageFunction(webCroppedFileData);
        }
        imageCompressforWeb && formDataCreatePartner.append("imageWeb", imageCompressforWeb);

        if (mobileCroppedFileData) {
            imageCompressforMobile = await CompressImageFunction(mobileCroppedFileData);
        }
        imageCompressforMobile && formDataCreatePartner.append("imageMobile", imageCompressforMobile);

        formDataCreatePartner.append("bannerTitle", content.bannerTitle);
        formDataCreatePartner.append("bannerDescription", content.bannerDescription);
        formDataCreatePartner.append("bannerButton", content.bannerButton);

        const createPartnerRes = await authServicePartner.partnerSetting(id, formDataCreatePartner)
        setMessage(true)
        setResponseMessage(createPartnerRes.data.message)
        try {
            if (createPartnerRes.status) {
                setInProcess(false)
                setError({
                    imageMobile: "",
                    imageWeb: "",
                    bannerTitle: "",
                    bannerDescription: "",
                    bannerButton: ""
                })
                setTimeout(() => {
                    setMessage(false)
                    setResponseMessage("")
                }, 3000)
            }
            handleClearAllStates()
        } catch (e) {
            console.log(e);
        }
    }

    // handle submit button when user click on save button at that time call
    const handleEditSubmit = (e) => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        if (!content.bannerTitle || !content.bannerDescription || !content.bannerButton) {
            setError({
                bannerTitle: !content.bannerTitle ? 'Enter title.' : '',
                bannerDescription: !content.bannerDescription ? 'Enter subtitle.' : '',
                bannerButton: !content.bannerButton ? 'Enter CTA.' : '',
            })
            return;
        }

        // Check if content.imageWeb[0] is a valid File or Blob
        if (content.imageWeb[0] instanceof File || imageWeb[0] instanceof Blob) {
            const image = new Image();
            image.src = URL.createObjectURL(imageWeb[0]);

            // image.onload = () => {
            //     if (image.width !== 2256 || image.height !== 760) {
            //         setError({ ...error, imageWeb: 'Allow only 2256 x 760' });
            //     } else {
            //         setError({ ...error, imageWeb: '' });
            //         updatePartnerApi();
            //     }
            // };
        } else if (content.imageWeb[0] !== "" && imageWeb[0] !== "") {
            setError({ ...error, imageWeb: '' });
            updatePartnerApi();
        } else {
            // setError({ ...error, imageWeb: 'Allow only 2256 x 760' });
        }
        // Check if content.imagemobile[0] is a valid File or Blob
        if (content.imageMobile[0] instanceof File || imageMobile[0] instanceof Blob) {
            const image = new Image();
            image.src = URL.createObjectURL(imageMobile[0]);

            // image.onload = () => {
            //     if (image.width !== 780 || image.height !== 500) {
            //         setError({ ...error, imageMobile: 'Allow only 780 x 500' });
            //     } else {
            //         setError({ ...error, imageMobile: '' });
            //         updatePartnerApi();
            //     }
            // };
        } else if (content.imageMobile[0] !== "" && imageMobile[0] !== "") {
            setError({ ...error, imageMobile: '' });
            updatePartnerApi();
        } else {
            // setError({ ...error, imageMobile: 'Allow only 780 x 500' });
        }
    }

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "PAGECHANGE", payload: false })
        dispatch({ type: "REMOVEPARTNERINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }
    // thumbnail image upload function
    const thumbnailImages = async (acceptedfile) => {
        setFileDimension(acceptedfile);
        dispatch({ type: "SETMODALINPROCESS" });
        setFileName(Math.random().toString() + acceptedfile.name);
    };
    return (
        <>
            {
                loader ?
                    <div className="cstm-data-edit-load2"><img src={Loader} /></div>
                    :
                    <>
                        <div className="users-info-content">
                            <div className="users-info-content-main">
                                {modalOpen && (
                                    <ImageCropperReact
                                        file={thumbnail}
                                        setModalOpen={setModalOpen}
                                        setcropedFile={(img) => setImageWeb(img)}
                                        setFile={setThumbnail}
                                        fileName={fileName}
                                        fileDimension={fileDimension}
                                        selectedBannerType={selectedBannerType}
                                        requiredImageSize={requiredImageSize}
                                        requiredImageAspectRatio={requiredImageAspectRatio}
                                        setBannerForWebCroppedImage={setBannerForWebCroppedImage}
                                        setBannerForMobileCroppedImage={setBannerForMobileCroppedImage}
                                        setWebCroppedFileData={setWebCroppedFileData}
                                        setMobileCroppedFileData={setMobileCroppedFileData}
                                        isLoading={isLoading}
                                    // setRequiredImageSizeError={() => {
                                    //     if (selectedBannerType === "webBanner") {
                                    //         setError({ ...error, imageWeb: `The minimum required size is ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight} pixels` })
                                    //     } else {
                                    //         setError({ ...error, imageMobile: `The minimum required size is ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight} pixels` })
                                    //     }
                                    // }}
                                    />
                                )}
                                <div className="uic-title">
                                    <h1>Banner</h1>
                                </div>
                                {message && (
                                    <div className="alert alert-info">{responseMessage}</div>
                                )}
                                <div className="group-info-user">
                                    <div className="cstm-video-upload">
                                        {/* web Dropzone */}
                                        <Dropzone
                                            onDrop={(acceptedFiles) => {
                                                setSelectedBannerType("webBanner");
                                                // Check if any of the dropped files are not allowed
                                                const file = acceptedFiles[0];
                                                if (file.type.startsWith("image/")) {
                                                    setModalOpen(true)
                                                    setIsLoading(true)
                                                    thumbnailImages(file);
                                                    const reader = new FileReader();
                                                    reader.onload = () => {setThumbnail(reader.result), setIsLoading(false)};
                                                    reader.readAsDataURL(file);
                                                    setError({ ...error, imageWeb: "" });
                                                    
                                                } else {
                                                    // If the dropped file is not an image, display an error message
                                                    // setError({ ...error, imageWeb: `Allow only ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight}` });
                                                }
                                            }}
                                            name="thumbnail"
                                            multiple={false}
                                            accept="image/jpeg, image/png,  image/tiff, image/jpg"
                                            maxSizeBytes={10485760}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps({ className: "dropzone" })}>
                                                    <input {...getInputProps()} />
                                                    <div className="cstm-video-upload-img channel-img-container">
                                                        {(bannerForWebCroppedImage || content.imageWeb) ? (
                                                            <img className="channel-img w-100 h-100" src={
                                                                bannerForWebCroppedImage ? bannerForWebCroppedImage
                                                                    : content.imageWeb
                                                            } alt="Thumbnail" />
                                                        ) : (
                                                            <img className="empty-img" src={Frame} alt="Placeholder image" />
                                                        )}

                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <span className="error">
                                            {error.imageWeb}
                                        </span>
                                    </div>
                                    <div class="group-info-user-link">
                                        <div class="cover-image-upload-ttl">Upload thumbnail for banner*</div>
                                        <div class="cover-image-upload-size">{`Recommended size ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight}`}</div>
                                    </div>
                                    <div className="cstm-video-upload">
                                        {/* mobile Dropzone */}
                                        <Dropzone
                                            onDrop={(acceptedFiles) => {
                                                setSelectedBannerType("mobileBanner")
                                                // Check if any of the dropped files are not allowed
                                                const file = acceptedFiles[0];
                                                if (file.type.startsWith("image/")) {
                                                    setModalOpen(true)
                                                    setIsLoading(true)
                                                    thumbnailImages(file);
                                                    const reader = new FileReader();
                                                    reader.onload = () => {setThumbnail(reader.result), setIsLoading(false)};
                                                    reader.readAsDataURL(file);
                                                    // setThumbnail(URL.createObjectURL(file));
                                                    setError({ ...error, imageMobile: "" });
                                                } else {
                                                    // If the dropped file is not an image, display an error message
                                                    // setError({ ...error, imageMobile: `Allow only ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight}` });
                                                }
                                            }}
                                            name="thumbnail"
                                            multiple={false}
                                            accept="image/jpeg, image/png,  image/tiff, image/jpg"
                                            maxSizeBytes={10485760}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps({ className: "dropzone" })}>
                                                    <input {...getInputProps()} />

                                                    <div className="cstm-video-upload-img">
                                                        {(bannerForMobileCroppedImage || content.imageMobile) ? (
                                                            <img className="channel-img w-100 h-100"
                                                                src={
                                                                    bannerForMobileCroppedImage ? bannerForMobileCroppedImage
                                                                        : content.imageMobile
                                                                }
                                                            />
                                                        ) : (
                                                            <img className="empty-img" src={Frame} alt="Placeholder image" />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <span className="error">
                                            {error.imageMobile}
                                        </span>
                                    </div>
                                    <div class="group-info-user-link">
                                        <div class="cover-image-upload-ttl">Upload thumbnail for banner*</div>
                                        <div class="cover-image-upload-size">{`Recommended size ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight}`}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 form-group">
                                        <div className="form-group-label">Title*</div>
                                        <div className="form-group-ct">
                                            <input
                                                type="text"
                                                placeholder="Enter title for banner"
                                                name="bannerTitle"
                                                onChange={handleChange}
                                                value={content.bannerTitle}
                                            />
                                        </div>
                                        <span className="error">{error.bannerTitle}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 form-group">
                                        <div className="form-group-label">Sub Title*</div>
                                        <div className="form-group-ct">
                                            <input
                                                type="text"
                                                placeholder="Enter description for banner"
                                                name="bannerDescription"
                                                onChange={handleChange}
                                                value={content.bannerDescription}
                                            />
                                        </div>
                                        <span className="error">{error.bannerDescription}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 form-group">
                                        <div className="form-group-label">CTA*</div>
                                        <div className="form-group-ct">
                                            <input
                                                type="text"
                                                placeholder="Enter Call to Action title for banner"
                                                name="bannerButton"
                                                onChange={handleChange}
                                                value={content.bannerButton}
                                            />
                                        </div>
                                        <span className="error">{error.bannerButton}</span>
                                    </div>
                                </div>
                                {inProcess ? (
                                    <div className="loader-button1">
                                        <button>In Process...</button>
                                        <span className="loader-btn">
                                            <img src={Loader2} />
                                        </span>
                                    </div>
                                ) : (
                                    <span className="uic-fl-search">
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={handleEditSubmit}
                                        >
                                            Save
                                        </button>
                                    </span>)}
                            </div>
                        </div>
                    </>

            }
            <AlertComponent />
        </>
    );
};
export default MainContent;
