import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Import components
import Questions from "./Questions";
import AddQues from "./AddQues";

// Import Api integration file
import Services from "../../Services/auth.service";

const QuestionBackend = () => {
  let history = useNavigate()
  const [showaddques, setShowaddques] = useState(false);
  const [allques, setallques] = useState([]);
  const retriveQues = () => {
    Services.getallquestions()
      .then(function (response) {
        setallques(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // useEffect called on allques change
  useEffect(() => {
    if (
      localStorage.getItem("adminidtoken") === null ||
      localStorage.getItem("adminidtoken").length === 0
    ) {
      history("/adminlogin");
    }  
    retriveQues();
  }, []);
  useEffect(() => {}, [allques]);

  return (
    <div className="cstm-all-admin"> 
      <Questions
        allques={allques}
        setallques={setallques}
        setShowaddques={setShowaddques}
        retriveQues={retriveQues}
      />
      <AddQues
        showaddques={showaddques}
        retriveQues={retriveQues}
        setShowaddques={setShowaddques}
        setallques={setallques}
      />
    </div>
  );
};

export default QuestionBackend;
