import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../Images/loader.gif";
import authServicePartner from "../../../../Services/auth.service.partner";
import imgDelete from "../../../../Images/BackendImages/delete.svg";
import editFill from "../../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../../Images/loader2.gif";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import AlertComponent from "../../../../Hooks/AlertComponent";
import moment from "moment";
import MessageModal from "../../../AdminCommonComponent/MessageModal";
import Frame from "../../../../Images/imgFrame.svg";
import authService from "../../../../Services/auth.service";
import Dropzone from "react-dropzone";
import {
  useReAssignPartnerCategoryQuery,
  useDeletePartnerCategoryMutation,
} from "../../../../Services/partners";
import ImageCropperReact from "../../../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../../../AdminNotification/CommonFunction";
//columns for table
const columns = [
  { id: "categoryImage", label: "Logo" },
  { id: "name", label: "Name" },
  { id: "action", label: "Actions" },
];

function createData(categoryImage, name, action, partnerCategoryCount) {
  return {
    categoryImage,
    name,
    action,
    partnerCategoryCount,
  };
}

const PartnerCategory = () => {
  const [dataLoader, setDataLoader] = useState(false);
  const [categoryAlert, setCategoryAlert] = useState("");
  const [allCategoryPartners, setAllCategoryPartners] = useState([]);
  const [categoryPartnersList, setCategoryPartnersList] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchText, setSearchText] = useState("");
  const [inProcess, setInProcess] = useState(false);
  const [isZoomImg, setIsZoomImg] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [err, setErr] = useState("");
  const [zoomImg, setZoomImg] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const [catgeoryError, setCatgeoryError] = useState("");
  const history = useNavigate();
  const [selCategoryId, setSelCategoryId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [addCategory, setAddCategory] = useState("");
  //   const [editAllNewSubCategory, setEditAllNewSubCategory] = useState([]);
  const [editAllSubCategory, setEditAllSubCategory] = useState([]);
  const [deleteSubCatIds, setdeleteSubCatIds] = useState([]);
  const [addErr, setAddErr] = useState("");
  const [editCategory, setEditCategory] = useState("");
  const [editerr, setediterr] = useState("");
  const [createInprocess, setCreateInprocess] = useState(false);
  const [editInprocess, setEditInprocess] = useState(false);
  const [categoryImg, setCategoryImg] = useState(null);
  const [categoryImgErr, setCategoryImgErr] = useState("");
  const [editCategoryImg, setEditCategoryImg] = useState(null);
  const [editCategoryImgErr, setEditCategoryImgErr] = useState("");
  const [file, setFile] = useState(null);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [actualsearch, setActualsearch] = useState("");
  const [listIndex, setListIndex] = useState(-1);

  //   add subcategory states
  const [selectedSubCatVal, setSelectedSubCatVal] = useState("");
  const [selecetdSubCatId, setSelecetdSubCatId] = useState("");
  const [addSubCatErr, setAddSubCatErr] = useState("");
  const [addSubCatInputErr, setAddSubCatInputErr] = useState("");
  const [addLoader, setAddLoader] = useState(false);

  //   edit subcategory states
  const [editSubCatInputErr, setEditSubCatInputErr] = useState("");
  const [editSubCatErr, setEditSubCatErr] = useState("");
  const [editAllNewSubCategory, setEditAllNewSubCategory] = useState([]);
  const [editLoader, setEditLoader] = useState(false);

  //  image cropping state
  const [modalOpen, setModalOpen] = useState(false);
  const [thumbnail, setThumbnail] = useState([]);
  const [fileDimension, setFileDimension] = useState();
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const [createImageFile, setCreateImageFile] = useState("");
  const [editImageFile, setEditImageFile] = useState("");
  const [createImageFileData, setCreateImageFileData] = useState("");
  const [editImageFileData, setEditImageFileData] = useState("");
  const [actionType, setActionType] = useState("add");



  //called when page loads initially
  useEffect(() => {
    setDataLoader(true);
    getCategoryList();
    handleClearAllStates();
    getallusersSuggestionListApi();
  }, []);

  //reassign parntner category
  const { data: reassignedData } = useReAssignPartnerCategoryQuery({
    id: selCategoryId,
  });
  //delete category
  const [deleteCategory] = useDeletePartnerCategoryMutation();

  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes =
        await authService.partnerCategoriesSuggestionList();

      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.name }));
        setSuggestionList(arr);
      }
    } catch (err) { }
  }

  //get category list
  const getCategoryList = () => {
    authServicePartner
      .categoryList()
      .then((res) => {
        if (res.data.status) {
          var data = res.data.data;
          var tbl = [];
          for (var i = 0; i < data.length; i++) {
            tbl[i] = createData(
              data[i].categoryImage,
              data[i].name,
              data[i]._id,
              data[i].categoryWisePartnerDataCount
            );
          }
          setRows(tbl);
          setAllCategoryPartners(tbl);
          setDataLoader(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //called when add input change
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setAddCategory(e.target.value);
    if (e.target.value.length > 0) {
      setAddErr("");
    } else {
      setAddErr("Enter category! ");
    }
  };
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  //called when category create
  const handleCreate = async () => {
    let imageCompress;
    if (addCategory.length > 0) {
      setCreateInprocess(true);
      const arr = [...editAllNewSubCategory];

      const addCategoryFormData = new FormData();
      addCategoryFormData.append("name", addCategory.trim());

      if (createImageFileData) {
        imageCompress = await CompressImageFunction(createImageFileData);
        addCategoryFormData.append("categoryImage", imageCompress);
      }
      arr.forEach((cat) => addCategoryFormData.append(`subcategory[]`, cat));

      authServicePartner
        .createCategory(addCategoryFormData)
        .then((res) => {
          setCreateInprocess(false);
          if (res.data.status) {
            setCreateImageFileData("")
            setEditAllNewSubCategory([]);
            setFile(null);
            setCategoryImg(null);
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.message);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
              $("#addCategory").modal("hide");
            }, 3000);
            handleClearAllStates();
            setdeleteSubCatIds([]);
            setAddCategory("");
            getCategoryList();
          } else {
            handleClearAllStates();
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.message);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
            }, 3000);
          }
        })
        .catch((e) => {
          setCreateInprocess(false);
          console.log(e);
        });
      setAddErr("");
    } else {
      setAddErr("Enter category!");
    }
  };

  //called when edit category input change
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditCategory(e.target.value);
    if (e.target.value.length > 0) {
      setediterr("");
    } else {
      setediterr("Enter category! ");
    }
  };

  const handleEditSubmit = async () => {
    if (editCategory.length > 0) {
      setEditInprocess(true);
      let arr = [...editAllNewSubCategory];

      editAllSubCategory.map((item) => {
        switch (typeof item) {
          case "object":
            arr.push(item.name);
            break;
          case "string":
            arr.push(item);
            break;
          default:
            break;
        }
      });

      const editCategoryFormdata = new FormData();
      editCategoryFormdata.append("name", editCategory);
      editCategoryFormdata.append("deleteSubCategory", deleteSubCatIds);
      arr.forEach((cat) => editCategoryFormdata.append(`subcategory[]`, cat));

      let imageCompress;
      if (editImageFileData) {
        imageCompress = await CompressImageFunction(editImageFileData);
        editCategoryFormdata.append("categoryImage", imageCompress);
      }

      authServicePartner
        .editCategory(selCategoryId, editCategoryFormdata)
        .then((res) => {
          if (res.data.status) {
            setSearchText("");
            setShowAlertEdit(true);
            setFile(null);
            setEditCategoryImg(null);
            setAlertMsgEdit(res.data.message);
            setEditAllSubCategory([]);
            setEditAllNewSubCategory([]);
            setdeleteSubCatIds([]);
            setEditCategory("");
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
              $("#editCategory").modal("hide");
              setEditImageFile("")
            }, 3000);
            setEditInprocess(false);
            getCategoryList();
          } else {
            setShowAlertEdit(true);
            setAlertMsgEdit(res.data.message);
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
            }, 3000);
          }

          dispatch({ type: "REMOVEMODALINPROCESS" });
          setEditInprocess(false);
        })
        .catch((e) => {
          console.log(e);
          dispatch({ type: "REMOVEMODALINPROCESS" });
          setEditInprocess(false);
        });
      setediterr("");
    } else {
      setediterr("Enter category!");
    }
  };

  //handle drag and reorder rows of partners
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const updatedRows = Array.from(categoryPartnersList);
    const [movedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, movedRow);
    setCategoryPartnersList(updatedRows);
    const ids = updatedRows.map((r) => r._id);

    authServicePartner.reorderCategoryForPartner({ ids });
  };

  //called when category delete
  const handleDeleteCategory = async () => {
    setCreateInprocess(true);
    const bodyData = {
      deletePartnerCategoryId: selCategoryId,
      reassignPartnerCategoryId:
        selectedCategory !== "" ? selectedCategory : null,
    };
    try {
      const deleteCategoryRes = await deleteCategory({ body: bodyData });
      if (deleteCategoryRes?.data?.status) {
        getCategoryList();
        setCreateInprocess(false);
        $("#deleteCategory").modal("hide");
        setShowAlert(true);
        setAlertMsg(deleteCategoryRes?.data?.message);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 3000);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  // get category detail api call
  async function getCategoryById(id) {
    setEditSubCatErr("");
    setAddSubCatErr("");
    setEditModalLoader(true);
    setSelCategoryId(id);
    setCatgeoryError("");
    try {
      const getCategoryByIdRes = await authServicePartner.getCategoryById(id);
      if (getCategoryByIdRes.data.status) {
        setEditModalLoader(false);
        setEditCategory(getCategoryByIdRes.data.data.name);
        setEditCategoryImg(getCategoryByIdRes.data.data.categoryImage);
        setEditAllSubCategory(
          getCategoryByIdRes.data.data.subcategory
            ? getCategoryByIdRes.data.data.subcategory
            : []
        );
      } else {
        setEditModalLoader(false);
      }
    } catch (error) {
      setEditModalLoader(false);
      return false;
    }
  }

  //hadnle zoom for logo
  const handleZoomImage = (e) => {
    setIsZoomImg(true);
    setZoomImg(e.target.src);
  };

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#addCategory").hasClass("show")) {
        $("#addCategory").modal("show");
      }
      if ($("#editCategory").hasClass("show")) {
        $("#editCategory").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#addCategory").hasClass("show")) {
        $("#addCategory").modal("hide");
      }
      if ($("#editCategory").hasClass("show")) {
        $("#editCategory").modal("hide");
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //search functionality
  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    if (allCategoryPartners !== undefined && allCategoryPartners.length > 0) {
      var data = allCategoryPartners.filter((cate) => {
        if (cate.name.includes(text)) {
          return cate;
        }
      });
      var tbl = [];
      for (var i = 0; i < data.length; i++) {
        tbl[i] = createData(
          data[i].categoryImage,
          data[i].name,
          data[i].action,
          data[i].categoryWisePartnerDataCount
        );
      }
      setRows(tbl);
    }
  };


  // --- add category handlers ---


  // Called on click on add button when we add category
  const handleAddSubCategory = async (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const subCatVal = document
      .getElementById("newSubCategoryValue")
      .value.trim();
    if (subCatVal.length > 0) {
      setAddLoader(true);

      if (!editAllNewSubCategory.includes(subCatVal.toLowerCase())) {
        const isSubCategoryExists =
          await authServicePartner.partnerCheckAlredayExistSubCategory({
            name: subCatVal.trim(),
          });
        if (!isSubCategoryExists?.data?.status) {
          setAddLoader(false);
          setAddSubCatErr(isSubCategoryExists?.data?.message);
        } else {
          setEditAllNewSubCategory([...editAllNewSubCategory, subCatVal]);
          document.getElementById("newSubCategoryValue").value = "";
          setAddSubCatErr("");
          setAddLoader(false);
        }
      } else {
        setAddSubCatErr(
          `Subcategory "${subCatVal}" already exists, subcategory must be unique!`
        );
        setAddLoader(false);
      }
    }
  };

  //  Called when add category popup open and change newly added subcategory
  const handleOnAddNewSubCategoryInputChange = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSelectedSubCatVal(e.target.value.trim());
    let subCatsArr = [...editAllNewSubCategory];
    subCatsArr[index] = e.target.value;
    setEditAllNewSubCategory(subCatsArr);
    setAddSubCatInputErr("");
    setAddSubCatErr("");
  };

  //  Called when add category popup open and update newly added subcategory
  const handleOnAddNewSubCategoryUpdateBtnEvent = async (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (selectedSubCatVal.length > 0) {
      if (
        editAllNewSubCategory.filter(
          (item, i) => item.trim() === selectedSubCatVal && i !== index
        ).length > 0
      ) {
        //if ((editAllNewSubCategory[index] !== editedSubCategoryForAdd[index]) && editedSubCategoryForAdd.filter((item, i) => item === editAllNewSubCategory[index] && i !== index).length > 0) {
        setAddSubCatInputErr(
          `Subcategory "${selectedSubCatVal}" already exists. Subcategory must be unique!`
        );
      } else {
        const isSubCategoryExists =
          await authServicePartner.partnerCheckAlredayExistSubCategory({
            name: selectedSubCatVal,
          });
        if (!isSubCategoryExists?.data.status) {
          setAddSubCatInputErr(isSubCategoryExists?.data.message);
        } else {
          let subCatsArr = [...editAllNewSubCategory];
          subCatsArr[index] = selectedSubCatVal;
          setEditAllNewSubCategory(subCatsArr);
          setAddSubCatInputErr("");
          setSelecetdSubCatId(-1);
          setSelectedSubCatVal("");
        }
      }

      let subCatsArr = [...editAllNewSubCategory];
      subCatsArr[index] = selectedSubCatVal;
      setEditAllNewSubCategory(subCatsArr);
      setAddSubCatInputErr("");
      setSelecetdSubCatId(-1);
      setSelectedSubCatVal("");
    } else {
      setAddSubCatInputErr(`Enter subcategory!`);
    }
  };
  //  Called when add category popup open and edit newly added subcategory
  const handleOnAddNewSubCategoryEditBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (addSubCatInputErr.length === 0 && addSubCatErr.length === 0) {
      document.getElementById("newSubCategoryValue").value = "";
      setSelecetdSubCatId(index);
      setSelectedSubCatVal(subcat);
    }
  };

  //  Called when add category popup open and delete newly added subcategory
  const handleOnAddNewSubCategoryDeleteBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (addSubCatInputErr.length === 0 && addSubCatErr.length === 0) {
      setEditAllNewSubCategory([
        ...editAllNewSubCategory.filter((cat, i) => {
          if (i !== index) return cat;
        }),
      ]);
    }
  };

  // --- edit category handlers ---

  //  Called when edit category popup open and change already exists subcategory
  const handleOnEditAlreadyExistsInputChange = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSelectedSubCatVal(e.target.value.trim());
    let subCatsArr = [...editAllSubCategory];
    const i = editAllSubCategory.findIndex(
      (item) => item._id.toString() === subcat._id.toString()
    );
    subCatsArr[i].name = e.target.value;
    setEditAllSubCategory(subCatsArr);
    setEditSubCatErr("");
    setEditSubCatInputErr("");
    setEditSubCatErr("");
  };

  //  Called when edit category popup open and edit already exists subcategory
  const handleOnEditAlreadyExistsEditBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      document.getElementById("newEditSubCatValue").value = "";
      setSelecetdSubCatId(subcat._id.toString());
      setSelectedSubCatVal(subcat.name);
    }
  };

  //  Called when edit category popup open and update already exists subcategory
  const handleOnEditAlreadyExistsUpdateBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (selectedSubCatVal.length > 0) {
      if (
        editAllSubCategory.filter(
          (item) =>
            item._id.toString() !== selecetdSubCatId &&
            item.name.toLowerCase() === selectedSubCatVal.toLowerCase()
        ).length > 0 ||
        editAllNewSubCategory.includes(selectedSubCatVal.toLowerCase())
      ) {
        setEditSubCatInputErr(
          `Subcategory "${selectedSubCatVal}" already exists. Subcategory  must be unique!`
        );
      } else {
        handleAlreadyAddedSubCategory(selectedSubCatVal, subcat, index);
      }
    } else {
      setEditSubCatInputErr(`Enter subcategory!`);
    }
  };

  //  Called when edit category popup open and delete already exists subcategory
  const handleOnEditAlreadyExistsDeleteBtnEvent = (e, subcat, index) => {
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      dispatch({ type: "SETMODALINPROCESS" });
      setEditAllSubCategory([
        ...editAllSubCategory.filter((cat, i) => {
          if (i !== index) return cat;
        }),
      ]);
      setdeleteSubCatIds([...deleteSubCatIds, subcat._id]);
    }
  };

  //  Called when edit category popup open and edit newly added subcategory
  const handleOnEditNewSubCategoryEditBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      document.getElementById("newEditSubCatValue").value = "";
      setSelecetdSubCatId(index);
      setSelectedSubCatVal(subcat);
    }
  };

  //  Called when edit category popup open and change new added subcategory
  const handleOnEditNewSubCategoryInputChange = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSelectedSubCatVal(e.target.value.trim());
    let subCatsArr = [...editAllNewSubCategory];
    subCatsArr[index] = e.target.value;
    setEditAllNewSubCategory(subCatsArr);
    setEditSubCatErr("");
    setEditSubCatInputErr("");
    setAddSubCatInputErr("");
    setAddSubCatErr("");
  };

  //  Called when edit category popup open and update newly added subcategory
  const handleOnEditNewSubCategoryUpdateBtnEvent = async (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (selectedSubCatVal.trim().length > 0) {
      if (
        editAllNewSubCategory.filter(
          (item, i) => item.trim() === selectedSubCatVal.trim() && i !== index
        ).length > 0
      ) {
        setEditSubCatInputErr(
          `Subcategory "${selectedSubCatVal}" already exists. Subcategory must be unique!`
        );
      } else {
        const isSubCategoryExists =
          await authServicePartner.partnerCheckAlredayExistSubCategory({
            name: selectedSubCatVal,
          });
        if (!isSubCategoryExists?.data?.status) {
          setEditSubCatInputErr(isSubCategoryExists?.data?.message);
        } else {
          let subCatsArr = [...editAllNewSubCategory];
          subCatsArr[index] = editAllNewSubCategory[index];
          setEditAllNewSubCategory(subCatsArr);
          setEditSubCatErr("");
          setEditSubCatInputErr("");
          setSelecetdSubCatId(-1);
          setSelectedSubCatVal("");
        }
      }
    } else {
      setEditSubCatInputErr(`Enter subcategory!`);
    }
  };

  //  Called when edit category popup open and delete new added subcategory
  const handleOnEditNewSubCategoryDeleteBtnEvent = (e, subcat, index) => {
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      dispatch({ type: "SETMODALINPROCESS" });
      setEditAllNewSubCategory([
        ...editAllNewSubCategory.filter((cat, i) => {
          if (i !== index) return cat;
        }),
      ]);
      setEditSubCatErr("");
      setEditSubCatInputErr("");
    }
  };

  // Called when editcategory popup open and edit subcategory which already added
  const handleAlreadyAddedSubCategory = (editedSubCate, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const bodayData = {
      name: editedSubCate.trim(),
    };
    authServicePartner
      .editSubCategory(subcat._id, bodayData)
      .then((res) => {
        if (res.data.status) {
          setEditSubCatInputErr("");
          let arrEditAllExists = [...editAllSubCategory];
          const existCatIndex = editAllSubCategory.findIndex(
            (item) => item._id.toString() === subcat._id.toString()
          );
          arrEditAllExists[existCatIndex].name = editedSubCate;
          setEditAllSubCategory(arrEditAllExists);
          setSelecetdSubCatId(-1);
          setSelectedSubCatVal("");
        } else {
          setEditSubCatInputErr(res.data.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Called on click on add button when editcategory popup open
  const handleEditSubCategory = async (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const subCatVal = document
      .getElementById("newEditSubCatValue")
      .value.trim();
    if (subCatVal.length > 0) {
      setEditLoader(true);

      if (!editAllNewSubCategory.includes(subCatVal.toLowerCase())) {
        const isSubCategoryExists =
          await authServicePartner.partnerCheckAlredayExistSubCategory({
            name: subCatVal,
          });
        if (isSubCategoryExists && !isSubCategoryExists?.data?.status) {
          setEditLoader(false);
          setEditSubCatErr(isSubCategoryExists?.data?.message);
        } else {
          setEditLoader(false);
          setEditAllNewSubCategory([...editAllNewSubCategory, subCatVal]);
          document.getElementById("newEditSubCatValue").value = "";
          setEditSubCatErr("");
        }
      } else {
        setEditSubCatErr(
          `Subcategory ${subCatVal} already exists, subcategory must be unique!`
        );
        setEditLoader(false);
      }
    }
  };

  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
  };
  return (
    <>
      <div className="users-info-content">
        {modalOpen && (
          <ImageCropperReact
            file={thumbnail}
            setModalOpen={setModalOpen}
            setFile={setThumbnail}
            fileName={fileName}
            fileDimension={fileDimension}
            setCreateImageFile={setCreateImageFile}
            setEditImageFile={setEditImageFile}
            setCreateImageFileData={setCreateImageFileData}
            setEditImageFileData={setEditImageFileData}
            actionType={actionType}
            isLoading={isLoading}
          />
        )}
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>Categories</h1>
            <div className="uic-fl-btn">
              <button
                className="btn"
                data-toggle="modal"
                data-target="#addCategory"
                onClick={() => {
                  setAddCategory("");
                  setEditAllNewSubCategory([]);
                  setEditAllSubCategory([]);
                  setCategoryImg(null);
                  handleClearAllStates();
                  setActionType("add")
                  setCreateImageFile("")
                  setEditImageFile("")
                  setCreateImageFile("")
                }}
              >
                Add category
              </button>
            </div>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                value={searchText}
                placeholder="Search"
                className="search-user-input"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.name
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox &&
                suggestions.length > 0 &&
                searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.name)}
                          className={
                            index === listIndex ? "selected-active" : ""
                          }
                        >
                          {searchText !== null &&
                            searchText.length > 0 &&
                            suggestion.name
                              .toLowerCase()
                              .startsWith(searchText.toLowerCase()) ? (
                            <>
                              <b>
                                {suggestion.name.slice(0, searchText.length)}
                              </b>
                              {suggestion.name &&
                                suggestion.name.slice(
                                  searchText.length,
                                  suggestion.name.length - 1
                                )}{" "}
                            </>
                          ) : (
                            suggestion.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
            </div>
          </div>
          {showAlert && <div className="alert alert-info">{alertMsg}</div>}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table min-w-auto">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="uic-th-td w-10"
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {dataLoader ? (
                  <div className="cstm-data-load">
                    <img src={Loader} />{" "}
                  </div>
                ) : rows.length > 0 ? (
                  <TableBody className="uic-table-td">
                    {rows.map((row, index) => {
                      return (
                        <TableRow className="uic-th-td w-20">
                          {columns.map((column) => {
                            const value = row[column.id];
                            return column.id === "categoryImage" ? (
                              <TableCell
                                onClick={handleZoomImage}
                                className="uic-th-td w-8"
                              >
                                {value !== undefined &&
                                  value !== null &&
                                  value !== "" ? (
                                  <img
                                    className="cstm-url-category-photo"
                                    src={
                                      process.env.REACT_APP_AWS_IMG_VID_PATH +
                                      value
                                    }
                                    alt="image"
                                  />
                                ) : (
                                  "-"
                                )}
                              </TableCell>
                            ) : column.id === "name" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-17"
                              >
                                <a
                                  data-toggle="modal"
                                  data-target="#editCategory"
                                  onClick={() => {
                                    setEditCategory("");
                                    setEditAllSubCategory([]);
                                    setEditAllNewSubCategory([]);
                                    setEditCategoryImg(null);
                                    getCategoryById(row.action);
                                  }}
                                  className="cstm-view-users-count"
                                >
                                  {value}
                                </a>
                              </TableCell>
                            ) : column.id === "action" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td uic-table-icon w-17"
                              >
                                <a
                                  data-toggle="modal"
                                  data-target="#editCategory"
                                  onClick={() => {
                                    document.getElementById(
                                      "newEditSubCatValue"
                                    ).value = "";
                                    setEditCategory("");
                                    setEditAllSubCategory([]);
                                    setEditAllNewSubCategory([]);
                                    setEditCategoryImg(null);
                                    getCategoryById(value);
                                    setActionType("edit")
                                    setCreateImageFile("")
                                    setEditImageFile("")
                                  }}
                                >
                                  <img src={editFill} />
                                </a>
                                <a
                                  data-toggle="modal"
                                  data-target="#deleteCategory"
                                  onClick={() => {
                                    setSelCategoryId(value);
                                    setShowDeleteModal(true);
                                    setSelectedRow(row);
                                    setSelectedCategory("");
                                    setErr("");
                                  }}
                                >
                                  <img src={imgDelete} />
                                </a>
                              </TableCell>
                            ) : (
                              <></>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <span className="no-data-found">No data found</span>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
      {/* add category modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="addCategory"
        tabIndex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {editModalLoader ? (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          ) : (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Add Category </h2>
                <button
                  type="button"
                  className="close"
                  //data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#addCategory").modal("hide");
                      // handleCloseAddModel(e)
                    }
                  }}
                // onClick={handleCloseAddModel}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertAdd && (
                  <div className="alert alert-info">{alertMsgAdd}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Category name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Name your category"
                      name="category"
                      onChange={handleAddChange}
                      value={addCategory}
                    />
                  </div>
                  <span className="error">{addErr}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Subcategory name</div>

                  {/* +++++ new added field  +++++ */}

                  <div className="form-group">
                    <div className="form-group-ct ">
                      <div
                        className="form-group doc-addsubcat-container-main"
                        id="newSubCat"
                      >
                        <input
                          type="text"
                          id="newSubCategoryValue"
                          disabled={addSubCatInputErr.length > 0}
                          onChange={(e) => {
                            setSelectedSubCatVal("");
                            setSelecetdSubCatId(-1);
                            setAddSubCatErr("");
                          }}
                          onKeyUp={(e) => {
                            setSelectedSubCatVal("");
                            setSelecetdSubCatId(-1);
                            e.key === "Enter" && handleAddSubCategory(e);
                          }}
                        />
                        <div className="cstm-subcategory-loader">
                          {addLoader && (
                            <span className="cstm-loadind-add">
                              <>
                                <img src={Loader} />
                              </>
                            </span>
                          )}
                          <button
                            type="button"
                            onClick={handleAddSubCategory}
                            className="btn"
                            disabled={addSubCatInputErr.length > 0}
                          >
                            Add
                          </button>
                        </div>
                        <span className="error-saubcategor-name">
                          {addSubCatErr}
                        </span>
                      </div>
                    </div>

                    <div className="form-group doc-addedsubcat-container">
                      {editAllNewSubCategory.map((subcat, index) => {
                        return (
                          <>
                            <div
                              className="form-group-ct doc-subcat-subcontainer"
                              onDoubleClick={(e) =>
                                handleOnAddNewSubCategoryEditBtnEvent(
                                  e,
                                  subcat,
                                  index
                                )
                              }
                            >
                              <input
                                type="text"
                                name={index}
                                value={subcat}
                                disabled={selecetdSubCatId !== index}
                                onChange={(e) => {
                                  handleOnAddNewSubCategoryInputChange(
                                    e,
                                    subcat,
                                    index
                                  );
                                }}
                                onBlur={(e) =>
                                  handleOnAddNewSubCategoryUpdateBtnEvent(
                                    e,
                                    subcat,
                                    index
                                  )
                                }
                              />

                              {selecetdSubCatId !== index && (
                                <>
                                  <div className="doc-subcat-subcontainer-icon">
                                    <img
                                      src={editFill}
                                      onClick={(e) => {
                                        handleOnAddNewSubCategoryEditBtnEvent(
                                          e,
                                          subcat,
                                          index
                                        );
                                      }}
                                    />
                                  </div>

                                  <div className="doc-subcat-subcontainer-icon">
                                    <img
                                      onClick={(e) => {
                                        handleOnAddNewSubCategoryDeleteBtnEvent(
                                          e,
                                          subcat,
                                          index
                                        );
                                      }}
                                      src={imgDelete}
                                    />{" "}
                                  </div>
                                </>
                              )}

                              {selecetdSubCatId === index &&
                                addSubCatInputErr !== "" && (
                                  <span className="error-doc-addedsucat">
                                    {addSubCatInputErr}
                                  </span>
                                )}
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  {/* ===(OLD) multi select subCategory functionality === */}

                  {/* <div className="form-group-ct">
                                              <input
                                                  type="text"
                                                  placeholder="Name your subcategory"
                                                  name="subcategory"
                                                  id="subcategory0"
                                                  data-index="0"
                                                  onKeyUp={(e) => {
                                                      dispatch({ type: "SETMODALINPROCESS" })
  
                                                      if (e.key === "Enter" && e.target.value.length > 0 && !editAllNewSubCategory.includes(e.target.value.trim())) {
                                                          setEditAllNewSubCategory([...editAllNewSubCategory, e.target.value.trim()]);
                                                          e.target.value = ""
                                                      }
                                                  }}
  
                                              />
                                              <p>Press enter to add a new subcategory (The name must be unique)</p>
                                              {
                                                  editAllNewSubCategory.map((subcat, index) => {
                                                      return (
                                                          <span className="topic-label-in">
                                                              <b>{subcat}</b>
                                                              <button
                                                                  onClick={() => {
                                                                      setEditAllNewSubCategory([...editAllNewSubCategory.filter((cat, i) => { if (i !== index) return cat; })])
                                                                  }}
                                                              >
                                                                  ×
                                                              </button>
                                                          </span>
                                                      )
                                                  })
                                              }
                                          </div> */}
                </div>
                <div className="form-group">
                  <div className="form-group-label">Add image</div>
                  <div className="form-group-ct">
                    <Dropzone
                      onDrop={(acceptedFiles) => {
                        // Check if any of the dropped files are not allowed
                        const file = acceptedFiles[0];
                        if (file.type.startsWith("image/")) {
                          setModalOpen(true)
                          setIsLoading(true)
                          thumbnailImages(file);
                          const reader = new FileReader();
                          reader.onload = () =>{ setThumbnail(reader.result),setIsLoading(false)};
                          reader.readAsDataURL(file);
                          dispatch({ type: "SETMODALINPROCESS" });
                          dispatch({ type: "SETSTAYONPAGE", payload: null });
                        }
                      }}
                      name="catImag"
                      multiple={false}
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      maxSizeBytes="10485760"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: "dropzone" })}>
                          <input {...getInputProps()} />
                          <div className="group-info-user cstm-upload-box">
                            <div className="group-info-category-img">
                              {createImageFile ?
                                (<img src={createImageFile} />)
                                : (<img src={Frame} />)
                              }
                            </div>
                            <div className="group-info-user-link">
                              <div className="cover-image-upload-ttl">
                                Add Image
                              </div>
                            </div>
                            <span className="error">{categoryImgErr}</span>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {createInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleCreate}
                    disabled={createInprocess}
                  >
                    Add category
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* edit category modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editCategory"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Edit Category</h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#editCategory").modal("hide");
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertEdit && (
                <div className="alert alert-info">{alertMsgEdit}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Category name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Name your category"
                    name="category"
                    onChange={handleEditChange}
                    value={editCategory}
                  />
                </div>
                <span className="error">{editerr}</span>
              </div>
              <div className="form-group">
                <div className="form-group-label">Subcategory name</div>
                <div className="form-group-ct">
                  <div
                    className="form-group doc-addsubcat-container-main"
                    id="newEditSubCat"
                  >
                    <input
                      type="text"
                      id="newEditSubCatValue"
                      disabled={editSubCatInputErr.length > 0}
                      onChange={(e) => {
                        setSelectedSubCatVal("");
                        setSelecetdSubCatId(-1);
                        setEditSubCatErr("");
                      }}
                      onKeyUp={(e) => {
                        setSelectedSubCatVal("");
                        setSelecetdSubCatId(-1);
                        e.key === "Enter" && handleEditSubCategory(e);
                      }}
                    />
                    <div className="cstm-subcategory-loader">
                      {editLoader && (
                        <span className="cstm-loadind-add">
                          <>
                            <img src={Loader} />
                          </>
                        </span>
                      )}
                      <button
                        type="button"
                        onClick={(e) => handleEditSubCategory(e)}
                        className="btn"
                        disabled={editSubCatInputErr.length > 0}
                      >
                        Add
                      </button>
                    </div>

                    <span className="error-saubcategor-name">
                      {editSubCatErr}
                    </span>
                  </div>
                  {/* <input
                      type="text"
                      placeholder="Name your subcategory"
                      name="subcategory"
                      id="subcategory0"
                      data-index="0"
                      onKeyUp={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        dispatch({ type: "SETSTAYONPAGE", payload: null });
                        if (
                          e.key === "Enter" &&
                          e.target.value.length > 0 &&
                          !editAllNewSubCategory.includes(e.target.value.trim()) &&
                          editAllSubCategory.filter((ecat) => {
                            if (ecat.name === e.target.value) return ecat;
                          }).length === 0
                        ) {
                          setEditAllNewSubCategory([
                            ...editAllNewSubCategory,
                            e.target.value.trim()
                          ]);
                          e.target.value = "";
                        }
                      }}
                    /> */}
                  <p>
                    Press enter to add a new subcategory (The name must be
                    unique)
                  </p>
                  <div className="form-group doc-addedsubcat-container">
                    {editAllSubCategory.map((subcat, index) => {
                      return (
                        <>
                          <div
                            className="form-group-ct doc-subcat-subcontainer"
                            onDoubleClick={(e) =>
                              handleOnEditAlreadyExistsEditBtnEvent(e, subcat)
                            }
                          >
                            <input
                              type="text"
                              name={subcat._id}
                              value={subcat.name}
                              disabled={
                                subcat._id.toString() !== selecetdSubCatId
                              }
                              // disabled={!editExistsSubCatInputEnabled[`${subcat._id.toString()}`]}
                              onChange={(e) => {
                                handleOnEditAlreadyExistsInputChange(
                                  e,
                                  subcat,
                                  index
                                );
                              }}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  handleOnEditAlreadyExistsUpdateBtnEvent(
                                    e,
                                    subcat,
                                    index
                                  );
                                }
                              }}
                              onBlur={(e) =>
                                handleOnEditAlreadyExistsUpdateBtnEvent(
                                  e,
                                  subcat,
                                  index
                                )
                              }
                            />

                            {selecetdSubCatId !== subcat._id.toString() && (
                              <>
                                <div className="doc-subcat-subcontainer-icon">
                                  <img
                                    src={editFill}
                                    onClick={(e) => {
                                      handleOnEditAlreadyExistsEditBtnEvent(
                                        e,
                                        subcat,
                                        index
                                      );
                                    }}
                                  />
                                </div>

                                <div className="doc-subcat-subcontainer-icon">
                                  <img
                                    onClick={(e) => {
                                      handleOnEditAlreadyExistsDeleteBtnEvent(
                                        e,
                                        subcat,
                                        index
                                      );
                                    }}
                                    src={imgDelete}
                                  />{" "}
                                </div>
                              </>
                            )}

                            {selecetdSubCatId === subcat._id.toString() &&
                              editSubCatInputErr !== "" && (
                                <span className="error-doc-addedsucat">
                                  {editSubCatInputErr}
                                </span>
                              )}
                          </div>
                        </>
                      );
                    })}
                    {editAllNewSubCategory.map((subcat, index) => {
                      return (
                        <div
                          className="form-group-ct doc-subcat-subcontainer"
                          onDoubleClick={(e) =>
                            handleOnEditNewSubCategoryEditBtnEvent(
                              e,
                              subcat,
                              index
                            )
                          }
                        >
                          <input
                            type="text"
                            name={index}
                            value={subcat}
                            disabled={index !== selecetdSubCatId}
                            onChange={(e) => {
                              handleOnEditNewSubCategoryInputChange(
                                e,
                                subcat,
                                index
                              );
                            }}
                            onBlur={(e) => {
                              handleOnEditNewSubCategoryUpdateBtnEvent(
                                e,
                                subcat,
                                index
                              );
                            }}
                          />

                          {selecetdSubCatId !== index && (
                            <>
                              <div className="doc-subcat-subcontainer-icon">
                                <img
                                  src={editFill}
                                  onClick={(e) => {
                                    handleOnEditNewSubCategoryEditBtnEvent(
                                      e,
                                      subcat,
                                      index
                                    );
                                  }}
                                />
                              </div>

                              <div className="doc-subcat-subcontainer-icon">
                                <img
                                  onClick={(e) => {
                                    handleOnEditNewSubCategoryDeleteBtnEvent(
                                      e,
                                      subcat,
                                      index
                                    );
                                  }}
                                  src={imgDelete}
                                />{" "}
                              </div>
                            </>
                          )}

                          {selecetdSubCatId === index &&
                            editSubCatInputErr !== "" && (
                              <span className="error-doc-addedsucat">
                                {editSubCatInputErr}
                              </span>
                            )}
                        </div>
                      );
                    })}
                  </div>

                  {/* {editAllSubCategory.map((subcat, index) => {
                      subcat, "fdfdf";
                      return (
                        <span className="topic-label-in">
                          <b>{subcat.name}</b>
                          <button
                            onClick={() => {
                              setEditAllSubCategory([
                                ...editAllSubCategory.filter((cat, i) => {
                                  if (i !== index) return cat;
                                })
                              ]);
                              setdeleteSubCatIds([
                                ...deleteSubCatIds,
                                subcat._id
                              ]);
                            }}
                          >
                            ×
                          </button>
                        </span>
                      );
                    })} */}
                  {/* --- this state is for multiselect --- */}
                  {/* {editAllNewSubCategory.map((subcat, index) => {
                      return (
                        <span className="topic-label-in">
                          <b>{subcat}</b>
                          <button
                            onClick={() => {
                              setEditAllNewSubCategory([
                                ...editAllNewSubCategory.filter((cat, i) => {
                                  if (i !== index) return cat;
                                })
                              ]);
                            }}
                          >
                            ×
                          </button>
                        </span>
                      );
                    })} */}
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Edit image</div>
                <div className="form-group-ct">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      // Check if any of the dropped files are not allowed
                      const file = acceptedFiles[0];
                      if (file.type.startsWith("image/")) {
                        setModalOpen(true)
                        setIsLoading(true)
                        thumbnailImages(file);
                        const reader = new FileReader();
                        reader.onload = () =>{ setThumbnail(reader.result),setIsLoading(false)};
                        reader.readAsDataURL(file);
                        dispatch({ type: "SETMODALINPROCESS" });
                        dispatch({ type: "SETSTAYONPAGE", payload: null });
                      }
                    }}
                    name="catImag"
                    multiple={false}
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                    maxSizeBytes="10485760"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="group-info-user cstm-upload-box">
                          <div className="group-info-category-img">
                            {editImageFile ?
                              <img src={editImageFile} />
                              : editCategoryImg ? (<img src={process.env.REACT_APP_AWS_IMG_VID_PATH + editCategoryImg} />)
                                : (<img src={Frame} />)
                            }
                          </div>
                          <div className="group-info-user-link">
                            <div className="cover-image-upload-ttl">
                              Add Image
                            </div>
                          </div>
                          <span className="error">{editCategoryImgErr}</span>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {editInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleEditSubmit}
                >
                  Update Changes
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Modal Delete */}
      <div
        className="modal fade cstm-delete-popup"
        id="deleteCategory"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {selectedRow.partnerCategoryCount !== undefined &&
              selectedRow.partnerCategoryCount > 0 ? (
              <div className="reassigncontent">
                <div className="cstm-delete-badge">
                  <div className="cstm-delete-badge-top">
                    <h3 className="popup-title-h3">Delete partner category?</h3>
                    <p className="popup-cont-p">
                      {selectedRow.partnerCategoryCount}{" "}
                      {selectedRow.partnerCategoryCount > 1
                        ? "partners are"
                        : "partner is"}{" "}
                      currently assigned to <b>{selectedRow.name}</b> category.
                      Pick a new category to reassign.
                    </p>
                  </div>
                  <div className="select-a-new">
                    <h5 className="select-a-new-h5">
                      Select a new category for these partner*
                    </h5>
                    <select
                      className="select-popup-field"
                      name="selpartner"
                      value={selectedCategory}
                      onChange={(e) => {
                        setSelectedCategory(e.target.value);
                        e.target.value !== ""
                          ? setErr("")
                          : setErr("Select category");
                      }}
                    >
                      <option value="">Select category</option>
                      {reassignedData?.data?.length > 0 &&
                        reassignedData?.data?.map((o) => (
                          <option value={o._id}>{o.name}</option>
                        ))}
                    </select>
                    <span className="error">{err}</span>
                  </div>
                </div>

                <div className="cstm-popup-footer">
                  <button
                    type="button"
                    className="btn-cancel-popup"
                    onClick={() => {
                      setShowDeleteModal(false);
                    }}
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {createInprocess ? (
                    <div className="loader-button1">
                      <button className="btn-blue-popup">In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      disabled={selectedCategory.trim() === ""}
                      type="button"
                      className="btn-blue-popup"
                      onClick={handleDeleteCategory}
                    >
                      Reassign and delete
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="cstm-delete-badge">
                  <div className="cstm-delete-badge-top">
                    <h3 className="popup-title-h3">Delete partner category?</h3>
                    <p className="popup-cont-p">
                      Are you sure you want to delete this "{selectedRow.name}"?
                      This action cannot be undone.
                    </p>
                  </div>
                </div>
                <div className="cstm-popup-footer">
                  <button
                    type="button"
                    className="btn-cancel-popup"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                  {createInprocess ? (
                    <div className="loader-button1">
                      <button className="btn-blue-popup">In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn-delete-popup"
                      onClick={handleDeleteCategory}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* zoom image model */}
      {isZoomImg && zoomImg !== null && (
        <div className="desk-globle-model-box globle-model-box cstm-zoom-img-popup cstm-company-logo">
          <div className="desk-globle-model-box-inner cstm-zoom-img-popup">
            <div className="modal-content">
              <div className="cstm-replace-pp">
                <div className="cstm-rpp-title">
                  Logo Image
                  <span
                    className="cstm-rpp-close-btn"
                    onClick={() => setIsZoomImg(false)}
                  >
                    &times;
                  </span>
                </div>

                {zoomImg && (
                  <div id="zoomImgContainer" className="zoomImgContainer">
                    <img id="zoomImg" src={zoomImg} className="cstmzoomImg" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <AlertComponent />
    </>
  );
};

export default PartnerCategory;
