import React from 'react'
import PropTypes from "prop-types"
const UploadIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M12 7.342c.207 0 .406.095.547.256l2.9 3.152a.861.861 0 0 1-.001 1.149.71.71 0 0 1-1.06-.003L12.75 10.12v11.07c0 .447-.336.811-.75.811s-.75-.364-.75-.812V10.12l-1.635 1.777a.71.71 0 0 1-1.06.002.861.861 0 0 1-.002-1.148l2.9-3.15A.725.725 0 0 1 12 7.341ZM6.435 2h11.14c2.206 0 4.041 1.93 4.372 4.252.058.41-.283.748-.697.748s-.743-.34-.83-.744c-.306-1.438-1.466-2.632-2.845-2.632H6.435c-1.386 0-2.55 1.19-2.856 2.631-.086.406-.415.745-.83.745-.413 0-.754-.337-.696-.748C2.383 3.925 4.223 2 6.435 2Z"
                />
                <path
                    fill={iconColor}
                    d="M12 7.342c.207 0 .406.095.547.256l2.9 3.152a.861.861 0 0 1-.001 1.149.71.71 0 0 1-1.06-.003L12.75 10.12v11.07c0 .447-.336.811-.75.811s-.75-.364-.75-.812V10.12l-1.635 1.777a.71.71 0 0 1-1.06.002.861.861 0 0 1-.002-1.148l2.9-3.15A.725.725 0 0 1 12 7.341ZM6.435 2h11.14c2.206 0 4.041 1.93 4.372 4.252.058.41-.283.748-.697.748s-.743-.34-.83-.744c-.306-1.438-1.466-2.632-2.845-2.632H6.435c-1.386 0-2.55 1.19-2.856 2.631-.086.406-.415.745-.83.745-.413 0-.754-.337-.696-.748C2.383 3.925 4.223 2 6.435 2Z"
                />
            </svg>
        </>
    )
}
UploadIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default UploadIcon
