import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import authServiceDocument from '../../../../Services/auth.service.document';
import Loader2 from "../../../../Images/loader2.gif";

const AddContentType = ({ recallApi, id = 'addContentType' }) => {
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const dispatch = useDispatch()
    const [createInprocess, setCreateInprocess] = useState(false);
    const [showAlertAdd, setShowAlertAdd] = useState(false);
    const [alertMsgAdd, setAlertMsgAdd] = useState("");
    const [addErr, setAddErr] = useState("");
    const [addContentType, setAddContentType] = useState("");

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }

    //called when add input change
    const handleAddTypeChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        setAddContentType(e.target.value);
        if (e.target.value.length > 0) {
            setAddErr("")
        } else {
            setAddErr("Enter content type! ")
        }
    };

    //called when category create
    const handleCreateType = () => {
        if (addContentType.length > 0) {

            setCreateInprocess(true);
            const addBodyData = {
                name: addContentType,

            }
            authServiceDocument.createContentType(addBodyData)
                .then((res) => {
                    console.log(res.data, "111111")

                    setCreateInprocess(false);
                    if (res.data.data.status) {
                        setShowAlertAdd(true);
                        setAlertMsgAdd(res.data.data.message);
                        setTimeout(() => {
                            setShowAlertAdd(false);
                            setAlertMsgAdd("");
                            document.querySelector('#addContentType .close').click()
                        }, 3000);
                        handleClearAllStates()
                        setAddContentType("");
                        recallApi()

                    } else {
                        handleClearAllStates()
                        setShowAlertAdd(true);
                        setAlertMsgAdd(res.data.data.message);
                        setTimeout(() => {
                            setShowAlertAdd(false);
                            setAlertMsgAdd("");
                        }, 3000);
                    }


                })
                .catch((e) => {
                    setCreateInprocess(false);
                    console.log(e);
                });
            setAddErr("");
        } else {
            setAddErr("Enter content type!");
        }
    };
    return (
        <>
            <div
                className="modal fade edit-user-details-popup"
                id={id}
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">

                        <div className="edit-user-details-header">
                            <h2>Add Content type</h2>
                            <button
                                type="button"
                                className="close"
                                //data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {

                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })



                                    } else {
                                        $("#addContentType").modal('hide')
                                        //handleCloseAddModel(e)
                                    }

                                }}
                            // onClick={handleCloseAddModel}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {showAlertAdd && <div className="alert alert-info">{alertMsgAdd}</div>}
                            <div className="form-group">
                                <div className="form-group-label">Content type name*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="Enter content type"
                                        name="contenttype"
                                        onChange={handleAddTypeChange}
                                        value={addContentType}
                                    />
                                </div>
                                <span className="error">{addErr}</span>
                            </div>

                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {createInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleCreateType}
                                    disabled={createInprocess}
                                >
                                    Add content type
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddContentType