import React from 'react'
import PropTypes from "prop-types"
const MdsIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM8.626 13.032c.94.482 1.845 1.04 2.764 1.56.193.111.246.08.246-.145-.004-1.61-.004-3.221 0-4.832a.432.432 0 0 0-.243-.41C9.668 8.25 7.941 7.295 6.215 6.342c-.457-.252-.866-.013-.866.506 0 .95.005 1.9-.002 2.849-.018 2.192.204 4.328 1.441 6.215.851 1.293 1.988 2.394 3.144 3.41.007.007-.014-.001 0 0 .008.002-.01-.002 0 0 0-1.064-.005-2.13 0-3.195a.453.453 0 0 0-.116-.318c-.669-.78-1.116-1.754-1.19-2.778Zm6.746 0c-.94.482-1.845 1.04-2.764 1.56-.193.111-.246.08-.246-.145.004-1.61.004-3.221 0-4.832a.432.432 0 0 1 .243-.41 6876.78 6876.78 0 0 1 5.178-2.862c.457-.252.866-.013.866.506 0 .95-.005 1.9.002 2.849.018 2.192-.204 4.328-1.441 6.215-.851 1.293-1.988 2.394-3.144 3.41-.007.007.014-.001 0 0-.004.002.005 0 0 0 0-1.064.005-2.13 0-3.195a.453.453 0 0 1 .116-.318c.669-.78 1.116-1.754 1.19-2.778Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
MdsIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    scale: PropTypes.number
}
export default MdsIcon
