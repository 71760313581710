import React from "react";
import PropTypes from "prop-types";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageTail.css";

const MessageTail = ({ isOutgoing = false }) => {
  return (
    <div className="MessageTail-sec">
      {isOutgoing ? (
        <svg
          xmlns="https://www.w3.org/2000/svg"
          width={8}
          height={12}
          fill="none"
          viewBox="0 0 8 12"
        >
          <path
            fill="var(--color-bg-chat-outgoing)"
            fillRule="evenodd"
            d="M7.858 11.58c.083.025.142.11.142.207 0 .118-.085.213-.19.213H0V0c0 5.534 3.33 10.193 7.858 11.58Z"
            clipRule="evenodd"
          />
        </svg>
      ) : (
        <svg
          xmlns="https://www.w3.org/2000/svg"
          width={8}
          height={12}
          fill="none"
        >
          <path
            fill="var(--color-bg-chat-incoming)"
            fillRule="evenodd"
            d="M.142 11.58a.213.213 0 0 0-.142.207c0 .118.085.213.19.213H8V0C8 5.534 4.67 10.193.142 11.58Z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </div>
  );
};

MessageTail.propTypes = {
  isOutgoing: PropTypes.bool,
};

export default MessageTail;
