import { useEffect, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//import api file
import Services from "../../Services/auth.service";
import moment from "moment";
//import components from mui/material
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

//import images
import edit from "../../Images/BackendImages/edit.svg";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import Loader from "../../Images/loader.gif";

const columns = [
  { id: "first_name", label: "First name" },
  { id: "last_name", label: "Last name" },
  { id: "email", label: "Email" },
  { id: "contact_number", label: "Contact number" },
  { id: "role", label: "Role" },
  { id: "createdAt", label: "Date joined" },
  { id: "action", label: "Actions" },
];

function createData(
  first_name,
  last_name,
  email,
  contact_number,
  role,
  createdAt,
  action
) {
  return {
    first_name,
    last_name,
    email,
    contact_number,
    role,
    createdAt,
    action,
  };
}

const AdminListing = ({ setselecteddiv, setselectedid }) => {
  const [alladmin, setalladmin] = useState([]);
  const [rows, setrow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setdeleteId] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [searchText, setsearchText] = useState("");
  const [loader,setLoader]=useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })

  useEffect(() => {
    setLoader(true)
    Services.getalladmin()
      .then((res) => {
        if (res.data.status) {
          const data = res.data.data;
          var arr = [];
          for (var i = 0; i < data.length; i++) {
            arr[i] = createData(
              data[i].first_name,
              data[i].last_name,
              data[i].email,
              data[i].contact_number,
              data[i].role.role_name,
              moment(data[i].createdAt).format("MMM DD, YYYY [at] hh:mm a"),
              data[i]._id
            );
          }
          setrow(arr);
          setalladmin(arr);
          setLoader(false)
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (value) => {
    setselectedid(value);
    setselecteddiv("edit");
  };
  const handleSearch = () => {
    if (alladmin !== undefined && alladmin.length > 0) {
      var data = alladmin.filter((admin) => {
        if (
          admin.first_name.includes(searchText) ||
          admin.last_name.includes(searchText) ||
          admin.email.includes(searchText) ||
          admin.role.includes(searchText) ||
          admin.contact_number.includes(searchText)
        ) {
          return admin;
        }
      });
      setrow(data);
    }
  };
  const handleDelete = () => {
    Services.deleteadmin(deleteId)
      .then((res) => {
        setshowalert(true);
        setalertmsg(res.data.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
        }, 3000);
        if (
          res.data.status &&
          res.data.data._id !== undefined &&
          res.data.data._id.length > 0
        ) {
          var data = alladmin.filter((admin) => {
            if (admin.action !== res.data.data._id) {
              return admin;
            }
          });
          setrow(data);
          setalladmin(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleSorting = (e) => {
    var originarr = alladmin;
    var arr = [...alladmin];
    if (e.target.value === "a-z") {
      arr.sort(function (a, b) {
        var textA = a.first_name.toUpperCase();
        var textB = b.first_name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (e.target.value === "z-a") {
      arr.sort(function (a, b) {
        var textA = a.first_name.toUpperCase();
        var textB = b.first_name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else {
      arr = [...originarr];
    }
    setrow(arr);
  };

  const handleSort = (col, direc) => {
    var normal_arr = alladmin;
    var arr = [...alladmin];
    if (direc === "asc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (direc === "desc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else {
      arr = normal_arr;
    }
    setrow(arr);
  };

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>All admins</h1>
            <div className="uic-fl-btn">
              <button
                className="btn"
                onClick={() => {
                  dispatch({type:"REMOVEMODALINPROCESS"})
                  dispatch({ type: "SETSTAYONPAGE", payload: null })
                  setselecteddiv("create");
                }}
              >
                Add admin
              </button>
            </div>
          </div>

          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                class="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setsearchText(e.target.value);
                }}
              />
              <i className="fa fa-search" onClick={handleSearch}></i>
            </div>

            <div class="uic-fl-btn sort-by-mn">
              <div class="sort-by-mn-title">Sort by : </div>
              <div class="sort-by-mn-select">
                <select onChange={handleSorting}>
                  <option value="all">All</option>
                  <option value="a-z">A-Z</option>
                  <option value="z-a">Z-A</option>
                </select>
              </div>
            </div>
          </div>

          {showalert && <div className="alert alert-info">{alertmsg}</div>}

          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={
                          column.id === "action"
                            ? "uic-th-td w-20"
                            : "uic-th-td w-15"
                        }
                      >
                        {column.id !== "action" ? (
                          <>
                            <span className="custom-sorting">
                              <i
                                class="fa fa-angle-down"
                                onClick={(e) => {
                                  handleSort(column.id, "desc");
                                }}
                              ></i>
                              <i
                                class="fa fa-angle-up"
                                onClick={(e) => {
                                  handleSort(column.id, "asc");
                                }}
                              ></i>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="uic-table-td">
                  {loader?
                  <div className="cstm-data-load"><img src={Loader} /> </div>
                  :rows.length>0?rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                          className="uic-th-td"
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return column.id !== "action" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-15"
                              >
                                {value}
                              </TableCell>
                            ) : (
                              <TableCell
                                key={column.id}
                                className="uic-th-td uic-table-icon w-20"
                              >
                                <a
                                  onClick={() => {
                                    dispatch({type:"REMOVEMODALINPROCESS"})
                                    dispatch({ type: "SETSTAYONPAGE", payload: null })
                                    handleEdit(value);
                                  }}
                                >
                                  <img src={edit} />
                                </a>
                                <a
                                  data-toggle="modal"
                                  data-target="#DeleteFromAdminList"
                                  onClick={() => {
                                    setdeleteId(value);
                                  }}
                                >
                                  <img src={imgdelete} />
                                </a>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    }): <div className="admin-no-found">
                    <p>No Data Found </p>
                  </div>}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          {/* Modal Delete */}
          <div
            className="modal fade msg-popup"
            id="DeleteFromAdminList"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
                <div className="msg-icon">
                  <img src={imgbigdelete} />
                </div>
                <h1>Are you sure you want to delete this admin?</h1>
                <div className="row">
                  <div className="col-6">
                    <span className="msg-btn">
                      <button
                        type="button"
                        className="btn btn-red"
                        onClick={handleDelete}
                        data-dismiss="modal"
                      >
                        Delete
                      </button>
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="msg-btn">
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminListing;
