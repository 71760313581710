import React from 'react'
import PropTypes from "prop-types"
const LogoutIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M2 17.575V6.435C2 3.99 4.153 2 6.8 2h5.288c2.642 0 4.79 1.985 4.79 4.426v.941c0 .414-.364.75-.813.75-.448 0-.811-.336-.811-.75v-.94c0-1.615-1.42-2.927-3.165-2.927H6.8c-1.753 0-3.177 1.316-3.177 2.935v11.14c0 1.612 1.421 2.925 3.166 2.925h5.287c1.752 0 3.177-1.317 3.177-2.934v-.932c0-.414.363-.75.811-.75.449 0 .812.336.812.75v.932c0 2.445-2.154 4.434-4.8 4.434H6.79C4.149 22 2 20.014 2 17.575Zm20.402-6.122c.161.14.255.34.255.546a.725.725 0 0 1-.256.547l-3.151 2.9a.861.861 0 0 1-1.149-.002.71.71 0 0 1 .003-1.06l1.777-1.635H8.81C8.365 12.75 8 12.413 8 12s.364-.75.812-.75H19.88l-1.777-1.635a.71.71 0 0 1-.003-1.06.861.861 0 0 1 1.149-.002l3.152 2.901Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
LogoutIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default LogoutIcon