import axios from "axios";
import authHeader from "../Services/auth.header";
import authAdminHeader from './auth.admin.header'
const API_URL = process.env.REACT_APP_API_URL;
const CHAT_API_URL = process.env.REACT_APP_CHAT_BASE_API_URL;

const createNotification = (data) => {
  return axios.post(API_URL + "notification/create", data, { headers: authAdminHeader() });
};
// const createNotificationSendMessage = (data) => {
//   return axios.post(CHAT_API_URL + "createNotificationSendMessage", data, {headers: authAdminHeader()})
// }
const getAllNotificationList = (query) => {
  return axios.get(API_URL + "notification/list" + query, { headers: authAdminHeader() })
}
const deleteNotification = (notificationIds) => {
  const data = {
    notificationIds: [notificationIds]
  };

  return axios.patch(API_URL + "notification/delete", data, { headers: authAdminHeader() });
};

const getNotification = (id) => {
  return axios.get(API_URL + "notification/getById/" + id, { headers: authAdminHeader() })
}

const updateNotification = (id, data) => {
  return axios.patch(API_URL + "notification/update/" + id, data, { headers: authAdminHeader() })
}

const cloneNotification = (id) => {
  return axios.get(API_URL + "notification/clone/" + id, { headers: authAdminHeader() })
}

const notificationUserList = (data) => {
  return axios.post(API_URL + "notification/sendNotificationUserList", data, { headers: authAdminHeader() });
}

const notificationSuggestionList = () => {
  return axios.get(API_URL + "notification/searchSuggestionList", { headers: authAdminHeader() });
}

const sendReferalEmails = (data) => {
  return axios.post(API_URL + 'email/sendEmailToUser', data, { headers: authHeader() });
}

export default {
  createNotification,
  getAllNotificationList,
  deleteNotification,
  getNotification,
  updateNotification,
  cloneNotification,
  notificationUserList,
  notificationSuggestionList,
  sendReferalEmails
}