import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Services from "../../Services/auth.service";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import editfill from "../../Images/BackendImages/edit.svg";
import Loader2 from "../../Images/loader2.gif";
import AlertComponent from "../../Hooks/AlertComponent";
const columns = [
    { id: "name", label: "Name" },
    { id: "counts", label: "No. of videos" },
    { id: "action", label: "Actions" },
];

function createData(name, counts, action) {
    return {
        name,
        counts,
        action,
    };
}
const VideoTag = () => {
    const history = useNavigate();
    const [rows, setrow] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [deleteId, setdeleteId] = useState("");
    const [showalert, setshowalert] = useState(false);
    const [alertmsg, setalertmsg] = useState("");
    const [showalertAdd, setshowalertAdd] = useState(false);
    const [alertmsgAdd, setalertmsgAdd] = useState("");
    const [showalertEdit, setshowalertEdit] = useState(false);
    const [alertmsgEdit, setalertmsgEdit] = useState("");
    const [searchText, setsearchText] = useState("");
    const [alltags, setalltags] = useState([]);
    const [addtag, setaddtag] = useState("");
    const [adderr, setadderr] = useState("");
    const [edittag, setedittag] = useState("");
    const [editerr, setediterr] = useState("");
    const [selectedId, setselectedId] = useState("");
    const [createInprocess, setcreateInprocess] = useState(false);
    const [editInprocess, seteditInprocess] = useState(false);
    const dispatch = useDispatch()
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    
    useEffect(() => {
        handleClearAllStates()
        Services.getalltags_byadmin("")
            .then((res) => {
                if (res.data.status) {
                    var data = res.data.data;
                    var tbl = [];
                    for (var i = 0; i < data.length; i++) {
                        tbl[i] = createData(data[i].name, data[i].counts, data[i]._id);
                    }
                    setrow(tbl);
                    setalltags(tbl);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    const handleCloseAddModel = (e) => {
        handleClearAllStates()
        setaddtag("");
    };
    
    //clear all global states
    const handleClearAllStates = () => {
        dispatch({type:"REMOVEMODALINPROCESS"})
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({type: "REMOVECUSTOMMODALPOPUPCLOSE"})
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleEdit = (value) => {
        setselectedId(value);
        Services.gettagbyid(value)
            .then((res) => {
                if (res.data.status) {
                    setedittag(res.data.data.name);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleSearch = () => {
        if (alltags !== undefined && alltags.length > 0) {
            var data = alltags.filter((tag) => {
                return tag.name.includes(searchText)
            });
            var tbl = [];
            for (var i = 0; i < data.length; i++) {
                tbl[i] = createData(data[i].name,data[i].counts, data[i].action);
            }
            setrow(tbl);
        }
    };
    const handleDelete = () => {
        Services.deleteTag(deleteId)
            .then((res) => {
                if (res.data.status) {
                    var tbl = [];
                    var n = 0;
                    for (var i = 0; i < alltags.length; i++) {
                        if (alltags[i].action !== deleteId) {
                            tbl[n] = createData(
                                alltags[i].name,
                                alltags[i].counts,
                                alltags[i].action
                            );
                            n++;
                        }
                    }
                    setalltags(tbl);
                    setrow(tbl);
                }
                setshowalert(true);
                setalertmsg(res.data.message);
                setTimeout(() => {
                    setshowalert(false);
                    setalertmsg("");
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleSorting = (e) => {
        var normal = alltags;
        var arr = [...alltags];

        if (e.target.value === "a-z") {
            arr.sort(function (a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
        } else if (e.target.value === "z-a") {
            arr.sort(function (a, b) {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();
                return textA > textB ? -1 : textA < textB ? 1 : 0;
            });
        } else {
            arr = normal;
        }
        var tbl = [];
        for (var i = 0; i < arr.length; i++) {
            tbl[i] = createData(arr[i].name, arr[i].counts, arr[i].action);
        }
        setrow(tbl);
    };

    const handleSort = (col, direc) => {
        var arr = alltags;
        if (direc === "asc") {
            arr.sort(function (a, b) {
                var textA = a[col];
                var textB = b[col];
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
        } else if (direc === "desc") {
            arr.sort(function (a, b) {
                var textA = a[col];
                var textB = b[col];
                return textA > textB ? -1 : textA < textB ? 1 : 0;
            });
        }
        var tbl = [];
        for (var i = 0; i < arr.length; i++) {
            tbl[i] = createData(arr[i].name, arr[i].counts, arr[i].action);
        }
        setrow(tbl);
    };
    const handleAddChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        setaddtag(e.target.value.trim());
        if (e.target.value.length > 0) {
            setadderr("")
        } else {
            setadderr("Enter tags! ")
        }
    };
    const handleCreate = () => {
        if (addtag.length > 0) {
            setcreateInprocess(true);

            Services.createtag({
                name: addtag.trim(),
            })
                .then((res) => {
                    setcreateInprocess(false);
                    if (res.data.status) {
                        setshowalertAdd(true);
                        setalertmsgAdd(res.data.message);
                        setTimeout(() => {
                            setshowalertAdd(false);
                            setalertmsgAdd("");
                        }, 3000);
                        Services.getalltags_byadmin("")
                            .then((response) => {
                                if (response.data.status) {
                                    var data = response.data.data;
                                    var tbl = [];

                                    for (var i = 0; i < data.length; i++) {
                                        tbl[i] = createData(
                                            data[i].name,
                                            data[i].counts,
                                            data[i]._id
                                        );
                                    }
                                    setrow(tbl);
                                    setalltags(tbl);
                                }
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                    } else {
                        setshowalertAdd(true);
                        setalertmsgAdd(res.data.message);
                        setTimeout(() => {
                            setshowalertAdd(false);
                            setalertmsgAdd("");
                        }, 3000);
                    }
                    handleClearAllStates()
                    setaddtag("");
                })
                .catch((e) => {
                    handleClearAllStates()
                    setcreateInprocess(false);
                    console.log(e);
                });
            setadderr("");
        } else {
            setadderr("Enter tags!");
        }
    };
    const handleEditChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        setedittag(e.target.value);
        if (e.target.value.length > 0) {
            setediterr("")
        } else {
            setediterr("Enter tags! ")
        }
    };
    const handleEditSubmit = () => {
        if (edittag.length > 0) {
            seteditInprocess(true);
            Services.editTag({
                id: selectedId,
                data: { name: edittag },
            })
                .then((res) => {
                    if (res.data.status) {
                        setshowalertEdit(true);
                        setalertmsgEdit(res.data.message);
                        setTimeout(() => {
                            setshowalertEdit(false);
                            setalertmsgEdit("");
                        }, 3000);
                        Services.getalltags_byadmin("")
                            .then((response) => {
                                if (response.data.status) {
                                    var data = response.data.data;
                                    var tbl = [];
                                    for (var i = 0; i < data.length; i++) {
                                        tbl[i] = createData(
                                            data[i].name,
                                            data[i].counts,
                                            data[i]._id
                                        );
                                    }
                                    setrow(tbl);
                                    setalltags(tbl);
                                }
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                        seteditInprocess(false);
                    } else {
                        setshowalertEdit(true);
                        setalertmsgEdit(res.data.message);
                        setTimeout(() => {
                            setshowalertEdit(false);
                            setalertmsgEdit("");
                        }, 3000);
                    }
                    handleClearAllStates()
                    seteditInprocess(false);
                })
                .catch((e) => {
                    console.log(e);
                    handleClearAllStates()
                    seteditInprocess(false);
                });
            setediterr("");
        } else {
            setediterr("Enter tags!");
        }
    };

    
     //pop up state for do you want to leave....
  useEffect(()=>{
    
    if (stayOnPage !== null && stayOnPage)
    { 
      if ($('#addtag').hasClass('show'))
        {
          $("#addtag").modal('show')
        }
      if ($('#edittag').hasClass('show'))
      {
        $("#edittag").modal('show')
      }
        
    }
    else if (stayOnPage !== null && !stayOnPage){
      if ($('#addtag').hasClass('show'))
      { 
        $("#addtag").modal('hide')
        handleCloseAddModel() 
      }
      if ($('#edittag').hasClass('show'))
       { 
        $("#edittag").modal('hide')
        handleCloseAddModel()
      }
       
    }
      
  },[stayOnPage])

    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title">
                        <h1>All video tags</h1>
                        <div className="uic-fl-btn">
                            <button
                                className="btn"
                                data-toggle="modal"
                                data-target="#addtag"
                            >
                                Add tag
                            </button>
                        </div>
                    </div>

                    <div className="uic-fl">
                        <div className="uic-fl-search">
                            <input
                                type="text"
                                placeholder="Search"
                                className="search-user-input"
                                value={searchText}
                                onChange={(e) => {
                                    setsearchText(e.target.value);
                                }}
                            />
                            <i className="fa fa-search" onClick={handleSearch}></i>
                        </div>

                        <div className="uic-fl-btn sort-by-mn">
                            <div className="sort-by-mn-title">Sort by : </div>
                            <div className="sort-by-mn-select">
                                <select onChange={handleSorting}>
                                    <option>All</option>
                                    <option value="a-z">A-Z</option>
                                    <option value="z-a">Z-A</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    {showalert && <div className="alert alert-info">{alertmsg}</div>}
                    <Paper className="uic-tb-mn">
                        <TableContainer className="uic-table-main">
                            <Table className="uic-table">
                                <TableHead className="uic-table-th">
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className="uic-th-td w-10"
                                            >
                                                {column.id !== "action" ? (
                                                    <>
                                                        <span className="custom-sorting">
                                                            <i
                                                                className="fa fa-angle-down"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "asc");
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="fa fa-angle-up"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "desc");
                                                                }}
                                                            ></i>
                                                        </span>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="uic-table-td">
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.code}
                                                    className="uic-th-td w-20"
                                                >
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return column.id === "name" ? (
                                                            <TableCell
                                                                key={column.id}
                                                                className="uic-th-td w-17"
                                                            >
                                                                {value}
                                                            </TableCell>
                                                        ) : column.id === "counts" ? (
                                                            <TableCell
                                                                key={column.id}
                                                                className="uic-th-td w-17"
                                                            >
                                                                {value}
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell
                                                                key={column.id}
                                                                className="uic-th-td uic-table-icon w-17"
                                                            >
                                                                <a
                                                                    data-toggle="modal"
                                                                    data-target="#edittag"
                                                                    onClick={() => {
                                                                        handleEdit(value);
                                                                    }}
                                                                >
                                                                    <img src={editfill} />
                                                                </a>
                                                                <a
                                                                    data-toggle="modal"
                                                                    data-target="#DeleteFromTag"
                                                                    onClick={() => {
                                                                        setdeleteId(value);
                                                                    }}
                                                                >
                                                                    <img src={imgdelete} />
                                                                </a>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="uic-pagination"
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>
            <div
                className="modal fade edit-user-details-popup"
                id="addtag"
                tabIndex="-1"
                role="dialog"
                data-backdrop="static" 
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Create tag</h2>
                            <button
                                type="button"
                                className="close"
                                //data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e)=>{ 
                                    if (modalInProcess)
                                    {
                                      dispatch({type:"SETCUSTOMMODALPOPUPCLOSE"})
                                    }else
                                    {
                                      $("#addtag").modal('hide')
                                      handleCloseAddModel(e)
                                    }
                                    
                                    }}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {showalertAdd && <div className="alert alert-info">{alertmsgAdd}</div>}
                            <div className="form-group">
                                <div className="form-group-label">Tag name*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="enter tags name"
                                        name="tag"
                                        onChange={handleAddChange}
                                        value={addtag}
                                    />
                                </div>
                                <span className="error">{adderr}</span>
                            </div>
                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {createInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleCreate}
                                    disabled={createInprocess}
                                >
                                    Create
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade edit-user-details-popup"
                id="edittag"
                tabIndex="-1"
                data-backdrop="static" 
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Edit Tag</h2>
                            <button
                                type="button"
                                className="close"
                               // data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e)=>{ 
                                    if (modalInProcess)
                                    {
                                      dispatch({type:"SETCUSTOMMODALPOPUPCLOSE"})
                                    }else
                                    {
                                      $("#edittag").modal('hide')
                                      handleCloseAddModel(e)
                                    }
                                    
                                    }}

                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {showalertEdit && <div className="alert alert-info">{alertmsgEdit}</div>}
                            <div className="form-group">
                                <div className="form-group-label">Tag name*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="enter tags name"
                                        name="tag"
                                        onChange={handleEditChange}
                                        value={edittag}
                                    />
                                </div>
                                <span className="error">{editerr}</span>
                            </div>

                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {editInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleEditSubmit}
                                >
                                    Update Changes
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Delete */}
            <div
                className="modal fade msg-popup"
                id="DeleteFromTag"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                            <img src={imgbigdelete} />
                        </div>
                        <h1>Are you sure you want to delete this tag?</h1>
                        <div className="row">
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button
                                        type="button"
                                        className="btn btn-red"
                                        onClick={handleDelete}
                                        data-dismiss="modal"
                                    >
                                        Delete
                                    </button>
                                </span>
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" className="btn2" data-dismiss="modal">
                                        Discard
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertComponent/>
        </>
    );
};
export default VideoTag;
