import { Editor } from "@tinymce/tinymce-react";
import React, { useState } from "react";
import AWS from "aws-sdk";
import { useDispatch } from "react-redux";
import loader from "../../Images/loader2.gif";

const TinyEditor = ({
  valueRef,
  value,
  handleEditorChange,
  handleKeyPress,
  placeholder = "",
  name = ""
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  // Prevent Bootstrap dialog from blocking focusin
  $(document).on("focusin", function (e) {
    if (
      $(e.target).closest(
        ".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root"
      ).length
    ) {
      e.stopImmediatePropagation();
    }
  });

  const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
  const REGION = process.env.REACT_APP_AWS_REGION;
  const ACCESS_KEY = process.env.REACT_APP_AWS_ID;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET;

  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  // function for deleteSelected image from S3 Bucket
  async function deleteSelectedImage(editor) {
    const selectedNode = editor.selection.getNode();

    console.log(selectedNode, "selectedNode");

    if (selectedNode.tagName === "IMG") {
      const imageUrl = selectedNode.title;
      console.log(imageUrl, "imageUrl");
      // Check if the selected node is an image
      selectedNode.remove();
      editor.fire("DeleteImageEvent", { imageNode: selectedNode });
      const params = {
        Bucket: S3_BUCKET,
        Key: "Editor_images/" + imageUrl,
      };
      try {
        await s3.deleteObject(params).promise();
        console.log(`Image '${selectedNode}' deleted successfully.`);
      } catch (error) {
        console.error("Error deleting image:", error);
      }
    }
  }

  const editorPlugins = {
    height: 191,
    menubar: false,
    placeholder: placeholder,
    plugins: [
      "advlist",
      "autolink",
      "lists",
      "pageembed",
      "mediaembed",
      "link",
      "image",
      "charmap",
      "accordion",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "save",
      "fullscreen",
      "insertdatetime",
      "autosave",
      "media",
      "table",
      "help",
      "wordcount",
      "pagebreak",
      "directionality",
      "visualchars",
      "nonbreaking",
      "autoresize",
      "export",
      "advcode",
      "advtable",
      "powerpaste",
      "casechange",
      "checklist",
      "noneditable",
      "quickbars",
      "fontsizeinput",
    ],
    relative_urls: false,  // Disable relative URLs
    remove_script_host: false,  // Include the domain in the URL
    image_title: true,
    menu: {
      format: { title: "Format", items: "bold italic underline" },
    },
    content_style: ` * {
            margin: 0;
        }`,
    forced_root_block: false,
    visual: false,
    statusbar: false,
    toolbar: false,
    nonbreaking_force_tab: true,
    nonbreaking_wrap: false,
    autosave_ask_before_unload: false,
    nonbreaking_force_tab: true,
    noneditable_editable_class: "mceEditable",
    branding: false,
    font_size_formats:
      "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
    quickbars_insert_toolbar: "quickimage quicktable | hr pagebreak",
    // quickbars_selection_toolbar:
    //   " fontsizeinput | bold italic backcolor forecolor image media| " +
    //   "alignleft aligncenter alignright alignjustify | blockquote quicklink " +
    //   "bullist numlist | code",
    quickbars_selection_toolbar:
      "bold italic underline| alignleft aligncenter alignright alignjustify| image media pageembed| " +
      "quicklink " +
      "bullist numlist | code",
    quickbars_insert_toolbar: false,
    mergetags_prefix: "{{.",
    mergetags_suffix: "}}",
    file_picker_types: "file image media",
    automatic_uploads: true,
    images_upload_allowed_types: "jpeg,jpg,png,gif,bmp,tiff,tif,svg",
    file_picker_callback: function (cb, value, meta) {
      var input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.onchange = function () {
        setIsLoading(true);
        var file = this.files[0];
        var reader = new FileReader();
        var uploadedUrl;
        const originalFileName = file.name; // Assuming file.name contains the original file name
        const timestamp = new Date().getTime();
        const modifiedFileName = `${timestamp}_${originalFileName}`;
        reader.onload = async function () {
          try {
            const params = {
              Bucket: S3_BUCKET,
              Key: "Editor_images/" + modifiedFileName,
              Body: file,
              ContentType: file.type,
            };
            await s3.putObject(params).promise();
            uploadedUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/Editor_images/${modifiedFileName}`;
            dispatch({ type: "SETMODALINPROCESS" });
            setIsLoading(false);
          } catch (err) {
            console.error(err);
            alert("File upload failed.");
            setIsLoading(false);
          } finally {
            setIsLoading(false);
          }

          var id = "blobid" + uploadedUrl;
          var blobCache = tinymce.activeEditor.editorUpload.blobCache;
          var base64 = reader.result.split(",")[1];
          var blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);
          cb(uploadedUrl, { title: modifiedFileName });
        };
        reader.readAsDataURL(file);
      };
      input.click();
    },
    setup: function (editor) {
      editor.on("keydown", function (e) {
        const selectedNode = editor.selection.getNode();
        if (
          selectedNode.tagName === "IMG" &&
          (e.keyCode === 46 || e.keyCode === 8)
        ) {
          // Detect the "Delete" key press (key code 46)
          e.preventDefault(); // Prevent the default delete action

          // Implement the image deletion logic here
          deleteSelectedImage(editor);
          editor.on("DeleteImageEvent", async function (e) {
            const deletedImageNode = e.imageNode;
            // You can perform additional actions here or send data to your server.
            console.log(deletedImageNode, "deletedImageNode");

            console.log("Image deleted:", deletedImageNode);
          });
        }
        // if (e.keyCode == 13 && !e.shiftKey) {  // Check if Enter key is pressed without Shift
        //   e.preventDefault();
        //   var range = editor.selection.getRng();  // Get selection range
        //   var newNode = editor.dom.create('br');  // Create a <br> element
        //   range.deleteContents();  // Delete the contents of the range
        //   range.insertNode(newNode);  // Insert the <br> element
        //   range.setStartAfter(newNode);  // Set cursor position after the <br> element
        //   range.collapse(true);  // Collapse the range to the end
        //   editor.selection.setRng(range);  // Set the range
        // }
      });
    },
  };
  return (
    <>
      <Editor
        name={name}
        apiKey="cuo0epjharoi0o7f6zndjoxcfwcwtzw0w0q67z0dsz3ufmui"
        init={editorPlugins}
        onInit={(evt, editor) => (valueRef.current = editor)}
        initialValue={value}
        onEditorChange={handleEditorChange}
        onKeyPress={handleKeyPress}
        onPaste={() => dispatch({ type: "SETMODALINPROCESS" })}
      />
      {/* show loader */}
      {isLoading && (
        <div className="insert-loading">
          <img src={loader} />
        </div>
      )}
    </>
  );
};

export default TinyEditor;
