import React, { useEffect, useState } from 'react'
import authServiceNews from '../../../Services/auth.service.news'
import moment from 'moment'
import TrendingPostsLink from "../../../Images/TrendingPostsLink.svg";
import noThumbnail from '../../../Images/no-image-available.jpg';
import { Link } from 'react-router-dom';
import logo from '../../../Images/logo-image.jpg'
const AllNews = ({ loader, setLoader }) => {
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState()
  const [newsData, setNewsData] = useState([])
  const [dataLoader, setDataLoader] = useState(false)

  //call on first render
  useEffect(() => { getAllNewsApi(page) }, [])

  //get allnews and content api call
  async function getAllNewsApi(page) {
    let query = `?page=${page}&limit=10`
    try {
      const allNewsRes = await authServiceNews.getAllNewsContent(query)
      if (allNewsRes.status) {
        if (page === 1) {
          setNewsData(allNewsRes.data.newsList.list)
        } else {
          setPage(allNewsRes.data.newsList.currentPage)
          setNewsData([...newsData, ...allNewsRes.data.newsList.list])
          setDataLoader(false)
        }
        setPageLimit(allNewsRes.data.newsList.totalPages)
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      setDataLoader(false)
      return false
    }
  }

  //handleshowmore for load more news from api
  const handleShowMore = () => {
    if (page !== pageLimit) {
      setDataLoader(true)
      getAllNewsApi(page + 1)
    }
  }
  return (
    <>
      {loader ?
        <>
          <div className='cstm-updates-details-loading'>
            <div className='cstm-updates-details-box-loading'>
              <div className='cstm-updates-details-box-img-loading skeleton-box'></div>
              <div className='cstm-updates-details-box-title-loading skeleton-box'></div>
              <div className='cstm-updates-details-box-date-loading skeleton-box'></div>
            </div>
            <div className='cstm-updates-details-box-loading'>
              <div className='cstm-updates-details-box-img-loading skeleton-box'></div>
              <div className='cstm-updates-details-box-title-loading skeleton-box'></div>
              <div className='cstm-updates-details-box-date-loading skeleton-box'></div>
            </div>
          </div>
        </>
        :
        <>
          <div className='news-updates-details'>

            {newsData !== undefined && newsData.length > 0 && newsData.map((item) => (
              <div className='news-updates-details-box'>
                <Link to={item.newsType === "news" ? "/frontnews/" + item._id : `/videos/${item.videoData._id}`}>
                  <div className='news-updates-details-box-img'>
                    {item.thumbnailUrl !== undefined && item.thumbnailUrl !== "" && item.thumbnailUrl !== null ?
                      <img src={process.env.REACT_APP_AWS_IMG_VID_PATH + item.thumbnailUrl} />
                      :
                      <img src={logo} />
                    }</div>
                  <div className='news-updates-details-box-title'>{item.titleMain !== undefined && item.titleMain !== null && item.titleMain !== "" ? item.titleMain : ""}</div>
                  <div className='news-updates-details-box-date'>{item.date !== undefined && item.date !== null && item.date !== "" ? moment(item.date).format('MMM DD, YYYY') : ""}</div>
                </Link>
              </div>
            ))}

          </div>
          {dataLoader &&
            ([...Array(3)].map(() => (
              <div className='cstm-updates-details-loading'>
                <div className='cstm-updates-details-box-loading'>
                  <div className='cstm-updates-details-box-img-loading skeleton-box'></div>
                  <div className='cstm-updates-details-box-title-loading skeleton-box'></div>
                  <div className='cstm-updates-details-box-date-loading skeleton-box'></div>
                </div>
                <div className='cstm-updates-details-box-loading'>
                  <div className='cstm-updates-details-box-img-loading skeleton-box'></div>
                  <div className='cstm-updates-details-box-title-loading skeleton-box'></div>
                  <div className='cstm-updates-details-box-date-loading skeleton-box'></div>
                </div>
              </div>
            )))

          }
          {page !== pageLimit ?
            <div className='news-updates-btn'><button disabled={dataLoader} className='show-more-news-btn' onClick={handleShowMore}>Show more news</button></div> : ""
          }
        </>
      }

    </>
  )
}

export default AllNews