import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    userCount: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.ruleUserCount.userCount.url,
          method: ApiRoutes.ruleUserCount.userCount.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
});

export const { useUserCountMutation } = extendedApi;
