import React, { useState, useEffect } from "react";
import DocumentTableList from "./DocumentTableList";
import EmptyState from "../../../components/EmptyState/EmptyState";
import Table from "../../../components/Table";
import TableBody from "../../../components/Table/TableBody";
import TableHead from "../../../components/Table/TableHead";
import { InView } from "react-intersection-observer";
import Button from "../../../components/Button/Button";
import authServiceDocument from "../../../Services/auth.service.document";
import SaveIcon from "../../../components/Icons/GeneralIcons/SaveIcon";
import ShareIcon from "../../../components/Icons/GeneralIcons/ShareIcon";
import DownloadIcon from "../../../components/Icons/GeneralIcons/DownloadIcon";
import ComplainIcon from "../../../components/Icons/GeneralIcons/ComplainIcon";
import NoResultDocIcon from "./NoResultDocIcon";
import IconSearchNoResults from "../../../components/GlobalComponents/Icons/IconEmptyState/IconSearchNoResults";
import { useLocation } from "react-router-dom";
import authService from "../../../Services/auth.service";
import ShareFunctionality from "../../../components/ShareFunctionality";
import Typography from "../../../components/Typography/Typography";
import ModelPopup from "../../../components/ModalPopup/ModalPopup";
import TextField from "../../../components/InputField/TextField";
import CrossIcon from "../../../components/Icons/GeneralIcons/CrossIcon";
import { writeText } from "clipboard-polyfill";
import TableLoadingSkeleton from "./TableLoadingSkeleton";

const DocsListView = ({
  handleFilterByOwner,
  setFileName,
  handlePreview,
  savedCount,
  setSavedCount,
  handleSelectCategory,
  selectedFilter,
  handleDownloadDoc,
  columns,
  allDocList,
  totalRecords,
  currentPage,
  totalPage,
  setAllDocList,
  handleMoreResult,
  moreLoader,
  loader,
  handleClearAllFilter,
  searchText,
  handleSortByColumn,
  sortedColumn,
  setSortedColumn,
  sortOrder,
  setSortOrder
}) => {
  const [docId, setDocId] = useState("");
  const [fileUrl, setFireUrl] = useState("");
  const [restricted, setRestricted] = useState(false);
  const [copyUrl, setCopyUrl] = useState("");
  const [validate, setValidate] = useState(false);
  const [copyVal, setCopyVal] = useState("Copy");

  const location = useLocation().search;
  const searchFromUrl = new URLSearchParams(location).get("search");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setValidate(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Update copyUrl when the modal is closed or the component unmounts
  useEffect(() => {
    return () => {
      setCopyUrl("");
    };
  }, []);

  async function handleSaveDocApi(id) {
    setAllDocList((prevData) =>
      prevData.map((item) => {
        if (item._id === id) {
          if (item.isSaved) {
            setSavedCount(savedCount - 1);
            return { ...item, isSaved: false };
          } else {
            setSavedCount(savedCount + 1);
            return { ...item, isSaved: true };
          }
        }
        return item;
      })
    );
    try {
      await authServiceDocument.saveDoc(id);
    } catch (error) {}
  }
  function handleShare(url, docId) {
    setValidate(true);
    setCopyVal("Copy");
    if (
      process.env.REACT_APP_FRONTEND_ROOTURL ===
        "https://dev.milliondollarsellers.com/" ||
      process.env.REACT_APP_FRONTEND_ROOTURL ===
        "https://test.milliondollarsellers.com/"
    ) {
      const androidParameters = {
        androidPackageName: process.env.REACT_APP_ANDROID_PACKAGENAME,
        androidFallbackLink: process.env.REACT_APP_ANDROID_FALLBACKLINK,
      };
      const iosParameters = {
        iosBundleId: process.env.REACT_APP_ANDROID_PACKAGENAME, // Should be IOS bundle ID here
        iosFallbackLink: process.env.REACT_APP_ANDROID_FALLBACKLINK, // Should be IOS fallback link here
        iosAppStoreId: process.env.REACT_APP_IOS_APPSTOREID,
      };
      const socialMetaTagParameters = {
        socialTitle: process.env.REACT_APP_SOCIAL_TITLE,
        socialDescription: "Click here",
      };
      const bodyRequestParam = {
        dynamicLinkInfo: {
          domainUriPrefix: process.env.REACT_APP_FIREBASE_URIPREFIX,
          link:
            process.env.REACT_APP_FIREBASE_URIPREFIX +
            "redirectto=document&document_id=" +
            docId +
            "&file_url=" +
            encodeURIComponent(url),
          iosInfo: iosParameters,
          socialMetaTagInfo: socialMetaTagParameters,
        },
        suffix: {
          option: process.env.REACT_APP_SUFFIX_OPTION,
        },
      };
      const API_key = process.env.REACT_APP_FIREBASE_API_KEY;

      // Call authService to get the short share link
      authService
        .getShortShareLink(API_key, bodyRequestParam)
        .then((res) => {
          setCopyUrl(res.data.shortLink);
        })
        .catch((error) => {
          console.error("Error sharing:", error);
        });
    }
  }
  const actionOptions = [
    allDocList.filter((docs) => docs._id === docId).length > 0 &&
    !allDocList.filter((docs) => docs._id === docId)[0].isSaved
      ? {
          id: "save",
          name: "Save",
          icon: <SaveIcon />,
          callbackFunction: () => handleSaveDocApi(docId),
        }
      : {
          id: "save",
          name: "Remove from saved",
          icon: <SaveIcon isFilled />,
          callbackFunction: () => handleSaveDocApi(docId),
        },
    {
      id: "download",
      name: "Download",
      icon: <DownloadIcon />,
      callbackFunction: () => handleDownloadDoc(docId, fileUrl, restricted),
    },
  ];

  // if (
  //   process.env.REACT_APP_TESTSITE_HOSTNAME !== "app.milliondollarsellers.com"
  // ) {
  //   actionOptions.push({
  //     id: "share",
  //     name: "Share",
  //     icon: <ShareIcon />,
  //     callbackFunction: () => handleShare(fileUrl, docId),
  //   });
  // }

  // Optionally add more options
  // actionOptions.push({ id: "report", name: "Report", icon: <ComplainIcon /> });

  const closeModel = () => {
    setValidate(false);
  };

  const handleCopyMessage = (chatData) => {
    navigator.clipboard.writeText(chatData.message);
  };

  const handleClickCross = () => {
    setValidate(false);
  };

  const handleClickbutton = () => {
    setCopyVal("Copied");

    writeText(copyUrl)
      .then(() => {
        console.log("Content copied to clipboard successfully!");
      })
      .catch((error) => {
        console.error("Error copying content to clipboard:", error);
      });
  };

  return (
    <>
      <div className="doc-table">
        {loader ? (
          <TableLoadingSkeleton/>
        ) : (
          <>
            {allDocList.length > 0 && (
              <Table>
                <TableHead
                  columns={columns}
                  hasSort
                  sortedColumn={sortedColumn}
                  setSortedColumn={setSortedColumn}
                  handleSort={handleSortByColumn}
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                />
                <td colspan="100%">
                  <hr />
                </td>
                <TableBody>
                  {allDocList.length > 0 &&
                    allDocList.map((docs, index) => {
                      return (
                        <>
                          {index === allDocList.length - 1 &&
                          index !== totalRecords - 1 ? (
                            <tr>
                              <td colspan="100%">
                                <InView
                                  onChange={(inView) => {
                                    if (inView) {
                                      handleMoreResult();
                                    }
                                  }}
                                >
                                  <table>
                                    <TableBody>
                                      <DocumentTableList
                                        handleFilterByOwner={
                                          handleFilterByOwner
                                        }
                                        setFileName={setFileName}
                                        handlePreview={handlePreview}
                                        handleSelectCategory={
                                          handleSelectCategory
                                        }
                                        setFireUrl={setFireUrl}
                                        setDocId={setDocId}
                                        handleSaveDocApi={handleSaveDocApi}
                                        columns={columns}
                                        docs={docs}
                                        actionOptions={actionOptions}
                                        setRestricted={setRestricted}
                                      />
                                    </TableBody>
                                  </table>
                                </InView>
                              </td>
                            </tr>
                          ) : (
                            <DocumentTableList
                              setRestricted={setRestricted}
                              handleFilterByOwner={handleFilterByOwner}
                              setFileName={setFileName}
                              handlePreview={handlePreview}
                              handleSelectCategory={handleSelectCategory}
                              setFireUrl={setFireUrl}
                              setDocId={setDocId}
                              handleSaveDocApi={handleSaveDocApi}
                              columns={columns}
                              docs={docs}
                              actionOptions={actionOptions}
                            />
                          )}
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            )}
            {!loader &&
            allDocList.length === 0 &&
            (selectedFilter > 0 ||
              (searchFromUrl !== null && searchFromUrl.length > 0)) ? (
              <EmptyState
                icon={<IconSearchNoResults />}
                onClick={handleClearAllFilter}
                title="Oops! No results this time"
                subTitle={`Please adjust your searching filters and give it another go!`}
              />
            ) : (
              !loader &&
              allDocList.length === 0 && (
                <EmptyState
                  icon={<NoResultDocIcon />}
                  onClick={handleClearAllFilter}
                  title="Break the ice & upload a document!"
                  subTitle={`Let's make this space buzz with knowledge.`}
                />
              )
            )}
            {moreLoader && (
              <TableLoadingSkeleton/>
            )}
            {!loader &&
              totalPage > 1 &&
              currentPage !== 0 &&
              currentPage < 2 && (
                <div className="more-results">
                  <Button
                    size="large"
                    handleClick={handleMoreResult}
                    variant="secondary"
                  >
                    More results
                  </Button>
                </div>
              )}
          </>
        )}
      </div>

      <ModelPopup
        isFooter={false}
        isHeader={false}
        modelSize="648"
        isOpen={validate}
        onClose={closeModel}
        headingLable="Share"
        buttonText1="Copy"
        // btn2variant="danger"*
        buttonType="onebutton"
        handleClick2ndButton={handleCopyMessage}
      >
        <div className="documet-share-popup">
          <Button
            size="small"
            variant="tonal"
            elementIcon={<CrossIcon />}
            handleClick={handleClickCross}
            hasText={false}
            toolTipText="Close"
          />

          <Typography variant="h3">Share</Typography>
          <Typography variant="p">
            Tell your friend what you are watching today{" "}
          </Typography>
          <div className="documet-share-field">
            <TextField
              hasLabel={false}
              hasRightImg={false}
              className="text-field"
              handleChange={() => {}}
              handleKeyUp={() => {}}
              helperText=""
              label="Label"
              name=""
              value={copyUrl}
              id="textFieldId"
            />
            <h5
              className="documet-share-field-copy"
              onClick={handleClickbutton}
            >
              {copyVal}
            </h5>
          </div>
        </div>
      </ModelPopup>
    </>
  );
};

export default DocsListView;
