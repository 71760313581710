import {
  TableCell,
  TableRow,
  TableBody,
  Tooltip,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import editIcon from "../../../Images/BackendImages/edit.svg";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import copyimg from "../../../Images/file-copy-line 4.svg";
import { Link } from "react-router-dom";
import CountUsersModals from "../../AdminNotification/CountUsersModals";
import ZoomImageModal from "../ZoomImageModal";
import ChannelUserDisplay from "../../ChannelManagement/ChannelManagement/ChannelUserDisplay";
//Table body component
const TableBodyComponent = ({
  isPagination = false,
  rows,
  rowsPerPage,
  page,
  columns,
  editCallBack,
  setDeleteId,
  editModalId = "editModal",
  deleteModalId = "deleteModal",
  selectAllChecked,
  selected,
  purchaseTicketCallBack,
  tabInformation = "",
}) => {
  const [showModal, setShowModal] = useState(false);
  const [rowId, setRowId] = useState("");
  const [isZoomImg, setIsZoomImg] = useState(false);
  const [zoomImg, setZoomImg] = useState(null);
  const [clickCountFlag, setClickCountFlag] = useState(false);
  const [showModalChannel, setModalChannel] = useState(false);
  const onEscCloseModal = (e) => {
    if ((showModal || showModalChannel) && e.key === "Escape") {
      setShowModal(false);
      setModalChannel(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  const handleZoomImage = (e) => {
    setIsZoomImg(true);
    setZoomImg(e.target.src);
  };

  document.addEventListener("keydown", onEscCloseModal);
  return (
    <>
      <TableBody className="uic-table-td">
        {isPagination
          ? rows.map((row) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.code}
                  className="uic-th-td w-20"
                >
                  {columns.map((column) => {
                    const value = row[column.id];
                    return column.id === "action" ? (
                      <TableCell
                        key={column.id}
                        className="uic-th-td uic-table-icon"
                      >
                        {window.location.pathname === "/notification/list" && (
                          <Tooltip title="Clone" arrow>
                            <a
                              data-toggle="modal"
                              onClick={() => {
                                setDeleteId(value);
                              }}
                              data-target="#cloneNotification"
                            >
                              <img src={copyimg} />
                            </a>
                          </Tooltip>
                        )}
                        {window.location.pathname === "/notification/list" &&
                          row.notificationStatus !== "Sent" && (
                            <Link
                              to={`/notification/detail?id=${value}`}
                              onClick={() => {
                                editCallBack(value, row);
                              }}
                            >
                              <Tooltip title="Edit" arrow>
                                {" "}
                                <img src={editIcon} alt="" />
                              </Tooltip>
                            </Link>
                          )}
                        {window.location.pathname ===
                        "/channel/channelListing" ? (
                          <Link
                            onClick={() => {
                              editCallBack(row.action, row);
                            }}
                            to={`/channel/detail?id=${value}`}
                          >
                            <Tooltip title="Edit" arrow>
                              {" "}
                              <img src={editIcon} alt="" />
                            </Tooltip>
                          </Link>
                        ) : (
                          window.location.pathname !== "/notification/list" &&
                          window.location.pathname !==
                            "/channel/channelListing" && (
                            <a
                              data-toggle="modal"
                              data-target={`#${editModalId}`}
                              onClick={() => {
                                editCallBack(value, row);
                              }}
                            >
                              <Tooltip title="Edit" arrow>
                                {" "}
                                <img src={editIcon} alt="" />
                              </Tooltip>
                            </a>
                          )
                        )}
                        <a
                          data-toggle="modal"
                          data-target={`#${deleteModalId}`}
                          onClick={() => {
                            setDeleteId(value, row);
                          }}
                        >
                          <Tooltip title="Delete" arrow>
                            {" "}
                            <img src={imgDelete} alt="" />
                          </Tooltip>
                        </a>
                      </TableCell>
                    ) : column.id === "makeFeatured" ? (
                      <TableCell
                        key={column.id}
                        className="uic-th-td uic-table-icon"
                      >
                        <input
                          type="checkbox"
                          name={"makeFeatured"}
                          value={value}
                          onChange={(e) => {
                            column.callBackFunc(e, row);
                          }}
                          checked={value}
                          disabled={row["publishOrHide"] === "hide"}
                        />
                      </TableCell>
                    ) : column.id === "bulkDelete" ? (
                      <TableCell
                        key={column.id}
                        className="uic-th-td uic-table-icon"
                      >
                        {row.type !== "Speaker" ? (
                          <Checkbox
                            className="cstm-ls-checkbox"
                            color="primary"
                            disabled={row.type === "Speaker"}
                            checked={
                              selected !== undefined &&
                              selected.filter(
                                (item) =>
                                  item.action.toString() ===
                                    row.action.toString() &&
                                  item.roleId.toString() ===
                                    row.roleId.toString()
                              ).length > 0
                            }
                            onChange={(e) => {
                              column.callBackFunc(e, row);
                            }}
                          />
                        ) : (
                          <span>-</span>
                        )}
                      </TableCell>
                    ) : column.id === "clickCount" ||
                      column.id === "sendCount" ? (
                      <TableCell
                        key={column.id}
                        className="uic-th-td uic-table-icon"
                      >
                        <>
                          <a
                            data-toggle="modal"
                            data-target={`#count`}
                            className="cstm-view-users-count"
                            onClick={() => {
                              if (
                                column.id === "clickCount" &&
                                row.clickCount > 0
                              ) {
                                setClickCountFlag(true);
                                setRowId(row.action);
                                setShowModal(true);
                              }
                              if (
                                column.id === "sendCount" &&
                                row.sendCount > 0
                              ) {
                                setClickCountFlag(false);
                                setRowId(row.action);
                                setShowModal(true);
                              }
                            }}
                          >
                            {value}
                          </a>
                        </>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={column.id}
                        className="uic-th-td uic-table-icon"
                      >
                        {column.id === "publishOrHide" ? (
                          value === "hide" ? (
                            "Hidden"
                          ) : (
                            "Published"
                          )
                        ) : column.id === "name" &&
                          window.location.pathname ===
                            "/channel/channelListing" ? (
                          <Link
                            onClick={() => {
                              editCallBack(row.action, row);
                            }}
                            to={`/channel/detail?id=${row.action}`}
                            className="cstm-view-users-count"
                          >
                            {value}
                          </Link>
                        ) : column.id === "title" &&
                          window.location.pathname === "/notification/list" &&
                          row.notificationStatus !== "Sent" ? (
                          <Link
                            to={`/notification/detail?id=${row.action}`}
                            className="cstm-view-users-count"
                            onClick={() => {
                              editCallBack(row.action, row);
                            }}
                          >
                            {value}
                          </Link>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })
          : rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                    className="uic-th-td w-20"
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return column.id === "action" ? (
                        <TableCell
                          key={column.id}
                          className="uic-th-td uic-table-icon"
                        >
                          {window.location.pathname ===
                            "/notification/list" && (
                            <Tooltip title="Clone" arrow>
                              <a
                                data-toggle="modal"
                                onClick={() => {
                                  setDeleteId(value);
                                }}
                                data-target="#cloneNotification"
                              >
                                <img src={copyimg} />
                              </a>
                            </Tooltip>
                          )}
                          {window.location.pathname ===
                          "/channel/channelListing" ? (
                            <Link
                              onClick={() => {
                                editCallBack(row.action, row);
                              }}
                              to={`/channel/detail?id=${value}`}
                            >
                              <Tooltip title="Edit" arrow>
                                {" "}
                                <img src={editIcon} alt="" />
                              </Tooltip>
                            </Link>
                          ) : (
                            <a
                              data-toggle="modal"
                              data-target={`#${editModalId}`}
                              onClick={() => {
                                editCallBack(value, row);
                              }}
                            >
                              <Tooltip title="Edit" arrow>
                                {" "}
                                <img src={editIcon} alt="" />
                              </Tooltip>
                            </a>
                          )}
                          {!(window.location.pathname === "/events/detail") ? (
                            <a
                              data-toggle="modal"
                              data-target={`#${deleteModalId}`}
                              onClick={() => {
                                setDeleteId(value, row);
                              }}
                            >
                              <Tooltip title="Delete" arrow>
                                {" "}
                                <img src={imgDelete} alt="" />
                              </Tooltip>
                            </a>
                          ) : window.location.pathname === "/events/detail" &&
                            tabInformation === "Attendees" &&
                            row?.isManuallyAdded === true ? (
                            <a
                              data-toggle="modal"
                              data-target={`#${deleteModalId}`}
                              onClick={() => {
                                setDeleteId(value, row);
                              }}
                            >
                              <Tooltip title="Delete" arrow>
                                {" "}
                                <img src={imgDelete} alt="" />
                              </Tooltip>
                            </a>
                          ) : (
                            window.location.pathname === "/events/detail" &&
                            tabInformation !== "Attendees" && (
                              <a
                                data-toggle="modal"
                                data-target={`#${deleteModalId}`}
                                onClick={() => {
                                  setDeleteId(value, row);
                                }}
                              >
                                <Tooltip title="Delete" arrow>
                                  {" "}
                                  <img src={imgDelete} alt="" />
                                </Tooltip>
                              </a>
                            )
                          )}
                        </TableCell>
                      ) : column.id === "makeFeatured" ? (
                        <TableCell
                          key={column.id}
                          className="uic-th-td uic-table-icon"
                        >
                          <input
                            type="checkbox"
                            name={"makeFeatured"}
                            value={value}
                            onChange={(e) => {
                              column.callBackFunc(e, row);
                            }}
                            checked={value}
                            disabled={row["publishOrHide"] === "hide"}
                          />
                        </TableCell>
                      ) : column.id === "bulkDelete" ? (
                        <TableCell
                          key={column.id}
                          className="uic-th-td uic-table-icon"
                        >
                          {row.type !== "Speaker" ? (
                            <Checkbox
                              className="cstm-ls-checkbox"
                              color="primary"
                              disabled={row.type === "Speaker"}
                              checked={
                                selected !== undefined &&
                                selected.filter(
                                  (item) =>
                                    item.action.toString() ===
                                      row.action.toString() &&
                                    item.roleId.toString() ===
                                      row.roleId.toString()
                                ).length > 0
                              }
                              onChange={(e) => {
                                column.callBackFunc(e, row);
                              }}
                            />
                          ) : (
                            <span>-</span>
                          )}
                        </TableCell>
                      ) : column.id === "isManuallyAdded" ? (
                        <TableCell
                          key={column.id}
                          className="uic-th-td uic-table-icon"
                        >
                          {value === true ? "true" : "false"}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={column.id}
                          className="uic-th-td uic-table-icon"
                        >
                          {column.id === "publishOrHide" ? (
                            value === "hide" ? (
                              "Hidden"
                            ) : (
                              "Published"
                            )
                          ) : column.id === "name" ? (
                            window.location.pathname ===
                            "/channel/channelListing" ? (
                              <Link
                                onClick={() => {
                                  editCallBack(row.action, row);
                                }}
                                to={`/channel/detail?id=${row.action}`}
                                className="cstm-view-users-count"
                              >
                                {value}
                              </Link>
                            ) : window.location.pathname ===
                              "/events/detail" ? (
                              <div className="cstm-lg-ttl">
                                {" "}
                                {editModalId == "editAttendee" ? (
                                  row.img !== undefined &&
                                  row.img !== null &&
                                  row.img !== "" ? (
                                    <img
                                      className="cstm-url-link"
                                      src={row.img}
                                      alt="image"
                                      onClick={handleZoomImage}
                                    />
                                  ) : (
                                    <div className="cstm-partner-avar">
                                      {row[column.id]
                                        ? row[column.id].charAt(0)
                                        : ""}
                                    </div>
                                  )
                                ) : (
                                  <></>
                                )}
                                <a
                                  data-toggle="modal"
                                  data-target={`#${editModalId}`}
                                  onClick={() => {
                                    editCallBack(row["action"], row);
                                  }}
                                  className="cstm-view-users-count"
                                >
                                  {value}
                                </a>{" "}
                              </div>
                            ) : (
                              value
                            )
                          ) : column.id === "count" ? (
                            <a
                              onClick={() => {
                                purchaseTicketCallBack(
                                  row["action"],
                                  row["participantId"]
                                );
                              }}
                            >
                              {value}
                            </a>
                          ) : column.id === "title" &&
                            window.location.pathname === "notification/list" &&
                            row.notificationStatus !== "Sent" ? (
                            <a
                              onClick={() => {
                                editCallBack(row["action"], row);
                              }}
                              className="cstm-view-users-count"
                            >
                              {value}
                            </a>
                          ) : column.id === "userCount" &&
                            window.location.pathname ===
                              "/channel/channelListing" ? (
                            <a
                              onClick={() => {
                                if (
                                  column.id === "userCount" &&
                                  row.userCount > 0
                                ) {
                                  setRowId(row.action);
                                  setModalChannel(true);
                                }
                              }}
                              className="cstm-view-users-count"
                            >
                              {value}
                            </a>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
      </TableBody>

      {showModal && (
        <CountUsersModals
          setRowId={setRowId}
          rowId={rowId}
          clickCountFlag={clickCountFlag}
          setViewModalOpen={setShowModal}
        />
      )}

      {showModalChannel && (
        <ChannelUserDisplay
          setRowId={setRowId}
          rowId={rowId}
          clickCountFlag={clickCountFlag}
          setViewModalOpen={setModalChannel}
        />
      )}

      {isZoomImg && zoomImg !== null && (
        <ZoomImageModal
          zoomImg={zoomImg}
          setIsZoomImg={setIsZoomImg}
          title="Profile Image"
        />
      )}
    </>
  );
};

export default TableBodyComponent;
