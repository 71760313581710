import React from 'react'
import TableHead from './TableHead'
import TableBody from './TableBody'
import TableCell from './TableCell'
import "./Table.css"
import "../../Styles/Theme/Theme.css"
const index = ({ children }) => {
    return (
        <>
            <table>
                {children}
            </table>
        </>
    )
}

export default index