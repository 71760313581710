import { useSelector, useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Dotted from "../../../Images/dotted.svg";
import Eye from "../../../Images/eye.svg";
import Dummy from "../../../Images/image1.png";
import openfolder from "../../../Images/folder-open.png";
const SidebarFilesList = () => {
    const latestfiles = useSelector((state) => {
        return state.ReduLatestFiles;
      })
    return (
        <>
  {latestfiles !== undefined &&
                latestfiles !== null &&
                latestfiles.length > 0 ? (
                  <ul>
                    {latestfiles.map((file) => {
                      return (
                        <li>
                          <div className="cstm-sg-list">
                            <img className="sctm-pt-mn" src={openfolder} />
                          </div>
                          <div className="cstm-sg-ttl-name">
                            <a className="cstm-sg-ttl-main" href="#">
                              {file.file.split("-").splice(1).join("-")}
                            </a>
                            <span className="cstm-sg-sb">File</span>
                          </div>
                          <div className="cstm-sg-dotted">
                            <span
                              className="remove-srarred-img"
                              data-toggle="dropdown"
                            >
                              <img src={Dotted} />
                            </span>
                            <div
                              className="dropdown-menu remove-srarred-in"
                              onClick={() => {
                                saveAs(
                                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    file.file,
                                  file.file.split("-").splice(1).join("-")
                                );
                              }}
                            >
                              <img className="sctm-eye" src={Eye} /> Download
                            </div>
                          </div>
                          <div className="cstm-sg-date">
                            {" "}
                            {moment(file.updatedAt).format("DD MMM, YYYY")}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div className="emptymsg">No Data Found</div>
                )}
                  
        </>
    )
}
export default SidebarFilesList;