import React from "react";
import PropTypes from "prop-types"
function ArrowChevronLeft({ height = 24, width = 24, iconColor = "#000", bold }) {
    return (
        <>
            {bold ? <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M15.207 4.293a1 1 0 0 1 0 1.414L8.914 12l6.293 6.293a1 1 0 0 1-1.414 1.414L6.086 12l7.707-7.707a1 1 0 0 1 1.414 0Z"
                />
            </svg > : <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
            >
                <path
                    fill={iconColor}
                    d="M15.353 18.554a.75.75 0 0 1-1.133.976l-7-7-.073-.084a.75.75 0 0 1 .073-.976l7-7 .084-.073a.75.75 0 0 1 .976.073l.073.084a.75.75 0 0 1-.073.976L8.811 12l6.47 6.47.072.084Z"
                />
            </svg >}
        </>
    );
}
ArrowChevronLeft.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    bold: PropTypes.bool
}
export default ArrowChevronLeft;
