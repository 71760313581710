import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import { TableCell, TableHead } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import ModalPopup from "../../ModalPopup/ModalPopup";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { useDeleteAddonsMutation } from "../../../Services/addons";

const DeleteAddons = ({
  rows,
  deleteId,
  showDeleteModal,
  setShowDeleteModal,
  setDeleteId,
  setShowAlert,
  setAlertMessage,
  guestTicketRedirect,
  eventId,
  addonRedirectId,
}) => {
  const [showAddonLinkedModal, setShowAddonLinkedModal] = useState(false);
  const [assignedGuestTickets, setAssignedGuestTickets] = useState([]);

  const navigate = useNavigate();
  //closing the modal while deleting
  const handleDeleteModalCloseEvent = () => {
    setShowDeleteModal(false);
    setShowAddonLinkedModal(false);
    setDeleteId("");
  };
  //delete addons
  const [deleteAddon, { isLoading: deleteLoader }] = useDeleteAddonsMutation();

  //redirect using guest ticket
  const handleGotoTicket = () => {
    guestTicketRedirect();
    setShowAddonLinkedModal(false);
    setShowDeleteModal(false);
    navigate(`/events/detail?id=${eventId}&addonId=${addonRedirectId}`);
  };

  //delete addon api
  const handleDelete = async () => {
    try {
      const deleteAddonResponse = await deleteAddon({
        id: deleteId,
      });
      const guestTickets = deleteAddonResponse?.data?.connectAddon?.guestTicket;
      setAssignedGuestTickets(guestTickets);
      setDeleteId("");
      if (deleteAddonResponse.data.status) {
        setShowDeleteModal(false);
        setShowAlert(true);
        setAlertMessage(deleteAddonResponse?.data?.message);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMessage("");
        }, 4000);
      } else {
        setShowAddonLinkedModal(true);
      }
    } catch (err) {
      console.log("Error in handle delete:", err);
    }
  };

  //get the name of the addon while deleting
  const deleteAddonName = (deleteId) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].action === deleteId) {
        return rows[i].name;
      }
    }
  };

  //closing guest ticket and delete modal while pressing esc
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && showDeleteModal) {
        handleDeleteModalCloseEvent();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleDeleteModalCloseEvent, showDeleteModal]);

  return (
    <>
      <ModalPopup
        onClose={handleDeleteModalCloseEvent}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant="danger"
        buttonText1={showAddonLinkedModal ? "Goto tickets" : "Delete"}
        isOpen={true}
        className="z-10"
        handleClick2ndButton={
          showAddonLinkedModal ? handleGotoTicket : handleDelete
        }
        headingLable={"Delete addon"}
        hasLoading={deleteLoader}
      >
        {showAddonLinkedModal ? (
          <div className="modal-content ctsm-modal-body">
            <h4>
              You can't delete this addon because it is assigned to the
              following guest tickets
            </h4>
            <Paper className="uic-tb-mn ">
              <TableContainer
                className=" uic-table-main "
                sx={{
                  minHeight: "auto !important",
                }}
              >
                <Table>
                  <TableHead className="uic-table-th">
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell>Guest Ticket</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {assignedGuestTickets !== undefined &&
                    assignedGuestTickets.length > 0 ? (
                      assignedGuestTickets.map((ticket, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell className="uic-th-td">
                              {index + 1}
                            </TableCell>
                            <TableCell className="uic-th-td">
                              {ticket}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <span className="no-data-found">No data found</span>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        ) : (
          <div className="ctsm-modal-body">
            <p>
              Are you sure you want to delete "{deleteAddonName(deleteId)}"
              addon?This action cannot be undone.
            </p>
          </div>
        )}
      </ModalPopup>
    </>
  );
};

export default DeleteAddons;
