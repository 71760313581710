export const emptyObject = {
  name: "",
  description: "",
  maxAllowedTicket: "",
  priceType: "",
  basePrice: 0,
  isCancellation: false,
  cancellationDaysBeforeEvent: "",
  applicationForm: "",
  specialPriceForGuest: 0,
  quantity: 0,
  isAddOnAvailable: false,
  selectAddons: [],
  isGuestRegistration: false,
  specialPrice: "",
  flatAmount: "",
  discount: "",
  standAlonePriceInput: "",
  specialPriceEndDate: "",
  specialPriceStartDate: "",
  startTime: "",
  endTime: "",
};
