import { createTheme } from '@mui/material/styles';
import { green, purple, red } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0B42CF', // main color
    },
    secondary: {
      main: '#000000', // main color
    },
    error: {
      main: '#e74c3c', // main color
    },
    text: {
      primary: '#000000DE',
      secondary: '#00000099',
    },
  },
  typography: {
    fontFamily: 'Inter',
    h1: {
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '34px',
      letterSpacing: '-0.4px',
      wordBreak: 'break-word',
    },
    h2: {
      fontSize: '22px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '30px',
      letterSpacing: '-0.4px',
      wordBreak: 'break-word',
    },
    h3: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '26px',
      letterSpacing: '-0.4px',
      wordBreak: 'break-word',
    },
    h4: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '-0.1px',
      wordBreak: 'break-word',
    },
    h5: {
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '16px',
      letterSpacing: '-0.1px',
      wordBreak: 'break-word',
    },
    h6: {
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '-0.1px',
      wordBreak: 'break-word',
    },
    body1: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '-0.1px',
      // wordBreak: 'break-word',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '-0.1px',
      // wordBreak: 'break-word',
    },
    subtitle1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '-0.1px',
      wordBreak: 'break-word',
    },
    subtitle2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '-0.1px',
      wordBreak: 'break-word',
    },
    overline: {
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '-0.1px',
      wordBreak: 'break-word',
    },
    caption: {
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '16px',
      letterSpacing: '-0.1px',
      wordBreak: 'break-word',
    },
    'error-text': {
      font: 'var(--body-x-small)',
      color: 'var(--color-text-danger-strong)',
      wordBreak: 'break-word',
    },

  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          position: 'relative',
          width: 'auto',
          fontSize: '14px',
          lineHeight: '24px',
          fontWeight: '600',
          letterSpacing: '-0.1px',
          display: 'inline-flex',
          alignItems: 'center',
          gap: '8px',
          boxShadow: 'unset',
          textTransform: 'initial',
          borderRadius: '8px',
          wordBreak: 'break-word',
          '& .MuiButton-icon ': {
            margin: 0
          },
          '&.Mui-disabled': {
            cursor: 'no-drop',
            pointerEvents: 'auto'
          },
        },
        // ghost: {
        //   border: '0',
        //   backgroundColor: 'transparent',
        //   '&:hover': {
        //     color: 'var(--color-text-neutral-strong-hovered)',
        //     backgroundColor: 'var(--color-bg-neutral-tertiary-hovered)',
        //   },
        //   '&:active': {
        //     color: 'var(--color-text-neutral-strong-pressed)',
        //     backgroundColor: 'var(--color-bg-neutral-tertiary-pressed)',
        //   },
        //   '&:disabled': {
        //     color: 'var(--color-text-disabled-onstrong)',
        //     backgroundColor: 'var(--color-bg-disabled-strong)',
        //   }
        // },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '600',
            gap: '8px',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: '600',
            minWidth: 'auto',
            gap: '8px',
          },
        },
        {
          props: { size: 'large' },
          style: {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: '600',
            gap: '12px',
          },
        },
      ],
    },
    MuiIconButton: {
      // styleOverrides: {
      //   root: {
      //     '&.Mui-disabled': {
      //       cursor: 'no-drop',
      //       pointerEvents: 'auto'
      //     },
      //     '&:focus-visible': {
      //       '&::before': {
      //         content: '""',
      //         display: 'block',
      //         border: '2px solid var(--color-border-inverse-neutral)',
      //         position: 'absolute',
      //         top: '-4px',
      //         left: '-4px',
      //         width: 'calc(100% + 8px)',
      //         height: 'calc(100% + 8px)',
      //         borderRadius: 'inherit'
      //       },
      //       '& .MuiTouchRipple-root': {
      //         display: 'none'
      //       },
      //     },
      //   },
      // },
      variants: [
        {
          props: { variant: 'rounded' },
          style: {
            borderRadius: '50%',
            padding: 12,
            fontSize: 24,
          },
        },
      ],
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          border: `2px solid #ffffff`,
          height: 12,
          width: 12,
          borderRadius: '999px'
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: '16px',
        },
        // Adjust styles for different variants (outlined, filled, standard)
        outlined: {
          borderRadius: '8px', // Rounded corners for outlined variant
        },
        filled: {
          backgroundColor: '#f0f0f0', // Custom background color for filled variant
        },
        standard: {
          borderColor: 'red', // Custom border color for standard variant
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          width: '100%',
          height: 'auto',
          '&::before, ::after': {
            display: 'none'
          },
          '& .MuiSelect-select': {
            padding: 'var(--spacing-10) var(--spacing-12) var(--spacing-10) var(--spacing-16)',
            font: 'var(--body-small)',
            color: 'var(--color-text-neutral-strong)',
            '&:hover': {
              borderColor: 'var(--color-border-neutral-strong-hovered)'
            },
            '&:focus': {
              borderColor: 'var(--color-border-neutral-strong-pressed)'
            },
          },

          '& fieldset ': {
            height: 'auto !important',
            borderRadius: 'var(--radius-8)',
            border: '1px solid var(--color-border-neutral-strong)',
            '&:hover': {
              borderColor: 'var(--color-border-neutral-strong-hovered)'
            },
            '&:focus-within': {
              border: '2px solid var(--color-border-inverse-neutral) !important',
              padding: '9px 11px 9px 15px',
            }
          },
          '&.Mui-focused.MuiInputBase-root': {
            '& fieldset ': {
              border: '2px solid var(--color-border-inverse-neutral) !important',
              padding: '9px 11px 9px 15px',
            }
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          width: '100%',
          height: 'auto',
          '&::before, ::after': {
            display: 'none'
          },
          '& input': {
            height: 'auto !important',
            borderRadius: 'var(--radius-8)',
            padding: 'var(--spacing-10) var(--spacing-12) var(--spacing-10) var(--spacing-16)',
            border: '1px solid var(--color-border-neutral-strong)',
            font: 'var(--body-small)',
            color: 'var(--color-text-neutral-strong)',
            '&:hover': {
              borderColor: 'var(--color-border-neutral-strong-hovered)'
            },
            '&:focus': {
              borderColor: 'var(--color-border-neutral-strong-pressed)'
            },
            '&:focus-within': {
              border: '2px solid var(--color-border-inverse-neutral) !important',
              padding: '9px 11px 9px 15px',
            },
            '&:disabled': {
              borderColor: 'var(--color-border-disabled-strong)',
              cursor: 'no-drop'
            }
          },
          '&.Mui-focused': {
            '& fieldset ': {
              borderColor: 'var(--color-border-neutral-strong-pressed) !iimportant',
              borderWidth: '1px'
            }
          },
        },
        variants: [
          {
            props: { variant: 'round' },
            style: {
              width: '100%',
              height: 'auto',
              '&::before, ::after': {
                // display: 'none'
              },
              '& input': {
                borderRadius: 'var(--radius-full)',
              }
            },
          },
        ],
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: 4,
          padding: 0,
          '& .MuiFormControl-root': {
            margin: '0'
          },
          '& .MuiInputBase-root': {
            padding: '0',
            '& .MuiInputBase-input': {
              padding: 'var(--spacing-10) var(--spacing-12) var(--spacing-10) var(--spacing-16)',
            }
          },
          '& .Mui-focused': {
            '& fieldset': {
              borderColor: 'var(--color-border-neutral-strong-pressed) !important'
            }
          }
        },
        input: {
          border: '0 !important',
          '&:focus-within': {
            border: '0 !important',
          }
        },
        option: {
          fontSize: 14,
          padding: 12,
        },
        paper: {
          // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        },
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(13, 13, 13, 0.50)',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            width: '100%',
            borderRadius: 'var(--radius-8)',
            margin: '24px',
            maxHeight: 'calc(100% - 24px)'
          },
          '& h2.MuiTypography-root': {
            font: 'var(--title-small)',
            fontWeight: '700',
            padding: ' var(--spacing-16) 64px var(--spacing-16) var(--spacing-24)',
            letterSpacing: 'initial',
            wordBreak: 'break-word',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          },
          '& .MuiDialogTitle-root': {
            position: 'relative',
            '& .close-icon': {
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
            },
          },
          '& .MuiDialogContent-root': {
            padding: '24px'
          },
        },
      }
    }
  },

});

export default theme;

export const TextareaStyle = {
  borderRadius: 'var(--radius-8)',
  padding: 'var(--spacing-10) var(--spacing-12) var(--spacing-10) var(--spacing-16)',
  border: '1px solid var(--color-border-neutral-strong)',
  font: 'var(--body-small)',
  backgroundColor: 'transparent',
  color: 'var(--color-text-neutral-strong)',
  '&:hover': {
    borderColor: 'var(--color-border-neutral-strong-hovered)'
  },
  '&:focus': {
    borderColor: 'var(--color-border-neutral-strong-pressed)'
  },
  '&:focus-within': {
    border: '2px solid var(--color-border-inverse-neutral) !important',
    padding: '9px 11px 9px 15px',
  },
  '&:disabled': {
    borderColor: 'var(--color-border-disabled-strong)',
    cursor: 'no-drop'
  }
}
