const loader = false;

const ReduSendMsgLoader = (state = loader, action) => {
  switch (action.type) {
    case "SETSENDMSGLOADER":
      return true;
    case "REMOVESENDMSGLOADER":
        return false;
    default:
      return state;
  }
};

export default ReduSendMsgLoader;
