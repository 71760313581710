import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "../../../../components/Typography/Typography";
import LinkIcon from "../../../../components/Icons/GeneralIcons/LinkIcon";
import ChatLinkThumbnail from "../HelperComponets/ChatLinkThumbnail";
import Button from "../../../../components/Button/Button";
// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/ChatLinkCell.css";
import authService from "../../../../Services/auth.service";

const ChatLinkCell = ({
  link = "https://www.milliondollarsellers.com/",
}) => {
  const [urlDetail, setUrlDetail] = useState({});
  const [showUrl,setUrl] = useState("")

  useEffect(() => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    // Extract the first URL found in the message
    const url = link.match(urlPattern)?.[0] || '';
    setUrl(url)
    authService.getUrlMetadata(url).then((res) => {
      if (res.data.data) {
        let data = {
          metaImageSrc:
            res.data.data?.meta?.image && res.data.data?.meta?.image.length
              ? res.data.data?.meta?.image
              : res.data.data?.og?.image && res.data.data?.og?.image.length
              ? res.data.data?.og?.image
              : res.data.data?.images && res.data.data?.images[0]
              ? res.data.data?.images[0].src
              : "",
          metaTitle: res.data.data?.meta?.title ?? "",
          metaLink: res.data.data?.meta?.url ?? "",
          metaDescription: res.data.data?.meta?.description ?? "",
          metaShortDescription: res.data.data?.meta?.shortdescription ?? "",
        };
        setUrlDetail(data);
      }
    });
  }, [link]);

  // Open link in a new tab
  const handleClick = () => {
    window.open(showUrl, "_blank");
  };

  return (
    <>
      <button class="chatlistcell-sec flex items-center" onClick={handleClick}>
        <div class="chatlistcell-container flex items-center">
          <ChatLinkThumbnail hasImage src={urlDetail?.metaImageSrc} />
          <div className="chatlistcell-content">
            <Typography variant="h5">{urlDetail?.metaTitle}</Typography>
            <p>{showUrl}</p>
          </div>
        </div>

        <Button
          elementIcon={<LinkIcon />}
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          onContextMenu={() => {}}
          handleClick={handleClick}
          size="small"
          variant="ghost"
          hasText={false}
          hasToolTip={true}
          toolTipId="infoToolTip"
          buttonId="infoButton"
          toolTipText="Url"
        />
      </button>
    </>
  );
};

ChatLinkCell.propTypes = {
  link: PropTypes.string,
};

export default ChatLinkCell;
