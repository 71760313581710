import { useEffect, useState, useRef } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import Services from "../../../../Services/auth.service.partner";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgDelete from "../../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import editIcon from "../../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../../Images/loader2.gif";
import Loader from "../../../../Images/loader.gif";
import AlertComponent from "../../../../Hooks/AlertComponent";
import { Tooltip } from "@mui/material";
import authHeader from "../../../../Services/auth.header";
const columns = [
  { id: "title", label: "Title" },
  { id: "url", label: "Url" },
  { id: "author", label: "Author" },
  { id: "action", label: "Actions" },
];

//create a object of using given key
function createData(title, url, author,
  action) {
  return {
    title,
    url,
    author,
    action
  };
}

//PartnerPost module
const PartnerPost = ({ tabInformation, partnername }) => {
  const emptyObjectErr = {
    title: "",
    url: "",
    member: "",
    author: ""
  };
  const emptyObjectModal = {
    title: "",
    url: "",
    member: "0",
    membername: "",
    memberid: "",
    author: ""
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setshowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [errMsgAdd, setErrMsgAdd] = useState("");
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allPartnerPosts, setAllPartnerPosts] = useState([]);
  const [addPartnerPost, setAddPartnerPost] = useState(emptyObjectModal);
  const [editPartnerPost, setEditPartnerPost] = useState(emptyObjectModal);
  const [addErr, setAddErr] = useState(emptyObjectErr);
  const [editErr, setEditErr] = useState(emptyObjectErr);
  const [selectedId, setSelectedId] = useState("");
  const [createInprocess, setCreateInprocess] = useState(false);
  const [editInprocess, setEditInprocess] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const [showApplyAlert, setShowApplyAlert] = useState(false);
  const [selectedPartnerPosts, setSelectedPartnerPosts] = useState([]);
  const [applyURL, setApplyURL] = useState('');
  const [applyLoader, setApplyLoader] = useState(false);
  const [applyURLErrMsg, setApplyURLErrMsg] = useState("");
  const [alertMsgApplyUrl, setAlertMsgApplyUrl] = useState("");
  const [totalPosts, setTotalPosts] = useState(0);
  const [selectedEditMemberOption, setSelectedEditMemberOption] = useState(null);
  const [selectedAddMemberOption, setSelectedAddMemberOption] = useState(null);
  const [inProcess, setInProcess] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const [urlData, setUrlData] = useState('');
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");

  //fetch all existing partner post list
  useEffect(() => {
    if (tabInformation === "PartnerPost") {
      handleClearAllStates()
      setSearchText("")
      setLoader(true);
      setSearchText("")
      getAllPartnerPostApi();
      getallusersSuggestionListApi()
    }
  }, [tabInformation]);
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getPartnerPostByPartnerIdSuggestionList()

      console.log(getSuggestionRes, "getSuggestionRes");
      let data = getSuggestionRes.data.data
      var arr = []
      if (data && data.length !== undefined && data.length !== null && data.length > 0) {
        data.map((item) => arr.push({ name: item.title }))
        console.log(arr);
        setSuggestionList(arr);
      }
    } catch (err) {

    }
  }
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex]
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText
        setSearchText(text);
      }
    }
  }, [listIndex]);

  //get all PartnerPost list
  const getAllPartnerPostApi = (col, sort) => {
    let query = ""
    query += col !== undefined && col !== null && col !== "" ? `?sortField=${col}` : ""
    query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
    const PartnerId = queryVariable
    Services.getAllPartnerPostByPartnerId(PartnerId, query)
      .then((res) => {
        if (res.data.status) {
          setLoader(false);
          var data = res.data.partnerPostList;
          var table = [];
          setTotalPosts(data.length)
          for (var i = 0; i < data.length; i++) {
            table[i] = createData(
              data[i].title,
              data[i].url,
              data[i].author,
              data[i]._id
            );
          }
          setRow(table);
          setAllPartnerPosts(table);
          setApplyURL(res.data.partnerAllPostUrl ? res.data.partnerAllPostUrl : "")
          setUrlData(res.data.partnerAllPostUrl)

        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  //close create partner post module
  const handleCloseAddModel = (e) => {
    setErrMsgAdd("")
    setAddPartnerPost(emptyObjectModal);
    setEditPartnerPost(emptyObjectModal);
    setAddErr(emptyObjectErr);
    setEditErr(emptyObjectErr);
    setApplyURLErrMsg('')
    setSelectedPartnerPosts([])
    setSelectedAddMemberOption(null)
    handleClearAllStates()
  };

  //close create partner post module
  const handleCloseApplyModel = (e) => {
    setErrMsgAdd("")
    setAddPartnerPost(emptyObjectModal);
    setEditPartnerPost(emptyObjectModal);
    setAddErr(emptyObjectErr);
    setEditErr(emptyObjectErr);
    setApplyURLErrMsg('')
    setSelectedPartnerPosts([])
    setApplyURL('')
  };

  //for pagination
  const handleChangePage = (PartnerPost, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (PartnerPost) => {
    setRowsPerPage(+PartnerPost.target.value);
    setPage(0);
  };

  //called when edit button is clicked
  const handleEdit = (value) => {
    setSelectedId(value);
    setEditModalLoader(true);
    Services.getPartnerPostDetail(value)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var data = res.data.partnerPostDetail;
          console.log(data, "data");
          setEditPartnerPost({
            title: data.title,
            url: data.url,
            author: data.author
          });
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };

  //search using text
  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false)
    if (text.length > 0) {
      if (allPartnerPosts !== undefined && allPartnerPosts.length > 0) {
        var data = allPartnerPosts.filter((partnerPost) => {
          if (
            text.length > 0 &&
            partnerPost.title.toLowerCase().includes(text.toLowerCase())
          )
            return PartnerPost;
        });
        var table = [];
        for (var i = 0; i < data.length; i++) {
          table[i] = createData(
            data[i].title,
            data[i].url,
            data[i].author,
            data[i].action
          );
        }
        setPage(0);
        setRow(table);
      }
    } else {
      setPage(0);
      setRow(allPartnerPosts);
    }
  };

  //deletion partner post api call
  const handleDelete = () => {
    setInProcess(true)
    setCreateInprocess(true)
    setErrMsgAdd("")
    Services.deletePartnerPost(deleteId)
      .then((res) => {
        if (res.data.status) {
          getAllPartnerPostApi()
          setCreateInprocess(false)
        }
        setshowAlert(true);
        setInProcess(false)
        document.querySelector('#DeletePartnerPost .close').click()
        setAlertMsg(res.data.message);
        setTimeout(() => {
          setshowAlert(false);
          setAlertMsg("");

        }, 3000);
      })
      .catch((e) => {
        setInProcess(false)
        console.log(e);
        setCreateInprocess(true)
      });
  };

  //sorting function
  const handleSort = (col, direc) => {
    setLoader(true)
    getAllPartnerPostApi(col, direc)
  };

  //function to check url is valid or not
  function isUrlValid(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  //handle onchange for create session
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })

    setAddPartnerPost({ ...addPartnerPost, [e.target.name]: e.target.value });
    if (e.target.name === "title") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      } else {
        setAddErr({ ...addErr, [e.target.name]: "Enter title!" });
      }

    } else if (e.target.name === "url") {
      if (e.target.value.trim().length > 0) {
        if (!isUrlValid(e.target.value))
          setAddErr({
            ...addErr,
            [e.target.name]: "Invalid url!",
          });
        else {
          setAddErr({ ...addErr, [e.target.name]: "" });
        }
      } else {
        setAddErr({ ...addErr, [e.target.name]: "Enter url!" });
      }
    } else if (e.target.name === "author") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      } else {
        setAddErr({ ...addErr, [e.target.name]: "Enter author!" });
      }
    }
  };

  //validation and api call on create session
  const handleCreate = async () => {
    var status = true;
    var temp = {};
    if (totalPosts === 4) {
      setErrMsgAdd("Only 4 posts are allowed!")
      status = false;
    }
    if (addPartnerPost.title.trim().length === 0) {
      temp = { ...temp, title: "Enter title!" };
      status = false;
    }
    if (addPartnerPost.author.trim().length === 0) {
      temp = { ...temp, author: "Enter author!" };
      status = false;
    }
    if (addPartnerPost.url.trim().length === 0) {
      temp = { ...temp, url: "Please enter url!" };
      status = false;
    } else {
      if (!isUrlValid(addPartnerPost.url.trim())) {
        temp = { ...temp, url: "Invalid url!" };
        status = false;
      }
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter(
        key => temp[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setCreateInprocess(true);
      createApiCall();
    } else {
      setAddErr(temp);
    }
  };

  //Api calll on create partnerpost
  const createApiCall = () => {
    setCreateInprocess(true);
    const data = {
      "title": addPartnerPost.title,
      "url": addPartnerPost.url,
      "member": null,
      "author": addPartnerPost.author,
      "partnerId": queryVariable
    }
    Services.addPartnerPost(data)
      .then((res) => {
        setCreateInprocess(false);
        if (res.data.status) {
          getAllPartnerPostApi()
          setShowAlertAdd(true);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowAlertAdd(false);
            setAlertMsgAdd("");
          }, 3000);
          setAddPartnerPost(emptyObjectModal);
          setSelectedAddMemberOption(null)
          //addMemberSelectBox.current.value = ""
        } else {
          setShowAlertAdd(true);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowAlertAdd(false);
            setAlertMsgAdd("");
          }, 3000);
        }
        handleClearAllStates()
      })
      .catch((e) => {
        setCreateInprocess(false);
        handleClearAllStates()
        console.log(e);
      });
    setAddErr(emptyObjectErr);
    setErrMsgAdd("")
  };

  //on change for Edit
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setEditPartnerPost({ ...editPartnerPost, [e.target.name]: e.target.value });
    if (e.target.name === "title") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      } else {
        setEditErr({ ...editErr, [e.target.name]: "Enter title!" });
      }

    } else if (e.target.name === "url") {
      if (e.target.value.trim().length > 0) {
        if (!isUrlValid(e.target.value))
          setEditErr({
            ...editErr,
            [e.target.name]: "Invalid url",
          });
        else {
          setEditErr({ ...editErr, [e.target.name]: "" });
        }

      } else {
        setEditErr({ ...editErr, [e.target.name]: "Enter url!" });
      }
    } else if (e.target.name === "author") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      } else {
        setEditErr({ ...editErr, [e.target.name]: "Enter author!" });
      }
    }

  };

  //Called when user click on update changes
  const handleEditSubmit = async () => {
    var status = true;
    var temp = {};
    if (editPartnerPost.title.trim().length === 0) {
      temp = { ...temp, title: "Enter title!" };
      status = false;
    }
    if (editPartnerPost.url.trim().length === 0) {
      temp = { ...temp, url: "Enter url!" };
      status = false;
    } else {
      if (!isUrlValid(editPartnerPost.url.trim())) {
        temp = { ...temp, url: "Invalid url!" };
        status = false;
      }
    }
    if (editPartnerPost.author.trim().length === 0) {
      temp = { ...temp, author: "Enter author!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter(
        key => temp[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      editApiCall();
    } else {
      setEditErr(temp);
    }
  };

  //Api calll on edit partnerpost
  const editApiCall = () => {
    setEditInprocess(true);
    const data = {
      "title": editPartnerPost.title,
      "url": editPartnerPost.url,
      "member": null,
      "author": editPartnerPost.author,
      "partnerId": queryVariable,
      "id": selectedId
    }
    Services.editPartnerPost(data)
      .then((res) => {
        setEditInprocess(false);
        if (res.data.status) {
          setEditInprocess(false)
          setEditPartnerPost(emptyObjectModal)
          getAllPartnerPostApi()
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
          setAddPartnerPost(emptyObjectModal);
        } else {
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
        }
        handleClearAllStates()
      })
      .catch((e) => {
        setEditInprocess(false);
        handleClearAllStates()
        console.log(e);
      });
    setEditErr(emptyObjectErr);
  };

  //call when apply url changes
  const handleApplyUrlChange = (e) => {
    setApplyURL(e.target.value)
    // setUrlData("")
    if (e.target.value.trim().length === 0) {
      setApplyURLErrMsg("Enter Url!")
    } else {
      if (!isUrlValid(e.target.value.trim())) {
        setApplyURLErrMsg("Invalid Url!")
      } else {
        setApplyURLErrMsg("")
      }
    }
  }

  const handleRemoveUrl = async (e) => {
    var status = true;

    if (status) {
      setApplyLoader(true);
      const data = {
        "url": "",
        "partnerId": queryVariable,
      }
      Services.applyUrlToAllPostsBtn(data)
        .then((res) => {
          setApplyLoader(false);
          if (res.data.status) {
            setShowApplyAlert(true);
            setAlertMsgApplyUrl(res.data.message);
            setTimeout(() => {
              setShowApplyAlert(false);
              setAlertMsgApplyUrl("");
            }, 3000);
            setApplyURL("");
            setUrlData("")
          }
        })
        .catch((e) => {
          setApplyLoader(false);
          console.log(e);
        });

    }


  }

  //call when apply url change and submit url
  const handleApplyUrlSubmit = async () => {
    var status = true;
    var temp = {};
    if (applyURL.trim().length === 0) {
      setApplyURLErrMsg("Enter url!");
      status = false;
    } else {
      if (!isUrlValid(applyURL.trim())) {
        setApplyURLErrMsg("Invalid url!");
        status = false;
      } else {
        setApplyURLErrMsg("");
      }
    }
    if (status) {
      setApplyLoader(true);
      const data = {
        "url": applyURL,
        "partnerId": queryVariable,
      }
      Services.applyUrlToAllPostsBtn(data)
        .then((res) => {
          setApplyLoader(false);
          if (res.data.status) {
            setShowApplyAlert(true);
            setAlertMsgApplyUrl(res.data.message);
            setTimeout(() => {
              setShowApplyAlert(false);
              setAlertMsgApplyUrl("");
            }, 3000);
            setApplyURL(applyURL);
            setUrlData(applyURL);
          } else {
            setShowApplyAlert(true);
            setAlertMsgApplyUrl(res.data.message);
            setTimeout(() => {
              setShowApplyAlert(false);
              setAlertMsgApplyUrl("");
            }, 3000);
            setApplyURL("");
            setUrlData("");
          }
        })
        .catch((e) => {
          setApplyLoader(false);
          console.log(e);
        });
      setApplyURLErrMsg("");

    }
  }

  //call when select member change for edit post
  const onhandleEditSelectMember = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setSelectedEditMemberOption(membersList.filter(itm => itm.value === selectedOption.value)[0])
    setEditPartnerPost({ ...editPartnerPost, member: selectedOption.value })
    setEditErr({ ...editErr, member: "" })
  }

  //call when select member change for add post 
  const onhandleAddSelectMember = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setAddPartnerPost({ ...addPartnerPost, member: selectedOption.value })
    setSelectedAddMemberOption(membersList.filter(itm => itm.value === selectedOption.value)[0])
    setAddErr({ ...addErr, member: "" });
  }

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($('#AddPartnerPost').hasClass('show')) {
        $("#AddPartnerPost").modal('show')
      }

      if ($('#editPartnerPost').hasClass('show')) {
        $("#editPartnerPost").modal('show')
      }

    }
    else if (stayOnPage !== null && !stayOnPage) {
      if ($('#AddPartnerPost').hasClass('show')) { $("#AddPartnerPost").modal('hide') }

      if ($('#editPartnerPost').hasClass('show')) { $("#editPartnerPost").modal('hide') }
    }

  }, [stayOnPage])

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }

  return (
    <>
      {alertMsgApplyUrl && <div className="alert alert-info">{alertMsgApplyUrl}</div>}
      <div className="uic-title-rt-only">
        <button className="btn" data-toggle="modal"
          //data-target="#AddPartnerPost"
          onClick={(e) => {
            if (totalPosts === 4) {
              setAlertMsgApplyUrl("You can not add more than 4 posts.")
              setTimeout(() => {
                setAlertMsgApplyUrl('')
              }, 2000)
            } else {
              $("#AddPartnerPost").modal('show');
              handleCloseAddModel(e);
            }
          }}
        >
          Create Partner Post
        </button>
        <div className="cstm-applyurl-container">
          <div className="form-group">
            <div className="form-group-ct">
              <input
                type="text"
                className="cstm-url-field"
                placeholder="Add url to all Post"
                name="applyurl"
                onChange={handleApplyUrlChange}
                value={applyURL}
              />
              <span className="error">{applyURLErrMsg}</span>
            </div>
          </div>
          <div className="cstm-apply-btn-container">
            {applyLoader ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) :
              urlData !== undefined && urlData === "" ?
                (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleApplyUrlSubmit}
                    disabled={applyLoader}
                  >
                    Save
                  </button>
                )
                :
                (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleRemoveUrl}
                    disabled={applyLoader}
                  >
                    Remove
                  </button>
                )
            }
          </div>
        </div>
      </div>
      <div className="uic-fl">
        <div className="uic-fl-search">
          <input
            type="text"
            placeholder="Search Post"
            className="search-user-input"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              var currentValue;
              if (e.target.value) {
                currentValue = e.target.value;
              } else {
                currentValue = e.target.value;
                setSearchText(currentValue);
              }
              setActualsearch(currentValue);
              if (suggestionList !== undefined && suggestionList.length > 0) {
                setListIndex(-1);
                setSuggestionBox(true)
                var searchResult = suggestionList.filter((user) => {
                  return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                });
                setSuggestions(searchResult);
              }
            }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSearch(e.target.value)
              } else if (e.key === "ArrowDown") {
                const listLength =
                  suggestions.length > 15
                    ? 15
                    : suggestions.length;
                if (listIndex < listLength - 1) {
                  setListIndex(listIndex + 1);
                } else if (listIndex === listLength - 1) {
                  setListIndex(-1);
                  setSearchText(actualsearch);
                }
              } else if (e.key === "ArrowUp") {
                e.preventDefault();
                const listLength =
                  suggestions.length > 15
                    ? 15
                    : suggestions.length;
                if (listIndex === 0) {
                  setListIndex(-1);
                  setSearchText(actualsearch);
                } else if (listIndex === -1) {
                  setListIndex(listLength - 1);
                } else {
                  setListIndex(listIndex - 1);
                }
              }
            }}
            onBlur={() => setSuggestionBox(false)}
          />

          {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
            <div className="suggestion-box">
              <ul id="suggestion-list">
                {suggestions.map((suggestion, index) => (
                  <li
                    key={index}
                    onMouseDown={() => handleSearch(suggestion.name)}
                    className={index === listIndex ? 'selected-active' : ''}
                  >
                    {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length - 1)} </> : suggestion.name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

          </i>
        </div>
      </div>
      {showAlert && <div className="alert alert-info">{alertMsg}</div>}
      <Paper className="uic-tb-mn">
        <TableContainer className="uic-table-main">
          <Table className="uic-table">
            <TableHead className="uic-table-th">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}

                    className="uic-th-td"
                  >
                    {"action" !== column.id ? (
                      <>
                        <span className="custom-sorting">
                          <i
                            className="fa fa-angle-down"
                            onClick={(e) => {
                              handleSort(column.id, "Asc");
                            }}
                          ></i>
                          <i
                            className="fa fa-angle-up"
                            onClick={(e) => {
                              handleSort(column.id, "Dec");
                            }}
                          ></i>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loader ? (
              <div className="cstm-data-load">
                <img src={Loader} />{" "}
              </div>
            ) : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .length > 0 ? (
              <TableBody className="uic-table-td">
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        className="uic-th-td w-20"
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return column.id === "title" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              {value}
                            </TableCell>
                          ) : column.id === "url" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              <Link to={value} target='_blank'>{value}</Link>
                            </TableCell>
                          ) : column.id === "author" ? (
                            <TableCell
                              key={column.id}
                              className="uic-th-td w-17"
                            >
                              {value}
                            </TableCell>
                          ) : (
                            <TableCell
                              key={column.id}

                              className="uic-th-td uic-table-icon w-6"
                            >
                              <a
                                data-toggle="modal"
                                data-target="#editPartnerPost"
                                onClick={() => {
                                  handleEdit(row['action']);
                                }}
                              >
                                <Tooltip title='Edit' arrow><img src={editIcon} /></Tooltip>
                              </a>
                              {row["name"] !== "others" && (
                                <a
                                  data-toggle="modal"
                                  data-target="#DeletePartnerPost"
                                  onClick={() => {
                                    setDeleteId(row['action']);
                                  }}
                                >
                                  <Tooltip title='Delete' arrow><img src={imgDelete} /></Tooltip>
                                </a>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            ) : (
              <span className="no-data-found">No data found</span>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          className="uic-pagination"
          rowsPerPageOptions={[10, 20, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* create modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="AddPartnerPost"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Partner Post</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                  } else {
                    $("#AddPartnerPost").modal('hide')
                    handleCloseAddModel(e)
                  }

                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">

              {alertMsgAdd && <div className="alert alert-info">{alertMsgAdd}</div>}
              <div className="form-group">
                <div className="form-group-label">Title*</div>
                <div className="form-group-ct">
                  <input
                    maxLength={90}
                    type="text"
                    placeholder="Enter title"
                    name="title"
                    onChange={handleAddChange}
                    value={addPartnerPost.title}
                  />
                  <span className="text-limitaion">The maximum character limit is 90.</span>
                  <span className="error">{addErr.title}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Url*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter url"
                    name="url"
                    onChange={handleAddChange}
                    value={addPartnerPost.url}
                  />
                  <span className="error">{addErr.url}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Author*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter author"
                    name="author"
                    onChange={handleAddChange}
                    value={addPartnerPost.author}
                  />
                  <span className="error">{addErr.author}</span>
                </div>
              </div>
            </div>

            <div className="edit-user-details-footer cstm-create-button">
              {errMsgAdd && (errMsgAdd != "") && <div className="err err-info" style={{ color: "red" }} >{errMsgAdd}</div>}
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>

          </div>
        </div>
      </div>
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editPartnerPost"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Partner Post</h2>
                <button
                  type="button"
                  className="close"
                  //data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                    } else {
                      $("#editPartnerPost").modal('hide')
                      handleCloseAddModel(e)
                    }

                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Title*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter title"
                      name="title"
                      maxLength={90}
                      onChange={handleEditChange}
                      value={editPartnerPost.title}
                    />
                    <span className="text-limitaion">The maximum character limit is 90.</span>
                    <span className="error">{editErr.title}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Url*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter url"
                      name="url"
                      onChange={handleEditChange}
                      value={editPartnerPost.url}
                    />
                    <span className="error">{editErr.url}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Author*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter author"
                      name="author"
                      onChange={handleEditChange}
                      value={editPartnerPost.author}
                    />
                    <span className="error">{editErr.author}</span>
                  </div>
                </div>
              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}
      <div
        className="modal fade msg-popup"
        id="DeletePartnerPost"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgBigDelete} />
            </div>
            <h1>Are you sure you want to delete partner post from {partnername}?</h1>
            <div className="row">
              <div className="col-6">
                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn btn-red"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </span>)}
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn2" data-dismiss="modal">
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AlertComponent/> */}

    </>
  );
};
export default PartnerPost;
