import axios from "axios";
import authHeader from "../Services/auth.header";
import authAdminHeader from "./auth.admin.header";

const API_URL = process.env.REACT_APP_API_URL;

const retrievehistoryvideos = (data) => {
  return axios.get(
    API_URL +
      "getVideoHistoryByUser?page=" +
      data.page +
      "&limit=" +
      data.limit,
    {
      headers: authHeader(),
    }
  );
};

const gethistoryvideos = (data) => {
  return axios.get(
    API_URL +
      "getVideoHistoryByUser?page=" +
      data.page +
      "&limit=" +
      data.limit,
    {
      headers: authHeader(),
    }
  );
};

const addorupdatehistoryvideo = (data) => {
  return axios.patch(API_URL + "addAndUpdateVideoHistory", data, {
    headers: authHeader(),
  });
};

const deletehistoryvideo = (data) => {
  return axios.patch(API_URL + "removeVideoHistory", data, {
    headers: authHeader(),
  });
};

const clearallhistory = () => {
  //console.log(authHeader(),'token')

  return axios.patch(
    API_URL + "removeAllVideoHistory",
    {},
    { headers: authHeader() }
  );
};

const contentarchivesubfilter = (data) => {
  let searchKey = data.search !== undefined ? data.search : "";
  return axios.get(
    API_URL +
      "getVideoByCateFilterSort?categorie_id=" +
      data.cat_id +
      "&filter=" +
      data.filter +
      "&subcategorie_id=" +
      data.subcategorie_id +
      "&page=" +
      data.page +
      "&limit=20&sort=" +
      data.sort +
      "&search=" +
      searchKey,
    {
      headers: authHeader(),
    }
  );
};

const getDeactivateRequestUserList = (data) => {
  return axios.get(API_URL + "user/getDeactivateRequestedUser", {
    headers: authAdminHeader(),
  });
};

const getallvideolist_byadmin = () => {
  return axios.get(API_URL + "videolistbyadmin", {
    headers: authAdminHeader(),
  });
};

const getallvideolistbydateforadmin = (data) => {
  return axios.get(
    API_URL +
      "videolistbydatefilter?fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authHeader() }
  );
};
const getvideostatisticbyfieldtypeforadmn = (data) => {
  return axios.get(
    API_URL +
      "videostatisticbyfieldtypeforadmn?fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate +
      "&fieldtype=" +
      data.fieldtype,
    { headers: authHeader() }
  );
};
const getallevent_byadmin = (query) => {
  return axios.get(
    API_URL +
      "eventList" +
      (query !== undefined && query !== null ? query : ""),
    { headers: authAdminHeader() }
  );
};

const getrestevents_byadmin = (id) => {
  return axios.get(API_URL + "restevents/" + id, {
    headers: authAdminHeader(),
  });
};
const geteventbyid = (id) => {
  return axios.get(API_URL + "eventById/" + id, { headers: authAdminHeader() });
};
const deleteEvent = (data) => {
  return axios.post(API_URL + "event/delete", data, {
    headers: authAdminHeader(),
  });
};
const createevent = (data) => {
  return axios.post(API_URL + "createEvent", data, {
    headers: authAdminHeader(),
  });
};
const editEvent = (data) => {
  return axios.put(API_URL + "event/edit/" + data.id, data.data, {
    headers: authAdminHeader(),
  });
};
const exportuserlist = () => {
  return axios.get(API_URL + "user/downloadUserExcel", {
    headers: authAdminHeader(),
  });
};

const importuserlist = (data) => {
  return axios.post(API_URL + "user/importUserExcel", data, {
    headers: authAdminHeader(),
  });
};

const statisticdata_videocount = () => {
  return axios.get(API_URL + "videostatistic-totalvideo", {
    headers: authAdminHeader(),
  });
};

const statisticdata = (data) => {
  return axios.get(
    API_URL +
      "videostatisticforvideo-datewise?filtertype=" +
      data.filtertype +
      "&field=" +
      data.field +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticvideolist = (data) => {
  return axios.get(
    API_URL +
      "/videostatistic-videolist-datewise?filtertype=" +
      data.filtertype +
      "&field=" +
      data.field +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticfieldcount = (data) => {
  return axios.get(
    API_URL +
      "videoStatistic-fieldcount-datewise-foradmin?filtertype=" +
      data.filtertype +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticdata_categorycount = () => {
  return axios.get(API_URL + "statisticforcategory-totalcategories", {
    headers: authAdminHeader(),
  });
};

const statisticcategorydata = (data) => {
  return axios.get(
    API_URL +
      "statisticforcategory-datewise?filtertype=" +
      data.filtertype +
      "&field=" +
      data.field +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticcategorylist = (data) => {
  return axios.get(
    API_URL +
      "/statistic-categorylist-datewise?filtertype=" +
      data.filtertype +
      "&field=" +
      data.field +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticcategorycount = (data) => {
  return axios.get(
    API_URL +
      "statistic-categorycount-datewise-foradmin?filtertype=" +
      data.filtertype +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticdata_tagcount = () => {
  return axios.get(API_URL + "statisticfortag-totaltags", {
    headers: authAdminHeader(),
  });
};

const statistictagdata = (data) => {
  return axios.get(
    API_URL +
      "statisticfortag-datewise?filtertype=" +
      data.filtertype +
      "&field=" +
      data.field +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statistictaglist = (data) => {
  return axios.get(
    API_URL +
      "/statistic-taglist-datewise?filtertype=" +
      data.filtertype +
      "&field=" +
      data.field +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statistictagcount = (data) => {
  return axios.get(
    API_URL +
      "statistic-tagcount-datewise-foradmin?filtertype=" +
      data.filtertype +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticdata_speakercount = () => {
  return axios.get(API_URL + "statisticforspeaker-totalspeakers", {
    headers: authAdminHeader(),
  });
};
const statisticspeakerdata = (data) => {
  return axios.get(
    API_URL +
      "statisticforspeaker-datewise?filtertype=" +
      data.filtertype +
      "&field=" +
      data.field +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticspeakerlist = (query) => {
  return axios.get(API_URL + "/statistic-speakerlist-datewise" + query, {
    headers: authAdminHeader(),
  });
};

const statisticspeakercount = (data) => {
  return axios.get(
    API_URL +
      "statistic-speakercount-datewise-foradmin?filtertype=" +
      data.filtertype +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticdata_eventcount = () => {
  return axios.get(API_URL + "statisticforevent-totalevents", {
    headers: authAdminHeader(),
  });
};
const statisticeventdata = (data) => {
  return axios.get(
    API_URL +
      "statisticforevent-datewise?filtertype=" +
      data.filtertype +
      "&field=" +
      data.field +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticeventlist = (data) => {
  return axios.get(
    API_URL +
      "/statistic-eventlist-datewise?filtertype=" +
      data.filtertype +
      "&field=" +
      data.field +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const statisticeventcount = (data) => {
  return axios.get(
    API_URL +
      "statistic-eventcount-datewise-foradmin?filtertype=" +
      data.filtertype +
      "&fromdate=" +
      data.fromdate +
      "&todate=" +
      data.todate,
    { headers: authAdminHeader() }
  );
};

const getAllOverVideos = (data) => {
  var querystring =
    data.id !== undefined && data.id !== null
      ? "?id=" + data.id + "&search=" + encodeURIComponent(data.search)
      : "?search=" + encodeURIComponent(data.search);

  return axios.get(API_URL + "AS/getAllOverVideos/" + querystring, {
    headers: authAdminHeader(),
  });
};

const getSingleVideoUsers = (data) => {
  return axios.get(
    API_URL +
      "videostatistic-getsinglevideoviewusers?id=" +
      data.id +
      "&field=" +
      data.field +
      "&filtertype=" +
      data.filtertype,
    { headers: authAdminHeader() }
  );
};

const getSingleVideoSingleUser = (data) => {
  return axios.get(
    API_URL +
      "videostatistic-getsinglevideosingleuserview?id=" +
      data.id +
      "&field=" +
      data.field +
      "&filtertype=" +
      data.filtertype +
      "&userId=" +
      data.userId +
      "&platform=" +
      data.platform,
    { headers: authAdminHeader() }
  );
};

const addUserWatchVideoTime = (data) => {
  return axios.post(
    API_URL +
      "addUserWatchTime?videoid=" +
      data.videoid +
      "&watchtime=" +
      data.watchtime,
    {},
    { headers: authHeader() }
  );
};

const getAllVideoWatchedUser = (data) => {
  return axios.get(API_URL + "statistic-getallvideousers", {
    headers: authAdminHeader(),
  });
};

const getAllVideoListByUserId = (id) => {
  return axios.get(API_URL + "statistic-getallvideosbyuserid?id=" + id, {
    headers: authAdminHeader(),
  });
};

const getPartnerByfilterAndSorting = (data) => {
  return axios.get(
    API_URL +
      `getPartnerByFilterAndSorting?page=${data.page}&limit=10&oldest=${data.oldest}&partnerAsc=${data.partnerAsc}&partnerDesc=${data.partnerDesc}&categoryId=${data.categoryId}&filter=${data.filter}&name=${data.searchText}&newest=${data.newest}&relevant=${data.relevant}&rating=${data.rating}&value=${data.value}&partnerType=${data.partnerType}&subcategoryId=${data.subcategoryId}&dateFilterType=${data.dateFilterType}&fromDate=${data.fromDate}&toDate=${data.toDate}&tagId=${data.tag}`,
    { headers: authHeader() }
  );
};

const getPartnerResultCount = (query) => {
  return axios.get(API_URL + "getPartnerByFilterAndSortingCount" + query, {
    headers: authHeader(),
  });
};
const getPartnerCategoryListForUser = (data) => {
  return axios.get(API_URL + `/partner/categoryListForUser`, {
    headers: authHeader(),
  });
};

const getAllCategoryListForUser = (data) => {
  return axios.get(API_URL + `partner/categoryListForUser`, {
    headers: authHeader(),
  });
};

export default {
  getPartnerByfilterAndSorting,
  getPartnerResultCount,
  getPartnerCategoryListForUser,
  retrievehistoryvideos,
  gethistoryvideos,
  addorupdatehistoryvideo,
  deletehistoryvideo,
  clearallhistory,
  contentarchivesubfilter,
  getDeactivateRequestUserList,
  getallvideolist_byadmin,
  getallvideolistbydateforadmin,
  getvideostatisticbyfieldtypeforadmn,
  geteventbyid,
  deleteEvent,
  createevent,
  editEvent,
  getallevent_byadmin,
  getrestevents_byadmin,
  exportuserlist,
  importuserlist,
  statisticdata,
  statisticvideolist,
  statisticfieldcount,
  statisticcategorydata,
  statisticcategorylist,
  statisticcategorycount,
  statistictagdata,
  statistictaglist,
  statistictagcount,
  statisticspeakerdata,
  statisticspeakerlist,
  statisticspeakercount,
  statisticeventdata,
  statisticeventlist,
  statisticeventcount,
  statisticdata_videocount,
  statisticdata_categorycount,
  statisticdata_eventcount,
  statisticdata_speakercount,
  statisticdata_tagcount,
  getAllOverVideos,
  getSingleVideoUsers,
  addUserWatchVideoTime,
  getAllVideoWatchedUser,
  getAllVideoListByUserId,
  getAllCategoryListForUser,
  getSingleVideoSingleUser,
};
