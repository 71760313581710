import React from 'react'

const TableLoadingSkeleton = () => {
  return (
    <div className="doc-table-loading">
            <div className="doc-table-tr-loading skeleton-box"></div>
            <div className="doc-table-tr-loading skeleton-box"></div>
            <div className="doc-table-tr-loading skeleton-box"></div>
            <div className="doc-table-tr-loading skeleton-box"></div>
            <div className="doc-table-tr-loading skeleton-box"></div>
            <div className="doc-table-tr-loading skeleton-box"></div>
            <div className="doc-table-tr-loading skeleton-box"></div>
            <div className="doc-table-tr-loading skeleton-box"></div>
          </div>
  )
}

export default TableLoadingSkeleton
