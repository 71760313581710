import React from 'react'
import PropTypes from "prop-types"
const NotificationIcon = ({ height = 24, width = 24, iconColor = "#000", isFilled }) => {
    return (
        <>
            {isFilled ?
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M13.99 3.486c.63.186 1.302.472 1.932.899 1.418.96 2.576 2.606 2.576 5.234v5.714c0 .921 1.28 2.138 2.204 2.143v1.429H3.501L3.5 17.476c.925-.005 2.205-1.221 2.205-2.143V9.62c0-2.628 1.157-4.274 2.575-5.234a6.957 6.957 0 0 1 1.997-.917 1.905 1.905 0 0 1 3.713.018Zm-3.764 17.562c0-.526.427-.953.953-.953h1.904a.952.952 0 1 1 0 1.905H11.18a.952.952 0 0 1-.953-.952Z"
                        clipRule="evenodd"
                    />

                </svg>
                : <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M13.952 2.56a7.28 7.28 0 0 1 2.03.944C17.47 4.513 18.684 6.241 18.684 9v6c0 .926 1.323 2.245 2.314 2.25l.001 1.5H2.938v-1.5c1.006-.006 2.314-1.326 2.314-2.25V9c0-2.76 1.216-4.487 2.704-5.496a7.304 7.304 0 0 1 2.097-.963 2 2 0 0 1 3.899.02Zm-1.983 1.19c-.73.007-2.05.236-3.171.996C7.703 5.487 6.752 6.759 6.752 9v6c0 .901-.455 1.67-.996 2.25h12.425c-.54-.58-.996-1.349-.996-2.25V9c0-2.24-.95-3.513-2.045-4.254-1.122-.76-2.441-.99-3.171-.996ZM10 21a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Z"
                        clipRule="evenodd"
                    />

                </svg>}
        </>
    )
}
NotificationIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool
}
export default NotificationIcon
