import { Paper, Table, TableContainer } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TableHeader from "../AdminCommonComponent/TableComponent/TableHeader";
import authHeaderNotification from "../../Services/auth.admin.notification";
import TableBodyComponent from "../AdminCommonComponent/TableComponent/TableBodyComponent";
import Loader from "../../Images/loader.gif";
import { TablePagination } from "@mui/material";
import DeleteModal from "../AdminCommonComponent/deleteModal";
import cloneImage from "../../Images/clone-icon.png";
import arrowimg from "../../Images/arow-left-right.png";
import authAdminNotification from "../../Services/auth.admin.notification";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { useDispatch } from "react-redux";
const columns = [
  { id: "title", label: "Title", width: 16 },
  // { id: "subtitle", label: "Description", width: 16 },
  // { id: "sendType", label: "Frequency", width: 16 },
  { id: "notificationStatus", label: "Status", width: 11 },
  { id: "sendCount", label: "Sent", width: 11 },
  { id: "clickCount", label: "Clicked", width: 12 },
  { id: "action", label: "Actions", width: 14 },
];
function createData(
  srNo,
  title,
  // subtitle,
  // sendType,
  sendCount,
  clickCount,
  notificationStatus,
  action,
  triggerAt
) {
  return {
    srNo,
    title,
    // subtitle,
    // sendType,
    sendCount,
    clickCount,
    notificationStatus,
    action,
    triggerAt,
  };
}
const AllNotification = () => {
  const [allEvents, setallEvents] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [tableAlertMsg, setTableAlertMsg] = useState("");
  const [cloning, setCloning] = useState(false);
  const [cloneSuccess, setCloneSuccess] = useState(false);
  const [cloneMsg, setCloneMsg] = useState("");
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [filterType, setFilterType] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [showCustomFilter, setShowCustomFilter] = useState(false);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "days").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");

  const [Showalert, setShowalert] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const dispatch = useDispatch();
  const location = useLocation().search;
  const search = new URLSearchParams(location).get("search");
  const rowsFromUrl = new URLSearchParams(location).get("rowsPerPage");
  const pageFromUrl = new URLSearchParams(location).get("page");
  const filterTypeFromUrl = new URLSearchParams(location).get("filterType");
  const selectedFilterFromUrl = new URLSearchParams(location).get(
    "selectedFilter"
  );
  const fromDateFromUrl = new URLSearchParams(location).get("fromDate");
  const toDateFromUrl = new URLSearchParams(location).get("toDate");
  const sortField = new URLSearchParams(location).get("sortField");
  const sortOrder = new URLSearchParams(location).get("sortOrder");
  const [dataLoader, setDataLoader] = useState(false);
  const [applySortFilter, setApplySortFilter] = useState(false);
  const [isChangeDate, setIsChangeDate] = useState(true);

  const fromDateRef = useRef(fromDate);
  const toDateRef = useRef(toDate);

  useEffect(() => {
    notificationSuggestionApi();
  }, []);
  useEffect(() => {
    if (pageFromUrl === undefined || pageFromUrl === null) {
      setPage(0);
    } else if (rowsFromUrl === undefined || rowsFromUrl === null) {
      setRowsPerPage(20);
    }
    search !== undefined && search !== null
      ? setSearchText(search)
      : setSearchText("");
  }, [search]);

  //get all news list
  const getAllNotificationApi = (
    page,
    rowsPerPage,
    searchText,
    filtertype,
    reqFromDate,
    reqToDate,
    filter,
    col,
    sort
  ) => {
    let query = `?page=${page}&limit=${rowsPerPage}`;
    query +=
      col !== undefined &&
      col !== null &&
      col !== "" &&
      sort !== undefined &&
      sort !== null &&
      sort !== ""
        ? `&sortField=${col}&sortType=${sort}`
        : "";
    query +=
      searchText !== undefined && searchText !== null && searchText !== ""
        ? `&search=${searchText}`
        : "";
    query +=
      filtertype !== undefined && filtertype !== null && filtertype !== ""
        ? `&filtertype=${filtertype}`
        : "";
    query +=
      reqFromDate !== undefined &&
      reqFromDate !== null &&
      reqToDate !== undefined &&
      reqToDate !== null &&
      filtertype === "custom"
        ? `&reqFromDate=${reqFromDate}&reqToDate=${reqToDate}`
        : "";
    query +=
      filter !== undefined && filter !== null && filter !== ""
        ? `&filter=${filter}`
        : "";
    setLoader(true);
    authHeaderNotification
      .getAllNotificationList(query)
      .then((res) => {
        if (res.data.status) {
          setIsChangeDate(false)
          setLoader(false);
          setTotalData(res.data.data.totalNotification);
          var data = res.data.data.notifiction;
          var tbl = [];
          for (var i = 0; i < data.length; i++) {
            tbl[i] = createData(
              i + 1,
              data[i].title,
              //data[i].subtitle,
              // data[i].sendType === "immediate"
              //   ? "Immediate"
              //   : data[i].status !== "draft"
              //   ? data[i].triggerAt && data[i].triggerAt.length > 0
              //     ? `Scheduled for: ${moment(data[i].triggerAt).format(
              //         "MM/DD/YYYY hh:mm A"
              //       )}`
              //     : "Draft"
              //   : "Draft",
              data[i].sendCount,
              data[i].clickCount,
              data[i].notificationStatus === "published"
                ? "Sent"
                : data[i].notificationStatus.charAt(0).toUpperCase() +
                    data[i].notificationStatus.slice(1),
              data[i]._id,
              data[i].triggerAt && data[i].triggerAt.length > 0
                ? data[i].triggerAt
                : ""
            );
          }
          setRow(tbl);
          setallEvents(tbl);
        } else {
          setLoader(false);
          setRow([]);
          setallEvents([]);
          setTotalData(0);
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setTableAlertMsg("");
    }, 3000);
    // setTableAlertMsg
  }, [tableAlertMsg]);

  async function notificationSuggestionApi() {
    const suggestionListRes =
      await authAdminNotification.notificationSuggestionList();
    if (suggestionListRes.data.status) {
      setSuggestionList(suggestionListRes.data.data);
    }
  }
  useEffect(() => {
    getAllNotificationApi(
      pageFromUrl !== null ? pageFromUrl : page + 1,
      rowsFromUrl !== null ? rowsFromUrl : rowsPerPage,
      search !== null ? search : searchText,
      filterTypeFromUrl !== null ? filterTypeFromUrl : filterType,
      fromDateFromUrl !== null ? fromDateFromUrl : fromDate,
      toDateFromUrl !== null ? toDateFromUrl : toDate,
      selectedFilterFromUrl !== null ? selectedFilterFromUrl : selectedFilter,
      sortField !== null ? sortField : sortField,
      sortOrder !== null ? sortOrder : sortOrder
    );
  }, [page, rowsPerPage, search, filterTypeFromUrl, sortOrder, sortField]);

  //create object from array
  const createObjectFromArray = (arr, updateAllEventArray = true) => {
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].srNo,
        arr[i].title,
        //arr[i].subtitle,
        // arr[i].sendType === "immediate"
        //   ? "Immediate"
        //   : arr[i].status !== "draft"
        //   ? arr[i].triggerAt && arr[i].triggerAt.length > 0
        //     ? `Scheduled for: ${moment(arr[i].triggerAt).format(
        //         "MM/DD/YYYY hh:mm A"
        //       )}`
        //     : "Draft"
        //   : "Draft",
        arr[i].sendCount,
        arr[i].clickCount,
        arr[i].notificationStatus,
        arr[i].action,
        arr[i].triggerAt && arr[i].triggerAt.length > 0 ? arr[i].triggerAt : ""
      );
    }
    setPage(0);
    setRow(tbl);
    if (updateAllEventArray) setallEvents(tbl);
  };

  //for pagination
  const handleChangePage = (event, newPage) => {
    navigate(
      `/notification/list?search=${searchText}&rowsPerPage=${rowsPerPage}&page=${
        newPage + 1
      }&filterType=${filterType}&fromDate=${fromDate}&toDate=${toDate}&selectedFilter=${selectedFilter}&sortField=${
        sortField ? sortField : ""
      }&sortOrder=${sortOrder ? sortOrder : ""}`
    );
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    navigate(
      `/notification/list?search=${searchText}&rowsPerPage=${+event.target
        .value}&page=${1}&filterType=${filterType}&fromDate=${fromDate}&toDate=${toDate}&selectedFilter=${selectedFilter}`
    );
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //handle delete Notification
  const handleDelete = () => {
    authHeaderNotification
      .deleteNotification(deleteId)
      .then((res) => {
        if (res.data.status) {
          setPage(0);

          getAllNotificationApi(
            pageFromUrl !== null ? pageFromUrl : 1,
            rowsFromUrl !== null ? rowsFromUrl : rowsPerPage,
            search !== null ? search : searchText,
            filterTypeFromUrl !== null ? filterTypeFromUrl : filterType,
            fromDateFromUrl !== null ? fromDateFromUrl : fromDate,
            toDateFromUrl !== null ? toDateFromUrl : toDate,
            selectedFilterFromUrl !== null
              ? selectedFilterFromUrl
              : selectedFilter
          );
        }

        setTableAlertMsg(res.data.message);
        setTimeout(() => {
          setTableAlertMsg("");
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //clone notification function
  async function handleCloneNotification() {
    setCloning(true);
    try {
      const cloneRes = await authAdminNotification.cloneNotification(deleteId);
      if (cloneRes.data.status) {
        setCloneSuccess(true);
        
        // getAllNotificationApi(
        //   page + 1,
        //   rowsPerPage,
        //   searchText,
        //   filterType,
        //   fromDate,
        //   toDate,
        //   selectedFilter
        // );
        setCloning(false);
        setCloneMsg(cloneRes.data.message);
        setTimeout(() => {
          setCloneSuccess(false);
          setCloneMsg("");
          const cloneData = cloneRes.data.data
          navigate(`/notification/detail?id=${cloneData._id}`)

        }, 3000);
      }
    } catch (error) {}
  }

  // handle search
  const handleSearch = (text) => {
    setSuggestionBox(false);
    setPage(0);
    navigate(
      `/notification/list?search=${text}&rowsPerPage=${rowsPerPage}&page=1&filterType=${filterType}&fromDate=${fromDate}&toDate=${toDate}&selectedFilter=${selectedFilter}`
    );
  };

  //called when sorting columns
  const handleSorting = (search, col, direc, arr) => {
    console.log(col, direc,'direc');
    if (direc === sortOrder && col === sortField) {
      setLoader(false);
    } else {
      setPage(0);
      navigate(
        `/notification/list?search=${searchText}&rowsPerPage=${rowsPerPage}&page=${1}&filterType=${filterType}&fromDate=${fromDate}&toDate=${toDate}&selectedFilter=${selectedFilter}&sortField=${col}&sortOrder=${direc}`
      );
    }
  };

  //called when filter change
  const handleOnChangeFilterType = (e) => {
    setFilterType(e.target.value);
    setPage(0);
    const filter = e.target.value;
    if (filter === "custom") {
      setShowCustomFilter(true);
    } else {
      setLoader(false);
      if (filterType !== e.target.value) {
        navigate(
          `/notification/list?search=${searchText}&rowsPerPage=${rowsPerPage}&page=${1}&filterType=${
            e.target.value
          }&fromDate=${fromDate}&toDate=${toDate}&selectedFilter=${selectedFilter}`
        );
      }
      setShowCustomFilter(false);
    }
  };
    //check date changed or not
    const handleDateChange = (date, setDateFunction, dateRef) => {
      setIsChangeDate(false)
      if (date !== dateRef.current) {
        setDateFunction(date);
        setIsChangeDate(true)
      }
    };
  //called when calender close
  const handleCalenderClose = () => {
    if (filterType === "custom") {
      setPage(0);
      if (
        moment(new Date(fromDate)).format("YYYY-MM-DD") >=
        moment(new Date(toDate)).format("YYYY-MM-DD")
      ) {
        setShowalert(true);
        setAlertMsg("From date should be less than To date!");
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      } else {
        if (fromDate !== "" && toDate !== "" && isChangeDate) {
          setLoader(true);
          setPage(0);
          getAllNotificationApi(
            1,
            rowsPerPage,
            searchText,
            filterType,
            fromDate,
            toDate,
            selectedFilter
          );
        }
      }
    }
  };

  // handle status filter
  const handleStatusFilter = (e) => {
    setSelectedFilter(e.target.value);
    setPage(0);
    getAllNotificationApi(
      1,
      rowsPerPage,
      searchText,
      filterType,
      fromDate,
      toDate,
      e.target.value
    );
  };

  //called when search suggestion list index changed
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>All Notification</h1>
            <div>
              <button
                className="btn"
                onClick={() => {
                  dispatch({
                    type: "NAVIGATEPAGE",
                    payload: "/notification/list?search=",
                  });
                  navigate("/notification/createNotification");
                }}
              >
                Add Notification
              </button>
            </div>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.title
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    setSuggestionBox(false);
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength = suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength = suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
              />
              {suggestionBox &&
                suggestions.length > 0 &&
                searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.title)}
                          className={
                            index === listIndex ? "selected-active" : ""
                          }
                        >
                          {searchText !== null &&
                          searchText.length > 0 &&
                          suggestion.title
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase()) ? (
                            <>
                              <b>
                                {suggestion.title.slice(0, searchText.length)}
                              </b>
                              {suggestion.title &&
                                suggestion.title.slice(
                                  searchText.length,
                                  suggestion.title.length
                                )}
                            </>
                          ) : (
                            suggestion.title
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              />
            </div>
            <div className="uic-fl-btn sort-by-mn lifetime-only">
              <div className="sort-by-mn-title">Filter by : </div>
              <div className="cstm-select-ap">
                <select value={filterType} onChange={handleOnChangeFilterType}>
                  <option value="">Lifetime</option>
                  <option value="first24hrs">Last 24 h</option>
                  <option value="past7days">Last 7 Days</option>
                  <option value="past28days">Last 30 Days</option>
                  <option value="past90days">Last 90 Days</option>
                  <option value="past365days">Last 365 Days</option>
                  <option value="custom">Custom</option>
                </select>
              </div>
              {filterType === "custom" && showCustomFilter ? (
                <div className="cstm-select-ap-drop cstm-datepicker-field">
                  <ReactDatePicker
                    showIcon
                    selected={fromDate}
                    onChange={(date) => handleDateChange(date, setFromDate, fromDateRef)}                    
                    onCalendarClose={handleCalenderClose}
                  />
                  <img src={arrowimg} className="cstm-arrow-left-right" />
                  <ReactDatePicker
                    showIcon
                    selected={toDate}
                    onChange={(date) => handleDateChange(date, setToDate, toDateRef)}
                    onCalendarClose={handleCalenderClose}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="uic-fl-btn sort-by-mn lifetime-only">
              <div className="cstm-select-ap">
                <select value={selectedFilter} onChange={handleStatusFilter}>
                  <option value="">All</option>
                  <option value="sent">Sent</option>
                  <option value="pending">Pending</option>
                  <option value="draft">Draft</option>
                </select>
              </div>
            </div>
          </div>
          {tableAlertMsg && (
            <div className="alert alert-info">{tableAlertMsg}</div>
          )}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table min-w-auto">
                <TableHeader
                  listApi={handleSorting}
                  columns={columns}
                  createObjectFromArray={createObjectFromArray}
                  allEventList={allEvents}
                  setLoader={setLoader}
                  columnsWithoutSoritng={[
                    "image",
                    "action",
                    "drag",
                    "sendType",
                    "notificationStatus",
                  ]}
                />
                {loader ? (
                  <div className="cstm-data-load">
                    <img src={Loader} alt="" />{" "}
                  </div>
                ) : rows.length > 0 ? (
                  <TableBodyComponent
                    isPagination={true}
                    rows={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    columns={columns}
                    deleteModalId="deleteModal"
                    editCallBack={(value, row) => {
                      dispatch({ type: "PAGECHANGE", payload: false });
                      dispatch({
                        type: "NAVIGATEPAGE",
                        payload: `/notification/list?search=${searchText}&rowsPerPage=${rowsPerPage}&page=1&filterType=${filterType}&fromDate=${fromDate}&toDate=${toDate}&selectedFilter=${selectedFilter}`,
                      });
                    }}
                    setDeleteId={(id) => setDeleteId(id)}
                  />
                ) : (
                  <span className="no-data-found">No data found</span>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      {/* Modal Delete */}
      <DeleteModal
        message={"Are you sure you want to delete this notification?"}
        handleDelete={handleDelete}
      />
      <div
        className="modal fade msg-popup"
        id="cloneNotification"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={cloneImage} />
            </div>
            <div className="cstm-clone-title">Clone Notification</div>
            <h1>Are you sure you want to clone this notification?</h1>
            <div className="row">
              <div className="col-6">
                <span class="msg-btn">
                  <button type="button" data-dismiss="modal" className="btn">
                    Discard
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn2"
                    data-dismiss="modal"
                    onClick={() => {
                      handleCloneNotification();
                    }}
                  >
                    Clone
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {cloning && (
        <div className="cstm-clone-msg-container">
          <h3>Cloning Record...</h3>
          <p>Please wait while the record is being cloned.</p>
        </div>
      )}
      {cloneSuccess && (
        <div className="cstm-success-msg-container">
          <div className="cstm-success-msg-container-title">{cloneMsg}</div>
        </div>
      )}
    </>
  );
};

export default AllNotification;
