import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import auth from "../../Services/auth0services";
import Services from "../../Services/auth.service";
import Loader2 from "../../Images/loader2.gif";
const AdminLogin = () => {
  const [isProcessing, setisProcessing] = useState(false)
  const [userDetail, setUserDetail] = useState({
    email: "",
    password: "",
  });
  const [DetailErr, setDetailErr] = useState({
    emailErr: "",
    passwordErr: "",
  });
  const [err, setErr] = useState("");
  let history = useNavigate();
  // Called when input field changed
  const handleChange = (e) => {
    switch (e.target.type) {
      case "email":
        e.target.value.length > 1
          ? e.target.value
            .toLowerCase()
            .match(
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) === null
            ? setDetailErr({
              ...DetailErr,
              [e.target.name + "Err"]: "Invalid email format!",
            })
            : setDetailErr({ ...DetailErr, [e.target.name + "Err"]: "" })
          : setDetailErr({
            ...DetailErr,
            [e.target.name + "Err"]: "Enter email address!",
          });
        break;
      case "password":
        e.target.value.length > 0
          ? setDetailErr({ ...DetailErr, [e.target.name + "Err"]: "" })
          : setDetailErr({
            ...DetailErr,
            [e.target.name + "Err"]: "Enter password!",
          });
        break;
      default:
        break;
    }
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
  };
  // Called on click event of login button
  const handleSubmit = (e) => {
    e.preventDefault();
    var obj;
    obj =
      userDetail.email.length > 0 && userDetail.password.length > 0
        ? { ...DetailErr }
        : userDetail.email.length === 0 && userDetail.password.length === 0
          ? { emailErr: "Enter email address!", passwordErr: "Enter password!" }
          : userDetail.email.length === 0
            ? { ...DetailErr, emailErr: "Enter email address!" }
            : { ...DetailErr, passwordErr: "Enter password!" };
    setDetailErr(obj);
    if (
      obj.emailErr.length === 0 &&
      obj.passwordErr.length === 0 &&
      DetailErr.emailErr.length === 0 &&
      DetailErr.passwordErr.length === 0
    ) {
      setisProcessing(true);
      loginUser();
    }
  };
  // Api Call
  const loginUser = () => {
    var loginData = {
      email: userDetail.email,
      password: userDetail.password,
    };
    Services.adminLogin(loginData)
      .then(function (response) {
        if (response.data.status) {
          localStorage.setItem("adminuserid", response.data.data._id);
          localStorage.setItem("isAdmin", true)
          localStorage.setItem("adminusername", response.data.data.first_name + " " + response.data.data.last_name);
          auth.login(
            {
              username: userDetail.email,
              password: userDetail.password,
              realm: process.env.REACT_APP_AUTH0_DB_NAME,
              redirectUri: window.location.origin + "/admin/postauthenticate",
              responseType: "token id_token",
            },
            function (err, result) {
              if (err) {
                setisProcessing(false)
                console.log("Login failed!");
                setErr(err.error_description);
              } else {
                setisProcessing(false)
                console.log("login succesfully!");
                history("/user/listing");
              }
            }
          );
        } else {
          setisProcessing(false)
          setErr(response.data.message);
        }
      })
      .catch(function (error) {
        setisProcessing(false)
        console.log(error);
      });
  };
  return (
    <>
      <div class="um-main">
        <div class="container-fluid">
          <div class="row">
            <div class="um-right">
              <div class="um-right-in">
                <div class="um-right-in-title">Login</div>
                <div class="um-right-in-sub">Let’s get started</div>
                <div id="err" class="form-group admin-error-msg">
                  <p className="error-msg">{err}</p>
                </div>
                <form onSubmit={handleSubmit}>
                  <div class="form-group">
                    <div class="um-right-in-label">Email*</div>
                    <div class="um-right-in-box">
                      <input
                        type="email"
                        name="email"
                        placeholder="enter your email"
                        value={userDetail.email}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="error-msg">{DetailErr.emailErr}</span>
                  </div>
                  <div class="form-group">
                    <div class="um-right-in-label">Password*</div>
                    <div class="um-right-in-box">
                      <input
                        type="password"
                        name="password"
                        placeholder="enter your password"
                        value={userDetail.password}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="error-msg">{DetailErr.passwordErr}</span>
                  </div>
                  <div className="form-group form-group-button">
                    {isProcessing ? (
                      <div className="um-rgt-btn-loading2">
                        <span className="loading-btn-in2">
                          <img src={Loader2} />
                        </span>
                        <button type="button" className="btn" disabled={true}>
                          Login
                        </button>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn"
                        onClick={handleSubmit}
                      >
                        Login
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminLogin;