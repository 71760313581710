import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import Loader2 from "../../../Images/loader2.gif";
import { emptyObject } from "./CommonFunction";
import Multiselect from "multiselect-react-dropdown";
import {
  useEditGuestTicketMutation,
  useLazyGetGuestTicketByIdQuery,
} from "../../../Services/guestTicket";
import { useLocation } from "react-router-dom";

const EditGuestTicket = ({ forceRender, editId, isCloseAddModal }) => {
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [editEvent, setEditEvent] = useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [isChecked, setIsChecked] = useState(false);
  const [basicPriceCheck, setBasicPriceCheck] = useState(false);
  const [specialPriceCheck, setSpecialPriceCheck] = useState(false);
  const [specialPrice, setSpecialPrice] = useState("");
  const [addonsList, setAddonsList] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [createInprocess, setCreateInprocess] = useState(false);
  const [showAlert, setShowAlert] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const editDescriptionRef = useRef(null);
  //event id
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("id");
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  //guest ticket edit api
  const [editGuestTicket] = useEditGuestTicketMutation();

  //get guest ticket by id
  const [guestTicketById] = useLazyGetGuestTicketByIdQuery();

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#editModalGuestTickets").hasClass("show")) {
        $("#editModalGuestTickets").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#editModalGuestTickets").hasClass("show")) {
        $("#editModalGuestTickets").modal("hide");
      }
    }
  }, [stayOnPage]);

  //clear edit state when click on create main ticket
  useEffect(() => {
    handleCloseAddModel();
  }, [isCloseAddModal]);

  useEffect(() => {
    handleEdit();
  }, [forceRender]);

  //fetch data to edit and load on edit modal
  const handleEdit = async () => {
    setSelectedId(editId);
    try {
      const response = await guestTicketById({ ticket_id: editId });
      if (response?.data?.status) {
        let data = response?.data?.data;
        let priceTypeCheck =
          data?.priceType === "FREE"
            ? "free"
            : data?.priceType === "INDIVIDUAL"
            ? "standAlonePrice"
            : "basePrice";

        let specialPriceTypeCheck =
          data?.specialPriceType === "FLAT"
            ? "flatAmount"
            : data?.specialPriceType === "PERCENTAGE"
            ? "Discount"
            : "";

        const validDateFormate = (date) => {
          if (!date) return;
          const newDate = new Date(date);
          const formattedDate = `${newDate.getFullYear()}-${(
            newDate.getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${newDate
            .getDate()
            .toString()
            .padStart(2, "0")}`;
          return formattedDate;
        };

        const getTime = (dateTimeString) => {
          let inputDate = new Date(dateTimeString);
          let hours = inputDate.getHours();
          let minutes = inputDate.getMinutes();
          minutes = minutes < 10 ? "0" + minutes : minutes;
          let formattedTime = hours + ":" + minutes;
          return formattedTime;
        };
        setEditEvent({
          name: data?.name,
          description: data?.description,
          quantity: 500,
          priceType: priceTypeCheck,
          specialPriceType: specialPriceTypeCheck,
          specialPriceForGuest: data?.specialPriceForGuest,
          isCancellation: data?.isCancellation,
          cancellationDaysBeforeEvent: Number(
            data?.cancellationDaysBeforeEvent
          ),
          maxAllowedTicket: data?.maxAllowedTicket,
          isAddOnAvailable: "true",
          selectAddons: data?.event_addons,
          isGuestRegistration: "false",
          discount:
            specialPriceTypeCheck === "Discount"
              ? data?.specialPriceForGuest
              : "",
          flatAmount:
            specialPriceTypeCheck === "flatAmount"
              ? data?.specialPriceForGuest
              : "",
          standAlonePriceInput: data?.price,
          specialPriceStartDate: validDateFormate(data?.salesStartDate),
          specialPriceEndDate: validDateFormate(data?.salesEndDate),
          startTime: getTime(data?.salesStartDate),
          endTime: getTime(data?.salesEndDate),
        });
        setBasicPriceCheck(
          data?.priceType === "BASE" || data?.priceType === "SPECIAL"
        );
        setSpecialPriceCheck(data?.isSpecialPrice);
        setIsChecked(data?.isCancellation);
        setSpecialPrice(specialPriceTypeCheck);
      }
    } catch (err) {
      console.log("Error:", err);
    }
  };
  //   close create session module
  const handleCloseAddModel = (e) => {
    if (editDescriptionRef.current) {
      editDescriptionRef.current.setContent("");
    }
    setEditEvent(emptyObject);
    setEditErr(emptyObject);
    setBasicPriceCheck(false);
    setSpecialPriceCheck(false);
    setIsChecked(false);
    setSpecialPrice("");
    setSelectedAddons([]);
    handleClearAllStates();
    setCreateInprocess(false);
    setShowAlert(false);
    setShowAlertEdit(false);
    setShowAlertAdd(false);
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //onChange event for edit guest tickets
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });

    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, name: "" });
      }
    } else if (e.target.name === "maxAllowedTicket") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value)) {
        setEditErr({
          ...editErr,
          maxAllowedTicket: "Please enter a valid number for maximum order",
        });
      }
      if (e.target.value.includes(".")) {
        setEditErr({
          ...editErr,
          maxAllowedTicket: "Max ticket should not be a decimal number",
        });
      }
    } else if (e.target.name === "discount") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }

      if (
        isNaN(e.target.value) ||
        e.target.value < 0 ||
        e.target.value >= 100
      ) {
        setEditErr({
          ...editErr,
          discount: "Discount should be a valid number between 1 and 100",
        });
      }
    } else if (e.target.name === "flatAmount") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value)) {
        setEditErr({
          ...editErr,
          flatAmount: "Flat Price should be a valid number greater than 0",
        });
      }
    } else if (e.target.name === "cancellationDaysBeforeEvent") {
      if (isNaN(e.target.value)) {
        setEditErr({
          ...editErr,
          cancellationDaysBeforeEvent: "Enter a valid number",
        });
      }
    } else if (e.target.name === "standAlonePriceInput") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }

      if (isNaN(e.target.value)) {
        setEditErr({
          ...editErr,
          standAlonePriceInput: "Price should greater than 0",
        });
      }
    }

    if (e.target.value === "basePrice") {
      setSpecialPriceCheck(false);
      setBasicPriceCheck(true);
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          standAlonePriceInput: "",
          specialPriceStartDate: "",
          specialPriceEndDate: "",
          specialPriceType: "",
          startTime: "",
          endTime: "",
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setSpecialPrice(false);
      setEditErr({ ...editErr, [e.target.name]: "" });
    } else if (e.target.value === "free") {
      setEditErr({ ...editErr, [e.target.name]: "" });
      setSpecialPriceCheck(false);
      setBasicPriceCheck(false);
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          standAlonePriceInput: "",
          specialPriceStartDate: "",
          specialPriceEndDate: "",
        };
        return updatedAddEvent;
      });
      setSpecialPrice(false);
    } else if (e.target.value === "standAlonePrice") {
      setSpecialPriceCheck(false);
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          standAlonePriceInput: "",
          specialPriceStartDate: "",
          specialPriceEndDate: "",
          startTime: "",
          endTime: "",
          specialPriceType: "",
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setSpecialPrice(false);
    }

    if (e.target.value === "flatAmount") {
      setSpecialPrice("flatAmount");
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          discount: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, [e.target.name]: "" });
    } else if (e.target.value === "Discount") {
      setSpecialPrice("Discount");
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          flatAmount: "",
        };
        return updatedAddEvent;
      });
      setEditErr({ ...editErr, [e.target.name]: "" });
    }
    if (e.target.name === "isCancellation") {
      setIsChecked(e.target.checked);
      setEditEvent({ ...editEvent, [e.target.name]: e.target.checked });
    }

    if (
      !specialPriceCheck ||
      (e.target.name === "specialPriceType" && e.target.value === "")
    ) {
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          specialPriceType: "",
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setSpecialPrice((p) => "");
    }

    if (!isChecked) {
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          cancellationDaysBeforeEvent: "",
        };
        return updatedAddEvent;
      });
    }

    if (editEvent.priceType !== "") {
      setEditErr({ ...editErr, [e.target.name]: "" });
    }
  };
  //submit edited form
  const handleEditSubmit = () => {
    let status = true;
    let temp = {};
    if (editEvent?.name?.trim().length === 0) {
      temp = { ...temp, name: "Enter name" };
      status = false;
    }

    if (
      isNaN(editEvent?.maxAllowedTicket) ||
      editEvent?.maxAllowedTicket === ""
    ) {
      temp = {
        ...temp,
        maxAllowedTicket: "Please enter a valid number for maximum order",
      };
      status = false;
    }

    if (String(editEvent.maxAllowedTicket).includes(".")) {
      temp = {
        ...temp,
        maxAllowedTicket: "Max ticket should not be a decimal number",
      };
      status = false;
    }

    if (editEvent?.priceType === "") {
      temp = {
        ...temp,
        priceType: "Please choose a price type",
      };
      status = false;
    }

    if (
      editEvent?.priceType === "basePrice" ||
      editEvent?.priceType === "standAlonePrice" ||
      (editEvent?.priceType === "SPECIAL" && specialPriceCheck)
    ) {
      if (specialPriceCheck && editEvent?.specialPriceType === "") {
        temp = {
          ...temp,
          specialPriceType: "Please choose either Discount or Flat Price",
        };
        status = false;
      } else {
        if (editEvent?.specialPriceType === "Discount") {
          const discountValue = parseFloat(editEvent?.discount);
          if (
            isNaN(discountValue) ||
            discountValue <= 0 ||
            discountValue >= 100
          ) {
            temp = {
              ...temp,
              discount: "Discount should be a valid number between 1 and 100",
            };
            status = false;
          }
        } else if (editEvent?.specialPriceType === "flatAmount") {
          const flatAmountValue = parseFloat(editEvent?.flatAmount);
          if (isNaN(flatAmountValue) || flatAmountValue <= 0) {
            temp = {
              ...temp,
              flatAmount: "Flat Price should be a valid number greater than 0",
            };
            status = false;
          }
        }
      }
    }

    if (
      editEvent?.priceType === "standAlonePrice" &&
      (editEvent?.standAlonePriceInput === "" ||
        isNaN(editEvent?.standAlonePriceInput))
    ) {
      temp = {
        ...temp,
        standAlonePriceInput: "Price should greater than 0",
      };
      status = false;
    }

    if (isChecked && !editEvent?.cancellationDaysBeforeEvent) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent:
          "Please either uncheck the checkbox or provide a valid input value",
      };
      status = false;
    } else if (isNaN(editEvent?.cancellationDaysBeforeEvent)) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent: "Enter a valid number",
      };
      status = false;
    }

    if (specialPriceCheck && editEvent?.specialPriceStartDate === "") {
      temp = {
        ...temp,
        specialPriceStartDate: "Select start date",
      };
      status = false;
    }
    if (specialPriceCheck && editEvent?.specialPriceEndDate === "") {
      temp = {
        ...temp,
        specialPriceEndDate: "Select end date",
      };
      status = false;
    }

    if (editEvent?.startTime === "" && specialPriceCheck) {
      temp = {
        ...temp,
        startTime: "Select start time",
      };
      status = false;
    }

    if (editEvent?.endTime === "" && specialPriceCheck) {
      temp = {
        ...temp,
        endTime: "Select end time",
      };
      status = false;
    }

    if (status) {
      guestTicketEditApi();
      if (editDescriptionRef.current) {
        editDescriptionRef.current.setContent("");
      }
    } else {
      setEditErr(temp);
    }
  };

  //guest ticket edit api
  const guestTicketEditApi = async () => {
    let priceType;
    if (editEvent?.priceType === "free") {
      priceType = "FREE";
    } else if (editEvent?.priceType === "basePrice") {
      priceType = "BASE";
    } else if (editEvent.priceType === "standAlonePrice") {
      priceType = "INDIVIDUAL";
    }

    let specialPriceType;
    if (editEvent?.specialPriceType === "flatAmount") {
      specialPriceType = "FLAT";
    } else if (editEvent?.specialPriceType === "Discount") {
      specialPriceType = "PERCENTAGE";
    }

    let specialPriceForGuest;
    if (editEvent?.flatAmount) {
      specialPriceForGuest = editEvent?.flatAmount;
    } else if (editEvent?.discount) {
      specialPriceForGuest = editEvent?.discount;
    }

    //convert string date to formatted date
    const dateConvertor = (date, time) => {
      if (!date) return;
      const combinedDateTimeString = date + "T" + time + ":00";
      const combinedDateTime = new Date(combinedDateTimeString);
      const isoFormattedDateTime = combinedDateTime.toISOString();
      return isoFormattedDateTime;
    };

    const formdata = {
      name: editEvent?.name,
      description: editDescriptionRef.current.getContent(),
      quantity: 500,
      priceType: priceType,
      isSpecialPrice: specialPriceCheck,
      price: Number(editEvent?.standAlonePriceInput),
      specialPriceType: specialPriceType,
      specialPriceForGuest: Number(specialPriceForGuest),
      isCancellation: isChecked,
      cancellationDaysBeforeEvent: Number(
        editEvent?.cancellationDaysBeforeEvent
      ),
      maxAllowedTicket: Number(editEvent?.maxAllowedTicket),
      isAddOnAvailable: selectedAddons.length > 0 ? true : false,
      selectAddons: editEvent?.selectAddons,
      isGuestRegistration: "false",
      salesStartDate: dateConvertor(
        editEvent.specialPriceStartDate,
        editEvent.startTime
      ),
      salesEndDate: dateConvertor(
        editEvent.specialPriceEndDate,
        editEvent.endTime
      ),
      eventId: eventId,
    };
    try {
      const editApiResponse = await editGuestTicket({
        id: selectedId,
        body: formdata,
      });
      if (editApiResponse?.data?.status) {
        setShowAlertEdit(true);
        setAlertMsgEdit(editApiResponse?.data?.message);
        setTimeout(() => {
          setShowAlertEdit(false);
          setAlertMsgEdit("");
        }, 3000);
        setTimeout(() => {
          document.querySelector("#editModalGuestTickets .close").click();
        }, 1500);
        handleClearAllStates();
      } else {
        setShowAlertEdit(true);
        setAlertMsgEdit(editApiResponse?.data?.message);
        setTimeout(() => {
          setShowAlertEdit(false);
          setAlertMsgEdit("");
        }, 3000);
      }
    } catch (err) {
      console.log("Edit submit err:", err);
    }
  };

  //handle special price
  const handleAddSpecialpriceHandler = (e) => {
    setSpecialPriceCheck(e.target.checked);
    if (!e.target.checked) {
      setSpecialPrice("");
      setEditEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          specialPriceType: "",
          flatAmount: "",
          discount: "",
          specialPriceStartDate: "",
          specialPriceEndDate: "",
        };
        return updatedAddEvent;
      });
    }
  };

  return (
    <>
      <div
        className="modal fade edit-user-details-popup"
        id="editModalGuestTickets"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Edit guest ticket</h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#editModalGuestTickets").modal("hide");
                    handleCloseAddModel();
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertEdit && (
                <div className="alert alert-info">{alertMsgEdit}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Guest Ticket Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter guest ticket name"
                    name="name"
                    onChange={handleEditChange}
                    value={editEvent?.name}
                  />
                  <span className="error">{editErr.name}</span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Description</div>
                  <div className="cstm-editor-tool-ds">
                    <TinyEditor
                      valueRef={editDescriptionRef}
                      value={editEvent.description}
                      handleEditorChange={(value, editor) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setEditErr({
                          ...editErr,
                          description: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{editErr.description}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Max Order Per Ticket*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter maximum order per ticket"
                    name="maxAllowedTicket"
                    onChange={handleEditChange}
                    value={editEvent.maxAllowedTicket}
                  />
                  <span className="error">{editErr.maxAllowedTicket}</span>
                </div>
              </div>

              <div class="form-group">
                <div class="cstm-channel-type-title partner-icon-rf-only">
                  Pricing*
                </div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="free"
                      name="priceType"
                      checked={editEvent?.priceType === "free"}
                      onChange={handleEditChange}
                    />
                    Free
                  </label>
                  <label class="subcat-addvideo">
                    {" "}
                    <input
                      type="radio"
                      value="basePrice"
                      name="priceType"
                      checked={
                        editEvent?.priceType === "basePrice" ||
                        editEvent?.priceType === "SPECIAL"
                      }
                      onChange={handleEditChange}
                    />
                    Base Price(Based on main price)
                  </label>
                  <label class="subcat-addvideo">
                    {" "}
                    <input
                      type="radio"
                      value="standAlonePrice"
                      name="priceType"
                      checked={editEvent?.priceType === "standAlonePrice"}
                      onChange={handleEditChange}
                    />
                    Stand alone price
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">
                  {editErr.priceType}
                </span>
              </div>
              {editEvent.priceType === "standAlonePrice" && (
                <div className="form-group">
                  <div className="form-group-label">Price*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter price"
                      name="standAlonePriceInput"
                      onChange={handleEditChange}
                      value={editEvent.standAlonePriceInput}
                    />
                    <span className="error">
                      {editErr.standAlonePriceInput}
                    </span>
                  </div>
                </div>
              )}
              {(editEvent.priceType === "standAlonePrice" ||
                basicPriceCheck) && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="cstm-chk-preregistration-container">
                        <input
                          type="checkbox"
                          name="priceType"
                          id="addSpecialPrice"
                          onChange={handleAddSpecialpriceHandler}
                          checked={specialPriceCheck === true}
                        />
                        <label
                          for="chk-preregistration"
                          htmlFor="addSpecialPrice"
                          onChange={handleAddSpecialpriceHandler}
                        >
                          Do you want to add special price?
                        </label>
                      </div>
                    </div>
                  </div>
                  {specialPriceCheck && (
                    <>
                      <div className="form-group cstm-admin-access-grp">
                        <div className="form-group-label">
                          Add special price
                        </div>
                        <div className="form-group-ct">
                          <select
                            name="specialPriceType"
                            value={editEvent.specialPriceType}
                            onChange={handleEditChange}
                          >
                            <option value="">Select special price</option>
                            <option value="flatAmount">Flat</option>
                            <option value="Discount">Discount</option>
                          </select>
                        </div>
                        <span className="error cstm-error4">
                          {editErr.specialPriceType}
                        </span>
                      </div>
                      {specialPrice === "flatAmount" && (
                        <>
                          <div className="form-group">
                            <div className="form-group-label">Flat($)</div>
                            <div className="form-group-ct">
                              <input
                                type="text"
                                placeholder="Enter flat price"
                                name="flatAmount"
                                onChange={handleEditChange}
                                value={editEvent?.flatAmount}
                              />
                              <span className="error">
                                {editErr.flatAmount}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                      {specialPrice === "Discount" && (
                        <>
                          <div className="form-group">
                            <div className="form-group-label">Discount(%)</div>
                            <div className="form-group-ct">
                              <input
                                type="text"
                                placeholder="Enter discount"
                                name="discount"
                                onChange={handleEditChange}
                                value={editEvent?.discount}
                              />
                              <span className="error">{editErr.discount}</span>
                            </div>
                          </div>
                        </>
                      )}
                      <>
                        <div className="form-group">
                          <div className="form-group-label">Start date*</div>
                          <div className="form-group-ct">
                            <input
                              type="date"
                              name="specialPriceStartDate"
                              onChange={handleEditChange}
                              value={editEvent.specialPriceStartDate}
                            />
                            <span className="error">
                              {editErr.specialPriceStartDate}
                            </span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">Start time*</div>
                          <div className="form-group-ct">
                            <input
                              type="time"
                              name="startTime"
                              onChange={handleEditChange}
                              value={editEvent.startTime}
                              disabled={
                                !editEvent.specialPriceStartDate ||
                                editEvent.specialPriceStartDate.length === 0
                              }
                            />
                            <span className="error">{editErr.startTime}</span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">End date*</div>
                          <div className="form-group-ct">
                            <input
                              type="date"
                              name="specialPriceEndDate"
                              onChange={handleEditChange}
                              value={editEvent.specialPriceEndDate}
                              disabled={
                                !editEvent.specialPriceStartDate ||
                                editEvent.specialPriceStartDate.length === 0
                              }
                              min={editEvent.specialPriceStartDate}
                            />
                            <span className="error">
                              {editErr.specialPriceEndDate}
                            </span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">End time*</div>
                          <div className="form-group-ct">
                            <input
                              type="time"
                              name="endTime"
                              onChange={handleEditChange}
                              value={editEvent.endTime}
                              disabled={
                                !editEvent.specialPriceStartDate ||
                                editEvent.specialPriceStartDate.length === 0
                              }
                            />
                            <span className="error">{editErr.endTime}</span>
                          </div>
                        </div>
                      </>
                    </>
                  )}
                </>
              )}

              <div className="form-group">
                <div className="form-group-label">Application Form</div>
                <div className="form-group-ct">
                  <select
                    value={editEvent.applicationForm}
                    onChange={handleEditChange}
                    name="applicationForm"
                  >
                    <option value={""}>Select application form</option>
                    <option>Example</option>
                  </select>
                </div>
              </div>

              <div className="form-group cstm-admin-access-grp">
                <div className="form-group-label">Addons</div>
                <div className="form-group-ct speakers-only">
                  <Multiselect
                    showArrow={true}
                    placeholder="Select addons"
                    displayValue="name"
                    options={addonsList}
                    selectedValues={editEvent?.selectAddons}
                    onSelect={(selectedlist) => {
                      dispatch({ type: "SETMODALINPROCESS" });
                      dispatch({ type: "SETSTAYONPAGE", payload: null });
                      setEditEvent((prevAddons) => {
                        const updatedAddons = {
                          ...prevAddons,
                          selectAddons: selectedlist,
                        };
                        return updatedAddons;
                      });
                    }}
                    onRemove={(selectedlist) => {
                      dispatch({ type: "SETMODALINPROCESS" });
                      dispatch({ type: "SETSTAYONPAGE", payload: null });
                      setEditEvent((prevAddons) => {
                        const updatedAddons = {
                          ...prevAddons,
                          selectAddons: selectedlist,
                        };
                        return updatedAddons;
                      });
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="cstm-chk-preregistration-container">
                    <input
                      type="checkbox"
                      name="isCancellation"
                      id="isCancellation"
                      onChange={handleEditChange}
                      checked={isChecked === true}
                    />
                    <label
                      for="chk-preregistration"
                      htmlFor="isCancellation"
                      onChange={handleEditChange}
                    >
                      Do you want to allow cancellation?
                    </label>
                  </div>
                </div>
              </div>
              {isChecked && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">
                        Cancellation Deadline
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="cancellationDaysBeforeEvent"
                          placeholder="Allow cancellation within xx days."
                          value={editEvent.cancellationDaysBeforeEvent}
                          onChange={handleEditChange}
                        />
                      </div>
                      <span className="error cstm-pm-list-msg">
                        {editErr.cancellationDaysBeforeEvent}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleEditSubmit}
                >
                  Update Changes
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGuestTicket;
