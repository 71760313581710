import React from 'react'
import PropTypes from "prop-types"
const TagIcon = ({ height = 24, width = 24, iconColor = "#000", isFilled }) => {
    return (
        <>
            {isFilled ? <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M7.349 2h1.922a5.35 5.35 0 0 1 3.782 1.567l6.298 6.298c.657.625 1.312 1.278 1.877 1.988.451.566.772 1.136.772 1.795 0 .66-.322 1.23-.773 1.796-.433.541-1.017 1.126-1.777 1.886l-.099.099c-.147.154-.293.311-.441.47-.493.529-.996 1.07-1.541 1.524l-.272.273c-.527.531-1.057 1.065-1.641 1.531-.565.45-1.136.773-1.796.773-.66 0-1.231-.32-1.797-.772-.71-.566-1.367-1.224-1.998-1.877l-6.298-6.298A5.35 5.35 0 0 1 2 9.27V7.23c0-1.084 0-1.917.078-2.61.08-.718.256-1.35.722-1.816.466-.467 1.097-.643 1.816-.724C5.318 2 6.164 2 7.27 2h.08ZM7.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                    clipRule="evenodd"
                />
            </svg> : <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M7.349 2h1.922a5.35 5.35 0 0 1 3.782 1.567l6.298 6.298c.657.625 1.312 1.278 1.877 1.988.451.566.772 1.136.772 1.795 0 .66-.322 1.23-.773 1.796-.451.565-1.068 1.176-1.876 1.985-.635.667-1.274 1.404-1.982 1.994-.618.618-1.229 1.257-1.913 1.804-.565.45-1.136.773-1.796.773-.66 0-1.231-.32-1.797-.772-.71-.566-1.367-1.224-1.998-1.877l-6.298-6.298A5.35 5.35 0 0 1 2 9.27V7.35c0-1.143-.003-2.011.078-2.73.08-.718.256-1.35.722-1.816.466-.467 1.097-.643 1.816-.724C5.336 1.998 6.204 2 7.35 2Zm1.922 1.528H7.35c-1.144 0-1.991.005-2.561.07-.57.064-.8.176-.907.284-.108.108-.22.338-.284.908-.064.57-.069 1.417-.069 2.56V9.27a3.82 3.82 0 0 0 1.12 2.702c2.1 2.1 4.236 4.172 6.31 6.298.808.808 1.41 1.405 1.86 1.762.447.358.688.439.84.439.154 0 .396-.083.844-.44.448-.358 1.05-.954 1.858-1.761.632-.643 1.27-1.28 1.905-1.92.594-.613 1.232-1.191 1.767-1.86.357-.449.44-.69.44-.842 0-.153-.082-.395-.439-.844-.546-.684-1.215-1.251-1.8-1.898l-6.26-6.26A3.82 3.82 0 0 0 9.27 3.529ZM7.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
                    clipRule="evenodd"
                />
            </svg>}
        </>
    )
}
TagIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool,
}
export default TagIcon
