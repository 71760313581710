import React from "react";
import PropTypes from "prop-types";
// Icons
import TickIcon from "../../../../components/Icons/GeneralIcons/TickIcon";
import Spinners from "../../../../components/Spinners/Spinners";
import ToolTip from "../../../../components/ToolTip/ToolTip";
// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/StopRecording.css";

// const StopRecording = ({ variant }) => {
//   return (
//     <>
//       <div className="stopRecording-sec">
//         <button className="stopRecording-container">
//           {variant === "Enabled" && <TickIcon width={24} height={24} />}
//           <ToolTip title="Tooltip" align="center" />
//           {variant === "Loading" && <Spinners type={"brand"} size={24} />}
//           {variant === "Disabled" && (
//             <TickIcon isFilled width={24} height={24} Disabled />
//           )}
//         </button>
//       </div>
//     </>
//   );
// };

// StopRecording.propTypes = {
//   state: PropTypes.string,
//   variant: PropTypes.oneOf(["Enabled", "Loading", "Disabled"]),
// };

// ==========================

function StopRecording({
  onContextMenu,
  className,
  handleClick,
  isLoading = false,
  disabled = false,
  onMouseEnter,
  onMouseLeave,
}) {
  return (
    <div className="stopRecording-sec">
      <button
      type="button"
        onContextMenu={onContextMenu}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
        className={`stopRecordingButton flex items-center justify-center ${
          className !== undefined ? className : isLoading ? "loading" : ""
        } ${
          className !== undefined ? className : disabled ? "disabled" : ""
        }`}
        onClick={handleClick}
      >
        <TickIcon
          width={24}
          height={24}
          iconColor="var(--color-icon-static-white)"
        />
        <div className="tool-tip">
          <ToolTip title="Stop recording" align="center" />
        </div>
        <div className="spinner">
          <Spinners type={"white"} size={24} />
        </div>
      </button>
    </div>
  );
}

// define types to all props
StopRecording.propTypes = {
  // className: PropTypes.oneOf(['lefticon', 'righticon', 'loading', 'disable']),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onContextMenu: PropTypes.func,
};

export default StopRecording;
