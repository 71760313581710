const chatloader = true;

const chatSidebarLoader = (state = chatloader, action) => {
  switch (action.type) {
    case "SETCHATLOADING":
      return true;
    case "REMOVECHATLOADING":
      return false;
    default:
      return state;
  }
};

export default chatSidebarLoader;
