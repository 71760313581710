import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import loader from "../../../../Images/loader.gif";
import authServicePartner from '../../../../Services/auth.service.partner';
import imgDelete from "../../../../Images/BackendImages/delete.svg";
import editFill from "../../../../Images/BackendImages/edit.svg";
import loader2 from "../../../../Images/loader2.gif";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import AlertComponent from "../../../../Hooks/AlertComponent";
import moment from 'moment';
import MessageModal from '../../../AdminCommonComponent/MessageModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ChromePicker } from 'react-color';
import ColorPicker from 'react-best-gradient-color-picker'
import authService from '../../../../Services/auth.service';

const BadgeManagement = () => {
    const columns = [
        { id: "drag", label: "" },
        { id: "name", label: "Name" },
        { id: "badgeColor", label: "Color" },
        { id: "createdAt", label: "Date created" },
        { id: "partnerCount", label: "Partner count" },
        { id: "_id", label: "Actions" }
    ]
    const [dataLoader, setDataLoader] = useState(false)
    const [rows, setRows] = useState([])
    const [allBadges, setAllBadges] = useState([])
    const [searchText, setSearchText] = useState("");
    const [actualsearch, setActualsearch] = useState("");
    const [badgeName, setBadgeName] = useState('')
    const [badgeError, setBadgeError] = useState('')
    const [createInprocess, setCreateInprocess] = useState(false)
    const [addAlertMsg, setAddAlertMsg] = useState("");
    const [editBadge, setEditBadge] = useState('')
    const [editBadgeColor, setEditBadgeColor] = useState('')
    const [editModalLoader, seteditModalLoader] = useState(false);
    const [badgeId, setBadgeId] = useState('')
    const [deletionMessage, setDeletionMessage] = useState("");
    const [isFailure, setIsFailure] = useState(false);
    const [isColor, setColor] = useState(false)
    const [messageModalDivsArray, setMessageModalDivsArray] = useState([]);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [restPartner, setRestPartner] = useState([])
    const [err, setErr] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    const [badgeColor, setBadgeColor] = useState("#000000")
    const [badgeColorError, setBadgeColorError] = useState('')
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [listIndex, setListIndex] = useState(-1);
    const [selectedBadge, setSelectedBadge] = useState('')
    const [selectedRow, setSelectedRow] = useState({})

    // on first render of component this api call
    useEffect(() => {
        setDataLoader(true)
        handleClearAllStates()
        badgeListApi()
        getallusersSuggestionListApi()
    }, [])

    // call when badge id will update
    useEffect(() => {
        restBadgeListApi()
    }, [badgeId])

    // create function to store data
    function createBadgeData(_id, name, badgeColor, partnerCount, createdAt) {
        return {
            _id, name, badgeColor, partnerCount, createdAt
        }
    }
    useEffect(() => {
        if (suggestionBox && listIndex > -1) {
            var currentList =
                document.getElementById("suggestion-list").children[listIndex]
            // .firstElementChild.firstElementChild;
            if (currentList) {
                var text = currentList.innerText
                setSearchText(text);
            }
        }
    }, [listIndex]);

    // rest badge list api function
    async function restBadgeListApi() {
        try {
            const resListRes = await authServicePartner.restBadge(badgeId)
            if (resListRes.data.status) {
                setRestPartner(resListRes.data.data)
            }
        } catch (error) {
            console.log(error);
            return false
        }
    }
    // badge list api function
    async function badgeListApi() {
        try {
            const badgeListRes = await authServicePartner.badgeList()
            if (badgeListRes.data.status) {
                var arr = badgeListRes.data.data
                if (arr.length > 0) {
                    var newArr = []
                    for (var i = 0; i < arr.length; i++) {
                        newArr[i] = createBadgeData(
                            arr[i]._id,
                            arr[i].name,
                            arr[i].badgeColor,
                            arr[i].countPartnerData,
                            arr[i].createdAt
                        )
                    }
                    setRows(newArr)
                    setAllBadges(newArr)
                }
                setDataLoader(false)
            } else {
                setDataLoader(false)
            }
        } catch (error) {
            setDataLoader(false)
            return false
        }
    }
    async function getallusersSuggestionListApi() {
        try {
            const getSuggestionRes = await authService.getAllPartnerBadgeSuggestionList()

            console.log(getSuggestionRes, "getSuggestionRes");
            let data = getSuggestionRes.data.data
            var arr = []
            if (data && data.length !== undefined && data.length !== null && data.length > 0) {
                data.map((item) => arr.push({ name: item.name }))
                console.log(arr);
                setSuggestionList(arr);
            }
        } catch (err) {

        }
    }
    //sorting function
    const handleSort = (col, direc) => {
        var sortedResult = allBadges;
        if (direc === "asc") {
            sortedResult.sort(function (firstItem, secondItem) {
                var itemA = firstItem[col];
                var itemB = secondItem[col];
                return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
            });
        } else if (direc === "desc") {
            sortedResult.sort(function (firstItem, secondItem) {
                var itemA = firstItem[col];
                var itemB = secondItem[col];
                return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
            });
        }
        var table = [];
        for (var i = 0; i < sortedResult.length; i++) {
            table[i] = createBadgeData(
                sortedResult[i]._id,
                sortedResult[i].name,
                sortedResult[i].badgeColor,
                sortedResult[i].partnerCount,
                sortedResult[i].createdAt,
            )
        }
        setRows(table);
    };

    //search using text
    const handleSearch = (text) => {
        setSearchText(text);
        setSuggestionBox(false);
        if (allBadges !== undefined && allBadges.length > 0) {
            var searchResult = allBadges.filter((badge) => {
                return badge.name.toLowerCase().includes(text.trim().toLowerCase());
            });
            setRows(searchResult);
        }
    };

    // handle create partner badge
    const handleCreatePartnerBadge = async (e) => {
        e.preventDefault()
        setCreateInprocess(true)
        if (badgeName.trim() === "") {
            setBadgeError('Enter type name')
        } else {
            const bodyData = {
                name: badgeName,
                badgeColor: badgeColor,
            }
            try {
                const createBadgeRes = await authServicePartner.createBadge(bodyData)
                if (createBadgeRes.data.status) {
                    setBadgeName('')
                    setCreateInprocess(false)
                    badgeListApi()
                    setAddAlertMsg(createBadgeRes.data.message)
                    setTimeout(() => {
                        setAddAlertMsg('')
                    }, 2000)
                } else {
                    setCreateInprocess(false)
                    setAddAlertMsg(createBadgeRes.data.message)
                    setTimeout(() => {
                        setAddAlertMsg('')
                    }, 2000)
                }
                handleClearAllStates()
            } catch (error) {
                setCreateInprocess(false)
                handleClearAllStates()
                return false
            }
        }
    }

    // get badge api call
    async function getBadgeByIdApi(id) {
        setBadgeId(id)
        seteditModalLoader(true)
        setBadgeError('')
        try {
            const getBadgeByIdRes = await authServicePartner.getBadgeById(id)
            if (getBadgeByIdRes.data.status) {
                seteditModalLoader(false)
                setEditBadge(getBadgeByIdRes.data.data.name)
                setEditBadgeColor(getBadgeByIdRes.data.data.badgeColor)
            } else {
                seteditModalLoader(false)
            }
        } catch (error) {
            seteditModalLoader(false)
            return false
        }
    }

    // handle edit badge
    const handleEditBadge = async (e) => {
        e.preventDefault()
        if (editBadge.trim() === "") {
            setBadgeError('Enter type name')
        } else {
            setCreateInprocess(true)
            const bodyData = {
                name: editBadge,
                badgeColor: editBadgeColor
            }
            try {
                const createBadgeRes = await authServicePartner.editBadge(badgeId, bodyData)
                if (createBadgeRes.data.status) {
                    setSearchText("")
                    setEditBadge('')
                    setCreateInprocess(false)
                    badgeListApi()
                    setAddAlertMsg(createBadgeRes.data.message)
                    setTimeout(() => {
                        setAddAlertMsg('')
                    }, 2000)
                } else {
                    setCreateInprocess(false)
                    setAddAlertMsg(createBadgeRes.data.message)
                    setTimeout(() => {
                        setAddAlertMsg('')
                    }, 2000)
                }
                handleClearAllStates()
            } catch (error) {
                setCreateInprocess(false)
                handleClearAllStates()
                return false
            }
        }
    }

    // handle delete badge
    async function handleDeleteBadge() {
        setCreateInprocess(true)
        const bodyData = {
            "deletePartnerBadgeId": badgeId,
            "reassignPartnerBadgeId": selectedBadge !== "" ? selectedBadge : null
        }

        try {
            const deleteBadgeRes = await authServicePartner.deleteBadge(bodyData)
            if (deleteBadgeRes.data.status) {
                setCreateInprocess(false)
                badgeListApi()
                $("#deleteBadge").modal('hide')
                setAddAlertMsg(deleteBadgeRes.data.message)
                setTimeout(() => {
                    setAddAlertMsg('')
                }, 2000)
            } else {
                $("#deleteBadge").modal('hide')
                setCreateInprocess(false)
                setIsFailure(true)
                let messageStringArray = [];
                if (deleteBadgeRes.data.data.partnerList.length) {
                    let actList = "Partner List : " + deleteBadgeRes.data.data.partnerList.toString().replace(",", ", ");
                    messageStringArray.push(actList)
                }
                setMessageModalDivsArray(messageStringArray)
                setDeletionMessage(deleteBadgeRes.data.message);
            }
        } catch (error) {
            setCreateInprocess(false)
            $("#deleteBadge").modal('hide')
            return false
        }
    }

    //stayonpage  popup for do you want to leave page
    useEffect(() => {
        if (stayOnPage !== null && stayOnPage) {
            if ($('#addBadge').hasClass('show')) {
                $("#addBadge").modal('show')
            }

            if ($('#editBadge').hasClass('show')) {
                $("#editBadge").modal('show')
            }

        }
        else if (stayOnPage !== null && !stayOnPage) {
            if ($('#addBadge').hasClass('show')) { $("#addBadge").modal('hide') }

            if ($('#editBadge').hasClass('show')) { $("#editBadge").modal('hide') }
        }

    }, [stayOnPage])

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }

    //handle drag and reorder rows of partners
    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }
        const updatedRows = Array.from(rows);
        const [movedRow] = updatedRows.splice(result.source.index, 1);
        updatedRows.splice(result.destination.index, 0, movedRow);
        setRows(updatedRows);
        const selctedBadges = updatedRows.map((item) => item._id)
        const ids = {
            ids: selctedBadges
        }
        authServicePartner.badgeReorder(ids)
    }

    function rgbaToHex(rgbaColor, type) {
        // Split the RGBA string to extract the individual color components
        const [r, g, b, a] = rgbaColor
            .substring(5, rgbaColor.length - 1)
            .split(',')
            .map((component) => parseInt(component.trim()));

        // Convert the individual components to hexadecimal values
        const rHex = r.toString(16).padStart(2, '0');
        const gHex = g.toString(16).padStart(2, '0');
        const bHex = b.toString(16).padStart(2, '0');

        // Convert the alpha value to a hexadecimal value
        const aHex = Math.round(a * 255).toString(16).padStart(2, '0');

        // Create the hexadecimal color code
        const hexColor = `#${rHex}${gHex}${bHex}${aHex}`;
        console.log(type, "type");
        type === "add" ? setBadgeColor(hexColor) : setEditBadgeColor(hexColor)
        return hexColor;
    }
    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title">
                        <h1>Types</h1>
                        <div className="uic-fl-btn">
                            <button
                                className="btn"
                                data-toggle="modal"
                                data-target="#addBadge"
                                onClick={() => {
                                    setBadgeError('')
                                    setBadgeName('')
                                    handleClearAllStates()
                                    setColor(false)
                                }}
                            >
                                Add partner type
                            </button>
                        </div>
                    </div>
                    <div className="uic-fl">
                        <div className="uic-fl-search">
                            <input
                                type="text"
                                placeholder="Search"
                                className="search-user-input"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    var currentValue;
                                    if (e.target.value) {
                                        currentValue = e.target.value;
                                    } else {
                                        currentValue = e.target.value;
                                        setSearchText(currentValue);
                                    }
                                    setActualsearch(currentValue);
                                    if (suggestionList !== undefined && suggestionList.length > 0) {
                                        setListIndex(-1);
                                        setSuggestionBox(true)
                                        var searchResult = suggestionList.filter((user) => {
                                            return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                                        });
                                        setSuggestions(searchResult);
                                    }
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch(e.target.value)
                                    } else if (e.key === "ArrowDown") {
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex < listLength - 1) {
                                            setListIndex(listIndex + 1);
                                        } else if (listIndex === listLength - 1) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        }
                                    } else if (e.key === "ArrowUp") {
                                        e.preventDefault();
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex === 0) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        } else if (listIndex === -1) {
                                            setListIndex(listLength - 1);
                                        } else {
                                            setListIndex(listIndex - 1);
                                        }
                                    }
                                }}
                                onBlur={() => setSuggestionBox(false)}
                            />

                            {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                                <div className="suggestion-box">
                                    <ul id="suggestion-list">
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onMouseDown={() => handleSearch(suggestion.name)}
                                                className={index === listIndex ? 'selected-active' : ''}
                                            >
                                                {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length - 1)} </> : suggestion.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <i
                                className="fa fa-search"
                                onClick={() => handleSearch(searchText)}
                            ></i>
                        </div>
                    </div>
                    {addAlertMsg && <div className="alert alert-info">{addAlertMsg}</div>}
                    <Paper className="uic-tb-mn">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <TableContainer className="uic-table-main">
                                <Table className="uic-table min-w-auto">
                                    <TableHead className="uic-table-th">
                                        <TableRow>
                                            {columns.map((col) => (
                                                <TableCell
                                                    key={col.id}
                                                    align={col.align}
                                                    className="uic-th-td"
                                                >
                                                    {/* {col.id !== "_id" && col.id !== "logo" && col.id !== "drag" && col.id !== "badgeColor" ? (
                                                        <>
                                                            <span className="custom-sorting">
                                                                <i
                                                                    className="fa fa-angle-down"
                                                                    onClick={(e) => {
                                                                        handleSort(col.id, "Asc");
                                                                    }}
                                                                ></i>
                                                                <i
                                                                    className="fa fa-angle-up"
                                                                    onClick={(e) => {
                                                                        handleSort(col.id, "Desc");
                                                                    }}
                                                                ></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                    {col.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    {dataLoader ?
                                        <div className="cstm-data-load"><img src={loader} /> </div> :
                                        rows.length > 0 ?
                                            <Droppable droppableId="table">
                                                {(provided) => (
                                                    <TableBody ref={provided.innerRef} {...provided.droppableProps} className="uic-table-td">
                                                        {rows.map((row, index) => {
                                                            return (<Draggable key={row._id} draggableId={row._id} index={index}>
                                                                {(provided) => (
                                                                    <TableRow
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className="uic-th-td w-20"
                                                                    >
                                                                        {columns.map((column) => {
                                                                            const value = row[column.id]
                                                                            return column.id === "name" ? <TableCell className="uic-th-td w-55" ><a data-toggle="modal"
                                                                                data-target="#editBadge"
                                                                                className="cstm-view-users-count"
                                                                                onClick={() => {
                                                                                    getBadgeByIdApi(row._id)
                                                                                    setColor(false)
                                                                                }}>{value}</a></TableCell> : column.id === "badgeColor" ? <TableCell className="uic-th-td w-55" ><div className='cstm-admin-table-badge-color' style={{ backgroundColor: (value !== undefined && value !== null ? value : "#000000") }}></div> </TableCell> :
                                                                                column.id === "partnerCount" ? <TableCell className="uic-th-td w-12"><a className="cstm-view-users-count" onClick={() => { navigate(`/partner/list?search=&badge=${row["_id"]}`) }}>{value}</a></TableCell> :
                                                                                    column.id === "createdAt" ? <TableCell className="uic-th-td w-12">{moment(value).format('MMM DD, YYYY')}</TableCell> : column.id === 'drag' ? <TableCell className="uic-th-td w-4">
                                                                                        <i class="fas fa-arrows-alt banner-drag-drop"></i>
                                                                                    </TableCell> :
                                                                                        <TableCell className="uic-th-td uic-table-icon w-8">
                                                                                            <a
                                                                                                data-toggle="modal"
                                                                                                data-target="#editBadge"
                                                                                                onClick={() => {
                                                                                                    getBadgeByIdApi(value)
                                                                                                    setColor(false)
                                                                                                }}
                                                                                            >
                                                                                                <Tooltip title='Edit' arrow><img src={editFill} /></Tooltip>
                                                                                            </a>
                                                                                            {window.location.hostname === "test.milliondollarsellers.com" && value !== "64f83db1acacc396564d4562" ? <a
                                                                                                data-toggle="modal"
                                                                                                data-target="#deleteBadge"
                                                                                                onClick={() => { setBadgeId(value); setConfirmDeleteModal(false); setErr(''); setSelectedRow(row); setSelectedBadge("") }}>
                                                                                                <Tooltip title='Delete' arrow><img src={imgDelete} /></Tooltip>
                                                                                            </a> : window.location.hostname === "dev.milliondollarsellers.com" && value !== "65083abc4e4b52568e71e55a" ? <a
                                                                                                data-toggle="modal"
                                                                                                data-target="#deleteBadge"
                                                                                                onClick={() => { setBadgeId(value); setConfirmDeleteModal(false); setErr(''); setSelectedRow(row); setSelectedBadge("") }}>
                                                                                                <Tooltip title='Delete' arrow><img src={imgDelete} /></Tooltip>
                                                                                            </a> : window.location.hostname === "app.milliondollarsellers.com" && value !== "6513f058feeb91913e2d303d" && <a
                                                                                                data-toggle="modal"
                                                                                                data-target="#deleteBadge"
                                                                                                onClick={() => { setBadgeId(value); setConfirmDeleteModal(false); setErr(''); setSelectedRow(row); setSelectedBadge("") }}>
                                                                                                <Tooltip title='Delete' arrow><img src={imgDelete} /></Tooltip>
                                                                                            </a>}
                                                                                        </TableCell>
                                                                        })}
                                                                    </TableRow>
                                                                )}
                                                            </Draggable>)
                                                        })}
                                                    </TableBody>
                                                )}
                                            </Droppable> :
                                            <span className="no-data-found">No data found</span>
                                    }
                                </Table>
                            </TableContainer>
                        </DragDropContext>
                    </Paper>
                </div>
            </div>
            {/* add badge modal */}
            <div
                className="modal fade edit-user-details-popup"
                id="addBadge"
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Add Partner Type</h2>
                            <button
                                type="button"
                                data-keyboard="false"
                                className="close"
                                //data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {
                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                    } else {
                                        $("#addBadge").modal('hide')
                                    }
                                }}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {addAlertMsg && <div className="alert alert-info">{addAlertMsg}</div>}
                            <div className="form-group">
                                <div className="form-group-label">Type*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="Enter type name"
                                        name="title"
                                        value={badgeName}
                                        onChange={(e) => {
                                            setBadgeName(e.target.value)
                                            dispatch({ type: "SETMODALINPROCESS" })
                                            dispatch({ type: "SETSTAYONPAGE", payload: null })
                                            e.target.value.trim() === '' ? setBadgeError('Enter type name') : setBadgeError('')
                                        }}
                                    />
                                    <span className="error">{badgeError}</span>
                                </div>

                            </div>
                            <div className="form-group">
                                <div className="form-group-label">Color</div>
                                <div className="form-group-ct">
                                    <div className='cstm-badgecolor-container'>
                                        <input type="color" name="badgeColor" className='cstm-admin-badge-color' value={badgeColor} onChange={(e) => {
                                            setBadgeColor(e.target.value)
                                            dispatch({ type: "SETMODALINPROCESS" })
                                            dispatch({ type: "SETSTAYONPAGE", payload: null })
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {createInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleCreatePartnerBadge}
                                    disabled={createInprocess}
                                >
                                    Create
                                </button>)}
                        </div>
                    </div>
                </div>
            </div>
            {/* edit badge modal */}
            <div
                className="modal fade edit-user-details-popup"
                id="editBadge"
                tabIndex="-1"
                role="dialog"
                data-backdrop="static"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    {editModalLoader ?
                        <div className="edit-modal-loader">
                            <img src={loader} />
                        </div> : (
                            <div className="modal-content">
                                <div className="edit-user-details-header">
                                    <h2>Edit Partner Type</h2>
                                    <button
                                        type="button"
                                        className="close"
                                        //data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={(e) => {
                                            if (modalInProcess) {
                                                dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                            } else {
                                                $("#editBadge").modal('hide')
                                            }
                                        }}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {addAlertMsg && <div className="alert alert-info">{addAlertMsg}</div>}
                                    <div className="form-group">
                                        <div className="form-group-label">Type*</div>
                                        <div className="form-group-ct">
                                            <input
                                                type="text"
                                                placeholder="Enter type name"
                                                name="title"
                                                value={editBadge}
                                                onChange={(e) => {
                                                    setEditBadge(e.target.value)
                                                    dispatch({ type: "SETMODALINPROCESS" })
                                                    dispatch({ type: "SETSTAYONPAGE", payload: null })
                                                    e.target.value.trim() === '' ? setBadgeError('Enter type name') : setBadgeError('')
                                                }}
                                            />
                                            <span className="error">{badgeError}</span>
                                        </div>
                                    </div>
                                    {window.location.hostname === "test.milliondollarsellers.com" && badgeId !== "64f83db1acacc396564d4562" ?
                                        <div className="form-group">
                                            <div className="form-group-label">Color</div>
                                            <div className="form-group-ct">
                                                <div className='cstm-badgecolor-container'>
                                                    <input type="color" name="badgeColor" className='cstm-admin-badge-color' value={editBadgeColor} onChange={(e) => {
                                                        dispatch({ type: "SETMODALINPROCESS" })
                                                        dispatch({ type: "SETSTAYONPAGE", payload: null })
                                                        setEditBadgeColor(e.target.value)
                                                    }} />
                                                </div>
                                            </div>
                                        </div> : window.location.hostname !== "dev.milliondollarsellers.com" && badgeId !== "65083abc4e4b52568e71e55a" ?
                                            <div className="form-group">
                                                <div className="form-group-label">Color</div>
                                                <div className="form-group-ct">
                                                    <div className='cstm-badgecolor-container'>
                                                        <input type="color" name="badgeColor" className='cstm-admin-badge-color' value={editBadgeColor} onChange={(e) => {
                                                            dispatch({ type: "SETMODALINPROCESS" })
                                                            dispatch({ type: "SETSTAYONPAGE", payload: null })
                                                            setEditBadgeColor(e.target.value)
                                                        }} />
                                                    </div>
                                                </div>
                                            </div>
                                            : window.location.hostname === "app.milliondollarsellers.com" && badgeId !== "6513f058feeb91913e2d303d" ?
                                                <div className="form-group">
                                                    <div className="form-group-label">Color</div>
                                                    <div className="form-group-ct">
                                                        <div className='cstm-badgecolor-container'>
                                                            <input type="color" name="badgeColor" className='cstm-admin-badge-color' value={editBadgeColor} onChange={(e) => {
                                                                dispatch({ type: "SETMODALINPROCESS" })
                                                                dispatch({ type: "SETSTAYONPAGE", payload: null })
                                                                setEditBadgeColor(e.target.value)
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                </div>
                                <div className="edit-user-details-footer cstm-create-button">
                                    {createInprocess ? (
                                        <div className="loader-button1">
                                            <button>In Process...</button>
                                            <span className="loader-btn">
                                                <img src={loader2} />
                                            </span>
                                        </div>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={handleEditBadge}
                                            disabled={createInprocess}
                                        >
                                            Update
                                        </button>)}
                                </div>
                            </div>)}
                </div>
            </div>
            {/* Modal Delete */}
            <div
                className="modal fade cstm-delete-popup"
                id="deleteBadge"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        {selectedRow.partnerCount !== undefined && selectedRow.partnerCount > 0 ?
                            <div className="reassigncontent">

                                <div className='cstm-delete-badge'>
                                    <div className='cstm-delete-badge-top'>
                                        <h3 className='popup-title-h3'>Delete partner badge?</h3>
                                        <p className='popup-cont-p'>{selectedRow.partnerCount} {selectedRow.partnerCount > 1 ? "partners are" : "partner is"} currently assigned to <b>{selectedRow.name}</b> badge. Pick a new badge to reassign.</p>
                                    </div>
                                    <div className="select-a-new">
                                        <h5 className="select-a-new-h5">Select a new badge for these partners*</h5>
                                        <select
                                            className='select-popup-field'
                                            name="selevent"
                                            onChange={(e) => {
                                                setSelectedBadge(e.target.value)
                                                e.target.value !== "" ? setErr('') : setErr('Select type')
                                            }}
                                        >
                                            <option value=''>Select badge</option>
                                            {restPartner.length > 0 && restPartner.map(o => o.name !== "nobadge" && <option value={o._id}>{o.name}</option>)}
                                        </select>
                                        <span className="error">{err}</span>
                                    </div>
                                </div>

                                <div className="cstm-popup-footer">

                                    <button type="button" className="btn-cancel-popup" onClick={() => { setConfirmDeleteModal(false); }} data-dismiss="modal">
                                        Cancel
                                    </button>
                                    {createInprocess ? (
                                        <div className="loader-button1">
                                            <button className='btn-blue-popup'>In Process...</button>
                                            <span className="loader-btn">
                                                <img src={loader2} />
                                            </span>
                                        </div>
                                    ) : (
                                        <button
                                            disabled={selectedBadge.trim() === ""}
                                            type="button"
                                            className="btn-blue-popup"
                                            onClick={handleDeleteBadge}
                                        >Reassign and delete
                                        </button>)}

                                </div>
                            </div>
                            : <>
                                <div className='cstm-delete-badge'>
                                    <div className='cstm-delete-badge-top'>
                                        <h3 className='popup-title-h3'>Delete partner badge?</h3>
                                        <p className='popup-cont-p'>Are you sure you want to delete the <b>{selectedRow.name}</b> badge?</p>
                                    </div>
                                </div>
                                <div className="cstm-popup-footer">
                                    <button type="button" className="btn-cancel-popup" data-dismiss="modal">
                                        Cancel
                                    </button>
                                    {createInprocess ? (
                                        <div className="loader-button1">
                                            <button className='btn-blue-popup'>In Process...</button>
                                            <span className="loader-btn">
                                                <img src={loader2} />
                                            </span>
                                        </div>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn-delete-popup"
                                            onClick={handleDeleteBadge}
                                        >
                                            Delete
                                        </button>)}
                                </div></>}
                    </div>
                </div>
            </div>
            <MessageModal deletionMessage={deletionMessage} setDeletionMessage={setDeletionMessage} failure={isFailure} additionalDivArray={messageModalDivsArray} />
            <AlertComponent />
        </>
    )
}

export default BadgeManagement
