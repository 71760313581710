import React from "react";
import "./Checkbox.css";
import "../../Styles/Theme/Theme.css";
import PropTypes from "prop-types";
import checkMark from "../../Images/check-smalls.svg";

const Checkbox = ({
  value,
  name,
  id = "CheckboxId",
  indeterminate = false,
  label = "label",
  hasLabel = false,
  disabled,
  required,
  checked,
  defaultChecked,
  onChange,
}) => {
  // console.log(checked, defaultChecked ,"checked")
  return (
    <>
      <div className={`checkbox-field ${indeterminate}`}>
        <input
          type="checkbox"
          value={value}
          name={name}
          onChange={onChange}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          required={required}
          className={`${checked ? "Checked" : ""}`}
        />
        <label htmlFor={id}>
          <span className={`relative ${indeterminate ? "indeterminate" : ""}`}>
            <svg
              xmlns="https://www.w3.org/2000/svg"
              width={12}
              height={12}
              fill="none"
              className="checkedIcon checkmark-icon"
            >
              <path
                fill="#fff"
                d="M4.389 9.314a.519.519 0 0 0 .722 0l5.743-5.742a.519.519 0 0 0 0-.722l-.704-.704a.495.495 0 0 0-.703 0L4.76 6.834 2.553 4.646a.495.495 0 0 0-.703 0l-.704.704a.519.519 0 0 0 0 .722L4.39 9.314Z"
              />
            </svg>

            <svg
              xmlns="https://www.w3.org/2000/svg"
              width={12}
              height={12}
              fill="none"
              className="checkedIcon indeterminate-icon"
            >
              <rect width={10} height={2} x={1} y={5} fill="#fff" rx={0.5} />
            </svg>
          </span>
          {hasLabel && <p className="checkbox-label">{label}</p>}
        </label>
      </div>
    </>
  );
};

Checkbox.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  id: PropTypes.string,
  indeterminate: PropTypes.bool,
  label: PropTypes.string,
  hasLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
