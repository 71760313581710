import React, { useEffect, useRef, useState } from "react";
import { emptyObject } from "./CommonFunction";
import { useSelector, useDispatch } from "react-redux";
import { useCreateGuestTicketMutation } from "../../../Services/guestTicket";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import Loader2 from "../../../Images/loader2.gif";
import Multiselect from "multiselect-react-dropdown";
import { useLocation } from "react-router-dom";
import AlertComponent from "../../../Hooks/AlertComponent";

const CreateGuestTicket = ({ isCloseAddModal, addonList, forceRender }) => {
  const [addEvent, setAddEvent] = useState(emptyObject);
  const [addErr, setAddErr] = useState(emptyObject);
  const [isChecked, setIsChecked] = useState(false);
  const [basicPriceCheck, setBasicPriceCheck] = useState(false);
  const [specialPriceCheck, setSpecialPriceCheck] = useState(false);
  const [specialPrice, setSpecialPrice] = useState("");
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [createInprocess, setCreateInprocess] = useState(false);
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [showAlert, setShowAlert] = useState("");

  //event id
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("id");

  const descriptionRef = useRef(null);

  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  //add guest ticket api hook
  const [createGuestTicket, { isError, isSuccess }] =
    useCreateGuestTicketMutation();

  //close create session module
  const handleCloseAddModel = (e) => {
    if (descriptionRef.current) {
      descriptionRef.current.setContent("");
    }
    setAddEvent(emptyObject);
    setAddErr(emptyObject);
    setBasicPriceCheck(false);
    setSpecialPriceCheck(false);
    setIsChecked(false);
    setSpecialPrice("");
    setSelectedAddons([]);
    handleClearAllStates();
    setCreateInprocess(false);
    setShowAlert(false);
    setShowAlertAdd(false);
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //converting state to initial state
  useEffect(() => {
    if (isCloseAddModal) handleCloseAddModel();
  }, [isCloseAddModal]);

  //clear add state when click on edit main ticket
  useEffect(() => {
    handleCloseAddModel();
  }, [forceRender]);

  //handle onchange for create guest ticket
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "maxAllowedTicket") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value)) {
        setAddErr({
          ...addErr,
          maxAllowedTicket: "Please enter a valid number for maximum order",
        });
      }
      if (e.target.value.includes(".")) {
        setAddErr({
          ...addErr,
          maxAllowedTicket: "Max ticket should not be a decimal number",
        });
      }
    } else if (e.target.name === "discount") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }

      if (
        isNaN(e.target.value) ||
        e.target.value < 0 ||
        e.target.value >= 100
      ) {
        setAddErr({
          ...addErr,
          discount: "Discount should be a valid number between 1 and 100",
        });
      }
    } else if (e.target.name === "flatAmount") {
      if (e.target.value.trim().length > 0) {
        setSpecialPrice("flatAmount");
        setAddErr({ ...addErr, [e.target.name]: "" });
      }

      if (isNaN(e.target.value)) {
        setAddErr({
          ...addErr,
          flatAmount: "Flat Price should be a valid number greater than 0",
        });
      }
    } else if (e.target.name === "cancellationDaysBeforeEvent") {
      if (isNaN(e.target.value)) {
        setAddErr({
          ...addErr,
          cancellationDaysBeforeEvent: "Enter a valid number",
        });
      }
    } else if (e.target.name === "standAlonePriceInput") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }

      if (isNaN(e.target.value)) {
        setAddErr({ ...addErr, standAlonePriceInput: "Enter valid price" });
      }
    }

    if (e.target.value === "basePrice") {
      setSpecialPriceCheck(false);
      setBasicPriceCheck(true);
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          standAlonePriceInput: "",
          specialPriceStartDate: "",
          specialPriceEndDate: "",
          startTime: "",
          endTime: "",
          specialPrice: "",
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setSpecialPrice(false);
      setAddErr({ ...addErr, [e.target.name]: "" });
    } else if (e.target.value === "free") {
      setAddErr({ ...addErr, [e.target.name]: "" });
      setSpecialPriceCheck(false);
      setBasicPriceCheck(false);
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          standAlonePriceInput: "",
          specialPriceStartDate: "",
          specialPriceEndDate: "",
        };
        return updatedAddEvent;
      });
      setSpecialPrice(false);
    } else if (e.target.value === "standAlonePrice") {
      setSpecialPriceCheck(false);
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          standAlonePriceInput: "",
          specialPriceStartDate: "",
          specialPriceEndDate: "",
          startTime: "",
          endTime: "",
          specialPrice: "",
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setSpecialPrice(false);
    }

    if (e.target.value === "flatAmount") {
      setSpecialPrice("flatAmount");
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          discount: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, [e.target.name]: "" });
    } else if (e.target.value === "Discount") {
      setSpecialPrice("Discount");
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          flatAmount: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, [e.target.name]: "" });
    }

    if (e.target.name === "isCancellation") {
      setIsChecked(e.target.checked);
    }

    if (!isChecked) {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          cancellationDaysBeforeEvent: "",
        };
        return updatedAddEvent;
      });
    }
    if (
      !specialPriceCheck ||
      (e.target.name === "specialPrice" && e.target.value === "")
    ) {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          specialPrice: "",
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setSpecialPrice((p) => "");
    }

    if (addEvent.priceType !== "") {
      setAddErr({ ...addErr, [e.target.name]: "" });
    }
  };

  //validation and api call on create guest tickets
  const handleCreate = () => {
    let status = true;
    let temp = {};
    if (addEvent?.name?.trim().length === 0) {
      temp = { ...temp, name: "Enter name" };
      status = false;
    }

    if (
      isNaN(addEvent?.maxAllowedTicket) ||
      addEvent?.maxAllowedTicket.trim() === ""
    ) {
      temp = {
        ...temp,
        maxAllowedTicket: "Please enter a valid number for maximum order",
      };
      status = false;
    }

    if (addEvent.maxAllowedTicket.includes(".")) {
      temp = {
        ...temp,
        maxAllowedTicket: "Max ticket should not be a decimal number",
      };
      status = false;
    }

    if (addEvent?.priceType === "") {
      temp = {
        ...temp,
        priceType: "Please choose a price type",
      };
      status = false;
    }

    if (
      (addEvent?.priceType === "basePrice" ||
        addEvent?.priceType === "standAlonePrice") &&
      specialPriceCheck
    ) {
      if (specialPriceCheck && addEvent?.specialPrice === "") {
        temp = {
          ...temp,
          specialPrice: "Please choose either Discount or Flat Price",
        };
        status = false;
      } else {
        if (addEvent?.specialPrice === "Discount") {
          const discountValue = parseFloat(addEvent?.discount);
          if (
            isNaN(discountValue) ||
            discountValue <= 0 ||
            discountValue >= 100
          ) {
            temp = {
              ...temp,
              discount: "Discount should be a valid number between 1 and 100",
            };
            status = false;
          }
        } else if (addEvent?.specialPrice === "flatAmount") {
          const flatAmountValue = parseFloat(addEvent?.flatAmount);
          if (isNaN(flatAmountValue) || flatAmountValue <= 0) {
            temp = {
              ...temp,
              flatAmount: "Flat Price should be a valid number greater than 0",
            };
            status = false;
          }
        }
      }
    }

    if (
      addEvent?.priceType === "standAlonePrice" &&
      (addEvent?.standAlonePriceInput === "" ||
        isNaN(addEvent?.standAlonePriceInput))
    ) {
      temp = {
        ...temp,
        standAlonePriceInput: "Price should greater than 0",
      };
      status = false;
    }
    if (isChecked && !addEvent?.cancellationDaysBeforeEvent) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent:
          "Please either uncheck the checkbox or provide a valid input value",
      };
      status = false;
    } else if (isNaN(addEvent?.cancellationDaysBeforeEvent)) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent: "Enter a valid number",
      };
      status = false;
    }

    if (addEvent?.specialPriceStartDate === "" && specialPriceCheck) {
      temp = {
        ...temp,
        specialPriceStartDate: "Select start date",
      };
      status = false;
    }
    if (addEvent?.specialPriceEndDate === "" && specialPriceCheck) {
      temp = {
        ...temp,
        specialPriceEndDate: "Select end date",
      };
      status = false;
    }

    if (addEvent?.startTime === "" && specialPriceCheck) {
      temp = {
        ...temp,
        startTime: "Select start time",
      };
      status = false;
    }

    if (addEvent?.endTime === "" && specialPriceCheck) {
      temp = {
        ...temp,
        endTime: "Select end time",
      };
      status = false;
    }

    if (status) {
      createApiCall();
      setAddEvent(emptyObject);
      if (descriptionRef.current && descriptionRef.current.getContent) {
        descriptionRef.current.setContent("");
      }
      setBasicPriceCheck(false);
      setSpecialPriceCheck(false);
      setIsChecked(false);
    } else {
      setAddErr(temp);
    }
  };

  //Api calll on create guest ticket
  const createApiCall = async () => {
    let priceType;
    if (addEvent?.priceType === "free") {
      priceType = "FREE";
    } else if (addEvent?.priceType === "basePrice") {
      priceType = "BASE";
    } else if (addEvent.priceType === "standAlonePrice") {
      priceType = "INDIVIDUAL";
    }

    let specialPriceType;
    if (addEvent?.specialPrice === "flatAmount") {
      specialPriceType = "FLAT";
    } else if (addEvent?.specialPrice === "Discount") {
      specialPriceType = "PERCENTAGE";
    }

    let specialPriceForGuest;
    if (addEvent?.flatAmount) {
      specialPriceForGuest = addEvent?.flatAmount;
    } else if (addEvent?.discount) {
      specialPriceForGuest = addEvent?.discount;
    }

    //convert string date to formatted date
    const dateConvertor = (date, time) => {
      if (!date) return;
      const combinedDateTimeString = date + "T" + time + ":00";
      const combinedDateTime = new Date(combinedDateTimeString);
      const isoFormattedDateTime = combinedDateTime.toISOString();
      return isoFormattedDateTime;
    };
    setCreateInprocess(true);

    const formData = {
      name: addEvent?.name,
      description: descriptionRef.current.getContent(),
      quantity: 500,
      priceType: priceType,
      isSpecialPrice: specialPriceCheck,
      price: Number(addEvent?.standAlonePriceInput),
      specialPriceType: specialPriceType,
      specialPriceForGuest: Number(specialPriceForGuest),
      isCancellation: isChecked,
      cancellationDaysBeforeEvent: Number(
        addEvent?.cancellationDaysBeforeEvent
      ),
      maxAllowedTicket: Number(addEvent?.maxAllowedTicket),
      isAddOnAvailable: selectedAddons.length > 0 ? true : false,
      selectAddons: selectedAddons,
      isGuestRegistration: "false",
      salesStartDate: dateConvertor(
        addEvent.specialPriceStartDate,
        addEvent.startTime
      ),
      salesEndDate: dateConvertor(
        addEvent.specialPriceEndDate,
        addEvent.endTime
      ),
      eventId: eventId,
    };

    try {
      const createGuestTicketRes = await createGuestTicket(formData);
      if (createGuestTicketRes?.data?.status) {
        setAddEvent(emptyObject);
        setCreateInprocess(false);
        setTimeout(() => {
          $("#CreateGuestTickets").modal("hide");
          handleCloseAddModel();
        }, 1500);
      }

      if (createGuestTicketRes?.error?.data?.status === false) {
        setShowAlertAdd(true);
        setAlertMsgAdd("This ticket name already exists");
        setTimeout(() => {
          setShowAlertAdd(false);
          setAlertMsgAdd("");
        }, 4000);
        setCreateInprocess(false);
      }
    } catch (err) {
      console.log("Guest ticket add Error:", err);
    }
    setAddErr(emptyObject);
  };

  //handle special price
  const handleAddSpecialpriceHandler = (e) => {
    setSpecialPriceCheck(e.target.checked);
    if (!e.target.checked) {
      setSpecialPrice("");
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          specialPrice: "",
          flatAmount: "",
          discount: "",
          specialPriceStartDate: "",
          specialPriceEndDate: "",
        };
        return updatedAddEvent;
      });
    }
  };

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#CreateGuestTickets").hasClass("show")) {
        $("#CreateGuestTickets").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#CreateGuestTickets").hasClass("show")) {
        $("#CreateGuestTickets").modal("hide");
      }
    }
  }, [stayOnPage]);

  return (
    <>
      {" "}
      <div
        className="modal fade edit-user-details-popup"
        id="CreateGuestTickets"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Guest Ticket</h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#CreateGuestTickets").modal("hide");
                    handleCloseAddModel();
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Guest ticket name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleAddChange}
                    value={addEvent.name}
                  />
                  <span className="error">{addErr.name}</span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Description</div>
                  <div className="cstm-editor-tool-ds">
                    <TinyEditor
                      valueRef={descriptionRef}
                      value={addEvent.description}
                      handleEditorChange={(value, editor) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setAddErr({
                          ...addErr,
                          description: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{addErr.description}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Max order per ticket*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter maximum order per ticket"
                    name="maxAllowedTicket"
                    onChange={handleAddChange}
                    value={addEvent.maxAllowedTicket}
                  />
                  <span className="error">{addErr.maxAllowedTicket}</span>
                </div>
              </div>

              <div class="form-group">
                <div class="cstm-channel-type-title partner-icon-rf-only">
                  Pricing*
                </div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="free"
                      name="priceType"
                      checked={addEvent?.priceType === "free"}
                      onChange={handleAddChange}
                    />
                    Free
                  </label>
                  <label class="subcat-addvideo">
                    {" "}
                    <input
                      type="radio"
                      value="basePrice"
                      name="priceType"
                      checked={addEvent?.priceType === "basePrice"}
                      onChange={handleAddChange}
                    />
                    Base price(Based on main price)
                  </label>
                  <label class="subcat-addvideo">
                    {" "}
                    <input
                      type="radio"
                      value="standAlonePrice"
                      name="priceType"
                      checked={addEvent?.priceType === "standAlonePrice"}
                      onChange={handleAddChange}
                    />
                    Stand alone price
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">
                  {addErr.priceType}
                </span>
              </div>
              {addEvent.priceType === "standAlonePrice" && (
                <div className="form-group">
                  <div className="form-group-label">Price*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter price"
                      name="standAlonePriceInput"
                      onChange={handleAddChange}
                      value={addEvent.standAlonePriceInput}
                    />
                    <span className="error">{addErr.standAlonePriceInput}</span>
                  </div>
                </div>
              )}
              {(addEvent.priceType === "standAlonePrice" ||
                basicPriceCheck) && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="cstm-chk-preregistration-container">
                        <input
                          type="checkbox"
                          name="priceType"
                          id="priceType"
                          onChange={handleAddSpecialpriceHandler}
                          checked={specialPriceCheck === true}
                        />
                        <label
                          for="chk-preregistration"
                          htmlFor="priceType"
                          onChange={handleAddSpecialpriceHandler}
                        >
                          Do you want to add special price?
                        </label>
                      </div>
                    </div>
                  </div>
                  {specialPriceCheck && (
                    <>
                      <div className="form-group cstm-admin-access-grp">
                        <div className="form-group-label">
                          Add special price
                        </div>
                        <div className="form-group-ct">
                          <select
                            name="specialPrice"
                            value={addEvent.specialPrice}
                            onChange={handleAddChange}
                          >
                            <option value="">Select special price</option>
                            <option value="flatAmount">Flat</option>
                            <option value="Discount">Discount</option>
                          </select>
                        </div>
                        <span className="error cstm-error4">
                          {addErr.specialPrice}
                        </span>
                      </div>
                      {specialPrice === "flatAmount" && (
                        <>
                          <div className="form-group">
                            <div className="form-group-label">Flat($)</div>
                            <div className="form-group-ct">
                              <input
                                type="text"
                                placeholder="Enter flat price"
                                name="flatAmount"
                                onChange={handleAddChange}
                                value={addEvent?.flatAmount}
                              />
                              <span className="error">{addErr.flatAmount}</span>
                            </div>
                          </div>
                        </>
                      )}
                      {specialPrice === "Discount" && (
                        <>
                          <div className="form-group">
                            <div className="form-group-label">Discount(%)</div>
                            <div className="form-group-ct">
                              <input
                                type="text"
                                placeholder="Enter discount"
                                name="discount"
                                onChange={handleAddChange}
                                value={addEvent?.discount}
                              />
                              <span className="error">{addErr.discount}</span>
                            </div>
                          </div>
                        </>
                      )}
                      <>
                        <div className="form-group">
                          <div className="form-group-label">Start date*</div>
                          <div className="form-group-ct">
                            <input
                              type="date"
                              name="specialPriceStartDate"
                              onChange={handleAddChange}
                              value={addEvent.specialPriceStartDate}
                            />
                            <span className="error">
                              {addErr.specialPriceStartDate}
                            </span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">Start time*</div>
                          <div className="form-group-ct">
                            <input
                              type="time"
                              name="startTime"
                              onChange={handleAddChange}
                              value={addEvent.startTime}
                              disabled={
                                addEvent.specialPriceStartDate.length === 0
                                  ? true
                                  : false
                              }
                            />
                            <span className="error">{addErr.startTime}</span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">End date*</div>
                          <div className="form-group-ct">
                            <input
                              type="date"
                              name="specialPriceEndDate"
                              onChange={handleAddChange}
                              value={addEvent.specialPriceEndDate}
                              disabled={
                                !addEvent.specialPriceStartDate ||
                                addEvent.specialPriceStartDate.length === 0
                              }
                              min={addEvent.specialPriceStartDate}
                            />
                            <span className="error">
                              {addErr.specialPriceEndDate}
                            </span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">End time*</div>
                          <div className="form-group-ct">
                            <input
                              type="time"
                              name="endTime"
                              onChange={handleAddChange}
                              value={addEvent.endTime}
                              disabled={
                                addEvent.specialPriceStartDate.length === 0
                                  ? true
                                  : false
                              }
                            />
                            <span className="error">{addErr.endTime}</span>
                          </div>
                        </div>
                      </>
                    </>
                  )}
                </>
              )}

              <div className="form-group">
                <div className="form-group-label">Application form</div>
                <div className="form-group-ct">
                  <select
                    value={addEvent.applicationForm}
                    onChange={handleAddChange}
                    name="applicationForm"
                  >
                    <option value={""}>Select application form</option>
                    <option>Example</option>
                  </select>
                </div>
              </div>

              <div className="form-group cstm-admin-access-grp">
                <div className="form-group-label">Addons</div>
                <div className="form-group-ct speakers-only">
                  <Multiselect
                    showArrow={true}
                    placeholder="Select addons"
                    displayValue="name"
                    options={addonList}
                    selectedValues={selectedAddons}
                    onSelect={(selectedlist) => {
                      dispatch({ type: "SETMODALINPROCESS" });
                      dispatch({ type: "SETSTAYONPAGE", payload: null });
                      setSelectedAddons(selectedlist);
                    }}
                    onRemove={(selectedlist) => {
                      dispatch({ type: "SETMODALINPROCESS" });
                      dispatch({ type: "SETSTAYONPAGE", payload: null });
                      setSelectedAddons(selectedlist);
                    }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="cstm-chk-preregistration-container">
                    <input
                      type="checkbox"
                      name="isCancellation"
                      id="isCancellation"
                      onChange={handleAddChange}
                      checked={isChecked === true}
                    />
                    <label
                      for="chk-preregistration"
                      htmlFor="isCancellation"
                      onChange={handleAddChange}
                    >
                      Do you want to allow cancellation?
                    </label>
                  </div>
                </div>
              </div>
              {isChecked && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">
                        Cancellation deadline
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="cancellationDaysBeforeEvent"
                          placeholder="Allow cancellation within xx days."
                          value={addEvent.cancellationDaysBeforeEvent}
                          onChange={handleAddChange}
                        />
                        <span className="error cstm-pm-list-msg">
                          {addErr.cancellationDaysBeforeEvent}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <AlertComponent />
    </>
  );
};

export default CreateGuestTicket;
