import axios from "axios";
import authHeader from "../Services/auth.header";
import authadminheader from "./auth.admin.header";
const API_URL = process.env.REACT_APP_API_URL;
const CHAT_API_URL = process.env.REACT_APP_CHAT_BASE_API_URL;

const createEvent = (data) => {
  return axios.post(API_URL + "event/addEvent", data, {
    headers: authadminheader(),
  });
};
const getAllEvents = (search) => {
  return axios.get(API_URL + "event/getAllEvents" + search, {
    headers: authadminheader(),
  });
};
const updateEventStatus = (id) => {
  return axios.patch(
    API_URL + "event/updateStatusEvent/" + id,
    {},
    { headers: authadminheader() }
  );
};
const getAllEventsLimitedFiedls = () => {
  return axios.get(API_URL + "event/getAllEventsLimitedFiedls", {
    headers: authadminheader(),
  });
};
const getEventById = (eventId) => {
  return axios.get(API_URL + "event/getEventById/" + eventId, {
    headers: authadminheader(),
  });
};
const editEventDetail = (data) => {
  return axios.patch(
    API_URL + "event/editEvent/" + data.eventId,
    data.formData,
    { headers: authadminheader() }
  );
};
const deleteEvent = (eventId) => {
  return axios.patch(
    API_URL + "event/deleteEvent/" + eventId,
    {},
    { headers: authadminheader() }
  );
};
//Activities crud operation
const getAllEventActivity = (data) => {
  return axios.get(API_URL + "event/getAllActivitys", {
    headers: authadminheader(),
  });
};
const getAllActivitysByEventId = (eventId, query) => {
  return axios.get(
    API_URL + "event/getAllActivitysByEventId/" + eventId + `?${query}`,
    { headers: authadminheader() }
  );
};
const createEventActivity = (data) => {
  return axios.post(API_URL + "event/createActivity", data, {
    headers: authadminheader(),
  });
};

const editEventActivity = (data) => {
  return axios.patch(API_URL + "event/editActivity/" + data.id, data.data, {
    headers: authadminheader(),
  });
};

const deleteEventActivity = (data) => {
  return axios.patch(
    API_URL + "event/deleteActivity/" + data,
    {},
    { headers: authadminheader() }
  );
};

const getEventActivityById = (data) => {
  return axios.get(API_URL + "event/getActivityById/" + data, {
    headers: authadminheader(),
  });
};

//Speakers crud operation
const getAllEventSpeakers = (data) => {
  return axios.get(API_URL + "getAllSpeakers", { headers: authadminheader() });
};

const createEventSpeaker = (data) => {
  return axios.post(API_URL + "createSpeaker", data, {
    headers: authadminheader(),
  });
};

const editEventSpeaker = (data) => {
  return axios.put(API_URL + "speaker/edit/" + data.id, data.data, {
    headers: authadminheader(),
  });
};

const deleteEventSpeaker = (data) => {
  return axios.post(
    API_URL + "speaker/delete/" + data,
    {},
    { headers: authadminheader() }
  );
};

const getEventSpeakerById = (data) => {
  return axios.get(API_URL + "speakerById/" + data, {
    headers: authadminheader(),
  });
};

//get sold ticket info api in event listing
const getSoldTicket = (eventId) => {
  return axios.get(API_URL + "event/ticket-purchased-qty-user/" + eventId, {
    headers: authadminheader(),
  });
};

//Session crud operation
const getAllEventSessions = () => {
  return axios.get(API_URL + "event/getAllSessions", {
    headers: authadminheader(),
  });
};
const getAllSessionsByEventId = (eventId, query) => {
  return axios.get(
    API_URL + "event/getAllSessionsByEventId/" + eventId + `?${query}`,
    { headers: authadminheader() }
  );
};
const getSessionSuggestionApi =(eventId) =>{
  return axios.get(
    API_URL + "event/get-all-session-suggestion/" + eventId ,
    { headers: authadminheader() }
  )
};
const getAllSessionsByDate = (data) => {
  return axios.get(
    API_URL +
      `event/getSessionListByDate/?date=${data.date}&eventId=${data.eventId}`,
    { headers: authadminheader() }
  );
};
const createEventSession = (data) => {
  return axios.post(API_URL + "event/createSession", data, {
    headers: authadminheader(),
  });
};

const editEventSession = (data) => {
  return axios.patch(API_URL + "event/editSession/" + data.id, data.data, {
    headers: authadminheader(),
  });
};

const deleteEventSession = (id, eventId) => {
  return axios.patch(
    API_URL + "event/deleteSession/" + id,
    { eventId: eventId },
    { headers: authadminheader() }
  );
};

const getEventSessionById = (data) => {
  return axios.get(API_URL + "event/getSessionDetails/" + data, {
    headers: authadminheader(),
  });
};

//Room Crud operation
const getAllEventRooms = () => {
  return axios.get(API_URL + "event/getAllRooms", {
    headers: authadminheader(),
  });
};
const getAllRoomsByEventId = (eventId, query) => {
  return axios.get(
    API_URL + "event/getAllRoomsByEventId/" + eventId + `?${query}`,
    { headers: authadminheader() }
  );
};
const getRoomsSuggestionApi =(eventId) =>{
  return axios.get(
    API_URL + "event/get-all-room-suggestion/" + eventId ,
    { headers: authadminheader() }
  )
};
const createEventRoom = (data) => {
  return axios.post(API_URL + "event/createRoom", data, {
    headers: authadminheader(),
  });
};

const editEventRoom = (data) => {
  return axios.patch(API_URL + "event/editRoom/" + data.id, data.data, {
    headers: authadminheader(),
  });
};

const deleteEventRoom = (data) => {
  return axios.patch(
    API_URL + "event/deleteRoom/" + data,
    {},
    { headers: authadminheader() }
  );
};

const getEventRoomById = (data) => {
  return axios.get(API_URL + "event/getRoomDetails/" + data, {
    headers: authadminheader(),
  });
};

//Event Location Api
const getAllEventLocations = (data) => {
  return axios.get(API_URL + "event/getalllocation", {
    headers: authadminheader(),
  });
};
const getAllEventLocationsByEventId = (eventId, query) => {
  return axios.get(
    API_URL + "event/getalllocationbyeventid/" + eventId + "?" + query,
    { headers: authadminheader() }
  );
};
const getLocationSuggestionApi =(eventId) =>{
return axios.get(
  API_URL + "event/get-all-location-suggestion/" + eventId ,
  { headers: authadminheader() }
)
};
const createEventLocation = (data) => {
  return axios.post(API_URL + "event/addlocation", data, {
    headers: authadminheader(),
  });
};

const editEventLocation = (data) => {
  return axios.patch(API_URL + "event/editlocation/" + data.id, data.data, {
    headers: authadminheader(),
  });
};

const deleteEventLocation = (id, eventId) => {
  return axios.patch(
    API_URL + "event/deletelocation/" + id,
    { eventId: eventId },
    { headers: authadminheader() }
  );
};

const getEventLocationById = (data) => {
  return axios.get(API_URL + "event/getlocationdetail/" + data, {
    headers: authadminheader(),
  });
};

//package crud operation
const getallpackages_byadmin = () => {
  return axios.get(API_URL + "event/getallpackages", {
    headers: authadminheader(),
  });
};
const getallpackagesByEventId = (eventId) => {
  return axios.get(API_URL + "event/getallpackagesByEventId/" + eventId, {
    headers: authadminheader(),
  });
};
const getPackagesSuggestionApi =(eventId) =>{
  return axios.get(
    API_URL + "event/get-all-package-suggestion/" + eventId ,
    { headers: authadminheader() }
  )
  }; 
const createEventPackage = (data) => {
  return axios.post(API_URL + "event/addpackage", data, {
    headers: authadminheader(),
  });
};

const deleteEventPackage = (data) => {
  return axios.patch(
    API_URL + "event/deletepackage/" + data,
    {},
    { headers: authadminheader() }
  );
};

const editEventPackage = (data) => {
  return axios.patch(API_URL + "event/editpackage/" + data.id, data.data, {
    headers: authadminheader(),
  });
};
const getEventPackageById = (data) => {
  return axios.get(API_URL + "event/getpackagedetail/" + data, {
    headers: authadminheader(),
  });
};
const eventUploadFiles = (data) => {
  return axios.post(API_URL + "event/upload/files", data, {
    headers: authadminheader(),
  });
};

//purchase ticket listing for attendees
const purchaseTicketListApi = (data) => {
  return axios.post(API_URL + "event/purchase-ticket-attendee", data, {
    headers: authadminheader(),
  });
};

//Attendees CRUD
const getAllAttendees = (data) => {
  return axios.get(API_URL + "event/exportAttendees", {
    headers: authadminheader(),
  });
};
const getAllAttendeesByEventId = (data, query) => {
  return axios.get(
    API_URL + "event/getAttendeesByEventId/" + data + `?${query}`,
    {
      headers: authadminheader(),
    }
  );
};

const getActivitySuggestionApi =(eventId) =>{
  return axios.get(
    API_URL + "event/get-all-activity-suggestion/" + eventId ,
    { headers: authadminheader() }
  )
}; 

const getAttendeesSuggestionApi =(eventId) =>{
  return axios.get(
    API_URL + "event/get-attendee-suggestion/" + eventId ,
    { headers: authadminheader() }
  )
  }; 

const importAllAttendees = (data) => {
  return axios.post(API_URL + "event/importAttendees", data, {
    headers: authadminheader(),
  });
};

const addExistingAttendees = (data) => {
  return axios.post(API_URL + "event/addExistingAttendeeToEvent", data, {
    headers: authadminheader(),
  });
};

const createEventAttendees = (data) => {
  return axios.post(API_URL + "event/createEventAttendees", data, {
    headers: authadminheader(),
  });
};

const deleteEventAttendees = (data) => {
  return axios.patch(
    API_URL +
      "event/deleteEventAttendees/" +
      data.id +
      "?role=" +
      data.role +
      "&eventId=" +
      data.eventId,
    {},
    { headers: authadminheader() }
  );
};

const editEventAttendees = (id, data) => {
  return axios.patch(API_URL + "event/editEventAttendees/" + id, data, {
    headers: authadminheader(),
  });
};
const getEventAttendeeById = (data) => {
  return axios.get(
    API_URL +
      "event/getAttendee/" +
      data.id +
      "?role=" +
      data.role +
      "&eventId=" +
      data.eventId,
    { headers: authadminheader() }
  );
};

//Faqs Curd
const getAllFaqs = () => {
  return axios.get(API_URL + "event/getAllFaqs", {
    headers: authadminheader(),
  });
};
const getAllFaqsByEventId = (eventId) => {
  return axios.get(API_URL + "event/getAllEventFaqsByEventId/" + eventId, {
    headers: authadminheader(),
  });
};
const createEventFaq = (data) => {
  return axios.post(API_URL + "event/createFaq", data, {
    headers: authadminheader(),
  });
};

const deleteEventFaq = (data) => {
  return axios.patch(
    API_URL + "event/deleteFaq/" + data,
    {},
    { headers: authadminheader() }
  );
};

const editEventFaq = (data) => {
  return axios.patch(API_URL + "event/editFaq/" + data.id, data.data, {
    headers: authadminheader(),
  });
};
const getEventFaqById = (data) => {
  return axios.get(API_URL + "event/getFaqDetail/" + data, {
    headers: authadminheader(),
  });
};

//Support Curd
const getAllContactSupportByEventId = (eventId) => {
  return axios.get(
    API_URL + "event/getAllContactSupportsByEventId/" + eventId,
    { headers: authadminheader() }
  );
};
const createEventContactSupport = (data) => {
  return axios.post(API_URL + "event/createContactSupport", data, {
    headers: authadminheader(),
  });
};
const deleteEventContactSuppor = (data) => {
  return axios.patch(
    API_URL + "event/deleteContactSupport/" + data,
    {},
    { headers: authadminheader() }
  );
};
const editEventContactSuppor = (data) => {
  return axios.patch(
    API_URL + "event/editContactSupport/" + data.id,
    data.data,
    { headers: authadminheader() }
  );
};
const getEventContactSupporById = (data) => {
  return axios.get(API_URL + "event/getContactSupportDetail/" + data, {
    headers: authadminheader(),
  });
};
const addEditEventContactSupport = (data) => {
  return axios.patch(
    API_URL + "event/editContactSupportInEvent/" + data.event,
    data,
    { headers: authadminheader() }
  );
};
//Gallery photos Curd
const getAllGalleryByEventId = (eventId) => {
  return axios.get(API_URL + "event/getEventGallery/" + eventId, {
    headers: authadminheader(),
  });
};
const addEventGalleryPhoto = (data) => {
  return axios.post(API_URL + "event/addEventGallery/" + data.id, data.data, {
    headers: authadminheader(),
  });
};

const deleteGalleryPhoto = (data) => {
  return axios.patch(API_URL + "event/deleteEventGallery", data, {
    headers: authadminheader(),
  });
};
//get all activity images list
const getAllActivitiesImages = (eventId) => {
  return axios.get(API_URL + "event/getAllActivityImages", {
    headers: authadminheader(),
  });
};

const deleteActivityIcon = (data) => {
  return axios.patch(API_URL + "event/deleteActivityIcon", data, {
    headers: authadminheader(),
  });
};
const rearrangeAttendee = (data) => {
  return axios.patch(API_URL + "event/partner/reorder", data, {
    headers: authadminheader(),
  });
};
const reOrderPackages = (data) => {
  return axios.post(API_URL + "/event/packageReorder", data, {
    headers: authadminheader(),
  });
};

//get all event type list
const getEventTypes = (query) => {
  return axios.get(API_URL + "event/EventTypelist" + query, {
    headers: authadminheader(),
  });
};

//create event type
const createEventType = (data) => {
  return axios.post(API_URL + "event/createEventType", data, {
    headers: authadminheader(),
  });
};

//get event type detail
const getEventTypeDetail = (id) => {
  return axios.get(API_URL + "event/EventTypedetail/" + id, {
    headers: authadminheader(),
  });
};

//delete event type
const deleteEventType = (id) => {
  return axios.patch(
    API_URL + "/event/deleteEventType/" + id,
    {},
    { headers: authadminheader() }
  );
};

//edit event type detail
const editEventType = (data) => {
  return axios.post(
    API_URL + "/event/editEventType/" + data.id,
    data.eventTypeData,
    { headers: authadminheader() }
  );
};

//add location images for event
const addLocationImageForEvent = (data) => {
  console.log(data, "data");
  return axios.post(API_URL + "/event/addLocationImages", data, {
    headers: authadminheader(),
  });
};

//edit location images for event
const editLocationImageForEvent = (eventid, data) => {
  console.log(data, "data");
  return axios.patch(API_URL + "/event/editLocationImages/" + eventid, data, {
    headers: authadminheader(),
  });
};

//bulk delete API
const deleteBulk = (id, data) => {
  return axios.patch(
    API_URL + "event/deleteEventAttendees" + "?eventId=" + id,
    data,
    { headers: authadminheader() }
  );
};

//bulk Resend Ticket API
const ResendTicket = (id, data) => {
  return axios.patch(
    API_URL + "event/resend-email-ticket-purchase" + "?eventId=" + id,
    data,
    { headers: authadminheader() }
  );
};

const sendSocketEventAtAttendeeUpdation = (data) => {
  return axios.post(CHAT_API_URL + "updateAttendeeSendMessage", data, {
    headers: authadminheader(),
  });
};

// event clone
const cloneEvent = (id) => {
  return axios.post(API_URL + "event/cloneEvent", id, {
    headers: authadminheader(),
  });
};

const reorderRooms = (data, eventId) => {
  return axios.post(API_URL + "event/reorderRoomsByEventId/" + eventId, data, {
    headers: authadminheader(),
  });
};

const reorderFaqs = (data, eventId) => {
  return axios.post(API_URL + "event/reorderFaqsOfEventId/" + eventId, data, {
    headers: authadminheader(),
  });
};

// get list of event participant
const eventParticipantTypesListApi = (eventid, query) => {
  // page==0  page+1;
  return axios.get(
    API_URL + `event/dynamicParticipantTypes/list/${eventid}/${query}`,
    { headers: authadminheader() }
  );
};

const getParticipantsTypeSuggestionApi =(eventId) =>{
  return axios.get(
    API_URL + "event/dynamic-participant-suggestion/" + eventId ,
    { headers: authadminheader() }
  )
  }; 

// Search list of event participant
const searchEventParticipantTypesApi = (eventid, member) => {
  return axios.get(
    API_URL + `event/dynamicParticipantTypes/all/${eventid}?search=${member}`,
    { headers: authadminheader() }
  );
};

// Add event participant
const addEventParticipantTypesApi = (body) => {
  return axios.post(API_URL + `event/dynamicParticipantTypes/create`, body, {
    headers: authadminheader(),
  });
};

// Edit event participant
const editEventParticipantTypesApi = (body) => {
  return axios.patch(API_URL + `event/dynamicParticipantTypes/update`, body, {
    headers: authadminheader(),
  });
};
// Search event participant
const searchByIdEventParticipantTypesApi = (id) => {
  return axios.get(API_URL + `event/dynamicParticipantTypes/get-by-id/${id}`, {
    headers: authadminheader(),
  });
};

// delete event participant
const deleteEventParticipantTypesListApi = (id) => {
  return axios.delete(API_URL + `event/dynamicParticipantTypes/delete/${id}`, {
    headers: authadminheader(),
  });
};

// categories crud
const getCategoriesList = (query = "") => {
  return axios.get(API_URL + "event/categories" + query, {
    headers: authadminheader(),
  });
};

const addCategory = (data) => {
  return axios.post(API_URL + "event/category/create", data, {
    headers: authadminheader(),
  });
};

const editCategory = (id, data) => {
  return axios.patch(API_URL + `event/category/edit/${id}`, data, {
    headers: authadminheader(),
  });
};

const deleteCategory = (id) => {
  return axios.delete(API_URL + `event/category/delete/${id}`, {
    headers: authadminheader(),
  });
};

const checkAlredayExistSubCategory = (data) => {
  return axios.post(
    API_URL + "event/subcategory/checkAlredayExistSubCategory",
    data,
    { headers: authadminheader() }
  );
};

const viewCategory = (id) => {
  return axios.get(API_URL + `event/category/getById/${id}`, {
    headers: authadminheader(),
  });
};

// subcategory crud
const editSubCategory = (id, data) => {
  return axios.post(API_URL + `event/subcategory/edit/${id}`, data, {
    headers: authadminheader(),
  });
};
//get the user info for sold ticket
const getSoldTicektPerUserApi = (ticketId) => {
  return axios.get(API_URL + `/event/ticket-purchase-user-info/${ticketId}`, {
    headers: authadminheader(),
  });
};
// fetch time zone automatically based on latitude and longitude
const getTimeZoneFromLocation = (query = "") => {
  return axios.get(API_URL + `event/getTimeZoneFromLocation${query}`, {
    headers: authadminheader(),
  });
};

//upcoming event listing for frontend
const upComingEventList = (query) => {
  return axios.get(API_URL + "event/getUpCommingEvent" + query, {
    headers: authHeader(),
  });
};

//upcoming event filter listing for frontend
const upComingFilterEventList = (query) => {
  return axios.get(API_URL + "event/EventTypelistData" + query, {
    headers: authHeader(),
  });
};

//past event listing for frontend
const pastEventList = (query) => {
  return axios.get(API_URL + "event/getPastEvent" + query, {
    headers: authHeader(),
  });
};

//past filter event listing for frontend
const pastEventFilterList = (query) => {
  return axios.get(API_URL + "event/getPastEventYearList" + query, {
    headers: authHeader(),
  });
};

//my event listing for frontend
const myEventList = (query) => {
  return axios.get(API_URL + "event/getEventList" + query, {
    headers: authHeader(),
  });
};

//search suggestion for frontend event
const eventSuggestionList = () => {
  return axios.get(API_URL + "allEventList", {
    headers: authHeader(),
  });
};

//search suggestion history for frontend event
const eventSuggestionHistory = () => {
  return axios.get(API_URL + "topEventSearch", {
    headers: authHeader(),
  });
};

//adding a search text to history
const addEventSearch = (data) => {
  return axios.post(API_URL + "addEventSearch", data, {
    headers: authHeader(),
  });
};

//fetch data based on search text for frontend event
const eventSearchList = (query) => {
  return axios.get(API_URL + "event/eventSearchList" + query, {
    headers: authHeader(),
  });
};

//fetch all the categories
const eventCategoriesList = (query) => {
  return axios.get(API_URL + "event/categoriesForUser" + query, {
    headers: authHeader(),
  });
};

//fetch data based on Event id for frontend event
const getTicketListEvent = (eventId) => {
  return axios.get(API_URL + "event/ticket-list/" + eventId, {
    headers: authHeader(),
  });
};

//fetch full list of categories
const eventUserCategoriesList = (query) => {
  return axios.get(API_URL + "event/allCategoriesForUser" + query, {
    headers: authHeader(),
  });
};
const getEventDetailByIdForUser = (eventId) => {
  return axios.get(API_URL + "event/getEvent/" + eventId, {
    headers: authHeader(),
  });
};

//delete history
const removeSearchHistory = (id) => {
  return axios.post(
    API_URL + `removeEventSearch/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
};

//event result count
const resultCountApi = (query) => {
  return axios.get(API_URL + "event/getFilterEventCountForUser" + query, {
    headers: authHeader(),
  });
};
export default {
  cloneEvent,
  deleteActivityIcon,
  getAllActivitiesImages,
  getAllSessionsByDate,
  createEvent,
  getAllEvents,
  updateEventStatus,
  getAllEventsLimitedFiedls,
  getEventById,
  getSoldTicket,
  editEventDetail,
  deleteEvent,
  getAllEventActivity,
  getAllActivitysByEventId,
  createEventActivity,
  editEventActivity,
  deleteEventActivity,
  getEventActivityById,
  getAllEventSpeakers,
  createEventSpeaker,
  editEventSpeaker,
  deleteEventSpeaker,
  getEventSpeakerById,
  getAllEventSessions,
  getAllSessionsByEventId,
  createEventSession,
  editEventSession,
  deleteEventSession,
  getEventSessionById,
  getAllEventRooms,
  getAllRoomsByEventId,
  createEventRoom,
  editEventRoom,
  deleteEventRoom,
  getEventRoomById,
  getAllEventLocations,
  getAllEventLocationsByEventId,
  createEventLocation,
  editEventLocation,
  deleteEventLocation,
  getEventLocationById,
  getallpackages_byadmin,
  getallpackagesByEventId,
  createEventPackage,
  deleteEventPackage,
  editEventPackage,
  getEventPackageById,
  eventUploadFiles,
  purchaseTicketListApi,
  getAllAttendees,
  getAllAttendeesByEventId,
  importAllAttendees,
  createEventAttendees,
  getAllFaqs,
  getAllFaqsByEventId,
  createEventFaq,
  deleteEventFaq,
  editEventFaq,
  getEventFaqById,
  getAllContactSupportByEventId,
  createEventContactSupport,
  deleteEventContactSuppor,
  editEventContactSuppor,
  getEventContactSupporById,
  deleteEventAttendees,
  editEventAttendees,
  getEventAttendeeById,
  getAllGalleryByEventId,
  addEventGalleryPhoto,
  deleteGalleryPhoto,
  addEditEventContactSupport,
  addExistingAttendees,
  rearrangeAttendee,
  reOrderPackages,
  getEventTypes,
  createEventType,
  getEventTypeDetail,
  deleteEventType,
  editEventType,
  addLocationImageForEvent,
  editLocationImageForEvent,
  deleteBulk,
  ResendTicket,
  sendSocketEventAtAttendeeUpdation,
  reorderRooms,
  reorderFaqs,
  eventParticipantTypesListApi,
  addEventParticipantTypesApi,
  editEventParticipantTypesApi,
  searchByIdEventParticipantTypesApi,
  deleteEventParticipantTypesListApi,
  searchEventParticipantTypesApi,
  getCategoriesList,
  addCategory,
  editCategory,
  deleteCategory,
  checkAlredayExistSubCategory,
  viewCategory,
  editSubCategory,
  getTimeZoneFromLocation,
  upComingEventList,
  pastEventList,
  myEventList,
  upComingFilterEventList,
  pastEventFilterList,
  eventSuggestionList,
  eventSuggestionHistory,
  eventSearchList,
  addEventSearch,
  eventCategoriesList,
  getEventDetailByIdForUser,
  getLocationSuggestionApi,
  getPackagesSuggestionApi,
  getRoomsSuggestionApi,
  getParticipantsTypeSuggestionApi,
  getAttendeesSuggestionApi,
  getSessionSuggestionApi,
  getActivitySuggestionApi
};
