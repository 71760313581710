import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { updateSrc } from "video.js";
import authService from "../../../Services/auth.service";
import authService1 from "../../../Services/auth.service1";
import { ReactComponent as VideoPlayIcon } from '../../../Images/icn_m_play_filled.svg'
import { ReactComponent as VideoPauseIcon } from '../../../Images/icn_m_pause_filled.svg'
import { ReactComponent as VideoVolumeDown } from '../../../Images/volumeDown.svg'
import { ReactComponent as VideoVolumeUp } from '../../../Images/icn_s_sound_full.svg'
import { ReactComponent as VideoVolumeMute } from '../../../Images/icn_s_sound_mute2.svg'
import { ReactComponent as VideoPlayForword } from '../../../Images/inc_m_forward5.svg'
import { ReactComponent as VideoPlayBackword } from '../../../Images/icn_m_backward5.svg'
import { ReactComponent as VideoPlayFast } from '../../../Images/icn_m_chevron_right_icon.svg'
import { ReactComponent as VideoPlaySlow } from '../../../Images/icn_m_chevron_left_icon.svg'
import moment from "moment";

const alertUserEmail = ["nr@decodeup.email", "tk@decodeup.email", "tangowithw@gmail.com", "komal@nncinfotech.com", "ravi@nncinfotech.com"]
export default function VideoPlayer({
  videooptions,
  changevideotime,
  videodetail,
  setvideodetail,
  setvideooptions,
}) {
  const videoRef = useRef(null);
  var completeTime = 0;
  var position = 0;
  const firstRender = useRef(true);
  const cursec = useRef(0);
  const { sec } = useParams();
  const [countDown, setCountDown] = useState(30);
  const [runTimer, setRunTimer] = useState(false);
  const [viewAdded, setViewAdded] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [showLabelMessage, setShowLabelMessage] = useState("");
  const [keyEvent, setKeyEvent] = useState("")

  useEffect(() => {
    const { videoid, sources, startTime, ...options } = videooptions;
    const player = videojs(videoRef.current, options, () => {
      player.updateSrc(videooptions.sources);
      player.on("seeking", () => { });
      player.on("durationchange", () => {
        let arr = videooptions;
        arr.startViewTime = new Date().getTime();
        setvideooptions(arr);
        const pausetime = player.currentTime();
        authService.addpausevideobyuser({
          id: videoid,
          data: { pause_time: pausetime },
        });
      });
      player.on("seeked", function () {
        completeTime = Math.floor(player.currentTime());
        position = Math.floor(player.currentTime());
      });
      player.on("pause", () => {
        setRunTimer(false);
        const pausetime = player.currentTime();
        const end = new Date();
        let delta = Math.abs(end.getTime() - videooptions.startViewTime) / 1000;
        let hours = Math.floor(delta / 3600);
        delta = delta - (hours > 0 ? hours * 3600 : 0);
        let minutes = Math.floor(delta / 60);
        delta = delta - (minutes > 0 ? minutes * 60 : 0);
        let seconds = Math.floor(delta);
        if (videooptions.startViewTime !== null && videooptions.isViewAdded) {
          let arr = videooptions;
          arr.startViewTime = null;
          setvideooptions(arr);
          authService1
            .addUserWatchVideoTime({
              videoid: videoid,
              watchtime: hours + ":" + minutes + ":" + seconds,
            })
            .then((res) => {
              if (res.data.status) {
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }

        authService.addpausevideobyuser({
          id: videoid,
          data: { pause_time: pausetime },
        });
      });
      player.on("play", () => {
        setRunTimer(true);
        if (videoRef.current) {
          document
            .getElementsByClassName("video-js")[0]
            .classList.add("vjs-fluid");
        }

        let arr = videooptions;
        arr.startViewTime = new Date().getTime();
        setvideooptions(arr);

        if (firstRender.current) {
          player.currentTime(
            cursec.current === 0 ? videooptions.startTime : cursec.current
          );
          firstRender.current = false;
        }
      });

      player.on("ended", () => {
        authService
          .addpausevideobyadmin({ id: videoid, data: { pause_time: 0 } })
          .then((res) => { })
          .catch((e) => {
            console.log(e);
          });
      });
    });
    player.getChild('controlBar').removeChild('captionsButton');
  }, [videooptions]);

  useEffect(() => {
    firstRender.current = true;
    cursec.current = videooptions.startTime;
  }, [videooptions]);

  useEffect(() => {
    let timerId;
    if (runTimer) {
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);
  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
      if (!viewAdded) {
        setViewAdded(true);
        authService
          .addviewbyuser(videooptions.videoid, "web")
          .then((res) => {
            if (res.data.status) {
              if (alertUserEmail.includes(localStorage.getItem("useremailid"))) {
                alert("Video count added!");
              }
              let arr = videooptions;
              arr.isViewAdded = true;
              arr.startViewTime = new Date().getTime();
              setvideooptions(arr);


              if (videooptions.startViewTime !== null) {
                authService1
                  .addUserWatchVideoTime({
                    videoid: videooptions.videoid,
                    watchtime: 0 + ":" + 0 + ":" + 30,
                  })
                  .then((res) => {
                    if (res.data.status) {
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }

              // authService
              //   .getcontentvideobyidForUser(videooptions.videoid)
              //   .then((res_) => {
              //     if (res_.data.status) {
              //       setvideodetail(res_.data.data);
              //     }
              //   })
              //   .catch((e) => {
              //     console.log(e);
              //   });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }, [countDown, runTimer]);
  const togglerTimer = () => setRunTimer((t) => !t);

  // Function to toggle fullscreen
  const toggleFullscreen = () => {
    const container = document.querySelector('.video-container');
    if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement) {
      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen();
      } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
    }
  };

  // shortcut lable handling
  var labelTimeout;
  const shortcutLabelHandler = (message) => {
    setShowLabelMessage("");
    setShowLabel(false);
    if (labelTimeout) {
      clearTimeout(labelTimeout);
    }
    setShowLabel(true);
    setShowLabelMessage(message);
    labelTimeout = setTimeout(() => {
      setShowLabel(false);
    }, 250);
  };

  const handleKeyDown = (event) => {
    const player = videojs(videoRef.current);
    const commentBox = document.getElementById("comments-scroll-sec");
    const referBox = document.getElementById("refer-box-sec");
    if (!videoRef.current) return;
    if (commentBox  && !commentBox.contains(event.target) || (referBox && !referBox.contains(event.target))) {
      switch (event.code) {
        // toggle fullScreen mode
        case "KeyF":
          toggleFullscreen();
          event.preventDefault();
          break;
        // toggle play/pause button
        case "Space":
          event.preventDefault()
          const isPaused = player.paused()
          if (isPaused) {
            player.play()
          } else {
            player.pause()
          }
          shortcutLabelHandler(isPaused ? "play" : "pause")
        default:
          break;
      }
      switch (event.key) {
        case "ArrowLeft":
          // Seek backward 5 seconds
          videoRef.current.currentTime = Math.max(
            0,
            videoRef.current.currentTime - 5
          );
          shortcutLabelHandler("backward")
          event.preventDefault();
          break;
        case "ArrowRight":
          // Seek forward 5 seconds
          videoRef.current.currentTime = Math.min(
            videoRef.current.duration,
            videoRef.current.currentTime + 5
          );
          shortcutLabelHandler("forward")
          event.preventDefault();
          break;
        case "<":
          // Decrease playback speed
          const currentRate = videoRef.current.playbackRate;
          const currentIndex = videooptions.playbackRates.indexOf(currentRate);
          if (currentIndex > 0) {
            videoRef.current.playbackRate =
              videooptions.playbackRates[currentIndex - 1];
          }
          shortcutLabelHandler("decrease-speed")
          event.preventDefault();
          break;
        case ">":
          // Increase playback speed
          const currentIncreaseRate = videoRef.current.playbackRate;
          const currentIncreaseIndex =
            videooptions.playbackRates.indexOf(currentIncreaseRate);
          if (currentIncreaseIndex < videooptions.playbackRates.length - 1) {
            videoRef.current.playbackRate =
              videooptions.playbackRates[currentIncreaseIndex + 1];
          }
          shortcutLabelHandler("increase-speed")
          event.preventDefault();
          break;
        // increase and descrease volume
        case "ArrowUp":
          player.volume(player.volume() + 0.05);
          shortcutLabelHandler(+(Math.round(player.volume() * 100)))
          setKeyEvent("ArrowUp")
          event.preventDefault();
          break;
        case "ArrowDown":
          player.volume(player.volume() - 0.05);
          shortcutLabelHandler(+(Math.round(player.volume() * 100)))
          setKeyEvent("ArrowDown")
          event.preventDefault();
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="video-container position-relative">
      <video
        ref={videoRef}
        id={videooptions.videoid}
        className="video-js vjs-default-skin"
        style={{ width: "100%" }}
        tabIndex="0"
      >
        <track
          kind="captions"
          src={
            videooptions.subtitle !== undefined &&
              videooptions.subtitle.length > 0
              ? "https://mds-community.s3.us-east-2.amazonaws.com/" +
              videooptions.subtitle
              : ""
          }
          srclang="en"
          label="English"
          default
        />
      </video>
      {showLabel && <>
        {(showLabelMessage === 'play'
          || showLabelMessage === 'pause'
          || (showLabelMessage > 0)
          || showLabelMessage === 0) && <div className={`center-icon ${showLabel ? 'animate' : ''}`}>
            {showLabelMessage === 'play' && <VideoPlayIcon />}
            {showLabelMessage === 'pause' && <VideoPauseIcon />}
            {keyEvent === "ArrowUp" && (showLabelMessage > 0) && <VideoVolumeUp />}
            {keyEvent === "ArrowDown" && (showLabelMessage > 0) && <VideoVolumeDown />}
            {showLabelMessage === 0 && <VideoVolumeMute />}
          </div>}
        {showLabelMessage === "forward" && <div className={`right-icon ${showLabel ? 'animate' : ''}`}>
          <VideoPlayForword />
        </div>}
        {showLabelMessage === "increase-speed" && <div className={`right-icon ${showLabel ? 'animate' : ''}`}>
          <VideoPlayFast />
        </div>}
        {showLabelMessage === "backward" && <div className={`left-icon ${showLabel ? 'animate' : ''}`}>
          <VideoPlayBackword />
        </div>}
        {showLabelMessage === "decrease-speed" && <div className={`left-icon ${showLabel ? 'animate' : ''}`}>
          <VideoPlaySlow />
        </div>}
        {(typeof showLabelMessage === "number") && <div className={`volume-top`}>
          <div className="volume-text">
          {showLabelMessage + "%"}
          </div>
        </div>}
      </>}
    </div>
  );
}