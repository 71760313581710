import React, { useEffect, useState } from 'react'
import AdminSidebar from '../AdminSidebar'
import Tags from './Tags'
import { Link } from 'react-router-dom'

const index = () => {
    const [sidebarmenu, setSidebarmenu] = useState(false);

    const updateWindowSize = () => {
        if (window.innerWidth > 992) {
            setSidebarmenu(false)
            document.body.classList.remove('overflow-hidden')
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowSize);
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    const sidebarHandleClick = (ele) => {
        setSidebarmenu(ele);
        if (window.innerWidth < 992) {
            if (sidebarmenu) {
                document.body.classList.remove('overflow-hidden')
            } else {
                document.body.classList.add('overflow-hidden')
            }
        }
    }
    return (
        <>
            <div className="cstm-all-admin">
                {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
                <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
                <div className="content-main">
                    <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <Link
                                        onClick={() => { sidebarHandleClick(false) }}
                                        className={`btn-link collapsed  sd-active`}>
                                        Tags
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tags />
                </div>
            </div>
        </>
    )
}

export default index