import { useNavigate } from "react-router-dom";
import Services from "../Services/auth.service";
import { useState } from "react";
import ModelPopup from "./ModalPopup/ModalPopup";
import Button from '../../src/components/Button/Button';
import { ReactComponent as Logo } from '../../src/Images/logo_mds_small.svg'

const LoginAccessPage = () => {
    const history = useNavigate()

    return (
        <ModelPopup
            handleClick2ndButton={""}
            onClose={""}
            isFooter={false}
            isHeader={false}
            modelSize="480"
            isOpen={true}
            headingLable=""
            hasCross={true}
            hasContentCenter={true}
            disabled={false}
            buttonText="Keep order"
            buttonText1="Cancel order"
            spinnersType="white"
            btn2variant="danger"
            hasLoading={true}
            hasTextString={"Read more about the"}
            buttonType={"fluidbutton"}
        >
            <div className="cstm-modal-referal-body scrollbar" id="refer-box-sec">
                <div className='modal-close-btn'>
                    <Button
                        size='small'
                        variant='ghost'
                        elementIcon={<></>}
                        handleClick={() => dispatch(setModalIsClose())}
                        hasText={false}
                    />
                </div>
                <div className='modal-success'>
                    <div className='modal-success-icon'>
                        <Logo />
                    </div>
                    <div className='modal-success-text'>
                        <h2 className="modal-success-title">Please login in to access this page</h2>
                        <p className='modal-success-disc'>You don't have access to this link or this link is invalid.</p>
                    </div>
                    <div className='modal-success-button w-100'>
                        <Button
                            size='medium'
                            extraClass='w-100'
                            variant='primary'
                            hasIconLeft={false}
                            handleClick={() => history('/')}
                            hasText={true}
                            label='Login'
                        />
                    </div>
                </div>
            </div>
        </ModelPopup>
    )
}
export default LoginAccessPage;