import React from "react";
import PropTypes from "prop-types"
function DocIcon({ height = 24, width = 24}) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#0263D1"
                    d="M5.862 1h8.907L21 7.5v12.638A2.862 2.862 0 0 1 18.138 23H5.862A2.862 2.862 0 0 1 3 20.138V3.862A2.862 2.862 0 0 1 5.862 1Z"
                />
                <path
                    fill="#fff"
                    d="M6.657 16.698v-3.642h1.29c.258 0 .499.038.721.11.223.074.424.18.605.323.181.142.323.332.427.57.104.237.157.51.157.818 0 .308-.053.581-.157.818a1.444 1.444 0 0 1-.427.57 1.98 1.98 0 0 1-.605.323c-.222.071-.462.11-.72.11h-1.29Zm.91-.792h.27c.146 0 .282-.018.404-.05.125-.036.237-.092.344-.167a.722.722 0 0 0 .25-.32c.061-.136.091-.302.091-.492s-.03-.356-.092-.495a.73.73 0 0 0-.249-.318 1.087 1.087 0 0 0-.344-.166 1.565 1.565 0 0 0-.403-.05h-.27v2.058Zm4.5.833c-.549 0-1.002-.178-1.361-.53-.36-.354-.537-.798-.537-1.332s.178-.979.537-1.332c.359-.353.812-.53 1.36-.53.54 0 .989.177 1.347.53.356.353.534.798.534 1.332s-.178.978-.534 1.331c-.358.353-.806.531-1.346.531Zm-.7-1.094a.89.89 0 0 0 .694.302c.282 0 .51-.1.691-.302.18-.205.27-.46.27-.768 0-.309-.09-.564-.27-.768a.885.885 0 0 0-.691-.303.89.89 0 0 0-.694.303c-.18.204-.273.46-.273.768s.092.563.273.768Zm4.745 1.094c-.53 0-.973-.166-1.322-.492-.353-.33-.528-.786-.528-1.37 0-.582.178-1.038.533-1.367.36-.33.795-.496 1.317-.496.472 0 .857.116 1.163.35.302.232.477.54.522.926l-.92.186a.823.823 0 0 0-.272-.486.745.745 0 0 0-.499-.184.855.855 0 0 0-.652.279c-.175.19-.264.45-.264.792 0 .34.089.602.26.789.176.19.392.281.656.281.19 0 .353-.053.487-.16a.702.702 0 0 0 .252-.427l.94.214a1.382 1.382 0 0 1-.573.857c-.293.204-.66.308-1.1.308Z"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
            </svg>
        </>
    );
}
DocIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
export default DocIcon;
