
import { useNavigate } from "react-router-dom";
import ListEvents from "./ListEvent";

const Events = () => {
    const navigate = useNavigate();
    return (
        <>
        <ListEvents/>
        </>
    );
};
export default Events;
