import React, { useEffect, useRef, useState } from 'react'
import Avtar from '../../Avtar/Avtar'
import mainUser from '../../../Images/image2.png'
import Button from '../../Button/Button'
import EmptyState from '../../EmptyState/EmptyState'
import SingleComment from './SingleComment'
import authService from '../../../Services/auth.service'
import CommentEmpty from '../../Illustrations/CommentEmpty'
import EmojiPicker from 'emoji-picker-react'
import EmojiIcon from "../../../components/Icons/GeneralIcons/EmojiIcon";
import { InView } from 'react-intersection-observer'
import ToastNotification from '../../ToastNotification/ToastNotification'

const VideoCommentBox = ({ allComments, setAllComments, setTotalCommts, selectedVideoId, totalCommts, currentCommtPage, setCurrentCommtPage }) => {
    const inputRef = useRef(null);
    const emojiRef = useRef(null)
    const USER_ID = localStorage.getItem("userid");
    const [commentField, setCommentField] = useState('');
    const [suggestionList, setSuggestionList] = useState([]);
    const [isEmoji, setEmoji] = useState(false)
    const [isComment, setComment] = useState(false)
    const [loader, setLoader] = useState(false);
    const [deletedComment, setDeleteComment] = useState(false)
    const [isCommentLoading, setCommentLoading] = useState(false)
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        authService.getalluseremailname()
            .then((res) => {
                if (res.data.status) {
                    var arr = [];
                    for (var i = 0; i < res.data.data.length; i++) {
                        arr[
                            i
                        ] = `${res.data.data[i].first_name} ${res.data.data[i].last_name}`;
                    }
                    setSuggestionList(arr);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }, [])
    const handleCancelComment = () => {
        setComment(false)
        setCommentField('');
        inputRef.current.innerText = "";
    }

    const addComment = async (selectedVideoId) => {
        if (commentField.trim().length > 0) {
            setCommentLoading(true)
            let data = {
                videoId: selectedVideoId,
                data: {
                    content: commentField,
                    userId: USER_ID,
                    user_type: "airtable-syncs",
                    createdAt: new Date(),
                },
            };
            const addRes = await authService.addcomment_video(data)
            if (addRes.data.status) {
                setCommentLoading(false)
                if (inputRef.current) {
                    inputRef.current.innerText = '';
                    inputRef.current.blur()
                }
                setComment(false)
                setCommentField("");
                setAllComments([
                    { ...addRes.data.data, likes: { count: 0, list: [] } },
                    ...allComments,
                ]);
                setTotalCommts(prev => prev + 1);
            } else {
                setCommentLoading(false)
            }
        }
    }

    function onChange(event) {
        setCommentField(event.target.innerText);
    }

    const handlePaste = (event) => {
        event.preventDefault();
        setCommentField(event.target.innerText);
        const text = (event.clipboardData || window.clipboardData).getData('text');
        document.execCommand('insertText', false, text);
        const el = inputRef.current;
        if (el) {
            el.scrollTop = el.scrollHeight
        }
    };

    function focusInput() {
        const el = inputRef.current;
        if (el) {
            // Set focus to the element
            el.focus();
            el.scrollTop = el.scrollHeight
            // Move the caret to the end of the content
            const range = document.createRange();
            const selection = window.getSelection();

            range.selectNodeContents(el);
            range.collapse(false);

            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    document.addEventListener("mousedown", (e) => {
        if (isEmoji && emojiRef.current && !emojiRef.current.contains(e.target)) {
            setEmoji(false)
        }
    })

    const fetchMoreComments = () => {
        authService
            .getallcomments_video({ videoid: selectedVideoId, page: parseInt(currentCommtPage) + 1, limit: 10 })
            .then((res) => {
                if (res.data.status) {
                    if (
                        res.data.data[0].comment !== undefined &&
                        res.data.data[0].comment.length > 0
                    ) {
                        var data = res.data.data[0].comment.map((comm) => {
                            var reply = [];
                            var rereply = [];
                            if (comm.comments.length > 0) {
                                reply = comm.comments.map((reply) => {
                                    if (reply.comments.length > 0) {
                                        rereply = reply.comments.map((r) => {
                                            return {
                                                ...r,
                                                likes: { count: r.likes.length, list: r.likes },
                                            };
                                        });
                                    }
                                    return {
                                        ...reply,
                                        likes: { count: reply.likes.length, list: reply.likes },
                                        comments: rereply,
                                    };
                                });
                            }
                            return {
                                ...comm,
                                likes: { count: comm.likes.length, list: comm.likes },
                                comments: reply,
                            };
                        });
                        setAllComments([...allComments, ...data]);
                        setCurrentCommtPage(res.data.data[0].currentPage)
                        setTotalCommts(res.data.data[0].totalComments);
                        setLoader(false);
                    } else {
                        setLoader(false);
                        //setAllComments([]);
                    }
                } else {
                    setLoader(false);
                    //setAllComments([]);
                }
            })
            .catch((e) => {
                setLoader(false);

                return false;
            });
    }

    const handleKeyDown = (e) => {
        if (isFocused) {
            e.stopPropagation();
        }
        if (
            e.code === "Escape" &&
            inputRef.current &&
            isComment
        ) {
            setComment(false);
            e.target.innerText = "";
            inputRef.current.blur();
        }
    };

    return (
        <>
            <div className='main-comment-box d-flex flex-column'>
                <div className='comment-field d-flex align-items-start'>
                    {localStorage.getItem("userprofile") ?
                        <Avtar size='40' src={localStorage.getItem("userprofile")} /> :
                        <Avtar
                            type="letter"
                            size="40"
                            letter={localStorage.getItem("username")}
                        />
                    }
                    <p
                        ref={inputRef}
                        className='comment-input align-self-center'
                        onKeyUp={(event) => onChange(event)}
                        onPaste={handlePaste}
                        contentEditable={true}
                        aria-label="Add a comment"
                        onFocus={() => {setComment(true);setIsFocused(true)}}
                        onBlur={()=> setIsFocused(false)}
                        onKeyDown={handleKeyDown}

                    />

                </div>
                {isComment && <div className='comment-btn d-flex align-items-center justify-content-end'>
                    <Button
                        extraClass='w-fit comment-emoji-btn'
                        size='small'
                        elementIcon={<EmojiIcon
                            width={24}
                            height={24}
                        />}
                        hasText={false}
                        variant='ghost'
                        handleClick={() => setEmoji(!isEmoji)} />
                    {isEmoji &&
                        <div ref={emojiRef} >
                            <EmojiPicker className='emoji-modal' onEmojiClick={(e) => {
                                if (inputRef.current) {
                                    inputRef.current.innerText += e.emoji;
                                }
                                setCommentField(prev => prev + e.emoji)
                                focusInput()
                            }} />
                        </div>}
                    <Button variant='ghost' handleClick={handleCancelComment} hasText={true} label='Cancel' />
                    <Button hasLoading={isCommentLoading} variant='primary' disabled={commentField.trim().length === 0 || isCommentLoading} handleClick={() => addComment(selectedVideoId)} hasText={true} label='Comment' />
                </div>}
            </div>

            <div>
                {allComments.length > 0 ? <div className='comment-list-wrapper'>
                    {/* comment list */}
                    {allComments.map((comment, index) => {
                        if (index === allComments.length - 1 &&
                            index !== totalCommts - 1) {
                            return (
                                <InView onChange={(inView) => {
                                    if (inView)
                                        fetchMoreComments()
                                }}>
                                    <SingleComment
                                        setTotalCommts={setTotalCommts}
                                        suggestionList={suggestionList}
                                        setAllComments={setAllComments}
                                        comment={comment}
                                        selectedVideoId={selectedVideoId}
                                        setDeleteComment={setDeleteComment}
                                        totalCommts={totalCommts}
                                    />
                                </InView>
                            )
                        } else {
                            return <SingleComment
                                setTotalCommts={setTotalCommts}
                                suggestionList={suggestionList}
                                setAllComments={setAllComments}
                                comment={comment}
                                selectedVideoId={selectedVideoId}
                                setDeleteComment={setDeleteComment}
                                totalCommts={totalCommts}
                            />
                        }
                    })}

                    {/* Skeleton */}
                    {loader && [...Array(10)].map((el, i) => (
                        <div class="comment-list-item">
                            <div class="comment-wrapper">
                                <div class="avtar-container relative skeleton-box avtar-40" style={{ borderRadius: '555px' }}></div>
                                <div class="comment-details">
                                    <div class="comment-text">
                                        <div class="comment-heading">
                                            <h5 className='skeleton-box' style={{ width: '109px', height: '24px' }}></h5>
                                            <h5 className='skeleton-box' style={{ width: '84px', height: '18px' }}></h5>
                                        </div>
                                        <div class="content-css skeleton-box" style={{ width: '100%', height: '40px', marginTop: '2px' }}></div>
                                        <div className='skeleton-box' style={{ width: '70px', height: '36px', borderRadius: '550px', marginLeft: '-16px' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> :
                    <EmptyState icon={<CommentEmpty />} hasSubTitle={true} hasTitle={false} subTitle="Be the first to share your thoughts!" isClear={false} />}
            </div>
            {deletedComment && <div className='toast-wrapper bottom left' style={{ width: "400px" }}>
                <ToastNotification text="Comment deleted" hasAction={false} hasCross={false} />
            </div>}

        </>
    )
}

export default VideoCommentBox

function Suggestions(props) {
    function selectSuggestion(username) {
        const regexp = /@[a-zA-Z0-9]*$/;
        const newValue = props.inputValue.replace(regexp, username + " ");
        props.applyMention(newValue); // THIS MIMICS AN ONCHANGE EVENT
        props.focusInput();
        props.setShowSuggestions(false);
    }

    const suggestionItems = props.suggestionList.map((item) => (
        <div className="item" onClick={() => selectSuggestion("@" + item)}>
            @{item}
        </div>
    ));
    return props.showSuggestions ? (
        <div className="cstm-newsfeed-drop">{suggestionItems}</div>
    ) : (
        <></>
    );
}