import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../../Services/auth.service1";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import editfill from "../../Images/BackendImages/edit.svg";
import Loader2 from "../../Images/loader2.gif";

const columns = [
    { id: "no", label: "Sr No." },
    { id: "title", label: "Video Title" },
    { id: "likescount", label: "No. of Likes" },
    { id: "commentscount", label: "No. of Comments" },
    { id: "viewscount", label: "No. of Views" },
  
];

function createData(no,title, likescount,commentscount, viewscount) {
    return {
        no,
        title,
        likescount,
        commentscount,
        viewscount
    };
}
const VideoStatistic = () => {
    const history = useNavigate();
    const [rows, setrow] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [deleteId, setdeleteId] = useState("");
    const [showalert, setshowalert] = useState(false);
    const [alertmsg, setalertmsg] = useState("");
    const [showalertAdd, setshowalertAdd] = useState(false);
    const [alertmsgAdd, setalertmsgAdd] = useState("");
    const [showalertEdit, setshowalertEdit] = useState(false);
    const [alertmsgEdit, setalertmsgEdit] = useState("");
    const [searchText, setsearchText] = useState("");
    const [allvideos, setallvideos] = useState([]);
    const [selectedId, setselectedId] = useState("");
    const [fromdate, setFromDate] = useState("");
    const [todate, setToDate] = useState("");

    useEffect(() => {
        Services.getallvideolist_byadmin()
            .then((res) => {
                if (res.data.status) {
                    var data = res.data.data;
                    console.log(data,"data");
                    var tbl = [];
                    for (var i = 0; i < data.length; i++) {
                        tbl[i] = createData(i+1, data[i].title, data[i].likescount, data[i].commentscount, data[i].viewscount);
                    }
                    setrow(tbl);
                    setallvideos(tbl);
                }
            })
            .catch((e) => {
                console.log(e);
                return false;
            });
    }, []);

  
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
   

    const handleSearch = () => {
        if (allvideos !== undefined && allvideos.length > 0) {
            var data = allvideos.filter((video) => {
                return video.title.includes(searchText)
            });
            var tbl = [];
            for (var i = 0; i < data.length; i++) {
                tbl[i] = createData(i+1,data[i].title,data[i].likescount,data[i].commentscount,data[i].viewscount);
            }
            setrow(tbl);
        }
    };
   

    // const handleSorting = (e) => {
    //     var normal = allvideos;
    //     var arr = [...allvideos];

    //     if (e.target.value === "a-z") {
    //         arr.sort(function (a, b) {
    //             var textA = a.name.toUpperCase();
    //             var textB = b.name.toUpperCase();
    //             return textA < textB ? -1 : textA > textB ? 1 : 0;
    //         });
    //     } else if (e.target.value === "z-a") {
    //         arr.sort(function (a, b) {
    //             var textA = a.name.toUpperCase();
    //             var textB = b.name.toUpperCase();
    //             return textA > textB ? -1 : textA < textB ? 1 : 0;
    //         });
    //     } else {
    //         arr = normal;
    //     }
    //     var tbl = [];
    //     for (var i = 0; i < arr.length; i++) {
    //         tbl[i] = createData(arr[i].name, arr[i].counts, arr[i].action);
    //     }
    //     setrow(tbl);
    // };

    const handleSort = (col, direc) => {
        var arr = allvideos;
        if (direc === "asc") {
            arr.sort(function (a, b) {
                var textA = a[col];
                var textB = b[col];
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
        } else if (direc === "desc") {
            arr.sort(function (a, b) {
                var textA = a[col];
                var textB = b[col];
                return textA > textB ? -1 : textA < textB ? 1 : 0;
            });
        }
        var tbl = [];
        for (var i = 0; i < arr.length; i++) {
            tbl[i] = createData(i+1,arr[i].title, arr[i].likescount, arr[i].commentscount, arr[i].viewscount);
        }
        setrow(tbl);
    };

    const handledatefilter = ()=>{
        if (fromdate !== undefined && fromdate !== null  && fromdate !== "" && todate !== undefined && todate !== null  && todate !== "")
        {
            const data = {
                fromdate: fromdate,
                todate: todate
            }
            Services.getallvideolistbydateforadmin(data)
            .then((res) => {
                if (res.data.status) {
                    var data = res.data.data;
                    console.log(data,"data");
                    var tbl = [];
                    for (var i = 0; i < data.length; i++) {
                        tbl[i] = createData(i+1, data[i].title, data[i].likescount, data[i].commentscount, data[i].viewscount);
                    }
                    setrow(tbl);
                    setallvideos(tbl);
                }
            })
            .catch((e) => {
                console.log(e);
                return false;
            });
        }else
        {
            Services.getallvideolist_byadmin()
            .then((res) => {
                if (res.data.status) {
                    var data = res.data.data;
                    console.log(data,"data");
                    var tbl = [];
                    for (var i = 0; i < data.length; i++) {
                        tbl[i] = createData(i+1, data[i].title, data[i].likescount, data[i].commentscount, data[i].viewscount);
                    }
                    setrow(tbl);
                    setallvideos(tbl);
                }
            })
            .catch((e) => {
                console.log(e);
                return false;
            });
        }
        
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
     

    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title">
                        <h1>Video Statistic</h1>
                        
                    </div>

                    <div className="uic-fl">
                        <div className="uic-fl-search">
                            <input
                                type="text"
                                placeholder="Search Videos"
                                className="search-user-input"
                                value={searchText}
                                onChange={(e) => {
                                    setsearchText(e.target.value);
                                }}
                            />
                            <i className="fa fa-search" onClick={handleSearch}></i>
                        </div>

                     
                    </div>

                    <div className="uic-fl">
                        <div className="uic-fl-date">
                            <input
                                type="date"
                                placeholder="DD/MM/YYYY"
                              
                                className="from-dt-user-input"
                                value={fromdate}
                                onChange={(e) => {
                                    setFromDate( formatDate(e.target.value));
                                }}
                            />

                            <input
                                type="date"
                                placeholder="DD/MM/YYYY"
                              
                                className="to-dt-user-input"
                                value={todate}
                                onChange={(e) => {
                                    setToDate(formatDate(e.target.value));
                                }}
                            />

                            <i className="fa fa-search" onClick={handledatefilter}></i>
                        </div>

                     
                    </div>

                    
                    <Paper className="uic-tb-mn">
                        <TableContainer className="uic-table-main">
                            <Table className="uic-table">
                                <TableHead className="uic-table-th">
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className="uic-th-td w-10"
                                            >
                                                {column.id !== "action" ? (
                                                    <>
                                                        <span className="custom-sorting">
                                                            <i
                                                                className="fa fa-angle-down"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "asc");
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="fa fa-angle-up"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "desc");
                                                                }}
                                                            ></i>
                                                        </span>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="uic-table-td">
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.code}
                                                    className="uic-th-td w-20"
                                                >
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return column.id === "no" ? (
                                                          <TableCell
                                                              key={column.id}
                                                              className="uic-th-td w-17"
                                                          >
                                                              {value}
                                                          </TableCell>
                                                      ): column.id === "title" ? (
                                                            <TableCell
                                                                key={column.id}
                                                                className="uic-th-td w-17"
                                                            >
                                                                {value}
                                                            </TableCell>
                                                        ) : column.id === "likescount" ? (
                                                            <TableCell
                                                                key={column.id}
                                                                className="uic-th-td w-17"
                                                            >
                                                                {value}
                                                            </TableCell>
                                                        ) : column.id === "commentscount" ? (
                                                          <TableCell
                                                              key={column.id}
                                                              className="uic-th-td w-17"
                                                          >
                                                              {value}
                                                          </TableCell>
                                                      ) : column.id === "viewscount" ? (
                                                        <TableCell
                                                            key={column.id}
                                                            className="uic-th-td w-17"
                                                        >
                                                            {value}
                                                        </TableCell>
                                                    ) : 
                                                           <></>
                                                        ;
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="uic-pagination"
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>
           
        
                </>
    );
};
export default VideoStatistic;
