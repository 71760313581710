import React from "react";

const IconNavPartnerFilled = () => {
  return (
    <div className="mds-icons filled">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M7.25 3A.75.75 0 0 1 8 2.25h8a.75.75 0 0 1 0 1.5H8A.75.75 0 0 1 7.25 3ZM5.5 5A4.5 4.5 0 0 0 1 9.5v1l1.5.352 6.697 1.674A2.5 2.5 0 0 1 11.5 11h1a2.5 2.5 0 0 1 2.303 1.526l6.697-1.674L23 10.5v-1A4.5 4.5 0 0 0 18.5 5h-13ZM23 12l-1.5.398-6.5 1.625v.477a2.5 2.5 0 0 1-2.5 2.5h-1A2.5 2.5 0 0 1 9 14.5v-.477l-6.5-1.625L1 12v5.5A4.5 4.5 0 0 0 5.5 22h13a4.5 4.5 0 0 0 4.5-4.5V12Zm-10.5.5h-1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavPartnerFilled;
