import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AdminSidebar from "../AdminSidebar";
import AddVideo from "./AddVideo";
import DeletedVideo from "./DeletedVideo";
import EditVideo from "./EditVideo";
import VideoTag from "./VideoTag";
import VideoDetail from "./VideoDetail";
import VideoListing from "./VideoListing";
import VideoStatistic from "./VideoStatistic";
import VideoEvent from "./VideoEvent";
import PageNotFound from "../PageNotFound";
import authService from "../../Services/auth.service";
import VideoCategory from "./VideoCategory"
const ContentLibrary = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { action, videoid, sec } = useParams();
  const [selectedDiv, setselectedDiv] = useState("");
  const [selectedVideoId, setselectedVideoId] = useState("");
  const videoInprocess = useSelector((state) => {
    return state.ReduVideoInProcess;
  })
  const [changePage, setchangePage] = useState("");
  const [showPopup, setshowPopup] = useState(false);
  const [tempVideoId, setTempVideoId] = useState("");
  const [pageNotFound, setPageNotFound] = useState(false);
  const [sidebarmenu, setSidebarmenu] = useState(false);

  useEffect(() => {
    dispatch({ type: "HISTORYPAGE", payload: "/contentlibrary/listing/noid/0" })
    if (action !== undefined && action.length > 0) setselectedDiv(action);
    if (videoid === "noid") setselectedVideoId("");
    else setselectedVideoId(videoid);

    if (action !== undefined && action !== null) {
      if (action !== "listing" && action !== "videocategories" && action !== "add" && action !== "detail"
        && action !== "speaker" && action !== "tag" && action !== "deletedvideo"
        && action !== "deletedvideo" && action !== "videoevent" && action !== "edit") {
        setPageNotFound(true)
      } else {


        if ((action === "category" || action === "videoevent" || action === "add" || action === "deletedvideo" || action === "speaker" || action === "tag"
        ) && videoid !== "noid") {
          setPageNotFound(true)
        } else {
          if ((action === "detail" || action === "edit" || action === "listing") && videoid !== "noid") {
            if (action === "listing" && videoid !== "noid") {
              authService.getcontentvideobyid(videoid).then((res) => {
                if (res.data.status) {
                  setPageNotFound(false)
                } else {
                  setPageNotFound(true)
                }
              }).catch((e) => {
                setPageNotFound(true)
                return false;
              });
            }

            if (action === "detail" || action === "edit") {

              authService.getcontentvideobyid(videoid).then((res) => {
                if (res.data.status) {

                  setPageNotFound(false)
                } else {
                  setPageNotFound(true)
                }
              }).catch((e) => {
                setPageNotFound(true)
                return false;
              });
            }
          } else {
            setPageNotFound(false)
          }
        }

      }
    } else {
      setselectedDiv("listing")
      setselectedVideoId("")
    }

  }, [action, videoid]);


  const updateWindowSize = () => {
    if (window.innerWidth > 992) {
      setSidebarmenu(false)
      document.body.classList.remove('overflow-hidden')
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  const sidebarHandleClick = (ele) => {
    setSidebarmenu(ele);
    if (window.innerWidth < 992) {
      if (sidebarmenu) {
        document.body.classList.remove('overflow-hidden')
      } else {
        document.body.classList.add('overflow-hidden')
      }
    }
  }
  return (
    <>
      {!pageNotFound && <div className="cstm-all-admin">
        {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
        <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
        <div className="content-main">
          <div className="users-info">
            <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <Link
                      to={!videoInprocess && "/contentlibrary/listing/noid/0"}
                      className={`btn-link collapsed ${selectedDiv === "listing" ? "sd-active" : ""} `}
                      onClick={() => {
                        sidebarHandleClick(false);
                        if (videoInprocess) {
                          setshowPopup(true);
                          setchangePage("/contentlibrary/listing/noid/0");
                        }

                      }}
                    >
                      All video content
                    </Link>
                  </div>
                </div>
                {/* <div className="card">
                  <div className="card-header" id="headingThree">
                    <Link
                      className={`btn-link collapsed ${selectedDiv === "add" ? "sd-active" : ""} `}
                      onClick={() => {
                        if (videoInprocess) {
                          setshowPopup(true);
                          setchangePage("/contentlibrary/add/noid/0");
                        } else {
                          history("/contentlibrary/add/noid/0");
                        }

                      }}
                    >
                      Add Video
                    </Link>
                  </div>
                </div> */}
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <Link
                      to={!videoInprocess && "/contentlibrary/deletedvideo/noid/0"}
                      className={`btn-link collapsed ${selectedDiv === "deletedvideo" ? "sd-active" : ""} `}
                      onClick={() => {
                        sidebarHandleClick(false);
                        if (videoInprocess) {
                          setshowPopup(true);
                          setchangePage("/contentlibrary/deletedvideo/noid/0");
                        }
                      }}
                    >
                      Deleted videos
                    </Link>
                  </div>
                </div>

                {/* 
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <Link
                      to={!videoInprocess && "/contentlibrary/videoevent/noid/0"}
                      className={`btn-link collapsed ${selectedDiv === "videoevent" ? "sd-active" : ""} `}
                      onClick={() => {
                        if (videoInprocess) {
                          setshowPopup(true);
                          setchangePage("/contentlibrary/videoevent/noid/0");
                        }

                      }}
                    >
                      Video events
                    </Link>
                  </div>
                </div> */}
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <Link
                      to={!videoInprocess && "/contentlibrary/videocategories/noid/0"}
                      className={`btn-link collapsed ${selectedDiv === "videocategories" ? "sd-active" : ""} `}
                      onClick={() => {
                        sidebarHandleClick(false);
                        if (videoInprocess) {
                          setshowPopup(true);
                          setchangePage("/contentlibrary/videocategories/noid/0");
                        }
                      }}
                    >
                      Video categories
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {selectedDiv === "listing" ? (
              <VideoListing />
            ) : selectedDiv === "add" ? (
              <AddVideo />
            ) : selectedDiv === "edit" ? (
              <EditVideo selectedVideoId={selectedVideoId} />
            ) : selectedDiv === "deletedvideo" ? (
              <DeletedVideo />
            ) : selectedDiv === "detail" ? (
              <VideoDetail selectedVideoId={selectedVideoId} />
            ) : selectedDiv === "tag" ? (
              <VideoTag />
            ) : selectedDiv === "videostatistic" ? (
              <VideoStatistic />
            ) : selectedDiv === "videoevent" ? (
              <VideoEvent />
            ) : selectedDiv === "videocategories" ? (
              <VideoCategory />
            ) : (
              <></>
            )}
          </div>
        </div>
        {showPopup && (
          <div className="desk-globle-model-box globle-model-box ">
            <div className="desk-globle-model-box-inner cstm-deactivate-account-only">

              <div className="cstm-deactivate-account-main">
                <div class="cstm-deactivate-account-main-title">
                  Video upload is still in progress, Are you sure want to leave?
                </div>

                <div className="ays-cancel-btn">
                  <button onClick={(e) => {
                    e.preventDefault();
                    dispatch({ type: "REMOVEINPROCESS" });
                    history(changePage);
                    setshowPopup(false);
                  }}>Yes</button><button onClick={(e) => {
                    e.preventDefault();
                    setshowPopup(false);
                  }}>Cancel</button>
                </div>

              </div>

            </div>{" "}
          </div>
        )}
      </div>}
      {pageNotFound && <PageNotFound />}
    </>
  );
};
export default ContentLibrary;
