import React from "react";
import PropTypes from "prop-types";
import "../Styles/ChatMessageType.css";
import "../../../../Styles/Theme/Theme.css";
import CameraIcon from "../../../../components/Icons/GeneralIcons/GeneralIconsSmall/CameraIconSmall";
import FileAltIcon from "../../../../components/Icons/GeneralIcons/GeneralIconsSmall/IconSfile";
import VideoIcon from "../../../../components/Icons/GeneralIcons/GeneralIconsSmall/IconSmediaSmall";
import IconSmicrophone from "../../../../components/Icons/GeneralIcons/GeneralIconsSmall/IconSmicrophone";

const ChatMessageType = ({
  type = "text",
  hasSender = false,
  label = "Description",
  name = "Nickname",
  iconColor = "var(--color-icon-neutral-secondary)",
}) => {
  return (
    <>
      {type === "text" && (
        <div className="chat-message-type">
          {hasSender && name.length > 0 && label.length > 0 && (
            <div className="chat-message-type-receivername">{`${name}: `}</div>
          )}
          <div className="chat-message-type-text">
            {Array.isArray(label) ? (
              <span>
                {label.map((item, index) => {
                  return (
                    <span>
                      {item}
                    </span>
                  );
                })}
              </span>
            ) : (
              // If label is a string, display the string
              <span>{label}</span>
            )}
          </div>
        </div >
      )}
      {
        type === "image" && (
          <div className="chat-message-type">
            {hasSender && name.length && (
              <div className="chat-message-type-receivername">{`${name}: `}</div>
            )}
            <div className="chat-message-type-file">
              <CameraIcon height={16} iconColor={iconColor} isFilled width={16} />
              Image
            </div>
          </div>
        )
      }
      {
        type === "video" && (
          <div className="chat-message-type">
            {hasSender && name.length && (
              <div className="chat-message-type-receivername">{`${name}: `}</div>
            )}
            <div className="chat-message-type-file">
              <VideoIcon height={16} iconColor={iconColor} isFilled width={16} />
              Video
            </div>
          </div>
        )
      }
      {
        type === "file" && (
          <div className="chat-message-type">
            {hasSender && name.length && (
              <div className="chat-message-type-receivername">{`${name}: `}</div>
            )}
            <div className="chat-message-type-file">
              <FileAltIcon
                height={16}
                iconColor={iconColor}
                isFilled
                width={16}
              />
              File
            </div>
          </div>
        )
      }
      {
        type === "voice" && (
          <div className="chat-message-type">
            {hasSender && name.length && (
              <div className="chat-message-type-receivername">{`${name}: `}</div>
            )}
            <div className="chat-message-type-file">
              <IconSmicrophone
                height={16}
                iconColor={iconColor}
                isFilled
                width={16}
              />
              Voice
            </div>
          </div>
        )
      }
    </>
  );
};
// define types to all props
ChatMessageType.propTypes = {
  type: PropTypes.oneOf(["text", "image", "video", "file", "voice"]),
  hasSender: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default ChatMessageType;
