import React, { useEffect, useRef, useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { saveAs } from "file-saver";
import { useSelector, useDispatch } from "react-redux";
import newsocket from "../../../components/SocketConnect.js";
import { useParams } from "react-router-dom";
import "../../../Styles/docManagement.css";
import "./ChatStyle/Chat.css";
import IcnMCrossWhite from "../../../Images/icn_m_cross_white.svg";
import icnMMminus from "../../../Images/icn_m_minus_alt.svg";
import IcnMPlus from "../../../Images/icn_m_plus_alt.svg";
import Spinners from "../../../components/Spinners/Spinners";
import PdfPreview from "../../DocumentManagement/Components/PdfPreview";
import Button from "../../../components/Button/Button";
import DownloadIcon from "../../../components/Icons/GeneralIcons/DownloadIcon";
import ShareIcon from "../../../components/Icons/GeneralIcons/ShareIcon";
import DeleteIcon from "../../../components/Icons/GeneralIcons/DeleteIcon";
import ArrowChevronRight from "../../../components/Icons/ArrowsIcon/ArrowChevronRight";
import ArrowChevronLeft from "../../../components/Icons/ArrowsIcon/ArrowChevronLeft";
import NoPreview from "../../DocumentManagement/Components/NoPreview";
import ModelPopup from "../../../components/ModalPopup/ModalPopup";

import Typography from "../../../components/Typography/Typography.js";
import {
  formatMediaPreviewMessages,
  formatAttachementMediaPreviewMessages,
} from "../CommonFunctions/AllMedia.js";

import { deletemessage } from "../../../Action";

export default function MediaPreview({
  mediaPreviewData,
  showPreview = false,
  handleClosePreview,
  mediaPreviewType,
  selectedImageVideo,
  showFileData,
  previewAttachmentDisplay,
  previewFrom = "chat",
}) {
  const { userid, type } = useParams();
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const imageRef = useRef(null);
  const pageRef = useRef(null);
  const transformWrapperRef = useRef(null); // Add a ref for TransformWrapper
  const [zoomLevel, setZoomLevel] = useState(100); // Initial zoom level at 100%
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [loader, setLoader] = useState(true);
  const [isNoPreview, setNoPreview] = useState(false);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomIntial, setZoomIntial] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [deleteModel, setDeleteModel] = useState(false);
  const [currentIndexData, setCurrentIndexData] = useState([]);
  const [currentIndexType, setCurrentIndexType] = useState("");
  const [showMediaPreviewData, setMediaPreviewData] = useState([]);

  // get image video preview data
  const getImageVideoData = useSelector((state) => {
    return state?.AllMediaPreview;
  });

  // get all messages
  const allMessages = useSelector((state) => {
    return state?.ReduChat;
  });

  // formate all data to display media
  useEffect(() => {
    if (previewFrom === "contentLibrary") {
      setMediaPreviewData(mediaPreviewData);
    } else {
      let formattedData;
      if (previewAttachmentDisplay) {
        formattedData = formatAttachementMediaPreviewMessages(mediaPreviewData);
      } else {
        formattedData = formatMediaPreviewMessages(getImageVideoData.reverse());
      }
      // Reverse the array before setting the state
      const reversedData = formattedData.slice();
      if (showPreview) {
        setMediaPreviewData(reversedData);
      }
    }
  }, [showPreview, getImageVideoData]);

  useEffect(() => {
    // Add event listener for keyboard keys
    document.addEventListener("keydown", handleKeyPress);

    // Clean up function to remove event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedImageVideo, showMediaPreviewData, showPreview, currentIndex]); // Run effect only once on component mount

  // Event listener for keyboard keys
  const handleKeyPress = (event) => {
    switch (event.keyCode) {
      case 27: // Escape key
        // Handle escape key press
        handleClosePreview();
        break;
      case 37: // Left arrow key (previous)
        // Handle left arrow key press (previous)
        if (currentIndex > 0) {
          setCurrentIndex((prevIndex) => {
            const newIndex =
              prevIndex > 0 ? prevIndex - 1 : showMediaPreviewData?.length - 1;
            return newIndex;
          });
          resetPreviewState();
        }
        break;
      case 39: // Right arrow key (next)
        if (currentIndex < showMediaPreviewData?.length - 1) {
          setCurrentIndex((prevIndex) => {
            const newIndex =
              prevIndex < showMediaPreviewData?.length - 1 ? prevIndex + 1 : 0;
            return newIndex;
          });
          resetPreviewState();
        }
        break;
      default:
        break;
    }
  };

  const resetPreviewState = () => {
    setZoomLevel(100); // Reset zoom level
    setZoomIntial(1); // Reset zoom initial
    if (videoRef.current) {
      videoRef.current.pause(); // Pause video if it exists
    }
    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform(); // Reset transform wrapper
    }
    // Any other state reset operations as needed
  };

  // set current index for slide image or video
  useEffect(() => {
    setZoomLevel(100);
    setZoomIntial(1);

    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform(); // Reset the zoom level
    }
    const currentIndexInitial = showMediaPreviewData?.findIndex((data) => {
      return data.url === selectedImageVideo;
    });
    // Make sure currentIndexInitial is not -1 (element not found) before setting the state
    if (currentIndexInitial !== -1) {
      setCurrentIndex(currentIndexInitial);
    }
  }, [selectedImageVideo, showMediaPreviewData, showPreview]);

  // handle onTransformed when Image zoomed
  const handleOnTransformed = (e) => {
    const zoomedValue = Math.round(e?.instance?.transformState.scale * 100);
    setZoomLevel(zoomedValue);
    setPosition({ x: 0, y: 0 });
  };

  // Get Current Data for current index data
  useEffect(() => {
    const currentData = showMediaPreviewData.filter(
      (data, index) => currentIndex === index
    );
    setCurrentIndexData(currentData);
    setCurrentIndexType(currentData[0]?.type);
  }, [currentIndex, showMediaPreviewData]);

  // handle previous arrow click in slider
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex =
        prevIndex > 0 ? prevIndex - 1 : showMediaPreviewData?.length - 1;
      return newIndex;
    });
    setZoomLevel(100);
    setZoomIntial(1);
    if (videoRef.current) {
      videoRef.current.pause();
    }
    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform(); // Reset the zoom level
    }
  };

  // handle next arrow click in slider
  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex =
        prevIndex < showMediaPreviewData?.length - 1 ? prevIndex + 1 : 0;
      return newIndex;
    });
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setZoomLevel(100);
    setZoomIntial(1);
    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform(); // Reset the zoom level
    }
  };

  // download Media File image video files
  const handleDownloadMedia = () => {
    if (mediaPreviewType === "media") {
      const currentData = showMediaPreviewData.filter(
        (data, index) => currentIndex === index
      );
      if (currentData[0]?.type === "image") {
        const path = new URL(currentData[0]?.url).pathname;
        const segments = path.split(".");
        const lastSegment = segments[segments.length - 1];
        const extension = lastSegment.split("?")[0];
        saveAs(currentData[0]?.url, `download.${extension}`);
      } else {
        const videoUrl = currentData[0]?.url;
        const urlSegments = videoUrl?.split(".");
        const videoExtension =
          urlSegments[urlSegments.length - 1].split("?")[0];

        // Handle video download with dynamic extension
        saveAs(videoUrl, `download.${videoExtension}`);
      }
    } else {
      const fileParts = showFileData?.fileName?.split(".");
      const fileExtension = fileParts[fileParts.length - 1];
      saveAs(showFileData?.url, `${fileParts[0]}.${fileExtension}`);
    }
  };

  // on click Delete media modal
  const onClickDeleteMedia = () => {
    setDeleteModel(true);
  };

  // on click close delete media modal
  const closeDeleteModel = () => {
    setDeleteModel(false);
  };

  // handle Delete Media func
  const handleDeleteMedia = () => {
    if (mediaPreviewType === "media") {
      const currentData = showMediaPreviewData.filter(
        (data, index) => currentIndex === index
      );
      let dataIndexImage, dataIndexVideo;
      const mediaData = getImageVideoData.filter((data) => {
        let indexImage = data.images.findIndex((image) => {
          return image.url === currentData[0].url;
        });

        let indexVideo = data.videos.findIndex((video) => {
          return video.url === currentData[0].url;
        });

        if (indexImage > -1 || indexVideo > -1) {
          dataIndexImage = indexImage;
          dataIndexVideo = indexVideo;
          return data;
        }
      });
      let messageFullDetail = allMessages[userid]
        ? allMessages[userid].filter((msg) => msg._id === mediaData[0]._id)[0]
        : {};
      let callDeleteMessage = false;
      if (
        messageFullDetail &&
        messageFullDetail.message_type &&
        !messageFullDetail.message_type.includes("document") &&
        !messageFullDetail.message_type.includes("text") &&
        !messageFullDetail.message_type.includes("url")
      ) {
        if (
          dataIndexImage > -1 &&
          !messageFullDetail.message_type.includes("video") &&
          messageFullDetail.images.length === 1
        ) {
          callDeleteMessage = true;
        } else if (
          dataIndexVideo > -1 &&
          !messageFullDetail.message_type.includes("image") &&
          messageFullDetail.videos.length === 1
        ) {
          callDeleteMessage = true;
        }
      }
      if (callDeleteMessage) {
        dispatch(deletemessage(mediaData[0]._id));
        dispatch({ type: "DELETEMEDIARECORD", payload: mediaData[0]._id });
        dispatch({ type: "DELETEURLRECORD", payload: mediaData[0]._id });
        dispatch({ type: "DELETEFILEFROMLIST", payload: mediaData[0]._id });
        newsocket.emit("deletechat", {
          chatId: userid,
          messageId: mediaData[0]._id,
          userId: localStorage.getItem("userid"),
          type: type,
        });
      } else {
        if (mediaData.length > 0) {
          newsocket.emit("edit", {
            messageId: mediaData[0]._id,
            message: undefined,
            recipient: userid,
            sender: localStorage.getItem("userid"),
            type: type,
            taggedUserId: undefined,
            images: dataIndexImage,
            videos: dataIndexVideo,
          });
          dispatch({ type: "DELETEMEDIA", payload: currentData[0].url });
        }
      }
      setDeleteModel(false);
    }
  };

  // handle click close before that pause video
  const handleClosePreviewManually = () => {
    let videoId = document.getElementById(`${currentIndex}-myVideo`);

    // Check if videoId is not null before attempting to pause
    if (videoId) {
      videoId.pause();
    }

    // Check if videoRef is valid before attempting to pause
    if (videoRef.current) {
      videoRef.current.pause();
    }
    // Call the onClose callback provided by the parent
    handleClosePreview();
  };

  return (
    <>
      <div className={`${showPreview ? "modal-fadein" : "modal-fadeout"}`}>
        <div className="desk-globle-model-box globle-model-box doc-file-modal-only">
          <div
            className={`desk-globle-model-box-inner doc-modal-only ${showPreview ? "sidebar" : ""
              } `}
          >
            <div className="doc-modal-only-left">
              <TransformWrapper
                ref={transformWrapperRef}
                doubleClick={{
                  disabled:
                    currentIndexType === "image" || currentIndexType === "pdf"
                      ? false
                      : true,
                }}
                wheel={{ disabled: true }}
                minScale={1}
                maxScale={3}
                initialScale={zoomIntial}
                onTransformed={handleOnTransformed}
                disabled={
                  currentIndexType === "image" || currentIndexType === "pdf"
                    ? false
                    : true
                }
              >
                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                  <>
                    <div
                      className="doc-modal-only-left-top"
                      onDoubleClick={(event) => {
                        if (currentIndexType === "video") {
                          return event.preventDefault();
                        }
                      }}
                    >
                      <div className="doc-modal-only-left-top-left">
                        <button
                          className="doc-modal-icon"
                          onClick={handleClosePreviewManually}
                        >
                          <img src={IcnMCrossWhite} />
                        </button>

                        {(mediaPreviewType === "file" ||
                          previewFrom === "contentLibrary") && (
                            <h5 className="doc-modal-filename">
                              {showFileData?.fileName ||
                                showFileData?.name !== undefined
                                ? showFileData?.fileName || showFileData?.name
                                : ""}
                            </h5>
                          )}
                      </div>
                      {((mediaPreviewType === "media" &&
                        (currentIndexType === "image" ? true : false)) ||
                        showFileData?.fileName ||
                        showFileData?.url?.split(".").pop() === "pdf") && (
                          <div className="doc-modal-only-left-top-center">
                            <button
                              className="doc-modal-icon"
                              onClick={() => {
                                zoomOut(0.1);
                              }}
                            >
                              <img src={icnMMminus} />
                            </button>
                            <span className="doc-modal-cont">{zoomLevel}%</span>
                            <button
                              className="doc-modal-icon"
                              onClick={() => {
                                zoomIn(0.1);
                              }}
                            >
                              <img src={IcnMPlus} />
                            </button>
                          </div>
                        )}

                      <div className="doc-modal-only-left-top-right chat-preview-header-btn">
                        {!previewAttachmentDisplay && (
                          <>
                            <Button
                              iconColor="FFF"
                              label=""
                              size="small"
                              variant="ghost"
                              hasText={false}
                              toolTipText="Download"
                              elementIcon={
                                <DownloadIcon
                                  height={24}
                                  iconColor="#FFFFFF"
                                  width={24}
                                />
                              }
                              handleClick={() => handleDownloadMedia()}
                            />
                            {/* <Button
                              iconColor="FFF"
                              label="Button"
                              size="small"
                              variant="ghost"
                              toolTipText="Share"
                              hasText={false}
                              elementIcon={
                                <ShareIcon
                                  height={24}
                                  iconColor="#FFFFFF"
                                  width={24}
                                />
                              }
                            /> */}
                            {currentIndexData[0]?.sender ===
                              localStorage.getItem("userid") &&
                              mediaPreviewType !== "file" && (
                                <Button
                                  iconColor="FFF"
                                  label="Button"
                                  size="small"
                                  variant="ghost"
                                  hasText={false}
                                  toolTipText="Delete"
                                  elementIcon={
                                    <DeleteIcon
                                      height={24}
                                      iconColor="#FFFFFF"
                                      width={24}
                                    />
                                  }
                                  handleClick={onClickDeleteMedia}
                                />
                              )}
                          </>
                        )}
                      </div>
                    </div>
                    {loader && (
                      <div className="preview-loader">
                        <Spinners type="white" />
                      </div>
                    )}
                    <div
                      className={`chat-slider-preview ${mediaPreviewType === "media"
                        ? "doc-preview-img"
                        : "doc-preview-media"
                        }`}
                    >
                      {mediaPreviewType === "media" &&
                        showMediaPreviewData?.length > 0 && (
                          <>
                            <>
                              {currentIndex !== 0 && (
                                <button
                                  className="chat-preview-slider-button chat-slider-left-arrow"
                                  onClick={handlePrev}
                                >
                                  <ArrowChevronLeft
                                    bold
                                    height={24}
                                    iconColor="#fff"
                                    width={24}
                                  />
                                </button>
                              )}
                              {currentIndex !==
                                showMediaPreviewData?.length - 1 && (
                                  <button
                                    className="chat-preview-slider-button chat-slider-right-arrow"
                                    onClick={handleNext}
                                  >
                                    <ArrowChevronRight
                                      bold
                                      height={24}
                                      iconColor="#fff"
                                      width={24}
                                    />
                                  </button>
                                )}
                            </>
                          </>
                        )}
                      <TransformComponent className="custom-transform-component">
                        {mediaPreviewType === "file" ? (
                          <div
                            className="doc-modal-only-left-sldier"
                            ref={pageRef}
                          >
                            {showFileData?.fileName &&
                              showFileData?.fileName.split(".").pop() ===
                              "pdf" && (
                                <PdfPreview
                                  setPageNumber={setPageNumber}
                                  loader={loader}
                                  pageNumber={pageNumber}
                                  numPages={numPages}
                                  setLoader={setLoader}
                                  setNumPages={setNumPages}
                                  zoomLevel={zoomLevel}
                                  url={
                                    showFileData.url.startsWith("https")
                                      ? showFileData.url
                                      : process.env.REACT_APP_AWS_IMG_VID_PATH +
                                      showFileData.url
                                  }
                                  setNoPreview={setNoPreview}
                                />
                              )}

                            {showFileData?.url &&
                              showFileData?.url.split(".").pop() === "pdf" && (
                                <PdfPreview
                                  setPageNumber={setPageNumber}
                                  loader={loader}
                                  pageNumber={pageNumber}
                                  numPages={numPages}
                                  setLoader={setLoader}
                                  setNumPages={setNumPages}
                                  zoomLevel={zoomLevel}
                                  url={
                                    showFileData.url.startsWith("https")
                                      ? showFileData.url
                                      : process.env.REACT_APP_AWS_IMG_VID_PATH +
                                      showFileData.url
                                  }
                                  setNoPreview={setNoPreview}
                                />
                              )}
                          </div>
                        ) : mediaPreviewType === "media" ? (
                          <div className="chat-preview-slider-inner">
                            <div className="chat-preview-slider-content">
                              {showMediaPreviewData.map((data, index) => (
                                <div
                                  key={index}
                                  className={`chat-preview-slider-item ${index === currentIndex ? "active" : ""
                                    }`}
                                >
                                  {data.type === "image" && (
                                    <img
                                      src={
                                        previewAttachmentDisplay
                                          ? data.url
                                          : data.url.startsWith("https")
                                            ? data.url
                                            : process.env
                                              .REACT_APP_AWS_IMG_VID_PATH +
                                            data.url
                                      }
                                      alt={`Image ${index + 1}`}
                                      onError={() => {
                                        setLoader(false);
                                        setNoPreview(true);
                                      }}
                                      ref={imageRef}
                                      style={{ userSelect: "none" }}
                                      onLoad={() => setLoader(false)}
                                    />
                                  )}
                                  {data.type === "video" && (
                                    <>
                                      {data.url.length === 0 ? (
                                        <div className="preview-loader">
                                          <Spinners type="white" />
                                        </div>
                                      ) : (
                                        <>
                                          <img
                                            src={data.thumbnail}
                                            alt={`Video Thumbnail ${index + 1}`}
                                            style={{
                                              display: loader
                                                ? "block"
                                                : "none",
                                            }}
                                          />
                                          <video
                                            ref={videoRef}
                                            onError={() => {
                                              setNoPreview(true);
                                              setLoader(false);
                                            }}
                                            id={`${index}-myVideo`}
                                            style={{
                                              display: loader
                                                ? "none"
                                                : "block",
                                            }}
                                            onLoad={() => {
                                              setLoader(false);
                                            }}
                                            controls
                                            src={data.url}
                                            preload={true}
                                            onDoubleClick={(event) =>
                                              event.preventDefault()
                                            }
                                            onLoadedData={() => {
                                              setLoader(false);
                                            }}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </TransformComponent>

                      {showFileData &&
                        showFileData?.fileName?.split(".").pop() !== "pdf" &&
                        showFileData?.url?.split(".").pop() !== "pdf" &&
                        mediaPreviewType === "file" && (
                          <NoPreview
                            handleDownload={() => {
                              const fileUrl = showFileData.url.startsWith(
                                "https"
                              )
                                ? showFileData.url
                                : process.env.REACT_APP_AWS_IMG_VID_PATH +
                                showFileData.url;

                              saveAs(
                                fileUrl,
                                showFileData?.fileName ||
                                showFileData?.url?.split(".")
                              );
                            }}
                            data={
                              showFileData.url &&
                                showFileData.url.startsWith("https")
                                ? showFileData.url
                                : process.env.REACT_APP_AWS_IMG_VID_PATH +
                                showFileData.url
                            }
                            restricted={false}
                            isNoPreview={isNoPreview}
                            setNoPreview={setNoPreview}
                            handleClosePreview={handleClosePreviewManually}
                            showFileData={showFileData}
                          />
                        )}

                      {showFileData?.showFileData?.fileName ||
                        (showFileData?.url?.split(".").pop() === "pdf" &&
                          mediaPreviewType === "file" &&
                          !loader && (
                            <div className="doc-modal-only-left-page">
                              <span className="doc-page-text">Page</span>
                              <span className="doc-page-cont">
                                {pageNumber}
                              </span>
                              <span className="doc-page-text">of</span>
                              <span className="doc-page-cont2">{numPages}</span>
                            </div>
                          ))}
                    </div>
                  </>
                )}
              </TransformWrapper>
            </div>
          </div>
        </div>
      </div>

      <ModelPopup
        isFooter={true}
        isHeader={false}
        modelSize="480"
        isOpen={deleteModel}
        buttonText="Cancel"
        buttonText1="Delete"
        onClose={closeDeleteModel}
        handleClick2ndButton={handleDeleteMedia}
        btn2variant="danger"
        className="chat-preview-modal-delete"
      >
        <div className="channel-group-wrapper">
          <Typography variant="h3">Delete media</Typography>
          <Typography variant="p">
            Are you sure you want to delete this media?
          </Typography>
        </div>
      </ModelPopup>
    </>
  );
}
