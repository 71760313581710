import React, { useState } from "react";
import "./Styles/videoSmallCard.css";
import { Link } from "react-router-dom";
import ImgVideo from "../../Images/dummy.jpg";
import Button from "../Button/Button";
import DotsIcon from "../Icons/GeneralIcons/DotsIcon";
import DropDownItem from "../DropDownItem/DropDownItem";
import PropTypes from "prop-types";

const VideoSmallCard = ({
  id = "",
  extraClass = "",
  linkURL = "javascript:void(0)",
  name = "Omnichannel Marketing: Leveling Up Beyond Amazon － Corin Prystal & Jay Hunter － MDS Inspire 2024",
  category = "Category",
  views = "1 year ago",
  uploaded = "10 views",
  duration = "10:00",
  hasDotMenu = true,
  thumbnail = "",
  handleActionClick,
  actions,
  handleSelect,
  activeId,
  dropPos = false,
  handleMouseLeave,
}) => {
  return (
    <div
      className={`video-small-wrapper position-relative ${hasDotMenu ? "has-dotmenu" : ""
        }`}
      onMouseLeave={handleMouseLeave}
    >
      <Link to={linkURL} className={`video-small-card ${extraClass}`}>
        <div className="video-small-thumb-img video-img-type-small position-relative">
          <img src={thumbnail} className={`thumb-image`} alt="thumb-img" />
          <span className="video-duration">{duration}</span>
        </div>
        <div className="video-small-details-wrapper">
          <h6 className="video-small-card-title">{name}</h6>
          <p className="video-type-text">{category}</p>
          <div className="video-details-view-date">
            <span className="video-view-text">{views}</span>
            <span className="video-uploded-date">{uploaded}</span>
          </div>
          {/* <p className="download-video-text">Downloading... (74%)</p>
          <p className="download-paused-text">Download paused</p> */}
        </div>
      </Link>
      <Button
        variant="ghost"
        extraClass="video-dot-btn ml-auto align-self-start"
        size={"small"}
        handleClick={() => {
          handleActionClick(id);
        }}
        hasText={false}
        elementIcon={<DotsIcon />}
      />
      {activeId === id && (
        <div>
          <DropDownItem
            extraClass={`video-dropdown-wrapper ${dropPos ? "top" : ""}`}
            isIcon
            id="actions"
            size="small"
            options={actions}
            onSelect={handleSelect}
          />
        </div>
      )}
    </div>
  );
};

VideoSmallCard.propTypes = {
  id: PropTypes.string,
  extraClass: PropTypes.string,
  linkURL: PropTypes.string,
  name: PropTypes.string,
  category: PropTypes.string,
  views: PropTypes.string,
  uploaded: PropTypes.string,
  duration: PropTypes.string,
  hasDotMenu: PropTypes.bool,
  thumbnail: PropTypes.string,
  handleActionClick: PropTypes.func,
  actions: PropTypes.array,   
  handleSelect : PropTypes.func,
  activeId: PropTypes.string,
  dropPos: PropTypes.string,
  handleMouseLeave: PropTypes.func,
};

export default VideoSmallCard;
