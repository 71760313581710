const alltypers = {};

const ReduTypers = (state = alltypers, action) => {
  switch (action.type) {
    case "ADDTYPER":
      if (action.payload.sender !== localStorage.getItem("userid")) {
        if (action.payload.type === "user") {
          return {
            ...state,
            [action.payload.sender]: {
              type: "user",
              username: { [action.payload.sender]: action.payload.sendername }
            },
          };
        } else {
          return {
            ...state,
            [action.payload.recipient]: {
              type: "group",
              username:
                state[action.payload.recipient] !== undefined && state[action.payload.recipient].username !== undefined
                  ? {
                    ...state[action.payload.recipient].username,
                    [action.payload.sender]: action.payload.sendername,
                  }
                  : {
                    [action.payload.sender]: action.payload.sendername
                  },
            },
          };
        }
      } else {
        return state;
      }

    case "REMOVETYPER":
      if (action.payload.type === "user") {
        var obj = {}
        for (var key in state) {
          if (key !== action.payload.sender)
            obj = { ...obj, [key]: state[key] }
        }
        return { ...obj };

      } else {
        var obj = {}
        for (var key in state) {
          if (key === action.payload.recipient) {
            var arr = {}
            for (var i in state[key].username) {
              if (i !== action.payload.sender) {
                arr = { ...arr, [i]: state[key].username[i] };
              }
            }
            obj = { ...obj, [key]: { ...state[key], username: arr } }
          }
          else
            obj = { ...obj, [key]: state[key] }
        }
        return { ...obj };
      }
    case 'LOGOUT':
      return alltypers;
    default:
      return state;
  }
};

export default ReduTypers;
