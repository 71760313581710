import { useEffect, useState, useRef, createRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import millify from "millify";
import InputEmoji from "react-input-emoji";
import Services from "../../Services/auth.service";
import CommentReply from "./CommentReply";
import File1 from "../../Images/image1.png";
import Dotted from "../../Images/dotted.svg";
import Delete2 from "../../Images/delete2.svg";
import fillheart from "../../Images/fillheart.svg";
import replyimg from "../../Images/reply-line.svg";
import Heart from "../../Images/heart.svg";
import timeimg from "../../Images/time-line.svg";
import loading from "../../Images/lodemorecmt.gif";
import cmtprocess from "../../Images/cmtprocess.gif";
import deletecmtloader from "../../Images/deletecmtloader.gif";
const Comments = ({
  videoId,
  allcomments,
  setallcomments,
  totalcommts,
  selectedVideoId,
  setselectedCmt,
  selectedCmt,
}) => {
  const history = useNavigate();
  const inputRef = useRef(null);
  const responRef = useRef([]);
  const USER_ID = localStorage.getItem("adminuserid");
  const [comment, setComment] = useState("");
  const [reply, setreply] = useState({});
  const [rereply, setrereply] = useState({});
  const [morecmt, setmorecmt] = useState(1);
  const [allusers, setallusers] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [cmtloader, setcmtloader] = useState(false);
  const [cmtinprocess, setcmtinprocess] = useState(false);
  const [replyinprocess, setreplyinprocess] = useState({});
  const [deletecmtInprocess, setdeletecmtInprocess] = useState({});
  // let totalcmt = totalcommts;
  const [totalcmt, settotalcmt] = useState(0);
  useEffect(() => {
    responRef.current = allcomments.map(
      (cmt, i) => responRef.current[i] ?? createRef()
    );
  }, [allcomments]);
  useEffect(() => {
    Services.getalluseremailname_admin()
      .then((res) => {
        if (res.data.status) {
          setallusers(res.data.data);
          var arr = [];
          for (var i = 0; i < res.data.data.length; i++) {
            arr[i] =
              res.data.data[i].first_name + " " + res.data.data[i].last_name;
          }
          setSuggestionList(arr);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getallcomments_videoadmin({ videoid: selectedVideoId, page: 1 })
      .then((res) => {
        if (res.data.status) {
          settotalcmt(res.data.data[0].totalComments);
        } else {
          settotalcmt(0);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  window.addEventListener("scroll", () => {
    var max = Math.ceil(document.documentElement.offsetHeight + 10);
    var min = Math.floor(document.documentElement.offsetHeight - 10);
    if (
      window.innerHeight + document.documentElement.scrollTop > min &&
      window.innerHeight + document.documentElement.scrollTop < max
    ) {
      console.log("i'm in");
      if (!cmtloader && morecmt < Math.ceil(totalcmt / 10)) {
        setcmtloader(true);
      }
    }
  });
  useEffect(() => {
    console.log(cmtloader, morecmt, totalcommts, totalcmt);
    if (cmtloader) {
      handlemorecmt();
    }
  }, [cmtloader]);
  useEffect(() => {
    settotalcmt(totalcommts);
  }, [totalcommts]);
  const addcomment = (videoid, commid) => {
    if (commid.length === 0 && comment.length > 0) {
      let data = {
        videoId: videoid,
        data: {
          content: comment,
          userId: USER_ID,
          user_type: "adminuser",
          createdAt: new Date(),
        },
      };
      setcmtinprocess(true);

      Services.addcomment_videoadmin(data)
        .then((res) => {
          if (res.data.status) {
            setComment("");
            setallcomments([
              { ...res.data.data, likes: { count: 0, list: [] } },
              ...allcomments,
            ]);
            settotalcmt(totalcmt + 1);
          }
          setcmtinprocess(false);
        })
        .catch((e) => {
          setcmtinprocess(false);
          console.log(e);
        });
    } else {
      if (
        (reply[commid] !== undefined && reply[commid].length > 0) ||
        (rereply[commid] !== undefined && rereply[commid].length > 0)
      ) {
        setreplyinprocess({ ...replyinprocess, [commid]: true });

        var data = {
          videoId: videoid,
          commId: commid,
          user_type: "adminuser",
          content:
            reply[commid] !== undefined && reply[commid].length > 0
              ? reply[commid]
              : rereply[commid],
          createdAt: new Date(),
        };
        Services.createreply_adminvideo(data)
          .then((res) => {
            setreply({ ...reply, [commid]: "" });
            setrereply({ ...rereply, [commid]: "" });
            if (res.data.status) {
              var data = allcomments.map((comm) => {
                const USERID = localStorage.getItem("adminuserid");
                var reply = [];
                if (comm._id === commid) {
                  reply = [
                    { ...res.data.data, likes: { count: 0, list: [] } },
                    ...comm.comments,
                  ];
                } else {
                  reply = [...comm.comments];
                }
                return {
                  ...comm,
                  comments: reply,
                };
              });
              setallcomments(data);
            }
            setreplyinprocess({ ...replyinprocess, [commid]: false });
          })
          .catch((e) => {
            setreplyinprocess({ ...replyinprocess, [commid]: false });
            console.log(e);
          });
      }
    }
  };
  const fetchcomments = () => {
    Services.getallcomments_videoadmin({
      videoid: videoId,
      page: 1,
      limit: allcomments.length,
    })
      .then((res) => {
        if (res.data.status) {
          if (
            res.data.data[0].comment !== undefined &&
            res.data.data[0].comment.length > 0
          ) {
            var data = res.data.data[0].comment.map((comm) => {
              var reply = [];
              var rereply = [];
              if (comm.comments.length > 0) {
                reply = comm.comments.map((reply) => {
                  if (reply.comments.length > 0) {
                    rereply = reply.comments.map((r) => {
                      return {
                        ...r,
                        likes: { count: r.likes.length, list: r.likes },
                      };
                    });
                  }
                  return {
                    ...reply,
                    likes: { count: reply.likes.length, list: reply.likes },
                    comments: rereply,
                  };
                });
              }
              return {
                ...comm,
                likes: { count: comm.likes.length, list: comm.likes },
                comments: reply,
              };
            });
            setallcomments(data);
            settotalcmt(res.data.data[0].totalComments);
            // totalcmt = res.data.data[0].totalComments
          } else {
            settotalcmt(0);
            // totalcmt = 0
            setallcomments([]);
          }
        } else {
          settotalcmt(0);
          // totalcmt = 0
          setallcomments([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const likecomment = (videoid, commid, type) => {
    var data = {
      videoId: videoid,
      commId: commid,
    };
    var data = allcomments.map((comm) => {
      const USERID = localStorage.getItem("adminuserid");
      var reply = [];
      var rereply = [];
      if (comm.comments.length > 0) {
        if (type === "reply") {
          reply = comm.comments.map((reply) => {
            if (reply._id === commid) {
              if (reply.likes.list.includes(USERID)) {
                return {
                  ...reply,
                  likes: {
                    count: reply.likes.count - 1,
                    list: reply.likes.list.filter((lik) => {
                      if (lik !== USERID) return lik;
                    }),
                  },
                };
              } else {
                return {
                  ...reply,
                  likes: {
                    count: reply.likes.count + 1,
                    list:
                      reply.likes.count > 0
                        ? [...reply.likes.list, USERID]
                        : [USERID],
                  },
                };
              }
            } else {
              return {
                ...reply,
                likes: {
                  count: reply.likes.count,
                  list: reply.likes.list,
                },
              };
            }
          });
        } else {
          reply = [...comm.comments];
        }
      }
      if (type === "cmt" && comm._id === commid) {
        if (comm.likes.count > 0 && comm.likes.list.includes(USERID)) {
          return {
            ...comm,
            likes: {
              count: comm.likes.count - 1,
              list: comm.likes.list.filter((lik) => {
                if (lik !== USERID) return lik;
              }),
            },
            comments: reply,
          };
        } else {
          return {
            ...comm,
            likes: {
              count: comm.likes.count > 0 ? comm.likes.count + 1 : 1,
              list:
                comm.likes.count > 0 ? [...comm.likes.list, USERID] : [USERID],
            },
            comments: reply,
          };
        }
      } else {
        return {
          ...comm,
          likes: { count: comm.likes.count, list: comm.likes.list },
          comments: reply,
        };
      }
    });
    console.log(data);
    setallcomments(data);
    Services.likecomment_adminvideo(data);
  };
  const deletecmt = (videoid, comtid) => {
    var data = {
      videoId: videoid,
      commId: comtid,
    };
    setdeletecmtInprocess({ ...deletecmtInprocess, [comtid]: true });

    Services.deletecomment_adminvideo(data)
      .then((res) => {
        fetchcomments();
        setdeletecmtInprocess({ ...deletecmtInprocess, [comtid]: false });
      })
      .catch((e) => {
        console.log(e);
        setdeletecmtInprocess({ ...deletecmtInprocess, [comtid]: false });
      });
  };
  const handlemorecmt = () => {
    if (morecmt < Math.ceil(totalcmt / 10)) {
      Services.getallcomments_videoadmin({
        videoid: videoId,
        page: morecmt + 1,
      })
        .then((res) => {
          setcmtloader(false);
          if (res.data.status) {
            var data = res.data.data[0].comment.map((comm) => {
              var reply = [];
              var rereply = [];
              if (comm.comments.length > 0) {
                reply = comm.comments.map((reply) => {
                  if (reply.comments.length > 0) {
                    rereply = reply.comments.map((r) => {
                      return {
                        ...r,
                        likes: { count: r.likes.length, list: r.likes },
                      };
                    });
                  }
                  return {
                    ...reply,
                    likes: { count: reply.likes.length, list: reply.likes },
                    comments: rereply,
                  };
                });
              }
              return {
                ...comm,
                likes: { count: comm.likes.length, list: comm.likes },
                comments: reply,
              };
            });
            setallcomments([...allcomments, ...data]);
            settotalcmt(res.data.data[0].totalComments);
            // totalcmt = res.data.data[0].totalComments
          } else {
            setallcomments([]);
            settotalcmt(0);
          }
        })
        .catch((e) => {
          setcmtloader(false);
          console.log(e);
        });
      setmorecmt(morecmt + 1);
    }
  };
  function onChange(text) {
    setComment(text);
    const regexp = /@[a-zA-Z0-9]*$/;
    if (regexp.test(text.split(" ").pop())) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }
  function focusInput() {
    inputRef.current.focus();
  }
  return (
    <>
      <div
        className="comment-main-title"
        onClick={() => {
          selectedCmt ? setselectedCmt(false) : setselectedCmt(true);
        }}
      >
        <span>Comments ({totalcmt > 0 ? millify(totalcmt) : 0})</span>
      </div>
      <div className="newsfeed-post-right-write">
        <div className="newsfeed-post-right-write-left">
          {localStorage.getItem("userprofile") &&
          localStorage.getItem("userprofile").length ? (
            <img
              src={
                localStorage.getItem("userprofile") &&
                localStorage.getItem("userprofile").length
                  ? localStorage.getItem("userprofile")
                  : File1
              }
            />
          ) : (
            <div class="cstm-avat-box">
              {localStorage.getItem("username")
                ? localStorage.getItem("username").charAt(0)
                : ""}
            </div>
          )}
        </div>
        <div className="newsfeed-post-right-write-right">
          <div className="newsfeed-write-your-comments-tx">
            <InputEmoji
              value={comment}
              // id={post._id}
              ref={inputRef}
              onChange={(text) => {
                onChange(text);
              }}
              onEnter={(text) => {
                if (!cmtinprocess) addcomment(videoId, "");
              }}
              placeholder={comment.length === 0 ? "Write your comments..." : ""}
            />
            <span
              className="wyc-send"
              onClick={() => {
                if (!cmtinprocess) addcomment(videoId, "");
              }}
            >
              {cmtinprocess ? (
                <img src={cmtprocess} className="dis-loader-cmt" />
              ) : (
                <i className="fa fa-paper-plane"></i>
              )}
            </span>
          </div>

          {showSuggestions && (
            <Suggestions
              inputValue={comment}
              suggestionList={suggestionList}
              applyMention={onChange}
              focusInput={focusInput}
              setShowSuggestions={setShowSuggestions}
              showSuggestions={showSuggestions}
            />
          )}
        </div>
      </div>

      {allcomments !== undefined && allcomments.length > 0 ? (
        <>
          <div className="comment-main">
            {allcomments.map((coment, index) => {
              // cntcmt++;
              return coment.userId !== undefined && coment.userId !== null ? (
                <div className="comment-main-box">
                  <div className="comment-main-box-left">
                    {" "}
                    <img
                      src={
                        coment.userId.profileImg !== undefined &&
                        coment.userId.profileImg.length > 0
                          ? coment.userId.profileImg
                          : File1
                      }
                    />
                  </div>
                  <div className="comment-main-box-right">
                    <div className="comment-main-box-right-box">
                      <div className="comment-main-box-right-box-name">
                        <div className="comment-main-box-right-box-name-name">
                          {coment.user_type !== "adminuser" &&
                          coment.userId !== undefined &&
                          coment.userId !== null
                            ? coment.userId.first_name +
                              " " +
                              coment.userId.last_name
                            : coment.user_type === "adminuser" &&
                              coment.userId !== undefined &&
                              coment.userId !== null &&
                              coment.userId.first_name !== undefined &&
                              coment.userId.first_name !== null
                            ? `${coment.userId.first_name} ${coment.userId.last_name}`
                            : ""}
                        </div>
                        <div className="comment-main-box-right-box-name-dotted">
                          {coment.userId && coment.userId._id === USER_ID ? (
                            <div className="newfeed-dp cstm-dtl-poll">
                              <span
                                className="cstm-poll-ad"
                                data-toggle="dropdown"
                              >
                                <img
                                  src={
                                    deletecmtInprocess[coment._id] !== true
                                      ? Dotted
                                      : deletecmtloader
                                  }
                                  className={
                                    deletecmtInprocess[coment._id] !== true
                                      ? ""
                                      : "delete-cmt-loader"
                                  }
                                />
                              </span>
                              <div className="dropdown-menu cstm-edit-delete-poll">
                                <ul>
                                  <li
                                    onClick={() => {
                                      if (
                                        deletecmtInprocess[coment._id] !== true
                                      )
                                        deletecmt(videoId, coment._id);
                                    }}
                                  >
                                    <a>
                                      <img src={Delete2} />
                                      Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="comment-main-box-right-box-cont">
                        {coment.content.split(" ").map((word, index) => {
                          var at_index;
                          var sec_index;
                          coment.content.split(" ").map((w, ind) => {
                            if (
                              w.charAt(0) === "@" &&
                              suggestionList.includes(
                                w.replace("@", "") +
                                  " " +
                                  coment.content.split(" ")[ind + 1]
                              )
                            ) {
                              at_index = ind;
                              sec_index = ind + 1;
                              console.log(sec_index, "setting sec_ind");
                            }
                          });
                          if (sec_index !== index) {
                            if (
                              word.charAt(0) === "@" &&
                              suggestionList.includes(
                                word.replace("@", "") +
                                  " " +
                                  coment.content.split(" ")[index + 1]
                              )
                            ) {
                              return (
                                <b
                                  onClick={() => {
                                    var uid = allusers.filter((user) => {
                                      if (
                                        user.first_name +
                                          " " +
                                          user.last_name ===
                                        word.replace("@", "") +
                                          " " +
                                          coment.content.split(" ")[index + 1]
                                      )
                                        return user;
                                    })[0]._id;
                                    history(`/settings/edit`);
                                  }}
                                >
                                  {word +
                                    " " +
                                    coment.content.split(" ")[index + 1]}{" "}
                                </b>
                              );
                            } else if (
                              /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(
                                word
                              )
                            ) {
                              return (
                                <a
                                  onClick={() => {
                                    var time = moment(word, "mm:ss").diff(
                                      moment().startOf("day"),
                                      "seconds"
                                    );
                                    history(
                                      `/videos/${selectedVideoId}/${time}`
                                    );
                                  }}
                                >
                                  {word}{" "}
                                </a>
                              );
                            } else {
                              return <>{word} </>;
                            }
                          } else {
                            return <></>;
                          }
                        })}
                      </div>
                    </div>
                    <div className="comment-main-box-right-icon">
                      <div className="comment-main-box-right-icon-left">
                        <span
                          className="newfeed-img-name"
                          onClick={() => {
                            likecomment(videoId, coment._id, "cmt");
                          }}
                        >
                          {" "}
                          {coment.likes.list.includes(USER_ID) ? (
                            <img src={fillheart} />
                          ) : (
                            <img src={Heart} />
                          )}
                          {millify(coment.likes.count)} Likes
                        </span>
                        <span
                          className="newfeed-img-name"
                          onClick={() => {
                            document.getElementById(coment._id).style.display =
                              document.getElementById(coment._id).style
                                .display === "block"
                                ? "none"
                                : "block";
                          }}
                        >
                          {" "}
                          <img src={replyimg} />
                          {millify(coment.comments.length)} Response
                        </span>
                      </div>
                      <div className="comment-main-box-right-icon-right">
                        <span className="comment-time-st">
                          <img src={timeimg} />{" "}
                          {moment(coment.createdAt).fromNow()}
                        </span>
                      </div>
                    </div>
                    <div className="comment-main-box-right-recmt">
                      <div id={coment._id} style={{ display: "none" }}>
                        <div className="newsfeed-post-right-write2">
                          <div className="newsfeed-post-right-write-left">
                            {localStorage.getItem("userprofile") &&
                            localStorage.getItem("userprofile").length ? (
                              <img
                                src={
                                  localStorage.getItem("userprofile") &&
                                  localStorage.getItem("userprofile").length
                                    ? localStorage.getItem("userprofile")
                                    : File1
                                }
                              />
                            ) : (
                              <div class="cstm-avat-box">
                                {localStorage.getItem("username")
                                  ? localStorage.getItem("username").charAt(0)
                                  : ""}
                              </div>
                            )}
                          </div>
                          <CommentReply
                            allcomments={allcomments}
                            coment={coment}
                            reply={reply}
                            setreply={setreply}
                            index={index}
                            videoId={videoId}
                            addcomment={addcomment}
                            cmtinprocess={cmtinprocess}
                            suggestionList={suggestionList}
                            replyinprocess={replyinprocess}
                          />
                        </div>

                        {coment.comments.length > 0
                          ? coment.comments.map((cmt) => {
                              return (
                                <>
                                  <div className="ss-cstm-clss">
                                    <div className="comment-main-box-right-recmt-left">
                                      {" "}
                                      <img
                                        src={
                                          cmt.userId &&
                                          cmt.userId.profileImg &&
                                          cmt.userId.profileImg.length > 0
                                            ? cmt.userId.profileImg
                                            : File1
                                        }
                                      />
                                    </div>
                                    <div className="comment-main-box-right-recmt-right">
                                      <div className="comment-main-box-right-box">
                                        <div className="comment-main-box-right-box-name">
                                          <div className="comment-main-box-right-box-name-name">
                                            {cmt.user_type !== "adminuser"
                                              ? cmt.userId
                                                ? cmt.userId.first_name +
                                                  " " +
                                                  cmt.userId.last_name
                                                : ""
                                              : cmt.userId
                                              ? `${cmt.userId.first_name} ${cmt.userId.last_name}`
                                              : ""}
                                          </div>
                                          {cmt.userId &&
                                          cmt.userId._id === USER_ID ? (
                                            <div className="comment-main-box-right-box-name-dotted">
                                              <div className="newfeed-dp cstm-dtl-poll">
                                                <span
                                                  className="cstm-poll-ad"
                                                  data-toggle="dropdown"
                                                >
                                                  <img
                                                    src={
                                                      deletecmtInprocess[
                                                        cmt._id
                                                      ] !== true
                                                        ? Dotted
                                                        : deletecmtloader
                                                    }
                                                    className={
                                                      deletecmtInprocess[
                                                        cmt._id
                                                      ] !== true
                                                        ? ""
                                                        : "delete-cmt-loader"
                                                    }
                                                  />
                                                </span>
                                                <div className="dropdown-menu cstm-edit-delete-poll">
                                                  <ul>
                                                    <li
                                                      onClick={() => {
                                                        if (
                                                          !deletecmtInprocess[
                                                            cmt._id
                                                          ]
                                                        )
                                                          deletecmt(
                                                            videoId,
                                                            cmt._id
                                                          );
                                                      }}
                                                    >
                                                      <a>
                                                        <img src={Delete2} />
                                                        Delete
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div className="comment-main-box-right-box-cont">
                                            {cmt.content
                                              .split(" ")
                                              .map((word, index) => {
                                                var at_index;
                                                var sec_index;
                                                cmt.content
                                                  .split(" ")
                                                  .map((w, ind) => {
                                                    if (
                                                      w.charAt(0) === "@" &&
                                                      suggestionList.includes(
                                                        w.replace("@", "") +
                                                          " " +
                                                          cmt.content.split(
                                                            " "
                                                          )[ind + 1]
                                                      )
                                                    ) {
                                                      at_index = ind;
                                                      sec_index = ind + 1;
                                                    }
                                                  });
                                                {
                                                  console.log(
                                                    sec_index,
                                                    "sec_index"
                                                  );
                                                }
                                                if (sec_index !== index) {
                                                  if (
                                                    word.charAt(0) === "@" &&
                                                    suggestionList.includes(
                                                      word.replace("@", "") +
                                                        " " +
                                                        cmt.content.split(" ")[
                                                          index + 1
                                                        ]
                                                    )
                                                  ) {
                                                    console.log("in true");
                                                    return (
                                                      <b
                                                        onClick={() => {
                                                          var uid =
                                                            allusers.filter(
                                                              (user) => {
                                                                if (
                                                                  user.first_name +
                                                                    " " +
                                                                    user.last_name ===
                                                                  word.replace(
                                                                    "@",
                                                                    ""
                                                                  ) +
                                                                    " " +
                                                                    cmt.content.split(
                                                                      " "
                                                                    )[index + 1]
                                                                )
                                                                  return user;
                                                              }
                                                            )[0]._id;
                                                          history(
                                                            `/settings/edit`
                                                          );
                                                        }}
                                                      >
                                                        {word +
                                                          " " +
                                                          cmt.content.split(
                                                            " "
                                                          )[index + 1]}{" "}
                                                      </b>
                                                    );
                                                  } else if (
                                                    /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(
                                                      word
                                                    )
                                                  ) {
                                                    return (
                                                      <b
                                                        onClick={() => {
                                                          var time = moment(
                                                            word,
                                                            "mm:ss"
                                                          ).diff(
                                                            moment().startOf(
                                                              "day"
                                                            ),
                                                            "seconds"
                                                          );
                                                          history(
                                                            `/videos/${selectedVideoId}`
                                                          );
                                                        }}
                                                      >
                                                        {word}{" "}
                                                      </b>
                                                    );
                                                  } else {
                                                    return <>{word} </>;
                                                  }
                                                } else {
                                                  return <></>;
                                                }
                                              })}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="comment-main-box-right-icon">
                                        <div className="comment-main-box-right-icon-left">
                                          <span
                                            className="newfeed-img-name"
                                            onClick={() => {
                                              likecomment(
                                                videoId,
                                                cmt._id,
                                                "reply"
                                              );
                                            }}
                                          >
                                            {" "}
                                            {cmt.likes.list.includes(
                                              USER_ID
                                            ) ? (
                                              <img src={fillheart} />
                                            ) : (
                                              <img src={Heart} />
                                            )}
                                            {millify(cmt.likes.count)} Likes
                                          </span>
                                        </div>
                                        <div className="comment-main-box-right-icon-right">
                                          <span className="comment-time-st">
                                            <img src={timeimg} />{" "}
                                            {moment(cmt.createdAt).fromNow()}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              );
            })}
          </div>
          {allcomments.length > 0 && allcomments.length < totalcmt ? (
            <div className="view-more-comments-link">
              {cmtloader ? (
                <div className="loadind-post">
                  <img src={loading} />{" "}
                </div>
              ) : (
                <></>
                // <a
                //   onClick={() => {
                //     handlemorecmt();
                //   }}
                // >
                //   View more comments
                // </a>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="comment-main cstm-no-comment-sec">
          <div className="emptymsg">No comments</div>
        </div>
      )}
    </>
  );
};
export default Comments;

function Suggestions(props) {
  function selectSuggestion(username) {
    const regexp = /@[a-zA-Z0-9]*$/;
    const newValue = props.inputValue.replace(regexp, username + " ");
    props.applyMention(newValue); // THIS MIMICS AN ONCHANGE EVENT
    props.focusInput();
    props.setShowSuggestions(false);
  }

  const suggestionItems = props.suggestionList.map((item) => (
    <div className="item" onClick={() => selectSuggestion("@" + item)}>
      @{item}
    </div>
  ));

  return props.showSuggestions ? (
    <div className="cstm-newsfeed-drop">{suggestionItems}</div>
  ) : (
    <></>
  );
}
