import React from "react";
import Checkbox from "@mui/material/Checkbox";

const ExportSelect = ({
  id,
  row,
  selected,
  setSelected,
  selectRow,
  setSelectRow,
  setSelectError,
  disabled = false,
  checkAll,
  setCheckAll,
  totalRows,
}) => {
  // when select at that time get id
  const isSelected = selected.includes(id);

  // checkbox select function
  const handleSelectClick = (e) => {
    const selectedIndex = selected.indexOf(id);
    const newSelected = [...selected];
    const newRow = [...selectRow];
    if (selectedIndex === -1) {
      newSelected.push(id);
      newRow.push(row);
      if (setCheckAll && totalRows) {
        newRow.length === totalRows ? setCheckAll(true) : setCheckAll(false);
      }
    } else {
      newSelected.splice(selectedIndex, 1);
      newRow.splice(selectedIndex, 1);
      if (setCheckAll) {
        setCheckAll(false);
      }
    }

    setSelected(newSelected);
    setSelectRow(newRow);
    setSelectError("");
  };

  return (
    <>
      <Checkbox
        className="cstm-ls-checkbox p-0"
        color="primary"
        checked={isSelected}
        onChange={(e) => {
          handleSelectClick(e);
        }}
        disabled={disabled}
      />
    </>
  );
};

export default ExportSelect;
