import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/UserComponents/Dashboard/Header'
import ChatMainPage from './ChatMainPage.js'
import { MediaInprogressProvider } from '../../context/MediaInprogress.jsx'

const index = () => {
  const userId = localStorage.getItem("userid")
  const idToken = localStorage.getItem("idtoken")
  const navigate = useNavigate();
  useEffect(() => { sessionStorage.setItem("previousurl", window.location.href) }, [])
  useEffect(() => {
    if (!userId || !idToken) {
      navigate('/'); // Redirect to the login page
    }
  }, [userId, idToken, navigate]);

  return (
    <>
      {userId && idToken ? (
        <MediaInprogressProvider>
          <Header />
          <div>
            {/* <ChatSideBar/> */}
            <div>
              <ChatMainPage />
            </div>
          </div>
        </MediaInprogressProvider>
      ) : ""}
    </>
  );
}

export default index