import React from 'react'
import PropTypes from "prop-types"
const SaveIcon = ({ height = 24, width = 24, iconColor = "#000", isFilled }) => {
    return (
        <>
            {isFilled ? <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M7.188 2C6.008 2 5 2.852 5 3.957v16.956c0 .25.057.57.308.808.25.24.554.279.786.279a.66.66 0 0 0 .362-.115L12 18.209l5.544 3.676a.66.66 0 0 0 .362.115c.232 0 .536-.04.786-.279.251-.238.308-.558.308-.808V3.957C19 2.852 17.992 2 16.812 2H7.188Z"
                    clipRule="evenodd"
                />
            </svg> : <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M7.188 2C6.008 2 5 2.852 5 3.957v16.956c0 .25.057.57.308.808.25.24.554.279.786.279a.66.66 0 0 0 .362-.115L12 18.209l5.544 3.676a.66.66 0 0 0 .362.115c.232 0 .536-.04.786-.279.251-.238.308-.558.308-.808V3.957C19 2.852 17.992 2 16.812 2H7.188Zm.252 1.753h9.12c.559 0 .83.317.83.618v15.597l-5.047-3.34a.624.624 0 0 0-.686 0l-5.046 3.34V4.371c0-.301.27-.618.83-.618H7.44Z"
                    clipRule="evenodd"
                />
            </svg>}
        </>
    )
}
SaveIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool
}
export default SaveIcon
