import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Services from "../../Services/auth.service";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import editfill from "../../Images/BackendImages/edit.svg";
import Loader2 from "../../Images/loader2.gif";
import Loader from "../../Images/loader.gif";
import AlertComponent from "../../Hooks/AlertComponent";
import authService from "../../Services/auth.service";

const columns = [
    { id: "name", label: "Name" },
    { id: "counts", label: "No. of videos" },
    { id: "partners", label: "No. of partners" },
    { id: "events", label: "No. of events" },
    { id: "totalDocument", label: "No. of document" },
    { id: "action", label: "Actions" },
];

function createData(name, counts, partners, events, totalDocument, action) {
    return {
        name,
        counts,
        partners, events, totalDocument,
        action,
    };
}
const Tags = () => {
    const history = useNavigate();
    const [rows, setRow] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [deleteId, setDeleteId] = useState("");
    const [showalert, setshowalert] = useState(false);
    const [alertmsg, setalertmsg] = useState("");
    const [showalertAdd, setshowalertAdd] = useState(false);
    const [alertmsgAdd, setalertmsgAdd] = useState("");
    const [showalertEdit, setshowalertEdit] = useState(false);
    const [alertmsgEdit, setalertmsgEdit] = useState("");
    const [searchText, setSearchText] = useState("");
    const [alltags, setalltags] = useState([]);
    const [addtag, setaddtag] = useState("");
    const [adderr, setadderr] = useState("");
    const [edittag, setedittag] = useState("");
    const [editerr, setediterr] = useState("");
    const [selectedId, setselectedId] = useState("");
    const [createInprocess, setcreateInprocess] = useState(false);
    const [editInprocess, seteditInprocess] = useState(false);
    const [dataLoader, setDataLoader] = useState(false);
    const dispatch = useDispatch()
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [listIndex, setListIndex] = useState(-1);
    const [actualsearch, setActualsearch] = useState("");
    const location = useLocation().search
    const search = new URLSearchParams(location).get("search");

    useEffect(() => {
        search !== undefined && search !== null && setSearchText(search)
        handleClearAllStates()
        setDataLoader(true)
        tagListApi(search)
        getallusersSuggestionListApi()
    }, []);
    async function getallusersSuggestionListApi() {
        try {
            const getSuggestionRes = await authService.tagSuggestionList()
            let data = getSuggestionRes.data.data
            var arr = []
            if (data && data.length !== undefined && data.length !== null && data.length > 0) {
                data.map((item) => arr.push({ name: item.name }))
                setSuggestionList(arr);
            }
        } catch (err) {

        }
    }
    useEffect(() => {
        if (suggestionBox && listIndex > -1) {
            var currentList =
                document.getElementById("suggestion-list").children[listIndex]
            if (currentList) {
                var text = currentList.innerText
                setSearchText(text);
            }
        }
    }, [listIndex]);
    // tag list api function
    function tagListApi(search, col, sort) {
        let query = ""
        query += search !== undefined && search !== null ? `?search=${search}` : ""
        query += col !== undefined && col !== null && col !== "" ? `&sortField=${col}` : ""
        query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
        Services.getalltags_byadmin(query)
            .then((res) => {
                if (res.data.status) {
                    setDataLoader(false)
                    var data = res.data.data;
                    var tbl = [];
                    for (var i = 0; i < data.length; i++) {
                        tbl[i] = createData(data[i].name, data[i].counts, data[i].totalpartners, data[i].totalevents, data[i].totalDocument, data[i]._id);
                    }
                    setRow(tbl);
                    setalltags(tbl);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    // handle close add modal
    const handleCloseAddModel = (e) => {
        handleClearAllStates()
        setaddtag("");
    };

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }


    // handle page change
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // handle row per page change
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    // handle edit
    const handleEdit = (value) => {
        setselectedId(value);
        Services.gettagbyid(value)
            .then((res) => {
                if (res.data.status) {
                    setedittag(res.data.data.name.trim());
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    // handle search
    const handleSearch = (text) => {
        setSearchText(text)
        setSuggestionBox(false)
        tagListApi(text)
        history(`/badge?search=${text}`)
        // if (alltags !== undefined && alltags.length > 0) {
        //     var data = alltags.filter((tag) => {
        //         return tag.name.toLowerCase().includes(searchText.trim().toLowerCase());
        //     });
        //     setRow(data);
        // }
    };

    // handle delete 
    const handleDelete = () => {
        Services.deleteTag(deleteId)
            .then((res) => {
                if (res.data.status) {
                    tagListApi(search)
                }
                setshowalert(true);
                setalertmsg(res.data.message);
                setTimeout(() => {
                    setshowalert(false);
                    setalertmsg("");
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    // handle sort
    const handleSort = (col, direc) => {
        setDataLoader(true)
        tagListApi(search, col, direc)
    };

    // handle change for add
    const handleAddChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        setaddtag(e.target.value.trim());
        if (e.target.value.length > 0) {
            setadderr("")
        } else {
            setadderr("Enter tags! ")
        }
    };

    // handle create tag
    const handleCreate = () => {
        if (addtag.length > 0) {
            setcreateInprocess(true);

            Services.createtag({
                name: addtag.trim(),
            })
                .then((res) => {
                    setcreateInprocess(false);
                    if (res.data.status) {
                        setshowalertAdd(true);
                        setalertmsgAdd(res.data.message);
                        setTimeout(() => {
                            setshowalertAdd(false);
                            setalertmsgAdd("");
                        }, 3000);
                        tagListApi(search)
                    } else {
                        setshowalertAdd(true);
                        setalertmsgAdd(res.data.message);
                        setTimeout(() => {
                            setshowalertAdd(false);
                            setalertmsgAdd("");
                        }, 3000);
                    }
                    handleClearAllStates()
                    setaddtag("");
                })
                .catch((e) => {
                    handleClearAllStates()
                    setcreateInprocess(false);
                    console.log(e);
                });
            setadderr("");
        } else {
            setadderr("Enter tags!");
        }
    };

    // handle edit change
    const handleEditChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        setedittag(e.target.value.trim());
        if (e.target.value.length > 0) {
            setediterr("")
        } else {
            setediterr("Enter tags! ")
        }
    };

    // handle edit submit
    const handleEditSubmit = () => {
        if (edittag.length > 0) {
            seteditInprocess(true);
            Services.editTag({
                id: selectedId,
                data: { name: edittag },
            })
                .then((res) => {
                    if (res.data.status) {
                        setshowalertEdit(true);
                        setalertmsgEdit(res.data.message);
                        setTimeout(() => {
                            setshowalertEdit(false);
                            setalertmsgEdit("");
                        }, 3000);
                        tagListApi(search)
                        seteditInprocess(false);
                    } else {
                        setshowalertEdit(true);
                        setalertmsgEdit(res.data.message);
                        setTimeout(() => {
                            setshowalertEdit(false);
                            setalertmsgEdit("");
                        }, 3000);
                    }
                    handleClearAllStates()
                    seteditInprocess(false);
                })
                .catch((e) => {
                    console.log(e);
                    handleClearAllStates()
                    seteditInprocess(false);
                });
            setediterr("");
        } else {
            setediterr("Enter tags!");
        }
    };


    //pop up state for do you want to leave....
    useEffect(() => {

        if (stayOnPage !== null && stayOnPage) {
            if ($('#addtag').hasClass('show')) {
                $("#addtag").modal('show')
            }
            if ($('#edittag').hasClass('show')) {
                $("#edittag").modal('show')
            }

        }
        else if (stayOnPage !== null && !stayOnPage) {
            if ($('#addtag').hasClass('show')) {
                $("#addtag").modal('hide')
                handleCloseAddModel()
            }
            if ($('#edittag').hasClass('show')) {
                $("#edittag").modal('hide')
                handleCloseAddModel()
            }

        }

    }, [stayOnPage])

    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title">
                        <h1>All video tags</h1>
                        <div className="uic-fl-btn">
                            <button
                                className="btn"
                                data-toggle="modal"
                                data-target="#addtag"
                            >
                                Add tag
                            </button>
                        </div>
                    </div>

                    <div className="uic-fl">
                        <div className="uic-fl-search">
                            <input
                                type="text"
                                placeholder="Search"
                                className="search-user-input"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    var currentValue;
                                    if (e.target.value) {
                                        currentValue = e.target.value;
                                    } else {
                                        currentValue = e.target.value;
                                        setSearchText(currentValue);
                                    }
                                    setActualsearch(currentValue);
                                    if (suggestionList !== undefined && suggestionList.length > 0) {
                                        setListIndex(-1);
                                        setSuggestionBox(true)
                                        var searchResult = suggestionList.filter((user) => {
                                            return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                                        });
                                        setSuggestions(searchResult);
                                    }
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch(e.target.value)
                                    } else if (e.key === "ArrowDown") {
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex < listLength - 1) {
                                            setListIndex(listIndex + 1);
                                        } else if (listIndex === listLength - 1) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        }
                                    } else if (e.key === "ArrowUp") {
                                        e.preventDefault();
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex === 0) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        } else if (listIndex === -1) {
                                            setListIndex(listLength - 1);
                                        } else {
                                            setListIndex(listIndex - 1);
                                        }
                                    }
                                }}
                                onBlur={() => setSuggestionBox(false)}
                            />

                            {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                                <div className="suggestion-box">
                                    <ul id="suggestion-list">
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onMouseDown={() => handleSearch(suggestion.name)}
                                                className={index === listIndex ? 'selected-active' : ''}
                                            >
                                                {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length)} </> : suggestion.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

                            </i>

                        </div>
                    </div>

                    {showalert && <div className="alert alert-info">{alertmsg}</div>}
                    <Paper className="uic-tb-mn">
                        <TableContainer className="uic-table-main">
                            <Table className="uic-table min-w-auto">
                                <TableHead className="uic-table-th">
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className="uic-th-td w-10"
                                            >
                                                {column.id !== "action" ? (
                                                    <>
                                                        <span className="custom-sorting">
                                                            <i
                                                                className="fa fa-angle-down"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "Asc");
                                                                }}
                                                            ></i>
                                                            <i
                                                                className="fa fa-angle-up"
                                                                onClick={(e) => {
                                                                    handleSort(column.id, "Dec");
                                                                }}
                                                            ></i>
                                                        </span>
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className="uic-table-td">
                                    {dataLoader ?
                                        <div className="cstm-data-load"><img src={Loader} /> </div> : rows
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length > 0 ?
                                            rows
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={row.code}
                                                            className="uic-th-td w-20"
                                                        >
                                                            {columns.map((column) => {
                                                                const value = row[column.id];
                                                                return column.id === "name" ? (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        className="uic-th-td w-17"
                                                                    >
                                                                        <a data-toggle="modal"
                                                                            data-target="#edittag"
                                                                            onClick={() => {
                                                                                handleEdit(row.action);
                                                                            }} className="cstm-view-users-count">{value}</a>
                                                                    </TableCell>
                                                                ) : column.id === "counts" ? (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        className="uic-th-td w-17"
                                                                    >
                                                                        <Link to={`/contentlibrary/listing/noid/0?tag=${row.action}`} className="cstm-view-users-count">{value}</Link>
                                                                    </TableCell>
                                                                ) : column.id === "partners" ? (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        className="uic-th-td w-17"
                                                                    >
                                                                        <Link to={`/partner/list?search=&tag=${row.action}`} className="cstm-view-users-count">{value}</Link>
                                                                    </TableCell>
                                                                ) : column.id === "events" ? (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        className="uic-th-td w-17"
                                                                    >
                                                                        <Link to={`/events/list?search=&tag=${row.action}`} className="cstm-view-users-count">{value}</Link>
                                                                    </TableCell>
                                                                ) : column.id === "totalDocument" ? (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        className="uic-th-td w-17"
                                                                    >
                                                                        <Link to={`/document/list?search=&tag=${row.action}`} className="cstm-view-users-count">{value}</Link>
                                                                    </TableCell>
                                                                ) : (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        className="uic-th-td uic-table-icon w-17"
                                                                    >
                                                                        <a
                                                                            data-toggle="modal"
                                                                            data-target="#edittag"
                                                                            onClick={() => {
                                                                                handleEdit(value);
                                                                            }}
                                                                        >
                                                                            <img src={editfill} />
                                                                        </a>
                                                                        <a
                                                                            data-toggle="modal"
                                                                            data-target="#DeleteFromTag"
                                                                            onClick={() => {
                                                                                setDeleteId(value);
                                                                            }}
                                                                        >
                                                                            <img src={imgdelete} />
                                                                        </a>
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                }) : <span className="no-data-found">No data found</span>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="uic-pagination"
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>
            <div
                className="modal fade edit-user-details-popup"
                id="addtag"
                tabIndex="-1"
                role="dialog"
                data-backdrop="static"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Create tag</h2>
                            <button
                                type="button"
                                className="close"
                                //data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {
                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                    } else {
                                        $("#addtag").modal('hide')
                                        handleCloseAddModel(e)
                                    }

                                }}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {showalertAdd && <div className="alert alert-info">{alertmsgAdd}</div>}
                            <div className="form-group">
                                <div className="form-group-label">Tag name*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="enter tags name"
                                        name="tag"
                                        onChange={handleAddChange}
                                        value={addtag}
                                    />
                                </div>
                                <span className="error">{adderr}</span>
                            </div>
                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {createInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleCreate}
                                    disabled={createInprocess}
                                >
                                    Create
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade edit-user-details-popup"
                id="edittag"
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Edit Tag</h2>
                            <button
                                type="button"
                                className="close"
                                // data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {
                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                    } else {
                                        $("#edittag").modal('hide')
                                        handleCloseAddModel(e)
                                    }

                                }}

                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {showalertEdit && <div className="alert alert-info">{alertmsgEdit}</div>}
                            <div className="form-group">
                                <div className="form-group-label">Tag name*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="enter tags name"
                                        name="tag"
                                        onChange={handleEditChange}
                                        value={edittag}
                                    />
                                </div>
                                <span className="error">{editerr}</span>
                            </div>

                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {editInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleEditSubmit}
                                >
                                    Update Changes
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Delete */}
            <div
                className="modal fade msg-popup"
                id="DeleteFromTag"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                            <img src={imgbigdelete} />
                        </div>
                        <h1>Are you sure you want to delete this tag?</h1>
                        <div className="row">
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button
                                        type="button"
                                        className="btn btn-red"
                                        onClick={handleDelete}
                                        data-dismiss="modal"
                                    >
                                        Delete
                                    </button>
                                </span>
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" className="btn2" data-dismiss="modal">
                                        Discard
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertComponent />
        </>
    );
};
export default Tags;
