import { TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import React, { useState } from "react";
import editIcon from "../../../Images/BackendImages/edit.svg";
import imgDelete from "../../../Images/BackendImages/delete.svg";

const TableBodyComponentModalPagination = ({
  isPagination = false,
  rows,
  rowsPerPage,
  page,
  columns,
  editCallBack,
  setDeleteId,
  editModalId = "editModal",
  deleteModalId = "deleteModal",
  selectAllChecked,
  selected,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [rowId, setRowId] = useState("");
  const [isZoomImg, setIsZoomImg] = useState(false);
  const [zoomImg, setZoomImg] = useState(null);

  const onEscCloseModal = (e) => {
    if (showModal && e.key === "Escape") {
      setShowModal(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  const handleZoomImage = (e) => {
    setIsZoomImg(true);
    setZoomImg(e.target.src);
  };

  document.addEventListener("keydown", onEscCloseModal);
  return (
    <TableBody className="uic-table-td">
      {rows.map((row) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={row.code}
            className="uic-th-td w-20"
          >
            {columns.map((column) => {
              const value = row[column.id];
              return column.id === "action" ? (
                <TableCell key={column.id} className="uic-th-td uic-table-icon">
                  <a
                    data-toggle="modal"
                    data-target={`#${editModalId}`}
                    onClick={() => {
                      editCallBack(value, row);
                    }}
                  >
                    <Tooltip title="Edit" arrow>
                      {" "}
                      <img src={editIcon} alt="" />
                    </Tooltip>
                  </a>
                  <a
                    data-toggle="modal"
                    data-target={`#${deleteModalId}`}
                    onClick={() => {
                      setDeleteId(value, row);
                    }}
                  >
                    <Tooltip title="Delete" arrow>
                      {" "}
                      <img src={imgDelete} alt="" />
                    </Tooltip>
                  </a>
                </TableCell>
              ) : column.id === "name" ? (
                <TableCell key={column.id} className="uic-th-td">
                  <a
                    className="cstm-view-users-count"
                    data-toggle="modal"
                    data-target={`#${editModalId}`}
                    onClick={() => {
                      editCallBack(row.action, row);
                    }}
                  >
                    {value}
                  </a>
                </TableCell>
              ) : column.id === "description" ? (
                <TableCell key={column.id} className="uic-th-td">
                  <div
                    dangerouslySetInnerHTML={{ __html: row["description"] }}
                  />
                </TableCell>
              ) : (
                <TableCell key={column.id} className="uic-th-td">
                  {value}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TableBodyComponentModalPagination;
