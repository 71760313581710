import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useSearchParams, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../../../Images/loader.gif";
import imgDelete from "../../../../Images/BackendImages/delete.svg";
import editFill from "../../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../../Images/loader2.gif";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import AlertComponent from "../../../../Hooks/AlertComponent";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import authServiceDocument from '../../../../Services/auth.service.document';
// import $ from "jquery"
//columns for table
const columns = [
    { id: "name", label: "Name", width: 60 },
    { id: "docCount", label: "No. of documents", width: 20 },
    { id: "action", label: "Actions", width: 7 },
];

function createData(name, docCount, action) {
    return {
        name,
        docCount,
        action,
    };
}



const ContentTypeManagement = () => {

    const [dataLoader, setDataLoader] = useState(false)
    const [allContentTypes, setAllContentTypes] = useState([])
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const [selContentTypeId, setSelContentTypeId] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [showAlertAdd, setShowAlertAdd] = useState(false);
    const [alertMsgAdd, setAlertMsgAdd] = useState("");
    const [showAlertEdit, setShowAlertEdit] = useState(false);
    const [alertMsgEdit, setAlertMsgEdit] = useState("");
    const [addContentType, setAddContentType] = useState("");
    const [addErr, setAddErr] = useState("");
    const [editContentType, setEditContentType] = useState("");
    const [editerr, setediterr] = useState("");
    const [createInprocess, setCreateInprocess] = useState(false);
    const [editInprocess, setEditInprocess] = useState(false);
    const [editModalLoader, setEditModalLoader] = useState(false)
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [actualsearch, setActualsearch] = useState("");
    const [listIndex, setListIndex] = useState(-1);
    const location = useLocation().search
    const search = new URLSearchParams(location).get("search");

    //called when page loads initially
    useEffect(() => {
        setDataLoader(true)
        getContentTypeList(search)
        handleClearAllStates()
    }, [])

    //get category list
    const getContentTypeList = (search) => {
        let query = ""
        query += search !== undefined && search !== null ? `?search=${search}` : ""
        authServiceDocument.contentTypeList(query)
            .then((res) => {
                if (res.data.data.status) {
                    setDataLoader(false)
                    var data = res.data.data.data;
                    var tbl = [];
                    for (var i = 0; i < data.length; i++) {
                        tbl[i] = createData(data[i].name, data[i].docCount, data[i]._id);
                    }
                    setRows(tbl);
                    setAllContentTypes(tbl);
                } else {
                    setDataLoader(false)
                    setRows([]);
                    setAllContentTypes([]);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    //called when add input change
    const handleAddChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        setAddContentType(e.target.value);
        if (e.target.value.length > 0) {
            setAddErr("")
        } else {
            setAddErr("Enter content type! ")
        }
    };
    useEffect(() => {
        if (suggestionBox && listIndex > -1) {
            var currentList =
                document.getElementById("suggestion-list").children[listIndex]
            // .firstElementChild.firstElementChild;
            if (currentList) {
                var text = currentList.innerText
                setSearchText(text);
            }
        }
    }, [listIndex]);

    //called when category create
    const handleCreate = () => {
        if (addContentType.length > 0) {

            setCreateInprocess(true);
            const addBodyData = {
                name: addContentType,

            }
            authServiceDocument.createContentType(addBodyData)
                .then((res) => {

                    setCreateInprocess(false);
                    if (res.data.data.status) {
                        setShowAlertAdd(true);
                        setAlertMsgAdd(res.data.data.message);
                        setTimeout(() => {
                            setShowAlertAdd(false);
                            setAlertMsgAdd("");
                            $("#addContentType").modal('hide')
                        }, 3000);
                        handleClearAllStates()
                        setAddContentType("");
                        getContentTypeList(search)

                    } else {
                        handleClearAllStates()
                        setShowAlertAdd(true);
                        setAlertMsgAdd(res.data.data.message);
                        setTimeout(() => {
                            setShowAlertAdd(false);
                            setAlertMsgAdd("");
                        }, 3000);
                    }


                })
                .catch((e) => {
                    setCreateInprocess(false);
                    console.log(e);
                });
            setAddErr("");
        } else {
            setAddErr("Enter content type!");
        }
    };

    //called when edit category input change
    const handleEditChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        setEditContentType(e.target.value);
        if (e.target.value.length > 0) {
            setediterr("")
        } else {
            setediterr("Enter content type! ")
        }
    };

    const handleEditSubmit = () => {
        if (editContentType.length > 0) {
            setEditInprocess(true);

            const addBodyData = {
                name: editContentType,
            }
            authServiceDocument.editContentType(selContentTypeId, addBodyData)
                .then((res) => {
                    if (res.data.data.status) {
                        setShowAlertEdit(true);
                        setAlertMsgEdit(res.data.data.message);
                        setEditContentType("")
                        setTimeout(() => {
                            setShowAlertEdit(false);
                            setAlertMsgEdit("");
                            $("#editContentType").modal('hide')
                        }, 3000);
                        setEditInprocess(false);
                        getContentTypeList(search)
                    } else {
                        setShowAlertEdit(true);
                        setAlertMsgEdit(res.data.data.message);
                        setTimeout(() => {
                            setShowAlertEdit(false);
                            setAlertMsgEdit("");
                        }, 3000);
                    }

                    dispatch({ type: "REMOVEMODALINPROCESS" })
                    setEditInprocess(false);
                })
                .catch((e) => {
                    console.log(e);
                    dispatch({ type: "REMOVEMODALINPROCESS" })
                    setEditInprocess(false);
                });
            setediterr("");
        } else {
            setediterr("Enter content type!");
        }
    }

    //called when category delete 
    const handleDelete = () => {
        authServiceDocument.deleteContentType(selContentTypeId)
            .then((res) => {
                if (res.data.data.status) {
                    document.querySelector('#deleteContentType .close').click()
                    getContentTypeList(search)
                    setPage(0)
                }
                setShowAlert(true);
                setAlertMsg(res.data.data.message);
                setTimeout(() => {
                    setShowAlert(false);
                    setAlertMsg("");
                }, 3000);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    // get content type detail api call
    async function getContentTypeById(id) {
        setEditModalLoader(true)
        setSelContentTypeId(id)
        try {
            const getContentTypeByIdRes = await authServiceDocument.viewContentType(id)
            console.log(getContentTypeByIdRes, 'fdsf')
            if (getContentTypeByIdRes.data.data.status) {

                setEditModalLoader(false)
                setEditContentType(getContentTypeByIdRes.data.data.data.name)

            } else {
                setEditModalLoader(false)
            }
        } catch (error) {
            setEditModalLoader(false)
            return false
        }
    }

    //stayonpage  popup for do you want to leave page
    useEffect(() => {
        if (stayOnPage !== null && stayOnPage) {
            if ($('#addContentType').hasClass('show')) {
                $("#addContentType").modal('show')
            }
            if ($('#editContentType').hasClass('show')) {
                $("#editContentType").modal('show')
            }


        }
        else if (stayOnPage !== null && !stayOnPage) {

            if ($('#addContentType').hasClass('show')) {
                $("#addContentType").modal('hide')
            }
            if ($('#editContentType').hasClass('show')) {
                $("#editContentType").modal('hide')
            }

        }

    }, [stayOnPage])


    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }

    //search functionality   
    const handleSearch = (text) => {
        setSearchText(text)
        setSuggestionBox(false)
        getContentTypeList(text)
        navigate(`/document/contenttypes?search=${text}`)
        // if (allContentTypes !== undefined && allContentTypes.length > 0) {
        //     var data = allContentTypes.filter((type) => {
        //         if (type.name.toLowerCase().includes(text.toLowerCase())) {
        //             return type;
        //         }
        //     });
        //     var tbl = [];
        //     for (var i = 0; i < data.length; i++) {
        //         tbl[i] = createData(data[i].name, data[i].docCount, data[i].action);
        //     }
        //     setRows(tbl);
        // }
    };

    //for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //for pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title">
                        <h1>Content types</h1>
                        <div className="uic-fl-btn">
                            <button
                                className="btn"
                                data-toggle="modal"
                                data-target="#addContentType"
                                onClick={() => {
                                    setAddContentType('')
                                    handleClearAllStates()
                                }}
                            >
                                Add content type
                            </button>
                        </div>
                    </div>
                    <div className="uic-fl">
                        <div className="uic-fl-search">
                            <input
                                type="text"
                                value={searchText}
                                placeholder="Search"
                                className="search-user-input"
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    var currentValue;
                                    if (e.target.value) {
                                        currentValue = e.target.value;
                                    } else {
                                        currentValue = e.target.value;
                                        setSearchText(currentValue);
                                    }
                                    setActualsearch(currentValue);
                                    if (suggestionList !== undefined && suggestionList.length > 0) {
                                        setListIndex(-1);
                                        setSuggestionBox(true)
                                        var searchResult = suggestionList.filter((user) => {
                                            return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                                        });
                                        setSuggestions(searchResult);
                                    }
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch(e.target.value)
                                    } else if (e.key === "ArrowDown") {
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex < listLength - 1) {
                                            setListIndex(listIndex + 1);
                                        } else if (listIndex === listLength - 1) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        }
                                    } else if (e.key === "ArrowUp") {
                                        e.preventDefault();
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex === 0) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        } else if (listIndex === -1) {
                                            setListIndex(listLength - 1);
                                        } else {
                                            setListIndex(listIndex - 1);
                                        }
                                    }
                                }}
                                onBlur={() => setSuggestionBox(false)}
                            />
                            <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

                            </i>
                        </div>
                    </div>
                    {showAlert && <div className="alert alert-info">{alertMsg}</div>}
                    <Paper className="uic-tb-mn">
                        <TableContainer className="uic-table-main">
                            <Table className="uic-table min-w-auto">
                                <TableHead className="uic-table-th">
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className={`uic-th-td w-${column.width}`}
                                            >
                                                {/* {column.id !== "action" && column.id !== "drag" ? (
                                                        <>
                                                            <span className="custom-sorting">
                                                                <i
                                                                    className="fa fa-angle-down"
                                                                    onClick={(e) => {
                                                                        handleSort(column.id, "asc");
                                                                    }}
                                                                ></i>
                                                                <i
                                                                    className="fa fa-angle-up"
                                                                    onClick={(e) => {
                                                                        handleSort(column.id, "desc");
                                                                    }}
                                                                ></i>
                                                            </span>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {dataLoader ?
                                    <div className="cstm-data-load"><img src={Loader} /> </div> :
                                    rows.length > 0 ?

                                        <TableBody className="uic-table-td">
                                            {rows
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            className="uic-th-td w-20"
                                                        >
                                                            {columns.map((column) => {
                                                                const value = row[column.id];
                                                                return column.id === "name" ? (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        className="uic-th-td w-60"
                                                                    >
                                                                        <a data-toggle="modal"
                                                                            data-target="#editContentType"
                                                                            className="cstm-view-users-count"
                                                                            onClick={() => {
                                                                                setEditContentType('')
                                                                                getContentTypeById(row.action);
                                                                            }}
                                                                        >{value}</a>
                                                                    </TableCell>
                                                                ) : column.id === "action" ? (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        className="uic-th-td uic-table-icon w-7"
                                                                    >
                                                                        <a
                                                                            data-toggle="modal"
                                                                            data-target="#editContentType"
                                                                            onClick={() => {
                                                                                setEditContentType('')
                                                                                getContentTypeById(value);
                                                                            }}
                                                                        >
                                                                            <img src={editFill} />
                                                                        </a>
                                                                        <a
                                                                            data-toggle="modal"
                                                                            data-target="#deleteContentType"
                                                                            onClick={() => {
                                                                                setSelContentTypeId(value);
                                                                            }}
                                                                        >
                                                                            <img src={imgDelete} />
                                                                        </a>
                                                                    </TableCell>
                                                                ) : column.id === "docCount" ? (
                                                                    <TableCell
                                                                        key={column.id}
                                                                        className="uic-th-td w-60"
                                                                    ><Link to={`/document/list?search=&&contentType=${row.name}`} className="cstm-view-users-count">{value}</Link>

                                                                    </TableCell>
                                                                ) : <></>
                                                            })}
                                                        </TableRow>
                                                    )
                                                })}
                                        </TableBody>
                                        :
                                        <span className="no-data-found">No data found</span>
                                }
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="uic-pagination"
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>
            {/* add category modal */}
            <div
                className="modal fade edit-user-details-popup"
                id="addContentType"
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    {editModalLoader ?
                        <div className="edit-modal-loader">
                            <img src={Loader} />
                        </div> : (
                            <div className="modal-content">

                                <div className="edit-user-details-header">
                                    <h2>Add Content type</h2>
                                    <button
                                        type="button"
                                        className="close"
                                        //data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={(e) => {
                                            if (modalInProcess) {

                                                dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })



                                            } else {
                                                $("#addContentType").modal('hide')
                                                //handleCloseAddModel(e)
                                            }

                                        }}
                                    // onClick={handleCloseAddModel}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {showAlertAdd && <div className="alert alert-info">{alertMsgAdd}</div>}
                                    <div className="form-group">
                                        <div className="form-group-label">Content type name*</div>
                                        <div className="form-group-ct">
                                            <input
                                                type="text"
                                                placeholder="Enter content type"
                                                name="contenttype"
                                                onChange={handleAddChange}
                                                value={addContentType}
                                            />
                                        </div>
                                        <span className="error">{addErr}</span>
                                    </div>

                                </div>
                                <div className="edit-user-details-footer cstm-create-button">
                                    {createInprocess ? (
                                        <div className="loader-button1">
                                            <button>In Process...</button>
                                            <span className="loader-btn">
                                                <img src={Loader2} />
                                            </span>
                                        </div>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={handleCreate}
                                            disabled={createInprocess}
                                        >
                                            Add content type
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                </div>
            </div>
            {/* edit content type modal */}
            <div
                className="modal fade edit-user-details-popup"
                id="editContentType"
                tabIndex="-1"
                role="dialog"
                data-backdrop="static"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    {editModalLoader ?
                        <div className="edit-modal-loader">
                            <img src={Loader} />
                        </div> : (
                            <div className="modal-content">
                                <div className="edit-user-details-header">
                                    <h2>Edit Content type</h2>
                                    <button
                                        type="button"
                                        className="close"
                                        aria-label="Close"
                                        onClick={(e) => {
                                            if (modalInProcess) {
                                                dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                            } else {
                                                $("#editContentType").modal('hide')
                                            }
                                        }}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {showAlertEdit && <div className="alert alert-info">{alertMsgEdit}</div>}
                                    <div className="form-group">
                                        <div className="form-group-label">Content type name*</div>
                                        <div className="form-group-ct">
                                            <input
                                                type="text"
                                                placeholder="Enter your content type"
                                                name="contenttype"
                                                onChange={handleEditChange}
                                                value={editContentType}
                                            />
                                        </div>
                                        <span className="error">{editerr}</span>
                                    </div>

                                </div>
                                <div className="edit-user-details-footer cstm-create-button">
                                    {editInprocess ? (
                                        <div className="loader-button1">
                                            <button>In Process...</button>
                                            <span className="loader-btn">
                                                <img src={Loader2} />
                                            </span>
                                        </div>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={handleEditSubmit}
                                        >
                                            Update Changes
                                        </button>
                                    )}
                                </div>
                            </div>)}
                </div>
            </div>
            {/* Modal Delete */}
            <div
                className="modal fade msg-popup"
                id="deleteContentType"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                            <img src={imgBigDelete} alt="" />
                        </div>
                        <h1>Are you sure you want to delete this content type?</h1>
                        <div className="row">
                            <div className="col-6">
                                {createInprocess ? (
                                    <div className="loader-button1">
                                        <button>In Process...</button>
                                        <span className="loader-btn">
                                            <img src={Loader2} />
                                        </span>
                                    </div>
                                ) : (
                                    <span className="msg-btn">
                                        <button
                                            type="button"
                                            className="btn btn-red"
                                            disabled={createInprocess}
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </button>
                                    </span>)}
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" className="btn2" data-dismiss="modal">
                                        Discard
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertComponent />
        </>
    )
}

export default ContentTypeManagement