import NotificationList from "./NotificationList";
// icon
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
const NotificationListPopup = ({ setclose, openingpop }) => {
    return (
      <div className="globle-model-box noti-globle-model-box">
        <div className="an-globle-model-box-inner">
          <div className="an-globle-model-heading">
            <button
              className="an-globle-back"
              onClick={() => {
                setclose({ ...openingpop, notifi: false });
              }}
            >
              <ArrowLeftLineIcon size="20px" />
            </button>
            <div className="an-globle-model-heading-sec">
              <h3>Notifications</h3>
            </div>
          </div>

          <NotificationList />
        </div>
      </div>
    );
}
export default NotificationListPopup;