import React, { useEffect } from "react";
import {
  Navigate,
  useNavigate,
  Location,
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

/*This is copmmon component for asking confirm messages on admin panel*/
const AlertComponent = ({ setcurrentDiv }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const historyPage = useSelector((state) => {
    return state.ReduHistoryPage;
  });
  const customPageChange = useSelector((state) => {
    return state.ReduCustomPageChange;
  });
  const modalPopClose = useSelector((state) => {
    return state.ReduCustomModalPopupClose;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  const isMDSPartner = useSelector((state) => {
    return state.isMDS;
  });
  const location = useLocation();
  const navType = useNavigationType();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get("id");

  /*This function will be called when back button is clicked*/
  useEffect(() => {
    if (navType === "POP") {
      if (modalInProcess) {
        if (confirm("Do you want to leave this page") == true) {
          $(".modal").modal("hide");
          dispatch({ type: "REMOVEMODALINPROCESS" });
          navigate(historyPage);
        } else {
          navigate(location.pathname + location.search);
        }
      } else {
        $(".modal").modal("hide");
        if (
          localStorage.getItem("HISTORYPAGE") !== null &&
          localStorage.getItem("HISTORYPAGE") !== "" &&
          localStorage.getItem("HISTORYPAGE") !== historyPage
        ) {
          navigate(localStorage.getItem("HISTORYPAGE"));
        }
        //  else {
        //   window.history.go(-1);
        // }
      }
    }
  }, [location]);

  //alert user when user we leave or reload page
  const alertUser = (e) => {
    if (modalInProcess) {
      e.preventDefault();
      localStorage.setItem("HISTORYPAGE", location.pathname + location.search);
      e.returnValue = "";
      dispatch({ type: "PAGECHANGE", payload: true });

      return "";
    } else {
      window.location.reload(true);
    }
  };

  function isSafari() {
    // Check for the presence of "Safari" in the user agent string
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  /*This function will be called when click on outside of modal popup for confirmation message*/
  const outSideClick = (e) => {
    if (
      e.target !== undefined &&
      e.target !== null &&
      e.target.classList.contains("modal") &&
      e.target.classList.contains("show")
    ) {
      if (modalInProcess) {
        if (confirm("Do you want to leave this page?") === true) {
          $(e.target).modal("hide");
          dispatch({ type: "REMOVEMODALINPROCESS" });
          dispatch({ type: "SETSTAYONPAGE", payload: null });
          dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
          const searchParams_ = new URLSearchParams(window.location.search);
          const tabValue = searchParams_.get("tab");
          // Check if the tab is "Attendees" and navigate
          if (tabValue && tabValue === "Attendees" && eventId) {
            navigate(`/events/detail?id=${eventId}&tab=Attendees`);
          }
        }
      } else {
        $(e.target).modal("hide");
        // Check if the tab is "Attendees" and navigate
        const searchParams_ = new URLSearchParams(window.location.search);
        const tabValue = searchParams_.get("tab");
        // Check if the tab is "Attendees" and navigate
        if (tabValue && tabValue === "Attendees" && eventId) {
          navigate(`/events/detail?id=${eventId}&tab=Attendees`);
        }
      }
    }
  };

  // function will call when escape press event listn
  const onEscPress = (e) => {
    if (e.key === "Escape") {
      if (modalInProcess) {
        if (confirm("Do you want to leave this page?") === true) {
          $(".modal").modal("hide");
          dispatch({ type: "REMOVEMODALINPROCESS" });
          dispatch({ type: "SETSTAYONPAGE", payload: null });
          dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
          // Check if the tab is "Attendees" and navigate
          const searchParams_ = new URLSearchParams(window.location.search);
          const tabValue = searchParams_.get("tab");
          // Check if the tab is "Attendees" and navigate
          if (tabValue && tabValue === "Attendees" && eventId) {
            navigate(`/events/detail?id=${eventId}&tab=Attendees`);
          }
        }
      } else {
        $(".modal").modal("hide");
        // Check if the tab is "Attendees" and navigate
        const searchParams_ = new URLSearchParams(window.location.search);
        const tabValue = searchParams_.get("tab");
        // Check if the tab is "Attendees" and navigate
        if (tabValue && tabValue === "Attendees" && eventId) {
          navigate(`/events/detail?id=${eventId}&tab=Attendees`);
        }
      }
    }
  };

  // function will call when user try to navigate from sidebar
  const innerSideBarClick = (e) => {
    if (modalInProcess) {
      e.preventDefault();
      if (confirm("Do you want to leave this page") == true) {
        $(".modal").modal("hide");
        dispatch({ type: "REMOVEMODALINPROCESS" });
        dispatch({ type: "SETSTAYONPAGE", payload: null });
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
        navigate(e.target.getAttribute("href"));
      } else {
        e.stopPropagation();
      }
    }
  };

  //to render on pageload
  useEffect(() => {
    localStorage.setItem("HISTORYPAGE", "");
  }, []);

  //
  useEffect(() => {
    const elements = document.querySelectorAll(".modal");
    elements.forEach((element) => {
      element.addEventListener("click", outSideClick);
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        ? element.addEventListener("keyup", onEscPress)
        : element.addEventListener("keydown", onEscPress);
    });

    dispatch({ type: "PAGECHANGE", payload: false });
    window.addEventListener("beforeunload", alertUser);
    window.history.pushState(null, null, window.location.href);

    $(
      ".users-info-sidebar .card-header a, .sidebar-menu li a,  .users-info-sidebar .card .cstm-ac-in-menu a"
    ).bind("click", innerSideBarClick);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
      $(
        ".users-info-sidebar .card-header a, .sidebar-menu li a,  .users-info-sidebar .card .cstm-ac-in-menu a"
      ).unbind("click", innerSideBarClick);
      const elements = document.querySelectorAll(".modal");
      elements.forEach((element) => {
        element.removeEventListener("click", outSideClick);
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
          ? element.removeEventListener("keyup", onEscPress)
          : element.removeEventListener("keydown", onEscPress);
      });
    };
  }, [modalInProcess]);

  /*This function will be called when navigation occur for confirmation message*/
  useEffect(() => {
    if (modalInProcess && customPageChange) {
      if (confirm("Do you want to leave this page") == true) {
        dispatch({ type: "REMOVECUSTOMPAGECHANGE" });
        dispatch({ type: "REMOVEMODALINPROCESS" });
        dispatch({ type: "SETSTAYONPAGE", payload: null });
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
        if (NAVIGATEPAGE !== "") {
          navigate(NAVIGATEPAGE);
        }
      } else {
        //dispatch({type: "SETCUSTOMPAGECHANGE"})

        dispatch({ type: "REMOVECUSTOMPAGECHANGE" });
      }
    }
  }, [customPageChange]);

  /*This function will be called when popup close button click*/
  useEffect(() => {
    if (modalInProcess && modalPopClose) {
      if (confirm("Do you want to leave this page?") == true) {
        dispatch({ type: "REMOVEMODALINPROCESS" });
        dispatch({ type: "SETSTAYONPAGE", payload: false });
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
      } else {
        dispatch({ type: "SETSTAYONPAGE", payload: true });
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
      }
    }
  }, [modalPopClose]);
  return <></>;
};

export default AlertComponent;
