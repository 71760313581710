import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PlayIcon from "../../../../components/Icons/VideoPlayerIcons/PlayIcon";
import PauseIcon from "../../../../components/Icons/VideoPlayerIcons/PauseIcon";

const VoiceMessageControls = ({ onPlayClick, onPauseClick, isPlaying }) => {
  const handleClick = () => {
    if (isPlaying) {
      onPauseClick();
    } else {
      onPlayClick();
    }
  };

  return (
    <div onClick={handleClick} style={{ cursor: "pointer" }}>
      {isPlaying ? (
        <PauseIcon iconColor="var(--color-icon-neutral-strong)" isFilled />
      ) : (
        <PlayIcon iconColor="var(--color-icon-neutral-strong)" />
      )}
    </div>
  );
};

VoiceMessageControls.propTypes = {
  onPlayClick: PropTypes.func,
  onPauseClick: PropTypes.func,
  handleClick:PropTypes.func,
  wavesurferRef: PropTypes.shape({
    current: PropTypes.object,
  }),
};

export default VoiceMessageControls;
