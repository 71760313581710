import React from "react";

const VideoComingSoon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={120}
      height={120}
      fill="none"
    >
      <circle cx={60} cy={60} r={60} fill="#6FCADF" fillOpacity={0.2} />
      <path
        fill="#060D14"
        fillRule="evenodd"
        d="M33.37 70.349c.467 5.745 4.943 9.66 11.252 9.633L62.714 80c5.958-.246 10.292-5.224 10.294-11.085l5.27 4.365c3.252 2.69 8.353.35 8.358-3.98l.031-18.97c.005-4.27-5.08-6.703-8.356-3.995l-5.315 4.386c-.031-6.147-5.27-11.13-11.434-10.694-5.271-.01-17.58-.042-17.936-.016-5.655.244-10.551 5.056-10.282 10.888-.007 18.992.018 19.343.026 19.45Zm10.4-26.469c.51-.02 10.918.003 17.93.013 3.825-.302 7.186 3.235 7.475 6.828.018.602.003 10.812 0 18.194 0 3.73-2.827 7.06-6.594 7.216-.49.018-10.919-.005-17.96-.016-3.804.294-7.127-2.439-7.419-6.014-.017-.614-.02-11.822-.02-19.29-.173-3.737 2.97-6.777 6.587-6.93Zm29.237 11.834c.002 2.156.003 4.888.003 8.191l7.702 6.384c.822.675 2.084.08 2.084-.994l.03-18.97c.005-1.071-1.263-1.673-2.083-.994l-7.736 6.383Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default VideoComingSoon;
