import React, { useEffect, useRef, useState } from "react";
import FilterHeader from "../../../../Pages/DocumentManagement/Components/FilterHeader";
import SearchField from "../../../InputField/SearchField";
import FilterNestedNavigation from "../../../../Pages/DocumentManagement/Components/FilterNestedNavigation";
import FilterFooter from "../../../../Pages/DocumentManagement/Components/FilterFooter";
import FilterListItem from "../../../../Pages/DocumentManagement/Components/FilterListItem";
import CategoryListItems from "./CategoryListItems";
import Avtar from "../../../Avtar/Avtar";
import NavVideoCategoryIcon from "../../../Icons/NavIcons/NavVideoCategoryIcon";
import CalendarIcon from "../../../Icons/GeneralIcons/CalendarIcon";
import ReactDatePicker from "react-datepicker";

const VideoFilterSidebar = ({
  dateList,
  filterRef,
  onCancel,
  onClear,
  resultCount,
  allCategories = [],
  allSpeakers = [],
  handleCategoryFilter,
  handleSubCategoryFilter,
  selectedCategory,
  selectedSubCategory,
  handlePeopleFilter,
  selectedPeople,
  handleDateFilter,
  selectedDateFilter,
  handleShowResult,
  handleFromDateChange,
  handleToDateChange,
  fromDate,
  toDate,
  allCategoriesList,
  allSpeakersList,
  setAllSpeakersList,
  setAllCategoriesList,
  handleCloseIconClick,
}) => {
  const [isCategory, setCategory] = useState(false);
  const [isPeople, setPeople] = useState(false);
  const [isDateList, setDateList] = useState(false);
  const [searchText, setSearchtext] = useState("");
  const fromDatePickerRef = useRef(null);
  const toDatePickerRef = useRef(null);
  const lastDateOfYear = `12/31/${(new Date().getFullYear() + 3)}`;
  const headerLabel = isCategory
    ? "Category"
    : isPeople
    ? "Members"
    : isDateList
    ? "Date"
    : "Filters";

  const handleDate = (data) => {
    handleDateFilter(data);
    data.id !== "custom" && handleBack();
  };

  const filterSearch = (text) => {
    setSearchtext(text);
    if (isCategory) {
      const filteredCategory = allCategories.filter((category) => {
        if (category.name.toLowerCase().includes(text.toLowerCase())) {
          return category;
        }
      });
      setAllCategoriesList(filteredCategory);
    }
    if (isPeople) {
      const filteredSpeaker = allSpeakers.filter((speaker) => {
        const fullName = `${speaker.first_name.toLowerCase()} ${speaker.last_name.toLowerCase()}`;
        return fullName.includes(text.toLowerCase());
      });
      setAllSpeakersList(filteredSpeaker);
    }
  };

  const handleBack = () => {
    setCategory(false);
    setPeople(false);
    setDateList(false);
    filterSearch("");
  };

  const handleNumericInput = (e) => {
    if (fromDatePickerRef.current && toDatePickerRef.current) {
      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "/",
      ];
      if (!allowedKeys.includes(e.key)) {
        e.preventDefault();
      }
    }
  };
  addEventListener("keypress", handleNumericInput);
  return (
    <div className="filter-sidebar-partners">
      <FilterHeader
        title={headerLabel}
        onCancel={onCancel}
        isNavigated={isCategory || isPeople || isDateList}
        onClick={handleBack}
      />
      {(isCategory || isPeople) && (
        <div className="filter-sidebar-search">
          <SearchField
            value={searchText}
            handleChange={(e) => filterSearch(e.target.value)}
            isRounded
            handleClear={() => filterSearch("")}
          />
        </div>
      )}
      <div className="filter-sidebar-body scrollbar">
        {isCategory ? (
          allCategoriesList.map((category) => (
            <CategoryListItems
              handleBack={handleBack}
              handleCategoryFilter={handleCategoryFilter}
              handleSubCategoryFilter={handleSubCategoryFilter}
              category={category}
              selectedCategory={selectedCategory}
              selectedSubCategory={selectedSubCategory}
            />
          ))
        ) : isPeople ? (
          allSpeakersList.map((speaker) => (
            <FilterListItem
              selected={selectedPeople?._id === speaker._id}
              onClick={() => {
                handlePeopleFilter(speaker);
                handleBack();
              }}
              icon={
                speaker?.speakerIcon ? (
                  <Avtar src={speaker?.speakerIcon} size="32" />
                ) : (
                  <Avtar type="letter" size="32" letter={speaker?.first_name} />
                )
              }
              label={`${speaker?.first_name} ${speaker?.last_name}`}
            />
          ))
        ) : isDateList ? (
          dateList.map((dates) => (
            <FilterListItem
              selected={selectedDateFilter.id === dates.id}
              onClick={() => handleDate(dates)}
              label={dates.label}
            />
          ))
        ) : (
          <>
            <FilterNestedNavigation
              closeIcon={Object.keys(selectedCategory).length ? true : false}
              closeIconClick={() => handleCloseIconClick("category")}
              icon={
                Object.keys(selectedCategory).length > 0 ? (
                  <NavVideoCategoryIcon />
                ) : (
                  ""
                )
              }
              label={
                Object.keys(selectedCategory).length > 0
                  ? selectedCategory.name
                  : "Category"
              }
              onClick={() => setCategory(true)}
            />
            <FilterNestedNavigation
              closeIcon={Object.keys(selectedPeople).length ? true : false}
              closeIconClick={() => handleCloseIconClick("people")}
              icon={
                Object.keys(selectedPeople).length > 0 &&
                (selectedPeople?.speakerIcon ? (
                  <Avtar src={selectedPeople?.speakerIcon} size="32" />
                ) : (
                  <Avtar
                    type="letter"
                    size="32"
                    letter={selectedPeople?.first_name}
                  />
                ))
              }
              label={
                Object.keys(selectedPeople).length > 0
                  ? `${selectedPeople?.first_name} ${selectedPeople?.last_name}`
                  : "People"
              }
              onClick={() => setPeople(true)}
            />
            <FilterNestedNavigation
              closeIcon={Object.keys(selectedDateFilter).length ? true : false}
              closeIconClick={() => handleCloseIconClick("date")}
              icon={
                Object.keys(selectedDateFilter).length > 0 ? (
                  <CalendarIcon />
                ) : (
                  ""
                )
              }
              label={
                Object.keys(selectedDateFilter).length > 0
                  ? selectedDateFilter.label
                  : "Selected date"
              }
              onClick={() => setDateList(true)}
            />
          </>
        )}
        {selectedDateFilter.id === "custom" && isDateList && (
          <>
            <div className="custom-date-range">
              <div className="text-field-main">
                <h6>From</h6>
                <div className={`text-field-box`}>
                  <ReactDatePicker
                    maxDate={new Date()}
                    onChange={handleFromDateChange}
                    selected={fromDate}
                    ref={fromDatePickerRef}
                  />
                </div>
              </div>
              <div className="text-field-main">
                <h6>To</h6>
                <div className={`text-field-box`}>
                  <ReactDatePicker
                    minDate={fromDate}
                    maxDate={new Date(lastDateOfYear)}
                    onChange={(date) => {
                      handleToDateChange(date);
                      handleBack();
                    }}
                    selected={toDate}
                    ref={toDatePickerRef}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {!isCategory && !isPeople && !isDateList && (
        <FilterFooter
          onCancel={onClear}
          resultCount={resultCount}
          onShow={handleShowResult}
        />
      )}
    </div>
  );
};

export default VideoFilterSidebar;
