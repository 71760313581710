import React from 'react'
import PropTypes from "prop-types"
import './TabItemFluid.css'
import '../../Separators/Separators.css'
import "../../../Styles/Theme/Theme.css"
import Typography from "../../Typography/Typography"
import NotificationBadge from "../../Badge/NotificationBadge"

const TabItemFluid = ({ label = "Label", isSelected = false, id, handleOnClick, counter = "(3)", hasCounter = false, hasBadge = false }) => {
    return (
        <>
            <button className={`tabs-button ${isSelected ? "active" : ""}`} onClick={handleOnClick}>
                <>
                    {label}
                    {hasCounter &&
                        <span className='tabs-has-counter'>({counter})</span>
                    }
                    {hasBadge &&
                        <span className='tabs-has-badge'><NotificationBadge /></span>
                    }
                </>
            </button>
        </>
    )
}

TabItemFluid.propTypes = {
    label: PropTypes.string,
    isSelected: PropTypes.bool,
    id: PropTypes.string,
    hasBadge: PropTypes.bool,
    hasCounter: PropTypes.bool,
    counter: PropTypes.string,
    handleOnClick: PropTypes.func
}

export default TabItemFluid