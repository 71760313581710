import PropTypes from "prop-types";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageReaction.css";

const MessageReaction = ({ emoji = "👍", handleReactionUsersList }) => {
  return (
    <div
      className="messageReaction-container flex"
      onClick={handleReactionUsersList}
    >
      {emoji}
    </div>
  );
};

MessageReaction.propTypes = {
  handleReactionUsersList: PropTypes.func.isRequired,
  emoji: PropTypes.string.isRequired
};

export default MessageReaction;
