import React from "react";
import PropTypes from "prop-types";
// CSS
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatUnreadContainer.css";

const ChatUnreadDivider = ({ text = "Unread messages", unReadMessageRef}) => {
  return (
    <>
      <div className="ChatUnreadContainer" ref={unReadMessageRef}>
        <div className="ChatUnreadContainer-body flex justify-end">{text}</div>
      </div>
    </>
  );
};

ChatUnreadDivider.propTypes = {
  text: PropTypes.string,
};

export default ChatUnreadDivider;
