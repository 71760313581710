const loader = false;

const ReduLoader = (state = loader, action) => {
  switch (action.type) {
    case "SETLOADER":
      console.log("setloader called")
      return true;
    case "REMOVELOADER":
      console.log("remove loader called")
        return false;
    default:
      return state;
  }
};

export default ReduLoader;
