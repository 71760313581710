import React from 'react'
import './Switch.css'
import "../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"
const Switch = ({ checked, defaultChecked, disabled, onChange, readOnly, required }) => {
    return (
        <>
            <label class="switch">
                <input
                    type="checkbox"
                    checked={checked}
                    defaultChecked={defaultChecked}
                    disabled={disabled}
                    onChange={onChange}
                    readOnly={readOnly}
                    required={required}
                />
                <span class="slider round"></span>
            </label>
        </>
    )
}

Switch.propTypes = {
    checked: PropTypes.bool,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool
}

export default Switch