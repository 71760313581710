import React from "react";

const IconNavGalleryFilled = () => {
  return (
    <div className="mds-icons filled">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M16.3 2H7.7C4.3 2 2 4.4 2 7.9v8.2C2 19.6 4.3 22 7.7 22h8.7c3.4 0 5.7-2.4 5.7-5.9V7.9C22 4.4 19.7 2 16.3 2ZM8.6 6.6c1.4 0 2.5 1.1 2.5 2.5s-1.1 2.5-2.5 2.5-2.5-1.1-2.5-2.5c0-1.3 1.1-2.5 2.5-2.5Zm10.6 8.9c-.3.3-.8.3-1.1 0l-2.2-2.2c-.2-.1-.4-.2-.6-.2-.2 0-.5.1-.6.3-2.3 2.8-2.3 2.9-2.4 2.9-.9.9-2.2.8-3.1 0 0 0-.9-1-1-1-.2-.2-.7-.2-.9.1L5.8 17c-.2.2-.3.2-.5.2s-.4-.1-.5-.2c-.3-.3-.3-.8 0-1.1l1.5-1.6c.8-.9 2.2-.9 3-.1l1 1c.3.3.7.3 1 0 .1-.1 2.3-2.8 2.3-2.8.4-.5 1-.8 1.6-.9.7-.1 1.3.1 1.8.5l2.3 2.3c.2.5.2.9-.1 1.2Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavGalleryFilled;
