import React from "react";

const ContactSupport = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={120}
      height={120}
      fill="none"
    >
      <circle cx={60} cy={60} r={60} fill="#6FCADF" fillOpacity={0.2} />
      <path
        fill="#060D14"
        fillRule="evenodd"
        d="M82.668 59.274c0 12.037-9.994 21.94-22.504 21.94a22.96 22.96 0 0 1-7.902-1.39 5.208 5.208 0 0 0-2.007-.29 8.4 8.4 0 0 0-1.288.164c-.727.145-1.521.378-2.25.605-.565.176-1.116.356-1.668.537h-.002c-1.024.335-2.054.671-3.188.989-.765.214-1.478.389-2.13.517.731-.582 1.566-1.26 2.175-1.867.45-.448 1.19-1.243 1.63-2.309a4.91 4.91 0 0 0 .363-2.358 4.702 4.702 0 0 0-1.1-2.585c-3.217-3.802-5.136-8.658-5.136-13.953 0-12.037 9.994-21.941 22.503-21.941 12.51 0 22.504 9.904 22.504 21.94Zm-46.123 23.19.013.007a.055.055 0 0 1-.013-.007Zm.438 2.331Zm49.685-25.521c0 14.326-11.866 25.94-26.504 25.94a26.96 26.96 0 0 1-9.276-1.633c-.634-.232-2.345.327-4.482 1.024-4.289 1.4-10.293 3.358-12.745.61-1.653-1.854 1.05-3.998 3.404-5.864 1.8-1.428 3.396-2.693 2.678-3.54-3.799-4.49-6.082-10.253-6.082-16.537 0-14.327 11.866-25.941 26.503-25.941 14.638 0 26.504 11.614 26.504 25.94ZM59.998 44C55.585 44 52 47.889 52 52.675c0 .941.704 1.705 1.572 1.705.867 0 1.57-.764 1.57-1.705 0-2.903 2.178-5.27 4.858-5.27 2.68 0 4.862 2.362 4.862 5.27 0 2.913-2.177 5.275-4.862 5.275-.867 0-1.571.763-1.571 1.704v3.974c0 .941.704 1.705 1.571 1.705.868 0 1.572-.764 1.572-1.705v-2.437C65.23 60.396 68 56.884 68 52.68c-.008-4.79-3.593-8.68-8.004-8.68ZM60 68a2.667 2.667 0 1 0 0 5.333 2.667 2.667 0 0 0 0-5.333Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ContactSupport;
