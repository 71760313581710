import React from 'react'
import './Badges.css'
import "../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"
const Badges = ({ className = "badges-neutral medium", label = 0, }) => {

  return (
    <>
      {label && label > 0 &&
        <div className={`badges ${className}`}>{label > 9 ? '9+' : label}</div>
      }
    </>
  )
}

// define types to all props
Badges.propTypes = {
  label: PropTypes.number,
  className: PropTypes.oneOf(['badges-neutral medium', 'badges-neutral large', 'badges-danger large', 'badges-danger medium'])
}

export default Badges