import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
//import api file
import Services from "../../Services/auth.service";
//import components from mui/material
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
//import images
import edit from "../../Images/BackendImages/edit.svg";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
const columns = [
  { id: "tpcname", label: "Topic Name" },
  { id: "numgrp", label: "Number of Groups" },
  { id: "tpcpost", label: "Topic Posts" },
  { id: "action", label: "Actions" },
];

function createData(tpcname, numgrp, tpcpost, action) {
  return {
    tpcname,
    numgrp,
    tpcpost,
    action,
  };
}

const TopicListing = ({ setcurrentDiv, setselectedTid, settopicdtl }) => {
  const alltpc = useSelector((state) => {
    return state.ReduTopics;
  });
  const [rows, setrow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteId, setdeleteId] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [searchText, setsearchText] = useState("");
  useEffect(() => {
    if (alltpc !== undefined && alltpc.length > 0) {
      var data = alltpc;
      var tbl = [];
      for (var i = 0; i < data.length; i++) {
        tbl[i] = createData(
          data[i].topic,
          data[i].numberOfGroup.length,
          data[i].totalPost,
          data[i]._id
        );
      }
      setrow(tbl);
    }
  }, [alltpc]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (value) => {
    Services.gettopicbyid(value)
      .then((res) => {
        if (res.data.status) {
          console.log(res.data.data);
          settopicdtl(res.data.data[0]);
          setselectedTid(value);
          setcurrentDiv("edit");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleSearch = () => {
    console.log(alltpc);
    if (alltpc !== undefined && alltpc.length > 0) {
      var data = alltpc.filter((t) => {
        if (t.topic.includes(searchText)) {
          return t;
        }
      });
      var tbl = [];
      for (var i = 0; i < data.length; i++) {
        tbl[i] = createData(
          data[i].topic,
          data[i].numberOfGroup.length,
          data[i].totalPost,
          data[i]._id
        );
      }
      setrow(tbl);
    }
  };
  const handleDelete = () => {
    Services.deletetopic(deleteId)
      .then((res) => {
        setshowalert(true);
        setalertmsg(res.data.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
        }, 3000);
        setcurrentDiv("lisiting");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSorting = (e) => {
    var originarr = alltpc
    var arr = [...alltpc];
    if (e.target.value === "a-z") {
      arr.sort(function (a, b) {
        var textA = a.topic.toUpperCase();
        var textB = b.topic.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (e.target.value === "z-a") {
      arr.sort(function (a, b) {
        var textA = a.topic.toUpperCase();
        var textB = b.topic.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }
    else{
      arr = [...originarr];
      
    }
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].topic,
        arr[i].numberOfGroup.length,
        arr[i].totalPost,
        arr[i]._id
      );
    }
    setrow(tbl);
  };
  return (
    <> <div className="content-main">
    <div className="users-info">
    <div className="users-info-sidebar">
              <div className="accordion" id="accordionExample"> 
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <button
                      type="button"
                      className="btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      onClick={()=>{setcurrentDiv("create")}}
                    >
                      Create New Topic
                    </button>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingThree">
                    <button
                      type="button"
                      className="btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                      onClick={()=>{setcurrentDiv("listing")}}
                    >
                      Topic Listing
                    </button>
                  </div>
                </div>
              </div>
            </div>
      <div className="users-info-content">
        <div className="users-info-content-main">
        <div className="uic-title">
            <h1>Topic Listing</h1>
            <div className="uic-fl-btn">
            <button
              className="btn"
              onClick={() => {
                setcurrentDiv("create");
              }}
            >
              Create Topic
            </button>
          </div>
        </div>

          

          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search Group"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setsearchText(e.target.value);
                }}
              />
              <i className="fa fa-search" onClick={handleSearch}></i>
            </div>

            <div className="uic-fl-btn sort-by-mn">   
            <div className="sort-by-mn-title">Sort by : </div>   
            <div className="sort-by-mn-select">
              <select onChange={handleSorting}>
                <option value="all">All</option>
                <option value="a-z">A-Z</option>
                <option value="z-a">Z-A</option>
              </select>
              </div>
            </div>
          </div>


          {showalert && <div className="alert alert-info">{alertmsg}</div>}

          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="uic-th-td w-10"
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="uic-table-td">
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                          className="uic-th-td w-20"
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return column.id !== "action" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-20"
                              >
                                {value}
                              </TableCell>
                            ) : (
                              <TableCell
                                key={column.id}
                                className="uic-th-td uic-table-icon w-15"
                              >
                                <a
                                  href="#"
                                  onClick={() => {
                                    handleEdit(value);
                                  }}
                                >
                                  <img src={edit} />
                                </a>
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#DeleteFromList"
                                  onClick={() => {
                                    setdeleteId(value);
                                  }}
                                >
                                  <img src={imgdelete} />
                                </a>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>

      {/* Modal Delete */}
      <div
        className="modal fade msg-popup"
        id="DeleteFromList"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgbigdelete} />
            </div>
            <h1>Are you sure you want to delete this group?</h1>
            <div className="row">
              <div className="col-6">
              <span className="msg-btn">
              <button
                type="button"
                className="btn btn-red"
                onClick={handleDelete}
                data-dismiss="modal"
              >
                Delete
              </button>
            </span>
              </div>
              <div className="col-6">
              <span className="msg-btn">
              <button
                type="button"
                className="btn"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </span>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      </div></div>
    </>
  );
};
export default TopicListing;
