import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Loader2 from "../../../../Images/loader2.gif";
import authService from '../../../../Services/auth.service';

const AddTag = ({ recallApi, id = 'addtag' }) => {

    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const dispatch = useDispatch()
    const [createInprocess, setCreateInprocess] = useState(false);
    const [showAlertAdd, setShowAlertAdd] = useState(false);
    const [alertMsgAdd, setAlertMsgAdd] = useState("");
    const [addErr, setAddErr] = useState("");
    const [addtag, setaddtag] = useState("");
    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }

    // handle tag onchange
    const handleAddChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        setaddtag(e.target.value.trim());
        if (e.target.value.length > 0) {
            setAddErr("")
        } else {
            setAddErr("Enter tags! ")
        }
    };

    // handle create tag
    const handleCreate = () => {
        if (addtag.length > 0) {
            setCreateInprocess(true);

            authService.createtag({
                name: addtag.trim(),
            })
                .then((res) => {
                    setCreateInprocess(false);
                    if (res.data.status) {
                        setShowAlertAdd(true);
                        setAlertMsgAdd(res.data.message);
                        recallApi()
                        setTimeout(() => {
                            setShowAlertAdd(false);
                            setAlertMsgAdd("");
                            document.querySelector('#addtag .close').click()
                        }, 3000);
                    } else {
                        setShowAlertAdd(true);
                        setAlertMsgAdd(res.data.message);
                        setTimeout(() => {
                            setShowAlertAdd(false);
                            setAlertMsgAdd("");
                        }, 3000);
                    }
                    handleClearAllStates()
                    setaddtag("");
                })
                .catch((e) => {
                    handleClearAllStates()
                    setCreateInprocess(false);
                    console.log(e);
                });
            setAddErr("");
        } else {
            setAddErr("Enter tags!");
        }
    };
    return (
        <>
            <div
                className="modal fade edit-user-details-popup"
                id={id}
                tabIndex="-1"
                role="dialog"
                data-backdrop="static"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Create tag</h2>
                            <button
                                type="button"
                                className="close"
                                //data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {
                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                    } else {
                                        $("#addtag").modal('hide')
                                    }
                                }}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {showAlertAdd && <div className="alert alert-info">{alertMsgAdd}</div>}
                            <div className="form-group">
                                <div className="form-group-label">Tag name*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="enter tags name"
                                        name="tag"
                                        onChange={handleAddChange}
                                        value={addtag}
                                    />
                                </div>
                                <span className="error">{addErr}</span>
                            </div>
                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {createInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleCreate}
                                    disabled={createInprocess}
                                >
                                    Create
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddTag