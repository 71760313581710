
const videosbycate = {};

const ReduVideoCategoryWise = (state = videosbycate, action) => {
  switch (action.type) {
    case "ADDCATVIDEOS":
      return {...state, [action.payload.id]: [...state[action.payload.id], ...action.payload.data]};
    case "FETCHCATVIDEOS":
      return {...state, [action.payload.id]: [...action.payload.data]};
    case "EMPTYCATVIDEOS":
      return {};
    default:
      return state;
  }
};

export default ReduVideoCategoryWise;
