import React, { useEffect, useState } from 'react'
import InviteListTable from './InviteListTable';
import authServiceCollaborator from '../../../Services/auth.service.collaborator';
import { useDispatch } from 'react-redux';
import authService from '../../../Services/auth.service';

const RevokedInviteList = ({ tabInformation }) => {
    const columns = [
        { id: "email", label: "Invitee email" },
        { id: "firstName", label: "Member name" },
        { id: "_id", label: "Actions" },
    ];
    const [loader, setLoader] = useState(false)
    const [rows, setRows] = useState([])
    const [allList, setAllList] = useState([])
    const [searchText, setsearchText] = useState("");
    const dispatch = useDispatch()
    const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions,setSuggestions]=useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
    // create function for create object
    function creatCollaborator(firstName, email, status, _id, inviteeName, userId) {
        return { firstName, email, status, _id, inviteeName, userId }
    }
    useEffect(() => {
        setLoader(true)
        getRevokedCollaboratorsListApi()
        getallusersSuggestionListApi()
    }, [tabInformation])
    // pending collaborator list api
    async function getRevokedCollaboratorsListApi(col, sort) {
        let query = ""
        query += col !== undefined && col !== null && col !== "" ? `?sortField=${col}` : ""
        query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
        try {
            const collaboratorRevokeListRes = await authServiceCollaborator.getRevokeCollaboratorsList()
            if (collaboratorRevokeListRes.data.status) {
                let data = collaboratorRevokeListRes.data.data
                const payload = {
                    revokedCount: data.length,
                }
                dispatch({
                    type: "SETINVITEECOUNT",
                    payload: payload,
                });
                if (data.length > 0) {
                    var newArr = []
                    for (let i = 0; i < data.length; i++) {
                        newArr[i] = creatCollaborator(
                            data[i].sharedUserDetails.firstName + ' ' + data[i].sharedUserDetails.lastName,
                            data[i].email,
                            data[i].teamMateInvitationStatus,
                            data[i]._id,
                            data[i].name,
                            data[i].sharedUserDetails.userId
                        )
                    }
                    setAllList(newArr)
                    setRows(newArr)
                }
                setLoader(false)
            } else {
                setAllList([])
                setRows([])
                const payload = {
                    revokedCount: 0,
                }
                dispatch({
                    type: "SETINVITEECOUNT",
                    payload: payload,
                });
                setLoader(false)
            }
        } catch (error) {
            setAllList([])
            setRows([])
            const payload = {
                revokedCount: 0,
            }
            dispatch({
                type: "SETINVITEECOUNT",
                payload: payload,
            });
            setLoader(false)
            return false
        }
    }
    async function getallusersSuggestionListApi() {
        try {
          const getSuggestionRes = await authService.getRevokedCollaboratorsSuggestionList()
    
           console.log(getSuggestionRes , "getSuggestionRes");
          let data = getSuggestionRes.data
          var arr = []
         if (data &&data.length !==undefined && data.length !== null && data.length>0 ){
          data.map((item)=>arr.push({ name:item.name}))
          console.log(arr);
          setSuggestionList(arr);
         }
        } catch (err) {
         
        }
      }
      useEffect(() => {
        if (suggestionBox && listIndex > -1) {
          var currentList =
            document.getElementById("suggestion-list").children[listIndex]
          // .firstElementChild.firstElementChild;
          if (currentList) {
            var text = currentList.querySelector('.cstm-cl-search-sug-history-title').innerText
            setsearchText(text);
          }
        }
      }, [listIndex]);

    //search using text
    const handleSearch = (text) => {
        setsearchText(text);
        setSuggestionBox(true);
        if (allList !== undefined && allList.length > 0) {
            var searchResult = allList.filter((invites) => {
                const searchTextLower = text.trim().toLowerCase();
                const inviteeNameLower = invites.inviteeName.toLowerCase();
                const memberNameLower = invites.firstName.toLowerCase();
                const emailLower = invites.email.toLowerCase();

                return (
                    inviteeNameLower.includes(searchTextLower) ||
                    memberNameLower.includes(searchTextLower) ||
                    emailLower.includes(searchTextLower)
                );
            });
            setRows(searchResult);
        }
    };
    return (
        <>
            <div className="uic-fl">
                <div className="uic-fl-search">
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => {
                      setsearchText(e.target.value);
                                    if (suggestionList!== undefined && suggestionList.length > 0) {
                          var searchResult = suggestionList.filter((user) => {
                            console.log(user,"user")
                              return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                          });
                          setSuggestions(searchResult);
                          setSuggestionBox(true)
                      }
                    }}
                    onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();}}}
                        className="search-user-input"
                    />
                     <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
               {suggestionBox && searchText.length > 0 && (
        <div className="suggestion-box">
          <ul>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className={index === listIndex ? 'selected' : ''}
              >
                {suggestion.name}
              </li>
            ))}
          </ul>
        </div>
      )}
                </div>
            </div>
            <InviteListTable allList={allList} columns={columns} rows={rows} loader={loader} setRows={setRows} recallApi={getRevokedCollaboratorsListApi} />
        </>
    )
}

export default RevokedInviteList