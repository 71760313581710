import React from "react";

const IconNavMdsExtrasFilled = () => {
  return (
    <div className="mds-icons filled">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.9689 18.9689C20.6802 18.7398 22 17.274 22 15.5V5.5C22 3.567 20.433 2 18.5 2H8.49999C6.72599 2 5.26024 3.31983 5.03113 5.03113C3.31983 5.26025 2 6.72599 2 8.5V18.5C2 20.433 3.567 22 5.5 22H15.5C17.274 22 18.7397 20.6802 18.9689 18.9689ZM18.5 3.5H8.49999C7.56807 3.5 6.78502 4.13739 6.563 5H15.5C17.433 5 19 6.567 19 8.5L19 17.437C19.8626 17.215 20.5 16.4319 20.5 15.5V5.5C20.5 4.39543 19.6046 3.5 18.5 3.5ZM11.25 10C11.25 9.58579 10.9142 9.25 10.5 9.25C10.0858 9.25 9.75 9.58579 9.75 10V12.75H7C6.58579 12.75 6.25 13.0858 6.25 13.5C6.25 13.9142 6.58579 14.25 7 14.25H9.75V17C9.75 17.4142 10.0858 17.75 10.5 17.75C10.9142 17.75 11.25 17.4142 11.25 17V14.25H14C14.4142 14.25 14.75 13.9142 14.75 13.5C14.75 13.0858 14.4142 12.75 14 12.75H11.25V10Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default IconNavMdsExtrasFilled;
