import { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
//import components
import Header from "../Dashboard/Header";
import LeftSidebar from "../Dashboard/LeftSidebar";
import ContentListing from "./ContentListing";
import ContentDetail from "./ContentDetail";
import ContentRelatedListing from "./ContentRelatedList";
import AllGroupMobile from "../Dashboard/AllGroupMobile";
import EventListPopup from "../Dashboard/EventListPopup";
import FilesPopup from "../Dashboard/FilesPopup";
import ForumListPopup from "../Dashboard/ForumListPopup";
import NotificationListPopup from "../Dashboard/NotificationListPopup";
import SavedPostPopup from "../Dashboard/SavedFilesPopup";
import { fetchsavedvideos, fetchsavedpost, setsocket } from "../../../Action";
import SavedVideos from "./SavedVideos";
import VideoHistory from "./VideoHistory";
import MyVideos from "./MyVideos";
import playstore from "../../../Images/playstore-logo.svg";
import appstore from "../../../Images/appstore-logo.svg";
import closebtn from "../../../Images/close-btn.svg";
import modellogo from "../../../Images/mds-model-logo.svg";
import modeimg from "../../../Images/model-img.png";
import PageNotFound from "../../PageNotFound";
import authService from "../../../Services/auth.service";
import AccessDeny from "../../AccessDeny";
const FrontendContentLibrary = () => {
  const dispatch = useDispatch();
  const migratePopup = useSelector((state) => {
    return state.ReduMigrateUserPopup;
  });

  const { action, videoid, sec } = useParams();
  const [selectedVideoId, setselectedVideoId] = useState("");
  const [selecteddiv, setselecteddiv] = useState("listing");
  const [leftsidebar, setleftsidebar] = useState(true);
  const [SelectedGroup, setSelectedGroup] = useState("");
  const [selectedDiv, setselectedDiv] = useState("dashboard");
  const [categoryId, setcategoryId] = useState("");
  const [topicSearch, settopicSearch] = useState("");
  const [prevDiv, setprevDiv] = useState("");
  const [pageNotFound, setPageNotFound] = useState(false);
  const [accessNotGranted, setAccessNotGranted] = useState(false);
  const search = useLocation().search;
  const listingtype = new URLSearchParams(search).get("type");
  const catId = new URLSearchParams(search).get("catid")
  const filtertype = new URLSearchParams(search).get("filter");
  const subfiltertype = new URLSearchParams(search).get("subfilter");
  const cleanedPathname = window.location.pathname.replace(/\/$/, "");
  const [openingpop, setopenpop] = useState({
    annou: false,
    files: false,
    save: false,
    event: false,
    forum: false,
    notifi: false,
    allgroup: false,
  });

  window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  useEffect(() => {
    if (
      localStorage.getItem("idtoken") === null ||
      localStorage.getItem("idtoken").length === 0 ||
      localStorage.getItem("userid") === null ||
      localStorage.getItem("userid").length === 0 ||
      localStorage.getItem("username") === null ||
      localStorage.getItem("username").length === 0 ||
      localStorage.getItem("role") === null ||
      localStorage.getItem("role") !== "user" ||
      localStorage.getItem("logintime") === undefined ||
      localStorage.getItem("logintime") === null
    ) {
      window.location.href = "/";
    }
    setSelectedGroup("");
    setselecteddiv("listing");
    setselectedDiv("dashboard");
    settopicSearch("");
    setselectedVideoId("");
    settopicSearch("");
    setcategoryId("");
    setprevDiv("");
    dispatch(fetchsavedpost());
    // dispatch(fetchsavedvideos());
    // dispatch(retrivehistoryvideos({page:1, limit:10}))
  }, []);
  useEffect(() => {


    if (action !== undefined && action.length > 0) setselecteddiv(action);
    if (action !== undefined && action.length > 0)
      setprevDiv(action);
    if (
      action !== undefined &&
      action !== "listing" &&
      action !== "myvideos" &&
      action !== "history" &&
      action !== "saved"
    )
      setcategoryId(action);
    if (action === undefined &&
      action === "listing" &&
      action === "myvideos" &&
      action === "history" &&
      action === "saved") {
      setselectedVideoId("");
      setcategoryId("");
    } else setselectedVideoId(action);
    if (action !== undefined && action !== null) {
      if (
        cleanedPathname !== "/videos"
      ) {
        // setPageNotFound(true);
      } else {
        if (cleanedPathname === "/videos") {
          if (
            listingtype !== undefined &&
            (listingtype !== null) & (listingtype.length !== 0)
          ) {
            if (listingtype === "all" || listingtype === "category") {
              if (
                filtertype !== undefined &&
                (filtertype !== null) & (filtertype.length !== 0) &&
                filtertype !== "recent" &&
                filtertype !== "popular" &&
                filtertype !== "comment"
              ) {
                setPageNotFound(true);
              } else {
                if (listingtype == "all") {
                  setPageNotFound(true);
                } else if (listingtype === "category") {
                  authService
                    .getcategorybyid(catId)
                    .then((res) => {
                      if (res.data.status) {
                        setPageNotFound(false);
                      } else {
                        setPageNotFound(true);
                      }
                    })
                    .catch((e) => {
                      setPageNotFound(false);
                      return false;
                    });
                } else {
                  setPageNotFound(false);
                }
              }
            } else {
              setPageNotFound(true);
            }
          } else {
            setPageNotFound(false);
          }
        }
      }
    }
    if (action !== undefined) {
      console.log(action, "action");
      authService.notAccessScreen({ videoId: action })
        .then((res) => {
          if (res.data.status) {
            if (res.data.access === true) {
              authService
                .getcontentvideobyidForUser(action)
                .then((detailRes) => {
                  if (!detailRes.data.status) {

                    setPageNotFound(false);
                    setAccessNotGranted(true);
                  }
                })
                .catch((err) => {
                  setPageNotFound(false);
                  setAccessNotGranted(true);
                })
            }
            setPageNotFound(false);
            setAccessNotGranted(false);
            if (res.data.access === false) {
              setPageNotFound(false);
              setAccessNotGranted(true);
            }
          } else {
            setAccessNotGranted(false);
            setPageNotFound(true);
          }
        })
        .catch((e) => {
          setPageNotFound(false);
          setAccessNotGranted(false);
          return false;
        });
    } else {
      setAccessNotGranted(false);
    }
    sessionStorage.setItem("previousurl", window.location.href)
  }, [action]);

  const resourceList = localStorage.getItem("accessResources")
  const isCollaborator = JSON.parse(localStorage.getItem('isCollaborator'))
  const videoResource = resourceList !== null && JSON.parse(resourceList).find(resource => resource.name === "Videos");
  return (
    <>
      {isCollaborator && videoResource !== undefined && videoResource ?
        <>
          {!pageNotFound && !accessNotGranted && (
            <>
              <Header
                setSelectedGroup={setSelectedGroup}
                setselectedDiv={setselectedDiv}
                settopicSearch={settopicSearch}
              />
              <div
                className={
                  leftsidebar
                    ? `all-content-main cstm-all-content-main  ${(action === undefined || action === "listing" ||
                      action === "myvideos" ||
                      action === "history" ||
                      action === "saved")
                      ? window.location.href.indexOf("videos") >
                        -1 ||
                        window.location.hostname ===
                        process.env.REACT_APP_TESTSITE_HOSTNAME
                        ? "cstm-all-library-content-main cstm-all-content-library-main-only"
                        : "cstm-all-library-content-main"
                      : ""
                    } ${action !== undefined && (action !== "listing" ||
                      action !== "myvideos" ||
                      action !== "history" ||
                      action !== "saved")
                      ? "cstm-all-library-video-details video-details-grid"
                      : "mn-cnt3-wd"
                    }`
                    : "all-content-main mn-cnt-both"
                }
              >
                {(action === undefined || action === "listing" ||
                  action === "myvideos" ||
                  action === "history" ||
                  action === "saved") ? (
                  <LeftSidebar
                    setSelectedGroup={setSelectedGroup}
                    setselectedDiv={setselectedDiv}
                    selectedDiv={selectedDiv}
                    SelectedGroup={SelectedGroup}
                    leftsidebar={leftsidebar}
                  />
                ) : (
                  <></>
                )}
                <div
                  className={
                    (action === undefined || action === "listing" ||
                      action === "myvideos" ||
                      action === "history" ||
                      action === "saved")
                      ? "all-content-main-center all-content-main-center"
                      : "all-content-main-center"
                  }
                >
                  {cleanedPathname === "/videos" &&
                    videoid === undefined &&
                    action !== "myvideos" &&
                    action !== "history" &&
                    action !== "saved" ? (
                    <ContentListing
                      categoryId={categoryId}
                      setcategoryId={setcategoryId}
                      selectedDiv={selectedDiv}
                    />
                  ) : selecteddiv === "myvideos" && action === "myvideos" ? (
                    <MyVideos />
                  ) : selecteddiv === "history" &&
                    action === "history" ? (
                    <VideoHistory />
                  ) : selecteddiv === "saved" && action === "saved" ? (
                    <SavedVideos />
                  ) : action !== undefined && (action !== "listing" ||
                    action !== "myvideos" ||
                    action !== "history" ||
                    action !== "saved") ? (
                    <ContentDetail
                      selectedVideoId={selectedVideoId}
                      prevDiv={prevDiv}
                      categoryId={categoryId}
                    />
                  ) : (
                    <></>
                  )}
                  {openingpop.files ? (
                    <FilesPopup setclose={setopenpop} openingpop={openingpop} />
                  ) : (
                    <></>
                  )}
                  {openingpop.save ? (
                    <SavedPostPopup setclose={setopenpop} openingpop={openingpop} />
                  ) : (
                    <></>
                  )}
                  {openingpop.event ? (
                    <EventListPopup setclose={setopenpop} openingpop={openingpop} />
                  ) : (
                    <></>
                  )}
                  {openingpop.forum ? (
                    <ForumListPopup setclose={setopenpop} openingpop={openingpop} />
                  ) : (
                    <></>
                  )}
                  {openingpop.notifi ? (
                    <NotificationListPopup
                      setclose={setopenpop}
                      openingpop={openingpop}
                    />
                  ) : (
                    <></>
                  )}
                  {openingpop.allgroup ? (
                    <AllGroupMobile setclose={setopenpop} openingpop={openingpop} />
                  ) : (
                    <></>
                  )}
                </div>
                {(action !== undefined && (action !== "listing" &&
                  action !== "myvideos" &&
                  action !== "history" &&
                  action !== "saved")) ? <ContentRelatedListing /> : <></>}
              </div>
              {migratePopup ? (
                <div className="desk-globle-model-box globle-model-box">
                  <div class="mds-model-box-main">
                    <div class="mds-model-box-body">
                      <div class="close-btn">
                        <img
                          src={closebtn}
                          alt="close-btn"
                          onClick={() => {
                            dispatch({ type: "ClosePopup" });
                          }}
                        />
                      </div>
                      <div class="mds-model-logo">
                        <img src={modellogo} alt="close-btn" />
                      </div>
                      <div class="mds-model-heading">
                        <h3>A New Way of Exploring Our Community’s Content</h3>
                      </div>
                      <div class="mds-model-main-img">
                        <img src={modeimg} alt="close-btn" />
                      </div>
                      <div class="mds-model-button-container">
                        <a
                          href="https://apps.apple.com/app/id1636838955"
                          target="_blank"
                          class="mds-button appstore"
                        >
                          <img src={appstore} alt="App store" />
                          <p>
                            <span>Download on the</span>
                            App Store
                          </p>
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.app.mdscommunity"
                          target="_blank"
                          class="mds-button playstore"
                        >
                          <img src={playstore} alt="Playstore" />
                          <p>
                            <span>Get it on</span>
                            Google Play
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* <Footer openingpop={openingpop} setopenpop={setopenpop} /> */}
            </>
          )}
          {accessNotGranted ? (
            <AccessDeny />
          ) : pageNotFound ? (
            <PageNotFound />
          ) : (
            <></>
          )}
        </>
        : isCollaborator !== null && !isCollaborator ?
          <>
            {!pageNotFound && !accessNotGranted && (
              <>
                <Header
                  setSelectedGroup={setSelectedGroup}
                  setselectedDiv={setselectedDiv}
                  settopicSearch={settopicSearch}
                />
                <div
                  className={
                    leftsidebar
                      ? `all-content-main cstm-all-content-main  ${(action === undefined || action === "listing" ||
                        action === "myvideos" ||
                        action === "history" ||
                        action === "saved")
                        ? window.location.href.indexOf("videos") >
                          -1 ||
                          window.location.hostname ===
                          process.env.REACT_APP_TESTSITE_HOSTNAME
                          ? "cstm-all-library-content-main cstm-all-content-library-main-only"
                          : "cstm-all-library-content-main"
                        : ""
                      } ${action !== undefined && (action !== "listing" ||
                        action !== "myvideos" ||
                        action !== "history" ||
                        action !== "saved")
                        ? "cstm-all-library-video-details video-details-grid"
                        : "mn-cnt3-wd"
                      }`
                      : "all-content-main mn-cnt-both"
                  }
                >
                  {(action === undefined || action === "listing" ||
                    action === "myvideos" ||
                    action === "history" ||
                    action === "saved") ? (
                    <LeftSidebar
                      setSelectedGroup={setSelectedGroup}
                      setselectedDiv={setselectedDiv}
                      selectedDiv={selectedDiv}
                      SelectedGroup={SelectedGroup}
                      leftsidebar={leftsidebar}
                    />
                  ) : (
                    <></>
                  )}
                  <div
                    className={
                      (action === undefined || action === "listing" ||
                        action === "myvideos" ||
                        action === "history" ||
                        action === "saved")
                        ? "all-content-main-center all-content-main-center"
                        : "all-content-main-center"
                    }
                  >
                    {cleanedPathname === "/videos" &&
                      videoid === undefined &&
                      action !== "myvideos" &&
                      action !== "history" &&
                      action !== "saved" ? (
                      <ContentListing
                        categoryId={categoryId}
                        setcategoryId={setcategoryId}
                        selectedDiv={selectedDiv}
                      />
                    ) : selecteddiv === "myvideos" && action === "myvideos" ? (
                      <MyVideos />
                    ) : selecteddiv === "history" &&
                      action === "history" ? (
                      <VideoHistory />
                    ) : selecteddiv === "saved" && action === "saved" ? (
                      <SavedVideos />
                    ) : action !== undefined && (action !== "listing" ||
                      action !== "myvideos" ||
                      action !== "history" ||
                      action !== "saved") ? (
                      <ContentDetail
                        selectedVideoId={selectedVideoId}
                        prevDiv={prevDiv}
                        categoryId={categoryId}
                      />
                    ) : (
                      <></>
                    )}
                    {openingpop.files ? (
                      <FilesPopup setclose={setopenpop} openingpop={openingpop} />
                    ) : (
                      <></>
                    )}
                    {openingpop.save ? (
                      <SavedPostPopup setclose={setopenpop} openingpop={openingpop} />
                    ) : (
                      <></>
                    )}
                    {openingpop.event ? (
                      <EventListPopup setclose={setopenpop} openingpop={openingpop} />
                    ) : (
                      <></>
                    )}
                    {openingpop.forum ? (
                      <ForumListPopup setclose={setopenpop} openingpop={openingpop} />
                    ) : (
                      <></>
                    )}
                    {openingpop.notifi ? (
                      <NotificationListPopup
                        setclose={setopenpop}
                        openingpop={openingpop}
                      />
                    ) : (
                      <></>
                    )}
                    {openingpop.allgroup ? (
                      <AllGroupMobile setclose={setopenpop} openingpop={openingpop} />
                    ) : (
                      <></>
                    )}
                  </div>
                  {(action !== undefined && (action !== "listing" &&
                    action !== "myvideos" &&
                    action !== "history" &&
                    action !== "saved")) ? <ContentRelatedListing /> : <></>}
                </div>
                {migratePopup ? (
                  <div className="desk-globle-model-box globle-model-box">
                    <div class="mds-model-box-main">
                      <div class="mds-model-box-body">
                        <div class="close-btn">
                          <img
                            src={closebtn}
                            alt="close-btn"
                            onClick={() => {
                              dispatch({ type: "ClosePopup" });
                            }}
                          />
                        </div>
                        <div class="mds-model-logo">
                          <img src={modellogo} alt="close-btn" />
                        </div>
                        <div class="mds-model-heading">
                          <h3>A New Way of Exploring Our Community’s Content</h3>
                        </div>
                        <div class="mds-model-main-img">
                          <img src={modeimg} alt="close-btn" />
                        </div>
                        <div class="mds-model-button-container">
                          <a
                            href="https://apps.apple.com/app/id1636838955"
                            target="_blank"
                            class="mds-button appstore"
                          >
                            <img src={appstore} alt="App store" />
                            <p>
                              <span>Download on the</span>
                              App Store
                            </p>
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.app.mdscommunity"
                            target="_blank"
                            class="mds-button playstore"
                          >
                            <img src={playstore} alt="Playstore" />
                            <p>
                              <span>Get it on</span>
                              Google Play
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {/* <Footer openingpop={openingpop} setopenpop={setopenpop} /> */}
              </>
            )}
            {accessNotGranted ? (
              <AccessDeny />
            ) : pageNotFound ? (
              <PageNotFound />
            ) : (
              <></>
            )}
          </> : isCollaborator && videoResource === undefined ?
            <AccessDeny /> : <></>}
    </>
  );
};
export default FrontendContentLibrary;
