import React, { useState } from "react";
import "./CheckInModal.css";
import { Check, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CloseFillIcon from "remixicon-react/CloseFillIcon.js";
import { LoadingButton } from "@mui/lab";

const CheckInModal = ({
  checkInModal = false,
  modalHideShowHandle = () => {},
  userDetail,
  handleCheckInButtonClick,
  loader,
  loaderRowId,
  loaderUserId,
  checkInLoader,
  eventSourceCheckinLoader,
}) => {
  const [scroll, setScroll] = React.useState("paper");

  //Attendee type
  const attendeeType =
    userDetail &&
    Object.keys(userDetail)?.length > 0 &&
    userDetail?.roleDetail?.map((detail) => detail.role).join(", ");

  return (
    <>
      <Dialog
        scroll={scroll}
        open={checkInModal}
        onClose={modalHideShowHandle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="sm"
        sx={{
          "@media screen and (max-width: 600px)": {
            "& .MuiPaper-root": {
              height: "100%",
              maxHeight: "100%",
              maxWidth: "100%",
              margin: "0",
              borderRadius: "0",
            },
          },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography
            variant="h3"
            sx={{
              fontWeight: "600",
              color: "#000000DE",
              letterSpacing: "-0.4px",
              lineHeight: "26px",
            }}
          >
            Attendee profile
          </Typography>
          <IconButton
            className="close-icon"
            onClick={modalHideShowHandle}
            sx={{ flexShrink: "0" }}
          >
            <CloseFillIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {loader && !checkInLoader && !eventSourceCheckinLoader ? (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "calc(600px - 24px)",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {userDetail && Object.keys(userDetail).length !== 0 ? (
                <Grid container direction={"column"} spacing={"24px"}>
                  {userDetail?.profileImg ? (
                    <Grid item>
                      <Box
                        sx={{
                          width: "auto",
                          height: "auto",
                          objectFit: "cover",
                          objectPosition: "center",
                          overflow: "hidden",
                          "& img": { borderRadius: "8px" },
                        }}
                      >
                        <img
                          src={userDetail?.profileImg}
                          width={240}
                          height={240}
                          className="user-img object-cover"
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item>
                    <Typography variant="h3" color="rgba(0, 0, 0, 0.87)">
                      {`${userDetail?.first_name} ${userDetail?.last_name}`}
                    </Typography>
                    <Typography variant="body2" color="rgba(0, 0, 0, 0.60)">
                      {attendeeType}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <List
                      sx={{
                        "& .MuiListItemText-root": {
                          margin: "4px 0",
                        },
                        "& .MuiListItem-root ": {
                          padding: "4px 0",
                        },
                      }}
                      disablePadding
                    >
                      {userDetail &&
                        Object.keys(userDetail?.eventCheckInData).length >
                          0 && (
                          <ListItem
                            disableGutters
                            dense={false}
                            divider={false}
                          >
                            <ListItemText
                              primary="Event"
                              secondary={userDetail?.eventCheckInData?.title}
                            />
                          </ListItem>
                        )}
                      {userDetail &&
                        userDetail?.purchased_ticket?.length > 0 && (
                          <ListItem
                            disableGutters
                            dense={false}
                            divider={false}
                          >
                            <ListItemText
                              primary="Ticket"
                              secondary={userDetail?.purchased_ticket[0]?.name}
                            />
                          </ListItem>
                        )}
                    </List>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <Typography variant="h3">Check-ins</Typography>
                    {userDetail &&
                      Object.keys(userDetail?.eventCheckInData)?.length > 0 && (
                        <List
                          sx={{
                            "& .MuiListItemText-root": {
                              margin: "4px 0",
                            },
                          }}
                          disablePadding
                        >
                          <ListItem
                            disableGutters={true}
                            s
                            dense={false}
                            divider
                          >
                            <ListItemText
                              primary={userDetail?.eventCheckInData?.title}
                              primaryTypographyProps={false}
                            />
                            {checkInLoader &&
                            userDetail?._id === loaderUserId &&
                            !loaderRowId ? (
                              <LoadingButton
                                sx={{
                                  "& svg .MuiCircularProgress-circle": {
                                    stroke: "#000",
                                  },
                                }}
                                size="small"
                                loading={true}
                                variant="text"
                              ></LoadingButton>
                            ) : (
                              <Button
                                sx={{ flexShrink: "0" }}
                                variant="text"
                                color={
                                  userDetail?.eventCheckInData?.status ===
                                  "checkIn"
                                    ? "success"
                                    : "primary"
                                }
                                size="small"
                                startIcon={
                                  userDetail?.eventCheckInData?.status ===
                                    "checkIn" && <Check />
                                }
                                onClick={() => {
                                  handleCheckInButtonClick(
                                    userDetail?._id,
                                    userDetail?.eventCheckInData?.status,
                                    "event",
                                    "",
                                    true
                                  );
                                }}
                              >
                                {userDetail?.eventCheckInData?.status ===
                                "checkIn"
                                  ? "Checked"
                                  : "Check in"}
                              </Button>
                            )}
                          </ListItem>
                        </List>
                      )}
                    {userDetail &&
                      userDetail?.event_activity?.map((activityItem) => (
                        <List
                          sx={{
                            "& .MuiListItemText-root": {
                              margin: "4px 0",
                            },
                          }}
                          disablePadding
                        >
                          <ListItem
                            disableGutters={true}
                            s
                            dense={false}
                            divider
                          >
                            <ListItemText
                              primary={activityItem?.name}
                              primaryTypographyProps={false}
                            />
                            {checkInLoader &&
                            activityItem._id === loaderRowId &&
                            userDetail?._id === loaderUserId ? (
                              <LoadingButton
                                sx={{
                                  "& svg .MuiCircularProgress-circle": {
                                    stroke: "#000",
                                  },
                                }}
                                size="small"
                                loading={true}
                                variant="text"
                              ></LoadingButton>
                            ) : (
                              <Button
                                sx={{ flexShrink: "0" }}
                                variant="text"
                                color={
                                  activityItem?.status === "checkIn"
                                    ? "success"
                                    : "primary"
                                }
                                size="small"
                                startIcon={
                                  activityItem?.status === "checkIn" && (
                                    <Check />
                                  )
                                }
                                onClick={() => {
                                  handleCheckInButtonClick(
                                    userDetail?._id,
                                    activityItem?.status,
                                    "activity",
                                    activityItem._id,
                                    true
                                  );
                                }}
                              >
                                {activityItem?.status === "checkIn"
                                  ? "Checked"
                                  : "Check in"}
                              </Button>
                            )}
                          </ListItem>
                        </List>
                      ))}
                  </Grid>

                  <Grid
                    item
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h3">Attendee’s info</Typography>
                    <List
                      sx={{
                        "& .MuiListItemText-root": {
                          margin: "4px 0",
                        },
                      }}
                      disablePadding
                    >
                      <ListItem disableGutters={true} dense={true}>
                        <ListItemText
                          primary="Email"
                          secondary={
                            <Link
                              href={`mailto:${userDetail["Preferred Email"]}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{ textDecoration: "none", color: "inherit" }} // Optional: to remove underline and keep the default text color
                            >
                              {userDetail["Preferred Email"]}
                            </Link>
                          }
                        />
                      </ListItem>
                      {userDetail &&
                        userDetail?.event_questions?.questions?.map(
                          (item, index) => {
                            const answer = item?.answer;
                            const urlRegex = /(https?:\/\/[^\s]+)/g;
                            const isLink = urlRegex.test(answer);
                            return (
                              <>
                                <ListItem
                                  disableGutters={true}
                                  dense={true}
                                  key={index}
                                >
                                  <ListItemText
                                    primary={item?.question}
                                    secondary={
                                      isLink ? (
                                        <Typography
                                          component="span"
                                          sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "1",
                                            WebkitBoxOrient: "vertical",
                                          }}
                                        >
                                          <Link
                                            href={answer}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {answer}
                                          </Link>
                                        </Typography>
                                      ) : (
                                        answer
                                      )
                                    }
                                  />
                                  {/* <IconButton
                                    color="default"
                                    className="pinicon"
                                    size="medium"
                                    onClick={() => pinnedHandle(item)}
                                  >
                                    {item?.pinned ? (
                                      <PushPin />
                                    ) : (
                                      <PushPinOutlined />
                                    )}
                                  </IconButton> */}
                                </ListItem>
                              </>
                            );
                          }
                        )}
                    </List>
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  variant="error-text"
                  component={"p"}
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  No data found
                </Typography>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CheckInModal;
