import React from "react";
import PropTypes from "prop-types";
import IncomingMessageTime from "./IncomingMessageTime";

import PlayIcon from "../../../../components/Icons/VideoPlayerIcons/PlayIcon";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageBubbleInner.css"; //This css used for the all the message bubble
import "../Styles/IncomingMessageVideo.css";

const IncomingMessageVideo = ({
  type = "Horizontal",
  hasTime,
  isEdited = false,
  time = "00:12",
  src = [
    "https://images.unsplash.com/photo-1705392852198-2a2334db0314?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ],
  isInProgress,
}) => {
  return (
    <>
      <div className="messagebubble-sec messagebubblevideo incomingmessagevideo">
        <div className="messagebubble-container">
          <div className="messagebubble-wrapper">
            {type === "Horizontal" && (
              <div
                className={`massage-insider ${
                  type === "Horizontal" && "Horizontal"
                }`}
              >
                <img src={src} alt="HorizontalImages" />
              </div>
            )}

            {type === "Vertical" && (
              <div
                className={`massage-insider ${
                  type === "Vertical" && "Vertical"
                }`}
              >
                <img src={src} alt="VerticalImages" />
              </div>
            )}

            {type === "Squre" && (
              <div className={`massage-insider ${type === "Squre" && "Squre"}`}>
                <img src={src} alt="SqureImages" />
              </div>
            )}
            <div className="msg-video-icon">
              <PlayIcon
                height={24}
                width={24}
                iconColor="var(--color-text-static-white)"
              />
            </div>
            {hasTime && (
              <div className="msg-time-sec">
                <IncomingMessageTime
                  hasBackdrop
                  isEdited={isEdited}
                  time={time}
                  isInProgress={isInProgress}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

IncomingMessageVideo.propTypes = {
  type: PropTypes.oneOf(["Horizontal", "Vertical", "Squre"]),
  hasTime: PropTypes.bool,
  isEdited: PropTypes.bool,
  time: PropTypes.string,
  src: PropTypes.array,
  isInProgress: PropTypes.bool,
};

export default IncomingMessageVideo;
