import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "cropperjs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Loader from "../../Images/loader.gif";
import AlertComponent from "../../Hooks/AlertComponent";
import UserInfoSideBar from "./UserInfoSideBar";
import {
  useGetImportedMongoUsersQuery,
  useImportCsvToMongoMutation,
} from "../../Services/users";
import loading from "../../Images/loader.gif";
import moment from "moment";
import ImportedUsersModal from "./Modals/ImportedUsersModal";

const columns = [
  { id: "createdAt", label: "Created date" },
  { id: "addCount", label: "Added" },
  { id: "skipCount", label: "Updated" },
  { id: "totalCount", label: "Total" },
];

function createData(createdAt, addCount, skipCount, totalCount, action) {
  return {
    createdAt,
    addCount,
    skipCount,
    totalCount,
    action,
  };
}
const ImportedMongoUsers = () => {
  const [users, setUsers] = useState([]);
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [importCsv, { isLoading: importLoader }] =
    useImportCsvToMongoMutation();
  const [sortType, setSortType] = useState("");
  const [viewType, setViewType] = useState("added");
  const [usersList, setUsersList] = useState([]);
  const [rowId, setRowId] = useState("");
  const [totalRows, setTotalRows] = useState(0);

  //get imported user list
  const { data: importedUserList, isFetching: dataLoader } =
    useGetImportedMongoUsersQuery({
      sortType: sortType,
      page: page + 1,
      limit: rowsPerPage,
    });

  useEffect(() => {
    if (importedUserList) {
      if (importedUserList?.status) {
        if (importedUserList.data) {
          const data = importedUserList.data;
          if (data.length > 0) {
            var newarr = [];
            for (var i = 0; i < data.length; i++) {
              newarr[i] = createData(
                data[i] &&
                data[i].createdAt &&
                moment(data[i].createdAt).format("MM/DD/YYYY hh:mm a"),
                data[i].addedEmails.length,
                data[i].skipEmails.length,
                data[i].addedEmails.length + data[i].skipEmails.length,
                data[i]._id
              );
            }
            setTotalRows(importedUserList.totalData);
            setRow(newarr);
            setUsersList(data);
          }
        }
      }
    }
  }, [importedUserList]);

  const onDrop = (acceptedFiles) => {
    assignFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    maxSizeBytes: 10485760,
    onDrop,
  });

  const assignFiles = (acceptedFile) => {
    if (
      acceptedFile.length > 0 &&
      acceptedFile[0] !== undefined &&
      acceptedFile[0] !== null &&
      acceptedFile[0] !== ""
    ) {
      const file = acceptedFile[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (fileExtension === "csv") {
        importFileData(acceptedFile);
      } else {
        setShowAlert(true);
        setAlertMsg("Please select a CSV file");
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 2000);
      }
    } else {
      setShowAlert(true);
      setAlertMsg("Please select file");
      setTimeout(() => {
        setShowAlert(false);
        setAlertMsg("");
      }, 2000);
    }
  };

  const importFileData = async (acceptedFile) => {
    const formData = new FormData();
    formData.append("file", acceptedFile[0]);
    const imported = await importCsv(formData);
    if (imported) {
      if (imported?.data?.status) {
        setShowAlert(true);
        setAlertMsg(imported.data.message);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 2000);
      }
    }
  };
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });

  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <div className="users-info">
        <UserInfoSideBar />
        <div className="users-info-content">
          <div className="users-info-content-main">
            <div className="uic-title">
              <h1>All imported users</h1>
            </div>
            <div className="uic-fl-mn">
              <div className="uic-fl-mn-left"></div>
              <div className="uic-fl-mn-right">
                <div className="uic-fl-import-btn">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span className="import text">Import</span>
                  </div>
                  {importLoader ? (
                    <div className="import-loading-image">
                      <span className="cstm-pli-bg-importfile">
                        <img src={loading} />
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            {showAlert && <div className="alert alert-info">{alertMsg}</div>}

            <Paper className="uic-tb-mn">
              <TableContainer className="uic-table-main">
                <Table className="uic-table">
                  <TableHead className="uic-table-th">
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            column.id === "action"
                              ? "uic-th-td w-8"
                              : "uic-th-td w-10"
                          }
                        >
                          {" "}
                          {column.id === "createdAt" ? (
                            <>
                              <span className="custom-sorting">
                                <i
                                  className="fa fa-angle-down"
                                  onClick={(e) => {
                                    setSortType("Asc");
                                  }}
                                ></i>
                                <i
                                  className="fa fa-angle-up"
                                  onClick={(e) => {
                                    setSortType("Desc");
                                  }}
                                ></i>
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="uic-table-td">
                    {dataLoader ? (
                      <div className="cstm-data-load">
                        <img src={Loader} />{" "}
                      </div>
                    ) : (
                      rows &&
                      rows.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                            className="uic-th-td w-20"
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return column.id === "createAt" ? (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td w-10"
                                >
                                  {value}
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td w-10"
                                >
                                  {row[column.id] !== 0 ? (
                                    <Link
                                      // to={`/user/edituser?id=${row.action}`}
                                      onClick={() => {
                                        switch (column.id) {
                                          case "addCount":
                                            setViewType("added");
                                            break;
                                          case "skipCount":
                                            setViewType("skip");
                                            break;
                                          case "totalCount":
                                            setViewType("total");
                                            break;
                                        }
                                        setModalOpen(true);
                                        setRowId(row.action);
                                      }}
                                      className="cstm-view-users-count"
                                    >
                                      {value}
                                    </Link>
                                  ) : (
                                    row[column.id]
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
                {!dataLoader && rows.length === 0 ? (
                  <div className="no-data-found">
                    <p>No Data Found </p>
                  </div>
                ) : (
                  <></>
                )}{" "}
              </TableContainer>
              <TablePagination
                className="uic-pagination"
                rowsPerPageOptions={[20, 50, 100, 200]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
      </div>
      {modalOpen && (
        <ImportedUsersModal
          setModalOpen={setModalOpen}
          usersList={usersList}
          type={viewType}
          rowId={rowId}
        />
      )}
      <AlertComponent />
    </>
  );
};
export default ImportedMongoUsers;
