import { useEffect, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Switch from "react-switch";
import Service from "../../Services/auth.service";
import arrowleft from "../../Images/arrow-left.png";
import Loader2 from "../../Images/loader2.gif";
import AlertComponent from "../../Hooks/AlertComponent";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
const EditAdmin = ({ setselecteddiv, selectedid }) => {
  const [Inprocess, setInprocess] = useState(false);
  const [admindetail, setadmindetail] = useState({
    first_name: "",
    last_name: "",
    username: "",
    role: "",
    contact_number: "",
    resource: [],
  });
  const [allresource, setallresource] = useState([]);
  const [allroles, setallroles] = useState([]);
  const [err, setErr] = useState({
    first_name: "",
    last_name: "",
    username: "",
    role: "",
    contact_number: "",
    resource: [],
  });
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })

  useEffect(() => {
    handleClearAllStates()
    Service.getallroles()
      .then((res) => {
        if (res.data.status) {
          setallroles(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Service.getallresource()
      .then((res) => {
        if (res.data.status) {
          setallresource(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {

    if (selectedid !== undefined && selectedid.length > 0) {
      Service.getadminbyid(selectedid)
        .then((res) => {
          if (res.data.status) {
            var obj = {
              first_name: res.data.data.first_name,
              last_name: res.data.data.last_name,
              username: res.data.data.username,
              contact_number: res.data.data.contact_number,
              role: res.data.data.role._id,
            };
            var arr = [];
            for (var i = 0; i < res.data.data.resource.length; i++) {
              arr[i] = res.data.data.resource[i]._id;
            }
            obj = { ...obj, resource: [...arr] };
            setadmindetail({ ...obj });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedid]);
  const handleChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    if (e.target.value.length === 0) {
      setErr({
        ...err,
        [e.target.name]: `Enter ${e.target.name.replace("_", " ")}!`,
      });
    } else {
      setErr({ ...err, [e.target.name]: "" });
    }
    setadmindetail({ ...admindetail, [e.target.name]: e.target.value });
  };
  const handleCreate = () => {
    var obj = { ...err };
    var err_exist = false;
    for (let key in admindetail) {
      if (admindetail[key].length === 0) {
        err_exist = true;
        obj = { ...obj, [key]: `Enter ${key.replace("_", " ")}!` };
      }
    }
    if (Object.keys(obj).length > 0) {
      const emptyKeys = Object.keys(obj).filter((key) => obj[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    setErr({ ...obj });
    if (!err_exist) {
      setInprocess(true);
      Service.editadmindetail({ id: selectedid, data: admindetail })
        .then((res) => {
          if (res.data.status) {
            setInprocess(false);
            setshowalert(true);
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false);
              setalertmsg("");
            }, 3000);
            setselecteddiv("listing");
          } else {
            setInprocess(false);
            setshowalert(true);
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false);
              setalertmsg("");
            }, 3000);
          }
          handleClearAllStates()
        })
        .catch((e) => {
          setInprocess(false);
          console.log(e);
        });
    }
  };

  useEffect(() => {
    if (stayOnPage !== null && !stayOnPage) {
      setselecteddiv("listing");
      setadmindetail({
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        role: "",
        contact_number: "",
        password: "",
        conpassword: "",
        resource: [],
      });


    }
    else if (stayOnPage !== null && stayOnPage) {

    }

  }, [stayOnPage])

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div>
            <div className="um-back">
              <button
                type="button"
                className="back-btn"
                onClick={() => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })

                  } else {
                    //navigate("/channel/channelListing")
                    setadmindetail({
                      first_name: "",
                      last_name: "",
                      username: "",
                      role: "",
                      contact_number: "",
                      resource: [],
                    });
                    setselecteddiv("listing");

                  }
                }}

              >
                <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
                {/* <img src={arrowleft} /> */}
                Back
              </button>
            </div>

            <div className="uic-title">
              <h1>Edit Admin</h1>
            </div>
            {showalert && <div className="alert alert-info">{alertmsg}</div>}
            <div className="row">
              <div className="col-sm-6 form-group">
                <div className="form-group-label">First Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    name="first_name"
                    value={admindetail.first_name}
                    onChange={handleChange}
                  />
                </div>
                <span className="error">{err.first_name}</span>
              </div>
              <div className="col-sm-6 form-group">
                <div className="form-group-label">Last Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    name="last_name"
                    value={admindetail.last_name}
                    onChange={handleChange}
                  />
                </div>
                <span className="error">{err.last_name}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 form-group">
                <div className="form-group-label">User name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    name="username"
                    value={admindetail.username}
                    onChange={handleChange}
                  />
                </div>
                <span className="error">{err.username}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 form-group">
                <div className="form-group-label">Select Role*</div>
                <div className="form-group-ct">
                  <select
                    name="role"
                    value={admindetail.role}
                    onChange={handleChange}
                  >
                    <option value="">Select Role</option>
                    {allroles.map((role) => {
                      if (
                        role._id !== process.env.REACT_APP_DB_NORMALUSER_ROLEID
                      )
                        return (
                          <option value={role._id}>{role.role_name}</option>
                        );
                      else return <></>;
                    })}
                  </select>
                </div>
                <span className="error">{err.role}</span>
              </div>
              <div className="col-sm-6 form-group">
                <div class="form-group-label">Admin contact no*</div>
                <div className="form-group-ct">
                  <input
                    type="number"
                    name="contact_number"
                    value={admindetail.contact_number}
                    onChange={(e) => {
                      if (e.target.value.length <= 10) handleChange(e);
                    }}
                    maxlength="10"
                    min="0"
                  />
                </div>
                <span className="error">{err.contact_number}</span>
              </div>
            </div>

            <hr />

            <h5 class="gl-title-sub">Resources</h5>
            <span className="error"> {err.resource} </span>
            <div className="plan-resources-main">
              <div className="plan-resources-main-head">
                <div className="plan-resources-main-left">
                  <div className="resources-na-title">Resources name</div>
                </div>
                <div className="plan-resources-main-right">
                  <div className="resources-na-title">Resources access</div>
                </div>
              </div>
              {allresource.length > 0 ? (
                allresource.map((r) => {
                  return (
                    <>
                      <div className="plan-resources-main-body">
                        <div className="plan-resources-main-left">
                          <label class="plan-reso-bd-title">
                            <span>{r.resource_name}</span>
                          </label>
                        </div>
                        <div className="plan-resources-main-right">
                          <label class="plan-reso-bd-sub">
                            <Switch
                              onChange={(checked) => {
                                checked
                                  ? setadmindetail({
                                    ...admindetail,
                                    resource: [
                                      ...admindetail.resource,
                                      r._id,
                                    ],
                                  })
                                  : setadmindetail({
                                    ...admindetail,
                                    resource: [
                                      ...admindetail.resource.filter(
                                        (admres) => {
                                          if (admres !== r._id) return admres;
                                        }
                                      ),
                                    ],
                                  });
                              }}
                              checked={admindetail.resource.includes(r._id)}
                            />
                          </label>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </div>

            <div className="cyp-btm-btn">
              {Inprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  data-dismiss="modal"
                  disabled={Inprocess}
                >
                  Update Admin
                </button>
              )}
              <button
                className="btn2"
                onClick={() => {
                  setadmindetail({
                    first_name: "",
                    last_name: "",
                    username: "",
                    role: "",
                    contact_number: "",
                    resource: [],
                  });
                  setselecteddiv("listing");
                }}
              >
                Discard
              </button>
            </div>
          </div>
        </div>
      </div>
      <AlertComponent setcurrentDiv={setselecteddiv} />
    </>
  );
};
export default EditAdmin;
