import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../../Images/loader.gif";
import Service from "../../../Services/auth.service.event";

const SoldTicketEventModal = ({ setShowSoldTicketEventModal, eventId }) => {
  const ticketColumns = [
    { id: "number", label: "No." },
    { id: "ticketName", label: "Ticket Name" },
    { id: "quantity", label: "Quantity" },
    { id: "usersPurchase", label: "No. of Users Purchased" },
  ];

  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(20);
  const [loader, setLoader] = useState(true);
  const [soldTicketEventList, setSoldTicketEventList] = useState([]);

  useEffect(() => {
    soldTicketApi();
  }, []);
  // Pagination onChange function
  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage);
  };

  const soldTicketApi = async () => {
    try {
      const soldTicketRes = await Service.getSoldTicket(eventId);
      setSoldTicketEventList(soldTicketRes.data.data);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log("Error:", err);
    }
  };
  return (
    <>
      <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
        <div className="desk-globle-model-box-inner">
          <div className="modal-content">
            <div class="cstm-replace-pp">
              <span
                class="cstm-rpp-close-btn"
                onClick={() => {
                  setShowSoldTicketEventModal(false);
                  document.body.classList.remove("modal-open");
                }}
              >
                ×
              </span>
              <div class="cstm-rpp-title">List of sold tickets</div>
              <div className="cstm-cstm-viewlist">
                <Paper className="uic-tb-mn ">
                  <TableContainer className=" uic-table-main ">
                    <Table className="">
                      <TableHead className="uic-table-th">
                        <TableRow>
                          {ticketColumns.map((column) => (
                            <TableCell className="uic-th-td" key={column.id}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className="uic-table-td">
                        {loader ? (
                          <div className="cstm-data-load">
                            <img src={Loader} />
                          </div>
                        ) : soldTicketEventList?.length > 0 &&
                          soldTicketEventList !== undefined ? (
                          soldTicketEventList
                            .slice(
                              userPage * userRowsPerPage,
                              userPage * userRowsPerPage + userRowsPerPage
                            )
                            .map((soldTicket, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={soldTicket.id}
                                >
                                  <TableCell className="uic-th-td">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {soldTicket.name}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {soldTicket.sold_ticket}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {soldTicket.TotalUser}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <span className="no-data-found-pl">
                            No data found
                          </span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={
                      soldTicketEventList !== undefined
                        ? soldTicketEventList.length
                        : 0
                    }
                    rowsPerPage={userRowsPerPage}
                    page={userPage}
                    onPageChange={handleChangeUserPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoldTicketEventModal;
