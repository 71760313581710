import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Import Api integration file
import Services from "../../Services/auth.service";
import AlertComponent from "../../Hooks/AlertComponent";
import deletefill from "../../Images/BackendImages/delete-fill.svg";
const EditQues = ({
  allques,
  selectedId,
  setshowedit,
  showedit,
  retriveQues,
  setallques
}) => {
  const [Inprocess, setInprocess] = useState(false)
  const [err, setErr] = useState("");
  const [error, setError] = useState({
    question: "",
    type: "",
  });
  const [question, setquestion] = useState({});
  const [showOption, setshowOption] = useState(false);
  const [showminmax, setshowminmax] = useState(false);
  const [showvalidDiv, setshowvalidDiv] = useState(false);
  const [showInfoDiv, setshowInfoDiv] = useState(false);
  const [quesoptions, setquesoptions] = useState([]);
  const [showalert, setshowalert] = useState(false)
  const [alertmsg, setalertmsg] = useState("")
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })

  useEffect(() => {
    handleClearAllStates()
  }, [])
  // useEffect called on change selectedId
  useEffect(() => {
    Services.getquestionbyid(selectedId).then((res) => {
      if (res.data.status) {
        var arr = res.data.data;
        var data = {
          question: arr.question,
          type: arr.field_type,
          placeholder: arr.placeholder,
          required: arr.require_field,
          info: arr.info,
          min: arr.minlength,
          max: arr.maxlength,
          options: arr.options
        }
        setquestion({ ...data });
        arr.options.length > 0 ? setshowOption(true) : setshowOption(false);
        arr.minlength > 0 || arr.maxlength > 0 || arr.require_field.length > 0
          ? setshowvalidDiv(true)
          : setshowvalidDiv(false);
        (arr.minlength > 0 || arr.maxlength > 0 || arr.require_field.length > 0) &&
          arr.field_type !== "radio" &&
          arr.field_type !== "date" &&
          arr.field_type !== "time" &&
          arr.field_type !== "email" &&
          arr.field_type !== "file"
          ? setshowminmax(true)
          : setshowminmax(false);
        arr.info.length > 0 ? setshowInfoDiv(true) : setshowInfoDiv(false);
        setquesoptions(arr.options);
      }
    }).catch((e) => { console.log(e) })

  }, [selectedId]);
  // useEffect called on change  question, show edit
  useEffect(() => { }, [question, setquestion, showedit, setshowedit]);
  // Called on click on delete button
  const handleDeleteOption = (index) => {
    var arr = quesoptions;
    arr.splice(index, 1);
    setquesoptions(arr);
  };
  // Called on click on add button
  const addoption = (e) => {
    if (document.getElementById("newoptionvalue").value.length > 0) {
      var newarr = quesoptions;
      newarr.push(document.getElementById("newoptionvalue").value);
      setquesoptions(newarr);
      question["options"] = newarr;
      document.getElementById("newoption").style.display = "none";
      document.getElementById("newoptionvalue").value = "";
      setquestion({ ...question, options: quesoptions });
    }
  };
  // Called on click on add option button
  const handleaddoptionbtn = (e) => {
    document.getElementById("newoption").style.display = "block";
  };
  // Called on type field change
  const handleType = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    switch (e.target.value) {
      case "checkbox":
      case "radio":
        if (e.target.value === "radio") {
          setshowminmax(false);
          setquestion({ ...question, min: 0, max: 0 });
        } else {
          setshowminmax(true);
        }
        setshowOption(true);
        break;
      case "date":
      case "time":
        setquestion({ ...question, min: 0, max: 0, type: e.target.value, options: [] });
        setquesoptions([]);
        setshowminmax(false);
        setshowOption(false);
        break;
      default:
        if (e.target.value === "email" || e.target.value === "file") {
          setshowminmax(false);
          setquestion({ ...question, type: e.target.value, min: 0, max: 0, options: [] });
        } else setshowminmax(true);
        setquesoptions([]);
        setshowOption(false);
        setquestion({ ...question, type: e.target.value, options: [] });
        break;
    }
    e.target.value.length === 0
      ? setError({ ...error, type: "Select answer type!" })
      : setError({ ...error, type: "" });
  };
  // Called on input field change event
  const handleOnChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    switch (e.target.name) {
      case "infocheckbox":
        if (showInfoDiv) {
          setshowInfoDiv(false);
          setquestion({ ...question, info: "" });
        } else setshowInfoDiv(true);
        break;
      case "required":
        setquestion({ ...question, [e.target.name]: e.target.checked });
        break;
      case "validatcheckbox":
        if (showvalidDiv) {
          setquestion({ ...question, required: false, min: 0, max: 0 });
          setshowvalidDiv(false);
        } else setshowvalidDiv(true);
        break;
      default:
        setError({
          ...error,
          question:
            e.target.name === "question" && e.target.value.length < 1
              ? "Enter question heading!"
              : "",
        });
        setquestion({ ...question, [e.target.name]: e.target.value });
        break;
    }
  };
  console.log("quest", question)
  // Called on click on update button
  const handleSubmit = (e) => {
    e.preventDefault();
    if (question.type !== "checkbox" && question.type !== "radio")
      setquestion({ ...question, options: [] });

    if (question.question.length > 0 && question.type.length > 0) {
      var obj =
        question.type === "checkbox" || question.type === "radio"
          ? question.options.length < 2
            ? "Minimum 2 options should be added!"
            : ""
          : "";
      if (obj.length === 0) {
        if (
          question.min > 0 &&
          question.max > 0 &&
          question.max < question.min
        ) {
          setErr("Minimum value should be less than maximum value ");
        } else {
          setInprocess(true)
          var data = {
            question: question.question,
            field_type: question.type,
            placeholder: question.placeholder,
            require_field: question.required,
            info: question.info,
            minlength: question.min,
            maxlength: question.max,
            options: question.options
          }
          Services.updatequestion({ id: selectedId, data: data })
            .then(function (response) {
              if (response.data.status) {
                Services.getallquestions()
                  .then(function (response) {
                    setallques(response.data.data);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });
                setInprocess(false)
                setshowalert(true)
                setalertmsg("Question updated successfully!")
                setTimeout(() => {
                  setshowalert(false);
                  document.getElementById("closeBtnEditQues").click();
                }, 2000);
                retriveQues();
                setshowOption(false);
                setquesoptions([]);
                setshowminmax(false);
                setshowvalidDiv(false);
                setshowInfoDiv(false);
              }
              setInprocess(false)
              handleClearAllStates()
            })
            .catch(function (error) {
              handleClearAllStates()
              console.log(error);
            });
        }
      } else {
        if (Object.keys(obj).length > 0) {
          const emptyKeys = Object.keys(obj).filter((key) => obj[key] !== "");
          const inputElement = document.getElementsByName(emptyKeys[0])[0];
          if (inputElement) {
            inputElement.focus(); // Focus the input element
          }
        }
        setErr(obj);
      }
    } else {
      question.question.length < 1 && question.type.length < 1
        ? setError({
          ...error,
          question: "Enter question heading!",
          type: "Select type!",
        })
        : question.question.length < 1
          ? setError({ ...error, question: "Enter question heading!" })
          : setError({ ...error, question: "Select type!" });
    }
  };
  // Called on click on cancel button
  const handleCancel = () => {
    setshowOption(false);
    setquesoptions([]);
    setshowminmax(false);
    setshowvalidDiv(false);
    setshowInfoDiv(false);
    setshowedit(false);
    handleClearAllStates()
  };

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($('#editques').hasClass('show')) {
        $("#editques").modal('show')
      }
    }
    else if (stayOnPage !== null && !stayOnPage) {
      if ($('#editques').hasClass('show')) { $("#editques").modal('hide') }
    }
  }, [stayOnPage])

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }
  return (
    <>
      <div
        className="modal fade edit-user-details-popup"
        id="editques"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Edit Question</h2>
              <button
                type="button"
                className="close"
                // data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                  } else {
                    $("#editques").modal('hide')
                    handleCancel(e)
                  }

                }}
                id="closeBtnEditQues"
              >
                &times;
              </button>
            </div>

            {showalert && <div className="alert alert-info">{alertmsg}</div>}
            <span className="error">{err}</span>
            <form>
              <div className="modal-body">
                <div className="form-group">
                  <div className="form-group-label">Question*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="enter question here"
                      name="question"
                      onChange={handleOnChange}
                      value={question.question}
                    />
                  </div>
                  <span className="error">{error.question}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Answer type*</div>
                  <div className="form-group-ct">
                    <select
                      name="type"
                      onChange={handleType}
                      value={question.type}
                    >
                      <option value="">--- Select Answer Type ---</option>
                      <option value="text">Text</option>
                      <option value="checkbox">Checkbox</option>
                      <option value="radio">Radio button</option>
                      <option value="number">Number</option>
                      <option value="textarea">Textarea</option>
                      <option value="email">Email</option>
                      <option value="time">Time</option>
                      <option value="date">Date</option>
                      <option value="file">Media</option>
                    </select>
                  </div>
                  <span className="error">{error.type}</span>
                </div>
                <div
                  className="form-group"
                  id="optiontr"
                  style={
                    showOption ? { display: "block" } : { display: "none" }
                  }
                >
                  {quesoptions.length > 0
                    ? quesoptions.map((option, index) => {
                      return (
                        <div className="form-group-ct cstm-edit-delete">
                          <input type="text" name={option} value={option} />
                          <div className="cstm-edit-delete-main">
                            <a
                              onClick={() => {
                                console.log(
                                  quesoptions.filter((val, ind) => {
                                    if (ind !== index) return val;
                                  })
                                );
                                setquesoptions(
                                  quesoptions.filter((val, ind) => {
                                    if (ind !== index) return val;
                                  })
                                );
                              }}
                            >
                              <img src={deletefill} />
                            </a>
                          </div>
                        </div>
                      );
                    })
                    : ""}
                  <div id="newoption" style={{ display: "none" }}>
                    <input type="text" id="newoptionvalue" />
                    <button type="button" onClick={addoption} className="btn">
                      Add
                    </button>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={handleaddoptionbtn}
                      className="btn"
                    >
                      Add Option
                    </button>
                  </div>
                </div>

                <div className="form-group clt-checkbox">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleOnChange}
                      name="validatcheckbox"
                      checked={showvalidDiv}
                    />
                    Show Validation
                  </label>
                </div>

                <div
                  style={
                    showvalidDiv ? { display: "block" } : { display: "none" }
                  }
                >
                  <div className="required-bx">
                    <div className="form-group clt-checkbox">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="required"
                          onChange={handleOnChange}
                          checked={question["required"]}
                        />
                        Required
                      </label>
                    </div>
                    <div
                      style={
                        showminmax ? { display: "block" } : { display: "none" }
                      }
                    >
                      <div className="form-group">
                        <div className="form-group-label">Min</div>
                        <div className="form-group-ct">
                          <input
                            type="number"
                            name="min"
                            onChange={handleOnChange}
                            value={question["min"]}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Max</div>
                        <div className="form-group-ct">
                          {" "}
                          <input
                            type="number"
                            name="max"
                            onChange={handleOnChange}
                            value={question["max"]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group clt-checkbox">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="infocheckbox"
                      onChange={handleOnChange}
                      checked={showInfoDiv}
                    />
                    Info
                  </label>
                </div>
                <div
                  className="form-group"
                  style={
                    showInfoDiv ? { display: "block" } : { display: "none" }
                  }
                >
                  <div className="form-group-label">Info</div>
                  <div className="form-group-ct">
                    <textarea
                      placeholder="info"
                      name="info"
                      onChange={handleOnChange}
                      value={question["info"]}
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>

            <div className="edit-user-details-footer question-footer-aa">
              <button className="btn" type="button" onClick={handleSubmit}>
                {Inprocess ? "In Process..." : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <AlertComponent />
    </>
  );
};

export default EditQues;
