import React, { useCallback, useReducer, useRef, useState } from 'react';
import './DropDown.css';
import '../../Styles/Theme/Theme.css';
import PropTypes from "prop-types"
import DropDownItem from '../DropDownItem/DropDownItem';

export default function DropDown({
    className,
    label = "Label",
    listVariant,
    onClear,
    onClose,
    onInputChange,
    onItemFocus,
    onOpen,
    onSelect,
    options = [],
    validity,
    isOpen = false,
    selected = "Select subcategory",
    size,
    ...props }) {
    return (
        <>
            <div className='dropdown'>
                <button className={`dropdown-label ${isOpen ? "dropdown-up-arrow" : ""}`} onClick={onOpen}>{label}<b>{selected}</b></button>
                {/* {options.length > 0 && isOpen && <DropDownItem size={size} options={options} onSelect={onSelect} />} */}
            </div>
        </>
    )
}

// define types to all props
DropDown.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    onSelect: PropTypes.func,
    onOpen: PropTypes.func,
    isOpen: PropTypes.bool,
    selected: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"])
}