import React from 'react'
import './ToolTip.css'
import "../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"
const ToolTip = ({ children, id, title, align = "center" }) => {
    return (
        <>
            <div className={`mds-tooltip ${align}`} id={id}>{title}</div>
            {/* <div className='tooltip left'>Unlock secret prices at your favourite brands with powerful discounts</div> */}
        </>

    )
}

ToolTip.propTypes = {
    children: PropTypes.element,
    align: PropTypes.oneOf(["left", "center"]),
    id: PropTypes.string,
    title: PropTypes.string
}

export default ToolTip