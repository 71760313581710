import React from "react";
import cloneImage from "../../../Images/clone-icon.png";
const CloneActivityModal = ({ handleCloneActivity }) => {
  return (
    <>
      <div
        className="modal fade msg-popup"
        id="cloneActivity"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={cloneImage} />
            </div>
            <div className="cstm-clone-title">Clone Activity</div>
            <h1>Are you sure you want to clone this activity?</h1>
            <div className="row">
              <div className="col-6">
                <span class="msg-btn">
                  <button type="button" data-dismiss="modal" className="btn">
                    Discard
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn2"
                    data-dismiss="modal"
                    onClick={handleCloneActivity}
                  >
                    Clone
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CloneActivityModal;
