import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import authService from "../../../Services/auth.service";
import ToastNotification from "../../../components/ToastNotification/ToastNotification";
import EmptyState from "../../../components/EmptyState/EmptyState";
import IconSidebarChat from "../../../components/Icons/GeneralIcons/IconSideBarChat";

export default function UnsubscribeEmail() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  // handle onclick in unsubscribe button
  const onClickUnsubscribe = () => {
    authService
      .unsubscribeEmailChat({ token: token })
      .then((response) => {
        if (response?.data?.status) {
          setMessage(response?.data?.message);
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          setMessage(response?.data?.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="center-view-box">
        <EmptyState
          icon={<IconSidebarChat />}
          isClear={true}
          label="Unsubscribe"
          onClick={onClickUnsubscribe}
          hasTitle={true}
          title="Are you sure you want to unsubscribe from missing messages newsletter?"
        />
        {message && (
          <div className="toast-wrapper top right">
            <ToastNotification
              onCancel={() => setMessage("")}
              title={message}
              hasAction={false}
              hasIcon={true}
              variant={"info"}
            />
          </div>
        )}
      </div>
    </>
  );
}
