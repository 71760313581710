import React from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import StatusBadge from "../Badge/StatusBadge";
import MdsLogo from "../Logos/MdsLogo";
import CameraIcon from "../Icons/GeneralIcons/CameraIcon";

// CSS file
import "./AvtarLarge.css";
import "../../Styles/Theme/Theme.css";

const AvtarLarge = ({
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  type = "photo",
  hasStatus = false,
  badgeSize = "large",
  badgeStatus = "offline",
  letter = "W",
  icon,
  hasEdit = false,
  onClickEditProfile,
  onImageUpload,
}) => {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop: (acceptedFiles) => {
      onImageUpload(acceptedFiles[0]); // Pass the uploaded file to the parent
    },
  });
  return (
    <div className="avatar-large">
      <>
        <div className="avatar-large-in avatar-large-96">
          {type === "photo" ? (
            <img src={src} />
          ) : type === "icon" ? (
            icon ?? <></>
          ) : type === "letter" ? (
            <div className="">{letter}</div>
          ) : type === "logo" ? (
            <MdsLogo />
          ) : type === "mds" ? (
            <MdsLogo />
          ) : type === "empty" ? (
            <div
              className="mds-large-empty"
              {...(hasEdit && {
                onClick: () => document.getElementById("imageInput").click(),
                ...getRootProps(),
              })}
            >
              {hasEdit && <input id="imageInput" {...getInputProps()} />}
              <CameraIcon />
            </div>
          ) : (
            <></>
          )}
          {hasEdit && (
            <span
              className="avatar-large-edit flex items-center justify-center absolute"
              onClick={() => document.getElementById("imageInput").click()}
              {...getRootProps()}
            >
              Edit
              <input id="imageInput" {...getInputProps()} />
            </span>
          )}
        </div>
        {hasStatus ? (
          <StatusBadge size={badgeSize} status={badgeStatus} />
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

AvtarLarge.propTypes = {
  size: PropTypes.oneOf(["XLarge"]),
  src: PropTypes.string,
  type: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds", "empty"]),
  hasStatus: PropTypes.bool,
  // badgeSize: PropTypes.oneOf(["small", "medium", "large"]),
  badgeStatus: PropTypes.oneOf(["offline", "online"]),
  letter: PropTypes.string,
  icon: PropTypes.element,
  isRounded: PropTypes.bool,
  hasEdit: PropTypes.bool,
  onClickEditProfile: PropTypes.func,
  onImageUpload: PropTypes.func,
  acceptedFiles: PropTypes.array,
};

export default AvtarLarge;
