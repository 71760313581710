import React from "react";
import FilterListItem from "../../../../Pages/DocumentManagement/Components/FilterListItem";

const CategoryList = ({
  handleBack,
  handleSubCategoryFilter,
  category,
  selectedCategory,
  selectedSubCategory,
  handleCategoryFilter,
}) => {
  const onCategoryClick = (category) => {
    if (category.subCategory != undefined && category.subCategory.length > 0) {
      handleCategoryFilter(category);
    } else {
      handleCategoryFilter(category);
      handleBack();
    }
  };
  return (
    <>
      <FilterListItem
        selected={category.name === selectedCategory}
        label={category.name}
        onClick={() => onCategoryClick(category)}
      />

      {selectedCategory === category.name &&
        category.subCategory !== undefined &&
        category.subCategory.length > 0 &&
        category.subCategory.map((subCat) => (
          <div key={subCat.name} style={{ marginLeft: "40px" }}>
            <FilterListItem
              onClick={() => {
                handleSubCategoryFilter(subCat);
                handleBack();
              }}
              selected={selectedSubCategory === subCat.name}
              label={subCat.name}
            />
          </div>
        ))}
    </>
  );
};

export default CategoryList;
