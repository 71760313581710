import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../../../Services/auth.service";
import { checktoken_user } from "../../checktoken";
import LoaderComponent from "../LoaderComponent";
import Image1 from "../../../Images/image1.png";
import Dotted from "../../../Images/dotted.svg";
import Star2 from "../../../Images/star2.svg";
import Stards from "../../../Images/star-ds.svg";
import Star from "../../../Images/star.svg";
import Atoz from "../../../Images/atoz.svg";
import shortLefticon from "../../../Images/short_left-icon.svg";
import loading3 from "../../../Images/loading3.gif";

const AllGroupMobile = ({ setclose, openingpop }) => {
  const history = useNavigate();
  const [allgroup, setallgroup] = useState([]);
  const [stargroup, setstargroup] = useState([]);
  const [inprocess_groupstar, setinprocess_groupstar] = useState({});
  const [loader, setloader] = useState(false);
  const [starloader, setstarloader] = useState(false);
  const [sort, setsort] = useState("asc");
  const [angle, setangle] = useState("up");
  const [SelectedGroup, setSelectedGroup] = useState("");
  const [selectedDiv, setselectedDiv] = useState("dashboard");
  const [topicSearch, settopicSearch] = useState("");
  useEffect(() => {
    setloader(true);
    setstarloader(true);
    Services.getallgrouploginuser()
      .then((res) => {
        if (res.data.status) {
          setallgroup(res.data.data);
          setloader(false);
        } else if (res.data.invalidToken) {
          setloader(false);
          localStorage.clear();
          history("/");
        } else {
          setloader(false);
        }
      })
      .catch((err) => {
        setloader(false);
        console.log(err);
      });
    Services.getstarredgroup()
      .then((res) => {
        checktoken_user(res);
        if (res.data.status) {
          setstargroup(res.data.data);
        }
        setstarloader(false);
      })
      .catch((err) => {
        setstarloader(false);
        console.log(err);
      });
  }, []);
  const handleAllGroupSort = () => {
    var arr = [...allgroup];
    if (sort === "asc") {
      arr.sort(function (a, b) {
        var textA = a.groupTitle.toUpperCase();
        var textB = b.groupTitle.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else {
      arr.sort(function (a, b) {
        var textA = a.groupTitle.toUpperCase();
        var textB = b.groupTitle.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }
    setallgroup(arr);
    setsort(sort === "des" ? "asc" : "des");
  };
  const addstargroup = (groupid) => {
    setinprocess_groupstar({ [groupid]: true });
    Services.addstargroup({ groupId: groupid })
      .then((res) => {
        if (res.data.status) {
          var arr = [];
          var n = 0;
          for (var i = 0; i < allgroup.length; i++) {
            if (allgroup[i]._id === groupid) {
              if (stargroup !== undefined && stargroup.length > 0)
                setstargroup([...stargroup, allgroup[i]]);
              else setstargroup([allgroup[i]]);
            }
          }
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
        setinprocess_groupstar({ [groupid]: false });
      })
      .catch((e) => {
        setinprocess_groupstar({ [groupid]: false });
        console.log(e);
      });
  };
  const removestargroup = (groupid) => {
    setinprocess_groupstar({ [groupid]: true });
    Services.removestargroup(groupid)
      .then((res) => {
        if (res.data.status) {
          var arr = [];
          var n = 0;
          for (var i = 0; i < stargroup.length; i++) {
            if (stargroup[i]._id !== groupid) {
              arr[n] = stargroup[i];
              n++;
            }
          }
          setstargroup(arr);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/");
        }
        setinprocess_groupstar({ [groupid]: false });
      })
      .catch((e) => {
        setinprocess_groupstar({ [groupid]: false });
        console.log(e);
      });
  };
  return (
    <>
      <div className="globle-model-box">
        <button
          className="globle-back-btn"
          onClick={() => {
            setclose({ ...openingpop, allgroup: false });
          }}
        >
          <img src={shortLefticon} alt="shortLefticon" />
        </button>
        <div>
          {stargroup !== undefined &&
          stargroup !== null &&
          stargroup.length > 0 ? (
            <div className="card">
              <div className="card-header" id="headingtwo">
                <button
                  type="button"
                  className="btn-link collapsed "
                  data-toggle="collapse"
                  data-target="#mobstarGrp"
                  onClick={() => {
                    angle === "down" ? setangle("up") : setangle("down");
                  }}
                >
                  <img src={Star} /> Starred Group{" "}
                  {stargroup !== undefined && stargroup.length > 0
                    ? `(${stargroup.length})`
                    : `(0)`}
                  {angle.starredgroup === "down" ? (
                    <i className="fa fa-angle-down"></i>
                  ) : (
                    <i className="fa fa-angle-up"></i>
                  )}
                </button>
              </div>
              <div
                id="mobstarGrp"
                className="collapse"
                aria-labelledby="headingtwo"
                // data-parent="#accordionExample2"
              >
                <div className="cstm-starred-group cstm-starred-group-sad">
                  {starloader ? (
                    <div className="loadind-post">
                      <LoaderComponent />
                    </div>
                  ) : stargroup !== undefined &&
                    stargroup !== null &&
                    stargroup.length > 0 ? (
                    <ul>
                      {stargroup !== undefined &&
                        stargroup !== null &&
                        stargroup.map((group) => {
                          return (
                            <>
                              <li className="cstm-starred-group-sad-li">
                                <div className="cstm-sg-list">
                                  <img
                                    className="sctm-pt-mn"
                                    src={
                                      group.groupImage !== undefined &&
                                      group.groupImage.length > 0
                                        ? process.env
                                            .REACT_APP_AWS_IMG_VID_PATH +
                                          group.groupImage
                                        : Image1
                                    }
                                  />
                                  <img className="sctm-pt-star" src={Star2} />
                                </div>
                                <div className="cstm-sg-ttl-name">
                                  <a
                                    className="cstm-sg-ttl-main"
                                    onClick={() => {
                                      history(`/group/${group._id}`);
                                    }}
                                  >
                                    {group.groupTitle}
                                  </a>
                                </div>
                                <div className="cstm-sg-dotted">
                                  <span
                                    className="remove-srarred-img"
                                    data-toggle="dropdown"
                                  >
                                    <img src={Dotted} />
                                  </span>
                                  <div
                                    className="dropdown-menu remove-srarred-in"
                                    onClick={() => {
                                      removestargroup(group._id);
                                    }}
                                  >
                                    <b>×</b> Remove Starred
                                  </div>
                                </div>
                              </li>
                            </>
                          );
                        })}
                    </ul>
                  ) : stargroup !== undefined &&
                    stargroup !== null &&
                    stargroup.length === 0 ? (
                    <span className="emptymsg">No Data Found</span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="collapse show" aria-labelledby="headingthree">
            <div className="sort-by-main">
              Sort by :{" "}
              <img
                src={Atoz}
                onClick={() => {
                  handleAllGroupSort();
                }}
              />
            </div>
            <div className="cstm-starred-group  glob-cstm-starred-group">
              {loader ? (
                <div className="loadind-post">
                  <LoaderComponent />
                </div>
              ) : allgroup !== undefined &&
                allgroup !== null &&
                allgroup.length > 0 ? (
                <ul>
                  {allgroup !== undefined &&
                    allgroup !== null &&
                    allgroup.map((group) => {
                      if (
                        stargroup.filter((gr) => {
                          if (gr._id === group._id) return gr;
                        }).length > 0
                      )
                        return <></>;
                      else {
                        return (
                          <>
                            <li className="cstm-statte-grup-main ">
                              <div className="cstm-sg-list">
                                <img
                                  className="sctm-pt-mn"
                                  src={
                                    group.groupImage !== undefined &&
                                    group.groupImage !== null &&
                                    group.groupImage.length > 0
                                      ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                        group.groupImage
                                      : Image1
                                  }
                                />
                              </div>
                              <div className="cstm-sg-ttl-name">
                                <a
                                  className="cstm-sg-ttl-main"
                                  onClick={() => {
                                    history(`/group/${group._id}`);
                                  }}
                                >
                                  {group.groupTitle}
                                </a>
                              </div>
                              {inprocess_groupstar[group._id] !== undefined &&
                              inprocess_groupstar[group._id] ? (
                                <div className="cstm-sg-dotted">
                                  <img src={loading3} className="dis-loader3" />
                                </div>
                              ) : (
                                <div
                                  className="cstm-sg-dotted"
                                  onClick={() => {
                                    stargroup !== undefined &&
                                    stargroup !== null &&
                                    stargroup.filter((gr) => {
                                      if (gr._id === group._id) return gr;
                                    }).length > 0
                                      ? removestargroup(group._id)
                                      : addstargroup(group._id);
                                  }}
                                >
                                  {stargroup !== undefined &&
                                  stargroup !== null &&
                                  stargroup.filter((gr) => {
                                    if (gr._id === group._id) return gr;
                                  }).length > 0 ? (
                                    <img src={Star2} />
                                  ) : (
                                    <img src={Stards} />
                                  )}
                                </div>
                              )}
                            </li>
                          </>
                        );
                      }
                    })}
                </ul>
              ) : allgroup !== undefined &&
                allgroup !== null &&
                !loader &&
                allgroup.length === 0 ? (
                <span className="emptymsg">No Data Found</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AllGroupMobile;
