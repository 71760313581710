import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Import Api integration file
import Services from "../../Services/auth.service";

import "../../Styles/adminGlobal.css";

// Import components
import RegistrationForm from "./RegistrationForm";
import AddGrid from "./AddGrid";
import AddField from "./AddField";
import { Alert } from "react-bootstrap";

const AdminRegistrationForm = () => {
  const [dbformfield, setdbformfield] = useState([]);
  const [showgrid, setShowgrid] = useState(false);
  const [showaddfield, setShowaddfield] = useState(false);
  const [fields, setFields] = useState([]);
  const [grid, setGrid] = useState(1);
  const [fieldarr, setfieldarr] = useState([]);
  let history = useNavigate();

  // useEffect called on first render
  useEffect(() => {
    if (
      localStorage.getItem("adminidtoken") === null ||
      localStorage.getItem("adminidtoken").length === 0
    ) {
      history("/adminlogin");
    }  
  
    Services.retrivefieldsregistrationform()
      .then(function (response) {
        setdbformfield(response.data.data);
      })
      .catch(function (error) {
        Alert("Something went wrong");
        console.log(error);
      });
    // setInterval(() => {
    //   Services.retrivefieldsregistrationform()
    //     .then(function (response) {
    //       setdbformfield(response.data.data);
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // }, 1000);
  }, []);
  // called when grid value change
  const settingFieldArrOnGridChange = (grid) => {
    var arr = [];
    for (var i = 0; i < grid; i++) {
      arr.push({
        label: "",
        type: "",
        placeholder: "",
        options: [],
        min: 0,
        max: 0,
        required: "",
        info: "",
      });
    }
    setfieldarr(arr);
  };

  return (
    <div className="cstm-all-admin">
      <RegistrationForm
        fields={fields}
        setShowgrid={setShowgrid}
        setFields={setFields}
        dbformfield={dbformfield}
        setdbformfield={setdbformfield}
      />
      <AddGrid
        setGrid={setGrid}
        showgrid={showgrid}
        setShowaddfield={setShowaddfield}
        setShowgrid={setShowgrid}
        setfieldarr={setfieldarr}
        grid={grid}
        settingFieldArrOnGridChange={settingFieldArrOnGridChange}
      />
      <AddField
        setFields={setFields}
        fields={fields}
        grid={grid}
        showaddfield={showaddfield}
        setShowaddfield={setShowaddfield}
        fieldarr={fieldarr}
        setGrid={setGrid}
        setfieldarr={setfieldarr}
      />
    </div>
  );
};

export default AdminRegistrationForm;
