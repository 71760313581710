import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import Services from "../../../Services/auth.service";
import authServiceEvent from "../../../Services/auth.service.event";
import NotifiedUsertable from "../../AdminNotification/NotifiedUsertable";
import RestrictedUserAccess from "../commonComponents/RestrictedUserAccess";
const AddRulesForChannel = ({
  ForWhichPurpose,
  title,
  addedRules,
  ruleError,
  setRuleError,
  setNoRuleFieldSelected,
  setTotalUsers,
  totalUsers,
  setDataFromRules,
  showEventField,
  rulesDetail,
  setRulesDetail,
  setRulesDetailErr,
  rulesDetailErr,
  selectedUsers,
  setSelectedUsers,
  editState,
  setEditState,
  selectedTags,
  setSelectedTags,
  isEdit,
  isEditForChannelAccess,
}) => {
  const [searchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [eventList, setEventList] = useState([]);
  const [totalUserList, setTotalUserList] = useState([]);
  const [isModal, setModal] = useState(false);
  const [totalUserEventList, setTotalUserEventList] = useState([]);
  const [isModalEvent, setModalEvent] = useState(false);
  const [totalUserEventSpecific, setTotalUserEventSpecific] = useState(0);
  const [restrictedOptionsUser, setRestrictedOptionsUser] = useState([]);
  const [allParticipantsEmailArr, setAllParticipantsEmailArr] = useState([]);
  const [clickedCheckboxUser, setClickedCheckboxUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [excelFileEmails, setExcelFileEmails] = useState([]);
  const [isError, setIsError] = useState([]);
  const [nonEmailText, setNonEmailText] = useState([]);
  const [alreadySelectedEventUsersEmail, setAlreadySelectedEventUsersEmail] =
    useState([]);
  const [tagAllList, setTagAllList] = useState([]);
  const [alreadySelectedTagUsersEmail, setAlreadySelectedTagUsersEmail] =
    useState([]);
  const [totalUserTagList, setTotalUserTagList] = useState([]);
  const [totalUserTagSpecific, setTotalUserTagSpecific] = useState(0);
  const [isModalTag, setModalTag] = useState(false);

  const dispatch = useDispatch();
  const rulesDetailRef = useRef(rulesDetail);

  useEffect(() => {
    rulesDetailRef.current = rulesDetail;
  }, [rulesDetail]);
  // useEffect will call on first render
  useEffect(() => {
    getEventListApi();
  }, []);

  // event list api call
  const getEventListApi = () => {
    authServiceEvent
      .getAllEventsLimitedFiedls()
      .then((res) => {
        if (res.data.status) {
          let list = res.data.data;
          let arr = [{ value: "", label: "Select event" }];
          list.map((event) => {
            arr.push({
              value: event._id,
              label: event.title,
            });
          });

          setEventList(arr);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    Services.getalltags_byadmin("")
      .then((response) => {
        if (response.data.status) {
          let tagList = response.data.data;
          let arr = [{ value: "", label: "Select tag" }];
          tagList.map((tag) => {
            arr.push({
              value: tag._id,
              label: tag.name,
            });
          });
          setTagAllList(response.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleEventChange = (selectedOption) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (totalUserTagList.length === 0 && selectedUsers.length === 0) {
      setTotalUserList([]);
      setTotalUsers(0);
    } else {
      setTotalUserList([...totalUserTagList, ...selectedUsers]);
      setTotalUsers([...totalUserTagList, ...selectedUsers].length);
    }
    if (selectedOption.value) {
      authServiceEvent
        .searchEventParticipantTypesApi(selectedOption.value, "")
        .then((res) => {
          if (res.data.status) {
            const participantTypesData = res.data.list;
            setRestrictedOptionsUser(participantTypesData);
          }
        });
    }
    setRulesDetail({
      ...rulesDetail,
      eventId: selectedOption.value,
      event: selectedOption,
    });

    if (selectedOption.value === "") {
      setRulesDetail({
        ...rulesDetail,
        eventId: selectedOption.value,
        event: selectedOption,
        accessPermission: "all",
        restrictedOptions: [],
      });
    }
    if (
      rulesDetail.userAccess === "restricted" &&
      selectedUsers.length === 0 &&
      selectedOption.value.length === 0
    ) {
      setRuleError(true);
      setNoRuleFieldSelected(true);
    } else {
      setNoRuleFieldSelected(false);
      setRuleError(false);
    }
  };

  // marge all the data in total user list
  const mergeUserLists = (tagUsers, eventUsers, selectedUsers) => {
    const combinedList = [...tagUsers, ...eventUsers, ...selectedUsers];
    // Remove duplicates based on user_id or _id
    const uniqueUsers = Array.from(
      new Set(combinedList.map((user) => user.user_id || user._id))
    ).map((id) =>
      combinedList.find((user) => user.user_id === id || user._id === id)
    );
    if (uniqueUsers.length) {
      setTotalUserList(uniqueUsers);
      setTotalUsers(uniqueUsers.length);
    } else {
      setTotalUserList([]);
      setTotalUsers(0);
    }
  };

  //get body data
  const getBodyData = async (data) => {
    setDataFromRules(data);
    try {
      const userListRes = await Services.getAllChannelUserBasedOnFilter(data);
      if (userListRes.data.status) {
        const fetchedUsers = userListRes.data.data;
        setAlreadySelectedEventUsersEmail(
          fetchedUsers.map((user) => user["Preferred Email"])
        );
        setTotalUserEventList(fetchedUsers);
        setTotalUserEventSpecific(fetchedUsers.length);
        mergeUserLists(totalUserTagList, fetchedUsers, selectedUsers);
      } else {
        setTotalUserEventSpecific(0);
        setTotalUserEventList([]);
      }
    } catch (error) {
      return false;
    }
  };

  //get Total user Data
  const getTotalUserData = async (value = "all") => {
    if (value === "all") {
      try {
        const userListRes = await Services.getAllAttendeeListForRules("");
        if (userListRes) {
          if (selectedUsers.length === 0) {
            setTotalUserList(userListRes.data);
            setTotalUsers(userListRes.data.length);
          } else {
            // Filter out selected users that already exist in totalUserList
            const filteredSelectedUsers = selectedUsers.filter(
              (user) =>
                !userListRes.data.some((apiUser) => apiUser._id === user._id)
            );
            // Combine API data with filtered selected users
            const combinedList = userListRes.data.concat(filteredSelectedUsers);
            setTotalUserList(combinedList);
            setTotalUsers(combinedList.length);
          }
        } else {
          setTotalUserList([]);
          setTotalUsers(0);
        }
      } catch (error) {
        return false;
      }
    } else if (value === "RegisteredUser") {
      try {
        const registerUserList = await Services.getAllUser("");
        if (registerUserList) {
          if (selectedUsers.length === 0) {
            setTotalUserList(registerUserList.data);
            setTotalUsers(registerUserList.data.length);
          } else {
            // Filter out selected users that already exist in totalUserList
            const filteredSelectedUsers = selectedUsers.filter(
              (user) =>
                !registerUserList.data.some(
                  (apiUser) => apiUser._id === user.value
                )
            );
            // Combine API data with filtered selected users
            const combinedList = registerUserList.data.concat(
              filteredSelectedUsers
            );
            setTotalUserList(combinedList);
            setTotalUsers(combinedList.length);
          }
        } else {
          setTotalUserList([]);
          setTotalUsers(0);
        }
      } catch (error) {
        return false;
      }
    }
  };

  //handling onchange events
  const handleRulesChange = (e) => {
    if (e.target.value === "RegisteredUser" || e.target.value === "all") {
      rulesDetailRef.current.eventId = "";
      rulesDetailRef.current.event = "";
      rulesDetailRef.current.restrictedOptions = [];
    }
    setRulesDetail({
      ...rulesDetail,
      [e.target.name]: e.target.value,
      restrictedOptions: [],
    });
    rulesDetailRef.current.userAccess = e.target.value;
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setTotalUserList([]);
    setTotalUsers(0);
    setRestrictedOptionsUser([]);
    rulesBasedUserListApi(e.target.value);
  };

  //called when Esc key press
  const onEscCloseModal = (e) => {
    if (isModal && e.key === "Escape") {
      setModal(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  document.addEventListener("keydown", onEscCloseModal);

  // useEffect call when user and event select
  useEffect(() => {
    if (rulesDetail.eventId) {
      authServiceEvent
        .searchEventParticipantTypesApi(rulesDetail.eventId, "")
        .then((res) => {
          if (res.data.status) {
            const participantTypesData = res.data.list;
            setRestrictedOptionsUser(participantTypesData);
          }
        });
    }
    // if (
    //   rulesDetail.userAccess === "restricted" &&
    //   selectedUsers?.length === 0 &&
    //   rulesDetail.eventId?.length === 0 &&
    //   rulesDetail.tagId.length === 0
    // ) {
    //   setRuleError(true);
    // } else {
    //   setRuleError(false);
    // }

    // if (rulesDetail.event) {
    rulesBasedUserListApi(rulesDetail.userAccess);
    // }
  }, [rulesDetail.event, selectedUsers, rulesDetail.tagId]);

  useEffect(() => {
    if (rulesDetail.restrictedOptions.length > 0) {
      rulesBasedUserListApi();
    } else {
      setTotalUserEventSpecific(0);
      setTotalUserEventList([]);
    }
    rulesBasedUserListApi();
  }, [rulesDetail.restrictedOptions]);

  const rulesBasedUserListApi = async (
    value = "",
    updatedRulesDetail = rulesDetailRef.current
  ) => {
    if (
      updatedRulesDetail.userAccess === "restricted" &&
      updatedRulesDetail.eventId
    ) {
      let rulesDetails;
      if (
        updatedRulesDetail.restrictedOptions.length !== 0 &&
        updatedRulesDetail.accessPermission === "restricted"
      ) {
        rulesDetails = {
          eventId: updatedRulesDetail.eventId,
          restrictedAccess: updatedRulesDetail.restrictedOptions,
        };

        getBodyData(rulesDetails);
      } else if (
        updatedRulesDetail.eventId &&
        (value === "all" || updatedRulesDetail.accessPermission === "all")
      ) {
        rulesDetails = {
          eventId: updatedRulesDetail.eventId,
        };
        getBodyData(rulesDetails);
      }
    } else {
      getTotalUserData(value);
    }
  };

  // useEffect will call when user access is changed
  useEffect(() => {
    if (rulesDetail.userAccess === "all") {
      setSelectedUsers([]);
      setRulesDetail({ ...rulesDetail, eventId: "", event: "" });
      setNoRuleFieldSelected(false);
    } else {
      setNoRuleFieldSelected(true);
    }
  }, [rulesDetail.userAccess]);

  useEffect(() => {
    const fetchUserTagList = async () => {
      const payload = {
        tagIds: selectedTags.map((tag) => tag.value), // Assuming selectedTags is an array of objects with value property
      };
      try {
        const userTagListRes = await Services.getAllChannelUserBasedOnTag(
          payload
        );
        if (userTagListRes.data.status) {
          const fetchedUsers = userTagListRes.data.data;
          setAlreadySelectedTagUsersEmail(
            fetchedUsers.map((user) => user["Preferred Email"])
          );
          setTotalUserTagList(fetchedUsers);
          setTotalUserTagSpecific(fetchedUsers.length);
          mergeUserLists(fetchedUsers, totalUserEventList, selectedUsers);
        } else {
          setTotalUserTagList([]);
          setTotalUserTagSpecific(0);
        }
      } catch (error) {
        console.error("Error fetching user tag list:", error);
      }
    };

    if (selectedTags.length > 0) {
      fetchUserTagList();
    }
  }, [selectedTags]);

  useEffect(() => {
    if (ruleError) {
      setRulesDetailErr({
        ...rulesDetailErr,
        event: "Please select rule!",
      });
    } else {
      setRulesDetailErr({
        ...ruleError,
        restrictedOptions: "",
      });
      setRulesDetailErr({
        ...rulesDetailErr,
        event: "",
      });
    }
  }, [ruleError]);

  // handle event detail changes
  const handleEventAccessChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });

    const updatedRulesDetail = {
      ...rulesDetail,
      [name]: value,
      restrictedOptions: [],
    };

    setRulesDetail(updatedRulesDetail);
    setRulesDetailErr({
      ...rulesDetailErr,
      event: "",
    });

    if (value === "restricted") {
      setTotalUserEventSpecific(0);
      setTotalUserEventList([]);
      if (totalUserTagList.length === 0 && selectedUsers.length === 0) {
        setTotalUserList([]);
        setTotalUsers(0);
      } else {
        setTotalUserList([...totalUserTagList, ...selectedUsers]);
        setTotalUsers([...totalUserTagList, ...selectedUsers].length);
      }
    } else if (value === "all") {
      rulesBasedUserListApi(value, updatedRulesDetail);
    }
  };

  const handleRestrictedOptionsUserChanges = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });

    const selectedId = e.target.value;
    let updatedRestrictedOptions;

    if (e.target.checked) {
      updatedRestrictedOptions = [
        ...(rulesDetail.restrictedOptions || []),
        selectedId,
      ];
      setRulesDetail((prevDetail) => ({
        ...prevDetail,
        restrictedOptions: updatedRestrictedOptions,
      }));

      // Clear any errors related to restrictedOptions
      setRulesDetailErr({
        ...rulesDetailErr,
        restrictedOptions: "",
      });
      setRuleError(false);

      // Make API call for adding an option
      const updatedRulesDetail = {
        ...rulesDetail,
        restrictedOptions: updatedRestrictedOptions,
      };
      rulesBasedUserListApi("restricted", updatedRulesDetail);
    } else {
      updatedRestrictedOptions = (rulesDetail.restrictedOptions || []).filter(
        (id) => id !== selectedId
      );
      setRulesDetail((prevDetail) => ({
        ...prevDetail,
        restrictedOptions: updatedRestrictedOptions,
      }));

      if (updatedRestrictedOptions.length === 0) {
        // Reset total user list and make API call to reset state

        if (totalUserTagList.length === 0 && selectedUsers.length === 0) {
          setTotalUserList([]);
          setTotalUsers(0);
        } else {
          setTotalUserList([...totalUserTagList, ...selectedUsers]);
          setTotalUsers([...totalUserTagList, ...selectedUsers].length);
        }
        setRulesDetail((prevDetail) => ({
          ...prevDetail,
          restrictedOptions: [],
        }));
      }
    }
  };

  return (
    <>
      <div class="cstm-channel-type-title partner-icon-rf-only">{title}</div>
      <span className="error error-rule select-atleast-one-rule-from">
        {rulesDetailErr?.event}
      </span>

      <div className="row">
        <div
          className={`col-sm-12 form-group cstm-admin-access-grp ${rulesDetail.userAccess !== "restricted" && "mb-2"
            }`}
        >
          <div className="form-group-label">User access</div>
          <div
            className={`form-group-ct ${ForWhichPurpose === "edit" && isEditForChannelAccess
              ? "edit-disabled"
              : ""
              }`}
          >
            <select
              name="userAccess"
              value={rulesDetail.userAccess}
              onChange={handleRulesChange}
              disabled={ForWhichPurpose === "edit" && isEditForChannelAccess}
            >
              <option value="all">All Users</option>
              <option value="RegisteredUser">All Registered Users</option>
              <option value="restricted">Restricted</option>
            </select>
          </div>
        </div>
      </div>
      {rulesDetail.userAccess === "restricted" && (
        <>
          {!showEventField && (
            <div className="row">
              <div className="col-sm-12 form-group">
                <div className="form-group-label">Select event</div>
                <div className="form-group-ct event-type-drop-only">
                  <Select
                    isSearchable={true}
                    options={eventList}
                    value={rulesDetail.event}
                    styles={{ zIndex: 9999 }}
                    onChange={handleEventChange}
                    isDisabled={ForWhichPurpose === "edit" && isEdit}
                  />
                </div>
              </div>
            </div>
          )}

          {rulesDetail.eventId && (
            <div className="row">
              <div className="col-sm-12 form-group">
                <div className="form-group-label">Event access*</div>
                <div className="form-group-ct">
                  <select
                    name="accessPermission"
                    value={rulesDetail.accessPermission}
                    onChange={handleEventAccessChange}
                  >
                    <option value="all">All</option>
                    <option value="restricted">Restricted</option>
                  </select>
                </div>
                <span className="error">{rulesDetailErr.accessPermission}</span>
              </div>
            </div>
          )}
          {rulesDetail.accessPermission === "restricted" &&
            rulesDetail.eventId &&
            restrictedOptionsUser.length > 0 && (
              <div className="row cstm-group-ls pl-4">
                <span className="error">
                  {rulesDetailErr.restrictedOptions}
                </span>
                <div className="col-sm-3">
                  <h2 className="lh-1">Options</h2>
                  {restrictedOptionsUser.map((opt) => {
                    return (
                      <div className="group-list-create-event" key={opt._id}>
                        <label>
                          <input
                            type="checkbox"
                            name="restrictedOptions"
                            onChange={handleRestrictedOptionsUserChanges}
                            checked={rulesDetail.restrictedOptions.includes(
                              opt._id
                            )}
                            value={opt._id}
                          />{" "}
                          {opt.role}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          {/* {totalUserEventSpecific > 0 && (
            <span
              className="notification-user-list d-block mb-4"
              onClick={() => setModalEvent(true)}
            >
              Preview {totalUserEventList?.length} people who are in selected
              event right now
            </span>
          )} */}
          <div className="form-group">
            <div className="form-group-label">Tags</div>
            <div className="form-group-ct speakers-only">
              <Multiselect
                showArrow={true}
                displayValue="label"
                placeholder="Select tag"
                options={tagAllList?.map((tag) => ({
                  label: tag.name,
                  value: tag._id,
                }))}
                selectedValues={selectedTags}
                onSelect={(selectedList) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  dispatch({ type: "SETSTAYONPAGE", payload: null });
                  setSelectedTags(selectedList);
                  const selectedTagIds = selectedList.map((tag) => tag.value);
                  setRulesDetail((prevState) => ({
                    ...prevState,
                    tagId: selectedTagIds,
                  }));
                  setRulesDetailErr({
                    ...rulesDetailErr,
                    event: "",
                  });
                }}
                onRemove={(selectedList) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  dispatch({ type: "SETSTAYONPAGE", payload: null });
                  setSelectedTags(selectedList);
                  const selectedTagIds = selectedList.map((tag) => tag.value);
                  // If no tags are selected, clear the total user tag list
                  if (selectedTagIds.length === 0) {
                    setTotalUserTagList([]);

                    // If there are no user events and no selected users, clear the total user list
                    if (
                      totalUserEventList.length === 0 &&
                      selectedUsers.length === 0
                    ) {
                      setTotalUserList([]);
                      setTotalUsers(0);
                    } else {
                      // Otherwise, combine user events and selected users
                      const combinedUserList = [
                        ...totalUserEventList,
                        ...selectedUsers,
                      ];
                      setTotalUserList(combinedUserList);
                      setTotalUsers(combinedUserList.length);
                    }
                  }
                  setRulesDetail((prevState) => ({
                    ...prevState,
                    tagId: selectedTagIds,
                  }));
                  setRulesDetailErr({
                    ...rulesDetailErr,
                    event: "",
                  });
                }}
              />
            </div>
          </div>
          {/* {totalUserTagSpecific > 0 && (
            <span
              className="notification-user-list d-block mb-4"
              onClick={() => setModalTag(true)}
            >
              Preview {totalUserTagList?.length} people who are in selected tag
              right now
            </span>
          )} */}
          {!showEventField && (
            <RestrictedUserAccess
              setSelectedUsers={setSelectedUsers}
              selectedUsers={selectedUsers}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              allParticipantsEmailArr={allParticipantsEmailArr}
              setAllParticipantsEmailArr={setAllParticipantsEmailArr}
              clickedCheckboxUser={clickedCheckboxUser}
              setClickedCheckboxUser={setClickedCheckboxUser}
              excelFileEmails={excelFileEmails}
              setExcelFileEmails={setExcelFileEmails}
              isError={isError}
              setIsError={setIsError}
              nonEmailText={nonEmailText}
              setNonEmailText={setNonEmailText}
              editState={editState}
              setEditState={setEditState}
              alreadySelectedEventUsersEmail={alreadySelectedEventUsersEmail}
              totalUserEventList={totalUserEventList}
              setTotalUsers={setTotalUsers}
              totalUsers={totalUsers}
              totalUserList={totalUserList}
              setTotalUserList={setTotalUserList}
              setRulesDetailErr={setRulesDetailErr}
              rulesDetailErr={rulesDetailErr}
              // tag
              totalUserTagList={totalUserTagList}
              alreadySelectedTagUsersEmail={alreadySelectedTagUsersEmail}
            />
          )}
        </>
      )}

      {totalUserList?.length > 0 && (
        <span
          className="notification-user-list d-block mb-4"
          onClick={() => setModal(true)}
        >
          Preview {totalUserList?.length} people who are in this channel right
          now
        </span>
      )}

      {isModalEvent && (
        <NotifiedUsertable
          setModal={setModalEvent}
          users={totalUserEventList}
          FieldChange={false}
          FromChannelList={true}
        />
      )}

      {isModalTag && (
        <NotifiedUsertable
          setModal={setModalTag}
          users={totalUserTagList}
          FieldChange={false}
          FromChannelList={true}
        />
      )}

      {isModal && (
        <NotifiedUsertable
          setModal={setModal}
          users={totalUserList}
          FieldChange={
            rulesDetail.userAccess === "RegisteredUser" ? true : false
          }
          FromChannelList={true}
        />
      )}
    </>
  );
};

export default AddRulesForChannel;
