import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Services from "../../Services/auth.service";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import edit from "../../Images/BackendImages/edit.svg";
import imgdelete from "../../Images/BackendImages/delete.svg";
import chat from "../../Images/BackendImages/chat.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import copyimg from "../../Images/file-copy-line 4.svg";
import list2 from "../../Images/errorwarningfill-2.svg";

const columns = [
  { id: "groupTitle", label: "Name" },
  { id: "totalGrpMember", label: "Member count" },
  { id: "totalGrpPosts", label: "Posts" },
  { id: "groupType", label: "Type" },
  { id: "groupVisibility", label: "Visibility" },
  { id: "action", label: "Actions" },
];

function createData(groupTitle, totalGrpMember, totalGrpPosts, groupType, groupVisibility, action) {
  return {
    groupTitle,
    totalGrpMember,
    totalGrpPosts,
    groupType,
    groupVisibility,
    action,
  };
}

const GroupList = ({
  setcurrentDiv,
  setselectedGid,
  setgrpdetail,
  allgrp,
  setallgrp,
  setinfotab
}) => {
  const formdata = new FormData();
  const [rows, setrow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setdeleteId] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [searchText, setsearchText] = useState("");
  const dispatch = useDispatch()
  const modalinprocess = useSelector((state) => { return state.ReduModalInProcess })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })


  useEffect(() => {
    if (allgrp !== undefined && allgrp.length > 0) {
      var data = allgrp;
      var tbl = [];
      for (var i = 0; i < data.length; i++) {
        var totalpost = data[i].totalGrpPosts >= 0 ? data[i].totalGrpPosts : 0 
        tbl[i] = createData(
          data[i].groupTitle,
          data[i].totalGrpMember,
          totalpost,
          data[i].groupType,
          data[i].groupVisibility,
          data[i]._id
        );
      }
      setrow(tbl);
    }
  }, [allgrp]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (value) => {
    Services.getadmingroupbyid(value)
      .then((res) => {
        if (res.data.status) {
          Services.gettopicsbygroupid_byadmin(value)
          .then((response)=>{
            var arr = []
            for(var i = 0 ; i < response.data.data.length ; i++)
            {
                arr[i] = response.data.data[i].topic
            }
            setgrpdetail({...res.data.data,topics:arr});
            setselectedGid(value);
            setcurrentDiv("dashboard");
          })
          .catch((e)=>{
            console.log(e)
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleInfo = (value) => {
    Services.getadmingroupbyid(value)
      .then((res) => {
        if (res.data.status) {
          Services.gettopicsbygroupid(value)
          .then((response)=>{
            var arr = []
            for(var i = 0 ; i < response.data.data.length ; i++)
            {
                arr[i] = response.data.data[i].topic
            }
            setgrpdetail({...res.data.data,topics:arr});
            setselectedGid(value);
            setinfotab(false);
            setcurrentDiv("dashboard");
          })
          .catch((e)=>{
            console.log(e)
          })
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const handleSearch = () => {
    console.log(allgrp);
    if (allgrp !== undefined && allgrp.length > 0) {
      var data = allgrp.filter((g) => {
        if (g.groupTitle.includes(searchText)) {
          return g;
        }
      });
      var tbl = [];
      for (var i = 0; i < data.length; i++) {
        var totalpost = data[i].totalGrpPosts >= 0 ? data[i].totalGrpPosts : 0 
        tbl[i] = createData(
          data[i].groupTitle,
          data[i].totalGrpMember,
          totalpost,
          data[i].groupType,
          data[i].groupVisibility,
          data[i]._id
        );
      }
      setrow(tbl);
    }
  };
  const handleDelete = () => {
    Services.deletegroup(deleteId)
      .then((res) => {
        if(res.data.status)
        {
          var arr = allgrp.filter((grp) => {
            if(grp._id !== deleteId)
              return grp;
          });
          var tbl = [];
          for (var i = 0; i < arr.length; i++) {
            var totalpost = arr[i].totalGrpPosts >= 0 ? arr[i].totalGrpPosts : 0 
            tbl[i] = createData(
              arr[i].groupTitle,
              arr[i].totalGrpMember,
              totalpost,
              arr[i].groupType,
              arr[i].groupVisibility,
              arr[i]._id
            );
          }
          setrow(tbl);
        }
        setshowalert(true);
        setalertmsg(res.data.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
        }, 3000);
        setcurrentDiv("lisiting");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSorting = (e) => {
    var normal = allgrp;
    var arr = [...allgrp];

    if (e.target.value === "a-z") {
      arr.sort(function (a, b) {
        var textA = a.groupTitle.toUpperCase();
        var textB = b.groupTitle.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (e.target.value === "z-a") {
      arr.sort(function (a, b) {
        var textA = a.groupTitle.toUpperCase();
        var textB = b.groupTitle.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }
    else{
      arr = normal;
    }
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      var totalpost = arr[i].totalGrpPosts >= 0 ? arr[i].totalGrpPosts : 0 
      tbl[i] = createData(
        arr[i].groupTitle,
        arr[i].totalGrpMember,
        totalpost,
        arr[i].groupType,
        arr[i].groupVisibility,
        arr[i]._id
      );
    }
    setrow(tbl);
    console.log(arr);
  };

  const handleSort = (col,direc) => {
    var arr = allgrp;
    if (direc === "asc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }
    else if(direc === "desc"){
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    }
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      var totalpost = arr[i].totalGrpPosts >= 0 ? arr[i].totalGrpPosts : 0
      tbl[i] = createData(
        arr[i].groupTitle,
        arr[i].totalGrpMember,
        totalpost,
        arr[i].groupType,
        arr[i].groupVisibility,
        arr[i]._id
      );
    }
    setrow(tbl);
  }

  const handleCopy = (id) => {
    Services.clonegroup({groupId: id}).then((res)=>{
      if(res.data.status)
      {
        setshowalert(true);
        setalertmsg(res.data.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
        }, 3000);
        setcurrentDiv("lisiting");
      }
      else{
        setshowalert(true);
        setalertmsg(res.data.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
        }, 3000);
      }
    })
  };
    return (
         <>
     <div className="users-info-content">
       <div className="users-info-content-main">
       <div className="uic-title">
            <h1>All groups List</h1>
          </div>

          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setsearchText(e.target.value);
                }}
              />
              <i className="fa fa-search" onClick={handleSearch}></i>
            </div>

            <div className="uic-fl-btn sort-by-mn">
              <div className="sort-by-mn-title">Sort by : </div>
              <div className="sort-by-mn-select">
              <select onChange={handleSorting}>
                <option>All</option>
                <option value="a-z">A-Z</option>
                <option value="z-a">Z-A</option>
              </select> 
              </div>
              </div> 



          </div>
       
       
          {showalert && <div className="alert alert-info">{alertmsg}</div>}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="uic-th-td w-10"
                      >{column.id !== 'action' ? <>
                        <span className="custom-sorting">
                          <i
                            className="fa fa-angle-down"
                            onClick={(e)=>{handleSort(column.id,'asc')}}
                          ></i>
                          <i
                            className="fa fa-angle-up"
                            onClick={(e)=>{handleSort(column.id,'desc')}}
                          ></i>
                        </span>
                      </>: ""}
                      
                        {column.label}
                      </TableCell>
                     
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody className="uic-table-td">
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                          className="uic-th-td w-20"
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return column.id !== "action" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-17"
                              >
                                {value}
                              </TableCell>
                            ) : (
                              <TableCell
                                key={column.id}
                                className="uic-th-td uic-table-icon w-17"
                              >
                                <a >
                                  <img src={chat} />
                                </a>
                                <a
                                  
                                  onClick={() => {
                                    dispatch({ type: "SETSTAYONPAGE", payload: null })
                                    dispatch({ type: "REMOVEMODALINPROCESS" })
                                    handleEdit(value);
                                  }}
                                >
                                 <img src={list2} />
                                </a>
                                <a
                                  onClick={() => {
                                    handleCopy(value);
                                  }}
                                >
                                  <img src={copyimg} />
                                </a>
                                <a
                                  data-toggle="modal"
                                  data-target="#DeleteFromList"
                                  onClick={() => {
                                    setdeleteId(value);
                                  }}
                                >
                                  <img src={imgdelete} />
                                </a>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
               component="div"
               count={rows.length}
               rowsPerPage={rowsPerPage}
               page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          
        </div>
      </div>
      {/* Modal Delete */}
      <div
        className="modal fade msg-popup"
        id="DeleteFromList"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgbigdelete} />
            </div>
            <h1>Are you sure you want to delete this group?</h1>
            <div className="row">
              <div className="col-6">
              <span className="msg-btn">
              <button
                type="button"
                className="btn btn-red"
                onClick={handleDelete}
                data-dismiss="modal"
              >
                Delete
              </button>
            </span>
              </div>
              <div className="col-6">
              <span className="msg-btn">
              <button
                type="button"
                className="btn2"
                data-dismiss="modal"
              >
                Discard
              </button>
            </span>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
    )
}

export default GroupList