const isOpenModal = false;

const ModalState = (state = isOpenModal, action) => {
  switch (action.type) {
    case "ISOPEN":
      return true;
    case "ISCLOSE":
        return false;
    default:
      return state;
  }
};

export default ModalState;
