import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedGuestApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    guestTicketByEventId: builder.query({
      query: ({ event_id, params }) => {
        return {
          url: ApiRoutes.guestTicket.getGuestTicketByEventId.url.replace(
            ":event_id",
            event_id
          ),
          method: ApiRoutes.guestTicket.getGuestTicketByEventId.method,
          params,
        };
      },
      providesTags: ["GETGUESTTICKETS"],
      transformResponse: (response) => response,
    }),
    createGuestTicket: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.guestTicket.addGuestTicket.url,
          method: ApiRoutes.guestTicket.addGuestTicket.method,
          body,
        };
      },
      invalidatesTags: ["GETGUESTTICKETS", "GETGUESTTICKETSSUGGESTION"],
      transformResponse: (response) => response,
    }),
    deleteGuestTicket: builder.mutation({
      query: ({ id }) => {
        return {
          url: ApiRoutes.guestTicket.deleteGuestTicket.url.replace(":id", id),
          method: ApiRoutes.guestTicket.deleteGuestTicket.method,
        };
      },
      invalidatesTags: ["GETGUESTTICKETS", "GETGUESTTICKETSSUGGESTION"],
      transformResponse: (response) => response,
    }),
    getGuestTicketById: builder.query({
      query: ({ ticket_id }) => {
        return {
          url: ApiRoutes.guestTicket.getGuestTicketById.url.replace(
            ":ticket_id",
            ticket_id
          ),
          method: ApiRoutes.guestTicket.getGuestTicketById.method,
        };
      },
      providesTags: ["GETGUESTTICKET"],
      transformResponse: (response) => response,
    }),
    editGuestTicket: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: ApiRoutes.guestTicket.editGuestTicket.url.replace(":id", id),
          method: ApiRoutes.guestTicket.editGuestTicket.method,
          body,
        };
      },
      invalidatesTags: [
        "GETGUESTTICKET",
        "GETGUESTTICKETS",
        "GETGUESTTICKETSSUGGESTION",
      ],
      transformResponse: (response) => response,
    }),
    guestTicketSuggestionList: builder.query({
      query: ({ id }) => {
        return {
          url: ApiRoutes.guestTicket.guestTicketSuggestionList.url.replace(
            ":id",
            id
          ),
          method: ApiRoutes.guestTicket.guestTicketSuggestionList.method,
        };
      },
      providesTags: ["GETGUESTTICKETSSUGGESTION"],
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGuestTicketByEventIdQuery,
  useCreateGuestTicketMutation,
  useDeleteGuestTicketMutation,
  useEditGuestTicketMutation,
  useLazyGetGuestTicketByIdQuery,
  useGuestTicketSuggestionListQuery,
} = extendedGuestApi;
