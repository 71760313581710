const eventParticipantTypesList = [];

const EventParticipantReducer = (state = eventParticipantTypesList, action) => {
    switch (action.type) {
        case "GET_EVENT_PARTICIPANT_TYPE_LIST":
             return { ...state, eventParticipantTypesList: action.payload, };

        default:
            return state;
    }
};

export default EventParticipantReducer;
