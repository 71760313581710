import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AdminSidebar from "../AdminSidebar";
import PageNotFound from "../PageNotFound";
import Channel from "./ChannelManagement/Channels";
import CreateChannel from "./CreateChannelManagement/CreateChannel";
import EditChannel from "./EditChannelManagement/EditChannel";

const ChannelManagement = () => {
    const history = useNavigate();
    const { action } = useParams();
    const [selectedDiv, setselectedDiv] = useState("");
    const [pageNotFound, setPageNotFound] = useState(false);
    const [sidebarmenu, setSidebarmenu] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "HISTORYPAGE", payload: "/channel/channelListing" })
        if (action !== undefined && action.length > 0) setselectedDiv(action);
        if (action !== undefined && action !== null) {
            if (!["channelListing", "createChannel", "detail"].includes(action))
                setPageNotFound(true)
        } else {
            setselectedDiv("channelListing");
        }
    }, [action]);

    const updateWindowSize = () => {
        if (window.innerWidth > 992) {
            setSidebarmenu(false)
            document.body.classList.remove('overflow-hidden')
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowSize);
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);

    const sidebarHandleClick = (ele) => {
        setSidebarmenu(ele);
        if (window.innerWidth < 992) {
            if (sidebarmenu) {
                document.body.classList.remove('overflow-hidden')
            } else {
                document.body.classList.add('overflow-hidden')
            }
        }
    }

    return (
        <>
            {!pageNotFound && <div className="cstm-all-admin">
                {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
                <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
                <div className="content-main">
                    <div className="users-info">
                        <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            onClick={() => { sidebarHandleClick(false) }}
                                            className={`btn-link collapsed ${selectedDiv === "createChannel" ? "sd-active" : ""} `}
                                            to={"/channel/createChannel"}
                                        >
                                            Add channel
                                        </Link>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            onClick={() => { sidebarHandleClick(false) }}
                                            to={'/channel/channelListing'}
                                            className={`btn-link collapsed ${selectedDiv === "channelListing" ? "sd-active" : ""} `}
                                        >
                                            All channels
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {selectedDiv === "channelListing" ? (
                            <Channel />
                        ) : selectedDiv === "createChannel" ? (
                            <CreateChannel />
                        ) : selectedDiv === "detail" ? (
                            <EditChannel />
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>}
            {pageNotFound && <PageNotFound />}
        </>
    );
};
export default ChannelManagement;


// console.log("--allRegisterdUserList--->",allRegisterdUserList)
// console.log("--allParticipantsEmailArr--->",allParticipantsEmailArr)



// allParticipantsEmailArr --> all participent
// allRegisterdUserList --> registerlist