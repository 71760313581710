import React, { useState, useEffect } from "react";
import authService from "../Services/auth.service";

//Image
import loading from "../Images/loader.gif";
import shareIcon from "../Assets/share.png";
import { writeText } from "clipboard-polyfill";

export default function ShareFunctionality({ url, page }) {
  const [showCopyButton, setCopyButton] = useState(true);
  const [showLoader, setLoader] = useState(false);

  useEffect(() => {
    document
      .getElementById("copyButton")
      .addEventListener("click", async () => {
        try {
          setLoader(true);
          const androidParameters = {
            androidPackageName: process.env.REACT_APP_ANDROID_PACKAGENAME,
            androidFallbackLink: process.env.REACT_APP_ANDROID_FALLBACKLINK,
          };
          const iosParameters = {
            iosBundleId: process.env.REACT_APP_ANDROID_PACKAGENAME,
            iosFallbackLink: process.env.REACT_APP_ANDROID_FALLBACKLINK,
            iosAppStoreId: process.env.REACT_APP_IOS_APPSTOREID,
          };
          const socialMetaTagParameters = {
            socialTitle: process.env.REACT_APP_SOCIAL_TITLE,
            socialDescription: "Click here",
          };
          const bodyRequestParam = {
            dynamicLinkInfo: {
              domainUriPrefix: process.env.REACT_APP_FIREBASE_URIPREFIX,
              link: process.env.REACT_APP_FIREBASE_URIPREFIX + url,
              androidInfo: androidParameters,
              iosInfo: iosParameters,
              socialMetaTagInfo: socialMetaTagParameters,
            },
            suffix: {
              option: process.env.REACT_APP_SUFFIX_OPTION,
            },
          };
          const API_key = process.env.REACT_APP_FIREBASE_API_KEY;
          const res = await authService.getShortShareLink(
            API_key,
            bodyRequestParam
          );
          if (res.status === 200) {
            writeText(res.data.shortLink); // Copy text to clipboard using clipboard-polyfill
            setLoader(false);
            setCopyButton(false);
          }
        } catch (error) {
          console.error("Clipboard access error:", error);
        }
      });
  }, [showLoader]);

  //onClick share button handler

  const onClickShareButton = () => {
    console.log("API calling");
    setLoader(true);
    // const androidParameters = {
    //   androidPackageName: process.env.REACT_APP_ANDROID_PACKAGENAME,
    //   androidFallbackLink: process.env.REACT_APP_ANDROID_FALLBACKLINK,
    // };
    // const iosParameters = {
    //   iosBundleId: process.env.REACT_APP_ANDROID_PACKAGENAME,
    //   iosFallbackLink: process.env.REACT_APP_ANDROID_FALLBACKLINK,
    //   iosAppStoreId: process.env.REACT_APP_IOS_APPSTOREID,
    // };
    // const socialMetaTagParameters = {
    //   socialTitle: process.env.REACT_APP_SOCIAL_TITLE,
    //   socialDescription: "Click here",
    // };
    // const bodyRequestParam = {
    //   dynamicLinkInfo: {
    //     domainUriPrefix: process.env.REACT_APP_FIREBASE_URIPREFIX,
    //     link: process.env.REACT_APP_FIREBASE_URIPREFIX + url,
    //     androidInfo: androidParameters,
    //     iosInfo: iosParameters,
    //     socialMetaTagInfo: socialMetaTagParameters,
    //   },
    //   suffix: {
    //     option: process.env.REACT_APP_SUFFIX_OPTION,
    //   },
    // };
    // const API_key = process.env.REACT_APP_FIREBASE_API_KEY;
    // authService
    //   .getShortShareLink(API_key, bodyRequestParam)
    //   .then((res) => {
    //     if (res.status === 200) {
    //       setLoader(false);
    //       setCopyButton(false);
    //       navigator.clipboard.writeText(res.data.shortLink);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e, "error");
    //     return false;
    //   });
  };

  setTimeout(() => {
    setCopyButton(true);
  }, 3000);

  return (
    <>
      {showCopyButton ? (
        <button className="share-btn" id="copyButton" onClick={onClickShareButton}>
          {page === "partner" ? (
           <span>Share</span>
          ) : page === "chat" ? (
            <>
              <img src={shareIcon} alt="" />
              <p>Share your chat</p>
            </>
          ) : showLoader ? (
            <div className="savedvideo-loading-image">
              <img src={loading} />
            </div>
          ) : (
            <i class="fa fa-share-alt" aria-hidden="true"></i>
          )}
        </button>
      ) : (
        <button className="share-btn" disabled>Copied!</button>
      )} 
    </>
  );
}

  
 