import React, { useEffect, useState ,useRef} from 'react'
import NewsBanner from './NewsBanner'
import TrendingPost from './TrendingPost'
import Header from '../Dashboard/Header'
import LeftSidebar from '../Dashboard/LeftSidebar'
import { Link, useLocation, useParams } from 'react-router-dom'
import '../../../Styles/newsManagement.css'
import closebtn from "../../../Images/close-btn.svg";
import modellogo from "../../../Images/mds-model-logo.svg";
import modeimg from "../../../Images/model-img.png";
import playstore from "../../../Images/playstore-logo.svg";
import appstore from "../../../Images/appstore-logo.svg";
import FeaturedNews from './FeaturedNews'
import AllNews from './AllNews'
import NewsDetail from './NewsDetail'
import AccessDeny from '../../AccessDeny'
import { useDispatch, useSelector } from 'react-redux'

const index = () => {
    const { action } = useParams()
    const textbox = useRef(null);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selected, setSelected] = useState("list");
    const [selectedDiv, setSelectedDiv] = useState("dashboard");
    const [topicSearch, setTopicSearch] = useState("");
    const [leftSidebar, setLeftSidebar] = useState(true);
    const [loader, setLoader] = useState(true)
    const [referalEmail, setReferalEmail] = useState("")


    const dispatch = useDispatch()
    const migratePopup = useSelector((state) => {
        return state.ReduMigrateUserPopup;
    });

    const names = (localStorage.getItem("username") || "").split(" ");
    const fname = names[0] || "";
    const lname = names.slice(1).join(" ") || "";
    //call on first render
    useEffect(() => {
        if (!localStorage.getItem("isreload")) {
            localStorage.setItem("isreload", true)
            window.location.reload(true)
        }
        if (
            localStorage.getItem("idtoken") === null ||
            localStorage.getItem("idtoken").length === 0 ||
            localStorage.getItem("userid") === null ||
            localStorage.getItem("userid").length === 0 ||
            localStorage.getItem("username") === null ||
            localStorage.getItem("username").length === 0 ||
            localStorage.getItem("role") === null ||
            localStorage.getItem("role") !== "user" ||
            localStorage.getItem("logintime") === undefined ||
            localStorage.getItem("logintime") === null
        ) {
            window.location.href = "/";
        }
        setLoader(true)
    }, [])

    useEffect(() => {
        if (action !== undefined) {
            setSelected(action)
        } else {
            setSelected("list")
        }
        sessionStorage.setItem("previousurl", window.location.href)
    }, [action])

    //call on textbox change
    useEffect(() => {
        if (textbox.current) {
          adjustHeight();
        }
      }, [textbox]);

    const resourceList = localStorage.getItem("accessResources")
    const isCollaborator = localStorage.getItem('isCollaborator') !== undefined && localStorage.getItem('isCollaborator') !== null && JSON.parse(localStorage.getItem('isCollaborator'))
    const newsResource = resourceList !== undefined && resourceList !== null && resourceList !== "undefined" && JSON.parse(resourceList).find(resource => resource.name === "News");

    return (
        <>
            {isCollaborator && newsResource !== undefined && newsResource ?
                <>
                    <Header
                        setSelectedGroup={setSelectedGroup}
                        setselectedDiv={setSelectedDiv}
                        settopicSearch={setTopicSearch}
                    />

                    <div
                        className={
                            leftSidebar
                                ? `all-content-main cstm-all-content-main  ${action !== "detail"
                                    ? window.location.href.indexOf("frontnews") || window.location.pathname === "/homepage" >
                                        -1 ||
                                        window.location.hostname ===
                                        process.env.REACT_APP_TESTSITE_HOSTNAME
                                        ? "cstm-all-library-content-main cstm-all-content-library-main-only"
                                        : "cstm-all-library-content-main"
                                    : ""
                                } ${action === "detail"
                                    ? "cstm-all-library-video-details"
                                    : "mn-cnt3-wd"
                                }`
                                : "all-content-main mn-cnt-both"
                        }
                    >
                        {action !== 'detail' ?
                            (<LeftSidebar
                                setSelectedGroup={setSelectedGroup}
                                setselectedDiv={setSelectedDiv}
                                selectedDiv={selectedDiv}
                            />) : (
                                <></>
                            )
                        }
                        <div
                            className={
                                selected === "list" || action !== "detail" || window.location.pathname === "/homepage"
                                    ? "all-content-main-center all-content-main-center"
                                    : "all-content-main-center"
                            }
                        >
                            {selected === "list" ?
                                <>
                                    <div className='newsmanagement-main'>
                                        <NewsBanner setLoader={setLoader} loader={loader} />
                                        <div className='trending-posts-main-all'>
                                            <TrendingPost setLoader={setLoader} loader={loader} />
                                            <FeaturedNews setLoader={setLoader} loader={loader} />
                                            <AllNews setLoader={setLoader} loader={loader} />
                                        </div>
                                    </div>
                                </> : <>
                                    <NewsDetail action={action} />
                                </>
                            }
                        </div>
                    </div>
                </> : isCollaborator !== null && !isCollaborator ?
                    <>
                        <Header
                            setSelectedGroup={setSelectedGroup}
                            setselectedDiv={setSelectedDiv}
                            settopicSearch={setTopicSearch}
                        />

                        <div
                            className={
                                leftSidebar
                                    ? `all-content-main cstm-all-content-main  ${action !== "detail"
                                        ? window.location.href.indexOf("frontnews") || window.location.pathname === "/homepage" >
                                            -1 ||
                                            window.location.hostname ===
                                            process.env.REACT_APP_TESTSITE_HOSTNAME
                                            ? "cstm-all-library-content-main cstm-all-content-library-main-only"
                                            : "cstm-all-library-content-main"
                                        : ""
                                    } ${action === "detail"
                                        ? "cstm-all-library-video-details"
                                        : "mn-cnt3-wd"
                                    }`
                                    : "all-content-main mn-cnt-both"
                            }
                        >
                            {action !== 'detail' ?
                                (<LeftSidebar
                                    setSelectedGroup={setSelectedGroup}
                                    setselectedDiv={setSelectedDiv}
                                    selectedDiv={selectedDiv}
                                />) : (
                                    <></>
                                )
                            }
                            <div
                                className={
                                    selected === "list" || action !== "detail" || window.location.pathname === "/homepage"
                                        ? "all-content-main-center all-content-main-center"
                                        : "all-content-main-center"
                                }
                            >
                                {selected === "list" ?
                                    <>
                                        <div className='newsmanagement-main'>
                                            <NewsBanner setLoader={setLoader} loader={loader} />
                                            <div className='trending-posts-main-all'>
                                                <TrendingPost setLoader={setLoader} loader={loader} />
                                                <FeaturedNews setLoader={setLoader} loader={loader} />
                                                <AllNews setLoader={setLoader} loader={loader} />
                                            </div>
                                        </div>
                                    </> : <>
                                        <NewsDetail action={action} />
                                    </>
                                }
                            </div>
                        </div>
                    </> : isCollaborator && newsResource === undefined ?
                        <AccessDeny /> : <></>
            }
            {migratePopup && <div className="desk-globle-model-box globle-model-box">
                <div class="mds-model-box-main">
                    <div class="mds-model-box-body">
                        <div class="close-btn">
                            <img
                                src={closebtn}
                                alt="close-btn"
                                onClick={() => {
                                    dispatch({ type: "ClosePopup" });
                                }}
                            />
                        </div>
                        <div class="mds-model-logo">
                            <img src={modellogo} alt="close-btn" />
                        </div>
                        <div class="mds-model-heading">
                            <h3>A New Way of Exploring Our Community’s Content</h3>
                        </div>
                        <div class="mds-model-main-img">
                            <img src={modeimg} alt="close-btn" />
                        </div>
                        <div class="mds-model-button-container">
                            <a
                                href="https://apps.apple.com/app/id1636838955"
                                target="_blank"
                                class="mds-button appstore"
                            >
                                <img src={appstore} alt="App store" />
                                <p>
                                    <span>Download on the</span>
                                    App Store
                                </p>
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.app.mdscommunity"
                                target="_blank"
                                class="mds-button playstore"
                            >
                                <img src={playstore} alt="Playstore" />
                                <p>
                                    <span>Get it on</span>
                                    Google Play
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default index
