import { useEffect, useState, useRef, useReducer } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import arrowleft from "../../../Images/arrow-left.png";
import authServiceEvent from "../../../Services/auth.service.event";
import EditEventInfo from "./EditEventInfo";
import Locations from "../LocationManagement/Locations";
import Packages from "../PackageManagement/Packages";
import Rooms from "../RoomManagement/Rooms";
import Sessions from "../SessionManagement/Sessions";
import Activities from "../ActivityManagement/Activities";
import Attendees from "../AttendeesManagement/Attendees";
import Faqs from "../FaqManagement/Faqs";
import Support from "../Support/SupportDetails";
import UploadPhotos from "../PhotosManagement.js/UploadPhotos";
import ParticipantType from "./ParticipantType";
import GuestTickets from "../GuestTicketsManagement/index";
import Addons from "../AddOnsManagement/index";
import MainTickets from "../MainTicketManagement/index";
import CheckInManagement from "../CheckInManagement/CheckInList";
import ToastNotification from "../../ToastNotification/ToastNotification";

const EventDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [eventDetail, setEventDetail] = useState({});
  const [tabInformation, setTabInformation] = useState(
    new URLSearchParams(location.search).get("tab") || "Info"
  );
  const [tabFocus, setTabFocus] = useState(false);
  const [copy, setCopy] = useState(false);
  const [apiCall, setApiCall] = useState(false);

  const guestTicketsRef = useRef();
  const checkInRef = useRef();
  const attendeeRef = useRef();

  const checkInAllow = useSelector((state) => {
    return state?.CheckInAllow;
  });

  //event id
  const eventId = new URLSearchParams(location.search).get("id");
  const activityId = new URLSearchParams(location.search).get("activityId");
  const checkInIdFromUrl = new URLSearchParams(location.search).get(
    "checkin-id"
  );
  const attendeeIdFromUrl = new URLSearchParams(location.search).get(
    "attendee-id"
  );
  const roleIdFromUrl = new URLSearchParams(location.search).get("role-id");
  const activityIdFromUrl = new URLSearchParams(location.search).get(
    "activityId"
  );
  const ticketIdFromUrl = new URLSearchParams(location.search).get("ticketId");
  const statusFromUrl = new URLSearchParams(location.search).get("status");
  const attendeeTypeFromUrl = new URLSearchParams(location.search).get(
    "attendeetype"
  );

  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });

  useEffect(() => {
    dispatch({ type: "HISTORYPAGE", payload: "/events/list?search=" });
    authServiceEvent
      .getEventById(queryVariable)
      .then((response) => {
        if (response.data.status) {
          setEventDetail(response.data.data);
          dispatch({
            type: "isCheckInAllow",
            payload: response.data.data.isCheckInAllow,
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, [queryVariable, apiCall]);

  const guestTicketRedirect = () => {
    setTabInformation("guestTickets");
    setTabFocus(true);
  };

  useEffect(() => {
    // Check if tabFocus becomes true
    if (tabFocus && guestTicketsRef.current) {
      // If true and the ref is available
      guestTicketsRef.current.click(); // Simulate click on Guest Tickets tab link
    }
  }, [tabFocus]);

  useEffect(() => {
    const tabParam = new URLSearchParams(location.search).get("tab");
    if (tabParam === "checkin") {
      setTabInformation(tabParam);
      if (checkInRef.current) {
        checkInRef.current.click();
      }
    } else if (tabParam === "Attendees") {
      setTabInformation(tabParam);
      attendeeRef.current.click();
    }
  }, [location.search, checkInAllow]);

  useEffect(() => {
    if (tabInformation === "addons") {
      setTabFocus(false);
    }
    if (tabInformation !== "guestTickets") {
      navigate(
        `/events/detail?id=${eventId}${
          tabInformation === "checkin" || tabInformation === "Attendees"
            ? `&tab=${tabInformation}${
                checkInIdFromUrl ? `&checkin-id=${checkInIdFromUrl}` : ""
              }${activityIdFromUrl ? `&activityId=${activityIdFromUrl}` : ""}${
                ticketIdFromUrl ? `&ticketId=${ticketIdFromUrl}` : ""
              }${statusFromUrl ? `&status=${statusFromUrl}` : ""}${
                attendeeTypeFromUrl
                  ? `&attendeetype=${attendeeTypeFromUrl}`
                  : ""
              }${attendeeIdFromUrl ? `&attendee-id=${attendeeIdFromUrl}` : ""}${
                roleIdFromUrl ? `&role-id=${roleIdFromUrl}` : ""
              }`
            : ""
        }`
      );
    }
  }, [tabInformation]);

  const handleCopyEventUrl = () => {
    navigator.clipboard.writeText(
      `https://link.milliondollarsellers.com/?redirectto=event_details&event_id=${eventId}&event_title=${eventDetail?.title}`
    );
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  const handleCopyBannerUrl = () => {
    navigator.clipboard.writeText(
      `https://event_redirect_screen&${eventId}&${eventDetail?.title
        .trim()
        .replace(/\s+/g, "_")}`
    );
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };
  return (
    <div className="users-info-content">
      <div className="users-info-content-main">
        <div className="um-back">
          <button
            type="button"
            className="back-btn"
            onClick={() => {
              if (modalInProcess) {
                dispatch({ type: "SETCUSTOMPAGECHANGE" });
                dispatch({
                  type: "NAVIGATEPAGE",
                  payload: "/events/list?search=",
                });
              } else {
                navigate(
                  NAVIGATEPAGE !== null && NAVIGATEPAGE !== ""
                    ? NAVIGATEPAGE
                    : "/events/list?search="
                );
              }
            }}
          >
            <img src={arrowleft} />
            Back
          </button>
        </div>

        <div className="d-flex align-items-center justify-content-between flex-wrap admin-event-details-heading-text">
          <h1>{eventDetail.title}</h1>
          <div className="uic-fl-mn-right cstm-sec-button flex-wrap">
            <div className="uic-fl-links">
              <a class="btn px-0" onClick={handleCopyEventUrl}>
                Copy notification URL
              </a>
            </div>
            <div className="uic-fl-links">
              <a onClick={handleCopyBannerUrl} class="btn px-0">
                Copy banner URL
              </a>
            </div>
            {copy && (
              <div className="toast-wrapper top right">
                <ToastNotification
                  onCancel={() => setCopy(false)}
                  title={"URL COPIED!"}
                />
              </div>
            )}
          </div>
        </div>
        <div className="group-listing-main">
          <ul
            className="nav group-listing-menu cstm-tabs-menu-only"
            role="tablist"
          >
            <li>
              <a
                className="active"
                href="#Info"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("Info");
                }}
              >
                Info
              </a>
            </li>
            <li>
              <a
                className=""
                href="#Location"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("Location");
                }}
              >
                Location
              </a>
            </li>
            <li>
              <a
                className=""
                href="#Packages"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("Packages");
                }}
              >
                Packages
              </a>
            </li>
            <li>
              <a
                className=""
                href="#Room"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("Room");
                }}
              >
                Room
              </a>
            </li>
            <li>
              <a
                className=""
                href="#participantType"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("participantType");
                }}
              >
                Participants Type
              </a>
            </li>
            <li>
              <a
                className=""
                href="#Attendees"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("Attendees");
                }}
                ref={attendeeRef}
              >
                Attendees
              </a>
            </li>
            {checkInAllow && (
              <li>
                <a
                  className=""
                  href="#checkin"
                  role="tab"
                  data-toggle="tab"
                  onClick={() => {
                    setTabInformation("checkin");
                  }}
                  ref={checkInRef}
                >
                  Check-ins
                </a>
              </li>
            )}

            <li>
              <a
                className=""
                href="#Session"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("Session");
                }}
              >
                Session
              </a>
            </li>
            <li>
              <a
                className=""
                href="#Activity"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("Activity");
                }}
              >
                Activity
              </a>
            </li>

            <li>
              <a
                className=""
                href="#Support"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("Support");
                }}
              >
                Support
              </a>
            </li>
            <li>
              <a
                className=""
                href="#FAQs"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("FAQs");
                }}
              >
                FAQs
              </a>
            </li>
            <li>
              <a
                className=""
                href="#Photos"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("Photos");
                }}
              >
                Photos
              </a>
            </li>
            <li>
              <a
                className=""
                href="#mainTickets"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("mainTickets");
                }}
              >
                Main Tickets
              </a>
            </li>
            <li>
              <a
                className=""
                href="#guestTickets"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("guestTickets");
                }}
                ref={guestTicketsRef}
              >
                Guest Tickets
              </a>
            </li>
            <li>
              <a
                className=""
                href="#addons"
                role="tab"
                data-toggle="tab"
                onClick={() => {
                  setTabInformation("addons");
                }}
              >
                Addons
              </a>
            </li>
          </ul>

          <div className="tab-content group-listing-tabs">
            <div
              role="tabpanel"
              className={false ? "tab-pane fade" : "tab-pane in active"}
              id="Info"
            >
              <h5 className="gl-title-sub">Event Info</h5>
              {tabInformation === "Info" && (
                <EditEventInfo setApiCall={setApiCall} />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="Location">
              {tabInformation === "Location" && (
                <Locations tabInformation={tabInformation} />
              )}
            </div>

            <div role="tabpanel" className="tab-pane fade" id="Packages">
              {tabInformation === "Packages" && (
                <Packages tabInformation={tabInformation} />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="Room">
              {tabInformation === "Room" && (
                <Rooms tabInformation={tabInformation} />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="Session">
              {tabInformation === "Session" && (
                <Sessions tabInformation={tabInformation} />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="Activity">
              {tabInformation === "Activity" && (
                <Activities
                  tabInformation={tabInformation}
                  activityId={activityId}
                />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="Attendees">
              {tabInformation === "Attendees" && (
                <Attendees
                  tabInformation={tabInformation}
                  eventName={eventDetail.title}
                />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="checkin">
              {tabInformation === "checkin" && (
                <CheckInManagement
                  tabInformation={tabInformation}
                  eventId={eventId}
                />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="Support">
              {tabInformation === "Support" && (
                <Support tabInformation={tabInformation} />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="FAQs">
              {tabInformation === "FAQs" && (
                <Faqs tabInformation={tabInformation} />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="Photos">
              {tabInformation === "Photos" && (
                <UploadPhotos tabInformation={tabInformation} />
              )}
            </div>

            <div role="tabpanel" className="tab-pane fade" id="participantType">
              {tabInformation === "participantType" && (
                <ParticipantType tabInformation={tabInformation} />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="mainTickets">
              {tabInformation === "mainTickets" && (
                <MainTickets
                  tabInformation={tabInformation}
                  locationType={eventDetail?.locationType}
                  eventUrl={eventDetail?.eventUrl}
                />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="guestTickets">
              {tabInformation === "guestTickets" && (
                <GuestTickets tabInformation={tabInformation} />
              )}
            </div>
            <div role="tabpanel" className="tab-pane fade" id="addons">
              {tabInformation === "addons" && (
                <Addons
                  tabInformation={tabInformation}
                  guestTicketRedirect={guestTicketRedirect}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EventDetail;
