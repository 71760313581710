import React from 'react'

const ZoomImageModal = ({ zoomImg, setIsZoomImg, title }) => {
    return (
        <>
            <div className="desk-globle-model-box globle-model-box cstm-zoom-img-popup cstm-company-logo">
          <div className="desk-globle-model-box-inner cstm-zoom-img-popup">
            <div className="modal-content">
              <div className="cstm-replace-pp">
                <div className="cstm-rpp-title">
                  {title !== undefined && title !== null ? title : "Logo Image"  }
                  <span
                    className="cstm-rpp-close-btn"
                    onClick={() => setIsZoomImg(false)}
                  >
                    &times;
                  </span>
                </div>

                {zoomImg && (
                  <div id="zoomImgContainer" className="zoomImgContainer">
                    <img id="zoomImg" src={zoomImg} className="cstmzoomImg" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        </>
    )
}

export default ZoomImageModal