import React from "react";
import PropTypes from "prop-types"
function NavDirectoryIcon({ height = 24, width = 24, iconColor = "#000", isFilled }) {
    return (
        <>
            {isFilled ?
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M13.5 3.5h-9a2 2 0 0 0-2 2v13l.001.077A2 2 0 0 0 4.5 20.5h9a2 2 0 0 0 2-2v-13a2 2 0 0 0-2-2ZM4.5 2A3.5 3.5 0 0 0 1 5.5v13A3.5 3.5 0 0 0 4.5 22h9a3.5 3.5 0 0 0 3.5-3.5v-13A3.5 3.5 0 0 0 13.5 2h-9ZM19.25 4.25A.75.75 0 0 1 20 5v14a.75.75 0 0 1-1.5 0V5a.75.75 0 0 1 .75-.75ZM22.25 6.25A.75.75 0 0 1 23 7v10a.75.75 0 0 1-1.5 0V7a.75.75 0 0 1 .75-.75Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M13.5 3.5h-9a2 2 0 0 0-2 2v11.35l3.4-1.942a6.25 6.25 0 0 1 6.2 0l3.4 1.943V5.5a2 2 0 0 0-2-2ZM9 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill={iconColor}
                        d="M11.5 9a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM4.5 20.5h9a2 2 0 0 0 1.998-1.923l-4.141-2.367a4.75 4.75 0 0 0-4.714 0l-4.142 2.367A2 2 0 0 0 4.5 20.5Z"
                    />
                </svg > : <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M13.5 3.5h-9a2 2 0 0 0-2 2v13l.001.077A2 2 0 0 0 4.5 20.5h9a2 2 0 0 0 2-2v-13a2 2 0 0 0-2-2ZM4.5 2A3.5 3.5 0 0 0 1 5.5v13A3.5 3.5 0 0 0 4.5 22h9a3.5 3.5 0 0 0 3.5-3.5v-13A3.5 3.5 0 0 0 13.5 2h-9ZM19.25 4.25A.75.75 0 0 1 20 5v14a.75.75 0 0 1-1.5 0V5a.75.75 0 0 1 .75-.75ZM22.25 6.25A.75.75 0 0 1 23 7v10a.75.75 0 0 1-1.5 0V7a.75.75 0 0 1 .75-.75Z"
                        clipRule="evenodd"
                    />
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M11.357 16.21a4.75 4.75 0 0 0-4.714 0l-4.142 2.367L2.5 16.85l3.4-1.942a6.25 6.25 0 0 1 6.2 0l3.4 1.943-.002 1.726-4.141-2.367ZM9 11.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM9 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                        clipRule="evenodd"
                    />
                </svg>
            }
        </>
    );
}
NavDirectoryIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool
}
export default NavDirectoryIcon;
