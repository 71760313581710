import React, { useEffect, useState, useRef } from "react";
import { PropTypes } from "prop-types";
import Avtar from "../../../components/Avtar/Avtar";
import Button from "../../../components/Button/Button";

// Icons
import StarIcon from "../../../components/Icons/GeneralIcons/StarIcon";
import InfoIcon from "../../../components/Icons/GeneralIcons/InfoIcon";
import MuteIcon from "../../../components/Icons/GeneralIcons/MuteIcon";
import NotificationIcon from "../../../components/Icons/GeneralIcons/NotificationIcon";
import DeleteIcon from "../../../components/Icons/GeneralIcons/DeleteIcon";
import DotsIcon from "../../../components/Icons/GeneralIcons/DotsIcon";
import SmallNotificationFillIcon from "../../../components/Icons/GeneralIcons/SmallNotificationFillIcon";
import LogoutIcon from "../../../components/Icons/GeneralIcons/LogoutIcon";
import EditIcon from "../../../components/Icons/GeneralIcons/EditIcon";
import ClearIcon from "../../../components/Icons/GeneralIcons/ClearIcon";

// CSS
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatHeading.css";
import DropDownItem from "../../../components/DropDownItem/DropDownItem";

const ChatHeading = ({
  onSelect,
  isAdmin = false,
  type = "Channel",
  isMuted = false,
  size = "small",
  GroupName = "",
  ChannelName = "",
  AvtarName = "Avtar Name",
  avtarSrc = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  avtarType = "photo",
  avtarLetter = "A",
  onlineStatus = "offline",
  handleStarUser,
  handleInfoClick,
  filled = false,
}) => {
  const [isDropDownVisible, setDropDownVisible] = useState(false);
  const dropDownRef = useRef(null);

  // outsideclick handle
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropDownVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  //handle 3 dot click header
  const handleDotsIconClick = () => {
    setDropDownVisible(!isDropDownVisible);
  };

  // get Dropdown options function
  const generateContextMenuOptions = (chatType) => {
    const commonOptions = [
      {
        icon: isMuted ? (
          <MuteIcon
            height={24}
            width={24}
            iconColor="var(--color-icon-neutral-strong)"
          />
        ) : (
          <NotificationIcon
            height={24}
            width={24}
            iconColor="var(--color-icon-neutral-strong)"
          />
        ),
        name: isMuted ? "Unmute notification" : "Mute notification",
        isError: false,
      },
      {
        icon: (
          <ClearIcon
            height={24}
            iconColor="var(--color-icon-neutral-strong)"
            width={24}
          />
        ),
        name: "Clear history",
        isError: false,
      },
    ];

    switch (chatType) {
      case "Channel":
        return [
          ...commonOptions,
          {
            icon: (
              <DeleteIcon
                width={24}
                height={24}
                iconColor="var(--color-icon-danger-strong)"
              />
            ),
            name: "Leave channel",
            isError: true,
          },
        ];
      case "Group":
        const groupOptions = [
          {
            icon: (
              <LogoutIcon
                height={24}
                iconColor="var(--color-icon-danger-strong)"
                width={24}
              />
            ),
            name: "Leave group",
            isError: true,
          },
        ];

        if (isAdmin) {
          groupOptions.unshift({
            icon: (
              <EditIcon
                width={24}
                height={24}
                iconColor="var(--color-icon-neutral-strong)"
              />
            ),
            name: "Edit group",
            isError: false,
          });
          groupOptions.push({
            icon: (
              <DeleteIcon
                height={24}
                iconColor="var(--color-icon-danger-strong)"
                width={24}
              />
            ),
            name: "Delete group",
            isError: true,
          });
        }

        return [...commonOptions, ...groupOptions];
      case "AvtarName": /// this option is user type
        return [...commonOptions];
      default:
        return [];
    }
  };

  //get Dropdown options
  const options = generateContextMenuOptions(type);

  return (
    <div className={`flex items-center chatHeading-sec ${type}`}>
      <div className="flex items-center chatHeading-left">
        <div className="flex items-center chatHeading-input">
          {type === "Channel" && (
            <>
              <Avtar
                size="32"
                src={avtarSrc}
                type={avtarSrc.length ? "photo" : "letter"}
                hasStatus={false}
                letter={avtarLetter}
              />
              <h5 className="chat-text">{ChannelName}</h5>
            </>
          )}

          {type === "Group" && (
            <>
              <Avtar
                size="32"
                src={avtarSrc}
                type={avtarSrc.length ? "photo" : "letter"}
                hasStatus={false}
                letter={avtarLetter}
              />

              <h5 className="chat-text">{GroupName}</h5>
            </>
          )}

          {type === "AvtarName" && (
            <>
              <Avtar
                type={avtarType}
                src={avtarSrc}
                hasStatus={false}
                badgeStatus={onlineStatus}
                letter={avtarLetter}
              />
              <h5 className="chat-text">{AvtarName}</h5>
            </>
          )}
        </div>
        <div className="chat-muted">
          {isMuted && (
            <SmallNotificationFillIcon iconColor="var(--color-text-neutral-tertiary)" />
          )}
        </div>
      </div>

      <div className="flex items-center relative" ref={dropDownRef}>
        <Button
          elementIcon={<StarIcon isFilled={filled} />}
          onContextMenu={() => {}}
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          size="small"
          variant="ghost"
          hasText={false}
          hasToolTip={true}
          toolTipId="starToolTip"
          buttonId="starButton"
          toolTipText="Move to starred"
          handleClick={handleStarUser}
        />

        <Button
          elementIcon={<InfoIcon />}
          handleClick={handleInfoClick}
          size="small"
          variant="ghost"
          hasText={false}
          hasToolTip={true}
          toolTipId="infoToolTip"
          buttonId="infoButton"
          toolTipText="Hide details"
        />

        <Button
          elementIcon={<DotsIcon />}
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          onContextMenu={() => {}}
          handleClick={handleDotsIconClick}
          size="small"
          variant="ghost"
          hasText={false}
          hasToolTip={true}
          toolTipId="moreToolTip"
          buttonId="moreButton"
          toolTipText="More"
        />
        {isDropDownVisible && (
          <DropDownItem
            size="small"
            options={options}
            isIcon={true}
            onSelect={onSelect}
          />
        )}
      </div>
    </div>
  );
};

ChatHeading.propTypes = {
  type: PropTypes.oneOf(["Channel", "Group", "AvtarName"]),
  isMuted: PropTypes.bool,
  filled: PropTypes.bool,
  size: PropTypes.string,
  GroupName: PropTypes.string,
  ChannelName: PropTypes.string,
  AvtarName: PropTypes.string,
  avtarSrc: PropTypes.string,
  avtarType: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds"]),
  avtarLetter: PropTypes.string,
  onlineStatus: PropTypes.oneOf(["offline", "online"]),
  handleClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  handleStarUser: PropTypes.func,
  handleInfoClick: PropTypes.func,
  onSelect: PropTypes.func,
};

export default ChatHeading;
