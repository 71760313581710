import axios from "axios";
import authAdminHeader from './auth.admin.header'
const API_URL = process.env.REACT_APP_CHAT_BASE_API_URL;

const getMessageReportList = () => {
    return axios.get(API_URL + "chat/getmessagereportdata", { headers: authAdminHeader() });
  };
const removeReport = (id) => {
    return axios.delete(API_URL + "chat/removemessagereport/" + id, { headers: authAdminHeader() });
}
const deleteReportedMessage = (id) => {
    return axios.post(API_URL + "chat/deletereportedmessage/" + id, {} , { headers: authAdminHeader() });
}
const blockMessageSender = (id) => {
    return axios.post(API_URL + "chat/blocksendermessage/" + id, {} , { headers: authAdminHeader() });
}

export default {
    getMessageReportList,removeReport,deleteReportedMessage,blockMessageSender
}