const MONOLITH_PATH = process.env.REACT_APP_MONOLITH_PATH;

const BILLING_PATH = process.env.REACT_APP_BILLING_PATH;

export const ApiRoutes = {
  // MONOLITH ENDPOINT ROUTES
  eventTicket: {
    getEventTicketForFrontend: {
      url: "event/ticket-list/:event_id",
      method: "GET",
    },
    purchaseEventTicket: {
      url: "event/ticket/ticket-purchase",
      method: "POST",
    },
    purchaseEventTicketDetailsByEventId: {
      url: "event/ticket-book-by-user/:eventId",
      method: "GET",
    },
    cancelOrderTicketing: {
      url: "/event/ticket/ticket-cancel/:userTicketId",
      method: "PATCH",
    },
  },
  mainTicket: {
    getTicketByeventid: {
      url: "event/ticket/get-ticket-byeventid/:event_id",
      method: "GET",
    },
    addEventTicket: {
      url: "event/ticket/add-event-ticket",
      method: "POST",
    },
    deleteMainTicket: {
      url: "event/ticket/delete-event-ticket/:id",
      method: "DELETE",
    },
    getMainTicketById: {
      url: "event/ticket/get-ticket-byticketid/:ticketId",
      method: "GET",
    },
    editMainTicket: {
      url: "event/ticket/edit-event-ticket/:id",
      method: "PATCH",
    },
    mainTicketSuggestionList: {
      url: "event/ticket/search-suggestion-list/:id",
      method: "GET",
    },
    reorderMainTicket: {
      url: "event/reorder-ticket/:id",
      method: "POST",
    },
    getSoldTicektPerUser: {
      url: "/event/ticket-purchase-user-info/:ticketId",
      method: "GET",
    },
  },
  guestTicket: {
    getGuestTicketByEventId: {
      url: "event/get-event-guest-ticket/:event_id",
      method: "GET",
    },
    addGuestTicket: {
      url: "event/add-event-guest-ticket",
      method: "POST",
    },
    deleteGuestTicket: {
      url: "event/delete-event-guest-ticket/:id",
      method: "DELETE",
    },
    getGuestTicketById: {
      url: "event/guest-ticket-byId/:ticket_id",
      method: "GET",
    },
    editGuestTicket: {
      url: "event/edit-event-guest-ticket/:id",
      method: "PATCH",
    },
    guestTicketSuggestionList: {
      url: "event/guest-ticket/search-suggestion-list/:id",
      method: "GET",
    },
  },
  addons: {
    getAddonsByEventId: {
      url: "event/addon/get-addon-by-event-id/:event_id",
      method: "GET",
    },
    createAddons: {
      url: "event/addon/add-event-addon",
      method: "POST",
    },
    deleteAddons: {
      url: "event/addon/delete-event-addon/:id",
      method: "DELETE",
    },
    getAddonsByAddonId: {
      url: "event/addon/get-event-addon-by-id/:ticket_id",
      method: "GET",
    },
    editAddons: {
      url: "event/addon/edit-event-addon/:id",
      method: "PATCH",
    },
    addonsSuggestionList: {
      url: "event/addon/get-all-addon-by-event-id/:id",
      method: "GET",
    },
  },
  session: {
    cloneSession: {
      url: "event/clone-event-session",
      method: "POST",
    },
  },
  activity: {
    cloneActivity: {
      url: "event/clone-event-activity",
      method: "POST",
    },
  },
  eventListing: {
    upComingEventList: {
      url: "event/getUpCommingEvent",
      method: "GET",
    },
    pastEventList: {
      url: "event/getPastEvent",
      method: "GET",
    },
    myEventList: {
      url: "event/getEventList",
      method: "GET",
    },
    upComingFilterEventList: {
      url: "event/EventTypelistData",
      method: "GET",
    },
    pastEventFilterList: {
      url: "event/getPastEventYearList",
      method: "GET",
    },
    eventSuggestionHistory: {
      url: "topEventSearch",
      method: "GET",
    },
    eventSuggestionList: {
      url: "allEventList",
      method: "GET",
    },
    addEventSearch: {
      url: "addEventSearch",
      method: "POST",
    },
    eventSearchList: {
      url: "event/eventSearchList",
      method: "GET",
    },
    eventCategoriesList: {
      url: "event/categoriesForUser",
      method: "GET",
    },
    eventSuggestionList: {
      url: "allEventList",
      method: "GET",
    },
    eventSuggestionHistory: {
      url: "topEventSearch",
      method: "GET",
    },
    eventSearchList: {
      url: "event/eventSearchList",
      method: "GET",
    },
    addEventSearch: {
      url: "addEventSearch",
      method: "POST",
    },
    removeHistorySearch: {
      url: "removeEventSearch/:id",
      method: "POST",
    },
    resultCount: {
      url: "event/getFilterEventCountForUser",
      method: "GET",
    },
    getAllCities: {
      url: "event/get-all-city-for-user",
      method: "GET",
    },
  },
  eventDetail: {
    upcomingEventDetailApi: {
      url: "event/getUpCommingEvent/:id",
      method: "GET",
    },
    pastEventDetailApi: {
      url: "event/getPastEvent/:eventId",
      method: "GET",
    },
    myEventDetailApi: {
      url: "event/getEvent/:eventId",
      method: "GET",
    },
    checkEventAccess: {
      url: "event/checkEventAccess",
      method: "POST",
    },
  },
  partners: {
    deleteCategory: {
      url: "partner/category/delete",
      method: "PATCH",
    },
    reAssignParnterCategory: {
      url: "partner/reassignCategories/:id",
      method: "GET",
    },
    partnerUserCount: {
      url: "partner/userCount",
      method: "POST",
    },
  },
  mainEvent: {
    deleteCategory: {
      url: "event/category/delete",
      method: "PATCH",
    },
    reAssignCategory: {
      url: "event/reassignCategories/:id",
      method: "GET",
    },
  },
  video: {
    deleteVideoCategory: {
      url: "delete/categories",
      method: "PATCH",
    },
    reAssignVideoCategory: {
      url: "AS/reassignCategories/:id",
      method: "GET",
    },
    videoUserCount: {
      url: "video/userCount",
      method: "POST",
    },
  },
  event: {
    mainEventUserCount: {
      url: "event/userCount",
      method: "POST",
    },
    getAllCities: {
      url: "event/get-all-city",
      method: "GET",
    },
    addCheckIn: {
      url: "event/addCheckIn/:id",
      method: "POST",
    },
    checkInList: {
      url: "event/checkIn/getAttendeesCheckInListByEventId/:event_id",
      method: "GET",
    },
    checkInStatus: {
      url: "event/checkIn/send-status-checkin",
      method: "POST",
    },
    checkInDetail: {
      url: "event/checkIn/getAttendeesCheckInDetail",
      method: "GET",
    },
    checkInSuggestionList: {
      url: "event/checkIn/getAttendeesSuggestionList/:id",
      method: "GET",
    },
    checkInActivityList: {
      url: "event/getAllActivitysIncheckIn/:id",
      method: "GET",
    },
    checkInTicketList: {
      url: "event/getAllTicketsIncheckIn/:id",
      method: "GET",
    },
    getEventAttendeeById: {
      url: `event/getAttendee/:attendee_id`,
      method: "GET",
    },
    searchEventParticipantTypes: {
      url: `event/dynamicParticipantTypes/all/:event_id`,
      method: "GET",
    },
    editEventAttendees: {
      url: `event/editEventAttendees/:attendee_id`,
      method: "PATCH",
    },
    addImportedQuestions: {
      url: "event/import-event-question/:id",
      method: "POST",
    },
    getEventById: {
      url: `event/getEventById/:event_id`,
      method: "GET",
    },
    getAllAttendeeListForRules: {
      url: "user/getAllAttendeeListForRules",
      method: "GET",
    },
  },
  news: {
    newsUserCount: {
      url: "news/userCount",
      method: "POST",
    },
    postsUserCount: {
      url: "news/post/userCount",
      method: "POST",
    },
  },
  tags: {
    getTags: {
      url: `tagList`,
      method: "GET",
    },
  },
  ruleUserCount: {
    userCount: {
      url: "restrictionAccess/userCount",
      method: "POST",
    },
  },
  adminModules: {
    getModules: {
      url: `${MONOLITH_PATH}/v1/admin-modules`,
      method: "GET",
    },
  },
  authentication: {
    ssoLogin: {
      url: `${MONOLITH_PATH}/v1/users/sso-login`,
      method: "POST",
    },
    login: {
      url: `${MONOLITH_PATH}/v1/users/login`,
      method: "POST",
    },
    signup: {
      url: `${MONOLITH_PATH}/v1/users/signup`,
      method: "POST",
    },
    validateLink: {
      url: `${MONOLITH_PATH}/v1/users/validate-link`,
      method: "GET",
    },
    activateAccount: {
      url: `${MONOLITH_PATH}/v1/users/activate-account`,
      method: "POST",
    },
    userEdges: {
      url: `${MONOLITH_PATH}/v1/user-edges`,
      method: "GET",
    },
  },
  applicationForms: {
    getApplicationForms: {
      url: `${MONOLITH_PATH}/v1/application-forms`,
      method: "GET",
    },
    getApplicationForm: {
      url: `${MONOLITH_PATH}/v1/application-forms/:application_form_id`,
      method: "GET",
    },
    addApplicationForm: {
      url: `${MONOLITH_PATH}/v1/application-forms`,
      method: "POST",
    },
    editApplicationForm: {
      url: `${MONOLITH_PATH}/v1/application-forms/:application_form_id`,
      method: "PATCH",
    },
    removeApplicationForm: {
      url: `${MONOLITH_PATH}/v1/application-forms/:application_form_id`,
      method: "DELETE",
    },
  },
  applicationFormFields: {
    getApplicationFormFields: {
      url: `${MONOLITH_PATH}/v1/application-form-fields`,
      method: "GET",
    },
    getApplicationFormField: {
      url: `${MONOLITH_PATH}/v1/application-form-fields/:application_form_field_id`,
      method: "GET",
    },
    addApplicationFormField: {
      url: `${MONOLITH_PATH}/v1/application-form-fields`,
      method: "POST",
    },
    editApplicationFormField: {
      url: `${MONOLITH_PATH}/v1/application-form-fields/:application_form_field_id`,
      method: "PATCH",
    },
    removeApplicationFormField: {
      url: `${MONOLITH_PATH}/v1/application-form-fields/:application_form_field_id`,
      method: "DELETE",
    },
  },
  roles: {
    getRoles: {
      url: `${MONOLITH_PATH}/v1/roles`,
      method: "GET",
    },
    getRole: {
      url: `${MONOLITH_PATH}/v1/roles/:role_id`,
      method: "GET",
    },
    addRole: {
      url: `${MONOLITH_PATH}/v1/roles`,
      method: "POST",
    },
    editRole: {
      url: `${MONOLITH_PATH}/v1/roles/:role_id`,
      method: "PATCH",
    },
    removeRole: {
      url: `${MONOLITH_PATH}/v1/roles/:role_id`,
      method: "DELETE",
    },
  },
  platformOwners: {
    getPlatformOwners: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners`,
      method: "GET",
    },
    getPlatformOwner: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners/:platform_owner_id`,
      method: "GET",
    },
    addPlatformOwner: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners`,
      method: "POST",
    },
    editPlatformOwner: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners/:platform_owner_id`,
      method: "PATCH",
    },
    removePlatformOwner: {
      url: `${MONOLITH_PATH}/v1/users/platform-owners/:platform_owner_id`,
      method: "DELETE",
    },
  },
  getPlatformUsers: {
    url: `${MONOLITH_PATH}/v1/users/platform-users`,
    method: "GET",
  },
  communityOwners: {
    getPlatformOwners: {
      url: `${MONOLITH_PATH}/v1/users/community-owners`,
      method: "GET",
    },
    getCommunityOwner: {
      url: `${MONOLITH_PATH}/v1/users/community-owners/:community_owner_id`,
      method: "GET",
    },
    addCommunityOwner: {
      url: `${MONOLITH_PATH}/v1/users/community-owners`,
      method: "POST",
    },
    editCommunityOwner: {
      url: `${MONOLITH_PATH}/v1/users/community-owners/:community_owner_id`,
      method: "PATCH",
    },
    removeCommunityOwner: {
      url: `${MONOLITH_PATH}/v1/users/community-owners/:community_owner_id`,
      method: "DELETE",
    },
  },
  getCommunityMembers: {
    url: `${MONOLITH_PATH}/v1/users/community-members`,
    method: "GET",
  },
  communities: {
    getCommunities: {
      url: `${MONOLITH_PATH}/v1/communities`,
      method: "GET",
    },
    getCommunity: {
      url: `${MONOLITH_PATH}/v1/communities/:community_id`,
      method: "GET",
    },
    createCommunity: {
      url: `${MONOLITH_PATH}/v1/communities`,
      method: "POST",
    },
    editCommunity: {
      url: `${MONOLITH_PATH}/v1/communities/:community_id`,
      method: "PATCH",
    },
    removeCommunity: {
      url: `${MONOLITH_PATH}/v1/communities/:community_id`,
      method: "DELETE",
    },
    generateSharableLink: {
      url: `${MONOLITH_PATH}/v1/communities/sharable-link`,
      method: "POST",
    },
    removeSharableLink: {
      url: `${MONOLITH_PATH}/v1/communities/sharable-link/:sharable_link_id`,
      method: "DELETE",
    },
    getCommunityByNickname: {
      url: `${MONOLITH_PATH}/v1/communities/nickname/:nickname`,
      method: "GET",
    },
    validateSharableLink: {
      url: `${MONOLITH_PATH}/v1/communities/sharable-link/validate`,
      method: "GET",
    },
    joinCommunity: {
      url: `${MONOLITH_PATH}/v1/communities/join`,
      method: "POST",
    },
  },
  platform: {
    getPlatform: {
      url: `${MONOLITH_PATH}/v1/platform`,
      method: "GET",
    },
    editPlatform: {
      url: `${MONOLITH_PATH}/v1/platform`,
      method: "PATCH",
    },
  },
  subscriptionSubmissions: {
    subscriptionSubmissions: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions`,
      method: "POST",
    },
    subscriptionSubmissionsStatus: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions/status`,
      method: "GET",
    },
    getSubscriptionSubmissions: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions`,
      method: "GET",
    },
    getSubscriptionSubmission: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions/:subscription_submission_id`,
      method: "GET",
    },
    editSubscriptionSubmission: {
      url: `${MONOLITH_PATH}/v1/subscription-submissions/:subscription_submission_id`,
      method: "PATCH",
    },
  },
  edgeLogin: {
    edgeLogin: {
      url: `${MONOLITH_PATH}/v1/users/edge-login`,
      method: "POST",
    },
  },
  // BILLING ENDPOINT ROUTES
  modules: {
    getModules: {
      url: `${BILLING_PATH}/api/v1/modules`,
      method: "GET",
    },
    getModule: {
      url: `${BILLING_PATH}/api/v1/modules/:module_id`,
      method: "GET",
    },
    addModule: {
      url: `${BILLING_PATH}/api/v1/modules`,
      method: "POST",
    },
    editModule: {
      url: `${BILLING_PATH}/api/v1/modules/:module_id`,
      method: "PATCH",
    },
    removeModule: {
      url: `${BILLING_PATH}/api/v1/modules/:module_id`,
      method: "DELETE",
    },
  },
  tiers: {
    getTiers: {
      url: `${BILLING_PATH}/api/v1/tiers`,
      method: "GET",
    },
    getTier: {
      url: `${BILLING_PATH}/api/v1/tiers/:tier_id`,
      method: "GET",
    },
    addTier: {
      url: `${BILLING_PATH}/api/v1/tiers`,
      method: "POST",
    },
    editTier: {
      url: `${BILLING_PATH}/api/v1/tiers/:tier_id`,
      method: "PATCH",
    },
    removeTier: {
      url: `${BILLING_PATH}/api/v1/tiers/:tier_id`,
      method: "DELETE",
    },
    getPopularTiers: {
      url: `${BILLING_PATH}/api/v1/tiers/popular`,
      method: "GET",
    },
  },
  tierBenefits: {
    getTierBenefits: {
      url: `${BILLING_PATH}/api/v1/tier-benefits`,
      method: "GET",
    },
    getTierBenefit: {
      url: `${BILLING_PATH}/api/v1/tier-benefits/:tier_benefit_id`,
      method: "GET",
    },
    addTierBenefit: {
      url: `${BILLING_PATH}/api/v1/tier-benefits`,
      method: "POST",
    },
    editTierBenefit: {
      url: `${BILLING_PATH}/api/v1/tier-benefits/:tier_benefit_id`,
      method: "PATCH",
    },
    removeTierBenefit: {
      url: `${BILLING_PATH}/api/v1/tier-benefits/:tier_benefit_id`,
      method: "DELETE",
    },
  },
  stripe: {
    config: {
      url: `${BILLING_PATH}/api/v1/stripe/config`,
      method: "GET",
    },
    webhook: {
      url: `${BILLING_PATH}/api/v1/stripe/webhook`,
      method: "POST",
    },
    createCheckoutSession: {
      url: `${BILLING_PATH}/api/v1/stripe/create-checkout-session`,
      method: "POST",
    },
    createAccountLink: {
      url: `${BILLING_PATH}/api/v1/stripe/create-account-link`,
      method: "POST",
    },
    fetchOnboardingStatus: {
      url: `${BILLING_PATH}/api/v1/stripe/fetch-onboarding-status`,
      method: "GET",
    },
    getSessionStatus: {
      url: `${BILLING_PATH}/api/v1/stripe/session-status`,
      method: "GET",
    },
    getSubscriptionChangeConfirmation: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-change-confirmation`,
      method: "GET",
    },
    subscriptionChange: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-change`,
      method: "POST",
    },
    subscriptionCancel: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-opt-out`,
      method: "POST",
    },
    subscriptionRenew: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-renew`,
      method: "POST",
    },
    fetchStripeAccount: {
      url: `${BILLING_PATH}/api/v1/stripe/fetch-account`,
      method: "GET",
    },
    getSubscriptionCancelConfirmation: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-cancel-confirmation`,
      method: "GET",
    },
    getBalance: {
      url: `${BILLING_PATH}/api/v1/stripe/balance`,
      method: "GET",
    },
    getMemberBalance: {
      url: `${BILLING_PATH}/api/v1/stripe/member-balance`,
      method: "GET",
    },
    subscriptionCancelByAdmin: {
      url: `${BILLING_PATH}/api/v1/stripe/subscription-cancel`,
      method: "POST",
    },
    getRevenuePayments: {
      url: `${BILLING_PATH}/api/v1/stripe/revenue`,
      method: "GET",
    },
    getGrossVolumePayments: {
      url: `${BILLING_PATH}/api/v1/stripe/gross-volume`,
      method: "GET",
    },
    getNetVolumePayments: {
      url: `${BILLING_PATH}/api/v1/stripe/net-volume`,
      method: "GET",
    },
    getSalesPayments: {
      url: `${BILLING_PATH}/api/v1/stripe/sales`,
      method: "GET",
    },
    createPayout: {
      url: `${BILLING_PATH}/api/v1/stripe/payouts`,
      method: "POST",
    },
    getPayouts: {
      url: `${BILLING_PATH}/api/v1/stripe/payouts`,
      method: "GET",
    },
  },
  subscriptions: {
    getSubscriptions: {
      url: `${BILLING_PATH}/api/v1/subscriptions`,
      method: "GET",
    },
    getSubscription: {
      url: `${BILLING_PATH}/api/v1/subscriptions/:subscription_id`,
      method: "GET",
    },
    getOwnerSubscription: {
      url: `${BILLING_PATH}/api/v1/subscriptions/owner/:owner_id`,
      method: "GET",
    },
    getSubscriptionHistory: {
      url: `${BILLING_PATH}/api/v1/subscriptions/history`,
      method: "GET",
    },
  },
  reminder: {
    getReminder: {
      url: `${BILLING_PATH}/api/v1/subscriptions-renewal-reminders/:subscription_renewal_reminder_config_id`,
      method: "GET",
    },
    editReminder: {
      url: `${BILLING_PATH}/api/v1/subscriptions-renewal-reminders/:subscription_renewal_reminder_config_id`,
      method: "PATCH",
    },
  },
  videos: {
    getVideos: {
      url: `get-video-by-filter-sort`,
      method: "GET",
    },
    videoCategories: {
      url: `categories`,
      method: "GET",
    },
    getSpeakersByVideo: {
      url: `getSpeakersByVideo`,
      method: "GET",
    },
    videoSaved: {
      url: "video/saved/:video_id",
      method: "PUT",
    },
    getVideoByFilterSortCount: {
      url: "get-video-by-filter-sort-count",
      method: "GET",
    },
    removeVideoHistory: {
      url: "removeVideoHistory",
      method: "PATCH",
    },
  },
  users: {
    importCsvToMongo: {
      url: "user/importUserFromCSV",
      method: "POST",
    },
    getImportedMongoUsers: {
      url: "user/get-import-user-detail",
      method: "GET",
    },
    cancelledUserLists: {
      url: "user/getAllUserCancle",
      method: "GET"
    },
    cancelledUserSuggestionLists: {
      url: "user/getallCancelUsersSuggestionList",
      method: "GET"
    }
  }
};
