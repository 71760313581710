import { useEffect, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from "react-dropzone";
import Services from "../../Services/auth.service";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import Frame from "../../Images/Frame.svg";
import AlertComponent from "../../Hooks/AlertComponent";
// import Frame from "../../Images/Frame.svg";

const GroupInfo = ({ setcurrentDiv, selectedGid, grpdetail, setgrpdetail }) => {
  const formdata = new FormData();
  const topics = useSelector((state) => {
    var arr = [];
    for (var i = 0; i < state.ReduTopics.length; i++) {
      arr[i] = state.ReduTopics[i].topic;
    }
    return arr;
  });
  const [Inprocess, setInprocess] = useState(false);
  const [editgrp, seteditgrp] = useState({
    groupImage: [],
    groupCoverImage: [],
    groupTitle: "",
    groupInfo: "",
    groupType: "",
    groupVisibility: "",
    createGroupChat: "",
    topics: [],
  });
  const [edit, setedit] = useState(false);
  const [err, setErr] = useState({
    groupTitle: "",
    groupInfo: "",
    topics: "",
  });
  const [grpimg, setgrpimg] = useState([]);
  const [coverimg, setcoverimg] = useState([]);
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const dispatch = useDispatch()
  const modalinprocess = useSelector((state) => { return state.ReduModalInProcess })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })

  useEffect(() => {
  }, [selectedGid]);

  useEffect(()=>{
    handleClearAllStates()
  },[])
  const handleChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    console.log('dfdf')
    if (e.target.name === "groupTitle") {
      e.target.value.length === 0
        ? setErr({ ...err, [e.target.name]: "Enter group title!" })
        : setErr({ ...err, [e.target.name]: "" });
    }
    if (e.target.name === "groupInfo") {
      e.target.value.length === 0
        ? setErr({ ...err, [e.target.name]: "Enter group info!" })
        : e.target.value.length > 150
        ? setErr({
            ...err,
            [e.target.name]:
              "Group info can only contain 150 maximum character!",
          })
        : setErr({ ...err, [e.target.name]: "" });
    }
    seteditgrp({ ...editgrp, [e.target.name]: e.target.value });
  };
  const handleUpdate = () => {
    var valid = true;
    var obj = { ...err };
    if (grpimg.length > 0) formdata.append("groupImage", grpimg[0]);

    if (coverimg.length > 0) formdata.append("groupCoverImage", coverimg[0]);

    if (editgrp.groupTitle.length > 0)
      formdata.append("groupTitle", editgrp.groupTitle.trim());
    else obj = { ...obj, groupTitle: "Enter group title!" };
    if (editgrp.groupInfo.length > 0)
      formdata.append("groupInfo", editgrp.groupInfo);
    else obj = { ...obj, groupInfo: "Enter group info!" };
    if (editgrp.groupType.length > 0)
      formdata.append("groupType", editgrp.groupType);
    if (editgrp.groupVisibility.length > 0)
      formdata.append("groupVisibility", editgrp.groupVisibility);
    if (editgrp.createGroupChat.length > 0)
      formdata.append("createGroupChat", editgrp.createGroupChat);
    if (editgrp.topics.length > 0) {
      for (var i = 0; i < editgrp.topics.length; i++) {
        formdata.append("topics[]", editgrp.topics[i]);
      }
    } else {
      obj = { ...obj, topics: "Enter group topics!" };
    }
    setErr({ ...obj });
    Object.keys(obj).map(function (key) {
      if (obj[key].length > 0) valid = false;
    });
    if (valid) {
      setInprocess(true);
      var data = {
        groupid: selectedGid,
        data: formdata,
      };
      Services.updategroup(data)
        .then((res) => {
          if (res.data.status) {
            setInprocess(false);
            setshowalert(true);
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false);
              setalertmsg("");
            }, 3000);
            setgrpdetail(res.data.data);
            setedit(false);
          } else {
            setInprocess(false);
            setshowalert(true);
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false);
              setalertmsg("");
            }, 3000);
          }
          handleClearAllStates()
        })
        .catch((e) => {
          handleClearAllStates()
          console.log(e);
        });
    }
  };
  const updateImg = (file) => {
    formdata.append("groupImage", file[0]);
    var data = {
      groupid: selectedGid,
      data: formdata,
    };
    Services.updategroupimg(data)
      .then((res) => {
        if (res.data.status) {
          setshowalert(true);
          setalertmsg(res.data.message);
          setTimeout(() => {
            setshowalert(false);
            setalertmsg("");
          }, 3000);
          setgrpdetail({
            ...grpdetail,
            groupImage: res.data.data[0].profile_img,
          });
        } else {
          setshowalert(true);
          setalertmsg(res.data.message);
          setTimeout(() => {
            setshowalert(false);
            setalertmsg("");
          }, 3000);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDelete = () => {
    Services.deletegroup(selectedGid)
      .then((res) => {
        setshowalert(true);
        setalertmsg(res.data.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
        }, 3000);
        setcurrentDiv("lisiting");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const validtopic = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    var topic_lower = topics.map((t) => t.toLowerCase());

    /^[a-zA-Z0-9\_\-]*$/.test(e.target.value) ||
    topic_lower.includes(e.target.value.toLowerCase())
      ? setErr({ ...err, topic: "" })
      : setErr({
          ...err,
          topic: "Enter only alphabets, numbers or underscore!",
        });
  };

  const handletopic = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    var tpcs_lower = editgrp.topics.map((t) => t.toLowerCase());
    var topic_lower = topics.map((t) => t.toLowerCase());
    var hash_first = e.target.value.substr(0, 1);
    if (err.topics.length === 0) {
      if (hash_first === "#") {
        if (!tpcs_lower.includes(e.target.value.toLowerCase())) {
          var index = topic_lower.indexOf(e.target.value.toLowerCase());
          var val = topics[index];
          seteditgrp({ ...editgrp, topics: [...editgrp.topics, val] });
        }
      } else if (!tpcs_lower.includes("#" + e.target.value.toLowerCase())) {
        var index = topic_lower.indexOf("#" + e.target.value.toLowerCase());
        if (index >= 0) {
          var val = topics[index];
          seteditgrp({ ...editgrp, topics: [...editgrp.topics, val] });
        } else {
          seteditgrp({
            ...editgrp,
            topics: [...editgrp.topics, "#" + e.target.value],
          });
        }
      }
      e.target.value = "";
    }
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }

  return (
    <>
      {selectedGid.length > 0 ? (
        <>
          <div
            className="group-info-main-font"
            style={edit ? { display: "none" } : { display: "block" }}
          >
            <h5 className="gl-title-sub">Group info</h5>

            {showalert && <div className="alert alert-info">{alertmsg}</div>}

            <Dropzone
              onDrop={(acceptedFiles) => {
                dispatch({ type: "SETMODALINPROCESS" })
                dispatch({ type: "SETSTAYONPAGE", payload: null })
                var file = acceptedFiles.map((file) => file);
                updateImg(file);
              }}
              name="groupImages"
              multiple={false}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              maxSizeBytes="10485760"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="group-info-user ctsm-fram-img ">
                    <div className="group-info-user-img cstm-group-info-user-img">
                      {grpdetail.groupImage.length > 0 ? (
                        <img
                          src={
                            process.env.REACT_APP_AWS_IMG_VID_PATH +
                            grpdetail.groupImage
                          }
                        />
                      ) : (
                        <img src={Frame} />
                      )}
                    </div>
                    <div className="group-info-user-link">
                      <button>Edit Group</button>
                    </div>
                    <div className="group-info-user-dotted">...</div>
                  </div>
                </div>
              )}
            </Dropzone>

            <div className="group-info-detials">
              <div className="group-info-detials-title">Group name</div>
              <div className="group-info-detials-cont">
                {grpdetail.groupTitle}
              </div>
            </div>

            <div className="group-info-detials">
              <div className="group-info-detials-title">Group info</div>
              <div className="group-info-detials-cont">
                {grpdetail.groupInfo}
              </div>
            </div>

            <div className="group-info-detials">
              <div className="group-info-detials-title">Group type</div>
              <div className="group-info-detials-cont">
                {grpdetail.groupType}
              </div>
            </div>

            <div className="group-info-detials">
              <div className="group-info-detials-title">Group visibility</div>
              <div className="group-info-detials-cont">
                {grpdetail.groupVisibility}
              </div>
            </div>

            <div className="row ct-lbl-main ">
              <div className="col-sm-12 form-group">
                {grpdetail.topics !== undefined && grpdetail.topics.length > 0
                  ? grpdetail.topics.map((t) => {
                      return (
                        <span className="topic-label-in">
                          <b>{t}</b>
                        </span>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="group-info-detials">
              <div className="group-info-detials-title">Cover image</div>

              <div className="group-info-detials-cont cstm-group-info-detials-cont">
                {grpdetail.groupCoverImage.length > 0 ? (
                  <img
                    src={
                      process.env.REACT_APP_AWS_IMG_VID_PATH +
                      grpdetail.groupCoverImage
                    }
                  />
                ) : (
                  <img src={Frame} />
                )}
              </div>
            </div>
            <div className="gi-btn-save">
              <button
                type="button"
                className="btn"
                onClick={() => {
                  seteditgrp(grpdetail);
                  setedit(true);
                }}
              >
                Edit Group
              </button>{" "}
              &nbsp;
              <button
                type="button"
                className="btn2"
                data-toggle="modal"
                data-target="#Delete"
              >
                Delete Group
              </button>
            </div>
          </div>

          <div
            className="group-info-main-bcd"
            style={edit ? { display: "block" } : { display: "none" }}
          >
            <h5 className="gl-title-sub">Edit Group</h5>

            <Dropzone
              onDrop={(acceptedFiles) => {
                dispatch({ type: "SETMODALINPROCESS" })
                setgrpimg(acceptedFiles.map((file) => file));
              }}
              name="groupImages"
              multiple={false}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              maxSizeBytes="10485760"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="group-info-user ctsm-fram-img">
                    <div className="group-info-user-img cstm-group-info-user-img">
                      {grpdetail.groupImage.length > 0 ? (
                        <img
                          src={
                            process.env.REACT_APP_AWS_IMG_VID_PATH +
                            grpdetail.groupImage
                          }
                        />
                      ) : grpimg.length > 0 ? (
                        <img src={URL.createObjectURL(grpimg[0])} />
                      ) : (
                        <img src={Frame} />
                      )}
                    </div>
                    <div className="group-info-user-link">
                      <div className="cover-image-upload-ttl">Group Image</div>
                      <div className="cover-image-upload-size">
                        JPEG or PNG, No logger that 10MB
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>

            <div className="form-group">
              <div className="form-group-label">Group title*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  name="groupTitle"
                  value={editgrp.groupTitle}
                  onChange={handleChange}
                />
                <span className="error">{err.groupTitle}</span>
              </div>
            </div>

            <div className="form-group">
              <div className="form-group-label">Group info*</div>
              <div className="form-group-ct">
                <textarea
                  name="groupInfo"
                  value={editgrp.groupInfo}
                  onChange={handleChange}
                ></textarea>
                <span className="error">{err.groupInfo}</span>
              </div>
            </div>

            <div className="form-group">
              <div className="form-group-label">Group Type</div>
              <div className="form-group-ct">
                <select
                  name="groupType"
                  value={editgrp.groupType}
                  onChange={handleChange}
                >
                  <option>Public</option>
                  <option>Private</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="form-group-label">Group visibility</div>
              <div className="form-group-ct">
                {" "}
                <select
                  name="groupVisibility"
                  value={editgrp.groupVisibility}
                  onChange={handleChange}
                >
                  <option>Anyone</option>
                  <option>Admin only</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 form-group">
                <div className="form-group-label"> Group Topics</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    className=""
                    list="topicsgroupedit"
                    name="topics"
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        handletopic(e);
                      } else {
                        validtopic(e);
                      }
                    }}
                  />
                </div>
                <datalist
                  id="topicsgroupedit"
                  onSelect={(e) => {
                    console.log(e.target.value);
                  }}
                >
                  {topics.length > 0
                    ? topics.map((tpc) => {
                        return <option value={tpc} />;
                      })
                    : ""}
                </datalist>
                <span className="error cstm-error4">{err.topics}</span>
              </div>
            </div>
            {/* <div className="row ct-lbl-main">
              <div className="col-sm-12 form-group">
            <div className="form-group-label">Topics Group</div>
            <div className="form-group-ct">
            <input
              list="topicsgroupedit"
              name="topics"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handletopic(e);
                } else {
                  validtopic(e);
                }
              }}
            />
            </div>
            <datalist
              id="topicsgroupedit"
              onSelect={(e) => {
                console.log(e.target.value);
              }}
            >
              {topics.length > 0
                ? topics.map((tpc) => {
                    return <option value={tpc} />;
                  })
                : ""}
            </datalist>
            <span className="error">{err.topics}</span>
            </div>
            </div> */}
            <div className="row ct-lbl-main cstm-tg-tg">
              <div className="col-sm-12 form-group">
                {editgrp.topics.length > 0
                  ? editgrp.topics.map((t) => {
                      return (
                        <span className="topic-label-in">
                          <b>{t}</b>
                          <button
                            onClick={() => {
                              seteditgrp({
                                ...editgrp,
                                topics: editgrp.topics.filter((tp) => tp !== t),
                              });
                            }}
                          >
                            ×
                          </button>
                        </span>
                      );
                    })
                  : ""}
              </div>
              {/* <span className="error">{err.topics}</span> */}
            </div>

            <h5 className="gl-title-sub">Cover image</h5>

            <div className="grup-main-cover-img-sec">
              <Dropzone
                onDrop={(acceptedFiles) => {
                  dispatch({ type: "SETMODALINPROCESS" })
                  setcoverimg(acceptedFiles.map((file) => file));
                }}
                name="groupCoverImages"
                multiple={false}
                accept="image/png, image/gif, image/jpeg, image/jpg"
                maxSizeBytes="10485760"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />

                    <div className="cover-image-dpdn">
                      <div className="cover-image-dpdn-in-left">
                        <img src={Frame} />
                      </div>
                      <div>
                        <div className="cover-image-upload-ttl">
                          Upload cover image
                        </div>
                        <div className="cover-image-upload-size">
                          JPEG or PNG, No logger that 10MB
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>

              <div className="cover-image-dpdn cstm-group-cover-img">
                {grpdetail.groupCoverImage.length > 0 ? (
                  <img
                    src={
                      process.env.REACT_APP_AWS_IMG_VID_PATH +
                      grpdetail.groupCoverImage
                    }
                  />
                ) : (
                  <img src={Frame} />
                )}
              </div>
            </div>

            <div className="gi-btn-save">
              <button type="button" className="btn" onClick={handleUpdate}>
                {Inprocess ? "In Process..." : "Update"}
              </button>{" "}
              &nbsp;
              <button
                type="button"
                className="btn2"
                onClick={() => {
                  handleClearAllStates()
                  seteditgrp(grpdetail);
                  setedit(false);
                }}
              >
                Discard
              </button>
            </div>
          </div>

          {/* Modal Delete */}
          <div
            className="modal fade msg-popup"
            id="Delete"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
                <div className="msg-icon">
                  <img src={imgbigdelete} />
                </div>
                <h1>Are you sure you want to delete this group?</h1>
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default GroupInfo;
