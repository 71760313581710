import React from "react";

const IconNavGallery = () => {
  return (
    <div className="mds-icons">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          d="M5.235 17.444a.75.75 0 0 1-.029-1.062l1.532-1.617a2.16 2.16 0 0 1 3.043-.091l.96.974c.267.27.702.276.97.011.102-.12 2.284-2.77 2.284-2.77.847-1.028 2.41-1.177 3.438-.333.043.035.082.07 2.283 2.33a.751.751 0 1 1-1.076 1.049s-2.053-2.109-2.2-2.25c-.38-.312-.982-.206-1.283.16-2.328 2.824-2.357 2.853-2.395 2.89-.86.844-2.248.83-3.093-.032 0 0-.931-.947-.947-.966-.232-.214-.645-.2-.892.062l-1.533 1.617a.75.75 0 0 1-1.062.028Z"
        />
        <path
          fillRule="evenodd"
          d="M6.063 9.15a2.513 2.513 0 0 0 2.509 2.512 2.514 2.514 0 0 0 2.51-2.511 2.514 2.514 0 0 0-2.51-2.51 2.512 2.512 0 0 0-2.51 2.51Zm1.503 0a1.007 1.007 0 1 1 2.014.002 1.007 1.007 0 0 1-2.014-.001Z"
          clipRule="evenodd"
        />
        <path
          fillRule="evenodd"
          d="M16.33 22H7.661C4.275 22 2 19.623 2 16.086V7.914C2 4.377 4.275 2 7.662 2h8.667c3.392 0 5.672 2.377 5.672 5.914v8.172C22 19.623 19.72 22 16.329 22ZM7.661 3.504c-2.526 0-4.159 1.731-4.159 4.41v8.173c0 2.68 1.633 4.41 4.159 4.41h8.667c2.532 0 4.169-1.73 4.169-4.41V7.914c0-2.679-1.637-4.41-4.169-4.41H7.662Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavGallery;
