import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedAddonsApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    addonsByEventId: builder.query({
      query: ({ event_id, params }) => {
        return {
          url: ApiRoutes.addons.getAddonsByEventId.url.replace(
            ":event_id",
            event_id
          ),
          method: ApiRoutes.addons.getAddonsByEventId.method,
          params,
        };
      },
      providesTags: ["GETADDONS"],
      transformResponse: (response) => response,
    }),
    createAddons: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.addons.createAddons.url,
          method: ApiRoutes.addons.createAddons.method,
          body,
        };
      },
      invalidatesTags: ["GETADDONS", "GETADDONSSUGGESTIONS"],
      transformResponse: (response) => response,
    }),
    deleteAddons: builder.mutation({
      query: ({ id }) => {
        return {
          url: ApiRoutes.addons.deleteAddons.url.replace(":id", id),
          method: ApiRoutes.addons.deleteAddons.method,
        };
      },
      invalidatesTags: ["GETADDONS", "GETADDONSSUGGESTIONS"],
      transformResponse: (response) => response,
    }),
    getAddonsByAddonId: builder.query({
      query: ({ ticket_id }) => {
        return {
          url: ApiRoutes.addons.getAddonsByAddonId.url.replace(
            ":ticket_id",
            ticket_id
          ),
          method: ApiRoutes.addons.getAddonsByAddonId.method,
        };
      },
      providesTags: ["GETADDON"],
      transformResponse: (response) => response,
    }),
    editAddons: builder.mutation({
      query: ({ id, body }) => {
        return {
          url: ApiRoutes.addons.editAddons.url.replace(":id", id),
          method: ApiRoutes.addons.editAddons.method,
          body,
        };
      },
      invalidatesTags: ["GETADDONS", "GETADDON", "GETADDONSSUGGESTIONS"],
      transformResponse: (response) => response,
    }),
    addonsSuggestionList: builder.query({
      query: ({ id }) => {
        return {
          url: ApiRoutes.addons.addonsSuggestionList.url.replace(":id", id),
          method: ApiRoutes.addons.addonsSuggestionList.method,
        };
      },
      providesTags: ["GETADDONSSUGGESTIONS"],
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useAddonsByEventIdQuery,
  useCreateAddonsMutation,
  useAddonsSuggestionListQuery,
  useDeleteAddonsMutation,
  useEditAddonsMutation,
  useLazyGetAddonsByAddonIdQuery,
} = extendedAddonsApi;
