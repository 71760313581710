import socketIOClient from "socket.io-client";

const newsocket = socketIOClient(process.env.REACT_APP_CHAT_BASE_SOCKET_URL, {
  path: "/socket-new",
  transports: ["websocket"],
  query: { userid: localStorage.getItem("userid") },
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 5000,
  reconnectionDelayMax: 2000,
  timeout: 20000,
});
export default newsocket;
