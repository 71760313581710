import React, { useEffect, useRef, useState } from "react";
import authService from "../../../Services/auth.service";
import authServicePartner from "../../../Services/auth.service.partner";
import { Link, useNavigate, useParams } from "react-router-dom";
import visitLink from "../../../Images/visitlink.svg";
import arrowLeft from "../../../Images/ArrowLeft.svg";
import webLogo from "../../../Images/weblogo.png";
import ratingStar from "../../../Images/rating-star.svg";
import trendingPostsLink from "../../../Images/TrendingPostsLink.svg";
import logo from "../../../Images/logo_mds_vertical_light.png";
import sepBullet from "../../../Images/separator-bullet.svg";
import chatIcon from "../../../Images/icn_m_sidebar_chat.svg";
import checkMark from "../../../Images/check-smalls.svg";
import moment from "moment";
import { Rating } from "@mui/material";
import closePopup from "../../../Images/close-popup.svg";
import icnDiamond from "../../../Images/icn_s_diamond.svg";
import noImageAvailable from "../../../Images/logo-image.jpg";
import appLogo from "../../../Images/news-logo.svg";
import profileIcon from "../../../Images/Profile1.svg";
import phoneIcon from "../../../Images/icn_m_phone.svg";
import emailIcon from "../../../Images/icn_m_email.svg";
import globIcon from "../../../Images/globe.svg";
import StarIcon from "@mui/icons-material/Star";
import succesIcon from "../../../Images/notifications.svg";
import Loader2 from "../../../Images/loader2.gif";
import starPart from "../../../Images/star_part.svg";
import mdsLogo from "../../../Images/logo-image-any-only.jpg";
import PartnerOfferCard from "./PartnerOfferCard";
import PartnerPerkCard from "./PartnerPerkCard";
import ShareFunctionality from "../../ShareFunctionality";
import CensusDataModal from "./CensusDataModal";
import Chips from "../../Chips/Chips";
import HashTag from "../../Hashtag/HashTag";

const PartnerInfo = ({ reviewCount, setReview }) => {
  const isUser = localStorage.getItem("role") === "user";
  const { id } = useParams();
  const reviewRef = useRef(null);
  const videoRef = useRef(null);
  const detailRef = useRef(null);
  const [partnerDetailData, setPartnerDetailData] = useState({});
  const [partnerPost, setPartnerPost] = useState([]);
  const [partnerVideos, setPartnerVideos] = useState([]);
  const [partnerReviews, setPartnerReviews] = useState([]);
  const [relatedPartner, setRelatedPartner] = useState({});
  const [showMoreVideos, setShowMoreVideos] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [showMoreReview, setShowMoreReview] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [getOfferDetails, setGetOfferDetails] = useState({});
  const [getOffer, setGetOffer] = useState(false);
  const [loader, setLoader] = useState(true);
  const [successModal, setSuccessModal] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const navigate = useNavigate();
  const [getOfferLoader, setGetOfferLoader] = useState(false);
  const [reviews, setReviews] = useState({
    rating: 0,
    feedBack: "",
    isAnonymous: false,
  });
  const [error, setError] = useState({
    rating: "",
    feedBack: "",
  });
  const [access, setAccess] = useState(false);
  const [access1, setAccess1] = useState(false);
  const [access2, setAccess2] = useState(false);

  // on first render of page this useEffect call
  useEffect(() => {
    if (localStorage.getItem("count") > 364) {
      setAccess2(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        console.log("element", element);

        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 1000);
  }, [location]);

  useEffect(() => {
    window.scrollTo({
      top: -50,
      left: -50,
      behavior: "smooth",
    });
    setLoader(true);
    setGetOfferLoader(true);
    getPartnerDetailApi();
  }, [id]);
  //partner details api function
  async function getPartnerDetailApi() {
    try {
      const [
        partnerDetailRes,
        partnerDetailPostRes,
        reviewDetailRes,
        partnerDetailVideosRes,
      ] = await Promise.all([
        isUser
          ? authService.getPartnerDetail(id)
          : authServicePartner.getPartnerDetailForPreview(id),
        isUser
          ? authService.getPartnerDetailPosts(id)
          : authServicePartner.getPartnerDetailPostsForPreview(id),
        isUser
          ? authService.getPartnerDetailReview(id)
          : authServicePartner.getPartnerDetailReviewForPreview(id),
        isUser
          ? authService.getPartnerDetailVideos(id)
          : authServicePartner.getPartnerDetailVideosForPreview(id),
      ]);
      if (partnerDetailVideosRes.data.status) {
        setPartnerVideos(
          partnerDetailVideosRes.data.partnerDetail.partnerVideos
        );
      } else {
        setLoader(false);
      }
      if (partnerDetailRes.data.status) {
        setPartnerDetailData(partnerDetailRes.data.partnerDetail);
      } else {
        setLoader(false);
      }
      if (partnerDetailPostRes.data.status) {
        setPartnerPost(partnerDetailPostRes.data.partnerDetail.partnerPosts);
      } else {
        setLoader(false);
      }
      if (reviewDetailRes.data.status) {
        setPartnerReviews(reviewDetailRes.data.data);
      } else {
        setLoader(false);
      }
      setLoader(false);
      const query = `${id}?category=${partnerDetailRes.data.partnerDetail.partnerCategory[0]._id}&type=${reviewDetailRes.data.data.MDSType}`;
      const getRelatedPartnerRes = isUser
        ? await authService.getPartnersInOtherCategories(query)
        : await authServicePartner.getPartnersInOtherCategoriesForPreview(
            query
          );
      if (getRelatedPartnerRes.data.status) {
        setRelatedPartner(getRelatedPartnerRes.data.partnerListInOther);
      } else {
        setLoader(false);
      }

      if (isUser && window.location.pathname.split("/")[1] === "partners") {
        await authService.updateUserViewCountOfPartner(id);
      }

      setGetOfferLoader(true);
      const getOfferDetailsCountRes = isUser
        ? await authService.getOfferDetailsCount(id)
        : await authServicePartner.getOfferDetailsCountForPreview(id);
      if (getOfferDetailsCountRes.data.status) {
        setGetOfferLoader(false);
        setGetOfferDetails(getOfferDetailsCountRes.data.data);
      } else {
        setGetOfferLoader(false);
      }
    } catch (error) {
      setLoader(false);
      setGetOfferLoader(false);
      return false;
    }
  }

  // handle restriction popup
  const handleRestriction = () => {
    if (reviewCount < 3) {
      setReview(true);
    }
  };

  //handle get offer detail and api call
  async function handleGetOfferDetails() {
    document.body.classList.add("ai-backdrop");
    document.body.classList.add("modal-open");
    setGetOffer(true);
    if (isUser) {
      await authService.updateUserClaimCountOfPartner(id);
    }
  }

  //handle review change function
  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setReviews({ ...reviews, [name]: value });
    switch (name) {
      case "rating":
        value === 0
          ? setError({ ...error, rating: "Please rate to continue." })
          : setError({ ...error, rating: "" });
        break;
      case "feedBack":
        value === 0
          ? setError({ ...error, feedBack: "Share your review to continue." })
          : setError({ ...error, feedBack: "" });
        break;
      default:
        break;
    }
  };

  //validate for input fields
  const validate = () => {
    let isValid = false;
    let reviewError = {};
    if (reviews.rating === 0) {
      isValid = true;
      reviewError.rating = "Please rate to continue.";
    }
    if (reviews.feedBack.trim() === "") {
      isValid = true;
      reviewError.feedBack = "Share your review to continue.";
    } else if (reviews.feedBack.length < 50) {
      isValid = true;
      reviewError.feedBack = "Review should be more than 50 characters.";
    }
    setError(reviewError);
    return isValid;
  };

  //handle submit review function
  const handleSubmitReview = async (e) => {
    e.preventDefault();
    if (!validate(reviews)) {
      setInProcess(true);
      const bodyData = {
        star: reviews.rating,
        reviewNote: reviews.feedBack,
        partnerId: id,
        isAnonymous: reviews.isAnonymous,
      };
      const leaveReviewRes = await authService.addPartnerReview(bodyData);
      try {
        if (leaveReviewRes.data.status) {
          setInProcess(false);
          setIsReview(false);
          setSuccessModal(true);
          document.body.classList.add("modal-open");
          setReviews({
            rating: 0,
            feedBack: "",
            isAnonymous: false,
          });
          setError({
            rating: "",
            feedBack: "",
          });
          setAlertMessage(leaveReviewRes.data.message);
          setTimeout(() => {
            setAlertMessage("");
          }, 3000);
        } else {
          setInProcess(false);
          setAlertMessage(leaveReviewRes.data.message);
          setTimeout(() => {
            setAlertMessage("");
          }, 3000);
        }
      } catch (error) {
        setInProcess(false);
        return false;
      }
    }
  };
  console.log("Parnter reviews issue >>", partnerReviews);
  // handle close for review modal
  const handleCloseReview = (e) => {
    e.preventDefault();
    setIsReview(false);
    document.body.classList.remove("modal-open");
    setReviews({
      rating: 0,
      feedBack: "",
      isAnonymous: false,
    });
    setError({
      rating: "",
      feedBack: "",
    });
  };
  // on outside click of modal this event will call
  const closeModalOnOutSideClick = (e) => {
    if (
      reviewRef.current &&
      showMoreReview &&
      !reviewRef.current.contains(e.target)
    ) {
      setShowMoreReview(false);
      document.body.classList.remove("modal-open");
    }
    if (
      videoRef.current &&
      showMoreVideos &&
      !videoRef.current.contains(e.target)
    ) {
      setShowMoreVideos(false);
      document.body.classList.remove("modal-open");
    }
    if (
      detailRef.current &&
      getOffer &&
      !detailRef.current.contains(e.target)
    ) {
      setGetOffer(false);
      document.body.classList.remove("modal-open");
    }
  };
  // on esc key press  this event will call
  const closeModalOnEsc = (e) => {
    if (
      e.code === "Escape" &&
      reviewRef.current &&
      showMoreReview &&
      !reviewRef.current.contains(e.target)
    ) {
      setShowMoreReview(false);
      document.body.classList.remove("modal-open");
    }
    if (
      e.code === "Escape" &&
      videoRef.current &&
      showMoreVideos &&
      !videoRef.current.contains(e.target)
    ) {
      setShowMoreVideos(false);
      document.body.classList.remove("modal-open");
    }
    if (
      e.code === "Escape" &&
      detailRef.current &&
      getOffer &&
      !detailRef.current.contains(e.target)
    ) {
      setGetOffer(false);
      document.body.classList.remove("modal-open");
    }
  };
  document.addEventListener("mousedown", closeModalOnOutSideClick);
  document.addEventListener("keydown", closeModalOnEsc);

  return (
    <>
      {access2 ||
      (localStorage.getItem("remindmetomorrowcensus") &&
        moment(
          localStorage.getItem("remindmetomorrowcensus"),
          "YYYY-MM-DD"
        ).diff(moment().format("YYYY-MM-DD"), "days") === 0 &&
        localStorage.getItem("dontshowcensuspopup") === "false" &&
        (access || access1)) ||
      ((localStorage.getItem("remindmetomorrowcensus") === null ||
        localStorage.getItem("remindmetomorrowcensus") === undefined) &&
        (localStorage.getItem("dontshowcensuspopup") === "false" ||
          localStorage.getItem("dontshowcensuspopup") === null ||
          localStorage.getItem("dontshowcensuspopup") === undefined) &&
        (access || access1)) ? (
        <CensusDataModal
          access={access}
          setAccess={setAccess}
          access1={access1}
          setAccess1={setAccess1}
          access2={access2}
          setAccess2={setAccess2}
        />
      ) : (
        <>
          {loader ? (
            <div className="partners-details-main">
              <div className="partners-details-banner-loading skeleton-box"></div>
              <div className="partners-details-main-left-right">
                <div className="partners-details-post-card-loading skeleton-box"></div>
                <div className="partners-details-about-cont">
                  <div className="mds-about-partner-title-loading skeleton-box"></div>
                  <div className="mds-about-partner-category-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <div className="partners-details-pare-loading skeleton-box"></div>
                  <hr />
                  <div className="partners-details-helpful-list-loading skeleton-box"></div>
                  <div className="partners-details-helpful-list-loading skeleton-box"></div>
                  <hr />
                  <div className="partners-details-related-posts-loading">
                    <div className="partners-details-related-posts-in-loading skeleton-box"></div>
                    <div className="partners-details-related-posts-in-loading skeleton-box"></div>
                    <div className="partners-details-related-posts-in-loading skeleton-box"></div>
                    <div className="partners-details-related-posts-in-loading skeleton-box"></div>
                  </div>
                  <hr />
                  <div className="partners-details-related-videos-loading">
                    <div className="partners-details-related-videos-loading-in skeleton-box"></div>
                    <div className="partners-details-related-videos-loading-in skeleton-box"></div>
                    <div className="partners-details-related-videos-loading-in skeleton-box"></div>
                  </div>
                  <hr />
                  <div className="partners-details-reviews-list-man-loading">
                    <div className="partners-details-reviews-list-loading">
                      <div className="partners-details-reviews-list-head-loading skeleton-box"></div>
                      <div className="partners-details-reviews-list-cont-loading skeleton-box"></div>
                      <div className="partners-details-reviews-list-date-loading skeleton-box"></div>
                    </div>
                    <div className="partners-details-reviews-list-loading">
                      <div className="partners-details-reviews-list-head-loading skeleton-box"></div>
                      <div className="partners-details-reviews-list-cont-loading skeleton-box"></div>
                      <div className="partners-details-reviews-list-date-loading skeleton-box"></div>
                    </div>
                    <div className="partners-details-reviews-list-loading">
                      <div className="partners-details-reviews-list-head-loading skeleton-box"></div>
                      <div className="partners-details-reviews-list-cont-loading skeleton-box"></div>
                      <div className="partners-details-reviews-list-date-loading skeleton-box"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="partners-details-main">
                {
                  <div className="partners-details-banner">
                    <h1 className="partnes-banner-title">
                      {partnerReviews.companyName !== undefined &&
                        partnerReviews.companyName !== null &&
                        partnerReviews.companyName !== "" &&
                        partnerReviews.companyName}
                    </h1>

                    {isUser && (
                      <Link
                        to={`/partners/all-partners?type=${partnerReviews?.MDSType}&&cat=all&&search=&&sortby=newest&&badge=all`}
                        className="back-to-offers-link"
                      >
                        <img src={arrowLeft} />
                        Back to offers
                      </Link>
                    )}
                    <div className="partners-details-banner-img">
                      <img
                        src={
                          partnerDetailData.webBanner !== undefined &&
                          partnerDetailData.webBanner !== null &&
                          partnerDetailData.webBanner
                        }
                      />
                    </div>
                    {/* {partnerReviews.companyLogo !== undefined && <div className="partners-get-details"><Link onClick={handleGetOfferDetails} className="get-details-btn">Get details</Link></div>}
                {partnerReviews.MDSType !== undefined && partnerReviews.MDSType !== null && partnerReviews.MDSType !== "perk" &&
                  <> */}
                    {/* <div className="partners-details-post-card-preferred">{partnerDetailData.partnerType !== undefined && partnerDetailData.partnerType !== null && partnerDetailData.partnerType}</div> */}
                    {/* {partnerDetailData.partnerType !== undefined && partnerDetailData.partnerType !== null && window.location.hostname === "test.milliondollarsellers.com" && partnerDetailData.partnerType._id === "64f83db1acacc396564d4562" ? <div className="partners-details-post-card-exclusive">{partnerDetailData.partnerType.name}</div> : partnerDetailData.partnerType !== undefined && partnerDetailData.partnerType !== null && window.location.hostname === "dev.milliondollarsellers.com" && partnerDetailData.partnerType._id === "65083abc4e4b52568e71e55a" ? <div className="partners-details-post-card-exclusive">{partnerDetailData.partnerType.name}</div> : (partnerDetailData.partnerType !== undefined && partnerDetailData.partnerType !== null && <div className="partners-details-post-card-preferred">{partnerDetailData.partnerType.name}</div>)}
                  </>
                } */}
                  </div>
                }
                <div className="partners-details-main-left-right">
                  {!loader && (
                    <div className="partners-details-post-card">
                      <div className="partners-details-post-card-logo">
                        {partnerReviews.companyLogo !== undefined &&
                          partnerReviews.companyLogo !== null &&
                          partnerReviews.companyLogo !== "" && (
                            <img
                              src={
                                partnerReviews.companyLogo !== undefined &&
                                partnerReviews.companyLogo !== null &&
                                partnerReviews.companyLogo !== ""
                                  ? partnerReviews.companyLogo
                                  : webLogo
                              }
                            />
                          )}
                      </div>
                      <h3>
                        {partnerDetailData.offerValue !== undefined &&
                          partnerDetailData.offerValue !== null &&
                          partnerDetailData.offerValue !== "" &&
                          partnerDetailData.offerValue}
                      </h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            partnerDetailData.OfferDescription !== undefined &&
                            partnerDetailData.OfferDescription !== null &&
                            partnerDetailData.OfferDescription,
                        }}
                      />
                      <div className="partners-details-reviews">
                        {partnerReviews !== undefined &&
                        partnerReviews !== null &&
                        partnerReviews.partnerReviews !== undefined &&
                        partnerReviews.partnerReviews.length > 0 ? (
                          <>
                            <span className="partners-reviews-count">
                              <Rating
                                precision={0.5}
                                readOnly
                                value={
                                  partnerDetailData.rating !== undefined &&
                                  partnerDetailData.rating !== null &&
                                  partnerDetailData.rating
                                }
                              />
                              {partnerDetailData.rating !== undefined &&
                                partnerDetailData.rating !== null &&
                                partnerDetailData.rating}
                            </span>
                            <a href="#rating" className="partners-reviews-link">
                              {partnerReviews !== undefined &&
                                partnerReviews !== null &&
                                partnerReviews.partnerReviews !== undefined &&
                                partnerReviews.partnerReviews.length}{" "}
                              {`${
                                partnerReviews.partnerReviews.length > 1
                                  ? "reviews"
                                  : "review"
                              }`}
                            </a>
                          </>
                        ) : (
                          <>
                            <span className="partners-be">
                              Be the first to{" "}
                            </span>
                            <Link
                              className="partners-reviews-link"
                              onClick={() => {
                                isUser && setIsReview(true);
                                isUser &&
                                  document.body.classList.add("modal-open");
                              }}
                            >
                              leave a review
                            </Link>
                          </>
                        )}
                      </div>
                      {partnerReviews.companyLogo !== undefined && (
                        <div className="partners-get-details">
                          <Link
                            onClick={handleGetOfferDetails}
                            className="get-details-btn"
                          >
                            Get details
                          </Link>
                        </div>
                      )}
                      {partnerReviews.MDSType !== undefined &&
                        partnerReviews.MDSType !== null &&
                        partnerReviews.MDSType !== "perk" && (
                          <>
                            {/* <div className="partners-details-post-card-preferred">{partnerDetailData.partnerType !== undefined && partnerDetailData.partnerType !== null && partnerDetailData.partnerType}</div> */}
                            {partnerDetailData.partnerType !== undefined &&
                            partnerDetailData.partnerType !== null &&
                            window.location.hostname ===
                              "test.milliondollarsellers.com" &&
                            partnerDetailData.partnerType._id ===
                              "64f83db1acacc396564d4562" ? (
                              <div
                                style={{
                                  backgroundColor:
                                    partnerDetailData.partnerType.badgeColor,
                                }}
                                className="partners-details-post-card-exclusive"
                              >
                                <img src={icnDiamond} />
                                {partnerDetailData.partnerType.name}
                              </div>
                            ) : partnerDetailData.partnerType !== undefined &&
                              partnerDetailData.partnerType !== null &&
                              window.location.hostname ===
                                "dev.milliondollarsellers.com" &&
                              partnerDetailData.partnerType._id ===
                                "65083abc4e4b52568e71e55a" ? (
                              <div
                                style={{
                                  backgroundColor:
                                    partnerDetailData.partnerType.badgeColor,
                                }}
                                className="partners-details-post-card-exclusive"
                              >
                                <img src={icnDiamond} />
                                {partnerDetailData.partnerType.name}
                              </div>
                            ) : partnerDetailData.partnerType !== undefined &&
                              partnerDetailData.partnerType !== null &&
                              window.location.hostname ===
                                "app.milliondollarsellers.com" &&
                              partnerDetailData.partnerType._id ===
                                "6513f058feeb91913e2d303d" ? (
                              <div
                                style={{
                                  backgroundColor:
                                    partnerDetailData.partnerType.badgeColor,
                                }}
                                className="partners-details-post-card-exclusive"
                              >
                                <img src={icnDiamond} />
                                {partnerDetailData.partnerType.name}
                              </div>
                            ) : (
                              partnerDetailData.partnerType !== undefined &&
                              partnerDetailData.partnerType !== null &&
                              partnerDetailData.partnerType.name !==
                                "nobadge" && (
                                <div
                                  style={{
                                    backgroundColor:
                                      partnerDetailData.partnerType.badgeColor,
                                  }}
                                  className="partners-details-post-card-preferred"
                                >
                                  {partnerDetailData.partnerType.name}
                                </div>
                              )
                            )}
                          </>
                        )}
                    </div>
                  )}

                  <div className="partners-details-about-cont">
                    {
                      <>
                        <div className="partners-details-about-cont-title">
                          <div className="partners-details-about-cont-title-left">
                            <h2>About partner</h2>
                          </div>
                          <div className="partners-details-about-cont-title-right">
                            {partnerDetailData.contactInfo !== undefined &&
                              partnerDetailData.contactInfo.website !==
                                undefined &&
                              partnerDetailData.contactInfo.website !== null &&
                              partnerDetailData.contactInfo.website !== "" && (
                                <Link
                                  className="partners-visit-website"
                                  to={
                                    partnerDetailData.contactInfo !==
                                      undefined &&
                                    partnerDetailData.contactInfo.website !==
                                      null &&
                                    partnerDetailData.contactInfo.website !==
                                      "" &&
                                    partnerDetailData.contactInfo.website
                                  }
                                  target="_blank"
                                >
                                  Visit website
                                  <img src={visitLink} />
                                </Link>
                              )}
                            {/* {(process.env.REACT_APP_FRONTEND_ROOTURL ===
                                "https://dev.milliondollarsellers.com/" ||
                                process.env.REACT_APP_FRONTEND_ROOTURL ===
                                "https://test.milliondollarsellers.com/") && (
                                  <a>
                                    <ShareFunctionality
                                      url={
                                        "redirectto=" + "partner" + "&partner_id=" + id
                                      }
                                      page="partner"
                                    />
                                  </a>
                                )} */}
                          </div>
                        </div>

                        <div className="partners-details-category">
                          {partnerDetailData.partnerCategory !== undefined &&
                          partnerDetailData.partnerCategory.length > 0
                            ? partnerDetailData.partnerCategory.map((cat) => (
                                <>
                                  <Link
                                    to={`/partners/all-partners?type=${partnerReviews.MDSType}&&cat=${cat._id}&&search=&&sortby=all&&badge=all`}
                                  >
                                    {" "}
                                    <Chips label={cat.name} />
                                  </Link>
                                  {cat.subcategory !== undefined &&
                                  cat.subcategory.length > 0
                                    ? cat.subcategory.map((subcat) => (
                                        <Link
                                          to={`/partners/all-partners?type=${partnerReviews.MDSType}&&cat=${cat._id}&&subcat=${subcat._id}&&search=&&sortby=all&&badge=all`}
                                        >
                                          {" "}
                                          <Chips label={subcat.name} />
                                        </Link>
                                      ))
                                    : null}
                                </>
                              ))
                            : null}
                        </div>
                        <div
                          className="partners-details-pare"
                          dangerouslySetInnerHTML={{
                            __html: partnerDetailData.description,
                          }}
                        ></div>
                        <div className="partner-tags-hashtag">
                          {partnerDetailData.partnerTags !== undefined &&
                          partnerDetailData.partnerTags.length > 0
                            ? partnerDetailData.partnerTags.map((tags) => (
                                <Link
                                  to={`/partners/all-partners?type=${partnerReviews.MDSType}&&cat=all&&search=&&sortby=all&&badge=all&&tag=${tags._id}`}
                                >
                                  <HashTag tag={`#${tags.name}`} />
                                </Link>
                              ))
                            : null}
                        </div>
                      </>
                    }
                    {partnerDetailData.helpfulLinks !== undefined &&
                      partnerDetailData.helpfulLinks !== null &&
                      partnerDetailData.helpfulLinks.length > 0 && (
                        <>
                          <hr />
                          <h3 className="partners-details-h3">Helpful links</h3>
                          <ul className="partners-details-helpful-list">
                            {
                              <>
                                {partnerDetailData.helpfulLinks.map((links) => (
                                  <li
                                    onClick={() =>
                                      window.open(links.url, "_blank")
                                    }
                                  >
                                    <div className="partners-details-helpful-list-img">
                                      <img
                                        src={
                                          links.linkIcon !== undefined &&
                                          links.linkIcon !== null &&
                                          links.linkIcon.includes("https")
                                            ? links.linkIcon
                                            : links.linkIcon !== undefined &&
                                              links.linkIcon !== null
                                            ? process.env
                                                .REACT_APP_AWS_IMG_VID_PATH +
                                              links.linkIcon
                                            : appLogo
                                        }
                                      />
                                    </div>
                                    <div className="partners-details-helpful-list-cont">
                                      <h4>{links.title}</h4>
                                      <h6>{links.url}</h6>
                                    </div>
                                    <div className="partners-details-helpful-list-link">
                                      <Link>
                                        <img src={trendingPostsLink} />
                                      </Link>
                                    </div>
                                  </li>
                                ))}
                              </>
                            }
                          </ul>
                        </>
                      )}
                    {partnerPost !== undefined &&
                      partnerPost !== null &&
                      partnerPost.length > 0 && (
                        <>
                          <hr />
                          <h3 className="partners-details-h3">Related posts</h3>
                          <ul className="partners-details-related-posts">
                            {partnerPost.slice(0, 4).map((posts) => (
                              <li
                                onClick={() => window.open(posts.url, "_blank")}
                              >
                                <Link className="partners-related-posts-link">
                                  <img src={trendingPostsLink} />
                                </Link>
                                <h4>{posts.title}</h4>
                                <p className="related-posts-label">
                                  {posts.author !== undefined &&
                                    posts.author !== null &&
                                    posts.author.trim() !== "" &&
                                    posts.author}
                                </p>
                              </li>
                            ))}
                          </ul>
                          {partnerDetailData.urlToAllPosts !== undefined &&
                            partnerDetailData.urlToAllPosts !== null &&
                            partnerDetailData.urlToAllPosts !== "" && (
                              <Link
                                target="_blank"
                                to={partnerDetailData.urlToAllPosts}
                                className="partners-details-btn-main"
                              >
                                Show all posts
                                <img src={trendingPostsLink} />
                              </Link>
                            )}
                        </>
                      )}

                    {partnerVideos !== undefined &&
                      partnerVideos !== null &&
                      partnerVideos.length > 0 && (
                        <>
                          <hr />
                          <h3 className="partners-details-h3">
                            Related videos
                          </h3>
                          <ul className="partners-details-related-videos">
                            {partnerVideos.slice(0, 3).map((videos) => (
                              <li
                                onClick={() => {
                                  isUser && navigate(`/videos/${videos._id}`);
                                }}
                              >
                                <div className="partners-details-related-videos-img">
                                  <img
                                    src={
                                      videos.thumbnail !== undefined &&
                                      videos.thumbnail !== null &&
                                      videos.thumbnail !== ""
                                        ? process.env
                                            .REACT_APP_AWS_IMG_VID_PATH +
                                          videos.thumbnail
                                        : mdsLogo
                                    }
                                  />
                                </div>
                                <div className="partners-details-related-videos-cont">
                                  <h4>{videos.title}</h4>
                                  <h6>
                                    {(videos.videocategory !== undefined) &
                                      (videos.videocategory !== null) &&
                                      videos.videocategory.length > 0 &&
                                      videos.videocategory.map((videoCat) => (
                                        <span>
                                          {videoCat.name !== undefined &&
                                            videoCat.name !== null &&
                                            videoCat.name !== "" &&
                                            videoCat.name}
                                        </span>
                                      ))}
                                  </h6>
                                  <p className="related-videos-year-view">
                                    {moment(videos.createdAt).fromNow()}
                                    <img src={sepBullet} />
                                    {videos.views}{" "}
                                    {videos.views !== 1 ? "views" : "view"}
                                  </p>
                                </div>
                              </li>
                            ))}
                          </ul>
                          {partnerVideos.length > 3 && (
                            <Link
                              id="rating"
                              className="partners-details-btn-main"
                              onClick={() => {
                                setShowMoreVideos(true);
                                document.body.classList.add("modal-open");
                              }}
                            >
                              Show{" "}
                              {
                                partnerVideos.slice(3, partnerVideos.length)
                                  .length
                              }{" "}
                              more{" "}
                              {partnerVideos.slice(3, partnerVideos.length)
                                .length > 1
                                ? "videos"
                                : "video"}
                            </Link>
                          )}
                        </>
                      )}

                    <hr id="rating" />
                    {partnerReviews !== undefined &&
                    partnerReviews !== null &&
                    partnerReviews.partnerReviews !== undefined ? (
                      <h3 className="partners-details-h3 reviews-mrn">
                        Reviews (
                        {partnerReviews !== undefined &&
                          partnerReviews !== null &&
                          partnerReviews.partnerReviews !== undefined &&
                          partnerReviews.partnerReviews.length}
                        )
                      </h3>
                    ) : (
                      <h3 className="partners-details-h3  reviews-mrn">
                        No reviews yet
                      </h3>
                    )}
                    <p className="partners-details-add-you">
                      Add you rating and any additional feedback about your
                      experience with this partner.
                    </p>

                    <span
                      className="partners-details-btn-main"
                      onClick={() => {
                        isUser && setIsReview(true);
                        isUser && document.body.classList.add("modal-open");
                      }}
                    >
                      Leave a review <img src={chatIcon} />
                    </span>
                    {partnerReviews !== undefined &&
                      partnerReviews !== null &&
                      partnerReviews.partnerReviews !== undefined &&
                      partnerReviews.partnerReviews.length > 0 && (
                        <>
                          <ul className="partners-details-reviews-list">
                            {partnerReviews.partnerReviews
                              .slice(0, 3)
                              .map((reviews) => (
                                <li>
                                  <div className="partners-details-reviews-list-head">
                                    {reviews.isAnonymous ? (
                                      <span className="reviews-cont">A</span>
                                    ) : (
                                      <span className="reviews-list-img">
                                        {reviews.userId.profileImg &&
                                        reviews.userId.profileImg.length > 0 ? (
                                          <img
                                            src={reviews.userId.profileImg}
                                          />
                                        ) : (
                                          <>
                                            <div className="reviews-cont">
                                              {reviews.userId
                                                ? `${reviews.userId.first_name} ${reviews.userId.last_name}`.charAt(
                                                    0
                                                  )
                                                : ""}
                                            </div>
                                          </>
                                        )}
                                      </span>
                                    )}
                                    <h3>
                                      {reviews.isAnonymous
                                        ? reviews.anonymousName
                                        : `${reviews.userId.first_name} ${reviews.userId.last_name}`}
                                    </h3>
                                    <span className="reviews-list-rating">
                                      <Rating value={reviews.star} readOnly />
                                    </span>
                                  </div>
                                  <p className="reviews-list-p">
                                    {reviews.reviewNote}
                                  </p>
                                  <h6>
                                    {moment(reviews.createdAt).format(
                                      "MMMM DD, YYYY"
                                    )}
                                  </h6>
                                </li>
                              ))}
                          </ul>
                          {partnerReviews.partnerReviews.length > 3 && (
                            <Link
                              onClick={() => {
                                setShowMoreReview(true);
                                document.body.classList.add("modal-open");
                              }}
                              className="partners-details-btn-main"
                            >
                              Show{" "}
                              {
                                partnerReviews.partnerReviews.slice(
                                  3,
                                  partnerReviews.length
                                ).length
                              }{" "}
                              more reviews
                            </Link>
                          )}
                        </>
                      )}
                    {relatedPartner !== undefined &&
                      relatedPartner !== null &&
                      relatedPartner.length > 0 && (
                        <>
                          <hr />
                          <h3 className="partners-details-h3">
                            Other offers in the{" "}
                            {partnerDetailData.partnerCategory !== undefined &&
                              partnerDetailData.partnerCategory.length > 0 &&
                              partnerDetailData.partnerCategory[0].name}{" "}
                            category
                          </h3>
                          <div className="mds-other-offers-only">
                            {relatedPartner.map((relatedPart) =>
                              partnerReviews.MDSType !== "perk" ? (
                                <PartnerOfferCard
                                  onClick={handleRestriction}
                                  reviewCount={reviewCount}
                                  item={relatedPart}
                                />
                              ) : (
                                <PartnerPerkCard
                                  onClick={handleRestriction}
                                  reviewCount={reviewCount}
                                  item={relatedPart}
                                />
                              )
                            )}
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </>
          )}
          {/* show more video modal */}
          <div
            className={`${showMoreVideos ? "modal-fadein" : "modal-fadeout"}`}
          >
            <div className="desk-globle-model-box globle-model-box ">
              <div
                className="desk-globle-model-box-inner partners-details-popup-main"
                ref={videoRef}
              >
                <div className="partners-details-popup-main-head">
                  <h3 className="partners-details-popup-title">
                    Related videos
                  </h3>
                  <span
                    className="partners-details-popup-close"
                    onClick={() => {
                      setShowMoreVideos(false);
                      document.body.classList.remove("modal-open");
                    }}
                  >
                    <img src={closePopup} />
                  </span>
                </div>
                <div className="partners-details-popup-main-cont">
                  <ul className="partners-details-related-videos">
                    {partnerVideos !== undefined &&
                      partnerVideos.length > 0 &&
                      partnerVideos.map((videos) => (
                        <li
                          onClick={() => {
                            isUser && navigate(`/videos/${videos._id}`);
                            document.body.classList.remove("modal-open");
                          }}
                        >
                          <div className="partners-details-related-videos-img">
                            <img
                              src={
                                videos.thumbnail !== undefined &&
                                videos.thumbnail !== null &&
                                videos.thumbnail !== ""
                                  ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    videos.thumbnail
                                  : mdsLogo
                              }
                            />
                          </div>
                          <div className="partners-details-related-videos-cont">
                            <h4>{videos.title}</h4>
                            <h6>
                              {(videos.videocategory !== undefined) &
                                (videos.videocategory !== null) &&
                                videos.videocategory.length > 0 &&
                                videos.videocategory.map((videoCat) => (
                                  <span>
                                    {videoCat.name !== undefined &&
                                      videoCat.name !== null &&
                                      videoCat.name !== "" &&
                                      videoCat.name}
                                  </span>
                                ))}
                            </h6>
                            <p className="related-videos-year-view">
                              {moment(videos.createdAt).fromNow()}
                              <img src={sepBullet} />
                              {videos.views}{" "}
                              {videos.views !== 1 ? "views" : "view"}
                            </p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* leave a review modal */}
          <div className={`${isReview ? "modal-fadein" : "modal-fadeout"}`}>
            <div className="desk-globle-model-box globle-model-box ">
              <div className="desk-globle-model-box-inner partners-details-popup-main">
                <div className="partners-details-popup-main-head">
                  <h3 className="partners-details-popup-title">
                    Leave a review
                  </h3>
                  <span
                    className="partners-details-popup-close"
                    onClick={handleCloseReview}
                  >
                    <img src={closePopup} />
                  </span>
                </div>
                <div className="partners-details-popup-leave-a-review">
                  <h5>Rating</h5>
                  <div className="partners-details-popup-leave-star">
                    <Rating
                      value={reviews.rating}
                      name="rating"
                      onChange={handleReviewChange}
                      emptyIcon={
                        <StarIcon style={{ opacity: 1, color: "#84898F" }} />
                      }
                    />
                    {error.rating && (
                      <span className="error">{error.rating}</span>
                    )}
                  </div>
                  <div className="cstm-rating-feedback">
                    <h5>Anything else you want to share with the Community?</h5>
                    <textarea
                      className={`anything-else-you-textarea ${
                        error.feedBack && "leave-review-tex"
                      }`}
                      placeholder="Share some feedback about this offer!"
                      value={reviews.feedBack}
                      name="feedBack"
                      onChange={handleReviewChange}
                      maxlength="255"
                    />
                    <span className="characters-count">
                      Сharacters: {reviews.feedBack.length}/255
                    </span>
                    {error.feedBack && (
                      <span className="error">{error.feedBack}</span>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          name="isAnonymous"
                          id="chk-preregistration"
                          checked={reviews.isAnonymous}
                          value={reviews.isAnonymous}
                          onChange={(e) =>
                            setReviews({
                              ...reviews,
                              isAnonymous: e.target.checked,
                            })
                          }
                        />
                        <label for="chk-preregistration">
                          <span>
                            <img src={checkMark} />
                          </span>{" "}
                          Keep my review anonymous
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="partners-details-popup-main-footer">
                  <button className="btn-cancel" onClick={handleCloseReview}>
                    Cancel
                  </button>
                  {inProcess ? (
                    <div className="loader-button1-footer">
                      <button className="btn-review">Send review</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      disabled={inProcess}
                      className="btn-review"
                      onClick={handleSubmitReview}
                    >
                      Send review
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* show more review modal */}
          <div
            className={`${showMoreReview ? "modal-fadein" : "modal-fadeout"}`}
          >
            <div className="desk-globle-model-box globle-model-box ">
              <div
                className="desk-globle-model-box-inner partners-details-popup-main"
                ref={reviewRef}
              >
                <div className="partners-details-popup-main-head">
                  <h3 className="partners-details-popup-title">Reviews</h3>
                  <span
                    className="partners-details-popup-close"
                    onClick={() => {
                      setShowMoreReview(false);
                      document.body.classList.remove("modal-open");
                    }}
                  >
                    <img src={closePopup} />
                  </span>
                </div>
                <div className="partners-details-reviews-list-popup">
                  <ul className="partners-details-reviews-list">
                    {partnerReviews !== undefined &&
                      partnerReviews.partnerReviews !== undefined &&
                      partnerReviews.partnerReviews.length > 0 &&
                      partnerReviews.partnerReviews.map((reviews) => (
                        <li>
                          <div className="partners-details-reviews-list-head">
                            <span className="reviews-list-img">
                              {reviews.isAnonymous ? (
                                <span className="reviews-cont">A</span>
                              ) : (
                                <img src={reviews.userId.profileImg} />
                              )}
                            </span>
                            <h3>
                              {reviews.isAnonymous
                                ? reviews.anonymousName
                                : `${reviews.userId.first_name} ${reviews.userId.last_name}`}
                            </h3>
                            <span className="reviews-list-rating">
                              <Rating value={reviews.star} readOnly />
                            </span>
                          </div>
                          <p className="reviews-list-p">{reviews.reviewNote}</p>
                          <h6>
                            {moment(reviews.createdAt).format("MMMM DD, YYYY")}
                          </h6>
                        </li>
                      ))}
                  </ul>
                </div>

                <div className="partners-details-popup-main-footer one-btn-right">
                  <button
                    onClick={() => {
                      {
                        isUser && setIsReview(true);
                        isUser && setShowMoreReview(false);
                        document.body.classList.add("modal-open");
                      }
                    }}
                    className="btn-review"
                  >
                    Send review
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* get offer modal */}
          <div className={`${getOffer ? "modal-fadein" : "modal-fadeout"}`}>
            <div className="desk-globle-model-box globle-model-box offer-details-modal">
              <div
                className="desk-globle-model-box-inner partners-details-popup-main"
                ref={detailRef}
              >
                <div className="partners-details-popup-main-head">
                  <h3 className="partners-details-popup-title">
                    Offer details
                  </h3>
                  <span
                    className="partners-details-popup-close"
                    onClick={() => {
                      setGetOffer(false);
                      document.body.classList.remove("modal-open");
                    }}
                  >
                    <img src={closePopup} />
                  </span>
                </div>
                {getOfferLoader ? (
                  <>
                    <div className="partners-details-popup-offer-details-loading skeleton-box"></div>
                    <div className="partners-details-popup-offer-details-cont-title-loading skeleton-box"></div>
                    <div className="partners-details-popup-offer-details-cont-cont-loading skeleton-box"></div>
                    <div className="partners-details-popup-offer-details-cont-title-loading skeleton-box"></div>
                    <div className="partners-details-popup-offer-details-cont-cont-loading skeleton-box"></div>
                    <div className="partners-details-popup-offer-details-cont-title-loading skeleton-box"></div>
                    <div className="partners-details-popup-offer-details-cont-cont-loading skeleton-box"></div>
                  </>
                ) : (
                  <>
                    <div className="partners-details-popup-offer-details">
                      <h1 className="partnes-popup-offer-banner-title">
                        {getOfferDetails.companyName !== undefined &&
                          getOfferDetails.companyName !== null &&
                          getOfferDetails.companyName}
                      </h1>
                      <img
                        src={
                          getOfferDetails.webBanner !== undefined &&
                          getOfferDetails.webBanner !== null &&
                          getOfferDetails.webBanner !== ""
                            ? getOfferDetails.webBanner
                            : webLogo
                        }
                      />
                    </div>
                    <div className="partners-details-popup-offer-details-cont">
                      <h3>
                        {getOfferDetails.offerValue !== undefined &&
                          getOfferDetails.offerValue !== null &&
                          getOfferDetails.offerValue !== "" &&
                          getOfferDetails.offerValue}
                      </h3>
                      {getOfferDetails.OfferDescription !== undefined &&
                        getOfferDetails.OfferDescription !== null &&
                        getOfferDetails.OfferDescription !== "" &&
                        getOfferDetails.OfferDescription && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                getOfferDetails.OfferDescription !==
                                  undefined &&
                                getOfferDetails.OfferDescription !== null &&
                                getOfferDetails.OfferDescription !== "" &&
                                getOfferDetails.OfferDescription,
                            }}
                          />
                        )}
                      <hr className="line-pd-od" />
                      <h4>How to claim offer</h4>
                      {getOfferDetails.OfferInstructions !== undefined &&
                        getOfferDetails.OfferInstructions !== null &&
                        getOfferDetails.OfferInstructions !== "" &&
                        getOfferDetails.OfferInstructions && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                getOfferDetails.OfferInstructions !==
                                  undefined &&
                                getOfferDetails.OfferInstructions !== null &&
                                getOfferDetails.OfferInstructions !== "" &&
                                getOfferDetails.OfferInstructions,
                            }}
                          />
                        )}
                      <>
                        {getOfferDetails !== undefined &&
                          getOfferDetails.contactInfo !== undefined && (
                            <>
                              <hr className="line-pd-od" />
                              <h4>Contacts</h4>
                              <div className="partners-details-popup-offer-details-cont-info">
                                {getOfferDetails.contactInfo !== undefined &&
                                  getOfferDetails.contactInfo !== null &&
                                  getOfferDetails.contactInfo.contactName !==
                                    "" && (
                                    <p>
                                      <img src={profileIcon} />
                                      {getOfferDetails.contactInfo.contactName}
                                    </p>
                                  )}
                                {getOfferDetails.contactInfo !== undefined &&
                                  getOfferDetails.contactInfo !== null &&
                                  getOfferDetails.contactInfo.phoneNumber !==
                                    "" && (
                                    <p>
                                      <img src={phoneIcon} />
                                      {getOfferDetails.contactInfo !==
                                        undefined &&
                                        getOfferDetails.contactInfo !== null &&
                                        getOfferDetails.contactInfo.phoneNumber}
                                    </p>
                                  )}
                                {getOfferDetails.contactInfo !== undefined &&
                                  getOfferDetails.contactInfo !== null &&
                                  getOfferDetails.contactInfo.email !== "" && (
                                    <p>
                                      <img src={emailIcon} />
                                      <a
                                        href={`mailto:${getOfferDetails.contactInfo.email}`}
                                      >
                                        {getOfferDetails.contactInfo !==
                                          undefined &&
                                          getOfferDetails.contactInfo !==
                                            null &&
                                          getOfferDetails.contactInfo.email}
                                      </a>
                                    </p>
                                  )}
                                {getOfferDetails.contactInfo !== undefined &&
                                  getOfferDetails.contactInfo !== null &&
                                  getOfferDetails.contactInfo.website !==
                                    "" && (
                                    <p>
                                      <img src={globIcon} />
                                      <a
                                        href={
                                          getOfferDetails.contactInfo.website
                                        }
                                        target="_blank"
                                      >
                                        {getOfferDetails.contactInfo !==
                                          undefined &&
                                          getOfferDetails.contactInfo !==
                                            null &&
                                          getOfferDetails.contactInfo.website}
                                      </a>
                                    </p>
                                  )}
                              </div>
                            </>
                          )}
                      </>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* success modal */}
          <div className={`${successModal ? "modal-fadein" : "modal-fadeout"}`}>
            <div className="desk-globle-model-box globle-model-box ">
              <div className="desk-globle-model-box-inner partners-details-popup-main">
                <div className="partners-details-popup-your-review">
                  <img src={succesIcon} />
                  <h3 className="your-review-title">Review submitted</h3>
                  <p className="sometihng-p">
                    Our moderation team will review it and respond shortly.
                  </p>
                  <span
                    onClick={() => {
                      setSuccessModal(false);
                      document.body.classList.remove("modal-open");
                    }}
                    className="partners-details-btn-main"
                  >
                    Back to partner
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PartnerInfo;
