import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const TableHeader = ({
  columns,
  createObjectFromArray,
  allEventList,
  columnsWithoutSoritng,
  listApi,
  setLoader,
  sortingHandler,
  searchText,
}) => {
  //handle sorting
  const handleSort = (col, direc, arr) => {
    //sorting in guest ticket using col and direc
    if (sortingHandler) {
      sortingHandler(col, direc);
      setLoader(false);
    } else {
      setLoader(true);
    }

    if (listApi) {
      listApi("", col, direc, arr);
    }
    // if (direc === "asc") {
    //     arr.sort(function (a, b) {
    //         var textA = a[col];
    //         var textB = b[col];
    //         return textA < textB ? -1 : textA > textB ? 1 : 0;
    //     });
    // } else if (direc === "desc") {
    //     arr.sort(function (a, b) {
    //         var textA = a[col];
    //         var textB = b[col];
    //         return textA > textB ? -1 : textA < textB ? 1 : 0;
    //     });
    // }
    // createObjectFromArray(arr)
  };

  return (
    <TableHead className="uic-table-th">
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            className={`uic-th-td w-${column?.width ? column.width : 9}`}
          >
            {!columnsWithoutSoritng.includes(column.id) &&
            column.id !== "drag" ? (
              <>
                <span className="custom-sorting">
                  <i
                    className="fa fa-angle-down"
                    onClick={(e) => {
                      handleSort(column.id, "Dec", allEventList);
                    }}
                  ></i>
                  <i
                    className="fa fa-angle-up"
                    onClick={(e) => {
                      handleSort(column.id, "Asc", allEventList);
                    }}
                  ></i>
                </span>
              </>
            ) : (
              ""
            )}
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
