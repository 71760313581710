import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedPartnersApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    videoUserCount: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.video.videoUserCount.url,
          method: ApiRoutes.video.videoUserCount.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    deleteVideoCategory: builder.mutation({
      query: ({ body }) => {
        return {
          url: ApiRoutes.video.deleteVideoCategory.url,
          method: ApiRoutes.video.deleteVideoCategory.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    reAssignVideoCategory: builder.query({
      query: (id) => {
        return {
          url: ApiRoutes.video.reAssignVideoCategory.url.replace(":id", id),
          method: ApiRoutes.video.reAssignVideoCategory.method,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useVideoUserCountMutation, useDeleteVideoCategoryMutation, useReAssignVideoCategoryQuery  } = extendedPartnersApi;
