import React from "react";
import ArrowChevronRight from "../../../components/Icons/ArrowsIcon/ArrowChevronRight";
import "./Document.css";
import "../../../Styles/Theme/Theme.css";
import PropTypes from "prop-types";
import { ReactComponent as IcnMClear } from "../../../Images/icn_m_clear.svg";
const FilterNestedNavigation = ({
  label = "Label",
  onClick,
  icon,
  closeIcon,
  closeIconClick,
}) => {
  return (
    <>
      <div className="filter-sidebar-navigation-item">
        <button className="filter-sidebar-navigation" onClick={onClick}>
          {icon} <h5 className="filter-sidebar-navigation-title">{label}</h5>
          <ArrowChevronRight />
        </button>
        {closeIcon && (
          <IcnMClear onClick={closeIconClick} className="close-icon" />
        )}
      </div>
    </>
  );
};
FilterNestedNavigation.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.element,
};
export default FilterNestedNavigation;
