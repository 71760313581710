import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import arrowleft from "../../../../Images/arrow-left.png";
import { useDispatch, useSelector } from 'react-redux';
import Loader2 from "../../../../Images/loader2.gif";
import EditDocumentInfo from './EditDocumentInfo';

const DocumentDetail = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMDSPartner = useSelector((state) => {
        return state.isMDS;
    })
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const partnerinprocess = useSelector((state) => { return state.ReduPartnerInProcess })
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    const partnerPendingReview = useSelector((state) => { return state.partnerPendingReview })
    const [tabInformation, setTabInformation] = useState("");
    const location = useLocation().search
    const id = new URLSearchParams(location).get("id")
    const [inProcess, setInProcess] = useState(false)

    //on first render call partner detail api
    useEffect(() => {
      
    }, [])

    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="um-back">
                        <button
                            type="button"
                            className="back-btn"
                            onClick={() => {
                                if (modalInProcess) {
                                    dispatch({ type: "SETCUSTOMPAGECHANGE" })
                                    dispatch({ type: "NAVIGATEPAGE", payload: "/document/list?search=" })
                                } else {
                                    navigate(NAVIGATEPAGE !== null && NAVIGATEPAGE !== "" ? NAVIGATEPAGE : "/document/list?search=");
                                }
                            }}

                        >
                            <img src={arrowleft} />
                            Back
                        </button>

                    </div>
                    <div className="uic-title">
                        <h1></h1>
                    </div>
                    <div className="group-listing-main">
                        <ul className="nav group-listing-menu" role="tablist">
                            <li>
                                <a className="active" href="#Info" role="tab" data-toggle="tab" onClick={() => {
                                    setTabInformation("Info");
                                }}>
                                    Document Info
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content group-listing-tabs">
                            <div
                                role="tabpanel"
                                className={false ? "tab-pane fade" : "tab-pane in active"}
                                id="Info"
                            >
                                <EditDocumentInfo />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentDetail