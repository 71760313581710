import React from 'react'
import PropTypes from "prop-types"
const SmallDoubleTickIcon = ({ height = 16, width = 16, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M7.755 10.662 6.278 9.185 4.699 7.608a.554.554 0 0 0-.783.783L5.494 9.97l-.693.693-3.056-3.054a.554.554 0 0 0-.783.783l3.447 3.446a.554.554 0 0 0 .784 0l.563-.563-.784-.783.522-.522 1.87 1.868a.554.554 0 0 0 .782 0l6.892-6.891a.554.554 0 0 0-.783-.784l-6.5 6.5Z"
                    clipRule="evenodd"
                />
                <path
                    fill={iconColor}
                    d="M12.084 4.946a.554.554 0 1 0-.783-.784L6.278 9.185l.522-.522.784.783 4.5-4.5Z"
                />
            </svg>
        </>
    )
}
SmallDoubleTickIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default SmallDoubleTickIcon
