const EventsList = () => {
  return (
    <ul>
      <li>
        <div className="cstm-events-list-left">
          <div className="cstm-events-list-left-nubmer">15</div>
          <div className="cstm-events-list-left-months">March</div>
        </div>
        <div className="cstm-events-list-right">
          <div className="cstm-events-list-right-title">
            <a href="#">Inovation new ideas</a>
          </div>
          <div className="cstm-events-list-right-sub">
            Lorem Ipsum is simply
          </div>
          <div className="cstm-events-list-right-join">
            <button>Join +</button>
          </div>
        </div>
      </li>
      <li>
        <div className="cstm-events-list-left">
          <div className="cstm-events-list-left-nubmer">12</div>
          <div className="cstm-events-list-left-months">March</div>
        </div>
        <div className="cstm-events-list-right">
          <div className="cstm-events-list-right-title">
            Inovation new ideas
          </div>
          <div className="cstm-events-list-right-sub">
            Lorem Ipsum is simply
          </div>
          <div className="cstm-events-list-right-join">
            <button>Join +</button>
          </div>
        </div>
      </li>
      <li>
        <div className="cstm-events-list-left">
          <div className="cstm-events-list-left-nubmer">10</div>
          <div className="cstm-events-list-left-months">March</div>
        </div>
        <div className="cstm-events-list-right">
          <div className="cstm-events-list-right-title">
            <a href="#">Inovation new ideas</a>
          </div>
          <div className="cstm-events-list-right-sub">
            Lorem Ipsum is simply
          </div>
          <div className="cstm-events-list-right-join">
            <button>Join +</button>
          </div>
        </div>
      </li>
      <li>
        <div className="cstm-events-list-left">
          <div className="cstm-events-list-left-nubmer">07</div>
          <div className="cstm-events-list-left-months">March</div>
        </div>
        <div className="cstm-events-list-right">
          <div className="cstm-events-list-right-title">
            Inovation new ideas
          </div>
          <div className="cstm-events-list-right-sub">
            Lorem Ipsum is simply
          </div>
          <div className="cstm-events-list-right-join">
            <button>Join +</button>
          </div>
        </div>
      </li>
    </ul>
  );
};
export default EventsList;
