import React from "react";
import FilterListItem from "../../../../Pages/DocumentManagement/Components/FilterListItem";

const CategoryListItems = ({
  handleBack,
  category,
  handleCategoryFilter,
  handleSubCategoryFilter,
  selectedCategory,
  selectedSubCategory,
}) => {
  const onCategoryClick = (category) => {
    if (category.subcategory != undefined && category.subcategory.length > 0) {
      handleCategoryFilter(category);
    } else {
      handleCategoryFilter(category);
      handleBack();
    }
  };
  return (
    <>
      <FilterListItem
        selected={category._id === selectedCategory._id}
        label={category.name}
        onClick={() => onCategoryClick(category)}
      />
      {selectedCategory.name === category.name &&
        category.subcategory &&
        category.subcategory.length > 0 &&
        category.subcategory.map((subCat) => (
          <div style={{ marginLeft: "40px" }}>
            <FilterListItem
              selected={selectedSubCategory._id === subCat._id}
              label={subCat.name}
              onClick={() => {
                handleSubCategoryFilter(subCat);
                handleBack();
              }}
            />
          </div>
        ))}
    </>
  );
};

export default CategoryListItems;
