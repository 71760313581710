import { useLoadScript } from "@react-google-maps/api";
import Map from "./GoogleMap";

import "../StaticPageManagement/MemberMapPage.css"
import LeftSidebar from '../../components/UserComponents/Dashboard/LeftSidebar'
import Header from '../../components/UserComponents/Dashboard/Header'

export default function MemberMapPage() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_KEY
  });


  return ( 
    <>
    <Header /> <div className="all-content-main-section">
    <LeftSidebar /> <div className='all-content-main-section-in'>
      <div className='member-page-main-only'>
        {/* <div className="member-banner-map">
          <div className="member-banner-map-in">
            <ul className="member-banner-menu">
              <li><a href="#">Facebook Groups</a></li>
              <li><a href="#" className="member-active">Member Map</a></li>
              <li><a href="#">Member Directory</a></li>
            </ul>
            <h2 className="member-banner-h2">Member Map</h2>
          </div>
        </div> */}
        <div className="member-text-main2">
          <p>We really can't stress enough how important it is to connect with your fellow members. The connections you make is what will drive your success. Our mission is to bring together a highly curated group of people by identifying individuals that we believe you can trust and grow with. There have been many strong friendships and businesses started through member connections in MDS, so don't be shy, reach out to the members in your area!</p>
          <p>The map above is an amazing tool to facilitate connections in your geographical area or while traveling. You will be able to see all members based on their home city. When you click on their profile, you can learn a little more about them and reach out!</p>
        </div>
        <div className="member-map-only">
       { isLoaded ? <Map /> : null}
         
        </div>


      </div>
    </div>
  </div>
  </>

    );
}