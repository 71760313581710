import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function ChatMediaProgress() {
  const { userId } = useParams(); // Corrected: invoked as a function
  const progressMessage = useSelector((state) => {
    return state.MediaProgressDisplayMessage;
  });

  return (
    <>
      {progressMessage.isUploading &&
        userId !== progressMessage.currentChatId && (
          <div className="connecting-message">{progressMessage.message}</div>
        )}
    </>
  );
}
