const savedvideos = [];

const ReduSavedVideos = (state = savedvideos, action) => {
  switch (action.type) {
    case "SAVEDVIDEOS":
      return [...state, action.payload];
    case "FETCHSAVEDVIDEOS":
      return action.payload;
    default:
      return state;
  }
};

export default ReduSavedVideos;
