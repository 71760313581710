import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Loader from "../../Images/loader.gif";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import exportImg from "../../Images/exportimg.svg";
// import ExportSelect from './ExportSelect';
import moment from "moment";
// import authNotification from '../../../../Services/auth.notification';
import authNotification from "../..//Services/auth.notification";
import ExportSelect from "../Admin/PartnerManagement/PartnerModals/ExportSelect";

const CountUsersModals = ({
  setViewModalOpen,
  claimId,
  filterType,
  fromDate,
  toDate,
  rowId,
  clickCountFlag,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  function viewUsersCreateData(id, name, viewCount, email, lastDate) {
    return { id, name, viewCount, email, lastDate };
  }

  const userColumns = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "readStatus", label: "Clicked Status" }, // true/False
    { id: "lastDate", label: "Clicked Date" },
  ];

  //function to create userrow for userlisting table
  function createUsers(
    id,
    name,
    email,
    readStatus,
    lastDate
  ) {
    return {
      id,
      name,
      email,
      readStatus,
      lastDate
    };
  }

  const [userRows, setUserRow] = useState([]);
  const [viewUsers, setViewUsers] = useState([]);
  const [userViewLoader, setUserViewLoader] = useState(false);
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0); // Selected rows
  const [selected, setSelected] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [selectError, setSelectError] = useState("");
  const [error, setError] = useState(false);
  const [showData, setshowData] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [sortColumn, setSortColumn] = useState("email");
  const [sortOrder, setSortOrder] = useState("Asc");
  const [exportTotalData, setExportTotalData] = useState(0); // Selected rows

  // first render when component is mount
  useEffect(() => {
   // setUserViewLoader(true);
    handleViewUsers(userPage + 1, sortColumn, sortOrder);
  }, [userPage]);

  // Get AllPartnerView Users List Api calling function
  const handleViewUsers = async (userPage, col, sort) => {
    try {
      setUserViewLoader(true);
      let querystring = `${rowId}`
      querystring += `?page=${userPage}&limit=${userRowsPerPage}`
      if (clickCountFlag !== undefined && clickCountFlag !== null && clickCountFlag) querystring +=  `&clickCount=${clickCountFlag}`
      if (col !== undefined && col !== null ) querystring += `&sortBy=${col}`
      if (sort !== undefined && sort !== null ) querystring += `&sortType=${sort}`

      const userCountList =
          await authNotification.sentNotificationUserCountList(querystring);

      if (userCountList && userCountList.status) {
        if (userCountList.data.data.notification.length > 0) {
          setshowData(userCountList.data.data.notification);
          setTotalData(userCountList.data.data.totalNotification);
          //setUserRow(userCountList.data.data.notification);

          const tbl = userCountList.data.data.notification
          let arr = []
          if (tbl && tbl.length > 0) {
            tbl.map((user) => {
              if (user && user.UserData !== undefined && user.UserData !== null && user.UserData.length > 0 )
              {
                const firstName =
                user.UserData[0].first_name ;
              const lastName =
                user.UserData[0].last_name;
              return { ...user, firstName, lastName };
              }
             
            })
              //.filter((user) => user.firstName !== null && user.lastName !== null)
              .map((user) =>
              
              user && user.UserData !== undefined && user.UserData !== null && user.UserData.length > 0 
              &&  arr.push(createUsers(user._id, `${(user.firstName !== undefined && user.firstName !== null ? user.firstName : "")   }  ${(user.firstName !== undefined && user.lastName !== null ? user.lastName : "")}`.trim(), user.UserData[0].email, user.isReadOnce, user.readAt))
              
              );

            setViewUsers(arr)
          }

        } else {
          setshowData([]);

        }

        setUserViewLoader(false);
      }
    } catch (error) {
      console.log("error:-----------", error);
      setUserViewLoader(false);
    }
  };

  // Pagination onChange function
  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage);
  };

  // Table data shorting function
  const handleSort = async (col, direc) => {
    setSortColumn(col)
    setSortOrder(direc)
    handleViewUsers(1,col, direc)
  };

  // Export for download xlsx file
  const handleExport = () => {
    const workbook_ = XLSX.utils.book_new(); // create new workbook
    if (selectRow.length > 0) {
      const modifiedClaimData = selectRow.map((item) => (
        {
          "Name": `${item.firstName}  ${item.lastName}`,
          "Email": item.UserData[0].email,
          "Clicked Status": item.isReadOnce ? "Yes" : "No",
          "Clicked Date": item.readAt ? moment(item.readAt).format(
            "MMM DD, YYYY"
          ) : "-"
        }));
      const convertToexcel = XLSX.utils.json_to_sheet(modifiedClaimData); // convert modified JSON data to sheet

      XLSX.utils.book_append_sheet(workbook_, convertToexcel, "Sheet2"); // add the sheet to the workbook
      const downloadExcel = XLSX.write(workbook_, {
        bookType: "xlsx",
        type: "array",
      });
      const dataExcel = new Blob([downloadExcel], { type: fileType });

      FileSaver.saveAs(dataExcel, "Notifications_Sent_To_The_Users.xlsx");
    } else {
      setError(true);
      setSelectError("Please select checkbox from column");
    }
  };

  return (
    <>
      <div className="desk-globle-model-box globle-model-box ">
        <div className="desk-globle-model-box-inner">
          <div className="modal-content">
            <div class="cstm-replace-pp">
              <div className="cstm-hdr">
                <span
                  class="cstm-rpp-close-btn"
                  onClick={() => {
                    setViewModalOpen(false);
                    document.body.classList.remove("modal-open");
                  }}
                >
                  ×
                </span>
                <div class="cstm-rpp-title">
                  User profiles that received notifications.
                </div>
              </div>
              <div className="cstm-export-link">
                <a onClick={handleExport}> <img src={exportImg} />Export</a>
              </div>
              <div className="cstm-cstm-viewlist">
                {error && <div className="alert alert-info">{selectError}</div>}
                <Paper className="uic-tb-mn">
                  <TableContainer className="uic-table-main">
                    <Table className="">
                      <TableHead className="uic-table-th">
                        <TableRow className="uic-th-td w-20">
                          <TableCell className="uic-th-td" padding="checkbox">
                            <Checkbox className='cstm-ls-checkbox' checked={checkAll}
                              color="primary"
                              onChange={async (e) => {
                                if (e.target.checked) {

                                  const exportData =  await authNotification.sentNotificationUserCountList(clickCountFlag ? `${rowId}?clickCount=${clickCountFlag}&sortBy=email&sortType=Asc` : `${rowId}?sortBy=email&sortType=Asc`);
                                  
                                  if (exportData && exportData.data.status) {
                                    const exportDataLists = exportData.data.data.notification
                                    let arr = []
                                    if (exportDataLists.length > 0) {
                                      exportDataLists.map((user) => {
                                        if (user && user.UserData !== undefined && user.UserData !== null && user.UserData.length > 0) {
                                        const firstName =
                                          user.UserData[0].first_name;
                                        const lastName =
                                          user.UserData[0].last_name;
                                        return { ...user, firstName, lastName };
                                      }
                                      })
                                        .map((user) =>
                                        user && user.UserData !== undefined && user.UserData !== null && user.UserData.length > 0 && arr.push(user)
                                        );


                                    }


                                    const newSelecteds = exportDataLists.map((row) => row._id);
                                    const newSelecteds1 = arr.map((row) => row);
                                    console.log(newSelecteds, newSelecteds1, exportDataLists,arr,'arr')
                                    setExportTotalData(newSelecteds1.length)
                                    setSelected(newSelecteds)
                                    setSelectRow(newSelecteds1)
                                    setSelectError("")
                                    setError(false)
                                    setCheckAll(true)
                                  } else {
                                    setSelected([]);
                                    setSelectRow([]);
                                    setError(true);
                                    setCheckAll(false)
                                    setSelectError("Export data not found!")
                                  }


                                } else {
                                  setSelected([]);
                                  setSelectRow([]);
                                  setCheckAll(false)
                                  setError(true);
                                  setSelectError("Please select checkbox from column")
                                }
                              }}
                              inputProps={{
                                'aria-label': 'select all desserts',
                              }}
                            />
                          </TableCell>

                          {userColumns.map((column) => (
                            <TableCell className="uic-th-td" key={column.id}>
                                  { (column.id === "email" || column.id === "lastDate" || column.id === "readStatus") &&
                                   <span className="custom-sorting">
                                   <i
                                     className="fa fa-angle-down"
                                     onClick={(e) => {
                                       handleSort((column.id == "readStatus"?"clicked_status":column.id == "lastDate"?"clicked_date":"email"), "Asc");
                                     }}
                                   ></i>
                                   <i
                                     className="fa fa-angle-up"
                                     onClick={(e) => {
                                       handleSort((column.id == "readStatus"?"clicked_status":column.id == "lastDate"?"clicked_date":"email"), "Desc");
                                     }}
                                   ></i>
                                 </span>
                        }
                           
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      <TableBody className="uic-table-td">
                        {userViewLoader ? (
                          <div className="cstm-data-load">
                            <img src={Loader} />
                          </div>
                        ) : viewUsers.length > 0 ? (
                          viewUsers?.map((row, index) => {
                            const isRead = row.readStatus;
                            const readDate = row.lastDate
                              ? moment(row.lastDate).format("MM/DD/YYYY, hh:mm a")
                              : "-";

                            return (
                              <>
                                {/* {row?.map((item) => ( */}
                                <TableRow className="uic-th-td w-20">
                                  <TableCell className='uic-th-td' padding="checkbox">
                                    <ExportSelect
                                      id={row.id}
                                      row={row}
                                      selected={selected}
                                      setSelected={setSelected}
                                      selectRow={selectRow}
                                      setSelectRow={setSelectRow}
                                      setSelectError={setSelectError}
                                      checkAll={checkAll}
                                      setCheckAll={setCheckAll}
                                      totalRows={exportTotalData}
                                    />
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {row.name}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {row.email}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {isRead ? "Yes" : "No"}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {readDate}
                                  </TableCell>
                                </TableRow>
                                {/* ))} */}
                              </>
                            );
                          })
                        ) : (
                          <span className="no-data-found-pl">
                            No data found
                          </span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={totalData}
                    rowsPerPage={userRowsPerPage}
                    page={userPage}
                    onPageChange={handleChangeUserPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountUsersModals;
