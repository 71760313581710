import { ApiRoutes } from "./apiRoutes";
import createApiInstance from "./createApiInstance";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    getTagsByAdmin: builder.query({
      query: (params) => {
        return {
          url: ApiRoutes.tags.getTags.url,
          method: ApiRoutes.tags.getTags.method,
          params,
        };
      },
      providesTags: ["Tags"],
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetTagsByAdminQuery } = extendedApi;
