import React, { useEffect, useState } from "react";
import authServicePartner from "../../../../Services/auth.service.partner";
import ReviewTable from "./ReviewTable";
import { useDispatch } from "react-redux";
import moment from "moment";
import authService from "../../../../Services/auth.service";

const AllReview = () => {
  const columns = [
    { id: "companyName", label: "Company name" },
    { id: "star", label: "Rating" },
    { id: "name", label: "Member name" },
    { id: "reviewNote", label: "Review" },
    { id: "date", label: "Date" },
    { id: "status", label: "Status" },
    { id: "reportCount", label: "Reports" },
    { id: "_id", label: "Actions" },
  ];
  const dispatch = useDispatch();
  const [allReview, setAllReview] = useState([]);
  const [rows, setRows] = useState([]);
  const [dataLoader, setDataLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("pending");
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");

  function createReviewData(
    _id,
    star,
    companyName,
    name,
    reviewNote,
    status,
    date,
    reportCount
  ) {
    return {
      _id,
      star,
      companyName,
      name,
      reviewNote,
      status,
      date,
      reportCount,
    };
  }

  useEffect(() => {
    setDataLoader(true);
    allReviewListApi(filter);
    getallusersSuggestionListApi();
  }, []);
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes =
        await authService.getPartnerReviewSuggestionList();

      let data = getSuggestionRes.data.data;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.companyName }));
        setSuggestionList(arr);
      }
    } catch (err) {}
  }
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  //all review list api call function
  async function allReviewListApi(filter, col, sort) {
    let query = `?filtertype=${filter}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    // setDataLoader(true)
    const allReviewListRes = await authServicePartner.allReviewList(query);
    try {
      if (allReviewListRes.status) {
        var arr = allReviewListRes.data.data;
        const pendingReview = arr.filter((item) => item.status === "none");
        dispatch({ type: "allReview", payload: pendingReview.length });
        if (arr.length > 0) {
          var newArr = [];
          for (let i = 0; i < arr.length; i++) {
            newArr[i] = createReviewData(
              arr[i]._id,
              arr[i].star !== undefined && arr[i].star !== null
                ? arr[i].star
                : 0,
              arr[i].partnerId !== undefined &&
                arr[i].partnerId !== null &&
                arr[i].partnerId.companyName !== undefined &&
                arr[i].partnerId.companyName !== null
                ? arr[i].partnerId.companyName
                : "-",
              arr[i].userId !== undefined &&
                arr[i].userId !== null &&
                (arr[i].userId.first_name !== "" ||
                  arr[i].userId.last_name !== "")
                ? `${arr[i].userId.first_name} ${arr[i].userId.last_name} ${
                    arr[i].isAnonymous ? `*` : ""
                  }`
                : "-",
              arr[i].reviewNote !== undefined &&
                arr[i].reviewNote !== null &&
                arr[i].reviewNote !== ""
                ? arr[i].reviewNote
                : "-",
              arr[i].status,
              moment(arr[i].createdAt).format("MMM DD, YYYY"),
              arr[i].reportCount
            );
          }
          // let finalData = newArr.sort((a, b) => a.status === 'none' && b.status !== 'none' ? -1 : 1,)
          setRows(newArr);
          setAllReview(newArr);
        } else {
          setRows([]);
          setAllReview([]);
        }
        setDataLoader(false);
      } else {
        setDataLoader(false);
      }
    } catch (error) {
      setDataLoader(false);
      return false;
    }
  }

  //search using text
  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    if (allReview !== undefined && allReview.length > 0) {
      const searchResult = allReview.filter((review) => {
        const lowercasedText = text.trim().toLowerCase();

        return (
          review.companyName.toLowerCase().includes(lowercasedText) ||
          review.name.toLowerCase().includes(lowercasedText)
        );
      });
      setRows(searchResult);
    }
  };

  //sorting function
  const handleSort = (col, direc) => {
    setDataLoader(true);
    allReviewListApi(filter, col, direc);
  };

  // handle status change filter function
  const handleStatusChange = (e) => {
    setFilter(e.target.value);
    setDataLoader(true);
    allReviewListApi(e.target.value);
  };

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>Reviews</h1>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.name
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox &&
                suggestions.length > 0 &&
                searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.name)}
                          className={
                            index === listIndex ? "selected-active" : ""
                          }
                        >
                          {searchText !== null &&
                          searchText.length > 0 &&
                          suggestion.name
                            .toLowerCase()
                            .startsWith(searchText.toLowerCase()) ? (
                            <>
                              <b>
                                {suggestion.name.slice(0, searchText.length)}
                              </b>
                              {suggestion.name &&
                                suggestion.name.slice(
                                  searchText.length,
                                  suggestion.name.length - 1
                                )}{" "}
                            </>
                          ) : (
                            suggestion.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
            </div>
            <div className="uic-fl-btn sort-by-mn">
              <div className="sort-by-mn-title">Filter by : </div>
              <div className="cstm-select-ap">
                <select value={filter} onChange={handleStatusChange}>
                  <option value="all">All Review</option>
                  <option value="verified">Approved</option>
                  <option value="pending">Pending</option>
                </select>
              </div>
            </div>
            <ReviewTable
              rows={rows}
              dataLoader={dataLoader}
              recallApi={allReviewListApi}
              handleSort={handleSort}
              filter={filter}
              columns={columns}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllReview;
