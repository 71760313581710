const unreadMessageIndex = "";

const ReduUnreadMessageIndex = (state = unreadMessageIndex, action) => {
  switch (action.type) {
    case "ASSIGNUNREADMESSAGE":
      // Only update the state if it's currently an empty string
      console.log();
      if (state === "") {
        return action.payload;
      }
      // If state already has a value, don't update it
      return state;
    case "RESETUNREADMESSAGEINDEX":
      // Reset the state to an empty string when the action payload is an empty string
      if (action.payload === "") {
        return "";
      }
      // If action payload is not empty, retain the current state
      return state;
    default:
      return state;
  }
};

export default ReduUnreadMessageIndex;
