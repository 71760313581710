import React, { useEffect, useState } from "react";
import AdminSidebar from "../AdminSidebar";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import CreateNotification from "./CreateNotification";
import EditChannel from "../ChannelManagement/EditChannelManagement/EditChannel";
import AllNotification from "./AllNotification";
import EditNotification from "./EditNotification";

const index = () => {
  const [pageNotFound, setPageNotFound] = useState(false);
  const [selectedDiv, setselectedDiv] = useState("");
  const [sidebarmenu, setSidebarmenu] = useState(false);

  const dispatch = useDispatch();
  const { action } = useParams();

  useEffect(() => {
    dispatch({
      type: "HISTORYPAGE",
      payload: "/admin/createNotification",
    });
    if (action !== undefined && action.length > 0) setselectedDiv(action);
    if (!["list", "createNotification", "detail"].includes(action))
      setPageNotFound(true);
  }, [action]);

  const updateWindowSize = () => {
    if (window.innerWidth > 992) {
      setSidebarmenu(false)
      document.body.classList.remove('overflow-hidden')
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  const sidebarHandleClick = (ele) => {
    setSidebarmenu(ele);
    if (window.innerWidth < 992) {
      if (sidebarmenu) {
        document.body.classList.remove('overflow-hidden')
      } else {
        document.body.classList.add('overflow-hidden')
      }
    }
  }
  return (
    <>
      <div className="cstm-all-admin">
        {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
        <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
        <div className="content-main">
          <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <Link
                    onClick={() => { sidebarHandleClick(false) }}
                    className={`btn-link collapsed ${selectedDiv === "list" || selectedDiv === "createNotification" || selectedDiv === "detail" ? "sd-active" : ""
                      } `}
                    to={"/notification/list?search="}
                  >
                    All notifications
                  </Link>
                </div>
              </div>
              {/* <div className="card">
                <div className="card-header" id="headingTwo">
                  <Link
                    className={`btn-link collapsed ${
                      selectedDiv === "createNotification" ? "sd-active" : ""
                    } `}
                    to={"/notification/createNotification"}
                  >
                    Add notification
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
          {selectedDiv === "list" ? (
            <AllNotification />
          ) : selectedDiv === "createNotification" ? (
            <CreateNotification />
          ) : selectedDiv === "detail" ? (
            <EditNotification />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};
export default index;
