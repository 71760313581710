import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedEventListApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    upComingEventList: builder.query({
      query: ({ params }) => {
        return {
          url: ApiRoutes.eventListing.upComingEventList.url,
          method: ApiRoutes.eventListing.upComingEventList.method,
          params,
        };
      },
      providesTags: ["GETLISTING"],
      transformResponse: (response) => response,
    }),
    pastEventList: builder.query({
      query: ({ params }) => {
        return {
          url: ApiRoutes.eventListing.pastEventList.url,
          method: ApiRoutes.eventListing.pastEventList.method,
          params,
        };
      },
      providesTags: ["GETLISTING"],
      transformResponse: (response) => response,
    }),
    myEventList: builder.query({
      query: ({ params }) => {
        return {
          url: ApiRoutes.eventListing.myEventList.url,
          method: ApiRoutes.eventListing.myEventList.method,
          params,
        };
      },
      providesTags: ["GETLISTING"],
      transformResponse: (response) => response,
    }),
    pastEventFilterList: builder.query({
      query: ({ params }) => {
        return {
          url: ApiRoutes.eventListing.pastEventFilterList.url,
          method: ApiRoutes.eventListing.pastEventFilterList.method,
          params,
        };
      },
      providesTags: ["GETLISTING"],
      transformResponse: (response) => response,
    }),
    upComingFilterEventList: builder.query({
      query: ({ params }) => {
        return {
          url: ApiRoutes.eventListing.upComingFilterEventList.url,
          method: ApiRoutes.eventListing.upComingFilterEventList.method,
          params,
        };
      },
      providesTags: ["GETLISTING"],
      transformResponse: (response) => response,
    }),
    eventCategoriesList: builder.query({
      query: ({ params }) => {
        return {
          url: ApiRoutes.eventListing.eventCategoriesList.url,
          method: ApiRoutes.eventListing.eventCategoriesList.method,
          params,
        };
      },
      providesTags: ["GETLISTING"],
      transformResponse: (response) => response,
    }),
    eventSuggestionList: builder.query({
      query: () => {
        return {
          url: ApiRoutes.eventListing.eventSuggestionList.url,
          method: ApiRoutes.eventListing.eventSuggestionList.method,
        };
      },
      transformResponse: (response) => response,
    }),
    eventSuggestionHistory: builder.query({
      query: () => {
        return {
          url: ApiRoutes.eventListing.eventSuggestionHistory.url,
          method: ApiRoutes.eventListing.eventSuggestionHistory.method,
        };
      },
      providesTags: ["SEARCHHISTORY"],
      transformResponse: (response) => response,
    }),
    addEventSearch: builder.mutation({
      query: ({ body }) => {
        return {
          url: ApiRoutes.eventListing.addEventSearch.url,
          method: ApiRoutes.eventListing.addEventSearch.method,
          body,
        };
      },
      invalidatesTags: ["SEARCHHISTORY"],
      transformResponse: (response) => response,
    }),
    eventSearchList: builder.query({
      query: ({ params }) => {
        return {
          url: ApiRoutes.eventListing.eventSearchList.url,
          method: ApiRoutes.eventListing.eventSearchList.method,
          params,
        };
      },
      transformResponse: (response) => response,
    }),
    removeSearchHistory: builder.mutation({
      query: ({ id }) => {
        return {
          url: ApiRoutes.eventListing.removeHistorySearch.url.replace(
            ":id",
            id
          ),
          method: ApiRoutes.eventListing.removeHistorySearch.method,
        };
      },
      invalidatesTags: ["SEARCHHISTORY"],
      transformResponse: (response) => response,
    }),
    resultCount: builder.query({
      query: ({ params }) => {
        return {
          url: ApiRoutes.eventListing.resultCount.url,
          method: ApiRoutes.eventListing.resultCount.method,
          params,
        };
      },
      transformResponse: (response) => response,
    }),
    getAllCities: builder.query({
      query: () => {
        return {
          url: ApiRoutes.eventListing.getAllCities.url,
          method: ApiRoutes.eventListing.getAllCities.method,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
});

export const {
  useUpComingEventListQuery,
  usePastEventListQuery,
  useMyEventListQuery,
  usePastEventFilterListQuery,
  useUpComingFilterEventListQuery,
  useEventCategoriesListQuery,
  useEventSuggestionListQuery,
  useEventSuggestionHistoryQuery,
  useAddEventSearchMutation,
  useLazyEventSearchListQuery,
  useRemoveSearchHistoryMutation,
  useResultCountQuery,
  useGetAllCitiesQuery,
} = extendedEventListApi;
