import { useEffect, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import authNews from "../../../Services/auth.service.news";
import authService from "../../../Services/auth.service";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import editIcon from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import Dropzone from "react-dropzone";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import AlertComponent from "../../../Hooks/AlertComponent";
import ModalPopup from "../../ModalPopup/ModalPopup";
import CreateBanner from "./CreateBanner";
import EditBanner from "./EditBanner";

const columns = [
    { id: "drag", label: "", width: 4 },
    { id: "image", label: "Image" },
    { id: "url", label: "URL" },
    { id: "startDate", label: "Start date" },
    { id: "startTime", label: "Start time" },
    { id: "endDate", label: "End date" },
    { id: "endTime", label: "End time" },
    { id: "saveAs", label: "Status" },
    { id: "action", label: "Actions" },
];

function createData(image, url, startDate, startTime, endDate, endTime, saveAs, action) {
    return {
        image,
        url,
        startDate,
        startTime,
        endDate,
        endTime,
        saveAs,
        action,
    };
}
const Banners = () => {
    const emptyObjectModal = { image: "", url: "", startDate: "", startTime: "", endDate: "", endTime: "", saveAs: "", imageWeb: "" };
    const emptyObjectErr = { image: "", url: "", startDate: "", startTime: "", endDate: "", endTime: "", saveAs: "", imageWeb: "" };
    const [rows, setRow] = useState([]);
    const [deleteId, setDeleteId] = useState("");
    const [searchText, setSearchText] = useState("");
    const [allEvents, setallEvents] = useState([]);
    const [editEvent, setEditEvent] = useState(emptyObjectModal);
    const [editErr, setEditErr] = useState(emptyObjectErr);
    const [selectedId, setSelectedId] = useState("");
    const [tableAlertMsg, setTableAlertMsg] = useState("");
    const [newImage, setNewImage] = useState("");
    const [loader, setLoader] = useState(false);
    const [isZoomImg, setIsZoomImg] = useState(false);
    const [zoomImg, setZoomImg] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [listIndex, setListIndex] = useState(-1);
    const [actualsearch, setActualsearch] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    //get banner list api call
    const getAllBannerApi = (searchText) => {
        let query = `?search=${searchText}`
        authNews.getAllNewsBanner(query)
            .then((res) => {
                setLoader(false);
                if (res.data.status) {
                    var data = res.data.data;
                    var tbl = [];
                    for (var i = 0; i < data.length; i++) {
                        tbl[i] = createData(
                            data[i].bannerImage,
                            data[i].bannerUrl,
                            data[i].publicationStartDate,
                            data[i].publicationStartTime,
                            data[i].publicationEndDate,
                            data[i].publicationEndTime,
                            data[i].saveAs,
                            data[i]._id
                        );
                    }
                    setRow(tbl);
                    setallEvents(tbl);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }
    //api call for the first time
    useEffect(() => {
        // setsearchText(search);
        setLoader(true);
        getAllBannerApi(searchText);
        handleClearAllStates()
        getallusersSuggestionListApi()
    }, []);
    async function getallusersSuggestionListApi() {
        try {
            const getSuggestionRes = await authService.getAllBannerSuggestionList()
            let data = getSuggestionRes.data.data
            var arr = []
            if (data && data.length !== undefined && data.length !== null && data.length > 0) {
                data.map((item) => arr.push({ name: item.bannerUrl }))
                setSuggestionList(arr);
            }
        } catch (err) {

        }
    }
    useEffect(() => {
        if (suggestionBox && listIndex > -1) {
            var currentList =
                document.getElementById("suggestion-list").children[listIndex]
            // .firstElementChild.firstElementChild;
            if (currentList) {
                var text = currentList.innerText
                setSearchText(text);
            }
        }
    }, [listIndex]);


    //edit modal api for by id
    const handleEdit = (value) => {
        setSelectedId(value);

    };

    //search url
    const handleSearch = (text) => {
        setSearchText(text);
        setSuggestionBox(false);
        getAllBannerApi(text)
        // if (allEvents !== undefined && allEvents.length > 0) {
        //     var data = allEvents.filter((event) => {
        //         return event.url.toLowerCase().includes(text.toLowerCase());
        //     });
        //     var tbl = [];
        //     for (var i = 0; i < data.length; i++) {
        //         tbl[i] = createData(
        //             data[i].image,
        //             data[i].url,
        //             data[i].startDate,
        //             data[i].startTime,
        //             data[i].endDate,
        //             data[i].endTime,
        //             data[i].saveAs,
        //             data[i].action
        //         );
        //     }
        //     setRow(tbl);
        // }
    };
    //handle delete banner
    const handleDelete = () => {
        authNews.deleteNewsBanner(deleteId)
            .then((res) => {
                if (res.data.status) {
                    setShowDeleteModal(false)
                    var tbl = [];
                    var n = 0;
                    for (var i = 0; i < allEvents.length; i++) {
                        if (allEvents[i].action !== deleteId) {
                            tbl[n] = createData(
                                allEvents[i].image,
                                allEvents[i].url,
                                allEvents[i].startDate,
                                allEvents[i].startTime,
                                allEvents[i].endDate,
                                allEvents[i].endTime,
                                allEvents[i].saveAs,
                                allEvents[i].action
                            );
                            n++;
                        }
                    }
                    setallEvents(tbl);
                    setRow(tbl);
                };
                tableAlertMsg(res.data.message);
            })
            .catch((e) => {
                console.log(e);
            });
    }
    //handle sorting
    const handleSorting = (e) => {
        var normal = allEvents;
        var arr = [...allEvents];

        if (e.target.value === "a-z") {
            arr.sort(function (a, b) {
                var textA = a.url.toUpperCase();
                var textB = b.url.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
        } else if (e.target.value === "z-a") {
            arr.sort(function (a, b) {
                var textA = a.url.toUpperCase();
                var textB = b.url.toUpperCase();
                return textA > textB ? -1 : textA < textB ? 1 : 0;
            });
        } else {
            arr = normal;
        }
        var tbl = [];
        for (var i = 0; i < arr.length; i++) {
            tbl[i] = createData(
                arr[i].image,
                arr[i].url,
                arr[i].startDate,
                arr[i].startTime,
                arr[i].endDate,
                arr[i].endTime,
                arr[i].saveAs,
                arr[i].action
            );
        }
        setRow(tbl);
    };

    const createObjectFromArray = (arr) => {
        var tbl = [];
        for (var i = 0; i < arr.length; i++) {
            tbl[i] = createData(
                arr[i].image,
                arr[i].url,
                arr[i].startDate,
                arr[i].startTime,
                arr[i].endDate,
                arr[i].endTime,
                arr[i].saveAs,
                arr[i].action
            );
        }
        setRow(tbl);
    }


    function isValidUrl(url) {
        // Regular expression pattern for URL validation
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

        return urlPattern.test(url);
    }



    //dropping row call
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const updatedRows = Array.from(rows);
        const [movedRow] = updatedRows.splice(result.source.index, 1);
        updatedRows.splice(result.destination.index, 0, movedRow);
        setRow(updatedRows);

        const ids = updatedRows.map(r => r.action)
        authNews.reorderNewsBanner({ ids })
            .then((res) => {
                if (!res.data.status) {
                    console.log(res.data.message)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    //set table alert message null after 3 secs
    useEffect(() => {
        setTimeout(() => {
            setTableAlertMsg("");
        }, 3000);
    }, [tableAlertMsg]);


    const handleZoomImage = (e) => {
        setIsZoomImg(true);
        setZoomImg(e.target.src);
    }


    //pop up state for do you want to leave....
    useEffect(() => {
        if (stayOnPage !== null && stayOnPage) {
            if ($('#addBanner').hasClass('show')) {
                $("#addBanner").modal('show')
            }

            if ($('#editBanner').hasClass('show')) {
                $("#editBanner").modal('show')
            }

        }
        else if (stayOnPage !== null && !stayOnPage) {
            if ($('#addBanner').hasClass('show')) {
                $("#addBanner").modal('hide')
            }

            if ($('#editBanner').hasClass('show')) {
                $("#editBanner").modal('hide')
            }

        }

    }, [stayOnPage])

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }

    const deleteBannerName = (deleteId) => {
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].action === deleteId) {
                return rows[i].url;
            }
        }
    };
   
    // String date
    function compareDates(dateString1, dateString2) {
        // Split date strings and convert to Date objects
        var dateParts1 = dateString1?.split("-");
        var date1 = new Date(dateParts1[2], dateParts1[0] - 1, dateParts1[1]);
        
        var dateParts2 = dateString2?.split("-");
        var date2 = new Date(dateParts2[2], dateParts2[0] - 1, dateParts2[1]);
    
        // Compare the two dates
        if (date1 > date2) {
            return true
        }
    }
    
    function getStatus(row,columnType) {
        if(columnType === "endDate"){
            return (row?.endDate)
        }else{
            if(columnType === "saveAs"){
                const today = new Date();
                const dd = String(today.getDate()).padStart(2, '0');
                const mm = String(today.getMonth() + 1).padStart(2, '0');
                const yyyy = today.getFullYear();
                const todayDate =  mm + '-' + dd + '-' + yyyy;
                if(compareDates(todayDate,row?.endDate)){
                    return "Expired"
                }else{
                    return row?.saveAs === "publish" ? "Published" : "Draft"
                }
            }
        }
    }
    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title">
                        <h1>Banners</h1>
                        <div className="uic-title-rt-only">
                            <button className="btn" data-toggle="modal" data-target="#addBanner" onClick={() => {
                            }}>
                                Add Banner
                            </button>
                        </div>
                    </div>
                    <div className="uic-fl">
                        <div className="uic-fl-search">
                            <input
                                type="text"
                                placeholder="Search"
                                className="search-user-input"
                                value={searchText}
                                onChange={(e) => {
                                    setSearchText(e.target.value);
                                    var currentValue;
                                    if (e.target.value) {
                                        currentValue = e.target.value;
                                    } else {
                                        currentValue = e.target.value;
                                        setSearchText(currentValue);
                                    }
                                    setActualsearch(currentValue);
                                    if (suggestionList !== undefined && suggestionList.length > 0) {
                                        setListIndex(-1);
                                        setSuggestionBox(true)
                                        var searchResult = suggestionList.filter((user) => {
                                            return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                                        });
                                        setSuggestions(searchResult);
                                    }
                                }}
                                onKeyUp={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSearch(e.target.value)
                                    } else if (e.key === "ArrowDown") {
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex < listLength - 1) {
                                            setListIndex(listIndex + 1);
                                        } else if (listIndex === listLength - 1) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        }
                                    } else if (e.key === "ArrowUp") {
                                        e.preventDefault();
                                        const listLength =
                                            suggestions.length > 15
                                                ? 15
                                                : suggestions.length;
                                        if (listIndex === 0) {
                                            setListIndex(-1);
                                            setSearchText(actualsearch);
                                        } else if (listIndex === -1) {
                                            setListIndex(listLength - 1);
                                        } else {
                                            setListIndex(listIndex - 1);
                                        }
                                    }
                                }}
                                onBlur={() => setSuggestionBox(false)}
                            />

                            {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                                <div className="suggestion-box">
                                    <ul id="suggestion-list">
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onMouseDown={() => handleSearch(suggestion.name)}
                                                className={index === listIndex ? 'selected-active' : ''}
                                            >
                                                {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length - 1)} </> : suggestion.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

                            </i>


                        </div>
                        <div className="uic-fl-btn sort-by-mn">
                            <div className="sort-by-mn-title">Sort by : </div>
                            <div className="sort-by-mn-select">
                                <select onChange={handleSorting}>
                                    <option>All</option>
                                    <option value="a-z">A-Z</option>
                                    <option value="z-a">Z-A</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {tableAlertMsg && <div className="alert alert-info">{tableAlertMsg}</div>}
                    <Paper className="uic-tb-mn">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <TableContainer className="uic-table-main">
                                <Table className="uic-table">
                                    <TableHeader columns={columns} createObjectFromArray={createObjectFromArray} allEventList={allEvents} columnsWithoutSoritng={["image", "action", "drag", "url", "startDate", "startTime", "endDate", "endTime", "saveAs"]} />
                                    {loader ?
                                        <div className="cstm-data-load"><img src={Loader} /> </div> :
                                        rows.length > 0 ?
                                            <Droppable droppableId="table">
                                                {(provided) => (
                                                    <TableBody ref={provided.innerRef} {...provided.droppableProps} className="uic-table-td">
                                                        {rows.map((row, index) => (
                                                            <Draggable key={row.action} draggableId={row.action} index={index}>
                                                                {(provided) => (
                                                                    <TableRow
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className="uic-th-td w-20"
                                                                    >

                                                                        {columns.map((column) => {
                                                                            const value = row[column.id];
                                                                            return column.id === "image" ? (
                                                                                <TableCell key={column.id} className="uic-th-td w-7">
                                                                                    <img className="banner-img" src={process.env.REACT_APP_AWS_IMG_VID_PATH + value} alt="image" onClick={handleZoomImage} />
                                                                                </TableCell>
                                                                            ) : column.id === "action" ? (
                                                                                <TableCell
                                                                                    key={column.id}
                                                                                    className="uic-th-td uic-table-icon w-17"
                                                                                >
                                                                                    <a
                                                                                        data-toggle="modal"
                                                                                        data-target="#editBanner"
                                                                                        onClick={() => {
                                                                                            handleEdit(value);
                                                                                        }}
                                                                                    >
                                                                                        <img src={editIcon} />
                                                                                    </a>
                                                                                    <a
                                                                                        data-toggle="modal"
                                                                                        data-target="#DeleteBanner"
                                                                                        onClick={() => {
                                                                                            setDeleteId(value);
                                                                                            setShowDeleteModal(true)
                                                                                        }}
                                                                                    >
                                                                                        <img src={imgDelete} />
                                                                                    </a>
                                                                                </TableCell>
                                                                            ) : column.id === "url" ?
                                                                                (
                                                                                    <TableCell key={column.id} className="uic-th-td w-40">
                                                                                        <a className="cstm-view-users-count" data-toggle="modal"
                                                                                            data-target="#editBanner"
                                                                                            onClick={() => {
                                                                                                handleEdit(row.action);
                                                                                            }}>
                                                                                            {value}
                                                                                        </a>
                                                                                    </TableCell>

                                                                                ) : column.id === "drag" ?
                                                                                    (
                                                                                        <TableCell className="uic-th-td w-3">
                                                                                            <i class="fas fa-arrows-alt banner-drag-drop"></i>
                                                                                        </TableCell>

                                                                                    ) :
                                                                                    (column.id === "saveAs" || column.id === "endDate") ?
                                                                                    (
                                                                                        <TableCell className="uic-th-td w-3">
                                                                                           {getStatus(row,column.id)}
                                                                                        </TableCell>

                                                                                    ) :
                                                                                    (
                                                                                        <TableCell key={column.id} className="uic-th-td w-8">
                                                                                            {value}
                                                                                        </TableCell>

                                                                                    );
                                                                        })}
                                                                    </TableRow>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </TableBody>
                                                )}
                                            </Droppable> : <span className="no-data-found">No data found</span>
                                    }
                                </Table>
                            </TableContainer>
                        </DragDropContext>
                    </Paper>
                </div>
            </div>
            {/* create modal */}
            <CreateBanner
                emptyObjectModal={emptyObjectModal}
                emptyObjectErr={emptyObjectErr}
                getAllBannerApi={getAllBannerApi}
                searchText={searchText}
                isValidUrl={isValidUrl}
                handleClearAllStates={handleClearAllStates}
            />

            {/* Edit modal */}
            <EditBanner
                emptyObjectModal={emptyObjectModal}
                emptyObjectErr={emptyObjectErr}
                getAllBannerApi={getAllBannerApi}
                searchText={searchText}
                isValidUrl={isValidUrl}
                handleClearAllStates={handleClearAllStates}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
            />
            {/* Modal Delete */}
            <ModalPopup
                onClose={() => setShowDeleteModal(false)}
                isFooter={true}
                isHeader={true}
                modelSize="480"
                buttonText="Cancel"
                buttonText1="Delete"
                isOpen={showDeleteModal}
                btn2variant="danger"
                className=""
                handleClick2ndButton={handleDelete}
                headingLable={"Delete banner"}
            >
                <div className="ctsm-modal-body">
                    <p>Are you sure you want to delete this "{deleteBannerName(
                        deleteId
                    )}"? This action cannot be undone.</p>
                </div>
            </ModalPopup>


            {/* zoomModel */}
            {isZoomImg && (zoomImg !== null) && (
                <div className="desk-globle-model-box globle-model-box cstm-zoom-img-popup cstm-banner-img" >
                    <div className="desk-globle-model-box-inner cstm-zoom-img-popup">
                        <div className="modal-content">
                            <div className="cstm-replace-pp">
                                <div className="cstm-rpp-title">
                                    Banner Image
                                    <span
                                        className="cstm-rpp-close-btn"
                                        onClick={() => setIsZoomImg(false)}
                                    >
                                        ×
                                    </span>
                                </div>

                                {zoomImg && (
                                    <div
                                        id="zoomImgContainer"
                                        className="zoomImgContainer"

                                    >

                                        <img id="zoomImg" src={zoomImg} className="cstmzoomImg" />


                                    </div>
                                )}
                            </div>
                        </div>
                    </div>{" "}
                </div>
            )}
            <><AlertComponent /></>
        </>

    );
};
export default Banners;
