import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import editIcon from "../../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../../Images/loader2.gif";
import Loader from "../../../../Images/loader.gif";
import authServicePartner from '../../../../Services/auth.service.partner';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import imgdelete from "../../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AlertComponent from "../../../../Hooks/AlertComponent";
import authService from '../../../../Services/auth.service';
import editfill from "../../../../Images/BackendImages/edit.svg";
const FeaturedPartner = () => {

    const [existingModalLoader, setExistingModalLoader] = useState(false);
    const [dataLoader, setDataLoader] = useState(false)
    const [existingModalAlert, setExistingModalAlert] = useState("");
    const [featuredAlert, setFeaturedAlert] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [allPartner, setAllPartner] = useState([])
    const [featuredPartnersList, setFeaturedPartnersList] = useState([])
    const [rows, setRows] = useState([])
    const [searchParticipents, setSearchParticipents] = useState("");
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [inProcess, setInProcess] = useState(false)
    const [featuredPartnerErr, setFeaturedPartnerErr] = useState({
        selctedPartners: ""
    })
    const [searchText, setSearchText] = useState("");
    const [partnerId, setPartnerId] = useState('')
    const [isZoomImg, setIsZoomImg] = useState(false);
    const [zoomImg, setZoomImg] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions,setSuggestions]=useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);

    const [showInput, setShowInput] = useState(false);
    const [showSub, setShowSub] = useState(false);
    const [editFeatured, setEditFeatured] = useState("");
    const [featuredSubtitle, setFeaturedSubtitle] = useState("");
    const [id, setId] = useState("");
    const [actualsearch, setActualsearch] = useState("");
    const featured = useRef(null);
    const featuredSub = useRef(null);
    const partnerLabels = useSelector((state) => { return state.ReduPartnerDynamicLabel })
    const defaultFeaturedLabel = {
        featuredLabel: "Featured Module 1",
        featuredSubLabel: "Sub title for featured partner 1"
    }

    useEffect(() => {
        handleClearAllStates()
        setDataLoader(true)
        getUnFeaturedPartnerApi()
        getFeaturedPartnerApi()
        partnerSettingApi();
        getallusersSuggestionListApi()
    }, [])


    // api function
    async function partnerSettingApi() {
        try {
            const partnerDetailRes = await authServicePartner.getPartnerSetting()
            console.log(partnerDetailRes, "partnerDetailRes")
            if (partnerDetailRes.status) {
                partnerDetailRes.data.data.map((item) => {
                    setEditFeatured(item.featurePartner)
                    setFeaturedSubtitle(item.featurePartnerSubTitle)
                    setId(item._id)
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    async function getallusersSuggestionListApi() {
        try {
          const getSuggestionRes = await authService.getFeaturedPartnersList()
    
           console.log(getSuggestionRes , "getSuggestionRes");
          let data = getSuggestionRes.data.data
          var arr = []
         if (data &&data.length !==undefined && data.length !== null && data.length>0 ){
          data.map((item)=>arr.push({ name:item.companyName}))
          console.log(arr);
          setSuggestionList(arr);
         }
        } catch (err) {
         
        }
      }
    



    function createFeaturedData(_id, companyLogo, companyName) {
        return {
            _id, companyLogo, companyName
        }
    }

    //get UnFeatured partner api call
    async function getUnFeaturedPartnerApi() {
        try {
            const getUnFeaturedPartnerRes = await authServicePartner.getUnFeaturedPartnerList()
            if (getUnFeaturedPartnerRes.status) {
                const data = getUnFeaturedPartnerRes.data.data
                setExistingModalLoader(false)
                setAllPartner(getUnFeaturedPartnerRes.data.data)
                const featuredList = data.filter((list) => (list.featuredPartner === true))
                setSelectedUsers(featuredList)
            }
        } catch (error) {
            return false
        }
    }
    useEffect(() => {
        if (suggestionBox && listIndex > -1) {
          var currentList =
            document.getElementById("suggestion-list").children[listIndex]
          // .firstElementChild.firstElementChild;
          if (currentList) {
            var text = currentList.innerText
            setSearchText(text);
          }
        }
      }, [listIndex]);

    //get featured partner api call
    async function getFeaturedPartnerApi() {
        let query = '?type=featured'
        try {
            const getFeaturedPartnerRes = await authServicePartner.getFeaturedPartnerList(query)
            if (getFeaturedPartnerRes.status) {
                var arr = getFeaturedPartnerRes.data.data
                if (arr.length > 0) {
                    var newArr = []
                    for (let i = 0; i < arr.length; i++) {
                        newArr[i] = createFeaturedData(
                            arr[i]._id,
                            arr[i].companyLogo,
                            arr[i].companyName
                        )
                    }
                    setFeaturedPartnersList(newArr)
                    setRows(newArr)
                } else {
                    setFeaturedPartnersList(arr)
                    setRows(arr)
                }
                setDataLoader(false)
            }
        } catch (error) {
            return false
        }
    }

    //handle partner selection without event
    const handlePartnerSelect = (user) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        const updatedSelection = selectedUsers.includes(user)
            ? selectedUsers.filter((selectedUser) => selectedUser !== user)
            : [...selectedUsers, user];
        setSelectedUsers(updatedSelection);
    };

    //add featured partner api
    async function addFeaturedPartnerApi() {
        if (selectedUsers.length > 0) {
            setExistingModalLoader(true)
            const selctedPartners = selectedUsers.map((item) => item._id)
            const bodydata = {
                partnersIds: selctedPartners,
                type: "featured"
            }
            try {
                const addFeaturedRes = await authServicePartner.addFeaturedPartner(bodydata)
                if (addFeaturedRes.status) {
                    setSelectedUsers([])
                    setExistingModalAlert(addFeaturedRes.data.message)
                    setExistingModalLoader(false)
                    getUnFeaturedPartnerApi()
                    getFeaturedPartnerApi()
                    setTimeout(() => {
                        setExistingModalAlert('')
                    }, 2000)
                    handleClearAllStates()
                }
            } catch (error) {
                handleClearAllStates()
                return false
            }
        } else {
            setFeaturedPartnerErr({ ...featuredPartnerErr, selctedPartners: "Please select partner" })
        }
    }

    //handle remove featured partner 
    const handleRemoveFeaturedPartner = async (e) => {

        setInProcess(true)
        let query = `${partnerId}?type=featured`
        try {
            const removeFeaturedRes = await authServicePartner.removeFeaturedPartner(query)
            if (removeFeaturedRes.status) {
                document.querySelector('#removeFeaturedPartner .close').click()
                setShowAlert(true)
                setInProcess(false)
                setFeaturedAlert(removeFeaturedRes.data.message)
                getUnFeaturedPartnerApi()
                getFeaturedPartnerApi()
                setSelectedUsers([])
                setTimeout(() => {
                    setShowAlert(false)
                    setFeaturedAlert('')
                }, 3000)
            } else {
                document.querySelector('#removeFeaturedPartner .close').click()
                setShowAlert(true)
                setInProcess(false)
                setFeaturedAlert(removeFeaturedRes.data.message)
            }
        } catch (error) {
            document.querySelector('#removeFeaturedPartner .close').click()
            return false
        }
    }


    //hadnle zoom for logo 
    const handleZoomImage = (e) => {
        setIsZoomImg(true);
        setZoomImg(e.target.src);
    }

    //search url
    const handleSearch = (text) => {
        setSuggestionBox(true);
        setSearchText(text);
        if (rows !== undefined && rows.length > 0) {
            var data = rows.filter((part) => {
                return part.companyName.toLowerCase().includes(text.toLowerCase());
            });
            setFeaturedPartnersList(data)
        }
    };

    //sorting function
    const handleSorting = (e) => {
        var normal = rows;
        var sortedResult = [...rows];

        if (e.target.value === "a-z") {
            sortedResult.sort(function (firstItem, secondItem) {
                var itemA = firstItem.companyName.toUpperCase();
                var itemB = secondItem.companyName.toUpperCase();
                return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
            });
        } else if (e.target.value === "z-a") {
            sortedResult.sort(function (firstItem, secondItem) {
                var itemA = firstItem.companyName.toUpperCase();
                var itemB = secondItem.companyName.toUpperCase();
                return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
            });
        } else {
            sortedResult = normal;
        }
        setFeaturedPartnersList(sortedResult);
    };

    //columns for table
    const columns = [
        { id: "drag", label: "" },
        { id: "companyLogo", label: "Logo" },
        { id: "companyName", label: "Company name" },
        { id: "_id", label: "Actions" },
    ];

    //handle drag and reorder rows of partners
    const onDragEnd = async (result) => {
        if (!result.destination) {
            return;
        }
        const updatedRows = Array.from(featuredPartnersList);
        const [movedRow] = updatedRows.splice(result.source.index, 1);
        updatedRows.splice(result.destination.index, 0, movedRow);
        setFeaturedPartnersList(updatedRows);
        const selctedPartners = updatedRows.map((item) => item._id)
        const ids = {
            ids: selctedPartners,
            type: "featured"
        }
        authServicePartner.reorderFreshDealPartner(ids)
    }

    //sorting function
    const handleSort = (col, direc) => {
        var sortedResult = featuredPartnersList;
        if (direc === "asc") {
            sortedResult.sort(function (firstItem, secondItem) {
                var itemA = firstItem[col];
                var itemB = secondItem[col];
                return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
            });
        } else if (direc === "desc") {
            sortedResult.sort(function (firstItem, secondItem) {
                var itemA = firstItem[col];
                var itemB = secondItem[col];
                return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
            });
        }
        var table = [];

        for (var i = 0; i < sortedResult.length; i++) {
            table[i] = createFeaturedData(
                sortedResult[i]._id,
                sortedResult[i].companyLogo,
                sortedResult[i].companyName
            )
        }
        setRows(table);
    };

    //stayonpage  popup for do you want to leave page
    useEffect(() => {
        if (stayOnPage !== null && stayOnPage) {
            if ($('#existingPartner').hasClass('show')) {
                $("#existingPartner").modal('show')
            }



        }
        else if (stayOnPage !== null && !stayOnPage) {
            if ($('#existingPartner').hasClass('show')) { $("#existingPartner").modal('hide') }


        }

    }, [stayOnPage])

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }
    //called when click on outside of input
    const handleClickOutside = (e) => {

        if (
            featured.current &&
            showInput &&
            !featured.current.contains(e.target)
        ) {
            const payload = {
                featuredLabel: editFeatured,
                featuredSubLabel: featuredSubtitle,
            }
            dispatch({
                type: "SETDYNAMICLABEL",
                payload: payload,
            });
            setShowInput(false)
            handleSubmitFeaturedLabel(editFeatured);
        }


        if (
            featuredSub.current &&
            showSub &&
            !featuredSub.current.contains(e.target)
        ) {
            const payload = {
                featuredLabel: editFeatured,
                featuredSubLabel: featuredSubtitle,
            }
            dispatch({
                type: "SETDYNAMICLABEL",
                payload: payload,
            });
            setShowSub(false)
            handleSubmitFeaturedSubLabel(featuredSubtitle);
        }

        document.removeEventListener('click', handleClickOutside);
    };

    //called when featured label one change
    const handleFeaturedPartnerLabelChange = (e) => {
        if (e.target !== undefined && e.target !== null) {
            setEditFeatured(e.target.value)
        }
    };

    //called when enter key press on featured label
    const handleFeaturedPartnerLabelKeyUp = (e) => {
        if (showInput && (e.keyCode == 13 || e.key === "Enter")) {
            setShowInput(false)
            const payload = {
                featuredLabel: editFeatured,
                featuredSubLabel: featuredSubtitle,
            }
            dispatch({
                type: "SETDYNAMICLABEL",
                payload: payload,
            });
            handleSubmitFeaturedLabel(e.target.value);
        }
    }

    //called when featured sub label change
    const handleFeaturedPartnerSubLabelChange = (e) => {
        if (e.target !== undefined && e.target !== null) {
            setFeaturedSubtitle(e.target.value)

        }
    };

    //called when enter key press on featured sub label
    const handleFeaturedPartnerSubLabelKeyUp = (e) => {
        if (showSub && (e.keyCode == 13 || e.key === "Enter")) {
            setShowSub(false)
            const payload = {
                featuredLabel: editFeatured,
                featuredSubLabel: featuredSubtitle,
            }
            dispatch({
                type: "SETDYNAMICLABEL",
                payload: payload,
            });
            handleSubmitFeaturedSubLabel(e.target.value);
        }
    }

    //called when submit featured label 
    const handleSubmitFeaturedLabel = async (changedTitle) => {
        const formDataCreatePartner = new FormData();
        const featuredVar = changedTitle !== "" ? changedTitle : defaultFeaturedLabel.featuredLabel
        formDataCreatePartner.append("featurePartner", featuredVar);
        formDataCreatePartner.append("featurePartnerSubTitle", partnerLabels.featuredSubLabel);

        try {
            const createPartnerRes = await authServicePartner.partnerSetting(id, formDataCreatePartner);
            if (createPartnerRes.status) {
                setEditFeatured(featuredVar)

                const payload = {
                    featuredLabel: featuredVar,
                    freshDealLabel: partnerLabels.freshDealLabel,
                    featuredSubLabel: partnerLabels.featuredSubLabel,
                    freshDealSubLabel: partnerLabels.freshDealSubLabel,

                }
                dispatch({
                    type: "SETDYNAMICLABEL",
                    payload: payload,
                });
            }
        } catch (error) {
            console.log(error);
        }

    }
    //called when submit featured sub label
    const handleSubmitFeaturedSubLabel = async (changedTitle) => {
        const formDataCreatePartner = new FormData();
        const featuredSubVar = changedTitle !== "" ? changedTitle : defaultFeaturedLabel.featuredSubLabel
        formDataCreatePartner.append("featurePartner", partnerLabels.featuredLabel);
        formDataCreatePartner.append("featurePartnerSubTitle", featuredSubVar);

        try {
            const createPartnerRes = await authServicePartner.partnerSetting(id, formDataCreatePartner);
            if (createPartnerRes.status) {
                const payload = {
                    featuredLabel: partnerLabels.featuredLabel,
                    featuredSubLabel: featuredSubVar,
                    freshDealLabel: partnerLabels.freshDealLabel,
                    freshDealSubLabel: partnerLabels.freshDealSubLabel,

                }
                dispatch({
                    type: "SETDYNAMICLABEL",
                    payload: payload,
                });

                setShowInput(false);
                setShowSub(false);

            }
        } catch (error) {
            console.log(error);
        }

    }
    useEffect(() => {
        // Add a click event listener to the entire document to detect clicks outside the input fields
        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [editFeatured, featuredSubtitle])
    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title cstm-pnr">
                        <div className='searchPartner'>
                            {showInput ? (
                                // Input field for editing
                                <input
                                    type="text"
                                    placeholder="Enter text..."
                                    value={editFeatured}
                                    onChange={handleFeaturedPartnerLabelChange}
                                    onKeyUp={handleFeaturedPartnerLabelKeyUp}
                                    ref={featured}
                                    className='editfeatured'
                                    autoFocus
                                />
                            ) : (
                                // Display the title
                                <>
                                    <h1 onDoubleClick={() => {
                                        setShowInput(true)
                                        setShowSub(false)
                                    }}><span>{partnerLabels.featuredLabel}</span><img onClick={() => {
                                        setShowInput(true)
                                        setShowSub(false)
                                    }} src={editfill} /></h1>
                                </>
                            )}
                            {showSub ? (
                                // Input field for editing subtitle
                                <input
                                    type="text"
                                    placeholder="Enter text..."
                                    value={featuredSubtitle}
                                    onChange={handleFeaturedPartnerSubLabelChange}
                                    onKeyUp={handleFeaturedPartnerSubLabelKeyUp}
                                    ref={featuredSub}
                                    className='editfeatured2'
                                    autoFocus
                                />
                            ) : (
                                // Display the subtitle
                                <>
                                    <span className='select-maximum' onDoubleClick={() => {
                                        setShowSub(true)
                                        setShowInput(false)
                                    }}><span>{partnerLabels.featuredSubLabel}</span><img onClick={() => {
                                        setShowSub(true)
                                        setShowInput(false)
                                    }} src={editfill} /></span>
                                </>
                            )}
                        </div>
                        <div className='featuredPartner'>
                            <div className="uic-fl-btn">
                                <button
                                    className="btn"
                                    data-toggle="modal"
                                    // onClick={() => {
                                    //     if (featuredPartnersList.length === 10) {
                                    //         setShowAlert(true)
                                    //         setFeaturedAlert("You can not add more than 10 partners.")
                                    //         setTimeout(() => {
                                    //             setFeaturedAlert('')
                                    //             setShowAlert(false)
                                    //         }, 2000)
                                    //     } else {
                                    //         handleClearAllStates()
                                    //         $("#existingPartner").modal('show');

                                    //     }
                                    // }}
                                    data-target="#existingPartner"
                                >
                                    Select partner
                                </button>
                            </div>
                            <span className='max-select'> Select up to 10 offers to feature.</span>
                        </div>
                    </div>
                    <div className="uic-fl">
                        <div className="uic-fl-search">
                            <input
                                type="text"
                                value={searchText}
                                placeholder="Search"
                                className="search-user-input"
                                onChange={(e) => {
                      setSearchText(e.target.value);
                      var currentValue;
                      if (e.target.value) {
                        currentValue = e.target.value;
                      } else {
                        currentValue = e.target.value;
                        setSearchText(currentValue);
                      }
                      setActualsearch(currentValue);
                      if (suggestionList !== undefined && suggestionList.length > 0) {
                        setListIndex(-1);
                        setSuggestionBox(true)
                        var searchResult = suggestionList.filter((user) => {
                          return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                        });
                        setSuggestions(searchResult);
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        handleSearch(e.target.value)
                      } else if (e.key === "ArrowDown") {
                        const listLength =
                          suggestions.length > 15
                            ? 15
                            : suggestions.length;
                        if (listIndex < listLength - 1) {
                          setListIndex(listIndex + 1);
                        } else if (listIndex === listLength - 1) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        }
                      }else if (e.key === "ArrowUp") {
                        e.preventDefault();
                        const listLength =
                        suggestions.length > 15
                            ? 15
                            : suggestions.length;
                        if (listIndex === 0) {
                          setListIndex(-1);
                          setSearchText(actualsearch);
                        } else if (listIndex === -1) {
                          setListIndex(listLength - 1);
                        } else {
                          setListIndex(listIndex - 1);
                        }
                      }
                    }}
                    onBlur={() => setSuggestionBox(false)}
                  />

                  {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                    <div className="suggestion-box">
                      <ul  id="suggestion-list">
                        {suggestions.map((suggestion, index) => (
                          <li
                            key={index}
                            onMouseDown={() => handleSearch(suggestion.name)}
                            className={index === listIndex ? 'selected-active' : ''}
                          >
                           {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length -1)} </> : suggestion.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

                  </i>

       
                        </div>
                    </div>
                    {showAlert && <div className="alert alert-info">{featuredAlert}</div>}
                    <Paper className="uic-tb-mn">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <TableContainer className="uic-table-main">
                                <Table className="uic-table min-w-auto">
                                    <TableHead className="uic-table-th">
                                        <TableRow>
                                            {columns.map((col) => (
                                                <TableCell
                                                    key={col.id}
                                                    align={col.align}
                                                    className="uic-th-td"
                                                >
                                                    {/* {col.id !== "action" && col.id !== "_id" && col.id !== "drag" && col.id !== "companyLogo" ? (
                                                        <>
                                                            <span className="custom-sorting">
                                                                <i className="fa fa-angle-down" onClick={(e) => {
                                                                    handleSort(col.id, "asc");
                                                                }} />
                                                                <i className="fa fa-angle-up" onClick={(e) => {
                                                                    handleSort(col.id, "desc");
                                                                }} />
                                                            </span>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )} */}
                                                    {col.label}
                                                </TableCell>))}
                                        </TableRow>
                                    </TableHead>
                                    {dataLoader ?
                                        <div className="cstm-data-load"><img src={Loader} /> </div>
                                        :
                                        featuredPartnersList.length > 0 ?
                                            <Droppable droppableId="table">
                                                {(provided) => (
                                                    <TableBody ref={provided.innerRef} {...provided.droppableProps} className="uic-table-td">
                                                        {featuredPartnersList.map((row, index) => (
                                                            <Draggable key={row._id} draggableId={row._id} index={index}>
                                                                {(provided) => (
                                                                    <TableRow
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className="uic-th-td w-20"
                                                                    >
                                                                        {columns.map((column) => {
                                                                            const value = row[column.id]
                                                                            return column.id === "companyLogo" ?
                                                                                <TableCell  className="uic-th-td w-8">{value !== "" ? <img className="cstm-url-link" onClick={handleZoomImage} src={value} alt="image" /> : "-"}</TableCell> :
                                                                                column.id === "companyName" ?
                                                                                    <TableCell className="uic-th-td w-65">{value}</TableCell> :
                                                                                    column.id === "_id" ? <TableCell className="uic-th-td w-6">
                                                                                        <a
                                                                                            data-toggle="modal"
                                                                                            data-target="#removeFeaturedPartner"
                                                                                            onClick={() => setPartnerId(value)}
                                                                                        >
                                                                                            <Tooltip title='Delete' arrow>  <img src={imgdelete} /></Tooltip>
                                                                                        </a>
                                                                                    </TableCell> :
                                                                                        <TableCell className="uic-th-td w-4">
                                                                                            <i class="fas fa-arrows-alt banner-drag-drop"></i>
                                                                                        </TableCell>

                                                                        })}
                                                                    </TableRow>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                    </TableBody>
                                                )}
                                            </Droppable> : <span className="no-data-found">No data found</span>
                                    }
                                </Table>
                            </TableContainer>
                        </DragDropContext>
                    </Paper>
                </div>
            </div>
            {/* existing partners */}
            <div
                className="modal fade edit-user-details-popup"
                id="existingPartner"
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Select Partners*</h2>
                            <button
                                type="button"
                                className="close"
                                //data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {
                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                    } else {
                                        $("#existingPartner").modal('hide')
                                    }

                                }}

                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {existingModalAlert && (
                                <div className="alert alert-info">{existingModalAlert}</div>
                            )}
                            <div className="form-group margin-bottom-0">
                                <div className="form-group-ct">
                                    <div className="row">
                                        <div className="col-sm-12 form-group">

                                            <div className="existing-attendee-search">
                                                <input
                                                    type="text"
                                                    value={searchParticipents}
                                                    placeholder="Search Partner"
                                                    onChange={(e) => {
                                                        setFeaturedPartnerErr({ ...featuredPartnerErr, selctedPartners: "" })
                                                        setSearchParticipents(e.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="select-attendees-box">
                                                {allPartner.filter((att) => {
                                                    return (
                                                        att.companyName
                                                            .toLowerCase()
                                                            .includes(searchParticipents.toLowerCase())
                                                    );
                                                }).map((part) =>
                                                (
                                                    <label
                                                        className="select-participants-box-in"
                                                        key={part._id}
                                                        style={{
                                                            backgroundColor: selectedUsers.includes(
                                                                part
                                                            )
                                                                ? "#EBE8FA"
                                                                : "",
                                                        }}
                                                    >
                                                        <div className="select-participants-box-in-name">
                                                            <p className="select-pt-title">
                                                                {part.companyName}
                                                            </p>
                                                        </div>
                                                        <div className="select-participants-box-in-checkbox">
                                                            <input
                                                                // defaultChecked={part.featuredPartner  }
                                                                checked={selectedUsers.includes(part)}
                                                                // disabled={part.featuredPartner || featuredPartnersList.length === 10}
                                                                type="checkbox"
                                                                onChange={() => {
                                                                    handlePartnerSelect(part);
                                                                }}
                                                            />
                                                        </div>
                                                    </label>)
                                                )
                                                }
                                            </div>
                                            <span className="error">{featuredPartnerErr.selctedPartners}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="edit-user-details-footer cstm-create-button">
                            {existingModalLoader ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={addFeaturedPartnerApi}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade msg-popup"
                id="removeFeaturedPartner"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                            <img src={imgBigDelete} />
                        </div>
                        <h1>Are you sure you want to delete this featured partner?</h1>
                        <div className="row">
                            <div className="col-6">
                                {inProcess ? (
                                    <div className="loader-button1">
                                        <button>In Process...</button>
                                        <span className="loader-btn">
                                            <img src={Loader2} />
                                        </span>
                                    </div>
                                ) : (
                                    <span className="msg-btn">
                                        <button
                                            type="button"
                                            className="btn btn-red"
                                            onClick={handleRemoveFeaturedPartner}
                                        >
                                            Delete
                                        </button>
                                    </span>)}
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" className="btn2" data-dismiss="modal">
                                        Discard
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isZoomImg && (zoomImg !== null) && (
                <div className="desk-globle-model-box globle-model-box cstm-zoom-img-popup cstm-company-logo" >
                    <div className="desk-globle-model-box-inner cstm-zoom-img-popup">
                        <div className="modal-content">
                            <div className="cstm-replace-pp">
                                <div className="cstm-rpp-title">
                                    Logo Image
                                    <span
                                        className="cstm-rpp-close-btn"
                                        onClick={() => setIsZoomImg(false)}
                                    >
                                        &times;
                                    </span>
                                </div>

                                {zoomImg && (
                                    <div
                                        id="zoomImgContainer"
                                        className="zoomImgContainer"

                                    >

                                        <img id="zoomImg" src={zoomImg} className="cstmzoomImg" />


                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <AlertComponent />
        </>
    )
}

export default FeaturedPartner
