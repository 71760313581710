import React from "react";
import "./Styles/EventSmallCard.css";
import { Link } from "react-router-dom";
import EventThumbnailSmallImage from "./EventThumbnailSmallImage.jsx";
import Button from "../../../../components/Button/Button.js";
import DotsIcon from "../../../../components/Icons/GeneralIcons/DotsIcon.js";

const EventSmallCard = ({
  url = "/",
  hasSpacings = false,
  thumbUrl,
  cardSize = "small",
  title = "Event Title Text",
  views = "260 View",
  uplodedDate = "2 Months ago",
  eventType = "Summit",
  thumbType,
  hasDate = true,
  hasEventType = true,
  hasDuration,
  duration,
  onClick,
  hasDotMenu = true,
  hasView = true,
  alignCenter = false,
}) => {
  return (
    <>
      <div className={`position-relative ${hasDotMenu ? "has-dotmenu" : ""}`}>
        <Link
          to={url}
          className={`event-card ${cardSize} ${
            alignCenter ? "align-items-center" : ""
          } ${hasSpacings ? "card-spacing" : ""}`}
          onClick={onClick}
        >
          <EventThumbnailSmallImage thumbUrl={thumbUrl} thumbType={thumbType} />
          <div className="event-card-text">
            <h6 className="event-card-title">{title}</h6>
            {(hasDate || hasView) && (
              <div class="event-details-view-date">
                {hasView && <span class="event-view-text">{views}</span>}
                {hasDate && (
                  <span class="event-uploded-date">{uplodedDate}</span>
                )}
              </div>
            )}
            {hasEventType && <p className="event-type-text">{eventType}</p>}
          </div>
        </Link>
        {hasDotMenu && (
          <Button
            variant="ghost"
            extraClass="event-dot-btn ml-auto align-self-start"
            size={"small"}
            handleClick={() => {}}
            hasText={false}
            elementIcon={<DotsIcon />}
          />
        )}
      </div>
    </>
  );
};

export default EventSmallCard;
