import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader2 from "../../../../Images/loader2.gif";
import SearchAndSorting from '../../../AdminCommonComponent/TableComponent/SearchAndSorting';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import authServicePartner from '../../../../Services/auth.service.partner';
import { useLocation } from 'react-router-dom';
import Loader from "../../../../Images/loader.gif";
import imgdelete from "../../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import AlertComponent from "../../../../Hooks/AlertComponent";
import show from "../../../../Images/Show.svg";
import hide from "../../../../Images/Hide.svg";
import moment from 'moment';
import Services from "../../../../Services/auth.service";
import authService from '../../../../Services/auth.service';
const RelatedVideo = ({ tabInformation, partnername }) => {

  const [searchVideo, setSearchVideo] = useState('')
  const [existingModalAlert, setExistingModalAlert] = useState("");
  const [dataLoader, setDataLoader] = useState(false)
  const [searchTag, setSearchTag] = useState('')
  const [existingModalLoader, setExistingModalLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false)
  const [videoAlert, setVideoAlert] = useState('')
  const [allVideo, setAllVideo] = useState([])
  const [rows, setRows] = useState([])
  const [partnerVideos, setPartnerVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState([]);
  const [tagList, setTagList] = useState([])
  const [loader, setLoader] = useState(false)
  const [selectedTag, setSelectedTag] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [videoId, setVideoId] = useState('')
  const location = useLocation().search
  const id = new URLSearchParams(location).get("id")
  const [videoErr, setVideoErr] = useState('')
  const [inProcess, setInProcess] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [selectedFilter, setSelectedFilter] = useState('latest')
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [clearSearch, setClearSearch] = useState(false)

  const columns = [
    { id: "drag", label: "" },
    { id: "title", label: "Title" },
    { id: "views", label: "Views" },
    { id: "comments", label: "Comments" },
    { id: "likes", label: "Likes" },
    { id: "updatedDate", label: "Uploaded Date" },
    { id: "status", label: "Status" },
    { id: "contentRef", label: "Edit Video" },
    { id: "_id", label: "Actions" },
  ]

  useEffect(() => {
    setLoader(true)
    setDataLoader(true)
    handleClearAllStates()
    getListByPartnerApi()
    getTagListApi()
    getAllVideoListApi()
    getallusersSuggestionListApi()
  }, [tabInformation])

  //function for soting video
  function createVideoData(_id, title, views, comments, likes, updatedDate, status) {
    return {
      _id, title, views, comments, likes, updatedDate, status
    }
  }
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getVideoListByPartnerSuggestionList()
      let data = getSuggestionRes.data.data
      var arr = []
      if (data && data.length !== undefined && data.length !== null && data.length > 0) {
        data.map((item) => arr.push({ name: item.title }))
        setSuggestionList(arr);
      }
    } catch (err) {

    }
  }
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex]
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText
        setSearchText(text);
      }
    }
  }, [listIndex]);

  // video list by partner 
  async function getListByPartnerApi() {
    try {
      const videoListRes = await authServicePartner.getVideoByPartner(id)
      if (videoListRes.data.status) {
        setSelectedFilter(videoListRes.data.data.relatedVideoSortOption)
        var arr = videoListRes.data.data.videoIds
        if (arr.length > 0) {
          var newArr = []
          for (let i = 0; i < arr.length; i++) {
            newArr[i] = createVideoData(
              arr[i]._id,
              arr[i].title,
              arr[i].views,
              arr[i].comments,
              arr[i].likes,
              moment(arr[i].createdAt).format('MMM DD, YYYY'),
              arr[i].status
            )
          }
          setRows(newArr)
          setPartnerVideos(newArr)
        } else {
          setRows([])
          setPartnerVideos([])
        }
        setSelectedVideo(videoListRes.data.data.videoIds)

        setLoader(false)
      } else {
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      return false
    }
  }

  //all tag list api function
  async function getTagListApi() {
    try {
      const alltagRes = await Services.getalltags_byadmin("")
      if (alltagRes.data.status) {
        setDataLoader(false)
        setTagList(alltagRes.data.data)
      }
    } catch (error) {
      return false
    }
  }

  //all video list api function
  async function getAllVideoListApi() {
    try {
      const allVideoRes = await authServicePartner.getAllVideoList()
      if (allVideoRes.data.status) {
        setDataLoader(false)
        setAllVideo(allVideoRes.data.data)
      }
    } catch (error) {
      return false
    }
  }

  // create object from array for filteration
  const createObjectFromArray = (arr, updateAllEventArray = true) => {
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createVideoData(
        arr[i]._id,
        arr[i].title,
        arr[i].views,
        arr[i].comments,
        arr[i].likes,
        arr[i].updatedDate,
        arr[i].status
      )
    }
    setRows(tbl);
    if (updateAllEventArray) setPartnerVideos(tbl);
  }

  //handle partner selection without event
  const handlePartnerSelect = (user) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    const updatedSelection = selectedVideo.includes(user)
      ? selectedVideo.filter((selectedVideo) => selectedVideo !== user)
      : [...selectedVideo, user];
    setSelectedVideo(updatedSelection);
  };

  //handle tag selection without event
  const handletagSelect = (user) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    const updatedSelection = selectedTag.includes(user)
      ? selectedTag.filter((tag) => tag !== user)
      : [...selectedTag, user];
    setSelectedTag(updatedSelection);
  };

  // handle add related video
  async function handleAddVideo() {
    if (selectedVideo.length > 0) {

      setExistingModalLoader(true)
      const videosIds = selectedVideo.map((item) => item._id)
      const bodyData = {
        "partnersId": id,
        "videoIds": videosIds,
        "sortingOption": rows.length === 0 ? "latest" : selectedFilter
      }
      rows.length === 0 ? setSelectedFilter("latest") : setSelectedFilter(selectedFilter)
      try {
        const addRelatedVideoRes = await authServicePartner.addRelatedvideo(bodyData)
        if (addRelatedVideoRes.data.status) {

          setExistingModalLoader(false)
          setExistingModalAlert(addRelatedVideoRes.data.message)
          getAllVideoListApi()
          getListByPartnerApi()
          setSelectedVideo([])
          setTimeout(() => {
            setExistingModalAlert('')
          }, 3000)
          handleClearAllStates()
        } else {
          setExistingModalLoader(false)
          setExistingModalAlert(addRelatedVideoRes.data.message)
          handleClearAllStates()
        }
      } catch (error) {

      }
    } else {
      setVideoErr('Please select video')
    }
  }

  // handle add related video by tag
  async function handleAddVideoByTag() {
    if (selectedTag.length > 0) {
      setVideoErr("")
      setExistingModalLoader(true)
      const tagIds = selectedTag.map((item) => item._id)
      const bodyData = {
        "partnersId": id,
        "tagIds": tagIds,
        "sortingOption": rows.length === 0 ? "latest" : selectedFilter
      }
      rows.length === 0 ? setSelectedFilter("latest") : setSelectedFilter(selectedFilter)
      try {
        const addRelatedVideoRes = await authServicePartner.addVideoByTag(bodyData)
        if (addRelatedVideoRes.data.status) {
          setExistingModalLoader(false)
          setExistingModalAlert(addRelatedVideoRes.data.message)
          getAllVideoListApi()
          getListByPartnerApi()
          setSelectedTag([])
          setTimeout(() => {
            setExistingModalAlert('')
          }, 3000)
          handleClearAllStates()
        } else {
          setExistingModalLoader(false)
          setExistingModalAlert(addRelatedVideoRes.data.message)
          handleClearAllStates()
        }
      } catch (error) {

      }
    } else {
      setVideoErr('Please select tag')
    }
  }

  async function handleRemoveVideo() {

    setInProcess(true)
    let query = `${id}?videoId=${videoId}`
    try {
      const deleteVideoRes = await authServicePartner.deleteVideo(query)
      if (deleteVideoRes.data.status) {
        setInProcess(false)
        document.querySelector('#DeleteVideo .close').click()
        getAllVideoListApi()
        getListByPartnerApi()
        setVideoAlert(deleteVideoRes.data.message)
        setShowAlert(true)
        setTimeout(() => {
          setVideoAlert('')
          setShowAlert(false)
        }, 3000)
        handleClearAllStates()
      } else {
        setInProcess(false)
        document.querySelector('#DeleteVideo .close').click()
        setVideoAlert(deleteVideoRes.data.message)
        setShowAlert(true)
        setTimeout(() => {
          setVideoAlert('')
          setShowAlert(false)
        }, 3000)
        handleClearAllStates()
      }
    } catch (error) {
      document.querySelector('#DeleteVideo .close').click()
      setInProcess(false)
      return false
    }
  }
  //sorting function
  const handleSort = (col, direc) => {
    var sortedResult = partnerVideos;
    if (direc === "asc") {
      sortedResult.sort(function (firstItem, secondItem) {
        var itemA = firstItem[col];
        var itemB = secondItem[col];
        return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
      });
    } else if (direc === "desc") {
      sortedResult.sort(function (firstItem, secondItem) {
        var itemA = firstItem[col];
        var itemB = secondItem[col];
        return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
      });
    }
    var table = [];

    for (var i = 0; i < sortedResult.length; i++) {
      table[i] = createVideoData(
        sortedResult[i]._id,
        sortedResult[i].title,
        sortedResult[i].views,
        sortedResult[i].comments,
        sortedResult[i].likes,
        sortedResult[i].updatedDate,
        sortedResult[i].status
      )
    }
    setRows(table);
  };
  //check for desable option for attendees
  const checkOptionselectionForUser = (video) => {
    var isChecked = false;
    for (let i = 0; i < partnerVideos.length; i++) {
      const videos = partnerVideos[i];
      if (videos._id === video._id) {
        isChecked = true;
        break;
      }
    }
    return isChecked;
  }

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($('#Addrelatedvideo').hasClass('show')) {
        $("#Addrelatedvideo").modal('show')
      }
    }
    else if (stayOnPage !== null && !stayOnPage) {
      if ($('#Addrelatedvideo').hasClass('show')) { $("#Addrelatedvideo").modal('hide') }
    }

  }, [stayOnPage])

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }

  const handleCloseAddModel = () => {
    handleClearAllStates()
  }

  //handle drag and reorder
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const updatedRows = Array.from(rows);
    const [movedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, movedRow);
    setRows(updatedRows);
    const selctedPartners = updatedRows.map((item) => item._id)
    const ids = {
      partnerId: id,
      videoIds: selctedPartners,
      videoSortingOption: "custom"
    }
    setSelectedFilter("custom")
    authServicePartner.reorderVideosInPartners(ids)
  }

  //handle status update api function
  async function updatePartnerVideoStatusApi(videoId, status) {
    setRows((prevData) =>
      prevData.map((item) => {
        if (item._id === videoId) {
          if (item.status === 'published') {
            return { ...item, status: 'hidden' };
          } else {
            return { ...item, status: 'published' };
          }
        }
        return item;
      })
    );
    let query = `${id}?videoid=${videoId}&status=${status}`
    try {
      const updateRes = await authServicePartner.updateRelatedVideoStatus(query)
      if (updateRes.data.status) {
        // getAllPartnerApi(page + 1, rowsPerPage)
      }
    } catch (error) {
      return false
    }
  }

  const handleFilterChange = async (e) => {
    const selctedPartners = rows.map((item) => item._id)
    const ids = {
      partnerId: id,
      videoIds: selctedPartners,
      videoSortingOption: e.target.value !== "" ? e.target.value : "latest"
    }
    setSelectedFilter(e.target.value !== "" ? e.target.value : "latest")
    setLoader(true)
    const reorderRes = await authServicePartner.reorderVideosInPartners(ids)
    if (reorderRes.data.status) {
      getListByPartnerApi()
    }
  }

  return (
    <>
      <div className="uic-title-rt-only">
        <div className="uic-fl-btn mrgn-rgt">
          <Tooltip title='add related video through tags automatically' arrow>
            <button
              className="btn"
              data-toggle="modal"
              data-target="#Addtagrelatedvideo"
            // onClick={handleCloseAddModel}
            >
              Add Video Via Tags
            </button>
          </Tooltip>
        </div>
        <div className="uic-fl-btn">
          <button
            className="btn"
            data-toggle="modal"
            data-target="#Addrelatedvideo"
          // onClick={handleCloseAddModel}
          >
            Add Related Video
          </button>
        </div>
      </div>

      <div className='cstm-add-related-video'>
        <SearchAndSorting currentArray={partnerVideos} placeholderText={"Search related video"}
          createObjectFromArray={createObjectFromArray} sortingOn='title' searchOn={["title"]}
          listIndex={listIndex}
          suggestionList={suggestionList}
          setSuggestionBox={setSuggestionBox}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          suggestionBox={suggestionBox}
          setListIndex={setListIndex}
          setClearSearch={setClearSearch}
          clearSearch={clearSearch}
        />
        <div className="uic-fl-btn sort-by-mn">
          <div className="sort-by-mn-title">Sort by : </div>
          <div className='cstm-select-ap' >
            <select value={selectedFilter} onChange={handleFilterChange}>
              <option value='latest'>Select sorting</option>
              <option value='views'>Views</option>
              <option value='comments'>Comments</option>
              <option value='likes'>Likes</option>
              <option value='default'>Uploaded Date</option>
              <option value='custom'>Custom</option>
            </select>
          </div>
        </div>
      </div>

      {showAlert && <div className="alert alert-info">{videoAlert}</div>}
      <Paper className="uic-tb-mn">
        <DragDropContext onDragEnd={onDragEnd}>
          <TableContainer className="uic-table-main">
            <Table className="uic-table">
              <TableHead className="uic-table-th">
                <TableRow>
                  {columns.map((col) => (
                    <TableCell
                      key={col.id}
                      align={col.align}
                      className="uic-th-td "
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <Droppable droppableId="table">
                {(provided) => (
                  <TableBody ref={provided.innerRef} {...provided.droppableProps} className="uic-table-td">
                    {loader ?
                      <div className="cstm-data-load"><img src={Loader} /> </div>
                      :
                      rows.length > 0 ?
                        rows.map((row, index) => {
                          return <Draggable key={row['_id']} draggableId={row['_id']} index={index}>
                            {(provided) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                className="uic-th-td "
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {columns.map((col, i) => {
                                  const value = row[col.id]
                                  return col.id === 'title' ?
                                    <TableCell className="uic-th-td  uic-table-icon">{value}</TableCell> :
                                    col.id === "views" ? <TableCell className="uic-th-td w-9">{value}</TableCell> :
                                      col.id === "comments" ? <TableCell className="uic-th-td w-9">{value}</TableCell> :
                                        col.id === "likes" ? <TableCell className="uic-th-td w-9">{value}</TableCell> :
                                          col.id === 'updatedDate' ? <TableCell className="uic-th-td w-10">{value}</TableCell> :
                                            col.id === 'status' ? <TableCell className="uic-th-td w-13">
                                              <>
                                                <div className='partner-status-select'>
                                                  {value === 'published' ? <img src={show} /> : <img src={hide} />}
                                                  <select value={value} className="status-select-only" onChange={(e) => updatePartnerVideoStatusApi(row._id, e.target.value)}>
                                                    <option value="published">Published</option>
                                                    <option value="hidden">Hidden</option>
                                                  </select>
                                                </div>
                                              </>
                                            </TableCell> :
                                              col.id === 'drag' ? <TableCell className="uic-th-td w-4">
                                                <i class="fas fa-arrows-alt banner-drag-drop"></i>
                                              </TableCell> :
                                                col.id === "contentRef" ? <TableCell
                                                  className="uic-th-td w-8"
                                                >
                                                  <a
                                                    href={`/contentlibrary/edit/${row._id}/0`}
                                                    target="_blank"
                                                    className="edit-content-link"
                                                  >
                                                    Edit Video
                                                  </a>
                                                </TableCell>
                                                  : <TableCell className="uic-th-td w-7 uic-table-icon">
                                                    <a
                                                      data-toggle="modal"
                                                      data-target="#DeleteVideo"
                                                      onClick={() => setVideoId(row._id)}
                                                    >
                                                      <Tooltip title='Delete' arrow><img src={imgdelete} /></Tooltip>
                                                    </a>
                                                  </TableCell>
                                })}
                              </TableRow>
                            )}
                          </Draggable>
                        }) :
                        <span className="no-data-found">No data found</span>
                    }
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>
        </DragDropContext>
      </Paper>
      {/* Related video add modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="Addrelatedvideo"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      > <div className="modal-dialog" role="document">
          {!dataLoader ?
            <>
              <div className="modal-content">
                <div className="edit-user-details-header">
                  <h2>Add Related Video</h2>
                  <button
                    type="button"
                    className="close"
                    //data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                      if (modalInProcess) {
                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                      } else {
                        $("#Addrelatedvideo").modal('hide')
                        handleCloseAddModel(e)
                      }

                    }}

                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {existingModalAlert && (
                    <div className="alert alert-info">{existingModalAlert}</div>
                  )}
                  <div className="form-group margin-bottom-0">
                    <div className="form-group-ct">
                      <div className="row">
                        <div className="col-sm-12 form-group">
                          <div className="cstm-channel-type-title">
                            Select Videos*
                          </div>
                          <div className="existing-attendee-search">
                            <input
                              type="text"
                              value={searchVideo}
                              placeholder="Search Video"
                              onChange={(e) => {
                                setSearchVideo(e.target.value);
                              }}
                            />
                          </div>
                          <div className="select-attendees-box">
                            {allVideo.filter((video) => {
                              const searchLower = searchVideo.toLowerCase();
                              const titleLower = video.title.toLowerCase();
                              const descriptionLower = video.description.toLowerCase();
                              return (
                                titleLower.includes(searchLower) || descriptionLower.includes(searchLower)
                              );
                            }).map((part) => {
                              var disable = true;
                              var isChecked = checkOptionselectionForUser(part);
                              if (!isChecked) {
                                isChecked = selectedVideo.filter((u) => {
                                  return u._id === part._id;
                                }).length > 0;
                                disable = false;
                              }
                              return <label
                                className="select-participants-box-in"
                                key={part._id}
                                style={{
                                  backgroundColor: selectedVideo.includes(
                                    part
                                  )
                                    ? "#EBE8FA"
                                    : "",
                                }}
                              >
                                <div className="select-participants-box-in-name">
                                  <p className="select-pt-title">
                                    {part.title}
                                  </p>
                                </div>
                                <div className="select-participants-box-in-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={isChecked}
                                    disabled={disable}
                                    onChange={() => {
                                      handlePartnerSelect(part);
                                      setVideoErr('')
                                    }}
                                  />
                                </div>
                              </label>
                            }

                            )}
                          </div>
                          <span className="error">{videoErr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="edit-user-details-footer cstm-create-button">
                  {existingModalLoader ? (
                    <div className="loader-button1">
                      <button>In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn"
                      onClick={handleAddVideo}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </> :
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          }

        </div>
      </div>
      {/* relatedvideo add by tag */}
      <div
        className="modal fade edit-user-details-popup"
        id="Addtagrelatedvideo"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      > <div className="modal-dialog" role="document">
          {!dataLoader ?
            <>
              <div className="modal-content">
                <div className="edit-user-details-header">
                  <h2>Add related video through tags automatically</h2>
                  <button
                    type="button"
                    className="close"
                    //data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                      if (modalInProcess) {
                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                      } else {
                        $("#Addtagrelatedvideo").modal('hide')
                        handleCloseAddModel(e)
                      }

                    }}

                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {existingModalAlert && (
                    <div className="alert alert-info">{existingModalAlert}</div>
                  )}
                  <div className="form-group margin-bottom-0">
                    <div className="form-group-ct">
                      <div className="row">
                        <div className="col-sm-12 form-group">
                          <div className="cstm-channel-type-title">
                            Select tags*
                          </div>
                          <div className="existing-attendee-search">
                            <input
                              type="text"
                              value={searchTag}
                              placeholder="Search tag"
                              onChange={(e) => {
                                setSearchTag(e.target.value);
                              }}
                            />
                          </div>
                          <div className="select-attendees-box">
                            {tagList.filter((video) => {
                              const searchLower = searchTag.toLowerCase();
                              const titleLower = video.name.toLowerCase();
                              return (
                                titleLower.includes(searchLower)
                              );
                            }).map((part) => {
                              return <label
                                className="select-participants-box-in"
                                key={part._id}
                                style={{
                                  backgroundColor: selectedTag.includes(
                                    part
                                  )
                                    ? "#EBE8FA"
                                    : "",
                                }}
                              >
                                <div className="select-participants-box-in-name">
                                  <p className="select-pt-title">
                                    {part.name}
                                  </p>
                                </div>
                                <div className="select-participants-box-in-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={selectedTag.includes(part)}
                                    onChange={() => {
                                      handletagSelect(part);
                                      setVideoErr('')
                                    }}
                                  />
                                </div>
                              </label>
                            }

                            )}
                          </div>
                          <span className="error">{videoErr}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="edit-user-details-footer cstm-create-button">
                  {existingModalLoader ? (
                    <div className="loader-button1">
                      <button>In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn"
                      onClick={handleAddVideoByTag}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            </> :
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          }

        </div>
      </div>
      {/* delete modal */}
      <div
        className="modal fade msg-popup"
        id="DeleteVideo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgBigDelete} />
            </div>
            <h1>Are you sure you want to delete this related video from {partnername}?</h1>
            <div className="row">
              <div className="col-6">
                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <span className="msg-btn">
                    <button
                      type="button"
                      className="btn btn-red"
                      onClick={handleRemoveVideo}
                    >
                      Delete
                    </button>
                  </span>)}
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn2" data-dismiss="modal">
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RelatedVideo
