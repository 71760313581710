import React from 'react'
import PropTypes from "prop-types"
const VideoIcon = ({ height = 24, width = 24, iconColor = "#000", isFilled }) => {
    return (
        <>
            {!isFilled ?
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M2.014 15.88c.175 2.155 1.853 3.623 4.219 3.613l6.785.007c2.234-.092 3.86-1.96 3.86-4.157l1.976 1.637c1.22 1.009 3.132.132 3.134-1.492L22 8.373c.002-1.601-1.905-2.514-3.134-1.499L16.874 8.52c-.012-2.305-1.977-4.174-4.288-4.01-1.977-.004-6.593-.015-6.726-.006-2.121.092-3.957 1.896-3.856 4.083-.003 7.122.007 7.254.01 7.294Zm3.9-9.925c.19-.008 4.094.001 6.723.005 1.435-.113 2.695 1.213 2.803 2.56.007.226.002 4.055 0 6.823 0 1.4-1.06 2.648-2.472 2.706-.184.007-4.095-.002-6.735-.006-1.427.11-2.673-.915-2.782-2.255-.007-.23-.008-4.434-.008-7.234-.065-1.401 1.114-2.541 2.47-2.599Zm10.964 4.438v3.071l2.889 2.394a.48.48 0 0 0 .781-.373l.012-7.113a.48.48 0 0 0-.781-.373l-2.901 2.394Z"
                        clipRule="evenodd"
                    />
                </svg> :
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        d="M8.875 4H2.792c-.802 0-1.459.656-1.459 1.459v4.916c0 .802.657 1.459 1.459 1.459h6.083c.803 0 1.459-.657 1.459-1.46V5.46C10.334 4.642 9.678 4 8.875 4Zm4.464.904-2.276 1.313v3.385l2.29 1.313c.578.338 1.314-.107 1.314-.759V5.648c0-.672-.769-1.079-1.328-.744Z"
                    />
                </svg>
            }
        </>
    )
}
VideoIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool
}
export default VideoIcon
