import React from "react";
import PropTypes from "prop-types";
import EmojiIcon from "../../../../components/Icons/GeneralIcons/EmojiIcon";
import ReplyIcon from "../../../../components/Icons/GeneralIcons/ReplyIcon";
import DotsIcon from "../../../../components/Icons/GeneralIcons/DotsIcon";
import Button from "../../../../components/Button/Button";

const OutgoingMessageActions = ({
  handleDotsClick,
  handleReplyClick,
  handleEmojiClick,
  chatId,
}) => {
  return (
    <>
      <div className="flex items-center">
        <Button
          elementIcon={<DotsIcon />}
          handleClick={handleDotsClick}
          variant="ghost"
          size="extrasmall"
          hasText={false}
          label=""
          toolTipText="More"
        />

        <Button
          elementIcon={<ReplyIcon />}
          handleClick={handleReplyClick}
          variant="ghost"
          size="extrasmall"
          hasText={false}
          label=""
          toolTipText="Reply"
        />
        <Button
          elementIcon={<EmojiIcon />}
          handleClick={handleEmojiClick}
          variant="ghost"
          size="extrasmall"
          hasText={false}
          label=""
          toolTipText="Emoji"
        />
      </div>
    </>
  );
};

OutgoingMessageActions.propTypes = {
  handleReplyClick: PropTypes.func.isRequired,
  handleDotsClick: PropTypes.func.isRequired,
  handleEmojiClick: PropTypes.func,
  chatId: PropTypes.string.isRequired,
};

export default OutgoingMessageActions;
