// import { compose, createStore, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
import rootReducer from "./Reducers";

import { combineReducers } from "redux";
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));
// export default store;

import { configureStore } from "@reduxjs/toolkit";

import createApiInstance from "./Services/createApiInstance";

const store = configureStore({
  reducer: combineReducers({
    ...rootReducer,
    [createApiInstance.reducerPath]: createApiInstance.reducer,
  }),
  //   devTools: process.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      createApiInstance.middleware,
    ]),
});

export default store;
