import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// services
import authServiceEvent from "../../../Services/auth.service.event";

// images
import Loader from "../../../Images/loader.gif";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import editFill from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import { useSelector } from "react-redux";

const CreateCategory = ({ handleClearAllStates, listingApi }) => {
  const dispatch = useDispatch();
  const [addCategory, setAddCategory] = useState("");
  const [addErr, setAddErr] = useState("");
  const [addSubCatErr, setAddSubCatErr] = useState("");
  const [addSubCatInputErr, setAddSubCatInputErr] = useState("");
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [selectedSubCatVal, setSelectedSubCatVal] = useState("");
  const [selecetdSubCatId, setSelecetdSubCatId] = useState("");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [addLoader, setAddLoader] = useState(false);
  const [createInprocess, setCreateInprocess] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  //called when add input change
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setAddCategory(e.target.value);
    if (e.target.value.length > 0) {
      setAddErr("");
    } else {
      setAddErr("Enter category! ");
    }
  };

  // Called on click on add button when addcategory popup open
  const handleAddSubCategory = async (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const subCatVal = document.getElementById("newSubCatValue").value.trim();
    if (subCatVal.length > 0) {
      setAddLoader(true);
      if (!allSubCategory.some(item=>item.toLowerCase() === subCatVal.toLowerCase())) {
        const isSubCategoryExists =
          await authServiceEvent.checkAlredayExistSubCategory({
            name: subCatVal.trim(),
          });
        if (!isSubCategoryExists.data.status) {
          setAddLoader(false);
          setAddSubCatErr(isSubCategoryExists.data.message);
        } else {
          setAllSubCategory([...allSubCategory, subCatVal]);
          document.getElementById("newSubCatValue").value = "";
          setAddSubCatErr("");
          setAddLoader(false);
        }
      } else {
        setAddSubCatErr(
          `Subcategory "${subCatVal}" already exists, subcategory must be unique!`
        );
        setAddLoader(false);
      }
    }
  };

  //called when category create
  const handleCreate = () => {
    if (
      addCategory.length > 0 &&
      addSubCatErr.length === 0 &&
      addSubCatInputErr.length === 0
    ) {
      setCreateInprocess(true);
      const addBodyData = {
        name: addCategory,
        subcategory: allSubCategory,
      };
      authServiceEvent
        .addCategory(addBodyData)
        .then((res) => {
          if (res.status) {
            setCreateInprocess(false);
            listingApi();
            setAllSubCategory([]);
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.message);
            handleClearAllStates();
            setAddCategory("");
            setSelectedSubCatVal("");
            setSelecetdSubCatId(-1);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
              document.querySelector("#addCategory .close").click();
            }, 3000);
            setAllSubCategory([]);
          } else {
            handleClearAllStates();
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.message);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
            }, 2000);
          }
        })
        .catch((e) => {
          setCreateInprocess(false);
          console.log(e);
        });
      setAddErr("");
    } else {
      addCategory.length === 0 && setAddErr("Enter category!");
    }
  };

    //stayonpage  popup for do you want to leave page
    useEffect(() => {
      if (stayOnPage !== null && stayOnPage) {
        if ($("#addCategory").hasClass("show")) {
          $("#addCategory").modal("show");
        }
       
      } else if (stayOnPage !== null && !stayOnPage) {
        if ($("#addCategory").hasClass("show")) {
          $("#addCategory").modal("hide");
        }
       
      }
    }, [stayOnPage]);

  //  Called when add category popup open and edit newly added subcategory
  const handleOnAddNewSubCategoryEditBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (addSubCatInputErr.length === 0 && addSubCatErr.length === 0) {
      document.getElementById("newSubCatValue").value = "";
      setSelecetdSubCatId(index);
      setSelectedSubCatVal(subcat);
    }
  };

  //  Called when add category popup open and change newly added subcategory
  const handleOnAddNewSubCategoryInputChange = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSelectedSubCatVal(e.target.value.trim());
    let subCatsArr = [...allSubCategory];
    subCatsArr[index] = e.target.value;
    setAllSubCategory(subCatsArr);
    setAddSubCatInputErr("");
    setAddSubCatErr("");
  };

  //  Called when add category popup open and update newly added subcategory
  const handleOnAddNewSubCategoryUpdateBtnEvent = async (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (selectedSubCatVal.length > 0) {
      if (
        allSubCategory.filter(
          (item, i) => item.toLowerCase().trim() === selectedSubCatVal.toLowerCase() && i !== index
        ).length > 0
      ) {
        //if ((allSubCategory[index] !== editedSubCategoryForAdd[index]) && editedSubCategoryForAdd.filter((item, i) => item === allSubCategory[index] && i !== index).length > 0) {
        setAddSubCatInputErr(
          `Subcategory "${selectedSubCatVal}" already exists. Subcategory must be unique!`
        );
      } else {
        const isSubCategoryExists =
          await authServiceEvent.checkAlredayExistSubCategory({
            name: selectedSubCatVal,
          });
        if (!isSubCategoryExists.data.status) {
          setAddSubCatInputErr(isSubCategoryExists.data.message);
        } else {
          let subCatsArr = [...allSubCategory];
          subCatsArr[index] = selectedSubCatVal;
          setAllSubCategory(subCatsArr);
          setAddSubCatInputErr("");
          setSelecetdSubCatId(-1);
          setSelectedSubCatVal("");
        }
      }
    } else {
      if (selecetdSubCatId !== -1) {
        setAddSubCatInputErr(`Enter subcategory!`);
      }
    }
  };

  //  Called when add category popup open and delete newly added subcategory
  const handleOnAddNewSubCategoryDeleteBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (addSubCatInputErr.length === 0 && addSubCatErr.length === 0) {
      setAllSubCategory([
        ...allSubCategory.filter((cat, i) => {
          if (i !== index) return cat;
        }),
      ]);
    }
  };
  return (
    <div
      className="modal fade edit-user-details-popup"
      id="addCategory"
      tabIndex="-1"
      data-backdrop="static"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="edit-user-details-header">
            <h2>Add Category</h2>
            <button
              type="button"
              className="close"
              //data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                if (modalInProcess) {
                  dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                } else {
                  $("#addCategory").modal("hide");
                  //handleCloseAddModel(e)
                }
              }}
              // onClick={handleCloseAddModel}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {showAlertAdd && (
              <div className="alert alert-info">{alertMsgAdd}</div>
            )}
            <div className="form-group">
              <div className="form-group-label">Category name*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  placeholder="Name your category"
                  name="category"
                  onChange={handleAddChange}
                  value={addCategory}
                />
              </div>
              <span className="error">{addErr}</span>
            </div>
            <h5 className="mb-1">Subcategory name</h5>
            <div className="form-group">
              <div className="form-group-ct ">
                <div
                  className="form-group doc-addsubcat-container-main"
                  id="newSubCat"
                >
                  <input
                    type="text"
                    id="newSubCatValue"
                    disabled={addSubCatInputErr.length > 0}
                    onChange={(e) => {
                      setSelectedSubCatVal("");
                      setSelecetdSubCatId(-1);
                      setAddSubCatErr("");
                    }}
                    onKeyUp={(e) => {
                      setSelectedSubCatVal("");
                      setSelecetdSubCatId(-1);
                      e.key === "Enter" && handleAddSubCategory(e);
                    }}
                  />
                  <div className="cstm-subcategory-loader">
                    {addLoader && (
                      <span className="cstm-loadind-add">
                        {" "}
                        <>
                          <img src={Loader} />{" "}
                        </>{" "}
                      </span>
                    )}
                    <button
                      type="button"
                      onClick={handleAddSubCategory}
                      className="btn"
                      disabled={addSubCatInputErr.length > 0}
                    >
                      Add
                    </button>
                  </div>
                  <span className="error-saubcategor-name">{addSubCatErr}</span>
                </div>
              </div>

              <div className="form-group doc-addedsubcat-container">
                {allSubCategory.map((subcat, index) => {
                  return (
                    <>
                      <div
                        className="form-group-ct doc-subcat-subcontainer"
                        onDoubleClick={(e) =>
                          handleOnAddNewSubCategoryEditBtnEvent(
                            e,
                            subcat,
                            index
                          )
                        }
                      >
                        <input
                          type="text"
                          name={index}
                          value={subcat}
                          disabled={selecetdSubCatId !== index}
                          onChange={(e) => {
                            handleOnAddNewSubCategoryInputChange(
                              e,
                              subcat,
                              index
                            );
                          }}
                          onKeyUp={(e) => {
                            if (e.key === "Enter") {
                              handleOnAddNewSubCategoryUpdateBtnEvent(
                                e,
                                subcat,
                                index
                              );
                            }
                          }}
                          onBlur={(e) =>
                            handleOnAddNewSubCategoryUpdateBtnEvent(
                              e,
                              subcat,
                              index
                            )
                          }
                        />

                        {selecetdSubCatId !== index && (
                          <>
                            <div className="doc-subcat-subcontainer-icon">
                              <img
                                src={editFill}
                                onClick={(e) => {
                                  handleOnAddNewSubCategoryEditBtnEvent(
                                    e,
                                    subcat,
                                    index
                                  );
                                }}
                              />
                            </div>

                            <div className="doc-subcat-subcontainer-icon">
                              <img
                                onClick={(e) => {
                                  handleOnAddNewSubCategoryDeleteBtnEvent(
                                    e,
                                    subcat,
                                    index
                                  );
                                }}
                                src={imgDelete}
                              />{" "}
                            </div>
                          </>
                        )}

                        {selecetdSubCatId === index &&
                          addSubCatInputErr !== "" && (
                            <span className="error-doc-addedsucat">
                              {addSubCatInputErr}
                            </span>
                          )}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="edit-user-details-footer cstm-create-button">
            {createInprocess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button
                type="button"
                className="btn"
                onClick={handleCreate}
                disabled={createInprocess}
              >
                Add category
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCategory;
