import React from "react";
import "../Styles/ChatDetailsMedia.css";
import PropTypes from "prop-types";
import "../../../../Styles/Theme/Theme.css";
import PlayIcon from "../../../../components/Icons/VideoPlayerIcons/PlayIcon";

const ChatDetailsMedia = ({
  isVideo,
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  aspectRatio = "1:1",
  onClick, // Receive onClick as a prop
}) => {
  return (
    <div className="chat-details-media" onClick={onClick}>
      {isVideo ? (
        <>
          <img src={src} className="chat-details-media-img" />
          <span className="chat-details-media-play-icon">
            <PlayIcon height={24} iconColor="#FFF" width={24} />
          </span>
        </>
      ) : (
        <img src={src} className="chat-details-media-img" />
      )}
    </div>
  );
};

ChatDetailsMedia.propTypes = {
  isVideo: PropTypes.bool,
  src: PropTypes.string,
  aspectRatio: PropTypes.string,
  onClick: PropTypes.func, // Define onClick as a function prop
};

export default ChatDetailsMedia;
