import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material'
import editIcon from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import authServiceEvent from '../../../Services/auth.service.event';
import imgDelete from "../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import AlertComponent from "../../../Hooks/AlertComponent";
import authService from '../../../Services/auth.service';
import ModalPopup from '../../ModalPopup/ModalPopup'

const EventType = () => {
  const emptyObject = {
    name: ""
  }
  const [showAlert, setShowAlert] = useState(false)
  const [allEventTypes, setAllEventTypes] = useState([])
  const [addEventType, setAddEventType] = useState(emptyObject)
  const [editEventType, setEditEventType] = useState(emptyObject)
  const [rows, setRows] = useState([])
  const [addErr, setAddErr] = useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [selectedId, setselectedId] = useState("");
  const [createInprocess, setcreateInprocess] = useState(false);
  const [editInprocess, seteditInprocess] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertAdd, setShowalertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [showAlertEdit, setShowalertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [actualsearch, setActualsearch] = useState("");
  const [inProcess, setInProcess] = useState(false)
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteName, setDeleteName] = useState("")
  useEffect(() => {

    setLoader(true)
    getAllEventTypes()
    handleClearAllStates()
    getallusersSuggestionListApi()
  }, [])

  //datastructure for inserting data into event typelist-table
  function createEventTypeData(name, action, countEventData) {
    return {
      name, action, countEventData
    }
  }

  //columns for table
  const columns = [
    { id: "name", label: "Name", w: 17 },
    { id: "countEventData", label: "No. of events", w: 17 },
    { id: "_id", label: "Actions", w: 6 },
  ];
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex]
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText
        setSearchText(text);
      }
    }
  }, [listIndex]);
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getAllEventTypeSuggestionList()
      console.log(getSuggestionRes, "getSuggestionRes");
      let data = getSuggestionRes.data.data
      var arr = []
      if (data && data.length !== undefined && data.length !== null && data.length > 0) {
        data.map((item) => arr.push({ name: item.name }))
        console.log(arr);
        setSuggestionList(arr);
      }
    } catch (err) {

    }
  }

  //get event type api call
  async function getAllEventTypes(col, sort) {
    let query = ''
    query += col !== undefined && col !== null && col !== "" ? `?sortField=${col}` : ""
    query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
    try {
      const getEventTypesRes = await authServiceEvent.getEventTypes(query)
      if (getEventTypesRes.status) {
        var arr = getEventTypesRes.data.data.length > 0 ? getEventTypesRes.data.data : []
        if (arr.length > 0) {
          var newArr = []
          for (let i = 0; i < arr.length; i++) {
            newArr[i] = createEventTypeData(

              arr[i].name,
              arr[i]._id,
              arr[i].countEventData
            )
          }
          setAllEventTypes(newArr)
          setRows(newArr)
        } else {
          setAllEventTypes(arr)
          setRows(arr)
        }
        setLoader(false)
      }
    } catch (error) {
      return false
    }
  }

  //close create eventype module
  const handleCloseAddModel = (e) => {
    setEditEventType(emptyObject);
    setEditErr(emptyObject);
    setAddEventType(emptyObject);
    setAddErr(emptyObject);
    handleClearAllStates()
  };

  //for pagination
  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    setPage(0);
  };

  //called when edit button is clicked
  const handleEdit = (value) => {
    setselectedId(value);
    setEditModalLoader(true);
    authServiceEvent.getEventTypeDetail(value)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setEditEventType({
            name: data.name,
          });
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
      });
  };

  //deletion event type api call
  const handleDelete = () => {
    setInProcess(true)
    authServiceEvent.deleteEventType(selectedId)
      .then((res) => {
        if (res.data.status) {
          getAllEventTypes()
          setShowDeleteModal(false)
        }
        setInProcess(false)
        setShowAlert(true);
        setAlertMsg(res.data.message);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 3000);
      })
      .catch((e) => {
        setInProcess(false)
        console.log(e);
      });
  };

  //handle onchange for create package
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setAddEventType({ ...addEventType, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.length > 0 || e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    }
  };

  //Create event type validation and api call
  const handleCreate = () => {
    var status = true;
    var tempObj = {};

    if (addEventType.name.length === 0 || addEventType.name.trim().length === 0) {

      tempObj = { ...tempObj, name: "Enter name!" };
      status = false;
    }


    if (status) {
      setcreateInprocess(true);
      createApiCall()

    } else {
      if (Object.keys(tempObj).length > 0) {
        const emptyKeys = Object.keys(tempObj).filter((key) => tempObj[key] !== "");
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      setAddErr(tempObj);
    }
  };

  //Api call on create event type
  const createApiCall = () => {
    authServiceEvent.createEventType({
      name: addEventType.name,

    })
      .then((res) => {
        setcreateInprocess(false);
        if (res.data.status) {
          setShowalertAdd(true);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowalertAdd(false);
            setAlertMsgAdd("");
          }, 3000);
          getAllEventTypes();
          handleClearAllStates()
        } else {
          setShowalertAdd(true);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowalertAdd(false);
            setAlertMsgAdd("");

          }, 3000);
          handleClearAllStates()
        }

        setAddEventType(emptyObject);
      })
      .catch((e) => {
        setcreateInprocess(false);
        console.log(e);
      });
    setAddErr(emptyObject);
  };
  console.log(suggestionList, "suggestions");

  //handle on change for edit modal
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setEditEventType({ ...editEventType, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      } else {
        setEditErr({ ...editErr, [e.target.name]: "Enter name!" });
      }
    }
  };

  //Called when user click on update changes
  const handleEditSubmit = () => {
    var status = true;
    var temp = {};
    if (editEventType.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }

    if (status) {
      seteditInprocess(true);
      editApiCall()
    } else {
      setEditErr(temp);
    }
  };

  //editApiCall
  const editApiCall = () => {
    authServiceEvent.editEventType({
      id: selectedId,
      eventTypeData: {
        name: editEventType.name,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setSearchText("")
          setShowalertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowalertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
          getAllEventTypes();
          setEditEventType(emptyObject)
        } else {
          setShowalertEdit(true);
          setAlertMsgEdit(res.data.message);
          setEditEventType(emptyObject)
          setTimeout(() => {
            setShowalertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
        }
        handleClearAllStates()
        seteditInprocess(false);
      })
      .catch((e) => {
        console.log(e);
        seteditInprocess(false);
        handleClearAllStates()
      });
    setEditErr(emptyObject);
  };

  //search using text
  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    if (text.length > 0) {
      if (allEventTypes !== undefined && allEventTypes.length > 0) {
        var data = allEventTypes.filter((eventype) => {
          if (
            text.length > 0 &&
            eventype.name.toLowerCase().includes(text.toLowerCase())
          )
            return eventype;
        });
        var table = [];
        for (var i = 0; i < data.length; i++) {
          table[i] = createEventTypeData(
            data[i].name,
            data[i].action,
            data[i].countEventData
          );
        }
        setPage(0);
        setRows(table);
      }
    } else {
      setPage(0);
      setRows(allEventTypes);
    }
  };

  //sorting function
  const handleSort = (col, direc) => {
    setLoader(true)
    getAllEventTypes(col, direc)
  };

  //pop up state for do you want to leave....
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($('#addEventType').hasClass('show')) {
        $("#addEventType").modal('show')
      }

      if ($('#editEventType').hasClass('show')) {
        $("#editEventType").modal('show')
      }

    }
    else if (stayOnPage !== null && !stayOnPage) {
      if ($('#addEventType').hasClass('show')) {
        $("#addEventType").modal('hide')
        handleCloseAddModel()
      }

      if ($('#editEventType').hasClass('show')) {
        $("#editEventType").modal('hide')
        handleCloseAddModel()
      }

    }

  }, [stayOnPage])

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }

  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>Event types</h1>
            <div className="uic-fl-btn">
              <button
                className="btn"
                data-toggle="modal"
                click={handleCloseAddModel}
                data-target="#addEventType"
              >Add event type</button>
            </div>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                value={searchText}
                placeholder="Search"
                className="search-user-input"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (suggestionList !== undefined && suggestionList.length > 0) {
                    setListIndex(-1);
                    setSuggestionBox(true)
                    var searchResult = suggestionList.filter((user) => {
                      return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch(e.target.value)
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15
                        ? 15
                        : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15
                        ? 15
                        : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                <div className="suggestion-box">
                  <ul id="suggestion-list">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onMouseDown={() => handleSearch(suggestion.name)}
                        className={index === listIndex ? 'selected-active' : ''}
                      >
                        {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length)} </> : suggestion.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <i className="fa fa-search" onClick={() => handleSearch(searchText)}></i>


              <i className="fa fa-search"></i>
            </div>
          </div>
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table min-w-auto">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}

                        className={`uic-th-td w-${column.w}`}
                      >
                        {"_id" !== column.id ? (
                          <>
                            <span className="custom-sorting">
                              <i
                                className="fa fa-angle-down"
                                onClick={(e) => {
                                  handleSort(column.id, "Asc");
                                }}
                              ></i>
                              <i
                                className="fa fa-angle-up"
                                onClick={(e) => {
                                  handleSort(column.id, "Dec");
                                }}
                              ></i>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loader ? (
                  <div className="cstm-data-load">
                    <img src={Loader} />{" "}
                  </div>
                ) : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .length > 0 ? (
                  <TableBody className="uic-table-td">
                    {rows
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                            className="uic-th-td w-20"
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return column.id === "name" ? (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td w-17"
                                >
                                  <a data-toggle="modal"
                                    data-target="#editEventType"
                                    onClick={() => {
                                      handleEdit(row['action']);
                                    }} className="cstm-view-users-count"> {value}</a>
                                </TableCell>
                              ) : column.id === "countEventData" ? (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td w-17"
                                >
                                  {value !== 0 ? value : "-"}
                                </TableCell>
                              ) : (
                                <TableCell
                                  key={column.id}

                                  className="uic-th-td uic-table-icon w-6"
                                >
                                  <a
                                    data-toggle="modal"
                                    data-target="#editEventType"
                                    onClick={() => {
                                      handleEdit(row['action']);
                                    }}
                                  >
                                    <img src={editIcon} />
                                  </a>

                                  {row.countEventData === 0 && <a
                                    // data-toggle="modal"
                                    // data-target="#DeleteEventType"
                                    onClick={() => {
                                      setselectedId(row['action']);
                                      setShowDeleteModal(true)
                                      setDeleteName(row.name)
                                    }}
                                  >
                                    <img src={imgDelete} />
                                  </a>}

                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  <span className="no-data-found">No data found</span>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[10, 20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>

      {/* create event type */}
      <div
        className="modal fade edit-user-details-popup"
        id="addEventType"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Event Type</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {

                  if (modalInProcess) {

                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })



                  } else {
                    $("#addEventType").modal('hide')
                    handleCloseAddModel(e)
                  }

                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleAddChange}
                    value={addEventType.name}
                  />
                  <span className="error">{addErr.name}</span>
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editEventType"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Event Type</h2>
                <button
                  type="button"
                  className="close"
                  // data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {

                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })



                    } else {
                      $("#editEventType").modal('hide')
                      handleCloseAddModel(e)
                    }

                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      onChange={handleEditChange}
                      value={editEventType.name}
                    />
                    <span className="error">{editErr.name}</span>
                  </div>
                </div>

              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant="danger"
        buttonText1="Delete"
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete event type"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>Are you sure you want to delete this "{deleteName}"? This action cannot be undone.</p>
        </div>
      </ModalPopup>
      <AlertComponent />
    </>
  )
}

export default EventType