import React from 'react'
import ImgVideo from "./../../../Images/imgvideo.png"
import imgIcon from "./../../../Images/icn_m_pdf.svg"
import sponsorIcon from "./../../../Images/heyday-img.png"

const VideoThumbnailSmallImage = ({ thumbUrl = ImgVideo, thumbType = "img", hasDuration = false, duration = "1h 3m" }) => {
    return (
        <div className={`video-thumb-image img-${thumbType}`}>
            {thumbType === 'img' && <img src={thumbUrl} width={128} height={72} className='img-fluid' alt='video-thumb' />}
            {/* {thumbType === 'file' && <img src={thumbUrl.length > 0 ? thumbUrl : imgIcon} width={128} height={72} className='img-fluid file-icon' alt='video-thumb' />} */}
            {thumbType === 'file' && thumbUrl}
            {thumbType === 'sponsor' && <img src={thumbUrl.length > 0 ? thumbUrl : sponsorIcon} width={128} height={72} className='img-fluid sponsor-icon' alt='video-thumb' />}
            {thumbType === '' && <img src={thumbUrl} width={128} height={72} className='img-fluid sponsor-icon' alt='video-thumb' />}
            {hasDuration && <span className='video-duration'>{duration}</span>}
        </div>
    )
}

export default VideoThumbnailSmallImage