import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Services from "../../../Services/auth.service.event";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import editIcon from "../../../Images/BackendImages/edit-fill.svg";
import imgDelete from "../../../Images/BackendImages/delete-fill.svg";

import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import { Editor } from '@tinymce/tinymce-react';
import { stateToHTML } from "draft-js-export-html";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import AlertComponent from "../../../Hooks/AlertComponent";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import ModalPopup from '../../ModalPopup/ModalPopup'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
//Event Faq module
const Faqs = ({ tabInformation }) => {
  const descriptionRef = useRef(null);
  const editDescriptionRef = useRef(null);
  const emptyObject = {
    question: "",
    answer: "",
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [addEvent, setAddEvent] = useState(emptyObject);
  const [editEvent, setEditEvent] = useState(emptyObject);
  const [addErr, setAddErr] = useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [selectedId, setSelectedId] = useState("");
  const [createInprocess, setCreateInprocess] = useState(false);
  const [editInprocess, setEditInprocess] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [deleteFaqId, setDeleteFaqId] = useState("");
  const [allFaqs, setAllFaqs] = useState([]);
  const [showAlert, setShowAlert] = useState("");
  const [loader, setLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [editorStateCreateModalAnswer, setEditorStateCreateModalAnswer] =
    useState("");
  const [editorStateEditModalAnswer, setEditorStateEditModalAnswer] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //fetch all existing faq list
  useEffect(() => {
    if (tabInformation === "FAQs") {
      setLoader(true);
      getAllEventFaqsApi();
      // handleClearAllStates()
    }
  }, [tabInformation]);
  //get all sessions list
  const getAllEventFaqsApi = () => {
    Services.getAllFaqsByEventId(queryVariable)
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setAllFaqs(data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //close create session module
  const handleCloseAddModel = (e) => {
    setAddEvent(emptyObject);
    setEditorStateCreateModalAnswer("")
    setEditorStateEditModalAnswer("")
    setEditEvent(emptyObject);
    setAddErr(emptyObject);
    setEditErr(emptyObject);
    handleClearAllStates()
  };

  //called when edit button is clicked
  const handleEdit = (value) => {
    setSelectedId(value);
    setEditModalLoader(true);
    Services.getEventFaqById(value)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setEditEvent({
            question: data.question,
            answer: data.answer
          });
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };

  //deletion faq api call
  const handleDelete = () => {
    setDeleteLoader(true)
    Services.deleteEventFaq(deleteFaqId)
      .then((res) => {
        if (res.data.status) {
          setDeleteLoader(false)
          setAllFaqs(
            allFaqs.filter((faq) => {
              return deleteFaqId !== faq._id;
            })
          );
        }
        setShowAlert(res.data.message);
        setTimeout(() => {
          setShowAlert("");
        }, 3000);
        setDeleteLoader(false)
      })
      .catch((e) => {
        setDeleteLoader(false)
        console.log(e);
      });
  };
  //handle onchange for create faq
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    if (e.target.name === "question") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "answer") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    }
  };

  //validation and api call on create faq
  const handleCreate = async () => {
    var status = true;
    var temp = {};
    setEditorStateCreateModalAnswer(descriptionRef.current.getContent())
    if (addEvent.question.trim().length === 0) {
      temp = { ...temp, question: "Enter question!" };
      status = false;
    }
    if (!queryVariable) {
      setShowAlert("Invalid event id");
      status = false;
    }
    if (!checkDesciptionValue(editorStateCreateModalAnswer)) {
      temp = { ...temp, answer: "Enter an answer!" };
      status = false;
    }
    if (status) {
      setCreateInprocess(true);
      // const answerHtml = await handleDesciption(
      //   editorStateCreateModalAnswer
      // );
      createApiCall();
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      setAddErr(temp);
    }
  };

  //covert function dataURLtoFile
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], Math.random().toString(), { type: mime });
  };

  //handling editor state to html state and uploading images to server
  const handleDesciption = async (state) => {
    return new Promise((resolve, reject) => {
      const contentState = state.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      if (
        contentRaw?.entityMap &&
        Object.keys(contentRaw.entityMap).length > 0
      ) {
        var entityMap = contentRaw.entityMap;
        var formdata = new FormData();
        var uploadStatus = false;
        var keyList = [];

        for (var key in entityMap) {
          var data = entityMap[key];
          if (
            data.type === "IMAGE" &&
            data.data.src.startsWith("data:image/")
          ) {
            uploadStatus = true;
            var fileData = dataURLtoFile(data.data.src);
            formdata.append(`image`, fileData);
            keyList = [...keyList, key];
          }
        }

        if (uploadStatus) {
          Services.eventUploadFiles(formdata)
            .then((res) => {
              if (res.data.status) {
                var mediaData = res.data.media;
                mediaData.map((m, index) => {
                  contentRaw.entityMap[keyList[index]].data.src = m.key;
                });
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              } else {
                const htmlContent = draftToHtml(contentRaw);
                resolve(htmlContent);
              }
            })
            .catch((e) => {
              console.log(e);
              const htmlContent = draftToHtml(contentRaw);
              reject(htmlContent);
            });
        } else {
          const htmlContent = draftToHtml(contentRaw);
          resolve(htmlContent);
        }
      } else {
        const htmlContent = draftToHtml(contentRaw);
        resolve(htmlContent);
      }
    });
  };

  const handlePastedText = (text, editor, stateChangeFunctionCall) => {
    const contentState = editor.getCurrentContent();
    const selectionState = editor.getSelection();

    // Create a new modified content state with the pasted text
    const modifiedContentState = Modifier.replaceText(
      contentState,
      selectionState,
      text,
      editor
        .getCurrentInlineStyle()
        .filter((style) => style !== "backgroundColor")
    );

    // Create a new editor state with the modified content state
    const newEditorState = EditorState.push(
      editor,
      modifiedContentState,
      "insert-characters"
    );
    stateChangeFunctionCall(newEditorState);
  };
  //Api calll on create faq
  const createApiCall = (answerHtml) => {
    setCreateInprocess(true);
    Services.createEventFaq({
      question: addEvent.question,
      answer: descriptionRef.current.getContent(),
      event: queryVariable,
    })
      .then((res) => {
        setCreateInprocess(false);
        if (res.data.status) {
          setShowAlertAdd(true);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowAlertAdd(false);
            setAlertMsgAdd("");
          }, 3000);
          getAllEventFaqsApi();
        } else {
          setShowAlertAdd(true);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowAlertAdd(false);
            setAlertMsgAdd("");
          }, 3000);
        }
        handleClearAllStates()
        setEditorStateCreateModalAnswer("")
        setAddEvent(emptyObject);
      })
      .catch((e) => {
        handleClearAllStates()
        setCreateInprocess(false);
        console.log(e);
      });
    setAddErr(emptyObject);
  };
  //on change for Edit
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    if (e.target.value.trim().length > 0) {
      setEditErr({ ...editErr, [e.target.name]: "" });
    }
  };
  //Called when user click on update changes
  const handleEditSubmit = async () => {
    var status = true;
    var temp = {};
    if (editEvent.question.trim().length === 0) {
      temp = { ...temp, question: "Enter question!" };
      status = false;
    }
    if (!checkEditDesciptionValue(editorStateEditModalAnswer)) {
      temp = { ...temp, answer: "Enter an answer!" };
      status = false;
    }
    if (!queryVariable) {
      setShowAlert("Invalid event id");
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setEditInprocess(true);
      // const answerHtml = await handleDesciption(
      //   editorStateEditModalAnswer
      // );
      editApiCall();
    } else {
      setEditErr(temp);
    }
  };
  //Edit faq api call
  const editApiCall = () => {
    Services.editEventFaq({
      id: selectedId,
      data: {
        question: editEvent.question,
        answer: editDescriptionRef.current.getContent(),
        event: queryVariable,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
          getAllEventFaqsApi();
          setEditInprocess(false);
          setEditEvent(emptyObject)
        } else {
          setShowAlertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowAlertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
        }
        handleClearAllStates()
        setEditInprocess(false);
      })
      .catch((e) => {
        console.log(e);
        setEditInprocess(false);
      });
    setEditErr(emptyObject);
  };
  //Create session validation and api call
  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };
  //Create session validation and api call
  const checkEditDesciptionValue = (state) => {
    var text = editDescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  //handle upload image in editor
  const uploadImageCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  //html to editor state
  function htmlToEditor(html, setEditor) {
    const contentBlock = htmlToDraft(html.replace("<p>&nbsp;</p>", ""));
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditor(editorState);
  }

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($('#AddFaq').hasClass('show')) {
        $("#AddFaq").modal('show')
      }

      if ($('#editFaq').hasClass('show')) {
        $("#editFaq").modal('show')
      }

    }
    else if (stayOnPage !== null && !stayOnPage) {
      if ($('#AddFaq').hasClass('show')) { $("#AddFaq").modal('hide') }

      if ($('#editFaq').hasClass('show')) { $("#editFaq").modal('hide') }
    }

  }, [stayOnPage])

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }

  //reorder faqs
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFaqs = Array.from(allFaqs);
    const [movedItem] = reorderedFaqs.splice(result.source.index, 1);
    reorderedFaqs.splice(result.destination.index, 0, movedItem);

    // Update the state with the new order
    setAllFaqs(reorderedFaqs);
    const data = { ids: reorderedFaqs.map(r => r._id) }
    Services.reorderFaqs(data, queryVariable)

  };

  const deleteFaqName = (deleteId) => {
    for (let i = 0; i < allFaqs.length; i++) {
      if (allFaqs[i]._id === deleteId) {
        console.log(allFaqs[i]);
        return allFaqs[i].question;
      }
    }
  };

  return (
    <div>
      <div className="uic-title justify-content-end">
        <div className="uic-fl-btn">
          <button className="btn" data-toggle="modal" data-target="#AddFaq">
            Add FAQs
          </button>
        </div>
      </div>
      {showAlert && <div className="alert alert-info">{showAlert}</div>}
      {!loader ? (
        <div className="questions-accordian faq-container-only">
          <div id="accordion">
            {allFaqs.length > 0 ?
              (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="faqs">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {allFaqs.map((faq, index) => (
                          <Draggable key={faq._id} draggableId={faq._id} index={index}>
                            {(provided) => (
                              <div
                                data-id={index}
                                key={index}
                                data-position={index}
                                className="card dragdrop2"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div>
                                  <div className="questions-accordian-header">
                                    {" "}

                                    <div className="faq-drag-drop"><i class="fas fa-arrows-alt"></i></div>

                                    <a
                                      className="card-link collapsed"
                                      data-toggle="collapse"
                                      href={`#div${index}`}
                                    >
                                      {" "}
                                      {faq.question}{" "}
                                    </a>{" "}
                                    <div className="questions-accordian-edt-delete">
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#editFaq"
                                        onClick={() => {
                                          handleEdit(faq._id);
                                        }}
                                      >
                                        <img src={editIcon} />
                                      </a>
                                      <a
                                        data-toggle="modal"
                                        data-target="#DeleteFaq"
                                        onClick={() => {
                                          setDeleteFaqId(faq._id);
                                          setShowDeleteModal(true)
                                        }}
                                      >
                                        <img src={imgDelete} />
                                      </a>
                                    </div>
                                  </div>
                                  <div
                                    id={`div${index}`}
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="questions-box-incont">
                                      <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

              ) : (
                <span className="no-data-found position-relative top-0 start-0 ms-0 w-100 d-block text-center">No data found</span>
              )}
          </div>
        </div>
      ) : (
        <div className="cstm-upload-photo-event-loader">
          <img src={Loader} />
        </div>
      )}
      {/* add modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="AddFaq"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create FAQs</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                  } else {
                    $("#AddFaq").modal('hide')
                    handleCloseAddModel(e)
                  }

                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Question*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter question"
                    name="question"
                    onChange={handleAddChange}
                    value={addEvent.question}
                  />
                  <span className="error">{addErr.question}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Answer*</div>
                <div className="form-group-ct">
                  <div className="cstm-vd cstm-code-addt">
                    <TinyEditor
                      valueRef={descriptionRef}
                      value={editorStateCreateModalAnswer}
                      handleEditorChange={(value, editor) => {
                        setAddErr({ ...addErr, answer: "" })
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" })
                      }}
                    />
                  </div>
                  <span className="error">{addErr.answer}</span>
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editFaq"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit FAQs</h2>
                <button
                  type="button"
                  className="close"
                  // data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                    } else {
                      $("#editFaq").modal('hide')
                      handleCloseAddModel(e)
                    }

                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Question*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter question"
                      name="question"
                      onChange={handleEditChange}
                      value={editEvent.question}
                    />
                    <span className="error">{editErr.question}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Answer*</div>
                  <div className="form-group-ct">
                    <div className="cstm-vd cstm-code-addt">
                      <TinyEditor
                        value={editEvent.answer}
                        valueRef={editDescriptionRef}
                        handleEditorChange={(value, editor) => {
                          setEditErr({ ...editErr, answer: "" })
                        }}
                        handleKeyPress={() => {
                          dispatch({ type: "SETMODALINPROCESS" })
                        }}
                      />
                    </div>
                    <span className="error">{editErr.answer}</span>
                  </div>
                </div>
              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteLoader ? "danger" : 'secondary'}
        buttonText1={!deleteLoader ? "Delete" : <img src={Loader2} width={50} height={15} className="btn-loader-img-cover" />}
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete faq"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>Are you sure you want to delete this "{deleteFaqName(deleteFaqId)}"? This action cannot be undone.</p>
        </div>


      </ModalPopup>

      {/* <div
        className="modal fade msg-popup"
        id="DeleteFaq"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgBigDelete} />
            </div>
            <h1>Are you sure you want to delete this faq?</h1>
            <div className="row">
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={handleDelete}
                    data-dismiss="modal"
                  >
                    Delete
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn2" data-dismiss="modal">
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <AlertComponent />
    </div>
  );
};
export default Faqs;
