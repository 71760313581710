import React from 'react'
import PropTypes from "prop-types"
const ClockIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M20.5 12a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Zm1.5 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10Zm-6.953 3.588a.756.756 0 0 0 1.03-.261.752.752 0 0 0-.26-1.03l-3.406-2.03V7.846a.75.75 0 1 0-1.5 0v4.849c0 .263.14.508.366.644l3.77 2.25Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
ClockIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default ClockIcon