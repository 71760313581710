import React from "react";
import "../Styles/ChatFileCell.css";
import PropTypes from "prop-types";
import "../../../../Styles/Theme/Theme.css";
import Typography from "../../../../components/Typography/Typography";
import PdfIcon from "../../../../components/Icons/FileIcons/PdfIcon";

const ChatFileCell = ({
  hasText = true,
  name = "milliondollarsellers.com",
  title = "https://www.milliondollarsellers.com/",
  size = 100,
  isSize = false,
  onClick, // Receive onClick as a prop
}) => {
  return (
    <div className="chat-file-cell" onClick={onClick}>
      <div className="chat-file-cell-file">
        <PdfIcon height={32} width={32} />
      </div>
      <div className="chat-file-cell-text">
        <Typography variant="h5">{name}</Typography>
        {hasText ? <Typography variant="h6">{title}</Typography> : ""}
        {isSize ? <Typography variant="h6">{size}</Typography> : ""}
      </div>
    </div>
  );
};

ChatFileCell.propTypes = {
  hasText: PropTypes.bool,
  name: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.number,
  isSize: PropTypes.bool,
  onClick: PropTypes.func, // Define onClick as a function prop
};

export default ChatFileCell;
