import React, { useState } from "react";
import PropTypes from "prop-types";
import { TableProvider } from "./TableContext";
import TableCell from "./TableCell";
import arrowUp from "../../Images/icn_s_arrow_top.svg";
import arrowDown from "../../Images/icn_s_arrow_bottom.svg";
import "./Table.css";
import "../../Styles/Theme/Theme.css";
import Checkbox from "../Checkbox/Checkbox";
const TableHead = ({
  variant,
  state,
  children,
  hasSort,
  Sorted,
  hasCheckbox,
  checked,
  onChange,
  columns,
  handleSort,
  sortedColumn,
  setSortedColumn,
  sortOrder,
  setSortOrder
}) => {
  const [isSorted, setIsSorted] = useState(false);
  const [hoverColumn, setHoverColumn] = useState("");

  return (
    <>
      <thead>
        <TableProvider isHead={true}>
          <tr>
            {hasCheckbox && (
              <TableCell>
                <Checkbox />
              </TableCell>
            )}
            {columns.map((col) => (
              <TableCell width={col.width} Sorted={isSorted}>
                <div
                  onMouseEnter={() => {
                    setHoverColumn(col.id);
                  }}
                  onMouseLeave={() => {
                    if (
                      sortedColumn !==
                      (col.id === "name"
                        ? "fileName"
                        : col.id === "people"
                        ? "owner"
                        : "dateUploaded")
                    ) {
                      setHoverColumn(
                        sortedColumn === "dateUploaded"
                          ? "dateUploaded"
                          : sortedColumn === "fileName"
                          ? "name"
                          : sortedColumn === "viewsCounts"
                          ? ""
                          : "dateUploaded"
                      );
                    }
                  }}
                  className={`table-heading ${
                    col.id !== "category" ? "tableHeadSort" : "tableHeadNoSort"
                  }`}
                  onClick={(e) => {
                    if (col.id !== "category") {
                      let order = sortOrder;
                      if (
                        (col.id === "name"
                          ? "fileName"
                          : col.id === "people"
                          ? "owner"
                          : "dateUploaded") !== sortedColumn
                      ) {
                        order = sortOrder === "desc" ? "asc" : "desc";
                      }
                      setSortOrder(order === "desc" ? "asc" : "desc");
                      setSortedColumn(
                        col.id === "name"
                          ? "fileName"
                          : col.id === "people"
                          ? "owner"
                          : "dateUploaded"
                      );
                      handleSort(
                        e,
                        col.id === "name"
                          ? "fileName"
                          : col.id === "people"
                          ? "owner"
                          : "dateUploaded",
                        order === "desc" ? "Asc" : "Desc"
                      );
                    }
                  }}
                >
                  {col.label}{" "}
                  {(col.id ===
                    (hoverColumn === "dateUploaded"
                      ? "dateUploaded"
                      : hoverColumn) ||
                    (col.id === "name"
                      ? "fileName"
                      : col.id === "people"
                      ? "owner"
                      : "dateUploaded") === sortedColumn) &&
                  col.id !== "action" &&
                  col.id !== "category" &&
                  hasSort &&
                  sortOrder === "asc" ? (
                    <img src={arrowUp} />
                  ) : (
                    col.id !== "action" &&
                    col.id !== "category" &&
                    hasSort &&
                    ((sortOrder === "desc" &&
                      (col.id === "name"
                        ? "fileName"
                        : col.id === "people"
                        ? "owner"
                        : "dateUploaded") === sortedColumn) ||
                      col.id ===
                        (hoverColumn === "dateUploaded"
                          ? "dateUploaded"
                          : hoverColumn)) && <img src={arrowDown} />
                  )}
                </div>
              </TableCell>
            ))}
          </tr>
        </TableProvider>
      </thead>
    </>
  );
};
TableHead.propTypes = {
  label: PropTypes.string,
  hasSort: PropTypes.bool,
  Sorted: PropTypes.bool,
  hasHeading: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  checked: PropTypes.string,
  handleSort: PropTypes.func,
  columns: PropTypes.array,
  onChange: PropTypes.func,
  width: PropTypes.string,
  sortedColumn: PropTypes.string,
};
export default TableHead;
