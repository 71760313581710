import React from 'react'
import PropTypes from "prop-types"
const IconScamera = ({ width = 16, height = 16, isFilled = true, iconColor = "#394046" }) => {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 16 16"
            >
                <path
                    fill={iconColor}
                    d="M8.875 4H2.792c-.802 0-1.458.656-1.458 1.459v4.916c0 .802.656 1.459 1.458 1.459h6.083c.803 0 1.46-.657 1.46-1.46V5.46c0-.817-.657-1.459-1.46-1.459Zm4.464.904-2.275 1.313v3.385l2.29 1.313c.577.338 1.313-.107 1.313-.759V5.648c0-.672-.769-1.079-1.328-.744Z"
                />
            </svg>
        </>
    )
}
IconScamera.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isFilled: PropTypes.bool,
    iconColor: PropTypes.string,
}
export default IconScamera