import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import services from "../../Services/auth.service";
import Loader2 from "../../Images/loader2.gif";
import loading from "../../Images/loader.gif";
import Loader from "../../Images/loader.gif";
import { useDropzone } from "react-dropzone";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import AlertComponent from "../../Hooks/AlertComponent";
import CroppingImageUserProfile from "./CroppingImageUserProfile";
import { CompressImageFunction } from "../AdminNotification/CommonFunction";
import Button from "../Button/Button";
import MinusIcon from "../Icons/GeneralIcons/MinusIcon";
import PlusIcon from "../Icons/GeneralIcons/PlusIcon";
import NotificationBanner from "../../Pages/Notifications/components/NotificationBanner";
import ImageCropperReact from "../ImageCropper/ImageCropperReact";

const EditUserProfile = () => {
  //hooks
  const history = useNavigate();
  const multiSelectRef = useRef();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  //react states
  const NavigatePage = useSelector((state) => {
    return state.partnerNavigate;
  });
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });

  const [inProcess, setInProcess] = useState(false);
  const [userDetail, setUserDetail] = useState({});
  const [purchasedPlan, setPurchasedPlan] = useState("");
  const [allGroup, setAllGroup] = useState([]);
  const [disGroup, setDisGroup] = useState([]);
  const [userAccessGroup, setuserAccessGroup] = useState([]);
  const [migratedUserStatus, setMigratedUserStatus] = useState(false);
  const [migrateData, setMigrateData] = useState({});
  const [userProfile, setUserProfile] = useState("");
  const [userActive, setUserActive] = useState();
  const [userVerified, setUserVerified] = useState();
  const [userBlock, setUserBlock] = useState();
  const [detailErr, setDetailErr] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [viewmodalOpen, setViewModalOpen] = useState(false);
  const [tmpViewImage, setViewImage] = useState(null);

  const [fileDimension, setFileDimension] = useState("");
  const [attendeeDetail, setAttendeeDetail] = useState({});
  const [allEvent, setAllEvent] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [partnerProfileImage, setpartnerProfileImage] = useState([]);
  const [auth0Id, setAuth0Id] = useState("");

  const queryVariable = searchParams.get("id");
  const [profileLoader, setProfileLoader] = useState(false);
  const [noOfTeamMate, setNoOfTeamMate] = useState(0);
  const [noOfAvialableSeat, setNoOfAvialableSeat] = useState(0);
  const [noOfTotalSeat, setNoOfTotalSeat] = useState(0);
  const [noOfUsedSeats, setNoOfUsedSeats] = useState(0);
  const [noOfSeatsErr, setNoOfSeatsErr] = useState("");
  const [tagList, setTagList] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [viewInvites, setViewInvites] = useState(false);

  //  image cropping state
  const [selectedImagePerson, setSelectedImagePerson] = useState("");
  const [fileName, setFileName] = useState("");
  const [thumbnail, setThumbnail] = useState([]);
  const [croppedUserFile, setCroppedUserFile] = useState();
  const [croppedPartnerFile, setCroppedPartnerFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const emptyObjErr = {
    first_name: "",
    last_name: "",
    profile_name: "",
    company: "",
    profession: "",
    phone: "",
    facebook: "",
    linkedin: "",
    passcode: "",
  };

  const [userDetailErr, setUserDetailErr] = useState(emptyObjErr);

  //profile image file drop function
  const onDrop = (acceptedFiles) => {
    // Check if any of the dropped files are not allowed
    const file = acceptedFiles[0];
    if (file.type.startsWith("image/")) {
      setModalOpen(true);
      setIsLoading(true)
      thumbnailImages(file);
      const reader = new FileReader();
      reader.onload = () => {setThumbnail(reader.result),setIsLoading(false)};
      reader.readAsDataURL(file);
    }
  };
  //drop-zone function
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });

  //loading all events and question when initialize page
  useEffect(() => {
    handleClearAllStates();
    getTagList(); // for tags listing
    services
      .allEventList()
      .then((res) => {
        if (res.data.status) {
          setAllEvent(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    services
      .getallgroup()
      .then((res) => {
        if (res.data.status) {
          var arr = [];
          var l = 1;
          arr[0] = { id: "All", name: "All" };
          for (var i = 0; i < res.data.data.length; i++) {
            arr[l] = {
              id: res.data.data[i]._id,
              name: res.data.data[i].groupTitle,
            };
            l++;
          }
          setAllGroup(arr);
          setDisGroup(arr);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/adminlogin");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  //load user details
  useEffect(() => {
    setProfileLoader(true);
    getUserDetails();
    setDetailErr({});
  }, [allEvent]);

  //load user details function
  const getUserDetails = () => {
    setProfileLoader(true);
    allEvent.length > 0 &&
      services
        .getuserbyid(queryVariable)
        .then((res) => {
          if (res.data.status) {
            setPurchasedPlan(
              res.data.data.profile[0].purchased_plan !== undefined &&
                res.data.data.profile[0].purchased_plan !== null &&
                res.data.data.profile[0].purchased_plan.plan_name !==
                undefined &&
                res.data.data.profile[0].purchased_plan.plan_name !== null
                ? res.data.data.profile[0].purchased_plan.plan_name
                : ""
            );
            let eventArr = [];
            let upcominEvent = [];
            res.data.data.profile[0]["Events Attended"] !== undefined &&
              res.data.data.profile[0]["Events Attended"] !== null &&
              res.data.data.profile[0]["Events Attended"].length > 0
              ? res.data.data.profile[0]["Events Attended"].map((event) => {
                eventArr.push({ title: event });
              })
              : eventArr.push();
            res.data.data.profile[0]["Upcoming Events Registered"] !==
              undefined &&
              res.data.data.profile[0]["Upcoming Events Registered"] !== null &&
              res.data.data.profile[0]["Upcoming Events Registered"].length > 0
              ? res.data.data.profile[0]["Upcoming Events Registered"].map(
                (event) => {
                  upcominEvent.push({ title: event });
                }
              )
              : upcominEvent.push();
            setSelectedEvent(eventArr);
            setSelectedTags(
              res?.data?.data?.profile[0]?.tagData.length &&
              res?.data?.data?.profile[0]?.tagData?.map((tag) => ({
                label: tag.name,
                value: tag.name,
              }))
            );
            setUpcomingEvent(upcominEvent);
            setUserDetail({
              first_name: res.data.data.profile[0].first_name,
              last_name: res.data.data.profile[0].last_name,
              email: res.data.data.profile[0]["Preferred Email"],
              passcode: res.data.data.profile[0]["passcode"],
              profile_name:
                res.data.data.profile[0].display_name &&
                  res.data.data.profile[0].display_name !== ""
                  ? res.data.data.profile[0].display_name
                  : `${res.data.data.profile[0].first_name} ${res.data.data.profile[0].last_name}`,
            });
            setUserActive(res.data.data.profile[0].active);
            setUserVerified(res.data.data.profile[0].verified);
            setUserProfile(res.data.data.profile[0].profileImg);
            setpartnerProfileImage(res.data.data.profile[0]?.partnerIcon);
            setUserBlock(res.data.data.profile[0].blocked);
            setAttendeeDetail(res.data.data.profile[0].attendeeDetail);
            setAuth0Id(res.data.data.profile[0].auth0Id);
            setCollaborators(res.data.data.profile[0].collaborators);
            setNoOfTeamMate(
              res.data.data.profile[0].no_of_team_mate
                ? res.data.data.profile[0].no_of_team_mate
                : 0
            );
            setNoOfAvialableSeat(
              res.data.data.profile[0].purchased_plan !== undefined &&
                res.data.data.profile[0].purchased_plan !== null &&
                res.data.data.profile[0].purchased_plan.plan_name !==
                undefined &&
                res.data.data.profile[0].purchased_plan.plan_name !== null
                ? res.data.data.profile[0].purchased_plan.no_of_team_mate
                : 0
            );
            setNoOfTotalSeat(res.data.data.profile[0].totalNoOfTeamMate);

            setNoOfUsedSeats(
              parseInt(
                res.data.data.profile[0].totalNoOfAcceptedInvite
                  ? res.data.data.profile[0].totalNoOfAcceptedInvite
                  : 0
              ) +
              parseInt(
                res.data.data.profile[0].totalNoOfPendingInvite
                  ? res.data.data.profile[0].totalNoOfPendingInvite
                  : 0
              )
            );

            var arr = [];
            for (
              var i = 0;
              i < res.data.data.profile[0].accessible_groups.length;
              i++
            ) {
              arr[i] = {
                id: res.data.data.profile[0].accessible_groups[i]._id,
                name: res.data.data.profile[0].accessible_groups[i].groupTitle,
              };
            }
            setuserAccessGroup(arr);

            if (res.data.data.profile[0].migrate_user_status) {
              setMigratedUserStatus(
                res.data.data.profile[0].migrate_user_status
              );
              setMigrateData(res.data.data.profile[0].migrate_user);
            }
          } else if (res.data.invalidToken) {
            localStorage.clear();
            history("/adminlogin");
          }
          setProfileLoader(false);
        })
        .catch((e) => {
          console.log(e);
        });
    setDetailErr({});
  };
  //called when change any input
  const handleChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });

    if (e.target.name === "active" || e.target.name === "verified") {
      setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
    } else if (e.target.name === "verified") {
      setUserDetail(e.target.value);
    } else {
      if (e.target.type === "checkbox") {
        var arr = userDetail[e.target.name].split(",");
        var errObj = { ...detailErr };
        if (e.target.checked) {
          if (arr.indexOf(e.target.value) === -1) arr.push(e.target.value);
        } else {
          arr.splice(arr.indexOf(e.target.value), 1);
        }
        setUserDetail({ ...userDetail, [e.target.name]: arr.toString() });
      } else if (e.target.type === "file") {
        setUserDetail({
          ...userDetail,
          [e.target.name]: e.target.files[0],
        });
      } else {
        setUserDetail({
          ...userDetail,
          [e.target.name]: e.target.value,
        });
        if (e.target.name === "first_name") {
          if (e.target.value.length === 0) {
            setUserDetailErr({
              ...userDetailErr,
              first_name: "Enter first name!",
            });
          } else {
            setUserDetailErr({ ...userDetailErr, first_name: "" });
          }
        }

        if (e.target.name === "last_name") {
          if (e.target.value.length === 0) {
            setUserDetailErr({
              ...userDetailErr,
              last_name: "Enter last name!",
            });
          } else {
            setUserDetailErr({ ...userDetailErr, last_name: "" });
          }
        }

        var errObj = { ...detailErr };
        if (e.target.type === "email") {
        } else {
        }
        setDetailErr({ ...detailErr, ...errObj });
      }
    }
  };

  //call when attendee detail changes
  const handleAttendeeDetailChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setAttendeeDetail({ ...attendeeDetail, [e.target.name]: e.target.value });
  };

  //called when submit profile data
  const handleSubmit = () => {
    var errObj = { ...detailErr };
    var errorExist = false;
    if (!errorExist) {
      let tmpErrStatus = false;
      let tmpErrObj = {};
      if (userDetail.first_name.length === 0) {
        tmpErrStatus = true;
        tmpErrObj = { ...tmpErrObj, first_name: "Enter first name!" };
      }

      if (userDetail.last_name.length === 0) {
        tmpErrStatus = true;
        tmpErrObj = { ...tmpErrObj, last_name: "Enter last name!" };
      }

      if (Object.keys(tmpErrObj).length > 0) {
        const emptyKeys = Object.keys(tmpErrObj).filter(
          (key) => tmpErrObj[key] !== ""
        );
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      if (tmpErrStatus) {
        setUserDetailErr(tmpErrObj);
        return;
      }
      if (
        parseInt(noOfTeamMate) + parseInt(noOfAvialableSeat) <
        parseInt(noOfUsedSeats)
      ) {
        const totalUsedExtraSeats =
          parseInt(noOfUsedSeats) - parseInt(noOfAvialableSeat);
        if (noOfTeamMate === 0) {
          setNoOfSeatsErr(`You can not have empty extra seats.`);
        } else {
          setNoOfSeatsErr(
            `You have already used ${totalUsedExtraSeats} extra seats so you can not reduce the seats less than this, please revoke the invite from user and then you can reduce.`
          );
        }

        return;
      } else {
        setNoOfSeatsErr("");
      }

      setInProcess(true);

      var arrAccessGrp = [];
      var j = 0;
      if (
        userAccessGroup.filter((acgp) => {
          if (acgp.id === "All") return acgp;
        }).length > 0
      ) {
        for (var i = 0; i < allGroup.length; i++) {
          if (allGroup[i].id !== "All") {
            arrAccessGrp[j] = allGroup[i].id;
            j++;
          }
        }
      } else {
        for (var i = 0; i < userAccessGroup.length; i++) {
          if (userAccessGroup[i].id !== "All") {
            arrAccessGrp[j] = userAccessGroup[i].id;
            j++;
          }
        }
        if (userAccessGroup.length === 0) {
          arrAccessGrp = [];
        }
      }
      const tagsIdArr = selectedTags
        ? tagList
          ?.filter((tag) =>
            selectedTags?.map((t) => t.value)?.includes(tag.name)
          )
          ?.map((ele) => ele._id)
        : [];
      services
        .updateprofilebyadmin({
          userId: queryVariable,
          first_name: userDetail.first_name,
          last_name: userDetail.last_name,
          display_name:
            userDetail.profile_name && userDetail.profile_name !== ""
              ? userDetail.profile_name
              : `${userDetail.first_name} ${userDetail.last_name}`,
          active: userActive,
          verified: userVerified === "true" ? true : false,
          accessible_groups: arrAccessGrp,
          blocked: userBlock,
          migratedata: migrateData,
          no_of_team_mate: +noOfTeamMate,
          attendeeDetail: attendeeDetail,
          passcode: userDetail.passcode,
          tagId: tagsIdArr,

          //no_of_team_mate: +extraTeamMate + +noOfTeamMate
        })
        .then((res) => {
          if (res.data.status) {
            setSelectedEvent([]);
            setInProcess(false);
            setShowAlert(true);
            setAlertMsg(res.data.message);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);

            //setselectedDiv("listing");
          } else if (res.data.invalidToken) {
            localStorage.clear();
            history("/adminlogin");
          } else {
            setInProcess(false);
            setShowAlert(true);
            setAlertMsg(res.data.message);
            setTimeout(() => {
              setShowAlert(false);
            }, 2000);
          }
          handleClearAllStates();
          setDetailErr({});
          services.updateUserDataInRadis(queryVariable);
          getUserDetails();
        })
        .catch((err) => {
          handleClearAllStates();
          console.log(err);
        });
    }
  };

  //set view profile data
  function viewProfileImage(e) {
    setViewModalOpen(true);
    setViewImage(e.currentTarget);
  }

  //called when cropping process completes
  const CropDone = async (croppedImg) => {
    let imageCompress;
    setLoader(true);
    setModalOpen(false);
    var formdata = new FormData();
    if (croppedImg) {
      imageCompress = await CompressImageFunction(croppedImg);
    }
    imageCompress &&
      formdata.append(
        selectedImagePerson === "user" ? "profileImg" : "partnerIcon",
        imageCompress
      );
    formdata.append("userId", queryVariable);
    services
      .updateprofileimagebyadmin(formdata)
      .then((res) => {
        if (res.data.status) {
          setInProcess(false);
          setShowAlert(true);
          setAlertMsg(res.data.message);
          setLoader(false);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);

          setUserProfile(res.data.data.profileImg);
        } else if (res.data.invalidToken) {
          localStorage.clear();
          history("/adminlogin");
        } else {
          setInProcess(false);
          setShowAlert(true);
          setLoader(false);
          setAlertMsg(res.data.message);
          setTimeout(() => {
            setShowAlert(false);
          }, 2000);
        }
        setDetailErr({});
        services.updateUserDataInRadis(queryVariable);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //team mate number change event
  const handleTeamMateChange = (e, type) => {
    let value = noOfTeamMate;
    if (type === "plus") {
      value = value + 1;
      setNoOfTeamMate((prev) => prev + 1);
    } else {
      if (value > 0) {
        value = value - 1;
        setNoOfTeamMate((prev) => prev - 1);
      }
    }

    if (
      parseInt(value > 0 ? value : 0) + parseInt(noOfAvialableSeat) <
      parseInt(noOfUsedSeats)
    ) {
      const totalUsedExtraSeats =
        parseInt(noOfUsedSeats) - parseInt(noOfAvialableSeat);
      setNoOfTotalSeat(
        parseInt(value > 0 ? value : 0) + parseInt(noOfAvialableSeat)
      );
      setNoOfSeatsErr(
        `You have already used ${totalUsedExtraSeats} extra seats so you can not reduce the seats less than this, please revoke the invite from user and then you can reduce.`
      );
    } else {
      setNoOfSeatsErr("");
      setNoOfTotalSeat(
        parseInt(value > 0 ? value : 0) + parseInt(noOfAvialableSeat)
      );
    }
  };

  //for tags options
  const getTagList = async () => {
    try {
      const tagListRes = await services.getalltags_byadmin("");
      if (tagListRes?.data?.status) {
        setTagList(tagListRes.data.data);
      }
    } catch (error) {
      return false;
    }
  };

  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    // setModalOpen(true);
  };

  return (
    <>
      {/*  Modal Edit */}
      <div className="users-info-content">
        <div className="users-info-content-main">
          {modalOpen && (
            <ImageCropperReact
              file={thumbnail}
              setModalOpen={setModalOpen}
              setFile={setThumbnail}
              fileName={fileName}
              fileDimension={fileDimension}
              CropDone={CropDone}
              setCroppedUserFile={setCroppedUserFile}
              selectedImagePerson={selectedImagePerson}
              setCroppedPartnerFile={setCroppedPartnerFile}
              requiredImageAspectRatio={1 / 1}
              isLoading={isLoading}
            />
          )}
          <div>
            <div className="um-back">
              <button
                type="button"
                className="back-btn"
                onClick={() => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMPAGECHANGE" });
                    dispatch({
                      type: "NavigatePage",
                      payload: "/user/listing",
                    });
                  } else {
                    history(
                      NavigatePage !== null && NavigatePage !== ""
                        ? NavigatePage
                        : "/user/listing"
                    );
                  }
                }}
              >
                <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
                Back
              </button>
            </div>
            <h1>Edit User Details</h1>
            {profileLoader ? (
              <div className="cstm-data-edit-load2">
                <img src={Loader} />{" "}
              </div>
            ) : (
              <>
                <div className="modal-body">
                  {showAlert && (
                    <>
                      <div className="alert alert-info">{alertMsg}</div>
                      <br />
                    </>
                  )}
                  <div className="d-flex gap-4">
                    {/* user profile pic */}
                    <div className="form-group cstm-edit-profile-img-userlis d-flex flex-column justify-content-center align-items-center">
                      <div className="form-group-ct">
                        <div className="cstm-profile-upload-img uuu">
                          {selectedImagePerson === "user" && loader ? (
                            <div className="profile-loading-image">
                              <span className="cstm-pli-bg-edituser">
                                <img src={loading} />
                              </span>
                            </div>
                          ) : croppedUserFile ||
                            (userProfile !== undefined &&
                              userProfile.length > 0) ? (
                            <img
                              className="cstm-ld-user-img"
                              src={
                                croppedUserFile
                                  ? croppedUserFile
                                  : userProfile !== undefined &&
                                  userProfile.length > 0 &&
                                  userProfile
                              }
                              onClick={viewProfileImage}
                            />
                          ) : (
                            <div className="cstm-ld-user-img">
                              {userDetail?.first_name &&
                                userDetail?.first_name?.charAt(0)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cstm-profile-upload-btn-sec">
                        <div className="cstm-profile-upload-btn">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <span
                              className="edit-pro-text"
                              onClick={() => setSelectedImagePerson("user")}
                            >
                              Change profile pic
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* partner profile pic */}
                    <div className="form-group cstm-edit-profile-img-userlis d-flex flex-column justify-content-center align-items-center">
                      <div className="form-group-ct">
                        <div className="cstm-profile-upload-img">
                          {selectedImagePerson === "partner" && loader ? (
                            <div className="profile-loading-image">
                              <span className="cstm-pli-bg-edituser">
                                <img src={loading} />
                              </span>
                            </div>
                          ) : croppedPartnerFile ||
                            (partnerProfileImage !== undefined &&
                              partnerProfileImage.length > 0) ? (
                            <img
                              className="cstm-ld-user-img"
                              src={
                                croppedPartnerFile
                                  ? croppedPartnerFile
                                  : partnerProfileImage !== undefined &&
                                  partnerProfileImage.length > 0 &&
                                  partnerProfileImage
                              }
                              onClick={viewProfileImage}
                            />
                          ) : (
                            <div className="cstm-ld-user-img">
                              {userDetail?.first_name &&
                                userDetail?.first_name?.charAt(0)}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="cstm-profile-upload-btn-sec">
                        <div className="cstm-profile-upload-btn">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <span
                              className="edit-pro-text"
                              onClick={() => setSelectedImagePerson("partner")}
                            >
                              Change partner profile pic
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {viewmodalOpen && (
                    <div className="desk-globle-model-box globle-model-box  cstm-rpp-view-profile">
                      <div className="desk-globle-model-box-inner cstm-vpp-popup">
                        <div className="modal-content">
                          <div className="cstm-replace-pp">
                            <div class="cstm-rpp-title">
                              View Profile Picture
                              <span
                                class="cstm-rpp-close-btn"
                                onClick={() => setViewModalOpen(false)}
                              >
                                ✖
                              </span>
                            </div>
                            <div className="cstm-cstm-vpp-photo">
                              {tmpViewImage && <img src={tmpViewImage.src} />}
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  )}
                  <div className="form-group">
                    <div className="form-group-label">Purchased Plan</div>
                    <div className="form-group-ct">
                      <input type="text" disabled value={purchasedPlan} />
                    </div>
                  </div>
                  {/* <div class="row">
                    <div className="form-group col-sm-6">
                      <div className="form-group-label">
                        Total no. of seats (Total of membership's seat + extra
                        seat)
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="noOfTotalSeat"
                          value={noOfTotalSeat}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <div className="form-group-label">
                        Available seats (Based on Membership)
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="noOfAvailableSeat"
                          value={noOfAvialableSeat}
                          readOnly
                        />
                      </div>
                    </div>
                  </div> 

                  <div className="row">
                    <div className="col-sm-6 form-group">
                      <div className="form-group-label">Extra seats</div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="noOfTeamMate"
                          value={noOfTeamMate}
                          onChange={handleTeamMateChange}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <span className="error-extra-seats">{noOfSeatsErr}</span>
                    </div>
                    <div className="form-group col-sm-6">
                      <div className="form-group-label">Total Used seats</div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="noOfUsedSeats"
                          value={noOfUsedSeats}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group cstm-admin-access-grp">
                    <div className="form-group-label">
                      Collaborators Invited
                    </div>
                    <div className="form-group-ct label-eup-main">
                      {collaborators.length > 0 ? (
                        collaborators.map((collab) => (
                          <>
                            <div className="label-eup-main-in">
                              <a
                                href={
                                  collab.CollaboratorUserDetails !== "" &&
                                  `/user/edituser?id=${collab.CollaboratorUserDetails.userId}`
                                }
                                target="_blank"
                              >
                                {collab.email}
                              </a>
                            </div>
                          </>
                        ))
                      ) : (
                        <div>-</div>
                      )}
                    </div>
                  </div>
                    */}
                  <div className="form-group">
                    <div className="form-group-label">Preferred Email</div>
                    <div className="form-group-ct">
                      <input
                        readOnly
                        type="text"
                        onChange={handleChange}
                        name="email"
                        value={userDetail.email}
                      />
                    </div>
                  </div>
                  <h1 className="mb-2">Team-users</h1>
                  <NotificationBanner
                    onClickFun={() => setViewInvites(!viewInvites)}
                    extraClass="mb-3"
                    hasActionText={true}
                    actionText="View invites"
                    hasIcon={false}
                    text={`${noOfUsedSeats} of ${noOfTotalSeat} seats are in used.`}
                    variant="info"
                  />
                  {viewInvites && (
                    <div className="form-group cstm-admin-access-grp">
                      <div className="form-group-label">Team-users Invited</div>
                      <div className="form-group-ct label-eup-main">
                        {collaborators.length > 0 ? (
                          collaborators.map((collab) => (
                            <>
                              <div className="label-eup-main-in">
                                <a
                                  href={
                                    collab.CollaboratorUserDetails !== "" &&
                                    `/user/edituser?id=${collab.CollaboratorUserDetails.userId}`
                                  }
                                  target="_blank"
                                >
                                  {collab.email}
                                </a>
                              </div>
                            </>
                          ))
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="form-group edit-stepper">
                    <p className="form-group-label ml-0 mb-0 pl-0">
                      Extra Seats
                    </p>
                    <div className="button-stepper">
                      <Button
                        elementIcon={
                          <MinusIcon
                            height={16}
                            iconColor="#060D14"
                            width={16}
                          />
                        }
                        variant="secondary"
                        hasText={false}
                        handleClick={(e) => {
                          handleTeamMateChange(e, "minus");
                        }}
                      />
                      <p>{noOfTeamMate}</p>
                      <Button
                        elementIcon={
                          <PlusIcon
                            height={16}
                            iconColor="#060D14"
                            width={16}
                          />
                        }
                        variant="secondary"
                        hasText={false}
                        handleClick={(e) => {
                          handleTeamMateChange(e, "plus");
                        }}
                      />
                    </div>
                    <span className="error-extra-seats">{noOfSeatsErr}</span>
                  </div>
                  <div className="form-group">
                    <h1 className="mb-2">User details</h1>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">First Name*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        onChange={handleChange}
                        name="first_name"
                        value={userDetail.first_name}
                      />
                    </div>
                    <span className="error">{userDetailErr.first_name}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Last Name*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        name="last_name"
                        onChange={handleChange}
                        value={userDetail.last_name}
                      />
                    </div>
                    <span className="error">{userDetailErr.last_name}</span>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Profile Name</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        name="profile_name"
                        onChange={handleChange}
                        value={userDetail.profile_name}
                      />
                    </div>
                    <span className="error">{userDetailErr.profile_name}</span>
                  </div>
                  {
                    <>
                      <div className="form-group cstm-admin-access-grp">
                        <div className="form-group-label">Access Group</div>
                        <div className="form-group-ct speakers-only">
                          <Multiselect
                            displayValue="name"
                            ref={multiSelectRef}
                            options={allGroup}
                            selectedValues={userAccessGroup}
                            onSelect={(selectedlist) => {
                              dispatch({ type: "SETMODALINPROCESS" });
                              dispatch({
                                type: "SETSTAYONPAGE",
                                payload: null,
                              });
                              if (
                                selectedlist.length > 0 &&
                                selectedlist.filter((list) => {
                                  if (list.id === "All") return list;
                                }).length === 0
                              ) {
                                setuserAccessGroup(selectedlist);
                              } else {
                                setuserAccessGroup(selectedlist);
                              }
                            }}
                            onRemove={(selectedlist) => {
                              dispatch({ type: "SETMODALINPROCESS" });
                              dispatch({
                                type: "SETSTAYONPAGE",
                                payload: null,
                              });
                              setuserAccessGroup(selectedlist);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group cstm-admin-access-grp">
                        <div className="form-group-label">Events Attended</div>
                        <div className="form-group-ct label-eup-main">
                          {selectedEvent.length > 0 ? (
                            selectedEvent.map((event) => (
                              <>
                                {event.title.split(",").map((title, index) => (
                                  <div
                                    key={index}
                                    className="label-eup-main-in"
                                  >
                                    {title.trim()}
                                  </div>
                                ))}
                              </>
                            ))
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group cstm-admin-access-grp">
                        <div className="form-group-label">
                          Events Registered
                        </div>
                        <div className="form-group-ct label-eup-main">
                          {upcomingEvent.length > 0 ? (
                            upcomingEvent.map((event) => (
                              <>
                                <div className="label-eup-main-in">
                                  {event.title}
                                </div>
                              </>
                            ))
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form-group-label">Status</div>
                        <div className="form-group-ct">
                          <select
                            name="active"
                            onChange={(e) => {
                              dispatch({ type: "SETMODALINPROCESS" });
                              dispatch({
                                type: "SETSTAYONPAGE",
                                payload: null,
                              });
                              setUserActive(e.target.value);
                            }}
                          >
                            <option
                              value={true}
                              selected={userActive ? true : false}
                            >
                              Active
                            </option>
                            <option
                              value={false}
                              selected={userActive ? false : true}
                            >
                              InActive
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Verified</div>
                        <div className="form-group-ct">
                          <select
                            name="verified"
                            onChange={(e) => {
                              dispatch({ type: "SETMODALINPROCESS" });
                              dispatch({
                                type: "SETSTAYONPAGE",
                                payload: null,
                              });
                              setUserVerified(e.target.value);
                            }}
                          >
                            <option
                              value={true}
                              selected={userVerified ? true : false}
                            >
                              Verified
                            </option>
                            <option
                              value={false}
                              selected={userVerified ? false : true}
                            >
                              Not Verified
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Block</div>
                        <div className="form-group-ct">
                          <select
                            name="active"
                            onChange={(e) => {
                              dispatch({ type: "SETMODALINPROCESS" });
                              dispatch({
                                type: "SETSTAYONPAGE",
                                payload: null,
                              });
                              setUserBlock(e.target.value);
                            }}
                          >
                            <option
                              value={true}
                              selected={userBlock ? true : false}
                            >
                              Block
                            </option>
                            <option
                              value={false}
                              selected={userBlock ? false : true}
                            >
                              Unblock
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form-group-label">Passcode</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter passcode"
                            name="passcode"
                            onChange={handleChange}
                            value={
                              userDetail !== undefined &&
                                userDetail.passcode !== undefined
                                ? userDetail.passcode
                                : ""
                            }
                          />
                        </div>
                        <span className="error">{userDetailErr.passcode}</span>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Company</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter company"
                            name="company"
                            onChange={handleAttendeeDetailChange}
                            value={
                              attendeeDetail !== undefined &&
                                attendeeDetail.company !== undefined
                                ? attendeeDetail.company
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Profession</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter profession"
                            name="profession"
                            onChange={handleAttendeeDetailChange}
                            value={
                              attendeeDetail !== undefined &&
                                attendeeDetail.profession !== undefined
                                ? attendeeDetail.profession
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Phone no.</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter phone no."
                            name="phone"
                            onChange={handleAttendeeDetailChange}
                            value={
                              attendeeDetail !== undefined &&
                                attendeeDetail.phone !== undefined
                                ? attendeeDetail.phone
                                : ""
                            }
                          />
                          <span className="error">{userDetailErr.phone}</span>
                        </div>
                      </div>

                      <div className="form-group">
                        <div className="form-group-label">Facebook</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter facebook link"
                            name="facebook"
                            onChange={handleAttendeeDetailChange}
                            value={
                              attendeeDetail !== undefined &&
                                attendeeDetail.facebook !== undefined
                                ? attendeeDetail.facebook
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Linkedin</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter linkedin link"
                            name="linkedin"
                            onChange={handleAttendeeDetailChange}
                            value={
                              attendeeDetail !== undefined &&
                                attendeeDetail.linkedin !== undefined
                                ? attendeeDetail.linkedin
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="form-group-label">Tags</div>
                        <div className="form-group-ct speakers-only">
                          <Multiselect
                            showArrow={true}
                            displayValue="label"
                            placeholder="Select users"
                            options={tagList?.map((tag) => ({
                              label: tag.name,
                              value: tag.name,
                            }))}
                            selectedValues={selectedTags}
                            onSelect={(selectedlist) => {
                              dispatch({ type: "SETMODALINPROCESS" });
                              dispatch({
                                type: "SETSTAYONPAGE",
                                payload: null,
                              });
                              setSelectedTags(selectedlist);
                              // setRulesDetailErr({
                              //   ...rulesDetailErr,
                              //   userAccessIds: "",
                              //   membership: "",
                              // });
                              // setRuleError(false);
                              // setNoRuleFieldSelected(false);
                            }}
                            onRemove={(selectedlist) => {
                              dispatch({ type: "SETMODALINPROCESS" });
                              dispatch({
                                type: "SETSTAYONPAGE",
                                payload: null,
                              });
                              setSelectedTags(selectedlist);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                </div>

                {inProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <div className="edit-user-details-footer">
                    <button
                      type="button"
                      className="btn"
                      onClick={handleSubmit}
                      data-dismiss="modal"
                      disabled={inProcess}
                    >
                      Update
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <>
        <AlertComponent />
      </>
    </>
  );
};

export default EditUserProfile;
