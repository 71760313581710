import React from "react";
import PropTypes from "prop-types"
function ArrowChevronRight({ height = 24, width = 24, iconColor = "#000", bold }) {
    return (
        <>
            {bold ? <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M8.793 4.293a1 1 0 0 0 0 1.414L15.086 12l-6.293 6.293a1 1 0 1 0 1.414 1.414L17.914 12l-7.707-7.707a1 1 0 0 0-1.414 0Z"
                    />
            </svg > : <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
            >
                <path
                    fill={iconColor}
                    d="M8.22 19.53a.75.75 0 0 1-.073-.976l.073-.084L14.689 12l-6.47-6.47a.75.75 0 0 1-.072-.976l.073-.084a.75.75 0 0 1 .976-.073l.084.073 7 7a.75.75 0 0 1 .073.976l-.073.084-7 7a.75.75 0 0 1-1.06 0Z"
                />
            </svg >}
        </>
    );
}
ArrowChevronRight.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    bold: PropTypes.bool
}
export default ArrowChevronRight;
