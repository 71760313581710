import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import auth0 from "auth0-js";

const AppleAuthenticate = () => {
    const location = useLocation();
    
useEffect(() => {
    if (location.code) {
        console.log(location.code)
        var options = {
            connection: "apple",
          };
          var auth_soc = new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
            responseType: "token id_token",
            redirectUri: process.env.REACT_APP_REDIRECT_URL_SOCIAL_SIGNUP,
            scope: "openid profile email full_access",
          });

          auth_soc.authorize(options, function (err, authResult) {
            if (err) {
              console.log(err, " error");
            }
            console.log(authResult, "*********");
          });
    }
    console.log(location , "location")
    }, [location]);
    return (
        <>Sign in successfull</>
    )
}
export default AppleAuthenticate