export default function authHeader() {
  const idtoken = localStorage.getItem("adminidtoken");
  if (idtoken) {
    // for Node.js Express back-end
    return {
      Authorization: "Bearer " + idtoken, "ngrok-skip-browser-warning": "69420" 
    };
  } else {
    return {};
  }
}
