import React from "react";

const IconNonAccess = () => {
  return (
    <div className="mds-icons-empty">
      <svg
    xmlns="https://www.w3.org/2000/svg"
    width={120}
    height={120}
    fill="none"
  >
    <g clipPath="url(#a)">
      <circle
        cx={60}
        cy={60}
        r={60}
        fill="#6FCADF"
        fillOpacity={0.2}
        opacity={0.9}
      />
      <path
        fill="#060D14"
        fillRule="evenodd"
        d="M28 60.208C28 42.963 42.318 29 60 29c17.682 0 32 13.963 32 31.208 0 3.141-.475 6.174-1.36 9.034H83.79a2 2 0 0 0-1.732 1L75.845 81a2 2 0 0 0 0 2l1.856 3.215c-5.068 3.286-11.154 5.2-17.701 5.2-17.682 0-32-13.963-32-31.207Zm30.542 6.854c.187.109.449 0 .449-.22h.037V53.829a.849.849 0 0 0-.486-.766l-14.168-7.656c-.972-.51-2.131.146-2.131 1.203v10.208c0 4.63.972 8.677 2.953 12.14 1.832 3.245 4.935 6.672 9.234 10.354v-8.75c0-.072-.038-.145-.075-.218-.635-.693-1.121-1.386-1.533-2.005-1.121-1.75-1.757-3.61-1.869-5.469l7.59 4.193Zm16.224 1.895c1.982-3.463 2.954-7.51 2.954-12.14v-9.843c0-1.204-1.309-1.97-2.393-1.386l-13.87 7.474a.849.849 0 0 0-.485.766v13.015c0 .219.449.219.449.219l7.588-4.193c-.15 1.86-.747 3.682-1.869 5.469-.411.62-.897 1.276-1.532 2.005-.075.073-.075.218-.075.218v8.75c4.299-3.645 7.364-7.109 9.233-10.354Z"
        clipRule="evenodd"
      />
      <path
        fill="#F1343D"
        fillRule="evenodd"
        d="M84.366 72.242h11.268L101.268 82l-5.634 9.758H84.366L78.732 82l5.634-9.758Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="m91.337 76-.282 8.988h-2.643L88.122 76h3.215Zm-1.604 13c-.476 0-.886-.146-1.227-.439-.342-.297-.51-.652-.506-1.065-.005-.41.164-.76.506-1.053a1.826 1.826 0 0 1 1.227-.44c.458 0 .86.147 1.206.44.347.292.523.643.528 1.053a1.32 1.32 0 0 1-.253.758 1.766 1.766 0 0 1-.628.545c-.26.134-.544.201-.853.201Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h120v120H0z" />
      </clipPath>
    </defs>
  </svg>
    </div>
  );
};

export default IconNonAccess;
