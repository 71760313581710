import React, { useEffect } from "react";
import Header from "../../components/UserComponents/Dashboard/Header";
import LeftSidebar from "../../components/UserComponents/Dashboard/LeftSidebar";
import DocumentMainPage from "./DocumentMainPage";
import "./Components/Document.css";
import AccessDeny from "../../components/AccessDeny";

const index = () => {
  useEffect(() => {
    sessionStorage.setItem("previousurl", window.location.href);
  }, []);
  const cleanedPathname = window.location.pathname.replace(/\/$/, "");

  const resourceList = localStorage.getItem("accessResources");
  const isCollaborator = JSON.parse(localStorage.getItem("isCollaborator"));
  const partnerResource =
    resourceList !== null &&
    JSON.parse(resourceList).find((resource) => resource.name === "Documents");
  return (
    <>
      {isCollaborator && partnerResource !== undefined && partnerResource ? (
        <>                                                                                          
          <Header />
          <div className="all-content-main-section">
            <LeftSidebar />
            <div className="all-content-main-section-in">
              <div className="doc-management-main">
                <DocumentMainPage />
              </div>
            </div>
          </div>
        </>
      ) : isCollaborator !== null && !isCollaborator ? (
        <>
          <Header />
          <div className="all-content-main-section">
            <LeftSidebar />
            <div className="all-content-main-section-in">
              <div className="doc-management-main">
                <DocumentMainPage />
              </div>
            </div>
          </div>
        </>
      ) : isCollaborator && partnerResource === undefined ? (         
        <AccessDeny />
      ) : (
        <></>
      )}
    </>
  );
};
export default index;