import { useEffect, useState } from "react";
import { Link,Navigate, useNavigate , useSearchParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AlertComponent from "../../Hooks/AlertComponent";

//import image
import deletefill from "../../Images/BackendImages/delete-fill.svg";

import AddFillIcon from "remixicon-react/AddFillIcon";

const EditSelectedField = ({
  styleDiv,
  newField,
  setNewField,
  selectedGrid,
  setstyleDiv,
  grid,
}) => {
  const [showOption, setshowOption] = useState(false);
  const [showaddoptionfield, setshowaddoptionfield] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  
  const [showInfoDiv, setshowInfoDiv] = useState(
    newField["fields"][selectedGrid - 1]["info"].length > 0 ? true : false
  );
  const [showvalidDiv, setshowvalidDiv] = useState(
    newField["fields"][selectedGrid - 1]["min"] > 0 ||
      newField["fields"][selectedGrid - 1]["max"] > 0 ||
      newField["fields"][selectedGrid - 1]["required"]
      ? true
      : false
  );
  const [showminmax, setshowminmax] = useState(
    newField["fields"][selectedGrid - 1]["min"] > 0 ||
      newField["fields"][selectedGrid - 1]["max"] > 0
      ? true
      : false
  );
  const [showPlaceholder, setshowPlaceholder] = useState(
    newField["fields"][selectedGrid - 1]["placeholder"].length > 0
      ? true
      : false
  );
  const [formFields, setFormFields] = useState(
    newField["fields"][selectedGrid - 1]
  );
  const [err, setErr] = useState("");
  const [fieldoptions, setfieldoptions] = useState(
    newField["fields"][selectedGrid - 1]["options"]
  );
  const [error, setError] = useState({ label: "", placeholder: "", type: "" });
  
  useEffect(()=>{
    dispatch({type:"REMOVEMODALINPROCESS"})
  },[])
  // useEffect called when fieldoptions change or setfieldoptions called
  useEffect(() => {
    setFormFields({ ...formFields, options: fieldoptions });
  }, [setfieldoptions, fieldoptions]);
  // useEffect called when states change
  useEffect(() => {
    console.log(newField.length);
  }, [
    styleDiv,
    showOption,
    showInfoDiv,
    setfieldoptions,
    fieldoptions,
    formFields,
    setFormFields,
    setshowaddoptionfield,
    showaddoptionfield,
    setshowInfoDiv,
    showvalidDiv,
    setshowvalidDiv,
    setshowminmax,
    showminmax,
    showPlaceholder,
    setshowPlaceholder,
  ]);
  // useEffect called when newField , selectedGrid , grid change
  useEffect(() => {
    setFormFields(newField["fields"][selectedGrid - 1]);
  }, [newField, selectedGrid, grid]);
  // useEffect called on first render
  useEffect(() => {
    setfieldoptions(newField["fields"][selectedGrid - 1]["options"]);
    newField["fields"][selectedGrid - 1]["options"].length > 0
      ? setshowOption(true)
      : setshowOption(false);
    newField["fields"][selectedGrid - 1]["info"].length > 0
      ? setshowInfoDiv(true)
      : setshowInfoDiv(false);
    newField["fields"][selectedGrid - 1]["required"].length > 0 ||
    newField["fields"][selectedGrid - 1]["min"] > 0 ||
    newField["fields"][selectedGrid - 1]["max"].length > 0
      ? setshowvalidDiv(true)
      : setshowvalidDiv(false);
    newField["fields"][selectedGrid - 1]["min"] > 0 ||
    newField["fields"][selectedGrid - 1]["max"].length > 0
      ? setshowminmax(true)
      : setshowminmax(false);
    newField["fields"][selectedGrid - 1]["placeholder"].length > 0
      ? setshowPlaceholder(true)
      : setshowPlaceholder(false);
  }, [newField]);
  // Called on click on create button
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formFields.type !== "select" &&
      (formFields.type !== "checkbox") & (formFields.type !== "radio")
    )
      setFormFields({ ...formFields, options: [] });

    if (formFields.label.length > 0 && formFields.type.length > 0) {
      if (
        ((formFields.type === "select" ||
          formFields.type === "checkbox" ||
          formFields.type === "radio") &&
          formFields.options.length > 1) ||
        formFields.placeholder.length > 0 ||
        formFields.type === "date" ||
        formFields.type === "time" ||
        formFields.type === "file"
      ) {
        if (
          formFields.min > 0 &&
          formFields.max > 0 &&
          formFields.max < formFields.min
        ) {
          setErr("Minimum value should be less than maximum value ");
        } else {
          if (newField.grid === 1) {
            setNewField({
              grid: 1,
              fields: [formFields],
            });
          } else {
            var newarr = newField.fields;
            newarr[selectedGrid - 1] = formFields;
            setNewField({ grid: newField.grid, fields: newarr });
          }
          setstyleDiv({ display: "none" });
          setshowOption(false);
          setfieldoptions([]);
          setshowminmax(false);
          setshowPlaceholder(false);
          setshowvalidDiv(false);
          setshowInfoDiv(false);
          e.target.reset();
          setErr("");
          setError({ label: "", type: "", placeholder: "" });
        }
      } else {
        formFields.placeholder.length > 0 && formFields.options.length < 2
          ? setErr("Please Add Minimum 2 Options")
          : setError({ ...error, placeholder: "Enter placeholder name!" });
      }
    } else {
      formFields.label.length < 1 && formFields.type.length < 1
        ? setError({
            ...error,
            label: "Enter label name!",
            type: "Select type!",
          })
        : formFields.label.length < 1
        ? setError({ ...error, label: "Enter label name!" })
        : setError({ ...error, label: "Select type!" });
    }
  };

  const handleType = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    switch (e.target.value) {
      case "select":
      case "checkbox":
      case "radio":
        if (e.target.value === "select" || e.target.value === "radio") {
          setshowminmax(false);
          setFormFields({ ...formFields, min: 0 });
          setFormFields({ ...formFields, max: 0 });
        } else {
          setshowminmax(true);
        }
        setFormFields({ ...formFields, placeholder: "" });
        setshowPlaceholder(false);
        setshowOption(true);
        break;
      case "date":
      case "time":
      case "file":
        setFormFields({ ...formFields, min: 0 });
        setFormFields({ ...formFields, max: 0 });
        setFormFields({ ...formFields, placeholder: "" });
        setfieldoptions([]);
        setshowminmax(false);
        setshowOption(false);
        setshowPlaceholder(false);
        setFormFields({ ...formFields, options: [] });
        break;
      default:
        if (e.target.value === "email") {
          setshowminmax(false);
          setFormFields({ ...formFields, min: 0 });
          setFormFields({ ...formFields, max: 0 });
        } else setshowminmax(true);
        setfieldoptions([]);
        setshowOption(false);
        setshowPlaceholder(true);
        setFormFields({ ...formFields, options: [] });
        break;
    }
    e.target.value.length > 0
      ? setError({ ...error, type: "" })
      : setError({ ...error, type: "Select type!" });
    setFormFields({ ...formFields, type: e.target.value });
  };
  // Called on click on add option button
  const handleaddoptionbtn = (e) => {
    setshowaddoptionfield(true);
  };
  // Called on click on add button
  const addoption = (e) => {
    if (document.getElementById("newoptionvalue").value.length > 0) {
      var newarr = fieldoptions;
      newarr.push(document.getElementById("newoptionvalue").value);
      setfieldoptions(newarr);
      formFields["options"] = newarr;
      setshowaddoptionfield(false);
      document.getElementById("newoptionvalue").value = "";
      console.log(fieldoptions);
      setFormFields({ ...formFields, options: fieldoptions });
    }
  };
  // Called on click on delete button
  const handleDeleteOption = (option) => {
    var arr = fieldoptions;
    arr.splice(arr.indexOf(option), 1);
    setfieldoptions(arr);
    setFormFields({ ...formFields, options: fieldoptions });
  };
  // Called on click on cancel button
  const handleCancel = (e) => {
    setstyleDiv({ display: "none" });
    setshowOption(false);
    setfieldoptions([]);
    setshowminmax(false);
    setshowPlaceholder(false);
    setshowvalidDiv(false);
    setshowInfoDiv(false);
    document.getElementById("form").reset();
    setError({ label: "", type: "", placeholder: "" });
    setErr("");
    dispatch({type: "REMOVECUSTOMMODALPOPUPCLOSE"})
    dispatch({type:"REMOVEMODALINPROCESS"})
  };
  // Called on input field change
  const handleOnChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    if (e.target.name === "infocheckbox") {
      if (showInfoDiv) {
        setshowInfoDiv(false);
        setFormFields({ ...formFields, info: "" });
      } else setshowInfoDiv(true);
    } else if (e.target.name === "required") {
      setFormFields({ ...formFields, [e.target.name]: e.target.checked });
    } else if (e.target.name === "validatcheckbox") {
      if (showvalidDiv) {
        setFormFields({ ...formFields, required: false, min: 0, max: 0 });
        setshowvalidDiv(false);
      } else setshowvalidDiv(true);
    } else {
      setFormFields({ ...formFields, [e.target.name]: e.target.value });
    }
  };

    //stayonpage  popup for do you want to leave page
    useEffect(()=>{
      if (stayOnPage !== null && stayOnPage)
      { 
        if ($('#editselectfield').hasClass('show'))
          {
            $("#editselectfield").modal('show')
          }
           
        
          
      }
      else if (stayOnPage !== null && !stayOnPage){
        if ($('#editselectfield').hasClass('show'))
        { $("#editselectfield").modal('hide')}
         
        
      }
        
    },[stayOnPage])

  return (
    <>
      {/* Add field details form */}
      {newField["fields"].length > 0 ? (
        <div className="edit-di-gbns" style={styleDiv}>
          <div className="cstm-right-panel">
            <form onSubmit={handleSubmit} id="form">
              <span className="error">{err}</span>
              <div className="form-group">
                <div className="form-group-label">Label name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter label here"
                    name="label"
                    onChange={handleOnChange}
                    value={formFields["label"]}
                  />
                </div>
                <span className="error">{error.label}</span>
              </div>
              <div className="form-group">
                <div className="form-group-label">Type*</div>
                <div className="form-group-ct">
                  <select
                    name="fieldtype"
                    placeholder="Type"
                    onChange={handleType}
                    value={formFields["type"]}
                  >
                    <option value="">--- Select Type ---</option>
                    <option value="text">Text</option>
                    <option value="select">Select</option>
                    {grid === 1 ? (
                      <>
                        <option value="checkbox">Checkbox</option>
                        <option value="radio">Radio button</option>
                      </>
                    ) : (
                      <></>
                    )}
                    <option value="number">Number</option>
                    <option value="textarea">Textarea</option>
                    <option value="email">Email</option>
                    <option value="time">Time</option>
                    <option value="date">Date</option>
                    <option value="file">Media</option>
                  </select>
                </div>
                <span className="error">{error.type}</span>
              </div>

              <div
                className="form-group"
                style={
                  showPlaceholder ? { display: "block" } : { display: "none" }
                }
              >
                <div className="form-group-label">Enter placeholder*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter placeholder here"
                    name="placeholder"
                    onChange={handleOnChange}
                    value={formFields["placeholder"]}
                  />
                </div>
                <span className="error">{error.placeholder}</span>
              </div>
              <div
                className="form-group"
                id="optiontr"
                style={
                  showOption || fieldoptions.length > 0
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div className="admin-added-select-options-main">
                  {fieldoptions.length > 0
                    ? fieldoptions.map((option) => {
                        return (
                          <>
                            <div className="admin-added-select-options-inner">
                              <div className="form-group-label">Options</div>
                              <div className="form-group-ct cstm-edit-delete">
                                <input
                                  type="text"
                                  name={option}
                                  value={option}
                                  disabled
                                />
                                <div className="cstm-edit-delete-main">
                                  <a
                                    href="#"
                                    onClick={() => {
                                      handleDeleteOption(option);
                                    }}
                                  >
                                    <img src={deletefill} />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ""}
                </div>

                <div
                  className="form-group"
                  id="newoption"
                  style={
                    showaddoptionfield
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="form-group-label">Options</div>
                  <input type="text" id="newoptionvalue" />
                  <button
                    type="button"
                    onClick={addoption}
                    className="admin-add-op"
                  >
                    Add
                  </button>
                </div>
                {showaddoptionfield ? (
                  <></>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={handleaddoptionbtn}
                      className="btn admin-add-option-btn"
                    >
                      <AddFillIcon size="20px" style={{ marginRight: "0px" }} />{" "}
                      Add Option
                    </button>
                  </div>
                )}
              </div>

              <div className="form-group clt-checkbox">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleOnChange}
                    name="validatcheckbox"
                    checked={showvalidDiv ? "checked" : ""}
                  />
                  Show validation
                </label>
              </div>

              <div
                style={
                  showvalidDiv ? { display: "block" } : { display: "none" }
                }
              >
                <div className="required-bx">
                  <div className="form-group clt-checkbox">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="required"
                        onChange={handleOnChange}
                        checked={formFields["required"]}
                      />
                      Required
                    </label>
                  </div>
                  <div
                    style={
                      showminmax || showvalidDiv
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="form-group">
                      <div className="form-group-label">Max</div>
                      <div className="form-group-ct">
                        <input
                          type="number"
                          name="max"
                          onChange={handleOnChange}
                          value={formFields["max"]}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">Minimum</div>
                      <div className="form-group-ct">
                        <input
                          type="number"
                          name="min"
                          onChange={handleOnChange}
                          value={formFields["min"]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group clt-checkbox">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="infocheckbox"
                    onChange={handleOnChange}
                    checked={
                      showInfoDiv || formFields["info"].length > 0
                        ? "checked"
                        : ""
                    }
                  />
                  Info
                </label>
              </div>

              <div
                className="form-group"
                style={showInfoDiv ? { display: "block" } : { display: "none" }}
              >
                <div className="form-group-label">Info</div>
                <div className="form-group-ct">
                  <textarea
                    name="info"
                    onChange={handleOnChange}
                    value={formFields["info"]}
                  >
                    write info
                  </textarea>
                </div>
              </div>

              <div className="edit-user-details-footer">
                <button type="submit" className="btn">
                  Done
                </button>
                <button
                  type="button"
                  //onClick={handleCancel}
                  onClick={(e)=>{
                    if (modalInProcess)
                    { dispatch({type:"SETCUSTOMMODALPOPUPCLOSE"})
                     }else
                    {
                      $("#editselectfield").modal('hide')
                      handleCancel(e)
                    }
                    
                
                  }}
                  className="btn cncle-btn"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
      <AlertComponent/>
    </>
    
  );
};

export default EditSelectedField;
