const historyvideos = [];


const ReduHistoryVideos = (state = historyvideos, action) => {
  switch (action.type) {
    case "ADD_HISTORY_VIDEOS":
      return [...state, ...action.payload];
    case "FETCH_HISTORY_VIDEOS":
      return  [...action.payload];
    case "FETCHHISTORYVIDEOS":
        return  [...action.payload];  
    default:
      return state;
  }
};

export default ReduHistoryVideos;
