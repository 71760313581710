import React from 'react'
import PropTypes from "prop-types"
const SearchIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M16.5 10a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Zm-1.398 6.162a8 8 0 1 1 1.06-1.06l5.368 5.368a.75.75 0 1 1-1.06 1.06l-5.368-5.368Z"
                    clipRule="evenodd"
                  />
            </svg>
        </>
    )
}
SearchIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default SearchIcon
