import React from 'react'
import TableCell from './TableCell'
import "./Table.css"
import "../../Styles/Theme/Theme.css"
import { TableProvider } from './TableContext'
const TableBody = ({ children }) => {
  return (
    <>
      <tbody>
        <TableProvider isHead={false}>
          {children}
        </TableProvider>
      </tbody>
    </>
  )
}

export default TableBody