const videoinprocess = false;

const ReduVideoInProcess = (state = videoinprocess, action) => {
  switch (action.type) {
    case "SETINPROCESS":
      return true;
    case "REMOVEINPROCESS":
        return false;
    default:
      return state;
  }
};

export default ReduVideoInProcess;