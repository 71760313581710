export function checktoken_admin(res) {
  if (res.data.invalidToken) {
    // window.location.href = "/adminlogin";
  }
}
export function checktoken_user(res) {
  if (res.data.invalidToken) {
    window.location.href = "/";
  }
}

export function getVideoProgressPercentage(convideo) {
  const video_pause = convideo.user_video_pause !== undefined && convideo.user_video_pause[localStorage.getItem("userid")] !== undefined &&
    convideo.user_video_pause !== null && convideo.user_video_pause[localStorage.getItem("userid")] !== null
    ? convideo.user_video_pause[localStorage.getItem("userid")] : 0

  if (convideo.duration !== null && convideo.duration !== undefined) {
    const video_duration = convideo.duration
    var hms = video_duration;   // your input string
   
    var a = hms.split(':'); // split it at the colons
    
    var video_seconds = 0
    if (a.length == 3) {
      a[0] = a[0].replace("h", "")
      a[1] = a[1].replace("m", "")
      a[2] = a[2].replace("s", "")
      video_seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

    } else if (a.length == 2) {

      a[0] = a[0].replace("m", "")
      a[1] = a[1].replace("s", "")

      video_seconds = (+a[0]) * 60 + (+a[1]);
    } else {

      a[0] = a[0].replace("s", "")

      video_seconds = (+a[0]);
    }


    var video_pause_percentage = 0
    video_pause_percentage = Math.round( (video_pause / video_seconds) * 100)

    return video_pause_percentage;

  } else {
    return 0;
  }

}