import React, { useEffect, useState } from 'react'
import authServiceNews from '../../../Services/auth.service.news'
import moment from 'moment'
import { Link } from 'react-router-dom';
import logo from '../../../Images/logo-image.jpg'

const FeaturedNews = ({ loader, setLoader }) => {
    const [featuredNewsData, setFeaturedNewsData] = useState({})

    //call on first render
    useEffect(() => {
        getFeaturedNewsApi()
    }, [])

    //featuredpost api call
    async function getFeaturedNewsApi() {
        try {
            const featuredNewsRes = await authServiceNews.getFeaturedNews()
            if (featuredNewsRes.status) {
                setFeaturedNewsData(featuredNewsRes.data.featuredNews)
            }
        } catch (error) {
            setLoader(false)
            return false
        }
    }
    return (
        <>
            {loader ? <>

                <div className="news-updates-top-loading">
                    <div className="news-updates-top-img-loading skeleton-box"></div>
                    <div className="news-updates-top-title-loading skeleton-box"></div>
                    <div className="news-updates-top-date-loading skeleton-box" ></div>
                </div>

            </> :
                <>
                    <h2 class="trending-posts-title">News & updates</h2>
                    {featuredNewsData !== undefined && featuredNewsData !== null && featuredNewsData !== "" && featuredNewsData._id !== undefined && featuredNewsData._id !== null && featuredNewsData._id !== "" ?
                        <>
                            <Link to={featuredNewsData.newsType === "news" ? "/frontnews/" + featuredNewsData._id : `/videos/${featuredNewsData.videoData._id}`} >
                                <div className='news-updates-top'>
                                    <div className='news-updates-top-img'>
                                        {featuredNewsData.thumbnailUrl !== undefined && featuredNewsData.thumbnailUrl !== null && featuredNewsData.thumbnailUrl !== "" ?
                                            <img src={process.env.REACT_APP_AWS_IMG_VID_PATH + featuredNewsData.thumbnailUrl} /> :
                                           <img src={logo} />
                                        }
                                    </div>
                                    <div className='news-updates-top-title'>{featuredNewsData.titleMain !== undefined && featuredNewsData.titleMain !== null && featuredNewsData.titleMain !== "" ? featuredNewsData.titleMain : ""}</div>
                                    <div className='news-updates-top-date'>
                                        {featuredNewsData.date !== undefined && featuredNewsData.date !== null && featuredNewsData.date !== "" ? moment(featuredNewsData.date).format('MMM DD, YYYY') : ""}
                                    </div>
                                </div>
                            </Link>
                        </> : <></>
                    }
                </>
            }
        </>
    )
}

export default FeaturedNews