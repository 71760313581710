import PropTypes from "prop-types";
import MessageReaction from "./MessageReaction";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/Reaction.css";

const Reaction = ({
  isOutgoing = false,
  hasCounter = false,
  hasReaction2 = false,
  hasReaction3 = false,
  hasReaction4 = false,
  counter = 4,
  emoji1,
  emoji2,
  emoji3,
  emoji4,
  chatId,
  handleReactionUsersList
}) => {

  return (
    <>
      <div
        className={`reaction-sec ${isOutgoing ? "isOutgoing" : ""}`}
        style={{ cursor: "pointer" }}
      >
        <div className="reaction-bg-screen">
          <div className="reaction-wrapper flex items-center">
            <MessageReaction
              handleReactionUsersList={() => handleReactionUsersList(chatId)}
              emoji={emoji1}
            />
            {hasReaction2 && (
              <MessageReaction
                handleReactionUsersList={() => handleReactionUsersList(chatId)}
                emoji={emoji2}
              />
            )}
            {hasReaction3 && (
              <MessageReaction
                handleReactionUsersList={() => handleReactionUsersList(chatId)}
                emoji={emoji3}
              />
            )}
            {hasReaction4 && (
              <MessageReaction
                handleReactionUsersList={() => handleReactionUsersList(chatId)}
                emoji={emoji4}
              />
            )}
            {hasCounter && counter > 0 && (
              <>
                <span
                  onClick={() => handleReactionUsersList(chatId)}
                >{counter}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

Reaction.propTypes = {
  isOutgoing: PropTypes.bool,
  hasCounter: PropTypes.bool,
  hasReaction2: PropTypes.bool,
  hasReaction3: PropTypes.bool,
  hasReaction4: PropTypes.bool,
  counter: PropTypes.number,
  emoji1: PropTypes.string,
  emoji2: PropTypes.string,
  emoji3: PropTypes.string,
  emoji4: PropTypes.string,
  chatId: PropTypes.array,
  handleReactionUsersList: PropTypes.func
};

export default Reaction;
