import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import authServiceEvent from "../../../Services/auth.service.event";
import Loader from "../../../Images/loader.gif";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import editFill from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";

const EditCategory = ({ handleClearAllStates, listingApi, categoryId }) => {
  const dispatch = useDispatch();
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [editCategory, setEditCategory] = useState("");
  const [editErr, setEditErr] = useState("");
  const [editSubCatInputErr, setEditSubCatInputErr] = useState("");
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [editLoader, setEditLoader] = useState(false);
  const [editSubCatErr, setEditSubCatErr] = useState("");
  const [editAllSubCategory, setEditAllSubCategory] = useState([]);
  const [editAllNewSubCategory, setEditAllNewSubCategory] = useState([]);
  const [editInprocess, setEditInprocess] = useState(false);
  const [showAlertEdit, setShowAlertEdit] = useState(false);
  const [selecetdSubCatId, setSelecetdSubCatId] = useState("");
  const [selectedSubCatVal, setSelectedSubCatVal] = useState("");
  const [deleteSubCatIds, setdeleteSubCatIds] = useState([]);
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  //called when edit category input change
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditCategory(e.target.value);
    if (e.target.value.length > 0) {
      setEditErr("");
    } else {
      setEditErr("Enter category! ");
    }
  };
  useEffect(() => {
    if (categoryId !== "") {
      getCategoryById(categoryId);
    }
  }, [categoryId]);
  // get category detail api call
  async function getCategoryById(id) {
    setSelecetdSubCatId(-1);
    setEditErr("");
    setEditSubCatErr("");
    setEditAllSubCategory([]);
    setEditAllNewSubCategory([]);
    setEditModalLoader(true);
    try {
      const getCategoryByIdRes = await authServiceEvent.viewCategory(id);
      if (getCategoryByIdRes.status) {
        setEditModalLoader(false);
        setEditCategory(getCategoryByIdRes.data.data.name);
        const arr = getCategoryByIdRes.data.data.subcategory
          ? getCategoryByIdRes.data.data.subcategory
          : [];
        setEditAllSubCategory(arr);
      } else {
        setEditModalLoader(false);
      }
    } catch (error) {
      setEditModalLoader(false);
      return false;
    }
  }

  const handleEditSubmit = () => {
    if (
      editCategory.length > 0 &&
      editSubCatErr.length === 0 &&
      editSubCatInputErr.length === 0
    ) {
      setEditInprocess(true);
      let arr = [...editAllNewSubCategory];

      editAllSubCategory.map((item) => {
        switch (typeof item) {
          case "object":
            arr.push(item.name);
            break;
          case "string":
            arr.push(item);
            break;
          default:
            break;
        }
      });
      const addBodyData = {
        name: editCategory,
        subcategory: arr,
      };
      authServiceEvent
        .editCategory(categoryId, addBodyData)
        .then((res) => {
          if (res.status) {
            listingApi();
            handleClearAllStates();
            setShowAlertEdit(true);
            setAlertMsgEdit(res.data.message);
            setEditAllSubCategory([]);
            setEditAllNewSubCategory([]);
            setdeleteSubCatIds([]);
            setEditCategory("");
            setEditInprocess(false);
            setSelectedSubCatVal("");
            setSelecetdSubCatId(-1);
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
              $("#editCategory").modal("hide");
            }, 2000);
          } else {
            setShowAlertEdit(true);
            setAlertMsgEdit(res.data.message);
            setTimeout(() => {
              setShowAlertEdit(false);
              setAlertMsgEdit("");
            }, 2000);
          }

          dispatch({ type: "REMOVEMODALINPROCESS" });
          setEditInprocess(false);
        })
        .catch((e) => {
          console.log(e);
          dispatch({ type: "REMOVEMODALINPROCESS" });
          setEditInprocess(false);
        });
      setEditErr("");
    } else {
      editCategory.length === 0 && setEditErr("Enter category!");
    }
  };

  //  Called when edit category popup open and edit already exists subcategory
  const handleOnEditAlreadyExistsEditBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      document.getElementById("newEditSubCatValue").value = "";
      setSelecetdSubCatId(subcat._id.toString());
      setSelectedSubCatVal(subcat.name);
    }
  };

  // Called when editcategory popup open and edit subcategory which already added
  const handleAlreadyAddedSubCategory = (editedSubCate, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const bodayData = {
      name: editedSubCate.trim(),
    };
    authServiceEvent
      .editSubCategory(subcat._id, bodayData)
      .then((res) => {
        if (res.status) {
          setEditSubCatInputErr("");
          let arrEditAllExists = [...editAllSubCategory];
          const existCatIndex = editAllSubCategory.findIndex(
            (item) => item._id.toString() === subcat._id.toString()
          );
          arrEditAllExists[existCatIndex].name = editedSubCate;
          setEditAllSubCategory(arrEditAllExists);
          setSelecetdSubCatId(-1);
          setSelectedSubCatVal("");
        } else {
          setEditSubCatInputErr(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //  Called when edit category popup open and update already exists subcategory
  const handleOnEditAlreadyExistsUpdateBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (selectedSubCatVal.length > 0) {
      if (
        editAllSubCategory.filter(
          (item) =>
            item._id.toString() !== selecetdSubCatId &&
            item.name.toLowerCase() === selectedSubCatVal.toLowerCase()
        ).length > 0 ||
        editAllNewSubCategory.some(item=>item.toLowerCase()===selectedSubCatVal.toLowerCase())
      ) {
        setEditSubCatInputErr(
          `Subcategory "${selectedSubCatVal}" already exists. Subcategory  must be unique!`
        );
      } else {
        handleAlreadyAddedSubCategory(selectedSubCatVal, subcat, index);
      }
    } else {
      if (selecetdSubCatId !== -1) {
        setEditSubCatInputErr(`Enter subcategory!`);
      }
    }
  };

  //  Called when edit category popup open and delete already exists subcategory
  const handleOnEditAlreadyExistsDeleteBtnEvent = (e, subcat, index) => {
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      dispatch({ type: "SETMODALINPROCESS" });
      setEditAllSubCategory([
        ...editAllSubCategory.filter((cat, i) => {
          if (i !== index) return cat;
        }),
      ]);
      setdeleteSubCatIds([...deleteSubCatIds, subcat._id]);
    }
  };

  //  Called when edit category popup open and change already exists subcategory
  const handleOnEditAlreadyExistsInputChange = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSelectedSubCatVal(e.target.value.trim());
    let subCatsArr = [...editAllSubCategory];
    const i = editAllSubCategory.findIndex(
      (item) => item._id.toString() === subcat._id.toString()
    );
    subCatsArr[i].name = e.target.value;
    setEditAllSubCategory(subCatsArr);
    setEditSubCatErr("");
    setEditSubCatInputErr("");
    setEditSubCatErr("");
  };

  // Called on click on add button when editcategory popup open
  const handleEditSubCategory = async (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    const subCatVal = document
      .getElementById("newEditSubCatValue")
      .value.trim();
    if (subCatVal.length > 0) {
      setEditLoader(true);
      if (!editAllNewSubCategory.some(item=>item.toLowerCase()===subCatVal.toLowerCase())) {
        const isSubCategoryExists =
          await authServiceEvent.checkAlredayExistSubCategory({
            name: subCatVal,
          });

        if (isSubCategoryExists && !isSubCategoryExists.data.status) {
          setEditLoader(false);
          setEditSubCatErr(isSubCategoryExists.data.message);
        } else {
          setEditLoader(false);
          setEditAllNewSubCategory([...editAllNewSubCategory, subCatVal]);
          document.getElementById("newEditSubCatValue").value = "";
          setEditSubCatErr("");
        }
      } else {
        setEditSubCatErr(
          `Subcategory ${subCatVal} already exists, subcategory must be unique!`
        );
        setEditLoader(false);
      }
    }
  };

  //  Called when edit category popup open and edit newly added subcategory
  const handleOnEditNewSubCategoryEditBtnEvent = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      document.getElementById("newEditSubCatValue").value = "";
      setSelecetdSubCatId(index);
      setSelectedSubCatVal(subcat);
    }
  };

  //  Called when edit category popup open and delete new added subcategory
  const handleOnEditNewSubCategoryDeleteBtnEvent = (e, subcat, index) => {
    if (editSubCatInputErr.length === 0 && editSubCatErr.length === 0) {
      dispatch({ type: "SETMODALINPROCESS" });
      setEditAllNewSubCategory([
        ...editAllNewSubCategory.filter((cat, i) => {
          if (i !== index) return cat;
        }),
      ]);
      setEditSubCatErr("");
      setEditSubCatInputErr("");
    }
  };

  //  Called when edit category popup open and change new added subcategory
  const handleOnEditNewSubCategoryInputChange = (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setSelectedSubCatVal(e.target.value.trim());
    let subCatsArr = [...editAllNewSubCategory];
    subCatsArr[index] = e.target.value;
    setEditAllNewSubCategory(subCatsArr);
    setEditSubCatErr("");
    setEditSubCatInputErr("");
  };

  //  Called when edit category popup open and update newly added subcategory
  const handleOnEditNewSubCategoryUpdateBtnEvent = async (e, subcat, index) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (selectedSubCatVal.trim().length > 0) {
      if (
        editAllNewSubCategory.filter(
          (item, i) => item.toLowerCase().trim() === selectedSubCatVal.toLowerCase().trim() && i !== index
        ).length > 0
      ) {
        setEditSubCatInputErr(
          `Subcategory "${selectedSubCatVal}" already exists. Subcategory must be unique!`
        );
      } else {
        const isSubCategoryExists =
          await authServiceEvent.checkAlredayExistSubCategory({
            name: selectedSubCatVal,
          });
        if (!isSubCategoryExists.data.status) {
          setEditSubCatInputErr(isSubCategoryExists.data.message);
        } else {
          let subCatsArr = [...editAllNewSubCategory];
          subCatsArr[index] = editAllNewSubCategory[index];
          setEditAllNewSubCategory(subCatsArr);
          setEditSubCatErr("");
          setEditSubCatInputErr("");
          setSelecetdSubCatId(-1);
          setSelectedSubCatVal("");
        }
      }
    } else {
      if (selecetdSubCatId !== -1) {
        setEditSubCatInputErr(`Enter subcategory!`);
      }
    }
  };

     //stayonpage  popup for do you want to leave page
     useEffect(() => {
      if (stayOnPage !== null && stayOnPage) {
        if ($("#editCategory").hasClass("show")) {
          $("#editCategory").modal("show");
        }
       
      } else if (stayOnPage !== null && !stayOnPage) {
        if ($("#editCategory").hasClass("show")) {
          $("#editCategory").modal("hide");
        }
       
      }
    }, [stayOnPage]);

  return (
    <div
      className="modal fade edit-user-details-popup"
      id="editCategory"
      tabIndex="-1"
      role="dialog"
      data-backdrop="static"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        {editModalLoader ? (
          <div className="edit-modal-loader">
            <img src={Loader} />
          </div>
        ) : (
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Edit Category</h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#editCategory").modal("hide");
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertEdit && (
                <div className="alert alert-info">{alertMsgEdit}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Category name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Name your category"
                    name="category"
                    onChange={handleEditChange}
                    value={editCategory}
                  />
                </div>
                <span className="error">{editErr}</span>
              </div>
              <h5 className="mb-1">Subcategory name</h5>
              <div className="form-group">
                <div className="form-group-ct">
                  <div
                    className="form-group doc-addsubcat-container-main"
                    id="newEditSubCat"
                  >
                    <input
                      type="text"
                      id="newEditSubCatValue"
                      disabled={editSubCatInputErr.length > 0}
                      onChange={(e) => {
                        setSelectedSubCatVal("");
                        setSelecetdSubCatId(-1);
                        setEditSubCatErr("");
                      }}
                      onKeyUp={(e) => {
                        setSelectedSubCatVal("");
                        setSelecetdSubCatId(-1);
                        e.key === "Enter" && handleEditSubCategory(e);
                      }}
                    />
                    <div className="cstm-subcategory-loader">
                      {editLoader && (
                        <span className="cstm-loadind-add">
                          {" "}
                          <>
                            <img src={Loader} />{" "}
                          </>{" "}
                        </span>
                      )}
                      <button
                        type="button"
                        onClick={(e) => handleEditSubCategory(e)}
                        className="btn"
                        disabled={editSubCatInputErr.length > 0}
                      >
                        Add
                      </button>
                    </div>

                    <span className="error-saubcategor-name">
                      {editSubCatErr}
                    </span>
                  </div>
                  {/* <span class="text-limitaion">Press enter to add a new subcategory (The name must be unique)</span> */}

                  <div className="form-group doc-addedsubcat-container">
                    {editAllSubCategory.map((subcat, index) => {
                      return (
                        <>
                          <div
                            className="form-group-ct doc-subcat-subcontainer"
                            onDoubleClick={(e) =>
                              handleOnEditAlreadyExistsEditBtnEvent(e, subcat)
                            }
                          >
                            <input
                              type="text"
                              name={subcat._id}
                              value={subcat.name}
                              disabled={
                                subcat._id.toString() !== selecetdSubCatId
                              }
                              // disabled={!editExistsSubCatInputEnabled[`${subcat._id.toString()}`]}
                              onChange={(e) => {
                                handleOnEditAlreadyExistsInputChange(
                                  e,
                                  subcat,
                                  index
                                );
                              }}
                              onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                  handleOnEditAlreadyExistsUpdateBtnEvent(
                                    e,
                                    subcat,
                                    index
                                  );
                                }
                              }}
                              onBlur={(e) =>
                                handleOnEditAlreadyExistsUpdateBtnEvent(
                                  e,
                                  subcat,
                                  index
                                )
                              }
                            />

                            {selecetdSubCatId !== subcat._id.toString() && (
                              <>
                                <div className="doc-subcat-subcontainer-icon">
                                  <img
                                    src={editFill}
                                    onClick={(e) => {
                                      handleOnEditAlreadyExistsEditBtnEvent(
                                        e,
                                        subcat,
                                        index
                                      );
                                    }}
                                  />
                                </div>

                                <div className="doc-subcat-subcontainer-icon">
                                  <img
                                    onClick={(e) => {
                                      handleOnEditAlreadyExistsDeleteBtnEvent(
                                        e,
                                        subcat,
                                        index
                                      );
                                    }}
                                    src={imgDelete}
                                  />{" "}
                                </div>
                              </>
                            )}

                            {selecetdSubCatId === subcat._id.toString() &&
                              editSubCatInputErr !== "" && (
                                <span className="error-doc-addedsucat">
                                  {editSubCatInputErr}
                                </span>
                              )}
                          </div>
                        </>
                      );
                    })}
                    {editAllNewSubCategory.map((subcat, index) => {
                      return (
                        <div
                          className="form-group-ct doc-subcat-subcontainer"
                          onDoubleClick={(e) =>
                            handleOnEditNewSubCategoryEditBtnEvent(
                              e,
                              subcat,
                              index
                            )
                          }
                        >
                          <input
                            type="text"
                            name={index}
                            value={subcat}
                            disabled={index !== selecetdSubCatId}
                            onChange={(e) => {
                              handleOnEditNewSubCategoryInputChange(
                                e,
                                subcat,
                                index
                              );
                            }}
                            onKeyUp={(e) => {
                              if (e.key === "Enter") {
                                handleOnEditNewSubCategoryUpdateBtnEvent(
                                  e,
                                  subcat,
                                  index
                                );
                              }
                            }}
                            onBlur={(e) => {
                              handleOnEditNewSubCategoryUpdateBtnEvent(
                                e,
                                subcat,
                                index
                              );
                            }}
                          />

                          {selecetdSubCatId !== index && (
                            <>
                              <div className="doc-subcat-subcontainer-icon">
                                <img
                                  src={editFill}
                                  onClick={(e) => {
                                    handleOnEditNewSubCategoryEditBtnEvent(
                                      e,
                                      subcat,
                                      index
                                    );
                                  }}
                                />
                              </div>

                              <div className="doc-subcat-subcontainer-icon">
                                <img
                                  onClick={(e) => {
                                    handleOnEditNewSubCategoryDeleteBtnEvent(
                                      e,
                                      subcat,
                                      index
                                    );
                                  }}
                                  src={imgDelete}
                                />{" "}
                              </div>
                            </>
                          )}

                          {selecetdSubCatId === index &&
                            editSubCatInputErr !== "" && (
                              <span className="error-doc-addedsucat">
                                {editSubCatInputErr}
                              </span>
                            )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {editInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleEditSubmit}
                >
                  Update Changes
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditCategory;
