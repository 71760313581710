import React from "react";
import ThumbImg from "../../../../Images/thumb-img.png";
import "./Styles/EventThumbImage.css";
import "../../../../Styles/frontendGlobal.css";

const EventThumbImage = ({
  ThumbSrc = ThumbImg,
  imgType = "medium",
  extraClass = "",
}) => {
  return (
    <>
      <div
        className={`event-thumb-img thumb-img-type-${imgType} ${extraClass}`}
      >
        <img src={ThumbSrc} className={`thumb-image`} alt="thumb-img" />
      </div>
    </>
  );
};

export default EventThumbImage;
