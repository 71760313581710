import React from 'react'
import PropTypes from "prop-types"
const AttachmentIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M10.485 13.524h5.555c2.288 0 4.142-1.748 4.142-3.905 0-2.156-1.854-3.905-4.142-3.905h-5.555c-3.682 0-6.667 2.814-6.667 6.286 0 3.472 2.985 6.286 6.667 6.286h8.638a.857.857 0 0 1 0 1.714h-8.638C5.799 20 2 16.418 2 12s3.799-8 8.485-8h5.555C19.332 4 22 6.516 22 9.619c0 3.103-2.668 5.62-5.96 5.62h-5.555c-1.897 0-3.434-1.45-3.434-3.239 0-1.788 1.537-3.238 3.434-3.238h4.597a.857.857 0 0 1 0 1.714h-4.597c-.893 0-1.616.682-1.616 1.524s.723 1.524 1.616 1.524Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
AttachmentIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default AttachmentIcon