import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Loader from "../../../Images/loader.gif";
import moment from 'moment';

const ImportedUsersModal = ({ setModalOpen, usersList, type, rowId }) => {
    
    const userColumns = [
        { id: "name", label: "Name" },
        { id: "verification_url", label: "Verification url" },
        { id: "old_verification_url", label: "Old verification url" },
        
    ];

    const [userRows, setUserRow] = useState([]);
    const [viewUsers, setViewUsers] = useState([]);
    const [userViewLoader, setUserViewLoader] = useState(false);
    const [userPage, setUserPage] = useState(0);
    const [userRowsPerPage, setUserRowsPerPage] = useState(20);
    const [selectError, setSelectError] = useState('');
    const [error, setError] = useState(false);

    // first render when component is mount
    useEffect(() => {
        const arr = usersList.filter((item)=>item._id.toString()===rowId.toString())[0]
        setUserViewLoader(true);
        switch (type) {
            case "added":
                setViewUsers(arr.addedEmails?arr.addedEmails:[] )
                setUserRow(arr.addedEmails? arr.addedEmails: [])
                setUserViewLoader(false)            
                break;
            case "skip":
                setViewUsers(arr.skipEmails? arr.skipEmails: [])
                setUserRow(arr.skipEmails ?arr.skipEmails :[])
                setUserViewLoader(false)            
                break;
            case "total":
                const addArry = (arr.addedEmails? arr.addedEmails : []) 
                const skippedArry = (arr.skipEmails ? arr.skipEmails : []) 

                setViewUsers(addArry.concat(skippedArry))
                setUserRow(addArry.concat(skippedArry))
                setUserViewLoader(false)            
                break;
        }
      
    }, []);


    // Pagination onChange function
    const handleChangeUserPage = (event, newPage) => {
        setUserPage(newPage);
    };

    // Table data shorting function
    const handleSort = (col, direc) => {
        const sortedResult = [...viewUsers];
        if (direc === "asc") {
            sortedResult.sort((firstItem, secondItem) => {
                const itemA = firstItem[col];
                const itemB = secondItem[col];
                return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
            });
        } else if (direc === "desc") {
            sortedResult.sort((firstItem, secondItem) => {
                const itemA = firstItem[col];
                const itemB = secondItem[col];
                return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
            });
        }

        setViewUsers(sortedResult);
        setUserRow(sortedResult);
    };

    return (
        <>
            <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
                <div className="desk-globle-model-box-inner">
                    <div className="modal-content">
                        <div class="cstm-replace-pp">
                            <span class="cstm-rpp-close-btn" onClick={() => { setModalOpen(false); document.body.classList.remove("modal-open"); }}>×</span>
                            <div class="cstm-rpp-title">{type==="added"? "Added": type==="skip" ? "Skipped" : "Total" } Users lists </div>
                            
                            {
                                error ? <div className="export-error">{selectError}</div> : ""
                            }
                            <div className="cstm-cstm-viewlist">
                                <Paper className="uic-tb-mn ">
                                    <TableContainer className=" uic-table-main ">
                                        <Table className="" >
                                            <TableHead className="uic-table-th">
                                                <TableRow>
                                                    {userColumns.map((column) => (
                                                        <TableCell
                                                            className="uic-th-td"
                                                            key={column.id}
                                                        >
                                                            <span className="custom-sorting">
                                                                <i
                                                                    className="fa fa-angle-down"
                                                                    onClick={(e) => {
                                                                        handleSort(column.id, "asc");
                                                                    }}
                                                                ></i>
                                                                <i
                                                                    className="fa fa-angle-up"
                                                                    onClick={(e) => {
                                                                        handleSort(column.id, "desc");
                                                                    }}
                                                                ></i>
                                                            </span>
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className="uic-table-td">
                                                {userViewLoader ? (
                                                    <div className="cstm-data-load">
                                                        <img src={Loader} />
                                                    </div>
                                                ) : userRows && userRows.length > 0 ? (
                                                    userRows
                                                        .slice(userPage * userRowsPerPage, userPage * userRowsPerPage + userRowsPerPage)
                                                        .map((row, index) => {
                                                            const name = row.first_name && row.last_name ? `${row.first_name} ${row.last_name}` 
                                                            : row.first_name && !row.last_name ? `${row.first_name}` 
                                                            : !row.first_name && row.last_name ? `${row.last_name}` 
                                                            : "-"   
                                                            return (
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={index}
                                                                >
                                                                  
                                                                    <TableCell className='uic-th-td'>{name}</TableCell>
                                                                    <TableCell className='uic-th-td'>{row.verification_url}</TableCell>
                                                                    <TableCell className='uic-th-td'>{row.old_verification_url}</TableCell>
                                                                    
                                                                </TableRow>
                                                            );
                                                        })
                                                ) : (
                                                    <span className="no-data-found-pl">No data found</span>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        className="uic-pagination"
                                        rowsPerPageOptions={[]}
                                        component="div"
                                        count={ userRows && userRows.length}
                                        rowsPerPage={userRowsPerPage}
                                        page={userPage}
                                        onPageChange={handleChangeUserPage}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImportedUsersModal;
