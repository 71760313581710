import React from "react";
import ImgEvent from "./../../Images/imgvideo.png";
import imgIcon from "./../../Images/icn_m_pdf.svg";
import sponsorIcon from "./../../Images/heyday-img.png";
import ImageIcon from "../Icons/FileIcons/ImageIcon";

const DocThumbnailSmallImage = ({
  thumbUrl = <ImageIcon />,
  thumbType = "img",
}) => {
  return (
    <div className={`doc-thumb-image imgtype-${thumbType}`}>
      {thumbType === "img" && (
        <img
          src={thumbUrl}
          width={128}
          height={72}
          className="img-fluid thumb-image"
          alt="doc-thumb"
        />
      )}
      {thumbType === "file" && thumbUrl}
    </div>
  );
};

export default DocThumbnailSmallImage;
