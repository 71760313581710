import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/Button/Button";
import StopRecording from "./StopRecording";

// Icons
import CrossIcon from "../../../../components/Icons/GeneralIcons/CrossIcon";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageVoiceMessage.css";

const MessageVoiceMessage = ({ handleClick, handleClickStopRecording }) => {
  const stopButtonRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioBlobRef = useRef();
  const canvasRef = useRef(null);
  const lineContainerRef = useRef(null);
  const cancelClickRef = useRef(null);

  const [recording, setRecording] = useState(true);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [recordingInProgress, setRecordingInProgress] = useState(false);

  // to create live data for audio

  useEffect(() => {
    let obj = {};
    obj.canvas = canvasRef.current;
    obj.ctx = obj.canvas.getContext("2d");
    obj.width = window.innerWidth * 0.9;
    obj.height = 20;
    obj.canvas.width = obj.width * window.devicePixelRatio;
    obj.canvas.height = obj.height * window.devicePixelRatio;
    obj.canvas.style.width = obj.width + "px";
    obj.canvas.style.height = obj.height + "px";
    obj.ctx.scale(window.devicePixelRatio, window.devicePixelRatio);

    const data = document.getElementById("canvas-line-voice");
    data.appendChild(obj.canvas);

    let timeOffset = 100;
    let now = parseInt(performance.now()) / timeOffset;
    let isRecordingStopped = false; // Flag to track recording status

    const loop = () => {
      if (isRecordingStopped) return; // Stop loop if recording is stopped

      obj.ctx.clearRect(0, 0, obj.canvas.width, obj.canvas.height);
      let max = 0;

      if (parseInt(performance.now() / timeOffset) > now) {
        now = parseInt(performance.now() / timeOffset);
        obj.analyser.getFloatTimeDomainData(obj.frequencyArray);
        for (let i = 0; i < obj.frequencyArray.length; i++) {
          if (obj.frequencyArray[i] > max) {
            max = obj.frequencyArray[i];
          }
        }
        let freq = Math.floor(max * 650);

        obj.bars.push({
          x: obj.width,
          y: obj.height / 2 - freq / 2,
          height: freq === 0 ? 2 : freq,
          width: 5,
        });
      }
      draw();
      requestAnimationFrame(loop);
    };
    obj.bars = [];

    const draw = () => {
      for (let i = 0; i < obj.bars.length; i++) {
        const bar = obj.bars[i];
        // Set a threshold frequency value for color change
        const thresholdFrequency = 10;

        // Change the color based on the frequency condition
        // if (bar.height > thresholdFrequency) {
        //   obj.ctx.fillStyle = "rgba(13, 153, 255, 1)";
        // }
        // else {
        //   obj.ctx.fillStyle = "rgba(13, 13, 13, 0.2)";
        // }
        obj.ctx.fillStyle = "rgba(13, 153, 255, 1)";
        obj.ctx.fillRect(bar.x, bar.y, bar.width, bar.height);
        bar.x = bar.x - 2;

        if (bar.x < 1) {
          obj.bars.splice(i, 1);
        }
      }
    };
    // when access microphone
    const soundAllowed = (stream) => {
      let AudioContext = window.AudioContext || window.webkitAudioContext;
      let audioContent = new AudioContext();
      let streamSource = audioContent.createMediaStreamSource(stream);

      obj.analyser = audioContent.createAnalyser();
      streamSource.connect(obj.analyser);
      obj.analyser.fftSize = 512;
      obj.frequencyArray = new Float32Array(obj.analyser.fftSize);
      loop();
      startRecording(stream);
    };

    // when microphone not access
    const soundNotAllowed = () => {
      // cancelClickRef.current = true;
      // handleClick();
      // }
    };
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      // Request microphone access and handle the result in soundAllowed or soundNotAllowed
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(soundAllowed)
        .catch(soundNotAllowed);
    } else {
      console.log("getUserMedia is not supported on this browser.");
      // Optionally, provide a fallback or show an error message to the user.
    }

    // Cleanup function
    return () => {
      isRecordingStopped = true; // Set flag to stop loop on component unmount
    };
  }, []);

  // get duration after 1 sec
  useEffect(() => {
    let intervalId;
    if (recordingInProgress) {
      intervalId = setInterval(() => {
        setRecordingDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [recordingInProgress]);

  // when click on microphone start recording
  const startRecording = (stream) => {
    if (!recordingInProgress) {
      let localStream;
      setRecordingInProgress(true);
      localStream = stream;
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];
      let startTime;
      let recordingTimeout;
      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunks.push(e.data);
        }
      };
      // start recording in when start click
      mediaRecorder.onstart = () => {
        startTime = new Date().getTime();
        const remainingTime = 180000 - recordingDuration * 1000; // Calculate remaining time
        recordingTimeout = setTimeout(() => {
          stopRecording();
        }, remainingTime);
      };
      // stop recording in when stop click
      mediaRecorder.onstop = () => {
        clearTimeout(recordingTimeout);
        const endTime = new Date().getTime();
        const duration = (endTime - startTime) / 1000; // Convert to seconds
        setRecordingDuration(formatDuration(duration));
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        audioBlobRef.current = audioBlob;
        setAudioBlob(audioBlob);
        setRecordingInProgress(false);
        if (!cancelClickRef.current) {
          handleClickStopRecording(recordingDuration, audioBlob);
        }
        // Stop each track in the stream
        localStream.getTracks().forEach((track) => track.stop());
      };
      mediaRecorderRef.current = mediaRecorder;
      setRecording(true);

      // Start recording
      mediaRecorder.start();
    }
  };

  // when click on stop recording
  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      let oldcanv = document.getElementById("canvas");
      const data = document.getElementById("canvas-line-voice");
      data.removeChild(oldcanv);
      // Handle stopping the stream and tracks properly
      if (mediaRecorderRef.current.stream) {
        const tracks = mediaRecorderRef.current.stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      setRecording(false);
      setRecordingInProgress(false);
      handleClick();
      handleClickStopRecording(recordingDuration, audioBlob);
    }
  };

  // when click on cross button
  const cancelRecording = () => {
    cancelClickRef.current = true;
    // Implement cancellation
    if (recordingInProgress) {
      setRecording(false);
      setRecordingInProgress(false);

      // Stop recording and clear any ongoing timeout
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
      clearTimeout(mediaRecorderRef.current.recordingTimeout);
      setRecordingDuration(0);
      setAudioBlob(null);

      // Handle stopping the stream and tracks properly
      if (mediaRecorderRef.current.stream) {
        const tracks = mediaRecorderRef.current.stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      handleClick();
    }
  };

  // formate duration for audio
  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    // Use padStart to ensure two-digit format
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    if (formattedMinutes !== "NaN" || formattedSeconds !== "NaN") {
      return `${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `0:00`;
    }
  };

  return (
    <>
      <div className="MessageVoiceMessage-sec flex items-center">
        <Button
          hasIconLeft
          elementIcon={<CrossIcon />}
          iconColor="#394046"
          hasText={false}
          onContextMenu={() => { }}
          onMouseEnter={() => { }}
          onMouseLeave={() => { }}
          handleClick={cancelRecording}
          size="extrasmall"
          variant="ghost"
          toolTipText="Cancel"
        />
        <div className="MessageVoiceMessage-content flex items-center">
          {recording && (
            <div className="MessageVoiceMessage-content-voice">
              <div
                id="canvas-line-voice"
                className="line-container1"
                ref={lineContainerRef}
              >
                <canvas id="canvas" ref={canvasRef}></canvas>
                <div className="vertical-line1" />
                <div className="dot1" />
                <div className="dot1" />
              </div>
            </div>
          )}
          <p>{formatDuration(recordingDuration)}</p>
        </div>
        <StopRecording handleClick={stopRecording} ref={stopButtonRef} />
      </div>
    </>
  );
};

MessageVoiceMessage.propTypes = {
  handleClick: PropTypes.func,
  handleClickStopRecording: PropTypes.func,
};

export default MessageVoiceMessage;
