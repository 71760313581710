import React from "react";

const IconNavEventFilled = () => {
  return (
    <div className="mds-icons filled">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M16 1.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V2a.75.75 0 0 1 .75-.75Zm2 1.957c2.308.653 4 2.775 4 5.293v8a5.5 5.5 0 0 1-5.5 5.5h-9A5.5 5.5 0 0 1 2 16.5v-8a5.502 5.502 0 0 1 4-5.293V5a2 2 0 1 0 4 0V3h4v2a2 2 0 1 0 4 0V3.207Zm-.47 6.263a.75.75 0 0 1 0 1.06l-5.793 5.793a1.75 1.75 0 0 1-2.474 0L7.47 14.53a.75.75 0 1 1 1.06-1.06l1.793 1.793a.25.25 0 0 0 .354 0L16.47 9.47a.75.75 0 0 1 1.06 0ZM8.75 2a.75.75 0 0 0-1.5 0v3a.75.75 0 0 0 1.5 0V2Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavEventFilled;
