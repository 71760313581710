import { createContext, useContext } from 'react';

const TableContext = createContext();

export const useTableContext = () => {
  return useContext(TableContext);
};

export const TableProvider = ({ children, isHead }) => {
  return (
    <TableContext.Provider value={{ isHead }}>
      {children}
    </TableContext.Provider>
  );
};
