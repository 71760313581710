import React from 'react'
import PropTypes from "prop-types"
const AddUserIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M9.846 12.746h.031c2.933 0 5.318-2.41 5.318-5.373C15.195 4.411 12.81 2 9.877 2 6.945 2 4.56 4.41 4.56 7.37c-.01 2.899 2.372 5.366 5.286 5.376ZM6.06 7.373c0-2.127 1.713-3.857 3.817-3.857 2.105 0 3.818 1.73 3.818 3.857 0 2.127-1.713 3.858-3.818 3.858-2.102-.009-3.825-1.76-3.817-3.858ZM2 18.282C2 22 7.929 22 9.877 22c3.382 0 7.877-.383 7.877-3.696 0-3.719-5.929-3.719-7.877-3.719-3.382 0-7.877.383-7.877 3.697Zm1.5 0c0-1.447 2.146-2.181 6.377-2.181s6.377.74 6.377 2.203c0 1.446-2.146 2.18-6.377 2.18-4.23 0-6.377-.741-6.377-2.202Z"
                clipRule="evenodd"
            />
            <path
                fill={iconColor}
                d="M19.204 13.548a.754.754 0 0 1-.75-.758v-1.269H17.16a.754.754 0 0 1-.75-.757c0-.419.336-.758.75-.758h1.294V8.738c0-.418.336-.758.75-.758s.75.34.75.758v1.268h1.296c.414 0 .75.34.75.758a.754.754 0 0 1-.75.757h-1.296v1.27a.754.754 0 0 1-.75.757Z"
            />
        </svg>
    )
}
AddUserIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default AddUserIcon