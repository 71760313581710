import React from "react";
import PropTypes from "prop-types";
import "../Styles/ChatTitle.css";
import "../../../../Styles/Theme/Theme.css";
import IconSNotificationMute from "../../../../components/Icons/GeneralIcons/GeneralIconsSmall/IconSNotificationMute";
import IconSoundMute from "../../../../components/Icons/GeneralIcons/GeneralIconsSmall/IconSoundMute";

const ChatMessageType = ({ type = "NotMuted", name = "Name" }) => {
  return (
    <>
      <div className="chat-title flex items-center">
        <span className="fw-semibold"> {name}</span>
        {type === "NotMuted" && <></>}
        {type === "SoundOff" && (
          <>
            <IconSoundMute
              width={16}
              height={16}
              iconColor="var(--color-icon-neutral-tertiary)"
              isFilled
            />
          </>
        )}
        {type === "Muted" && (
          <>
            <IconSNotificationMute
              height={16}
              iconColor="var(--color-icon-neutral-tertiary)"
              isFilled
              width={16}
            />
          </>
        )}
      </div>
    </>
  );
};
// define types to all props
ChatMessageType.propTypes = {
  type: PropTypes.oneOf(["NotMuted", "SoundOff", "Muted"]),
  name: PropTypes.string,
};

export default ChatMessageType;
