import React from 'react'
import "./HashTag.css"
import "../../Styles/Theme/Theme.css"
import PropTypes from 'prop-types'

const HashTag = ({ size = "small", tag = "#HashTag" }) => {
    return (
        <button className={`hashtag ${size}`}>{tag}</button>
    )
}

HashTag.propTypes = {
    size: PropTypes.oneOf(["small", "xsmall"]),
    tag: PropTypes.string
} 
 
export default HashTag