import React, { useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import NotificationBadge from "../../../components/Badge/NotificationBadge";
import Avtar from "../../../components/Avtar/Avtar";
import "../Styles/notificationscard.css";
import DotsIcon from "../../../components/Icons/GeneralIcons/DotsIcon";
import Button from "../../../components/Button/Button";
import Typography from "../../../components/Typography/Typography";
import DropDownItem from "../../../components/DropDownItem/DropDownItem";
import authNotification from "../../../Services/auth.notification";
import { useDispatch, useSelector } from "react-redux";
import { notificationlistAction } from "../../../Action";
import HideIcon from "../../../components/Icons/GeneralIcons/HideIcon";
import TickIcon from "../../../components/Icons/GeneralIcons/TickIcon";
import MdsIcon from "../../../components/Icons/GeneralIcons/MdsIcon";
const NotificationsCard = ({
  title,
  subTitle,
  date,
  type,
  hasImage,
  hasPoint,
  id,
  deepLink,
  // icon=file,
  src,
}) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [isDropDownVisible, setDropDownVisible] = useState(false);
  const [context, setContext] = useState({ x: 0, y: 0 })
  const [menuContext, setMenuContext] = useState({ x: 0, y: 0 })
  
  let pageNo = 1;
  let limit = 10;
  
  useEffect(() => {
    let notification = document.querySelector(".notifications-in-body");
    notification.addEventListener("scroll", handleScroll);
    return () => {
      return () => notification.removeEventListener("scroll", handleScroll);
    }

    
  }, []);

  const handleScroll = () => {
    let notification = document.querySelector(".notifications-in-body");

    if (
      notification.scrollTop + notification.offsetHeight ===
      notification.scrollHeight
    ) {
      // if (notificationData.totalPages > pageNo) {
      //     pageNo = pageNo + 1
      // }
      if (notificationData.totalNotification > limit) {
        limit = notificationData.totalNotification;
      }

      notificationList();
    }
  };

  // use selectors
  const notificationData = useSelector((state) => {
    return state?.NotificationReducer.notificationList;
  });

  // Dropdown Menu options change text according to read and unread data
  const options = [
    { id, option_id: 1, icon: <HideIcon />, name: "Hide this notification" },
    {
      id,
      option_id: 2,
      icon: <TickIcon />,
      name: notificationData.notification.find(
        (item) => item?.notificationId?._id == id && item?.isRead
      )
        ? "Mark as unread"
        : "Mark as read",
    },
  ];

  // calling Api
  async function notificationList() {
    try {
      const notificationList = await authNotification.getNotificationList(
        pageNo,
        limit
      );
      if (notificationList.status) {
        dispatch(notificationlistAction(notificationList.data.data));
      }
    } catch (error) {
      console.log("error:-----------", error);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onOutsideClick();
    }
  };

  const onOutsideClick = () => {
    setDropDownVisible(false);
  };

  const onSelect = async (selectedOption) => {
    if (selectedOption.option_id == 1) {
      let data = { notificationIds: [selectedOption.id] };
      try {
        const deleteNotification =
          await authNotification.deleteNotificationByUser(data);
        if (deleteNotification.status === 200) {
          notificationList();
        }
      } catch (error) {
        console.log("error:-----------", error);
      }
    }

    if (selectedOption.option_id == 2) {
      let readNotification = notificationData.notification.find(
        (item) => item?.notificationId?._id == id && item?.isRead
      )
        ? false
        : true;
      let data = {
        notificationIds: [selectedOption.id],
        isRead: readNotification,
      };
      try {
        const markasRead = await authNotification.markNotificationRead(data);
        if (markasRead.status === 200) {
          notificationList();
        }
      } catch (error) {
        console.log("error:-----------", error);
      }
    }
    setDropDownVisible(false);
  };

  const handleDotsIconClick = (e, id) => {
    var button = document.querySelector(`.card-${id} .button`);
    var rectButton = button.getBoundingClientRect();
    var tooltip = document.getElementById(`moreButton${id}`);
    var rectTooltip = tooltip.getBoundingClientRect();
    let x = e.clientX > window.innerWidth ? e.clientX + 40 : e.clientX + 40;
    let y = e.clientY > window.innerHeight - 170 ? e.clientY - 170 : e.clientY - 10; // Adjusted y position
    
    let tooltipY = rectButton.top - rectTooltip.height - 10;
    
    if (y + rectTooltip.height > window.innerHeight && tooltipY < 0) {
        y = e.clientY - rectTooltip.height + 30;
    } else {
      if (((window.innerHeight - e.clientY)) > 0 && ((window.innerHeight - e.clientY) < 180))
       y = y //(last tooltip)
      else
       y = e.clientY - rectTooltip.height - 5
    }
    setContext({ x: x, y: y });
    setDropDownVisible(!isDropDownVisible);
    
  };
  const onLeaveHandler = () => {
    setDropDownVisible(false);
  };

  const deepLinkHandler = async (e, id) => {
    let readNotificationData = notificationData.notification.filter(item => item.notificationId._id.toString() == id.toString());
    if (!readNotificationData[0].isRead)
    {
      let data = {
        notificationIds: [id],
        isRead: true,
      };
      try {
        const markasRead = await authNotification.markNotificationRead(data);
        if (markasRead.status === 200) {
        
  
        
        }
      } catch (error) {
        console.log("error:-----------", error);
      }
    }
    notificationList();
    window.open(deepLink,'_self');
  };
  return (
    <>
      <div className={`notification-card card-${id}`} id={id}  onMouseLeave={onLeaveHandler}>
        <span className="notification-card-badge">
          {hasPoint && <NotificationBadge />}
        </span>

        <div className="notification-card-body">
          <div
            className="notification-card-body-container"
            onClick={(e) => deepLinkHandler(e, id)}
          >
            <div className="notification-card-body-container-avtar">
            <Avtar
  size="32"
  src="https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png"
  type="mds"
/>
            </div>
            <div className="notification-card-body-container-text">
              <div className="notification-card-body-container-text-sub">
                <Typography variant="h6">{title}</Typography>
                <span className="notification-card-body-container-text-subtitle">
                  {/* {subTitle} */}
                </span>
              </div>
              <div className="notification-card-body-container-text-date">
                {date}
              </div>
            </div>
          </div>
          {hasImage && (
            <div
              className="notification-card-body-thumbnail"
              onClick={() => window.open(deepLink,'_self')}
            >
              {src && (
                <img src={process.env.REACT_APP_AWS_IMG_VID_PATH + src} />
              )}
            </div>
          )}

          <div className="notification-card-body-btn" ref={wrapperRef}>
            <Button
              elementIcon={<DotsIcon />}
              onMouseEnter={() => {}}
              onMouseLeave={() => {}}
              onContextMenu={() => {}}
              handleClick={(e)=>handleDotsIconClick(e,id)}
              size="small"
              variant="ghost"
              hasText={false}
              hasToolTip={false}
              toolTipText="More"
              toolTipId="moreToolTip"
              buttonId={`moreButton${id}`}
            ></Button>

            {isDropDownVisible && (
              <div className='notification-table-action-icon' style={{ top: context?.y ?? 0 + "px", left: context?.x ?? 0 + "px", position: "fixed", zIndex: "4", display: `${isDropDownVisible ? "block" : "none"}` }}>
                <DropDownItem
                isIcon
                onSelect={onSelect}
                options={options}
                size="medium"
              /></div>
              
            )}
          </div>
        </div>
      </div>
    </>
  );
};

NotificationsCard.propTypes = {
  hasPoint: PropTypes.bool,
  hasImage: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  date: PropTypes.string,
};

export default NotificationsCard;
