import axios from "axios";
import authAdminHeader from "./auth.admin.header";
import authHeader from "./auth.header";
const API_URL = process.env.REACT_APP_DOCUMENTS_API_URL;
{
  /* admin api calls*/
}
const addDocument = (data) => {
  return axios.post(API_URL + "documents/add", data, {
    headers: authAdminHeader(),
  });
};

const getDocumentList = (query) => {
  return axios.get(API_URL + "documents/list" + query, {
    headers: authAdminHeader(),
  });
};

const deleteDocument = (id) => {
  return axios.patch(
    API_URL + "documents/delete/" + id,
    {},
    { headers: authAdminHeader() }
  );
};

const getDocumentDetail = (id) => {
  return axios.get(API_URL + "documents/view/" + id, {
    headers: authAdminHeader(),
  });
};
const editDocument = (id, data) => {
  return axios.post(API_URL + "documents/edit/" + id, data, {
    headers: authAdminHeader(),
  });
};
const categoryList = (id) => {
  return axios.get(API_URL + "documents/getAllDocCategory", {
    headers: authAdminHeader(),
  });
};
const documentTypeList = (id) => {
  return axios.get(API_URL + "/documents/documentTypeList", {
    headers: authAdminHeader(),
  });
};
const statusList = (id) => {
  return axios.get(API_URL + "/documents/getAllDocSubmissionStates", {
    headers: authAdminHeader(),
  });
};
const hashTagsList = (id) => {
  return axios.get(API_URL + "documents/getAllDocHashTags", {
    headers: authAdminHeader(),
  });
};
const addCategory = (data) => {
  return axios.post(API_URL + "documents/category/add", data, {
    headers: authAdminHeader(),
  });
};

const viewCategory = (id) => {
  return axios.get(API_URL + "documents/category/view/" + id, {
    headers: authAdminHeader(),
  });
};
const editCategory = (id, data) => {
  return axios.patch(API_URL + "documents/category/edit/" + id, data, {
    headers: authAdminHeader(),
  });
};
const deleteCategory = (id) => {
  return axios.delete(API_URL + "documents/category/delete/" + id, {
    headers: authAdminHeader(),
  });
};

const uploadMediaFie = (data, onUploadProgress) => {
  return axios.post(API_URL + "documents/addMedia", data, {
    headers: authAdminHeader(),
    onUploadProgress: onUploadProgress,
  });
};

const uploadFiePreview = (data, onUploadProgress) => {
  return axios.post(API_URL + "documents/addMediaPreview", data, {
    headers: authAdminHeader(),
    onUploadProgress: onUploadProgress,
  });
};

const deleteMediaFie = (data) => {
  return axios.post(API_URL + "documents/deleteMedia", data, {
    headers: authAdminHeader(),
  });
};
const createReason = (data) => {
  return axios.post(API_URL + "documents/reason", data, {
    headers: authAdminHeader(),
  });
};

const editReason = (data) => {
  return axios.patch(API_URL + "documents/reason", data, {
    headers: authAdminHeader(),
  });
};

const viewReason = (id) => {
  return axios.get(API_URL + "documents/reason/" + id, {
    headers: authAdminHeader(),
  });
};

const listReason = (query) => {
  return axios.get(API_URL + "documents/reason/" + query, {
    headers: authAdminHeader(),
  });
};

const deleteReason = (query) => {
  return axios.delete(API_URL + "documents/reason/" + query, {
    headers: authAdminHeader(),
  });
};

const createContentType = (data) => {
  return axios.post(API_URL + "documents/contentType/add", data, {
    headers: authAdminHeader(),
  });
};

const editContentType = (id, data) => {
  return axios.patch(API_URL + "documents/contentType/edit/" + id, data, {
    headers: authAdminHeader(),
  });
};

const viewContentType = (id) => {
  return axios.get(API_URL + "documents/contentType/getById/" + id, {
    headers: authAdminHeader(),
  });
};

const contentTypeList = (query = "") => {
  return axios.get(API_URL + "documents/contentType/list" + query, {
    headers: authAdminHeader(),
  });
};

const deleteContentType = (query) => {
  return axios.delete(API_URL + "documents/contentType/delete/" + query, {
    headers: authAdminHeader(),
  });
};

const updateDocStatus = (data) => {
  return axios.post(API_URL + "documents/update-visibility", data, {
    headers: authAdminHeader(),
  });
};

const adminSuggestionList = () => {
  return axios.get(API_URL + "documents/all", { headers: authAdminHeader() });
};

const userWiseViewDocumentList = (id, query) => {
  return axios.get(
    API_URL + `documents/userWiseViewDocumentList/${id}${query}`,
    { headers: authAdminHeader() }
  );
};

const userWiseDownalodList = (id, query) => {
  return axios.get(API_URL + `documents/userWiseDownalodList/${id}${query}`, {
    headers: authAdminHeader(),
  });
};

const downloadsDocumentsUserList = (query) => {
  return axios.get(
    API_URL + "documents/moreThen5DownloadsDocumentsUserList" + query,
    { headers: authAdminHeader() }
  );
};

const updateUserDocumentDownloadBlockStatus = (data) => {
  return axios.patch(
    API_URL + `documents/updateUserDocumentDownloadBlockStatus`,
    data,
    { headers: authAdminHeader() }
  );
};
{
  /* user api calls*/
}
const userCategoryList = () => {
  return axios.get(API_URL + "documents/getAllDocCategory", {
    headers: authHeader(),
  });
};

const userDocList = (query) => {
  return axios.get(API_URL + "documents/list" + query, {
    headers: authHeader(),
  });
};

const userContentTypeList = () => {
  return axios.get(API_URL + "documents/contentType/list", {
    headers: authHeader(),
  });
};

const resultCount = (query) => {
  return axios.get(API_URL + "documents/filterAccordingDocCounts" + query, {
    headers: authHeader(),
  });
};

const userList = () => {
  return axios.get(API_URL + "documents/users/list", { headers: authHeader() });
};

const saveDocList = (query) => {
  return axios.get(API_URL + "documents/docSaves/list" + query, {
    headers: authHeader(),
  });
};

const saveDoc = (id) => {
  return axios.get(API_URL + "documents/save-doc/" + id, {
    headers: authHeader(),
  });
};

const userSuggestionList = (query) => {
  return axios.get(API_URL + "documents/all" + query, {
    headers: authHeader(),
  });
};

const addSearchHistory = (data) => {
  return axios.post(API_URL + "documents/search-history/add", data, {
    headers: authHeader(),
  });
};

const getSearchHistory = (query) => {
  return axios.get(API_URL + "documents/search-history/get" + query, {
    headers: authHeader(),
  });
};

const deleteSearchHistory = (query) => {
  return axios.delete(API_URL + "documents/search-history/delete/" + query, {
    headers: authHeader(),
  });
};

const getDocById = (query) => {
  return axios.get(API_URL + "documents/view/" + query, {
    headers: authHeader(),
  });
};

const updateDocumentViewsCount = (docId) => {
  return axios.get(
    API_URL + "documents/updateUserViewCountOfDocuments/" + docId,
    { headers: authHeader() }
  );
};
const updateDocumentDownloadCount = (docId) => {
  return axios.get(
    API_URL + "documents/updateUserDownloadCountOfDocuments/" + docId,
    { headers: authHeader() }
  );
};
const getUserwiseDownloadDocumentList = (query) => {
  return axios.get(
    API_URL + "documents/userWiseDownloadDocumentCountList/" + query,
    { headers: authAdminHeader() }
  );
};

const editSubCategory = (id, data) => {
  return axios.post(API_URL + "documents/subCategory/edit/" + id, data, {
    headers: authAdminHeader(),
  });
};

const checkAlredayExistSubCategory = (data) => {
  return axios.post(API_URL + "documents/checkAlredayExistSubCategory", data, {
    headers: authAdminHeader(),
  });
};

//delete document category
const deleteDocumentCategoryApi = (body) => {
  return axios.patch(API_URL + "documents/category/delete", body, {
    headers: authAdminHeader(),
  });
};

const documentUserCount = (data) => {
  return axios.post(API_URL + "documents/userCount", data, {
    headers: authAdminHeader(),
  });
};

//get reassigned document
const reAssignDocumentCategoryApi = (docId) => {
  return axios.get(API_URL + "documents/category/reassignCategories/" + docId, {
    headers: authAdminHeader(),
  });
};
export default {
  uploadMediaFie,
  listReason,
  deleteMediaFie,
  createReason,
  editReason,
  viewReason,
  deleteReason,
  addDocument,
  deleteDocument,
  getDocumentList,
  getDocumentDetail,
  editDocument,
  categoryList,
  documentTypeList,
  contentTypeList,
  statusList,
  hashTagsList,
  addCategory,
  deleteCategory,
  viewCategory,
  editCategory,
  createContentType,
  deleteContentType,
  editContentType,
  viewContentType,
  updateDocStatus,
  adminSuggestionList,
  userCategoryList,
  userDocList,
  userContentTypeList,
  resultCount,
  userList,
  saveDoc,
  saveDocList,
  userSuggestionList,
  addSearchHistory,
  getSearchHistory,
  deleteSearchHistory,
  getDocById,
  updateDocumentViewsCount,
  updateDocumentDownloadCount,
  getUserwiseDownloadDocumentList,
  userWiseViewDocumentList,
  userWiseDownalodList,
  downloadsDocumentsUserList,
  updateUserDocumentDownloadBlockStatus,
  editSubCategory,
  checkAlredayExistSubCategory,
  uploadFiePreview,
  deleteDocumentCategoryApi,
  reAssignDocumentCategoryApi,
  documentUserCount,
};
