import React from "react";
import PropTypes from "prop-types"
function NavVideoCategoryIcon({ height = 24, width = 24, iconColor = "#000", isFilled }) {
    return (
        <>
            {isFilled ?
                <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M7.733 2.4a3.2 3.2 0 0 1 3.2 3.2v2.133a3.2 3.2 0 0 1-3.2 3.2H5.6a3.2 3.2 0 0 1-3.2-3.2V5.6a3.2 3.2 0 0 1 3.2-3.2h2.133ZM10.933 16.267a3.2 3.2 0 0 0-3.2-3.2H5.6a3.2 3.2 0 0 0-3.2 3.2V18.4a3.2 3.2 0 0 0 3.2 3.2h2.133a3.2 3.2 0 0 0 3.2-3.2v-2.133ZM16.266 2.4a3.2 3.2 0 0 0-3.2 3.2v2.133a3.2 3.2 0 0 0 3.2 3.2H18.4a3.2 3.2 0 0 0 3.2-3.2V5.6a3.2 3.2 0 0 0-3.2-3.2h-2.134ZM18.4 4h-2.134a1.6 1.6 0 0 0-1.6 1.6v2.133a1.6 1.6 0 0 0 1.6 1.6H18.4a1.6 1.6 0 0 0 1.6-1.6V5.6A1.6 1.6 0 0 0 18.4 4ZM13.066 16.267a3.2 3.2 0 0 1 3.2-3.2H18.4a3.2 3.2 0 0 1 3.2 3.2V18.4a3.2 3.2 0 0 1-3.2 3.2h-2.134a3.2 3.2 0 0 1-3.2-3.2v-2.133Z"
                        clipRule="evenodd"
                    />
                </svg > : <svg
                    xmlns="https://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <path
                        fill={iconColor}
                        fillRule="evenodd"
                        d="M7.733 2.4a3.2 3.2 0 0 1 3.2 3.2v2.133a3.2 3.2 0 0 1-3.2 3.2H5.6a3.2 3.2 0 0 1-3.2-3.2V5.6a3.2 3.2 0 0 1 3.2-3.2h2.133ZM5.6 4h2.133a1.6 1.6 0 0 1 1.6 1.6v2.133a1.6 1.6 0 0 1-1.6 1.6H5.6a1.6 1.6 0 0 1-1.6-1.6V5.6A1.6 1.6 0 0 1 5.6 4ZM10.933 16.267a3.2 3.2 0 0 0-3.2-3.2H5.6a3.2 3.2 0 0 0-3.2 3.2V18.4a3.2 3.2 0 0 0 3.2 3.2h2.133a3.2 3.2 0 0 0 3.2-3.2v-2.133Zm-3.2-1.6H5.6a1.6 1.6 0 0 0-1.6 1.6V18.4A1.6 1.6 0 0 0 5.6 20h2.133a1.6 1.6 0 0 0 1.6-1.6v-2.133a1.6 1.6 0 0 0-1.6-1.6ZM16.266 2.4a3.2 3.2 0 0 0-3.2 3.2v2.133a3.2 3.2 0 0 0 3.2 3.2H18.4a3.2 3.2 0 0 0 3.2-3.2V5.6a3.2 3.2 0 0 0-3.2-3.2h-2.134ZM18.4 4h-2.134a1.6 1.6 0 0 0-1.6 1.6v2.133a1.6 1.6 0 0 0 1.6 1.6H18.4a1.6 1.6 0 0 0 1.6-1.6V5.6A1.6 1.6 0 0 0 18.4 4ZM13.066 16.267a3.2 3.2 0 0 1 3.2-3.2H18.4a3.2 3.2 0 0 1 3.2 3.2V18.4a3.2 3.2 0 0 1-3.2 3.2h-2.134a3.2 3.2 0 0 1-3.2-3.2v-2.133Zm3.2-1.6H18.4a1.6 1.6 0 0 1 1.6 1.6V18.4a1.6 1.6 0 0 1-1.6 1.6h-2.134a1.6 1.6 0 0 1-1.6-1.6v-2.133a1.6 1.6 0 0 1 1.6-1.6Z"
                        clipRule="evenodd"
                    />
                </svg>
            }
        </>
    );
}
NavVideoCategoryIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
    isFilled: PropTypes.bool
}
export default NavVideoCategoryIcon;
