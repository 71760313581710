import moment from 'moment';
// Icons
import IconNonAccess from '../../GlobalComponents/Icons/IconEmptyState/IconNonAccess';
import IconReminder from '../../GlobalComponents/Icons/IconEmptyState/IconReminder';
import IconTimeLapse from '../../GlobalComponents/Icons/IconEmptyState/IconTimeLapse';

const CensusDataModal = ({ access, setAccess, access1, setAccess1, access2, setAccess2 }) => {
    const handlecloseexpire = () => {
        window.open("https://marketing824423.typeform.com/to/DXs5mhZn#email="+ localStorage.getItem("useremailid") +"&first_name="+ localStorage.getItem("username"), "_blank")

    }
    const handleclose = () => {
        setAccess(false)
        setAccess1(false)
        setAccess2(false);
        localStorage.setItem("dontshowcensuspopup", true);
        localStorage.setItem("remindmetomorrowcensus", null);
        window.open("https://marketing824423.typeform.com/to/DXs5mhZn#email="+ localStorage.getItem("useremailid") +"&first_name="+ localStorage.getItem("username"), "_blank")
    }
    const handleRemindMeTomorrow = () => {
        localStorage.setItem("remindmetomorrowcensus", moment().add(1, 'days').format("YYYY-MM-DD"))
        localStorage.setItem("dontshowcensuspopup", false);
        setAccess(false)
        setAccess1(false)
        setAccess2(false);
    }
    return (
        <>
            {
                access2 &&
                <>
                    <div className="access-expired-page">
                        <div className="access-expires-page-icon">
                        <IconNonAccess />
                        </div>
                        <div className="access-expires-page-cont">
                            <h4 className="access-expires-page-title">Your #MDSOnly Access Expires</h4>
                            <p className="access-expires-page-p">Regain access to #MDSOnly content by updating your census data.</p>
                        </div>
                        <div className="access-expires-page-btn">
                            <button class="btn-cancel" onClick={handlecloseexpire}>Update now</button>
                        </div>
                    </div>
                </>
            }

            {
                ( localStorage.getItem("remindmetomorrowcensus") && moment(localStorage.getItem("remindmetomorrowcensus"),"YYYY-MM-DD").diff(moment().format("YYYY-MM-DD"), 'days') === 0 && localStorage.getItem("dontshowcensuspopup") === "false" && access ) || (((localStorage.getItem("remindmetomorrowcensus") === null || localStorage.getItem("remindmetomorrowcensus") === undefined) || (localStorage.getItem("remindmetomorrowcensus") && moment(localStorage.getItem("remindmetomorrowcensus"),"YYYY-MM-DD").diff(moment().format("YYYY-MM-DD"), 'days') !== 0)) && (localStorage.getItem("dontshowcensuspopup") === "false" || localStorage.getItem("dontshowcensuspopup") === null || localStorage.getItem("dontshowcensuspopup") === undefined) && access) ?
                <>
                    <div >
                        <div className="desk-globle-model-box globle-model-box ">
                            <div className="access-expires-soon">
                                <div className="access-expires-soon-icon">
                                    <IconReminder/>
                                </div>
                                <div className="access-expires-soon-cont">
                                    <h4 className="access-expires-soon-title">Your #MDSOnly access expires soon!</h4>
                                    <p className="access-expires-soon-p">To continue enjoying #MDSOnly content, make sure to update your Census Data.</p>
                                </div>
                                <div className="access-expires-soon-btn">
                                    <button class="btn-cancel" onClick={handleRemindMeTomorrow}>Remind me tomorrow</button>
                                    <button class="btn-review" onClick={handleclose}>Update now</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </> :<></>
            }

            {
                ( localStorage.getItem("remindmetomorrowcensus") && moment(localStorage.getItem("remindmetomorrowcensus"),"YYYY-MM-DD").diff(moment().format("YYYY-MM-DD"), 'days') === 0 && localStorage.getItem("dontshowcensuspopup") === "false" && access1 ) || (((localStorage.getItem("remindmetomorrowcensus") === null || localStorage.getItem("remindmetomorrowcensus") === undefined) || (localStorage.getItem("remindmetomorrowcensus") && moment(localStorage.getItem("remindmetomorrowcensus"),"YYYY-MM-DD").diff(moment().format("YYYY-MM-DD"), 'days') !== 0)) && (localStorage.getItem("dontshowcensuspopup") === "false" || localStorage.getItem("dontshowcensuspopup") === null || localStorage.getItem("dontshowcensuspopup") === undefined) && access1) ?
                <>
                    <div >
                        <div className="desk-globle-model-box globle-model-box ">
                            <div className="access-expires-soon">
                                <div className="access-expires-soon-icon">
                                <IconTimeLapse />
                                </div>
                                <div className="access-expires-soon-cont">
                                    <h4 className="access-expires-soon-title">Your #MDSOnly access expires TOMORROW</h4>
                                    <p className="access-expires-soon-p">To continue enjoying #MDSOnly content, make sure to update your Census Data.</p>
                                </div>
                                <div className="access-expires-soon-btn">
                                    <button class="btn-cancel">Remind me tomorrow</button>
                                    <button class="btn-review" onClick={handleclose}>Update now</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </> : <></>
            }

        </>
    )
}

export default CensusDataModal