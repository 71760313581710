import ErrorFour from "../../src/Images/404Error.svg";

import { useNavigate, useLocation } from "react-router-dom";
import Header from "./UserComponents/Dashboard/Header";
import Services from "../Services/auth.service";
import logo from "../Images/logo_mds_small.svg";
import user from "../Images/BackendImages/user.png";
import Logouticon from "../Images/logouticon.svg";
import { useEffect, useState } from "react";
import LoginAccessPage from "./LoginAccessPage.js";

const PageNotFound = () => {
  const location = useLocation();
  let history = useNavigate();
  const [isUserLoggedin, setUserLoggedin] = useState(null);
  const [isDelay, setIsDelay] = useState(null);

  useEffect(() => {
    if (
      localStorage.getItem("idtoken") == null &&
      localStorage.getItem("role") == null &&
      localStorage.getItem("userid") == null
    ) {
      setUserLoggedin(false);
    } else {
      setUserLoggedin(true);
    }
    setTimeout(() => {
      setIsDelay(true);
    }, 500);
  }, []);

  const handleLogout = () => {
    Services.setlastlogin();
    localStorage.clear();
    sessionStorage.removeItem("previousurl");
    history("/adminlogin");
  };
  const handleGoHome = () => {
    if (
      localStorage.getItem("adminidtoken") !== null &&
      localStorage.getItem("adminidtoken").length !== 0 &&
      localStorage.getItem("isAdmin") === "true" &&
      localStorage.getItem("userid") !== null &&
      localStorage.getItem("userid").length !== 0
    ) {
      history("/userlisting");
    }

    if (
      localStorage.getItem("idtoken") !== null &&
      localStorage.getItem("idtoken").length !== 0 &&
      localStorage.getItem("role") !== null &&
      localStorage.getItem("role") === "user" &&
      localStorage.getItem("userid") !== null &&
      localStorage.getItem("userid").length !== 0
    ) {
      history("/");
    }
  };
  return (
    <>
      {isUserLoggedin === false || isUserLoggedin === null ? (
        isDelay && <LoginAccessPage />
      ) : (
        <>
          {localStorage.getItem("idtoken") !== null &&
            localStorage.getItem("idtoken").length !== 0 &&
            localStorage.getItem("role") !== null &&
            localStorage.getItem("role") === "admin" &&
            localStorage.getItem("userid") !== null &&
            localStorage.getItem("userid").length !== 0 && (
              <div>
                <div className="header-bk">
                  <div className="header-bk-left">
                    <a>
                      <img src={logo} />
                    </a>
                  </div>
                  <div className="header-bk-right">
                    <div className="header-bk-right-user  dropdown">
                      <a
                        className="dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img src={user} className="user-icon" />
                        {localStorage.getItem("username")}
                      </a>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a onClick={handleLogout}>
                          Logout <img src={Logouticon} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {localStorage.getItem("idtoken") !== null &&
            localStorage.getItem("idtoken").length !== 0 &&
            localStorage.getItem("role") !== null &&
            localStorage.getItem("role") === "user" &&
            localStorage.getItem("userid") !== null &&
            localStorage.getItem("userid").length !== 0 && <Header />}
          <div className="not-found-page">
            <span className="not-found-image">
              <img src={ErrorFour} alt="ErrorFour" />
            </span>
            <h1>Oops! Page not found</h1>
            <p class="sorry-but-cont">
              Sorry, but the link you followed may be broken, or the page may
              have been moved.
            </p>
            <button className="btn2" onClick={handleGoHome}>
              Back to home
            </button>
          </div>
        </>
      )}
    </>
  );
};
export default PageNotFound;
