import React from "react";
import PropTypes from "prop-types";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/IncomingMessageTime.css";

const IncomingMessageTime = ({
  hasBackdrop = false,
  isEdited = false,
  time = "00:00 AM"
}) => {
  return (
    <span
      className={`IncomingMessageTime-sec flex items-center ${
        hasBackdrop ? "hasBackdrop" : ""
      }`}
    >
      {isEdited ? `edited ${time}` : time}
    </span>
  );
};

IncomingMessageTime.propTypes = {
  hasBackdrop: PropTypes.bool,
  isEdited: PropTypes.bool,
  time: PropTypes.string
};

export default IncomingMessageTime;
