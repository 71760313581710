import { useState, useEffect } from "react";
import AdminSidebar from "../AdminSidebar";
import AdminListing from "./AdminListing";
import CreateAdmin from "./CreateAdmin";
import EditAdmin from "./EditAdmin";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
const AdminManagement = () => {
  const [downarrow, setdownarrow] = useState(true)
  const [selecteddiv, setselecteddiv] = useState('listing');
  const [selectedid, setselectedid] = useState('');
  const dispatch = useDispatch();
  const [sidebarmenu, setSidebarmenu] = useState(false);
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  useEffect(() => {
    if (!modalInProcess) {
      handleClearAllStates()
    }

  }, [selecteddiv])


  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }

  const updateWindowSize = () => {
    if (window.innerWidth > 992) {
      setSidebarmenu(false)
      document.body.classList.remove('overflow-hidden')
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  const sidebarHandleClick = (ele) => {
    setSidebarmenu(ele);
    if (window.innerWidth < 992) {
      if (sidebarmenu) {
        document.body.classList.remove('overflow-hidden')
      } else {
        document.body.classList.add('overflow-hidden')
      }
    }
  }
  return (
    <>
      <div className="cstm-all-admin">
        {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
        <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
        <div className="content-main">
          <div className="users-info">
            <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <button
                      className="btn-link sd-active2"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                      onClick={() => { setdownarrow(!downarrow) }}
                    >
                      Admin info {downarrow ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-up"></i>}
                    </button>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div className="cstm-ac-in-menu">
                      <ul>
                        <li>
                          <Link
                            className="sd-active"
                            onClick={() => {
                              sidebarHandleClick(false);
                              setselecteddiv('listing');
                            }}
                          >
                            Admin profiles Listing
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            {
              selecteddiv === "listing" ? <>
                <AdminListing setselecteddiv={setselecteddiv} setselectedid={setselectedid} />
              </> : selecteddiv === "create" ? <><CreateAdmin setselecteddiv={setselecteddiv} /></> : selecteddiv === "edit" ? <><EditAdmin setselecteddiv={setselecteddiv} selectedid={selectedid} /></> : <></>
            }

          </div>
        </div>
      </div>
    </>)
}
export default AdminManagement;