import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchmyaccount,
  fetchactivityfeelings,
  fetchmemberprofile,
} from "../../../Action";
//import components
import Header from "../Dashboard/Header";
import EditProfile from "./EditProfile";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms_Condition from "./Terms_Condition";
import Contact_Us from "./Contact_Us";
import MyVideos from "./MyVideos";
import LoaderComponent from "../LoaderComponent";
import AccountProfileSettings from "./AccountProfileSettings";
import PageNotFound from "../../PageNotFound";
import UserInvite from "./UserInvite.js";
import Button from "../../../components/Button/Button";
import ArrowleftIcon from "../../../components/Icons/ArrowsIcon/ArrowleftIcon";
const UserProfile = () => {
  let { action } = useParams();
  const memberid = localStorage.getItem("userid")
  const [searchParams, setSearchParams] = useSearchParams();
  const queryvar = searchParams.get("page");
  const dispatch = useDispatch();
  let history = useNavigate();
  const [leftsidebar, setleftsidebar] = useState(true);
  const [rightsidebar, setrightsidebar] = useState(true);
  const loader = useSelector((state) => {
    return state.ReduLoader;
  });
  const profileDetail = useSelector((state) => {
    return state.ReduProfile;
  });
  const memberposts = useSelector((state) => {
    return state.ReduMemberPost;
  });
  const [showeditdiv, setshoweditdiv] = useState(false);
  const [showvideodiv, setshowvideodiv] = useState(false);
  const [pageNotFound, setPageNotFound] = useState(false);
  useEffect(() => {
    setPageNotFound(false)
    if (memberid !== undefined && memberid !== localStorage.getItem("userid") && action !== undefined
      && (action == "edit" || action === "profile"
        || action === "users" || action === "userinvite")) {
      setPageNotFound(true)
    }
  }, [])

  useEffect(() => {

    if (
      localStorage.getItem("idtoken") === null ||
      localStorage.getItem("idtoken").length === 0 ||
      localStorage.getItem("userid") === null ||
      localStorage.getItem("userid").length === 0 ||
      localStorage.getItem("username") === null ||
      localStorage.getItem("username").length === 0 ||
      localStorage.getItem("role") === null ||
      localStorage.getItem("role") !== "user"
    ) {
      history("/");
    } else {
      if (
        action !== null &&
        action !== undefined &&
        (action === "edit" ||
          action === "profile" ||
          action === "purchase-details" || action === "users" || action === "userinvite")
      )
        setshoweditdiv(true);
      else setshoweditdiv(false);

      if (action !== null && action !== undefined && action === "video") {
        setshowvideodiv(true);
        setshoweditdiv(false);
      }

      if (
        memberid !== undefined &&
        memberid === localStorage.getItem("userid") &&
        action !== undefined &&
        action === "edit"
      ) {

        dispatch(fetchactivityfeelings());
        dispatch(fetchmyaccount());
      } else if (
        memberid !== undefined &&
        memberid !== localStorage.getItem("userid") &&
        action !== undefined &&
        action === "edit"
      ) {

        dispatch(fetchmemberprofile(memberid));
      }

      if (memberid !== undefined && memberid === localStorage.getItem("userid") && action !== undefined
        && (action == "edit" || action === "profile"
          || action === "users" || action === "userinvite")) {
        dispatch(fetchactivityfeelings());
        dispatch(fetchmyaccount());
        setPageNotFound(false)
      } else {

        setPageNotFound(true)
      }

    }
  }, [memberid, action]);

  const handleBackButtonClick = () => {
    window.location.href = "/homepage";
  }

  return (
    <>
      {
        !pageNotFound &&
        <>
          <Header />
          {action === "purchase-details" ? (
            <h1 className="page-not-found">404 Page Not Found </h1>
          ) : loader ? (
            <div className="all-content-main mn-cnt-both">
              <div className="all-content-main-edit-profile ">
                <div className="loadind-post">
                  <LoaderComponent />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                leftsidebar && rightsidebar
                  ? showeditdiv ||
                    (memberid === localStorage.getItem("userid") &&
                      window.location.hostname ===
                      process.env.REACT_APP_TESTSITE_HOSTNAME)
                    ? "all-content-main cstm-all-content-main edit-profile"
                    : showvideodiv
                      ? "all-content-main cstm-all-content-main get-videos"
                      : "all-content-main cstm-all-content-main"
                  : leftsidebar === false && rightsidebar
                    ? "all-content-main mn-cnt-wd"
                    : rightsidebar === false && leftsidebar
                      ? "all-content-main mn-cnt3-wd"
                      : "all-content-main mn-cnt-both"
              }
            >

              {showeditdiv ||
                (memberid === localStorage.getItem("userid") &&
                  window.location.hostname ===
                  process.env.REACT_APP_TESTSITE_HOSTNAME) ? (
                <>
                  <div className="cstm-profile-ct">
                    <div className="cstm-profile-sd">


                      <div className="cstm-profile-back" onClick={handleBackButtonClick}>
                        <Link to="/homepage">
                          <ArrowleftIcon />
                          <span className="cstm-profile-back-label">Back to Homepage</span>
                        </Link>


                      </div>

                      <hr className="cstm-profile-back-line" />


                      <div className="cstm-ac-se-nav">
                        <ul>


                          {/* <Button
                  hasIconLeft={true}
                  label="Back to Homepage"
                  size="small"
                  variant="ghost"
                  elementIcon={
                    <ArrowleftIcon height={24} iconColor="#000" width={24} />
                  }
                  // handleClick={handleButtonClick}
                /> */}


                          <li>
                            <Link
                              to={`/settings/edit`}
                              className={action === "edit" ? "active-ac" : ""}
                            >
                              Public profile
                            </Link>
                          </li>
                          {/* <li><a href="#"><NotificationLineIcon/> Notification settings</a></li>   */}
                          <li>
                            <Link
                              to={`/settings/profile`}
                              className={
                                action === "profile"
                                  ? "active-ac"
                                  : ""
                              }
                            >
                              Account settings
                            </Link>
                          </li>
                          {!JSON.parse(localStorage.getItem("isCollaborator")) && <li>
                            <Link
                              to={`/settings/users`}
                              className={
                                action === "users" || action === "userinvite"
                                  ? "active-ac"
                                  : ""
                              }
                            >
                              Team-users
                            </Link>
                            <span className="nav-beta-badge">Beta</span>
                          </li>}
                        </ul>
                      </div>
                    </div>
                    <div className="all-content-main-edit-profile ">
                      {true && action !== undefined && action === "edit" ? (
                        <EditProfile
                          profileDetail={profileDetail}
                          setshoweditdiv={setshoweditdiv}
                        />
                      )
                        : action !== undefined &&
                          action === "profile" ? (
                          <AccountProfileSettings />
                        ) : action !== undefined &&
                          action === "users" || action === "userinvite" ? (
                          <UserInvite profileDetail={profileDetail} />
                        ) : action && action === "purchase-details" ? (
                          <h1 className="page-not-found">404 Page Not Found</h1>
                        ) : (
                          <></>
                        )}
                    </div>
                  </div>
                </>
              ) : showvideodiv || memberid === localStorage.getItem("userid") ? (
                <>
                  <MyVideos />
                </>
              ) : (
                <>
                  <div
                    className={`all-content-main-center cstm-all-content-main-center ${memberid !== undefined &&
                      memberid === localStorage.getItem("userid")
                      ? ""
                      : "other-user"
                      }`}
                  >
                    {memberid === localStorage.getItem("userid") ? (
                      <Middlebar_profile
                        profiletype="myprofile"
                        profileDetail={profileDetail}
                        setshoweditdiv={setshoweditdiv}
                      />
                    ) : (
                      <Middlebar_profile profileDetail={profileDetail} />
                    )}
                  </div>
                  <div
                    className={
                      rightsidebar
                        ? "all-content-main-right cstm-all-content-main-right"
                        : "all-content-main-right  sidebar-hd"
                    }
                  >
                    {memberid === localStorage.getItem("userid") ? (
                      <RightSidebar_profile
                        profiletype="myprofile"
                        profileDetail={profileDetail}
                        setshoweditdiv={setshoweditdiv}
                      />
                    ) : (
                      <RightSidebar_profile profileDetail={profileDetail} />
                    )}
                  </div>
                </>
              )}
            </div>
          )}

        </>
      }
      {
        pageNotFound && <><PageNotFound /></>
      }
    </>

  );
};
export default UserProfile;
