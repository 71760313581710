import * as React from "react"
import PropTypes from "prop-types"
const AddEmojiIcon = ({ width = 24, height = 24, iconColor = "#000" }) => (
    <svg
        xmlns="https://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
    >
        <path
            fill={iconColor}
            fillRule="evenodd"
            d="M19.5.25a.75.75 0 0 1 .75.75v2.75H23a.75.75 0 0 1 0 1.5h-2.75V8a.75.75 0 0 1-1.5 0V5.25H16a.75.75 0 0 1 0-1.5h2.75V1a.75.75 0 0 1 .75-.75Zm1 11.75a8.5 8.5 0 1 1-7.64-8.457.81.81 0 0 0 .853-.522c.152-.434-.115-.905-.572-.957A10.109 10.109 0 0 0 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10c0-.386-.022-.767-.064-1.141-.052-.457-.523-.724-.957-.572a.81.81 0 0 0-.522.853c.028.283.043.57.043.86ZM7 9.5c0 .83.676 1.5 1.504 1.5C9.33 11 10 10.33 10 9.5S9.331 8 8.504 8H8.49C7.663 8 7 8.67 7 9.5Zm8.504 1.5C14.676 11 14 10.33 14 9.5S14.663 8 15.49 8h.014C16.33 8 17 8.67 17 9.5s-.669 1.5-1.496 1.5ZM12 18a5 5 0 0 1-5-5h10a5 5 0 0 1-5 5Zm0-1.5a3.5 3.5 0 0 0 3.092-1.859.097.097 0 0 0-.087-.141h-6.01a.097.097 0 0 0-.087.141A3.5 3.5 0 0 0 12 16.5Z"
            clipRule="evenodd"
        />
    </svg>
)
AddEmojiIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default AddEmojiIcon
