import React from "react";

const IconSmallLink = () => {
  return (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={16}
        height={16} 
      >
        <path
          fillRule="evenodd"
          d="M8.833 2.667a.5.5 0 0 0 .5.5h2.793L6.98 8.313a.5.5 0 0 0 .707.707l5.146-5.146v2.793a.5.5 0 1 0 1 0v-4.5h-4.5a.5.5 0 0 0-.5.5Zm-2.5.666h.5a.5.5 0 0 1 0 1h-.5A2.667 2.667 0 0 0 3.666 7v3.333A2.667 2.667 0 0 0 6.333 13h3.333a2.667 2.667 0 0 0 2.667-2.667V9.167a.5.5 0 1 1 1 0v1.166A3.667 3.667 0 0 1 9.666 14H6.333a3.667 3.667 0 0 1-3.667-3.667V7a3.667 3.667 0 0 1 3.667-3.667Z"
          clipRule="evenodd"
        />
      </svg> 
  );
};

export default IconSmallLink;
