import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loader from "../../../Images/loader.gif";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import TableBodyComponentModalPagination from "../../AdminCommonComponent/TableComponent/TableBodyComponentModalPagination";
import { useGuestTicketByEventIdQuery } from "../../../Services/guestTicket";
import DeleteGuestTicket from "./DeleteGuestTicket";

const columns = [
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
  { id: "maxAllowedTicket", label: "Max order" },
  { id: "priceType", label: "Price type" },
  { id: "applicationForm", label: "Application form" },
  { id: "selectAddons", label: "Add ons" },
  { id: "isCancellation", label: "Cancellation allowed" },
  { id: "createdAt", label: "Created on " },
  { id: "updatedAt", label: "Updated on" },
  { id: "action", label: "Actions" },
];

function createData(
  name,
  description,
  maxAllowedTicket,
  priceType,
  applicationForm,
  selectAddons,
  isCancellation,
  createdAt,
  updatedAt,
  action
) {
  return {
    name,
    description,
    maxAllowedTicket,
    priceType,
    applicationForm,
    selectAddons,
    isCancellation,
    createdAt,
    updatedAt,
    action,
  };
}

const ListGuestTicket = ({
  handleEditId,
  finalSearchedText,
  selectedType,
  filterAddon,
  filterType,
  fromDateIsoString,
  toDateIsoString,
}) => {
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  //event id
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("id");

  //get all the guest ticket api hook
  const { data: rowsList, isFetching: guestTicketLoader } =
    useGuestTicketByEventIdQuery({
      event_id: eventId,
      params: {
        page: page + 1,
        limit: rowsPerPage,
        search: finalSearchedText && finalSearchedText,
        priceType: selectedType && selectedType.toUpperCase(),
        addon: filterAddon,
        sortField: sortField && sortField,
        sortType: sortType && sortType,
        filterType: filterType && filterType,
        reqFromDate:
          fromDateIsoString && filterType === "custom" && fromDateIsoString,
        reqToDate:
          toDateIsoString && filterType === "custom" && toDateIsoString,
      },
    });

  useEffect(() => {
    getGuestTicketsList();
  }, [rowsList]);

  //get all the guest tickets
  const getGuestTicketsList = () => {
    if (rowsList?.status) {
      let data = rowsList?.data;
      let table = [];
      for (let i = 0; i < data.length; i++) {
        const currentUpdatedAt = new Date(data[i].updatedAt);
        // Format the date using Intl.DateTimeFormat
        const updatedAtformattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(currentUpdatedAt);

        const currentCreatedAt = new Date(data[i].createdAt);
        // Format the date using Intl.DateTimeFormat
        const createdAtformattedDate = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(currentCreatedAt);

        //converting first letter of priceType to capital
        const priceType =
          data[i].priceType?.toLowerCase().charAt(0).toUpperCase() +
          data[i].priceType?.slice(1).toLowerCase();

        table[i] = createData(
          data[i].name,
          data[i].description,
          data[i].maxAllowedTicket,
          priceType,
          data[i].applicationForm ? "Yes" : "-",
          data[i].selectAddons.length > 0 ? data[i].selectAddons.length : 0,
          data[i].isCancellation ? "Yes" : "No",
          createdAtformattedDate,
          updatedAtformattedDate,
          data[i]._id
        );
      }
      setAllEvents(table);
      setTotalCount(rowsList?.totalGuestTicket);
      setRow(table);
    } else {
      setRow([]);
    }
  };

  //handle sorting
  const sortingHandler = (col, direc) => {
    setSortType(direc === "Dec" ? "Dsc" : "Asc");
    setSortField(col === "selectAddons" ? "available_addon" : col);
  };

  //handling pagination when we move to next page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //handling number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {showAlert && <div className="alert alert-info">{alertMessage}</div>}
      <Paper className="uic-tb-mn">
        <TableContainer className="uic-table-main">
          <Table className="uic-table">
            <TableHeader
              columns={columns}
              allEventList={allEvents}
              columnsWithoutSoritng={[
                "applicationForm",
                "addOns",
                "action",
                "updatedAt",
              ]}
              sortingHandler={sortingHandler}
              setLoader={setLoader}
              searchText={""}
            />
            {guestTicketLoader ? (
              <div className="cstm-data-load">
                <img src={Loader} />{" "}
              </div>
            ) : rows.length > 0 ? (
              <TableBodyComponentModalPagination
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={page}
                columns={columns}
                editCallBack={(value) => {
                  handleEditId(value);
                }}
                setDeleteId={(id) => {
                  setDeleteId(id);
                  setShowDeleteModal(true);
                }}
                editModalId="editModalGuestTickets"
                deleteModalId=""
              />
            ) : (
              <span className="no-data-found">No data found</span>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          className="uic-pagination"
          rowsPerPageOptions={[20, 50, 100, 200]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          count={totalCount}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Modal Delete */}
      {showDeleteModal && (
        <DeleteGuestTicket
          deleteId={deleteId}
          rows={rows}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setDeleteId={setDeleteId}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
        />
      )}
    </>
  );
};

export default ListGuestTicket;
