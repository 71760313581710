import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import millify from "millify";

import authService from "../../../Services/auth.service";
import videodummy from "../../../Images/logo-image-any-only.jpg";
import sepbullet from "../../../Images/separator-bullet.svg";
import VideoXSmallCard from "./VideoXSmallCard";
import { InView } from "react-intersection-observer";

const ContentRelatedListing = () => {
  const { action, videoid, sec } = useParams();
  const [relatedvideoslist, setrelatedvideoslist] = useState([]);
  const [relatedEventList, setRelatedEventlist] = useState([]);
  const [loader, setloader] = useState(false);
  const [moreLoader, setmoreLoader] = useState(false);
  const [moreEventLoader, setmoreEventLoader] = useState(false);
  const [eventLoader, setEventLoader] = useState(false);
  const history = useNavigate();
  const [access, setAccess] = useState(false);
  const [access1, setAccess1] = useState(false);
  const [access2, setAccess2] = useState(false);
  const [totalEventVideos, setTotalEventVideo] = useState(0);
  const [totalRelatedVideos, setTotalRelatedVideos] = useState(0);
  const [pageRelatedVideos, setPageRelatedVideo] = useState(0);
  // on first render of page this useEffect call
  useEffect(() => {
    if (localStorage.getItem("count") > 364) {
      setAccess2(true);
    }
  }, []);

  useEffect(() => {
    if (
      action !== undefined &&
      (action !== "listing" ||
        action !== "myvideos" ||
        action !== "history" ||
        action !== "saved")
    ) {
      setloader(true);
      setEventLoader(true);
      authService
        .getcontentvideobyidForUser(action)
        .then((detailRes) => {
          if (detailRes.data.status) {
            authService
              .getrelatedvideos(`${action}?page=1&limit=20`)
              .then((res) => {
                if (res.data.status) {
                  setPageRelatedVideo(parseInt(res.data.data[0].currentPage));
                  setTotalRelatedVideos(res.data.data[0].totalVideos);
                  setrelatedvideoslist(res.data.data[0].videos);
                }
                setloader(false);
              })
              .catch((e) => {
                setloader(false);
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
      authService
        .getcontentvideobyidForUser(action)
        .then((detailRes) => {
          if (detailRes.data.status) {
            if (
              detailRes.data.data.eventIds &&
              detailRes.data.data.eventIds.length > 0
            ) {
              authService
                .getRelatedVideosEvent(`/${action}`, {
                  eventIds: detailRes.data.data.eventIds.map(
                    (eventIds) => eventIds._id
                  ),
                })
                .then((relatedEventListRes) => {
                  if (relatedEventListRes.data.status) {
                    setEventLoader(false);
                    setTotalEventVideo(relatedEventListRes.data.totalVideos);
                    setRelatedEventlist(relatedEventListRes.data.videos);
                  } else {
                    setEventLoader(false);
                    setRelatedEventlist([]);
                  }
                })
                .catch((e) => {
                  setEventLoader(false);
                });
            } else {
              setEventLoader(false);
              setRelatedEventlist([]);
            }
          }
        })
        .catch(() => {
          setRelatedEventlist([]);
        });
    }
  }, [action]);

  //Filter related videos based on event video
  useEffect(() => {
    if (relatedEventList.length > 0) {
      const eventVideoIds = new Set(relatedEventList.map((video) => video._id));
      if (relatedvideoslist.length > 0) {
        const filteredRelatedVideos = relatedvideoslist.filter(
          (video) => !eventVideoIds.has(video._id)
        );
        if (filteredRelatedVideos.length !== relatedvideoslist.length) {
          setrelatedvideoslist(filteredRelatedVideos);
        }
      }
    }
  }, [relatedEventList]);
  const fetchMoreRelatedEventVideo = () => {
    setmoreEventLoader(true);
    authService
      .getcontentvideobyidForUser(action)
      .then((detailRes) => {
        if (detailRes.data.status) {
          if (
            detailRes.data.data.eventIds &&
            detailRes.data.data.eventIds.length > 0
          ) {
            authService
              .getRelatedVideosEvent(
                `/${action}`,
                {
                  eventIds: detailRes.data.data.eventIds.map(
                    (eventIds) => eventIds._id
                  ),
                }
              )
              .then((relatedEventListRes) => {
                setmoreEventLoader(false);
                if (relatedEventListRes.data.status) {
                  setTotalEventVideo(relatedEventListRes.data.totalVideos);
                  setRelatedEventlist([
                    ...relatedEventList,
                    ...relatedEventListRes.data.videos,
                  ]);
                } else {
                  setmoreEventLoader(false);
                }
              })
              .catch((e) => {
                setmoreEventLoader(false);
              });
          }
        } else {
          setmoreEventLoader(false);
        }
      })
      .catch(() => {
        setmoreEventLoader(false);
      });
  };

  const fetchMoreRelatedVideos = () => {
    setmoreLoader(true);
    authService
      .getrelatedvideos(`${action}?page=${pageRelatedVideos + 1}&limit=20`)
      .then((res) => {
        if (res.data.status) {
          setmoreLoader(false);
          setPageRelatedVideo(parseInt(res.data.data[0].currentPage));
          setTotalRelatedVideos(res.data.data[0].totalVideos);
          setrelatedvideoslist([
            ...relatedvideoslist,
            ...res.data.data[0].videos,
          ]);
        }
        setloader(false);
        setmoreLoader(false);
      })
      .catch((e) => {
        setloader(false);
        setmoreLoader(false);
        console.log(e);
      });
  };
  return (
    <>
      {access2 ||
        (localStorage.getItem("remindmetomorrowcensus") &&
          moment(
            localStorage.getItem("remindmetomorrowcensus"),
            "YYYY-MM-DD"
          ).diff(moment().format("YYYY-MM-DD"), "days") === 0 &&
          localStorage.getItem("dontshowcensuspopup") === "false" &&
          (access || access1)) ||
        ((localStorage.getItem("remindmetomorrowcensus") === null ||
          localStorage.getItem("remindmetomorrowcensus") === undefined) &&
          (localStorage.getItem("dontshowcensuspopup") === "false" ||
            localStorage.getItem("dontshowcensuspopup") === null ||
            localStorage.getItem("dontshowcensuspopup") === undefined) &&
          (access || access1)) ? (
        <></>
      ) : (
        <div className="all-content-main-right cstm-all-content-main-right">
          <div className="related-videos-main">
            <div className="related-videos-contant">
              {eventLoader && (
                <div className="video-details-right-box related-videos-wrapper">
                  <h4>From the same event</h4>
                  <div className="video-listing">
                    {[...Array(5)].map((el, index) => (
                      <div
                        className="video-card card-spacing small"
                        style={{ backgroundColor: "unset" }}
                      >
                        <div className="video-thumb-image img-img skeleton-box"></div>
                        <div
                          className="video-card-text"
                          style={{ width: "100%" }}
                        >
                          <h6
                            className="video-card-title skeleton-box"
                            style={{ width: "100%", height: "40px" }}
                          ></h6>
                          <p
                            className="video-type-text skeleton-box"
                            style={{ width: "100%", height: "18px" }}
                          ></p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {loader && (
                <div className="video-details-right-box related-videos-wrapper">
                  <h4>Related videos</h4>
                  <div className="video-listing">
                    {[...Array(20)].map((el, index) => (
                      <div
                        className="video-card card-spacing small"
                        style={{ backgroundColor: "unset" }}
                      >
                        <div className="video-thumb-image img-img skeleton-box"></div>
                        <div
                          className="video-card-text"
                          style={{ width: "100%" }}
                        >
                          <h6
                            className="video-card-title skeleton-box"
                            style={{ width: "100%", height: "40px" }}
                          ></h6>
                          <p
                            className="video-type-text skeleton-box"
                            style={{ width: "100%", height: "18px" }}
                          ></p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {!eventLoader && relatedEventList.length > 0 && (
                <div className="video-details-right-box same-event-videos-wrapper">
                  <h4>From the same event</h4>
                  <div className="video-listing scrollbar">
                    {relatedEventList.length > 0 ? (
                      !loader &&
                      relatedEventList &&
                      relatedEventList.map((video, index) => {
                        if (
                          index === relatedEventList.length - 1 &&
                          index !== totalEventVideos - 1
                        ) {
                          return (
                            <InView
                              onChange={(inView) => {
                                if (inView) fetchMoreRelatedEventVideo();
                              }}
                            >
                              <VideoXSmallCard
                                onClick={() => {
                                  setrelatedvideoslist([]);
                                  setRelatedEventlist([]);
                                }}
                                hasSpacings={true}
                                url={`/videos/${video._id}`}
                                thumbUrl={
                                  video.thumbnail !== undefined &&
                                    video.thumbnail !== null &&
                                    video.thumbnail.length > 0
                                    ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                    video.thumbnail
                                    : videodummy
                                }
                                hasDate={true}
                                hasDuration={true}
                                duration={video.duration}
                                hasVideoType={false}
                                thumbType={"img"}
                                hasView={true}
                                views={
                                  video.viewsCount !== undefined
                                    ? video.viewsCount > 1
                                      ? `${millify(video.viewsCount)} views`
                                      : video.viewsCount === 1
                                        ? "1 view"
                                        : "0 views"
                                    : "0 views"
                                }
                                title={video.title}
                                uplodedDate={moment(video.createdAt).fromNow()}
                              // videoType={video.categories[0].name}
                              />
                            </InView>
                          );
                        } else {
                          return (
                            <VideoXSmallCard
                              onClick={() => {
                                setrelatedvideoslist([]);
                                setRelatedEventlist([]);
                              }}
                              hasSpacings={true}
                              url={`/videos/${video._id}`}
                              thumbUrl={
                                video.thumbnail !== undefined &&
                                  video.thumbnail !== null &&
                                  video.thumbnail.length > 0
                                  ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                  video.thumbnail
                                  : videodummy
                              }
                              hasDate={true}
                              hasDuration={true}
                              duration={video.duration}
                              hasVideoType={false}
                              thumbType={"img"}
                              hasView={true}
                              views={
                                video.viewsCount !== undefined
                                  ? video.viewsCount > 1
                                    ? `${millify(video.viewsCount)} Views`
                                    : video.viewsCount === 1
                                      ? "1 View"
                                      : "0 Views"
                                  : "0 Views"
                              }
                              title={video.title}
                              uplodedDate={moment(video.createdAt).fromNow()}
                            // videoType={video.categories[0].name}
                            />
                          );
                        }
                      })
                    ) : (
                      <p>There is no more videos</p>
                    )}
                    {moreEventLoader &&
                      [...Array(5)].map((el, index) => (
                        <div
                          className="video-card card-spacing small"
                          style={{ backgroundColor: "unset" }}
                        >
                          <div className="video-thumb-image img-img skeleton-box"></div>
                          <div
                            className="video-card-text"
                            style={{ width: "100%" }}
                          >
                            <h6
                              className="video-card-title skeleton-box"
                              style={{ width: "100%", height: "40px" }}
                            ></h6>
                            <p
                              className="video-type-text skeleton-box"
                              style={{ width: "100%", height: "18px" }}
                            ></p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              {!loader && relatedvideoslist.length > 0 && (
                <div className="video-details-right-box related-videos-wrapper">
                  <h4>Related videos</h4>
                  <div className="video-listing">
                    {relatedvideoslist.length > 0 ? (
                      !loader &&
                      relatedvideoslist &&
                      relatedvideoslist.map((video, index) => {
                        return (
                          <VideoXSmallCard
                            hasSpacings={true}
                            url={`/videos/${video._id}`}
                            thumbUrl={
                              video.thumbnail !== undefined &&
                                video.thumbnail !== null &&
                                video.thumbnail.length > 0
                                ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                video.thumbnail
                                : videodummy
                            }
                            hasDate={true}
                            hasDuration={true}
                            duration={video.duration}
                            hasVideoType={false}
                            thumbType={"img"}
                            hasView={true}
                            views={
                              video.viewsCount !== undefined
                                ? video.viewsCount > 1
                                  ? `${millify(video.viewsCount)} Views`
                                  : video.viewsCount === 1
                                    ? "1 View"
                                    : "0 Views"
                                : "0 Views"
                            }
                            title={video.title}
                            uplodedDate={moment(video.createdAt).fromNow()}
                            videoType={video.categories[0].name}
                          />
                        );
                      })
                    ) : (
                      <p>There is no more videos</p>
                    )}
                    {moreLoader &&
                      [...Array(20)].map((el, index) => (
                        <div
                          className="video-card card-spacing small"
                          style={{ backgroundColor: "unset" }}
                        >
                          <div className="video-thumb-image img-img skeleton-box"></div>
                          <div
                            className="video-card-text"
                            style={{ width: "100%" }}
                          >
                            <h6
                              className="video-card-title skeleton-box"
                              style={{ width: "100%", height: "40px" }}
                            ></h6>
                            <p
                              className="video-type-text skeleton-box"
                              style={{ width: "100%", height: "18px" }}
                            ></p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ContentRelatedListing;
