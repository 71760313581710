import React from 'react'
import './Styles/DocSmallCard.css'
import { Link } from 'react-router-dom'
import DocThumbnailSmallImage from './DocThumbnailSmallImage.jsx'
import Button from '../../components/Button/Button.js'
import DotsIcon from '../../components/Icons/GeneralIcons/DotsIcon.js'
import docImg from '../../Images/docs-collection-cover.png'


const DocSmallCard = ({
    url = "/",
    hasSpacings = false,
    thumbUrl = docImg,
    cardSize = "small",
    title = 'Doc Title Text',
    uplodedDate = "2 Months ago",
    thumbType = "img",
    hasDate = true,
    hasDuration,
    duration,
    onClick,
    hasDotMenu = true,
    hasView = true,
    alignCenter = false
}) => {
    return (
        <>
            <div className={`position-relative ${hasDotMenu ? 'has-dotmenu' : ''}`}>
                <Link to={url} className={`doc-card ${cardSize} ${alignCenter ? 'align-items-center' : ''} ${hasSpacings ? 'card-spacing' : ''}`} onClick={onClick}>
                    <DocThumbnailSmallImage thumbUrl={thumbUrl} hasDuration={hasDuration} duration={duration} thumbType={thumbType} />
                    <div className='doc-card-text'>
                        <h6 className='doc-card-title'>{title}</h6>
                        {(hasDate || hasView) && <div class="doc-details-view-date"> 
                            {hasDate && <span class="doc-uploded-date">{uplodedDate}</span>}
                        </div>}
                    </div>
                </Link>
                {hasDotMenu && <Button
                    variant="ghost"
                    extraClass="doc-dot-btn ml-auto align-self-start"
                    size={"small"}
                    handleClick={() => { }}
                    hasText={false}
                    elementIcon={<DotsIcon />}
                />}
            </div>
        </>
    )
}

export default DocSmallCard