import React from 'react'
import './ToastNotification.css'
import '../Typography/Typography.css'
import "../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"
import ClockIcon from '../Icons/GeneralIcons/ClockIcon'
import InfoIcon from '../Icons/GeneralIcons/InfoIcon'
import CheckIcon from '../Icons/GeneralIcons/CheckIcon'
import ExclamationIcon from '../Icons/GeneralIcons/ExclamationIcon'
import BlockIcon from '../Icons/GeneralIcons/BlockIcon'
import CrossIcon from '../Icons/GeneralIcons/CrossIcon'
import ProgressBar from '../ProgressBar/ProgressBar'
import { Link } from 'react-router-dom'
const ToastNotification = ({ variant, size, hasIcon = true, title, text, isProgress = false, progress, onCancel, hasAction = true, hasCross = true, actionText = 'Action', actionHadleClick = () => { }, actionUrl = "javascript:void(0)" }) => {
    return (
        <>
            <div className={`toast-notification ${size} ${variant}`}>
                {isProgress ?
                    <>
                        <ProgressBar label={title} progress={progress} color='white' />
                        <span className='toast-notification-cancel' onClick={onCancel}>Cancel</span>
                    </> :
                    <>
                        <div className='toast-notification-body'>
                            {hasIcon && <span className='toast-notification-body-icon'>{hasIcon && variant === "neutral" ? <ClockIcon /> : hasIcon && variant === "info" ? <InfoIcon /> : hasIcon && variant === "success" ? <CheckIcon /> : hasIcon && variant === "warning" ? <ExclamationIcon /> : hasIcon && variant === "danger" ? <BlockIcon /> : null}</span>}
                            <div className='toast-notification-body-text'>
                                <h6>{title}</h6>
                                <span className='tnd-description'>{text}</span>
                            </div>
                        </div>
                        {hasAction && <Link to={actionUrl} target='_blank' className='action-text' onClick={actionHadleClick} >{actionText}</Link>}
                        {hasCross && <div className='toast-notification-close'><CrossIcon /></div>}
                    </>
                }
            </div>
        </>
    )
}
ToastNotification.propTypes = {
    size: PropTypes.oneOf(["medium", "small"]),
    variant: PropTypes.oneOf(["neutral", "info", "success", "warning", "danger"]),
    hasIcon: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string,
    isProgress: PropTypes.bool,
    progress: PropTypes.number,
    onCancel: PropTypes.func,
    hasCross: PropTypes.bool,
    hasAction: PropTypes.bool,
    actionText: PropTypes.string,
    actionHadleClick: PropTypes.func,
    actionUrl: PropTypes.string,
}
export default ToastNotification