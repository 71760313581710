import React from "react";
import "../Styles/ChatNotificationCell.css";
import PropTypes from "prop-types";
import "../../../../Styles/Theme/Theme.css";
import Typography from "../../../../components/Typography/Typography";
import Switch from "../../../../components/Switch/Switch";

const ChatNotificationCell = ({
  text = "When this setting is off you will not receive notifications.",
  title = "Allow notifications",
  onChangeSwitch,
  isMuted,
}) => {
  return (
    <>
      <div className="chat-notification-cell">
        <div className="chat-notification-cell-text">
          <Typography variant="h5">{title}</Typography>
          <Typography variant="p">{text}</Typography>
        </div>
        <div className="chat-notification-cell-switch">
          <Switch onChange={onChangeSwitch} checked={isMuted} />
        </div>
      </div>
    </>
  );
};

ChatNotificationCell.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  isMuted: PropTypes.bool,
  onChangeSwitch: PropTypes.func,
};

export default ChatNotificationCell;
