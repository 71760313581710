import React from 'react'
import PropTypes from "prop-types"
const CheckIcon = ({ width = 24, height = 24, isFilled, iconColor = "#000" }) => {
    return (
        <>
            {isFilled ? <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M12 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10Zm-1.406-6.974a.745.745 0 0 0 1.06 0L16.4 10.28a.749.749 0 1 0-1.06-1.06l-4.216 4.215-1.844-1.842a.749.749 0 1 0-1.06 1.06l2.374 2.373Z"
                    clipRule="evenodd"
                />
            </svg> : <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="M10.594 15.026a.745.745 0 0 0 1.06 0L16.4 10.28a.749.749 0 1 0-1.06-1.06l-4.216 4.215-1.844-1.842a.749.749 0 1 0-1.06 1.06l2.374 2.373Z"
                />
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M2 12c0 5.514 4.486 10 10 10s10-4.486 10-10S17.514 2 12 2 2 6.486 2 12Zm1.5 0c0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.813 8.5-8.5 8.5-4.687 0-8.5-3.813-8.5-8.5Z"
                    clipRule="evenodd"
                />
            </svg>}
        </>
    )
}
CheckIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isFilled: PropTypes.bool,
    iconColor: PropTypes.string
}
export default CheckIcon