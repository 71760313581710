import React from 'react';
import imgBigDelete from "../../Images/BackendImages/delete-icon.svg";

const DeleteModal = ({ message,  handleDelete, modalId = "deleteModal"}) => {
    return (
        <div className="modal fade msg-popup" id={modalId} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button type="button" className="close" data-dismiss="modal" data-target="#deleteModal"  aria-label="Close">
                        &times;
                    </button>
                    <div className="msg-icon">
                        <img src={imgBigDelete} alt="" />
                    </div>
                    <h1>{message}</h1>
                    <div className="row">
                        <div className="col-6">
                            <span className="msg-btn">
                                <button type="button" className="btn btn-red" onClick={handleDelete} data-dismiss="modal">
                                    Delete
                                </button>
                            </span>
                        </div>
                        <div className="col-6">
                            <span className="msg-btn">
                                <button type="button" className="btn2" data-dismiss="modal">
                                    Discard
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteModal;