const intialState = [];

const reducerInProgressMediaMessage = (state = intialState, action) => {
  switch (action.type) {
    case "ADDINPROGRESSMESSAGE":
      return [...state, action.payload];
    case "REMOVEINPROGRESSMESSAGE":
      return state.filter((data) => {
        if (data.frontendUniqueId !== action.payload) return data;
      });
    case 'LOGOUT':
      return intialState;
    default:
      return state;
  }
};

export default reducerInProgressMediaMessage;
