import React from "react";

const VideoNoHistory = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={120}
      height={120}
      fill="none"
    >
      <circle cx={60} cy={60} r={60} fill="#6FCADF" fillOpacity={0.2} />
      <path
        fill="#060D14"
        d="m36.718 46.997-1.37-6.018a1.997 1.997 0 0 0-2.392-1.505 1.993 1.993 0 0 0-1.505 2.393l2.441 10.745c.231 1.039 1.365 1.75 2.4 1.504l10.736-2.492a1.997 1.997 0 0 0 1.497-2.398 1.997 1.997 0 0 0-2.399-1.496l-6.085 1.41c5.093-9.36 16.397-13.987 26.598-10.87 10.83 3.308 17.697 14.499 15.742 25.653-1.955 11.154-12.219 19.342-23.528 18.77-10.975-.554-20.138-9.216-21.42-20.049a1.037 1.037 0 0 0-1.092-.923l-1.94.14a.97.97 0 0 0-.906 1.068c1.427 12.668 12.297 23.054 25.159 23.704 13.273.67 25.317-8.939 27.612-22.029 2.294-13.09-5.764-26.223-18.474-30.107-11.87-3.627-25.03 1.676-31.074 12.5Z"
      />
      <path
        fill="#060D14"
        d="M68.126 69.566 58.072 63.57a2.012 2.012 0 0 1-.976-1.72V48.921a2 2 0 0 1 4 0v11.792l9.08 5.413a2.005 2.005 0 0 1 .694 2.744 2.01 2.01 0 0 1-2.745.696Z"
      />
    </svg>
  );
};

export default VideoNoHistory;
