import React from 'react'
import PropTypes from "prop-types"
const NewChatIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M8.754 15.185h2.928c.373 0 .724-.144.988-.408l5.737-5.728-3.47-3.464-5.701 5.693c-.255.254-.4.592-.408.95l-.074 2.957Zm7.227-10.641 3.469 3.464.605-.605c.303-.302.47-.705.47-1.133 0-.429-.167-.832-.47-1.135L18.858 3.94a1.615 1.615 0 0 0-2.273 0l-.604.604Zm-4.3 12.114H7.999a.738.738 0 0 1-.738-.755l.093-3.71a2.857 2.857 0 0 1 .84-1.957l7.35-7.338a3.091 3.091 0 0 1 4.357 0l1.197 1.195c.583.582.904 1.354.903 2.177a3.05 3.05 0 0 1-.903 2.175l-7.384 7.373a2.857 2.857 0 0 1-2.031.84ZM16.337 22H7.658C4.274 22 2 19.629 2 16.1V7.936c0-3.529 2.274-5.9 5.658-5.9h3.677a.737.737 0 1 1 0 1.473H7.658c-2.541 0-4.183 1.738-4.183 4.427V16.1c0 2.69 1.642 4.427 4.183 4.427h8.678c2.541 0 4.183-1.738 4.183-4.427v-3.955a.737.737 0 0 1 1.476 0V16.1c0 3.529-2.275 5.9-5.66 5.9Z"
                clipRule="evenodd"
            />

        </svg>
    )
}
NewChatIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default NewChatIcon
