import React from 'react'
import PropTypes from "prop-types"
const ReloadIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    d="m3.27 7.124-.514-2.257a.749.749 0 1 0-1.462.333l.916 4.03c.086.389.512.655.9.563l4.026-.934a.749.749 0 1 0-.339-1.46l-2.282.528c1.91-3.51 6.15-5.245 9.975-4.076 4.06 1.24 6.636 5.437 5.903 9.62-.733 4.183-4.582 7.253-8.823 7.039-3.993-.202-7.346-3.265-7.982-7.158-.066-.402-.416-.71-.823-.68-.408.029-.718.384-.656.788.695 4.585 4.686 8.29 9.386 8.527 4.977.252 9.494-3.352 10.355-8.26.86-4.91-2.162-9.834-6.928-11.29-4.451-1.36-9.386.628-11.653 4.687Z"
                />
                <path
                    fill={iconColor}
                    d="m15.047 15.587-3.77-2.249a.755.755 0 0 1-.366-.645V7.845a.75.75 0 0 1 1.5 0v4.422l3.405 2.03a.752.752 0 0 1-.769 1.29Z"
                />
            </svg>
        </>
    )
}
ReloadIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default ReloadIcon
