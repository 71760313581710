import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
//import function from action file
import { fetchtopics } from "../../Action/index.js";
//import components
import AdminSidebar from "../AdminSidebar";
import TopicListing from "./TopicListing";
import CreateTopic from "./CreateTopic";
import EditTopic from "./EditTopic";
const TopicsManagement = () => {
  const dispatch = useDispatch();
  const [currentDiv, setcurrentDiv] = useState("");
  const [selectedTid, setselectedTid] = useState("");
  const [topicdtl, settopicdtl] = useState({});
  useEffect(() => {
    dispatch(fetchtopics());
  }, []);
  useEffect(() => {
    dispatch(fetchtopics());
  }, [currentDiv]);
  switch (currentDiv) {
    case "create":
      return (
        <div className="cstm-all-admin">
          <AdminSidebar />
          <CreateTopic setcurrentDiv={setcurrentDiv} />
        </div>
      );
    case "edit":
      return (
        <div className="cstm-all-admin">
          <AdminSidebar />
          <EditTopic
            setcurrentDiv={setcurrentDiv}
            topicdtl={topicdtl}
            selectedTid={selectedTid}
          />
        </div>
      );
    case "listing":
      return (
        <div className="cstm-all-admin">
          <AdminSidebar />
          <TopicListing
            setcurrentDiv={setcurrentDiv}
            setselectedTid={setselectedTid}
            settopicdtl={settopicdtl}
          />
        </div>
      );
    default:
      return (
        <div className="cstm-all-admin">
          <AdminSidebar />
          <TopicListing
            setcurrentDiv={setcurrentDiv}
            setselectedTid={setselectedTid}
            settopicdtl={settopicdtl}
          />
        </div>
      );
  }
};
export default TopicsManagement;
