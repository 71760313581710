import React, { useState } from "react";
import authServiceNews from "../../../Services/auth.service.news";
import Dropzone from "react-dropzone";
import moment from "moment";
import Frame from "../../../Images/Frame.svg";
import Loader2 from "../../../Images/loader2.gif";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const CreateBanner = ({
  emptyObjectModal,
  emptyObjectErr,
  getAllBannerApi,
  searchText,
  isValidUrl,
  handleClearAllStates,
}) => {
  //hooks
  const dispatch = useDispatch();

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });

  const [addEvent, setAddEvent] = useState(emptyObjectModal);
  const [addErr, setAddErr] = useState(emptyObjectErr);
  const [createInprocess, setCreateInprocess] = useState(false);
  const [addAlertMsg, setAddAlertMsg] = useState("");

  const [requiredImageSize, setRequiredImageSize] = useState({
    webBannerWidth: 2256,
    webBannerHeight: 760,
    mobileBannerWidth: 1500,
    mobileBannerHeight: 960
  });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(
    16 / 9
  );

  const handleCloseAddModel = (e) => {
    setAddEvent(emptyObjectModal);
    setAddErr(emptyObjectErr);
    handleClearAllStates();
  };

  //addchange for create modal
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    if (e.target.value.trim().length > 0) {
      setAddErr({ ...addErr, [e.target.name]: "" });
    }
  };

  //validation and api call on create session
  const handleCreate = () => {
    var status = true;
    var temp = {};
    if (!addEvent.image) {
      temp = { ...temp, image: "Select image!" };
      status = false;
    } else if (addErr.image) {
      status = false;
      temp = { ...temp, image: `Allow only ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight}` };
    }
    //web image validation
    if (!addEvent.imageWeb) {
      temp = { ...temp, imageWeb: "Select image!" };
      status = false;
    } else if (addErr.imageWeb) {
      status = false;
      temp = { ...temp, imageWeb: `Allow only ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight}` };
    }
    if (addEvent.url.trim().length === 0) {
      temp = { ...temp, url: "Enter url!" };
      status = false;
    } else if (!isValidUrl(addEvent.url)) {
      temp = { ...temp, url: "Invalid url!" };
      status = false;
    }
    if (addEvent.startDate.length === 0) {
      temp = { ...temp, startDate: "Enter a start date!" };
      status = false;
    }
    if (addEvent.startTime.length === 0) {
      temp = { ...temp, startTime: "Enter a start time!" };
      status = false;
    }
    if (addEvent.endDate.length === 0) {
      temp = { ...temp, endDate: "Enter an end date!" };
      status = false;
    }
    if (addEvent.endTime.length === 0) {
      temp = { ...temp, endTime: "Enter an end time!" };
      status = false;
    }
    if (addEvent.saveAs.length === 0) {
      temp = { ...temp, saveAs: "Select option!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setCreateInprocess(true);
      createApiCall();
    } else {
      setAddErr(temp);
    }
  };

  //Api calll on create session
  const createApiCall = () => {
    setCreateInprocess(true);
    const formdata = new FormData();
    formdata.append("bannerImage", addEvent.image);
    formdata.append("bannerUrl", addEvent.url);
    formdata.append(
      "publicationStartTime",
      moment(addEvent.startTime, "hh:mm A").format("hh:mm A")
    );
    formdata.append(
      "publicationEndTime",
      moment(addEvent.endTime, "hh:mm A").format("hh:mm A")
    );
    formdata.append(
      "publicationStartDate",
      moment(addEvent.startDate).format("MM-DD-YYYY")
    );
    formdata.append(
      "publicationEndDate",
      moment(addEvent.endDate).format("MM-DD-YYYY")
    );
    formdata.append("saveAs", addEvent.saveAs);
    formdata.append("webBannerImage", addEvent.imageWeb);

    authServiceNews
      .createNewsBanner(formdata)
      .then((res) => {
        setCreateInprocess(false);
        if (res.data.status) {
          setAddEvent(emptyObjectModal);
          getAllBannerApi(searchText);
        }
        handleClearAllStates();
        setAddAlertMsg(res.data.message);
        setTimeout(() => {
          setAddAlertMsg("");
        }, 3000);
      })
      .catch((e) => {
        setCreateInprocess(false);
        handleClearAllStates();
        console.log(e);
      });
    setAddErr(emptyObjectErr);
  };
  return (
    <div
      className="modal fade edit-user-details-popup"
      id="addBanner"
      data-backdrop="static"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-keyboard="false"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="edit-user-details-header">
            <h2>Create Banner</h2>
            <button
              type="button"
              className="close"
              //data-dismiss="modal"
              aria-label="Close"
              onClick={(e) => {
                if (modalInProcess) {
                  dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                } else {
                  $("#addBanner").modal("hide");
                  handleCloseAddModel(e);
                }
              }}
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            {addAlertMsg && (
              <div className="alert alert-info">{addAlertMsg}</div>
            )}
            {/* Mobile dropzone */}
            <Dropzone
              onDrop={(acceptedFiles) => {
                const file = acceptedFiles[0];
                if (file.type.startsWith("image/")) {
                  const reader = new FileReader();
                  reader.onload = function (event) {
                    const img = new Image();
                    img.onload = function () {
                      // setAddEvent({ ...addEvent, image: file })
                      // setAddErr({ ...addErr, image: "" })
                      const height = img.height;
                      const width = img.width;
                      const requiredWidth = requiredImageSize.mobileBannerWidth;
                      const requiredHeight = requiredImageSize.mobileBannerHeight;
                      if (width === requiredWidth && height === requiredHeight) {
                        setAddEvent({ ...addEvent, image: file })
                        setAddErr({ ...addErr, image: "" })
                      } else {
                        setAddErr({ ...addErr, image: `Allow only ${requiredWidth} x ${requiredHeight} size image.` })
                      }
                    };
                    img.src = event.target.result;
                  };
                  reader.readAsDataURL(file);
                } else {
                  setAddErr({
                    ...addErr,
                    image: "File type not supported. Please upload an image."
                  });
                }
              }}
              name="image"
              multiple={false}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              maxSizeBytes="10485760"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="group-info-user cstm-upload-box">
                    <div className="group-info-user-img">
                      <img
                        className={""}
                        alt="Mobile banner image"
                        src={
                          addEvent.image
                            ? URL.createObjectURL(addEvent.image)
                            : Frame
                        }
                      />
                    </div>
                    <div className="group-info-user-link">
                      <div className="cover-image-upload-ttl">
                        Add banner for mobile
                      </div>
                      <p>{`Allow only ${requiredImageSize.mobileBannerWidth} x ${requiredImageSize.mobileBannerHeight} size image.`}</p>
                    </div>
                    <span className="error">{addErr.image}</span>
                  </div>
                </div>
              )}
            </Dropzone>
            
            {/* Web dropzone */}
            <Dropzone
              onDrop={(acceptedFiles) => {
                const file = acceptedFiles[0];
                if (file.type.startsWith("image/")) {
                  const reader = new FileReader();
                  reader.onload = function (event) {
                    const img = new Image();
                    img.onload = function () {
                      // setAddEvent({ ...addEvent, imageWeb: file })
                      // setAddErr({ ...addErr, imageWeb: "" })
                      const height = img.height;
                      const width = img.width;
                      const requiredWidth = requiredImageSize.webBannerWidth;
                      const requiredHeight = requiredImageSize.webBannerHeight;
                      if (width === requiredWidth && height === requiredHeight) {
                        setAddEvent({ ...addEvent, imageWeb: file })
                        setAddErr({ ...addErr, imageWeb: "" })
                      } else {
                        setAddErr({ ...addErr, imageWeb: `Allow only ${requiredWidth} x ${requiredHeight} size image.` })
                      }
                    };
                    img.src = event.target.result;
                  };
                  reader.readAsDataURL(file);
                } else {
                  setAddErr({
                    ...addErr,
                    imageWeb: "File type not supported. Please upload an image."
                  });
                }
              }}
              name="imageWeb"
              multiple={false}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              maxSizeBytes="10485760"
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <div className="group-info-user cstm-upload-box">
                    <div className="group-info-user-img">
                      <img
                        className={""}
                        alt="Web banner image"
                        src={
                          addEvent.imageWeb
                            ? URL.createObjectURL(addEvent.imageWeb)
                            : Frame
                        }
                      />
                    </div>
                    <div className="group-info-user-link">
                      <div className="cover-image-upload-ttl">
                        Add banner for web
                      </div>
                      <p>{`Allow only ${requiredImageSize.webBannerWidth} x ${requiredImageSize.webBannerHeight} size image.`}</p>
                    </div>
                    <span className="error">{addErr.imageWeb}</span>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="form-group">
              <div className="form-group-label">Url*</div>
              <div className="form-group-ct">
                <input
                  type="text"
                  placeholder="Enter url"
                  name="url"
                  onChange={handleAddChange}
                  value={addEvent.url}
                />
                <span className="error">{addErr.url}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="form-group-label">Start date*</div>
              <div className="form-group-ct">
                <input
                  type="date"
                  name="startDate"
                  onChange={handleAddChange}
                  value={addEvent.startDate}
                  min={moment().format("YYYY-MM-DD")}
                />
                <span className="error">{addErr.startDate}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="form-group-label">Start time*</div>
              <div className="form-group-ct">
                <input
                  type="time"
                  name="startTime"
                  onChange={handleAddChange}
                  value={addEvent.startTime}
                  disabled={addEvent.startDate.length === 0 ? true : false}
                />
                <span className="error">{addErr.startTime}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="form-group-label">End Date*</div>
              <div className="form-group-ct">
                <input
                  type="date"
                  name="endDate"
                  onChange={handleAddChange}
                  value={addEvent.endDate}
                  disabled={addEvent.startDate.length === 0 ? true : false}
                  min={addEvent.startDate}
                />
                <span className="error">{addErr.endDate}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="form-group-label">End time*</div>
              <div className="form-group-ct">
                <input
                  type="time"
                  name="endTime"
                  onChange={handleAddChange}
                  value={addEvent.endTime}
                  disabled={addEvent.startDate.length === 0 ? true : false}
                />
                <span className="error">{addErr.endTime}</span>
              </div>
            </div>
            <div className="form-group">
              <div className="cstm-add-video-title-sub">Save As*</div>
              <div className="form-group-ct">
                <label className="subcat-addvideo">
                  <input
                    type="radio"
                    id="draft"
                    name="saveAs"
                    value="draft"
                    onChange={handleAddChange}
                    defaultChecked={addEvent.saveAs === "draft"}
                    checked={addEvent.saveAs === "draft"}
                  />
                  Draft
                </label>
                <label className="subcat-addvideo">
                  <input
                    type="radio"
                    id="publish"
                    name="saveAs"
                    value="publish"
                    onChange={handleAddChange}
                    defaultChecked={addEvent.saveAs === "publish"}
                    checked={addEvent.saveAs === "publish"}
                  />
                  Publish
                </label>
                <span className="error">{addErr.saveAs}</span>
              </div>
            </div>
          </div>
          <div className="edit-user-details-footer cstm-create-button">
            {createInprocess ? (
              <div className="loader-button1">
                <button>In Process...</button>
                <span className="loader-btn">
                  <img src={Loader2} />
                </span>
              </div>
            ) : (
              <button
                type="button"
                className="btn"
                onClick={handleCreate}
                disabled={createInprocess}
              >
                Create
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBanner;
