import React from 'react';
import copyImage   from "../../../../Images/copy.png";

const PreventionCopyModal = ({ message, handleConfirmOk, modalId = "confirmPrevention", title, handleConfirmCancel }) => {
    return (
        <div
            className="modal fade msg-popup"
            id={modalId}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={handleConfirmCancel}
                    >
                        &times;
                    </button>
                    <div className="msg-icon">
                        <img src={copyImage} />
                    </div>
                    <h1>{title}</h1> 
                    <h4 className='prevention-copy-sub-title'>{message}</h4>
                    <div className="row">
                        <div className="col-6">
                            <span class="msg-btn">
                                <button type="button" data-dismiss="modal" onClick={handleConfirmCancel}  className="btn">
                                    Discard
                                </button>
                            </span>
                        </div>
                        <div className="col-6">
                            <span className="msg-btn">
                                <button
                                    type="button"
                                    className="btn2"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        handleConfirmOk()
                                    }}
                                >
                                    Confirm
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PreventionCopyModal;