import { useState, useEffect, useRef } from "react";
// import SaveVideosList from "../Dashboard/SaveVideosList";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Services from "../../../Services/auth.service";
import Services1 from "../../../Services/auth.service1";
import { fetchsavedvideos } from "../../../Action";
import { fetchhistoryvideos, addhistoryvideo } from "../../../Action/index1";

import moment from "moment";
import videoimg from "../../../Images/videos.svg";
import MoreLine from "../../../Images/more-line.png";
import { InView } from "react-intersection-observer";
import noMediaFound from "../../../Images/nomediafound.svg";
import loading from "../../../Images/loader.gif";
const MyVideos = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("history");
  const [goingUp, setGoingUp] = useState(false);

  const [deletevideoBtnStatus, setDeleteVideoBtnStatus] = useState({});
  const [deletesavedvideoBtnStatus, setDeleteSavedVideoBtnStatus] = useState(
    {}
  );
  const [historyparam, setHistoryParams] = useState({
    page: 1,
    limit: 10,
  });

  const [loader, setloader] = useState(true);
  const [deletesavedloader, setdeletesavedloader] = useState({});
  const [deleteremoveloader, setdeleteremoveloader] = useState({});

  const [page, setpage] = useState(1);
  const [totalvideos, settotalvideos] = useState(0);
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);
  const [nodatafound, setnodatafound] = useState(false);
  const [pageloader, setpageloader] = useState(false);
  const [displayvideos, setdisplayvideos] = useState([]);
  const savedvideos = useSelector((state) => {
    return state.ReduSavedVideos;
  });
  const historyvideos = useSelector((state) => {
    return state.ReduHistoryVideos;
  });

  useEffect(() => {
    setloader(true);
    dispatch(fetchsavedvideos());
    // dispatch(fetchhistoryvideos(historyparam))
    Services1.gethistoryvideos(historyparam)
      .then((res) => {
        if (res.data.status) {
          setHistoryParams({ page: 1, limit: 10 });
          setpageloader(false);
          settotalvideos(res.data.data[0].totalVideos);
          dispatch(fetchhistoryvideos(res.data.data[0].video_history_data));

          setloader(false);

          console.log(res.data.data[0].totalVideos, "total videos");
          if (res.data.data[0].totalVideos === 0) setnodatafound(true);
          else setnodatafound(false);
        } else {
          setloader(false);
          setnodatafound(true);
          //console.log(nodatafound,"Sdsd")
        }

        return true;
      })
      .catch((e) => {
        setnodatafound(true);
        setloader(false);
        console.log(e);
        return false;
      });

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      historyvideos !== undefined &&
      historyvideos !== null &&
      pageloader &&
      historyvideos.length < totalvideos
    ) {
      setHistoryParams({ page: historyparam.page + 1, limit: 10 });
      setpage(page + 1);

      //setHistoryParams({page:page, limit:1})
    }
  }, [pageloader]);

  useEffect(() => {
    console.log(historyvideos, "sdd");
    if (
      historyvideos !== null &&
      historyvideos !== undefined &&
      historyvideos.length > 0
    ) {
      setdisplayvideos([...historyvideos]);
      var arr = {};
      historyvideos.map((video, index) => {
        arr = { ...arr, [video.video_id]: false };
      });
      //setDeleteVideoBtnStatus(arr);
      setnodatafound(false);
      //console.log(deletevideoBtnStatus,  'delete buttons')
    } else {
      setdisplayvideos([]);
      setnodatafound(true);
    }
  }, [historyvideos]);

  useEffect(() => {
    console.log(historyvideos, "history videos");
    if (
      historyvideos !== null &&
      historyvideos !== undefined &&
      historyvideos.length < totalvideos &&
      page <= Math.ceil(totalvideos / 10) &&
      page > 1
    ) {
      setloader(true);
      Services1.gethistoryvideos(historyparam)
        .then((res) => {
          if (res.data.status) {
            dispatch(addhistoryvideo(res.data.data[0].video_history_data));
            settotalvideos(res.data.data[0].totalVideos);
            setpageloader(false);
            setloader(false);

            if (res.data.data[0].totalVideos === 0) {
              setnodatafound(true);
              setloader(false);
            } else {
              setnodatafound(false);
              setloader(false);
            }
          } else {
            setpageloader(false);
            setloader(false);
          }
        })
        .catch((e) => {
          setpageloader(false);
          setnodatafound(true);
          setloader(false);
          console.log(e);
        });
    }
  }, [page]);

  function toggleRemoveButton(e, video_id) {
    setDeleteVideoBtnStatus({
      [video_id]: !deletevideoBtnStatus[video_id],
    });
    //setDeleteVideoBtnStatus({...deletevideoBtnStatus,[video_id]: !deletevideoBtnStatus[video_id]}); // new object triggers re-render
    console.log(deletevideoBtnStatus, "sdsad");
  }

  function toggleSavedRemoveButton(e, video_id) {
    setDeleteSavedVideoBtnStatus({
      [video_id]: !deletesavedvideoBtnStatus[video_id],
    });
    //setDeleteVideoBtnStatus({...deletevideoBtnStatus,[video_id]: !deletevideoBtnStatus[video_id]}); // new object triggers re-render
    console.log(deletesavedvideoBtnStatus, "sdsad");
  }

  function removeHistoryVideo(e, video_id) {
    setdeleteremoveloader({ [video_id]: true });
    console.log(video_id, "video");
    const data = {
      _id: video_id,
    };
    Services1.deletehistoryvideo(data)
      .then((res_) => {
        console.log(res_, "response");
        if (res_.data.status) {
          //alert("deleted");
          console.log(displayvideos, "history videos");
          var array = [...displayvideos]; // make a separate copy of the array
          var filteredarray = array.filter((item) => item._id !== video_id);

          setdisplayvideos([...filteredarray]);
          setdeleteremoveloader({ [video_id]: false });
          dispatch(fetchhistoryvideos([...filteredarray]));
        }
      })
      .catch((e) => {
        setpageloader(false);
        setnodatafound(true);
        setdeleteremoveloader({ [video_id]: false });
        console.log(e);
      });
  }

  function removeSavedVideo(e, video_id) {
    setdeletesavedloader({ [video_id]: true });
    Services.savevideo(video_id)
      .then((res_) => {
        if (res_.data.status) {
          dispatch(fetchsavedvideos());
          setdeletesavedloader({ [video_id]: false });
        } else {
          setdeletesavedloader({ [video_id]: false });
        }
      })
      .catch((e) => {
        setpageloader(false);
        setnodatafound(true);
        setdeletesavedloader({ [video_id]: false });
        console.log(e);
      });
  }

  return (
    <>
      <div className="cstm-my-videos-main">
        <div className="cstm-my-videos-title">My videos</div>

        <div className="cstm-my-videos-tabs">
          <div id="tabs">
            <ul class="nav nav-tabs">
              <li className={activeTab === "history" ? "mv-active" : ""}>
                <a data-toggle="tab" className="active" href="#history">
                  History
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#saved">
                  Saved videos
                </a>
              </li>
              <li>
                <a data-toggle="tab" href="#downloaded">
                  Downloaded videos
                </a>
              </li>
            </ul>

            <div class="tab-content cstm-tabs-mv-cont">
              <div
                id="history"
                className={
                  activeTab === "history"
                    ? "tab-pane fade in show active"
                    : "tab-pane fade"
                }
              >
                {loader ? (
                  <div className="historyvideo-loading-image">
                    <span className="cstm-pli-bg">
                      <img src={loading} />
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                {nodatafound ? (
                  <div className="cstm-news-no-history-video no-cl-found">
                    {" "}
                    <img src={noMediaFound} alt="noMediaFound" />{" "}
                    <span className="emptymsg">No Media Found</span>{" "}
                  </div>
                ) : (
                  <></>
                )}
                <div className="cstm-my-history-videos-list">
                  {!loader &&
                    displayvideos !== undefined &&
                    displayvideos !== null &&
                    displayvideos.length > 0 &&
                    displayvideos.map((video, index) => {
                      if (
                        historyvideos !== null &&
                        historyvideos !== undefined &&
                        index === historyvideos.length - 1
                      ) {
                        return (
                          <InView
                            onChange={(inView, entry) => {
                              if (inView && !pageloader) {
                                setpageloader(true);
                                console.log("called in view");
                              }
                            }}
                          >
                            <div className="cstm-my-videos-list-box">
                              <a
                                onClick={() => {
                                  navigate(`/videos/${video.video_id}`);
                                }}
                              >
                                <div className="cstm-my-videos-list-box-img">
                                  <div className="cstm-vl-img">
                                    <img
                                      src={
                                        video.archive_video.thumbnail
                                          ? process.env
                                              .REACT_APP_AWS_IMG_VID_PATH +
                                            video.archive_video.thumbnail
                                          : videoimg
                                      }
                                    />
                                  </div>
                                  <span className="cstm-vl-new">New</span>
                                  <span className="cstm-vl-time">
                                    {video.archive_video.duration}
                                  </span>
                                </div>
                                <div className="cstm-my-videos-list-box-info">
                                  <div className="cstm-vl-tp">
                                    <span className="cstm-vl-title">
                                      {" "}
                                      {video.archive_video.title}
                                    </span>
                                    <span className="cstm-vl-sub">
                                      {video.archive_video.categories[0].name}
                                    </span>
                                  </div>
                                  <div className="cstm-vl-bt">
                                    <span className="cstm-vl-yr">
                                      {moment(video.history_date).fromNow()}
                                    </span>
                                    <span className="csrtm-vl-dt">&#9679;</span>
                                    <span className="cstm-vl-yr">
                                      {video.archive_video.viewsCount} views
                                    </span>
                                  </div>
                                </div>
                              </a>
                              <div className="cstm-my-videos-list-box-dt">
                                <a
                                  onClick={(e) =>
                                    toggleRemoveButton(e, video.video_id)
                                  }
                                >
                                  <img src={MoreLine} />
                                </a>
                              </div>
                              {deleteremoveloader[video._id] ? (
                                <div className="historyvideo-loading-image">
                                  <span className="cstm-pli-bg">
                                    <img src={loading} />
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div
                                className="cstm-removeOpen-btns"
                                style={{
                                  display: deletevideoBtnStatus[video.video_id]
                                    ? "block"
                                    : "none",
                                }}
                              >
                                <a
                                  data-videoId={video.video_id}
                                  onClick={(e) => {
                                    removeHistoryVideo(e, video._id);
                                  }}
                                >
                                  Remove from History Videos
                                </a>
                              </div>
                            </div>
                          </InView>
                        );
                      } else {
                        return (
                          <div className="cstm-my-videos-list-box">
                            <a
                              onClick={() => {
                                navigate(`/videos/${video.video_id}`);
                              }}
                            >
                              <div className="cstm-my-videos-list-box-img">
                                <div className="cstm-vl-img">
                                  <img
                                    src={
                                      video.archive_video.thumbnail
                                        ? process.env
                                            .REACT_APP_AWS_IMG_VID_PATH +
                                          video.archive_video.thumbnail
                                        : videoimg
                                    }
                                  />
                                </div>
                                <span className="cstm-vl-new">New</span>
                                <span className="cstm-vl-time">
                                  {video.archive_video.duration}
                                </span>
                              </div>
                              <div className="cstm-my-videos-list-box-info">
                                <div className="cstm-vl-tp">
                                  <span className="cstm-vl-title">
                                    {" "}
                                    {video.archive_video.title}
                                  </span>
                                  <span className="cstm-vl-sub">
                                    {video.archive_video.categories[0].name}
                                  </span>
                                </div>
                                <div className="cstm-vl-bt">
                                  <span className="cstm-vl-yr">
                                    {moment(video.history_date).fromNow()}
                                  </span>
                                  <span className="csrtm-vl-dt">&#9679;</span>
                                  <span className="cstm-vl-yr">
                                    {video.archive_video.viewsCount} views
                                  </span>
                                </div>
                              </div>
                            </a>
                            <div className="cstm-my-videos-list-box-dt">
                              <a
                                onClick={(e) =>
                                  toggleRemoveButton(e, video.video_id)
                                }
                              >
                                <img src={MoreLine} />
                              </a>
                            </div>

                            {deleteremoveloader[video._id] ? (
                              <div className="historyvideo-loading-image">
                                <span className="cstm-pli-bg">
                                  <img src={loading} />
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div
                              className="cstm-removeOpen-btns"
                              style={{
                                display: deletevideoBtnStatus[video.video_id]
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <a
                                data-videoId={video.video_id}
                                onClick={(e) => {
                                  removeHistoryVideo(e, video._id);
                                }}
                              >
                                Remove from History Videos
                              </a>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
              <div id="saved" class="tab-pane fade">
                {savedvideos.length > 0 ? (
                  <div className="cstm-my-videos-list">
                    {savedvideos.map((video) => {
                      return (
                        <div className="cstm-my-videos-list-box">
                          <a
                            onClick={() => {
                              navigate(`/videos/${video._id}`);
                            }}
                          >
                            <div className="cstm-my-videos-list-box-img">
                              <div className="cstm-vl-img">
                                <img
                                  src={
                                    video.thumbnail
                                      ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                        video.thumbnail
                                      : videoimg
                                  }
                                />
                              </div>
                              <span className="cstm-vl-new">New</span>
                              <span className="cstm-vl-time">
                                {video.duration}
                              </span>
                            </div>
                            <div className="cstm-my-videos-list-box-info">
                              <div className="cstm-vl-tp">
                                <span className="cstm-vl-title">
                                  {video.title}
                                </span>
                                <span className="cstm-vl-sub">
                                  {video.categories[0].name}
                                </span>
                              </div>
                              <div className="cstm-vl-bt">
                                <span className="cstm-vl-yr">
                                  {moment(video.createdAt).fromNow()}
                                </span>
                                <span className="csrtm-vl-dt">&#9679;</span>
                                <span className="cstm-vl-yr">
                                  {video.viewsCount} views
                                </span>
                              </div>
                            </div>
                          </a>
                          <div
                            className="cstm-my-videos-list-box-dt"
                            onClick={(e) =>
                              toggleSavedRemoveButton(e, video._id)
                            }
                          >
                            <img src={MoreLine} />
                            {deletesavedloader[video._id] ? (
                              <div className="savedvideo-loading-image">
                                <span className="cstm-pli-bg">
                                  <img src={loading} />
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            className="cstm-removeOpen-saved-btns"
                            style={{
                              display: deletesavedvideoBtnStatus[video._id]
                                ? "block"
                                : "none",
                            }}
                          >
                            <a
                              onClick={(e) => {
                                removeSavedVideo(e, video._id);
                              }}
                            >
                              Unsaved videos
                            </a>
                          </div>
                        </div>
                        // </a>
                      );
                    })}
                  </div>
                ) : (
                  <span className="mv-no-data-found">No Data Found</span>
                )}
              </div>
              <div id="downloaded" class="tab-pane fade">
                <span className="mv-no-data-found">No Data Found</span>
              </div>
            </div>
          </div>
          {/* <ul>
                        <li className="mv-active"><a href="#">History</a></li>
                        <li><a href="#">Saved videos</a></li>
                        <li><a href="#">Downloaded videos</a></li>
                    </ul> */}
        </div>

        {/* <div className="cstm-my-videos-list-box">
                        <div className="cstm-my-videos-list-box-img">
                            <div className="cstm-vl-img"><img src={Galleryimg1} /></div>
                            <span className="cstm-vl-new">New</span>
                            <span className="cstm-vl-time">1h 3m</span>
                        </div>
                        <div className="cstm-my-videos-list-box-info">
                            <div className="cstm-vl-tp">
                                <span className="cstm-vl-title">Test video, do not delete. Here we need a very long name to make designer's life miserable</span>
                                <span className="cstm-vl-sub">Account health</span>
                            </div>
                            <div className="cstm-vl-bt">
                                <span className="cstm-vl-yr">1 year ago</span>
                                <span className="csrtm-vl-dt">&#9679;</span>
                                <span className="cstm-vl-yr">10 views</span>
                            </div>
                        </div>
                        <div className="cstm-my-videos-list-box-dt"><img src={MoreLine} /></div>
                    </div>

                    <div className="cstm-my-videos-list-box">
                        <div className="cstm-my-videos-list-box-img">
                            <div className="cstm-vl-img"><img src={Galleryimg1} /></div>
                            <span className="cstm-vl-new">New</span>
                            <span className="cstm-vl-time">1h 3m</span>
                        </div>
                        <div className="cstm-my-videos-list-box-info">
                            <div className="cstm-vl-tp">
                                <span className="cstm-vl-title">Test video, do not delete. Here we need a very long name to make designer's life miserable</span>
                                <span className="cstm-vl-sub">Account health</span>
                            </div>
                            <div className="cstm-vl-bt">
                                <span className="cstm-vl-yr">1 year ago</span>
                                <span className="csrtm-vl-dt">&#9679;</span>
                                <span className="cstm-vl-yr">10 views</span>
                            </div>
                        </div>
                        <div className="cstm-my-videos-list-box-dt"><img src={MoreLine} /></div>
                    </div>

                    <div className="cstm-my-videos-list-box">
                        <div className="cstm-my-videos-list-box-img">
                            <div className="cstm-vl-img"><img src={Galleryimg1} /></div>
                            <span className="cstm-vl-new">New</span>
                            <span className="cstm-vl-time">1h 3m</span>
                        </div>
                        <div className="cstm-my-videos-list-box-info">
                            <div className="cstm-vl-tp">
                                <span className="cstm-vl-title">Test video, do not delete. Here we need a very long name to make designer's life miserable</span>
                                <span className="cstm-vl-sub">Account health</span>
                            </div>
                            <div className="cstm-vl-bt">
                                <span className="cstm-vl-yr">1 year ago</span>
                                <span className="csrtm-vl-dt">&#9679;</span>
                                <span className="cstm-vl-yr">10 views</span>
                            </div>
                        </div>
                        <div className="cstm-my-videos-list-box-dt"><img src={MoreLine} /></div>
                    </div>

                    <div className="cstm-my-videos-list-box">
                        <div className="cstm-my-videos-list-box-img">
                            <div className="cstm-vl-img"><img src={Galleryimg1} /></div>
                            <span className="cstm-vl-new">New</span>
                            <span className="cstm-vl-time">1h 3m</span>
                        </div>
                        <div className="cstm-my-videos-list-box-info">
                            <div className="cstm-vl-tp">
                                <span className="cstm-vl-title">Test video, do not delete. Here we need a very long name to make designer's life miserable</span>
                                <span className="cstm-vl-sub">Account health</span>
                            </div>
                            <div className="cstm-vl-bt">
                                <span className="cstm-vl-yr">1 year ago</span>
                                <span className="csrtm-vl-dt">&#9679;</span>
                                <span className="cstm-vl-yr">10 views</span>
                            </div>
                        </div>
                        <div className="cstm-my-videos-list-box-dt"><img src={MoreLine} /></div>
                    </div> */}
      </div>

      {/* </div> */}

      {/* <div className="cstm-my-videos-main">
                <div className="cstm-my-videos-title">My videos</div>
                <div className="cstm-my-videos-cont">
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="History" {...a11yProps(0)} />
                                <Tab label="Saved videos" {...a11yProps(1)} />
                                <Tab label="Downloaded videos" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            Item One
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {savedvideos.length > 0 ? (
                                <ul>
                                    {savedvideos.map((video) => {
                                        return (
                                            <li onClick={() => {
                                                navigate(`/videos/${video._id}`);
                                            }}>
                                                <div className="cstm-sg-list" >
                                                    <img src={video.thumbnail ? process.env.REACT_APP_AWS_IMG_VID_PATH + video.thumbnail : videoimg} />
                                                </div>
                                                <div className="cstm-sg-ttl-name">
                                                    <a className="cstm-sg-ttl-main">
                                                        {video.title}
                                                    </a>
                                                    <span className="cstm-video-like">
                                                        {video.likes.length} likes
                                                    </span>
                                                    <span className="cstm-video-view">
                                                        {video.viewsCount} views
                                                    </span>
                                                </div>
                                                <div className="cstm-sg-dotted">
                                                    <span
                                                        className="remove-srarred-img"
                                                        data-toggle="dropdown"
                                                    >
                                                        <img src={Dotted} />
                                                    </span>

                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                <span className="emptymsg">No Data Found</span>
                            )}
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            Item Three
                        </TabPanel>
                    </Box>

                </div>
            </div> */}
      {/* <SaveVideosList /> */}
    </>
  );
};

export default MyVideos;
