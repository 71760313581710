import React from 'react'
import PropTypes from "prop-types";
import { ReactComponent as ExclamationFilled } from '../../../Images/icn_s_exclamation_filled.svg'
import '../Styles/notificationBanner.css'

const NotificationBanner = ({
    icon = <ExclamationFilled />,
    title = "Title",
    size = "small",
    text = "Description",
    actionText = "Action",
    hasActionText = false,
    hasIcon = true,
    onClickFun = () => { },
    variant = 'success',
    extraClass = ""
}) => {
    return (
        <>
            <div className={`notification-banner-box notification-banner-bg-${variant} notification-banner-${size} ${extraClass}`}>
                {hasIcon && <div className="notification-banner-icon">
                    {icon}
                </div>}
                <div className='notification-banner-text-wrapper'>
                    {size === 'medium' && <p className='notification-banner-title'>{title}</p>}
                    <p className='notification-banner-text'>{text}</p>
                    {hasActionText && <p className="notification-banner-action-text" onClick={onClickFun}>{actionText}</p>}
                </div>
            </div>
        </>
    )
};

NotificationBanner.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    size: PropTypes.oneOf([
        "medium", "small",
    ]),
    text: PropTypes.string,
    actionText: PropTypes.string,
    hasActionText: PropTypes.bool,
    hasIcon: PropTypes.bool,
    onClickFun: PropTypes.func,
    variant: PropTypes.oneOf([
        "success", "warning", "info", 'danger', 'neutral'
    ]),
    extraClass: PropTypes.string,
};

export default NotificationBanner