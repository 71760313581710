import { useEffect, useState } from "react";
import Services from "../../Services/auth.service";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
// import TableSortLabel from "@material-ui/core/TableSortLabel";
import imgdelete from "../../Images/BackendImages/delete.svg";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import { color } from "@mui/system";
const columns = [
  { id: "name", label: "Name" },
  { id: "joined", label: "Joined" },
  { id: "status", label: "Member status" },
  { id: "active", label: "Last active" },
  { id: "action", label: "Actions" },
];

function createData(name, joined, status, active, action) {
  return {
    name,
    joined,
    status,
    active,
    action,
  };
}

const MemberList = ({ setcurrentDiv, selectedGid }) => {
  const formdata = new FormData();
  const [allmemb, setallmemb] = useState([]);
  const [rows, setrow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setdeleteId] = useState("");
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [searchText, setsearchText] = useState("");
  useEffect(() => {
    Services.groupmembers_admin(selectedGid)
      .then((res) => {
        if (res.data.status && res.data.data.length > 0) {
          var tbl = [];
          for (var i = 0; i < res.data.data.length; i++) {
            var date =
              res.data.data[i].updatedAt !== undefined
                ? new Date(res.data.data[i].updatedAt).toLocaleDateString(
                    "en-us",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    }
                  ) +
                  " at " +
                  new Date(res.data.data[i].updatedAt).toLocaleTimeString(
                    navigator.language,
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )
                : "";
            tbl[i] = createData(
              `${res.data.data[i].userId.first_name} ${res.data.data[i].userId.last_name}` ,
              date,
              res.data.data[i].user_type === "adminuser" ? "Admin" : "User",
              res.data.data[i].updatedAt,
              res.data.data[i].userId._id
            );
          }
          setallmemb(tbl);
          setrow(tbl);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    if (allmemb.length > 0) {
      var data = allmemb.filter((m) => {
        if (m.name.includes(searchText)) {
          return m;
        }
      });
      var tbl = [];
      for (var i = 0; i < data.length; i++) {
        tbl[i] = createData(
          data[i].name,
          data[i].joined,
          data[i].status,
          data[i].active,
          data[i].action
        );
      }
      setrow(tbl);
    }
  };
  const handleDelete = () => {
    var data = {
      groupId: selectedGid,
      memberId: deleteId,
    };
    Services.deletemember(data)
      .then((res) => {
        setshowalert(true);
        setalertmsg(res.data.message);
        setTimeout(() => {
          setshowalert(false);
          setalertmsg("");
        }, 3000);
        setcurrentDiv("dashboard");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleSorting = (e) => {
    var normal_arr = allmemb;
    var arr = [...allmemb];
    if (e.target.value === "a-z") {
      arr.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (e.target.value === "z-a") {
      arr.sort(function (a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else {
      arr = normal_arr;
    }
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].name,
        arr[i].joined,
        arr[i].status,
        arr[i].active,
        arr[i].action
      );
    }
    setrow(tbl);
  };

  const handleSort = (col, direc) => {
    var normal_arr = allmemb;
    var arr = [...allmemb];
    if (direc === "asc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (direc === "desc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else {
      arr = normal_arr;
    }
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].name,
        arr[i].joined,
        arr[i].status,
        arr[i].active,
        arr[i].action
      );
    }
    setrow(tbl);
  };

  return (
    <>
      <div className="uic-fl">
        <div className="uic-fl-search">
          <input
            type="text"
            placeholder="Search Group"
            className="search-user-input"
            value={searchText}
            onChange={(e) => {
              setsearchText(e.target.value);
            }}
          />
          <i className="fa fa-search" onClick={handleSearch}></i>
        </div>

        <div className="uic-fl-btn sort-by-mn">
          <div className="sort-by-mn-title">Sort by : </div>
          <div className="sort-by-mn-select">
            <select onChange={handleSorting}>
              <option>All</option>
              <option value="a-z">A-Z</option>
              <option value="z-a">Z-A</option>
            </select>
          </div>
        </div>
      </div>

      {showalert && <div className="alert alert-info">{alertmsg}</div>}
      <Paper className="uic-tb-mn">
        <TableContainer className="uic-table-main">
          <Table className="uic-table">
            <TableHead className="uic-table-th">
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className="uic-th-td w-10"
                  >
                    {column.id !== "action" ? (
                      <>
                        <span className="custom-sorting">
                          <i
                            className="fa fa-angle-down"
                            onClick={(e) => {
                              handleSort(column.id, "desc");
                            }}
                          ></i>
                          <i
                            className="fa fa-angle-up"
                            onClick={(e) => {
                              handleSort(column.id, "asc");
                            }}
                          ></i>
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="uic-table-td">
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                      className="uic-th-td w-20"
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return column.id !== "action" ? (
                          <TableCell key={column.id} className="uic-th-td w-20">
                            {value}
                          </TableCell>
                        ) : (
                          <TableCell
                            key={column.id}
                            className="uic-th-td uic-table-icon w-15"
                          >
                            {row.status !== "admin" ? (
                              <a
                                data-toggle="modal"
                                data-target="#DeleteFromList"
                                onClick={() => {
                                  setdeleteId(value);
                                }}
                              >
                                <img src={imgdelete} />
                              </a>
                            ) : (
                              ""
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className="uic-pagination"
          rowsPerPageOptions={[20, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Modal Delete */}
      <div
        className="modal fade msg-popup"
        id="DeleteFromList"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              &times;
            </button>
            <div className="msg-icon">
              <img src={imgbigdelete} />
            </div>
            <h1>Are you sure you want to delete this group?</h1>
            <div className="row">
              <div className="col-6">
                <span className="msg-btn">
                  <button
                    type="button"
                    className="btn btn-red"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </span>
              </div>
              <div className="col-6">
                <span className="msg-btn">
                  <button type="button" className="btn" onClick={handleDelete}>
                    Discard
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MemberList;
