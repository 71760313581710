import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ModalPopup from "../../ModalPopup/ModalPopup";

const NonAttendeePopUpModal = ({
  setShowNonAttendeeListPopUp,
  showNonAttendeeListPopUp,
  apiType,
  handleCreate,
  handleSaveDraft,
  handleEditSubmit,
  handleEditSaveDraft,
  modalLoader,
  nonAttendees,
  eventDetail,
}) => {
  return (
    <ModalPopup
      onClose={() => {
        setShowNonAttendeeListPopUp(false);
      }}
      isFooter={true}
      isHeader={true}
      modelSize="648"
      buttonText="Cancel"
      buttonText1="Okay"
      isOpen={showNonAttendeeListPopUp}
      className="z-full"
      handleClick2ndButton={
        apiType === "create"
          ? handleCreate
          : apiType === "draft"
          ? handleSaveDraft
          : apiType === "edit"
          ? handleEditSubmit
          : handleEditSaveDraft
      }
      headingLable={"Non-attendee emails detected"}
      headingDescription={`Note: Below emails are not attendees for ${eventDetail?.title} event. So they will not be assigned to activity.`}
      hasHeadingDescription={true}
      hasLoading={modalLoader}
    >
      <div className="ctsm-modal-body scrollbar no-border-scrollbar">
        <div className="checkin-modal-body-wrapper">
          <Paper className="uic-tb-mn ">
            <TableContainer className=" uic-table-main ">
              <Table className="">
                <TableHead className="uic-table-th">
                  <TableRow>
                    <TableCell className="uic-th-td">No.</TableCell>
                    <TableCell className="uic-th-td">Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="uic-table-td">
                  {nonAttendees?.length > 0 &&
                    nonAttendees !== undefined &&
                    nonAttendees.map((email, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell className="uic-th-td">
                            {index + 1}
                          </TableCell>
                          <TableCell className="uic-th-td">{email}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </ModalPopup>
  );
};

export default NonAttendeePopUpModal;
