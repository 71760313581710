import React from "react";

const Email = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={120}
      height={120}
      fill="none"
    >
      <circle cx={60} cy={60} r={60} fill="#6FCADF" fillOpacity={0.2} />
      <path
        fill="#060D14"
        d="M60.001 36C46.746 36 36 46.745 36 60s10.746 24 24.001 24c4.969 0 9.701-1.671 13.545-4.276.254-.111.482-.266.679-.455a.391.391 0 0 1 .011-.007v-.001a2.323 2.323 0 0 0-1.618-3.99 2.31 2.31 0 0 0-1.246.366l-.009-.011c-3.194 2.326-7.107 3.723-11.362 3.723-10.685 0-19.347-8.663-19.347-19.348 0-10.686 8.661-19.348 19.347-19.348 10.687 0 19.35 8.662 19.35 19.347 0 1.615-.2 3.182-.578 4.679-.074.297-.185.578-.273.87-.566 1.482-1.534 2.312-3.001 2.312a3.141 3.141 0 0 1-3.142-3.142c0-.014.004-.026.004-.039h-.004V51.297h-.014a2.222 2.222 0 0 0-2.215-2.1c-.953 0-1.76.6-2.08 1.44a12.273 12.273 0 0 0-8.047-2.991c-6.823 0-12.355 5.532-12.355 12.355S53.177 72.356 60 72.356c3.517 0 6.683-1.477 8.934-3.834a7.666 7.666 0 0 0 6.638 3.834c2.659 0 5-1.353 6.378-3.407C83.506 66.632 84 63.166 84 59.999c.002-13.253-10.744-23.998-23.999-23.998V36Zm0 31.895a7.895 7.895 0 1 1 0-15.79 7.896 7.896 0 0 1 0 15.79Z"
      />
    </svg>
  );
};

export default Email;
