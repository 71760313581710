import Image1 from "../../../Images/app_logo.png";
import Yes2 from "../../../Images/yes2.svg";
import Groupposts from "../../../Images/group-posts.svg";

// Icons
import CheckDoubleLineIcon from "remixicon-react/CheckDoubleLineIcon";

const NotificationList = () => {
    return (
      <>
        {/* <span className="close-noti">✖</span> */}
        <div className="header-noti-box-cont-title">Notifications</div>

        <div className="header-noti-box-cont-tabs">
          <span className="mark-as-all-read">
            {/* <img src={Yes2} /> */}
            <CheckDoubleLineIcon size="16px" />
            <span className="ms-All-read">Mark as all read</span>
          </span>
          <ul className="nav noti-nav-tabs">
            <li>
              <a className=" active" data-toggle="tab" href="#s1">
                All
              </a>
            </li>
            <li>
              <a className="" data-toggle="tab" href="#s2">
                Unread
              </a>
            </li>
          </ul>

          <div className="tab-content header-noti-cont-tabs-main">
            <div className="tab-pane container fade in show active" id="s1">
              <div className="header-noti-box-cont-tabs-head">
                <div className="header-noti-box-cont-tabs-head-title">New</div>
                <div className="header-noti-box-cont-tabs-head-view-all">
                  <a href="#">View All</a>
                </div>
              </div>
              <div className="header-noti-box-cont-tabs-cont">
                <ul>
                  <li>
                    <div className="head-noti-user">
                      <img className="noti-usr-photo" src={Image1} />
                      <img className="noti-usr-icon" src={Groupposts} />
                    </div>
                    <div className="head-noti-nametime">
                      <span className="head-noti-name">
                        <a href="#">Ronald Richards</a> posted two photos in
                        Amazon.
                      </span>
                      <span className="head-noti-time">2h ago</span>
                    </div>
                    <div className="head-noti-new">New</div>
                  </li>
                  <li>
                    <div className="head-noti-user">
                      <img className="noti-usr-photo" src={Image1} />
                      <img className="noti-usr-icon" src={Groupposts} />
                    </div>
                    <div className="head-noti-nametime">
                      <span className="head-noti-name">
                        <a href="#">Ronald Richards</a> posted two photos in
                        Amazon.
                      </span>
                      <span className="head-noti-time">2h ago</span>
                    </div>
                    <div className="head-noti-new">New</div>
                  </li>
                  <li>
                    <div className="head-noti-user">
                      <img className="noti-usr-photo" src={Image1} />
                      <img className="noti-usr-icon" src={Groupposts} />
                    </div>
                    <div className="head-noti-nametime">
                      <span className="head-noti-name">
                        <a href="#">Ronald Richards</a> posted two photos in
                        Amazon.
                      </span>
                      <span className="head-noti-time">2h ago</span>
                    </div>
                    <div className="head-noti-new">New</div>
                  </li>
                  <li>
                    <div className="head-noti-user">
                      <img className="noti-usr-photo" src={Image1} />
                      <img className="noti-usr-icon" src={Groupposts} />
                    </div>
                    <div className="head-noti-nametime">
                      <span className="head-noti-name">
                        <a href="#">Ronald Richards</a> posted two photos in
                        Amazon.
                      </span>
                      <span className="head-noti-time">2h ago</span>
                    </div>
                    <div className="head-noti-new">New</div>
                  </li>
                </ul>
              </div>
              <hr className="noti-line" />
              <div className="header-noti-box-cont-tabs-head">
                <div className="header-noti-box-cont-tabs-head-title">Old</div>
              </div>
              <div className="header-noti-box-cont-tabs-cont">
                <ul>
                  <li>
                    <div className="head-noti-user">
                      <img className="noti-usr-photo" src={Image1} />
                      <img className="noti-usr-icon" src={Groupposts} />
                    </div>
                    <div className="head-noti-nametime">
                      <span className="head-noti-name">
                        <a href="#">Ronald Richards</a> posted two photos in
                        Amazon.
                      </span>
                      <span className="head-noti-time">2h ago</span>
                    </div>
                  </li>
                  <li>
                    <div className="head-noti-user">
                      <img className="noti-usr-photo" src={Image1} />
                      <img className="noti-usr-icon" src={Groupposts} />
                    </div>
                    <div className="head-noti-nametime">
                      <span className="head-noti-name">
                        <a href="#">Ronald Richards</a> posted two photos in
                        Amazon.
                      </span>
                      <span className="head-noti-time">2h ago</span>
                    </div>
                  </li>
                  <li>
                    <div className="head-noti-user">
                      <img className="noti-usr-photo" src={Image1} />
                      <img className="noti-usr-icon" src={Groupposts} />
                    </div>
                    <div className="head-noti-nametime">
                      <span className="head-noti-name">
                        <a href="#">Ronald Richards</a> posted two photos in
                        Amazon.
                      </span>
                      <span className="head-noti-time">2h ago</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="tab-pane container unreadnoti fade" id="s2">
              <div className="header-noti-box-cont-tabs-cont">
                <ul>
                  <li>
                    <div className="head-noti-user">
                      <img className="noti-usr-photo" src={Image1} />
                      <img className="noti-usr-icon" src={Groupposts} />
                    </div>
                    <div className="head-noti-nametime">
                      <span className="head-noti-name">
                        <a href="#">Ronald Richards</a> posted two photos in
                        Amazon.
                      </span>
                      <span className="head-noti-time">2h ago</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
export default NotificationList;