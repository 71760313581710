import React from "react";
import PropTypes from "prop-types";
function ArrowChevronUp({ height = 24, width = 24, iconColor = "#000" }) {
  return (
    <>
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
           fill={iconColor}
          d="M19.53 15.78a.75.75 0 0 1-.976.073l-.084-.073L12 9.311l-6.47 6.47a.75.75 0 0 1-.976.072l-.084-.073a.75.75 0 0 1-.073-.976l.073-.084 7-7a.75.75 0 0 1 .976-.073l.084.073 7 7a.75.75 0 0 1 0 1.06Z"
        />
      </svg>
    </>
  );
}
ArrowChevronUp.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  iconColor: PropTypes.string,
};
export default ArrowChevronUp;
