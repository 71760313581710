import React, { useState } from "react";
import DotsIcon from "../../Icons/GeneralIcons/DotsIcon";
import Button from "../../Button/Button";
import ReplyCommentList from "./ReplyCommentList";
import ReplyCommentBox from "./ReplyCommentBox";
import Avtar from "../../Avtar/Avtar";
import { ReactComponent as DownIcons } from "../../../Images/icn_s_chevron-down.svg";
import DeleteIcon from "../../Icons/GeneralIcons/DeleteIcon";
import { ReactComponent as ReplyIcon } from "../../../Images/reply.svg";
import { ReactComponent as IconSuccessful } from "../../../Images/Successful.svg";
import ComplainIcon from "../../Icons/GeneralIcons/ComplainIcon";
import EditIcon from "../../Icons/GeneralIcons/EditIcon";
import moment from "moment";
import ShowMoreText from "react-show-more-text";
import ModalPopup from "../../ModalPopup/ModalPopup";
import EmptyState from "../../EmptyState/EmptyState";
import { Link } from "react-router-dom";
import authService from "../../../Services/auth.service";
import CrossIcon from "../../Icons/GeneralIcons/CrossIcon";

const SingleComment = ({
  comment,
  selectedVideoId,
  setAllComments,
  suggestionList,
  setTotalCommts,
  setDeleteComment,
  totalCommts,
}) => {
  const [commentBox, setCommentBox] = useState({
    box: false,
    id: "",
  });
  const [editComment, setEditComment] = useState({
    box: false,
    id: "",
    message: "",
  });
  const [commentDelete, setCommentDelete] = useState({
    isOpen: false,
    commentId: "",
  });
  const [reportModal, setReportModal] = useState(false);
  const [commentlist, setCommentlist] = useState(false);
  const [dropPos, setDropPos] = useState(false);
  const [commentDropDown, setCommentCommentDropDown] = useState(false);
  const [deletetInprocess, setDeletetInprocess] = useState({});
  const [deletedCommentLoading, setDeleteCommentLoading] = useState(false);
  const [repliedComment, setRepliedComment] = useState({
    isReply: false,
    comment: {},
  });
  const handleCommentBox = (id) => {
    setCommentBox((prev) => ({
      box: prev.id !== id ? true : !prev.box,
      id: id,
    }));
  };

  const handleEditCommentBox = (id, message) => {
    setEditComment((prev) => ({ box: !prev.box, id: id, message: message }));
  };

  const handleCommentDrop = (event) => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const buttonRect = event.target.getBoundingClientRect();
    const buttonBottomRelativeToViewport = buttonRect.bottom + scrollTop;

    const windowHeight = window.innerHeight;
    const isButtonAtBottom =
      buttonBottomRelativeToViewport >= scrollTop + windowHeight - 200; // Adjust 10 as needed for tolerance
    if (isButtonAtBottom) {
      setDropPos(true);
    } else {
      setDropPos(false);
    }

    setCommentCommentDropDown((ele) => !ele);
  };

  const handleExpandCommentList = () => {
    setCommentlist((prev) => !prev);
    setRepliedComment({ isReply: false, comment: {} });
  };
  const handleReport = () => {
    setReportModal(true);
  };

  const deletecmt = (videoid, comtid) => {
    setDeleteCommentLoading(true);
    var data = {
      videoId: videoid,
      commId: comtid,
    };
    setDeletetInprocess({ ...deletetInprocess, [comtid]: true });
    authService
      .deletecomment_video(data)
      .then((res) => {
        if (res.data.status) {
          setDeleteComment(true);
          setDeleteCommentLoading(false);
          setCommentDelete({ isOpen: false, commentId: "" });
          setAllComments((prev) => {
            return prev.filter((cmt) => cmt._id !== comtid);
          });
          setTotalCommts((prev) => prev - 1);
        } else {
          setDeleteCommentLoading(false);
        }
        setTimeout(() => {
          setDeleteComment(false);
        }, 2000);
      })
      .catch((e) => {
        setDeleteCommentLoading(false);
        console.log(e);
      });
  };

  return (
    <>
      <div className="comment-list-item">
        <div className="comment-wrapper">
          <Avtar size="40" src={comment?.userId?.profileImg} />
          <div className="comment-details">
            <div
              className="comment-text"
              onMouseLeave={() => setCommentCommentDropDown(false)}
            >
              <div className="comment-heading">
                <h5>{`${comment?.userId?.display_name ? comment?.userId?.display_name : ""}`}</h5>
                <span className="comment-date">
                  {moment(comment?.createdAt).format("MMM DD, YYYY")}
                </span>
              </div>
              {editComment.box ? (
                <ReplyCommentBox
                  commentBox={editComment.box}
                  message={editComment.message}
                  userImageSrc={localStorage.getItem("userprofile")}
                  commentId={comment._id}
                  cancelClick={() => handleEditCommentBox("", "")}
                  selectedVideoId={selectedVideoId}
                  setAllComments={setAllComments}
                  totalCommts={totalCommts}
                  setTotalCommts={setTotalCommts}
                />
              ) : (
                <ShowMoreText
                  lines={4}
                  more="Read more"
                  less="Read less"
                  className="content-css"
                  anchorclassName="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  <p>{comment?.content}</p>
                </ShowMoreText>
              )}

              <Button
                variant="ghost"
                extraClass="comment-dot-btn"
                size={"small"}
                handleClick={handleCommentDrop}
                hasText={false}
                elementIcon={<DotsIcon />}
              >
                {commentDropDown && (
                  <div className={`comment-option ${dropPos && "top"}`}>
                    {localStorage.getItem("userid") !== comment.userId._id ? (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            handleCommentBox(comment._id);
                          }}
                        >
                          <ReplyIcon />
                          Reply
                        </button>
                        {/* <button type="button" onClick={handleReport}>
                      <ComplainIcon />
                      Report
                    </button> */}
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          onMouseDown={() => {
                            handleEditCommentBox(comment._id, comment.content);
                          }}
                        >
                          <EditIcon />
                          Edit
                        </button>
                        <button
                          type="button"
                          className="danger-text"
                          onClick={() => {
                            setCommentDelete((prev) => ({
                              isOpen: !prev.isOpen,
                              commentId: comment._id,
                            }));
                          }}
                        >
                          <DeleteIcon />
                          Delete
                        </button>
                      </>
                    )}
                  </div>
                )}
              </Button>
            </div>
            <div className="reply-wrapper">
              <div className="reply-btns">
                {comment?.comments && comment?.comments.length > 0 && (
                  <Button
                    variant="ghost"
                    hasText={true}
                    size="small"
                    handleClick={handleExpandCommentList}
                    extraClass={`comment-reply-btn ${
                      commentlist && "collapsed"
                    }`}
                    label={`${comment?.comments.length} ${
                      comment?.comments.length > 1 ? "Replies" : "Reply"
                    }`}
                    hasIconRight={true}
                    elementIcon={<DownIcons />}
                  />
                )}
                <Button
                  variant="ghost"
                  hasText={true}
                  size="small"
                  handleClick={() => handleCommentBox(comment._id)}
                  label="Reply"
                />
              </div>
              {(commentlist || commentBox.box || repliedComment.isReply) && (
                <div className="reply-comment-list comment-list-wrapper">
                  {repliedComment.isReply && !commentlist && (
                    <ReplyCommentList
                      setDeleteComment={setDeleteComment}
                      setTotalCommts={setTotalCommts}
                      setAllComments={setAllComments}
                      commentId={repliedComment.comment._id}
                      selectedVideoId={selectedVideoId}
                      suggestionList={suggestionList}
                      userImageSrc={repliedComment.comment?.userId?.profileImg}
                      userName={`${repliedComment.comment?.userId?.display_name}`}
                      comment={repliedComment.comment?.content}
                      commentDate={moment(comment?.createdAt).format(
                        "MMM DD, YYYY"
                      )}
                      userId={repliedComment.comment?.userId?._id}
                      mainCommentId={comment._id}
                      totalCommts={totalCommts}
                      
                    />
                  )}
                  {commentlist &&
                    comment?.comments &&
                    comment?.comments.length > 0 &&
                    comment?.comments.map((repComm) => (
                      <ReplyCommentList
                        setDeleteComment={setDeleteComment}
                        setTotalCommts={setTotalCommts}
                        setAllComments={setAllComments}
                        commentId={repComm._id}
                        selectedVideoId={selectedVideoId}
                        suggestionList={suggestionList}
                        userImageSrc={repComm?.userId?.profileImg}
                        userName={`${repComm?.userId?.display_name}`}
                        comment={repComm?.content}
                        commentDate={moment(comment?.createdAt).format(
                          "MMM DD, YYYY"
                        )}
                        userId={repComm?.userId?._id}
                        mainCommentId={comment._id}
                        totalCommts={totalCommts}
                      />
                    ))}
                  {commentBox.box && commentBox.id === comment._id && (
                    <ReplyCommentBox
                      commentBox={commentBox.box}
                      userImageSrc={localStorage.getItem("userprofile")}
                      commentId={comment._id}
                      cancelClick={() => handleCommentBox("")}
                      selectedVideoId={selectedVideoId}
                      setAllComments={setAllComments}
                      setRepliedComment={setRepliedComment}
                      totalCommts={totalCommts}
                      setTotalCommts={setTotalCommts}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* delete comment */}
      <ModalPopup
        hasLoading={deletedCommentLoading}
        disabled={deletedCommentLoading}
        isOpen={commentDelete.isOpen}
        isFooter={true}
        isHeader={false}
        btn2variant="danger"
        buttonText1="Delete"
        buttonText="Cancel"
        className="delete-video-modal"
        onClose={() => setCommentDelete({ isOpen: false, commentId: "" })}
        handleClick2ndButton={() =>
          deletecmt(selectedVideoId, commentDelete.commentId)
        }
      >
        <div className="ctsm-modal-body">
          <Button
            size="small"
            variant="ghost"
            elementIcon={<CrossIcon />}
            handleClick={() =>
              setCommentDelete({ isOpen: false, commentId: "" })
            }
            hasText={false}
            toolTipText="Close"
          />
          <h3 className="mb-2">Delete comment</h3>
          <p>Delete your comment permanently?</p>
        </div>
      </ModalPopup>
      {/* Report message */}
      <ModalPopup
        isOpen={reportModal}
        isFooter={false}
        isHeader={false}
        className="report-modal"
        modelSize="648"
      >
        <div className="ctsm-modal-body">
          <EmptyState
            title="Thank you for your report!"
            hasIcon={true}
            icon={<IconSuccessful />}
            onClick={() => {
              setReportModal(false);
            }}
            label="Got it, thanks"
            subTitle={
              <>
                We're here to keep our community safe. If this content goes
                against our{" "}
                <Link to="/" target="_blank">
                  Community Guidelines
                </Link>{" "}
                , we'll remove it.
              </>
            }
          />
        </div>
      </ModalPopup>
    </>
  );
};

export default SingleComment;
