import Services from "../Services/auth.service1";

export function retrivehistoryvideos(data) {
  return async (dispatch, getState) => {
    if (data !== undefined && data !=null)
    {
      dispatch({
        type: "SETHISTORYVIDEOLOADER"
      });  
      await Services.retrievehistoryvideos(data).then((response)=>{
            if (response.data.status)
            {
            dispatch({
              type: "FETCHHISTORYVIDEOS",
              payload:  response.data.data.length > 0 ? response.data.data[0].video_history_data : []
            });

            dispatch({
              type: "REMOVEHISTORYVIDEOLOADER"
            });
        }
        }) .catch((e) => {
          dispatch({
            type: "REMOVEHISTORYVIDEOLOADER"
          });
          console.log(e);
        });
  }
  };
}

export const fetchhistoryvideos = (data) => {
  
  return {
    type: "FETCH_HISTORY_VIDEOS",
    payload: data,
  };
};
export const addhistoryvideo = (data) => {
  return {
    type: "ADD_HISTORY_VIDEOS",
    payload: data,
  };
};