import millify from "millify";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as LikeIcon } from "../../../Images/icn_m_like.svg";
import { ReactComponent as LikedIcon } from "../../../Images/icn_m_like_filled.svg";
import { ReactComponent as UnLikeIcon } from "../../../Images/icn_m_dislike.svg";
import { ReactComponent as UnLikedIcon } from "../../../Images/icn_m_dislike_filled.svg";
import { ReactComponent as SaveViideoTagIcon } from "../../../Images/icn_nav_video_saved.svg";
import { ReactComponent as SavedViideoTagIcon } from "../../../Images/icn_nav_video_saved_filled.svg";
import { ReactComponent as ShareIcon } from "../../../Images/icn_m_share.svg";
import Button from "../../Button/Button";
import DownloadIcon from "../../Icons/GeneralIcons/DownloadIcon";
import PauseIcon from "../../Icons/GeneralIcons/PauseIcon";
import DeleteIcon from "../../Icons/GeneralIcons/DeleteIcon";
import ModelPopup from "../../ModalPopup/ModalPopup";
import CrossIcon from "../../Icons/GeneralIcons/CrossIcon";

import { fetchsavedvideos } from "../../../Action";
import authService from "../../../Services/auth.service";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const InfoTab = ({ videodetail, setvideodetail, selectedVideoId }) => {
  const downloadref = useRef(null);
  const downloaDropdref = useRef(null);
  const [downloadVideo, setDownloadVideo] = useState(false);
  const [downloadVideoDrop, setDownloadVideoDrop] = useState(false);
  const [savedloader, setsavedloader] = useState(false);
  const [removeDownloadVideoModal, setRemoveDownloadVideoModal] =
    useState(false);
  const savedvideos = useSelector((state) => {
    return state.ReduSavedVideos;
  });
  const dispatch = useDispatch();
  const [isSaved, setSaved] = useState(false);

  useEffect(() => {
    if (
      savedvideos.filter((vid) => vid._id.toString() === selectedVideoId)
        .length === 0
    ) {
      setSaved(false);
    } else {
      setSaved(true);
    }
  }, [savedvideos]);

  const handleLike = () => {
    const liked = videodetail.likes.some(
      (usr) => usr.id === localStorage.getItem("userid")
    );
    const disliked = videodetail.dislikes.some(
      (usr) => usr.id === localStorage.getItem("userid")
    );

    if (liked) {
      // User already liked the video, remove like
      const newLikes = videodetail.likes.filter(
        (usr) => usr.id !== localStorage.getItem("userid")
      );
      setvideodetail({ ...videodetail, likes: newLikes });
      const payload = {
        clickOn: "like",
      };
      authService.addlikebyuser(selectedVideoId, payload);
    } else {
      // User hasn't liked the video
      const newLikes = [
        ...videodetail.likes,
        {
          id: localStorage.getItem("userid"),
          name: localStorage.getItem("username"),
        },
      ];
      // If the user had previously disliked the video, remove the dislike
      const newDisLikes = videodetail.dislikes.filter(
        (usr) => usr.id !== localStorage.getItem("userid")
      );
      setvideodetail({
        ...videodetail,
        likes: newLikes,
        dislikes: newDisLikes,
      });
      const payload = {
        clickOn: "like",
      };
      authService.addlikebyuser(selectedVideoId, payload);
    }
  };

  const handleDislike = () => {
    const disliked = videodetail.dislikes.some(
      (usr) => usr.id === localStorage.getItem("userid")
    );
    const liked = videodetail.likes.some(
      (usr) => usr.id === localStorage.getItem("userid")
    );

    if (disliked) {
      // User already disliked the video, remove dislike
      const newDisLikes = videodetail.dislikes.filter(
        (usr) => usr.id !== localStorage.getItem("userid")
      );
      setvideodetail({ ...videodetail, dislikes: newDisLikes });
      const payload = {
        clickOn: "dislike",
      };
      authService.addlikebyuser(selectedVideoId, payload);
    } else {
      // User hasn't disliked the video
      const newDisLikes = [
        ...videodetail.dislikes,
        {
          id: localStorage.getItem("userid"),
          name: localStorage.getItem("username"),
        },
      ];
      // If the user had previously liked the video, remove the like
      const newLikes = videodetail.likes.filter(
        (usr) => usr.id !== localStorage.getItem("userid")
      );
      setvideodetail({
        ...videodetail,
        likes: newLikes,
        dislikes: newDisLikes,
      });
      const payload = {
        clickOn: "dislike",
      };
      authService.addlikebyuser(selectedVideoId, payload);
    }
  };

  const handlesavevideo = () => {
    if (isSaved) {
      setSaved(false);
    } else {
      setSaved(true);
    }
    setsavedloader(true);
    authService
      .savevideo(selectedVideoId)
      .then((res) => {
        dispatch(fetchsavedvideos());
        setsavedloader(false);
      })
      .catch((e) => {
        setsavedloader(false);
        return false;
      });
  };

  const hanleDownloadVideo = () => {
    setDownloadVideo(true);
    if (downloadVideo) {
      setDownloadVideoDrop((ele) => !ele);
    }
  };

  const handlePauseDownloading = () => {
    setDownloadVideo(false);
    setDownloadVideoDrop(false);
  };

  const handleCloseDeletVideoFromDownload = () => {
    setDownloadVideoDrop(false);
    setRemoveDownloadVideoModal(false);
  };

  const handleDeletVideoFromDownload = () => {
    setDownloadVideo(false);
    setDownloadVideoDrop(false);
    setRemoveDownloadVideoModal(false);
  };

  const handleRemoveFromDownloading = () => {
    setRemoveDownloadVideoModal(true);
  };

  const handleEscKey = (event) => {
    if (event.key === "Escape") {
      // Escape key pressed, hide the component
      setDownloadVideoDrop(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      downloadref.current &&
      !downloadref.current.contains(event.target) &&
      downloadVideoDrop
    ) {
      setDownloadVideoDrop(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  useEffect(() => {
    // document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);
    return () => {
      // document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleEscKey);
    };
  }, []);
  return (
    <div className="video-details-wrapper">
      <div className="video-details-heading-text">
        <h4>{videodetail.title}</h4>
      </div>
      <div className="video-details-buttons">
        <div className="video-details-view-date">
          <span className="video-view-text">
            {videodetail.views !== undefined && videodetail.views.length > 0
              ? `${millify(
                  videodetail.starting_view_cnt
                    ? videodetail.views.length + videodetail.starting_view_cnt
                    : videodetail.views.length
                )} ${
                  videodetail.starting_view_cnt
                    ? videodetail.views.length + videodetail.starting_view_cnt >
                      1
                      ? "Views"
                      : "View"
                    : videodetail.views.length > 1
                    ? "Views"
                    : "View"
                }`
              : "0 Views"}
          </span>
          <span className="video-uploded-date">
            {moment(videodetail.createdAt).fromNow()}
          </span>
        </div>
        <div className="video-details-btns">
          <div className="btn-item">
            <Button
              variant="ghost"
              size="small"
              hasText={false}
              handleClick={handleLike}
              hasIconLeft={true}
              elementIcon={
                videodetail.likes.filter((usr) => {
                  if (usr.id === localStorage.getItem("userid")) return usr;
                }).length > 0 ? (
                  <LikedIcon />
                ) : (
                  <LikeIcon />
                )
              }
            />
            {videodetail.likes !== undefined && videodetail.likes.length > 0 ? (
              <span className="btn-item-text">
                {millify(videodetail.likes.length)}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="btn-item">
            <Button
              variant="ghost"
              size="small"
              hasText={false}
              handleClick={handleDislike}
              hasIconLeft={true}
              elementIcon={
                videodetail.dislikes.filter(
                  (usr) => usr.id === localStorage.getItem("userid")
                ).length > 0 ? (
                  <UnLikedIcon />
                ) : (
                  <UnLikeIcon />
                )
              }
            />
            {videodetail.dislikes !== undefined &&
            videodetail.dislikes.length > 0 ? (
              <span className="btn-item-text">
                {millify(videodetail.dislikes.length)}
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="btn-item">
            <Button
              variant="ghost"
              size="small"
              hasText={false}
              handleClick={handlesavevideo}
              hasIconLeft={true}
              elementIcon={
                !isSaved ? <SaveViideoTagIcon /> : <SavedViideoTagIcon />
              }
            />
          </div>
          {/* <div className="btn-item">
            <Button
              variant="ghost"
              hasText={false}
              handleClick={hanleDownloadVideo}
              refClass={downloadref}
              hasIconLeft={true}
              elementIcon={<DownloadIcon />}
            />
            {downloadVideo && (
              <span className="btn-item-text">Downloading...(4%)</span>
            )}
            {downloadVideoDrop && (
              <div className="download-option" ref={downloaDropdref}>
                <button type="button" onClick={handlePauseDownloading}>
                  <PauseIcon />
                  Pause downloading
                </button>
                <button type="button" onClick={handleRemoveFromDownloading}>
                  <DeleteIcon />
                  Remove from Downloads
                </button>
              </div>
            )}
          </div>
          <div className="btn-item">
            <Button
              variant="ghost"
              hasText={false}
              handleClick={() => {}}
              hasIconLeft={true}
              elementIcon={<ShareIcon />}
            />
          </div> */}
        </div>
        <ModelPopup
          isOpen={removeDownloadVideoModal}
          isFooter={true}
          isHeader={false}
          btn2variant="danger"
          buttonText1="Yes, remove"
          buttonText="Cancel"
          className="delete-video-modal"
          onClose={handleCloseDeletVideoFromDownload}
          handleClick2ndButton={handleDeletVideoFromDownload}
        >
          <div className="ctsm-modal-body">
            <Button
              size="small"
              variant="ghost"
              elementIcon={<CrossIcon />}
              handleClick={handleCloseDeletVideoFromDownload}
              hasText={false}
              toolTipText="Close"
            />
            <h3 className="mb-2">Delete video?</h3>
            <p>Are you sure you want to remove this video from Downloads?"</p>
          </div>
        </ModelPopup>
        {/* 
                      {(process.env.REACT_APP_FRONTEND_ROOTURL ===
                        "https://dev.milliondollarsellers.com/" ||
                        process.env.REACT_APP_FRONTEND_ROOTURL ===
                          "https://test.milliondollarsellers.com/") && (
                        <div className="mark-btn-watched">
                          {videodetail !== undefined && (
                            <ShareFunctionality
                              url={
                                "redirectto=" +
                                "video" +
                                "&video_id=" +
                                videodetail._id +
                                "&video_title=" +
                                videodetail.title +
                                "&video_count=" +
                                videodetail.views.length +
                                "&video_url=" +
                                encodeURIComponent(videodetail.video_url)
                              }
                            />
                          )}
                        </div>
                      )} */}
      </div>
    </div>
  );
};

export default InfoTab;
