import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import arrowleft from "../../../../Images/arrow-left.png";
import EditPartnerInfo from './EditPartnerInfo';
import authServicePartner from '../../../../Services/auth.service.partner';
import HelpfulLink from '../HelpfulLinkManagement/HelpfulLink';
import Reason from '../ReasonManagement/Reason';
import PartnerPost from '../PartnerPostManagement/PartnerPost';
import { useDispatch, useSelector } from 'react-redux';
import PartnerReview from '../ReviewManagement/PartnerReview';
import RelatedVideo from '../RelatedVideo/RelatedVideo'
import Loader2 from "../../../../Images/loader2.gif";

const PartnerDetail = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const isMDSPartner = useSelector((state) => {
        return state.isMDS;
    })
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const partnerinprocess = useSelector((state) => { return state.ReduPartnerInProcess })
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    const partnerPendingReview = useSelector((state) => { return state.partnerPendingReview })
    const [tabInformation, setTabInformation] = useState("");
    const location = useLocation().search
    const id = new URLSearchParams(location).get("id")
    const [partnerDetail, setPartnerDetail] = useState({})
    const [alertCloneMsg, setAlertCloneMsg] = useState("")
    const [inProcess, setInProcess] = useState(false)

    //on first render call partner detail api
    useEffect(() => {
        authServicePartner
            .getPartnerDetail(id)
            .then((detailRes) => {
                if (detailRes.status) {
                    setPartnerDetail(detailRes.data.partnerDetail);
                }
            })
    }, [])

    const handleClonePartner = async () => {
        setInProcess(true)
        authServicePartner
            .clonePartner({ partnerId: id })
            .then((resPartner) => {
                if (resPartner.status) {
                    setInProcess(false);
                    setAlertCloneMsg(resPartner.data.message);
                    setTimeout(() => {
                        setAlertCloneMsg("");
                    }, 3000);

                }
            })
    }

    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="um-back">
                        <button
                            type="button"
                            className="back-btn"
                            onClick={() => {
                                if (modalInProcess) {
                                    dispatch({ type: "SETCUSTOMPAGECHANGE" })
                                    dispatch({ type: "NAVIGATEPAGE", payload: "/partner/list?search=" })
                                } else {
                                    navigate(NAVIGATEPAGE !== null && NAVIGATEPAGE !== "" ? NAVIGATEPAGE : "/partner/list?search=");
                                }
                            }}

                        >
                            <img src={arrowleft} />
                            Back
                        </button>

                    </div>

                    {/* {alertCloneMsg.length > 0 && (
                        <div className="alert alert-info">{alertCloneMsg}</div>
                    )} */}
                    <div className="uic-title">
                        <h1>{partnerDetail?.companyName}</h1>
                        {/* <div className="uic-fl-btn cstm-clone-sub-main">
                            {inProcess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    className="btn"
                                    onClick={handleClonePartner}
                                >
                                    Clone Partner
                                </button>
                            )}

                        </div> */}
                        {isMDSPartner && <a className='cstm-partner-preview' target="_blank" href={`/partnerpreview/${id}`} >Preview</a>}
                    </div>
                    <div className="group-listing-main">
                        <ul className="nav group-listing-menu" role="tablist">
                            <li>
                                <a className="active" href="#Info" role="tab" data-toggle="tab" onClick={() => {
                                    setTabInformation("Info");
                                }}>
                                    Partner Info
                                </a>
                            </li>
                            {isMDSPartner &&
                                <>
                                    <li>
                                        <a className="" href="#PartnerPost" role="tab" data-toggle="tab" onClick={() => {
                                            setTabInformation("PartnerPost");
                                        }}>
                                            Post
                                        </a>
                                    </li>
                                    <li>
                                        <a className="" href="#partnerReview" role="tab" data-toggle="tab" onClick={() => {
                                            setTabInformation("partnerReview");
                                        }}>
                                            Review {partnerPendingReview > 0 && <span className='review-count'>{partnerPendingReview}</span>}
                                        </a>
                                    </li>
                                    <li>
                                        <a className="" href="#HelpfulLink" role="tab" data-toggle="tab" onClick={() => {
                                            setTabInformation("HelpfulLink");
                                        }}>
                                            Helpful Link
                                        </a>
                                    </li>
                                    <li>
                                        <a className="" href="#relatedVideo" role="tab" data-toggle="tab" onClick={() => {
                                            setTabInformation("relatedVideo");
                                        }}>
                                            Related Video
                                        </a>
                                    </li>
                                </>
                            }
                        </ul>
                        <div className="tab-content group-listing-tabs">
                            <div
                                role="tabpanel"
                                className={false ? "tab-pane fade" : "tab-pane in active"}
                                id="Info"
                            >
                                <h5 className="gl-title-sub">Partner Info</h5>
                                <EditPartnerInfo />
                            </div>
                            <div
                                role="tabpanel"
                                className="tab-pane fade"
                                id="PartnerPost"
                            >
                                <PartnerPost partnername={partnerDetail?.companyName} tabInformation={tabInformation} />
                            </div>
                            <div
                                role="tabpanel"
                                className="tab-pane fade"
                                id="HelpfulLink"
                            >
                                <HelpfulLink tabInformation={tabInformation} partnername={partnerDetail?.companyName} />
                            </div>
                            <div
                                role="tabpanel"
                                className="tab-pane fade"
                                id="partnerReview"
                            >
                                <PartnerReview tabInformation={tabInformation} partnername={partnerDetail?.companyName} />
                            </div>
                            <div
                                role="tabpanel"
                                className="tab-pane fade"
                                id="relatedVideo"
                            >
                                <RelatedVideo tabInformation={tabInformation} partnername={partnerDetail?.companyName} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PartnerDetail