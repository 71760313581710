/ global google /
import React, { useState, useEffect } from "react";
import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import authService from "../../Services/auth.service";
import Loader from "../../Images/loader.gif";
function Map() {
  const [activeMarker, setActiveMarker] = useState(null);
  const [count, setCount] = React.useState(0);
  const [mapData, setMapData] = React.useState([]);
  const [showAuthToken, setAuthToken] = useState(false);
  const [showErrorHandling, setErrorHandling] = useState(false)
  const [showErrorHandleMessage, setErrorHandleMessage] = useState("")
  const [showCenterLocation, setCenterLocation] = useState({})
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [loader, setLoader] = useState(false);
  const markers = mapData;

  useEffect(() => {
    getAllUserApi()
  }, [])

  async function getAllUserApi() {
    try {
      setLoader(true)
      const getAllUserRes = await authService.getAllUsersForMap()
      if (getAllUserRes.status) {
        setMapData(getAllUserRes.data.data)
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      return false
    }
  }


  const handleMarkerClick = (index, lat, lng, id, address, FacebookProfileLink, AboutMe, name, hobbies, profileImg, goodAt) => {
    mapRef?.panTo({ lat, lng });
    console.log(goodAt, 'saddsad')
    setInfoWindowData({ id, address, FacebookProfileLink, AboutMe, name, hobbies, profileImg, goodAt });
    setIsOpen(true);
  };

  var centerMarker = {
    lat: markers.length > 0 ? markers[0].lat : 47.116386,
    lng: markers.length > 0 ? markers[0].lng : -101.299591
  }

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  return (
    <>
      {!loader ?
        <GoogleMap
          zoom={3}
          center={centerMarker}
          onLoad={handleOnLoad}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ height: "604px" }}
        >

          <MarkerClusterer>
            {clusterer =>

              markers.map(({ lat, lng, id, address, FacebookProfileLink, AboutMe, name, hobbies, profileImg, goodAt }, index) => (
                <Marker clusterer={clusterer} key={index} position={{ lat, lng }} onClick={() => {
                  handleMarkerClick(index, lat, lng, id, address, FacebookProfileLink, AboutMe, name, hobbies, profileImg, goodAt);
                }} >


                  {isOpen && infoWindowData?.id === id && (
                    <InfoWindow
                      onCloseClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <div className="user-cstm-map-dt">
                        <div className="all-content-main-edit-profile-upload">
                          <div className="cstm-profile-upload-img">
                            {(infoWindowData.profileImg !== undefined && infoWindowData.profileImg !== null &&
                              infoWindowData.profileImg.length > 0) ? (
                              <img
                                className="cstm-map-ld-user-img"
                                src={infoWindowData.profileImg}
                              />
                            ) : (
                              <div className="cstm-map-upload-avar">
                                {infoWindowData.name
                                  ? infoWindowData.name.charAt(0)
                                  : ""}
                              </div>
                            )}
                          </div>

                        </div>

                        <div className="map_tbl_ttl">
                          {infoWindowData.name}

                        </div>
                        {
                          infoWindowData.FacebookProfileLink && infoWindowData.FacebookProfileLink !== "" &&
                          <div className="user-map-lable">
                            <span className="user_map_left">
                              Facebook Profile Link:
                            </span>
                            <span className="off-loc-name">{infoWindowData.FacebookProfileLink} </span>
                          </div>
                        }
                        {
                          infoWindowData.AboutMe && infoWindowData.AboutMe !== "" &&
                          <div className="user-map-lable">
                            <span className="user_map_left">
                              About Me:
                            </span>
                            <span className="off-loc-name">{infoWindowData.AboutMe}</span>
                          </div>
                        }
                        {
                          infoWindowData.hobbies && infoWindowData.hobbies !== "" &&
                          <div className="user-map-lable">
                            <span className="user_map_left">
                              Hobbies:
                            </span>
                            <span className="off-loc-name">{infoWindowData.hobbies}</span>
                          </div>
                        }
                        {
                          infoWindowData.goodAt && infoWindowData.goodAt !== "" && infoWindowData.goodAt.length !== 0 &&
                          <div className="user-map-lable">
                            <span className="user_map_left">
                              I am good at:
                            </span>
                            <span className="off-loc-name">{infoWindowData.goodAt.join(", ")}</span>
                          </div>
                        }

                      </div>


                    </InfoWindow>
                  )}

                </Marker>
              ))}
          </MarkerClusterer>
        </GoogleMap>
        : <>
          <div className="map-data-loading">
            <img src={Loader} alt="Loading..." />
          </div>
        </>}
    </>
  );
}


export default Map;
