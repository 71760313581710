import React from 'react'
import MdsIcon from '../../../../Images/MdsIcon.svg'
const PartnerMainModal = ({ isReview, setReview, isList, setList, reviewCount }) => {
    return (
        <>
            <div className={`${isReview ? "modal-fadein" : "modal-fadeout"}`}>
                <div className="desk-globle-model-box globle-model-box ">
                    <div className="desk-globle-model-box-inner partners-details-popup-main">
                        <div className="partners-details-popup-your-review">
                            <div className='unlock-partners-icon'> <img src={MdsIcon} /></div>
                            <div className='unlock-partners'>
                                <h3 className="your-review-title">Unlock partners with {reviewCount !== 0 ? 3 - reviewCount : 3} reviews</h3>
                                <p className="sometihng-p">Unlock exclusive partner offers. Leave {reviewCount !== 0 ? 3 - reviewCount : 3} reviews to access the partner's section or <a
                                    onClick={() => {
                                        if (window.Intercom) {
                                            window.Intercom('show');
                                        }
                                    }}
                                    className='message-us-link'>message us</a> if you haven't used our partners' services.</p>
                            </div>
                            <div className='btn-unlock-section'>
                                <span
                                    onClick={() => {
                                        setReview(false)
                                        document.body.classList.remove("modal-open");
                                    }}
                                    className="partners-details-btn-main"
                                >
                                    Maybe later
                                </span>
                                <span
                                    onClick={() => {
                                        setList(true)
                                        setReview(false)
                                    }
                                    }
                                    className="btn-review"
                                >
                                    Select partners
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default PartnerMainModal