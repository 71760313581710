import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./DropDownItem.css";
import "../../Styles/Theme/Theme.css";
import index from "../Table";
import Avtar from "../Avtar/Avtar";
import logo from "../../Images/logo-32-32.svg";

const SuggestionDropDown = React.forwardRef(
  (
    {
      options = [],
      historyOptions = [],
      onMouseDown,
      size = "small",
      id,
      text = "",
      handleRemove,
      handlePreview,
      listIndex,
    },
    ref
  ) => {
    return (
      <>
        <ul
          className={`dropdown-list scrollbar no-border-scrollbar ${size}`}
          ref={ref}
          id={id}
        >
          {options.map((suggestion, index) => {
            return (
              <li className={listIndex === index ? " active-list" : ""}>
                {"profileImg" in suggestion ? (
                  suggestion.name === "MDS" ? (
                    <img src={logo} />
                  ) : suggestion.profileImg !== "" ? (
                    <Avtar src={suggestion.profileImg} />
                  ) : (
                    <div className="type-avatar-text-32">
                      {suggestion.name.charAt(0).toUpperCase()}
                    </div>
                  )
                ) : (
                  suggestion.icon
                )}
                <div
                  className="doc-suggestion-dropdown-list"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    onMouseDown(suggestion);
                    suggestion.isHistory === undefined &&
                      handlePreview &&
                      !("profileImg" in suggestion) &&
                      handlePreview(suggestion);
                  }}
                >
                  <h5>
                    {text !== undefined &&
                    text !== null &&
                    text.length > 0 &&
                    suggestion.name
                      .toLowerCase()
                      .startsWith(text.toLowerCase()) ? (
                      <>
                        {suggestion.name.slice(0, text.length)}
                        <b>
                          {suggestion.name.slice(
                            text.length,
                            suggestion.name.length
                          )}
                        </b>
                      </>
                    ) : (
                      suggestion.name
                    )}
                  </h5>
                  <h6>{suggestion.owner}</h6>
                </div>
                {suggestion.isHistory !== undefined &&
                  suggestion.isHistory &&
                  handleRemove !== undefined && (
                    <button
                      className="cstm-cl-search-sug-history-remove"
                      onMouseDown={(e) => {
                        e.preventDefault();
                        handleRemove(suggestion._id);
                      }}
                    >
                      Delete
                    </button>
                  )}
              </li>
            );
          })}
          {options.length === 0 && <li>Nothing was found for your request</li>}
        </ul>
      </>
    );
  }
);

SuggestionDropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onMouseDown: PropTypes.func,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  id: PropTypes.string,
  handleRemove: PropTypes.func,
  text: PropTypes.string,
};

export default SuggestionDropDown;
