import React from "react";
import Logo from "../../../Images/logo_mds_small.svg";
import Email from "../../Illustrations/Email";
import IcnMEmail from "../../../Images/icn_m_email.svg";
import ContactSupport from "../../Illustrations/ContactSupport";
import Button from "../../Button/Button";
import { Link } from "react-router-dom";

const EmailNotVerified = () => {
  return (
    <>
      <div className="container-fluid new-contact-support">
        <div className="new-login-box-wrapper">
          <div className="header-collaborator header-spacing">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
          <div className="collaborator-info">
            <ContactSupport />
            <div>
              <h2 className="collaborator-h2 mb-2">Contact support</h2>
              <h6 className="collaborator-h6">
                Please reach out to our support team and they'll assist you in gaining
                access.
              </h6>
            </div>
            <Button
              label="Open chat"
              handleClick={() =>
                (window.location = "mailto:appsupport@milliondollarsellers.com")
              }
            />
          </div>
        </div>
      </div >
    </>
  );
};

export default EmailNotVerified;
