import React from "react";
import PropTypes from "prop-types";
import IncomingMessageTime from "./IncomingMessageTime";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageBubbleInner.css"; //This css used for the all the message bubble
import "../Styles/IncomingMessageImage.css";

const IncomingMessageImage = ({
  // type = "MaxHeight",
  hasTime = false,
  src = [
    "https://images.unsplash.com/photo-1705392852198-2a2334db0314?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

    // "https://images.unsplash.com/photo-1504415724575-56cef5e3da72?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    // "https://images.unsplash.com/photo-1704525083773-a066e2b40c86?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    // "https://images.unsplash.com/photo-1668086620216-851f13fbce3b?q=80&w=1935&auto=format&fit=crop&ixl/ib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ],
  count = 2,
  isEdited = false,
  time = "12:00 AM",
  handlePreviewClick,
  uniqueId,
}) => {
  return (
    <>
      <div className="messagebubble-sec incomingmessagimage">
        <div className="messagebubble-container">
          {src && src.length ? (
            <div
              className={`messagebubble-wrapper flex items-center ${
                src.length === 1
                  ? "imgone"
                  : src.length === 2
                  ? "imgtwo"
                  : src.length === 3
                  ? "imgthree"
                  : src.length > 3
                  ? "imgmore"
                  : ""
              }`}
            >
              {src.map((img, index) => {
                if (index < 3) {
                  return (
                    <div
                      className={`massage-insider ${
                        src.length > 3 && index === 2
                          ? "counterImg"
                          : src.length === 1
                          ? img.aspectRatio > 1
                            ? "Horizontal"
                            : img.aspectRatio === 1
                            ? "Squre"
                            : "Vertical"
                          : ""
                      }`}
                      onClick={() =>
                        handlePreviewClick(
                          src.map((image) => image.img),
                          index, // pass Index of Images
                          uniqueId.slice(0, -1),
                          "images"
                        )
                      }
                      key={index} // Add a key to each rendered element
                    >
                      <img src={img?.img} alt="img" />
                      <div className="img-counter-sec">
                        <p className="img-coun">+{count + 1}</p>
                      </div>
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}

              {hasTime && (
                <div className="msg-time-sec">
                  <IncomingMessageTime
                    hasBackdrop
                    isEdited={isEdited}
                    time={time}
                  />
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

IncomingMessageImage.propTypes = {
  src: PropTypes.array,
  hasTime: PropTypes.bool,
  isEdited: PropTypes.bool,
  count: PropTypes.number,
  time: PropTypes.string,
};

export default IncomingMessageImage;
