import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

// Import image
import logo from "../../../Images/logo.png";

// Import Api integration file
import Services from "../../../Services/auth.service";
import auth from "../../../Services/auth0services";
import arrowleft from "../../../Images/arrow-left.png";
const ForgotPassword = () => {
  const [showForgotPwd, setshowForgotPwd] = useState(true);
  const [showCheckEmailDiv, setshowCheckEmailDiv] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [link, setLink] = useState("");
  const [emailErr, setemailErr] = useState("");
  let history = useNavigate();
  // useeffect called on states change
  useEffect(() => {}, [
    showForgotPwd,
    setshowForgotPwd,
    showCheckEmailDiv,
    setshowCheckEmailDiv,
    userEmail,
    setUserEmail,
    emailErr,
    setemailErr,
    link,
    setLink,
  ]);
  // Called on input field changed
  const handleChange = (e) => {
    e.target.value.length > 1
      ? e.target.value.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/) === null
        ? setemailErr("Enter valid email address!")
        : setemailErr("")
      : setemailErr("Enter Email!");
    setUserEmail(e.target.value);
  };
  // Called on click on submit button
  const handleSubmit = (e) => {
    e.preventDefault();
    userEmail.length > 0
      ? emailErr.length > 0
        ? setemailErr("Enter valid email address!")
        : setemailErr("")
      : setemailErr("Enter Email!");
    if (emailErr.length === 0 && userEmail.length > 0) {
      
     
      Services.forgotpassword({ email: userEmail })
        .then((response) => {
          if(response.data.isAuth)
          {
            setshowForgotPwd(false);
            setshowCheckEmailDiv(true);
            auth.changePassword({
              connection:"Username-Password-Authentication",
              email:userEmail,
            },function(err,res){
              if(err)
                console.log(err)
              else  
                history("/")
            })  
          }
          else{
            setemailErr(response.data.message)
          }
        })
        .catch((err) => {
          setshowForgotPwd(true);
          setshowCheckEmailDiv(false);
          setemailErr("Email not found!");
        });
    }
  };
  return (
    <>
      <div className="um-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 um-left">
              <div className="um-left-in">
                <div className="um-logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>

                <div className="um-left-in-mn">
                  <div className="um-title">
                    Forgot Your
                    <br />
                    Password?
                  </div>
                  <div className="um-text">Follow these few steps</div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 um-right">
              <div className="um-right-in">
              <div className="um-back">
                  <button
                    className="back-btn"
                    onClick={() => {
                     history("/")
                    }}
                  >
                    <img src={arrowleft} /> Back
                  </button>
                </div>
                <div
                  style={
                    showForgotPwd ? { display: "block" } : { display: "none" }
                  }
                >
                  <form onSubmit={handleSubmit}>
                    <div className="um-right-in-title">Forgot Password</div>
                    <div className="um-right-in-sub">
                      Enter the email associated with your account, and we will
                      send an email with instructions to reset your password.
                    </div>
                    <hr className="um-line" />

                    <div className="form-group">
                      <div className="um-right-in-label">Email*</div>
                      <div className="um-right-in-box">
                        <input
                          type="email"
                          name="email"
                          placeholder="enter your registered email"
                          onChange={handleChange}
                          value={userEmail}
                        />
                      </div>
                      <span className="error">{emailErr}</span>
                    </div>

                    <div className="form-group">
                      <button type="submit" className="btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  style={
                    showCheckEmailDiv
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div className="um-right-in-title">Check Your Email</div>
                  <div className="um-right-in-sub">
                    We have sent a new password with recovery instructions to
                    your email.
                  </div>
                  {link.length > 0 ? <a href={link}>resetpassword</a> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
