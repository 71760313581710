// Icons
import IconNavNews from "./Icons/IconsNavigation/IconNavNews";
import IconNavNewsFilled from "./Icons/IconsNavigation/IconNavNewsFilled";
import IconNavDoc from "./Icons/IconsNavigation/IconNavDoc";
import IconNavDocFilled from "./Icons/IconsNavigation/IconNavDocFilled";
import IconNavVideo from "./Icons/IconsNavigation/IconNavVideo";
import IconNavVideoFilled from "./Icons/IconsNavigation/IconNavVideoFilled";
import IconFacebookImg from "./Icons/IconsNavigation/IconFacebookImg";
import IconShopifyImg21 from "./Icons/IconsNavigation/IconShopifyImg";
import IconNavGroup from "./Icons/IconsNavigation/IconNavGroup";
import IconNavGroupFilled from "./Icons/IconsNavigation/IconNavGroupFilled";
import IconNavScorecard from "./Icons/IconsNavigation/IconNavScorecard";
import IconNavScorecardFilled from "./Icons/IconsNavigation/IconNavScorecardFilled";
import IconNavCensus from "./Icons/IconsNavigation/IconNavCensus";
import IconNavCensusFilled from "./Icons/IconsNavigation/IconNavCensusFilled";
import IconNavMap from "./Icons/IconsNavigation/IconNavMap";
import IconNavMapFilled from "./Icons/IconsNavigation/IconNavMapFilled";
import IconNavMemberDirectory from "./Icons/IconsNavigation/IconNavMemberDirectory";
import IconNavMemberDirectoryFilled from "./Icons/IconsNavigation/IconNavMemberDirectoryFilled";
import IconNavChapters from "./Icons/IconsNavigation/IconNavChapters";
import IconNavChaptersFilled from "./Icons/IconsNavigation/IconNavChaptersFilled";
import IconNavSquadSignup from "./Icons/IconsNavigation/IconNavSquadSignup";
import IconNavSquadSignupFilled from "./Icons/IconsNavigation/IconNavSquadSignupFilled";
import IconNavDobation from "./Icons/IconsNavigation/IconNavDobation";
import IconNavDobationFilled from "./Icons/IconsNavigation/IconNavDobationFilled";
import IconNavEvent from "./Icons/IconsNavigation/IconNavEvent";
import IconNavEventFilled from "./Icons/IconsNavigation/IconNavEventFilled";
import IconNavPartner from "./Icons/IconsNavigation/IconNavPartner";
import IconNavPartnerFilled from "./Icons/IconsNavigation/IconNavPartnerFilled";
import IconNavGallery from "./Icons/IconsNavigation/IconNavGallery";
import IconNavGalleryFilled from "./Icons/IconsNavigation/IconNavGalleryFilled";
import IconNavMdsExtrasFilled from "./Icons/IconsNavigation/IconNavMdsExtrasFilled";
import IconNavMdsExtras from "./Icons/IconsNavigation/IconNavMdsExtras";
import NavGroupIcon from "../Icons/NavIcons/NavGroupIcon";
import InfoIcon from "../Icons/GeneralIcons/InfoIcon";
import NavDirectoryIcon from "../Icons/NavIcons/NavDirectoryIcon";
import ChannelIcon from "../Icons/GeneralIcons/ChannelIcon";
// menu list array of modules
export const menuArrays = [
  {
    name: "Newsroom",
    to: "/homepage",
    icon: <IconNavNews />,
    activeIcon: <IconNavNewsFilled />,
    isTarget: false,
    tab: "frontnews",
    include: "frontnews",
    accessResource: "News",
    isDisabled: false,
    subMenus: false,
    id: "frontnews",
  },
  {
    name: "Events",
    icon: (
      <div className="mds-icons">
        <IconNavEvent />
      </div>
    ),
    activeIcon: (
      <div className="mds-icons filled">
        <IconNavEventFilled isFilled />
      </div>
    ),
    isTarget: false,
    subMenus: false,
    accessResource: "Events",
    isDisabled: false,
    subMenus: false,
    to: "/home-event",
    tab: "events",
    id: "events",
  },
  {
    name: "Partners",
    to: "/partners",
    icon: <IconNavPartner />,
    activeIcon: <IconNavPartnerFilled />,
    isTarget: false,
    include: "partners",
    accessResource: "Partners",
  },
  {
    name: "Videos",
    to: "null",
    icon: <IconNavVideo />,
    activeIcon: <IconNavVideoFilled />,
    isTarget: false,
    include: ["/videos"],
    accessResource: "Videos",
    isDisabled: false,
    subMenus: false,
    to: "/videos?type=all&filter=recent",
    tab: "videos",
    id: "videos",
  },
  {
    name: "Documents",
    to: "/documents",
    icon: <IconNavDoc />,
    activeIcon: <IconNavDocFilled />,
    isTarget: false,
    include: ["documents"],
    accessResource: "Documents",
  },
  {
    name: "Photos",
    to: "/photos",
    icon: <IconNavGallery />,
    activeIcon: <IconNavGalleryFilled />,
    isTarget: false,
    include: ["/photos"],
    accessResource: "Photos",
  },
  {
    name: "MDS Extras",
    to: "https://extras.milliondollarsellers.com/verification",
    icon: <IconNavMdsExtras />,
    activeIcon: <IconNavMdsExtrasFilled />,
    isTarget: true,
    isDisabled: false,
    subMenus: false,
    id: "extras",
  },
  {
    name: "Network",
    to: "/?123",
    icon: (
      <div className="mds-icons">
        <NavGroupIcon />
      </div>
    ),
    activeIcon: (
      <div className="mds-icons filled">
        <NavGroupIcon isFilled />
      </div>
    ),
    isTarget: true,
    include: ["/facebook-groups"],
    accessResource: "",
    isDisabled: true,
    subMenus: true,
    tab: "network",
    id: "network",
  },
  {
    name: "Programs",
    to: "/?123",
    icon: <IconNavDoc />,
    activeIcon: <IconNavDocFilled />,
    isTarget: true,
    include: ["/memberchapterpage", "/member-link", "/squads"],
    accessResource: "",
    isDisabled: true,
    subMenus: true,
    tab: "program",
    id: "program",
  },
  {
    name: "Directories",
    to: "/?123",
    icon: (
      <div className="mds-icons">
        <NavDirectoryIcon />
      </div>
    ),
    activeIcon: (
      <div className="mds-icons filled">
        <NavDirectoryIcon isFilled />
      </div>
    ),
    isTarget: true,
    include: [
      "/airbnb-directory",
      "/book-club",
      "/employee-blocklist",
      "/employee-hire",
      "/employee-match",
      "/freelancers",
      "/gift-guide",
    ],
    accessResource: "",
    isDisabled: false,
    subMenus: true,
    tab: "directory",
    id: "directory",
  },
  {
    name: "Info",
    to: "/?123",
    icon: (
      <div className="mds-icons">
        <InfoIcon />
      </div>
    ),
    activeIcon: (
      <div className="mds-icons filled">
        <InfoIcon isFilled />
      </div>
    ),
    isTarget: true,
    include: ["/team", "/values", "/census-data-google-studio"],
    accessResource: "",
    isDisabled: true,
    subMenus: true,
    tab: "info",
    id: "info",
  },
  {
    name: "MDS channels",
    to: "/mds-channels",
    icon: <div className="mds-icons"> <ChannelIcon /> </div>,
    activeIcon: (
      <div className="mds-icons filled">
        <ChannelIcon isFilled />
      </div>
    ),
    isTarget: false,
    include: ["mds-channels"],
    accessResource: "mds-channels",
  }
];

export const videosMenuArray = [];

// group menu list array
export const groupsMenuArray = [
  {
    name: "Amazon",
    to: "https://www.facebook.com/groups/milliondollarsellers/",
    icon: <IconFacebookImg />,
    isTarget: true,
  },

  {
    name: "DTC/Shopify",
    to: "https://www.facebook.com/groups/330569427467806/",
    icon: <IconShopifyImg21 />,
    isTarget: true,
  },
  {
    name: "Wholesale",
    to: "https://www.facebook.com/groups/564417397386639/",
    icon: <IconNavGroup />,
    activeIcon: <IconNavGroupFilled />,
    isTarget: true,
  },
  {
    name: "Retail",
    to: "https://www.facebook.com/groups/MDSRetail",
    icon: <IconNavGroup />,
    activeIcon: <IconNavGroupFilled />,
    isTarget: true,
  },
  {
    name: "Marketplace",
    to: "https://www.facebook.com/groups/886780908459417",
    icon: <IconNavGroup />,
    activeIcon: <IconNavGroupFilled />,
    isTarget: true,
  },
  {
    name: "Brand management",
    to: "https://www.facebook.com/groups/2154276704666301",
    icon: <IconNavGroup />,
    activeIcon: <IconNavGroupFilled />,
    isTarget: true,
  },
  {
    name: "Business Ops",
    to: "https://www.facebook.com/groups/573746113253999",
    icon: <IconNavGroup />,
    activeIcon: <IconNavGroupFilled />,
    isTarget: true,
  },
  {
    name: "Investments",
    to: "https://www.facebook.com/groups/MDSInvestmentsNewsMarkets",
    icon: <IconNavGroup />,
    activeIcon: <IconNavGroupFilled />,
    isTarget: true,
  },
  {
    name: "Lifestyle",
    to: "https://www.facebook.com/groups/mdslifestyle",
    icon: <IconNavGroup />,
    activeIcon: <IconNavGroupFilled />,
    isTarget: true,
  },
  {
    name: "Chat bots",
    to: "https://www.facebook.com/groups/1843267605780005 ",
    icon: <IconNavGroup />,
    activeIcon: <IconNavGroupFilled />,
    isTarget: true,
  },
];

// community menu list array
export const communityMenuArray = [
  {
    name: "Scorecard",
    to: "https://member.milliondollarsellers.com/members-score",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: true,
  },
  {
    name: "Census data",
    to: "https://member.milliondollarsellers.com/page-18381",
    icon: <IconNavCensus />,
    activeIcon: <IconNavCensusFilled />,
    isTarget: true,
  },
  {
    name: "Member map",
    to: "https://member.milliondollarsellers.com/Member-Map",
    icon: <IconNavMap />,
    activeIcon: <IconNavMapFilled />,
    isTarget: true,
  },
  {
    name: "Member directory",
    to: "https://member.milliondollarsellers.com/Member-Directory",
    icon: <IconNavMemberDirectory />,
    activeIcon: <IconNavMemberDirectoryFilled />,
    isTarget: true,
  },
  {
    name: "MDS chapters",
    to: "https://member.milliondollarsellers.com/chapters",
    icon: <IconNavChapters />,
    activeIcon: <IconNavChaptersFilled />,
    isTarget: true,
  },
  {
    name: "Squad signup page",
    to: "https://milliondollarsellers.wildapricot.org/Sys/Login?ReturnUrl=%2fevent-4965181",
    icon: <IconNavSquadSignup />,
    activeIcon: <IconNavSquadSignupFilled />,
    isTarget: true,
  },
  {
    name: "Donations",
    to: "https://member.milliondollarsellers.com/Donations",
    icon: <IconNavDobation />,
    activeIcon: <IconNavDobationFilled />,
    isTarget: true,
  },
];

// event menu list array
export const eventMenuArray = [
  {
    name: "In persons",
    to: "https://member.milliondollarsellers.com/in-person",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: true,
    slug: "inperson",
  },
  {
    name: "Experiences",
    to: "https://member.milliondollarsellers.com/Experiences",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: true,
    slug: "experience",
  },
  {
    name: "Virtual events",
    to: "https://member.milliondollarsellers.com/page-18313",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: true,
    slug: "virtualevent",
  },
  {
    name: "Summit",
    to: "https://member.milliondollarsellers.com/page-18487",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: true,
    slug: "summit",
  },
];

// resource menu list array
export const resourceMenuArray = [
  {
    name: "Partner offers",
    to: `${window.location.origin}/partners/all-partners?type=offer&&cat=all&&search=&&sortby=newest&&badge=all `,
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "parneroffer",
    include: "partners",
  },
  {
    name: "Documents",
    to: `${window.location.origin}/documents?type=documents`,
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "document",
    include: "documents",
  },
];

// directory menu list array
export const directoryMenuArray = [
  {
    name: "Airbnb",
    to: "/airbnb-directory",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "airbnb",
    include: "airbnb-directory",
  },
  {
    name: "Book club",
    to: "/book-club",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "bookclub",
    include: "book-club",
  },
  {
    name: "Freelancers",
    to: "/freelancers",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "freelancer",
    include: "freelancers",
  },
  {
    name: "Gift guide",
    to: "/gift-guide",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "giftguide",
    include: "gift-guide",
  },
];

// network menu list array
export const networkMenuArray = [
  {
    name: "Member map",
    to: "/members",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "members",
    include: "members",
  },
  {
    name: "Member directory ",
    to: "https://member.milliondollarsellers.com/Member-Directory",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: true,
  },
];

// programs menu list array
export const programMenuArray = [
  {
    name: "Chapters",
    to: `/chapters`,
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "fbgroups",
    include: "chapters",
  },
  {
    name: "Squads",
    to: "/squads",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "squads",
    include: "squads",
  }
];

// info menu list array
export const infoMenuArray = [
  {
    name: "Team",
    to: "/team",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "fbgroups",
    include: "team",
  },
  {
    name: "Values",
    to: "/values",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "membermap",
    include: "values",
  },
  {
    name: "Member's Score",
    to: "/member-score",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
  },
  {
    name: "Donations",
    to: "https://member.milliondollarsellers.com/Donations",
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: true,
  },
  {
    name: "Census data",
    to: `${window.location.origin}/census-data-google-studio`,
    icon: <IconNavScorecard />,
    activeIcon: <IconNavScorecardFilled />,
    isTarget: false,
    slug: "censusdata",
    include: "census-data-google-studio",
  },
];
