import React from 'react'
import PropTypes from "prop-types"
const InfoIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M12 20.5c-4.687 0-8.5-3.813-8.5-8.5 0-4.687 3.813-8.5 8.5-8.5 4.687 0 8.5 3.813 8.5 8.5 0 4.687-3.813 8.5-8.5 8.5ZM12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2Z"
                clipRule="evenodd"
            />
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M11.994 10.627a.75.75 0 0 0-.75.75v4.419a.75.75 0 0 0 1.5 0v-4.419a.75.75 0 0 0-.75-.75ZM12.004 7.204c-.553 0-1.005.447-1.005 1 0 .553.443 1 .995 1h.01a1 1 0 1 0 0-2Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
InfoIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default InfoIcon