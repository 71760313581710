import React from 'react'
import ImgEvent from "./../../Images/imgvideo.png"
import imgIcon from "./../../Images/icn_m_pdf.svg"
import sponsorIcon from "./../../Images/heyday-img.png"

const SponsorThumbnailSmallImage = ({ thumbUrl = ImgEvent, thumbType = "img", }) => {
    return (
        <div className={`sponsor-thumb-image imgtype-${thumbType}`}>
            {thumbType === 'img' && <img src={thumbUrl} width={128} height={72} className='img-fluid thumb-image' alt='doc-thumb' />}
            {/* {thumbType === 'file' && thumbUrl} */}
            {thumbType === 'sponsor' && <img src={thumbUrl} width={128} height={72} className='img-fluid sponsor-icon' alt='doc-thumb' />}
        </div>
    )
}

export default SponsorThumbnailSmallImage