import React from "react";
import PropTypes from "prop-types";
import ArrowChevronDown from "../../../../components/Icons/ArrowsIcon/ArrowChevronDown";
import Badges from "../../../../components/Badge/Badges";
// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/ChatFloatingButton.css";

const ChatFloatingButton = ({ count , handleClick}) => {
  

  return (
    <>
      <button
        className="chat-floating-button flex items-center justify-center"
        onClick={handleClick}
      >
        {count > 0 && (
          <div className="chat-badge">
            <Badges className="badges-danger large" label={count} />{" "}
          </div>
        )}
        <ArrowChevronDown />
      </button>
    </>
  );
};

ChatFloatingButton.propTypes = {
  count: PropTypes.number,
  //   Click Events
  handleClick: PropTypes.func,
};

export default ChatFloatingButton;
