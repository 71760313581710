const totalmessages = {};

const ReduTotalMessages = (state = totalmessages, action) => {
  switch (action.type) {
    case "SETTOTALMESSAGES":
      return { ...state, ...action.payload };
    case 'LOGOUT':
      return totalmessages;
    default:
      return state;
  }
};

export default ReduTotalMessages;
