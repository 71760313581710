import SavePostList from "./SavePostList";


// icon
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";

const SavedPostPopup = ({ setclose, openingpop }) => {
  return (
    <div className="globle-model-box for-globle-model-box">
      <div className="an-globle-model-box-inner">
        <div className="an-globle-model-heading">
          <button
            className="an-globle-back"
            onClick={() => {
          setclose({ ...openingpop, save: false });
        }}
          >
            <ArrowLeftLineIcon size="20px" />
          </button>
          <div className="an-globle-model-heading-sec">
            <h3>Saved Post</h3>
          </div>
        </div>
        <SavePostList />
      </div>
    </div>

    // <div className="globle-model-box">
    //   <button
    //     onClick={() => {
    //       setclose({ ...openingpop, save: false });
    //     }}
    //   >
    //     ✕
    //   </button>
    //   <SavePostList />
    // </div>
  );
};
export default SavedPostPopup;