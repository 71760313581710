import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Yes2 from "../../../Images/yes2.svg";
import Image1 from "../../../Images/image1.png"; 
import Groupposts from "../../../Images/group-posts.svg";

//import components
import Header from "../Dashboard/Header"
import LeftSidebar from "../Dashboard/LeftSidebar"

const Notification = () => {
    const dispatch = useDispatch()
  let history = useNavigate();
  const [leftsidebar, setleftsidebar] = useState(true)
  const [rightsidebar, setrightsidebar] = useState(true)
  const [SelectedGroup, setSelectedGroup] = useState('')
  const [selectedDiv, setselectedDiv] = useState('dashboard')
  const [topicSearch, settopicSearch] = useState('')
  useEffect(() => {
    if(localStorage.getItem("idtoken") === null || localStorage.getItem("idtoken").length === 0 || localStorage.getItem("userid") === null ||
    localStorage.getItem("userid").length === 0 ||
    localStorage.getItem("username") === null ||
    localStorage.getItem("username").length === 0 || localStorage.getItem("role") === null || localStorage.getItem("role") !== 'user' || localStorage.getItem("logintime") === undefined || localStorage.getItem("logintime") === null ){
      window.location.href = "/"
      dispatch(fetchsavedpost())
    }  
    setSelectedGroup('')
    setselectedDiv('dashboard')
    settopicSearch('')
  }, []);
    return (
        <>
        <Header setSelectedGroup={setSelectedGroup} setselectedDiv={setselectedDiv} settopicSearch={settopicSearch}/> 
      <div className={leftsidebar && rightsidebar ? "all-content-main" : leftsidebar === false && rightsidebar ? "all-content-main mn-cnt-wd" : rightsidebar === false && leftsidebar ? "all-content-main mn-cnt3-wd" : "all-content-main mn-cnt-both"}> 
      <div className={leftsidebar ? "sidebar-icon " : "sidebar-icon sidebar-icon2"} onClick={()=>{leftsidebar ? setleftsidebar(false) : setleftsidebar(true)}}><i className="fa fa-angle-left"></i></div> 
        <div className={leftsidebar ? "all-content-main-left" : "all-content-main-left sidebar-hd"}>
          <LeftSidebar setSelectedGroup={setSelectedGroup} setselectedDiv={setselectedDiv} selectedDiv={selectedDiv} SelectedGroup={SelectedGroup}/>
        </div>
        <div className="all-content-main-center">
        
           <div className="notifications-ps-main">
           <div className="header-noti-box-cont-title">Notifications</div>
           <div className="notifications-ps-main-cont">
              <span className="mark-as-all-read"><img src={Yes2} />Mark as all read </span>
              <ul className="nav noti-nav-tabs"> 
                  <li><a className=' active' data-toggle='tab' href='#s11'>All</a></li>
                  <li><a className='' data-toggle='tab' href='#s22'>Unread</a></li> 
              </ul>
              <div className="header-noti-box-cont-tabs-head">
                   <div className="header-noti-box-cont-tabs-head-title">New</div>
                 </div>
                 <div className="tab-content header-noti-cont-tabs-main">
                        <div className='tab-pane container fade in show active' id='s11'>
                          
                        <div className="header-noti-box-cont-tabs-cont">
                               <ul>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                    <div className="head-noti-new">New</div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                    <div className="head-noti-new">New</div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                    <div className="head-noti-new">New</div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                    <div className="head-noti-new">New</div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                    <div className="head-noti-new">New</div>
                                  </li>
                               </ul>
                        </div>
                           <hr className="noti-line" />
                        <div className="header-noti-box-cont-tabs-head">
                            <div className="header-noti-box-cont-tabs-head-title">Old</div>
                        </div>

                        <div className="header-noti-box-cont-tabs-cont">
                               <ul>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                  </li>
                               </ul>
                        </div>

                        </div>
                        <div class='tab-pane container fade' id='s22'>
                          
                        <div className="header-noti-box-cont-tabs-cont">
                               <ul>
                               <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                    <div className="head-noti-new">New</div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                    <div className="head-noti-new">New</div>
                                  </li>
                                  <li>
                                    <div className="head-noti-user"><img className="noti-usr-photo" src={Image1} /><img className="noti-usr-icon" src={Groupposts} /></div>
                                    <div className="head-noti-nametime">
                                      <span className="head-noti-name"><a href="#">Ronald Richards</a> posted two photos in Amazon.</span>
                                      <span className="head-noti-time">2h ago</span>
                                    </div>
                                  </li>
                                  </ul>
                        </div>

                        </div>
                    </div>

           </div>

           </div>
        
        </div>
        
        <div className={rightsidebar ? "all-content-main-right" : "all-content-main-right  sidebar-hd"}>
        
        </div>
        {/* <div className={rightsidebar ? "sidebar2-icon " : "sidebar2-icon sidebar2-icon2"} onClick={()=>{rightsidebar ? setrightsidebar(false) : setrightsidebar(true)}}><i className="fa fa-angle-right"></i></div>  */}
      </div>

        </>
    )
}
export default Notification;