import React from "react";
import PropTypes from "prop-types"
function ExcelFileIcon({ height = 24, width = 24 }) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#00733B"
                    fillRule="evenodd"
                    d="M5.865 1h8.902L21 7.502v12.633A2.866 2.866 0 0 1 18.135 23H5.865A2.866 2.866 0 0 1 3 20.135V3.865A2.866 2.866 0 0 1 5.865 1Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M8 16.854h3.023V18H8v-1.146ZM12.967 11H16v1.136h-3.033V11ZM8 11h3.023v1.136H8V11Zm4.967 1.926H16v1.146h-3.033v-1.146Zm-4.967 0h3.023v1.146H8v-1.146Zm4.967 2.002H16v1.146h-3.033v-1.146Zm-4.967 0h3.023v1.146H8v-1.146Zm4.967 1.926H16V18h-3.033v-1.146Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
            </svg>
        </>
    );
}
ExcelFileIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
export default ExcelFileIcon;
