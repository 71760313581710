import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import Multiselect from "multiselect-react-dropdown";
import Frame from "../../../Images/Frame.svg";
import Loader2 from "../../../Images/loader2.gif";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import AddRules from "../../CommonRules/AddRules";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";
import { emptyObject } from "./CommonFunctions";
import CroppingImageEventModule from "../MainEventManagement/CroppingImageEventModule";
import { useCreateTicketMutation } from "../../../Services/mainTicket";
import { useLocation } from "react-router-dom";
import { useGuestTicketByEventIdQuery } from "../../../Services/guestTicket";
import { useAddonsByEventIdQuery } from "../../../Services/addons";
import AlertComponent from "../../../Hooks/AlertComponent";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";

const CreateMainTicket = ({ isCloseAddModal, forceRender }) => {
  //hooks
  const location = useLocation();
  const descriptionRef = useRef(null);
  const dispatch = useDispatch();

  //redux states

  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });

  const [addEvent, setAddEvent] = useState(emptyObject);
  const [addErr, setAddErr] = useState(emptyObject);
  const [allowGuestCheck, setAllowGuestCheck] = useState(false);
  const [checkoutCheck, setcheckoutCheck] = useState(false);
  const [addonAvailableCheck, setAddonAvailableCheck] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedGuestTickets, setSelectedGuestTickets] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [createInprocess, setCreateInprocess] = useState(false);
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [isErrorMessage, setErrorMessage] = useState(false);
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [validate, setValidate] = useState(false);
  const [ruleError, setRuleError] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [resetRulesFields, setResetRulesFields] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [cropedFileData, setcropedFileData] = useState();
  const [priceType, setPriceType] = useState("");

  //  image cropping state
  const [cropedFile, setcropedFile] = useState();
  const [fileDimension, setFileDimension] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState("");

  //event id
  const eventId = new URLSearchParams(location.search).get("id");

  //add main ticket api hook
  const [createTicket, { isError, isSuccess }] = useCreateTicketMutation();

  //get guest tickets list
  const { data: guestTicketList } = useGuestTicketByEventIdQuery({
    event_id: eventId,
    params: {
      page: 1,
      limit: 100,
    },
  });
  //get addons list
  const { data: addonList } = useAddonsByEventIdQuery({
    event_id: eventId,
    params: {
      page: 1,
      limit: 100,
    },
  });
  //close create session module
  const handleCloseAddModel = () => {
    if (descriptionRef.current) {
      descriptionRef.current.setContent("");
    }
    setResetRulesFields(true);
    setTimeout(() => {
      setResetRulesFields(false);
    }, 2000);
    setAddEvent(emptyObject);
    setAddErr(emptyObject);
    setcropedFile();
    setAllowGuestCheck(false);
    setcheckoutCheck(false);
    setAddonAvailableCheck(false);
    setIsChecked(false);
    setSelectedGuestTickets([]);
    setSelectedAddons([]);
    setCreateInprocess(false);
    handleClearAllStates();
  };

  // image upload
  const assignFiles = (acceptedFile) => {
    setFileDimension(acceptedFile[0]);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedFile[0].name);
    setFile(URL.createObjectURL(acceptedFile[0]));
  };
  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };
  //converting state to initial state
  useEffect(() => {
    if (isCloseAddModal) handleCloseAddModel();
  }, [isCloseAddModal]);

  //clear add state when click on edit main ticket
  useEffect(() => {
    handleCloseAddModel();
  }, [forceRender]);

  //handle onchange for create addons
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "maxTickets") {
      if (e.target.value.trim().length >= 0) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "",
        }));
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Please enter a valid amount",
        }));
      }
    } else if (e.target.name === "minTickets") {
      if (e.target.value.trim().length >= 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Enter a valid number",
        });
      }
    } else if (e.target.name === "discount") {
      if (e.target.value.trim().length >= 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }

      if (
        isNaN(e.target.value) ||
        e.target.value < 0 ||
        e.target.value >= 100
      ) {
        setAddErr({
          ...addErr,
          [e.target.name]:
            "Discount should be a valid number between 1 and 100",
        });
      }
    } else if (e.target.name === "flatAmount") {
      if (e.target.value.trim().length >= 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }

      if (isNaN(e.target.value)) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Flat Price should be a valid number greater than 0",
        });
      }
    } else if (e.target.name === "mainAmount") {
      if (e.target.value.trim().length >= 0) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "",
        }));
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Please enter a valid amount",
        }));
      }
    } else if (e.target.name === "quantity") {
      if (e.target.value.trim().length >= 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Please enter a valid quantity",
        });
      } else if (e.target.value.includes(".")) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Quantity should not be a decimal number",
        });
      }
    } else if (e.target.name === "maxGuestAllowed") {
      if (e.target.value.trim().length >= 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Enter a valid number",
        });
      } else if (e.target.value.includes(".")) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Max guest allowed should not be a decimal number",
        });
      }
    } else if (e.target.name === "cancellationDaysBeforeEvent") {
      if (e.target.value.trim().length >= 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
      if (isNaN(e.target.value) || e.target.value <= 0) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Enter a valid number",
        });
      } else if (e.target.value.includes(".")) {
        setAddErr({
          ...addErr,
          [e.target.name]: "Cancellation days should not be a decimal number",
        });
      }
    }

    if (e.target.name === "maxTickets") {
      if (
        Number(e.target.value) > Number(addEvent.minTickets) &&
        !e.target.value.includes(".")
      ) {
        setAddErr({ ...addErr, [e.target.name]: "", minTickets: "" });
      } else if (
        Number(e.target.value) <= Number(addEvent.minTickets) &&
        !e.target.value.includes(".")
      ) {
        setAddErr({
          ...addErr,
          [e.target.name]:
            "Max quantity should be larger than the min quantity",
        });
      }
    } else if (e.target.name === "minTickets") {
      if (
        Number(e.target.value) < Number(addEvent.maxTickets) &&
        !e.target.value.includes(".")
      ) {
        setAddErr({ ...addErr, [e.target.name]: "", maxTickets: "" });
      } else if (
        Number(e.target.value) > Number(addEvent.maxTickets) &&
        !e.target.value.includes(".")
      ) {
        setAddErr({
          ...addErr,
          [e.target.name]:
            "Min quantity should not be larger than the max quantity",
        });
      }
      if (e.target.value.includes(".")) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Quantity should not be a decimal number",
        }));
      } else if (e.target.value < 0) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Enter a valid number",
        }));
      }
    }

    if (e.target.name === "mainAmount") {
      if (Number(e.target.value) > Number(addEvent.flatAmount)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "",
        }));
      } else if (Number(e.target.value) <= Number(addEvent.flatAmount)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "Flat Price should not be greater than main amount",
        }));
      }
    } else if (e.target.name === "flatAmount") {
      if (Number(e.target.value) < Number(addEvent.mainAmount)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "",
        }));
      } else if (Number(e.target.value) >= Number(addEvent.mainAmount)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          flatAmount: "Flat Price should not be greater than main amount",
        }));
      }
    }

    if (e.target.name === "quantity") {
      if (Number(e.target.value) > Number(addEvent?.maxTickets)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          maxTickets: "",
        }));
      } else if (Number(e.target.value) <= Number(addEvent.maxTickets)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          maxTickets:
            "QTY should be less or equal to the total number of tickets available",
        }));
      }
    } else if (e.target.name === "maxTickets") {
      if (
        Number(e.target.value) < Number(addEvent.quantity) &&
        !e.target.value.includes(".")
      ) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          maxTickets: "",
        }));
      } else if (Number(e.target.value) >= Number(addEvent.quantity)) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          maxTickets:
            "QTY should be less or equal to the total number of tickets available",
        }));
      }
      if (e.target.value.includes(".")) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Quantity should not be a decimal number",
        }));
      } else if (e.target.value < 0) {
        setAddErr((prevAddErr) => ({
          ...prevAddErr,
          [e.target.name]: "Enter a valid number",
        }));
      }
    }

    if (!isChecked) {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          cancellationDaysBeforeEvent: "",
        };
        return updatedAddEvent;
      });
      setAddErr((prevAddErr) => ({
        ...prevAddErr,
        cancellationDaysBeforeEvent: "",
      }));
    }

    if (e.target.name === "priceType" && e.target.value !== "") {
      setAddErr({
        ...addErr,
        priceType: "",
      });
      if (e.target.value === "free") {
        setAddEvent((prevAddEvent) => {
          const updatedAddEvent = {
            ...prevAddEvent,
            flatAmount: "",
            discount: "",
            mainAmount: "",
            salePrice: "",
            saleEnd: "",
            salesEndTime: "",
            saleStart: "",
            salesStartTime: "",
          };
          return updatedAddEvent;
        });
      } else if (e.target.value === "paid") {
      }
    } else if (e.target.name === "priceType" && e.target.value === "") {
      setPriceType("");
    }

    if (e.target.name === "salePrice" && e.target.value !== "") {
      setAddErr({
        ...addErr,
        salePrice: "",
      });
    } else if (e.target.name === "salePrice" && e.target.value === "") {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          flatAmount: "",
          discount: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, flatAmount: "", discount: "" });
    }

    if (e.target.value === "free") {
      setPriceType("free");
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          paid: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, [e.target.name]: "" });
    } else if (e.target.value === "paid") {
      setPriceType("paid");
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          free: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, [e.target.name]: "" });
    }

    if (e.target.name === "isCancellation") {
      setIsChecked(e.target.checked);
    }

    if (e.target.name === "allowGuests") {
      setAllowGuestCheck(e.target.checked);
      if (!e.target.checked) {
        setSelectedGuestTickets([]);
      }
    }

    if (e.target.name === "checkout") {
      setcheckoutCheck(e.target.checked);
    }

    if (e.target.name === "addonAvailable") {
      setAddonAvailableCheck(e.target.checked);
      if (!e.target.checked) {
        setSelectedAddons([]);
      }
    }

    if (e.target.value === "flat") {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          discount: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, discount: "", salePrice: "" });
    } else if (e.target.value === "percentage") {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          flatAmount: "",
        };
        return updatedAddEvent;
      });
      setAddErr({ ...addErr, flatAmount: "", salePrice: "" });
    }

    if (e.target.name === "visibility") {
      setAddErr({ ...addErr, visibility: "" });
    }
    if (e.target.name === "visibility" && e.target.value !== "custom") {
      setAddEvent((prevAddEvent) => {
        const updatedAddEvent = {
          ...prevAddEvent,
          visibilityEndDate: "",
          visibilityStartDate: "",
          visibilityStartTime: "",
          visibilityEndTime: "",
        };
        return updatedAddEvent;
      });
    }

    if (e.target.name === "saleStart") {
      setAddErr({ ...addErr, saleStart: "" });
    }
    if (e.target.name === "saleEnd") {
      setAddErr({ ...addErr, saleEnd: "" });
    }
    if (e.target.name === "salesStartTime") {
      setAddErr({ ...addErr, salesStartTime: "" });
    }
    if (e.target.name === "salesEndTime") {
      setAddErr({ ...addErr, salesEndTime: "" });
    }
    if (e.target.name === "visibilityStartDate") {
      setAddErr({ ...addErr, visibilityStartDate: "" });
    }
    if (e.target.name === "visibilityEndDate") {
      setAddErr({ ...addErr, visibilityEndDate: "" });
    }
    if (e.target.name === "visibilityStartTime") {
      setAddErr({ ...addErr, visibilityStartTime: "" });
    }
    if (e.target.name === "visibilityEndTime") {
      setAddErr({ ...addErr, visibilityEndTime: "" });
    }

    if (
      e.target.name === "visibilityStartDate" ||
      e.target.name === "visibilityEndDate"
    ) {
      setAddErr((prevAddErr) => ({
        ...prevAddErr,
        visibilityStartDate:
          e.target.name === "visibilityStartDate"
            ? ""
            : prevAddErr.visibilityStartDate,
        visibilityEndDate:
          e.target.name === "visibilityEndDate"
            ? ""
            : prevAddErr.visibilityEndDate,
        visibilityStartTime:
          addEvent.visibilityStartDate === addEvent.visibilityEndDate
            ? ""
            : prevAddErr.visibilityStartTime,
        visibilityEndTime:
          addEvent.visibilityStartDate === addEvent.visibilityEndDate
            ? ""
            : prevAddErr.visibilityEndTime,
      }));
    }

    // Additional validation logic for start and end time
    if (
      e.target.name === "visibilityStartTime" ||
      e.target.name === "visibilityEndTime"
    ) {
      const startDate = addEvent.visibilityStartDate;
      const startTime =
        e.target.name === "visibilityStartTime"
          ? e.target.value
          : addEvent.visibilityStartTime;
      const endTime =
        e.target.name === "visibilityEndTime"
          ? e.target.value
          : addEvent.visibilityEndTime;

      // Compare start and end time only if both are defined and date is the same
      if (
        startDate &&
        startTime &&
        endTime &&
        startDate === addEvent.visibilityEndDate
      ) {
        const startDateTime = new Date(`${startDate}T${startTime}`);
        const endDateTime = new Date(`${startDate}T${endTime}`);

        if (startDateTime >= endDateTime) {
          setAddErr((prevAddErr) => ({
            ...prevAddErr,
            [e.target.name === "visibilityStartTime"
              ? "visibilityStartTime"
              : "visibilityEndTime"]: "Start time should be less than end time",
          }));
          return;
        } else {
          setAddErr((prevAddErr) => ({
            ...prevAddErr,
            visibilityEndTime: "",
            visibilityStartTime: "",
          }));
        }
      }
    }
  };
  //validation and api call on create addons
  const handleCreate = () => {
    let status = true;
    let temp = {};

    if (addEvent?.name?.trim().length === 0) {
      temp = { ...temp, name: "Enter name" };
      status = false;
    }
    if (
      isNaN(addEvent?.maxTickets) ||
      addEvent?.maxTickets.trim() === "" ||
      addEvent?.maxTickets <= 0
    ) {
      temp = {
        ...temp,
        maxTickets: "Enter a valid number",
      };
      status = false;
    }

    if (
      isNaN(addEvent?.minTickets) ||
      addEvent?.minTickets.trim() === "" ||
      addEvent?.minTickets <= 0
    ) {
      temp = {
        ...temp,
        minTickets: "Enter a valid number",
      };
      status = false;
    }

    if (addEvent?.maxTickets.includes(".")) {
      temp = {
        ...temp,
        maxTickets: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (addEvent?.minTickets.includes(".")) {
      temp = {
        ...temp,
        minTickets: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (
      isNaN(addEvent?.quantity) ||
      addEvent?.quantity.trim() === "" ||
      addEvent?.quantity <= 0
    ) {
      temp = {
        ...temp,
        quantity: "Please enter a valid quantity",
      };
      status = false;
    }

    if (addEvent?.quantity.includes(".")) {
      temp = {
        ...temp,
        quantity: "Quantity should not be a decimal number",
      };
      status = false;
    }

    if (allowGuestCheck && !addEvent?.maxGuestAllowed) {
      temp = {
        ...temp,
        maxGuestAllowed:
          "Please either uncheck the checkbox or provide a valid input value",
      };
      status = false;
    } else if (
      allowGuestCheck &&
      (isNaN(addEvent?.maxGuestAllowed) ||
        addEvent?.maxGuestAllowed.trim() === "" ||
        addEvent?.maxGuestAllowed <= 0)
    ) {
      temp = {
        ...temp,
        maxGuestAllowed: "Enter a valid number",
      };
      status = false;
    }

    if (addEvent?.maxGuestAllowed.includes(".")) {
      temp = {
        ...temp,
        maxGuestAllowed: "max guest allowed should not be a decimal number",
      };
      status = false;
    }

    if (
      descriptionRef.current &&
      descriptionRef.current.getContent &&
      descriptionRef.current.getContent().length > 2500
    ) {
      temp = {
        ...temp,
        description: "Description exceeds the character limit",
      };
      status = false;
    }

    if (Number(addEvent?.flatAmount) > Number(addEvent?.mainAmount)) {
      temp = {
        ...temp,
        flatAmount: "Flat Price should not be greater than main amount",
      };
      status = false;
    }

    if (Number(addEvent?.maxTickets) > Number(addEvent?.quantity)) {
      temp = {
        ...temp,
        maxTickets:
          "QTY should be less or equal to the total number of tickets available",
      };
      status = false;
    }

    if (Number(addEvent?.minTickets) > Number(addEvent?.maxTickets)) {
      temp = {
        ...temp,
        minTickets: "min quantity should not be larger than the max quantity ",
      };
      status = false;
    }

    if (
      addEvent?.priceType === "paid" &&
      (isNaN(addEvent?.mainAmount) ||
        addEvent?.mainAmount.trim() === "" ||
        addEvent?.mainAmount <= 0)
    ) {
      temp = {
        ...temp,
        mainAmount: "Please enter a valid amount",
      };
      status = false;
    }

    if (addEvent?.priceType === "") {
      temp = {
        ...temp,
        priceType: "Select price type",
      };
      status = false;
    }

    if (addEvent?.salePrice === "percentage") {
      const discountValue = Number(addEvent?.discount);
      if (isNaN(discountValue) || discountValue <= 0 || discountValue >= 100) {
        temp = {
          ...temp,
          discount: "Discount should be a valid number between 1 and 100",
        };
        status = false;
      }
    } else if (addEvent?.salePrice === "flat") {
      const flatAmountValue = Number(addEvent?.flatAmount);
      if (isNaN(flatAmountValue) || flatAmountValue <= 0) {
        temp = {
          ...temp,
          flatAmount: "Flat Price should be a valid number greater than 0",
        };
        status = false;
      }
    }

    if (isChecked && !addEvent?.cancellationDaysBeforeEvent) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent:
          "Please either uncheck the checkbox or provide a valid input value",
      };
      status = false;
    } else if (
      isChecked &&
      (isNaN(addEvent?.cancellationDaysBeforeEvent) ||
        addEvent?.cancellationDaysBeforeEvent.trim() === "" ||
        addEvent?.cancellationDaysBeforeEvent <= 0)
    ) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent: "Enter a valid number",
      };
      status = false;
    }

    if (addEvent?.cancellationDaysBeforeEvent.includes(".")) {
      temp = {
        ...temp,
        cancellationDaysBeforeEvent:
          "Cancellation days should not be a decimal number",
      };
      status = false;
    }

    if (addonAvailableCheck && selectedAddons.length <= 0) {
      temp = {
        ...temp,
        addonErrorMessage:
          "Please either uncheck the checkbox or provide a addons",
      };
      status = false;
    }

    if (allowGuestCheck && selectedGuestTickets.length <= 0) {
      temp = {
        ...temp,
        guestTicketErrorMessage:
          "Please either uncheck the checkbox or provide a guest tickets",
      };
      status = false;
    }

    if (addEvent?.visibility === "") {
      temp = {
        ...temp,
        visibility: "Select visibility",
      };
      status = false;
    }

    if (addEvent?.salePrice !== "" && addEvent?.saleStart === "") {
      temp = {
        ...temp,
        saleStart: "Select start date",
      };
      status = false;
    }

    if (addEvent?.salePrice !== "" && addEvent?.saleEnd === "") {
      temp = {
        ...temp,
        saleEnd: "Select end date",
      };
      status = false;
    }

    if (addEvent?.salePrice !== "" && addEvent?.salesStartTime === "") {
      temp = {
        ...temp,
        salesStartTime: "Select start time",
      };
      status = false;
    }

    if (addEvent?.salePrice !== "" && addEvent?.salesEndTime === "") {
      temp = {
        ...temp,
        salesEndTime: "Select end time",
      };
      status = false;
    }

    if (
      addEvent?.visibility === "custom" &&
      addEvent?.visibilityStartDate === ""
    ) {
      temp = {
        ...temp,
        visibilityStartDate: "Select start date",
      };
      status = false;
    }
    if (
      addEvent?.visibility === "custom" &&
      addEvent?.visibilityEndDate === ""
    ) {
      temp = {
        ...temp,
        visibilityEndDate: "Select end date",
      };
      status = false;
    }
    if (
      addEvent?.visibility === "custom" &&
      addEvent?.visibilityStartTime === ""
    ) {
      temp = {
        ...temp,
        visibilityStartTime: "Select start time",
      };
      status = false;
    }
    if (
      addEvent?.visibility === "custom" &&
      addEvent?.visibilityEndTime === ""
    ) {
      temp = {
        ...temp,
        visibilityEndTime: "Select end time",
      };
      status = false;
    }

    if (addEvent?.visibilityEndTime || addEvent?.visibilityStartTime) {
      const startDate = addEvent.visibilityStartDate;
      // Compare start and end time only if both are defined and date is the same
      if (
        addEvent?.visibilityStartTime >= addEvent?.visibilityEndTime &&
        startDate === addEvent.visibilityEndDate
      ) {
        status = false;
        temp = {
          ...temp,
          visibilityEndTime: "Start time should be less than end time",
        };
      }
    }

    if (status && !ruleError && !noRuleFieldSelected) {
      createApiCall();
    } else {
      setAddErr(temp);
      noRuleFieldSelected && setRuleError(true);
    }
  };

  //Api calll on create main tickets
  const createApiCall = async () => {
    let priceType = "";
    if (addEvent?.priceType === "free") {
      priceType = "FREE";
    } else if (addEvent?.priceType === "paid") {
      priceType = "PAID";
    }

    let salePriceType;
    if (addEvent?.salePrice === "flat") {
      salePriceType = "FLAT";
    } else if (addEvent?.salePrice === "percentage") {
      salePriceType = "PERCENTAGE";
    }

    let salesValue;
    if (addEvent?.flatAmount) {
      salesValue = addEvent?.flatAmount;
    } else if (addEvent?.discount) {
      salesValue = addEvent?.discount;
    }

    let visibility;
    if (addEvent?.visibility === "public") {
      visibility = "VISIBLE";
    } else if (addEvent?.visibility === "hidden") {
      visibility = "HIDDEN";
    } else if (addEvent?.visibility === "custom") {
      visibility = "CUSTOM";
    }

    //convert string date to formatted date
    const dateConvertor = (date, time) => {
      if (!date) return;
      const combinedDateTimeString = date + "T" + time + ":00";
      const combinedDateTime = new Date(combinedDateTimeString);
      const isoFormattedDateTime = combinedDateTime.toISOString();
      return isoFormattedDateTime;
    };
    setCreateInprocess(true);
    let imageCompress;
    const formdata = new FormData();
    if (cropedFileData) {
      imageCompress = await CompressImageFunction(cropedFileData);
    }
    imageCompress && formdata.append("thumbnail", imageCompress);
    formdata.append("name", addEvent?.name);
    formdata.append("quantity", addEvent?.quantity);
    formdata.append("type", priceType);
    formdata.append("isSpecialPrice", salesValue ? true : false);
    formdata.append("salesValue", salesValue || 0);
    formdata.append("minimumTicket", addEvent?.minTickets);
    formdata.append("maximumTicket", addEvent?.maxTickets);
    formdata.append("isCancellation", isChecked);
    formdata.append(
      "cancellationDaysBeforeEvent",
      addEvent?.cancellationDaysBeforeEvent
    );
    formdata.append("isGuestAllow", allowGuestCheck);
    formdata.append("guestPerTicket", addEvent?.maxGuestAllowed);
    selectedGuestTickets?.length > 0 &&
      selectedGuestTickets.map((guestTicket) => {
        formdata.append(`guestTickets`, guestTicket._id);
      });
    formdata.append("isAddOnAvailable", addonAvailableCheck);
    selectedAddons?.length > 0 &&
      selectedAddons.map((addon) => {
        formdata.append(`selectAddons`, addon._id);
      });
    formdata.append("basePrice", addEvent.mainAmount || 0);
    formdata.append("eventId", eventId);

    dataFromRules?.userAccess.length !== 0 &&
      formdata.append(
        `ticketAccess`,
        dataFromRules?.userAccess === "all" ? "PUBLIC" : "RESTRICTED"
      );
    if (descriptionRef.current && descriptionRef.current.getContent) {
      formdata.append("description", descriptionRef?.current?.getContent());
    }
    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formdata.append(`restrictedAccessGroups`, id);
        });
    }
    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formdata.append(`restrictedAccessMemberships`, id);
        });
    }
    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formdata.append(`restrictedIndivisualUser`, id);
      });

    if (salePriceType !== undefined) {
      formdata.append("salesPriceType", salePriceType);
      formdata.append(
        "salesStartDate",
        dateConvertor(addEvent.saleStart, addEvent.salesStartTime)
      );
      formdata.append(
        "salesEndDate",
        dateConvertor(addEvent.saleEnd, addEvent.salesEndTime)
      );
    }

    if (visibility !== undefined) {
      formdata.append("visibilityType", visibility);
    }

    if (addEvent?.visibility === "custom") {
      formdata.append(
        "visibilityStartDate",
        dateConvertor(
          addEvent.visibilityStartDate,
          addEvent.visibilityStartTime
        )
      );
      formdata.append(
        "visibilityEndDate",
        dateConvertor(addEvent.visibilityEndDate, addEvent.visibilityEndTime)
      );
    }
    try {
      const createMainTicketRes = await createTicket(formdata);

      if (createMainTicketRes?.data?.data) {
        setAddEvent(emptyObject);
        setAllowGuestCheck(false);
        if (descriptionRef.current && descriptionRef.current.getContent) {
          descriptionRef.current.setContent("");
        }
        setCreateInprocess(false);
        setShowAlertAdd(true);
        setAlertMsgAdd(createMainTicketRes?.data?.message);
        setTimeout(() => {
          $("#CreateMainTickets").modal("hide");
          handleCloseAddModel();
          setShowAlertAdd(false);
          setAlertMsgAdd("");
        }, 3000);
      }

      if (createMainTicketRes?.error?.data?.status === false) {
        setErrorMessage(true);
        setAddErrorMessage(createMainTicketRes?.error?.data?.message);
        setTimeout(() => {
          setErrorMessage(false);
          setAddErrorMessage("");
        }, 4000);
        setCreateInprocess(false);
      }
    } catch (err) {
      console.log("Error : ", err);
    }
    setAddErr(emptyObject);
  };

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#CreateMainTickets").hasClass("show")) {
        $("#CreateMainTickets").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#CreateMainTickets").hasClass("show")) {
        $("#CreateMainTickets").modal("hide");
      }
    }
  }, [stayOnPage]);

  //setting the error message for addons, guest tickets and description
  useEffect(() => {
    if (
      descriptionRef.current &&
      descriptionRef.current.getContent &&
      descriptionRef.current.getContent().length > 2500
    ) {
      setAddErr((prevAddErr) => ({
        ...prevAddErr,
        description: "Description exceeds the character limit",
      }));
    }
    if (selectedAddons.length > 0) {
      setAddErr((prevAddErr) => ({
        ...prevAddErr,
        addonErrorMessage: "",
      }));
    }
    if (selectedGuestTickets.length > 0) {
      setAddErr((prevAddErr) => ({
        ...prevAddErr,
        guestTicketErrorMessage: "",
      }));
    }
  }, [selectedAddons, selectedGuestTickets, descriptionRef.current]);

  return (
    <>
      {modalOpen && (
        <ImageCropperReact
          file={file}
          setModalOpen={setModalOpen}
          setcropedFile={setcropedFile}
          setFile={setFile}
          fileName={fileName}
          setcropedFileData={setcropedFileData}
          fileDimension={fileDimension}
          isLoading={isLoading}
        />
      )}
      {/* add modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="CreateMainTickets"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Main Ticket</h2>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={(e) => {
                  console.log("modal in process >", modalInProcess);
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#CreateMainTickets").modal("hide");
                    handleCloseAddModel();
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              {isErrorMessage && (
                <div className="alert alert-danger">{addErrorMessage}</div>
              )}
              <div className="upload-image-wrapper">
                <div className="cstm-video-upload">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      // Check if any of the dropped files are not allowed
                      const disallowedFiles = acceptedFiles.filter((file) => {
                        const allowedImageTypes = [
                          "image/jpeg",
                          "image/png",
                          "image/tiff",
                          "image/jpg",
                          "image/svg",
                          "image/EPS",
                        ];
                        return !allowedImageTypes.includes(file.type);
                      });

                      if (disallowedFiles.length > 0) {
                        //  message if disallowed files are detected
                        setValidate(true);
                      } else {
                        setIsLoading(true)
                        setModalOpen(true)
                        // Proceed with file assignment if all files are allowed
                        assignFiles(acceptedFiles);
                        setValidate(false);
                        setIsLoading(false)
                      }
                    }}
                    name="thumbnail"
                    multiple={false}
                    accept="image/jpeg, image/png,  image/tiff, image/jpg"
                    maxSizeBytes={10485760}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="cstm-video-upload-img">
                          {cropedFile ? (
                            <img className="channel-img w-100 h-100" src={cropedFile} alt="Uploaded video thumbnail" />
                          ) : (
                            <img className="empty-img" src={Frame} alt="Placeholder image" />
                          )}
                        </div>

                      </div>
                    )}
                  </Dropzone>

                  {/* Display error message for file selection */}
                  {validate && (
                    <span
                      className="error cstm-error44"
                      style={{ marginTop: "5px" }}
                    >
                      {"Invalid file type. Please select an image file."}
                    </span>
                  )}

                  {/* Display error message related to notificationDetailErr.image */}
                  {addErr.image && (
                    <span
                      className="error cstm-error44"
                      style={{ marginTop: "2px" }}
                    >
                      {addErr.image}
                    </span>
                  )}
                </div>
                <div className="group-info-user-link upload-ticket-image">
                  <div className="cover-image-upload-ttl">Upload image</div>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleAddChange}
                    value={addEvent.name}
                  />
                  <span className="error">{addErr.name}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Available quantity*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter available quantity"
                    name="quantity"
                    onChange={handleAddChange}
                    value={addEvent.quantity}
                  />
                  <span className="error">{addErr.quantity}</span>
                </div>
              </div>

              <div className="form-group cstm-admin-access-grp">
                <div className="form-group-label">Price type*</div>
                <div className="form-group-ct">
                  <select
                    name="priceType"
                    value={addEvent.priceType}
                    onChange={handleAddChange}
                  >
                    <option value="">Select price</option>
                    <option value="free">Free</option>
                    <option value="paid">Paid</option>
                  </select>
                </div>
                <span className="error cstm-error4">{addErr.priceType}</span>
              </div>
              {addEvent.priceType === "paid" && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">Amount*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter amount"
                        name="mainAmount"
                        onChange={handleAddChange}
                        value={addEvent.mainAmount}
                      />
                      <span className="error">{addErr.mainAmount}</span>
                    </div>
                  </div>
                  <div className="form-group cstm-admin-access-grp">
                    <div className="form-group-label">Sale Price</div>
                    <div className="form-group-ct">
                      <select
                        name="salePrice"
                        value={addEvent.salePrice}
                        onChange={handleAddChange}
                      >
                        <option value="">Select sale price</option>
                        <option value="flat">Flat</option>
                        <option value="percentage">Percentage</option>
                      </select>
                    </div>
                    <span className="error cstm-error4">
                      {addErr.salePrice}
                    </span>
                  </div>

                  {addEvent.salePrice === "flat" && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Flat($)*</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter flat price"
                            name="flatAmount"
                            onChange={handleAddChange}
                            value={addEvent?.flatAmount}
                          />
                          <span className="error">{addErr.flatAmount}</span>
                        </div>
                      </div>
                    </>
                  )}

                  {addEvent.salePrice === "percentage" && (
                    <>
                      <div className="form-group">
                        <div className="form-group-label">Discount(%)*</div>
                        <div className="form-group-ct">
                          <input
                            type="text"
                            placeholder="Enter discount"
                            name="discount"
                            onChange={handleAddChange}
                            value={addEvent?.discount}
                          />
                          <span className="error">{addErr.discount}</span>
                        </div>
                      </div>
                    </>
                  )}
                  {(addEvent.salePrice === "flat" ||
                    addEvent.salePrice === "percentage") && (
                      <div class="form-group">
                        <div class="cstm-channel-type-title partner-icon-rf-only">
                          Sale duration
                        </div>
                        <div className="form-group">
                          <div className="form-group-label">Start date*</div>
                          <div className="form-group-ct">
                            <input
                              type="date"
                              name="saleStart"
                              onChange={handleAddChange}
                              value={addEvent.saleStart}
                            />
                            <span className="error">{addErr.saleStart}</span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">Start time*</div>
                          <div className="form-group-ct">
                            <input
                              type="time"
                              name="salesStartTime"
                              onChange={handleAddChange}
                              value={addEvent.salesStartTime}
                              disabled={
                                addEvent.saleStart.length === 0 ? true : false
                              }
                            />
                            <span className="error">{addErr.salesStartTime}</span>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="form-group-label">End date*</div>
                          <div className="form-group-ct">
                            <input
                              type="date"
                              name="saleEnd"
                              onChange={handleAddChange}
                              value={addEvent.saleEnd}
                              disabled={
                                addEvent.saleStart.length === 0 ? true : false
                              }
                              min={addEvent.saleStart}
                            />
                            <span className="error">{addErr.saleEnd}</span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="form-group-label">End time*</div>
                          <div className="form-group-ct">
                            <input
                              type="time"
                              name="salesEndTime"
                              onChange={handleAddChange}
                              value={addEvent.salesEndTime}
                              disabled={
                                addEvent.saleEnd.length === 0 ? true : false
                              }
                            />
                            <span className="error">{addErr.salesEndTime}</span>
                          </div>
                        </div>
                      </div>
                    )}
                </>
              )}

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Description</div>
                  <div className="cstm-editor-tool-ds">
                    <TinyEditor
                      valueRef={descriptionRef}
                      value={addEvent.description}
                      handleEditorChange={(value, editor) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        setAddErr({
                          ...addErr,
                          description: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{addErr.description}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Max. tickets per order*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter maximum tickets"
                    name="maxTickets"
                    onChange={handleAddChange}
                    value={addEvent.maxTickets}
                  />
                  <span className="error">{addErr.maxTickets}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Min. tickets per order*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter minimum quantity"
                    name="minTickets"
                    onChange={handleAddChange}
                    value={addEvent.minTickets}
                  />
                  <span className="error">{addErr.minTickets}</span>
                </div>
              </div>

              <div class="form-group">
                <div class="cstm-channel-type-title partner-icon-rf-only">
                  Select visibility*
                </div>
                <div class="cstm-pm-list">
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="public"
                      name="visibility"
                      checked={addEvent?.visibility === "public"}
                      onChange={handleAddChange}
                    />
                    Public
                  </label>
                  <label class="subcat-addvideo">
                    {" "}
                    <input
                      type="radio"
                      value="hidden"
                      name="visibility"
                      checked={addEvent?.visibility === "hidden"}
                      onChange={handleAddChange}
                    />
                    Hidden
                  </label>
                  <label class="subcat-addvideo">
                    <input
                      type="radio"
                      value="custom"
                      name="visibility"
                      checked={addEvent?.visibility === "custom"}
                      onChange={handleAddChange}
                    />
                    Custom
                  </label>
                </div>
                <span className="error cstm-pm-list-msg">
                  {addErr.visibility}
                </span>
              </div>

              {addEvent?.visibility === "custom" && (
                <>
                  <div className="form-group">
                    <div className="form-group-label">Start date*</div>
                    <div className="form-group-ct">
                      <input
                        type="date"
                        name="visibilityStartDate"
                        onChange={handleAddChange}
                        value={addEvent.visibilityStartDate}
                      />
                      <span className="error">
                        {addErr.visibilityStartDate}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">Start time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        name="visibilityStartTime"
                        onChange={handleAddChange}
                        value={addEvent.visibilityStartTime}
                        disabled={
                          addEvent.visibilityStartDate.length === 0
                            ? true
                            : false
                        }
                      />
                      <span className="error">
                        {addErr.visibilityStartTime}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">End date*</div>
                    <div className="form-group-ct">
                      <input
                        type="date"
                        name="visibilityEndDate"
                        onChange={handleAddChange}
                        value={addEvent.visibilityEndDate}
                        disabled={
                          addEvent.visibilityStartDate.length === 0
                            ? true
                            : false
                        }
                        min={addEvent.visibilityStartDate}
                      />
                      <span className="error">{addErr.visibilityEndDate}</span>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-group-label">End time*</div>
                    <div className="form-group-ct">
                      <input
                        type="time"
                        name="visibilityEndTime"
                        onChange={handleAddChange}
                        value={addEvent.visibilityEndTime}
                        disabled={
                          addEvent.visibilityStartTime.length === 0
                            ? true
                            : false
                        }
                      />
                      <span className="error">{addErr.visibilityEndTime}</span>
                    </div>
                  </div>
                </>
              )}
              <AddRules
                title="Rules*"
                ruleError={ruleError}
                setRuleError={setRuleError}
                totalUsers={totalUsers}
                setTotalUsers={setTotalUsers}
                noRuleFieldSelected={noRuleFieldSelected}
                setNoRuleFieldSelected={setNoRuleFieldSelected}
                dataFromRules={dataFromRules}
                setDataFromRules={setDataFromRules}
                resetRulesFields={resetRulesFields}
                showEventField={true}
              />
              <div className="form-group">
                <div className="form-group-label">Application form</div>
                <div className="form-group-ct">
                  <select
                    value={addEvent.applicationForm}
                    onChange={handleAddChange}
                    name="applicationForm"
                  >
                    <option value={""}>Select application form</option>
                    <option>Example</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="cstm-chk-preregistration-container">
                    <input
                      type="checkbox"
                      name="isCancellation"
                      id="allowCancellation"
                      onChange={handleAddChange}
                      checked={isChecked === true}
                    />
                    <label
                      for="chk-preregistration"
                      htmlFor="allowCancellation"
                      onChange={handleAddChange}
                    >
                      Do you want to allow cancellation?
                    </label>
                  </div>
                </div>
              </div>
              {isChecked && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">
                        Cancellation deadline
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="cancellationDaysBeforeEvent"
                          placeholder="Allow cancellation within xx days."
                          value={addEvent.cancellationDaysBeforeEvent}
                          onChange={handleAddChange}
                        />
                        <span className="error">
                          {addErr.cancellationDaysBeforeEvent}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="cstm-chk-preregistration-container">
                    <input
                      type="checkbox"
                      name="allowGuests"
                      id="allowGuests"
                      onChange={handleAddChange}
                      checked={allowGuestCheck === true}
                    />
                    <label
                      for="chk-preregistration"
                      htmlFor="allowGuests"
                      onChange={handleAddChange}
                    >
                      Do you want to allow guests?
                    </label>
                  </div>
                </div>
              </div>

              {allowGuestCheck && (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="form-group-label">
                        Max. guest allowed*
                      </div>
                      <div className="form-group-ct">
                        <input
                          type="text"
                          name="maxGuestAllowed"
                          placeholder="Max guest Allowed for this ticket"
                          value={addEvent.maxGuestAllowed}
                          onChange={handleAddChange}
                        />
                        <span className="error">{addErr.maxGuestAllowed}</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group cstm-admin-access-grp">
                    <div className="form-group-label">Guest tickets*</div>
                    <div className="form-group-ct speakers-only">
                      <Multiselect
                        showArrow={true}
                        placeholder="Select guest tickets"
                        displayValue="name"
                        options={guestTicketList?.data}
                        selectedValues={selectedGuestTickets}
                        onSelect={(selectedlist) => {
                          dispatch({ type: "SETMODALINPROCESS" });
                          dispatch({ type: "SETSTAYONPAGE", payload: null });
                          setSelectedGuestTickets(selectedlist);
                        }}
                        onRemove={(selectedlist) => {
                          dispatch({ type: "SETMODALINPROCESS" });
                          dispatch({ type: "SETSTAYONPAGE", payload: null });
                          setSelectedGuestTickets(selectedlist);
                        }}
                      />
                      <span className="error">
                        {addErr.guestTicketErrorMessage}
                      </span>
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="cstm-chk-preregistration-container">
                    <input
                      type="checkbox"
                      name="checkout"
                      id="checkout"
                      onChange={handleAddChange}
                      checked={checkoutCheck === true}
                    />
                    <label
                      for="chk-preregistration"
                      htmlFor="checkout"
                      onChange={handleAddChange}
                    >
                      Required guest info while checkout?(<b>In development</b>)
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="cstm-chk-preregistration-container">
                    <input
                      type="checkbox"
                      name="addonAvailable"
                      id="addonAvailable"
                      onChange={handleAddChange}
                      checked={addonAvailableCheck === true}
                    />
                    <label
                      for="chk-preregistration"
                      htmlFor="addonAvailable"
                      onChange={handleAddChange}
                    >
                      Do you want to allow addons?
                    </label>
                  </div>
                </div>
              </div>

              {addonAvailableCheck && (
                <div className="form-group cstm-admin-access-grp">
                  <div className="form-group-label">Addons*</div>
                  <div className="form-group-ct speakers-only">
                    <Multiselect
                      showArrow={true}
                      placeholder="Select addons"
                      displayValue="name"
                      options={addonList?.data}
                      selectedValues={selectedAddons}
                      onSelect={(selectedlist) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        dispatch({ type: "SETSTAYONPAGE", payload: null });
                        setSelectedAddons(selectedlist);
                      }}
                      onRemove={(selectedlist) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        dispatch({ type: "SETSTAYONPAGE", payload: null });
                        setSelectedAddons(selectedlist);
                      }}
                    />
                    <span className="error">{addErr.addonErrorMessage}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <AlertComponent />
    </>
  );
};

export default CreateMainTicket;
