import React from "react";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import { useNavigate, useParams } from "react-router-dom";
import IconSuccessGreen from "../../../../components/GlobalComponents/Icons/IconEmptyState/IconSuccessGreen";
import { ReactComponent as IconSuccessfulPayment } from "../../../../Images/IconSuccessfulPayment.svg";
import Header from "../../../../components/UserComponents/Dashboard/Header";

export default function PurchaseThankYou() {
  const history = useNavigate();
  let { type } = useParams();
  const handleGoEventClick = () => {
    if (type === "purchase") {
      history("/home-event?type=myEvents");
    } else {
      history("/home-event");
    }
  };
  return (
    <>
      <Header />
      <div className="center-view-box">
        <EmptyState
          onClick={handleGoEventClick}
          subTitle={
            type === "purchase"
              ? "We look forward to seeing you at the event."
              : "Refunds will be issued in accordance with our Refund Policy"
          }
          title={
            type === "purchase"
              ? "You have successfully registered for the event"
              : "Your order successfully canceled"
          }
          label={type === "purchase" ? "Go to my events" : "Go to events"}
          icon={
            type === "purchase" ? (
              <IconSuccessGreen />
            ) : (
              <IconSuccessfulPayment />
            )
          }
        />
      </div>
    </>
  );
}
