import { useState, useEffect } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from "react-dropzone";
import Services from "../../Services/auth.service";
import arrowleft from "../../Images/arrow-left.png";
import Dummy from "../../Images/dummy.jpg";
import Frame from "../../Images/Frame.svg";
import loader from "../../Images/loader.gif";
import AlertComponent from "../../Hooks/AlertComponent";
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";

const CreateGroup = ({ setcurrentDiv }) => {
  const [Inprocess, setInprocess] = useState(false);
  const topics = useSelector((state) => {
    var arr = [];
    for (var i = 0; i < state.ReduTopics.length; i++) {
      arr[i] = state.ReduTopics[i].topic;
    }
    return arr;
  });
  const [grpdetail, setgrpdetail] = useState({
    groupImages: [],
    groupCoverImages: [],
    groupTitle: "",
    groupInfo: "",
    groupType: "",
    groupVisibility: "",
    createGroupChat: "",
    topics: [],
  });
  const [err, setErr] = useState({
    groupTitle: "",
    groupInfo: "",
    topic: "",
  });
  const formdata = new FormData();
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const dispatch = useDispatch()
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })

  //useEffect function is for clearing global states
  useEffect(() => {
    handleClearAllStates()
  }, [])

  const handleChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })

    if (e.target.name === "groupTitle") {
      e.target.value.length === 0
        ? setErr({ ...err, [e.target.name]: "Enter group title!" })
        : setErr({ ...err, [e.target.name]: "" });
    }
    if (e.target.name === "groupInfo") {
      e.target.value.length === 0
        ? setErr({ ...err, [e.target.name]: "Enter group info!" })
        : e.target.value.length > 150
          ? setErr({
            ...err,
            [e.target.name]:
              "Group info can only contain 150 maximum character!",
          })
          : setErr({ ...err, [e.target.name]: "" });
    }
    setgrpdetail({ ...grpdetail, [e.target.name]: e.target.value });
  };
  const handleCreate = () => {
    var valid = true;
    var obj = { ...err };
    if (grpdetail.groupImages.length > 0)
      formdata.append("groupImage", grpdetail.groupImages[0]);
    else formdata.append("groupImage", "");
    if (grpdetail.groupCoverImages.length > 0)
      formdata.append("groupCoverImage", grpdetail.groupCoverImages[0]);
    else formdata.append("groupCoverImage", "");
    if (grpdetail.groupTitle.length > 0)
      formdata.append("groupTitle", grpdetail.groupTitle.trim());
    else obj = { ...obj, groupTitle: "Enter group title!" };
    if (grpdetail.groupInfo.length > 0)
      formdata.append("groupInfo", grpdetail.groupInfo);
    else obj = { ...obj, groupInfo: "Enter group info!" };
    if (grpdetail.topics.length > 0) {
      for (var i = 0; i < grpdetail.topics.length; i++) {
        formdata.append("topics[]", grpdetail.topics[i]);
      }
    } else {
      obj = { ...obj, topic: "Enter group topics!" };
    }
    if (grpdetail.groupType.length > 0)
      formdata.append("groupType", grpdetail.groupType);
    if (grpdetail.groupVisibility.length > 0)
      formdata.append("groupVisibility", grpdetail.groupVisibility);
    if (grpdetail.createGroupChat.length > 0)
      formdata.append("createGroupChat", grpdetail.createGroupChat);
    setErr({ ...obj });
    if (Object.keys(obj).length > 0) {
      const emptyKeys = Object.keys(obj).filter((key) => obj[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    Object.keys(obj).map(function (key) {
      if (obj[key].length > 0) valid = false;
    });
    if (valid) {
      setInprocess(true);
      Services.creategroup(formdata)
        .then((res) => {
          if (res.data.status) {
            setInprocess(false);
            setshowalert(true);
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false);
              setalertmsg("");
              setgrpdetail({
                groupImages: [],
                groupCoverImages: [],
                groupTitle: "",
                groupInfo: "",
                groupType: "",
                groupVisibility: "",
                createGroupChat: "",
                topics: [],
              });
            }, 3000);
            setcurrentDiv("lisiting");

          } else {
            setInprocess(false);
            setshowalert(true);
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false);
              setalertmsg("");
            }, 3000);
          }
          handleClearAllStates()
        })
        .catch((e) => {
          handleClearAllStates()
          console.log(e);
        });
    }
  };
  const validtopic = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    var topic_lower = topics.map((t) => t.toLowerCase());
    // console.log(/^[a-zA-Z0-9\_\-]*$/.test(e.target.value),topic_lower.includes(e.target.value.toLowerCase()));
    /^[a-zA-Z0-9\_\-]*$/.test(e.target.value) ||
      topic_lower.includes(e.target.value.toLowerCase())
      ? setErr({ ...err, topic: "" })
      : setErr({
        ...err,
        topic: "Enter only alphabets, numbers or underscore!",
      });
  };

  const handletopic = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    var tpcs_lower = grpdetail.topics.map((t) => t.toLowerCase());
    var topic_lower = topics.map((t) => t.toLowerCase());
    var hash_first = e.target.value.substr(0, 1);
    if (err.topic.length === 0) {
      if (hash_first === "#") {
        if (!tpcs_lower.includes(e.target.value.toLowerCase())) {
          var index = topic_lower.indexOf(e.target.value.toLowerCase());
          var val = topics[index];
          setgrpdetail({ ...grpdetail, topics: [...grpdetail.topics, val] });
        }
      } else if (!tpcs_lower.includes("#" + e.target.value.toLowerCase())) {
        var index = topic_lower.indexOf("#" + e.target.value.toLowerCase());
        if (index >= 0) {
          var val = topics[index];
          setgrpdetail({ ...grpdetail, topics: [...grpdetail.topics, val] });
        } else {
          setgrpdetail({
            ...grpdetail,
            topics: [...grpdetail.topics, "#" + e.target.value],
          });
        }
      }
      e.target.value = "";
    }
  };

  //pop up state for do you want to leave....
  useEffect(() => {
    if (stayOnPage !== null && !stayOnPage) {
      setcurrentDiv("listing");
    }
    else if (stayOnPage !== null && stayOnPage) {
    }
  }, [stayOnPage])

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }
  return (
    <>
      <div className="create-topic-info">
        {showalert && <div className="alert alert-info">{alertmsg}</div>}

        <div className="um-back">
          <button
            type="button"
            className="back-btn"
            onClick={() => {
              if (modalInProcess) {
                dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
              } else {
                setcurrentDiv("listing");
              }
            }}
          >
            <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
            Back
          </button>
        </div>
        <h1>Group Info</h1>
        {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard.
            </p> */}

        <Dropzone
          onDrop={(acceptedFiles) => {
            dispatch({ type: "SETMODALINPROCESS" })
            dispatch({ type: "SETSTAYONPAGE", payload: null })
            setgrpdetail({
              ...grpdetail,
              groupImages: acceptedFiles.map((file) => file),
            });
          }}
          name="groupImages"
          multiple={false}
          accept="image/png, image/gif, image/jpeg, image/jpg"
          maxSizeBytes="10485760"
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <div className="group-info-user ctsm-fram-img">
                <div className="group-info-user-img cstm-group-info-user-img">
                  <img
                    src={
                      grpdetail.groupImages.length > 0
                        ? URL.createObjectURL(grpdetail.groupImages[0])
                        : Frame
                    }
                  />
                </div>
                <div className="group-info-user-link">
                  <div className="cover-image-upload-ttl">Group Image</div>
                  <div className="cover-image-upload-size">
                    JPEG or PNG, No longer then 10MB
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropzone>

        <div className="row">
          <div className="col-sm-12 form-group">
            <div className="form-group-label">Group title*</div>
            <div className="form-group-ct">
              <input
                type="text"
                name="groupTitle"
                value={grpdetail.groupTitle}
                onChange={handleChange}
              />
            </div>
            <span className="error cstm-error4">{err.groupTitle}</span>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 form-group">
            <div className="form-group-label"> Group info*</div>
            <div className="form-group-ct">
              <textarea
                name="groupInfo"
                value={grpdetail.groupInfo}
                onChange={handleChange}
              ></textarea>
            </div>
            <span className="error cstm-error4">{err.groupInfo}</span>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-4 form-group">
            <div className="form-group-label">Group type</div>
            <div className="form-group-ct">
              <select
                name="groupType"
                value={grpdetail.groupType}
                onChange={handleChange}
              >
                <option>Public</option>
                <option>Private</option>
              </select>
            </div>
          </div>
          <div className="col-sm-4 form-group">
            <div className="form-group-label">Group Visibility</div>
            <div className="form-group-ct">
              <select
                name="groupVisibility"
                value={grpdetail.groupVisibility}
                onChange={handleChange}
              >
                <option>Anyone</option>
                <option>Admin only</option>
              </select>
            </div>
          </div>
          <div className="col-sm-4 form-group">
            <div className="form-group-label">Create group chat</div>
            <div className="form-group-ct">
              <select
                name="createGroupChat"
                value={grpdetail.createGroupChat}
                onChange={handleChange}
              >
                <option>Yes</option>
                <option>Delete</option>
                <option>Hide</option>
              </select>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 form-group">
            <div className="form-group-label"> Group Topics</div>
            <div className="form-group-ct">
              <input
                className=""
                type="text"
                list="topicsgroup"
                name="browser"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handletopic(e);
                  } else {
                    validtopic(e);
                  }
                }}
              />
            </div>

            <datalist
              id="topicsgroup"
              onSelect={(e) => {
                console.log(e.target.value);
              }}
            >
              {topics.length > 0
                ? topics.map((tpc) => {
                  return <option value={tpc} />;
                })
                : ""}
            </datalist>
            <span className="error cstm-error4">{err.topic}</span>
          </div>
        </div>

        <div className="row cstm-tg-tg">
          <div className="col-sm-12 form-group">
            {grpdetail.topics.length > 0
              ? grpdetail.topics.map((t) => {
                return (
                  <span className="topic-label-in">
                    <b>{t}</b>
                    <button
                      onClick={() => {
                        setgrpdetail({
                          ...grpdetail,
                          topics: grpdetail.topics.filter((tp) => tp !== t),
                        });
                      }}
                    >
                      ×
                    </button>
                  </span>
                );
              })
              : ""}
          </div>
        </div>

        <h5 className="gl-title-sub">Cover image</h5>
        <div className="grup-main-cover-img-sec">
          <Dropzone
            onDrop={(acceptedFiles) => {
              setgrpdetail({
                ...grpdetail,
                groupCoverImages: acceptedFiles.map((file) => file),
              });
            }}
            name="groupCoverImages"
            multiple={false}
            accept="image/png, image/gif, image/jpeg, image/jpg"
            maxSizeBytes="10485760"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <div className="cover-image-dpdn">
                  <div className="cover-image-dpdn-in-left">
                    <img src={Frame} />
                  </div>
                  <div className="cover-image-dpdn-in-right">
                    <span className="cstm-drag-drop">
                      Drag & drop or click to add cover image.
                    </span>
                    <span className="cstm-jpeg-png">
                      JPEG or PNG, Standard size is 1300X475, Image is no logger
                      that 10MB
                    </span>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="cover-image-dpdn cstm-group-cover-img">
            {grpdetail.groupCoverImages.length > 0 ? (
              <img src={URL.createObjectURL(grpdetail.groupCoverImages[0])} />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="cyp-btm-btn">
          <button className="btn" onClick={handleCreate} disabled={Inprocess}>
            {Inprocess ? "In Process.." : "Create Group"}
          </button>
          <button
            className="btn2"
            onClick={() => {
              handleClearAllStates()
              setcurrentDiv("lisiting");
            }}
          >
            Discard
          </button>
        </div>
      </div>
      <AlertComponent setcurrentDiv={setcurrentDiv} />
    </>
  );
};

export default CreateGroup;
