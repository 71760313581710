const initialState = {
    isMDSPartner: false,
    PAGECHANGE: false
}
const ReduMDSPartner = (state = initialState, action) => {
    switch (action.type) {
        case "PAGECHANGE":
            return action.payload;
        default:
            return state
    }
}

export default ReduMDSPartner;