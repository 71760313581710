import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import authServicePartner from "../../../../Services/auth.service.partner";
import Loader from "../../../../Images/loader.gif";
const ReportCountModal = ({ setReport, reviewId }) => {
  const callolumns = [
    { id: "name", label: "User Name" },
    { id: "email", label: "Email" },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);

  //function for report count users list  in state
  function countUsersCreateData(id, name, email) {
    return { id, name, email };
  }

  useEffect(() => {
    reportReviewCountListApi();
  }, []);

  async function reportReviewCountListApi() {
    setLoader(true);
    try {
      const countRes = await authServicePartner.reportReviewCount(reviewId);
      if (countRes.data.status) {
        let data = countRes.data.data;
        if (data.length > 0) {
          let arr = [];
          for (let i = 0; i < data.length; i++) {
            console.log(data[i], "data");
            arr[i] = countUsersCreateData(
              data[i]._id,
              `${data[i].first_name} ${data[i].last_name}`,
              data[i].email
            );
          }
          setRows(arr);
        } else {
          setRows([]);
        }
        setLoader(false);
      }
    } catch (error) {}
  }

  //pagination for page change
  const handleChangeUserPage = (event, newPage) => {
    setPage(newPage);
  };

  //pagination for rows per page change
  const handleChangeUserRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
      <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
        <div className="desk-globle-model-box-inner">
          <div className="modal-content">
            <div class="cstm-replace-pp">
              <div className="cstm-hdr">
                <span
                  class="cstm-rpp-close-btn"
                  onClick={() => setReport(false)}
                >
                  ×
                </span>
                <div class="cstm-rpp-title">Partner Report Count List</div>
              </div>
              <div className="cstm-cstm-viewlist">
                <Paper className="uic-tb-mn">
                  <TableContainer className=" uic-table-main">
                    <Table>
                      <TableHead className="uic-table-th">
                        <TableRow>
                          {callolumns.map((colmn) => (
                            <TableCell key={colmn.id} className="uic-th-td">
                              {colmn.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className="uic-table-td">
                        {loader ? (
                          <div className="cstm-data-load">
                            <img src={Loader} />{" "}
                          </div>
                        ) : rows.length > 0 ? (
                          rows
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <TableRow hover tabIndex={-1} key={row.id}>
                                  <TableCell className="uic-th-td">
                                    {row.name}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {row.email}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <span className="no-data-found">No data found</span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangeUserPage}
                    onRowsPerPageChange={handleChangeUserRowsPerPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportCountModal;
