const historyvideoloader = false;

const ReduHistoryVideoLoader = (state = historyvideoloader, action) => {
  switch (action.type) {
    case "SETHISTORYVIDEOLOADER":
      return true;
    case "REMOVEHISTORYVIDEOLOADER":
        return false;
    default:
      return state;
  }
};

export default ReduHistoryVideoLoader;