import { Link, useNavigate } from "react-router-dom";
// Import image
import logo from "../../../Images/logo.png";
import thankyoupic from "../../../Images/thank-you.png";

const Thankyou = () => {
  const history = useNavigate();
  return (
    <>
      <div className="um-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 um-left um-thank-you-left">
              <div className="um-left-in">
                <div className="um-logo">
                  <Link to="/">
                    <img src={logo} />
                  </Link>
                </div>

                <div className="um-left-in-mn">
                  <img src={thankyoupic} />
                </div>
              </div>
            </div>
           
              <div className="col-sm-6 um-right">
                <div className="um-right-in">
                  <div className="um-right-in-title">Your Application Awaits!</div>
                  <br />
                  <div className="form-group">
                    <button
                      className="btn2"
                      onClick={() => {
                        history("/");
                      }}
                    >
                      Begin
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;



