import { ApiRoutes } from "./apiRoutes";
import createApiInstance from "./createApiInstance";

export const extendedApi = createApiInstance.injectEndpoints({
    endpoints: (builder) => ({
        getVideos: builder.query({
            query: (params) => {
                return {
                    url: ApiRoutes.videos.getVideos.url,
                    method: ApiRoutes.videos.getVideos.method,
                    params,
                }
            },
            // providesTags: ["VIDEOS"],
            transformResponse: (response) => response
        }),
        getVideoByFilterSortCount: builder.query({
            query: (params) => {
                return {
                    url: ApiRoutes.videos.getVideoByFilterSortCount.url,
                    method: ApiRoutes.videos.getVideoByFilterSortCount.method,
                    params,
                }
            },
            transformResponse: (response) => response
        }),
        getVideosCategories: builder.query({
            query: (params) => {
                return {
                    url: ApiRoutes.videos.videoCategories.url,
                    method: ApiRoutes.videos.videoCategories.method,
                    params,
                }
            },
            transformResponse: (response) => response
        }),
        getSpeakersByVideo: builder.query({
            query: (params) => {
                return {
                    url: ApiRoutes.videos.getSpeakersByVideo.url,
                    method: ApiRoutes.videos.getSpeakersByVideo.method,
                    params,
                }
            },
            transformResponse: (response) => response
        }),
        saveVideo: builder.mutation({
            query: ({ video_id, params }) => {
                return {
                    url: ApiRoutes.videos.videoSaved.url.replace(":video_id", video_id),
                    method: ApiRoutes.videos.videoSaved.method,
                    params
                }
            },
            invalidatesTags: ["VIDEOS"],
            transformResponse: (response) => response
        }),
        removeVideoHistory: builder.mutation({
            query: ({ video_id, params }) => {
                return {
                    url: ApiRoutes.videos.removeVideoHistory.url,
                    method: ApiRoutes.videos.removeVideoHistory.method,
                    body: params
                }
            },
            transformResponse: (response) => response
        })
    }),
    overrideExisting: false
})

export const { useGetVideosQuery, useLazyGetVideosQuery, useGetVideosCategoriesQuery, useGetSpeakersByVideoQuery, useSaveVideoMutation, useGetVideoByFilterSortCountQuery, useRemoveVideoHistoryMutation } = extendedApi