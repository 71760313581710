import React from 'react'
import cloneImage from '../../../../Images/clone-icon.png'
const CloneModal = ({ setClone, handleClonePartner }) => {
    return (
        <>
            <div
                className="modal fade msg-popup"
                id="clonePartner"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                        <img src={cloneImage} /></div>
                        <div className='cstm-clone-title'>Clone Partner</div> 
                        <h1>Are you sure you want to clone this partner?</h1>
                        <div className="row">
                            <div className="col-6">
                               <span class="msg-btn">
                                <button type="button" data-dismiss="modal" className="btn" onClick={() => setClone(false)}>
                                    Discard
                                </button>
                                </span>
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" className="btn2" data-dismiss="modal" onClick={() => {
                                        handleClonePartner()
                                    }}>
                                        Clone
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CloneModal