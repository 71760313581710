import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../../Services/auth.service1";
import videoimg from "../../Images/nothumbnail.png";
import Union from "../../Images/Union.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import Graph from "./Graph";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "chartjs-adapter-moment";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Statistic.css";
import loading from "../../Images/loader.gif";
import arrowimg from "../../Images/arow-left-right.png";
import arrowup from "../../Images/Arrow-Up.png";
const columns = [
  { id: "srno", label: "No." },
  { id: "speaker_name", label: "Speakers" },
  { id: "videocount", label: "Uploaded videos" },
  { id: "viewscount", label: "Views" },
  { id: "commentscount", label: "Comments" },
  { id: "likescount", label: "Likes" },
  { id: "dislikescount", label: "Dislikes" },
];
function createData(
  srno,
  speaker_name,
  videocount,
  viewscount,
  commentscount,
  likescount,
  dislikescount,
  createdAt
) {
  return {
    srno,
    speaker_name,
    videocount,
    viewscount,
    commentscount,
    likescount,
    dislikescount,
    createdAt,
  };
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend
);
const SpeakerStatistic = () => {
  const history = useNavigate();
  const [filterType, setFilterType] = useState("lifetime");
  const [field, setField] = useState("views");
  const [statisticData, setStatisticData] = useState([]);
  const [statisticSpeakers, setStatisticSpeakers] = useState([]);
  const [speakersCount, setSpeakersCount] = useState(0);
  const [viewsCount, setViewsCount] = useState(0);
  const [dislikescount, setDisLikesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [likesCount, setLikesCount] = useState(0);
  const [Loader, setLoader] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState({});
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRowsCount, setTotalRowsCount] = useState(0);
  const [tblAllSpeakers, setTblAllSpeakers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsFromUrl = new URLSearchParams(location).get("perpage");
  const [filterHeader, setFilterHeader] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [noSpeakersFound, setNoSpeakersFound] = useState(false);
  const [noChartDataFound, setNoChartDataFound] = useState(false);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "days").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [dataLoader, setDataLoader] = useState(false);
  const [videoLoader, setVideoLoader] = useState(false);
  const [chartLoader, setchartLoader] = useState(false);
  const [sortOrder, setSortOrder] = useState('asc')
  const [sortCol, setSortCol] = useState("speaker_name")
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showCustomFilter, setShowCustomFilter] = useState(false);
  const [sortVideoOrder, setSortVideoOrder] = useState("asc");
  const [startingView, setStartingView] = useState(0);
  const [statisticsOptions, setStatisticsOptions] = useState(null);
  const [isChangeDate, setIsChangeDate] = useState(true);

  const fromDateRef = useRef(fromDate);
  const toDateRef = useRef(toDate);
  //calls when page loads
  useEffect(() => {
    setNoDataFound(false);
    setNoChartDataFound(false);
    setNoSpeakersFound(false);
    setChartData(null);
    setChartOptions(null);
    rowsFromUrl !== null && setRowsPerPage(rowsFromUrl);
    const filter = {
      filtertype: filterType,
      field: field,
      fromdate: moment(fromDate).format("YYYY-MM-DD"),
      todate: moment(toDate).format("YYYY-MM-DD"),
    };
    setDataLoader(true);

    Services.statisticdata_speakercount()
      .then((res) => {
        if (res.data.status) {
          setSpeakersCount(res.data.data);
        }
      })
      .catch((e) => {
        setDataLoader(false);
        return false;
      });

    Services.statisticspeakercount(filter)
      .then((res) => {
        if (res.data.status) {
          var fieldsData = res.data.data;
          setViewsCount(fieldsData[0].viewscount);
          setCommentsCount(fieldsData[0].commentscount);
          setLikesCount(fieldsData[0].likescount);
          setDisLikesCount(fieldsData[0].dislikescount);
          let nodatafound_ = false;
          if (field == "views")
            fieldsData[0].viewscount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "comments")
            fieldsData[0].commentscount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "likes")
            fieldsData[0].likescount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "dislikes")
            fieldsData[0].dislikescount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          setNoDataFound(nodatafound_);
          if (field == "views" && !nodatafound_) {
            getAllStatisticsData(page==0 ? 1: page, rowsPerPage, filter.fromdate, filter.todate, filter.filtertype,sortCol,sortOrder,filter.field)
          } else {
            setDataLoader(false);
          }
        }
      })
      .catch((e) => {
        setDataLoader(false);
        return false;
      });
    if (filterType === "first24hrs") {
      var fromdate = moment().subtract(24, "hours").format("DD MMM HH:mm");
      var todate = moment().format("DD MMM HH:mm:SS");
      setFilterHeader(fromdate + "-" + todate);
    } else {
      setFilterHeader("Lifetime");
    }
  }, []);

  console.log(rows, page, "rows");
  //getData
  async function getAllStatisticsData(
    page,
    rowsPerPage,
    fromdate,
    todate,
    filtertype,
    col,
    sort,
    field
  ) {
    // filtertype=" + data.filtertype + "&field=" + data.field + "&fromdate=" + data.fromdate + "&todate=" + data.todate

    setDataLoader(true);

    let query = "";
    query += `?limit=${rowsPerPage}&page=${page}`;
    query +=
      filtertype !== undefined && filtertype !== null && filtertype !== ""
        ? `&filtertype=${filtertype}&fromdate=${fromdate}&todate=${todate}`
        : "";
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    query +=
      field !== undefined && field !== null && field !== ""
        ? `&field=${field}`
        : "";
    try {
      const res = await Services.statisticspeakerlist(query);
      if (res.data.status) {
        let data = res.data.data;
        //setStatisticSpeakers(data)
        setTotalPages(res.data.totalPages ? res.data.totalPages : 1);
        setTotalRowsCount(res.data.totalCount ? res.data.totalCount : 0);
        setDataLoader(false);

        if (data.length > 0) {
          var newarr = [];
          var arr = [...data];
          for (var i = 0; i < arr.length; i++) {
            if (field == "views") {
              newarr[i] = createData(
                i + 1,
                arr[i].speaker_name,
                arr[i].videocount,
                arr[i].viewscount,
                0,
                0,
                arr[i].createdAt
              );
            }

            if (field == "comments") {
              newarr[i] = createData(
                i + 1,
                arr[i].speaker_name,
                arr[i].videocount,
                0,
                arr[i].commentscount,
                0,
                arr[i].createdAt
              );
            }
            if (field == "likes") {
              newarr[i] = createData(
                i + 1,
                arr[i].speaker_name,
                arr[i].videocount,
                0,
                0,
                arr[i].likescount,
                arr[i].createdAt
              );
            }
            if (field == "dislikes") {
              newarr[i] = createData(
                i + 1,
                arr[i].speaker_name,
                arr[i].videocount,
                0,
                0,
                0,
                arr[i].dislikescount,
                arr[i].createdAt
              );
            }
          }
          setTblAllSpeakers(newarr);
          setRow(newarr);
        } else {
          setTblAllSpeakers([]);
          setRow([]);
        }
      } else {
        setDataLoader(false);
      }
    } catch (error) {
      setDataLoader(false);
      return false;
    }
  }

  //calls when filter and tab change
  useEffect(() => {
    const filter = {
      filtertype: filterType,
      field: field,
      fromdate: moment(fromDate).format("YYYY-MM-DD"),
      todate: moment(toDate).format("YYYY-MM-DD"),
    };
    setNoDataFound(false);
    setChartData(null);
    setChartOptions(null);
    setStatisticSpeakers([]);
    setPage(0);
    if (filterType !== "custom") {
      let fromDateTime = "";
      let toDateTime = "";
      if (filterType === "first24hrs") {
        fromDateTime = moment().subtract(24, "hours").subtract(-1);
        toDateTime = moment().add(1, "hours");

        var fromdate = moment().subtract(24, "hours").format("DD MMM HH:mm");
        var todate = moment().format("DD MMM HH:mm");

        setFilterHeader(fromdate + "-" + todate);
      } else {
        var addFilterCount = 0;
        if (filterType === "past7days") {
          addFilterCount = 6;
        } else if (filterType === "past28days") {
          addFilterCount = 27;
        } else if (filterType === "past90days") {
          addFilterCount = 89;
        } else if (filterType === "past365days") {
          addFilterCount = 364;
        }
        if (filterType === "lifetime") {
          setFilterHeader("Lifetime");
        } else if (filterType === "past365days") {
          fromDateTime = moment().subtract(addFilterCount + 1, "days");
          toDateTime = moment().add(1, "days");

          var fromdate = moment()
            .subtract(addFilterCount, "days")
            .format("DD MMM, YYYY");
          var todate = moment().format("DD MMM, YYYY");
          setFilterHeader(fromdate + "-" + todate);
        } else if (filterType === "custom") {
          fromDateTime = moment(fromDate).subtract(1, "days");
          toDateTime = moment(toDate).add(1, "days");

          var fromdate = moment(fromDate).format("DD MMM, YYYY");
          var todate = moment(toDate).format("DD MMM, YYYY");

          setFilterHeader(fromdate + "-" + todate);
        } else {
          fromDateTime = moment().subtract(addFilterCount + 1, "days");
          toDateTime = moment().add(1, "days");

          var fromdate = fromDateTime.format("DD MMM");
          var todate = toDateTime.format("DD MMM, YYYY");

          var fromdate = moment()
            .subtract(addFilterCount, "days")
            .format("DD MMM");
          var todate = moment().format("DD MMM");

          setFilterHeader(fromdate + "-" + todate);
        }
      }

      if (filterType === "lifetime") {
        setFilterHeader("Lifetime");
      }
      setDataLoader(true);
      Services.statisticspeakercount(filter)
        .then((res) => {
          var fieldsData = res.data.data;
          setViewsCount(fieldsData[0].viewscount);
          setCommentsCount(fieldsData[0].commentscount);
          setLikesCount(fieldsData[0].likescount);
          setDisLikesCount(fieldsData[0].dislikescount);
          let nodatafound_ = false;
          if (field == "views")
            fieldsData[0].viewscount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "comments")
            fieldsData[0].commentscount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "likes")
            fieldsData[0].likescount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "dislikes")
            fieldsData[0].dislikescount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          setNoDataFound(nodatafound_);
          if (
            (field == "views" && !nodatafound_) ||
            (field == "comments" && !nodatafound_) ||
            (field == "likes" && !nodatafound_) ||
            (field == "dislikes" && !nodatafound_)
          ) {
            if (filterType !== "lifetime") {
              Services.statisticspeakerdata(filter)
                .then((res) => {
                  if (res.data.status) {
                    let startingViewCount = res.data.startingView;
                    const statisticData = res.data.data;
                    setStatisticData(statisticData);

                    var labels = [];
                    var chartDataArr = [];
                    statisticData.map((item) => {
                      if (field == "views") {
                        labels.push(new Date(item.viewdate).getTime());
                      } else if (field == "likes") {
                        labels.push(new Date(item.likedate).getTime());
                      } else if (field == "comments") {
                        labels.push(new Date(item.commentdate).getTime());
                      } else if (field == "dislikes") {
                        labels.push(new Date(item.dislikedate).getTime());
                      }
                      chartDataArr.push(
                        field === "views"
                          ? item.viewscount
                          : field === "comments"
                          ? item.commentscount
                          : field === "dislikes"
                          ? item.dislikescount
                          : item.likescount
                      );
                    });

                    const statisticsOpt = {
                      startingViewCount: startingViewCount,
                      field: field,
                      filterType: filterType,
                      fromDateTime: fromDateTime,
                      toDateTime: toDateTime,
                      labels: labels,
                      chartDataArr: chartDataArr,
                    };

                    setStatisticsOptions(statisticsOpt);
                    getAllStatisticsData(page==0?1:page, rowsPerPage, fromDate, toDate, filterType,sortCol,sortOrder,field)

                  }
                })
                .catch((e) => {
                  return false;
                });
            } else {
                getAllStatisticsData(page==0?1:page, rowsPerPage, fromDate, toDate, filterType,sortCol,sortOrder,field)
            }
          } else {
            setDataLoader(false);
          }
        })
        .catch((e) => {
          return false;
        });

      setShowCustomFilter(false);
    } else {
      if (filterType === "custom") {
        setShowCustomFilter(true);
        handleCalenderClose();
      }
    }
  }, [filterType, field]);

  //calls when tab and filter change, here video lists will be changed. This call back function is used to change column names of video list

 
  const ChangeFilterType = (e) => {
    setFilterType(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllStatisticsData(newPage+1,rowsPerPage, fromDate, toDate, filterType, sortCol,sortOrder, field)
    
};
const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    getAllStatisticsData(1,+event.target.value ,fromDate, toDate, filterType, sortCol, sortOrder, field)
    setPage(1);
};
const handleSort = (col, direc) => {
    setSortCol(col)
    setSortOrder(direc)
    getAllStatisticsData(1,rowsPerPage ,fromDate, toDate, filterType, col, direc , field)
  
};
  
  //check date changed or not
  const handleDateChange = (date, setDateFunction, dateRef) => {
    setIsChangeDate(false)
    if (date !== dateRef.current) {
      setDateFunction(date);
      setIsChangeDate(true)
    }
  };

  //called when calender close
  const handleCalenderClose = (e) => {
    if (filterType === "past365days" || filterType === "custom") {
      var fromdate = moment(fromDate).format("DD MMM, YYYY");
      var todate = moment(toDate).format("DD MMM, YYYY");
      setFilterHeader(fromdate + "-" + todate);
    }
    if (filterType === "lifetime") {
      setFilterHeader("lifetime");
    }
    if (filterType === "custom") {
      if (
        moment(new Date(fromDate)).format("YYYY-MM-DD") >=
        moment(new Date(toDate)).format("YYYY-MM-DD")
      ) {
        setShowAlert(true);
        setAlertMsg("Fromdate should be less than Todate!");
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 3000);
        setNoChartDataFound(true);
        setNoDataFound(true);
        setNoSpeakersFound(true);
        setChartData(null);
        setChartOptions(null);
        setStatisticSpeakers([]);
        setViewsCount(0);
        setCommentsCount(0);
        setLikesCount(0);
        setDisLikesCount(0);
      } else {
        if (fromDate !== "" && toDate !== "" && isChangeDate) {
          var resultDays = 0;
          if (
            moment(new Date(fromDate)).format("YYYY-MM-DD") ===
            moment(new Date(toDate)).format("YYYY-MM-DD")
          ) {
            resultDays = moment(new Date(toDate)).diff(
              moment(new Date(fromDate)),
              "hours"
            );
          } else {
            resultDays = moment(new Date(toDate)).diff(
              moment(new Date(fromDate)),
              "days"
            );
          }
          const filter = {
            filtertype: filterType,
            field: field,
            fromdate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
            todate: moment(new Date(toDate)).format("YYYY-MM-DD"),
          };
          setNoChartDataFound(false);
          setNoDataFound(false);
          setNoSpeakersFound(false);
          setChartData(null);
          setChartOptions(null);
          setDataLoader(true);

          var fromDateTime = moment(new Date(fromDate)).subtract(1, "days");
          var toDateTime = moment(new Date(toDate)).add(1, "days");

          Services.statisticspeakercount(filter)
            .then((res) => {
              if (res.data.status) {
                var fieldsData = res.data.data;
                setIsChangeDate(false)
                setNoDataFound(false);
                setViewsCount(fieldsData[0].viewscount);
                setCommentsCount(fieldsData[0].commentscount);
                setLikesCount(fieldsData[0].likescount);
                setDisLikesCount(fieldsData[0].dislikescount);
                let nodatafound_ = false;
                if (field == "views")
                  fieldsData[0].viewscount !== 0
                    ? (nodatafound_ = false)
                    : (nodatafound_ = true);
                if (field == "comments")
                  fieldsData[0].commentscount !== 0
                    ? (nodatafound_ = false)
                    : (nodatafound_ = true);
                if (field == "likes")
                  fieldsData[0].likescount !== 0
                    ? (nodatafound_ = false)
                    : (nodatafound_ = true);
                if (field == "dislikes")
                  fieldsData[0].dislikescount !== 0
                    ? (nodatafound_ = false)
                    : (nodatafound_ = true);
                setNoDataFound(nodatafound_);
                if (
                  (field == "views" && !nodatafound_) ||
                  (field == "comments" && !nodatafound_) ||
                  (field == "likes" && !nodatafound_) ||
                  (field == "dislikes" && !nodatafound_)
                ) {
                  Services.statisticspeakerdata(filter)
                    .then((res) => {
                      if (res.data.status) {
                        let startingViewCount = res.data.startingView;
                        const statisticData = res.data.data;
                        setStatisticData(statisticData);

                        var labels = [];
                        var chartDataArr = [];
                        statisticData.map((item) => {
                          if (field == "views") {
                            labels.push(new Date(item.viewdate).getTime());
                          } else if (field == "likes") {
                            labels.push(new Date(item.likedate).getTime());
                          } else if (field == "comments") {
                            labels.push(new Date(item.commentdate).getTime());
                          } else if (field == "dislikes") {
                            labels.push(new Date(item.dislikedate).getTime());
                          }
                          chartDataArr.push(
                            field === "views"
                              ? item.viewscount
                              : field === "comments"
                              ? item.commentscount
                              : field === "dislikes"
                              ? item.dislikescount
                              : item.likescount
                          );
                        });

                        const statisticsOpt = {
                          startingViewCount: startingViewCount,
                          field: field,
                          filterType: filterType,
                          fromDateTime: fromDateTime,
                          toDateTime: toDateTime,
                          labels: labels,
                          chartDataArr: chartDataArr,
                        };

                        setStatisticsOptions(statisticsOpt);

                        getAllStatisticsData(
                          page,
                          rowsPerPage,
                          filter.fromdate,
                          filter.todate,
                          filter.filtertype,
                          sortCol,
                          sortOrder,
                          filter.field
                        );
                        // Services.statisticspeakerlist(filter).then((res) => {
                        //     if (res.data.status) {
                        //         var data = res.data.data;
                        //         setStatisticSpeakers(data)
                        //         setTotalPages(data.totalPages?data.totalPages : 1 )
                        //         setTotalRowsCount(data.totalCount?data.totalCount : 0)
                        //         setNoSpeakersFound(false)
                        //         setDataLoader(false)
                        //     } else {
                        //         setDataLoader(false)
                        //         setNoSpeakersFound(true)
                        //     }
                        // }).catch((e) => {
                        //     setDataLoader(false)
                        //     return false;
                        // });
                      }
                    })
                    .catch((e) => {
                      return false;
                    });
                } else {
                  setDataLoader(false);
                }
              }
            })
            .catch((e) => {
              setDataLoader(false);
              return false;
            });
          if (filterType === "first24hrs") {
            var fromdate = moment()
              .subtract(24, "hours")
              .format("DD MMM HH:mm");
            var todate = moment().format("DD MMM HH:mm");
            setFilterHeader(fromdate + "-" + todate);
          } else {
            var addFilterCount = 0;
            if (filterType === "past7days") {
              addFilterCount = 6;
            } else if (filterType === "past28days") {
              addFilterCount = 27;
            } else if (filterType === "past90days") {
              addFilterCount = 89;
            } else if (filterType === "past365days") {
              addFilterCount = 364;
            }
            if (filterType === "past365days") {
              var fromdate = moment()
                .subtract(addFilterCount, "days")
                .format("DD MMM, YYYY");
              var todate = moment().format("DD MMM, YYYY");
              setFilterHeader(fromdate + "-" + todate);
            } else if (filterType === "custom") {
              var fromdate = moment(fromDate).format("DD MMM, YYYY");
              var todate = moment(toDate).format("DD MMM, YYYY");
              setFilterHeader(fromdate + "-" + todate);
            } else {
              var fromdate = moment()
                .subtract(addFilterCount, "days")
                .format("DD MMM");
              var todate = moment().format("DD MMM, YYYY");
              setFilterHeader(fromdate + "-" + todate);
            }
          }
        }
      }
    }
  };

  function formatWithCommas(n) {
    if (n !== undefined) return n.toString().replace(/\B(?=(\d{3})+\b)/g, ",");
    else return n;
  }

  return (
    <div className="users-info-content video-statistic-info-content">
      <div className="video-statistic-info-content-main">
        <>
          {showAlert && <div className="alert alert-info">{alertMsg}</div>}
          <div className="cstm-video-filter-container">
            <div className="cstm-video-filter-container-left">
              <h2 className="cstn-vst">{speakersCount} Speakers</h2>
            </div>
            <div className="cstm-video-filter-container-right">
              <h4 className="cstm-video-select-title">{filterHeader}</h4>
              <select
                className="cstm-video-select-field"
                onChange={ChangeFilterType}
              >
                <option
                  value="lifetime"
                  selected={filterType === "lifetime" ? true : false}
                >
                  lifetime
                </option>
                <option
                  value="first24hrs"
                  selected={filterType === "first24hrs" ? true : false}
                >
                  Last 24 h
                </option>
                <option
                  value="past7days"
                  selected={filterType === "past7days" ? true : false}
                >
                  Last 7 Days
                </option>
                <option
                  value="past28days"
                  selected={filterType === "past28days" ? true : false}
                >
                  Last 30 Days
                </option>
                <option
                  value="past90days"
                  selected={filterType === "past90days" ? true : false}
                >
                  Last 90 Days
                </option>
                <option
                  value="past365days"
                  selected={filterType === "past365days" ? true : false}
                >
                  Last 365 Days
                </option>
                <option
                  value="custom"
                  selected={filterType === "custom" ? true : false}
                >
                  Custom
                </option>
              </select>
              {filterType === "custom" && showCustomFilter ? (
                <div className="cstm-video-filter-custom">
                  <DatePicker
                    showIcon
                    selected={fromDate}
                    onChange={(date) => handleDateChange(date, setFromDate, fromDateRef)}
                    onCalendarClose={handleCalenderClose}
                  />
                  <img src={arrowimg} className="cstm-arrow-left-right" />
                  <DatePicker
                    showIcon
                    selected={toDate}
                    onChange={(date) => handleDateChange(date, setToDate, toDateRef)}
                    onCalendarClose={handleCalenderClose}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className="cstm-tab-video-statistic-main">
            <ul
              className={`${
                filterType === "lifetime" ? "statistic-lifetime-tab" : ""
              }  cstm-tab-video-statistic`}
            >
              <li className={field === "views" ? "cstm-active-vs" : null}>
                <a
                  className="active cstm-videostatistic-view-link"
                  id="VideoStatisticViewBtn"
                  data-toggle="tab"
                  href="#VideoStatisticViewContent"
                  role="tab"
                  onClick={() => { setField("views"); setSortCol("speaker_name"); setSortOrder("asc"); setSortVideoOrder("asc"); }}
                >
                  <div className="csm-videostatistic-header">
                    <p className="csm-videostatistic-header-title">Views</p>
                    <h3 className="csm-videostatistic-header-viewscount">
                      {formatWithCommas(viewsCount)}
                    </h3>
                    <p className="csm-videostatistic-header-subtitle">
                      {filterType == "first24hrs"
                        ? `First 24 hours`
                        : filterType == "past7days"
                        ? `In last 7 days`
                        : filterType == "past28days"
                        ? `In last 28 days`
                        : filterType == "past90days"
                        ? `In last 90 days`
                        : filterType == "past365days"
                        ? `In last 365 days`
                        : filterType == "lifetime"
                        ? `Lifetime`
                        : ""}
                    </p>
                  </div>
                </a>
              </li>

              <li className={field === "likes" ? "cstm-active-vs" : null}>
                <a
                  className="active cstm-videostatistic-likes-link"
                  id="VideoStatisticLikesBtn"
                  data-toggle="tab"
                  href="#VideoStatisticLikesContent"
                  role="tab"
                  onClick={() => { setField("likes"); setSortCol("speaker_name"); setSortOrder("asc"); setSortVideoOrder("asc");  }}
                >
                  <div className="csm-videostatistic-header">
                    <p className="csm-videostatistic-header-title">Likes</p>
                    <h3 className="csm-videostatistic-header-viewscount">
                      {formatWithCommas(likesCount)}
                    </h3>
                    <p className="csm-videostatistic-header-subtitle">
                      {" "}
                      {filterType == "first24hrs"
                        ? `First 24 hours`
                        : filterType == "past7days"
                        ? `In last 7 days`
                        : filterType == "past28days"
                        ? `In last 28 days`
                        : filterType == "past90days"
                        ? `In last 90 days`
                        : filterType == "past365days"
                        ? `In last 365 days`
                        : filterType == "lifetime"
                        ? `Lifetime`
                        : ""}{" "}
                    </p>
                  </div>
                </a>
              </li>

              <li className={field === "dislikes" ? "cstm-active-vs" : null}>
                <a
                  className="active cstm-videostatistic-likes-link"
                  id="VideoStatisticDisLikesBtn"
                  data-toggle="tab"
                  href="#VideoStatisticDisLikesContent"
                  role="tab"
                  onClick={() => { setField("dislikes");setSortCol("speaker_name"); setSortOrder("asc"); setSortVideoOrder("asc");   }}
                >
                  <div className="csm-videostatistic-header">
                    <p className="csm-videostatistic-header-title">Dislikes</p>
                    <h3 className="csm-videostatistic-header-viewscount">
                      {formatWithCommas(dislikescount)}
                    </h3>
                    <p className="csm-videostatistic-header-subtitle">
                      {filterType == "first24hrs"
                        ? `First 24 hours`
                        : filterType == "past7days"
                        ? `In last 7 days`
                        : filterType == "past28days"
                        ? `In last 28 days`
                        : filterType == "past90days"
                        ? `In last 90 days`
                        : filterType == "past365days"
                        ? `In last 365 days`
                        : filterType == "lifetime"
                        ? `Lifetime`
                        : ""}
                    </p>
                  </div>
                </a>
              </li>

              <li className={field === "comments" ? "cstm-active-vs" : null}>
                <a
                  className="active cstm-videostatistic-comment-link"
                  id="VideoStatisticCommentBtn"
                  data-toggle="tab"
                  href="#VideoStatisticCommentContent"
                  role="tab"
                  onClick={() => { setField("comments");setSortCol("speaker_name"); setSortOrder("asc"); setSortVideoOrder("asc");   }}
               
                >
                  <div className="csm-videostatistic-header">
                    <p className="csm-videostatistic-header-title">Comments</p>
                    <h3 className="csm-videostatistic-header-viewscount">
                      {formatWithCommas(commentsCount)}
                    </h3>
                    <p className="csm-videostatistic-header-subtitle">
                      {" "}
                      {filterType == "first24hrs"
                        ? `First 24 hours`
                        : filterType == "past7days"
                        ? `In last 7 days`
                        : filterType == "past28days"
                        ? `In last 28 days`
                        : filterType == "past90days"
                        ? `In last 90 days`
                        : filterType == "past365days"
                        ? `In last 365 days`
                        : filterType == "lifetime"
                        ? `Lifetime`
                        : ""}
                    </p>
                  </div>
                </a>
              </li>
            </ul>
            <div
              className={`tab-content ${
                filterType !== "lifetime" ? "videostatistic-tabs" : ""
              }`}
              id="VideoStatisticViewContent"
            >
              <div
                className={`tab-pane fade show active ${
                  filterType !== "lifetime" ? `cstm-video-graph-container` : ``
                }`}
                id="videostatisticview"
                role="tabpanel"
              >
                {dataLoader && (
                  <>
                    {" "}
                    <div className="statistic-loader-img">
                      <img src={loading} />
                    </div>
                  </>
                )}
                {!dataLoader && noDataFound && (
                  <>
                    <div className="cstm-no-data-found">No Data Found</div>
                  </>
                )}
                {!dataLoader &&
                !noDataFound &&
                filterType !== "lifetime" &&
                statisticsOptions !== undefined &&
                statisticsOptions !== null &&
                Object.keys(statisticsOptions).length > 0 ? (
                  <Graph statisticsOptions={statisticsOptions} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {!dataLoader &&
            !noDataFound &&
            rows !== undefined &&
            rows !== null &&
            rows.length > 0 && (
              <>
                <div className="cstm-vs-title4">
                  <h4>Speakers</h4>
                </div>
                <div className="cstm-videostatistic-videolist-container">
                  <Paper className="uic-tb-mn">
                    <TableContainer className="">
                      <Table className="">
                        <TableHead className="">
                          <TableRow>
                            {columns.map((column) =>
                              column.id === "srno" ? (
                                <TableCell
                                  key={column.id}
                                  className={
                                    column.id === "speaker_name"
                                      ? "cstm-sr-w"
                                      : "cstm-sr-w"
                                  }
                                >
                                  {" "}
                                  {column.label}
                                </TableCell>
                              ) : column.id === "speaker_name" ? (
                                <TableCell
                                  key={column.id}
                                  className={
                                    column.id === "speaker_name"
                                      ? "cstm-vd-w"
                                      : " cstm-vd-w"
                                  }
                                >
                                  {" "}
                                  {column.label}
                                </TableCell>
                              ) : column.id === "videocount" ? (
                                <TableCell
                                  key={column.id}
                                  className={
                                    column.id === "videocount"
                                      ? "cstm-vc-w"
                                      : " cstm-vc-w"
                                  }
                                >
                                  {" "}
                                  {column.id === `videocount`
                                    ? column.label
                                    : ""}
                                  <span className="cstm-vs-icon">
                                    <img
                                      src={
                                        sortVideoOrder == "asc"
                                          ? Union
                                          : arrowup
                                      }
                                      onClick={(e) => {
                                        setSortVideoOrder(sortVideoOrder == "asc" ? "desc" : "asc");
                                        handleSort(column.id, sortVideoOrder == "asc" ? "desc" : "asc")
                                      }}
                                    />
                                  </span>{" "}
                                </TableCell>
                              ) : column.id === `${field}count` ? (
                                <TableCell
                                  key={column.id}
                                  className={
                                    column.id === "speaker_name"
                                      ? "cstm-ve-w"
                                      : "cstm-ve-w"
                                  }
                                >
                                  {column.id === `${field}count`
                                    ? column.label
                                    : ""}
                                  <span className="cstm-vs-icon">
                                    <img
                                      src={sortOrder == "asc" ? Union : arrowup}
                                      onClick={(e) => {
                                        setSortOrder(sortOrder == "asc" ? "desc" : "asc");
                                        // setSortCol(column.id);
                                        handleSort(column.id, sortOrder == "asc" ? "desc" : "asc")
                                      }}
                                    />
                                  </span>
                                </TableCell>
                              ) : (
                                <></>
                              )
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody className="">
                          {rows !== undefined &&
                            rows.length > 0 &&
                            rows.map((row, index) => {
                              return (
                                <TableRow
                                  hover
                                  tabIndex={-1}
                                  key={row.code}
                                  className=""
                                >
                                  {columns.map((column) => {
                                    return column.id == "srno" ? (
                                      <TableCell
                                        key={column.id}
                                        className=" cstm-sr-w"
                                      >
                                        {row["srno"]}
                                      </TableCell>
                                    ) : column.id === "speaker_name" ? (
                                      <TableCell
                                        key={column.id}
                                        className="cstm-vd-w"
                                      >
                                        {row[column.id]}
                                      </TableCell>
                                    ) : column.id === "videocount" ? (
                                      <TableCell
                                        key={column.id}
                                        className="cstm-vd-w"
                                      >
                                        {formatWithCommas(row[column.id])}
                                      </TableCell>
                                    ) : column.id == `${field}count` ? (
                                      <TableCell
                                        key={column.id}
                                        className="cstm-ve-w"
                                      >
                                        {formatWithCommas(row[column.id])}
                                      </TableCell>
                                    ) : (
                                      <></>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                      {rows.length === 0 ? (
                        <div className="admin-no-found">
                          <p>No Data Found </p>
                        </div>
                      ) : (
                        <></>
                      )}{" "}
                    </TableContainer>
                    {console.log(totalPages, "dssad")}
                    <TablePagination
                      className="uic-pagination"
                      rowsPerPageOptions={[20, 50, 100, 200]}
                      component="div"
                      count={totalRowsCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </div>
              </>
            )}
        </>
      </div>
    </div>
  );
};
export default SpeakerStatistic;