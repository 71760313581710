import Image1 from "../../../Images/app_logo.png";
const ForumList = () => {
  return (
    <ul>
      <li>
        <div className="cstm-sg-list">
          <img className="sctm-pt-mn" src={Image1} />
        </div>
        <div className="cstm-sg-ttl-name">
          <a className="cstm-sg-ttl-main" href="#">
            Lontrary to popular
          </a>
          <span className="cstm-sg-sb">Lorem ipusme</span>
        </div>
      </li>
      <li>
        <div className="cstm-sg-list">
          <img className="sctm-pt-mn" src={Image1} />
        </div>
        <div className="cstm-sg-ttl-name">
          <a className="cstm-sg-ttl-main" href="#">
            Lontrary to popular
          </a>
          <span className="cstm-sg-sb">Lorem ipusme</span>
        </div>
      </li>
    </ul>
  );
};

export default ForumList;
