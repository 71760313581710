import React from "react";
import "./Styles/SponsorSmallCell.css";
import { Link } from "react-router-dom";
import SponsorThumbnailSmallImage from "./SponsorThumbnailSmallImage.jsx";
import Button from "../../components/Button/Button.js";
import DotsIcon from "../../components/Icons/GeneralIcons/DotsIcon.js";
import sponsorImg from "../../Images/heyday-img.png";

const SponsorSmallCell = ({
  url = "/",
  hasSpacings = false,
  thumbUrl = sponsorImg,
  cardSize = "small",
  title = "Sponsor Title Text",
  thumbType = "sponsor",
  hasDuration,
  duration,
  onClick,
  hasDotMenu = true,
  alignCenter = false,
  hasSponsorType = false,
  eventType = "Platinum Sponsor",
  target = "",
}) => {
  return (
    <>
      <div className={`position-relative ${hasDotMenu ? "has-dotmenu" : ""}`}>
        <Link
          target={target}
          to={url}
          className={`sponsor-card ${cardSize} ${
            alignCenter ? "align-items-center" : ""
          } ${hasSpacings ? "card-spacing" : ""}`}
          onClick={onClick}
        >
          <SponsorThumbnailSmallImage
            thumbUrl={thumbUrl}
            hasDuration={hasDuration}
            duration={duration}
            thumbType={thumbType}
          />
          <div className="sponsor-card-text">
            <h6 className="sponsor-card-title">{title}</h6>
            {hasSponsorType && <p className="sponsor-type-text">{eventType}</p>}
          </div>
        </Link>
        {hasDotMenu && (
          <Button
            variant="ghost"
            extraClass="sponsor-dot-btn ml-auto align-self-start"
            size={"small"}
            handleClick={() => {}}
            hasText={false}
            elementIcon={<DotsIcon />}
          />
        )}
      </div>
    </>
  );
};

export default SponsorSmallCell;
