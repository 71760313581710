import React from 'react'
import "./Document.css"
import "../../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"
import TickIcon from '../../../components/Icons/GeneralIcons/TickIcon'
const FilterListItem = ({ label = "Label", onClick, selected, icon }) => {
    return (
        <>
            <button className='filter-sidebar-navigation' onClick={onClick}>
                {icon} <h5 className='filter-sidebar-navigation-title fs-font-title'>{label}</h5>
                {selected && <TickIcon />}
            </button>
        </>
    )
}
FilterListItem.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    selected: PropTypes.bool,
    icon: PropTypes.element
}
export default FilterListItem