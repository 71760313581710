import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import Loader from "../../../Images/loader.gif";
import authServiceCollaborator from '../../../Services/auth.service.collaborator';
import sendIcon from '../../../Images/sendIcon.svg'
import undoIcon from '../../../Images/undoIcon.svg'
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import Loader2 from "../../../Images/loader2.gif";
import { Link } from 'react-router-dom';

const InviteListTable = ({ loader, columns, rows, allList, setRows, recallApi }) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [message, setMessage] = useState('')
    const [inProcess, setInProcess] = useState(false)
    const [inviteeId, setInviteId] = useState('')
    const [isRevoke, setRevoke] = useState(false)
    //sorting function
    const handleSort = (col, direc) => {
        recallApi(col, direc)
    };

    // handle resend invitation
    async function handleResendInvitation(id) {
        const bodyData = {
            "collaboratorId": id,
            "type": "resend"
        }
        const resendRes = await authServiceCollaborator.resendInvitation(bodyData)
        if (resendRes.data.status) {
            setMessage(resendRes.data.message)
            recallApi()
            setTimeout(() => {
                setMessage('')
            }, 3000)
        }
    }


    // handle revoke invitation
    async function handleRevokeInvitation() {
        setInProcess(true)
        const bodyData = {
            "collaboratorId": inviteeId,
            "type": "revoke"
        }
        try {
            const revokeRes = await authServiceCollaborator.resendInvitation(bodyData)
            if (revokeRes.data.status) {
                setInProcess(false)
                setRevoke(false)
                document.querySelector('#revokeInvitePop .close').click()
                setMessage(revokeRes.data.message)
                recallApi()
                setTimeout(() => {
                    setMessage('')
                }, 3000)
            } else {
                setInProcess(false)
                setRevoke(false)
                document.querySelector('#revokeInvitePop .close').click()
                setMessage(revokeRes.data.message)
                recallApi()
                setTimeout(() => {
                    setMessage('')
                }, 3000)
            }
        } catch (error) {
            console.log(error);
            return false
        }

    }

    //for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //for pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <>
            {message && <div className="alert alert-info">{message}</div>}
            <Paper className="uic-tb-mn">
                <TableContainer className="uic-table-main">
                    <Table className="uic-table">
                        <TableHead className="uic-table-th">
                            <TableRow>
                                {columns.map((column, i) => (
                                    <TableCell className="uic-th-td" key={column.id}>
                                        {column.id !== "_id" &&
                                            <>
                                                <span className="custom-sorting">
                                                    <i
                                                        className="fa fa-angle-down"
                                                        onClick={(e) => {
                                                            handleSort(column.id, "Asc");
                                                        }}
                                                    ></i>
                                                    <i
                                                        className="fa fa-angle-up"
                                                        onClick={(e) => {
                                                            handleSort(column.id, "Dec");
                                                        }}
                                                    ></i>
                                                </span>
                                            </>
                                        }{column.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loader ?
                                <div className="cstm-data-load"><img src={Loader} /> </div>
                                : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length > 0 ?
                                    rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
                                            return (
                                                <TableRow>
                                                    {columns.map((col) => {
                                                        const value = row[col.id]
                                                        return (
                                                            col.id === "inviteeName" ? <TableCell className="uic-th-td w-18">{value}</TableCell> :
                                                                col.id === "firstName" ? <TableCell className="uic-th-td w-18"><Link target='_blank' to={`/user/edituser?id=${row.userId}`} className="cstm-view-users-count">{value}</Link></TableCell> :
                                                                    col.id === "email" ? <TableCell className="uic-th-td w-18">{value}</TableCell> :
                                                                        col.id === 'status' ? <TableCell className="uic-th-td w-10">{value.charAt(0).toUpperCase() + value.slice(1)}</TableCell> :
                                                                            <TableCell className="uic-th-td w-7 uic-table-icon">
                                                                                {row.status === "pending" ?
                                                                                    <>
                                                                                        <Tooltip title='Resend' arrow> <a onClick={() => {
                                                                                            handleResendInvitation(value)
                                                                                        }}><img src={sendIcon} /></a></Tooltip>
                                                                                        <Tooltip title='Revoke' arrow><a
                                                                                            onClick={() => {
                                                                                                setRevoke(true)
                                                                                                setInviteId(value)
                                                                                            }}><img src={undoIcon} /></a></Tooltip>
                                                                                    </>
                                                                                    : row.status === "revoked" ?
                                                                                        <>
                                                                                            <Tooltip title='Resend' arrow><a onClick={() => {
                                                                                                handleResendInvitation(value)
                                                                                            }}><img src={sendIcon} /></a></Tooltip>
                                                                                        </> :
                                                                                        <Tooltip title='Revoke' arrow><a onClick={() => {
                                                                                            setRevoke(true)
                                                                                            setInviteId(value)
                                                                                        }}><img src={undoIcon} /></a></Tooltip>
                                                                                }
                                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            )
                                        })
                                    : <span className="no-data-found">No data found</span>}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[20, 50, 100, 200]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper >
            <div className={`modal fade msg-popup delete-modal-with-loader ${isRevoke ? "show" : ""}`} id="revokeInvitePop" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: isRevoke ? "block" : "none" }}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setRevoke(false)}
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                            <img src={imgBigDelete} />
                        </div>
                        <h1>Are you sure you want to revoke this invitee?</h1>
                        <div className="row">
                            <div className="col-6">
                                {inProcess ? (
                                    <div className="loader-button1">
                                        <button>In Process...</button>
                                        <span className="loader-btn">
                                            <img src={Loader2} />
                                        </span>
                                    </div>
                                ) :
                                    (<span className="msg-btn">
                                        <button
                                            type="button"
                                            className="btn btn-red"
                                            onClick={handleRevokeInvitation}
                                        >
                                            Revoke
                                        </button>
                                    </span>)}
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" className="btn2" data-dismiss="modal" onClick={() => setRevoke(false)}>
                                        Discard
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InviteListTable