import React from "react";
import PropTypes from "prop-types"
function ExcelIcon({ height = 24, width = 24}) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#00733B"
                    fillRule="evenodd"
                    d="M5.858 1h8.911L21 7.504v12.63A2.861 2.861 0 0 1 18.142 23H5.858A2.866 2.866 0 0 1 3 20.134V3.858A2.865 2.865 0 0 1 5.858 1Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    d="M10.605 17.018H9.537l-.73-1.228-.731 1.228H7l1.269-2.136-1.116-1.854h1.075l.579.963.57-.963h1.075L9.345 14.89l1.261 2.128Zm.377 0v-3.99h1.02v3.123h1.734v.867h-2.754Zm4.44.049c-.4 0-.754-.129-1.06-.378-.296-.24-.465-.546-.489-.907l.875-.257a.793.793 0 0 0 .241.49.708.708 0 0 0 .482.192.638.638 0 0 0 .361-.096.31.31 0 0 0 .137-.257c0-.088-.04-.16-.113-.225a1.023 1.023 0 0 0-.28-.144 3.72 3.72 0 0 0-.386-.113 2.685 2.685 0 0 1-.426-.152 1.499 1.499 0 0 1-.385-.217.836.836 0 0 1-.281-.353 1.163 1.163 0 0 1-.113-.522c0-.33.137-.602.418-.82.28-.224.618-.328 1.012-.328.393 0 .738.096 1.027.28.29.193.474.442.546.747l-.915.386a.799.799 0 0 0-.233-.41.64.64 0 0 0-.425-.152.57.57 0 0 0-.305.08.235.235 0 0 0-.105.209c0 .08.048.152.145.209.096.048.224.088.369.112a2.925 2.925 0 0 1 .955.337c.145.08.265.217.362.394.104.184.152.401.152.65 0 .37-.144.675-.433.9-.29.224-.667.345-1.132.345Z"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
            </svg>
        </>
    );
}
ExcelIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
export default ExcelIcon;
