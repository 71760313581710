import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import auth0 from "auth0-js";
import FacebookLogin from "@greatsumini/react-facebook-login";
import AppleLogin from 'react-apple-login'
import authService from "../../../Services/auth.service";
import auth from "../../../Services/auth0services";
import { setregidetail } from "../../../Action";
import logo from "../../../Images/logo.png";
import authHeaderChannel from "../../../Services/auth.header.channel";
const PostAuthenticate = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const redirectURL = window.location.hostname
  const { auth0_appdata, auth0_metadata, auth_user_id, verifed } = location.state !== null && location.state !== undefined ? location.state : { undefined, undefined, undefined };
  const [selectedMedia, setselectedMedia] = useState("");
  const [email, setemail] = useState("");
  const [userid, setuserid] = useState("");
  const [displaydiv, setdisplaydiv] = useState(false);
  const [tokenError, setTokenError] = useState("")
  const previousUrl = sessionStorage.getItem("previousurl")
  // const [planid, setplanid] = useState("");
  const [error, setError] = useState("");

  const processHash = (hash) => {
    //  auth.crossOriginVerification();
    auth.parseHash({ hash }, function (err, result) {
      console.log(result, err);
      if (err) {
        console.log("there is an error");
        console.log(err);
        setError(<span className="error">Something went wrong please try again to login</span>)
      } else {
        console.log(result);
        // const userinfo = JSON.parse(localStorage.getItem("userinfo"));
        if (result && result.accessToken) {
          const accesstoken = result.accessToken;
          const idtoken = result.idToken;
          const namespace = 'https://MDS.example.com/';
          const userid_auth = result.idTokenPayload.sub.split("|")[1];

          setemail(result.idTokenPayload.email);
          setuserid(result.idTokenPayload.sub);
          const m_user = result.idTokenPayload[namespace + "alldata"]
          const app_user = result.idTokenPayload[namespace + "appdata"];
          localStorage.setItem("m_user", JSON.stringify(m_user));
          localStorage.setItem("app_user", JSON.stringify(app_user));
          authService.userExist({ auth0Id: userid_auth, socialEmail: result?.idTokenPayload?.email, mdsEmailExists: false }).then((res) => {
            if (res.data.status) {
              localStorage.setItem("username", `${res.data.data.display_name}`);
              localStorage.setItem(
                "userprofile",
                res.data.data.profileImg !== undefined
                  ? res.data.data.profileImg
                  : ""
              );
              localStorage.setItem("userid", res.data.data._id);
              dispatch({ type: "SETUSERID", payload: res.data.data._id });
              var userinfo = {
                userid: res.data.data._id,
                provider: res.data.data.provider,
                register_status: res.data.data.register_status,
                personalDetail_status: res.data.data.personalDetail_status,
                payment_status: res.data.data.payment_status,
                QA_status: res.data.data.QA_status,
                emailid: res.data.data.email,
                backpage: "login",
                blocked: res.data.data.blocked,
                active: res.data.data.active,
                facebookLinkedinId: res.data.data.facebookLinkedinId,
              };
              if (res.data.data.isCollaborator) {
                localStorage.setItem("useremailid", userinfo.emailid);
                localStorage.setItem("createdAt", res.data.data.createdAt);
                localStorage.setItem("accessToken", accesstoken);
                localStorage.setItem("idtoken", idtoken);
                localStorage.setItem("role", "user");
                localStorage.setItem("logintime", new Date());
                localStorage.setItem("isCollaborator", true)
                localStorage.setItem("accessResources", JSON.stringify(res.data.data.accessResources))
                localStorage.removeItem("userinfo");
                axios.put(
                  process.env.REACT_APP_API_URL + "user/manage/lastlogin",
                  { newmetadata: m_user },
                  { headers: { Authorization: "Bearer " + idtoken } }
                );
                window.location.href = previousUrl !== null ? previousUrl : "/homepage"
              } else {
                if (userinfo) {
                  if (
                    result.idTokenPayload.sub.split("|")[0] === "auth0" &&
                    userinfo.register_status &&
                    !userinfo.personalDetail_status &&
                    !userinfo.payment_status &&
                    !userinfo.QA_status
                  ) {
                    setdisplaydiv(true);
                    localStorage.setItem("migrated_user_plan_id", m_user.plan_id);
                  } else {
                    if (
                      userinfo.register_status &&
                      userinfo.personalDetail_status &&
                      userinfo.payment_status &&
                      userinfo.QA_status &&
                      !userinfo.blocked &&
                      userinfo.active
                    ) {
                      localStorage.setItem("useremailid", userinfo.emailid);
                      localStorage.setItem("createdAt", res.data.data.createdAt);
                      localStorage.setItem("accessToken", accesstoken);
                      localStorage.setItem("idtoken", idtoken);
                      localStorage.setItem("role", "user");
                      localStorage.setItem("isCollaborator", false)
                      localStorage.setItem("logintime", new Date());
                      localStorage.removeItem("userinfo");
                      axios.put(
                        process.env.REACT_APP_API_URL + "user/manage/lastlogin",
                        { newmetadata: m_user },
                        { headers: { Authorization: "Bearer " + idtoken } }
                      );
                      window.location.href = previousUrl !== null ? previousUrl : "/homepage"
                    } else {
                      if (!userinfo.personalDetail_status) {
                        setTimeout(() => {
                          dispatch(
                            setregidetail({
                              userid: userinfo.userid,
                              provider: userinfo.provider,
                              register_status: userinfo.register_status,
                              personalDetail_status: userinfo.personalDetail_status,
                              payment_status: userinfo.payment_status,
                              QA_status: userinfo.QA_status,
                              emailid: userinfo.emailid,
                              backpage: "login",
                            })
                          );
                          localStorage.removeItem("userinfo", "");
                          navigate("/firststepregister/3");
                        }, 2000);
                      } else if (!userinfo.payment_status) {
                        setTimeout(() => {
                          dispatch(
                            setregidetail({
                              userid: userinfo.userid,
                              provider: userinfo.provider,
                              register_status: userinfo.register_status,
                              personalDetail_status: userinfo.personalDetail_status,
                              payment_status: userinfo.payment_status,
                              QA_status: userinfo.QA_status,
                              emailid: userinfo.emailid,
                              backpage: "login",
                            })
                          );
                          localStorage.removeItem("userinfo", "");
                          navigate("/firststepregister/4");
                        }, 2000);
                      } else if (!userinfo.QA_status) {
                        setTimeout(() => {
                          dispatch(
                            setregidetail({
                              userid: userinfo.userid,
                              provider: userinfo.provider,
                              register_status: userinfo.register_status,
                              personalDetail_status: userinfo.personalDetail_status,
                              payment_status: userinfo.payment_status,
                              QA_status: userinfo.QA_status,
                              emailid: userinfo.emailid,
                              backpage: "login",
                            })
                          );
                          localStorage.removeItem("userinfo");
                          navigate("/firststepregister/6");
                        }, 2000);
                      } else if (userinfo.blocked) {
                        console.log(userinfo);
                        navigate("/", { state: { err: "Your account is inactive. Once the admin reactivates it, you will be able to login." } });
                        setError(<span className="error">Your account is inactive. Once the admin reactivates it, you will be able to login.</span>);
                      } else if (!userinfo.active) {
                        navigate("/", { state: { err: "Your account is inactive. Once the admin reactivates it, you will be able to login." } });
                      }
                    }
                  }
                }
              }
            }
            else {
              console.log("hmndghgdf");
              if (res.data.socialEmailDBExists) {
                navigate("/email-verified", { state: result.idTokenPayload.email })
              } else {
                navigate("/verify-email", {
                  state: {
                    err: "Your social email is not registered with MDS so please enter your registeted email address with MDS.",
                    socialEmailDBExists: res.data.socialEmailDBExists,
                    email: result.idTokenPayload.email,
                    authId: result.idTokenPayload.sub.split("|")[1],
                    firstName: result.idTokenPayload.given_name ? result.idTokenPayload.given_name : result.idTokenPayload.first_name,
                    lastName: result.idTokenPayload.family_name ? result.idTokenPayload.family_name : result.idTokenPayload.last_name,
                    provider: result.idTokenPayload.sub.split("|")[0],
                    userId: result.idTokenPayload.sub,
                    name: result.idTokenPayload.name,
                    nickname: result.idTokenPayload.nickname,
                    picture: result.idTokenPayload.picture,
                    notExist: true
                  }
                });
              }
            }
          })
        } else {
          setTokenError("Something went wrong! please try again")
        }
      }
    });
  };

  useEffect(() => {
    if (location.hash) {
      processHash(location.hash);
    }
    console.log(location.state, "location.state");
    if (location.state !== undefined && location.state !== null && location.state.userExist) {
      setdisplaydiv(true);
      setError(<span className="error">This {location.state.selectedMedia} account is already in MDS <a href="/">click here to login!</a></span>);
    } else {
      setError("")
    }
  }, [location]);

  return (
    <>
      {displaydiv && { error }}
      <div>{tokenError}</div>
    </>
  );
};

export default PostAuthenticate;
