import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Button/Button";
import MinusIcon from "../../../../components/Icons/GeneralIcons/MinusIcon";
import PlusIcon from "../../../../components/Icons/GeneralIcons/PlusIcon";

export default function EventTicketList({
  ticketList,
  index,
  minimumTicketErrorMsg,
}) {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [soldOutState, setSoldOutState] = useState(false);

  const SelectedEventData = useSelector((state) => {
    return state?.SelectedEventData;
  });

  // default quntity set
  useEffect(() => {
    const selectedTicket = SelectedEventData.selectedTickets.find(
      (data) => data.id === ticketList._id
    );
    if (selectedTicket) {
      setQuantity(selectedTicket.quantity);
    } else {
      setQuantity(ticketList?.minimumTicket); //ticketList.minimumTicket || 1
    }
    if (ticketList.available_ticket === 0) {
      setSoldOutState(true);
    } else if (ticketList.minimumTicket >= ticketList.available_ticket) {
      setSoldOutState(true);
    } else {
      setSoldOutState(false);
    }
  }, [SelectedEventData.selectedTickets, ticketList]);

  useEffect(() => {
    setErrorMessage(minimumTicketErrorMsg);
  }, [minimumTicketErrorMsg]);

  // handle click on minus button
  const handleMinusClick = () => {
    if (quantity > ticketList?.minimumTicket) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      handleQuantityChange(newQuantity);
      setErrorMessage("");
    } else {
      handleQuantityChange();
      setErrorMessage("Minimum quantity reached");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  };

  // handle click on plus button
  const handlePlusClick = () => {
    if (quantity < ticketList.maximumTicket) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      handleQuantityChange(newQuantity);
      setErrorMessage("");
    } else {
      handleQuantityChange();
      setErrorMessage("Maximum quantity reached");
      setTimeout(() => {
        setErrorMessage("");
      }, 2000);
    }
  };
  
  // onClick of ticket
  const onChangeEventSelectTicket = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      dispatch({
        type: "ADD_SELECTED_TICKET_EVENT",
        payload: {
          id: ticketList?._id,
          name: ticketList?.name,
          quantity: quantity,
          price: ticketList?.basePrice,
          thumbnail: ticketList?.thumbnail,
          type: ticketList?.type,
        },
      });
    } else {
      dispatch({
        type: "REMOVE_SELECTED_TICKET_EVENT",
        payload: ticketList._id,
      });
    }
  };

  // Function to handle quantity change
  const handleQuantityChange = (newQuantity) => {
    dispatch({
      type: "ADD_OR_UPDATE_SELECTED_TICKET_EVENT",
      payload: {
        id: ticketList._id,
        name: ticketList.name,
        quantity: newQuantity || newQuantity === 0 ? newQuantity : quantity,
        price: ticketList.basePrice,
        thumbnail: ticketList.thumbnail,
        type: ticketList?.type,
      },
    });
  };

  return (
    <div>
      <div
        className={`position-relative event-ticket-medium-card-item ${
          soldOutState ? "sold-out-cls" : ""
        }`}
      >
        <input
          type="radio"
          id={"radioId" + index}
          name="event-card"
          onChange={onChangeEventSelectTicket}
          checked={SelectedEventData.selectedTickets.some(
            (data) => data.id === ticketList._id
          )}
          disabled={soldOutState}
        />
        <label htmlFor={"radioId" + index} className="event-ticket-medium-card">
          {soldOutState && (
            <span className="sold-out-cls-tag">Ticket Sold Out</span>
          )}
          <div className="event-ticket-text">
            <h3>{ticketList?.name}</h3>
            <p dangerouslySetInnerHTML={{ __html: ticketList?.description }} />
          </div>
          <div className="event-ticket-controls">
            <h4 className="ticket-type">
              {ticketList.type === "FREE"
                ? "Free"
                : ticketList.type === "PAID"
                ? `$` + ticketList.basePrice.toFixed(2)
                : ""}
            </h4>
          </div>
        </label>
        <div className="button-stepper">
          <Button
            elementIcon={
              <MinusIcon height={16} iconColor="#060D14" width={16} />
            }
            variant="secondary"
            hasText={false}
            handleClick={handleMinusClick}
            // disabled={
            //   SelectedEventData.selectedTickets.some(
            //     (data) => data.id === ticketList._id
            //   )
            //     ? false
            //     : true
            // }
            disabled={soldOutState}
          />
          <p>{quantity}</p>
          <Button
            elementIcon={
              <PlusIcon height={16} iconColor="#060D14" width={16} />
            }
            variant="secondary"
            hasText={false}
            handleClick={handlePlusClick}
            // disabled={
            //   SelectedEventData.selectedTickets.some(
            //     (data) => data.id === ticketList._id
            //   )
            //     ? false
            //     : true
            // }
            disabled={soldOutState}
          />
        </div>
      </div>
      {errorMessage && (
        <p className="error-text w-100 mb-0">
          <i className="fa fa-exclamation-circle"></i>
          {errorMessage}
        </p>
      )}
    </div>
  );
}
