export const handleSearch = (text, searchOn, array) => {
  if (array !== undefined && array.length > 0 && text) {
    var data = array.filter((event) => {
      return searchOn.some((searchField) => {
        return (
          event[searchField] &&
          event[searchField].toLowerCase().includes(text.toLowerCase())
        );
      });
    });
    return data;
  }
  return array;
};

export const handleSorting = (sortingPattern, sortOn, array) => {
    var normal = array;
    var arr = [...array];

    if(!sortOn || !normal.length) return array;

    if (sortingPattern === "a-z") {
        arr.sort(function (a, b) {
            var textA = a[sortOn].toUpperCase();
            var textB = b[sortOn].toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    } else if (sortingPattern === "z-a") {
        arr.sort(function (a, b) {
            var textA = a[sortOn].toUpperCase();
            var textB = b[sortOn].toUpperCase();
            return textA > textB ? -1 : textA < textB ? 1 : 0;
        });
    } else {
        arr = normal;
    }
    return arr;
};

export const handleAttendeeTypeFilter = (filterType, array) => {
  var filterData = [];
  if (filterType !== "all") {
    for (let index = 0; index < array.length; index++) {
      if (array[index].roleID === filterType) {
        filterData.push(array[index]);
      }
    }
  } else {
    filterData = [...array]
  }
  return filterData;
};

export const searchAndSortArray = (arr, searchText, searchOn, sortingPattern, sortOn, filterType, filterationType)=>{
    if(arr.length){
      let tempArray = [...arr];
      tempArray = handleSearch(searchText, searchOn, tempArray);
      tempArray = handleSorting(sortingPattern, sortOn, tempArray);
      if(filterationType){
        tempArray = handleAttendeeTypeFilter(filterType, tempArray);
      }
      return tempArray;
    }
    return [];
  }
