import React, { useEffect, useRef, useState } from "react";
import FilterHeader from "./FilterHeader";
import FilterNestedNavigation from "./FilterNestedNavigation";
import Separators from "../../../components/Separators/Separators";
import FilterFooter from "./FilterFooter";
import PropTypes from "prop-types";
import "./Document.css";
import "../../../Styles/Theme/Theme.css";
import FilterItems from "./FilterItems";
import MdsIcon from "../../../components/Icons/GeneralIcons/MdsIcon";
import Avtar from "../../../components/Avtar/Avtar";
import ReactDatePicker from "react-datepicker";
import FileIcon from "../../../components/Icons/GeneralIcons/FileIcon";
import UploadIcon from "../../../components/Icons/GeneralIcons/UploadIcon";
import UpdateIcon from "../../../components/Icons/GeneralIcons/UpdateIcon";
import FilterListItem from "./FilterListItem";
import SearchField from "../../../components/InputField/SearchField";
const FilterSidebar = ({
  userList,
  onCancel,
  contentTypeList,
  selectedSortBy,
  selectedFile,
  selectedContent,
  selectedCreated,
  selectedUpdated,
  handleSortBy,
  handleFilter,
  handleSelectContent,
  handleDateCreated,
  handleDateUpdated,
  handleClearAllFilter,
  handleFiletype,
  handlePeopleFilter,
  resultCount,
  selectedPeople,
  sortBy,
  dateList,
  fileFormat,
  uploadedFrom,
  uploadedTo,
  handleUploadedTo,
  handleUploadedFrom,
  handleModifiedFrom,
  handleModifiedto,
  modifiedTo,
  modifiedFrom,
  isFilter,
}) => {
  const [sort, setSort] = useState(false);
  const [people, setPeople] = useState(false);
  const [fileType, setFileType] = useState(false);
  const [contentType, setContentType] = useState(false);
  const [dateCreated, setDateCreated] = useState(false);
  const [dateUpdated, setDateUpdated] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchPeoples, setSearchPeoples] = useState([]);
  const filterRef = useRef();

  useEffect(() => {
    setSearchPeoples(userList);
  }, [userList]);
  // handle back from filter
  const handleBack = () => {
    setSort(false);
    setSort(false);
    setPeople(false);
    setFileType(false);
    setContentType(false);
    setDateCreated(false);
    setDateUpdated(false);
  };

  // close filter on outside click
  const handleCloseFilter = (e) => {
    if (
      filterRef.current &&
      isFilter &&
      !filterRef.current.contains(e.target)
    ) {
      onCancel();
    }
  };
  document.addEventListener("mousedown", handleCloseFilter);

  // function for date uploaded filter
  const handleCreatedFilter = (data) => {
    handleDateCreated(data);
    data.id !== "custom" && handleBack();
  };

  // function for date updated filter
  const handleUpdatedFilter = (data) => {
    handleDateUpdated(data);
    data.id !== "custom" && handleBack();
  };

  const handleSearchPeople = (e) => {
    setSearchText(e.target.value);
    let searchList = userList.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchPeoples(searchList);
  };

  const headerLabel = sort
    ? "Sort by"
    : people
    ? "People"
    : fileType
    ? "File format"
    : contentType
    ? "Content type"
    : dateCreated
    ? "Date uploaded"
    : dateUpdated
    ? "Last modified"
    : "Filters";
  return (
    <>
      <div className="filter-sidebar-partners" ref={filterRef}>
        <FilterHeader
          title={headerLabel}
          onCancel={onCancel}
          isNavigated={
            sort ||
            fileType ||
            people ||
            contentType ||
            dateCreated ||
            dateUpdated
          }
          onClick={handleBack}
        />
        {people && (
          <div className="filter-sidebar-search">
            <SearchField value={searchText} handleChange={handleSearchPeople} />
          </div>
        )}
        <div className="filter-sidebar-body scrollbar">
          {sort ? (
            sortBy.map((label) => (
              <FilterListItem
                onClick={() => {
                  handleSortBy(label, label.id);
                  handleBack();
                }}
                label={label.label}
                selected={label.id === selectedSortBy.id}
              />
            ))
          ) : people ? (
            <>
              {searchPeoples.map((users) => (
                <FilterListItem
                  selected={users._id === selectedPeople._id}
                  icon={
                    users.name === "admin" ? (
                      <span className="filter-icon-svg">
                        {" "}
                        <MdsIcon scale={1.34} />{" "}
                      </span>
                    ) : users.profileImg !== undefined &&
                      users.profileImg !== "" ? (
                      <Avtar src={users.profileImg} size="32" />
                    ) : (
                      <div className="type-avatar-text-32">
                        {users.display_name.charAt(0).toUpperCase()}
                      </div>
                    )
                  }
                  label={users.name === "admin" ? "MDS" : users.display_name}
                  onClick={() => {
                    handlePeopleFilter(users);
                    handleBack();
                  }}
                />
              ))}
            </>
          ) : fileType ? (
            fileFormat.map((files) => (
              <FilterListItem
                label={files.label}
                onClick={() => {
                  handleFiletype(files);
                  handleBack();
                }}
                selected={selectedFile.id === files.id}
                icon={files.icon}
              />
            ))
          ) : contentType ? (
            contentTypeList.map((content) => (
              <FilterListItem
                onClick={() => {
                  handleSelectContent(content.name);
                  handleBack();
                }}
                label={content.name}
                selected={selectedContent === content.name}
              />
            ))
          ) : dateCreated ? (
            dateList.map((files) => (
              <FilterListItem
                onClick={() => {
                  handleCreatedFilter(files);
                }}
                label={files.label}
                selected={selectedCreated.id === files.id}
                icon={files.icon}
              />
            ))
          ) : dateUpdated ? (
            dateList.map((files) => (
              <FilterListItem
                onClick={() => {
                  handleUpdatedFilter(files);
                }}
                label={files.label}
                selected={selectedUpdated.id === files.id}
                icon={files.icon}
              />
            ))
          ) : (
            <>
              <FilterNestedNavigation
                onClick={() => setSort(true)}
                label={`Sort by ${
                  selectedSortBy.label !== undefined
                    ? ":" + " " + selectedSortBy.label
                    : ""
                }`}
              />
              <Separators />
              <FilterNestedNavigation
                icon={
                  Object.keys(selectedPeople).length > 0 &&
                  selectedPeople.name === "admin" ? (
                    <span className="filter-icon-svg">
                      {" "}
                      <MdsIcon scale={1.34} />{" "}
                    </span>
                  ) : Object.keys(selectedPeople).length > 0 &&
                    selectedPeople.profileImg !== "" ? (
                    <Avtar src={selectedPeople.profileImg} size="32" />
                  ) : Object.keys(selectedPeople).length > 0 &&
                    selectedPeople.profileImg === "" ? (
                    <div className="type-avatar-text-32">
                      {selectedPeople.name.charAt(0).toUpperCase()}
                    </div>
                  ) : (
                    ""
                  )
                }
                onClick={() => setPeople(true)}
                label={
                  Object.keys(selectedPeople).length > 0 &&
                  selectedPeople.name === "admin"
                    ? "MDS"
                    : Object.keys(selectedPeople).length > 0
                    ? selectedPeople.name
                    : "People"
                }
              />
              <FilterNestedNavigation
                onClick={() => setFileType(true)}
                icon={selectedFile.icon}
                label={
                  selectedFile.id !== undefined
                    ? selectedFile.label
                    : "File format"
                }
              />
              <FilterNestedNavigation
                icon={selectedContent !== "" ? <FileIcon /> : ""}
                onClick={() => setContentType(true)}
                label={
                  selectedContent !== "" ? selectedContent : "Content type"
                }
              />
              <FilterNestedNavigation
                icon={
                  selectedCreated.id !== undefined &&
                  selectedCreated.id !== "" ? (
                    <UploadIcon />
                  ) : (
                    ""
                  )
                }
                onClick={() => setDateCreated(true)}
                label={
                  selectedCreated.id !== undefined && selectedCreated.id !== ""
                    ? selectedCreated.label
                    : "Date uploaded"
                }
              />
              <FilterNestedNavigation
                icon={
                  selectedUpdated.id !== undefined &&
                  selectedUpdated.id !== "" ? (
                    <UpdateIcon />
                  ) : (
                    ""
                  )
                }
                onClick={() => setDateUpdated(true)}
                label={
                  selectedUpdated.id !== undefined && selectedUpdated.id !== ""
                    ? selectedUpdated.label
                    : "Last modified"
                }
              />
            </>
          )}
          {selectedCreated.id === "custom" && dateCreated && (
            <>
              <div className="custom-date-range">
                <div className="text-field-main">
                  <h6>From</h6>
                  <div className={`text-field-box`}>
                    <ReactDatePicker
                      selected={uploadedFrom}
                      onChange={handleUploadedFrom}
                    />
                  </div>
                </div>
                <div className="text-field-main">
                  <h6>To</h6>
                  <div className={`text-field-box`}>
                    <ReactDatePicker
                      selected={uploadedTo}
                      onChange={(date) => {
                        handleUploadedTo(date);
                        handleBack();
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {selectedUpdated.id === "custom" && dateUpdated && (
            <>
              <div className="custom-date-range">
                <div className="text-field-main">
                  <h6>From</h6>
                  <div className={`text-field-box`}>
                    <ReactDatePicker
                      selected={modifiedFrom}
                      onChange={handleModifiedFrom}
                    />
                  </div>
                </div>
                <div className="text-field-main">
                  <h6>To</h6>
                  <div className={`text-field-box`}>
                    <ReactDatePicker
                      selected={modifiedTo}
                      onChange={(date) => {
                        handleModifiedto(date);
                        handleBack();
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {!sort &&
          !fileType &&
          !people &&
          !contentType &&
          !dateCreated &&
          !dateUpdated && (
            <FilterFooter
              resultCount={resultCount}
              onCancel={handleClearAllFilter}
              onShow={handleFilter}
            />
          )}
      </div>
    </>
  );
};
FilterSidebar.propTypes = {
  onCancel: PropTypes.func,
  contentTypeList: PropTypes.array,
  selectedSortBy: PropTypes.string,
  selectedFile: PropTypes.string,
  selectedContent: PropTypes.string,
  selectedCreated: PropTypes.string,
  selectedUpdated: PropTypes.string,
  handleSortBy: PropTypes.func,
  handleFilter: PropTypes.func,
  handleSelectContent: PropTypes.func,
  handleDateCreated: PropTypes.func,
  handleDateUpdated: PropTypes.func,
  handleClearAllFilter: PropTypes.func,
  handleFiletype: PropTypes.func,
  handlePeopleFilter: PropTypes.func,
  handleUploadedTo: PropTypes.func,
  handleUploadedFrom: PropTypes.func,
  resultCount: PropTypes.number,
  userList: PropTypes.array,
  handleModifiedFrom: PropTypes.func,
  handleModifiedto: PropTypes.func,
};

export default FilterSidebar;
