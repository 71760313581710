import React from "react";
import PropTypes from "prop-types";
import "./Styles/ChatDetailsMembers.css";
import "./Styles/ChatDetailsMediaMembers.css";
import TabsFluid from "../../../components/Tabs/TabsFluid";
import Button from "../../../components/Button/Button";
import ArrowleftIcon from "../../../components/Icons/ArrowsIcon/ArrowleftIcon";
import Typography from "../../../components/Typography/Typography";
import ChatDetailsMedia from "./HelperComponets/ChatDetailsMedia";
import ChatLinkCell from "./HelperComponets/ChatLinkCell";
import ChatFileCell from "./HelperComponets/ChatFileCell";
import { formatBytes } from "../CommonFunctions/ConvertBytes";
import EmptyState from "../../../components/EmptyState/EmptyState";
import IconNoDataFound from "../../../components/GlobalComponents/Icons/IconEmptyState/IconNoDataFound";

const ChatDetailsMediaMembers = ({
  label = "Media",
  isSelected = true,
  counter = "3",
  hasCounter = false,
  hasBadge = false,
  hasTab3 = true,
  label1 = "Files",
  isSelected1 = false,
  counter1 = "3",
  hasCounter1 = false,
  hasBadge1 = false,
  label2 = "Links",
  isSelected2 = false,
  counter2 = "3",
  hasCounter2 = false,
  hasBadge2 = false,
  isVideo = true,
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  onClickBackMediaFiles,
  handleTabClick,
  newMediaList,
  filesList,
  linkList,
  handlePreviewClick,
}) => {
  return (
    <div className="chat-details-members">
      <div className="chat-details-members-heading">
        <div className="chat-details-members-heading-title">
          <Button
            iconColor="#060D14"
            label=""
            size="small"
            variant="ghost"
            elementIcon={<ArrowleftIcon height={24} width={24} />}
            hasText={false}
            toolTipText="Back"
            handleClick={onClickBackMediaFiles}
          />
          <Typography variant="h4">Media and files</Typography>
        </div>
        {/* Tabs */}
        <div className="chat-details-members-heading-tabs">
          <TabsFluid
            counter={counter}
            counter1={counter1}
            counter2={counter2}
            handleClick={handleTabClick}
            hasTab3={hasTab3}
            id={() => {}}
            isSelected={isSelected}
            isSelected1={isSelected1}
            isSelected2={isSelected2}
            label={label}
            label1={label1}
            label2={label2}
            hasBadge={hasBadge}
            hasBadge1={hasBadge1}
            hasBadge2={hasBadge2}
            hasCounter={hasCounter}
            hasCounter1={hasCounter1}
            hasCounter2={hasCounter2}
          />
        </div>
      </div>
      <hr />
      {isSelected && newMediaList.length === 0 ? (
        <div className="chat-media-empty">
          <EmptyState
            isClear={false}
            subTitle="No media"
            icon={<IconNoDataFound />}
            hasTitle={false}
            hasIcon={true}
          />
        </div>
      ) : (
        <div className="chat-details-members-wrapper scrollbar">
          {isSelected &&
            newMediaList.map((message) => (
              <ChatDetailsMedia
                key={message.id}
                onClick={() => handlePreviewClick(message.url, "media")}
                src={message.type === "video" ? message.thumbnail : message.url}
                isVideo={message.type === "video"}
              />
            ))}
        </div>
      )}
      {isSelected1 && filesList.length === 0 ? (
        <div className="chat-media-empty">
          <EmptyState
            isClear={false}
            subTitle="No files"
            icon={<IconNoDataFound />}
            hasTitle={false}
            hasIcon={true}
          />
        </div>
      ) : (
        <div className="chat-details-files-list scrollbar">
          {isSelected1 &&
            filesList.map(
              (item) =>
                item.documents &&
                item.documents.length > 0 &&
                item.documents.map(
                  (document) =>
                    // Render the content directly within the onClick handler
                    document.key && (
                      <ChatFileCell
                        key={document.key} // Add a unique key if possible
                        hasText={false}
                        name={document.key
                          ?.split("?")[0]
                          .split("-GRPCHATUNIQUE-")
                          .pop()}
                        isSize={true}
                        size={formatBytes(document?.size)}
                        onClick={() => handlePreviewClick(document, "file")}
                      />
                    )
                )
            )}
        </div>
      )}
      {isSelected2 && linkList.length === 0 ? (
        <div className="chat-media-empty">
          <EmptyState
            isClear={false}
            subTitle={"No links"}
            icon={<IconNoDataFound />}
            hasTitle={false}
            hasIcon={true}
          />
        </div>
      ) : (
        <div className="chat-details-links-list scrollbar">
          {isSelected2 &&
            linkList?.map(
              (item) =>
                item?.message && (
                  <ChatLinkCell
                    key={item._id}
                    link={item.message}
                  />
                )
            )}
        </div>
      )}
    </div>
  );
};

ChatDetailsMediaMembers.propTypes = {
  hasTab3: PropTypes.bool,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  id: PropTypes.string,
  hasBadge: PropTypes.bool,
  hasCounter: PropTypes.bool,
  counter: PropTypes.string,
  label1: PropTypes.string,
  isSelected1: PropTypes.bool,
  hasBadge1: PropTypes.bool,
  hasCounter1: PropTypes.bool,
  counter1: PropTypes.string,
  label2: PropTypes.string,
  isSelected2: PropTypes.bool,
  hasBadge2: PropTypes.bool,
  hasCounter2: PropTypes.bool,
  counter2: PropTypes.string,
  handleOnClick: PropTypes.func,
  isVideo: PropTypes.bool,
  src: PropTypes.string,
  onClickBackMediaFiles: PropTypes.func,
  handlePreviewClick: PropTypes.func,
};

export default ChatDetailsMediaMembers;
