import React from "react";


const IconSuccesfull = () => {
  return (
    <div className="mds-icons-empty">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={120}
        height={120}
        fill="none"
        viewBox="0 0 120 120"
      >
        <circle cx={60} cy={60} r={60} fill="#6FCADF" fillOpacity={0.2} />
        <path
          fill="#060D14"
          d="m79.07 44.649-26 26L40.848 58.43a2.215 2.215 0 1 0-3.132 3.134l13.788 13.783a2.215 2.215 0 0 0 3.133 0l27.566-27.566a2.215 2.215 0 1 0-3.133-3.133Z"
        />
      </svg>
    </div>
  );
};


export default IconSuccesfull;