import React from "react";

const IconNoEventFound = () => {
  return (
    <div className="mds-icons-empty">
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_4514_109)">
          <path
            d="M59.392 119.945C57.8043 119.945 56.2013 120.092 54.6381 119.911C52.1814 119.626 49.7247 119.276 47.3047 118.764C38.5104 116.903 30.2583 113.058 23.1794 107.522C20.9096 105.772 18.7697 103.86 16.7765 101.8C13.5738 98.4428 10.7567 94.7382 8.37752 90.7555C6.68239 87.9423 5.2248 84.993 4.01986 81.938C2.64404 78.4433 1.61545 74.822 0.948921 71.1262C0.551178 69.1154 0.263108 67.0844 0.0859871 65.0423C-0.107482 62.0909 0.0368521 59.1149 0.0859871 56.145C0.156619 52.0124 1.00727 47.9871 2.04525 44.0171C2.84335 40.9584 3.92277 37.9799 5.26973 35.1198C6.72906 31.9358 8.47003 28.8884 10.4719 26.0139C11.6315 24.3085 12.8768 22.663 14.2031 21.0835C16.0721 18.9588 18.0689 16.9496 20.1822 15.0671C22.5147 12.9741 25.0134 11.0739 27.6538 9.38512C37.7101 3.01409 49.428 -0.242733 61.3328 0.024545C75.4238 0.337813 88.9539 5.60029 99.5477 14.888C110.142 24.1758 117.122 36.8951 119.263 50.8128C119.917 54.9232 120.134 59.0915 119.908 63.2475C119.162 75.6148 115.074 86.7548 107.535 96.6032C102.548 103.086 96.2938 108.489 89.1524 112.483C85.8702 114.336 82.4155 115.866 78.8371 117.052C74.7545 118.415 70.5307 119.312 66.2463 119.727C63.97 119.968 61.679 120.041 59.392 119.945Z"
            fill="#6FCADF"
            fill-opacity="0.2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M70 34.6667C70 33.5621 69.1046 32.6667 68 32.6667C66.8954 32.6667 66 33.5621 66 34.6667V38.6667H54V34.6667C54 33.5621 53.1046 32.6667 52 32.6667C50.8954 32.6667 50 33.5621 50 34.6667V38.6815C42.2092 39.0301 36 45.4566 36 53.3333V69.3333C36 77.4335 42.5665 84 50.6667 84H69.3333C77.4335 84 84 77.4335 84 69.3333V53.3333C84 45.4566 77.7908 39.0301 70 38.6815V34.6667ZM50 42.6872C44.64 43.0177 40.351 47.3067 40.0205 52.6667H79.9795C79.649 47.3067 75.36 43.0177 70 42.6872V45.3333C70 46.4379 69.1046 47.3333 68 47.3333C66.8954 47.3333 66 46.4379 66 45.3333V42.6667H54V45.3333C54 46.4379 53.1046 47.3333 52 47.3333C50.8954 47.3333 50 46.4379 50 45.3333V42.6872ZM40 56.6667H80V69.3333C80 75.2244 75.2244 80 69.3333 80H50.6667C44.7756 80 40 75.2244 40 69.3333V56.6667Z"
            fill="#060D14"
          />
        </g>
        <defs>
          <clipPath id="clip0_4514_109">
            <rect width="120" height="120" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default IconNoEventFound;
