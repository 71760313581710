import React from "react";
import Logo from "../../../Images/logo_mds_small.svg";
import Email from "../../Illustrations/Email";
import IcnMEmail from "../../../Images/icn_m_email.svg";
import { Link, useLocation } from "react-router-dom";

const EmailVerified = () => {
  const location = useLocation();
  return (
    <>
      <div className="container-fluid new-check-email">
        <div className="new-login-box-wrapper">
          <div className="header-collaborator header-spacing">
            <Link to="/">
              <img src={Logo} />
            </Link>
          </div>
          <div className="collaborator-info">
            <Email />
            <h2 className="collaborator-h2">Check your email</h2>
            <h6 className="collaborator-h6">
              You're almost done! Check your email to finalize your account
              registration.
            </h6>
            <div className="collaborator-email">
              <img src={IcnMEmail} />
              {location.state}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerified;
