import React from 'react'
import PropTypes from "prop-types"
const DiamondIcon = ({ width = 24, height = 24, iconColor = "#000" }) => {
    return (
        <svg
            xmlns="https://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill={iconColor}
                fillRule="evenodd"
                d="M10.53 20.408a2 2 0 0 0 2.94 0l9.227-9.996a2 2 0 0 0-.056-2.77l-4.055-4.056A2 2 0 0 0 17.172 3H6.828a2 2 0 0 0-1.414.586L1.36 7.64a2 2 0 0 0-.056 2.771l9.227 9.996ZM2.8 9.75l7.7 8.75-4.008-8.75H2.8Zm5.308 0L12 19l3.892-9.25H8.108Zm9.4 0L13.5 18.5l7.7-8.75h-3.692Zm3.62-1.5H17.5L15.937 4.5h.613a2 2 0 0 1 1.414.586L21.13 8.25ZM14.313 4.5H10.09L8.213 8.25h7.662L14.312 4.5ZM6.537 8.25 8.411 4.5H7.45a2 2 0 0 0-1.415.586L2.871 8.25h3.665Z"
                clipRule="evenodd"
            />
        </svg>
    )
}
DiamondIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string
}
export default DiamondIcon