import React from 'react'
import FilterItems from '../../../../Pages/DocumentManagement/Components/FilterItems'
import FilterListItem from '../../../../Pages/DocumentManagement/Components/FilterListItem'

const CategoryList = ({ handleBack, handleSubCategoryFilter, category, selectedCategory, selectedSubCategory, handleCategoryFilter }) => {

  const onCategoryClick = (category) => {
    if (category.subcategory != undefined && category.subcategory.length > 0) {
      handleCategoryFilter(category)
    } else {
      handleCategoryFilter(category)
      handleBack()
    }
  }
  return (
    <>
      <FilterListItem selected={category._id === selectedCategory._id} label={category.name} onClick={() => onCategoryClick(category)} />

      {selectedCategory.name === category.name &&
        selectedCategory.subcategory !== undefined &&
        selectedCategory.subcategory.length > 0 && (
          selectedCategory.subcategory.map((subCat) => (
            <div key={subCat.name} style={{ "marginLeft": "40px" }}>
              <FilterListItem onClick={() => { handleSubCategoryFilter(subCat); handleBack() }} selected={selectedSubCategory._id === subCat._id} label={subCat.name} />
            </div>
          ))
        )}

    </>
  )
}

export default CategoryList