import axios from "axios";
import authHeader from "../Services/auth.header";
import authAdminHeader from './auth.admin.header'
const API_URL = process.env.REACT_APP_API_URL;

// Get
const getNotificationList = (page,limit) => {
   return axios.get(API_URL + `notification/notificationListForUser?page=${page}&limit=${limit}`, { headers: authHeader() })
}

const sentNotificationUserCountList = (id) => {
  return axios.get(API_URL + `notification/sentNotificationUserCountList/${id}`, { headers: authAdminHeader() })
}

const markNotificationRead = (data) => {
  return axios.post(API_URL + 'notification/markNotificationReadOrUnread', data, { headers: authHeader() })
}
 
const deleteNotificationByUser = (data) => {
  return axios.post(API_URL + 'notification/deleteNotificationByUser', data, { headers: authHeader() })
}
 
export default {
    getNotificationList,
     markNotificationRead,
    deleteNotificationByUser,
    sentNotificationUserCountList
}
  