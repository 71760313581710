const custompagechange = false;

const ReduCustomPageChange = (state = custompagechange, action) => {
  switch (action.type) {
    case "SETCUSTOMPAGECHANGE":
      return true;
    case "REMOVECUSTOMPAGECHANGE":
        return false;
    default:
      return state;
  }
};

export default ReduCustomPageChange;