import { useEffect, useState } from "react";
//import images
import white1 from "../../Images/BackendImages/white1.svg";
import white2 from "../../Images/BackendImages/white2.svg";
import white3 from "../../Images/BackendImages/white3.svg";

const AddGrid = ({
  setGrid,
  showgrid,
  setShowaddfield,
  setShowgrid,
  setfieldarr,
  grid,
  settingFieldArrOnGridChange,
}) => {
  const [localgrid, setlocalgrid] = useState(grid);
  // Called on click on add button
  const handleAddButton = (e) => {
    setGrid(localgrid ?? 1);
    settingFieldArrOnGridChange(localgrid);
    setShowgrid(false);
    setShowaddfield(true);
  };
  // Called on click on cancel button
  const handleCancel = () => {
    setfieldarr([]);
    setShowgrid(false);
    setShowaddfield(false);
    setGrid(1);
  };
  // Called when grid selected
  const handleGrid = (e) => {
    
    setlocalgrid(parseInt(e.target.value)) ;
  };
  // useEffect called when grid change or setShowgrid , setShowaddfield called
  useEffect(() => {}, [setShowgrid, setShowaddfield, grid]);

  return (
    <>
      {/* Select grid section */}
      <div>
        <div
          className="modal fade edit-user-details-popup 1"
          id="Addgrid"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content edit-user-model-content">
              <div className="edit-user-details-header">
                <h2>Choose Layout type</h2>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCancel}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="choose-layout-type-main">
                  <div className="choose-layout-type-main-box">
                    <div className="choose-layout-type-main-box-header">
                      <span className="clm-label">1 grid</span>
                      <input
                        type="radio"
                        className="form-check-input"
                        onChange={(e) => {
                          handleGrid(e);
                        }}
                        name="gridcheckbox"
                        value="1"
                        checked={localgrid === 1 ? "checked" : ""}
                      />
                    </div>
                    <div className="choose-layout-type-main-box-img">
                      <img src={white1} />
                    </div>
                  </div>
                  <div className="choose-layout-type-main-box">
                    <div className="choose-layout-type-main-box-header">
                      <span className="clm-label">2 grid</span>
                      <input
                        type="radio"
                        className="form-check-input"
                        onChange={(e) => {
                          handleGrid(e);
                        }}
                        name="gridcheckbox"
                        value="2"
                        checked={localgrid === 2 ? "checked" : ""}
                      />
                    </div>
                    <div className="choose-layout-type-main-box-img">
                      <img src={white2} />
                    </div>
                  </div>
                  <div className="choose-layout-type-main-box">
                    <div className="choose-layout-type-main-box-header">
                      <span className="clm-label">3 grid</span>
                      <input
                        type="radio"
                        className="form-check-input"
                        onChange={(e) => {
                          handleGrid(e);
                        }}
                        name="gridcheckbox"
                        value="3"
                        checked={localgrid === 3 ? "checked" : ""}
                      />
                    </div>
                    <div className="choose-layout-type-main-box-img">
                      <img src={white3} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-user-details-footer cstm-add-button">
                <button
                  type="button"
                  className="btn"
                  data-toggle="modal"
                  data-target="#Addfield"
                  onClick={handleAddButton}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddGrid;
