import { useSelector, useDispatch } from "react-redux";
import Services from "../../../Services/auth.service";
import { fetchsavedpost} from "../../../Action";
import Image1 from "../../../Images/app_logo.png";
import Dotted from "../../../Images/dotted.svg";
const SavePostList = () => {
    const savedpost = useSelector((state) => {
        return state.ReduSavedPost
      })
      const dispatch = useDispatch()  
      const handleSave = (postid) => {
        Services.savepost(postid).then((res) => {
         dispatch(fetchsavedpost())
        }).catch((e) =>{
          console.log(e)
        })
      }
    return (
        savedpost.length > 0 ? (
            <ul>
              {savedpost.map((post) => {
                return (
                  <li>
                    <div className="cstm-sg-list">
                      {post.images.length > 0 ? (
                        <img
                          className="sctm-pt-mn"
                          src={
                            process.env.REACT_APP_AWS_IMG_VID_PATH +
                            post.images[0]
                          }
                        />
                      ) : (
                        <img className="sctm-pt-mn" src={Image1} />
                      )}
                    </div>
                    <div className="cstm-sg-ttl-name">
                      <a className="cstm-sg-ttl-main">
                        {post.description}
                      </a>
                      <span className="cstm-sg-sb">
                        {post.likes.length} likes
                      </span>
                      {/* <span className="cstm-sg-sb">97.2k Views</span> */}
                    </div>
                    <div className="cstm-sg-dotted">
                      <span
                        className="remove-srarred-img"
                        data-toggle="dropdown"
                      >
                        <img src={Dotted} />
                      </span>
                      <div
                        className="dropdown-menu remove-srarred-in"
                        onClick={() => {
                          handleSave(post._id);
                        }}
                      >
                        Remove
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <span className="emptymsg">No Data Found</span>
          )
    )
}

export default SavePostList;