const chatpageloader = false;

const ReduChatPageLoader = (state = chatpageloader, action) => {
  switch (action.type) {
    case "SETCHATPAGELOADER":
      return true;
    case "REMOVECHATPAGELOADER":
      return false;
    case 'LOGOUT':
      return chatpageloader;
    default:
      return state;
  }
};

export default ReduChatPageLoader;