import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import authServiceDocument from "../../../../Services/auth.service.document";
import Loader2 from "../../../../Images/loader2.gif";
const AddCategory = ({ recallApi, id = "addCategory" }) => {
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const dispatch = useDispatch();
  const [createInprocess, setCreateInprocess] = useState(false);
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [addCategory, setAddCategory] = useState("");
  const [allSubCategory, setAllSubCategory] = useState([]);
  const [addErr, setAddErr] = useState("");
  //called when add input change
  const handleAddCategoryChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    setAddCategory(e.target.value);
    if (e.target.value.length > 0) {
      setAddErr("");
    } else {
      setAddErr("Enter category! ");
    }
  };

  //called when category create
  const handleCreateCategory = () => {
    if (addCategory.length > 0) {
      setCreateInprocess(true);
      const addBodyData = {
        name: addCategory,
        subcategory: allSubCategory,
      };
      authServiceDocument
        .addCategory(addBodyData)
        .then((res) => {
          setCreateInprocess(false);
          if (res.data.data.status) {
            setAllSubCategory([]);
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.data.message);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
              document.querySelector("#addCategory .close").click();
            }, 3000);
            handleClearAllStates();
            setAddCategory("");
            recallApi();
          } else {
            handleClearAllStates();
            setShowAlertAdd(true);
            setAlertMsgAdd(res.data.data.message);
            setTimeout(() => {
              setShowAlertAdd(false);
              setAlertMsgAdd("");
            }, 3000);
          }
        })
        .catch((e) => {
          setCreateInprocess(false);
          console.log(e);
        });
      setAddErr("");
    } else {
      setAddErr("Enter category!");
    }
  };

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };
  console.log("add Ceatpryu");
  return (
    <>
      <div
        className="modal fade edit-user-details-popup"
        id={id}
        tabIndex="-1"
        data-backdrop="static"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Add Category </h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addCategory").modal("hide");
                    //handleCloseAddModel(e)
                  }
                }}
                // onClick={handleCloseAddModel}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Category name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Name your category"
                    name="category"
                    onChange={handleAddCategoryChange}
                    value={addCategory}
                  />
                </div>
                <span className="error">{addErr}</span>
              </div>
              <div className="form-group">
                <div className="form-group-label">Subcategory name</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Name your subcategory"
                    name="subcategory"
                    id="subcategory0"
                    data-index="0"
                    onKeyUp={(e) => {
                      dispatch({ type: "SETMODALINPROCESS" });

                      if (
                        e.key === "Enter" &&
                        e.target.value.length > 0 &&
                        !allSubCategory.includes(e.target.value.trim())
                      ) {
                        setAllSubCategory([
                          ...allSubCategory,
                          e.target.value.trim(),
                        ]);
                        e.target.value = "";
                      }
                    }}
                  />
                  <span class="text-limitaion">
                    Press enter to add a new subcategory (The name must be
                    unique)
                  </span>
                  {allSubCategory.map((subcat, index) => {
                    return (
                      <span className="topic-label-in">
                        <b>{subcat}</b>
                        <button
                          onClick={() => {
                            setAllSubCategory([
                              ...allSubCategory.filter((cat, i) => {
                                if (i !== index) return cat;
                              }),
                            ]);
                          }}
                        >
                          ×
                        </button>
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreateCategory}
                  disabled={createInprocess}
                >
                  Add category
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
