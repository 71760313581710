import React from "react";
// Icons
import SmallPlayIcon from "../../../../components/Icons/VideoPlayerIcons/VideoPlayersIconsSmall/SmallPlayIcon";
// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/PlayAttachment.css";

const PlayAttachment = () => {
  return (
    <>
      <div className="playAttachment-container flex">
        <SmallPlayIcon iconColor="var(--color-icon-static-white)" />
      </div>
    </>
  );
};

export default PlayAttachment;
