import React from "react";
import PropTypes from "prop-types";
import Typography from "../Typography/Typography";
import CrossIcon from "../Icons/GeneralIcons/CrossIcon";
import Avtar from "../Avtar/Avtar";
import Button from "../Button/Button";

import "./Styles/ChipMember.css";
import "../../Styles/Theme/Theme.css";
import ChipCross from "./ChipCross";

function ChipMember({
  chipsName = "label",
  handleClick,
  type = "photo",
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",

  // Letter
  letter = "W",
  hasCrossButton = true
}) {
  return (
    <div className="chipmember-container flex items-center">
      <Avtar
        size={24}
        src={src}
        letter={letter}
        type={type}
        hasStatus={false}
      />
      <div className="chipmember-content">
        <span>{chipsName}</span>
      </div>
    {hasCrossButton &&
      <ChipCross handleClick={handleClick} />}
    </div>
  );
}

// define types to all props
ChipMember.propTypes = {
  chipsName: PropTypes.string,
  handleClick: PropTypes.func,
  //Avtar
  type: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds"]),
  src: PropTypes.string,
  isRounded: PropTypes.bool,

  // Letter
  letter: PropTypes.string,
  //  Avtar type Logo
  logoSrc: PropTypes.string,
  //   Icon
  iconColor: PropTypes.string,
  icon: PropTypes.element,
};

export default ChipMember;
