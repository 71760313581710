import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import Loader from "../../../Images/loader.gif";

const PurchaseTicketCountModal = ({
  setShowPurchaseTicketModal,
  purchasedTicketList,
  loader,
}) => {
  const ticketColumns = [
    { id: "number", label: "No." },
    { id: "purchasedTicket", label: "Purchased Tickets" },
    { id: "quantity", label: "Quantity" },
  ];

  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(20);

  // Pagination onChange function
  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage);
  };
  return (
    <>
      <div className="desk-globle-model-box globle-model-box partner-listing-popup-only">
        <div className="desk-globle-model-box-inner">
          <div className="modal-content">
            <div class="cstm-replace-pp">
              <span
                class="cstm-rpp-close-btn"
                onClick={() => {
                  setShowPurchaseTicketModal(false);
                  document.body.classList.remove("modal-open");
                }}
              >
                ×
              </span>
              <div class="cstm-rpp-title">List of purchased tickets</div>
              <div className="cstm-cstm-viewlist">
                <Paper className="uic-tb-mn ">
                  <TableContainer className=" uic-table-main ">
                    <Table className="">
                      <TableHead className="uic-table-th">
                        <TableRow>
                          {ticketColumns.map((column) => (
                            <TableCell className="uic-th-td" key={column.id}>
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody className="uic-table-td">
                        {loader ? (
                          <div className="cstm-data-load">
                            <img src={Loader} />
                          </div>
                        ) : purchasedTicketList?.length > 0 &&
                          purchasedTicketList !== undefined ? (
                          purchasedTicketList
                            .slice(
                              userPage * userRowsPerPage,
                              userPage * userRowsPerPage + userRowsPerPage
                            )
                            .map((purchasedTicket, index) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={purchasedTicket.id}
                                >
                                  <TableCell className="uic-th-td">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {purchasedTicket.ticket}
                                  </TableCell>
                                  <TableCell className="uic-th-td">
                                    {purchasedTicket.purchaseQuantity}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                        ) : (
                          <span className="no-data-found-pl">
                            No data found
                          </span>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[]}
                    component="div"
                    count={
                      purchasedTicketList !== undefined
                        ? purchasedTicketList.length
                        : 0
                    }
                    rowsPerPage={userRowsPerPage}
                    page={userPage}
                    onPageChange={handleChangeUserPage}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchaseTicketCountModal;
