import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import authServiceDocument from "../../../../Services/auth.service.document";
import Loader from "../../../../Images/loader.gif";
import moment from "moment";
import DocumentModal from "../DocumentModals/DocumentModal";
import TickIcon from "../../../Icons/GeneralIcons/TickIcon";
import BlockIcon from "../../../Icons/GeneralIcons/BlockIcon";
import ReactDatePicker from "react-datepicker";
import arrowImg from "../../../../Images/arow-left-right.png";
// import $ from "jquery"
//columns for table
const columns = [
  { id: "userName", label: "User name", width: 65 },
  { id: "totalDownloads", label: "No. of downloads", width: 15 },
  { id: "totalDocuments", label: "No. of documents", width: 15 },
  { id: "action", label: "Block status", width: 15 },
];

function createData(name, docCount, action) {
  return {
    name,
    docCount,
    action,
  };
}

const userColumns = [
  { id: "fileName", label: "Document name", width: 72 },
  { id: "downloadCount", label: "No. of downloads", width: 28 },

];

const DownloadManagement = () => {
  const [dataLoader, setDataLoader] = useState(false);
  const [allDownloadDocuments, setAllDownloadDocuments] = useState([]);
  const [allRowDownloadDocuments, setAllRowDownloadDocuments] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalData, setTotalData] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [actualsearch, setActualsearch] = useState("");
  const [listIndex, setListIndex] = useState(-1);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [userRows, setUserRow] = useState([]);
  const [viewUsers, setViewUsers] = useState([]);
  const [userViewLoader, setUserViewLoader] = useState(false);
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(20);
  const [selected, setSelected] = useState([]); // Selected rows
  const [selectRow, setSelectRow] = useState([]);
  const [selectError, setSelectError] = useState("");
  const [userId, setUserId] = useState("");
  const [userRowsData, setUserRowsData] = useState([]);
  const [filterType, setFilterType] = useState("");
  const [showCustomFilter, setShowCustomFilter] = useState(false);
  const [filterHeader, setFilterHeader] = useState("Lifetime");
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "days").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [exportFileName, setExportFileName] = useState("userdownloadDocuments.xlsx");
  const [isChangeDate, setIsChangeDate] = useState(true);


  const fromDateRef = useRef(fromDate);
  const toDateRef = useRef(toDate);
  //called when page loads initially
  useEffect(() => {
    setDataLoader(true);
    getDownloadDocumentsList(page, rowsPerPage);
  }, []);

  //get category list
  const getDownloadDocumentsList = (page, rowsPerPage, searchText, filtertype, fromdate, todate) => {
    let query = `?page=${page + 1}&limit=${rowsPerPage}`;
    query +=
      filtertype !== undefined && filtertype !== null && filtertype !== ""
        ? `&filterType=${filtertype}`
        : "";
    query +=
      filtertype !== undefined && filtertype !== null && filtertype === "custom"
        ? `&filterTypeFromDate=${fromdate}&filterTypeToDate=${todate}`
        : "";
    authServiceDocument
      .downloadsDocumentsUserList(query)
      .then((res) => {
        if (res.data.data.status) {
          setIsChangeDate(false);
          setDataLoader(false);
          let docData = res.data.data.data.Docs;
          setTotalData(res.data.data.data.totalDocs)

          setAllRowDownloadDocuments(docData);
          setRows(docData);
          setAllDownloadDocuments(docData);
        } else {
          setDataLoader(false);
          setRows([]);
          setAllDownloadDocuments([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  //search functionality
  const handleSearch = (text) => {
    setSearchText(text);
    setSuggestionBox(false);
    if (allDownloadDocuments !== undefined && allDownloadDocuments.length > 0) {
      var data = allDownloadDocuments.filter((rowDoc) => {
        if (rowDoc.userName.toLowerCase().includes(text.toLowerCase())) {
          return rowDoc;
        }
      });

      setRows(data);
    }
  };

  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getDownloadDocumentsList(newPage, rowsPerPage, searchText, filterType, fromDate, toDate);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //called when click on document count
  const handleDocumentCountClick = (id) => {
    setUserId(id);
    const docs = rows.filter((docs) => docs._id.toString() === id)[0].documents;
    const name = `${rows.filter((docs) => docs._id.toString() === id)[0].userName}_document_downloads.xlsx`
    setExportFileName(name)
    setUserRowsData(docs);
    setViewModalOpen(true);
    document.body.classList.add("modal-open");
  };

  // Table data shorting function
  const handleSort = (col, direc) => {
    const sortedResult = [...allDownloadDocuments];
    if (direc === "asc") {
      sortedResult.sort((firstItem, secondItem) => {
        const itemA = firstItem[col];
        const itemB = secondItem[col];
        return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
      });
    } else if (direc === "desc") {
      sortedResult.sort((firstItem, secondItem) => {
        const itemA = firstItem[col];
        const itemB = secondItem[col];
        return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
      });
    }

    setRows(sortedResult);
  };

  //update status of user (block or unblock) 
  const updateBlockStatus = async (e, id) => {
    const body = {
      status: e.target.value,
      userId: id,
    };
    setRows((prevData) =>
      prevData.map((item) => {
        if (item._id === id) {
          if (item.blockStatus) {
            return { ...item, blockStatus: false };
          } else {
            return { ...item, blockStatus: true };
          }
        }
        return item;
      })
    );
    try {

      const updateStatusRes =
        await authServiceDocument.updateUserDocumentDownloadBlockStatus(body);

    } catch (error) { }
  };

  //close modal popup on keypress of esc
  const onEscCloseModal = (e) => {
    if (viewModalOpen && e.key === "Escape") {
      setViewModalOpen(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", onEscCloseModal);
    }
  };
  document.addEventListener("keydown", onEscCloseModal);


  //called when filter change
  const handleOnChangeFilterType = (e) => {
    setFilterType(e.target.value);
    const filter = e.target.value;
    setShowCustomFilter(false);
    let fromDateTime = "";
    let toDateTime = "";

    if (filter === "first24hrs") {
      var fromdate = moment().subtract(24, "hours").format("DD MMM HH:mm");
      var todate = moment().format("DD MMM HH:mm");

      setFilterHeader(fromdate + "-" + todate);
    } else {

      var addFilterCount = 0;
      if (filter === "past7days") {
        addFilterCount = 6;
      } else if (filter === "past28days") {
        addFilterCount = 27;
      } else if (filter === "past90days") {
        addFilterCount = 89;
      } else if (filter === "past365days") {
        addFilterCount = 364;
      }

      if (filter === "") {
        setFilterHeader("Lifetime");
      } else if (filter === "past365days") {
        var fromdate = moment()
          .subtract(addFilterCount, "days")
          .format("DD MMM, YYYY");
        var todate = moment().format("DD MMM, YYYY");
        setFilterHeader(fromdate + "-" + todate);
      } else if (filter === "custom") {
        setDataLoader(true);
        fromDateTime = moment(fromDate).format("YYYY-MM-DD");
        toDateTime = moment(toDate).format("YYYY-MM-DD");
        var fromdate = moment(fromDate).format("DD MMM, YYYY");
        var todate = moment(toDate).format("DD MMM, YYYY");
        getDownloadDocumentsList(0, rowsPerPage, searchText, filter, fromdate, todate)
        setFilterHeader(fromdate + "-" + todate);
        setShowCustomFilter(true);
      } else {
        var fromdate = moment()
          .subtract(addFilterCount, "days")
          .format("DD MMM");
        var todate = moment().format("DD MMM");
        setFilterHeader(fromdate + "-" + todate);
      }
    }
    if (filter !== "custom") {
      setDataLoader(true);
      getDownloadDocumentsList(0, rowsPerPage, searchText, filter, fromdate, todate)
    }
  };

  //check date changed or not
  const handleDateChange = (date, setDateFunction, dateRef) => {
    setIsChangeDate(false)
    if (date !== dateRef.current) {
      setDateFunction(date);
      setIsChangeDate(true)
    }
  };

  //called when calender close
  const handleCalenderClose = () => {
    if (filterType === "custom") {
      var fromdate = moment(fromDate).format("DD MMM, YYYY");
      var todate = moment(toDate).format("DD MMM, YYYY");
      setFilterHeader(fromdate + "-" + todate);
    }
    if (filterType === "custom") {
      if (
        moment(new Date(fromDate)).format("YYYY-MM-DD") >=
        moment(new Date(toDate)).format("YYYY-MM-DD")
      ) {
        setShowAlert(true);
        setAlertMsg("From date should be less than To date!");
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 3000);
      } else {
        if (fromDate !== "" && toDate !== "" && isChangeDate) {
          setDataLoader(true);
          getDownloadDocumentsList(0, rowsPerPage, searchText, filterType, fromdate, todate)
        }
      }
    }
  };


  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title cstm-uic-doc-title">
            <h1>User Downloads</h1>
            {/*             
            <div className="uic-sub-title">Users listed have downloaded either a single document or multiple documents more than 5 times</div> */}
          </div>


          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                value={searchText}
                placeholder="Search"
                className="search-user-input"
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return user.userName
                        .toLowerCase()
                        .includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />
              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
            </div>
            <div className="uic-fl-btn sort-by-mn">

              <div className="uic-fl-btn sort-by-mn lifetime-only">
                <div className="cstm-select-ap">
                  <select
                    value={filterType}
                    onChange={handleOnChangeFilterType}
                  >
                    <option value="">Lifetime</option>
                    <option value="first24hrs">Last 24 h</option>
                    <option value="past7days">Last 7 Days</option>
                    <option value="past28days">Last 30 Days</option>
                    <option value="past90days">Last 90 Days</option>
                    <option value="past365days">Last 365 days</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>
                {filterType === "custom" && showCustomFilter ? (
                  <div className="cstm-select-ap-drop documenlist-datepicker cstm-datepicker-field">
                    <ReactDatePicker
                      showIcon
                      selected={fromDate}
                      onChange={(date) => handleDateChange(date, setFromDate, fromDateRef)}
                      onCalendarClose={handleCalenderClose}
                    />
                    <img src={arrowImg} className="cstm-arrow-left-right" />
                    <ReactDatePicker
                      showIcon
                      selected={toDate}
                      onChange={(date) => handleDateChange(date, setToDate, toDateRef)}
                      onCalendarClose={handleCalenderClose}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {showAlert && <div className="alert alert-info">{alertMsg}</div>}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table min-w-auto">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={`uic-th-td w-${column.width}`}
                      >
                        <span className="custom-sorting">
                          <i
                            className="fa fa-angle-down"
                            onClick={(e) => {
                              handleSort(column.id, "asc");
                            }}
                          ></i>
                          <i
                            className="fa fa-angle-up"
                            onClick={(e) => {
                              handleSort(column.id, "desc");
                            }}
                          ></i>
                        </span>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {dataLoader ? (
                  <div className="cstm-data-load">
                    <img src={Loader} />{" "}
                  </div>
                ) : rows && rows.length > 0 ? (
                  <TableBody className="uic-table-td">
                    {rows.map((row, index) => {
                      return (
                        <TableRow className="uic-th-td w-20">
                          {columns.map((column) => {
                            const value = row[column.id];
                            return column.id === "totalDownloads" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-60"
                              >
                                <a
                                  className="cstm-view-users-count"
                                  onClick={() =>
                                    handleDocumentCountClick(row._id)
                                  }
                                >
                                  {value}
                                </a>
                              </TableCell>
                            ) : column.id === "totalDocuments" ? (
                              <TableCell
                                key={column.id}
                                className="uic-th-td w-60"
                              >

                                {row.documents.length}

                              </TableCell>
                            ) : column.id === "action" ? (
                              <TableCell className="uic-th-td">
                                <div className="document-status-select">
                                  {row.blockStatus ? (
                                    <a>
                                      <BlockIcon iconColor="red" />
                                    </a>
                                  ) : (
                                    <a>
                                      <TickIcon />
                                    </a>
                                  )}
                                  <select
                                    value={row.blockStatus}
                                    className="status-select-only"
                                    onChange={(e) =>
                                      updateBlockStatus(e, row._id)
                                    }
                                  >
                                    <option value={true}>
                                      Restricted
                                    </option>
                                    <option value={false}>
                                      Allowed
                                    </option>
                                  </select>
                                </div>
                              </TableCell>
                            ) :
                              (
                                <TableCell
                                  key={column.id}
                                  className="uic-th-td w-60"
                                >
                                  {value}
                                </TableCell>
                              );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                ) : (
                  <span className="no-data-found">No data found</span>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={totalData}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>

      {viewModalOpen && (
        <DocumentModal
          setViewModalOpen={setViewModalOpen}
          userRowsData={userRowsData}
          setUserRowsData={setUserRowsData}
          userColumns={userColumns}
          userId={userId}
          exportFileName={exportFileName}
        />
      )}
    </>
  );
};

export default DownloadManagement;
