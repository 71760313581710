import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import authReport from "../../Services/auth.service.report";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Loader from "../../Images/loader.gif";
import AlertComponent from "../../Hooks/AlertComponent";
import playVideo from "../../Images/play-video.svg";
import AdminSidebar from "../AdminSidebar";
import {
  replaceIdWithNameUsingUnformattedList,
} from "../../Pages/ChatModule/CommonFunctions/taggedUserIdReplace";
import imgbigdelete from "../../Images/BackendImages/delete-icon.svg";
import blocked from "../../Images/BackendImages/blocked.svg";
import messageDelete from "../../Images/Message-delete.svg";
import reportDelete from "../../Images/Report-delete.svg";
function createData(message, sender, reportedBy, action) {
  return {
    message,
    sender,
    reportedBy,
    action,
  };
}
const AdminMessageReport = () => {
  const [rows, setRow] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [allReports, setallReports] = useState([]);
  const [tableAlertMsg, setTableAlertMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [displayMedia, setDisplayMedia] = useState({});
  const [sidebarmenu, setSidebarmenu] = useState(false);

  //get all report list
  const getAllReportApi = () => {
    authReport
      .getMessageReportList()
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          var reportList = res.data.data;
          var tbl = [];
          for (var i = 0; i < reportList.length; i++) {
            tbl[i] = createData(reportList[i].messageId, reportList[i].messageId ? reportList[i].messageId.sender?.firstname : "", reportList[i].reportedBy ? reportList[i].reportedBy.length : 0, reportList[i]._id);
          }
          setRow(tbl);
          setallReports(tbl);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    setLoader(true);
    getAllReportApi("");
  }, []);

  //handle delete news
  const handleDelete = () => {
    authReport
      .removeReport(deleteId)
      .then((res) => {
        if (res.data.status) {
          var tbl = [];
          var n = 0;
          for (var i = 0; i < allReports.length; i++) {
            if (allReports[i].action !== deleteId) {
              tbl[n] = createData(allReports[i].message, allReports[i].sender, allReports[i].reportedBy, allReports[i].action);
              n++;
            }
          }
          setallReports(tbl);
          setRow(tbl);
        }

        setTableAlertMsg(res.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  //handle block user
  const handleBlock = () => {
    authReport
      .blockMessageSender(deleteId)
      .then((res) => {
        if (res.data.status) {
          var tbl = [];
          var n = 0;
          for (var i = 0; i < allReports.length; i++) {
            if (allReports[i].action !== deleteId) {
              tbl[n] = createData(allReports[i].message, allReports[i].sender, allReports[i].reportedBy, allReports[i].action);
              n++;
            }
          }
          setallReports(tbl);
          setRow(tbl);
        }

        setTableAlertMsg(res.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleDeleteMessage = () => {
    authReport
      .deleteReportedMessage(deleteId)
      .then((res) => {
        if (res.data.status) {
          var tbl = [];
          var n = 0;
          for (var i = 0; i < allReports.length; i++) {
            if (allReports[i].action !== deleteId) {
              tbl[n] = createData(allReports[i].message, allReports[i].sender, allReports[i].reportedBy, allReports[i].action);
              n++;
            }
          }
          setallReports(tbl);
          setRow(tbl);
        }

        setTableAlertMsg(res.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setTimeout(() => {
      setTableAlertMsg("");
    }, 3000);
  }, [tableAlertMsg]);

  //columns array
  const columns = [
    { id: "message", label: "Message", width: 40 },
    { id: "sender", label: "Sender", width: 7 },
    { id: "reportedBy", label: "No. of Reports", width: 7 },
    { id: "action", label: "Actions", width: 7 },
  ];

  const updateWindowSize = () => {
    if (window.innerWidth > 992) {
      setSidebarmenu(false)
      document.body.classList.remove('overflow-hidden')
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);
    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, []);

  const sidebarHandleClick = (ele) => {
    setSidebarmenu(ele);
    if (window.innerWidth < 992) {
      if (sidebarmenu) {
        document.body.classList.remove('overflow-hidden')
      } else {
        document.body.classList.add('overflow-hidden')
      }
    }
  }
  return (
    <>
      <div className="cstm-all-admin">
        {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
        <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
        <div className="content-main">
          <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <Link className={`btn-link collapsed  sd-active`} onClick={() => { sidebarHandleClick(false) }}>
                    Message Reports
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="users-info-content">
            <div className="users-info-content-main">
              <div className="uic-title">
                <h1>Message Reports</h1>
              </div>
              {tableAlertMsg && (
                <div className="alert alert-info">{tableAlertMsg}</div>
              )}
              <Paper className="uic-tb-mn">
                <TableContainer className="uic-table-main">
                  <Table className="uic-table">
                    <TableHead className="uic-table-th">
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className="uic-th-td w-10"
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className="uic-table-td">
                      {loader ? (
                        <div className="cstm-data-load">
                          <img src={Loader} />{" "}
                        </div>
                      ) : rows.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).length > 0 ? (
                        rows
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                                className="uic-th-td w-20"
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return column.id === "message" ? (
                                    <TableCell
                                      key={column.id}
                                      className="uic-th-td w-17"
                                    >
                                      <p>{value?.message ? replaceIdWithNameUsingUnformattedList(
                                        value.message,
                                        value.taggedUserId,
                                        true
                                      ) : ""}</p>
                                      {value &&
                                        value.images &&
                                        value.images.length > 0 ? (
                                        value.images.map((img) => {
                                          return <img src={img.url} className="report-message-thumbnail" data-toggle="modal" data-target="#singleMediaDisplay"
                                            onClick={() => {
                                              setDisplayMedia({ type: "image", url: img.url });
                                            }} />;
                                        })
                                      ) : (
                                        <></>
                                      )}
                                      {value &&
                                        value.videos &&
                                        value.videos.length > 0 ? (
                                        value.videos.map((video) => {
                                          return <img src={video.thumbnail} />;
                                        })
                                      ) : (
                                        <></>
                                      )}
                                      {value &&
                                        value.documents &&
                                        value.documents.length > 0 ? (
                                        value.documents.map((med) => {
                                          if (med.key) {
                                            var media_name = med.key
                                              .split("-GRPCHATUNIQUE-")
                                              .pop()
                                              .split("?")[0];
                                            return (
                                              <a onClick={() => {
                                                saveAs(med.key, media_name);
                                              }}>{media_name}</a>
                                            );
                                          } else {
                                            return <></>
                                          }

                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </TableCell>
                                  ) : column.id === "sender" || column.id === "reportedBy" ? (
                                    <TableCell
                                      key={column.id}
                                      className="uic-th-td w-17"
                                    >
                                      <p>{value}</p>
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      key={column.id}
                                      className="uic-th-td uic-table-icon w-17"
                                    >
                                      <a
                                        data-toggle="modal"
                                        data-target="#DeleteMessage"
                                        onClick={() => {
                                          setDeleteId(value);
                                        }}
                                      >
                                        <img src={messageDelete} />
                                      </a>
                                      <a
                                        data-toggle="modal"
                                        data-target="#DeleteFromReport"
                                        onClick={() => {
                                          setDeleteId(value);
                                        }}
                                      >
                                        <img src={reportDelete} />
                                      </a>
                                      <a
                                        data-toggle="modal"
                                        data-target="#BlockUser"
                                        onClick={() => {
                                          setDeleteId(value);
                                        }}
                                      >
                                        <img src={blocked} />
                                      </a>

                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })
                      ) : (
                        <span className="no-data-found">No data found</span>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="uic-pagination"
                  rowsPerPageOptions={[20, 50, 100, 200]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </div>
          </div>
          {/* Modal Delete */}
          <div
            className="modal fade msg-popup"
            id="DeleteFromReport"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
                <div className="msg-icon">
                  <img src={imgbigdelete} />
                </div>
                <h1>Are you sure you want to delete this report?</h1>
                <div className="row">
                  <div className="col-6">
                    <span className="msg-btn">
                      <button
                        type="button"
                        className="btn btn-red"
                        onClick={handleDelete}
                        data-dismiss="modal"
                      >
                        Delete
                      </button>
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="msg-btn">
                      <button type="button" className="btn2" data-dismiss="modal">
                        Discard
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade msg-popup"
            id="BlockUser"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
                <div className="msg-icon">
                  <img src={blocked} />
                </div>
                <h1>Are you sure you want to block the sender of the message?</h1>
                <div className="row">
                  <div className="col-6">
                    <span className="msg-btn">
                      <button
                        type="button"
                        className="btn btn-red"
                        onClick={handleBlock}
                        data-dismiss="modal"
                      >
                        Block
                      </button>
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="msg-btn">
                      <button type="button" className="btn2" data-dismiss="modal">
                        Discard
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade msg-popup"
            id="DeleteMessage"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
                <div className="msg-icon">
                  <img src={imgbigdelete} />
                </div>
                <h1>Are you sure you want to delete the message?</h1>
                <div className="row">
                  <div className="col-6">
                    <span className="msg-btn">
                      <button
                        type="button"
                        className="btn btn-red"
                        onClick={handleDeleteMessage}
                        data-dismiss="modal"
                      >
                        Delete
                      </button>
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="msg-btn">
                      <button type="button" className="btn2" data-dismiss="modal">
                        Discard
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade message-report-media-popup"
            id="singleMediaDisplay"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </button>
                {displayMedia.type && displayMedia.type === "video" ? <video src={displayMedia.url} controls={true}> </video> : displayMedia.type === "image" ? <img src={displayMedia.url} /> : <></>}

              </div></div></div>

          <AlertComponent />
        </div>
      </div>
    </>
  );
};
export default AdminMessageReport;
