const alltopics = [];

const ReduTopics = (state = alltopics, action) => {
  switch (action.type) {
    case "ADDTOPICS":
      return [...alltopics, action.payload];
    case "FETCHTOPICS":
      console.log(action.payload)
      return action.payload;
    default:
      return state;
  }
};

export default ReduTopics;
