import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../../Services/auth.service1";
import videoimg from "../../Images/nothumbnail.png";
import Union from "../../Images/Union.svg";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "chartjs-adapter-moment";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Statistic.css";
import loading from "../../Images/loader.gif";
import arrowimg from "../../Images/arow-left-right.png";
import arrowup from "../../Images/Arrow-Up.png";
import Graph from "./Graph";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import exportImg from "../../Images/exportimg.svg";
import { Checkbox } from "@mui/material";
import ExportSelect from "../Admin/PartnerManagement/PartnerModals/ExportSelect";

const columns = [
  { id: "srno", label: "No." },
  { id: "id", label: "Videos" },
  { id: "viewscount", label: "Views" },
  { id: "uniqueviewerscount", label: "Unique viewers" },
  { id: "commentscount", label: "Comments" },
  { id: "likescount", label: "Likes" },
  { id: "disLikescount", label: "Dislikes" },
  { id: "createdAt", label: "createdAt" },
];

const userViewcolumns = [
  { id: "srno", label: "No." },
  { id: "watchedDate", label: "Watched date" },
  { id: "watchedTime", label: "Watched time" },
];

const user_columns = [
  { id: "firstname", label: "First Name" },
  { id: "lastname", label: "Last Name" },
  { id: "email", label: "Email" },
  { id: "webViews", label: "No. of web views" },
  { id: "mobileViews", label: "No. of mobile views" },
  { id: "liked", label: "Like Date" },
  { id: "disliked", label: "Dislike Date" },
  { id: "commentdate", label: "Comment Date" },
];

function createData(
  srno,
  id,
  title,
  thumbnail,
  viewscount,
  uniqueviewerscount,
  commentscount,
  likescount,
  disLikescount,
  createdAt
) {
  return {
    srno,
    id,
    title,
    thumbnail,
    viewscount,
    uniqueviewerscount,
    commentscount,
    likescount,
    disLikescount,
    createdAt,
  };
}

function createUsers(
  srno,
  id,
  firstname,
  lastname,
  email,
  webViews,
  mobileViews,
  liked,
  disliked,
  commentdate
) {
  return {
    srno,
    id,
    firstname,
    lastname,
    email,
    webViews,
    mobileViews,
    liked,
    disliked,
    commentdate,
  };
}

function createViewUsers(srno, watchedDate, watchedTime) {
  return {
    srno,
    watchedDate,
    watchedTime,
  };
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend
);
const VideoStatistic = () => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const history = useNavigate();
  const [filterType, setFilterType] = useState("lifetime");
  const [field, setField] = useState("views");
  const [videoCount, setVideoCount] = useState(0);
  const [statisticData, setStatisticData] = useState([]);
  const [statisticVideos, setStatisticVideos] = useState([]);
  const [viewsCount, setViewsCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [likesCount, setLikesCount] = useState(0);
  const [disLikescount, setDislikeCount] = useState(0);
  const [uniqueViewersCount, setUniqueViewersCount] = useState(0);
  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState({});
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [tblAllVideos, setTblAllVideos] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterHeader, setFilterHeader] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [noVideosFound, setNoVideosFound] = useState(false);
  const [noChartDataFound, setNoChartDataFound] = useState(false);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(1, "days").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  const [dataLoader, setDataLoader] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortCol, setSortCol] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showCustomFilter, setShowCustomFilter] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [userRows, setUserRow] = useState([]);
  const [userPage, setUserPage] = useState(0);
  const [userRowsPerPage, setUserRowsPerPage] = useState(10);
  const [videoUsers, setVideoUsers] = useState([]);
  const [tblAllVideoUsers, setTblAllVideoUsers] = useState([]);
  const [startingView, setStartingView] = useState(0);
  const [statisticsOptions, setStatisticsOptions] = useState(null);
  const [columnsToUse, setColumnsToUse] = useState(userViewcolumns);
  const [singleUserData, setSingleUserData] = useState([]);
  const [singleUserRows, setSingleUserRows] = useState([]);
  const [singleUserPage, setSingleUserPage] = useState(0);
  const [singleUserRowsPerPage, setSingleUserRowsPerPage] = useState(10);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [singleUserViewModal, setSingleUserViewModal] = useState(false);
  const [singleUserName, setSingleUserName] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectRow, setSelectRow] = useState([]);
  const [selectError, setSelectError] = useState("");
  const [error, setError] = useState(false);
  const [isChangeDate, setIsChangeDate] = useState(true);

  const fromDateRef = useRef(fromDate);
  const toDateRef = useRef(toDate);
  //calls when page loads
  useEffect(() => {
    let startingViewCount = 0;
    setNoDataFound(false);
    setNoChartDataFound(false);
    setNoVideosFound(false);
    setChartData(null);
    setChartOptions(null);
    const filter = {
      filtertype: filterType,
      field: field,
      fromdate: moment(fromDate).format("YYYY-MM-DD"),
      todate: moment(toDate).format("YYYY-MM-DD"),
    };

    setDataLoader(true);
    Services.statisticdata_videocount()
      .then((res) => {
        if (res.data.status) {
          setVideoCount(res.data.data);
        }
      })
      .catch((e) => {
        setDataLoader(false);
        return false;
      });
    Services.statisticfieldcount(filter)
      .then((res) => {
        if (res.data.status) {
          var fieldsData = res.data.data;
          setViewsCount(fieldsData[0].viewscount);
          setCommentsCount(fieldsData[0].commentscount);
          setLikesCount(fieldsData[0].likescount);
          setDislikeCount(fieldsData[0].disLikescount);
          setUniqueViewersCount(fieldsData[0].uniqueViewersCount);
          let nodatafound_ = false;
          if (field == "views")
            fieldsData[0].viewscount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "comments")
            fieldsData[0].commentscount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "likes")
            fieldsData[0].likescount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "dislikes")
            fieldsData[0].disLikescount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "uniqueviewers")
            fieldsData[0].uniqueViewersCount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);

          setNoDataFound(nodatafound_);
          if (field == "views" && !nodatafound_) {
            Services.statisticvideolist(filter)
              .then((res) => {
                if (res.data.status) {
                  var data = res.data.data;

                  setStatisticVideos(data);
                  setDataLoader(false);
                } else {
                  setDataLoader(false);
                }
              })
              .catch((e) => {
                setDataLoader(false);
                return false;
              });
          } else {
            setDataLoader(false);
          }
        }
      })
      .catch((e) => {
        setDataLoader(false);
        return false;
      });
    if (filterType === "first24hrs") {
      var fromdate = moment().subtract(24, "hours").format("DD MMM HH:mm");
      var todate = moment().format("DD MMM HH:mm:SS");
      setFilterHeader(fromdate + "-" + todate);
    } else {
      setFilterHeader("Lifetime");
    }
  }, []);
  //calls when filter and tab change
  useEffect(() => {
    setColumnsToUse(field === "views" ? userViewcolumns : user_columns);
    let startingViewCount = 0;
    const filter = {
      filtertype: filterType,
      field: field,
      fromdate: moment(fromDate).format("YYYY-MM-DD"),
      todate: moment(toDate).format("YYYY-MM-DD"),
    };
    setNoDataFound(false);
    setChartData(null);
    setChartOptions(null);
    setStatisticVideos([]);
    setPage(0);

    if (filterType !== "custom") {
      let fromDateTime = "";
      let toDateTime = "";
      if (filterType === "first24hrs") {
        fromDateTime = moment().subtract(24, "hours").subtract(-1);
        toDateTime = moment().add(1, "hours");

        var fromdate = moment().subtract(24, "hours").format("DD MMM HH:mm");
        var todate = moment().format("DD MMM HH:mm");

        setFilterHeader(fromdate + "-" + todate);
      } else {
        var addFilterCount = 0;
        if (filterType === "past7days") {
          addFilterCount = 6;
        } else if (filterType === "past28days") {
          addFilterCount = 27;
        } else if (filterType === "past90days") {
          addFilterCount = 89;
        } else if (filterType === "past365days") {
          addFilterCount = 364;
        }
        if (filterType === "lifetime") {
          setFilterHeader("Lifetime");
        } else if (filterType === "past365days") {
          fromDateTime = moment().subtract(addFilterCount + 1, "days");
          toDateTime = moment().add(1, "days");

          var fromdate = moment()
            .subtract(addFilterCount, "days")
            .format("DD MMM, YYYY");
          var todate = moment().format("DD MMM, YYYY");
          setFilterHeader(fromdate + "-" + todate);
        } else if (filterType === "custom") {
          fromDateTime = moment(fromDate).subtract(1, "days");
          toDateTime = moment(toDate).add(1, "days");

          var fromdate = moment(fromDate).format("DD MMM, YYYY");
          var todate = moment(toDate).format("DD MMM, YYYY");

          setFilterHeader(fromdate + "-" + todate);
        } else {
          fromDateTime = moment().subtract(addFilterCount + 1, "days");
          toDateTime = moment().add(1, "days");

          var fromdate = fromDateTime.format("DD MMM");
          var todate = toDateTime.format("DD MMM, YYYY");

          var fromdate = moment()
            .subtract(addFilterCount, "days")
            .format("DD MMM");
          var todate = moment().format("DD MMM");

          setFilterHeader(fromdate + "-" + todate);
        }
      }
      setShowCustomFilter(false);

      if (filterType === "lifetime") {
        setFilterHeader("Lifetime");
      }
      setDataLoader(true);
      Services.statisticfieldcount(filter)
        .then((res) => {
          var fieldsData = res.data.data;
          setViewsCount(fieldsData[0].viewscount);
          setCommentsCount(fieldsData[0].commentscount);
          setLikesCount(fieldsData[0].likescount);
          setDislikeCount(fieldsData[0].disLikescount);
          setUniqueViewersCount(fieldsData[0].uniqueviewerscount);
          let nodatafound_ = false;
          if (field == "views")
            fieldsData[0].viewscount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "comments")
            fieldsData[0].commentscount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "likes")
            fieldsData[0].likescount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "dislikes")
            fieldsData[0].disLikescount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          if (field == "uniqueviewers")
            fieldsData[0].uniqueviewerscount !== 0
              ? (nodatafound_ = false)
              : (nodatafound_ = true);
          setNoDataFound(nodatafound_);
          if (
            (field == "views" && !nodatafound_) ||
            (field == "comments" && !nodatafound_) ||
            (field == "likes" && !nodatafound_) ||
            (field == "uniqueviewers" && !nodatafound_) ||
            (field == "dislikes" && !nodatafound_)
          ) {
            Services.statisticdata(filter)
              .then((res) => {
                if (res.data.status) {
                  startingViewCount = res.data.startingView;
                  const statisticData = res.data.data;
                  setStatisticData(statisticData);
                  var labels = [];
                  var chartDataArr = [];
                  statisticData.map((item) => {
                    if (field == "views") {
                      labels.push(new Date(item.viewdate).getTime());
                    } else if (field == "likes") {
                      labels.push(new Date(item.likedate).getTime());
                    } else if (field == "comments") {
                      labels.push(new Date(item.commentdate).getTime());
                    } else if (field == "uniqueviewers") {
                      labels.push(new Date(item.viewdate).getTime());
                    } else if (field == "dislikes") {
                      labels.push(new Date(item.dislikedate).getTime());
                    }
                    chartDataArr.push(
                      field === "views"
                        ? item.viewscount
                        : field === "comments"
                          ? item.commentscount
                          : field === "uniqueviewers"
                            ? item.viewscount
                            : field === "dislike"
                              ? item.disLikescount
                              : item.likescount
                    );
                  });

                  const statisticsOpt = {
                    startingViewCount: startingViewCount,
                    field: field,
                    filterType: filterType,
                    fromDateTime: fromDateTime,
                    toDateTime: toDateTime,
                    labels: labels,
                    chartDataArr: chartDataArr,
                  };

                  setStatisticsOptions(statisticsOpt);

                  Services.statisticvideolist(filter)
                    .then((res) => {
                      setDataLoader(false);
                      if (res.data.status) {
                        var data = res.data.data;
                        setStatisticVideos(data);
                      }
                    })
                    .catch((e) => {
                      setDataLoader(false);
                      return false;
                    });
                }
              })
              .catch((e) => {
                return false;
              });
          } else {
            setDataLoader(false);
          }
        })
        .catch((e) => {
          return false;
        });
    } else {
      if (filterType === "custom") {
        setShowCustomFilter(true);
        handleCalenderClose();
      }
    }
  }, [filterType, field]);

  //calls when tab and filter change, here video lists will be changed. This call back function is used to change column names of video list
  useEffect(() => {
    if (statisticVideos.length > 0) {
      var newarr = [];
      var arr = [...statisticVideos];
      if (arr.length > 0) {
        field === "views"
          ? (arr = arr.sort((a, b) => b.viewscount - a.viewscount))
          : field === "comments"
            ? (arr = arr.sort((a, b) => b.commentscount - a.commentscount))
            : field === "uniqueviewers"
              ? (arr = arr.sort(
                (a, b) => b.uniqueviewerscount - a.uniqueviewerscount
              ))
              : field === "dislikes"
                ? (arr = arr.sort((a, b) => b.disLikescount - a.disLikescount))
                : (arr = arr.sort((a, b) => b.likescount - a.likescount));
      }
      for (var i = 0; i < arr.length; i++) {
        if (field == "views") {
          newarr[i] = createData(
            i + 1,
            arr[i].id,
            arr[i].title,
            arr[i].thumbnail,
            arr[i].viewscount,
            0, // arr[i].uniqueViewers,
            0,
            0,
            0,
            arr[i].createdAt
          );
        }
        if (field == "comments") {
          newarr[i] = createData(
            i + 1,
            arr[i].id,
            arr[i].title,
            arr[i].thumbnail,
            0,
            0,
            arr[i].commentscount,
            0,
            0,
            arr[i].createdAt
          );

        }
        if (field == "likes") {
          newarr[i] = createData(
            i + 1,
            arr[i].id,
            arr[i].title,
            arr[i].thumbnail,
            0,
            0,
            0,
            0,
            arr[i].likescount,
            arr[i].createdAt
          );

        }
        if (field == "dislikes") {
          newarr[i] = createData(
            i + 1,
            arr[i].id,
            arr[i].title,
            arr[i].thumbnail,
            0,
            0,
            0,
            0,
            arr[i].disLikescount,
            arr[i].createdAt
          );
        }
        if (field == "uniqueviewers") {
          newarr[i] = createData(
            i + 1,
            arr[i].id,
            arr[i].title,
            arr[i].thumbnail,
            0,
            arr[i].uniqueviewerscount,
            0,
            0,
            0,
            arr[i].createdAt
          );
          console.log("newarr[i]", newarr[i]);
        }
      }

      setTblAllVideos(newarr);
      setRow(newarr);
    }
  }, [statisticVideos]);

  useEffect(() => {
    if (sortCol !== null) {
      handleSort(sortCol, sortOrder);
    }
  }, [sortOrder, sortCol]);

  useEffect(() => {
    if (videoUsers.length > 0) {
      var newarr = [];
      var arr = [...videoUsers];
      for (var i = 0; i < arr.length; i++) {
        if (field == "views") {
          if (arr[i]._id && arr[i]._id.first_name !== undefined) {
            newarr[i] = createUsers(
              i + 1,
              arr[i]._id.id,
              arr[i]._id.first_name,
              arr[i]._id.last_name,
              arr[i]._id["Preferred Email"],
              arr[i].webViews,
              arr[i].mobileViews,
              "",
              ""
            );
          }
        }
        if (field == "comments") {
          newarr[i] = createUsers(
            i + 1,
            arr[i].id,
            arr[i].first_name,
            arr[i].last_name,
            arr[i]["Preferred_Email"],
            "",
            "",
            "",
            moment(arr[i].commentdate).format("MM/DD/YYYY hh:mm A")
          );
        }
        if (field == "likes") {
          newarr[i] = createUsers(
            i + 1,
            arr[i].id,
            arr[i].first_name,
            arr[i].last_name,
            arr[i]["Preferred_Email"],
            "",
            "",
            moment(arr[i].liked).format("MM/DD/YYYY hh:mm A"),
            ""
          );
        }
        if (field == "dislikes") {
          newarr[i] = createUsers(
            i + 1,
            arr[i].id,
            arr[i].first_name,
            arr[i].last_name,
            arr[i]["Preferred_Email"],
            "",
            "",
            "",
            moment(arr[i].disliked).format("MM/DD/YYYY hh:mm A"),
            ""
          );
        }
        if (field == "uniqueviewers") {
          if (arr[i]._id && arr[i]._id.first_name !== undefined) {
            newarr[i] = createUsers(
              i + 1,
              arr[i]._id.id,
              arr[i]._id.first_name,
              arr[i]._id.last_name,
              arr[i]._id["Preferred Email"],
              arr[i].webViews,
              arr[i].mobileViews,
              "",
              ""
            );
          }
        }
      }
      setTblAllVideoUsers(newarr);
      setUserRow(newarr);
    } else {
      setTblAllVideoUsers([]);
      setUserRow([]);
    }
  }, [videoUsers]);

  const ChangeFilterType = (e) => {
    setFilterType(e.target.value);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSort = (col, direc) => {
    var normal_arr = tblAllVideos;
    var arr = [...tblAllVideos];
    if (direc === "asc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else if (direc === "desc") {
      arr.sort(function (a, b) {
        var textA = a[col];
        var textB = b[col];
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      });
    } else {
      arr = normal_arr;
    }
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      if (field == "views") {
        tbl[i] = createData(
          i + 1,
          arr[i].id,
          arr[i].title,
          arr[i].thumbnail,
          arr[i].viewscount,
          0,
          0,
          0,
          arr[i].createdAt
        );

      }
      if (field == "comments") {
        tbl[i] = createData(
          i + 1,
          arr[i].id,
          arr[i].title,
          arr[i].thumbnail,
          0,
          0,
          arr[i].commentscount,
          0,
          arr[i].createdAt
        );
      }
      if (field == "likes") {
        tbl[i] = createData(
          i + 1,
          arr[i].id,
          arr[i].title,
          arr[i].thumbnail,
          0,
          0,
          0,
          arr[i].likescount,
          arr[i].createdAt
        );
      }

      if (field == "dislikes") {
        tbl[i] = createData(
          i + 1,
          arr[i].id,
          arr[i].title,
          arr[i].thumbnail,
          0,
          0,
          0,
          0,
          arr[i].disLikescount,
          arr[i].createdAt
        );
      }

      if (field == "uniqueviewers") {
        tbl[i] = createData(
          i + 1,
          arr[i].id,
          arr[i].title,
          arr[i].thumbnail,
          0,
          arr[i].uniqueviewerscount,
          0,
          0,
          arr[i].createdAt
        );
      }
    }
    setRow(tbl);
  };

  //check date changed or not
  const handleDateChange = (date, setDateFunction, dateRef) => {
    setIsChangeDate(false)
    if (date !== dateRef.current) {
      setDateFunction(date);
      setIsChangeDate(true)
    }
  };

  //called when calender close
  const handleCalenderClose = (e) => {
    if (filterType === "past365days" || filterType === "custom") {
      var fromdate = moment(fromDate).format("DD MMM, YYYY");
      var todate = moment(toDate).format("DD MMM, YYYY");
      setFilterHeader(fromdate + "-" + todate);
    }
    if (filterType === "lifetime") {
      setFilterHeader("lifetime");
    }
    if (filterType === "custom") {
      if (
        moment(new Date(fromDate)).format("YYYY-MM-DD") >=
        moment(new Date(toDate)).format("YYYY-MM-DD")
      ) {
        setShowAlert(true);
        setAlertMsg("Fromdate should be less than Todate!");
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 3000);
        setNoChartDataFound(true);
        setNoDataFound(true);
        setNoVideosFound(true);
        setChartData(null);
        setChartOptions(null);
        setStatisticVideos([]);
        setViewsCount(0);
        setCommentsCount(0);
        setLikesCount(0);
        setDislikeCount(0);
        setUniqueViewersCount(0);
      } else {
        if (fromDate !== "" && toDate !== "" && isChangeDate) {
          var resultDays = 0;
          if (
            moment(new Date(fromDate)).format("YYYY-MM-DD") ===
            moment(new Date(toDate)).format("YYYY-MM-DD")
          ) {
            resultDays = moment(new Date(toDate)).diff(
              moment(new Date(fromDate)),
              "hours"
            );
          } else {
            resultDays = moment(new Date(toDate)).diff(
              moment(new Date(fromDate)),
              "days"
            );
          }
          const filter = {
            filtertype: filterType,
            field: field,
            fromdate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
            todate: moment(new Date(toDate)).format("YYYY-MM-DD"),
          };
          setNoChartDataFound(false);
          setNoDataFound(false);
          setNoVideosFound(false);
          setChartData(null);
          setChartOptions(null);
          setDataLoader(true);
          var fromDateTime = moment(new Date(fromDate)).subtract(1, "days");
          var toDateTime = moment(new Date(toDate)).add(1, "days");

          Services.statisticfieldcount(filter)
            .then((res) => {
              if (res.data.status) {
                var fieldsData = res.data.data;
                setIsChangeDate(false)
                setNoDataFound(false);
                setViewsCount(fieldsData[0].viewscount);
                setCommentsCount(fieldsData[0].commentscount);
                setLikesCount(fieldsData[0].likescount);
                setDislikeCount(fieldsData[0].disLikescount);
                setUniqueViewersCount(fieldsData[0].uniqueviewerscount);
                let nodatafound_ = false;
                if (field == "views")
                  fieldsData[0].viewscount !== 0
                    ? (nodatafound_ = false)
                    : (nodatafound_ = true);
                if (field == "comments")
                  fieldsData[0].commentscount !== 0
                    ? (nodatafound_ = false)
                    : (nodatafound_ = true);
                if (field == "likes")
                  fieldsData[0].likescount !== 0
                    ? (nodatafound_ = false)
                    : (nodatafound_ = true);
                if (field == "dislikes")
                  fieldsData[0].disLikescount !== 0
                    ? (nodatafound_ = false)
                    : (nodatafound_ = true);
                if (field == "uniqueviewers")
                  fieldsData[0].uniqueviewerscount !== 0
                    ? (nodatafound_ = false)
                    : (nodatafound_ = true);
                setNoDataFound(nodatafound_);
                if (
                  (field == "views" && !nodatafound_) ||
                  (field == "comments" && !nodatafound_) ||
                  (field == "likes" && !nodatafound_) ||
                  (field == "dislikes" && !nodatafound_) ||
                  (field == "uniqueviewers" && !nodatafound_)
                ) {
                  Services.statisticdata(filter)
                    .then((res) => {
                      if (res.data.status) {
                        let startingViewCount = res.data.startingView;
                        const statisticData = res.data.data;
                        setStatisticData(statisticData);

                        var labels = [];
                        var chartDataArr = [];
                        statisticData.map((item) => {
                          if (field == "views") {
                            labels.push(new Date(item.viewdate).getTime());
                          } else if (field == "likes") {
                            labels.push(new Date(item.likedate).getTime());
                          } else if (field == "dislikes") {
                            labels.push(new Date(item.dislikedate).getTime());
                          } else if (field == "comments") {
                            labels.push(new Date(item.commentdate).getTime());
                          } else if (field == "uniqueviewers") {
                            labels.push(new Date(item.viewdate).getTime());
                          }

                          chartDataArr.push(
                            field === "views"
                              ? item.viewscount
                              : field === "comments"
                                ? item.commentscount
                                : field === "uniqueviewers"
                                  ? item.viewscount
                                  : field === "dislikes"
                                    ? disLikescount
                                    : item.likescount
                          );
                        });

                        const statisticsOpt = {
                          startingViewCount: startingViewCount,
                          field: field,
                          filterType: filterType,
                          fromDateTime: fromDateTime,
                          toDateTime: toDateTime,
                          labels: labels,
                          chartDataArr: chartDataArr,
                        };

                        setStatisticsOptions(statisticsOpt);

                        Services.statisticvideolist(filter)
                          .then((res) => {
                            if (res.data.status) {
                              var data = res.data.data;

                              setStatisticVideos(data);
                              setNoVideosFound(false);
                              setDataLoader(false);
                            } else {
                              setDataLoader(false);
                              setNoVideosFound(true);
                            }
                          })
                          .catch((e) => {
                            setDataLoader(false);
                            return false;
                          });
                      }
                    })
                    .catch((e) => {
                      return false;
                    });
                } else {
                  setDataLoader(false);
                }
              }
            })
            .catch((e) => {
              setDataLoader(false);
              return false;
            });
          if (filterType === "first24hrs") {
            var fromdate = moment()
              .subtract(24, "hours")
              .format("DD MMM HH:mm");
            var todate = moment().format("DD MMM HH:mm");
            setFilterHeader(fromdate + "-" + todate);
          } else {
            var addFilterCount = 0;
            if (filterType === "past7days") {
              addFilterCount = 6;
            } else if (filterType === "past28days") {
              addFilterCount = 27;
            } else if (filterType === "past90days") {
              addFilterCount = 89;
            } else if (filterType === "past365days") {
              addFilterCount = 364;
            }
            if (filterType === "past365days") {
              var fromdate = moment()
                .subtract(addFilterCount, "days")
                .format("DD MMM, YYYY");
              var todate = moment().format("DD MMM, YYYY");
              setFilterHeader(fromdate + "-" + todate);
            } else if (filterType === "custom") {
              var fromdate = moment(fromDate).format("DD MMM, YYYY");
              var todate = moment(toDate).format("DD MMM, YYYY");
              setFilterHeader(fromdate + "-" + todate);
            } else {
              var fromdate = moment()
                .subtract(addFilterCount, "days")
                .format("DD MMM");
              var todate = moment().format("DD MMM, YYYY");
              setFilterHeader(fromdate + "-" + todate);
            }
          }
        }
      }
    }
  };

  const handleSingleVideoSingleUser = (e, id, name, column) => {
    if (
      parseInt(e.target.innerHTML) !== undefined &&
      parseInt(e.target.innerHTML) !== 0
    ) {
      setSingleUserName(name);
      setSingleUserPage(0);
      const data = {
        userId: id,
        id: selectedVideo,
        field: field,
        filtertype: filterType,
        platform: column === "mobileViews" ? "mobile" : "web",
      };
      Services.getSingleVideoSingleUser(data)
        .then((res) => {
          if (res.data.status) {
            setSingleUserData(res.data.data);
            document.body.classList.add("modal-open");
            setSingleUserViewModal(true);
            setSelected([]);
            setSelectRow([]);
          }
        })
        .catch((e) => {
          setDataLoader(false);
          return false;
        });
    }
  };

  const handleVideoUsers = (e, id) => {
    setSelected([]);
    setSelectRow([]);
    if (
      parseInt(e.target.innerHTML) !== undefined &&
      parseInt(e.target.innerHTML) !== 0
    ) {
      setUserPage(0);
      setSelectedVideo(id);
      const data = {
        id: id,
        field: field,
        filtertype: filterType,
      };
      Services.getSingleVideoUsers(data)
        .then((res) => {
          if (res.data.status) {
            setVideoUsers(res.data.data);
            setStartingView(res.data.starting_view);
            document.body.classList.add("modal-open");
            setViewModalOpen(true);
          }
        })
        .catch((e) => {
          setDataLoader(false);
          return false;
        });
    }
  };

  const handleChangeUserPage = (event, newPage) => {
    setUserPage(newPage);
  };

  const handleChangeUserRowsPerPage = (event) => {
    setUserRowsPerPage(+event.target.value);
    setUserPage(0);
  };

  const handleChangeSingleUserPage = (event, newPage) => {
    setSingleUserPage(newPage);
  };

  const handleChangeSingleUserRowsPerPage = (event) => {
    setSingleUserRowsPerPage(+event.target.value);
    setSingleUserPage(0);
  };

  useEffect(() => {
    if (singleUserData.length > 0) {
      var newarr = [];
      var arr = [...singleUserData];
      for (var i = 0; i < arr.length; i++) {
        newarr[i] = createViewUsers(
          i + 1,
          arr[i].watched && arr[i].watched !== ""
            ? moment(arr[i].watched).format("MM/DD/YYYY")
            : "-",
          arr[i].watched && arr[i].watched !== ""
            ? moment(arr[i].watched).format("hh:mm A")
            : "-"
        );
      }
      setSingleUserRows(newarr);
    } else {
      setSingleUserRows([]);
    }
  }, [singleUserData]);

  const handleSingleUserEscClose = (e) => {
    if (singleUserViewModal && viewModalOpen && e.key === "Escape") {
      setSingleUserViewModal(false);
      document.body.classList.remove("modal-open");
    }
  };

  const handleViewModalEscClose = (e) => {
    if (viewModalOpen && !singleUserViewModal && e.key === "Escape") {
      setViewModalOpen(false);
      document.body.classList.remove("modal-open");
    }
  };

  useEffect(() => {
    if (singleUserViewModal) {
      document.addEventListener("keydown", handleSingleUserEscClose);
    } else {
      document.removeEventListener("keydown", handleSingleUserEscClose);
    }
    if (viewModalOpen) {
      document.addEventListener("keydown", handleViewModalEscClose);
    } else {
      document.removeEventListener("keydown", handleViewModalEscClose);
    }

    return () => {
      document.removeEventListener("keydown", handleSingleUserEscClose);
      document.removeEventListener("keydown", handleViewModalEscClose);
    };
  }, [viewModalOpen, singleUserViewModal]);

  // Export for download xlsx file
  const handleExport = () => {
    const workbook_ = XLSX.utils.book_new(); // create new workbook
    if (selectRow.length > 0) {
      let modifiedData;
      let fileName;
      if (field === "views" && singleUserViewModal) {
        modifiedData = selectRow.map((item) => ({
          "Watched date": item.watchedDate,
          "Watched time": item.watchedTime,
        }));
        fileName = `Unique_views_By_${singleUserName}`;
      } else {
        modifiedData = selectRow.map((item) => ({
          "First Name": item.firstname,
          "Last Name": item.lastname,
          Email: item.email,
          "No. of views": item.viewsCount,
        }));
        fileName = "Videos_Views_Based_On_Users";
      }
      if (field === "likes") {
        modifiedData = selectRow.map((item) => ({
          "First Name": item.firstname,
          "Last Name": item.lastname,
          Email: item.email,
          "Like At": item.liked,
        }));
        fileName = "Videos_Likes_Based_On_Users";
      }
      if (field === "dislikes") {
        modifiedData = selectRow.map((item) => ({
          "First Name": item.firstname,
          "Last Name": item.lastname,
          Email: item.email,
          "Dislike At": item.disliked,
        }));
        fileName = "Videos_Dislikes_Based_On_Users";
      }
      if (field === "comments") {
        modifiedData = selectRow.map((item) => ({
          "First Name": item.firstname,
          "Last Name": item.lastname,
          Email: item.email,
          "Comment At": item.commentdate,
        }));
        fileName = "Videos_Comments_Based_On_Users";
      }
      const convertToexcel = XLSX.utils.json_to_sheet(modifiedData); // convert modified JSON data to sheet
      XLSX.utils.book_append_sheet(workbook_, convertToexcel, "Sheet2"); // add the sheet to the workbook
      const downloadExcel = XLSX.write(workbook_, {
        bookType: "xlsx",
        type: "array",
      });
      const dataExcel = new Blob([downloadExcel], { type: fileType });

      FileSaver.saveAs(dataExcel, `${fileName}.xlsx`);
    } else {
      setError(true);
      setSelectError("Please select document to export");
      setTimeout(() => {
        setError(false);
        setSelectError("");
      }, 2000);
    }
  };

  function formatWithCommas(n) {
    return n.toString().replace(/\B(?=(\d{3})+\b)/g, ",");
  }

  return (
    <>
      <div className="users-info-content video-statistic-info-content">
        <div className="video-statistic-info-content-main">
          <>
            {showAlert && <div className="alert alert-info">{alertMsg}</div>}
            <div className="cstm-video-filter-container">
              <div className="cstm-video-filter-container-left">
                <h2 className="cstn-vst"> {videoCount} videos</h2>
              </div>
              <div className="cstm-video-filter-container-right">
                <h4 className="cstm-video-select-title">{filterHeader}</h4>
                <select
                  className="cstm-video-select-field"
                  onChange={ChangeFilterType}
                >
                  <option
                    value="lifetime"
                    selected={filterType === "lifetime" ? true : false}
                  >
                    lifetime
                  </option>
                  <option
                    value="first24hrs"
                    selected={filterType === "first24hrs" ? true : false}
                  >
                    Last 24 h
                  </option>
                  <option
                    value="past7days"
                    selected={filterType === "past7days" ? true : false}
                  >
                    Last 7 Days
                  </option>
                  <option
                    value="past28days"
                    selected={filterType === "past28days" ? true : false}
                  >
                    Last 30 Days
                  </option>
                  <option
                    value="past90days"
                    selected={filterType === "past90days" ? true : false}
                  >
                    Last 90 Days
                  </option>
                  <option
                    value="past365days"
                    selected={filterType === "past365days" ? true : false}
                  >
                    Last 365 Days
                  </option>
                  <option
                    value="custom"
                    selected={filterType === "custom" ? true : false}
                  >
                    Custom
                  </option>
                </select>
                {filterType === "custom" && showCustomFilter ? (
                  <div className="cstm-video-filter-custom">
                    <DatePicker
                      showIcon
                      selected={fromDate}
                      onChange={(date) => handleDateChange(date, setFromDate, fromDateRef)}
                      onCalendarClose={handleCalenderClose}
                    />
                    <img src={arrowimg} className="cstm-arrow-left-right" />
                    <DatePicker
                      showIcon
                      selected={toDate}
                      onChange={(date) => handleDateChange(date, setToDate, toDateRef)}
                      onCalendarClose={handleCalenderClose}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="cstm-tab-video-statistic-main">
              <ul
                className={`${filterType === "lifetime" ? "statistic-lifetime-tab" : ""
                  }  cstm-tab-video-statistic`}
              >
                <li className={field === "views" ? "cstm-active-vs" : null}>
                  <a
                    className="active cstm-videostatistic-view-link"
                    id="VideoStatisticViewBtn"
                    data-toggle="tab"
                    href="#VideoStatisticViewContent"
                    role="tab"
                    onClick={() => {
                      setField("views");
                      setSortCol(null);
                      setSortOrder("asc");
                    }}
                  >
                    <div className="csm-videostatistic-header">
                      <p className="csm-videostatistic-header-title">Views</p>
                      <h3 className="csm-videostatistic-header-viewscount">
                        {formatWithCommas(viewsCount)}
                      </h3>
                      <p className="csm-videostatistic-header-subtitle">
                        {filterType == "first24hrs"
                          ? `First 24 hours`
                          : filterType == "past7days"
                            ? `In last 7 days`
                            : filterType == "past28days"
                              ? `In last 28 days`
                              : filterType == "past90days"
                                ? `In last 90 days`
                                : filterType == "past365days"
                                  ? `In last 365 days`
                                  : filterType == "lifetime"
                                    ? `Lifetime`
                                    : ""}
                      </p>
                    </div>
                  </a>
                </li>

                <li className={field === "likes" ? "cstm-active-vs" : null}>
                  <a
                    className="active cstm-videostatistic-likes-link"
                    id="VideoStatisticLikesBtn"
                    data-toggle="tab"
                    href="#VideoStatisticLikesContent"
                    role="tab"
                    onClick={() => {
                      setField("likes");
                      setSortCol(null);
                      setSortOrder("asc");
                    }}
                  >
                    <div className="csm-videostatistic-header">
                      <p className="csm-videostatistic-header-title">Likes</p>
                      <h3 className="csm-videostatistic-header-viewscount">
                        {formatWithCommas(likesCount)}
                      </h3>
                      <p className="csm-videostatistic-header-subtitle">
                        {filterType == "first24hrs"
                          ? `First 24 hours`
                          : filterType == "past7days"
                            ? `In last 7 days`
                            : filterType == "past28days"
                              ? `In last 28 days`
                              : filterType == "past90days"
                                ? `In last 90 days`
                                : filterType == "past365days"
                                  ? `In last 365 days`
                                  : filterType == "lifetime"
                                    ? `Lifetime`
                                    : ""}
                      </p>
                    </div>
                  </a>
                </li>

                <li className={field === "dislikes" ? "cstm-active-vs" : null}>
                  <a
                    className="active cstm-videostatistic-likes-link"
                    id="VideoStatisticDisLikesBtn"
                    data-toggle="tab"
                    href="#VideoStatisticDisLikesContent"
                    role="tab"
                    onClick={() => {
                      setField("dislikes");
                      setSortCol(null);
                      setSortOrder("asc");
                    }}
                  >
                    <div className="csm-videostatistic-header">
                      <p className="csm-videostatistic-header-title">
                        Dislikes
                      </p>
                      <h3 className="csm-videostatistic-header-viewscount">
                        {formatWithCommas(disLikescount)}
                      </h3>
                      <p className="csm-videostatistic-header-subtitle">
                        {filterType == "first24hrs"
                          ? `First 24 hours`
                          : filterType == "past7days"
                            ? `In last 7 days`
                            : filterType == "past28days"
                              ? `In last 28 days`
                              : filterType == "past90days"
                                ? `In last 90 days`
                                : filterType == "past365days"
                                  ? `In last 365 days`
                                  : filterType == "lifetime"
                                    ? `Lifetime`
                                    : ""}
                      </p>
                    </div>
                  </a>
                </li>

                <li className={field === "comments" ? "cstm-active-vs" : null}>
                  <a
                    className="active cstm-videostatistic-comment-link"
                    id="VideoStatisticCommentBtn"
                    data-toggle="tab"
                    href="#VideoStatisticCommentContent"
                    role="tab"
                    onClick={() => {
                      setField("comments");
                      setSortCol(null);
                      setSortOrder("asc");
                    }}
                  >
                    <div className="csm-videostatistic-header">
                      <p className="csm-videostatistic-header-title">
                        Comments
                      </p>
                      <h3 className="csm-videostatistic-header-viewscount">
                        {formatWithCommas(commentsCount)}
                      </h3>
                      <p className="csm-videostatistic-header-subtitle">
                        {filterType == "first24hrs"
                          ? `First 24 hours`
                          : filterType == "past7days"
                            ? `In last 7 days`
                            : filterType == "past28days"
                              ? `In last 28 days`
                              : filterType == "past90days"
                                ? `In last 90 days`
                                : filterType == "past365days"
                                  ? `In last 365 days`
                                  : filterType == "lifetime"
                                    ? `Lifetime`
                                    : ""}
                      </p>
                    </div>
                  </a>
                </li>

                <li
                  className={
                    field === "uniqueviewers" ? "cstm-active-vs" : null
                  }
                >
                  <a
                    className="active cstm-videostatistic-uniqueviewers-link"
                    id="VideoStatisticUniqueViewersBtn"
                    data-toggle="tab"
                    href="#VideoStatisticUniqueViewersContent"
                    role="tab"
                    onClick={() => {
                      setField("uniqueviewers");
                      setSortCol(null);
                      setSortOrder("asc");
                    }}
                  >
                    <div className="csm-videostatistic-header">
                      <p className="csm-videostatistic-header-title">
                        Unique viewers
                      </p>
                      <h3 className="csm-videostatistic-header-viewscount">
                        {formatWithCommas(
                          uniqueViewersCount ? uniqueViewersCount : 0
                        )}
                      </h3>
                      <p className="csm-videostatistic-header-subtitle">
                        {filterType == "first24hrs"
                          ? `First 24 hours`
                          : filterType == "past7days"
                            ? `In last 7 days`
                            : filterType == "past28days"
                              ? `In last 28 days`
                              : filterType == "past90days"
                                ? `In last 90 days`
                                : filterType == "past365days"
                                  ? `In last 365 days`
                                  : filterType == "lifetime"
                                    ? `Lifetime`
                                    : ""}
                      </p>
                    </div>
                  </a>
                </li>
              </ul>

              <div
                className={`tab-content ${filterType !== "lifetime" ? "videostatistic-tabs" : ""
                  }`}
                id="VideoStatisticViewContent"
              >
                <div
                  className={`tab-pane fade show active ${filterType !== "lifetime"
                    ? `cstm-video-graph-container`
                    : ``
                    }`}
                  id="videostatisticview"
                  role="tabpanel"
                >
                  {dataLoader && (
                    <>
                      <div className="statistic-loader-img">
                        <img src={loading} />
                      </div>
                    </>
                  )}
                  {!dataLoader && noDataFound && (
                    <>
                      <div className="cstm-no-data-found">No Data Found</div>
                    </>
                  )}
                  {!dataLoader &&
                    !noDataFound &&
                    filterType !== "lifetime" &&
                    statisticsOptions !== undefined &&
                    statisticsOptions !== null &&
                    Object.keys(statisticsOptions).length > 0 ? (
                    <Graph statisticsOptions={statisticsOptions} />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            {!dataLoader &&
              !noDataFound &&
              statisticVideos !== undefined &&
              statisticVideos !== null &&
              statisticVideos.length > 0 && (
                <>
                  <div className="cstm-vs-title4">
                    <h4>Videos</h4>
                  </div>
                  <div className="cstm-videostatistic-videolist-container">
                    <Paper className="uic-tb-mn">
                      <TableContainer className="">
                        <Table className="">
                          <TableHead className="">
                            <TableRow>
                              {columns.map((column) =>
                                column.id === "srno" ? (
                                  <TableCell
                                    key={column.id}
                                    className={
                                      column.id === "id"
                                        ? "cstm-sr-w"
                                        : "cstm-sr-w"
                                    }
                                  >
                                    {column.label}
                                  </TableCell>
                                ) : column.id === "id" ? (
                                  <TableCell
                                    key={column.id}
                                    className={
                                      column.id === "id"
                                        ? "cstm-vd-w"
                                        : " cstm-vd-w"
                                    }
                                  >
                                    {column.label}
                                  </TableCell>
                                ) : column.id ===
                                  `${field === "dislikes" ? "disLikes" : field
                                  }count` ? (
                                  <TableCell
                                    key={column.id}
                                    className={
                                      column.id === "id"
                                        ? "cstm-ve-w"
                                        : "cstm-ve-w"
                                    }
                                  >
                                    {column.id ===
                                      `${field === "dislikes" ? "disLikes" : field
                                      }count`
                                      ? column.label
                                      : ""}
                                    <span className="cstm-vs-icon">
                                      <img
                                        src={
                                          sortOrder == "asc" ? Union : arrowup
                                        }
                                        onClick={(e) => {
                                          setSortOrder(
                                            sortOrder == "asc" ? "desc" : "asc"
                                          );
                                          setSortCol(column.id);
                                        }}
                                      />
                                    </span>
                                  </TableCell>
                                ) : (
                                  <></>
                                )
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody className="gggggg">
                            {rows
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={row.code}
                                    className=""
                                  >
                                    {columns.map((column) => {
                                      return column.id == "srno" ? (
                                        <TableCell
                                          key={column.id}
                                          className="cstm-sr-w"
                                        >
                                          {row["srno"]}
                                        </TableCell>
                                      ) : column.id === "id" ? (
                                        <TableCell
                                          key={column.id}
                                          className="cstm-vd-w"
                                        >
                                          <div
                                            className={`cstm-videos-statistic-list-video-main-box`}
                                          >
                                            <div className="cstm-videos-statistic-list-video-main-box-img">
                                              <img
                                                src={
                                                  row["thumbnail"]
                                                    ? process.env
                                                      .REACT_APP_AWS_IMG_VID_PATH +
                                                    row["thumbnail"]
                                                    : videoimg
                                                }
                                              />
                                            </div>

                                            <div className="cstm-videostatistic-list-main-box-info">
                                              <h3 className="cstm-vlm-title">
                                                {row["title"]}
                                              </h3>

                                              <p className="cstm-vlm-date">
                                                {moment(
                                                  row["createdAt"]
                                                ).format("D MMMM YYYY")}
                                              </p>
                                            </div>
                                          </div>
                                        </TableCell>
                                      ) : column.id ==
                                        `${field === "dislikes"
                                          ? "disLikes"
                                          : field
                                        }count` ? (
                                        <TableCell
                                          key={column.id}
                                          className="cstm-statistics-count"
                                        >
                                          <a
                                            onClick={(e) => {
                                              handleVideoUsers(e, row["id"]);
                                            }}
                                          >
                                            {formatWithCommas(
                                              row[column.id]
                                                ? row[column.id]
                                                : 0
                                            )}
                                          </a>
                                        </TableCell>
                                      ) : (
                                        <></>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                        {rows.length === 0 ? (
                          <div className="admin-no-found">
                            <p>No Data Found </p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </TableContainer>
                      <TablePagination
                        className="uic-pagination"
                        rowsPerPageOptions={[20, 50, 100, 200]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Paper>
                  </div>
                </>
              )}
          </>
        </div>
      </div>

      {viewModalOpen && (
        <div className="desk-globle-model-box globle-model-box  cstm-videostatistic-singlevideo-userlist">
          <div className="desk-globle-model-box-inner cstm-singleuserlist-popup">
            <div className="modal-content">
              <div className="cstm-replace-pp">
                <div className="cstm-hdr">
                  <div class="cstm-rpp-title">
                    List of individuals who&nbsp;
                    {field === "views"
                      ? "viewed"
                      : field === "likes"
                        ? "Liked"
                        : field === "dislikes"
                          ? "Dislikes"
                          : field === "uniqueviewers"
                            ? "viewed"
                            : "Commented"}
                    &nbsp;this video
                    <span
                      class="cstm-rpp-close-btn"
                      onClick={() => {
                        setViewModalOpen(false);
                        document.body.classList.remove("modal-open");
                      }}
                    >
                      ×
                    </span>
                  </div>
                  {field === "views" ? (
                    <div className="cstm-starting-view-container">
                      Starting view count (Added from the admin manually):
                      {startingView}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {userRows.length > 0 && (
                  <div className="cstm-export-link">
                    <a onClick={handleExport}>
                      <img src={exportImg} />
                      Export
                    </a>
                  </div>
                )}
                <div className="cstm-cstm-viewlist">
                  {error && (
                    <div className="alert alert-info">{selectError}</div>
                  )}
                  {videoUsers !== undefined &&
                    videoUsers !== null &&
                    videoUsers.length > 0 && (
                      <Paper className="uic-tb-mn">
                        <TableContainer className="uic-table-main">
                          <Table className="uic-table min-w-auto">
                            <TableHead className="uic-table-th">
                              <TableRow>
                                <TableCell className="uic-th-td w-5">
                                  <Checkbox
                                    className="cstm-ls-checkbox"
                                    color="primary"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        const newSelectedids = userRows.map(
                                          (row) => row.id
                                        );
                                        const newSelectedRow = userRows.map(
                                          (row) => row
                                        );
                                        setSelected(newSelectedids);
                                        setSelectRow(newSelectedRow);
                                        setSelectError("");
                                      } else {
                                        setSelected([]);
                                        setSelectRow([]);
                                        setSelectError("");
                                      }
                                    }}
                                    inputProps={{
                                      "aria-label": "select all desserts",
                                    }}
                                  />
                                </TableCell>
                                {user_columns.map((column) =>
                                  column.id === "firstname" ? (
                                    <TableCell
                                      key={column.id}
                                      className={`uic-th-td w-20`}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ) : column.id === "lastname" ? (
                                    <TableCell
                                      key={column.id}
                                      className={`uic-th-td w-20`}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ) : column.id === "email" ? (
                                    <TableCell
                                      key={column.id}
                                      className={`uic-th-td w-25`}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ) : field === "views" &&
                                    (column.id === "mobileViews" ||
                                      column.id === "webViews") ? (
                                    <TableCell
                                      className={`uic-th-td w-10`}
                                      key={column.id}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ) : field === "comments" &&
                                    column.id === "commentdate" ? (
                                    <TableCell
                                      className={`uic-th-td w-10`}
                                      key={column.id}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ) : field === "dislikes" &&
                                    column.id === "disliked" ? (
                                    <TableCell
                                      className={`uic-th-td w-10`}
                                      key={column.id}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ) : field === "likes" &&
                                    column.id === "liked" ? (
                                    <TableCell
                                      className={`uic-th-td w-10`}
                                      key={column.id}
                                    >
                                      {column.label}
                                    </TableCell>
                                  ) : (
                                    <></>
                                  )
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody className="uic-table-td">
                              {userRows
                                .slice(
                                  userPage * userRowsPerPage,
                                  userPage * userRowsPerPage + userRowsPerPage
                                )
                                .map((row, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      key={row.code}
                                      className="uic-th-td w-20"
                                    >
                                      <TableCell className="uic-th-td w-5">
                                        <ExportSelect
                                          id={row.id}
                                          row={row}
                                          selected={selected}
                                          setSelected={setSelected}
                                          selectRow={selectRow}
                                          setSelectRow={setSelectRow}
                                          setSelectError={setSelectError}
                                        />
                                      </TableCell>
                                      {user_columns.map((column) =>
                                        column.id === "firstname" ? (
                                          <TableCell className="uic-th-td w-20">
                                            {row[column.id]}
                                          </TableCell>
                                        ) : column.id === "lastname" ? (
                                          <TableCell className="uic-th-td w-20">
                                            {row[column.id]}
                                          </TableCell>
                                        ) : column.id === "email" ? (
                                          <TableCell className="uic-th-td w-25">
                                            {row[column.id]}
                                          </TableCell>
                                        ) : field === "views" &&
                                          (column.id === "webViews" ||
                                            column.id === "mobileViews") ? (
                                          <TableCell
                                            key={column.id}
                                            className="cstm-view-users-count uic-th-td w-10"
                                          >
                                            <a
                                              onClick={(e) => {
                                                handleSingleVideoSingleUser(
                                                  e,
                                                  row["id"],
                                                  `${row["firstname"]} ${row["lastname"]}`,
                                                  column.id
                                                );
                                              }}
                                            >
                                              {row[column.id]}
                                            </a>
                                          </TableCell>
                                        ) : field === "comments" &&
                                          column.id === "commentdate" ? (
                                          <TableCell
                                            key={column.id}
                                            className="uic-th-td w-10"
                                          >
                                            {row[column.id]}
                                          </TableCell>
                                        ) : field === "dislikes" &&
                                          column.id === "disliked" ? (
                                          <TableCell
                                            key={column.id}
                                            className="uic-th-td w-10"
                                          >
                                            {row[column.id]}
                                          </TableCell>
                                        ) : field === "likes" &&
                                          column.id === "liked" ? (
                                          <TableCell
                                            key={column.id}
                                            className="uic-th-td w-10"
                                          >
                                            {row[column.id]}
                                          </TableCell>
                                        ) : (
                                          <></>
                                        )
                                      )}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                          {userRows.length === 0 ? (
                            <div className="view-users-no-found">
                              <p>No Users Found </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </TableContainer>
                        <TablePagination
                          className="uic-pagination"
                          rowsPerPageOptions={[10, 20, 50, 100, 200]}
                          component="div"
                          count={userRows.length}
                          rowsPerPage={userRowsPerPage}
                          page={userPage}
                          onPageChange={handleChangeUserPage}
                          onRowsPerPageChange={handleChangeUserRowsPerPage}
                        />
                      </Paper>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {singleUserViewModal && (
        <div
          className="desk-globle-model-box globle-model-box  cstm-videostatistic-singlevideo-userlist"
          id="watchSingleUserModal"
        >
          <div className="desk-globle-model-box-inner cstm-singlevideo-singleuser-list">
            <div className="modal-content">
              <div className="cstm-replace-pp">
                <div class="cstm-rpp-title">
                  Unique views of {singleUserName} on this video
                  <span
                    class="cstm-rpp-close-btn"
                    onClick={() => {
                      setSingleUserViewModal(false);
                      document.body.classList.remove("modal-open");
                    }}
                  >
                    ×
                  </span>
                </div>
                <div className="cstm-cstm-viewlist">
                  {error && (
                    <div className="alert alert-info">{selectError}</div>
                  )}
                  {singleUserData !== undefined &&
                    singleUserData !== null &&
                    singleUserData.length > 0 && (
                      <Paper className="uic-tb-mn">
                        <TableContainer className="uic-table-main">
                          <Table className="uic-table min-w-auto">
                            <TableHead className="uic-table-th">
                              <TableRow>
                                {/* <TableCell className="uic-th-td w-5" padding="checkbox">
                                                                <Checkbox
                                                                    className="cstm-ls-checkbox"
                                                                    color="primary"
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            const newSelectedids = singleUserRows.map((row) => row.id);
                                                                            const newSelectedRow = singleUserRows.map((row) => row);
                                                                            setSelected(newSelectedids)
                                                                            setSelectRow(newSelectedRow)
                                                                            setSelectError("")
                                                                        } else {
                                                                            setSelected([])
                                                                            setSelectRow([])
                                                                            setSelectError("")
                                                                        }
                                                                    }}
                                                                    inputProps={{
                                                                        'aria-label': 'select all desserts',
                                                                    }}
                                                                />
                                                            </TableCell> */}
                                {userViewcolumns.map((column) => (
                                  <TableCell
                                    key={column.id}
                                    className={
                                      column.id === "srno"
                                        ? "uic-th-td w-5"
                                        : "uic-th-td w-20"
                                    }
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody className="uic-table-td">
                              {singleUserRows
                                .slice(
                                  singleUserPage * singleUserRowsPerPage,
                                  singleUserPage * singleUserRowsPerPage +
                                  singleUserRowsPerPage
                                )
                                .map((row, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      className="uic-th-td"
                                    >
                                      {/* <TableCell className="uic-th-td w-5">
                                                                            <ExportSelect
                                                                                disabled={true}
                                                                                id={row.id}
                                                                                row={row}
                                                                                selected={selected}
                                                                                setSelected={setSelected}
                                                                                selectRow={selectRow}
                                                                                setSelectRow={setSelectRow}
                                                                                setSelectError={setSelectError}

                                                                            />
                                                                        </TableCell> */}
                                      {userViewcolumns.map((column) => (
                                        <TableCell
                                          className={
                                            column.id === "srno"
                                              ? "uic-th-td w-5"
                                              : "uic-th-td w-20"
                                          }
                                          key={column.id}
                                        >
                                          {row[column.id]}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                          {singleUserRows.length === 0 ? (
                            <div className="view-users-no-found">
                              <p>No views Found </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </TableContainer>
                        <TablePagination
                          className="uic-pagination"
                          rowsPerPageOptions={[10, 20, 50, 100, 200]}
                          component="div"
                          count={singleUserRows.length}
                          rowsPerPage={singleUserRowsPerPage}
                          page={singleUserPage}
                          onPageChange={handleChangeSingleUserPage}
                          onRowsPerPageChange={
                            handleChangeSingleUserRowsPerPage
                          }
                        />
                      </Paper>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default VideoStatistic;
