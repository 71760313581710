import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
// Import api integration file
import Services from "../../../Services/auth.service";
import auth from "../../../Services/auth0services";
import authHeaderChannel from "../../../Services/auth.header.channel";
import loading from "../../../Images/loader.gif";
import authService from "../../../Services/auth.service";
const MigrateUserSocialSignup = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [err, seterr] = useState("");
  const [tokenError, setTokenError] = useState("")
  const processHash = (hash) => {
    auth.parseHash({ hash }, function (err, result) {
      if (err) {
        seterr(err);
        console.log("there is an error");
        console.log(err);
        history("/");
      } else {
        console.log(result);

        if (result && result.accessToken) {
          const m_user = JSON.parse(localStorage.getItem("m_user"));
          const app_metadata = JSON.parse(localStorage.getItem("app_metadata"));
          const accesstoken = result.accessToken;
          const idtoken = result.idToken;
          const email = result.idTokenPayload.email;
          const userid = result.idTokenPayload.sub.split("|")[1];
          const media = result.idTokenPayload.sub.split("|")[0];
          Services.socialuserexist({ facebookLinkedinId: userid, socialEmail: email, mdsEmailExists: false }).then((res) => {
            if (res.data.status) {
              authService.updateMigrationCaseStatus({
                "auth0Id": localStorage.getItem("AuthUserId").split("|")[1],
                "field": "case6",
                "fieldval": true,
                "error": ""
              })
              history("/migrateuserregister", { state: { userExist: true, selectedMedia: media } })

            } else {
              authService.updateMigrationCaseStatus({
                "auth0Id": localStorage.getItem("AuthUserId").split("|")[1],
                "field": "case7",
                "fieldval": true,
                "error": res.data.message
              })
              localStorage.setItem("socialId", userid)
              var auth0_data = {
                email: app_metadata ? app_metadata.email_id ?? "" : "",
                first_name: app_metadata ? app_metadata.first_name ?? "" : "",
                last_name: app_metadata ? app_metadata.last_name ?? "" : "",
                provider: media,
                auth0Id: localStorage.getItem("AuthUserId").split("|")[1],
                isSocial: true,
                plan_id: localStorage.getItem("migrated_user_plan_id") ?? "",
                migrate_user: m_user,
                migrate_user_status: true,
                AuthUserId: localStorage.getItem("AuthUserId") ?? "",
                AuthUserId2: result.idTokenPayload.sub,
                socialauth0id: userid,
                facebookLinkedinId: userid ?? ""
              };
              if (app_metadata.email_id && localStorage.getItem("migrated_user_plan_id") && localStorage.getItem("migrated_user_plan_id").length > 0) {
                Services.updatemigrateduserdetail(auth0_data)
                  .then((res) => {
                    if (res.data.status) {
                      authService.updateMigrationCaseStatus({
                        "auth0Id": auth0_data.auth0Id,
                        "field": "case8",
                        "fieldval": true,
                        "error": ""
                      })
                      localStorage.setItem("accessToken", accesstoken);
                      localStorage.setItem("isCollaborator", false)
                      localStorage.setItem("idtoken", idtoken);
                      localStorage.setItem("role", "user");
                      localStorage.setItem("logintime", new Date());
                      localStorage.setItem("userinfo", "");
                      localStorage.setItem("migrated_user_plan_id", "");
                      localStorage.setItem(
                        "username", `${res.data.data.first_name} ${res.data.data.last_name}`
                      );
                      localStorage.setItem(
                        "userprofile",
                        res.data.data.profileImg !== undefined
                          ? res.data.data.profileImg
                          : ""
                      );
                      dispatch({ type: "SETUSERID", payload: res.data.data._id });
                      localStorage.setItem("userid", res.data.data._id);
                      localStorage.setItem("useremailid", res.data.data.email);
                      localStorage.setItem("createdAt", res.data.data.createdAt);
                      dispatch({ type: "OpenPopup" });
                      axios.put(
                        process.env.REACT_APP_API_URL + "user/manage/lastlogin",
                        {},
                        { headers: { Authorization: "Bearer " + idtoken } }
                      );
                      history("/homepage")
                    } else {
                      authService.updateMigrationCaseStatus({
                        "auth0Id": userid,
                        "field": "case9",
                        "fieldval": true,
                        "error": res.data.message
                      })
                      seterr(res.data.message);
                      localStorage.removeItem("migrated_user_plan_id")
                    }
                  })
                  .catch((e) => {
                    authService.updateMigrationCaseStatus({
                      "auth0Id": userid,
                      "field": "case9",
                      "fieldval": true,
                      "error": e
                    })
                    console.log(e);
                  });
              } else {
                alert("Please provide email id in app metadata and plain id in user metadata")
              }

            }
          })
        } else {
          setTokenError("Something went wrong! please try again")
        }

      }
    });
  };

  useEffect(() => {
    if (location.hash) {
      processHash(location.hash);
    }
  }, [location]);
  return (
    <>
      <div className="loadind-post">
        {err ?
          <div>
            <div>{err}</div>
            <Link to={"/"}>Back to login</Link>
          </div>
          : tokenError ? <div>{tokenError}</div> :
            <img src={loading} />
        }
      </div>
    </>
  );
};
export default MigrateUserSocialSignup;
