import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Services from "../../../Services/auth.service";
import shortLefticon from "../../../Images/short_left-icon.svg";
import Searchicon from "../../../Images/searchicon.svg";
import Searchmain from "../../../Images/search.svg";

import CloseFillIcon from "remixicon-react/CloseFillIcon";
import { ConnectedTvOutlined } from "@mui/icons-material";

const GlobleSearch = ({ setxyz }) => {
  const history = useNavigate();
  const [allgroup, setallgroup] = useState([]);
  const [alltopic, setalltopic] = useState([]);
  const [list, setlist] = useState([]);
  const [search, setsearch] = useState("topics");
  const [text, settext] = useState("");
  const [showlist, setshowlist] = useState(false);
  useEffect(() => {
    Services.getallgrouploginuser()
      .then((res) => {
        if (res.data.status) {
          setallgroup(res.data.data);
          var arr = [];
          var t = 0;
          for (var i = 0; i < res.data.data.length; i++) {
            Services.gettopicsbygroupid(res.data.data[i]._id)
              .then((response) => {
                if (response.data.status) {
                  for (var n = 0; n < response.data.data.length; n++) {
                    arr[t] = response.data.data[n];
                    t++;
                  }
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
          setalltopic(arr);
        } else {
          if (res.data.message === "Invalid token!") {
            localStorage.clear();
            history("/");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleSearch = (value) => {
    // closePopup();

    setshowlist(true);
    switch (search) {
      case "topics":
        var arr = [];
        var n = 0;
        for (var i = 0; i < alltopic.length; i++) {
          if (alltopic[i].topic.toLowerCase().includes(value.toLowerCase())) {
            arr[n] = {
              id: alltopic[i]._id,
              name: alltopic[i].topic,
              type: "topic",
            };
            n++;
          }
        }
        setlist(arr);
        if (arr.length < 1) setshowlist(false);
        break;
      case "groups":
        var arr = [];
        var n = 0;
        for (var i = 0; i < allgroup.length; i++) {
          if (
            allgroup[i].groupTitle.toLowerCase().includes(value.toLowerCase())
          ) {
            arr[n] = {
              id: allgroup[i]._id,
              name: allgroup[i].groupTitle,
              type: "group",
            };
            n++;
          }
        }
        setlist(arr);
        if (arr.length < 1) setshowlist(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="globle-model-box globle-search-model-box">
      <div className="globle-search-heading-sec">
        <h3>Search</h3>

        <button
          className="globle-back-btn"
          onClick={() => {
            setxyz(false);
          }}
        >
          <CloseFillIcon size="24px" />
          {/* <img src={shortLefticon} alt="shortLefticon" /> */}
        </button>
      </div>

        <div className="header-ft-search">
        <div className="header-ft-search-search">
          <input
            type="text"
            placeholder="Search"
            value={text}
            onChange={(e) => {
              settext(e.target.value);
            }}
          />
          <img
            className="header-search-main-icon"
            src={Searchmain}
            alt="Searchmain"
            onClick={() => {
              console.log("globlesearch");
              history("/globlesearch?text=" + text);
            }}
          />
        </div>
        {/* <div className="header-ft-search-select">
          <select
            onChange={(e) => {
              setsearch(e.target.value);
            }}
          >
            <option value="topics">Topics</option>
            <option value="groups">Groups</option>
          </select>
        </div> */}

        <div
          className="header-ft-search-search-down"
          style={showlist ? { display: "block" } : { display: "none" }}
        >
          {" "}
          <ul>
            {list.map((l) => {
              return (
                <li
                  onClick={() => {
                    // handlelistClick(l);
                  }}
                >
                  <img src={Searchicon} />
                  {l.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="header-ft-search-search-down">
        <ul>
          {list.map((l) => {
            return (
              <li
                onClick={() => {
                  //   handlelistClick(l);
                }}
              >
                <img src={Searchicon} />
                {l.name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default GlobleSearch;
