import SidebarFilesList from "./SidebarFilesList";

// icon
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";

const FilesPopup = ({ setclose, openingpop }) => {
  return (
    <>
      <div className="globle-model-box for-globle-model-box">
        <div className="an-globle-model-box-inner">
          <div className="an-globle-model-heading">
            <button
              className="an-globle-back"
              onClick={() => {
                setclose({ ...openingpop, files: false });
              }}
            >
              <ArrowLeftLineIcon size="20px" />
            </button>
            <div className="an-globle-model-heading-sec">
              <h3>Files</h3>
            </div>
          </div>
          <SidebarFilesList />
        </div>
      </div>
    </>
  );
};
export default FilesPopup;
