import React from 'react'
import '../Styles/ChatBadgeMention.css'
import "../../../../Styles/Theme/Theme.css"
import MentionIcon from "../../../../components/Icons/GeneralIcons/GeneralIconsSmall/IconSMentionSmall"

const ChatBadgeMention = ({ }) => {
    return (
        <div className='chat-badge-mention'>
            <MentionIcon
                height={16}
                iconColor="#FFF"
                isFilled
                width={16}
            />
        </div>
    )
}

export default ChatBadgeMention