import React from "react";
import PropTypes from "prop-types"
function AudioFileIcon({ height = 24, width = 24}) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#90C"
                    fillRule="evenodd"
                    d="M5.86 1h8.904L21 7.5v12.64A2.866 2.866 0 0 1 18.14 23H5.86A2.862 2.862 0 0 1 3 20.14V3.86A2.857 2.857 0 0 1 5.86 1Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    d="M14.513 15.091c.268-.415.45-.943.485-1.462a1.963 1.963 0 0 0-.234-1.015c-.277-.52-.744-.808-1.203-1.095-.338-.208-.658-.408-.883-.688l-.043-.047c-.13-.168-.286-.36-.312-.52-.017-.16-.181-.272-.346-.264-.182.016-.311.144-.311.312v5.482a2.012 2.012 0 0 0-1.004-.255c-.918 0-1.662.551-1.662 1.23C9 17.45 9.744 18 10.662 18c.917 0 1.67-.551 1.67-1.23V13.18c.502.184 1.316.631 1.54 1.678-.043.056-.077.12-.13.168-.12.128-.103.32.035.44a.35.35 0 0 0 .468-.032c.086-.096.173-.2.242-.32l.026-.024Z"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
            </svg>
        </>
    );
}
AudioFileIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
export default AudioFileIcon;
