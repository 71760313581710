import ForumList from "./ForumList";

// icon
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";

const ForumListPopup = ({ setclose, openingpop }) => {
  return (
    <div className="globle-model-box for-globle-model-box">
      <div className="an-globle-model-box-inner">
        <div className="an-globle-model-heading">
          <button
            className="an-globle-back"
            onClick={() => {
              setclose({ ...openingpop, forum: false });
            }}
          >
            <ArrowLeftLineIcon size="20px" />
          </button>
          <div className="an-globle-model-heading-sec">
            <h3>Forum</h3>
          </div>
        </div>
        <ForumList />
      </div>
    </div>
  );
};
export default ForumListPopup;
