import React from 'react'
import './Spinners.css'
import "../../Styles/Theme/Theme.css"
import PropTypes from "prop-types"

const Spinners = ({ type = "brand", size = "24" }) => {
    return (
        <div className={`spinners ${type === "white" ? "white2" : type} wh-${size}`} />
    )
}

Spinners.propTyes = {
    size: PropTypes.oneOf(["16", "20", "24", "32", "40", "48"]),
    type: PropTypes.oneOf(["white", "brand"]),
}

export default Spinners 