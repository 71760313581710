const initialState = {
  selectedTickets: [],
};

const SelectedEventData = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_STATE":
      return initialState;
    case "ADD_SELECTED_TICKET_EVENT":
      return {
        ...state,
        selectedTickets: [action.payload],
      };
    case "REMOVE_SELECTED_TICKET_EVENT":
      return {
        ...state,
        selectedTickets: state.selectedTickets.filter(
          (ticket) => ticket.id !== action.payload
        ),
      };
    case "UPDATE_SELECTED_TICKET_QUANTITY":
      return {
        ...state,
        selectedTickets: state.selectedTickets.map((ticket) => {
          if (ticket.id === action.payload.id) {
            return {
              ...ticket,
              quantity: action.payload.quantity,
            };
          }
          return ticket;
        }),
      };
    case "ADD_OR_UPDATE_SELECTED_TICKET_EVENT":
      const existingTicketIndex = state.selectedTickets.findIndex(
        (ticket) => ticket.id === action.payload.id
      );

      // If ticket already exists, replace it with the new data
      if (existingTicketIndex !== -1) {
        return {
          ...state,
          selectedTickets: [
            ...state.selectedTickets.slice(0, existingTicketIndex),
            action.payload, // New data entry
            ...state.selectedTickets.slice(existingTicketIndex + 1),
          ],
        };
      } else {
        // If ticket doesn't exist, add it
        return {
          ...state,
          selectedTickets: [action.payload],
        };
      }
    default:
      return state;
  }
};

export default SelectedEventData;
