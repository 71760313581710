import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SearchAndSorting from "../../../AdminCommonComponent/TableComponent/SearchAndSorting";
import authServicePartner from "../../../../Services/auth.service.partner";
import Loader2 from "../../../../Images/loader2.gif";
import Loader from "../../../../Images/loader.gif";
import { Paper, TableContainer, TablePagination } from "@mui/material";
import { Table } from "react-bootstrap";
import TableHeader from "../../../AdminCommonComponent/TableComponent/TableHeader";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import AlertComponent from "../../../../Hooks/AlertComponent";
import authService from "../../../../Services/auth.service";
import authServiceDocument from "../../../../Services/auth.service.document";
import $ from "jquery"
import TableBodyComponentModalPagination from "../../../AdminCommonComponent/TableComponent/TableBodyComponentModalPagination";
const columns = [
    { id: "name", label: "Marcros", width: 90 },
    { id: "action", label: "Actions", width: 10 },
];

//create a object of using given key
function createData(name, action) {
    return {
        name,
        action,
    };
}
const ReasonManagement = ({ tabInformation }) => {
    const emptyObject = { reason: "" };
    const [searchParams, setSearchParams] = useSearchParams();
    const queryVariable = searchParams.get("id");
    const [rows, setRow] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [deleteId, setDeleteId] = useState("");
    const [tableAlertMsg, setTableAlertMsg] = useState("");
    const [showAlertAdd, setShowAlertAdd] = useState(false);
    const [alertMsgAdd, setAlertMsgAdd] = useState("");
    const [showAlertEdit, setShowAlertEdit] = useState(false);
    const [alertMsgEdit, setAlertMsgEdit] = useState("");
    const [allReasonList, setAllReasonList] = useState([]);
    const [addReason, setAddReason] = useState(emptyObject);
    const [addErr, setAddErr] = useState(emptyObject);
    const [editReason, setEditReason] = useState(emptyObject);
    const [editerr, setediterr] = useState(emptyObject);
    const [selectedId, setselectedId] = useState("");
    const [createInprocess, setcreateInprocess] = useState(false);
    const [editInprocess, seteditInprocess] = useState(false);
    const [editModalLoader, seteditModalLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [inProcess, setInProcess] = useState(false)
    const [totalRecord, setTotalRecord] = useState(0)
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [listIndex, setListIndex] = useState(-1);

    const getReasonListApi = (page, rowsPerPage, col, sort) => {
        let query = ""
        query += page !== undefined && page !== null && page !== "" ? `?page=${page}` : ""
        query += rowsPerPage !== undefined && rowsPerPage !== null && rowsPerPage !== "" ? `&limit=${rowsPerPage}` : ""
        query += col !== undefined && col !== null && col !== "" ? `&sortField=${col}` : ""
        query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
        authServiceDocument.listReason(query)
            .then((res) => {
                setLoader(false);
                if (res.data.data.status) {
                    setTotalRecord(res.data.data.data.totalMessages)
                    let data = res.data.data.data.list;
                    let table = [];
                    for (let i = 0; i < data.length; i++) {
                        table[i] = createData(data[i].reason, data[i]._id);
                    }
                    setRow(table);
                    setAllReasonList(table);
                    var arr = []
                    if (data && data.length !== undefined && data.length !== null && data.length > 0) {
                        data.map((item) => arr.push({ name: item.reason }))
                        setSuggestionList(arr);
                    }
                }
            })
            .catch((e) => {
                setLoader(false);
                console.log(e);
            });
    };

    useEffect(() => {
        setLoader(true);
        handleClearAllStates()
    }, [tabInformation]);

    // when component mount and page and rowsperpage change  
    useEffect(() => {
        getReasonListApi(page + 1, rowsPerPage);
    }, [page, rowsPerPage])

    //close create room module
    const handleCloseAddModel = (e) => {
        setAddReason(emptyObject);
        setAddErr(emptyObject);
        setediterr(emptyObject);
        setEditReason(emptyObject);
        handleClearAllStates()
    };
    //for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //for pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //called when edit button is clicked
    const handleEdit = (value) => {
        setselectedId(value);
        seteditModalLoader(true);
        authServiceDocument
            .viewReason(value)
            .then((res) => {
                seteditModalLoader(false);
                if (res.data.data.status) {
                    var data = res.data.data.data;
                    setEditReason({
                        reason: data.reason,
                    });
                }
            })
            .catch((e) => {
                seteditModalLoader(false);
                console.log(e);
            });
    };

    //handle delete Channel
    const handleDelete = () => {
        setInProcess(true)
        authServiceDocument
            .deleteReason(deleteId)
            .then((res) => {
                if (res.data.data.status) {
                    getReasonListApi(page + 1, rowsPerPage)
                    setInProcess(false)
                    document.querySelector('#deleteModal .close').click()
                    setTableAlertMsg(res.data.data.message);
                }
            })
            .catch((e) => {
                setInProcess(false)
                document.querySelector('#deleteModal .close').click()
                console.log(e);
            });
    };

    useEffect(() => {
        setTimeout(() => {
            if (tableAlertMsg) setTableAlertMsg("");
        }, 3000);
    }, [tableAlertMsg])


    //handle onchange for create room
    const handleAddChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        setAddReason({ reason: e.target.value });
        if (e.target.value.trim().length > 0) {
            setAddErr({ ...addErr, [e.target.name]: "" });
        }
    };
    //Create room validation and api call
    const handleCreate = () => {
        let status = true;
        let temp = {};
        if (addReason.reason.trim().length === 0) {
            temp = { ...temp, reason: "Enter a marcros!" };
            status = false;
        }
        if (status) {
            setcreateInprocess(true);
            authServiceDocument
                .createReason({
                    reason: addReason.reason,
                })
                .then((res) => {
                    setcreateInprocess(false);
                    if (res.data.data.status) {
                        setShowAlertAdd(true);
                        setAlertMsgAdd(res.data.data.message);
                        setTimeout(() => {
                            setShowAlertAdd(false);
                            setAlertMsgAdd("");
                        }, 3000);
                        getReasonListApi(page + 1, rowsPerPage);
                    } else {
                        setShowAlertAdd(true);
                        setAlertMsgAdd(res.data.data.message);
                        setTimeout(() => {
                            setShowAlertAdd(false);
                            setAlertMsgAdd("");
                        }, 3000);
                    }
                    handleClearAllStates()
                    setAddReason(emptyObject);
                })
                .catch((e) => {
                    setcreateInprocess(false);
                    handleClearAllStates()
                    console.log(e);
                });
            setAddErr(emptyObject);
        } else {
            setAddErr(temp);
        }
    };
    //
    const handleEditChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        setEditReason({ ...editReason, [e.target.name]: e.target.value });
        if (e.target.value.trim().length > 0) {
            setediterr({ ...editerr, [e.target.name]: "" });
        }
    };
    //Called when user click on update changes
    const handleEditSubmit = () => {
        var status = true;
        var tempObj = {};
        if (editReason.reason.trim().length === 0) {
            tempObj = { ...tempObj, reason: "Enter a marcros!" };
            status = false;
        }
        if (status) {
            seteditInprocess(true);
            authServiceDocument
                .editReason({
                    id: selectedId,
                    reason: editReason.reason
                })
                .then((res) => {
                    if (res.data.status) {
                        setShowAlertEdit(true);
                        setSearchText("")
                        setAlertMsgEdit(res.data.message);
                        setTimeout(() => {
                            setShowAlertEdit(false);
                            setAlertMsgEdit("");
                        }, 3000);
                        getReasonListApi(page + 1, rowsPerPage);
                        seteditInprocess(false);
                    } else {
                        setShowAlertEdit(true);
                        setAlertMsgEdit(res.data.message);
                        setTimeout(() => {
                            setShowAlertEdit(false);
                            setAlertMsgEdit("");
                        }, 3000);
                    }
                    handleClearAllStates()
                    seteditInprocess(false);
                })
                .catch((e) => {
                    console.log(e);
                    seteditInprocess(false);
                    handleClearAllStates()
                });
            setediterr(emptyObject);
        } else {
            setediterr(tempObj);
        }
    };
    //object from array
    const createObjectFromArray = (arr, updateAllEventArray = true) => {
        var tbl = [];
        for (var i = 0; i < arr.length; i++) {
            tbl[i] = createData(arr[i].name, arr[i].action);
        }
        setPage(0);
        setRow(tbl);
        if (updateAllEventArray) setAllReasonList(tbl);
    };
    //stayonpage  popup for do you want to leave page
    useEffect(() => {
        if (stayOnPage !== null && stayOnPage) {
            if (jQuery('#createReason').hasClass('show')) {
                jQuery("#createReason").modal('show')
            }

            if (jQuery('#editReason').hasClass('show')) {
                jQuery("#editReason").modal('show')
            }

        }
        else if (stayOnPage !== null && !stayOnPage) {
            if (jQuery('#createReason').hasClass('show')) { jQuery("#createReason").modal('hide') }

            if (jQuery('#editReason').hasClass('show')) { jQuery("#editReason").modal('hide') }
        }

    }, [stayOnPage])

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }

    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="uic-title">
                        <h1>Marcros</h1>
                    </div>
                    <div className="uic-title-rt-only">
                        <div className="uic-fl-btn">
                            <button
                                className="btn"
                                data-toggle="modal"
                                data-target="#createReason"
                                onClick={handleCloseAddModel}
                            >
                                Add marcros
                            </button>
                        </div>
                    </div>

                    <SearchAndSorting
                        currentArray={allReasonList}
                        createObjectFromArray={createObjectFromArray}
                        sortingOn={"name"}
                        searchOn={["name"]}
                        placeholderText={"Search"}
                        listIndex={listIndex}
                        suggestionList={suggestionList}
                        setSuggestionBox={setSuggestionBox}
                        suggestions={suggestions}
                        setSuggestions={setSuggestions}
                        suggestionBox={suggestionBox}
                        setListIndex={setListIndex}

                    />


                    {tableAlertMsg && <div className="alert alert-info">{tableAlertMsg}</div>}
                    <Paper className="uic-tb-mn">
                        <TableContainer className="uic-table-main">
                            <Table className="uic-table min-w-auto">
                                <TableHeader
                                    columns={columns}
                                    createObjectFromArray={createObjectFromArray}
                                    allEventList={allReasonList}
                                    columnsWithoutSoritng={["action"]}
                                    listApi={getReasonListApi}
                                    setLoader={setLoader}
                                    searchText={""}
                                />
                                {loader ? (
                                    <div className="cstm-data-load">
                                        <img src={Loader} />{" "}
                                    </div>
                                ) : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .length > 0 ? (
                                    <TableBodyComponentModalPagination
                                        rows={rows}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        columns={columns}
                                        editCallBack={(value) => handleEdit(value)}
                                        setDeleteId={(id) => {
                                            setDeleteId(id);
                                        }}
                                        editModalId="editReason"
                                        deleteModalId="deleteModal"
                                    />
                                ) : (
                                    <span className="no-data-found">No data found</span>
                                )}
                            </Table>
                        </TableContainer>
                        <TablePagination
                            className="uic-pagination"
                            rowsPerPageOptions={[20, 50, 100, 200]}
                            component="div"
                            count={totalRecord}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </div>
            </div>
            {/* create modal */}
            <div
                className="modal fade edit-user-details-popup"
                id="createReason"
                tabIndex="-1"
                role="dialog"
                data-backdrop="static"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Add Marcros</h2>
                            <button
                                type="button"
                                className="close"
                                //data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {
                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                    } else {
                                        jQuery("#createReason").modal('hide')
                                        handleCloseAddModel(e)
                                    }

                                }}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {showAlertAdd && (
                                <div className="alert alert-info">{alertMsgAdd}</div>
                            )}
                            <div className="form-group">
                                <div className="form-group-label">Marcros*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="Enter marcros"
                                        name="reason"
                                        onChange={handleAddChange}
                                        value={addReason.reason}
                                    />
                                    <span className="error">{addErr.reason}</span>
                                </div>
                            </div>
                        </div>
                        <div className="edit-user-details-footer cstm-create-button">
                            {createInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleCreate}
                                    disabled={createInprocess}
                                >
                                    Create
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit modal */}
            <div
                className="modal fade edit-user-details-popup"
                id="editReason"
                tabIndex="-1"
                role="dialog"
                data-backdrop="static"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    {!editModalLoader ? (
                        <div className="modal-content">
                            <div className="edit-user-details-header">
                                <h2>Edit Marcros</h2>
                                <button
                                    type="button"
                                    className="close"
                                    //data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={(e) => {
                                        if (modalInProcess) {
                                            dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                        } else {
                                            jQuery("#editReason").modal('hide')
                                            handleCloseAddModel(e)
                                        }

                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                {alertMsgEdit && (
                                    <div className="alert alert-info">{alertMsgEdit}</div>
                                )}
                                <div className="form-group">
                                    <div className="form-group-label">Marcros*</div>
                                    <div className="form-group-ct">
                                        <input
                                            type="text"
                                            placeholder="Enter marcros"
                                            name="reason"
                                            onChange={handleEditChange}
                                            value={editReason.reason}
                                        />
                                        <span className="error">{editerr.reason}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="edit-user-details-footer cstm-create-button">
                                {editInprocess ? (
                                    <div className="loader-button1">
                                        <button>In Process...</button>
                                        <span className="loader-btn">
                                            <img src={Loader2} />
                                        </span>
                                    </div>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={handleEditSubmit}
                                    >
                                        Update Changes
                                    </button>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="edit-modal-loader">
                            <img src={Loader} />
                        </div>
                    )}
                </div>
            </div>
            {/* Modal Delete */}
            <div
                className="modal fade msg-popup"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                            <img src={imgBigDelete} />
                        </div>
                        <h1>Are you sure you want to delete this reason?</h1>
                        <div className="row">
                            <div className="col-6">
                                {inProcess ? (
                                    <div className="loader-button1">
                                        <button>In Process...</button>
                                        <span className="loader-btn">
                                            <img src={Loader2} />
                                        </span>
                                    </div>
                                ) : (
                                    <span className="msg-btn">
                                        <button
                                            type="button"
                                            className="btn btn-red"
                                            onClick={handleDelete}
                                        >
                                            Delete
                                        </button>
                                    </span>)}
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" className="btn2" data-dismiss="modal">
                                        Discard
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertComponent />
        </>
    );
};

export default ReasonManagement;