import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    cloneSession: builder.mutation({
      query: ({ body }) => {
        return {
          url: ApiRoutes.session.cloneSession.url,
          method: ApiRoutes.session.cloneSession.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useCloneSessionMutation } = extendedApi;
