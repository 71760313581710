import React from "react";

const IconNavPartner = () => {
  return (
    <div className="mds-icons">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fill="#000"
          d="M8 2.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5H8Z"
        />
        <path
          fillRule="evenodd"
          d="M1 9.5A4.5 4.5 0 0 1 5.5 5h13A4.5 4.5 0 0 1 23 9.5v8a4.5 4.5 0 0 1-4.5 4.5h-13A4.5 4.5 0 0 1 1 17.5v-8Zm1.5 2.898V17.5a3 3 0 0 0 3 3h13a3 3 0 0 0 3-3v-5.102L15 14.023v.477a2.5 2.5 0 0 1-2.5 2.5h-1A2.5 2.5 0 0 1 9 14.5v-.477l-6.5-1.625Zm6.697.128A2.5 2.5 0 0 1 11.5 11h1a2.5 2.5 0 0 1 2.303 1.526l6.697-1.674V9.5a3 3 0 0 0-3-3h-13a3 3 0 0 0-3 3v1.352l6.697 1.674ZM11.5 12.5h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavPartner;
