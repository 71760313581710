import React, { useEffect, useState, useRef } from "react";
import { Tooltip } from '@mui/material';
import ClosePopup from "../../../Images/close-popup.svg";
import CloseGmv from "../../../Images/CloseGmv.svg";
import IcnMCopy from "../../../Images/icn_m_copy.svg";
import Slider from "react-slick";
import authService from "../../../Services/auth.service";
import VideoIconWhite from "../../../Images/video-icon-white.svg";
import { saveAs } from "file-saver";
import Loader from "../../../Images/loader.gif";
import moment from "moment";

const MediaDetailsPopup = ({
  allMedia,
  onClose,
  categoryDetails,
  currentImageId,
  AllMediasData,
  getMediaDataAPI
}) => {

  const [showMediaDetails, setMediaDetails] = useState([]);
  const [showCategoryOption, setCategoryOption] = useState(false);
  const [showCategorySelected, setCategorySelected] = useState([]);
  const [showCurrentIndexImage, setCurrentIndexImage] = useState();
  const [showAllMedia, setAllMedia] = useState(AllMediasData)
  const [copyUrlLoader, setcopyUrlLoader] = useState();
  const [showDeleteMessage, setDeleteMessage] = useState("")
  const [showSaveLoader, setSaveLoader] = useState(false);
  const [shwoDeleteLoader, setDeleteLoader] = useState(false)
  const [showSaveMessage, setSaveMessage] = useState("");
  const [formData, setFormData] = useState({
    altText: "",
    title: "",
    categories: [],
    description: "",
  });
  const slickRef = useRef(null);
  useEffect(() => {
    if (showAllMedia) {
      const currentMedia = showAllMedia.filter((data) => data._id === currentImageId);
      console.log(currentMedia, "currentMedia")
      if (currentMedia.length) {
        setMediaDetails(currentMedia);
        if (currentMedia.length !== 0) {
          setFormData({
            altText: currentMedia[0].altText || "", // If altText is null, set an empty string
            title: currentMedia[0].title || "", // If title is null, set an empty string
            categories: currentMedia[0].category || [], // Assuming categories is an array
            description: currentMedia[0].description || "", // If description is null, set an empty string
          });
        }
      }
      console.log(currentMedia, "currentMedia")
    }
    const index = showAllMedia.findIndex((data) => data._id === currentImageId);
    setCurrentIndexImage(index);
    slickRef.current = index
  }, [currentImageId]);

  useEffect(() => {
    const currentImageIndex = showAllMedia.findIndex(
      (data) => data._id === currentImageId
    );
    setCurrentIndexImage(currentImageIndex);
  }, []);

  const handleAfterChange = (current, next) => {
    const index = next > current ? next : current;
    setCurrentIndexImage(showCurrentIndexImage);
    slickRef.current = index

    // Update media details based on the new currentImageId
    const nextImageId = showAllMedia[index]._id;
    const newMedia = showAllMedia.filter((data) => data._id === nextImageId);
    setMediaDetails(newMedia);
    setFormData({
      altText: newMedia[0].altText || "", // Set an empty string if altText is null
      title: newMedia[0].title || "", // Set an empty string if title is null
      categories: newMedia[0].category || [], // Assuming newMedia.category is an array
      description: newMedia[0].description || "", // Set an empty string if description is null
    });
  };

  const handleBeforeChange = (current, next) => {
    console.log(current, "current handleBeforeChange")
    console.log(next, "next handleBeforeChange")
  }

  // //settings for slider
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: showCurrentIndexImage,
    afterChange: handleAfterChange,
    beforeChange: handleBeforeChange
  };

  console.log(showCategorySelected, "showCategorySelected")

  const onClickCategoryRemove = (categoryId) => {
    console.log("remove calling")
    console.log("categoryId to remove:", categoryId);
    setCategorySelected((prev) =>
      prev.filter((category) => {
        console.log("category.id:", category.id);
        return category.id !== categoryId;
      })
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      categories: prevFormData.categories.filter((category) => category.id !== categoryId),
    }));
  };

  const handleCategoryClick = () => {
    setCategoryOption(!showCategoryOption);
  };

  const onCopyUrl = (url, data) => {
    setcopyUrlLoader(data)
    console.log(url, "url");
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setcopyUrlLoader();
    }, 1000);
  };


  const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const sizeInFormattedUnits = (bytes / Math.pow(k, i)).toFixed(i === 0 ? 0 : 1);
    return `${sizeInFormattedUnits} ${sizes[i]}`;
  };

  const onClickDeleteImage = async (id, type) => {
    setDeleteLoader(true)
    console.log(id, "od")
    const body = {
      ids: [id]
    }
    const response = await authService.deleteMedia(body);
    console.log(response, "response")
    if (response.data.status) {
      const currentMedia = showAllMedia.filter((data) => {
        return data._id !== id;
      });
      console.log(currentMedia, "AllMediasData");
      setAllMedia(currentMedia);
      const currentImageIndex = showAllMedia.findIndex(
        (data) => data._id === id
      );
      const nextImageId = showAllMedia[currentImageIndex + 1]._id;
      const newMedia = showAllMedia.filter((data) => data._id === nextImageId);
      console.log(newMedia, "NewMedia");
      setMediaDetails(newMedia)
      setFormData({
        altText: newMedia[0].altText || "", // Set an empty string if altText is null
        title: newMedia[0].title || "", // Set an empty string if title is null
        categories: newMedia[0].category || [], // Assuming newMedia.category is an array
        description: newMedia[0].description || "", // Set an empty string if description is null
      });
      setDeleteLoader(false)
      setDeleteMessage(`${type} deleted successfully`)
      getMediaDataAPI(1);
    }
  }

  setTimeout(() => {
    setDeleteMessage("");
    setSaveMessage("");
  }, 2500);


  const onSaveFormData = async (id, type) => {
    setSaveLoader(true)
    const bodyData = {
      id: id,
      title: formData.title,
      category: formData.categories.map((cat) => cat.id),
      description: formData.description,
      altText: formData.altText,
    };
    // Perform the save operation using bodyData
    console.log(bodyData, "bodyData");

    const response = await authService.editMediaDetails(bodyData);
    console.log(response, "response")
    if (response.data.status) {
      setSaveMessage(`${type} data saved successfully`)
      setSaveLoader(false)
      getMediaDataAPI(1);
    } else {
      setSaveMessage(`${type} data not save.please try again`)
      setSaveLoader(false)
    }

  };

  const onAltTextChange = (value) => {
    setFormData((prev) => ({ ...prev, altText: value }));
  };

  const onTitleChange = (value) => {
    setFormData((prev) => ({ ...prev, title: value }));
  };

  const onClickCategoryAdd = (categoryId, categoryName) => {
    if (!showCategorySelected.some((category) => category.id === categoryId)) {
      setCategorySelected((prev) => [
        ...prev,
        { id: categoryId, name: categoryName },
      ]);

      setFormData((prev) => ({
        ...prev,
        categories: [...prev.categories, { id: categoryId, name: categoryName }],
      }));
    }
    setCategoryOption(!showCategoryOption);
  };

  const onDescriptionChange = (value) => {
    setFormData((prev) => ({ ...prev, description: value }));
  };

  return (
    <>
      <div
        className="modal fade media-attachment-details"
        id="mediaModel"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="media-attachment-details-header">
              <h4 className="madh-h4">Attachment details</h4>
              <button
                type="button"
                className="madh-close"
                aria-label="Close"
                onClick={(e) => {
                  $("#mediaModel").modal("hide");
                  onClose(e);
                }}
              >
                <img src={ClosePopup} />
              </button>
            </div>
            <div className="media-attachment-details-body">
              {showDeleteMessage && (
                <div className="media-delete-message-alert">{showDeleteMessage}</div>
              )}

              {showSaveMessage && (
                <div className="media-delete-message-alert">{showSaveMessage}</div>
              )}
              <div className="media-attachment-details-left">
                <div className="media-attachment-details-left-main">
                  <div className="media-attachment-slider">
                    <Slider {...sliderSettings}>
                      {showAllMedia.map((data) =>
                        data.type === "image" && !data.isProgress ? (
                          <img
                            src={data.urls.length > 1 ? data.urls[4]?.url : data.urls[0]?.url}
                            className="media-slider-img-only"
                          />
                        ) : data.type === "video" && !data.isProgress ? (
                          <video className="media-slider-video-only" controls preload="true">
                            <source key={data.version} src={data.urls[0]?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          ""
                        )
                      )}
                    </Slider>

                    {/* <Flickity
                      className={'carousel'} // default ''
                      elementType={'div'} // default 'div'
                      // options={flickityOptions} // takes flickity options {}
                      disableImagesLoaded={false} // default false
                      reloadOnUpdate // default false
                      flickityRef={flickityRef}
                    >
                      {showAllMedia.map((data, index) =>
                        data.type === "image" && !data.isProgress ? (
                          <img
                            src={data.urls[0]?.url}
                            className="media-slider-img-only"
                            data-id={index}
                          />
                        ) : data.type === "video" && !data.isProgress ? (
                          <video className="media-slider-video-only" controls data-id={index}>
                            <source src={data.urls[0]?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          ""
                        )
                      )}
                    </Flickity> */}

                  </div>
                </div>
              </div>
              {showMediaDetails &&
                showMediaDetails.map((data, index) => {
                  return (
                    <div className="media-attachment-details-right scrollbar">
                      <div className="mad-image-box">
                        <div className="mad-image-box-left">
                          {data.type === "image" ? (
                            <img src={data.urls[0]?.url} alt={data.title} className="media-details-video" />
                          ) : data.type === "video" ? (
                            // <video controls>
                            //   <source
                            //     src={data.urls[0]?.url}
                            //     type="video/mp4"
                            //   />
                            //   Your browser does not support the video tag.
                            // </video>
                            <>
                              <img
                                src={data.videoThumbnailUrl}
                                alt={`Preview ${data.title || `Media ${index}`
                                  }`}
                                className="media-details-video"
                              />
                              <div className="media-popup-video" >
                                <img src={VideoIconWhite} alt="Video Icon" />
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mad-image-box-right">
                          <h5>{data.title}</h5>
                          <h6>
                            {moment(data.createdAt).format("DD MMM, YYYY")}
                          </h6>
                          {/* {data.urls[0]?.size !== undefined &&
                            <h6>{formatBytes(data.urls[0]?.size)}</h6>} */}
                          {/* {data.type !== "video" ?
                            data.urls[0]?.width !== undefined &&
                            <h6>{data.urls[0]?.width}x{data.urls[0]?.height}px</h6> : data.videoWidth !== undefined &&
                            <h6>{data.videoWidth}x{data.videoHeight}px</h6>
                          } */}
                          {data.type !== "image" && data.videoDuration !== undefined &&
                            <h6> {moment.utc(data.videoDuration * 1000).format('HH:mm:ss')}</h6>}
                        </div>
                      </div>
                      <hr className="mad-hr" />

                      <div className="mad-field-box">
                        <div className="mad-field-box-main">
                          <h6>Alt text</h6>
                          <input
                            className="mad-text-field"
                            type="text"
                            value={formData.altText}
                            onChange={(e) => onAltTextChange(e.target.value)}
                          />
                        </div>

                        <div className="mad-field-box-main">
                          <h6>Title</h6>
                          <input
                            className="mad-text-field"
                            type="text"
                            value={formData.title}
                            onChange={(e) => onTitleChange(e.target.value)}
                          />
                        </div>

                        <div className="mad-field-box-main">
                          <h6>Categories</h6>

                          <div className="categories-select-box">
                            <span
                              onClick={handleCategoryClick}
                              className={`categories-select-label ${showCategoryOption && "categories-up-arrow"}`}
                            >
                              Select
                            </span>
                            {showCategoryOption && (
                              <ul className="categories-select-list scrollbar">
                                {categoryDetails.length > 0 &&
                                  categoryDetails.map((catData) => (
                                    <li
                                      className=""
                                      key={catData._id}
                                      onClick={() =>
                                        onClickCategoryAdd(
                                          catData._id,
                                          catData.name
                                        )
                                      }
                                    >
                                      {catData.name}
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </div>
                          {formData.categories.length !== 0 &&
                            <div className="mad-categories">
                              {formData.categories.map((selectData, index) => (
                                <div key={index}>
                                  <span className="mad-categories-badge">{selectData.name}
                                    <img
                                      src={CloseGmv}
                                      onClick={() => onClickCategoryRemove(selectData.id)}
                                    />
                                  </span>
                                </div>
                              ))}
                            </div>
                          }
                        </div>

                        <div className="mad-field-box-main">
                          <h6>Description</h6>
                          <textarea
                            className="mad-text-textarea"
                            value={formData.description}
                            onChange={(e) =>
                              onDescriptionChange(e.target.value)
                            }
                          />
                        </div>

                        <div className="mad-save-formdata-btn">
                          <button
                            className="mad-save-btn"
                            onClick={() => onSaveFormData(data._id, data.type)}
                            disabled={showSaveLoader ? true : false}
                          >
                            {showSaveLoader &&
                              <span className="savedata-loader-img" >
                                <img src={Loader} alt="Loading..." />
                              </span>
                            }Save
                          </button>
                        </div>
                      </div>

                      <hr className="mad-hr" />

                      {data.type !== "video" ?
                        <>

                          <div className="mad-copy-url">
                            <h5>Copy URL</h5>
                            <ul className="mad-copy-url-list">
                            {/* <h6>{data.videoWidth}x{data.videoHeight}px</h6> */}
                            <Tooltip title={
                                        <div>
                                          <div>{formatBytes(data.urls[4]?.size)}</div>
                                          <div>{data.urls[4]?.width}x{data.urls[4]?.height} px</div>
                                        </div>} arrow>
                              <li onClick={() => onCopyUrl(data.urls[4]?.url, 4)}><span className="mad-copy-url-list-text">100%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>

                              <Tooltip title={
                                        <div>
                                          <div>{formatBytes(data.urls[3]?.size)}</div>
                                          <div>{data.urls[3]?.width}x{data.urls[3]?.height} px</div>
                                        </div>} arrow>
                              <li onClick={() => onCopyUrl(data.urls[3]?.url, 3)}><span className="mad-copy-url-list-text">80%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>

                              <Tooltip title={
                                        <div>
                                          <div>{formatBytes(data.urls[2]?.size)}</div>
                                          <div>{data.urls[2]?.width}x{data.urls[2]?.height} px</div>
                                        </div>} arrow>
                              <li onClick={() => onCopyUrl(data.urls[2]?.url, 2)}><span className="mad-copy-url-list-text">60%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>

                              <Tooltip title={
                                        <div>
                                          <div>{formatBytes(data.urls[1]?.size)}</div>
                                          <div>{data.urls[1]?.width}x{data.urls[1]?.height} px</div>
                                        </div>} arrow>
                              <li onClick={() => onCopyUrl(data.urls[1]?.url, 1)}><span className="mad-copy-url-list-text">40%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>

                              <Tooltip title={
                                        <div>
                                          <div>{formatBytes(data.urls[0]?.size)}</div>
                                          <div>{data.urls[0]?.width}x{data.urls[0]?.height} px</div>
                                        </div>} arrow>
                              <li onClick={() => onCopyUrl(data.urls[0]?.url, 0)}><span className="mad-copy-url-list-text">20%</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>
                            </ul>
                          </div>
                          {/* <div className="mad-copy-url">
                            <div className="mad-field-box-left">
                              <h5>Copy URL</h5>
                            </div>
                            {data.urls.length !== 1 ?
                              copyUrlLoader === 4 ?
                                <button className="share-btn-details" disabled>Copied!</button> :
                                <div className="mad-field-box-right">
                                  <button
                                    className="copy-url-icon-btn"
                                    onClick={() => onCopyUrl(data.urls[4]?.url, 4)}
                                  >
                                    100%
                                    <img src={IcnMCopy} />
                                  </button>
                                </div>
                              : ""
                            }
                            {data.urls.length !== 1 ? copyUrlLoader === 3 ?
                              <button className="share-btn-details" disabled>Copied!</button> :
                              <div className="mad-field-box-right">
                                <button
                                  className="copy-url-icon-btn"
                                  onClick={() => onCopyUrl(data.urls[3]?.url, 3)}
                                >
                                  80%
                                  <img src={IcnMCopy} />
                                </button>
                              </div>
                              : ""
                            }
                            {data.urls.length !== 1 ? copyUrlLoader === 2 ?
                              <button className="share-btn-details" disabled>Copied!</button> :
                              <div className="mad-field-box-right">
                                <button
                                  className="copy-url-icon-btn"
                                  onClick={() => onCopyUrl(data.urls[2]?.url, 2)}
                                >
                                  60%
                                  <img src={IcnMCopy} />
                                </button>
                              </div>
                              : ""
                            }
                            {data.urls.length !== 1 ? copyUrlLoader === 1 ?
                              <button className="share-btn-details" disabled>Copied!</button> :
                              <div className="mad-field-box-right">
                                <button
                                  className="copy-url-icon-btn"
                                  onClick={() => onCopyUrl(data.urls[1]?.url, 1)}
                                >
                                  40%
                                  <img src={IcnMCopy} />
                                </button>
                              </div>
                              : ""
                            }
                            {copyUrlLoader === 0 ?
                              <button className="share-btn-details" disabled>Copied!</button> :
                              <div className="mad-field-box-right">
                                <button
                                  className="copy-url-icon-btn"
                                  onClick={() => onCopyUrl(data.urls[0]?.url, 0)}
                                >
                                  20%
                                  <img src={IcnMCopy} />
                                </button>
                              </div>
                            }
                          </div> */}
                        </>
                        :
                        <>
                          <div className="mad-copy-url">
                            <h5>Copy URL</h5>
                            <ul className="mad-copy-url-list">

                            {data.urls[2] !== undefined ?
                               <Tooltip title={
                                <div>
                                  <div>{formatBytes(data.urls[2]?.size)}</div> 
                                </div>} arrow> 
                                <li className={`${data.urls[2]?.isProgress ? "li-disabled" : "li-enabled"}`} onClick={() => onCopyUrl(data.urls[2]?.url, 2)}><span className="mad-copy-url-list-text">1080p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>
                              : 
                              <li className="li-disabled"><span className="mad-copy-url-list-text">1080p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li> 
                              }

                          {data.urls[1] !== undefined ?
                               <Tooltip title={
                                <div>
                                  <div>{formatBytes(data.urls[1]?.size)}</div> 
                                </div>} arrow>
                                <li className={`${data.urls[1]?.isProgress ? "li-disabled" : "li-enabled"}`} onClick={() => onCopyUrl(data.urls[1]?.url, 1)}><span className="mad-copy-url-list-text">720p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>
                              : 
                              <li className="li-disabled"><span className="mad-copy-url-list-text">720p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li> 
                              } 

                              <Tooltip title={
                              <div>
                                <div>{formatBytes(data.urls[0]?.size)}</div> 
                              </div>} arrow>
                              <li onClick={() => onCopyUrl(data.urls[0]?.url, 0)}><span className="mad-copy-url-list-text">480p</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li></Tooltip>


                              
                              <li onClick={() => onCopyUrl(data.audioFileUrl, 3)}><span className="mad-copy-url-list-text">Audio</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li>
 
                              <li onClick={() => onCopyUrl(data.vttFileUrl, 4)}><span className="mad-copy-url-list-text">.vtt</span><img src={IcnMCopy} className="mad-copy-url-list-icon" /></li>
                            </ul>
                          </div>
                        </>
                      }

                      <div className="mad-delete-download">
                        <span className="mad-delete-link" onClick={() => onClickDeleteImage(data._id, data.type)}>Delete {data.type !== "image" ? "video" : "image"}
                          {shwoDeleteLoader &&
                            <span className="mad-delete-loader-img"> <img src={Loader} alt="Loading..." /></span>
                          }
                        </span>

                        <span className="mad-download-link" onClick={() => {
                          saveAs(data.urls[0]?.url, data.title);
                        }}>
                          Download {data.type !== "image" ? "video" : "image"}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaDetailsPopup;

