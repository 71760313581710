/ global google /
import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from "@react-google-maps/api";
import authService from "../../Services/auth.service";
import Loader from "../../Images/loader.gif";
import defaultIconUrl from '../../Images/mapMarker.svg'; // Replace with your marker icon
import hoverIconUrl from '../../Images/mapMarker.svg'; // Replace with your marker icon
import NavChatIcon from "../../components/Icons/NavIcons/NavChatIcon";

function Map() {
  const [activeMarker, setActiveMarker] = useState(null);
  const [count, setCount] = React.useState(0);
  const [mapData, setMapData] = React.useState([]);
  const [showAuthToken, setAuthToken] = useState(false);
  const [showErrorHandling, setErrorHandling] = useState(false)
  const [showErrorHandleMessage, setErrorHandleMessage] = useState("")
  const [showCenterLocation, setCenterLocation] = useState({})
  const [mapRef, setMapRef] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState();
  const [loader, setLoader] = useState(false);
  const markers = mapData;
  const [currentIcon, setCurrentIcon] = React.useState(defaultIconUrl);
  const navigate = useNavigate();
  // Define your custom map style
// const customMapStyle = [
//   {
//     "elementType": "geometry",
//     "stylers": [
//       { "color": "#6c93a7" }
//     ]
//   },
//   {
//     "elementType": "labels.icon",
//     "stylers": [
//       { "visibility": "off" }
//     ]
//   },
//   {
//     "elementType": "labels.text.fill",
//     "stylers": [
//       { "color": "#757575" }
//     ]
//   },
//   {
//     "elementType": "labels.text.stroke",
//     "stylers": [
//       { "color": "#212121" }
//     ]
//   },
//   {
//     "featureType": "administrative",
//     "elementType": "geometry",
//     "stylers": [
//       { "color": "#757575" }
//     ]
//   },
//   {
//     "featureType": "administrative.country",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       { "color": "#9e9e9e" }
//     ]
//   },
//   {
//     "featureType": "administrative.land_parcel",
//     "stylers": [
//       { "visibility": "off" }
//     ]
//   },
//   {
//     "featureType": "administrative.locality",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       { "color": "#bdbdbd" }
//     ]
//   },
//   {
//     "featureType": "poi",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       { "color": "#757575" }
//     ]
//   },
//   {
//     "featureType": "poi.park",
//     "elementType": "geometry",
//     "stylers": [
//       { "color": "#6c93a7" }
//     ]
//   },
//   {
//     "featureType": "poi.park",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       { "color": "#616161" }
//     ]
//   },
//   {
//     "featureType": "poi.park",
//     "elementType": "labels.text.stroke",
//     "stylers": [
//       { "color": "#1b1b1b" }
//     ]
//   },
//   {
//     "featureType": "road",
//     "elementType": "geometry.fill",
//     "stylers": [
//       { "color": "#2c2c2c" }
//     ]
//   },
//   {
//     "featureType": "road",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       { "color": "#8a8a8a" }
//     ]
//   },
//   {
//     "featureType": "road.arterial",
//     "elementType": "geometry",
//     "stylers": [
//       { "color": "#373737" }
//     ]
//   },
//   {
//     "featureType": "road.highway",
//     "elementType": "geometry",
//     "stylers": [
//       { "color": "#3c3c3c" }
//     ]
//   },
//   {
//     "featureType": "road.highway.controlled_access",
//     "elementType": "geometry",
//     "stylers": [
//       { "color": "#4e4e4e" }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       { "color": "#616161" }
//     ]
//   },
//   {
//     "featureType": "transit",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       { "color": "#757575" }
//     ]
//   },
//   {
//     "featureType": "water",
//     "elementType": "geometry",
//     "stylers": [
//       { "color": "#0d445d" }
//     ]
//   },
//   {
//     "featureType": "water",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       { "color": "#3d3d3d" }
//     ]
//   }
// ];


  useEffect(() => {
    getAllUserApi()
  }, [])

  async function getAllUserApi() {
    try {
      setLoader(true)
      const getAllUserRes = await authService.getAllUsersForMap()
      if (getAllUserRes.status) {
        setMapData(getAllUserRes.data.data)
        setLoader(false)
      }
    } catch (error) {
      setLoader(false)
      return false
    }
  }


  const handleMarkerClick = (index, lat, lng, id, address, FacebookProfileLink, AboutMe, name, hobbies, profileImg, goodAt) => {
    mapRef?.panTo({ lat, lng });
    setInfoWindowData({ id, address, FacebookProfileLink, AboutMe, name, hobbies, profileImg, goodAt });
    setIsOpen(true);
  };

  var centerMarker = {
    lat: markers.length > 0 ? markers[0].lat : 47.116386,
    lng: markers.length > 0 ? markers[0].lng : -101.299591
  }

  const handleOnLoad = (map) => {
    const bounds = new google.maps.LatLngBounds();
    markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const handleMouseOver = () => {
    setCurrentIcon(hoverIconUrl);
  };

  const handleMouseOut = () => {
    setCurrentIcon(defaultIconUrl);
  };

  return (
    <>
      {!loader ?
        <GoogleMap
          zoom={3}
          center={centerMarker}
          onLoad={handleOnLoad}
          onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ height: "604px" }}
          // options={{
          //   styles: customMapStyle, // Apply the custom style here
          // }}
        >

          <MarkerClusterer>
            {clusterer =>

              markers.map(({ lat, lng, id, address, FacebookProfileLink, AboutMe, name, hobbies, profileImg, goodAt }, index) => (
                <Marker 
                icon={{
                  url: currentIcon, // URL to your custom marker image
                  scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(20, 40), // Position of the marker relative to its origin
                }}
                clusterer={clusterer} key={index} position={{ lat, lng }} onClick={() => {
                  handleMarkerClick(index, lat, lng, id, address, FacebookProfileLink, AboutMe, name, hobbies, profileImg, goodAt);
                }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                >


                  {isOpen && infoWindowData?.id === id && (
                    <InfoWindow
                      onCloseClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <div className="user-cstm-map-dt">
                        <div className="all-content-main-edit-profile-upload">
                          <div className="cstm-profile-upload-img">
                            {(infoWindowData.profileImg !== undefined && infoWindowData.profileImg !== null &&
                              infoWindowData.profileImg.length > 0) ? (
                              <img
                                className="cstm-map-ld-user-img"
                                src={infoWindowData.profileImg}
                              />
                            ) : (
                              <div className="cstm-map-upload-avar">
                                {infoWindowData.name && infoWindowData.name !== ""
                                  ? infoWindowData.name.charAt(0).toUpperCase()
                                  : ""}
                              </div>
                            )}
                            <a onClick={()=>{ 
                              const chatUrl = encodeURI(`chats/${id}/user?user_name=${name}&user_image=${profileImg}`)
                              window.open(chatUrl, "_blank");
                              }}><NavChatIcon/></a>
                          </div>

                        </div>

                        <div className="map_tbl_ttl">
                          <a  onClick={()=>{ 
                              const chatUrl = encodeURI(`chats/${id}/user?user_name=${name}&user_image=${profileImg}`)
                              window.open(chatUrl, "_blank");
                              }}>{infoWindowData.name}</a>

                        </div>
                        {/* {
                          infoWindowData.FacebookProfileLink && infoWindowData.FacebookProfileLink !== "" &&
                          <div className="user-map-lable">
                            <span className="user_map_left">
                              Facebook Profile Link:
                            </span>
                            <span className="off-loc-name">{infoWindowData.FacebookProfileLink} </span>
                          </div>
                        } */}
                        {
                          infoWindowData.AboutMe && infoWindowData.AboutMe !== "" &&
                          <div className="user-map-lable">
                            <span className="user_map_left">
                              About Me:
                            </span>
                            <span className="off-loc-name">{infoWindowData.AboutMe}</span>
                          </div>
                        }
                        {
                          infoWindowData.hobbies && infoWindowData.hobbies !== "" &&
                          <div className="user-map-lable">
                            <span className="user_map_left">
                              Hobbies:
                            </span>
                            <span className="off-loc-name">{infoWindowData.hobbies}</span>
                          </div>
                        }
                        {
                          infoWindowData.goodAt && (infoWindowData.goodAt !== "" || infoWindowData.goodAt.length !== 0) &&
                          <div className="user-map-lable">
                            <span className="user_map_left">
                              I am good at:
                            </span>
                            <span className="off-loc-name">{infoWindowData.goodAt.join(", ")}</span>
                          </div>
                        }

                      </div>


                    </InfoWindow>
                  )}

                </Marker>
              ))}
          </MarkerClusterer>
        </GoogleMap>
        : <>
          <div className="map-data-loading">
            <img src={Loader} alt="Loading..." />
          </div>
        </>}
    </>
  );
}


export default Map;
