import React, { useMemo, useState, useCallback, useEffect } from "react";
import { createEditor, Transforms } from "slate";
import { Slate, Editable, withReact } from "slate-react";

const EditableTextArea = ({
  excelFileEmails,
  setExcelFileEmails,
  selectedUsers,
  allParticipantsEmailArr,
  clickedCheckboxUser,
  setSelectedUsers,
  allUserList,
  allRegisterdUserList,
  setClickedCheckboxUser,
  setNonSelectedUsers,
  setNonEmailText,
  setRegisteredUsers,
  setAttendees,
  alreadySelectedEventUsersEmail,
  alreadySelectCount,
  setAlreadySelectCount,
  totalUserList,
  setTotalUserList,
  setTotalUsers,
  totalUsers,
  totalUserEventList,
  totalUserTagList,
  alreadySelectedTagUsersEmail,
}) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const initialValue = [
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ];

  const [value, setValue] = useState(initialValue);
  const emailRegex = /\b[\w.+-]+@[\w.-]+\.\w{2,10}\b/g;

  useEffect(() => {
    let finalString = "";
    const registeredParticipants = [];
    const Attendees = [];
    const alreadySelectedEventUsers = [];

    let emailArray = (
      (value[0].children[0].text ? value[0].children[0].text + "," : "") +
      excelFileEmails?.join(",")
    ).split(",");

    const nonMatchingEmails = emailArray.filter((emailData) => {
      return (
        !totalUserTagList.some(
          (user) => user["Preferred Email"] === emailData
        ) &&
        !totalUserEventList.some(
          (eventUser) => eventUser["Preferred Email"] === emailData
        )
      );
    });

    // Manual email validation function
    let nonEmailWords = [];
    const manualValidateEmails = emailArray.filter((email) => {
      const atIndex = email.indexOf("@");
      const dotIndex = email.lastIndexOf(".");
      if (
        atIndex > 0 && // '@' should not be the first character
        dotIndex > atIndex + 1 && // '.' should be after '@' and not immediately after '@'
        dotIndex < email.length - 1 && // '.' should not be the last character
        email.indexOf("@", atIndex + 1) === -1 // Only one '@' symbol should be present
      ) {
        return true;
      } else {
        nonEmailWords.push(email);
      }
      setNonEmailText(
        [...new Set([...nonEmailWords])].filter((email) => email !== "")
      );
    });

    const totalUserArr = allUserList.map((user) => user["Preferred Email"]);
    const nonSelectedUserArr = manualValidateEmails.filter(
      (item) => !totalUserArr.includes(item)
    );
    setNonSelectedUsers(
      [...new Set([...nonSelectedUserArr])].filter((email) => email !== "")
    );

    if (allRegisterdUserList.length !== 0) {
      for (let i = 0; i < allUserList.length; i++) {
        const item = allUserList[i];
        const email = item["Preferred Email"];

        if (manualValidateEmails.includes(email)) {
          if (
            alreadySelectedEventUsersEmail?.includes(email) ||
            alreadySelectedTagUsersEmail?.includes(email)
          ) {
            alreadySelectedEventUsers.push(item);
          } else if (allRegisterdUserList?.includes(email)) {
            registeredParticipants.push(item);
          } else {
            Attendees.push(item);
          }
        }
      }
    }

    setRegisteredUsers(registeredParticipants);
    setAttendees(Attendees);
    setSelectedUsers(
      [...Attendees, ...registeredParticipants].filter((email) => email !== "")
    );
    setTotalUserList([
      ...totalUserEventList,
      ...totalUserTagList,
      ...Attendees,
      ...registeredParticipants,
    ]);
    setTotalUsers(
      [
        ...totalUserEventList,
        ...totalUserTagList,
        ...Attendees,
        ...registeredParticipants,
      ].length
    );
    let uniqueEmails = new Set(nonMatchingEmails);
    finalString = Array.from(uniqueEmails).join(",");
    if (finalString !== value?.[0]?.children?.[0]?.text) {
      const newValue = [
        {
          type: "paragraph",
          children: [{ text: finalString }],
        },
      ];
      setValue(newValue);
      editor.children = newValue;
      Transforms.select(editor, {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: finalString.length },
      });
    }
  }, [allParticipantsEmailArr, allRegisterdUserList]);

  useEffect(() => {
    let finalString = "";
    const registeredParticipants = [];
    const Attendees = [];
    const alreadySelectedEventUsers = [];
    if (excelFileEmails?.length) {
      let emailArray = (
        (value[0].children[0].text ? value[0].children[0].text + "," : "") +
        excelFileEmails?.join(",")
      ).split(",");
      // Manual email validation function
      let nonEmailWords = [];
      const manualValidateEmails = emailArray.filter((email) => {
        const atIndex = email.indexOf("@");
        const dotIndex = email.lastIndexOf(".");
        if (
          atIndex > 0 && // '@' should not be the first character
          dotIndex > atIndex + 1 && // '.' should be after '@' and not immediately after '@'
          dotIndex < email.length - 1 && // '.' should not be the last character
          email.indexOf("@", atIndex + 1) === -1 // Only one '@' symbol should be present
        ) {
          return true;
        } else {
          nonEmailWords.push(email);
        }
        setNonEmailText(
          [...new Set([...nonEmailWords])].filter((email) => email !== "")
        );
      });

      const totalUserArr = allUserList.map((user) => user["Preferred Email"]);
      const nonSelectedUserArr = manualValidateEmails.filter(
        (item) => !totalUserArr.includes(item)
      );
      setNonSelectedUsers(
        [...new Set([...nonSelectedUserArr])].filter((email) => email !== "")
      );

      for (let i = 0; i < allUserList.length; i++) {
        const item = allUserList[i];
        const email = item["Preferred Email"];
        if (manualValidateEmails.includes(email)) {
          if (
            alreadySelectedEventUsersEmail?.includes(email) ||
            alreadySelectedTagUsersEmail?.includes(email)
          ) {
            alreadySelectedEventUsers.push(item);
          } else if (allRegisterdUserList?.includes(email)) {
            registeredParticipants.push(item);
          } else {
            Attendees.push(item);
          }
        }
      }
      setRegisteredUsers(registeredParticipants);
      setAttendees(Attendees);
      setAlreadySelectCount(alreadySelectedEventUsers);
      setSelectedUsers(
        [...Attendees, ...registeredParticipants].filter(
          (email) => email !== ""
        )
      );
      setTotalUserList([
        ...totalUserEventList,
        ...totalUserTagList,
        ...Attendees,
        ...registeredParticipants,
      ]);
      setTotalUsers(
        [
          ...totalUserEventList,
          ...totalUserTagList,
          ...Attendees,
          ...registeredParticipants,
        ].length
      );
      let uniqueEmails = new Set(emailArray);
      finalString = Array.from(uniqueEmails).join(",");
    } else {
      const currentValueStringArr = value[0].children[0].text.split(",");
      const selectedUsersArr = selectedUsers.map(
        (user) => user["Preferred Email"]
      );

      const emails = new Set(
        [...currentValueStringArr, ...selectedUsersArr].filter((email) =>
          email?.trim()
        )
      );
      const result = [...emails].join(",");

      const removeEmail = (str, email) => {
        const emailArray = str.split(",");
        const filteredEmails = emailArray.filter(
          (item) => item?.trim() !== email
        );
        return filteredEmails.join(",");
      };
      finalString = removeEmail(result, clickedCheckboxUser);
    }
    if (finalString !== value?.[0]?.children?.[0]?.text) {
      const newValue = [
        {
          type: "paragraph",
          children: [{ text: finalString }],
        },
      ];
      setValue(newValue);
      editor.children = newValue;
      Transforms.select(editor, {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: finalString.length },
      });
    }
    setClickedCheckboxUser(null);
    excelFileEmails?.length && setExcelFileEmails([]);
  }, [selectedUsers]);

  const handleBlur = () => {
    setNonSelectedUsers([]);
    setNonEmailText([]);

    const rawText = value?.[0]?.children?.[0]?.text || "";
    const emails = rawText.split(",").map((item) => item.trim());

    // Manual email validation function
    let nonEmailWords = [];
    const isValidEmail = (email) => {
      const atIndex = email.indexOf("@");
      const dotIndex = email.lastIndexOf(".");
      if (
        atIndex > 0 && // '@' should not be the first character
        dotIndex > atIndex + 1 && // '.' should be after '@' and not immediately after '@'
        dotIndex < email.length - 1 && // '.' should not be the last character
        email.indexOf("@", atIndex + 1) === -1 // Only one '@' symbol should be present
      ) {
        return true;
      } else {
        nonEmailWords.push(email);
      }
      setNonEmailText(
        [...new Set([...nonEmailWords])].filter((email) => email !== "")
      );
    };

    const validEmails = emails.filter(isValidEmail);
    const selectedUsersList = allUserList?.filter((item) =>
      validEmails.includes(item["Preferred Email"])
    );
    const selectedEmail = selectedUsersList.map(
      (item) => item["Preferred Email"]
    );
    const uniqueArray = [...new Set([...validEmails, ...selectedEmail])].filter(
      (email) => email !== ""
    );
    const nonSelected = uniqueArray.filter(
      (item) => !selectedEmail.includes(item)
    );

    const registeredParticipants = [];
    const Attendees = [];
    const alreadySelectedEventUsers = [];
    for (let i = 0; i < allUserList.length; i++) {
      const item = allUserList[i];
      const email = item["Preferred Email"];

      if (validEmails.includes(email)) {
        if (
          alreadySelectedEventUsersEmail?.includes(email) ||
          alreadySelectedTagUsersEmail?.includes(email)
        ) {
          alreadySelectedEventUsers.push(item);
        } else if (allRegisterdUserList?.includes(email)) {
          registeredParticipants.push(item);
        } else {
          Attendees.push(item);
        }
      }
    }

    setRegisteredUsers(registeredParticipants);
    setAttendees(Attendees);
    setAlreadySelectCount(alreadySelectedEventUsers);
    setSelectedUsers(
      [...Attendees, ...registeredParticipants].filter((email) => email !== "")
    );
    setTotalUserList([
      ...totalUserEventList,
      ...totalUserTagList,
      ...Attendees,
      ...registeredParticipants,
    ]);
    setTotalUsers(
      [
        ...totalUserEventList,
        ...totalUserTagList,
        ...Attendees,
        ...registeredParticipants,
      ].length
    );
    setNonSelectedUsers(nonSelected);
  };

  const handleBlurMemoized = useMemo(
    () => handleBlur,
    [value, allUserList, setSelectedUsers, setNonSelectedUsers]
  );

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const decorate = useCallback(
    ([node, path]) => {
      const ranges = [];
      let text = node.text;
      let match;

      // Filter out null or undefined values from allParticipantsEmailArr
      const validParticipantsEmailArr = allParticipantsEmailArr.filter(
        (email) => email !== null && email !== undefined
      );

      // Highlight emails
      while ((match = emailRegex.exec(text)) !== null) {
        let isNotInList = false;
        let isRegistered = false;

        const emailLowerCase = match[0].toLowerCase();

        // Check if the email is in alreadySelectedEventUsersEmail
        let isInEventUsers = false;
        if (alreadySelectedEventUsersEmail.length > 0) {
          isInEventUsers = alreadySelectedEventUsersEmail
            .map((email) => email.toLowerCase())
            .includes(emailLowerCase);
        }

        // Check if the email is in alreadySelectedTagUsersEmail
        let isInTagUsers = false;
        if (alreadySelectedTagUsersEmail.length > 0) {
          isInTagUsers = alreadySelectedTagUsersEmail
            .map((email) => email.toLowerCase())
            .includes(emailLowerCase);
        }

        // Combine the results
        const isAlreadySelected = isInEventUsers || isInTagUsers;

        if (!isAlreadySelected) {
          isNotInList = !validParticipantsEmailArr
            .map((email) => email.toLowerCase())
            .includes(emailLowerCase);
          isRegistered =
            allRegisterdUserList.length &&
            allRegisterdUserList
              ?.map((email) => email.toLowerCase())
              .includes(emailLowerCase);
        }

        ranges.push({
          anchor: { path, offset: match.index },
          focus: { path: [0, 0], offset: match.index + match[0].length },
          email: true,
          notInList: isNotInList,
          registeredEmail: isRegistered,
          alreadySelected: isAlreadySelected,
        });
      }
      // Highlight random words (non-email text)
      text?.split(/[\s,]+/).forEach((word, index) => {
        if (word && !emailRegex.test(word)) {
          const wordLowerCase = word.toLowerCase();
          const isNotInList = !validParticipantsEmailArr
            .map((email) => email.toLowerCase())
            .includes(wordLowerCase);
          const wordIndex = text.indexOf(
            word,
            index > 0
              ? text.indexOf(text.split(/[\s,]+/)[index - 1]) +
              text.split(/[\s,]+/)[index - 1].length
              : 0
          );
          ranges.push({
            anchor: { path, offset: wordIndex },
            focus: { path: [0, 0], offset: wordIndex + word.length },
            randomWord: true,
            notInList: isNotInList,
          });
        }
      });

      return ranges;
    },
    [
      allParticipantsEmailArr,
      allRegisterdUserList,
      alreadySelectedEventUsersEmail,
      alreadySelectedTagUsersEmail,
    ]
  );

  const Element = ({ attributes, children }) => (
    <p {...attributes}>{children}</p>
  );

  const Leaf = ({ attributes, children, leaf }) => (
    <span
      {...attributes}
      style={{
        color: leaf.email
          ? leaf.registeredEmail
            ? "green" // Email in the registered list
            : leaf.alreadySelected
              ? "purple"
              : leaf.notInList
                ? "blue" // Email not in the list
                : "black" // Email in the list
          : leaf.randomWord
            ? leaf.notInList
              ? "red" // Random word not in the list
              : "black" // Random word in the list
            : "inherit",
        whiteSpace: leaf.email ? "nowrap" : "normal",
      }}
    >
      {children}
    </span>
  );

  return (
    <Slate editor={editor} initialValue={value} onChange={setValue}>
      <Editable
        placeholder="Enter emails..."
        className="editable-textArea"
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        decorate={decorate}
        onBlur={handleBlurMemoized}
      />
    </Slate>
  );
};

export default EditableTextArea;
