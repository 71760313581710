import React, { useEffect, useState } from 'react'
import PendingInviteList from './PendingInviteList';
import RevokedInviteList from './RevokedInviteList';
import AcceptedInviteList from './AcceptedInviteList';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const InviteList = () => {

    const [tabInformation, setTabInformation] = useState("Accepted");
    const navigate = useNavigate()
    const inviteeCount = useSelector((state) => { return state.ReduInviteeCount })

    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="um-back"></div>
                    <div className="uic-title">
                        <h1>Team-Users invitees</h1>
                        <div className="uic-fl-btn cstm-clone-sub-main">
                            <button
                                className="btn"
                                onClick={() => {
                                    navigate('/membership/invite')
                                }}
                            >
                                Invite Team-User
                            </button>
                        </div>
                    </div>
                    <div className="group-listing-main">
                        <ul className="nav group-listing-menu" role="tablist">
                            <li>
                                <a className="active" href="#Accepted" role="tab" data-toggle="tab" onClick={() => {
                                    setTabInformation("Accepted");
                                }}>
                                    Accepted invites {inviteeCount.acceptedCount > 0 && <span className='review-count'>{inviteeCount.acceptedCount}</span>}
                                </a>
                            </li>
                            <li>
                                <a className="" href="#Pending" role="tab" data-toggle="tab" onClick={() => {
                                    setTabInformation("Pending");
                                }}>
                                    Pending invites {inviteeCount.pendingCount > 0 && <span className='review-count'>{inviteeCount.pendingCount}</span>}
                                </a>
                            </li>
                            {/* <li>
                                <a className="" href="#Revoked" role="tab" data-toggle="tab" onClick={() => {
                                    setTabInformation("Revoked");
                                }}>
                                    Revoke invites {inviteeCount.revokedCount > 0 && <span className='review-count'>{inviteeCount.revokedCount}</span>}
                                </a>
                            </li> */}
                        </ul>
                        <div className="tab-content group-listing-tabs">
                            <div
                                role="tabpanel"
                                className={false ? "tab-pane fade" : "tab-pane in active"}
                                id="Accepted"
                            >
                                <AcceptedInviteList tabInformation={tabInformation} />
                            </div>
                            <div
                                role="tabpanel"
                                className="tab-pane fade"
                                id="Pending"
                            >
                                <PendingInviteList tabInformation={tabInformation} />
                            </div>
                            <div
                                role="tabpanel"
                                className="tab-pane fade"
                                id="Revoked"
                            >
                                <RevokedInviteList tabInformation={tabInformation} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InviteList