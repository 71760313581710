import React from 'react'
import './Button.css'
import { ReactComponent as IcnMChevronRightIcon } from "../../Images/icn_m_chevron_right.svg";
import { ReactComponent as IcnMChevronLeftIcon } from "../../Images/icn_m_chevron_left.svg";

const ButtonCarousel = ({ type = "right", hadleClick = () => { } }) => {
  return (
    <>
      <button type='button' className='button button-carousel' onClick={hadleClick}>{type.toLocaleLowerCase() === 'right' ? <IcnMChevronRightIcon /> : <IcnMChevronLeftIcon />}</button>
    </>
  )
}

export default ButtonCarousel