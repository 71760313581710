import React, { useEffect, useRef, useState } from "react";
import FilterHeader from "../../../DocumentManagement/Components/FilterHeader";
import FilterNestedNavigation from "../../../DocumentManagement/Components/FilterNestedNavigation";
import Separators from "../../../../components/Separators/Separators";
import FilterFooter from "../../../DocumentManagement/Components/FilterFooter";
import PropTypes from "prop-types";
import "../../../DocumentManagement/Components/Document.css";
import "../../../../Styles/Theme/Theme.css";
import FilterListItem from "../../../DocumentManagement/Components/FilterListItem";
import UploadIcon from "../../../../components/Icons/GeneralIcons/UploadIcon";
import CategoryList from "./CategoryList";
import NavVideoCategoryIcon from "../../../../components/Icons/NavIcons/NavVideoCategoryIcon";
import NavEventIcon from "../../../../components/Icons/NavIcons/NavEventIcon";
import SearchField from "../../../../components/InputField/SearchField";

const EventFilterSidebar = ({
  selectedTab,
  upComingList,
  pastEventList,
  citiesList,
  onCancel,
  selectedSortBy,
  selectedEventType,
  selectedYear,
  selectedCreated,
  allCategoriesList,
  selectedSubCategory,
  handleSubCategoryFilter,
  handleCategoryFilter,
  selectedCategory,
  handleSortBy,
  handleFilter,
  resultCount,
  handleSelectContent,
  handleDateCreated,
  handleClearAllFilter,
  sortBy,
  dateList,
  isFilter,
  selectedCity,
  handleSelectCity,
}) => {
  const [sort, setSort] = useState(false);
  const [upComing, setUpComing] = useState(false);
  const [pastEvent, setPastEvent] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [isCategory, setCategory] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchCategories, setSearchCategories] = useState([]);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState("");
  const [selectedSubCategoryFilter, setSelectedSubCategoryFilter] =
    useState("");
  const [selectedYearFilter, setSelectedYearFilter] = useState("");
  const [selectedEventTypeFilter, setSelectedEventTypeFilter] = useState("");
  const [selectedSortByFilter, setSelectedSortByFilter] = useState({});
  const [upcomingListFilter, setUpcomingListFilter] = useState([]);
  const [pastListFilter, setPastListFilter] = useState([]);
  const [citiesListFilter, setCitiesListFilter] = useState([]);
  const [isCity, setCity] = useState(false);
  const [selectedCityFilter, setSelectedCityFilter] = useState("");

  useEffect(() => {
    setSearchCategories(allCategoriesList);
    setUpcomingListFilter(upComingList);
    if (pastEventList) {
      setPastListFilter(["Any type", ...pastEventList]);
    }
    if (citiesList) {
      setCitiesListFilter([{ city: "All cities" }, ...citiesList]);
    }
  }, [allCategoriesList, upComingList, pastEventList, citiesList]);

  useEffect(() => {
    setSelectedCategoryFilter(selectedCategory);
    setSelectedYearFilter(selectedYear);
    setSelectedEventTypeFilter(selectedEventType);
    setSelectedSubCategoryFilter(selectedSubCategory);
    setSelectedSortByFilter(selectedSortBy);
    setSelectedCityFilter(selectedCity);
  }, [
    selectedCategory,
    selectedYear,
    selectedEventType,
    selectedSubCategory,
    selectedSortBy,
    selectedCity,
  ]);

  const filterRef = useRef();

  // handle back from filter
  const handleBack = () => {
    setSort(false);
    setUpComing(false);
    setPastEvent(false);
    setStartDate(false);
    setCategory(false);
    setCity(false);
  };

  // close filter on outside click
  const handleCloseFilter = (e) => {
    if (
      filterRef.current &&
      isFilter &&
      !filterRef.current.contains(e.target)
    ) {
      onCancel();
    }
  };
  document.addEventListener("mousedown", handleCloseFilter);

  // function for date uploaded filter
  const handleCreatedFilter = (data) => {
    handleDateCreated(data);
    data.id !== "custom" && handleBack();
  };

  const headerLabel = sort
    ? "Sort by"
    : upComing
    ? "Event type"
    : pastEvent
    ? "Year"
    : isCategory
    ? "Category"
    : startDate
    ? "Last modified"
    : isCity
    ? "City"
    : "Filters";

  //filter data based on entered text
  const handleSearchCategory = (e) => {
    setSearchText(e.target.value);
    const searchedCategory = allCategoriesList.filter((category) =>
      category.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchCategories(searchedCategory);
  };

  //clear the search field
  const handleClearSearch = () => {
    setSearchText("");
    setSearchCategories(allCategoriesList);
  };

  //close cross mark handler
  const handleCloseIconClick = (section) => {
    if (section === "sort") {
      handleSortBy("", "filter");
      setSelectedSortByFilter({});
    } else if (section === "year") {
      handleSelectContent("", "pastEvent");
      setSelectedYearFilter("");
      setPastListFilter(pastEventList);
    } else if (section === "eventType") {
      handleSelectContent("", "upcoming");
      setSelectedEventTypeFilter("");
      setUpcomingListFilter(upComingList);
    } else if (section === "category") {
      handleCategoryFilter("");
      handleSubCategoryFilter("");
      setSelectedCategoryFilter("All categories");
      setSelectedSubCategoryFilter("All subcategories");
      setSearchCategories(allCategoriesList);
    } else if (section === "city") {
      handleSelectCity("");
      setSelectedCityFilter("");
      setCitiesListFilter(citiesList);
    }
  };

  return (
    <>
      <div className="filter-sidebar-partners" ref={filterRef}>
        <FilterHeader
          title={headerLabel}
          onCancel={onCancel}
          isNavigated={
            sort || upComing || pastEvent || startDate || isCategory || isCity
          }
          onClick={handleBack}
        />
        {isCategory && (
          <div className="filter-sidebar-search">
            <SearchField
              value={searchText}
              handleChange={handleSearchCategory}
              handleClear={handleClearSearch}
            />
          </div>
        )}
        <div className="filter-sidebar-body scrollbar">
          {sort ? (
            sortBy.map((label) => (
              <FilterListItem
                onClick={() => {
                  handleSortBy(label, label.id);
                  handleBack();
                }}
                label={label.label}
                selected={label.id === selectedSortByFilter.id}
              />
            ))
          ) : isCategory ? (
            searchCategories.map((category) => (
              <CategoryList
                handleBack={handleBack}
                selectedSubCategory={selectedSubCategoryFilter}
                handleSubCategoryFilter={handleSubCategoryFilter}
                category={category}
                selectedCategory={selectedCategoryFilter}
                handleCategoryFilter={handleCategoryFilter}
              />
            ))
          ) : upComing && selectedTab === "upcoming" ? (
            upcomingListFilter.map((content) => (
              <FilterListItem
                onClick={() => {
                  handleSelectContent(content.name, "upcoming");
                  handleBack();
                }}
                label={content.name}
                selected={selectedEventTypeFilter === content.name}
              />
            ))
          ) : pastEvent && selectedTab === "pastEvents" ? (
            pastListFilter.map((content) => (
              <FilterListItem
                onClick={() => {
                  handleSelectContent(content, "pastEvent");
                  handleBack();
                }}
                label={content}
                selected={selectedYearFilter === content}
              />
            ))
          ) : startDate ? (
            dateList.map((event) => (
              <FilterListItem
                onClick={() => {
                  handleCreatedFilter(event);
                }}
                label={event.label}
                selected={selectedCreated.id === event.id}
                icon={event.icon}
              />
            ))
          ) : isCity ? (
            citiesListFilter.map((content) => (
              <FilterListItem
                onClick={() => {
                  handleSelectCity(content.city);
                  handleBack();
                }}
                label={content.city}
                selected={selectedCityFilter === content.city}
              />
            ))
          ) : (
            <>
              <FilterNestedNavigation
                onClick={() => setSort(true)}
                label={`Sort by ${
                  selectedSortByFilter.label !== undefined
                    ? ":" + " " + selectedSortByFilter.label
                    : ""
                }`}
                closeIcon={
                  selectedSortByFilter.label !== undefined ? true : false
                }
                closeIconClick={() => handleCloseIconClick("sort")}
              />
              <Separators />

              <FilterNestedNavigation
                icon={
                  selectedCategoryFilter !== "All categories" &&
                  selectedCategoryFilter !== undefined ? (
                    <NavVideoCategoryIcon />
                  ) : (
                    ""
                  )
                }
                onClick={() => setCategory(true)}
                label={
                  selectedCategoryFilter !== "All categories" &&
                  selectedCategoryFilter !== undefined
                    ? selectedCategoryFilter
                    : "Category"
                }
                closeIcon={
                  selectedCategoryFilter !== "All categories" &&
                  selectedCategoryFilter !== undefined
                    ? true
                    : false
                }
                closeIconClick={() => handleCloseIconClick("category")}
              />
              {selectedTab === "upcoming" && (
                <FilterNestedNavigation
                  icon={
                    selectedEventTypeFilter !== "" &&
                    selectedEventTypeFilter !== "Event type" ? (
                      <NavEventIcon />
                    ) : (
                      ""
                    )
                  }
                  onClick={() => setUpComing(true)}
                  label={
                    selectedEventTypeFilter !== ""
                      ? selectedEventTypeFilter === "virtual"
                        ? "Virtual"
                        : selectedEventTypeFilter === "inPerson"
                        ? "InPerson"
                        : selectedEventTypeFilter
                      : "Event type"
                  }
                  closeIcon={
                    selectedEventTypeFilter !== "" &&
                    selectedEventTypeFilter !== "Event type"
                      ? true
                      : false
                  }
                  closeIconClick={() => handleCloseIconClick("eventType")}
                />
              )}
              {selectedTab === "pastEvents" && (
                <FilterNestedNavigation
                  icon={
                    selectedYearFilter !== "" &&
                    selectedYearFilter !== "Event type" ? (
                      <NavEventIcon />
                    ) : (
                      ""
                    )
                  }
                  onClick={() => setPastEvent(true)}
                  label={
                    selectedYearFilter !== "" ? selectedYearFilter : "Year"
                  }
                  closeIcon={
                    selectedYearFilter !== "" &&
                    selectedYearFilter !== "Event type"
                      ? true
                      : false
                  }
                  closeIconClick={() => handleCloseIconClick("year")}
                />
              )}
              <FilterNestedNavigation
                icon=""
                onClick={() => setCity(true)}
                label={
                  selectedCityFilter !== "All cities" &&
                  selectedCityFilter !== undefined &&
                  selectedCityFilter !== ""
                    ? selectedCityFilter
                    : "City"
                }
                closeIcon={
                  selectedCityFilter !== "All cities" &&
                  selectedCityFilter !== undefined &&
                  selectedCityFilter !== ""
                    ? true
                    : false
                }
                closeIconClick={() => handleCloseIconClick("city")}
              />
              {/* <FilterNestedNavigation
                icon={
                  selectedCreated.id !== undefined &&
                  selectedCreated.id !== "" ? (
                    <UploadIcon />
                  ) : (
                    ""
                  )
                }
                onClick={() => setStartDate(true)}
                label={
                  selectedCreated.id !== undefined && selectedCreated.id !== ""
                    ? selectedCreated.label
                    : "Start date"
                }
              /> */}
            </>
          )}
          {/* //   {selectedCreated.id === "custom" && startDate && (
        //     <>
        //       <div className="custom-date-range">
        //         <div className="text-field-main">
        //           <h6>From</h6>
        //           <div className={`text-field-box`}>
        //             <ReactDatePicker
        //               selected={uploadedFrom}
        //               onChange={handleUploadedFrom}
        //             />
        //           </div>
        //         </div>
        //         <div className="text-field-main">
        //           <h6>To</h6>
        //           <div className={`text-field-box`}>
        //             <ReactDatePicker
        //               selected={uploadedTo}
        //               onChange={(date) => {
        //                 handleUploadedTo(date);
        //                 handleBack();
        //               }}
        //             />
        //           </div>
        //         </div>
        //       </div>
        //     </>
        //   )}
        //   {selectedUpdated.id === "custom" && dateUpdated && (
        //     <>
        //       <div className="custom-date-range">
        //         <div className="text-field-main">
        //           <h6>From</h6>
        //           <div className={`text-field-box`}>
        //             <ReactDatePicker
        //               selected={modifiedFrom}
        //               onChange={handleModifiedFrom}
        //             />
        //           </div>
        //         </div>
        //         <div className="text-field-main">
        //           <h6>To</h6>
        //           <div className={`text-field-box`}>
        //             <ReactDatePicker
        //               selected={modifiedTo}
        //               onChange={(date) => {
        //                 handleModifiedto(date);
        //                 handleBack();
        //               }}
        //             />
        //           </div>
        //         </div>
        //       </div>
        //     </>
        //   )} */}
        </div>
        {!sort && !upComing && !pastEvent && !startDate && !isCity && (
          <FilterFooter
            resultCount={resultCount?.eventCount ? resultCount?.eventCount : 0}
            onCancel={handleClearAllFilter}
            onShow={handleFilter}
          />
        )}
      </div>
    </>
  );
};
EventFilterSidebar.propTypes = {
  onCancel: PropTypes.func,
  contentTypeList: PropTypes.array,
  selectedSortBy: PropTypes.string,
  selectedFile: PropTypes.string,
  selectedYear: PropTypes.string,
  selectedEventType: PropTypes.string,
  selectedCreated: PropTypes.string,
  selectedUpdated: PropTypes.string,
  handleSortBy: PropTypes.func,
  handleFilter: PropTypes.func,
  handleSelectContent: PropTypes.func,
  handleSelectCity: PropTypes.func,
  handleDateCreated: PropTypes.func,
  handleDateUpdated: PropTypes.func,
  handleClearAllFilter: PropTypes.func,
  handleFiletype: PropTypes.func,
  handlePeopleFilter: PropTypes.func,
  handleUploadedTo: PropTypes.func,
  handleUploadedFrom: PropTypes.func,
  resultCount: PropTypes.number,
  userList: PropTypes.array,
  handleModifiedFrom: PropTypes.func,
  handleModifiedto: PropTypes.func,
  selectedCity: PropTypes.string,
};

export default EventFilterSidebar;
