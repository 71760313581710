import React, { useRef, useState } from "react";
import { useTableContext } from "./TableContext";
import PropTypes from "prop-types";
import actionIcon from "../../Images/icn_m_dots_vertical.svg";
import toggleIcon from "../../Images/toggle.svg";
import photo3 from "../../Images/photo3.png";
import pdfIcon from "../../Images/icn_m_pdf.svg";
import folderIcon from "../../Images/icn_m_folder_color.svg";
import Badges from "../Badge/Badges";
import docCollectionCover from "../../Images/docs-collection-cover.png";
import companyLogo from "../../Images/table-cell-company-logos.svg";
import likeIcon from "../../Images/icn_s_like.svg";
import LogoClickup from "../../Images/logo_clickup.svg";
import Chips from "../Chips/Chips";
import { Rating } from "@mui/material";
import "./Table.css";
import "../../Styles/Theme/Theme.css";
import Button from "../Button/Button";
import Avtar from "../Avtar/Avtar";
import Switch from "../Switch/Switch";
import DotsIcon from "../Icons/GeneralIcons/DotsIcon";
import SaveIcon from "../Icons/GeneralIcons/SaveIcon";
import MdsIcon from "../Icons/GeneralIcons/MdsIcon";
import DropDownItem from "../DropDownItem/DropDownItem";
import PdfIcon from "../Icons/FileIcons/PdfIcon";
import NotDefinedFileIcon from "../Icons/FileIcons/NotDefinedFileIcon";
import DocIcon from "../Icons/FileIcons/DocIcon";
import ExcelFileIcon from "../Icons/FileIcons/ExcelFileIcon";
import PptIcon from "../Icons/FileIcons/PptIcon";
import ToolTip from "../ToolTip/ToolTip";
import logo from "../../Images/logo-32-32.svg";
import ImageIcon from "../Icons/FileIcons/ImageIcon";
import VideoFileIcon from "../Icons/FileIcons/VideoFileIcon";
import AudioFileIcon from "../Icons/FileIcons/AudioFileIcon";
const TableCell = ({
  children,
  variant,
  width = 1,
  hasText,
  action2,
  action3,
  hasCounter,
  file,
  category = [],
  showAll,
  hasError,
  setDocId,
  title,
  description,
  handleSave,
  saved = false,
  fileType,
  actionOptions = [],
  onClick,
  onMouseEnter,
  handleSelectCategory,
  onMouseOverCapture,
  onMouseLeave,
  src = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  match,
  ids,
  check,
  ownerIds,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isAction, setAction] = useState(false);
  const menuRef = useRef(null);
  const actionRef = useRef(null);
  const [isToolTip, setToolTip] = useState(false);
  const [context, setContext] = useState({ x: 0, y: 0 });
  const [menuContext, setMenuContext] = useState({ x: 0, y: 0 });

  const handleActionClick = (e) => {
    var button = document.querySelector(".button");
    if (button) {
      var rect = button.getBoundingClientRect();
      var windowHeight = window.innerHeight;
      var tooltip = document.getElementById("actions");

      var rect = tooltip.getBoundingClientRect();
      console.log("Coordinates: " + rect.left + "px, " + rect.top + "px");

      let x = e.clientX + 150 > window.innerWidth ? e.clientX - 150 : e.clientX;
      let y =
        e.clientY + 220 > window.innerHeight ? e.clientY - 220 : e.clientY + 20;

      setContext({ x: x, y: y });
      setAction(!isAction);
    }
  };

  const openCategoryMenu = (e) => {
    let x = e.clientX + 150 > window.innerWidth ? e.clientX - 150 : e.clientX;
    let y =
      e.clientY + 220 > window.innerHeight ? e.clientY - 220 : e.clientY + 20;
    setMenuContext({ x: x, y: y });
    setOpen(!isOpen);
  };
  const closeMenu = (e) => {
    if (menuRef.current && isOpen && !menuRef.current.contains(e.target)) {
      setOpen(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("mousedown", closeMenu);
    }
    if (
      actionRef.current &&
      isAction &&
      !actionRef.current.contains(e.target)
    ) {
      setAction(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("mousedown", closeMenu);
    }
  };
  // on esc key press  this event will call
  const closeMenuOnEsc = (e) => {
    if (e.code === "Escape" && actionRef.current && isAction) {
      setAction(false);
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", closeMenuOnEsc);
    }
  };
  // close menus on scroll
  const handleCloseOnScroll = () => {
    if (isAction) {
      setAction(false);
      document.addEventListener("scroll", handleCloseOnScroll);
    }
    if (isOpen) {
      setOpen(false);
      document.addEventListener("scroll", handleCloseOnScroll);
    }
  };
  document.addEventListener("keydown", closeMenuOnEsc);
  document.addEventListener("mousedown", closeMenu);
  document.addEventListener("scroll", handleCloseOnScroll);
  const { isHead } = useTableContext();

  function handleSelect(selectedItem) {
    selectedItem.callbackFunction();
    setAction(false);
  }
  if (isHead !== undefined && isHead) {
    return <th>{children}</th>;
  } else {
    return (
      <td
        className={`width-${width} ${
          width === 54 ? "document-name-tooltip" : ""
        } ${width === 12 ? "document-people-tooltip" : ""}`}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOverCapture={onMouseOverCapture}
      >
        {/* {match && <ToolTip title={title} id={`tooltip_${ids}`} />}

        {check && <ToolTip title={title} id={`tooltip_${ownerIds}`} />} */}

        <div className="table-cell">
          {variant === "checkbox" && (
            <input type="checkbox" className="type-checkbox-input" />
          )}
          {variant === "action" && (
            <>
              <div className="table-action-main" ref={actionRef}>
                <Button
                  onMouseLeave={() => setToolTip(false)}
                  onMouseEnter={() => {
                    setToolTip(true);
                  }}
                  handleClick={handleSave}
                  size="small"
                  elementIcon={<SaveIcon isFilled={saved} />}
                  onContextMenu={() => {}}
                  variant="ghost"
                  hasText={false}
                  hasToolTip={false}
                ></Button>
                {isToolTip && (
                  <div className="table-action-tool-tip">
                    {" "}
                    <ToolTip title="Save" />
                  </div>
                )}

                <Button
                  handleClick={handleActionClick}
                  size="small"
                  elementIcon={<DotsIcon />}
                  onMouseEnter={() => {}}
                  onMouseLeave={() => {}}
                  onContextMenu={() => {}}
                  variant="ghost"
                  hasText={false}
                  hasToolTip={false}
                ></Button>
                <div
                  className="table-action-icon"
                  style={{
                    top: context?.y ?? 0 + "px",
                    left: context?.x ?? 0 + "px",
                    position: "fixed",
                    zIndex: "3",
                    display: `${isAction ? "block" : "none"}`,
                  }}
                >
                  <DropDownItem
                    id="actions"
                    onSelect={handleSelect}
                    isIcon
                    size="small"
                    options={actionOptions}
                  />{" "}
                </div>
              </div>
            </>
          )}
          {variant === "status" && (
            <span className="status-badge status-medium status-radius-small accent-yellow">
              badge
            </span>
          )}
          {variant === "file" && (
            <>
              {fileType === "photos" ? (
                <span className="filter-icon-svg">
                  {" "}
                  <ImageIcon scale={1.34} />{" "}
                </span>
              ) : fileType === "pdfs" ? (
                <span className="filter-icon-svg">
                  {" "}
                  <PdfIcon scale={1.34} />{" "}
                </span>
              ) : fileType === "documents" ? (
                <span className="filter-icon-svg">
                  {" "}
                  <DocIcon scale={1.34} />{" "}
                </span>
              ) : fileType === "tables" ? (
                <span className="filter-icon-svg">
                  {" "}
                  <ExcelFileIcon scale={1.34} />
                </span>
              ) : fileType === "presentations" ? (
                <span className="filter-icon-svg">
                  {" "}
                  <PptIcon scale={1.34} />
                </span>
              ) : fileType === "videos" ? (
                <span className="filter-icon-svg">
                  {" "}
                  <VideoFileIcon scale={1.34} />
                </span>
              ) : fileType === "audio" ? (
                <span className="filter-icon-svg">
                  {" "}
                  <AudioFileIcon scale={1.34} />
                </span>
              ) : (
                <span className="filter-icon-svg">
                  {" "}
                  <NotDefinedFileIcon scale={1.34} />
                </span>
              )}
            </>
          )}
          {variant === "folder" && (
            <>
              <div className="type-folder-name-file">
                <img
                  src={folderIcon}
                  alt="File Icon"
                  className="type-folder-icon"
                />
                <Badges className="badges-neutral medium" label={5} />
              </div>
            </>
          )}
          {variant === "company" && (
            <>
              <div class="type-company-logo-full">
                <img
                  src={companyLogo}
                  alt="Company Logo"
                  className="type-company-logo"
                />
              </div>
              {/* <img src={LogoClickup} alt="Company Logo" className="type-company-logo-clickup" /> */}
            </>
          )}
          {variant === "doc" && (
            <>
              <img src={docCollectionCover} className="doc-collection-image" />
            </>
          )}
          {variant === "likes" && (
            <>
              <div className="type-likes">
                <img src={likeIcon} />
                <h6 className="type-likes-h6">15</h6>
              </div>
            </>
          )}
          {variant === "avatar" && (
            <>
              {title !== "MDS" && src !== undefined && src !== "" ? (
                <Avtar src={src} />
              ) : title !== "MDS" ? (
                <div className="type-avatar-text-32">
                  {title.charAt(0).toUpperCase()}
                </div>
              ) : (
                <img src={logo} />
              )}
            </>
          )}
          {variant === "ratings" && (
            <div className="rating-five-stars">
              <Rating readOnly value={5} />
              <h5 className="rating-h5">5.0</h5>
            </div>
          )}
          {variant === "categories" && (
            <>
              <div className="type-categories" ref={menuRef}>
                {category.length > 0 &&
                  category
                    .slice(0, 3)
                    .map((cate) => (
                      <Chips
                        handleClick={() => handleSelectCategory(cate)}
                        className="small"
                        label={cate.name}
                      />
                    ))}
                {category.length > 3 && (
                  <div className="chipa-show-all-main">
                    <Chips
                      handleClick={openCategoryMenu}
                      className="small chipa-show-all"
                      label="All"
                    />
                  </div>
                )}
                {isOpen && (
                  <div
                    className="chipa-show-all-open"
                    style={{
                      top: menuContext?.y ?? 0 + "px",
                      position: "fixed",
                      zIndex: "3",
                    }}
                  >
                    {" "}
                    <DropDownItem
                      onSelect={handleSelectCategory}
                      options={category.slice(3, category.length)}
                    />{" "}
                  </div>
                )}
              </div>
            </>
          )}
          {hasText && (
            <div className="type-text-only">
              <h5 className="table-h5">{title}</h5>
              <h6 className="table-h6">{description}</h6>
            </div>
          )}
          {action2 && (
            <Button handleClick={handleActionClick} size="small">
              <img src={actionIcon} />
            </Button>
          )}
          {action3 && (
            <Button handleClick={handleActionClick} size="small">
              <img src={actionIcon} />
            </Button>
          )}
          {showAll && <span className="show-all">Show All</span>}
          {variant === "toggle" && <Switch />}
          {children}
        </div>
      </td>
    );
  }
};

TableCell.propTypes = {
  variant: PropTypes.oneOf([
    "text-only",
    "checkbox",
    "toggle",
    "status",
    "action",
    "avatar",
    "file",
    "folder",
    "doc",
    "company",
    "categories",
    "likes",
    "ratings",
  ]).isRequired,
  hasText: PropTypes.bool,
  action2: PropTypes.bool,
  action3: PropTypes.bool,
  hasCounter: PropTypes.bool,
  file: PropTypes.shape({
    instance: PropTypes.string,
  }),
  showAll: PropTypes.bool,
  hasError: PropTypes.bool,
  state: PropTypes.oneOf([
    "enabled",
    "hovered",
    "pressed",
    "focused",
    "disabled",
  ]).isRequired,
  children: PropTypes.node.isRequired,
  handleActionClick: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  src: PropTypes.string,
  category: PropTypes.array,
  saved: PropTypes.bool,
  handleSave: PropTypes.func,
  width: PropTypes.string,
  actionOptions: PropTypes.array,
  fileType: PropTypes,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
};

export default TableCell;
