import React from "react";
import PropTypes from "prop-types";
import SmallCrossIcon from "../Icons/GeneralIcons/SmallCrossIcon";

// CSS
import "../../Styles/Theme/Theme.css";
import "./Styles/ChipCross.css";

function ChipCross({
  //   Click Events
  onMouseLeave,
  handleClick,
  onMouseEnter,
}) {
  return (
    <button
      className="chip-cross-icon"
      onMouseLeave={() => {}}
      onClick={handleClick}
      onMouseEnter={() => {}}
    >
      <SmallCrossIcon
        width={16}
        height={16}
        iconColor="var(--color-icon-neutral-strong)"
      />
    </button>
  );
}

// define types to all props
ChipCross.propTypes = {
  handleClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default ChipCross;
