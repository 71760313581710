import React from "react";
import PropTypes from "prop-types";
import EventThumbImage from "./EventThumbImage";
import ThumbImg from "../../../../Images/thumb-img.png";
import "../HelperComponents/Styles/EventMediumCard.css";
import "../../../../Styles/frontendGlobal.css";
import BadgesControl from "../../../../components/Badge/BadgesControl.js";
import DotsIcon from "../../../../components/Icons/GeneralIcons/DotsIcon.js";

const EventMediumCard = ({
  eventId = "65e69643b748e40e62fc69ac",
  selectedTab = "upcoming",
  name = "Event name",
  eventType = "Virtual",
  ThumbSrc = ThumbImg,
  hasBadge = false,
  badgeText = "New",
  eventAmount = "$1,200.00",
  eventPrice = "Free",
  hasPriceType = "No Badge",
  hasPriceDiscount = "0",
  hasDatePeriod = false,
  hasStartYear = false,
  startDates = "Mar 2",
  startYear = "2024",
  endDates = "Jun 4",
  endYear = "2025",
}) => {
  const eventPriceFun = () => {
    if (hasPriceType === "No Badge") {
      if (eventPrice === "Free") {
        return "Free";
      } else if (eventPrice === "From") {
        return (
          <>
            <span className="from-text">from </span>
            {"$" + eventAmount}
          </>
        );
      } else if (eventPrice === "Amount") {
        return "$" + eventAmount;
      } else if (eventPrice === "No Type") {
        return false;
      } else if (eventPrice === "Completed") {
        return "Completed";
      }
    } else if (hasPriceType === "Discount") {
      if (eventPrice === "Free") {
        return "Free";
      } else if (eventPrice === "From") {
        return (
          <>
            <span className="from-text">from </span>
            {"$" + eventAmount}
            <BadgesControl
              color="accent-yellow"
              label={hasPriceDiscount + "% OFF"}
              rounded
              size="status-medium"
            />
          </>
        );
      } else if (eventPrice === "Amount") {
        return (
          <>
            {" "}
            {"$" + eventAmount}
            <BadgesControl
              color="accent-yellow"
              label={hasPriceDiscount + "% OFF"}
              rounded
              size="status-medium"
            />
          </>
        );
      } else if (eventPrice === "No Type") {
        return false;
      } else if (eventPrice === "Completed") {
        return "Completed";
      }
    } else if (hasPriceType === "Registered") {
      if (eventPrice === "Free") {
        return (
          <>
            Free
            <BadgesControl
              color="neutral"
              label={"REGISTERED"}
              rounded
              size="status-medium"
            />
          </>
        );
      } else if (eventPrice === "From") {
        return (
          <>
            <span className="from-text">from </span>
            {"$" + eventAmount}
            <BadgesControl
              color="neutral"
              label={"REGISTERED"}
              rounded
              size="status-medium"
            />
          </>
        );
      } else if (eventPrice === "Amount") {
        return (
          <>
            {" "}
            {"$" + eventAmount}
            <BadgesControl
              color="neutral"
              label={"REGISTERED"}
              rounded
              size="status-medium"
            />
          </>
        );
      } else if (eventPrice === "No Type") {
        return false;
      } else if (eventPrice === "Completed") {
        return "Completed";
      }
    } else if (hasPriceType === "Live") {
      if (eventPrice === "Free") {
        return (
          <>
            Free
            <BadgesControl
              color="danger"
              label={"Live"}
              rounded
              size="status-medium"
            />
          </>
        );
      } else if (eventPrice === "From") {
        return (
          <>
            <span className="from-text">from </span>
            {"$" + eventAmount}
            <BadgesControl
              color="danger"
              label={"Live"}
              rounded
              size="status-medium"
            />
          </>
        );
      } else if (eventPrice === "Amount") {
        return (
          <>
            {" "}
            {"$" + eventAmount}
            <BadgesControl
              color="danger"
              label={"Live"}
              rounded
              size="status-medium"
            />
          </>
        );
      } else if (eventPrice === "No Type") {
        return (
          <BadgesControl
            color="danger"
            label={"Live"}
            rounded
            size="status-medium"
          />
        );
      } else if (eventPrice === "Completed") {
        return "Completed";
      }
    }
  };

  const handleClick = () => {
    window.open(`/event-details/${selectedTab}/${eventId}`, "_blank");
  };

  return (
    <>
      <div className="event-medium-card-box">
        <div className="event-medium-card-img d-block relative">
          <div
            className="event-image-cursor event-medium-card-image"
            onClick={handleClick}
          >
            <EventThumbImage ThumbSrc={ThumbSrc} />
          </div>
          {hasBadge && (
            <BadgesControl
              color="white"
              label={badgeText}
              rounded
              size="status-medium"
            />
          )}
        </div>
        <div className="event-medium-card-texts relative">
          <p className="event-medium-card-title text-ellipsis">{name}</p>
          <p className="event-medium-card-date">
            {!hasDatePeriod
              ? startDates + ", " + startYear
              : !hasStartYear
              ? startDates + " - " + endDates + ", " + endYear
              : startDates +
                ", " +
                startYear +
                " - " +
                endDates +
                ", " +
                endYear}
          </p>
          <p className="event-medium-card-event-type text-ellipsis">
            {eventType}
          </p>
          {eventPriceFun() && (
            <p className="event-medium-card-event-price"> {eventPriceFun()}</p>
          )}
          {/* <div className="event-medium-card-dot">
            <DotsIcon height={24} iconColor="#000" width={24} />
          </div> */}
        </div>
      </div>
    </>
  );
};

EventMediumCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  ThumbSrc: PropTypes.string,
  hasBadge: PropTypes.bool,
  badgeText: PropTypes.string,
  hasPriceDiscount: PropTypes.string,
  eventType: PropTypes.string,
  eventPrice: PropTypes.oneOf([
    "Free",
    "Amount",
    "From",
    "No Type",
    "Completed",
  ]),
  hasPriceType: PropTypes.oneOf(["No Badge", "Discount", "Registered", "Live"]),
  hasDatePeriod: PropTypes.bool,
  hasStartYear: PropTypes.bool,
  startDates: PropTypes.string,
  eventAmount: PropTypes.number,
  startYear: PropTypes.string,
  endDates: PropTypes.string,
  endYear: PropTypes.string,
};

export default EventMediumCard;
