import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllValidUser,
  clearchatredu,
  clearchatfromlist,
} from "../../../Action";
import { getFormatedListForUserAndAttendees } from "../CommonFunctions/formatedUserList.js";
import ChatMessageBarContent from "../MainComponent/ChatMessageBarContent.js";
import ChatRightSideBar from "./ChatRightSideBar";
import ChatHeaderContent from "./ChatHeaderContent.js";
import ChatContainerContent from "./ChatContainerContent.js";
import newsocket from "../../../components/SocketConnect.js";

import "../../../Styles/Theme/Theme.css";

const ChatMessageComponents = ({
  handleAddMemberClick,
  getAllExistingMembers,
  editorClear,
  setEditorClear,
  onChangeChatList,
}) => {
  const { userid, type } = useParams();
  const history = useNavigate();
  const userIdRef = useRef();
  const typeRef = useRef();
  const quoteMessageSearchIdRef = useRef("");
  const dispatch = useDispatch();
  const isChatInfo = localStorage.getItem("isChatInfo");
  const allUsersList = useSelector((state) => {
    return state.ReduAllUsersList;
  });
  const [showRightSidebar, setShowRightSidebar] = useState(
    isChatInfo === "true"
  );
  const [replyMessageData, setReplyMessageData] = useState({});
  const [allChatHeaderDetail, setAllChatHeaderDetail] = useState({});
  const [editMessage, seteditMessage] = useState({
    status: false,
    msg: "",
    id: "",
    taggedId: [],
  });

  const [editMessageState, setEditMessageState] = useState(false);
  const [msg, setMsg] = useState("");
  const [selectedMentionName, setSelectedMentionName] = useState([]);
  const [groupMemberList, setGroupMemberList] = useState({});
  const [editGroup, setEditGroup] = useState(false);
  const [attachmentData, setAttachmentData] = useState([]);
  const [previewDisplay, setPreviewDisplay] = useState(false);
  const [previewType, setPreviewType] = useState("");
  const [drag, setdrag] = useState(false);
  const [
    showSelectedMediaPreviewAttachment,
    setSelectedMediaPreviewAttachment,
  ] = useState("");

  // get image video preview data
  const getImageVideoData = useSelector((state) => {
    return state?.AllMediaPreview;
  });
  // emit event calling for group details
  useEffect(() => {
    userIdRef.current = userid;
    typeRef.current = type;
    if (userid !== "me" && type !== "me") {
      if (type === "userChatGroup") {
        newsocket.emit("group-member-detail", { groupId: userid });
      } else if (type === "chatChannel") {
        newsocket.emit("channel-member-detail", { channelId: userid });
      }
      dispatch(getAllValidUser());

      newsocket.emit("media-list-latest", {
        chatid: userid,
        authUserId: localStorage.getItem("userid"),
        type: type,
      });
      newsocket.emit("url-list-latest", {
        chatid: userid,
        authUserId: localStorage.getItem("userid"),
        type: type,
      });
      newsocket.emit("file-list-latest", {
        chatid: userid,
        authUserId: localStorage.getItem("userid"),
        type: type,
      });
    }
  }, [userid, type]);

  useEffect(() => {
    newsocket.on("group-member-detail-receive", (res) => {
      if (res.status) {
        setGroupMemberList(res?.data[0]);
        let allUsers = getFormatedListForUserAndAttendees(
          res.data[0].members
            .filter(
              (member) => member.userId !== localStorage.getItem("userid")
            )
            .map((mem) => {
              return mem.user;
            })
        );
        getAllExistingMembers(
          allUsers.map((user) => {
            if (
              allUsersList.filter(
                (allUser) => allUser.id === user.id && allUser.onlineStatus
              ).length
            ) {
              return { ...user, onlineStatus: true };
            } else {
              return { ...user, onlineStatus: false };
            }
          })
        );
      } else {
        setGroupMemberList([]);
      }
    });

    newsocket.on("channel-member-detail-receive", (res) => {
      if (res.status) {
        setGroupMemberList(res);
      } else {
        setGroupMemberList([]);
      }
    });
    newsocket.on("media-list-receive-latest", (res) => {
      if (res.message.status) {
        if (res.message.currentPage === 1) {
          dispatch({
            type: "FETCHIMAGEANDVIDEO",
            payload: res.message.data.list,
          });
        } else {
          dispatch({
            type: "APPENDMEDIA",
            payload: res.message.data.list,
          });
        }
      } else {
        dispatch({
          type: "FETCHIMAGEANDVIDEO",
          payload: [],
        });
      }
    });

    newsocket.on("file-list-receive-latest", (res) => {
      if (res.message.status) {
        if (res.message.currentPage === 1) {
          dispatch({
            type: "FETCHALLFILESLIST",
            payload: res.message.data.list,
          });
        } else {
          dispatch({
            type: "APPENDFILES",
            payload: res.message.data.list,
          });
        }
      } else {
        dispatch({
          type: "FETCHALLFILESLIST",
          payload: [],
        });
      }
    });
    newsocket.on("url-list-receive-latest", (res) => {
      if (res.message.status) {
        if (res.message.currentPage === 1) {
          dispatch({
            type: "FETCHALLURLLIST",
            payload: res.message.data.list,
          });
        } else {
          dispatch({
            type: "APPENDLINKS",
            payload: res.message.data.list,
          });
        }
      } else {
        dispatch({
          type: "FETCHALLURLLIST",
          payload: [],
        });
      }
    });
    newsocket.on("leave-group-receive", (res) => {
      if (res.message.status) {
        if (res.message.data._id === userIdRef.current) {
          newsocket.emit("group-member-detail", {
            groupId: res.message.data._id,
          });
          newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
        }
        if (
          res.message.messageData[0].activity.userId[0].id ===
          localStorage.getItem("userid")
        ) {
          newsocket.emit("leaveRoom", { roomId: res.message.data._id });
          dispatch({
            type: "DELETECHATFROMLIST",
            payload: res.message.data._id,
          });
          dispatch(clearchatredu(res.message.data._id));
          newsocket.emit("newChatList", { id: localStorage.getItem("userid") });

          if (res.message.data._id === userIdRef.current) {
            history("/chats/me/me");
          }
        }
      }
    });

    newsocket.on("leave-channel-receive", (res) => {
      if (res.message.status) {
        if (res.message.channelDetail._id === userIdRef.current) {
          newsocket.emit("channel-member-detail", {
            channelId: res.message.channelDetail._id,
          });
          newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
        }
        if (
          res.message.messageData[0].activity.userId[0].id ===
          localStorage.getItem("userid")
        ) {
          newsocket.emit("leaveRoom", {
            roomId: res.message.channelDetail._id,
          });
          dispatch({
            type: "DELETECHATFROMLIST",
            payload: res.message.channelDetail._id,
          });
          dispatch(clearchatredu(res.message.channelDetail._id));
          newsocket.emit("newChatList", { id: localStorage.getItem("userid") });

          if (res.message.channelDetail._id === userIdRef.current) {
            history("/chats/me/me");
          }
        }
      }
    });

    //delete-group-receive socket event
    newsocket.on("delete-group-receive", (res) => {
      newsocket.emit("leaveRoom", { roomId: res.message.data._id });
      dispatch({
        type: "DELETECHATFROMLIST",
        payload: res.message.data._id,
      });
      dispatch(clearchatredu(res.message.data._id));
      newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
      if (res.message.status) {
        if (userIdRef.current === res.message.data._id) {
          history("/chats/me/me");
        }
      }
    });
    // delete channel receive event action
    newsocket.on("delete-channel-receive", (response) => {
      newsocket.emit("leaveRoom", { roomId: response.message });
      dispatch({
        type: "DELETECHATFROMLIST",
        payload: response.message,
      });
      dispatch(clearchatredu(response.message));
      newsocket.emit("newChatList", { id: localStorage.getItem("userid") });

      if (response.message === userIdRef.current) {
        history("/chats/me/me");
      }
    });

    // remove-member-receive
    newsocket.on("remove-member-receive", (response) => {
      if (response.message.status) {
        if (response.memberIds.includes(localStorage.getItem("userId"))) {
          dispatch(clearchatfromlist(response.message.data._id));
          newsocket.emit("leaveRoom", { roomId: response.message.data._id });
          dispatch({
            type: "DELETECHATFROMLIST",
            payload: response.message.data._id,
          });
          dispatch({
            type: "FETCHALLMESSAGES",
            payload: { [userIdRef.current]: [] },
          });
          newsocket.emit("newChatList", { id: localStorage.getItem("userid") });

          if (response.message.data._id === userIdRef.current) {
            history("/chats/me/me");
          }
        } else {
          if (response.message.data._id === userIdRef.current) {
            newsocket.emit("getLastMessage", {
              roomId: response.message.data._id,
              userId: localStorage.getItem("userid"),
            });
            if (response.message.data._id === userIdRef.current) {
              newsocket.emit("group-member-detail", {
                groupId: userIdRef.current,
              });
            }
          }
        }
      }
    });
    // remove channel member receive event action
    newsocket.on("remove-channel-member-receive", (response) => {
      if (
        response?.memberIds &&
        response.memberIds.includes(localStorage.getItem("userid"))
      ) {
        dispatch(clearchatfromlist(response.message._id));
        newsocket.emit("leaveRoom", { roomId: response.message._id });
        dispatch({
          type: "DELETECHATFROMLIST",
          payload: response.message._id,
        });
        dispatch({
          type: "FETCHALLMESSAGES",
          payload: { [userIdRef.current]: [] },
        });
        newsocket.emit("newChatList", { id: localStorage.getItem("userid") });

        if (response.message._id === userIdRef.current) {
          history("/chats/me/me");
        }
      } else {
        newsocket.emit("getLastMessage", {
          roomId: response.message._id,
          userId: localStorage.getItem("userid"),
        });
        if (response.message._id === userIdRef.current) {
          newsocket.emit("channel-member-detail", {
            channelId: userIdRef.current,
          });
        }
      }
    });
    // edit channel receive event action
    newsocket.on("edit-channel-receive", (response) => {
      newsocket.emit("getLastMessage", {
        roomId: response.message._id,
        userId: localStorage.getItem("userid"),
      });
      if (response.message._id === userIdRef.current) {
        newsocket.emit("channel-member-detail", {
          channelId: userIdRef.current,
        });
      }
    });
    // newsocket.on("edit-group-receive", (res) => {
    //   if (res.message.status) {
    //     newsocket.emit("getLastMessage", {
    //       roomId: res.message._id,
    //       userId: localStorage.getItem("userid"),
    //     });
    //     if (res.message._id === userIdRef.current) {
    //       newsocket.emit("group-member-detail", { groupId: userIdRef.current });
    //     }
    //   }
    // });
    newsocket.on("create-channel-receive", (response) => {
      newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
    });
  }, [newsocket]);

  //handle message reply click
  const handleReplyClick = (messageData) => {
    setReplyMessageData(messageData);
  };

  const findQuotedmessage = (quoteMsgId) => {
    var ele = document.getElementById(quoteMsgId);
    quoteMessageSearchIdRef.current = quoteMsgId;
    if (ele !== null && ele !== undefined) {
      ele.scrollIntoView();
      setTimeout(() => {
        quoteMessageSearchIdRef.current = "";
      }, 2000);
    } else {
      quoteMessageSearchIdRef.current = quoteMsgId;
      const chat = document.getElementById("NEW_MAIN_CHAT_CONTAINER");
      chat.scrollTop = -chat.scrollHeight;
    }
  };

  const handlePreviewClick = (data, type) => {
    setPreviewDisplay(true);
    setPreviewType(type);
    setSelectedMediaPreviewAttachment(data);
  };

  // remove member from group
  const handleRemoveMember = (removeMemberId) => {
    if (Object.keys(groupMemberList).length > 0) {
      let groupMembers = groupMemberList.members.filter(
        (member) => member?.userId !== removeMemberId
      );
      let removeMemberGroupData = { ...groupMemberList, members: groupMembers };
      setGroupMemberList(removeMemberGroupData);
    }
  };


  return (
    <>
      <div className="chat-message-wrapper flex flex-col">
        {userid !== "me" && type !== "me" ? (
          <ChatHeaderContent
            showRightSidebar={showRightSidebar}
            setShowRightSidebar={setShowRightSidebar}
            allChatHeaderDetail={allChatHeaderDetail}
            setAllChatHeaderDetail={setAllChatHeaderDetail}
            groupMemberList={groupMemberList}
            setEditGroup={setEditGroup}
          />
        ) : (
          <></>
        )}
        <ChatContainerContent
          drag={drag}
          setdrag={setdrag}
          groupMemberList={groupMemberList}
          handleReplyClick={handleReplyClick}
          seteditMessage={seteditMessage}
          setMsg={setMsg}
          allChatHeaderDetail={allChatHeaderDetail}
          quoteMessageSearchIdRef={quoteMessageSearchIdRef}
          findQuotedmessage={findQuotedmessage}
          getImageVideoData={getImageVideoData}
          attachmentData={attachmentData}
          previewDisplay={previewDisplay}
          setPreviewDisplay={setPreviewDisplay}
          previewType={previewType}
          showSelectedMediaPreviewAttachment={
            showSelectedMediaPreviewAttachment
          }
          setEditMessageState={setEditMessageState}
        />
        {userid !== "me" && type !== "me" ? (
          <ChatMessageBarContent
            replyMessageData={replyMessageData}
            setReplyMessageData={setReplyMessageData}
            allChatHeaderDetail={allChatHeaderDetail}
            editMessage={editMessage}
            seteditMessage={seteditMessage}
            msg={msg}
            setMsg={setMsg}
            handleClickOnRepliedMessage={findQuotedmessage}
            setAttachmentData={setAttachmentData}
            handlePreviewClick={handlePreviewClick}
            drag={drag}
            setdrag={setdrag}
            groupMemberList={groupMemberList}
            editMessageState={editMessageState}
            setEditMessageState={setEditMessageState}
            editorClear={editorClear}
            setEditorClear={setEditorClear}
            setGroupMemberList={setGroupMemberList}
          />
        ) : (
          <></>
        )}
      </div>

      <div className="chat-right-details">
        {userid !== "me" && type !== "me" && showRightSidebar ? (
          <ChatRightSideBar
            groupMemberList={groupMemberList}
            handleAddMemberClick={handleAddMemberClick}
            editGroup={editGroup}
            setEditGroup={setEditGroup}
            onChangeChatList={onChangeChatList}
            handleRemoveMember={handleRemoveMember}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ChatMessageComponents;
