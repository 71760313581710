import React from "react";
import "./Styles/ChipFilter.css";
import { ReactComponent as IcnMClear } from "../../Images/icn_m_clear.svg";

const ChipFilter = ({
  isActive = false,
  closeIcon = <IcnMClear />,
  dropIcon = <IcnMClear />,
  hasAvatarIcon = false,
  hasIcon = false,
  hasFileIcon = false,
  avatarIcon = <IcnMClear />,
  eleIcon,
  fileIcon,
  label = "Label",
  handleClick,
  extraClass = "",
}) => {
  return (
    <>
      <button
        onClick={handleClick}
        className={`chips-filter ${extraClass} ${
          isActive ? "active-chips-filter" : ""
        }`}
      >
        {hasAvatarIcon && avatarIcon}
        {hasIcon && eleIcon}
        {hasFileIcon && fileIcon}
        {label.length > 0 && <div className="chip-container">{label}</div>}
        {isActive ? closeIcon : dropIcon}
      </button>
    </>
  );
};

export default ChipFilter;
