import React from "react";
import PropTypes from "prop-types"
function DocAltIcon({ height = 24, width = 24 }) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#0263D1"
                    d="M5.86 1h8.907L21 7.5v12.64A2.863 2.863 0 0 1 18.135 23H5.861A2.862 2.862 0 0 1 3 20.14V3.86A2.862 2.862 0 0 1 5.86 1Z"
                />
                <path
                    fill="#fff"
                    d="M15.518 11.965H8.487A.486.486 0 0 1 8 11.482c0-.266.22-.482.487-.482h7.03a.482.482 0 1 1 0 .965Zm-1.934 6H8.487A.486.486 0 0 1 8 17.482c0-.266.22-.482.487-.482h5.097a.482.482 0 1 1 0 .965Zm1.934-2H8.487A.486.486 0 0 1 8 15.482c0-.266.22-.482.487-.482h7.03a.482.482 0 1 1 0 .965Zm0-2H8.487A.486.486 0 0 1 8 13.482c0-.266.22-.482.487-.482h7.03a.482.482 0 1 1 0 .965Z"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
            </svg>
        </>
    );
}
DocAltIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
}
export default DocAltIcon;
