import createApiInstance from "./createApiInstance";
import { ApiRoutes } from "./apiRoutes";

export const extendedPartnersApi = createApiInstance.injectEndpoints({
  endpoints: (builder) => ({
    deletePartnerCategory: builder.mutation({
      query: ({ body }) => {
        return {
          url: ApiRoutes.partners.deleteCategory.url,
          method: ApiRoutes.partners.deleteCategory.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
    reAssignPartnerCategory: builder.query({
      query: ({ id }) => {
        return {
          url: ApiRoutes.partners.reAssignParnterCategory.url.replace(
            ":id",
            id
          ),
          method: ApiRoutes.partners.reAssignParnterCategory.method,
        };
      },
      transformResponse: (response) => response,
    }),
    partnerUserCount: builder.mutation({
      query: (body) => {
        return {
          url: ApiRoutes.partners.partnerUserCount.url,
          method: ApiRoutes.partners.partnerUserCount.method,
          body,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useDeletePartnerCategoryMutation,
  useReAssignPartnerCategoryQuery,
  usePartnerUserCountMutation,
} = extendedPartnersApi;
