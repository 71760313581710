import { Link } from "react-router-dom";
import moment from "moment";
import millify from "millify";
import authService from "../../../Services/auth.service";
import videoPlayericons from "../../../Images/video-player-icons.svg";
import videodummy from "../../../Images/nothumbnail.png";
import { useEffect, useState } from "react";
import { getVideoProgressPercentage } from "../../checktoken";
import sepbullet from "../../../Images/separator-bullet.svg";

const ListingVideoComponent = ({ convideo }) => {
  const [videopausepercentage, setvideopausepercentage] = useState(0);

  useEffect(() => {
    const video_percentage = getVideoProgressPercentage(convideo);
    setvideopausepercentage(video_percentage);
  }, []);
  // console.log(videopausepercentage,'video pause percentage')

  return (
    <>
      <Link
        to={`/videos/${convideo._id}`}
      >
        <div
          className="cstm-cv-video-in"
          onClick={async () => {
            //var res = await authService.addviewbyuser(convideo._id);
          }}
        >
          <div className="gallery-img">
            <div
              className="cstm-gallery-img cstm-gallery-img-main "
              style={{
                overflow: "hidden",
              }}
            >
              <img
                className="video-js vjs-default-skin"
                src={
                  convideo.thumbnail !== undefined &&
                    convideo.thumbnail !== null &&
                    convideo.thumbnail.length > 0
                    ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                    convideo.thumbnail
                    : videodummy
                }
              />
              <img
                className="cstm-videoPlayericons"
                data-src={videoPlayericons}
                alt="videoPlayericons"
              />
              {convideo.duration !== undefined && convideo.duration !== null ? (
                <div class="cstm-rv-time">{convideo.duration}</div>
              ) : (
                <></>
              )}

              {getVideoProgressPercentage(convideo) !== 0 && (
                <div className="video_progress_wrapper">
                  <div
                    className="video_progress_bar"
                    style={{
                      width: `${getVideoProgressPercentage(convideo)}%`,
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>

          <div></div>
          <div className="cstm-gle">
            <p className="gallery-content1 cstm-ttl2">{convideo.title}</p>

            <p className="gallery-content2">{convideo.categories[0].name}</p>
            <div className="icon-box">
              <a className="gallery-main-icon"><span>{moment(convideo.createdAt).fromNow()}</span></a><span className="cstm-bullet"><img src={sepbullet} /></span>
              <a className="gallery-main-icon">
              <span>
                {convideo.viewsCount !== undefined && convideo.viewsCount > 0
                  ? `${millify(convideo.viewsCount)} ${convideo.viewsCount > 1 ? "Views" : "View"}`
                  : "0 Views"}
              </span>
              </a>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};
export default ListingVideoComponent;
