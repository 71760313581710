import { useEffect, useState } from "react";
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import authNews from "../../../Services/auth.service.news";
import editfill from "../../../Images/BackendImages/edit.svg";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import imgdelete from "../../../Images/BackendImages/delete.svg";
import Loader from "../../../Images/loader.gif";
import Loader2 from "../../../Images/loader2.gif";
import AlertComponent from "../../../Hooks/AlertComponent";
import authService from "../../../Services/auth.service";
import ModalPopup from "../../ModalPopup/ModalPopup";

const columns = [
  { id: "title", label: "Title", w: 30 },
  { id: "date", label: "Date", w: 15 },
  { id: "publishOrHide", label: "Status", w: 7 },
  { id: "makeFeaturedCheckbox", label: "Feature", w: 7 },
  { id: "contentRef", label: "Edit", w: 7 },
  { id: "action", label: "Actions", w: 7 },
];

function createData(
  title,
  date,
  publishOrHide,
  makeFeaturedCheckbox,
  contentRef,
  action
) {
  return {
    title,
    date,
    publishOrHide,
    makeFeaturedCheckbox,
    contentRef,
    action,
  };
}
const ContentListing = () => {
  const emptyObjectModal = {
    date: "",
    publishOrHide: "",
    makeFeaturedCheckbox: "",
  };
  const emptyObjectErr = {
    date: "",
    publishOrHide: "",
    makeFeaturedCheckbox: "",
  };
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allContent, setAllContent] = useState([]);
  const [editContent, setEditContent] = useState(emptyObjectModal);
  const [editErr, setEditErr] = useState(emptyObjectErr);
  const [selectedId, setSelectedId] = useState("");
  const [editInProcess, setEditInProcess] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [actualsearch, setActualsearch] = useState("");
  const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
  const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
  const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    setLoader(true);
    getAllContentNewsApi(searchText);
    handleClearAllStates()
    getallusersSuggestionListApi()

  }, []);
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getContentNewsSuggestionList()
      console.log(getSuggestionRes, "getSuggestionRes");
      let data = getSuggestionRes.data.newsList
      var arr = []
      if (data && data.length !== undefined && data.length !== null && data.length > 0) {
        data.map((item) => arr.push({ name: item.title }))
        console.log(arr);
        setSuggestionList(arr);
      }
    } catch (err) {

    }
  }
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex]
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText
        setSearchText(text);
      }
    }
  }, [listIndex]);
  const getAllContentNewsApi = (searchText, col, sort) => {
    let query = `?search=${searchText}`
    query += col !== undefined && col !== null && col !== "" ? `&sortField=${col}` : ""
    query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
    authNews
      .getContentNewsList(query)
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          var data = res.data.newsList;
          var tbl = [];
          for (var i = 0; i < data.length; i++) {
            tbl[i] = createData(
              data[i].videoReferenceId.title,
              moment(data[i].date).format("MMM DD, YYYY [at] hh:mm a"),
              data[i].publishOrHide,
              data[i].makeFeaturedCheckbox,
              data[i].videoReferenceId._id,
              data[i]._id
            );
          }
          setRow(tbl);
          setAllContent(tbl);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //close add modal
  const handleCloseAddModel = (e) => {
    setEditContent(emptyObjectModal);
    setEditErr(emptyObjectErr);
    handleClearAllStates()

  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleMakeFeaturedCheckbox = (checkbox, newsId) => {
    var table = [];
    for (var i = 0; i < rows.length; i++) {
      if (rows[i].action === newsId) {
        table[i] = createData(
          rows[i].title,
          rows[i].date,
          rows[i].publishOrHide,
          !rows[i].makeFeaturedCheckbox,
          rows[i].contentRef,
          rows[i].action
        );
      } else {
        table[i] = createData(
          rows[i].title,
          rows[i].date,
          rows[i].publishOrHide,
          checkbox ? false : rows[i].makeFeaturedCheckbox,
          rows[i].contentRef,
          rows[i].action
        );
      }

    }
    setRow(table);
    authNews
      .makeNewsFeaturedById({
        id: newsId,
        data: { makeFeaturedCheckbox: checkbox },
      })
      .then((response) => {
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // handle edit content
  const handleEdit = (value) => {
    setSelectedId(value);
    setEditModalLoader(true);
    authNews
      .getNewsById(value)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var newsList = res.data.newsDetail;
          setEditContent({
            date: moment(newsList.date).format("YYYY-MM-DD"),
            publishOrHide: newsList.publishOrHide,
            makeFeaturedCheckbox: newsList.makeFeaturedCheckbox,
          });
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };

  //edited modal submit validation and api call
  const handleEditSubmit = () => {
    var status = true;
    var temp = {};

    if (editContent.date.length === 0) {
      temp = { ...temp, date: "Enter a date!" };
      status = false;
    }
    if (editContent.publishOrHide.length === 0) {
      temp = { ...temp, publishOrHide: "Select status!" };
      status = false;
    }
    if (status) {
      setEditInProcess(true);
      editApiCall();
    } else {
      setEditErr(temp);
    }
  };

  //edit api call
  const editApiCall = (descriptionHtml) => {
    const formdata = new FormData();
    formdata.append("date", moment(editContent.date).format("MM-DD-YYYY"));
    formdata.append("publishOrHide", editContent.publishOrHide);
    formdata.append("makeFeaturedCheckbox", editContent.makeFeaturedCheckbox);
    authNews
      .editNewsDetail({
        id: selectedId,
        data: formdata,
      })
      .then((res) => {
        if (res.data.status) {
          getAllContentNewsApi(searchText);
        }
        setAlertMsgEdit(res.data.message);
        setTimeout(() => {
          setAlertMsgEdit("");
        }, 3000);
        setEditInProcess(false);
        handleClearAllStates()
      })
      .catch((e) => {
        console.log(e);
        setEditInProcess(false);
        handleClearAllStates()
      });
    setEditErr(emptyObjectErr);
  };

  //delete news content
  const handleDelete = () => {
    authNews
      .deleteNewsContent(deleteId)
      .then((res) => {
        if (res.data.status) {
          setShowDeleteModal(false)
          var table = [];
          var n = 0;
          for (var i = 0; i < allContent.length; i++) {
            if (allContent[i].action !== deleteId) {
              table[n] = createData(
                allContent[i].title,
                allContent[i].date,
                allContent[i].publishOrHide,
                allContent[i].makeFeaturedCheckbox,
                allContent[i].contentRef,
                allContent[i].action
              );
              n++;
            }
          }
          setAllContent(table);
          setRow(table);
        }
        setShowAlert(true);
        setAlertMsg(res.data.message);
        setTimeout(() => {
          setShowAlert(false);
          setAlertMsg("");
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //sorting function
  const handleSorting = (e) => {
    var normal = allContent;
    var arr = [...allContent];

    if (e.target.value === "a-z") {
      arr.sort(function (a, b) {
        var itemA = a.title.toUpperCase();
        var itemB = b.title.toUpperCase();
        return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
      });
    } else if (e.target.value === "z-a") {
      arr.sort(function (a, b) {
        var itemA = a.title.toUpperCase();
        var itemB = b.title.toUpperCase();
        return itemA > itemB ? -1 : itemA < itemB ? 1 : 0;
      });
    } else {
      arr = normal;
    }
    var table = [];
    for (var i = 0; i < arr.length; i++) {
      table[i] = createData(
        arr[i].title,
        arr[i].date,
        arr[i].makeFeaturedCheckbox,
        arr[i].contentRef,
        arr[i].action
      );
    }
    setRow(table);
  };

  //sorting function
  const handleSort = (col, direc) => {
    setLoader(true)
    getAllContentNewsApi(searchText, col, direc);
  };

  //handle onChange for edit modal
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    setEditContent({ ...editContent, [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      setEditErr({ ...editErr, [e.target.name]: "" });
    }
  };

  //pop up state for do you want to leave....
  useEffect(() => {

    if (stayOnPage !== null && !stayOnPage) {
      if ($('#editContentNews').hasClass('show')) {
        $("#editContentNews").modal('hide')
        handleCloseAddModel()
      }

      if ($('#editContentNews').hasClass('show')) {
        $("#editContentNews").modal('hide')
        handleCloseAddModel()
      }

    }

  }, [stayOnPage])

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" })
    dispatch({ type: "SETSTAYONPAGE", payload: null })
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
  }

  //search using text
  const handleSearch = (text) => {
    setSuggestionBox(false);
    setSearchText(text);
    setLoader(true)
    getAllContentNewsApi(text)
  };
  const deleteContentName = (deleteId) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].action === deleteId) {
        return rows[i].title;
      }
    }
  };
  return (
    <>
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>Content</h1>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}

                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (suggestionList !== undefined && suggestionList.length > 0) {
                    setListIndex(-1);
                    setSuggestionBox(true)
                    var searchResult = suggestionList.filter((user) => {
                      return user.name.toLowerCase().includes(e.target.value.trim().toLowerCase());
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch(e.target.value)
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15
                        ? 15
                        : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15
                        ? 15
                        : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox && suggestions.length > 0 && searchText.length > 0 && (
                <div className="suggestion-box">
                  <ul id="suggestion-list">
                    {suggestions.map((suggestion, index) => (
                      <li
                        key={index}
                        onMouseDown={() => handleSearch(suggestion.name)}
                        className={index === listIndex ? 'selected-active' : ''}
                      >
                        {searchText !== null && searchText.length > 0 && suggestion.name.toLowerCase().startsWith(searchText.toLowerCase()) ? <><b>{suggestion.name.slice(0, searchText.length)}</b>{suggestion.name && suggestion.name.slice(searchText.length, suggestion.name.length - 1)} </> : suggestion.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              <i className="fa fa-search" onClick={() => handleSearch(searchText)}>

              </i>
            </div>
            <div className="uic-fl-btn sort-by-mn">
              <div className="sort-by-mn-title">Sort by : </div>
              <div className="sort-by-mn-select">
                <select onChange={handleSorting}>
                  <option>All</option>
                  <option value="a-z">A-Z</option>
                  <option value="z-a">Z-A</option>
                </select>
              </div>
            </div>
          </div>
          {showAlert && <div className="alert alert-info">{alertMsg}</div>}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHead className="uic-table-th">
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={`uic-th-td w-${column.w}`}
                      >
                        {column.id !== "action" && column.id !== "makeFeaturedCheckbox" && column.id !== "contentRef" ? (
                          <>
                            <span className="custom-sorting">
                              <i
                                className="fa fa-angle-down"
                                onClick={(e) => {
                                  handleSort(column.id, "Dec");
                                }}
                              ></i>
                              <i
                                className="fa fa-angle-up"
                                onClick={(e) => {
                                  handleSort(column.id, "Asc");
                                }}
                              ></i>
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loader ? (
                  <div className="cstm-data-load">
                    <img src={Loader} />{" "}
                  </div>
                ) : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length > 0 ?
                  <TableBody className="uic-table-td">
                    {(
                      rows
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                              className="uic-th-td w-20"
                            >
                              {columns.map((column) => {
                                const value = row[column.id];

                                return column.id === "title" ?
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td w-30"
                                  >
                                    <a className="cstm-view-users-count" data-toggle="modal"
                                      data-target="#editContentNews"
                                      onClick={() => {
                                        handleEdit(row.action);
                                      }}>
                                      {value}
                                    </a>
                                  </TableCell>
                                  :
                                  column.id === "makeFeaturedCheckbox" ||
                                    column.id === "date" || column.id === "publishOrHide" ? (
                                    <TableCell
                                      key={column.id}
                                      className={`uic-th-td ${column.id === "date" ? "w-15" : "w-7"}`}
                                    >
                                      {column.id === "makeFeaturedCheckbox" ? (
                                        <input
                                          type="checkbox"
                                          onChange={(e) => {
                                            handleMakeFeaturedCheckbox(
                                              e.target.checked,
                                              rows[index].action
                                            );
                                          }}
                                          defaultChecked={value}
                                          checked={value}
                                          disabled={rows[index].publishOrHide === "hide"}
                                        />
                                      ) : column.id === "publishOrHide" ? value === "hide" ? "Hidden" : "Published"
                                        : value
                                      }
                                    </TableCell>
                                  ) : column.id === "thumbnail" ? (
                                    <TableCell
                                      key={column.id}
                                      className="uic-th-td w-7"
                                    >
                                      <img
                                        src={
                                          process.env.REACT_APP_AWS_IMG_VID_PATH +
                                          value
                                        }
                                      />
                                    </TableCell>
                                  ) : column.id === "contentRef" ? (
                                    <TableCell
                                      key={column.id}
                                      className="uic-th-td w-7"
                                    >
                                      <a
                                        href={`/contentlibrary/edit/${value}/0`}
                                        target="_blank"
                                        className="edit-content-link"
                                      >
                                        Edit
                                      </a>
                                    </TableCell>
                                  ) : (
                                    <TableCell
                                      key={column.id}
                                      className="uic-th-td uic-table-icon w-7"
                                    >
                                      <a
                                        data-toggle="modal"
                                        data-target="#editContentNews"
                                        onClick={() => {
                                          handleEdit(value);
                                        }}
                                      >
                                        <img src={editfill} />
                                      </a>

                                      <a
                                        onClick={() => {
                                          setDeleteId(value);
                                          setShowDeleteModal(true)
                                        }}
                                      >
                                        <img src={imgdelete} />
                                      </a>
                                    </TableCell>
                                  );
                              })}
                            </TableRow>
                          );
                        })
                    )}
                  </TableBody> : <div className="admin-no-found">
                    <p>No Content Found </p>
                  </div>}
              </Table>

            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editContentNews"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Content News</h2>
                <button
                  type="button"
                  className="close"
                  //data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                    } else {
                      $("#editContentNews").modal('hide')
                      handleCloseAddModel(e)
                    }

                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {alertMsgEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Date*</div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      placeholder="Enter Date"
                      name="date"
                      onChange={handleEditChange}
                      value={editContent.date}
                    />
                  </div>
                  <span className="error">{editErr.date}</span>
                </div>
                <div className="form-group">
                  <div className="cstm-add-video-title-sub">Publish/Hide*</div>
                  <div className="form-group-ct">
                    <label className="subcat-addvideo">
                      <input
                        type="radio"
                        id="draft"
                        name="publishOrHide"
                        value="publish"
                        onChange={handleEditChange}
                        defaultChecked={editContent.publishOrHide === "publish"}
                        checked={editContent.publishOrHide === "publish"}
                      />
                      Publish
                    </label>
                    <label className="subcat-addvideo">
                      <input
                        type="radio"
                        id="publish"
                        name="publishOrHide"
                        value="hide"
                        onChange={(e) => {
                          if (e.target.checked)
                            setEditContent({ ...editContent, publishOrHide: "hide", makeFeaturedCheckbox: false })
                        }}
                        defaultChecked={editContent.publishOrHide === "hide"}
                        checked={editContent.publishOrHide === "hide" ? "checked" : ""}
                      />
                      hide
                    </label>
                    <span className="error">{editErr.publishOrHide}</span>
                  </div>
                </div>
                {/* <div className="cstm-add-video-title-sub">Make Featured</div> */}
                <div className="form-group mb-0">
                  <div className="form-group-ctst">

                    <label className="subcat-addvideo">
                      <input
                        type="checkbox"
                        defaultChecked={editContent.makeFeaturedCheckbox}
                        checked={editContent.makeFeaturedCheckbox}
                        onChange={(e) => {
                          setEditContent({
                            ...editContent,
                            makeFeaturedCheckbox: e.target.checked,
                          });
                        }}
                        disabled={editContent.publishOrHide === "hide"}
                      />{" "}
                      Do you want to make this post featured?
                    </label>
                  </div>
                </div>

              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {editInProcess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        buttonText1="Delete"
        isOpen={showDeleteModal}
        btn2variant="danger"
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete content"}
      >
        <div className="ctsm-modal-body">
          <p>Are you sure you want to delete this "{deleteContentName(
            deleteId
          )}"? This action cannot be undone.</p>
        </div>
      </ModalPopup>
      <><AlertComponent /></>
    </>
  );
};
export default ContentListing;
