import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SearchAndSorting from '../../../AdminCommonComponent/TableComponent/SearchAndSorting'
import imgdelete from "../../../../Images/BackendImages/delete.svg";
import editfill from "../../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../../Images/loader2.gif";
import Loader from "../../../../Images/loader.gif";
import Frame from "../../../../Images/Frame.svg";
import imgBigDelete from "../../../../Images/BackendImages/delete-icon.svg";
import Dropzone from 'react-dropzone';

import authServicePartner from '../../../../Services/auth.service.partner';
import authService from '../../../../Services/auth.service';
import AlertComponent from "../../../../Hooks/AlertComponent";
import { CompressImageFunction } from '../../../AdminNotification/CommonFunction';
import ImageCropperReact from '../../../ImageCropper/ImageCropperReact';
const HelpFulLink = ({ tabInformation, partnername }) => {
    //hooks
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation().search

    const id = new URLSearchParams(location).get("id")

    //weburl regex
    const webRegex = new RegExp(/^https?:\/\/.+/i);

    //redux states
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const PAGECHANGE = useSelector((state) => state.ReduMDSPartner)
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })

    const columns = [
        // { id: "linkIcon", label: "Link icon" },
        { id: "title", label: "Title" },
        { id: "url", label: "URL" },
        { id: "action", label: "Actions" },
    ];

    const [addLink, setAddLink] = useState({
        title: "",
        url: "",
        image: [],
        linkIcon: ""
    })
    const [linkIcon, setLinkIcon] = useState('')
    const [editLink, setEditLink] = useState({
        title: "",
        url: "",
        linkIcon: "",
        icon: []
    })
    const [addLinkErr, setAddLinkErr] = useState({
        title: "",
        url: "",
        icon: ""
    })
    const [loader, setLoader] = useState(false)
    const [allLink, setAllLink] = useState([])
    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [createInprocess, setCreateInprocess] = useState(false);
    const [addAlertMsg, setAddAlertMsg] = useState("");
    const [showMsg, setShowMsg] = useState(false)
    const [linkId, setLinkId] = useState('')
    const [editModalLoader, seteditModalLoader] = useState(false);
    const [inProcess, setInProcess] = useState(false)
    const [isZoomImg, setIsZoomImg] = useState(false);
    const [zoomImg, setZoomImg] = useState(null);
    const [suggestionList, setSuggestionList] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionBox, setSuggestionBox] = useState(false);
    const [listIndex, setListIndex] = useState(-1);
    const [clearSearch, setClearSearch] = useState(false)

    //  image cropping state
    const [modalOpen, setModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileName, setFileName] = useState("");
    const [fileDimension, setFileDimension] = useState("");
    const [helpFullLinkActionType, setHelpFullLinkActionType] = useState();
    const [addIconFile, setAddIconFile] = useState("");
    const [editIconFile, setEditIconFile] = useState("");
    const [createHelpFullLinkCroppedfileData, setcreateHelpFullLinkCroppedfileData] = useState();
    const [editHelpFullLinkCroppedfileData, setEditHelpFullLinkCroppedfileData] = useState();
    const [thumbnail, setThumbnail] = useState([]);

    //call when tab change
    useEffect(() => {
        if (tabInformation === "HelpfulLink") {
            dispatch({ type: "REMOVEMODALINPROCESS" })
            setLoader(true)
            getAllLinkApi()
            setClearSearch(true)
            getallusersSuggestionListApi()
        }
    }, [tabInformation])

    //create function to store data in state
    function createLinkData(action, title, url, linkIcon, partnerId) {
        return {
            action, title, url, linkIcon, partnerId
        }
    }
    async function getallusersSuggestionListApi() {
        try {
            const getSuggestionRes = await authService.getPartnerhelpfulLinksByPartnerIdSuggestionList()
            let data = getSuggestionRes.data.data
            var arr = []
            if (data && data.length !== undefined && data.length !== null && data.length > 0) {
                data.map((item) => arr.push({ name: item.channelName }))
                setSuggestionList(arr);
            }
        } catch (err) {
        }
    }


    //helpfull link list api
    async function getAllLinkApi(col, sort) {
        let query = ""
        query += col !== undefined && col !== null && col !== "" ? `?sortField=${col}` : ""
        query += sort !== undefined && sort !== null && sort !== "" ? `&sortType=${sort}` : ""
        try {
            const allLinkRes = await authServicePartner.getAllLink(id, query)
            if (allLinkRes.status) {
                var arr = allLinkRes.data.partnerLinksList
                if (arr.length > 0) {
                    var newArr = []
                    for (let i = 0; i < arr.length; i++) {
                        newArr[i] = createLinkData(
                            arr[i]._id,
                            arr[i].title,
                            arr[i].url,
                            arr[i].linkIcon,
                            arr[i].partnerId
                        )
                    }
                    setLoader(false)
                    setRows(newArr)
                    setAllLink(newArr)
                } else {
                    setRows(arr)
                    setAllLink(arr)
                    setLoader(false)
                }
            }
        } catch (error) {
            setLoader(false)
            return false
        }
    }

    //linkdetail api call function
    async function linkDetailApi(id) {
        setHelpFullLinkActionType("edit")
        seteditModalLoader(true)
        setLinkId(id)
        const linkDetailRes = await authServicePartner.getLinkDetail(id)
        try {
            if (linkDetailRes.status) {
                setEditLink({
                    ...editLink,
                    title: linkDetailRes.data.partnerHelpfulLinkDetail.title,
                    url: linkDetailRes.data.partnerHelpfulLinkDetail.url,
                })
                setLinkIcon(linkDetailRes.data.partnerHelpfulLinkDetail.linkIcon)
                seteditModalLoader(false)
            }
        } catch (error) {
            return false
        }
    }

    //validate function
    const editValidate = () => {
        let linkError = {}
        let isValid = false
        if (editLink.title.trim() === "") {
            isValid = true
            linkError.title = "Add Title"
        }
        if (editLink.url.trim() === "") {
            isValid = true
            linkError.url = 'Add url'
        } else if (!webRegex.test(editLink.url)) {
            isValid = true
            linkError.url = 'Add valid url'
        } else {
            linkError.url = ""
        }
        if (editLink.icon.length === 0 && !linkIcon) {
            isValid = true
            linkError.icon = 'Add link icon'
        }
        if (Object.keys(linkError).length > 0) {
            const emptyKeys = Object.keys(linkError).filter(
                key => linkError[key] !== ""
            );
            const inputElement = document.getElementsByName(emptyKeys[0])[0];
            if (inputElement) {
                inputElement.focus(); // Focus the input element
            }
        }
        setAddLinkErr(linkError)
        return isValid
    }
    //fetch metadata and og image from url
    const fetchMetaData = async (newurl) => {
        authService.getUrlMetadata(newurl).then((res) => {
            if (res.status && res.data.status && res.data.data !== null && res.data.data.og !== null && Object.keys(res.data.data.og).length > 0) {
                setAddLinkErr({ ...addLinkErr, icon: "" })
                setLinkIcon(res.data.data.og.image)
            }
            else {
                setLinkIcon('')
            }
        }).catch((err) => { return false })
    }
    //edit link api call function
    async function editLinkApi() {
        if (!editValidate(editLink)) {
            let imageCompress;
            setCreateInprocess(true)
            const editLinkFormdata = new FormData()
            editLinkFormdata.append("title", editLink.title)
            editLinkFormdata.append("url", editLink.url)
            if (editHelpFullLinkCroppedfileData) {
                imageCompress = await CompressImageFunction(editHelpFullLinkCroppedfileData);
            }
            if (imageCompress) { editLinkFormdata.append("linkIcon", imageCompress) } else {
                editLinkFormdata.append("linkIcon", linkIcon)
            }
            const editLinkres = await authServicePartner.editLink(linkId, editLinkFormdata)
            try {
                if (editLinkres.status) {
                    getAllLinkApi()
                    setAddAlertMsg(editLinkres.data.message)
                    setCreateInprocess(false)
                    setEditLink({
                        title: "",
                        url: "",
                        linkIcon: "",
                        icon: []
                    })
                    setLinkIcon('')
                    setTimeout(() => {
                        setAddAlertMsg("")
                    }, 2000)
                } else {
                    setCreateInprocess(false)
                }
                dispatch({ type: "REMOVEMODALINPROCESS" })
            } catch (error) {
                return false
                dispatch({ type: "REMOVEMODALINPROCESS" })
            }
        }
    }

    //handle editLink change
    const handleEditLinkChange = async (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        const { name, value } = e.target;
        setEditLink({ ...editLink, [name]: value })

        switch (name) {
            case "title":
                setEditLink({ ...editLink, [name]: value });
                value.trim() === ""
                    ? setAddLinkErr({ ...addLinkErr, title: "Add Title" })
                    : setAddLinkErr({ ...addLinkErr, title: "" });
                break;
            case "url":
                setEditLink({ ...editLink, [name]: value });
                if (value.trim() === "") {
                    setAddLinkErr({ ...addLinkErr, url: "Add url" });
                } else if (!webRegex.test(value)) {
                    setAddLinkErr({ ...addLinkErr, url: "Add valid url" });
                } else {
                    setAddLinkErr({ ...addLinkErr, url: "" });
                }
                if (webRegex.test(value)) {
                    fetchMetaData(value)
                }
                break;
            default:
                break;
        }

    };

    //validate function for addlink data
    const validate = () => {
        let linkError = {}
        let isValid = false
        if (addLink.title.trim() === "") {
            isValid = true
            linkError.title = "Add Title"
        }
        if (addLink.url.trim() === "") {
            isValid = true
            linkError.url = 'Add url'
        } else if (!webRegex.test(addLink.url)) {
            isValid = true
            linkError.url = 'Add valid url'
        } else {
            linkError.url = ""
        }
        if (addLink.image.length === 0 && !linkIcon) {
            isValid = true
            linkError.icon = 'Add link icon'
        }
        if (Object.keys(linkError).length > 0) {
            const emptyKeys = Object.keys(linkError).filter(
                key => linkError[key] !== ""
            );
            const inputElement = document.getElementsByName(emptyKeys[0])[0];
            if (inputElement) {
                console.log(inputElement, "inputElement");
                inputElement.focus(); // Focus the input element
            }
        }
        setAddLinkErr(linkError)
        return isValid
    }

    //handle change for addlink
    const handleChangeAddLink = async (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        const { name, value } = e.target
        setAddLink({ ...addLink, [name]: value })
        switch (name) {
            case "title":
                value.trim() === "" ? setAddLinkErr({ ...addLinkErr, title: "Add Title" }) : setAddLinkErr({ ...addLinkErr, title: "" })
                break;
            case "url":
                value.trim() === "" ? setAddLinkErr({ ...addLinkErr, url: "Add url" }) : !webRegex.test(value) ? setAddLinkErr({ ...addLinkErr, url: "Add valid url" }) : setAddLinkErr({ ...addLinkErr, url: "" })
                if (value.trim() === "") {
                    setAddLinkErr({ ...addLinkErr, url: "Add url" });
                } else if (!webRegex.test(value)) {
                    setAddLinkErr({ ...addLinkErr, url: "Add valid url" });
                } else {
                    setAddLinkErr({ ...addLinkErr, url: "" });
                }
                if (webRegex.test(value)) {
                    fetchMetaData(value)
                }
                break;
            default:
                break;
        }

    }

    //add link api function
    async function addLinkApi() {
        let imageCompress;
        const addLinkFormData = new FormData()
        addLinkFormData.append("title", addLink.title)
        addLinkFormData.append("url", addLink.url)
        if (createHelpFullLinkCroppedfileData) {
            imageCompress = await CompressImageFunction(createHelpFullLinkCroppedfileData);
        }
        imageCompress ? addLinkFormData.append("linkIcon", imageCompress) : addLinkFormData.append("linkIcon", linkIcon)
        addLinkFormData.append("partnerId", id)
        const addLinkRes = await authServicePartner.addHelpfulLink(addLinkFormData)
        try {
            if (addLinkRes.status) {
                setCreateInprocess(false)
                setAddAlertMsg(addLinkRes.data.message)
                getAllLinkApi()
                setAddLink({
                    title: "",
                    url: "",
                    image: []
                })
                setLinkIcon('')
                setTimeout(() => { setAddAlertMsg('') }, 2000)
                dispatch({ type: "REMOVEMODALINPROCESS" })
            } else {
                setCreateInprocess(false)
                dispatch({ type: "REMOVEMODALINPROCESS" })
            }
        } catch (error) {
            setCreateInprocess(false)
            dispatch({ type: "REMOVEMODALINPROCESS" })
            return false
        }
    }

    //handle add link
    const handleAddLink = (e) => {
        e.preventDefault()
        if (!validate(addLink)) {
            setCreateInprocess(true)
            addLinkApi()
        }
    }

    //deletlink api call function
    async function deleteLinkApi() {
        setInProcess(true)
        const deleteLinkRes = await authServicePartner.deleteLink(linkId)
        try {
            if (deleteLinkRes.status) {
                setShowMsg(true)
                setInProcess(false)
                setAddAlertMsg(deleteLinkRes.data.message)
                document.querySelector('#Deletelink .close').click()
                getAllLinkApi()
                setTimeout(() => {
                    setShowMsg(false)
                    setAddAlertMsg('')
                }, 2000);
            } else {
                setShowMsg(true)
                setInProcess(false)
                setAddAlertMsg(deleteLinkRes.data.message)
                document.querySelector('#Deletelink .close').click()
                getAllLinkApi()
                setTimeout(() => {
                    setShowMsg(false)
                    setAddAlertMsg('')
                }, 2000);
            }
        } catch (error) {
            setInProcess(false)
            document.querySelector('#Deletelink .close').click()
            return false
        }
    }
    //object from array
    const createObjectFromArray = (arr, updateAllEventArray = true) => {
        var tbl = [];
        for (var i = 0; i < arr.length; i++) {
            tbl[i] = createLinkData(
                arr[i].action,
                arr[i].title,
                arr[i].url,
                arr[i].linkIcon,
                arr[i].partnerId
            );
        }
        setRows(tbl);
        if (updateAllEventArray) setAllLink(tbl);
    }

    //close create module
    const handleCloseAddModel = (e) => {
        setHelpFullLinkActionType("add")
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
        dispatch({ type: "REMOVEMODALINPROCESS" })
        setAddLink({
            title: "",
            url: "",
            image: []
        });
        setAddLinkErr({
            title: "",
            url: "",
            icon: ""
        });
        setEditLink({
            title: "",
            url: "",
            linkIcon: "",
            icon: []
        });
        setLinkIcon('')
    };

    //for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    //for pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    //sorting function
    const handleSort = (col, direc) => {
        setLoader(true)
        getAllLinkApi(col, direc)
    };

    //stayonpage  popup for do you want to leave page
    useEffect(() => {
        if (stayOnPage !== null && stayOnPage) {
            if ($('#AddHelpfulLink').hasClass('show')) {
                $("#AddHelpfulLink").modal('show')
            }

            if ($('#editHelpfulLink').hasClass('show')) {
                $("#editHelpfulLink").modal('show')
            }

        }
        else if (stayOnPage !== null && !stayOnPage) {
            if ($('#AddHelpfulLink').hasClass('show')) { $("#AddHelpfulLink").modal('hide') }

            if ($('#editHelpfulLink').hasClass('show')) { $("#editHelpfulLink").modal('hide') }
        }

    }, [stayOnPage])

    //hadnle zoom for logo 
    const handleZoomImage = (e) => {
        setIsZoomImg(true);
        setZoomImg(e.target.src);
    }
    // thumbnail image upload function
    const thumbnailImages = async (acceptedfile) => {
        setFileDimension(acceptedfile);
        dispatch({ type: "SETMODALINPROCESS" });
        setFileName(Math.random().toString() + acceptedfile.name);
    };
    return (
        <>
            {modalOpen && (
                <ImageCropperReact
                    file={thumbnail}
                    setModalOpen={setModalOpen}
                    setNotificationDetail={setAddLink}
                    notificationDetail={addLink}
                    fileName={fileName}
                    fileDimension={fileDimension}
                    actionType={helpFullLinkActionType}
                    setAddIconFile={setAddIconFile}
                    setEditIconFile={setEditIconFile}
                    setcreateHelpFullLinkCroppedfileData={setcreateHelpFullLinkCroppedfileData}
                    setEditHelpFullLinkCroppedfileData={setEditHelpFullLinkCroppedfileData}
                    requiredImageAspectRatio={1 / 1}
                    isLoading={isLoading}
                />)}
            <div className="uic-title-rt-only">
                <div className="uic-fl-btn">
                    <button
                        className="btn"
                        data-toggle="modal"
                        data-target="#AddHelpfulLink"
                        onClick={handleCloseAddModel}
                    >
                        Create Helpful Link
                    </button>
                </div>
            </div>
            <SearchAndSorting currentArray={allLink}
                placeholderText={"Search helpful link"}
                createObjectFromArray={createObjectFromArray} s
                ortingOn={"title"}
                searchOn={["title"]}
                listIndex={listIndex}
                suggestionList={suggestionList}
                setSuggestionBox={setSuggestionBox}
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                suggestionBox={suggestionBox}
                setListIndex={setListIndex}
                setClearSearch={setClearSearch}
                clearSearch={clearSearch}
            />
            {showMsg && <div className="alert alert-info">{addAlertMsg}</div>}
            <Paper className="uic-tb-mn">
                <TableContainer className="uic-table-main">
                    <Table className="uic-table">
                        <TableHead className="uic-table-th">
                            <TableRow>
                                {columns.map((col) => (
                                    <TableCell
                                        key={col.id}
                                        align={col.align}
                                        className="uic-th-td "
                                    >
                                        {col.id !== "action" && col.id !== "linkIcon" ? (
                                            <>
                                                <span className="custom-sorting">
                                                    <i className="fa fa-angle-down" onClick={(e) => {
                                                        handleSort(col.id, "Asc");
                                                    }}
                                                    />
                                                    <i className="fa fa-angle-up" onClick={(e) => {
                                                        handleSort(col.id, "Dec");
                                                    }} />
                                                </span>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {col.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className="uic-table-td">
                            {loader ?
                                <div className="cstm-data-load"><img src={Loader} /> </div>
                                :
                                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length > 0 ?
                                    rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    className="uic-th-td "
                                                >
                                                    {columns.map((column) => {
                                                        const value = row[column.id]
                                                        return column.id === "title" ? <TableCell className="uic-th-td w-35" >
                                                            <div className='cstm-lg-ttl'>
                                                                <img onClick={handleZoomImage} className="cstm-url-link" src={row["linkIcon"]?.includes('https') ? row["linkIcon"] : process.env.REACT_APP_AWS_IMG_VID_PATH + row["linkIcon"]} alt="image" />
                                                                <a className="cstm-view-users-count"
                                                                    data-toggle="modal"
                                                                    data-target="#editHelpfulLink"
                                                                    onClick={() => {
                                                                        linkDetailApi(row.action)
                                                                        setAddLinkErr({
                                                                            title: "",
                                                                            url: "",
                                                                            icon: ""
                                                                        })
                                                                    }}
                                                                >{value}</a></div></TableCell> :
                                                            column.id === "url" ? <TableCell className="uic-th-td w-35"><Link to={value} target='_blank'>{value}</Link></TableCell> : column.id === "action" ?
                                                                <TableCell className="uic-th-td  uic-table-icon w-8">
                                                                    <a
                                                                        data-toggle="modal"
                                                                        data-target="#editHelpfulLink"
                                                                        onClick={() => {
                                                                            linkDetailApi(value)
                                                                            setAddLinkErr({
                                                                                title: "",
                                                                                url: "",
                                                                                icon: ""
                                                                            })
                                                                        }}
                                                                    >
                                                                        <Tooltip title='Edit' arrow><img src={editfill} /></Tooltip>
                                                                    </a>
                                                                    <a
                                                                        data-toggle="modal"
                                                                        data-target="#Deletelink"
                                                                        onClick={() => setLinkId(value)}
                                                                    >
                                                                        <Tooltip title='Delete' arrow><img src={imgdelete} /></Tooltip>
                                                                    </a>
                                                                </TableCell> : <></>
                                                    })}
                                                </TableRow>
                                            )
                                        }) :
                                    <span className="no-data-found">No data found</span>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    className="uic-pagination"
                    rowsPerPageOptions={[20, 50, 100, 200]}
                    component="div"
                    count={allLink.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {/* crate helpful link */}
            <div
                className="modal fade edit-user-details-popup"
                id="AddHelpfulLink"
                tabIndex="-1"
                data-backdrop="static"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="edit-user-details-header">
                            <h2>Create Helpful Link</h2>
                            <button
                                type="button"
                                className="close"
                                //data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => {
                                    if (modalInProcess) {
                                        dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                    } else {
                                        $("#AddHelpfulLink").modal('hide')
                                        handleCloseAddModel(e)
                                    }

                                }}
                            >
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            {addAlertMsg.length > 0 && (
                                <div className="alert alert-info">{addAlertMsg}</div>
                            )}
                            <div className="form-group">
                                <div className="form-group-label">Title*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="text"
                                        placeholder="Enter title"
                                        name="title"
                                        value={addLink.title}
                                        onChange={handleChangeAddLink}
                                    />
                                    <span className="error">{addLinkErr.title}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-group-label">URL*</div>
                                <div className="form-group-ct">
                                    <input
                                        type="url"
                                        placeholder="Enter url"
                                        name="url"
                                        onChange={handleChangeAddLink}
                                        value={addLink.url}
                                    />
                                    <span className="error">{addLinkErr.url}</span>
                                </div>
                            </div>
                            {/* crate helpful link Dropzone */}
                            <Dropzone
                                onDrop={(acceptedFiles) => {
                                    // Check if any of the dropped files are not allowed
                                    const file = acceptedFiles[0];
                                    if (file.type.startsWith("image/")) {
                                        setModalOpen(true)
                                        setIsLoading(true)
                                        thumbnailImages(file);
                                        const reader = new FileReader();
                                        reader.onload = () => {setThumbnail(reader.result),setIsLoading(false)};
                                        reader.readAsDataURL(file);
                                        setAddLinkErr({ ...addLinkErr, icon: "" });
                                    }
                                }}
                                name="thumbnail"
                                multiple={false}
                                accept="image/jpeg, image/png,  image/tiff, image/jpg"
                                maxSizeBytes={10485760}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input {...getInputProps()} />
                                        <div className="group-info-user cstm-upload-box">
                                            <div className="group-info-user-img">
                                                <img
                                                    className={""}
                                                    src={addIconFile ? addIconFile : linkIcon !== undefined && linkIcon !== null && linkIcon !== "" && linkIcon?.includes('https') && linkIcon.length > 0 ? linkIcon : Frame}
                                                />
                                            </div>
                                            <div className="group-info-user-link">
                                                <div className="cover-image-upload-ttl">
                                                    Add icon*
                                                </div>
                                            </div>
                                            <span className="error">{addLinkErr.icon}</span>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                        </div>

                        <div className="edit-user-details-footer cstm-create-button">
                            {createInprocess ? (
                                <div className="loader-button1">
                                    <button>In Process...</button>
                                    <span className="loader-btn">
                                        <img src={Loader2} />
                                    </span>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={handleAddLink}
                                    disabled={createInprocess}
                                >
                                    Create
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/* edit helpful link */}
            <div
                className="modal fade edit-user-details-popup"
                id="editHelpfulLink"
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                data-keyboard="false"
            >
                <div className="modal-dialog" role="document">
                    {editModalLoader ?
                        <div className="edit-modal-loader">
                            <img src={Loader} />
                        </div> : (
                            <div className="modal-content">
                                <div className="edit-user-details-header">
                                    <h2>Edit Helpful Link</h2>
                                    <button
                                        type="button"
                                        className="close"
                                        //data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={(e) => {
                                            if (modalInProcess) {
                                                dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" })
                                            } else {
                                                $("#editHelpfulLink").modal('hide')
                                                handleCloseAddModel(e)
                                            }

                                        }}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {addAlertMsg.length > 0 && (
                                        <div className="alert alert-info">{addAlertMsg}</div>
                                    )}
                                    <div className="form-group">
                                        <div className="form-group-label">Title*</div>
                                        <div className="form-group-ct">
                                            <input
                                                type="text"
                                                placeholder="Enter title"
                                                name="title"
                                                value={editLink.title}
                                                onChange={handleEditLinkChange}
                                            />
                                            <span className="error">{addLinkErr.title}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="form-group-label">URL*</div>
                                        <div className="form-group-ct">
                                            <input
                                                type="url"
                                                placeholder="Enter url"
                                                name="url"
                                                onChange={handleEditLinkChange}
                                                value={editLink.url}
                                            />
                                            <span className="error">{addLinkErr.url}</span>
                                        </div>
                                    </div>
                                    {/* edit helpful link Dropzone */}
                                    <Dropzone
                                        onDrop={(acceptedFiles) => {
                                            // Check if any of the dropped files are not allowed
                                            const file = acceptedFiles[0];
                                            if (file.type.startsWith("image/")) {
                                                setModalOpen(true)
                                                setIsLoading(true)
                                                thumbnailImages(file);
                                                const reader = new FileReader();
                                                reader.onload = () => {setThumbnail(reader.result),setIsLoading(false)};
                                                reader.readAsDataURL(file);
                                                setAddLinkErr({ ...addLinkErr, icon: "" });
                                            }
                                        }}
                                        name="thumbnail"
                                        multiple={false}
                                        accept="image/png, image/gif, image/jpeg, image/jpg"
                                        maxSizeBytes="10485760"
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps({ className: "dropzone" })}>
                                                <input {...getInputProps()} />
                                                <div className="group-info-user cstm-upload-box">
                                                    <div className="group-info-user-img">
                                                        <img
                                                            className={""}
                                                            src={editIconFile ? editIconFile : linkIcon !== null && linkIcon !== undefined && linkIcon.length > 0 ? linkIcon?.includes('https') ? linkIcon : process.env.REACT_APP_AWS_IMG_VID_PATH + linkIcon : Frame}
                                                        />
                                                    </div>
                                                    <div className="group-info-user-link">
                                                        <div className="cover-image-upload-ttl">
                                                            Add icon*
                                                        </div>
                                                    </div>
                                                    <span className="error">{addLinkErr.icon}</span>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                                <div className="edit-user-details-footer cstm-create-button">
                                    {createInprocess ? (
                                        <div className="loader-button1">
                                            <button>In Process...</button>
                                            <span className="loader-btn">
                                                <img src={Loader2} />
                                            </span>
                                        </div>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn"
                                            onClick={() => editLinkApi()}
                                            disabled={createInprocess}
                                        >
                                            Update
                                        </button>
                                    )}
                                </div>
                            </div>)}
                </div>
            </div>
            {/* zoomModel */}
            {isZoomImg && (zoomImg !== null) && (
                <div className="desk-globle-model-box globle-model-box cstm-zoom-img-popup cstm-company-logo" >
                    <div className="desk-globle-model-box-inner cstm-zoom-img-popup">
                        <div className="modal-content">
                            <div className="cstm-replace-pp">
                                <div className="cstm-rpp-title">
                                    Logo Image
                                    <span
                                        className="cstm-rpp-close-btn"
                                        onClick={() => setIsZoomImg(false)}
                                    >
                                        &times;
                                    </span>
                                </div>

                                {zoomImg && (
                                    <div
                                        id="zoomImgContainer"
                                        className="zoomImgContainer"

                                    >
                                        <img id="zoomImg" src={zoomImg} className="cstmzoomImg" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Modal Delete */}
            <div
                className="modal fade msg-popup"
                id="Deletelink"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            &times;
                        </button>
                        <div className="msg-icon">
                            <img src={imgBigDelete} alt="" />
                        </div>
                        <h1>Are you sure you want to delete this helpful link from {partnername}?</h1>
                        <div className="row">
                            <div className="col-6">
                                {inProcess ? (
                                    <div className="loader-button1">
                                        <button>In Process...</button>
                                        <span className="loader-btn">
                                            <img src={Loader2} />
                                        </span>
                                    </div>
                                ) : (
                                    <span className="msg-btn">
                                        <button
                                            type="button"
                                            className="btn btn-red"
                                            onClick={deleteLinkApi}
                                        >
                                            Delete
                                        </button>
                                    </span>)}
                            </div>
                            <div className="col-6">
                                <span className="msg-btn">
                                    <button type="button" className="btn2" data-dismiss="modal">
                                        Discard
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AlertComponent/> */}
        </>
    )
}

export default HelpFulLink