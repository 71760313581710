import React, { useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import CloseAttachment from "./CloseAttachment";
import PlayAttachment from "./PlayAttachment";
import VoiceMessageControls from "./VoiceMessageControls";
import WaveSurfer from "wavesurfer.js";
import FileIcon from "../../../../components/Icons/GeneralIcons/FileIcon";
// import PlayIcon from "../../../../components/Icons/VideoPlayerIcons/PlayIcon";

// Images
// import VoiceMessagesImage from "../../../../Images/VoiceMessage.png";
import Soundwave from "../../../../Images/soundwave.svg";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageBarAttachments.css";

const MessageBarAttachments = ({
  alt = "images",
  handleClose,
  duration = "2:15",
  files = [
    {
      fileName: "filename.svg",
      size: "55KB",
    },
  ],
  voiceControlType = false,
  audioBlobData,
  hasImages = true,
  hasVideos = false,
  hasFiles = false,
  hasVoice = false,
  imageSrc = [
    "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  ],
  videoSrc = [
    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
  ],
  onCompletePlayback,
  handlePreviewClick,
}) => {
  const wavesurferRef = useRef(null);
  const [currentDuration, setCurrentDuration] = useState(duration);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    // Initialize Wavesurfer when the component mounts
    if (audioBlobData) {
      wavesurferRef.current = WaveSurfer.create({
        container: "#wavesurfer-container",
        height: 24,
        waveColor: "#CFCFCF",
        progressColor: "#0d99ff",
        cursorWidth: 0,
        barWidth: 2,
        barHeight: 6,
        barGap: 2,
      });
      // Convert the audio blob to an object URL
      const audioUrl = URL.createObjectURL(audioBlobData);

      // Load the audio data into WaveSurfer
      wavesurferRef.current.load(audioUrl);
      wavesurferRef.current.on("play", () => {
        setIsPlaying(true);
        updateDuration(); // Start updating duration on play
      });

      wavesurferRef.current.on("pause", () => {
        setIsPlaying(false);
        updateDuration(); // Update duration on pause
      });

      wavesurferRef.current.on("finish", () => {
        setIsPlaying(false);
      });

      return () => {
        if (wavesurferRef.current) {
          wavesurferRef.current.destroy();
        }
      };
    }
  }, [audioBlobData]);

  const updateDuration = () => {
    const interval = setInterval(() => {
      const currentTime = wavesurferRef.current.getCurrentTime();
      setCurrentDuration(formatDuration(currentTime));
    }, 1000);

    wavesurferRef.current.on("audioprocess", () => {
      const currentTime = wavesurferRef.current.getCurrentTime();
      setCurrentDuration(formatDuration(currentTime));
    });

    wavesurferRef.current.on("pause", () => {
      clearInterval(interval);
    });
  };

  const formatDuration = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    // Use padStart to ensure two-digit format
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const playAudio = () => {
    wavesurferRef.current.play();
  };

  const pauseAudio = () => {
    wavesurferRef.current.pause();
  };

  return (
    <div className="flex items-center attachment-bar-sec">
      {hasImages && (
        <>
          {imageSrc &&
            imageSrc.length &&
            imageSrc.map((images, index) => {
              return (
                <div className="attachment-items relative">
                  <img
                    src={images}
                    alt={alt}
                    onClick={() => handlePreviewClick(images, "media")}
                  />
                  <div className="attachment-close absolute">
                    <CloseAttachment
                      onClick={() => {
                        handleClose("image", index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </>
      )}
      {hasVideos && (
        <>
          {videoSrc &&
            videoSrc.length &&
            videoSrc.map((video, index) => {
              return (
                <div className="attachment-items relative">
                  {/* video */}
                  <video
                    src={video}
                    onClick={() => handlePreviewClick(video, "media")}
                  ></video>
                  {/* <video src={video} alt={alt} /> */}
                  <div className="attachment-close absolute">
                    <CloseAttachment
                      onClick={() => {
                        handleClose("video", index);
                      }}
                    />
                  </div>
                  <div className="attachment-play absolute">
                    <PlayAttachment />
                  </div>
                </div>
              );
            })}
        </>
      )}
      {hasFiles && (
        <>
          {files &&
            files.length &&
            files.map((file, index) => {
              return (
                <div className="attachment-files relative flex items-center">
                  <FileIcon
                    width={24}
                    height={24}
                    iconColor="var(--color-icon-neutral-strong)"
                  />
                  <div className="attachment-content">
                    <p className="file-title">{file.fileName}</p>
                    <p className="file-size">{file.size}</p>
                  </div>
                  <div className="attachment-close absolute">
                    <CloseAttachment
                      onClick={() => {
                        handleClose("file", index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </>
      )}

      {hasVoice && (
        <>
          <div className="attachment-voice relative flex items-center">
            <VoiceMessageControls
              type={voiceControlType}
              onPlayClick={playAudio}
              onPauseClick={pauseAudio}
              isPlaying={isPlaying}
            />
            <div className="flex items-center voice-content">
              <div id="wavesurfer-container"> </div>
              <p>{currentDuration}</p>
            </div>
            <div className="attachment-close absolute">
              <CloseAttachment
                onClick={() => {
                  handleClose("voice", 0);
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

MessageBarAttachments.propTypes = {
  alt: PropTypes.string,
  handleClose: PropTypes.func,
  files: PropTypes.array,
  duration: PropTypes.string,
  voiceControlType: PropTypes.bool,
  hasImages: PropTypes.bool,
  hasVideos: PropTypes.bool,
  hasFiles: PropTypes.bool,
  hasVoice: PropTypes.bool,
  imageSrc: PropTypes.array,
  videoSrc: PropTypes.array,
  audioBlobData: PropTypes.instanceOf(Blob),
  onCompletePlayback: PropTypes.func,
  handlePreviewClick: PropTypes.func,
};

export default MessageBarAttachments;
