import React, { useState } from 'react'
import mdsLogoImgForCatelogry from "../../../../Images/logo_mds_vertical_light.png";
import ArrowLeftBack from "../../../../Images/arrow-left-back.svg";
import IconMSearch from "../../../../Images/icn_m_search.svg";
import SearchNoResults from "../../../../Images/search_no_results.svg";
import Loader from "../../../../Images/loader.gif";
import Cancel from "../../../../Images/Cancel.svg";
import { Tooltip } from '@mui/material';
const PartnerListModal = ({ loader, setReview, isList, setList, partnerList, setSelectedPartner, selectedPartner, setRating, handleSearch, searchText, setPendingReview, pendingReview }) => {
    const [selectedPartnerId, setSelectedPartnerId] = useState([])

    // handle partner select from list
    const handlePartnerSelect = (id) => {
        const newSelected = [...selectedPartner];
        const newSelectedId = [...selectedPartnerId]
        const selectedIndex = newSelected.indexOf(id);
        if (selectedIndex === -1) {
            if (newSelected.length < 3 && pendingReview < 3) {
                setPendingReview(pendingReview + 1)
                newSelectedId.push(id._id)
                newSelected.push(id);
            }
        } else {
            setPendingReview(pendingReview - 1)
            newSelectedId.splice(selectedIndex, 1);
            newSelected.splice(selectedIndex, 1);
        }
        setSelectedPartnerId(newSelectedId)
        setSelectedPartner(newSelected);
    }
    return (
        <>
            <div className={`${isList ? "modal-fadein" : "modal-fadeout"}`}>
                <div className="desk-globle-model-box globle-model-box ">
                    <div className="desk-globle-model-box-inner select-partners-popup-main">

                        <div className="select-partners-pp-only">
                            <span className='select-partners-pp-icon-bank' onClick={() => {
                                setReview(true)
                                setList(false)
                            }}><img src={ArrowLeftBack} /></span>
                            <h3 className="partners-details-popup-title">Select partners</h3>
                        </div>

                        <div className="select-partners-pp-search-field">
                            <input placeholder='Search' type='text' value={searchText} onChange={(e) => handleSearch(e.target.value)} />{searchText.length > 0 ? <img className='search-icon-cloce' src={Cancel} onClick={() => { handleSearch("") }} /> : <img className='search-icon-field' src={IconMSearch} />}
                        </div>
                        {loader ? <div className='user-loader'>
                            <img src={Loader} />
                        </div>
                            : partnerList.length > 0 ?
                                <>
                                    <div className="select-partners-pp-search-img">
                                        {partnerList.map((item) => (
                                            <>
                                                <Tooltip title={item.companyName} arrow>
                                                    <div onClick={() => {
                                                        handlePartnerSelect(item)
                                                    }} className={`select-partners-pp-search-img-in ${selectedPartnerId.includes(item._id) ? 'select-active' : ''}`}>
                                                        <img src={item.thumbnail !== "" ? item.thumbnail : mdsLogoImgForCatelogry} />
                                                    </div>
                                                </Tooltip>
                                            </>
                                        ))}
                                    </div>
                                </> : <div className="select-partners-pp-search-no-data">

                                    <div className="cstm-search-no-data-icon"><img src={SearchNoResults} /></div>

                                    <div className="cstm-search-no-data-title">
                                        <h4 className='cstm-oops-ttile'>Oops! No results this time</h4>
                                        <p className='cstm-please-adjust-sub-title'>Please adjust your searching and give it another go!</p>
                                    </div>

                                    <div className="cstm-search-no-data-btn">
                                        <span onClick={() => { handleSearch("") }} className="partners-details-btn-main">Clear search</span>
                                    </div>

                                </div>}
                        <div className="btn-footer-pp-only">
                            {pendingReview === 3 ? <button className="btn-pick-more"
                                onClick={() => {
                                    setRating(true)
                                    setList(false)
                                }}
                            >Continue</button> :
                                <button className="disabled-btn">
                                    {`Pick ${pendingReview > 0 ? 3 - pendingReview : 3} more`}
                                </button>}
                            <p className='never-used-text'>Never used any of our partner's services? <a href='#' onClick={() => {
                                if (window.Intercom) {
                                    window.Intercom('show');
                                }
                            }} >Send us a message</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PartnerListModal