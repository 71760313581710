import React from "react";

const IconNavNews = () => {
  return (
    <div className="mds-icons">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width={24}
        height={24}
        fill="none"
      >
        <path
          fillRule="evenodd"
          d="M18.5 3.5h-10a2 2 0 0 0-2 2v14a1 1 0 0 0 1 1h11a2 2 0 0 0 2-2v-13a2 2 0 0 0-2-2ZM8.5 2A3.5 3.5 0 0 0 5 5.5v14A2.5 2.5 0 0 0 7.5 22h11a3.5 3.5 0 0 0 3.5-3.5v-13A3.5 3.5 0 0 0 18.5 2h-10Zm.25 10a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 0 1.5h-8a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h8a.75.75 0 0 0 0-1.5h-8ZM8.75 9a.75.75 0 0 1 .75-.75h8a.75.75 0 0 1 0 1.5h-8A.75.75 0 0 1 8.75 9Zm-5.5-.5a.75.75 0 0 0-1.5 0v12a.75.75 0 0 0 1.5 0v-12Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  );
};

export default IconNavNews;
