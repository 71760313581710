import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Header from "../../../components/UserComponents/Dashboard/Header";
import authServiceChannel from "../../../Services/auth.header.channel";
import Button from "../../../components/Button/Button";
import authService from "../../../Services/auth.service";
import newsocket from "../../../components/SocketConnect.js";
import Spinners from "../../../components/Spinners/Spinners.js";

export default function CreateInviteLink() {

  const { channel_id } = useParams();
  const navigate = useNavigate();
  const [channelDetails, setChannelDetails] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [isMainLoader, setMainLoader] = useState(true)
  const [channelMemberAlreadyExist, setChannelMemberAlreadyExist] =
    useState(false);
  const userId = localStorage.getItem("userid");
  const idToken = localStorage.getItem("idtoken");

  // previousUrl Define
  useEffect(() => {
    sessionStorage.setItem("previousurl", window.location.href);
  }, []);

  // navigate to login screen
  useEffect(() => {
    if (!userId || !idToken) {
      navigate("/"); // Redirect to the login page
    }
  }, [userId, idToken, navigate]);

  // get channel data by id 
  useEffect(() => {
    authServiceChannel
      .getChannelDetailByIdFrontend(channel_id)
      .then((response) => {
        if (response.data.status) {
          const userAlreadyExist = response.data.membersList.some(
            (membersList) => {
              return membersList._id === userId;
            }
          );
          setChannelMemberAlreadyExist(userAlreadyExist);
          setChannelDetails(response.data.channelData);
          setMainLoader(false);
        } else {
          navigate("/chats/me/me?channelnotfound");
          setMainLoader(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setMainLoader(false);
      });
  }, [channel_id]);

  // handle onclick in join now button
  const onClickJoinNow = () => {
    if (channelMemberAlreadyExist) {
      navigate(
        `/chats/${channel_id}/chatChannel?user_name=${channelDetails.channelName
        }&user_image=${process.env.REACT_APP_AWS_IMG_VID_PATH + channelDetails?.channelIcon
        }`
      );
    } else {
      setLoader(true);
      authService
        .joinInviteLinkChannel({
          ChannelId: channel_id,
        })
        .then((response) => {
          if (response.data.status) {
            newsocket.emit("newChatList", {
              id: localStorage.getItem("userid"),
            });
            navigate(
              `/chats/${channel_id}/chatChannel?user_name=${channelDetails.channelName
              }&user_image=${process.env.REACT_APP_AWS_IMG_VID_PATH +
              channelDetails?.channelIcon
              }`
            );
            setLoader(false);
          }
        })
        .catch((e) => {
          console.log(e);
          setLoader(false);
        });
    }
  };
  return (
    <>
      <Header />
      {!isMainLoader ?
        <div className="invite-info-page">
          <div className={`empty-state`}>
            <div className="empty-state-icon h-auto">
              <img
                src={
                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                  channelDetails?.channelIcon
                }
                width={96}
                height={96}
                className="rounded-full"
                alt="logo-img"
              />
            </div>
            <div className="empty-state-info">
              <h4 className="empty-state-info-title">
                You've been invited to join the <strong>{channelDetails.channelName}</strong>{" "}
                channel!
              </h4>
            </div>
            <Button
              label={channelMemberAlreadyExist ? "Go to channel" : "Join now"}
              size="medium"
              variant="primary"
              hasText={true}
              toolTipText="Join now"
              handleClick={onClickJoinNow}
              hasLoading={isLoader}
              spinnersType="white"
              disabled={isLoader}
            />
            <p className="invite-text pt-2">
              Not interested?
              <Link to={"/chats/me/me"} className="fw-semibold">
                Decline invite
              </Link>
            </p>
          </div>
        </div>
        :
        <div className="fullscreen-loader">
          <Spinners size="32" type="brand" />
        </div>
      }
    </>
  );
}
