import React from 'react'
import PropTypes from "prop-types"
const ReplyIcon = ({ height = 24, width = 24, iconColor = "#000" }) => {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M20.324 18.54c.951.962 1.666 2.053 1.666 3.144l.003-.436c.01-.8.026-2.028-.139-3.421-.295-2.503-1.17-5.54-3.698-7.591-1.516-1.23-3.545-1.464-5.489-1.464a.253.253 0 0 1-.251-.254V2.539c0-.48-.573-.72-.908-.38l-9.353 9.46a.542.542 0 0 0 0 .761l9.353 9.461a.531.531 0 0 0 .908-.38v-6.076a.03.03 0 0 1 .03-.03 15.447 15.447 0 0 1 1.565.08c1.55.163 3.106.596 4.346 1.47.67.471 1.366 1.028 1.967 1.636Zm-9.504-.163a.2.2 0 0 1-.342.14l-6.304-6.376a.2.2 0 0 1 0-.282l6.304-6.376a.2.2 0 0 1 .342.14v2.895c0 1.032.827 1.868 1.847 1.868 1.892 0 3.43.248 4.49 1.109 1.313 1.066 2.11 2.486 2.585 3.991.058.182-.152.324-.307.213l-.166-.119c-2.048-1.442-4.657-1.84-6.823-1.84-.898 0-1.626.737-1.626 1.645v2.992Z"
                    clipRule="evenodd"
                />
            </svg>
        </>
    )
}
ReplyIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    iconColor: PropTypes.string,
}
export default ReplyIcon
