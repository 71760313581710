import React from "react";
import PropTypes from "prop-types"
function VideoFileIcon({ height = 24, width = 24}) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#FA0000"
                    fillRule="evenodd"
                    d="M5.864 1h8.902L21 7.498v12.638A2.861 2.861 0 0 1 18.141 23H5.864A2.862 2.862 0 0 1 3 20.136V3.864A2.862 2.862 0 0 1 5.864 1Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    d="M12.003 10A4.006 4.006 0 0 0 8 14.003a4 4 0 0 0 8 0A4.008 4.008 0 0 0 12.003 10Zm1.683 4.127a.328.328 0 0 1-.13.13L11.273 15.4a.287.287 0 0 1-.384-.13.226.226 0 0 1-.03-.13v-2.283c0-.16.13-.284.284-.284.043 0 .087.006.13.03l2.283 1.14a.289.289 0 0 1 .13.383Z"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
            </svg>
        </>
    );
}
VideoFileIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
export default VideoFileIcon;
