import axios from "axios";
import authHeader from "../Services/auth.header";
import authAdminHeader from "./auth.admin.header";
const API_URL = process.env.REACT_APP_API_URL;
const CHAT_API_URL = process.env.REACT_APP_CHAT_BASE_API_URL;
const createChannel = (data) => {
  return axios.post(API_URL + "create/channel", data, {
    headers: authAdminHeader(),
  });
};

const getAllChannelList = (query) => {
  return axios.get(API_URL + "list/channel" + query, {
    headers: authAdminHeader(),
  });
};
const deleteChannel = (data) => {
  return axios.patch(
    API_URL + "delete/channel/" + data,
    {},
    { headers: authAdminHeader() }
  );
};

const editChannel = (data) => {
  return axios.patch(API_URL + "edit/channel/" + data.id, data.data, {
    headers: authAdminHeader(),
  });
};
const getChannelDetailById = (data) => {
  return axios.get(API_URL + "get/channel/members/" + data, {
    headers: authAdminHeader(),
  });
};
const getChannelDetailByIdFrontend = (data) => {
  return axios.get(API_URL + "get/channel/members/frondend/" + data, {
    headers: authHeader(),
  });
};
const createChannelSendMessage = (data) => {
  return axios.post(CHAT_API_URL + "createChannelSendMessage", data, {
    headers: authAdminHeader(),
  });
};
const editChannelSendMessage = (data) => {
  return axios.post(CHAT_API_URL + "editChannelSendMessage", data, {
    headers: authAdminHeader(),
  });
};
const deleteChannelSendMessage = (data) => {
  return axios.post(CHAT_API_URL + "deleteChannelSendMessage", data, {
    headers: authAdminHeader(),
  });
};

const getChannelUserListDetails = (data) => {
  return axios.get(API_URL + "get/channel/members/list-info/" + data, {
    headers: authAdminHeader(),
  });
};

export default {
  createChannel,
  getAllChannelList,
  deleteChannel,
  editChannel,
  getChannelDetailById,
  getChannelDetailByIdFrontend,
  createChannelSendMessage,
  editChannelSendMessage,
  deleteChannelSendMessage,
  getChannelUserListDetails,
};
