import React, { useState } from "react";
import "../Styles/ChatMemberCell.css";
import PropTypes from "prop-types";
import "../../../../Styles/Theme/Theme.css";
import Button from "../../../../components/Button/Button";
import Typography from "../../../../components/Typography/Typography";
import Avtar from "../../../../components/Avtar/Avtar";
import DeleteIcon from "../../../../components/Icons/GeneralIcons/DeleteIcon";
const ChatMemberCell = ({
  hasText = true,
  handleClick,
  name = "Guy Hawkins",
  title = "Owner",
  src = "",
  isOnline = "online",
  hasStatus = false,
  handleChatList,
  chat,
  hasRemoveMemberButton,
  handleRemoveMemberButtonClick = () => { },
  badgeStatus,
  data,
  disabledRemoveButton,
}) => {
  const types = src === "" ? "letter" : "photo";

  const [deleteIconShow, setDeleteIconShow] = useState(false);

  return (
    <>
      <div
        class="chat-member-cell"
        onClick={handleClick}
        onMouseEnter={() => {
          setDeleteIconShow(true);
        }}
        onMouseLeave={() => {
          setDeleteIconShow(false);
        }}
      >
        <div class="chat-member-cell-avatar">
          <Avtar
            badgeStatus={badgeStatus}
            size="40"
            src={src}
            letter={name}
            type={types}
            hasStatus={hasStatus}
            badgeSize="medium"
          />
        </div>
        <div class="chat-member-cell-text" onClick={() => handleChatList(chat)}>
          <Typography variant="h5">{name}</Typography>
          {hasText ? <Typography variant="h6">{title}</Typography> : ""}
        </div>
        {hasRemoveMemberButton && deleteIconShow ? (
          <Button
            elementIcon={<DeleteIcon />}
            variant="ghost"
            hasText={false}
            size="small"
            handleClick={handleRemoveMemberButtonClick}
            toolTipText="Remove member"
            disabled={data.userId === disabledRemoveButton ? true : false}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

ChatMemberCell.propTypes = {
  hasText: PropTypes.bool,
  src: PropTypes.string,
  handleChatList: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string,
  badgeStatus: PropTypes.string,
  hasStatus: PropTypes.bool,
  chat: PropTypes.object,
};

export default ChatMemberCell;
