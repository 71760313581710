import { generateVideoThumbnails } from "@rajesh896/video-thumbnails-generator";
import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";

import { dataURLtoFile } from "./ConvertURLtoFile.js";
import authService from "../../../Services/auth.service";
const options = {
  maxSizeMB: 2, // Set the maximum size in megabytes
  // maxWidthOrHeight: 1920, // Set the maximum width or height
  useWebWorker: true, // Enable or disable web worker for better performance
};

const calculateAspectRatio = (file, type) => {
  if (type === "image") {
    // For images
    return new Promise((resolve) => {
      const image = new Image();
      image.src = URL?.createObjectURL(file);

      image.onload = () => {
        const aspectRatio = image.naturalWidth / image.naturalHeight;
        resolve(aspectRatio);
      };
    });
  } else if (type === "video") {
    // For videos
    return new Promise(async (resolve) => {
      try {
        const response = await fetch(URL.createObjectURL(file.videoFile));
        const blob = await response.blob();

        const video = document.createElement("video");
        video.src = URL.createObjectURL(blob);

        video.addEventListener("loadedmetadata", () => {
          resolve(video.videoWidth / video.videoHeight);
        });
      } catch (error) {
        console.error("Error fetching video file:", error);
        resolve(null);
      }
    });
  }

  return null;
};

export const compressImageFun = async (images) => {
  const compressedFileArray = [];

  await Promise.all(
    images.map(async (image, index) => {
      try {
        const compressedBlob = await imageCompression(image, options);
        const extension = image?.type.split("/")[1];
        // Create a File object with a new filename
        const filename = `compressed_image_${index}.${extension}`; // specify the desired filename
        const compressedFile = new File([compressedBlob], filename, {
          type: compressedBlob.type,
        });

        compressedFileArray[index] = compressedFile;
      } catch (error) {
        console.error("Error compressing image:", error);
        compressedFileArray[index] = null; // Handle errors gracefully, you can adjust this as needed
      }
    })
  );

  return compressedFileArray;
};

export const thumbnailCreateForVideo = async (videos) => {
  const thumbnailWithVideoArray = await Promise.all(
    videos.map(async (video) => {
      try {
        const generateThumbnailFile = await generateVideoThumbnails(video, 1);
        const filedata = dataURLtoFile(
          generateThumbnailFile[0],
          Math.random().toString() + video.name.split(".")[0] + ".png"
        );
        return { thumnailFile: filedata, videoFile: video };
      } catch (error) {
        console.error("Error compressing image:", error);
        return null; // Handle errors gracefully, you can adjust this as needed
      }
    })
  );
  return thumbnailWithVideoArray;
};

export const uploadmultiplefiles = async (allfiles, type) => {
  const response = await Promise.all(
    allfiles.map(async (file) => {
      let formdata, res, aspectRatio;

      switch (type) {
        case "images":
          formdata = new FormData();
          formdata?.append("media", file);
          res = await authService.ChatUploadFiles(formdata);
          aspectRatio = await calculateAspectRatio(file, "image");

          if (res?.data?.status) {
            return {
              id: uuidv4(),
              url: res.data?.media[0].key,
              aspectRatio: parseFloat(aspectRatio?.toFixed(1)),
              type: "images",
            };
          }
          break;
        case "videos":
          let formdata_thumbnail = new FormData();
          formdata_thumbnail?.append(`media`, file?.thumnailFile);
          res = await authService?.ChatUploadFiles(formdata_thumbnail);
          let thumbnail = res?.data?.media[0]?.key;
          formdata = new FormData();
          formdata.append("media", file?.videoFile);
          let responseOfVideoupload = await authService?.ChatUploadFiles(
            formdata
          );
          aspectRatio = await calculateAspectRatio(file, "video");
          if (responseOfVideoupload?.data?.status) {
            return {
              id: uuidv4(),
              url: responseOfVideoupload?.data?.media[0]?.key,
              aspectRatio: parseFloat(aspectRatio?.toFixed(1)),
              thumbnail: thumbnail,
              type: "videos",
            };
          }
          break;
        case "documents":
          formdata = new FormData();
          formdata?.append("otherfiles", file);
          let responseOfDocupload = await authService?.ChatUploadFiles(
            formdata
          );
          if (responseOfDocupload?.data?.status) {
            return {
              id: uuidv4(),
              key: responseOfDocupload?.data?.otherfiles[0]?.key,
              size: responseOfDocupload?.data?.otherfiles[0]?.size,
              type: "documents",
            };
          }
          break;
        case "voiceNotes":
          formdata = new FormData();
          formdata?.append(`voiceNotes`, file, "audio.wav");
          res = await authService.ChatUploadFiles(formdata);
          return {
            url: res?.data?.voiceNotes[0]?.key,
            type: "voice",
          };
        default:
          break;
      }
    })
  );

  return response;
};

export const sendInProgressMediaMessages = async (
  message,
  toChangeCompresstoProgress
) => {
  let compressedImages = [],
    thumbnailAndVideo = [],
    uploadedImages = [],
    uploadedVideos = [],
    uploadedDocs = [],
    uploadedVoiceNotes = [];
  if (message?.images?.length > 0) {
    compressedImages = await compressImageFun(message.images);
  }
  if (message?.videos?.length) {
    thumbnailAndVideo = await thumbnailCreateForVideo(message?.videos);
  }

  toChangeCompresstoProgress(message?.recipient, message?.frontendUniqueId);

  if (compressedImages.length) {
    uploadedImages = await uploadmultiplefiles(compressedImages, "images");
  }
  if (thumbnailAndVideo?.length) {
    uploadedVideos = await uploadmultiplefiles(thumbnailAndVideo, "videos");
  }
  if (message?.documents) {
    uploadedDocs = await uploadmultiplefiles(message.documents, "documents");
  }
  if (message?.voiceNotes) {
    uploadedVoiceNotes = await uploadmultiplefiles(
      message?.voiceNotes,
      "voiceNotes"
    );
  }

  return {
    uploadedImages,
    uploadedVideos,
    uploadedDocs,
    uploadedVoiceNotes,
  };
};
