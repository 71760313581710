import React, { useState } from "react";
import { InView } from "react-intersection-observer";
import VideoMediumCard from "../../../CardsVideo/VideoMediumCard";
import videodummy from "../../../../Images/nothumbnail.png";
import millify from "millify";
import moment from "moment";
import { useSaveVideoMutation } from "../../../../Services/videos";
import SaveIcon from "../../../Icons/GeneralIcons/SaveIcon";
import { useSelector } from "react-redux";
import { fetchsavedvideos } from "../../../../Action";
import { useDispatch } from "react-redux";
import ToastNotification from "../../../ToastNotification/ToastNotification";
import VideoComingSoon from "../../../GlobalComponents/Icons/IconEmptyState/VideoComingSoon";
import IconSearchNoResults from "../../../GlobalComponents/Icons/IconEmptyState/IconSearchNoResults";

const LibraryListing = ({
  displayVideos = [],
  pageLoader,
  totalVideos,
  setPage,
  page,
  noData,
  renderEmptyState,
  setListingCount,
  selectedCategory,
}) => {
  const [saveVideo] = useSaveVideoMutation();
  const [activeId, setActiveId] = useState(null);
  const [dropPos, setDropPos] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [alreadySaved, setAlreadySaved] = useState(false);
  const dispatch = useDispatch();
  const savedvideos = useSelector((state) => {
    return state.ReduSavedVideos;
  });

  const actions = (videoId) => [
    {
      id: "save",
      name: `${
        savedvideos.map((video) => video._id).includes(videoId)
          ? "Remove from saved"
          : "Save"
      }`,
      icon: (
        <SaveIcon
          isFilled={savedvideos.map((video) => video._id).includes(videoId)}
        />
      ),
      callbackFunction: async () => {
        setActiveId(null);
        const saveVideoRes = await saveVideo({ video_id: activeId });
        if (saveVideoRes.data) {
          if (savedvideos.map((video) => video._id).includes(videoId)) {
            setAlreadySaved(true);
            setListingCount((prev) => ({ ...prev, saved: prev.saved - 1 }));
          } else {
            setListingCount((prev) => ({ ...prev, saved: prev.saved + 1 }));
          }
          setSaved(true);
          dispatch(fetchsavedvideos());
          setTimeout(() => {
            setSaved(false);
            setAlreadySaved(false);
          }, 2000);
        }
      },
    },
  ];
  const handleActionClick = (event, id) => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const buttonRect = event.target.getBoundingClientRect();
    const buttonBottomRelativeToViewport = buttonRect.bottom + scrollTop;

    const windowHeight = window.innerHeight;
    const isButtonAtBottom =
      buttonBottomRelativeToViewport >= scrollTop + windowHeight - 200; // Adjust 10 as needed for tolerance
    if (isButtonAtBottom) {
      setDropPos(true);
    } else {
      setDropPos(false);
    }
    setActiveId(activeId === id ? null : id);
  };

  function handleSelect(selectedItem) {
    selectedItem.callbackFunction();
  }

  const handleMouseLeave = () => {
    setActiveId(null);
  };
  return (
    <div className="video-library-grid">
      {pageLoader && page === 1
        ? [...Array(20)].map((ele) => (
            <div className="cstm-cl-mv-ldr-main">
              <div className="cstm-cl-mv-ldr-video skeleton-box"></div>
              <div className="cstm-cl-mv-ldr-title skeleton-box"></div>
              <div className="cstm-cl-mv-ldr-time skeleton-box"></div>
              <div className="cstm-cl-mv-ldr-view skeleton-box"></div>
            </div>
          ))
        : displayVideos.length > 0 &&
          displayVideos.map((convideo, index) => {
            if (
              index === displayVideos.length - 1 &&
              index !== totalVideos - 1
            ) {
              return (
                <InView
                  onChange={(inView) => {
                    if (inView) setPage((prev) => prev + 1);
                  }}
                >
                  <>
                    <VideoMediumCard
                      id={convideo._id}
                      handleActionClick={handleActionClick}
                      convideo={convideo}
                      pageUrl={`/videos/${convideo._id}`}
                      thumbnail={
                        convideo.thumbnail !== undefined &&
                        convideo.thumbnail !== null &&
                        convideo.thumbnail.length > 0
                          ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                            convideo.thumbnail
                          : videodummy
                      }
                      duration={
                        !convideo.duration || convideo.duration.includes("NaN")
                          ? "0:00"
                          : convideo.duration
                      }
                      name={convideo.title}
                      category={
                        selectedCategory &&
                        convideo?.categories.some(
                          (ele) => ele?._id === selectedCategory._id
                        )
                          ? selectedCategory?.name
                          : convideo?.categories[0].name
                      }
                      views={
                        convideo.viewsCount !== undefined &&
                        convideo.viewsCount > 0
                          ? convideo.viewsCount === 1
                            ? `${millify(convideo.viewsCount)} View`
                            : `${millify(convideo.viewsCount)} Views`
                          : "0 Views"
                      }
                      uploaded={moment(convideo.createdAt).fromNow()}
                      actions={actions(convideo._id)}
                      activeId={activeId}
                      dropPos={dropPos}
                      handleSelect={handleSelect}
                      handleMouseLeave={handleMouseLeave}
                      badgeText="NEW"
                      hasBadge={
                        moment().diff(moment(convideo.createdAt), "days") <= 7
                      }
                    />
                  </>
                </InView>
              );
            } else {
              return (
                <>
                  <VideoMediumCard
                    id={convideo._id}
                    handleActionClick={handleActionClick}
                    convideo={convideo}
                    pageUrl={`/videos/${convideo._id}`}
                    thumbnail={
                      convideo.thumbnail !== undefined &&
                      convideo.thumbnail !== null &&
                      convideo.thumbnail.length > 0
                        ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                          convideo.thumbnail
                        : videodummy
                    }
                    duration={
                      !convideo.duration || convideo.duration.includes("NaN")
                        ? "0:00"
                        : convideo.duration
                    }
                    name={convideo.title}
                    category={
                      selectedCategory &&
                      convideo?.categories.some(
                        (ele) => ele?._id === selectedCategory._id
                      )
                        ? selectedCategory?.name
                        : convideo?.categories[0].name
                    }
                    views={
                      convideo.viewsCount !== undefined &&
                      convideo.viewsCount > 0
                        ? convideo.viewsCount === 1
                          ? `${millify(convideo.viewsCount)} View`
                          : `${millify(convideo.viewsCount)} Views`
                        : "0 Views"
                    }
                    uploaded={moment(convideo.createdAt).fromNow()}
                    actions={actions(convideo._id)}
                    activeId={activeId}
                    dropPos={dropPos}
                    handleSelect={handleSelect}
                    handleMouseLeave={handleMouseLeave}
                    badgeText="NEW"
                    hasBadge={
                      moment().diff(moment(convideo.createdAt), "days") <= 7
                    }
                  />
                </>
              );
            }
          })}
      {pageLoader &&
        displayVideos.length > 0 &&
        [...Array(20)].map((el, index) => (
          <div className="video-list-main">
            <div className="video-list-thumb skeleton-box"></div>
            <div className="video-list-heading skeleton-box"></div>
            <div className="video-list-datetime skeleton-box"></div>
            <div className="video-list-view-cmt">
              <div className="video-list-view skeleton-box"></div>
              <div className="video-list-cmt skeleton-box"></div>
            </div>
          </div>
        ))}
      {!pageLoader &&
        displayVideos.length === 0 &&
        noData &&
        renderEmptyState(
          displayVideos,
          "all",
          <VideoComingSoon />,
          <IconSearchNoResults />,
          "Coming soon!",
          "Oops! No results this time!",
          "Our video library is getting a refresh! Stay tuned for new content.",
          "Please adjust your searching filters and give it another go!",
          "Clear all filters"
        )}
      {isSaved && (
        <div className="toast-wrapper bottom left" style={{ width: "400px" }}>
          <ToastNotification
            variant="neutral"
            size="medium"
            hasIcon={false}
            text={alreadySaved ? "Removed from Saved" : "Video saved"}
            hasAction={!alreadySaved}
            hasCross={false}
            actionText="View list"
            actionUrl="/videos?type=saved&filter=recent"
          />
        </div>
      )}
    </div>
  );
};

export default LibraryListing;
