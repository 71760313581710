import React from "react";
import PropTypes from "prop-types";
import StatusBadge from "../Badge/StatusBadge";
import MdsLogoSmall from "../Logos/MdsLogoSmall"
import MealIcon from "../Icons/EventIcons/MealIcon";

// Style
import "../../Styles/Theme/Theme.css";
import "./Avtar.css";

const Avtar = ({
  type = "photo",
  size = "32",
  src = "",
  isRounded = true,

  // Badge
  hasStatus = false,
  badgeStatus = "online",

  // Letter
  letter = "W",

  // Logo (If avtar type is logo)
  logoSrc = "https://logosandtypes.com/wp-content/uploads/2023/03/ClickUp.png",

  // Icons
  iconColor = "var(--color-icon-inverse-screen)",
  icon = <MealIcon width={24} height={24} iconColor={iconColor} />,
}) => {

  return (
    <div
      className={`avtar-container relative ${isRounded ? "rounded" : ""
        } avtar-${size}`}
    >
      <div className={`avtar-wrapper avtar-${type}`}>
      
        {type === "photo" ? (
          <img src={src} className="" />
        ) : type === "icon" ? (
          icon
        ) : type === "letter" ? (
          letter.charAt(0)
        ) : type === "logo" ? (
          <img src={logoSrc} className="" />
        ) : type === "mds" ? (
          <MdsLogoSmall />
        ) : (
          <></>
        )}
      </div>
      {hasStatus ? (
        <div className="avtar-badge">
          <StatusBadge
            size={
              size === "60"
                ? "large"
                : size === "48" || size === "40"
                  ? "medium"
                  : size === "32" || size === "24"
                    ? "small"
                    : "small"
            }
            status={size === "16" ? false : badgeStatus}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

Avtar.propTypes = {
  type: PropTypes.oneOf(["photo", "icon", "letter", "logo", "mds"]),
  size: PropTypes.oneOf(["60", "48", "40", "32", "24", "16"]),
  src: PropTypes.string,
  isRounded: PropTypes.bool,
  // Badge
  hasStatus: PropTypes.bool,
  badgeStatus: PropTypes.oneOf(["offline", "online"]),
  // Letter
  letter: PropTypes.string,
  //  Avtar type Logo
  logoSrc: PropTypes.string,
  //   Icon
  iconColor: PropTypes.string,
  icon: PropTypes.element,
};

export default Avtar;
