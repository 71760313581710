import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import EmojiPicker from "emoji-picker-react";
import Dropzone from "react-dropzone";
// Icons
import AttachmentIcon from "../../../../components/Icons/GeneralIcons/AttachmentIcon";
import MentionIcon from "../../../../components/Icons/GeneralIcons/MentionIcon";
import EmojiIcon from "../../../../components/Icons/GeneralIcons/EmojiIcon";
import MicrophoneIcon from "../../../../components/Icons/GeneralIcons/MicrophoneIcon";
import SendIcon from "../../../../components/Icons/GeneralIcons/SendIcon";
import Button from "../../../../components/Button/Button";

// CSS
import "../../../../Styles/Theme/Theme.css";
import "../Styles/MessageBarActions.css";

const MessageBarActions = ({
  hasEmojiDropdown = false,
  setHasEmojiDropdown,
  hasMentionDropdown = false,
  setHasMentionDropdown,
  isFilled = false,
  AttachmentClickHandle,
  MentionClickHandle = () => {},
  EmojiIconClickHandle,
  handleOnClickVoiceMessage,
  handleMentionDropDownPosition = () => {},
  handleOnClickSend = () => {},
  handleSelectEmoji,
}) => {
  const emojiDropdownRef = useRef(null);
  const mentionDropdownRef = useRef(null);
  const [showMicroPhone, setMicroPhoneDisable] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event, ref, setter) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setter(false);
      }
    };

    const handleEmojiClickOutside = (event) => {
      handleClickOutside(event, emojiDropdownRef, setHasEmojiDropdown);
    };

    const handleMentionClickOutside = (event) => {
      handleClickOutside(event, mentionDropdownRef, setHasMentionDropdown);
    };

    document.addEventListener("click", handleEmojiClickOutside);
    document.addEventListener("click", handleMentionClickOutside);

    return () => {
      document.removeEventListener("click", handleEmojiClickOutside);
      document.removeEventListener("click", handleMentionClickOutside);
    };
  }, [setHasEmojiDropdown, setHasMentionDropdown]);

  const handleSendClick = () => {
    setMicroPhoneDisable(true);
    handleOnClickSend();
    setTimeout(() => {
      setMicroPhoneDisable(false);
    }, 2000);
  };

  return (
    <div className="messagebar-section flex items-center justify-between">
      <div className="flex items-center" ref={mentionDropdownRef}>
        <Dropzone
          onDrop={(acceptedFiles, fileRejected) => {
            AttachmentClickHandle(acceptedFiles, fileRejected);
          }}
          multiple={true}
          accept="image/png, image/gif, image/jpeg, image/jpg, video/mp4, video/mov, video/gif, video/webm, image/heic, video/avi, application/vnd.ms-excel, application/pdf, application/msword, text/csv, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: "dropzone messages-send-icon-main",
              })}
            >
              <input {...getInputProps()} />
              <Button
                elementIcon={<AttachmentIcon />}
                handleClick={() => {}}
                onContextMenu={() => {}}
                onMouseEnter={() => {}}
                onMouseLeave={() => {}}
                variant="ghost"
                size="extrasmall"
                hasText={false}
                label=""
                toolTipText="Attachments"
              />
            </div>
          )}
        </Dropzone>

        <Button
          elementIcon={<MentionIcon />}
          handleClick={(element) => {
            MentionClickHandle(element);
            handleMentionDropDownPosition(element);
          }}
          onContextMenu={() => {}}
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          variant="ghost"
          size="extrasmall"
          hasText={false}
          label=""
          toolTipText="Mention"
        />

        <div className="relative" ref={emojiDropdownRef}>
          <Button
            elementIcon={<EmojiIcon />}
            handleClick={EmojiIconClickHandle}
            onContextMenu={() => {}}
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
            variant="ghost"
            size="extrasmall"
            hasText={false}
            label=""
            toolTipText="Emoji"
          />
          <div className="chat-emojies-sec">
            {hasEmojiDropdown && (
              <EmojiPicker
                emojiStyle="native"
                onEmojiClick={handleSelectEmoji}
                // onMouseLeave={handleMouseLeave}
              />
            )}
          </div>
        </div>
      </div>

      <div>
        {isFilled ? (
          <div className="send-btn">
            <Button
              elementIcon={<SendIcon />}
              handleClick={handleSendClick}
              onContextMenu={() => {}}
              onMouseEnter={() => {}}
              onMouseLeave={() => {}}
              variant="ghost"
              size="extrasmall"
              hasText={false}
              label=""
              toolTipText="Send"
            />
          </div>
        ) : (
          <Button
            elementIcon={<MicrophoneIcon />}
            handleClick={handleOnClickVoiceMessage}
            onContextMenu={() => {}}
            onMouseEnter={() => {}}
            onMouseLeave={() => {}}
            variant="ghost"
            size="extrasmall"
            hasText={false}
            label=""
            toolTipText="Start recording"
            disabled={showMicroPhone ? true : false}
          />
        )}
      </div>
    </div>
  );
};

MessageBarActions.propTypes = {
  hasEmojiDropdown: PropTypes.bool,
  setHasEmojiDropdown: PropTypes.func.isRequired,
  hasMentionDropdown: PropTypes.bool,
  setHasMentionDropdown: PropTypes.func.isRequired,
  isFilled: PropTypes.bool,
  AttachmentClickHandle: PropTypes.func,
  MentionClickHandle: PropTypes.func,
  EmojiIconClickHandle: PropTypes.func,
  handleOnClickSend: PropTypes.func,
  handleOnClickVoiceMessage: PropTypes.func,
  handleMentionDropDownPosition: PropTypes.func,
  handleSelectEmoji: PropTypes.func,
};

export default MessageBarActions;
