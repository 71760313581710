import React from 'react'
import PropTypes from "prop-types"
const ChannelIcon = ({ width = 24, height = 24, isFilled, iconColor = "#000" }) => {
    return (
        <>
            {isFilled ? <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M17.162 2.148C17.994 1.708 19 2.306 19 3.24v15.206c0 .935-1.006 1.534-1.838 1.093l-6.1-3.49h-.812v3.719A2.24 2.24 0 0 1 8 22a2.24 2.24 0 0 1-2.25-2.231v-3.934C3.581 15.194 2 13.201 2 10.844c0-2.875 2.35-5.206 5.25-5.206h3.813l6.099-3.49ZM8 20.512a.747.747 0 0 1-.75-.743V16.05h1.5v3.719c0 .41-.336.744-.75.744Z"
                    clipRule="evenodd"
                />
                <path
                    fill={iconColor}
                    d="M21.25 7.125c.414 0 .75.333.75.744v5.95c0 .41-.336.744-.75.744a.747.747 0 0 1-.75-.744v-5.95c0-.41.336-.744.75-.744ZM7.25 19.769c0 .41.336.744.75.744s.75-.334.75-.744V16.05h-1.5v3.719Z"
                />
            </svg> : <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill={iconColor}
                    fillRule="evenodd"
                    d="M19 3.241c0-.935-1.006-1.534-1.838-1.093l-6.1 3.49H7.25c-2.9 0-5.25 2.33-5.25 5.206 0 2.358 1.581 4.35 3.75 4.99v3.935A2.24 2.24 0 0 0 8 22a2.24 2.24 0 0 0 2.25-2.231V16.05h.813l6.099 3.49c.832.44 1.838-.158 1.838-1.093V3.24ZM7.25 7.125c-2.071 0-3.75 1.665-3.75 3.719s1.679 3.719 3.75 3.719h3.25V7.125H7.25ZM12 14.86l5.5 3.173V3.654L12 6.828v8.032ZM7.25 19.77c0 .41.336.744.75.744s.75-.334.75-.744V16.05h-1.5v3.719ZM21.25 7.125c.414 0 .75.333.75.744v5.95c0 .41-.336.744-.75.744a.747.747 0 0 1-.75-.744v-5.95c0-.41.336-.744.75-.744Z"
                    clipRule="evenodd"
                />
            </svg>}
        </>
    )
}
ChannelIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    isFilled: PropTypes.bool,
    iconColor: PropTypes.string
}
export default ChannelIcon