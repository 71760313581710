import React from "react";
import PropTypes from "prop-types";
import EventThumbImage from "./EventThumbImage";
import "./Styles/EventSummaryTicket.css";
import "../../../../Styles/frontendGlobal.css";
import ThumbImg from "../../../../Images/thumb-img.png";

const EventSummaryTicket = ({
  title = "Title Text",
  amount = "$10,000",
  ThumbSrc = ThumbImg,
  hasRemove = false,
  hasClickFun = () => {},
}) => {
  return (
    <>
      <div className="event-summary-ticket-item">
        <EventThumbImage
          ThumbSrc={ThumbSrc.length > 0 ? ThumbSrc : ThumbImg}
          imgType="x-small"
          extraClass={"event-summary-ticket-image"}
        />
        <div className="event-summary-ticket-text-wrapper">
          <div className="event-summary-ticket-title-wrapper flex justify-content-between">
            <p className="event-summary-ticket-title text-ellipsis">{title}</p>
            {hasRemove && (
              <a
                href="javascript:void(0)"
                className="remove-btn"
                onClick={hasClickFun}
              >
                Remove
              </a>
            )}
          </div>
          <p className="event-summary-ticket-amount">${amount}</p>
        </div>
      </div>
    </>
  );
};
EventSummaryTicket.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.string,
  ThumbSrc: PropTypes.string,
  hasRemove: PropTypes.bool,
};

export default EventSummaryTicket;
