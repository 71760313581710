import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, IconButton, ListItemIcon } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

const ActionButtons = ({ menuSelectFun = () => {}, options, row = {} }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "transparent",
            },
            "& .custom-backdrop": {
              boxShadow: "0px 0px 9px 1px #00000030",
            },
          }}
          classes={{
            paper: "custom-backdrop",
          }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {options.map((ele) => {
            return (
              <MenuItem
                onClick={() => {
                  handleClose();
                  menuSelectFun(ele, row);
                }}
              >
                <ListItemIcon>{ele.icon}</ListItemIcon>
                {ele.name}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    </>
  );
};

export default ActionButtons;
