import React from "react";
import PropTypes from "prop-types"
function ImageIcon({ height = 24, width = 24}) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#14AE5C"
                    fillRule="evenodd"
                    d="M5.865 1h8.902L21 7.502v12.633A2.866 2.866 0 0 1 18.135 23H5.865A2.866 2.866 0 0 1 3 20.135V3.865A2.866 2.866 0 0 1 5.865 1Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M15.152 12H8.859c-.472 0-.859.407-.859.901V17.1c0 .494.387.901.859.901h6.293c.471 0 .848-.407.848-.901V12.9c0-.494-.377-.901-.848-.901Zm-4.576 1.132c.513 0 .921.44.921.967 0 .538-.408.978-.921.978-.513 0-.932-.44-.932-.978 0-.528.419-.967.932-.967Zm4.859 3.967c0 .165-.126.308-.283.308H8.859c-.157 0-.283-.143-.283-.308v-.176l1.141-1.198.943.99c.115.12.293.12.408 0l2.367-2.484 2 2.099v.769Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
            </svg>
        </>
    );
}
ImageIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
export default ImageIcon;
