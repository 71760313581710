import React from "react";
import PropTypes from "prop-types"
function NotDefinedFileIcon({ height = 24, width = 24 }) {
    return (
        <>
            <svg
                xmlns="https://www.w3.org/2000/svg"
                width={width}
                height={height}
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    fill="#1C68E0"
                    fillRule="evenodd"
                    d="M5.86 1h8.904L21 7.5v12.64A2.866 2.866 0 0 1 18.14 23H5.86A2.862 2.862 0 0 1 3 20.14V3.86A2.857 2.857 0 0 1 5.86 1Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#fff"
                    d="M12.673 15.499h-1.347v-.676a1.464 1.464 0 0 1 .114-.657c.133-.211.3-.402.495-.568l.863-.799a.933.933 0 0 0 .28-.693.895.895 0 0 0-.286-.68 1.035 1.035 0 0 0-.74-.27 1.131 1.131 0 0 0-.758.26 1.016 1.016 0 0 0-.352.692H9.5a2.208 2.208 0 0 1 .829-1.54 2.78 2.78 0 0 1 1.772-.566 2.684 2.684 0 0 1 1.727.535c.456.38.702.93.669 1.497.015.4-.124.792-.393 1.105-.108.13-.223.256-.345.376-.08.073-.184.167-.315.281-.131.115-.243.214-.335.298a3.163 3.163 0 0 0-.216.216 1 1 0 0 0-.216.685l-.004.504Zm.263 1.634c0 .23-.098.45-.274.613A.976.976 0 0 1 12 18a.976.976 0 0 1-.663-.254.836.836 0 0 1-.274-.613c0-.23.098-.45.274-.613a.976.976 0 0 1 .663-.254c.248 0 .486.091.662.254a.836.836 0 0 1 .274.613Z"
                />
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M14.76 1v6.5H21L14.76 1Z"
                    clipRule="evenodd"
                    opacity={0.3}
                />
            </svg>
        </>
    );
}
NotDefinedFileIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
}
export default NotDefinedFileIcon;
