import { useEffect, useRef, useState } from "react";
import Service from "../../../Services/auth.service";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ArrowLeftLineIcon from "remixicon-react/ArrowLeftLineIcon";
import AlertComponent from "../../../Hooks/AlertComponent";
import Multiselect from "multiselect-react-dropdown";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
const CreatePlan = () => {

    const descriptionRef = useRef(null)
    const [inProcess, setInprocess] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const modalInProcess = useSelector((state) => { return state.ReduModalInProcess })
    const NAVIGATEPAGE = useSelector((state) => { return state.partnerNavigate })
    const stayOnPage = useSelector((state) => { return state.ReduStayOnPage })
    const [allResource, setAllResource] = useState([])
    const [planDetail, setPlanDetail] = useState({
        plan_name: "",
        plan_price: 0,
        plan_description: "",
        recurring_timeframe: "",
        plan_status: "",
        automatic_renewal: true,
        auth0_plan_id: "",
        isTeamMate: false,
        planResource: [],
        noOfTeamMate: "",
        group_ids: [],
        group_access: "",
        event_on_offline: "",
    });
    const [err, setErr] = useState({
        plan_name: "",
        plan_price: "",
        recurring_timeframe: "",
        plan_status: "",
        automatic_renewal: "",
        group_ids: "",
        planResource: "",
        noOfTeamMate: "",
    });
    const [showalert, setshowalert] = useState(false);
    const [alertmsg, setalertmsg] = useState("");
    const [allGroup, setAllGroup] = useState([]);
    useEffect(() => {
        Service.getAllPlanResource()
            .then((allResourceRes) => {
                if (allResourceRes.data.status) {
                    setAllResource(allResourceRes.data.data)
                }
            })
        Service.getallgroup()
            .then((res) => {
                if (res.data.status) {
                    setAllGroup(res.data.data);
                }
            })
            .catch((e) => {
                console.log(e);
            });
        handleClearAllStates()
    }, []);

    // handle change function for input fields
    const handleChange = (e) => {
        dispatch({ type: "SETMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        const { name, value } = e.target
        setPlanDetail({ ...planDetail, [name]: value });
        switch (name) {
            case "plan_name":
                value.trim() === "" ? setErr({ ...err, plan_name: "Enter plan name" }) : setErr({ ...err, plan_name: "" })
                break;
            case "plan_price":
                value.trim() === "" ? setErr({ ...err, plan_price: "Enter plan price" }) : setErr({ ...err, plan_price: "" })
                break;
            case "recurring_timeframe":
                value === "" ? setErr({ ...err, recurring_timeframe: "Enter recurring timeframe" }) : setErr({ ...err, recurring_timeframe: "" })
                break;
            case "plan_status":
                value === "" ? setErr({ ...err, plan_status: "Select plan status" }) : setErr({ ...err, plan_status: "" })
                break;
            case "noOfTeamMate":
                value === "" ? setErr({ ...err, noOfTeamMate: "Enter number of teammates" }) : setErr({ ...err, noOfTeamMate: "" })
                break;
            default:
                break;
        }
    };

    // validate function for validation of reuired field
    const validate = () => {
        let isValid = false
        let planError = {}
        if (planDetail.plan_name.trim() === "") {
            isValid = true
            planError.plan_name = "Enter plan name"
        }
        if (planDetail.group_ids.length === 0) {
            isValid = true
            planError.group_ids = "Select group"
        }
        if (planDetail.plan_price === "") {
            isValid = true
            planError.plan_price = "Enter plan price"
        }
        if (planDetail.plan_price > 0 && planDetail.recurring_timeframe.trim() === "") {
            isValid = true
            planError.recurring_timeframe = "Enter recurring timeframe"
        }
        if (planDetail.plan_status.trim() === "") {
            isValid = true
            planError.plan_status = "Select plan status"
        }
        if (planDetail.isTeamMate) {
            if (planDetail.planResource.length === 0) {
                isValid = true
                planError.planResource = "Select plan resources"
            }
            if (planDetail.noOfTeamMate.trim() === "") {
                isValid = true
                planError.noOfTeamMate = "Enter number of teammates"
            }
        }
        if (Object.keys(planError).length > 0) {
            const emptyKeys = Object.keys(planError).filter((key) => planError[key] !== "");
            const inputElement = document.getElementsByName(emptyKeys[0])[0];
            if (inputElement) {
                inputElement.focus(); // Focus the input element
            }
        }
        setErr(planError)
        return isValid
    }

    // handle create
    const handleCreate = () => {
        if (!validate(planDetail)) {
            setInprocess(true);
            const selectedResources = planDetail.planResource.length > 0 ? planDetail.planResource.map((plan) => (
                plan._id
            )) : []
            const description = descriptionRef.current !== null ? descriptionRef.current.getContent() : ""
            var data = {
                plan_name: planDetail.plan_name,
                plan_price: planDetail.plan_price,
                plan_description: description,
                recurring_timeframe: planDetail.recurring_timeframe,
                auth0_plan_id: planDetail.auth0_plan_id,
                plan_status: planDetail.plan_status,
                automatic_renewal: planDetail.automatic_renewal,
                isTeamMate: planDetail.isTeamMate,
                no_of_team_mate: planDetail.noOfTeamMate,
                accessResources: selectedResources,
                apple_plan_id: "",
                play_store_plan_id: "",
                group_ids: planDetail.group_ids,
                show_all_for_group:
                    planDetail.group_access === "show_all_for_group" ? true : false,
                show_only_with_access_for_group:
                    planDetail.group_access === "show_only_with_access_for_group"
                        ? true
                        : false,
                event_offline:
                    planDetail.event_on_offline === "event_offline" ? true : false,
                event_online:
                    planDetail.event_on_offline === "event_online" ? true : false,
            };
            Service.createmembershipplan(data)
                .then((res) => {
                    if (res.data.status) {
                        setInprocess(false);
                        setshowalert(true);
                        setalertmsg(res.data.message);
                        setTimeout(() => {
                            setshowalert(false);
                            setalertmsg("");
                            navigate('/membership/list')
                        }, 3000);
                    } else {
                        setInprocess(false);
                        setshowalert(true);
                        setalertmsg(res.data.message);
                        setTimeout(() => {
                            setshowalert(false);
                            setalertmsg("");
                        }, 3000);
                    }
                    handleClearAllStates()
                })
                .catch((e) => {
                    console.log(e);
                    setInprocess(false);
                    handleClearAllStates()
                });
        }
    };

    //pop up state for do you want to leave....
    useEffect(() => {
        if (stayOnPage !== null && !stayOnPage) {
            setPlanDetail({
                plan_name: "",
                plan_price: 0,
                plan_description: "",
                recurring_timeframe: "",
                plan_status: "",
                automatic_renewal: true,
                group_ids: [],
                group_access: "",
                event_on_offline: "",
            });
        }
        else if (stayOnPage !== null && stayOnPage) {
        }
    }, [stayOnPage])

    //clear all global states
    const handleClearAllStates = () => {
        dispatch({ type: "REMOVEMODALINPROCESS" })
        dispatch({ type: "SETSTAYONPAGE", payload: null })
        dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" })
    }
    return (
        <>
            <div className="users-info-content">
                <div className="users-info-content-main">
                    <div className="um-back">
                        <button
                            type="button"
                            className="back-btn"
                            onClick={() => {
                                if (modalInProcess) {
                                    dispatch({ type: "SETCUSTOMPAGECHANGE" })
                                    dispatch({ type: "NAVIGATEPAGE", payload: "/membership/list" })
                                } else {
                                    navigate("/membership/list");
                                }
                            }}
                        >
                            <ArrowLeftLineIcon size="20px" style={{ marginRight: "4px" }} />
                            Back
                        </button>
                    </div>

                    <div className="uic-title uic-title-crt-plan">
                        <h1>Add Membership Plan</h1>
                    </div>
                    {showalert && <div className="alert alert-info">{alertmsg}</div>}
                    <div className="row">
                        <div className="col-sm-6 form-group">
                            <div className="form-group-label">Plan name*</div>
                            <div className="form-group-ct">
                                <input
                                    type="text"
                                    name="plan_name"
                                    value={planDetail.plan_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <span className="error cstm-error1">{err.plan_name}</span>
                        </div>
                        <div className="col-sm-6 form-group">
                            <div className="form-group-label">Auth0 Plan Id for Migrated user</div>
                            <div className="form-group-ct">
                                <input
                                    type="text"
                                    name="auth0_plan_id"
                                    value={planDetail.auth0_plan_id}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6 form-group">
                            <div className="form-group-label">Plan price*</div>
                            <div className="form-group-ct cstm-plan-price-only">
                                <input
                                    type="number"
                                    name="plan_price"
                                    value={planDetail.plan_price}
                                    onChange={handleChange}
                                    min="0"
                                />
                            </div>
                            <span className="error cstm-error1">{err.plan_price}</span>
                        </div>
                        {planDetail.plan_price > 0 &&
                            <div className="col-sm-6 form-group">
                                <div className="form-group-label">Recurring timeframe*</div>
                                <div className="form-group-ct">
                                    <select
                                        name="recurring_timeframe"
                                        value={planDetail.recurring_timeframe}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Duration</option>
                                        <option value="year">Annually</option>
                                        <option value="month">Monthly</option>
                                        <option value="day">daily</option>
                                    </select>
                                </div>
                                <span className="error cstm-error1">
                                    {err.recurring_timeframe}
                                </span>
                            </div>}
                    </div>

                    <div className="row">
                        <div className="col-sm-6 form-group">
                            <div className="form-group-label">Plan status*</div>
                            <div className="form-group-ct">
                                <select
                                    name="plan_status"
                                    value={planDetail.plan_status}
                                    onChange={handleChange}
                                >
                                    <option value="">Select status</option>
                                    <option value='Active'>Active</option>
                                    <option value='Deactive'>Deactive</option>
                                </select>
                            </div>
                            <span className="error cstm-error1">{err.plan_status}</span>
                        </div>
                        {planDetail.plan_price > 0 && <div className="col-sm-6 form-group">
                            <span className="automatic-renewal-title">Automatic renewal</span>
                            <div className="automatic-renewalbox">
                                <label className="auto-rene-label">
                                    <input
                                        type="radio"
                                        name="automatic_renewal"
                                        value={true}
                                        onChange={handleChange}
                                        defaultChecked
                                    />
                                    <span className="auto-rene-lbl">Yes</span>
                                </label>
                                <label className="auto-rene-label">
                                    <input
                                        type="radio"
                                        name="automatic_renewal"
                                        value={false}
                                        onChange={handleChange}
                                    />
                                    <span className="auto-rene-lbl">No</span>
                                </label>
                            </div>
                            <span className="error cstm-error1">
                                {err.automatic_renewal}
                            </span>
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-sm-12 form-group">
                            <div className="form-group-label">Plan description</div>
                            <div className="form-group-ct cstm-pln-editor-ss cstm-plan-editor-padding">
                                <TinyEditor
                                    value={planDetail.plan_description}
                                    valueRef={descriptionRef}
                                    handleEditorChange={(value, editor) => {
                                        dispatch({ type: "SETMODALINPROCESS" })
                                    }}
                                    handleKeyPress={() => {
                                        dispatch({ type: "SETMODALINPROCESS" })
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 form-group">
                            <div className="cstm-chk-preregistration-container">
                                <input value={planDetail.isTeamMate} type="checkbox" name="mdsPartner" id="chk-preregistration"
                                    onChange={(e) => {
                                        dispatch({ type: "SETMODALINPROCESS" })
                                        dispatch({ type: "SETSTAYONPAGE", payload: null })
                                        setPlanDetail({
                                            ...planDetail,
                                            isTeamMate: e.target.checked
                                        });
                                    }}
                                />
                                <label for="chk-preregistration">Do you want to allow this plan for inviting teammates?</label>
                            </div>
                        </div>
                    </div>

                    {planDetail.isTeamMate &&
                        <>
                            <div className="row">
                                <div className="col-sm-6 form-group">
                                    <div className="form-group-label">Number Of Teammates*</div>
                                    <div className="form-group-ct">
                                        <input
                                            type="text"
                                            name="noOfTeamMate"
                                            value={planDetail.noOfTeamMate}
                                            onChange={handleChange}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    <span className="error cstm-error1">{err.noOfTeamMate}</span>
                                </div>
                                <div className="col-sm-6 form-group">
                                    <div className="form-group-label">Plan Resources*</div>
                                    <div className="form-group-ct speakers-only">
                                        <Multiselect
                                            options={allResource}
                                            displayValue="name"
                                            selectedValues={planDetail.planResource}
                                            onSelect={(selectedlist) => {
                                                selectedlist.length > 0 ? setErr({ ...err, planResource: "" }) : setErr({ ...err, planResource: "Select plan resources" })
                                                setPlanDetail({ ...planDetail, planResource: selectedlist })
                                            }}
                                            onRemove={(selectedlist) => {
                                                selectedlist.length > 0 ? setErr({ ...err, planResource: "" }) : setErr({ ...err, planResource: "Select plan resources" })
                                                setPlanDetail({ ...planDetail, planResource: selectedlist })
                                            }}
                                        />
                                    </div>
                                    <span className="error cstm-error1">{err.planResource}</span>
                                </div></div>
                        </>
                    }
                    <div className="plan-resources-main">
                        <div className="plan-resources-main-head cstm-plan-resourse">
                            <div className="plan-resources-main-left">
                                <h5 className="gl-title-sub cstm-gl-title-sub">Plan Resources</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 plan-resourses-header">
                                <h1>Groups</h1>
                            </div>
                            <div className="col-8 plan-resources-checkbox">
                                <label className="plan-reso-bd-title">
                                    <input
                                        type="checkbox"
                                        checked={
                                            planDetail.group_access !== undefined &&
                                            planDetail.group_access === "show_all_for_group"
                                        }
                                        onChange={(e) => {
                                            dispatch({ type: "SETMODALINPROCESS" })
                                            dispatch({ type: "SETSTAYONPAGE", payload: null })
                                            e.target.checked
                                                ? setPlanDetail({
                                                    ...planDetail,
                                                    group_access: "show_all_for_group",
                                                })
                                                : setPlanDetail({
                                                    ...planDetail,
                                                    group_access: "",
                                                });
                                        }}
                                    />
                                    <span>Show all</span>
                                </label>
                                <label className="plan-reso-bd-title cstm-plan-reso-bd-title">
                                    <input
                                        type="checkbox"
                                        checked={
                                            planDetail.group_access !== undefined &&
                                            planDetail.group_access}
                                        onChange={(e) => {
                                            dispatch({ type: "SETMODALINPROCESS" })
                                            dispatch({ type: "SETSTAYONPAGE", payload: null })
                                            e.target.checked
                                                ? setPlanDetail({
                                                    ...planDetail,
                                                    group_access: "show_only_with_access_for_group",
                                                })
                                                : setPlanDetail({
                                                    ...planDetail,
                                                    group_access: "",
                                                });
                                        }}
                                    />
                                    <span>Show only access</span>
                                </label>
                            </div>
                        </div>
                        <span className="error cstm-error2">{err.group_ids}</span>
                        <div className="plan-resources">
                            {allGroup.length > 0 ? (
                                allGroup.map((group) => {
                                    return (
                                        <>
                                            <div className="plan-resources-main-body">
                                                <div className="plan-resources-main-left">
                                                    <label className="plan-reso-bd-title">
                                                        <input
                                                            type="checkbox"
                                                            name="plan_resource"
                                                            value={group._id}
                                                            onChange={(e) => {
                                                                dispatch({ type: "SETMODALINPROCESS" })
                                                                setErr({ ...err, group_ids: "" })
                                                                dispatch({ type: "SETSTAYONPAGE", payload: null })
                                                                e.target.checked
                                                                    ? setPlanDetail({
                                                                        ...planDetail,
                                                                        group_ids: [
                                                                            ...planDetail.group_ids,
                                                                            group._id,
                                                                        ],
                                                                    })
                                                                    : setPlanDetail({
                                                                        ...planDetail,
                                                                        group_ids: [
                                                                            ...planDetail.group_ids.filter(
                                                                                (id) => {
                                                                                    if (id !== group._id) return id;
                                                                                }
                                                                            ),
                                                                        ],
                                                                    });
                                                            }}
                                                            checked={
                                                                planDetail.group_ids !== undefined &&
                                                                planDetail.group_ids.includes(group._id)
                                                            }
                                                        />
                                                        <span>{group.groupTitle}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 plan-reso-bd-custm">
                            <label className="plan-reso-bd-title">Event</label>
                        </div>
                        <div className="col-sm-3 plan-reso-bd-custm">
                            <label className="plan-reso-bd-title">
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        dispatch({ type: "SETMODALINPROCESS" })
                                        dispatch({ type: "SETSTAYONPAGE", payload: null })
                                        e.target.checked
                                            ? setPlanDetail({
                                                ...planDetail,
                                                event_on_offline: "event_offline",
                                            })
                                            : setPlanDetail({
                                                ...planDetail,
                                                event_on_offline: "",
                                            });
                                    }}
                                />
                                <span>Offline</span>
                            </label>
                        </div>
                        <div className="col-sm-3 plan-reso-bd-custm">
                            <label className="plan-reso-bd-title">
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        dispatch({ type: "SETMODALINPROCESS" })
                                        dispatch({ type: "SETSTAYONPAGE", payload: null })
                                        e.target.checked
                                            ? setPlanDetail({
                                                ...planDetail,
                                                event_on_offline: "event_online",
                                            })
                                            : setPlanDetail({
                                                ...planDetail,
                                                event_on_offline: "",
                                            });
                                    }}
                                />
                                <span>Online</span>
                            </label>
                        </div>
                    </div>
                    <div className="cyp-btm-btn">
                        <button
                            className="btn"
                            onClick={handleCreate}
                            disabled={inProcess}
                        >
                            {inProcess ? "In Process..." : "Create Plan"}
                        </button>
                        <button
                            className="btn2"
                            onClick={() => {
                                navigate('/membership/list')
                                handleClearAllStates()
                            }}
                        >
                            Discard
                        </button>
                    </div>

                </div>
            </div >
            <AlertComponent />
        </>
    );
};
export default CreatePlan;
