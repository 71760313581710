import React, { useEffect, useRef, useState } from "react";
import IcnMCrossWhite from "../../../Images/icn_m_cross_white.svg";
import icnMMminus from "../../../Images/icn_m_minus_alt.svg";
import IcnMPlus from "../../../Images/icn_m_plus_alt.svg";
import {
  cancelDownload,
  handleDownloadDoc,
} from "../commonFunctions/downloadFunction";
import ToastNotification from "../../../components/ToastNotification/ToastNotification";
import authServiceDocument from "../../../Services/auth.service.document";
import NoPreview from "./NoPreview";
import PdfPreview from "./PdfPreview";
import Spinners from "../../../components/Spinners/Spinners";
import "jquery-ui/ui/widgets/draggable";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import ModalPopup from "../../../components/ModalPopup/ModalPopup";
import Typography from "../../../components/Typography/Typography";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const DocFileModal = ({
  handleClosePreview,
  isDoc,
  setDoc,
  data,
  setSavedCount,
  savedCount,
  setAllDocList,
  setSelectedRow,
  setRestrict,
}) => {
  const [isTab, setTab] = useState("tab1");
  const [isShow, setShow] = useState(true);
  const [progress, setProgress] = useState(0);
  const [downloading, setDownloading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [zoomLevel, setZoomLevel] = useState(100); // Initial zoom level at 100%
  const [loader, setLoader] = useState(true);
  const [isNoPreview, setNoPreview] = useState(false);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomInitial, setZoomInitial] = useState(1);
  const transformWrapperRef = useRef(null);
  const pageRef = useRef(null);
  const audioRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const handleLinkClick = (e) => {
        if (e.target.tagName === "A" && !e.target.hasAttribute("target")) {
          e.target.setAttribute("target", "_blank");
        }
      };
      document.addEventListener("click", handleLinkClick);
      return () => {
        document.removeEventListener("click", handleLinkClick);
      };
    }, 200);
  }, []);

  // Handle document save or unsave
  const handleSaveDocApi = async (id) => {
    setSelectedRow({ ...data, isSaved: !data.isSaved });
    setAllDocList((prevData) =>
      prevData.map((item) =>
        item._id === id ? { ...item, isSaved: !item.isSaved } : item
      )
    );
    try {
      await authServiceDocument.saveDoc(id);
    } catch (error) {
      // Handle error
    }
  };

  // Handle document download
  const handleDownload = async (data, fileUrl, restricted) => {
    if (restricted) {
      setSelectedRow(data);
      setDoc(false);
      setRestrict(true);
    } else {
      handleDownloadDoc(
        fileUrl,
        setDownloading,
        setProgress,
        data.fileName,
        data._id
      );
    }
  };

  // Reset zoom level when preview component mounts or unmounts
  useEffect(() => {
    setZoomLevel(100);
    setZoomInitial(1);

    if (transformWrapperRef.current) {
      transformWrapperRef.current.resetTransform();
    }

    if (!isDoc) {
      if (audioRef.current) audioRef.current.remove();
      if (videoRef.current) videoRef.current.remove();
    }

    if (
      isDoc &&
      ["", "documents", "tables", "presentations"].includes(data?.documentType)
    ) {
      setLoader(!data.mediaPreviewUrl);
      setNoPreview(!data.mediaPreviewUrl);
    } else {
      setLoader(true);
    }

    if (!isDoc) setNoPreview(false);
  }, [isDoc]);

  return (
    <div className={`${isDoc ? "modal-fadein" : "modal-fadeout"}`}>
      <div className="desk-globle-model-box globle-model-box doc-file-modal-only">
        <div
          className={`desk-globle-model-box-inner doc-modal-only ${
            isShow ? "sidebar" : ""
          }`}
        >
          <div className="doc-modal-only-left">
            <TransformWrapper
              ref={transformWrapperRef}
              doubleClick={{ step: 0.1 }}
              wheel={{ disabled: true }}
              minScale={1}
              maxScale={3}
              initialScale={zoomInitial}
              onTransformed={(e) =>
                setZoomLevel(
                  Math.round(e?.instance?.transformState.scale * 100)
                )
              }
              panning={{ disabled: true }}
            >
              {({ zoomIn, zoomOut }) => (
                <>
                  <div className="doc-modal-only-left-top">
                    <div className="doc-modal-only-left-top-left">
                      <button
                        className="doc-modal-icon"
                        onClick={handleClosePreview}
                      >
                        <img src={IcnMCrossWhite} />
                      </button>
                      <h5 className="doc-modal-filename">
                        {data?.fileName || ""}
                      </h5>
                    </div>

                    {!isNoPreview &&
                      ["photos", "pdfs"].includes(data?.documentType) && (
                        <div className="doc-modal-only-left-top-center">
                          <button
                            className="doc-modal-icon"
                            onClick={() => zoomOut(0.1)}
                          >
                            <img src={icnMMminus} />
                          </button>
                          <span className="doc-modal-cont">{zoomLevel}%</span>
                          <button
                            className="doc-modal-icon"
                            onClick={() => zoomIn(0.1)}
                          >
                            <img src={IcnMPlus} />
                          </button>
                        </div>
                      )}
                    <div className="doc-modal-only-left-top-right">
                      <button
                        className="doc-modal-btn"
                        onClick={() =>
                          handleDownload(
                            data,
                            data?.fileUrl,
                            data?.userDownloadBlockStatus
                          )
                        }
                        disabled={data?.userDownloadBlockStatus && isNoPreview}
                      >
                        Download
                      </button>
                    </div>
                  </div>

                  {loader && (
                    <div className="preview-loader">
                      <Spinners type="white" />
                    </div>
                  )}
                  {!loader && isNoPreview && (
                    <div className="doc-modal-only-left-sldier only-file-doc">
                      <NoPreview
                        handleDownload={handleDownload}
                        data={data}
                        restricted={data?.userDownloadBlockStatus}
                        isNoPreview={isNoPreview}
                        setNoPreview={setNoPreview}
                        handleClosePreview={handleClosePreview}
                        setRestrict={setRestrict}
                      />
                    </div>
                  )}
                  {!isNoPreview && (
                    <div
                      className={
                        data?.documentType === "photos"
                          ? "doc-preview-img"
                          : "doc-preview-media"
                      }
                    >
                      <TransformComponent className="custom-transform-component">
                        {data?.documentType === "pdfs" ||
                        ["documents", "tables", "presentations"].includes(
                          data?.documentType
                        ) ? (
                          <div
                            className="doc-modal-only-left-sldier"
                            ref={pageRef}
                          >
                            <PdfPreview
                              setPageNumber={setPageNumber}
                              loader={loader}
                              pageNumber={pageNumber}
                              numPages={numPages}
                              setLoader={setLoader}
                              setNumPages={setNumPages}
                              url={
                                data.mediaPreviewUrl &&
                                data?.documentType !== "pdfs"
                                  ? data.mediaPreviewUrl
                                  : data?.fileUrl
                              }
                              setNoPreview={setNoPreview}
                            />
                          </div>
                        ) : data?.documentType === "photos" ? (
                          <img
                            src={data?.fileUrl}
                            onError={() => {
                              setLoader(false);
                              setNoPreview(true);
                            }}
                            style={{ userSelect: "none" }}
                            onLoad={() => setLoader(false)}
                          />
                        ) : data?.documentType === "videos" ? (
                          <div className="doc-modal-only-left-sldier-video">
                            <video
                              ref={videoRef}
                              onError={() => {
                                setNoPreview(true);
                                setLoader(false);
                              }}
                              controls
                              src={data?.fileUrl}
                            />
                          </div>
                        ) : (
                          data?.documentType === "audio" && (
                            <div className="doc-modal-only-left-sldier-video">
                              <audio
                                ref={audioRef}
                                onError={() => setNoPreview(true)}
                                controls
                                src={data?.fileUrl}
                              />
                            </div>
                          )
                        )}
                      </TransformComponent>

                      {(data?.documentType === "pdfs" ||
                        ["documents", "tables", "presentations"].includes(
                          data?.documentType
                        )) &&
                        !isNoPreview &&
                        !loader && (
                          <div className="doc-modal-only-left-page">
                            <span className="doc-page-text">Page</span>
                            <span className="doc-page-cont">{pageNumber}</span>
                            <span className="doc-page-text">of</span>
                            <span className="doc-page-cont2">{numPages}</span>
                          </div>
                        )}
                    </div>
                  )}
                </>
              )}
            </TransformWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocFileModal;
